import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { CustomIcon, facClose } from '@dagility-ui/shared-components/icons';

import { DATA_MORPH_SEGMENT, JOB_SETS_SEGMENT } from '../../../data-morph-routing.constants';
import { Tool } from '../../../models/processor-monitoring/plugin.model';

@Component({
    selector: 'dp-create-new-job-definition-form',
    templateUrl: './create-new-job-definition-form.component.html',
    styleUrls: ['./create-new-job-definition-form.component.scss'],
})
export class CreateNewJobDefinitionFormComponent {
    icons: Record<string, IconDefinition | CustomIcon> = {
        facClose: facClose,
    };
    tool: Tool;
    selectedJob: any;

    viewSelector = 1;

    constructor(public modalService: NgbActiveModal, private route: ActivatedRoute, private router: Router) {}

    changeView(value: any) {
        this.viewSelector = value;
    }

    handleChangeJob(event: any) {
        this.selectedJob = event;
    }

    addJob() {
        if (this.viewSelector === 1) {
            if (this.selectedJob) {
                this.router.navigate([`admin/${DATA_MORPH_SEGMENT}/${JOB_SETS_SEGMENT}/new`], {
                    relativeTo: this.route,
                    state: {
                        jobId: this.selectedJob.id,
                        toolId: this.tool.toolId ? this.tool.toolId : null,
                        jobSetId: this.tool.jobSetId,
                        defaultJobSet: this.tool.jobSet.default,
                    },
                });
                this.modalService.close();
            }
        } else {
            this.router.navigate([`admin/${DATA_MORPH_SEGMENT}/${JOB_SETS_SEGMENT}/new`], {
                relativeTo: this.route,
                state: { toolId: this.tool.toolId, jobSetId: this.tool.jobSetId, defaultJobSet: this.tool.jobSet.default },
            });
            this.modalService.close();
        }
    }
}
