import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DropdownItem } from '@dagility-ui/kit';

import { CreateTabStepComponent } from '../create-tab/create-tab.component';
import { DashboardGroupState } from '../../state/dp-dashboard.state.model';
import { DataMorph } from '../../models/dp-dashboard.model';
import { DpDashboardStore, removeExtraSpaces } from '../../state/dp-dashboard.store';
import { ENTITY_NAME } from '../../directives/entity-name.control';
import { FormValidation } from '../../services/form-validation';

export const GROUP_COLORS = [
    '#3C4043',
    '#747474',
    '#BDC1C6',
    '#A94452',
    '#D32F2F',
    '#E91E63',
    '#FF9800',
    '#FFEB3B',
    '#4CAF50',
    '#17A2B8',
    '#31708F',
    '#03A9F4',
    '#327DE2',
    '#4054B2',
    '#673AB7',
];

@Component({
    selector: 'dp-edit-group',
    templateUrl: './dp-edit-group.component.html',
    styleUrls: ['./dp-edit-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: ENTITY_NAME, useValue: 'Group' }],
})
export class DpEditGroupComponent implements OnInit, CreateTabStepComponent {
    @Input() group: Pick<DashboardGroupState, 'name' | 'groupOrder' | 'definition'>;

    @Input() modal = false;

    @Input() createTab = false;

    @Output() save = new EventEmitter();

    @Output() cancel = new EventEmitter<void>();

    colors = GROUP_COLORS;

    readonly fontSizeBounds = {
        min: 12,
        max: 32,
    };

    readonly customErrors = {
        min: `Font size should be greater than ${this.fontSizeBounds.min - 1}`,
        max: `Font size should be less than ${this.fontSizeBounds.max + 1}`,
    };

    form = this.fb.group({
        name: ['', [Validators.required, Validators.pattern(`[- _A-Za-z0-9]*[A-Za-z0-9]$`)]],
        groupOrder: 0,
        definition: this.fb.group({
            headerFontSize: 12,
            headerColor: this.colors[0],
            hideGroupHeader: false,
            hidden: false,
            type: DataMorph.DashboardGroupVisibility.DEFAULT,
        }),
    });

    groupTypes: DropdownItem[] = [
        {
            value: DataMorph.DashboardGroupVisibility.DEFAULT,
            label: 'Show Group Header',
        },
        {
            value: DataMorph.DashboardGroupVisibility.HIDE_HEADER,
            label: 'Hide Group Header',
        },
        {
            value: DataMorph.DashboardGroupVisibility.HIDDEN,
            label: 'Hide Group',
        },
    ];

    private formValidation = inject(FormValidation);

    constructor(
        private fb: FormBuilder,
        private activeModal: NgbActiveModal,
        private store: DpDashboardStore,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        if (this.group) {
            const { type } = this.group.definition;
            const headerFontSize = 12;
            const headerColor = this.colors[0];

            this.form.patchValue({
                groupOrder: this.group.groupOrder || 0,
                name: this.group.name,
                definition: {
                    headerFontSize,
                    headerColor,
                    type: type || DataMorph.DashboardGroupVisibility.DEFAULT,
                },
            });
            this.cdr.detectChanges();
        }
    }

    handleSave() {
        this.runSaveValidators();

        if (!this.form.valid) {
            return;
        }

        const formValue = this.form.value;

        if (this.modal) {
            this.activeModal.close(formValue);
        } else {
            this.save.emit(formValue);
        }
    }

    handleCancel() {
        if (this.modal) {
            this.activeModal.dismiss();
        } else {
            this.cancel.emit();
        }
    }

    runSaveValidators() {
        if (
            !this.createTab &&
            this.form.value.name !== this.group?.name &&
            this.store.getGroupNamesByTab(this.store.value.activeTab).has(removeExtraSpaces(this.form.value.name))
        ) {
            this.formValidation.appendError(this.form.get('name'), 'unique');
            this.form.get('name').markAsTouched();
            this.cdr.detectChanges();
        }
    }
}
