import { Component, Input } from '@angular/core';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'dp-work-progress-widget',
    templateUrl: './work-progress-widget.component.html',
    styleUrls: ['./work-progress-widget.component.scss'],
})
export class WorkProgressWidgetComponent {
    @Input() options: any;

    chartData: any;
    label: string;
    noData: boolean;

    ngOnInit() {
        if (this.options?.series.length === 0) {
            return;
        }
        this.label = this.options.title.subtext;
        this.options.title.subtext = '';
        this.chartData = cloneDeep(this.options.series[0].data);
    }
}
