import { InjectionToken } from '@angular/core';

import { appendError, showServerValidationErrors, validateFormAndDisplayErrors } from '@dagility-ui/kit';

export const FormValidation = new InjectionToken('Form Validation', {
    factory: () => ({
        validateFormAndDisplayErrors,
        showServerValidationErrors,
        appendError,
    }),
});
