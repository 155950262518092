export interface FactorScoreModel {
    title: string;
    description: string;
    value: number;
}

export enum FactorScoreValueTypes {
    OUT_OF_TEN = 'out-of-10',
    PERCENT = 'percent'
}

export type FactorScoreValueType = FactorScoreValueTypes.OUT_OF_TEN | FactorScoreValueTypes.PERCENT;
