import { Component } from '@angular/core';
import { ITooltipAngularComp } from '@ag-grid-community/angular';

import { SandBox } from 'data-processor/lib/widget-library/widget-builder/services/widget-builder.util';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'tooltip-component.tooltip.show',
    template: `
        <div class="tooltip-inner" *ngIf="isHeader && valueToDisplay" [innerHtml]="this.valueToDisplay | safeHtml: 'html'"></div>
    `,
    styles: [
        `
            :host {
                overflow: hidden;
                pointer-events: none;
                transition: opacity 0.5s;
                opacity: 0.7 !important;
            }

            .tooltip-inner {
                background-color: #323232e6 !important;
            }

            :host.ag-tooltip-hiding {
                opacity: 0;
            }
        `,
    ],
})
export class CustomTooltipComponent implements ITooltipAngularComp {
    public valueToDisplay: string;
    public isHeader: boolean;

    agInit(params: any): void {
        if (params.location === 'header') {
            this.isHeader = true;
            this.valueToDisplay = params.value;
        }
    }

    executeTooltipTemplateScript(script: string) {
        const sandbox = new SandBox();
        return sandbox.buildFn(`return ( function(value, placeholders, data) {${script} })`);
    }
}
