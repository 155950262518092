<div
    class="vertical-container"
    libScroller
    libScrollerSize="24"
    [attr.libScrollerDir]="libScrollerOrientation"
    [libScrollerDir]="libScrollerOrientation"
    [class.h-100]="libScrollerOrientation === 'vertical'"
    [style.max-height.px]="libScrollerOrientation === 'vertical' ? (height$ | async) : ''"
>
    <dp-widget-tiles new [placeholders]="placeholders" [position]="position" (drilldown)="handleDrilldown($event)"></dp-widget-tiles>
</div>
