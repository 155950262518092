import { Component } from '@angular/core';
import { ControlContainer, FormArray, FormGroup, FormGroupDirective } from '@angular/forms';
import { WidgetBuilderFacade } from 'data-processor/lib/widget-library/widget-builder/state/widget-builder.facade';
import { WidgetColorThreshold } from 'data-processor';

@Component({
    selector: 'dp-widget-builder-color-thresholds',
    templateUrl: './widget-builder-color-thresholds.component.html',
    styleUrls: ['./widget-builder-color-thresholds.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class WidgetBuilderColorThresholdsComponent {
    get formGroup(): FormGroup {
        return this.parent.control;
    }

    get thresholdsArray(): FormArray {
        return this.formGroup.get('colorThreshold') as FormArray;
    }

    constructor(public facade: WidgetBuilderFacade, private parent: FormGroupDirective) {}

    addThreshold() {
        this.thresholdsArray.push(this.facade.buildThresholdGroup({} as WidgetColorThreshold));
    }

    removeThreshold(index: number) {
        this.thresholdsArray.removeAt(index);
    }
}
