<div class="d-flex h-100 w-100">
    <perfect-scrollbar [config]="perfectScrollbarConfig" class="gantt-tree-scrollbar">
        <dp-sre-gantt-tree class="gantt-element"></dp-sre-gantt-tree>
    </perfect-scrollbar>
    <perfect-scrollbar stopScrollPropagation class="gantt-wrapper-scrollbar">
        <dp-sre-gantt-wrapper
            class="gantt-element gantt-wrapper w-100 position-relative"
            [class.without-side-menu]="!service.selectedValue"
            [style.minHeight.px]="service.wrapperHeight"
        ></dp-sre-gantt-wrapper>
    </perfect-scrollbar>
    <dp-sre-gantt-side-menu
        *ngIf="service.selectedValue"
        class="gantt-element border-left ml-2 p-3"
        [style.minHeight.px]="service.wrapperHeight"
    ></dp-sre-gantt-side-menu>
</div>
