/* eslint-disable no-underscore-dangle */
import { Component, Input, ViewChild } from '@angular/core';
import { GridColumn, sortingDown, sortingUp } from '@dagility-ui/kit';
import { Sort, sortArrayByFieldAndDirection, sortGridColumn } from '@dagility-ui/shared-components/utils/sorting.utils';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarComponent, PerfectScrollbarConfig } from 'ngx-perfect-scrollbar';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-project-pipeline-execution-log-modal',
    templateUrl: './project-pipeline-execution-log-modal.component.html',
    styleUrls: ['./project-pipeline-execution-log-modal.component.scss'],
})
export class ProjectPipelineExecutionLogModalComponent {
    @ViewChild(PerfectScrollbarComponent) scrollbar: PerfectScrollbarComponent;
    @Input() pipelineName: string;
    @Input() fileName: string;
    @Input() logs$: Observable<any>;
    @Input() request$: Observable<any>;
    @Input() time: string;
    @Input() buildNumber: number;

    sort$ = new BehaviorSubject({ field: 'variableName', direction: 'DESC' } as Sort);
    icons = {
        sortingUp,
        sortingDown,
    };
    dataSource: Observable<Variable[]>;

    gridColumns: Array<GridColumn & { sort?: string }> = [
        {
            title: 'Variable Name',
            field: 'variableName',
            sortingField: 'variableName',
            width: '25%',
            sort: '',
        },
        {
            title: 'Value',
            field: 'value',
            width: '75%',
        },
    ];

    perfectScrollbarConfig: PerfectScrollbarConfig = new PerfectScrollbarConfig({
        suppressScrollX: false,
        suppressScrollY: true,
    });

    constructor(public activeModal: NgbActiveModal) {}

    ngOnInit() {
        this.dataSource = this.logs$.pipe(
            map(response => response.variables),
            map(val =>
                Object.keys(val).map(key => ({
                    variableName: key,
                    value: val[key],
                }))
            ),
            switchMap(this.sortVariables.bind(this))
        );
    }

    sortClick(index: number) {
        sortGridColumn(index, this.gridColumns, this.sort$);
    }

    sortVariables(variables: Variable[]) {
        return this.sort$.pipe(map(({ field, direction }) => sortArrayByFieldAndDirection(variables, field, direction)));
    }
}
interface Variable {
    variableName: string;
    value: string;
}
