import { Component, Inject, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, throwError, of, from } from 'rxjs';
import { catchError, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { EditJobDefinitionService } from './edit-job-definition.service';
import { IssueElement } from './models/issue-list.model';
import { JobDefinition } from './models/job-definition';
import { JobDefinitionBuilderComponent } from './job-definition-builder/job-definition-builder.component';
import { ProcessorMonitoringService } from '../processor-monitoring.service';
import { JobDefinitionFacade } from './state/job-definition.facade';
import { EnvironmentModel, ModalService } from '@dagility-ui/kit';
import { JobDefinitionCommentsComponent } from 'data-processor/lib/processor-monitoring/edit-job-definition-form/job-definition-comments/job-definition-comments.component';
import { moveToOldJDFields } from 'data-processor/lib/processor-monitoring/job-definition.lines';

const NEW_JD_ID = 'new';
const NEW_JD_NAME = 'New Job';

@Component({
    selector: 'dp-edit-job-definition-form',
    templateUrl: './edit-job-definition-form.component.html',
    styleUrls: ['./edit-job-definition-form.component.scss'],
    host: {
        class: 'job-definition-form',
    },
    providers: [EditJobDefinitionService, JobDefinitionFacade],
})
export class EditJobDefinitionFormComponent implements OnInit {
    @Input() editJDMode = false;
    @Input() toolName = '';
    @Input() templateJobId: string;

    @ViewChild(JobDefinitionBuilderComponent) set builder(builder: JobDefinitionBuilderComponent) {
        this._builder = builder;

        Promise.resolve().then(() => {
            this.facade.builderView = builder;
        });
    }
    get builder() {
        return this._builder;
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _builder: JobDefinitionBuilderComponent;

    jd$: Observable<any>;
    error = '';
    loading = false;
    saving = false;

    jdName = '';
    comment = '';
    jdId: string;
    creatingNewJd = false;
    templateToolId: string;
    toolId: string;
    jobSetId: number;

    readonly = true;
    defaultJobSet = false;
    backToTools = !!this.environment.component;

    constructor(
        public editJDState: EditJobDefinitionService,
        public facade: JobDefinitionFacade,
        private pmService: ProcessorMonitoringService,
        private toast: ToastrService,
        private route: ActivatedRoute,
        private router: Router,
        private modal: ModalService,
        @Inject('environment') private environment: EnvironmentModel,
        @Inject('templateProvider') public templateProvider: Record<string, TemplateRef<any>>
    ) {
        if (this.router.getCurrentNavigation()?.extras?.state) {
            const {
                templateJobId,
                templateToolId,
                readonly,
                toolId,
                jobSetId,
                defaultJobSet,
            } = this.router.getCurrentNavigation().extras.state;

            this.templateJobId = templateJobId;
            this.templateToolId = templateToolId;
            this.readonly = !!readonly;
            this.toolId = toolId;
            this.defaultJobSet = !!defaultJobSet;
            this.jobSetId = jobSetId;
        }
    }

    ngOnInit() {
        this.jd$ = this.route.paramMap.pipe(
            map(params => params.get('id')),
            tap(id => (this.creatingNewJd = id === NEW_JD_ID)),
            mergeMap(id => {
                if (this.creatingNewJd) {
                    return !!this.templateJobId
                        ? this.pmService.getJobDefinition(this.templateJobId).pipe(
                              map(jd => {
                                  if (jd) {
                                      jd.instanceName = '';
                                      jd.id = null;
                                  }

                                  return jd;
                              })
                          )
                        : of({});
                } else {
                    this.jdId = id;

                    return this.pmService.getJobDefinition(id);
                }
            }),
            tap(jd => {
                if (!jd) {
                    this.back();
                } else {
                    this.jdName = this.creatingNewJd ? NEW_JD_NAME : jd.instanceName;

                    this.comment = this.defaultJobSet ? jd.comment ?? '' : undefined;
                    delete jd.comment;
                    moveToOldJDFields(jd.actions ?? []);

                    this.editJDState.init(new JobDefinition(jd));
                    this.editJDState.setToolId(this.toolId || this.templateToolId);
                }
            })
        );
    }

    handleSaveClick() {
        const jd: any = this.editJDState.toSave();

        if (jd && this.editJDState.isWorkflowJD) {
            const invalidBlockIssue = this.editJDState.findInvalidBlock();

            if (invalidBlockIssue) {
                this.handleIssueSelected(invalidBlockIssue);

                return;
            }
        }

        if (!!jd) {
            this.saving = true;

            if (this.creatingNewJd) {
                jd.jobSetId = this.jobSetId;
            }

            (this.defaultJobSet
                ? from(
                      this.modal.open(
                          JobDefinitionCommentsComponent,
                          {
                              size: 'xl',
                          },
                          {
                              comment: this.comment,
                          }
                      ).result
                  ).pipe(
                      tap(comment => {
                          jd.comment = comment;
                          this.comment = comment;
                      })
                  )
                : of(null)
            )
                .pipe(
                    switchMap(() =>
                        (this.creatingNewJd ? this.pmService.createJobDefinition(jd) : this.pmService.updateJobDefinition(jd)).pipe(
                            catchError(err => {
                                this.toast.error('', 'Something went wrong');
                                this.loading = false;

                                return throwError(err);
                            })
                        )
                    ),
                    finalize(() => {
                        this.saving = false;
                        this.loading = false;
                    })
                )
                .subscribe(
                    response => {
                        this.toast.success('Success');
                        this.loading = false;

                        this.jdName = jd.instanceName;
                        if (this.creatingNewJd) {
                            this.router.navigate(['../', response.id], { relativeTo: this.route });
                        }
                    },
                    () => {}
                );
        }
    }

    back() {
        this.router.navigate(this.backToTools ? ['../../admin/tools'] : ['../'], { relativeTo: this.route });
    }

    handleIssueSelected(issue: IssueElement) {
        if (issue.isActionBlock) {
            this.editJDState.goToPath(['actions']);

            this.builder.onEditAction(issue.block, issue);
        } else {
            this.editJDState.goToPathAndValidateControl(issue);
        }
    }

    throwSaveError = (error: any) => {
        this.error = 'Something went wrong';
        this.loading = false;
        return throwError(error);
    };
}
