<div>
    <div *ngFor="let row of data; let isFirst = first"
        class="row no-gutters w-100 flex-nowrap"
    >
        <div class="statistics-block col mr-4" [ngClass]="{'mt-4': !isFirst}">
            <div class="row no-gutters upper-case">{{ row.total }}</div>
            <div class="row no-gutters normal-case">Total {{ row.itemName }}</div>
        </div>

        <div class="statistics-block colored--{{ row.grade }} col" [ngClass]="{'mt-4': !isFirst}">
            <div class="row no-gutters upper-case">
                <div class="col">
                    {{ row.itemCount }}
                </div>
                <div class="col text-right">
                    {{ row.grade.toLocaleUpperCase() }}
                </div>
            </div>
            <div class="row no-gutters normal-case">{{ row.grade.toLocaleUpperCase() }} Grade Resources</div>
        </div>
    </div>
</div>
