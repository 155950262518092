import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedComponentsModule } from '@dagility-ui/shared-components';

import { AlertListModalModule } from '../../../pages/insights/dp-extensions/alert-list-modal/alert-list-modal.component';
import { SortableAgGridHeaderComponent } from './renderers/sortable-ag-grid-header/sortable-ag-grid-header.component';
import { PipelineCellRendererComponent } from './renderers/pipeline-cell/pipeline-cell-renderer.component';
import { PipelineActionsCellRendererComponent } from './renderers/pipeline-actions-cell/pipeline-actions-cell-renderer.component';
import { PipelineTemplateUsageActionsCellComponent } from './renderers/pipeline-template-usage-actions-cell/pipeline-template-usage-actions-cell.component';
import { TemplateUsageSyncAllHeaderComponent } from './renderers/template-usage-sync-all-header.component';
import { PipelineListComponent } from './pipeline-list.component';


@NgModule({
    imports: [SharedComponentsModule, AlertListModalModule, RouterModule],
    declarations: [
        PipelineListComponent,
        SortableAgGridHeaderComponent,
        PipelineCellRendererComponent,
        PipelineActionsCellRendererComponent,
        PipelineTemplateUsageActionsCellComponent,
        TemplateUsageSyncAllHeaderComponent,
    ],
    exports: [PipelineListComponent],
})
export class PipelineListModule {}
