import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ModalService, RbacAction, RbacStaticType, sortingDown, sortingUp } from '@dagility-ui/kit';
import { isEqual } from 'lodash';
import { WorkflowsLogsComponent } from '@app/shared/components/workflows-logs/workflows-logs.component';
import { facEditorExecution, facPipelineEditor } from '@dagility-ui/shared-components/icons';
import { JobsManagementService } from '@app/shared/components/pipeline-list/api/jobs-management.service';
import { StompService } from '@app/shared/services/admin/stomp.service';
import { AuthService } from '@app/auth';
import { WorkflowsService } from '../../../pages/ci-cd/workflows/workflows.service';
import { Workflow } from '../../../pages/ci-cd/workflows/workflows.component';
import { sortArrByFieldAndDirection } from '../workflow-pipeline-list/workflow-pipeline-list.component';

@Component({
    selector: 'app-workflows-dashboard',
    templateUrl: './workflows-dashboard.component.html',
    styleUrls: ['./workflows-dashboard.component.scss'],
})
export class WorkflowsDashboardComponent implements OnChanges {
    @Input() withProjectCol = false;
    @Input() workflows: Workflow[];
    @Input() searchText: string = '';

    sortIcons = { sortingUp, sortingDown };
    gridColumns = [
        { title: 'Workflow', width: '20%', sortingField: 'name', sort: '' },
        { title: 'Project', width: '14%', sortingField: 'projectName', sort: '' },
        { title: 'Pipeline(s)', width: '10%', sortingField: 'pipelinesNumber', sort: '' },
        { title: 'Last Launch', width: '13%', sortingField: 'lastLaunchDate', sort: '' },
        { title: 'History', width: '10%' },
        { title: 'Status', width: '10%', sortingField: 'status', sort: '' },
        { title: '', width: '11%' },
        { title: 'Actions', width: '12%' },
    ];

    icons = {
        facEditor: facPipelineEditor,
        facEditorExecution: facEditorExecution,
    };
    defaultWorkflow: Workflow[];
    unsortedWorkflows: Workflow[] = [];

    canExecute: boolean;
    canModify: boolean;

    constructor(
        private router: Router,
        private workflowsService: WorkflowsService,
        private toastr: ToastrService,
        public modalService: ModalService,
        private readonly stompService: StompService,
        private jobsManagementService: JobsManagementService,
        private authService: AuthService
    ) {
        combineLatest([this.hasRight('execute'), this.hasRight('modify')]).subscribe(([canExecute, canModify]) => {
            this.canExecute = canExecute;
            this.canModify = canModify;
        });
    }

    private hasRight(action: RbacAction): Observable<boolean> {
        return this.authService.hasPermission(RbacStaticType.OBJECT, 'Pipeline', action);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!isEqual(changes.workflows?.previousValue, changes.workflows?.currentValue)) {
            this.defaultWorkflow = [...this.workflows];
            this.setSubscriptions();
        }
        if (changes.searchText?.previousValue !== changes.searchText?.currentValue) {
            this.search(this.searchText);
        }
        this.unsortedWorkflows = [...this.workflows];
    }

    private search(searchText: string) {
        if (!searchText) {
            this.workflows = this.defaultWorkflow;
        }
        searchText = searchText.toLowerCase();
        this.workflows = this.defaultWorkflow.filter(
            item => item.name.toLowerCase().includes(searchText) || item.projectName.toLowerCase().includes(searchText)
        );
    }

    private setSubscriptions() {
        if (this.workflows) {
            this.workflows.forEach((workflow: any) => {
                if (workflow.subscriptionId) {
                    this.stompSubscribing(workflow.subscriptionId);
                }
            });
        }
    }

    private subscribeWorkflow(subscribeId: any) {
        this.stompSubscribing(subscribeId);
    }

    private unsubscribeWorkflow(subscribeId: string) {
        this.jobsManagementService.unsubscribeToPipeline(subscribeId).subscribe();
    }

    private stompSubscribing(subscribeId: string) {
        this.stompService.listenTopic(StompService.CI_CD_WORKFLOW_USER_TOPIC + subscribeId).subscribe(msg => {
            const msgHeader = JSON.parse(msg.body);
            if (subscribeId !== msgHeader.type) {
                return;
            }

            const msgObj = JSON.parse(msgHeader.json);

            if (this.workflows) {
                const id = this.workflows.findIndex((w: Workflow) => w.id === msgObj.workflowId);
                if (id > -1) {
                    this.workflows[id].status = msgObj.status;
                    this.workflows[id].progress = msgObj.progress;
                }
            }
        });
    }

    handleOpenRealTime(projectId: number, workflowId: number) {
        this.router.navigate([`/project/workflow-builder/${projectId}/${workflowId}/execution-viewer`]).then();
    }

    handleProjectRedirect(projectId: number) {
        this.router.navigate([`project/${projectId}/summary-dashboard`]).then();
    }

    handleActionWorkflowRun(projectId: number, workflow: Workflow) {
        this.workflowsService.runWorkflow(projectId, workflow.id).subscribe((subscribeId: any) => {
            this.toastr.success(`${workflow.name} in progress.`, 'Attention!');
            const id = this.workflows.findIndex(w => workflow === w);
            this.workflows[id].status = 'RUNNING';
            this.workflows[id].subscribeId = subscribeId[0];
            this.subscribeWorkflow(subscribeId[0]);
        });
    }

    handleActionWorkflowStop(projectId: number, workflow: Workflow) {
        this.workflowsService.stopWorkflow(projectId, workflow.id).subscribe(() => {
            this.toastr.success(`${workflow.name} was stopped.`, 'Attention!');
            this.workflows.find(w => workflow === w).status = 'ABORTED';
            this.unsubscribeWorkflow(workflow.subscribeId);
        });
    }

    handleActionEditWorkflow(projectId: number, workflowId: number) {
        this.router.navigate([`project/${projectId}/workflow/${workflowId}`]).then();
    }

    handleActionWorkflowBuilder(projectId: number, workflowId: number) {
        this.router.navigate([`project/workflow-builder/${projectId}/${workflowId}`]).then();
    }

    handleActionOpenLogs(workflow: Workflow) {
        this.modalService
            .open(
                WorkflowsLogsComponent,
                {
                    centered: true,
                    backdrop: 'static',
                    windowClass: 'workflows-logs-modal',
                },
                {
                    workflowId: workflow.id,
                    projectId: workflow.projectId,
                    buildId: workflow.buildId,
                    lastExecutedTime: workflow.lastLaunchDate,
                }
            )
            .result.then()
            .catch(() => {
                /* rejected */
            });
    }

    sortAccordion(index: number) {
        if (!this.gridColumns[index].sortingField) {
            return;
        }
        for (let i = 0; i < this.gridColumns.length; i++) {
            if (i === index) {
                this.gridColumns[index].sort =
                    this.gridColumns[index].sort === '' ? 'ASC' : this.gridColumns[index].sort === 'ASC' ? 'DESC' : '';
            } else {
                this.gridColumns[i].sort = '';
            }
        }

        this.workflows =
            this.gridColumns[index].sort === ''
                ? this.unsortedWorkflows
                : sortArrByFieldAndDirection(this.workflows, this.gridColumns[index].sortingField, this.gridColumns[index].sort);
    }
}
