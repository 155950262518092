import { AfterViewInit, Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ProgressSpinnerComponent } from '@dagility-ui/kit';
import { IUserSession, IUserSessionResolver, KeycloakUserModel, kni } from '@dagility-ui/keep-ni';
import { cloneDeep } from 'lodash';

import { ENV_TOKEN } from '@app/tokens';
import { FeatureToggleService, UserService } from '@app/services';
import { ContextHelpService } from '@app/shared/services/context-help/context-help.service';
import { HelpSupportService } from '@dagility-ui/shared-components/utils/help-support/help-support.service';
import { SessionExpirationTracker } from './core/services/session-expiration.tracker';
import { AnnouncementService } from './shared/modules/announcement/announcement.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
    spinner = ProgressSpinnerComponent;
    formattedContextHelpData: any[] = [];
    formattedHelpData: any = {};

    constructor(
        @Inject(ENV_TOKEN) private env: Env,
        private ngZone: NgZone,
        private sessionExpirationTracker: SessionExpirationTracker,
        public announcementService: AnnouncementService,
        public contextHelpService: ContextHelpService,
        public helpSupportService: HelpSupportService,
        public userService: UserService,
        public featureToggleService: FeatureToggleService
    ) {}

    ngOnInit() {
        this.sessionExpirationTracker.track();

        this.getContextHelpData();

        // this.announcementService.getAnnouncement();
        const trackingLogIsActive = this.featureToggleService.isActive('keepni_log_events').toPromise();
        this.ngZone.runOutsideAngular(() => {
            this.configureTracking(this.userService, true, trackingLogIsActive);
        });
    }

    ngAfterViewInit() {
        document.body.classList.add('app-initialization-complete');
    }

    processContextHelpData(helpData: any) {
        this.formattedContextHelpData.push(...this.contextHelpService.formatContextHelpData(helpData));
        if (
            helpData.hasOwnProperty('links') &&
            helpData.links.hasOwnProperty('next') &&
            helpData.links.next.hasOwnProperty('href') &&
            helpData.links.next.href !== ''
        ) {
            this.contextHelpService.getContextHelpPaginatedData(helpData.links.next.href).subscribe((responseData: any) => {
                this.processContextHelpData(responseData);
            });
        } else {
            sessionStorage.setItem('contextHelp', JSON.stringify(this.formattedContextHelpData));
        }
    }

    processHelpData(helpData: any, fromBase = true) {
        if (fromBase) {
            this.formattedHelpData = cloneDeep(helpData);
        } else {
            this.formattedHelpData.data.push(...helpData.data);
        }
        if (
            helpData.hasOwnProperty('links') &&
            helpData.links.hasOwnProperty('next') &&
            helpData.links.next.hasOwnProperty('href') &&
            helpData.links.next.href !== ''
        ) {
            this.helpSupportService.getHelpSupportPaginatedData(helpData.links.next.href).subscribe((responseData: any) => {
                this.processHelpData(responseData, false);
            });
        } else {
            sessionStorage.setItem('helpSupportData', JSON.stringify(this.formattedHelpData));
        }
    }

    getContextHelpData() {
        this.contextHelpService.getContextHelpData().subscribe((response) => {
            this.processContextHelpData(response);
        });

        this.helpSupportService.getmenu().subscribe((result: any) => {
            this.processHelpData(result);
        });
    }

    ngOnDestroy() {
        this.sessionExpirationTracker.unTrack();
    }

    private configureTracking(us: UserService, exist: boolean, trackingLogIsActive: Promise<boolean>) {
        kni(this.env.webConsoleApiUrl)
            .withProductAndCustomer(this.env.env.product, this.env.env.customer, this.env.env.name)
            .userSessionResolver(
                new (class implements IUserSessionResolver {
                    getUserSession(): Observable<IUserSession> {
                        const ku = us.user;
                        const u: KeycloakUserModel = {
                            createdOn: ku.createdOn,
                            email: ku.email,
                            extId: null,
                            firstName: ku.firstName,
                            id: ku.id,
                            keycloakId: ku.keycloakId,
                            lastLoginTime: ku.lastLoginTime,
                            lastName: ku.lastName,
                            login: ku.login,
                            roles: ku.roles,
                        };
                        return of(u);
                    }
                })()
            )
            .withManualPathResolver()
            .start(exist);
        trackingLogIsActive.then((enabled) => {
            kni().api.enableLog(enabled);
        });
    }
}
