<perfect-scrollbar style="max-height: inherit;">
    <ul style="max-height: inherit" class="list-unstyled overflow-auto mb-0">
        <li class="row no-gutters list-element pt-2 pb-2" *ngFor="let issue of issuesList"
            (click)="issueSelected.emit(issue)">
            <div class="col-2 d-flex justify-content-center align-items-center">
                <fa-icon [ngClass]="'icon-' + (issue.type | lowercase)" class="fa type-icon" [icon]="icons[issue.type]">
                </fa-icon>
            </div>
            <div class="col-10 flex-column">
                <div class="text-truncate">{{ issue.message }}</div>
                <div class="error-path text-truncate">{{ issue.errorPath | normalizePath }}</div>
            </div>
        </li>
    </ul>
</perfect-scrollbar>