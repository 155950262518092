import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilesAttacherService } from './files-attacher.service';
import { FilesAttacherComponent } from './files-attacher.component';
import { SharedComponentsModule } from '@dagility-ui/shared-components';

@NgModule({
    imports: [CommonModule, SharedComponentsModule],
    declarations: [FilesAttacherComponent],
    exports: [FilesAttacherComponent],
    providers: [FilesAttacherService],
})
export class FilesAttacherModule {}
