import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DropdownOptionItem } from '../../../../../widget-builder/services/widget-builder.service';

@Component({
    selector: 'dp-filter-value-preview',
    template: `
        <span
            [ngbTooltip]="item.label"
            libTooltipWhenOverflow
            triggers="manual"
            data-test-id="filter-value-preview"
            class="body1 text-truncate"
            >{{ item.label }}</span
        >
        <dp-preferred-item-icon [item]="item"></dp-preferred-item-icon>
    `,
    styleUrls: ['./filter-value-preview.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'filter-item pr-4',
    },
})
export class FilterValuePreviewComponent {
    @Input() item: DropdownOptionItem;
}
