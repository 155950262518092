import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { PropertiesEditorModule, SharedComponentsModule } from '@dagility-ui/shared-components';
import { NgbAccordionModule, NgbNavModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { WidgetLibraryModule } from '../widget-library.module';
import { DpDashboardGroupHeaderComponent } from './components/dp-dashboard-group-header/dp-dashboard-group-header.component';
import { DpDashboardGroupComponent } from './components/dp-dashboard-group/dp-dashboard-group.component';
import { CdkDropListActualContainerDirective, DpDashboardTabComponent } from './components/dp-dashboard-tab/dp-dashboard-tab.component';
import { DpDashboardComponent } from './components/dp-dashboard/dp-dashboard.component';
import { DpDashboardService } from './services/dp-dashboard.service';
import { DashboardWidgetListComponent } from './components/dashboard-widget-list/dashboard-widget-list.component';
import {
    DashboardWidgetSettingsComponent,
    DashboardWidgetSettingsFormExtension,
} from './components/dashboard-widget-settings/dashboard-widget-settings.component';
import { DpEditGroupComponent } from './components/dp-edit-group/dp-edit-group.component';
import { DpEditTabComponent } from './components/dp-edit-tab/dp-edit-tab.component';
import { WidgetBuilderDashboardComponent } from './components/widget-builder-dashboard/widget-builder-dashboard.component';
import { GridsterModule } from 'angular2gridster';
import { GridsterResizeComponent } from './components/dp-dashboard-group/gridster-resize.component';
import { DisableScrollDirective } from './components/dashboard-widget-list/disable-scroll.directive';
import { ExportLoaderComponent } from 'data-processor/lib/widget-library/dashboard/components/dp-dashboard/export-loader';
import { PerfectScrollbarEventPropagationDirective } from './directives/perfect-scrollbar-event-propagation.directive';
import { DashboardWidgetSettingsManager } from 'data-processor/lib/widget-library/dashboard/services/dashboard-widget-settings.manager';
import { WIDGET_EXTENSIONS_INNER, WidgetExtensionInner } from 'data-processor/lib/extensions';
import { GridsterWidgetsDirective } from 'data-processor/lib/widget-library/dashboard/components/dp-dashboard-group/angular2grister-patch';
import { GridsterItemSizeComponent } from './components/dp-dashboard-group/gridster-item-size/gridster-item-size.component';
import { CdkScrollableModule, ScrollingModule } from '@angular/cdk/scrolling';

import { IlluminateExtensionsDirective } from './components/dp-dashboard-group/illuminate-extensions.directive';
import { CreateTabComponent } from './components/create-tab/create-tab.component';
import { DragAndDropAreaComponent } from './components/dp-dashboard-group/drag-and-drop-area/drag-and-drop-area.component';
import { GridsterHeightDirective } from './components/dp-dashboard-group/gridster-height.directive';
import { DpCreateDashboardComponent } from './components/create-dashboard/dp-create-dashboard.component';
import { DASHBOARD_FORM_EXTENSION, DashboardFormExtension } from 'data-processor/tokens';
import { EditDashboardOptionsComponent } from './components/edit-dashboard-new/edit-dashboard-options.component';
import { ProvideDefaultPlaceholdersDirective } from './components/widget-builder-dashboard/provide-default-placeholders.directive';
import { EntityNameDirective } from 'data-processor/lib/widget-library/dashboard/directives/entity-name.control';
import { SubmitAfterAsyncValidationDirective } from 'data-processor/lib/widget-library/dashboard/directives/submit-after-async-validation.directive';
import { DashboardExternalFilterDirective } from './directives/dashboard-external-filter.directive';
import { DashboardFilterSetModule } from './components/dashboard-filter-set/dashboard-filter-set.module';
import { DpDashboardGroupReorderDialogComponent } from './components/dp-dashboard-group-reorder-dialog/dp-dashboard-group-reorder-dialog.component';
import { DpDashboardGridsterDirective } from './components/dp-dashboard-group/dp-dashboard-gridster.directive';

const providers = [DpDashboardService];

@NgModule({
    imports: [
        NgbNavModule,
        NgbAccordionModule,
        NgbPopoverModule,
        WidgetLibraryModule,
        SharedComponentsModule,
        PropertiesEditorModule,
        RouterModule,
        GridsterModule.forRoot(),
        ScrollingModule,
        EntityNameDirective,
        SubmitAfterAsyncValidationDirective,
        DashboardFilterSetModule,
        CdkScrollableModule,
    ],
    declarations: [
        DpDashboardComponent,
        DpDashboardTabComponent,
        CdkDropListActualContainerDirective,
        DpDashboardGroupReorderDialogComponent,
        DpDashboardGroupComponent,
        DpDashboardGridsterDirective,
        GridsterWidgetsDirective,
        DpDashboardGroupHeaderComponent,
        DashboardWidgetListComponent,
        DashboardWidgetSettingsComponent,
        DpEditGroupComponent,
        DpEditTabComponent,
        WidgetBuilderDashboardComponent,
        GridsterResizeComponent,
        DisableScrollDirective,
        ExportLoaderComponent,
        PerfectScrollbarEventPropagationDirective,
        GridsterItemSizeComponent,
        IlluminateExtensionsDirective,
        CreateTabComponent,
        DragAndDropAreaComponent,
        GridsterHeightDirective,
        ProvideDefaultPlaceholdersDirective,
        DpCreateDashboardComponent,
        EditDashboardOptionsComponent,
        DashboardExternalFilterDirective,
    ],
    providers: [...providers],
    exports: [DpDashboardComponent, WidgetBuilderDashboardComponent, DpCreateDashboardComponent, DashboardExternalFilterDirective],
})
export class DpDashboardModule {
    static forChild(config: {
        dashboardWidgetSettingsManager: Type<DashboardWidgetSettingsManager>;
        dashboardFormExtensionNew?: Type<DashboardFormExtension>;
        widgetFormExtension?: Type<DashboardWidgetSettingsFormExtension>;
        extensions?: WidgetExtensionInner[];
    }): ModuleWithProviders<DpDashboardModule> {
        return {
            ngModule: DpDashboardModule,
            providers: [
                ...providers,
                {
                    provide: DashboardWidgetSettingsManager,
                    useExisting: config.dashboardWidgetSettingsManager,
                },
                {
                    provide: DASHBOARD_FORM_EXTENSION,
                    useValue: config.dashboardFormExtensionNew,
                },
                {
                    provide: DashboardWidgetSettingsFormExtension,
                    useValue: config.widgetFormExtension,
                },
                {
                    provide: WIDGET_EXTENSIONS_INNER,
                    useValue: config.extensions ?? [],
                },
            ],
        };
    }
}
