import { Inject, Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, pluck, shareReplay } from 'rxjs/operators';
import { ENV_TOKEN } from '@app/tokens';

@Injectable({
    providedIn: 'root',
})
export class HealthService {
    private readonly healthUrls: Record<string, string> = this.env.calibrate?.enabled
        ? {
            calibrate: `${this.env.calibrate?.apiUrl}`,
        }
        : {
              calibrate: `${this.env.calibrate?.apiUrl}`,
              conveyor: `${this.env.serviceUrl}/pipeline`,
              habitat: `${this.env.serviceUrl}/habitat`,
              monitoring: `${this.env.serviceUrl}/monitoring`,
              notification: `${this.env.serviceUrl}/notification`,
          };
    isAlive$ = Object.keys(this.healthUrls).reduce<Record<string, Observable<boolean>>>((prev, curr: string) => {
        prev[curr] = this.isServiceAlive(this.healthUrls[curr]).pipe(shareReplay(1));
        return prev;
    }, {});

    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {}

    getIsServiceAlive(entity: string): Observable<boolean> {
        return this.isAlive$[entity];
    }

    getIsHealthUrlAlive(healthUrl: string): Observable<boolean> | null {
        const entity = Object.keys(this.healthUrls).find(
            key => `${this.healthUrls[key].replace(this.env.serviceUrl, '')}/actuator/health` === healthUrl
        );
        if (!entity) {
            return null;
        }
        return this.isAlive$[entity];
    }

    private isServiceAlive(url: string): Observable<boolean> {
        return this.http
            .get<string>(`${url}/actuator/health`, {
                headers: { 'skip-global-error': 'true' },
            })
            .pipe(
                pluck('status'),
                map(status => status === 'UP'),
                catchError(() => of(false))
            );
    }
}
