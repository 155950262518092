import { Component } from '@angular/core';
import { QueryBuilderStore } from '@app/shared/components/query-builder/store/query-builder.store';
import { QB_ICON_URLS } from '@app/shared/components/query-builder/consts/qb-icon-urls.const';
import { DataSetField, FieldRoleType, Model } from '@app/shared/components/query-builder/models/model-graph.model';

@Component({
    selector: 'app-qb-visualization-dataset-items',
    templateUrl: './dataset-items.component.html',
    styleUrls: ['./dataset-items.component.scss'],
})
export class DatasetItemsComponent {
    icons = QB_ICON_URLS;

    constructor(private queryBuilderStore: QueryBuilderStore) {}

    get datasetFields(): DataSetField[] {
        if (!this.queryBuilderStore.modelGraph.dataSetFields) {
            return [];
        }
        return this.queryBuilderStore.modelGraph.dataSetFields.fields;
    }

    get models(): Model[] {
        return this.queryBuilderStore.modelGraph ? this.queryBuilderStore.modelGraph.models : [];
    }

    get calculatedFields(): DataSetField[] {
        return this.queryBuilderStore.modelGraph.dataSetFields.fields.filter(dsField => dsField.calculated);
    }

    get dataSetFields(): DataSetField[] {
        return this.queryBuilderStore.modelGraph.dataSetFields.fields.sort();
    }

    dragStart(event: DragEvent, field: DataSetField) {
        event.dataTransfer.setData('text', JSON.stringify(field));
    }

    datasetFieldSelected(model: Model, field: DataSetField): boolean {
        return this.queryBuilderStore.modelGraph.dataSetFields.fields.filter(dsField => dsField.uid === field.uid)[0].selected;
    }

    fieldHasFieldRoleType(field: DataSetField, fieldRoleType: FieldRoleType) {
        return this.queryBuilderStore.modelGraph.models
            .filter(model => model.uid === field.srcModelUid)[0]
            .data.fields.filter(fieldData => fieldData.name === field.dsFieldName)[0]
            .roles.find(role => role.type.includes(fieldRoleType));
    }
}
