import { getTypeMetadata, isObject } from '../type.decorator';
import { isEmpty } from 'lodash';

export abstract class Deserializable {
    static readonly METAFIELDS = ['_class', '_enums', '_maps', '_arrays', 'meta', 'typingErrors', '_nullableFields', '_nameMapping'];

    abstract meta: any;

    typingErrors: Record<string, any> = {};

    // info about typing object fields
    abstract _class: any;
    // info about typing enums fields
    abstract _enums: any;
    // info about typing arrays fields
    abstract _arrays: any;
    // info about typing maps fields
    abstract _maps: any;

    _nameMapping?: any = {};

    getType() {
        return '';
    }

    getInfo() {
        return `info about ${this.getType()}`;
    }

    instance(fieldName: string, args: any, isArray = false, isMap = false) {
        const constructor = (isArray ? this._arrays : isMap ? this._maps : this._class)[fieldName];
        return PrimitiveDataType.isPrimitiveDataType(constructor) ? args : new constructor(args);
    }

    // create a new instance of the class
    create() {
        return Reflect.construct(Reflect.getPrototypeOf(this).constructor, []);
    }

    deserialize(obj: any) {
        let thisObj = this;
        Object.keys(obj).forEach(key => {
            if (typeof obj[key] === 'object' || (typeof obj[key] === 'string' && isObject((this as Record<string, any>)[key]))) {
                if (Array.isArray(obj[key])) {
                    if (typeof obj[key][0] == 'object' && this._arrays[key]) {
                        (thisObj as any)[key] = obj[key].map((o: any) => this.instance(key, o, true));
                    } else {
                        (thisObj as any)[key] = obj[key];
                    }
                } else if (this._class[key]) {
                    (thisObj as any)[key] = this.instance(key, obj[key]);
                } else if (this._enums[key] && !this._maps[key]) {
                    (thisObj as Record<string, any>)[key] = obj[key] ? this._enums[key][obj[key]] : obj[key];
                } else if (this._maps[key]) {
                    const isEnumMap = key in this._enums;
                    const mapData = obj[key];

                    let mapConstructor: any;

                    if (this._maps[key] && ['string', 'boolean', 'number'].indexOf(typeof this._maps[key]) === -1) {
                        mapConstructor = this._maps[key];

                        if (PrimitiveDataType.isPrimitiveDataType(this._maps[key])) {
                            mapConstructor = null;
                        }
                    }
                    (thisObj as any)[key] = Object.keys(mapData).reduce<any>((acc, k) => {
                        acc[k] =
                            mapConstructor && !isEnumMap
                                ? new mapConstructor(mapData[k])
                                : isEnumMap
                                ? this._enums[key][mapData[k]]
                                : mapData[k];

                        return acc;
                    }, {});
                }
            } else {
                (thisObj as any)[key] = obj[key];
            }
        });
    }

    checkTypingField<T, K extends keyof T>(constructor: new (args: any) => T, obj: any, key: any): T[K] {
        const instance = Reflect.construct(constructor, []);
        const typingMetadata = getTypeMetadata(instance, key);

        if (typingMetadata) {
            if (typingMetadata.is && !typingMetadata.is(obj[key])) {
                (this.typingErrors as Record<string, any>)[key as string] =
                    typingMetadata.type === 'Enum'
                        ? typingMetadata.typingError(obj[key], true)
                        : typingMetadata.typingError(typeof obj[key]);
                return typingMetadata.default;
            } else if (
                typingMetadata.type &&
                typingMetadata.childType &&
                ['Array', 'Map'].includes(typingMetadata.type.name) &&
                typingMetadata.is
            ) {
                const { is } = typingMetadata.childType;
                if (typingMetadata.type.name === 'Map') {
                    return Object.keys(obj[key]).reduce<any>((acc, k) => {
                        if (is(obj[key][k])) {
                            acc[k] = (obj as Record<string, any>)[key][k];
                        }

                        return acc;
                    }, {});
                }
                return (obj[key] as any).map((field: any) => {
                    if (is(field)) {
                        return field;
                    }
                });
            } else {
                return obj[key];
            }
        } else {
            return obj[key];
        }
    }

    removeNotOwnFields<T>(constructor: new (args?: any) => T, obj: any): Partial<T> {
        const ownKeys = Describer.describeClass(constructor);

        return Object.keys(obj).reduce((acc, key) => {
            if (ownKeys.includes(key)) {
                return {
                    ...acc,
                    [key]: this.checkTypingField(constructor, obj, key),
                };
            } else {
                return acc;
            }
        }, {});
    }

    fillByInstance(childConstructor: any, instanceData: any) {
        if (childConstructor && instanceData) {
            this.deserialize(this.removeNotOwnFields(childConstructor, instanceData));
        }
    }
}

// class marker for untyped data
export class PrimitiveDataType {
    type: 'string' | 'boolean' | 'number' = 'string';

    value: string | boolean | number = '';

    static isPrimitiveDataType(type: any) {
        return type && type === PrimitiveDataType;
    }

    static IsEmpty(el: any) {
        return typeof el === 'string' ? isEmpty(el) : Array.isArray(el) ? !el.length : false;
    }

    constructor(value: string | boolean | number | null) {
        if (!value) {
            return;
        }
        this.value = value;
        this.type = typeof value as any;
    }
}

// @dynamic
export class Describer {
    static describeClass(constructor: any) {
        const a = new constructor();
        return Object.getOwnPropertyNames(a);
    }

    static describeObject(obj: any) {
        return Object.keys(obj).filter(key => obj[key]);
    }
}

