import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseWidgetFilter } from '../../../../components/widget/filters/base-widget-filter.component';
import { ComplexNamedDropdownFilter, FILTER_DEFAULT_SIZES } from '../../../../models/any-widget.model';
import { DropdownComponent } from '@dagility-ui/kit';
import { take, takeUntil } from 'rxjs/operators';
import { getWidgetWidth } from '../../../../services/widget-builder.util';
import { DashboardWidgetSettingsManager } from '../../../../../dashboard/services/dashboard-widget-settings.manager';
import { Observable, Subject } from 'rxjs';

@Component({
    selector: 'dp-complex-named-dropdown-filter',
    template: `
        <ng-template #optionTemplate let-item="item">
            <div
                style="width: calc(100% - 40px)"
                class="text-truncate"
                [ngbTooltip]="item.label"
                container="body"
                triggers="manual"
                libTooltipWhenOverflow
            >
                <span>{{ item.label }}</span>
                <span class="position-absolute" style="right: 0.5rem"
                    ><fa-icon
                        icon="facDelete"
                        class="close-icon mr-2 font-16px color-red"
                        (click)="handleDeleteItem(item, $event)"
                    ></fa-icon>
                    <fa-icon icon="rotationRight" class="font-16px" (click)="handleRefreshItem(item, $event)"></fa-icon>
                </span>
            </div>
        </ng-template>

        <ng-template #labelTemplate let-item="item">
            <div
                class="text-truncate position-relative"
                [ngbTooltip]="item.label"
                container="body"
                triggers="manual"
                libTooltipWhenOverflow
            >
                {{ item.label }}
            </div>
        </ng-template>

        <lib-dropdown
            *ngIf="items"
            class="size-s"
            [width]="filter.width | mapper: getWidth"
            [searchable]="true"
            [clearSearchOnAdd]="false"
            [formControl]="formControl"
            [label]="filter.label"
            [items]="items"
            [disableFilterOnSelect]="true"
            appendTo="body"
            [addTag]="true"
            [addTagFunction]="addTagFn"
            [optionTemplateRef]="optionTemplate"
            [labelTemplateRef]="labelTemplate"
            [attr.action-id]="'action-' + filter.placeholder"
            (valueChange)="handleChange($event)"
        ></lib-dropdown>
    `,
    styles: [
        `
            .text-truncate {
                z-index: 1;
            }
        `,
    ],
})
export class ComplexNamedDropdownFilterComponent extends BaseWidgetFilter implements OnInit {
    @ViewChild(DropdownComponent) dropdown: DropdownComponent;

    @Input() filter: ComplexNamedDropdownFilter;
    @Input() filtersGroup: FormGroup;
    @Input() dashboardId: number;
    @Input() placeholders$: Observable<any>;
    @Input() dummyFilter = false;

    @Output() change = new EventEmitter();

    items: any[];

    getWidth = getWidgetWidth(FILTER_DEFAULT_SIZES.DROPDOWN);

    private filterDependencyInit$ = new Subject<void>();

    constructor(
        private service: DashboardWidgetSettingsManager,
        private cdr: ChangeDetectorRef,
        public elementRef: ElementRef<HTMLElement>
    ) {
        super(null);

        this.mainFilter = true;
    }

    ngOnInit() {
        this.service
            .getComplexNamedDropdownItems(this.dashboardId)
            .pipe(take(1))
            .subscribe(value => {
                this.items = value && value.values ? value.values : [];
                this.cdr.detectChanges();

                if (this.items.length) {
                    this.dropdown.writeValue(this.items[0].value);
                    this.handleChange(this.items[0].value);
                } else {
                    this.change.next(true);
                }
            });

        this.getFilterDependency();
    }

    public getFilterText(): { title: string; value: any } {
        return { title: this.filter.label, value: this.dropdown.ngSelect.selectedValues };
    }

    private getFilterDependency() {
        if (!this.filter.filterDependency) {
            return;
        }

        this.placeholders$.pipe(takeUntil(this.filterDependencyInit$)).subscribe(value => {
            if (value[this.filter.filterDependency]) {
                this.initDependentFilters(value[this.filter.filterDependency]);
            }
        });
    }

    private initDependentFilters(value: any) {
        Object.keys(value)?.forEach(key => {
            const control = this.filtersGroup.get(key);
            if (!control) {
                return;
            }

            control.setValue(value[key]);
        });

        this.filterDependencyInit$.next();
    }

    addTagFn = (value: string) => {
        const dependencies: any = {};
        this.filter.dependencies.forEach(dependency => {
            const control = this.filtersGroup.get(dependency);
            if (control) {
                dependencies[dependency] = control.value;
            }
        });

        this.items = [...this.items, { value: value, label: value, ...dependencies }];
        this.service
            .updateComplexNamedDropdownItems(this.dashboardId, this.items)
            .pipe(take(1))
            .subscribe();
    };

    handleChange(event: any) {
        if (this.dummyFilter) {
            return;
        }

        const value = this.items.find(item => item.value === event);

        if (!value) {
            return;
        }

        this.filter.dependencies.forEach(dependency => {
            const control = this.filtersGroup.get(dependency);
            if (control && value[dependency]) {
                const newControlValue = { controlValue: value[dependency], fromComplexNamedDropdown: true };
                control.setValue(newControlValue);
            }
        });

        this.change.next(true);
    }

    handleDeleteItem(deletedItem: any, event: Event) {
        event.stopPropagation();

        this.items = this.items.filter((item: any) => item.value !== deletedItem.value && item.label !== deletedItem.label);
        this.service
            .updateComplexNamedDropdownItems(this.dashboardId, this.items)
            .pipe(take(1))
            .subscribe();
    }

    handleRefreshItem(oldItem: any, event: Event) {
        event.stopPropagation();

        const dependencies: any = {};
        this.filter.dependencies.forEach(dependency => {
            const control = this.filtersGroup.get(dependency);
            if (control) {
                dependencies[dependency] = control.value;
            }
        });

        const i = this.items.findIndex((item: any) => item.value === oldItem.value && item.label === oldItem.label);
        this.items[i] = { value: this.items[i].value, label: this.items[i].label, ...dependencies };
        this.service
            .updateComplexNamedDropdownItems(this.dashboardId, this.items)
            .pipe(take(1))
            .subscribe();

        this.cdr.detectChanges();
    }
}
