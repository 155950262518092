<div class="d-flex flex-column" style="height: 284px" #mainAreaRef [style.maxHeight.px]="284">
    <ng-container *ngIf="conditions.length !== 0; else addCondition">
        <svg [attr.width]="mainAreaRef.offsetWidth" [attr.height]="mainAreaRef.offsetHeight" #svgContainer>
            <g *ngFor="let condition of conditions; let conditionIndex = index">
                <path
                    *ngFor="let path of condition.expression; let expressionIndex = index"
                    [attr.d]="getPath(expressionIndex, conditionIndex)"
                    class="relation-path"
                >
                </path>
                <path [attr.d]="getPath(conditionIndex, conditionIndex, true)" class="relation-path"></path>
            </g>
            <foreignObject [attr.x]="0" [attr.y]="0" [attr.width]="mainAreaRef.offsetWidth"
                           [attr.height]="mainAreaRef.offsetHeight">
                <perfect-scrollbar>
                    <div
                        [style]="'margin-left:' + 20 * (condition.parentId + 1) + 'px;'"
                        *ngFor="let condition of conditions; let index = index"
                        class="conditionWrapper"
                    >
                        <div class="operatorWrapper mb-16px">
                            <fa-icon class="path-start color-brand" icon="facFilterGroup"></fa-icon>
                            <div class="operator">
                                <span
                                    (click)="openOperatorActions(condition.id, index, 'operator')">{{ condition.groupOp }}</span>
                            </div>
                            <fa-icon
                                class="icon expressionInputRef"
                                icon="plus"
                                (click)="openOperatorActions(condition.id, index, 'plus')"
                            ></fa-icon>
                            <fa-icon class="icon ml-12px" icon="facTrash"
                                     (click)="handleDelete(condition.id, 'operator')"></fa-icon>
                        </div>
                        <div *ngFor="let expression of condition.expression; let expressionIndex = index"
                             class="expressionWrapper">
                            <div class="expression expression-{{ index }} mb-16px">
                                <span
                                    *ngIf="
                                        !editCondition || editConditionIndex !== condition.id || addExpressionIndex !== expressionIndex;
                                        else expressionEditor
                                    "
                                    class="expression-data"
                                >{{ updateExpression(expression) }}</span
                                >
                            </div>
                            <fa-icon class="icon" icon="facEdit"
                                     (click)="handleEdit(condition.id, expressionIndex, expression)"></fa-icon>
                            <fa-icon class="icon ml-12px" icon="facTrash"
                                     (click)="handleDelete(condition.id, 'expression', expression)"></fa-icon>
                        </div>
                        <div class="input-form mb-16px" *ngIf="addExpression && addExpressionIndex === condition.id">
                            <div
                                class="fx-button"
                                [class.selected]="isSwitchFunctionEditor"
                                (click)="onOpenFunctionEditor()"
                                id="fxButtonRef"
                            >
                                <span class="m-2 fa">
                                    <img alt="" src="assets/images/icons/icon_function.svg"/>
                                </span>
                            </div>
                            <lib-input
                                [readonly]="isSwitchFunctionEditor"
                                [id]="'expressionInput'"
                                (input)="onExpressionInput($event)"
                            ></lib-input>
                        </div>
                    </div>
                </perfect-scrollbar>
            </foreignObject>
        </svg>
    </ng-container>
</div>

<ng-template #addCondition>
    <div
        class="cursor-pointer d-flex align-items-center"
        id="rootCondition"
        (click)="openOperatorActions(undefined, 0, 'root')"
    >
        <ng-container *ngIf="!conditions.length; else plusTmp">
            <svg class="color-brand mr-15px" width="12" height="12" viewBox="0 0 12 12" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6 4V8M4 6H8M2.5 1.5H9.5C10.0523 1.5 10.5 1.94772 10.5 2.5V9.5C10.5 10.0523 10.0523 10.5 9.5 10.5H2.5C1.94772 10.5 1.5 10.0523 1.5 9.5V2.5C1.5 1.94772 1.94772 1.5 2.5 1.5Z"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
            Add Condition
        </ng-container>
        <ng-template #plusTmp>
            <fa-icon ngbTooltip="Add Relation" openDelay="500" container="body" class="secondary-text-and-icons"
                     icon="plus"></fa-icon>
        </ng-template>
    </div>
</ng-template>

<div
    class="d-flex flex-column operator-actions"
    [style.top.px]="operatorActionsPosition.top"
    [style.left.px]="operatorActionsPosition.left"
    *ngIf="isOperatorActions"
>
    <span
        *ngIf="isOperatorActionsType === 'operator' && isOperatorActionsType !== 'root'"
        (click)="setOperator(isOperatorActionsIndex, 'AND')"
    >And</span
    >
    <span
        *ngIf="isOperatorActionsType === 'operator' && isOperatorActionsType !== 'root'"
        (click)="setOperator(isOperatorActionsIndex, 'OR')"
    >Or</span
    >
    <div *ngIf="isOperatorActionsType === 'operator' && isOperatorActionsType !== 'root'" class="divider"></div>
    <span (click)="handleNewOperator(isOperatorActionsIndex, 'AND')">Add AND Group</span>
    <span (click)="handleNewOperator(isOperatorActionsIndex, 'OR')">Add OR Group</span>
    <span *ngIf="isOperatorActionsType !== 'root'" (click)="handleEnableAddExpression(isOperatorActionsIndex, true)">Add Condition</span>
    <span *ngIf="isOperatorActionsType !== 'root'" (click)="handleDelete(isOperatorActionsIndex, 'operator')">Delete Group</span>
</div>


<app-qb-model-expression-builder
    id="expressionBuilderRef"
    [cursor]="expressionCursor"
    [expression]="newExpression"
    [isSwitchFunctionEditor]="isSwitchFunctionEditor"
    [isViewEditor]="isViewEditor"
    (expressionChanged)="onExpressionChanged($event)"
    [style.top.px]="expressionBuilderPosition.top"
    [style.left.px]="expressionBuilderPosition.left"
>
</app-qb-model-expression-builder>

<ng-template #expressionEditor>
    <div class="input-form">
        <div class="fx-button" [class.selected]="isSwitchFunctionEditor" (click)="onOpenFunctionEditor()"
             id="fxButtonTemplateRef">
            <span class="m-2 fa">
                <img alt="" src="assets/images/icons/icon_function.svg"/>
            </span>
        </div>
        <lib-input
            [id]="'expressionInputTemplate'"
            (input)="onExpressionInput($event)"
        ></lib-input>
    </div>
</ng-template>
