<div style="display: flex; flex-direction: column; width: 100%">
    <button (click)="loadItems()" class="cursor-pointer btn size-s btn-link">
        <ng-container *ngIf="loadingStatus === 'READY' && !opened">
            Add new
            <app-icon-remoter class="ml-5px" [width]="10" [height]="10" [color]="'BRAND'" [iconName]="'CHEVRON'"></app-icon-remoter>
        </ng-container>
        <ng-container *ngIf="loadingStatus === 'READY' && opened">
            Cancel
            <app-icon-remoter style="transform: rotate(180deg);" class="ml-5px" [width]="10" [height]="10" [color]="'BRAND'" [iconName]="'CHEVRON'"></app-icon-remoter>
        </ng-container>
        <ng-container *ngIf="loadingStatus === 'ERROR'">
            <app-icon-remoter class="mr-5px" [width]="12" [height]="12" [color]="'ERROR'" [iconName]="'ERROR'"></app-icon-remoter>
            <span style="color: #c43030">Error: Retry?</span>
        </ng-container>
        <ng-container *ngIf="loadingStatus === 'LOADING' || loadingStatus === 'APPLYING'">
            <lib-loader></lib-loader>
        </ng-container>
    </button>
    <div class="container" *ngIf="opened && items" perfectScrollbar>
        <div class="items-container" *ngFor="let item of items">
            <div class="item" (click)="selectItem(item)">
                <span>{{joinType(item)}}</span>{{candidateName(item)}}
            </div>
        </div>
    </div>
</div>
