import { inject, Injectable } from '@angular/core';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, publishReplay, refCount, switchMap, take } from 'rxjs/operators';

import { AuthService } from '@app/auth';
import { IDefaultRoute, UserDefaultRoute, UserDefaultRoutes } from '@app/models';
import { ModulesResolver } from '@app/core/services/modules.resolver';
import { defaultLandingPagePropName, PlatformSettingsService } from '@app/shared/services/admin/platform-settings.service';

const ROUTE_PRIORITIES: IDefaultRoute[] = [UserDefaultRoute.INSIGHTS, UserDefaultRoute.CONVEYOR];
const STATIC_ROUTES: Set<IDefaultRoute> = new Set([UserDefaultRoute.SETTINGS]);

function isStaticRoute(route: IDefaultRoute) {
    return STATIC_ROUTES.has(route);
}

@Injectable({
    providedIn: 'root',
})
export class UserDefaultRoutesService {
    private authService = inject(AuthService);
    private platformSettings = inject(PlatformSettingsService);
    private modulesMap$ = inject(ModulesResolver).modules$.pipe(
        map(modules =>
            modules.reduce<Record<string, Observable<boolean>>>((acc, { contextPath, isActive$ }) => {
                acc[contextPath] = isActive$.pipe(catchError(() => of(false)));

                return acc;
            }, {})
        ),
        publishReplay(1),
        refCount()
    );
    private availableDefaultRoutes$ = this.modulesMap$.pipe(
        switchMap(modulesMap => forkJoin(UserDefaultRoutes.map(route => modulesMap[route.route] ?? of(isStaticRoute(route))))),
        map(statuses => UserDefaultRoutes.filter((_, i) => statuses[i])),
        publishReplay(1),
        refCount()
    );

    getDefaultUserRoutes() {
        return this.availableDefaultRoutes$.pipe(map(routes => this.filterByAdminStatus(routes)));
    }

    getDefaultPlatformRoutes() {
        return this.availableDefaultRoutes$.pipe(map(routes => this.filterByNonAdminStatus(routes)));
    }

    getDefaultRoute({
        settings,
        checkPlatformRoute,
        checkUserRoute,
    }: {
        settings?: Record<string, unknown>;
        checkPlatformRoute?: boolean;
        checkUserRoute?: boolean;
    }) {
        const settings$ = settings ? of(settings) : this.platformSettings.getAllSettings();

        return combineLatest([settings$, this.modulesMap$.pipe(take(1))]).pipe(
            switchMap(async ([platformSettings, modulesMap]) => {
                const defaultRoutes = this.getDefaultUserAndPlatformRoute(platformSettings, checkPlatformRoute, checkUserRoute);

                for (const route of new Set(defaultRoutes.concat(ROUTE_PRIORITIES))) {
                    const status = await (modulesMap[route.route]?.toPromise() ?? Promise.resolve(isStaticRoute(route)));

                    if (status) {
                        return route;
                    }
                }

                return UserDefaultRoute.PROJECT;
            })
        );
    }

    private getDefaultUserAndPlatformRoute(settings: Record<string, unknown>, checkPlatformRoute: boolean, checkUserRoute: boolean) {
        const routes: IDefaultRoute[] = [];
        const defaultPlatformPage: string = settings[defaultLandingPagePropName] as string;
        const { defaultPage: userDefaultPage } = this.authService.getAuthenticatedUser().variables;

        if (checkUserRoute) {
            routes.push(this.checkDefaultRouteAvailability(userDefaultPage));
        }
        if (checkPlatformRoute) {
            routes.push(this.checkDefaultRouteAvailability(defaultPlatformPage));
        }

        return routes.filter(Boolean);
    }

    private checkDefaultRouteAvailability(routeId: unknown) {
        if (typeof routeId === 'number') {
            return UserDefaultRoutes.find(route => route.id === routeId);
        }

        if (typeof routeId === 'string') {
            return UserDefaultRoutes.find(route => route.route === routeId);
        }

        return null;
    }

    private filterByAdminStatus(routes: IDefaultRoute[]) {
        const isAdmin = this.authService.isAdmin();

        return routes.filter(({ adminOnly }) => isAdmin || !adminOnly);
    }

    private filterByNonAdminStatus(routes: IDefaultRoute[]) {
        return routes.filter(({ adminOnly }) => !adminOnly);
    }
}
