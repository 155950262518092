import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { from, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModalService } from '@dagility-ui/kit';

import { WidgetExtensionInnerViewContext } from 'data-processor/lib/extensions';
import { SharedComponentsModule } from '@dagility-ui/shared-components';
import { FilesAttacherModule } from '@app/shared/components/files-attacher/files-attacher.module';
import { CKEditorModalModule } from '@app/shared/components/ckeditor-modal/ckeditor-modal.module';
import { RecipientsEditorModule } from '@app/shared/modules/recipients-editor/recipients-editor.module';
import { ThresholdSettingsModalService } from './threshold-settings-modal/services/threshold-settings-modal.service';
import { ThresholdSettingsModalComponent } from './threshold-settings-modal/threshold-settings-modal.component';

import { AlertCardComponent } from './alert-card/alert-card.component';
import { EventConditionsComponent } from './threshold-settings-modal/event-conditions/event-conditions.component';
import { AlertListModalWrapperComponent } from './alert-list-modal-wrapper.component';

@Component({
    selector: 'app-alert-list-modal',
    templateUrl: './alert-list-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./alert-list-modal.component.scss'],
})
export class AlertListModalComponent {
    @Input() context: WidgetExtensionInnerViewContext = null;
    @Input() pipelineId: number;
    @Output() close = new EventEmitter();
    isLibrary: boolean = false;
    thresholds: any[];
    importanceTypes: any[];
    frequenciesTypes: any[];
    operatorTypes: any[];
    metrics: any[];
    selected: any[] = [];
    noThresholds: boolean = true;
    allAlerts: boolean = false;
    alertSearchText: string;
    alertsById: any[];
    isLoading: boolean = false;

    constructor(
        private service: ThresholdSettingsModalService,
        public modalService: ModalService,
        public modal: NgbActiveModal,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.isLoading = true;
        this.alertSearchText = null;
        zip(this.service.getImportance(), this.service.getFrequencies(), this.service.getConditions(), this.service.getMetrics()).subscribe(
            ([importance, frequency, operator, metric]) => {
                this.importanceTypes = importance;
                this.frequenciesTypes = frequency;
                this.operatorTypes = operator;
                this.metrics = metric;
                this.reloadAlertList();
            }
        );
    }

    onSelectAlert(alertId: number) {
        this.selected = this.selected.includes(alertId)
            ? this.selected.filter((id: number) => id !== alertId)
            : [...this.selected, alertId];
        this.allAlerts = this.selected.length === this.thresholds.length;
    }

    findSelected(alertId: number) {
        return this.selected.includes(alertId);
    }

    selectAllAlerts() {
        this.selected = this.thresholds.map((threshold: any) => threshold.id);
        if (this.allAlerts) {
            this.selected = [];
        }
        this.allAlerts = this.selected.length === this.thresholds.length;
    }

    onSave() {
        this.addRelation();
        this.closeLibrary();
        this.reloadAlertList();
    }

    onClose() {
        this.close.emit();
    }

    onAlertSearchInputChange() {
        this.loadThresholds();
    }

    getImportance(importance: string) {
        return this.importanceTypes.filter(code => code.code === importance)[0]?.description;
    }

    getFrequency(frequency: string) {
        return this.frequenciesTypes.filter(code => code.code === frequency)[0]?.description;
    }

    getOperatorName(operator: string) {
        return this.operatorTypes.filter(code => code.code === operator)[0]?.name || operator;
    }

    isMultiOperator(operator: string) {
        return this.operatorTypes.filter(code => code.code === operator)[0]?.multiple;
    }

    getMetric(metric: string) {
        return this.metrics.filter(code => code.code === metric)[0]?.name || metric;
    }

    deleteAlert(id: number) {
        if (this.isLibrary) {
            this.service.deleteSettings(id).subscribe(() => {
                this.reloadAlertList();
            });
            return;
        }
        if (this.context) {
            this.service.deleteRelation(id, this.context.widgetId).subscribe(() => {
                this.reloadAlertList();
            });
        } else {
            this.service.deleteAlertPipelineRelation(this.pipelineId, id).subscribe(() => {
                this.reloadAlertList();
            });
        }
    }

    createAlert() {
        const modalRef = this.modalService.open(
            ThresholdSettingsModalComponent,
            { centered: true, backdrop: 'static', size: 'lg' },
            { widgetTags: this.context?.options.tags || [], enableEventConditions: true, pipelineId: this.pipelineId }
        );
        modalRef.componentInstance.editAlertId = null;
        from(modalRef.result).subscribe(result => {
            this.addRelation(result.id);
        });
    }

    openLibrary() {
        this.isLibrary = true;
        this.isLoading = true;
        this.loadThresholds();
    }

    closeLibrary() {
        this.isLibrary = false;
    }

    editAlert(id: number) {
        const modalRef = this.modalService.open(
            ThresholdSettingsModalComponent,
            { centered: true, backdrop: 'static', size: 'lg' },
            { widgetTags: this.context?.options.tags || [], enableEventConditions: true }
        );
        modalRef.componentInstance.editAlertId = id;
        modalRef.closed.subscribe(() => {
            this.reloadAlertList();
        });
    }

    private reloadAlertList() {
        this.isLoading = true;
        if (this.isLibrary) {
            this.loadThresholds();
            return;
        }
        if (this.context) {
            this.loadWidgetThresholds();
        } else {
            this.loadPipelineAlerts();
        }
    }

    private loadThresholds() {
        this.service
            .getSettings(this.alertSearchText)
            .pipe(
                map(list => {
                    list.forEach((t: any) => (t.conditionParts = this.makeConditionArray(t.conditions)));
                    return list;
                })
            )
            .subscribe(thresholds => {
                this.thresholds = thresholds;
                this.isLoading = false;
                this.cdr.detectChanges();
            });
    }

    private loadWidgetThresholds() {
        this.service
            .getSettingsByWidgetId(this.context.widgetId)
            .pipe(
                map(list => {
                    list.forEach((t: any) => (t.conditionParts = this.makeConditionArray(t.conditions)));
                    return list;
                })
            )
            .subscribe(thresholds => {
                this.alertsById = thresholds;
                this.isLoading = false;
                this.cdr.detectChanges();
            });
    }

    private loadPipelineAlerts() {
        this.service.getAlertSettingsByPipelineId(this.pipelineId).subscribe(alerts => {
            this.alertsById = alerts;
            this.isLoading = false;
            this.cdr.detectChanges();
        });
    }

    private makeConditionArray(conditions: any): string[] {
        return conditions?.reduce(
            (acc: any[], condition: any) => [
                ...acc,
                ...[
                    { value: condition.logicOperator, op: true, display: condition.logicOperator !== null },
                    { value: this.getMetric(condition.metric), op: false, display: true },
                    { value: this.getOperatorName(condition.operator), op: false, display: true },
                    { value: condition.value[0], op: false, display: true },
                    { value: condition.value[1], op: false, display: this.isMultiOperator(condition.operator) },
                ],
            ],
            []
        );
    }

    private addRelation(id?: number) {
        const ids = id ? [id] : this.selected;
        if (this.context) {
            this.service
                .saveRelation({
                    widgetId: this.context.widgetId,
                    alertSettingsId: ids,
                })
                .subscribe(() => {
                    this.reloadAlertList();
                });
        } else {
            this.service.addAlertPipelineRelation(this.pipelineId, ids).subscribe(() => {
                this.reloadAlertList();
            });
        }
    }
}

@NgModule({
    imports: [SharedComponentsModule, PerfectScrollbarModule, FilesAttacherModule, CKEditorModalModule, RecipientsEditorModule],
    declarations: [
        AlertListModalWrapperComponent,
        AlertListModalComponent,
        ThresholdSettingsModalComponent,
        AlertCardComponent,
        EventConditionsComponent,
    ],
    exports: [ThresholdSettingsModalComponent, AlertCardComponent, AlertListModalComponent],
})
export class AlertListModalModule {}
