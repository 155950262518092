import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { PerfectScrollbarComponent, PerfectScrollbarConfig } from 'ngx-perfect-scrollbar';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { polling } from '@dagility-ui/kit';

import { JobsManagementService } from '@app/shared/components/pipeline-list/api/jobs-management.service';
import { ExtendJob, ProgressiveLogResponse } from '@app/shared/components/pipeline-list/model/jobs-management.classes';

const defaultCollapsedHeight = '40px';
const defaultExpandedHeight = '250px';
const CLICK_TIME = 400;
type BuilderType = 'pipeline' | 'workflow';

@Component({
    selector: 'app-progressive-log',
    templateUrl: './progressive-log.component.html',
    styleUrls: ['./progressive-log.component.scss'],
})
export class ProgressiveLogComponent implements OnInit, OnDestroy {
    @ViewChild(PerfectScrollbarComponent) scrollbar: PerfectScrollbarComponent;

    @Input() height: number;
    @Input() resizeItem: any;
    @Input() reload: Subject<any>;
    @Input() jobDetails: Observable<any>;
    @Input() mode: BuilderType = 'pipeline';
    @Input() lastPipelineWasPassed = false;

    @Output() resizedEmit = new EventEmitter();

    perfectScrollBarConfig: PerfectScrollbarConfig = new PerfectScrollbarConfig({
        wheelPropagation: false
    });

    collapsed = true;

    offset: number = 0;

    text: string = '';

    logs$: Observable<ProgressiveLogResponse> = new Observable<ProgressiveLogResponse>();

    runEnded$ = new Subject<boolean>();
    destroy$ = new Subject();

    collapsedHeight = defaultCollapsedHeight;

    job: ExtendJob = null;

    mouseDownTime: number;

    constructor(private apiService: JobsManagementService) {}

    ngOnInit(): void {
        this.jobDetails.pipe(takeUntil(this.destroy$)).subscribe(response => {
            if ((this.mode === 'pipeline' && !this.job) || this.mode === 'workflow') {
                if (response && response.buildId && response.toolId && response.project && response.jobName) {
                    this.job = {
                        buildNumber: response.buildId,
                        toolId: response.toolId,
                        project: response.project,
                        jobMessageProcessorType: 'PIPELINE',
                        name: response.jobName,
                    };

                    this.resizedEmit.emit();
                    this.logs$ = this.getPolling();
                }
            } else if (response && this.job && response.buildId > this.job.buildNumber) {
                this.job.buildNumber = response.buildId;
                this.reloadLogs();
            }
        });

        if (this.reload) {
            this.reload.pipe(takeUntil(this.destroy$)).subscribe(() => this.reloadLogs());
        }
    }

    getPolling() {
        return polling(1600, () => this.apiService.getProgressiveLog(this.getJobWithOffset(this.offset))).pipe(
            takeUntil(this.runEnded$),
            tap(response => {
                if (response.consoleLog) {
                    this.resizedEmit.emit();
                }

                this.text += response.consoleLog;
                this.offset = response.currentBufferSize;

                if (!response.hasMoreData) {
                    if (!response.consoleLog.includes('<title>Error 404 Not Found</title>')) {
                        this.runEnded$.next(true);
                    } else {
                        if (this.mode === 'pipeline' || this.lastPipelineWasPassed) {
                            this.text = '';
                            this.offset = 0;
                        }
                    }
                }
                setTimeout(() => {
                    if (this.text && this.scrollbar) {
                        this.scrollbar.directiveRef.scrollToBottom(0);
                    }
                });
            })
        );
    }

    onCollapsed(value: boolean) {
        this.resizeItem.style.height =
            value && this.resizeItem.style.height !== defaultCollapsedHeight ? defaultCollapsedHeight : defaultExpandedHeight;

        this.collapsed = value;
        this.resizedEmit.emit();
    }

    onMouseUp() {
        if (Date.now() - this.mouseDownTime < CLICK_TIME) {
            this.onCollapsed(true);
        }
    }

    onMouseDown() {
        this.mouseDownTime = Date.now();
    }

    reloadLogs() {
        this.text = '';
        this.offset = 0;
        this.runEnded$.next();
        this.runEnded$ = new Subject();
        this.logs$ = this.getPolling();
    }

    private getJobWithOffset(bufferOffset: number): ExtendJob & { bufferOffset: number } {
        return this.job
            ? Object.assign({}, this.job, {
                  bufferOffset,
              })
            : null;
    }

    ngOnDestroy() {
        this.runEnded$.next(true);
        this.destroy$.next();
    }
}
