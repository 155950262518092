import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnDestroy, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { isDefined } from '@dagility-ui/kit';
import { merge, Observable, of, Subject } from 'rxjs';
import { filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AnyWidgetStore } from 'data-processor/lib/widget-library/widget-builder/components/widget/any-widget/any-widget.store';
import { BaseWidgetFilter } from 'data-processor/lib/widget-library/widget-builder/components/widget/filters/base-widget-filter.component';
import { DropdownFilter, WidgetFilter, WidgetFilterType } from '../../../../models/any-widget.model';
import { expandedFilterFactory } from './expanded-filter.factory';

@Component({
    selector: 'dp-any-widget-filter-item',
    templateUrl: './any-widget-filter-item.component.html',
    styleUrls: ['./any-widget-filter-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnyWidgetFilterItemComponent implements OnDestroy {
    @Input() filter: WidgetFilter;

    @Input() form: FormGroup;

    @Input() expandedFilter: WidgetFilter;

    @Output() expandedFilterChange = new EventEmitter<WidgetFilter>();

    @Output() controlUpdated = new EventEmitter();

    @HostBinding('hidden') hidden = false;

    value$: Observable<any>;

    widgetFilterType = WidgetFilterType;

    hasOptions$: Observable<boolean>;

    private readonly destroyed$ = new Subject();

    constructor(private readonly store: AnyWidgetStore, private readonly cdRef: ChangeDetectorRef) {}

    ngOnInit() {
        const control = new FormControl();
        this.form.addControl(this.filter.placeholder, control);

        merge(this.store.workflow.placeholdersUpdated$)
            .pipe(
                tap((value: any) => {
                    if (control.value !== value[this.filter.placeholder]) {
                        control.setValue(value[this.filter.placeholder]);
                    }
                }),
                takeUntil(this.destroyed$)
            )
            .subscribe();

        this.hasOptions$ = of(this.filter).pipe(
            switchMap(() => {
                if (this.filter.type === WidgetFilterType.DROPDOWN) {
                    return this.store.debuggerState.dataSources[this.filter.placeholder].pipe(
                        filter(items => !items.type),
                        map(items => !!items?.length)
                    );
                }

                return of(true);
            })
        );
        this.value$ = control.valueChanges.pipe(
            startWith(control.value as unknown),
            switchMap(value => {
                switch (this.filter.type) {
                    case WidgetFilterType.DATE:
                        return of(BaseWidgetFilter.transformDate(value));
                    case WidgetFilterType.RANGE:
                    case WidgetFilterType.NEW_RANGE: {
                        if (isDefined(value?.startDate)) {
                            return of(`${BaseWidgetFilter.transformDate(value.startDate)}
                             - ${BaseWidgetFilter.transformDate(value.endDate)}`);
                        }
                        break;
                    }
                    case WidgetFilterType.INPUT:
                        return of(value);
                    case WidgetFilterType.DROPDOWN: {
                        return this.store.debuggerState.dataSources[this.filter.placeholder].pipe(
                            filter(items => !items.type),
                            map(items => {
                                if ((this.filter as DropdownFilter).multiple) {
                                    const arr = value && Array.isArray(value) ? value : value ? [value] : [];
                                    return arr
                                        .map((value: unknown) => {
                                            const item = items.find((item: { value: unknown }) => item.value == value);
                                            return item?.label;
                                        })
                                        .filter(Boolean);
                                }
                                const item = items.find((item: { value: unknown }) => item.value == value);
                                return item?.label;
                            })
                        );
                    }
                }

                return of(value);
            })
        );
        if (this.filter.type === WidgetFilterType.CHECKBOX) {
            control.valueChanges
                .pipe(
                    tap(value => this.controlUpdated.emit(value)),
                    takeUntil(this.destroyed$)
                )
                .subscribe();
        }
        this.listenVisibleState();
    }

    expandedFilterFactory = expandedFilterFactory;

    get isExpanded() {
        return this.expandedFilter === this.filter;
    }

    toggle() {
        if (this.filter.type === WidgetFilterType.CHECKBOX) {
            return;
        }
        this.expandedFilterChange.emit(this.expandedFilter === this.filter ? null : this.filter);
    }

    ngOnDestroy() {
        this.form.removeControl(this.filter.placeholder);
        this.destroyed$.next();
    }

    private listenVisibleState() {
        const dataSource = this.store.debuggerState;
        if (!dataSource) {
            return;
        }

        dataSource.filterVisibleState?.[this.filter.placeholder]?.pipe(takeUntil(this.destroyed$)).subscribe(isVisible => {
            this.hidden = !isVisible;
            this.cdRef.markForCheck();
        });
    }
}
