<div class="d-inline-flex w-100 align-items-center" [ngClass]="'mb-4 pl-1'">
    <h2 class="mt-0">Drilldown</h2>
    <button type="button" class="close ml-auto" (click)="handleCancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="d-flex flex-column overflow-hidden flex-1">
    <perfect-scrollbar>
        <div class="pl-1">
            <ng-container *ngIf="form" [formGroup]="form">
                <checkbox class="d-block mb-3" formControlName="default">Default drilldown</checkbox>
                <lib-dropdown
                    class="d-block mb-3"
                    label="Display Type"
                    placeholder="Select widget type"
                    formControlName="displayType"
                    [items]="displayType"
                ></lib-dropdown>
                <lib-input
                    class="d-block mb-3"
                    label="Drilldown Name"
                    placeholder="Drilldown name"
                    formControlName="drillDownName"
                ></lib-input>
                <lib-input
                    class="d-block mb-3"
                    label="Parameter Name"
                    placeholder="Parameter name"
                    formControlName="drillDownParamName"
                ></lib-input>
            </ng-container>
        </div>
    </perfect-scrollbar>

    <div class="d-flex align-items-center pt-3">
        <button *ngIf="canDelete" class="btn btn-secondary" (click)="handleDelete()">Delete</button>

        <button class="btn btn-secondary ml-auto mr-4" (click)="handleCancel()">Cancel</button>
        <button class="btn btn-primary" (click)="handleSave()">Save</button>
    </div>
</div>
