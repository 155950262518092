import { ValidatorFn, Validators, FormGroup, ValidationErrors, FormArray, FormControl } from '@angular/forms';
import 'reflect-metadata';

export function Validate(validators: ValidatorFn[]) {
    return function(target: any, propertyKey: string) {
        const rules = [...(Reflect.getMetadata('validators', target, propertyKey) || []), ...validators];
        Reflect.defineMetadata('validators', rules, target, propertyKey);
    };
}

export const Required = () => Validate([Validators.required]);

export const MapRequired = () => Validate([mapRequired]);

export function mapRequired(control: FormGroup): ValidationErrors {
    return Object.keys(control.controls).length ? null : { required: true };
}

export const NotEmpty = <T>(emptyPredicate: (value: T) => boolean) => (control: FormGroup | FormArray): ValidationErrors => {
    const controls = control instanceof FormGroup ? Object.values(control.controls) : control.controls;

    const error = controls.length && controls.map(c => c.value).every(emptyPredicate);
    return error ? { minOneNotEmpty: true } : null;
};

export const Min = (min: number) => (control: FormControl) => {
    return +control.value < min ? { min: { min: min, actual: control.value } } : null;
};

export const CollectionElementValidator = (validators: ValidatorFn[]) => (target: any, propertyKey: string) => {
    const rules = [...(Reflect.getMetadata('elementValidators', target, propertyKey) || []), ...validators];
    Reflect.defineMetadata('elementValidators', rules, target, propertyKey);
};

export function getValidatorsByField(target: any, propertyKey: string): ValidatorFn[] {
    return Reflect.getMetadata('validators', target, propertyKey) || [];
}

export function getValidatorsCollectionElement(target: any, propertyKey: string): ValidatorFn[] {
    return Reflect.getMetadata('elementValidators', target, propertyKey) || [];
}
