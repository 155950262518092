<div *ngIf="jenkinsFile$ | obsWithStatus | async as vm">
    <div *ngIf="vm.loading" class="d-inline-flex justify-content-center align-items-center w-100 h-100 pb-4 mt-15px">
        <lib-spinner></lib-spinner>
    </div>
    <perfect-scrollbar *ngIf="vm.value" class="generated-script scrollbar pb-4 bottom-border-radius"
                       [style.maxHeight.px]="height">
        <ng-container *ngIf="vm.value['script']; else noData">
            <div *ngIf="!codeMirror"
                 class="generated-script-content p-4 bottom-border-radius">
                {{ vm.value['script'] }}
            </div>
            <lib-codemirror-editor
                *ngIf="codeMirror"
                class="col mh-0 px-0"
                [mode]="'text/x-groovy'"
                [refresh$]="refresh$"
                [lineWrapping]="true"
                [readonly]="true"
                [(ngModel)]="vm.value['script']"
            ></lib-codemirror-editor>
        </ng-container>
    </perfect-scrollbar>

    <ng-container *ngIf="vm.value">
        <div class="modal-footer">
            <button class="btn btn-secondary btn-download pull-right"
                    (click)="downloadFile()">
                Download
            </button>
            <button class="btn btn-secondary btn-copy-to-clipboard ml-25px pull-right"
                    (click)="copyToClipboard()">
                Copy To Clipboard
            </button>
        </div>
    </ng-container>

    <ng-template #noData>
        <span class="ml-15px no-data-text">There are no data</span>
    </ng-template>
</div>
