<nav class="navbar navbar-expand-lg fixed-top app-header">
    <div
        ngbDropdown
        class="d-flex ml-4"
        [class.flex-row-reverse]="menuCollapsed"
        [tourAnchor]="getTourAnchorId('project')"
        hostQuerySelector="lib-navbar-menu .active"
    >
        <app-organizations-switcher class="my-auto mr-2"></app-organizations-switcher>
        <div class="app-header-logo">
            <a class="navbar-brand app-header-navbar" routerLink="/" *ngIf="!isCalibrate; else showCalibrateLogo"
                ><img
                    alt="image"
                    [src]="'assets/images/dagilityLogo' + (isDarkMode ? 'Dark.svg' : 'Light.svg') + '?q'"
                    tourAnchor="welcome"
                    class="logo"
            /></a>

            <ng-template #showCalibrateLogo>
                <a class="navbar-brand app-header-navbar" routerLink="/calibrate"
                    ><img alt="image" src="assets/images/calibrate-logo.svg" tourAnchor="welcome" class="logo"/></a
            ></ng-template>
        </div>

        <lib-navbar-menu
            class="header-menu"
            [class.mr-3]="menuCollapsed"
            [menuItems$]="headerMenuService.menuItems$"
            (collapsed$)="menuCollapsed = $event"
            [tourAnchor]="getTourAnchorId('illuminate')"
            hostQuerySelector=".active"
        >
            <ng-template navbarSpecialItem let-item>
                <a
                    *ngIf="item.active$ ? (item.active$ | async) : true"
                    [href]="item.link ? (item.link | async) : ''"
                    [routerLinkActive]="['active']"
                    class="nav-link nav-link-with-icon"
                >
                    <div class="d-flex flex-column">
                        <div class="row no-gutters justify-content-center align-content-center" [style.height.px]="22">
                            <fa-icon *ngIf="item.icon" class="fa-icon" [icon]="item.icon"></fa-icon>
                            <span *ngIf="item.image && !item.icon" class="header-item-image" [innerHTML]="item.image | safeHtml: 'html'">
                            </span>
                        </div>
                        <div class="row no-gutters" [style.margin-top.px]="2">
                            <span>{{ item.label }}</span>
                        </div>
                    </div>
                </a>
            </ng-template>
        </lib-navbar-menu>
    </div>

    <div class="form-inline ml-auto">
        <ng-template #approvalsInfoPanelRef></ng-template>
        <span tourAnchor="end-of-tour">
            <lib-context-menu [menuItems]="helpMenuItems" [style.height.px]="18" class="mr-10px">
                <ng-template buttonTemplate>
                    <fa-icon class="fa-icon right-block-icon help" icon="QuestionInCircle"></fa-icon>
                </ng-template>
            </lib-context-menu>
        </span>

        <span tourAnchor="my-settings">
            <lib-context-menu [menuItems]="userSettingMenuItems" class="user-pic mr-10px">
                <ng-template buttonTemplate>
                    <lib-user-pic *ngIf="user" [user]="user"></lib-user-pic>
                </ng-template>

                <ng-template specialItems>
                    <a class="dropdown-item cursor-pointer" (click)="logout()">
                        <fa-icon class="fa-icon" [icon]="icons.facLogout"></fa-icon>
                        <div class="item-text">
                            Logout
                        </div>
                    </a>
                </ng-template>
            </lib-context-menu>
        </span>
        <div *ngIf="organizationLogo.imagePath" class="mr-2">
            <app-organization-logo class="organization-logo" [Organization]="organizationLogo" [size]="40"></app-organization-logo>
        </div>
    </div>
</nav>
