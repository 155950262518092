import { ChangeDetectorRef, EventEmitter, Injectable } from "@angular/core";
import {
    SREGanttData,
    SREGanttOptions,
    SREGanttTable,
    SREGanttValue,
    SREGanttValueWithUnitName
} from "../models/sre-gantt.model";
import { SidenavItem, SidenavItemGroup, SidenavItems } from "@dagility-ui/kit";
import { BehaviorSubject } from "rxjs";
import {
    CustomTooltipComponent
} from "data-processor/lib/widget-library/widget-builder/components/widget/any-widget/custom-tooltip.component";
import {
    CellCustomComponent
} from "data-processor/lib/widget-library/widget-builder/components/custom-components/cell-custom/cell-custom.component";

const DEFAULT_COLUMN_DEF: any = {
    tooltipComponentFramework: CustomTooltipComponent,
    cellRendererFramework: CellCustomComponent,
    cellRendererParams: { cellRender: null, cellTooltipTemplate: undefined, placeholders: {} },
};

const TAG_COLUMN_DEFS: any = [
    {
        headerName: 'Tag',
        field: 'tag',
        tooltipField: 'tag',
        ...DEFAULT_COLUMN_DEF,
    },
    {
        headerName: 'Text',
        field: 'text',
        tooltipField: 'text',
        ...DEFAULT_COLUMN_DEF,
    },
];

const RELEASE_COLUMN_DEFS: any = [
    {
        headerName: 'Release',
        field: 'release',
        tooltipField: 'tag',
        ...DEFAULT_COLUMN_DEF,
    },
    {
        headerName: 'Text',
        field: 'releaseText',
        tooltipField: 'releaseText',
        ...DEFAULT_COLUMN_DEF,
    },
];

const VALUE_HEIGHT = 48;
const MARGIN_TOP = 24; // 1.5rem

@Injectable()
export class SreGanttService {
    tableData$ = new BehaviorSubject<SREGanttTable>(null);
    values$ = new BehaviorSubject<SREGanttValue[]>([]);
    drilldownEvent$ = new EventEmitter();
    selectedValue: SREGanttValue;

    // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
    private _minimum = 0;
    // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
    private _maximum = 0;
    // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
    private _maximumDuration = 0;
    // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
    private _values: SREGanttValue[] = [];
    // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
    private _tree: SidenavItems = [];
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _wrapperHeight: number = 0;
    private columnDefs = [[...TAG_COLUMN_DEFS], [...RELEASE_COLUMN_DEFS]];
    private filteredGroups: string[] = [];
    private selectedTab: number = 0;

    private _hideSlideSelector = false;

    set minimum(value: number) {
        this._minimum = value;
    }

    get minimum() {
        return this._minimum;
    }

    set maximum(value: number) {
        this._maximum = value;
    }

    get maximum(): number {
        return this._maximum;
    }

    set maximumDuration(value: number) {
        this._maximumDuration = value;
    }

    set values(value: SREGanttValue[]) {
        this._values = value;
    }

    set hideSlideSelector(value: boolean) {
        this._hideSlideSelector = value;
    }

    get values(): SREGanttValue[] {
        return this._values;
    }

    set tree(value: SidenavItems) {
        this._tree = value;
    }

    get tree(): SidenavItems {
        return this._tree;
    }

    get wrapperHeight(): number {
        return this._wrapperHeight;
    }

    get hideSlideSelector() {
        return this._hideSlideSelector;
    }

    constructor(private cdr: ChangeDetectorRef) {}

    init({ minimum, maximum, maximumDuration, data, hideSlideSelector, customColumnDefs }: SREGanttOptions) {
        this.minimum = minimum;
        this.maximum = maximum;
        this.maximumDuration = maximumDuration;

        this.initValues(data);
        this.initTree(data);
        this.initWrapperHeight();

        this.hideSlideSelector = hideSlideSelector;

        if (customColumnDefs) {
            this.initCustomColumnDefs(customColumnDefs);
        }

        this.values$.next(this.values);
    }

    private initValues(data: SREGanttData[]) {
        data.forEach(item => {
            this.values.push({ ...item.value, title: item.title });
            if (item.children) {
                this.initValues(item.children);
            }
        });
    }

    private initTree(data: SREGanttData[]) {
        this.tree = [
            {
                title: '',
                routerLink: null,
                items: data.map(item => ({
                    id: item.value.id,
                    title: item.title,
                    routerLink: null,
                    children: this.createTreeChildren(item.children),
                })),
            },
        ];
    }

    private createTreeChildren(children: SREGanttData[]): SidenavItem[] {
        return children?.map((child: SREGanttData) => ({
            id: child.value.id,
            title: child.title,
            routerLink: null,
            children: (child.children || []).length ? this.createTreeChildren(child.children) : null,
            withoutRouterLink: !child.children || !child.children.length,
            sidenavParent: child.value.parentId,
            sidenavParentId: child.value.parentId,
        }));
    }

    private initWrapperHeight() {
        this._wrapperHeight = this.values.length * VALUE_HEIGHT + MARGIN_TOP;
    }

    private initCustomColumnDefs(customColumnDefs: any[]) {
        this.columnDefs = [customColumnDefs.map(columnDef => ({ ...columnDef, ...DEFAULT_COLUMN_DEF }))];
    }

    getValueWithUnitName(value: number): SREGanttValueWithUnitName {
        return value / 1000 < 1
            ? {
                  value: value,
                  unitName: 'µs',
              }
            : value / 1000 / 1000 < 1
            ? {
                  value: value / 1000,
                  unitName: 'ms',
              }
            : {
                  value: value / 1000 / 1000,
                  unitName: 's',
              };
    }

    changeTab(selectedTab: number) {
        this.selectedTab = selectedTab;
        this.changeTableData();
    }

    handleItemClicked(item: SidenavItem) {
        this.updateSelectedValue(this.values.find(val => val.title === item.title && val.parentTitle === item.sidenavParent));
    }

    updateSelectedValue(value: SREGanttValue) {
        this.selectedValue = value;
        this.changeTableData();

        setTimeout(() => this.cdr.detectChanges());
    }

    changeTableData() {
        this.tableData$.next({
            columnDefs: this.columnDefs[this.selectedTab],
            rowData: this.selectedValue?.tableData || [],
        });
    }

    handleGroupClicked(group: SidenavItemGroup & { parentId: string }) {
        const filterChildren = (id: string) => {
            group.isCollapsed !== false
                ? this.filteredGroups.push(id)
                : (this.filteredGroups = this.filteredGroups.filter(group => group !== id));

            this.values.filter(val => val.parentId === id).forEach(val => filterChildren(val.id));
        };

        filterChildren(group.id);

        this.values$.next(this.values.filter(value => !this.filteredGroups.includes(value.parentId)));
    }

    openLogs() {
        this.drilldownEvent$.next(this.selectedValue);
    }
}
