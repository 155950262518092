import { inject, Injectable } from '@angular/core';
import { ENV_TOKEN } from '@app/tokens';
import { HttpClient } from '@angular/common/http';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class OrganizationsService {
    private readonly env = inject(ENV_TOKEN);
    private readonly baseUrl = `${this.env.adminURL}/api/global/organizations`;
    private readonly http = inject(HttpClient);

    getUserOrganizations(): Observable<OrganizationShort[]> {
        return this.http.get<OrganizationShort[]>(this.baseUrl);
    }

    setCurrentOrganization(id: number): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}/set/${id}`, null);
    }

    create(organization: Omit<CreateOrganizationDto, 'id'>): Observable<CreateOrganizationDto> {
        return timer(5000).pipe(
            map(() => ({
                id: 1,
                ...organization,
            }))
        );
    }
}

export type CreateOrganizationDto = Pick<OrganizationDto, 'id' | 'name' | 'description'>;

export type OrganizationShort = Pick<OrganizationDto, 'id' | 'name' | 'current'>;

export interface OrganizationDto {
    id: number;
    name: string;
    owner: string;
    created: number;
    lastAccessed: number;
    role: string;
    totalUsers: number;
    description: string;
    current: boolean;
    readonly: boolean;
    active: boolean;
}
