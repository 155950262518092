import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CodeScanTool, ComponentDetails, ComponentShared, ProjectComponent, ScmTool } from './models/project-component-items';
import { JobResponse } from '@app/shared/components/pipeline-list/model/jobs-management.classes';
import { ICreateProjectByTemplate } from '../../../ci-cd/api/ci-cd-template.service';
import { CiCdProperty, IToolsChoosingResult } from '@dagility-ui/shared-components';
import { ENV_TOKEN } from '@app/tokens';

@Injectable({
    providedIn: 'root'
})
export class ProjectComponentsService {
    baseUrl = this.env.buildApiURL + '/v2/projects/';
    adminUrl = this.env.adminApiURL + '/v2/projects/';

    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {}

    getComponents(projectId: number): Observable<ProjectComponent[]> {
        return this.http.get<ProjectComponent[]>(`${this.adminUrl}${projectId}/components`);
    }

    getComponentDetails(projectId: number, componentId: number): Observable<ComponentDetails> {
        return this.http.get<ComponentDetails>(`${this.adminUrl}${projectId}/components/${componentId}/details`);
    }

    saveComponentDetails(projectId: number, componentId: number, componentDetails: ComponentDetails): Observable<void> {
        return this.http.post<void>(`${this.adminUrl}${projectId}/components/${componentId}/details`, componentDetails);
    }

    deleteComponent(projectId: number, componentId: number): Observable<void> {
        return this.http.delete<void>(`${this.adminUrl}${projectId}/components/${componentId}`);
    }

    copyComponent(projectId: number, componentId: number): Observable<ProjectComponent> {
        return this.http.post<ProjectComponent>(`${this.adminUrl}${projectId}/components/${componentId}/copy`, { id: componentId });
    }

    getComponentCodeScan(projectId: number, componentId: number): Observable<CodeScanTool[]> {
        return this.http.get<CodeScanTool[]>(`${this.adminUrl}${projectId}/components/${componentId}/codescan`);
    }

    getComponentSCM(projectId: number, componentId: number): Observable<ScmTool[]> {
        return this.http.get<ScmTool[]>(`${this.adminUrl}${projectId}/components/${componentId}/scm`);
    }

    saveComponentCodeScan(projectId: number, componentId: number, componentTools: any): Observable<void> {
        return this.http.post<void>(`${this.adminUrl}${projectId}/components/${componentId}/codescan`, componentTools);
    }

    saveComponentSCM(projectId: number, componentId: number, componentTools: IToolsChoosingResult[]): Observable<void> {
        return this.http.post<void>(`${this.adminUrl}${projectId}/components/${componentId}/scm`, componentTools);
    }

    getComponentRepoUrl(projectId: number, componentId: number): Observable<string> {
        return this.http.get<string>(`${this.baseUrl}${projectId}/components/${componentId}/repoUrl`, { responseType: 'text' as 'json' });
    }

    addNewComponent(projectId: number, newComponent: ComponentDetails): Observable<number> {
        return this.http.post<number>(`${this.adminUrl}${projectId}/components/new`, newComponent);
    }

    getComponentProperties(projectId: number, componentId: number): Observable<CiCdProperty[]> {
        return this.http.get<CiCdProperty[]>(`${this.adminUrl}${projectId}/components/${componentId}/properties`);
    }

    updateComponentProperties(projectId: number, componentId: number, properties: CiCdProperty[]): Observable<void> {
        return this.http.put<void>(`${this.adminUrl}${projectId}/components/${componentId}/properties`, properties);
    }

    generateSourceCodeTemplate(componentId: number, action: string, commit: string) {
        let params = new HttpParams();
        if (commit && commit.length) {
            params = params.append('commit', commit);
        }
        return this.http.get(`${this.env.buildApiURL}/ci-cd/app-sources/components/${componentId}/generate/${action}`, { responseType: 'blob', params });
    }

    getPipelines(projectId: number, componentId: number): Observable<JobResponse> {
        return this.http.get<any>(`${this.adminUrl}${projectId}/components/${componentId}/pipelines`);
    }

    addNewPipelines(projectId: number, componentId: number, jobs: number[]): Observable<void> {
        return this.http.post<void>(`${this.adminUrl}${projectId}/components/${componentId}/pipelines`, jobs);
    }

    getComponentRepositoryUrl(componentId: number, unit: any): Observable<string> {
        return this.http.post<string>(`${this.env.buildApiURL}/ci-cd/componentRepository/${componentId}`, unit, { responseType: 'text' as 'json' });
    }

    getComponentsRepositoryUrls(project: ICreateProjectByTemplate): Observable<NumberMap<string>> {
        return this.http.post<NumberMap<string>>(`${this.env.buildApiURL}/ci-cd/repositoryUrl`, project);
    }

    getComponentSharedList(projectId: number, componentId: number): Observable<ComponentShared[]> {
        return this.http.get<ComponentShared[]>(`${this.adminUrl}${projectId}/components/${componentId}/shared`);
    }
}
