import { AfterViewInit, Component, Directive, ElementRef, HostBinding, inject, Input, OnDestroy } from '@angular/core';
import round from 'lodash/round';

import { DropdownItem, ResizeObserverService } from '@dagility-ui/kit';
import { WidgetTile } from 'data-processor';
import { defer, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

interface BreakdownDetail extends DropdownItem<unknown> {
    color: string;
}

type WithTooltip<T> = T & Partial<{ tooltip: Partial<{ title: string; items: BreakdownDetail[] }> }>;

function extractValueAndSuffix(data: unknown): [number | string, string] {
    let correctedValue: number | string;
    let suffix: string;

    if (typeof data === 'string') {
        correctedValue = parseFloat(data);

        if (Number.isNaN(correctedValue)) {
            correctedValue = data;
        } else {
            suffix = data.replace(/^[\-+]?[0-9]*(\.[0-9]+)?/g, '');
        }
    } else if (typeof data === 'number') {
        correctedValue = data;
    } else {
        correctedValue = data.toString();
    }

    return [correctedValue, suffix];
}

@Component({
    selector: 'dp-metric-tile',
    templateUrl: './metric-tile.component.html',
    styleUrls: ['./metric-tile.component.scss'],
    providers: [ResizeObserverService],
})
export class MetricTileComponent {
    @Input() data: {
        tile: WithTooltip<WidgetTile>;
        details: WithTooltip<DropdownItem<string | number>>[];
        // @deprecated
        tooltip?: BreakdownDetail[];
    };

    private readonly elementRef: ElementRef<HTMLElement> = inject(ElementRef);
    private readonly resizeObserver = inject(ResizeObserverService);
    readonly metricWidth$ = defer(() => {
        const tile = this.elementRef.nativeElement.querySelector('button');
        const postfix = tile.querySelector('.tile-body > div');

        return this.resizeObserver.observe$(new ElementRef<HTMLElement>(tile)).pipe(
            startWith(null as {}),
            map(() => {
                return tile.clientWidth - (postfix?.clientWidth ?? 0) - 1;
            })
        );
    });

    formatMetric(value: unknown, customMapper: (value: number) => number = x => x) {
        let [correctedValue, suffix] = extractValueAndSuffix(value);

        if (typeof correctedValue === 'number') {
            correctedValue = customMapper(correctedValue);

            const sign = Math.sign(correctedValue) === -1 ? '-' : '';
            const power = Math.abs(correctedValue / 1000);

            if (power > 99) {
                correctedValue = `${sign}99K+`;
            } else {
                let formattedValue = sign;

                if (power >= 1) {
                    formattedValue += `${Math.trunc(power)}K`;

                    if (power % 1 !== 0) {
                        formattedValue += '+';
                    }
                } else {
                    formattedValue = round(correctedValue, 1).toString();
                }

                correctedValue = formattedValue;
            }
        }

        return `${correctedValue.toString()}${suffix ?? ''}`;
    }

    formatDelta = (value: unknown) => {
        return this.formatMetric(value);
    };

    formatMetricTooltip(data: unknown) {
        let [value, suffix] = extractValueAndSuffix(data);

        return `${typeof value === 'number' ? round(value, 2) : value}${suffix ?? ''}`;
    }

    disableTooltip(data: WithTooltip<unknown>) {
        return !(data?.tooltip && (data.tooltip.title || data.tooltip.items?.length));
    }

    isMetricTooltip(data: unknown): WithTooltip<unknown>['tooltip'] {
        return data;
    }
}

@Directive({
    selector: '[full-text-width]',
    providers: [ResizeObserverService],
})
export class FullTextWidth implements AfterViewInit, OnDestroy {
    @Input() min = 12;
    @Input() max = 52;

    @Input() availableWidth$ = this.resizeObserver.observe$(this.elementRef).pipe(map(() => this.elementRef.nativeElement.offsetWidth));

    private destroy$ = new Subject<void>();

    constructor(private resizeObserver: ResizeObserverService, private elementRef: ElementRef<HTMLElement>) {}

    ngAfterViewInit() {
        const canvas = document.createElement('canvas');
        const fontFamily = getComputedStyle(this.elementRef.nativeElement).getPropertyValue('font-family');
        const context = canvas.getContext('2d');
        const text = this.elementRef.nativeElement.textContent;

        this.availableWidth$.pipe(takeUntil(this.destroy$)).subscribe(width => {
            let current = this.min + (this.max - this.min) / 2;
            let { max, min } = this;
            let i = 0;

            while (max !== min && i++ < 10) {
                context.font = `${current}px ${fontFamily}`;
                const mwidth = context.measureText(text).width;

                if (mwidth < width) {
                    min = current;
                } else {
                    max = current;
                }

                current = min + (max - min) / 2;
            }

            this.elementRef.nativeElement.style.fontSize = `${current}px`;
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
    }
}
