import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { Packer } from './services/packer';
import { PackerBounds, PackerElement, PackerPosition } from './models/packer.model';
import { createSREHexagonElement, createSREHexagonElementWExtScoring } from './services/sre-hexagon.util';
import ResizeObserver from 'resize-observer-polyfill';
import { EventEmitter } from '@angular/core';
import { Hexagon } from 'data-processor/lib/widget-library/widget-builder/components/sre-components/sre-hexagons/models/hexagon.model';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const MIN_ALLOWED_RADIUS = 25;
const MAX_ALLOWED_RADIUS = 70;

@Component({
    selector: 'dp-sre-hexagons',
    templateUrl: './sre-hexagons.component.html',
    styleUrls: ['./sre-hexagons.component.scss'],
})
export class SreHexagonsComponent implements OnInit, OnDestroy {
    @Input() hexagons: Hexagon[] = [];
    @Input() bounds: { average: number; bad: number } = { average: 60, bad: 75 };
    @Input() invertColors = false;
    @Input() externalScoring = false;
    @Input() externalBounds: { label: string, color: string, value: number, id: number}[]

    @HostBinding('class.flex-column') get isFlexCol() {
        return this.externalScoring
    }

    @Output() hexagonClicked = new EventEmitter();

    @ViewChild('hexagonContainer', { static: true }) hexagonContainer: ElementRef;
    @ViewChild('hexagonTooltip', { static: false }) hexagonTooltip: ElementRef;

    tooltip: boolean;

    private elementsMap: Map<string, Element> = new Map();
    private packer: Packer;
    private resize$: ResizeObserver;
    private destroy$ = new Subject<void>();
    private noUniqueValues = false;

    constructor(private elRef: ElementRef, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.initPacker();

        this.resize$ = new ResizeObserver(this.onResize.bind(this));
        this.resize$.observe(this.hexagonContainer.nativeElement);
    }

    onResize() {
        const rect: DOMRect = this.elRef.nativeElement.getBoundingClientRect();
        const bounds: PackerBounds = { width: rect.width, height: rect.height };
        const target: PackerPosition = { x: bounds.width / 2, y: bounds.height / 2 };

        this.packer.setBounds(bounds);
        this.packer.setTarget(target);

        this.packer.update();
    }

    initPacker() {
        const rect: DOMRect = this.elRef.nativeElement.getBoundingClientRect();
        const bounds: PackerBounds = { width: rect.width, height: rect.height };
        const target: PackerPosition = { x: bounds.width / 2, y: bounds.height / 2 };

        const values = this.hexagons.map(hexagon => hexagon.value);
        this.noUniqueValues = values.filter((item, i, array) => array.indexOf(item) === i).length === 1;

        if (this.noUniqueValues) {
            const newValue = values[0] + 1;
            this.hexagons.push({ value: newValue, label: this.hexagons[0].label });
            values.push(newValue);
        }

        const maxRangeRadius = Math.max(...values);
        const minRangeRadius = Math.min(...values);

        const elements: PackerElement[] = [];

        for (let i = 0; i < this.hexagons.length; i++) {
            elements.push(
                this.createElement(
                    this.scaleBetween(
                        this.hexagons[i].value,
                        values.length <= 10 ? MIN_ALLOWED_RADIUS + 20 : MIN_ALLOWED_RADIUS,
                        MAX_ALLOWED_RADIUS - values.length,
                        minRangeRadius,
                        maxRangeRadius
                    ),
                    this.hexagons[i],
                    bounds,
                    i
                )
            )
        }

        this.packer = new Packer({
            bounds: bounds,
            target: target,
            elements: elements,
            onMove: this.render(this.elementsMap),
            collisionPasses: 30,
            centeringPasses: 200,
        });
    }

    createElement(radius: number, value: Hexagon, bounds: PackerBounds, index: number) {
        const id = 'element-' + this.random(0, 1000) + '-' + Date.now();

        const element: PackerElement = {
            id: id,
            radius: radius,
            position: {
                x: this.random(radius, bounds.width - radius),
                y: this.random(radius, bounds.height - radius),
            },
        };

        this.addElementToHexagonContainer(id, radius, value, index);

        return element;
    }

    random(min: number, max: number) {
        return Math.floor(Math.random() * (max - min) + min);
    }

    addElementToHexagonContainer(id: string, radius: number, hexagon: Hexagon, index: number) {
        const diameter = radius * 2;
        const element = this.externalScoring
            ? createSREHexagonElementWExtScoring(id, diameter, hexagon.value, hexagon.label || '', this.externalBounds, index)
            : createSREHexagonElement(id, diameter, hexagon.value, hexagon.label || '', this.bounds, this.invertColors);

        if (this.noUniqueValues && hexagon.value === this.hexagons[0].value + 1) {
            element.classList.add('fake-element');
        }

        this.addEvents(element, hexagon, hexagon.tooltip);
        this.hexagonContainer.nativeElement.appendChild(element);

        this.elementsMap.set(id, element);
    }

    addEvents(element: HTMLElement, value: Hexagon, tooltip?: string) {
        fromEvent(element, 'click')
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.hexagonClicked.next(value));

        if (!tooltip) {
            return;
        }

        const hexagon = element.getElementsByClassName('hexagon').item(0) as HTMLElement;

        fromEvent(hexagon, 'mouseover')
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.showTooltip(hexagon, tooltip));

        fromEvent(hexagon, 'mouseout')
            .pipe(takeUntil(this.destroy$))
            .subscribe(event => this.hideTooltip(event));
    }

    showTooltip(element: HTMLElement, tooltip: string) {
        this.tooltip = true;
        this.cdr.detectChanges();

        setTimeout(() => {
            if (!this.hexagonTooltip) {
                return;
            }

            this.hexagonTooltip.nativeElement.innerHTML = tooltip;
            const { left, top } = element.getBoundingClientRect();
            const { width, height } = this.hexagonTooltip.nativeElement.getBoundingClientRect();

            this.hexagonTooltip.nativeElement.style.left = `${left - width / 4}px`;
            this.hexagonTooltip.nativeElement.style.top = `${top + 0.75 * height}px`;

            const copyEl = this.hexagonTooltip.nativeElement;
            (this.hexagonTooltip.nativeElement as HTMLElement).parentElement.removeChild(this.hexagonTooltip.nativeElement);
            document.body.appendChild(copyEl);
        });
    }

    hideTooltip({ toElement }: any) {
        if (
            (toElement as HTMLElement)?.classList.contains('hexagon-tooltip') ||
            (toElement as HTMLElement)?.classList.contains('hexagon')
        ) {
            return;
        }

        this.tooltip = false;
        this.cdr.detectChanges();
    }

    scaleBetween(unscaledNum: number, minAllowed: number, maxAllowed: number, min: number, max: number) {
        // need to make different radius if a lot of elements
        if (maxAllowed < minAllowed + 5) {
            maxAllowed = minAllowed + 5;
        }

        return ((maxAllowed - minAllowed) * (unscaledNum - min)) / (max - min) + minAllowed;
    }

    render = (elementMap: Map<string, any>) => (elements: any) => {
        requestAnimationFrame(() => {
            Object.keys(elements).forEach((id: string) => {
                const domElement = elementMap.get(id);

                if (!domElement) {
                    return;
                }

                const element = elements[id];
                const x = element.position.x - element.radius;
                const y = element.position.y - element.radius;

                domElement.style.transform = 'translateX(' + x + 'px) translateY(' + y + 'px)';

                setTimeout(() => {
                    domElement.classList.add('is-visible');
                }, 75);
            });
        });
    };

    ngOnDestroy() {
        this.resize$?.unobserve(this.hexagonContainer.nativeElement);
        this.packer.destroy();
        this.destroy$.next();

        const tooltipEls: any = document.getElementsByClassName('hexagon-tooltip');
        [...tooltipEls].forEach(el => el.parentElement.removeChild(el));
    }
}
