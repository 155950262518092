<ng-template #filterTemplate>
    <fa-icon
        icon="facFilter"
        class="mr-2 position-relative"
        data-test-id="filter-icon"
        [class.filter-in-use]="dpAnyWidget?.filterWrapperComp?.isDefaultValueDirty$ | async"
    ></fa-icon>
    Filters
</ng-template>
<ng-container *ngIf="vm$ | async as vm">
    <div class="flex-1 d-flex flex-column container-width min-width-0" keepni-path-type="WIDGET" [attr.keepni-path-name]="title$ | async">
        <ng-template #widgetMenu>
            <ng-container *ngIf="treeMenuItems$ | async as treeMenuItem">
                <lib-treelike-menu
                    *ngIf="treeMenuItem.length"
                    class="action-item manage-widget-button"
                    [treeItems]="treeMenuItem"
                    [openIconStyles]="{
                        color: 'var(--da-brand-base)'
                    }"
                    btnClasses="border-0 btn-secondary"
                    [closeOnClick]="true"
                    [closeOnScroll]="true"
                >
                </lib-treelike-menu>
                <span *ngIf="(logger.empty$ | async) === false" class="error-log-indicator"></span>
            </ng-container>
        </ng-template>
        <div
            *ngIf="separatedView && !showingOnlyFiltersBlock"
            class="any-widget-header w-100 align-items-center px-4 py-3"
            [ngClass]="vm.widget && vm.widget.chartOptions?.hideTitle && !edit ? 'd-none' : 'd-inline-flex'"
            [class.border-bottom]="separatedView"
        >
            <fa-icon
                wc-event-skip
                *ngIf="!store.isRoot()"
                class="mr-3 cursor-pointer p-2"
                icon="facArrowLeftRegular"
                (click)="back()"
            ></fa-icon>
            <div class="d-flex flex-row w-100">
                <div *ngIf="title$ | async as title" class="d-flex flex-column chart-header">
                    <div class="d-flex align-items-center">
                        <h3
                            #widgetTitle
                            class="mt-0 chart-header__title"
                            [ngClass]="{ 'color-red': (logger.empty$ | async) === false }"
                            [innerHTML]="parseLoadingStr(title) | dpWidgetTemplate: placeholders"
                        ></h3>
                    </div>

                    <ng-container *ngIf="!(hideDescription$ | async)">
                        <span
                            *ngIf="description$ | async as description"
                            class="body2 chart-header__description"
                            [innerHTML]="parseLoadingStr(description) | dpWidgetTemplate: placeholders"
                        ></span>
                    </ng-container>
                </div>
            </div>
            <div
                wc-event-skip
                #actionsRef
                class="ml-auto d-flex align-items-center actions-set"
                ngbDropdown
                [container]="'body'"
                #dropdown="ngbDropdown"
            >
                <div class="actions-menu">
                    <ng-container *ngIf="extensionsEnabled && widgetId">
                        <ng-container *ngFor="let extension of extensions">
                            <button
                                *ngIf="extensionsMap[extension.extensionId]"
                                class="action-item btn btn-link default-size px-1 widget-button"
                                (click)="handleOpenExtension(extension)"
                                [class.d-none]="extension.extensionId === 'THRESHOLD_SETTINGS'"
                            >
                                <fa-icon *ngIf="!extension.viewIconIsPlainSVG" class="font-16px" [icon]="extension.viewIcon"> </fa-icon>

                                <div *ngIf="extension.viewIconIsPlainSVG" [innerHtml]="extension.viewIcon | safeHtml: 'html'"></div>
                            </button>
                        </ng-container>
                    </ng-container>

                    <ng-template [ngTemplateOutlet]="actionsTemplate" [ngTemplateOutletContext]="{ $implicit: this, vm: vm }"></ng-template>
                    <div *ngIf="store.workflow?.isFunnelIconEnabled || isSmallWidget" class="action-item" [class.action-item--hidden]="isSmallWidget">
                        <ng-template [cdkPortalOutlet]="filtersPortal"></ng-template>
                    </div>

                    <ng-template [ngTemplateOutlet]="widgetMenu" [ngTemplateOutletContext]="{ $implicit: this, vm: vm }"></ng-template>
                    <button
                        *ngIf="closeButton"
                        type="button"
                        class="action-item default-size close m-0 p-0"
                        aria-label="Close button"
                        aria-describedby="modal-title"
                        (click)="activeModal.close()"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        </div>

        <ng-container *ngIf="!separatedView && !showingOnlyFiltersBlock">
            <fa-icon
                wc-event-skip
                *ngIf="!(logger.empty$ | async)"
                class="cursor-pointer mr-3 font-14px show-widget-error-log-icon"
                icon="facLogs"
                ngbTooltip="Show error log"
                container="body"
                (click)="handleShowErrorLogs()"
            ></fa-icon>
        </ng-container>

        <dp-any-widget
            *ngIf="store.widgetIsValid; else widgetIsNotConfiguredTmpl"
            #widget
            class="widget"
            [triggerAll]="triggerAll"
            [options]="vm.widget"
            [isSmallWidget]="isSmallWidget"
            [isSmallWidgetWithoutFilter]="isSmallWidgetWithoutFilter"
            [separatedView]="separatedView"
            [previewMode]="previewMode"
            (openModal)="openModal($event)"
            (drilldown)="handleDrilldown($event)"
            (storeAfterLoad)="storeAfterLoad.emit($event)"
            [showingOnlyFiltersBlock]="showingOnlyFiltersBlock"
            (initPortal)="initFiltersPortal($event)"
        ></dp-any-widget>

        <div wc-event-skip class="cursor-default" *ngIf="activeExtension && !showingOnlyFiltersBlock">
            <ng-container *dpComponentOutlet="activeExtension.view; context: extensionContext(activeExtension)"></ng-container>
        </div>
    </div>

    <dp-widget-context-help
        wc-event-skip
        *ngIf="showHelp && !showingOnlyFiltersBlock"
        class="col-5 cursor-default"
        [help]="vm.widget.help"
        (close)="showHelp = false"
    ></dp-widget-context-help>
</ng-container>

<ng-template #widgetIsNotConfiguredTmpl>
    <div *ngIf="!showingOnlyFiltersBlock" class="d-flex align-items-center justify-content-center flex-1 no-configured-text">
        Widget is not configured
    </div>
</ng-template>
