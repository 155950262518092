import { Component, EventEmitter, HostBinding, OnInit, Output, inject } from '@angular/core';

import { FILTER_PANEL_WIDTH, WIDGET_FILTER_VISIBILITY_DEFAULT } from "../dashboard-filter-set.tokens";
import { DashboardFiltersManager } from '../dashboard-filters.manager';
import { GlobalFiltersWorkflow } from '../../../../widget-builder/services/global-filters.flow';
import { WidgetWorkflow } from '../../../../widget-builder/services/widget.flow';
import { isDynamicFilter } from '../../../../widget-builder/services/widget-builder.util';

export interface Selectors {
    label: string;
    name: string;
    checked?: boolean;
}

@Component({
    selector: 'dp-filter-set-accordion',
    templateUrl: './filter-set-accordion.component.html',
    styleUrls: ['./filter-set-accordion.component.scss'],
    providers: [DashboardFiltersManager],
})
export class FilterSetAccordionComponent implements OnInit {
    @Output() widgetFilterVisibility = new EventEmitter();

    @HostBinding('style.--filter-size.px') filterSize = 40;

    panelWidth = inject(FILTER_PANEL_WIDTH);
    filtersManager = inject(DashboardFiltersManager);
    workflow = inject(WidgetWorkflow) as GlobalFiltersWorkflow;
    selectedwidgetFilterVisibility = WIDGET_FILTER_VISIBILITY_DEFAULT;
    widgetFilterVisibilityOptions: Selectors[] = [
        {
            label: 'Hide All',
            name: 'hideAll',
            checked: false,
        },
        {
            label: 'Show All',
            name: 'showAll',
            checked: false,
        },
    ];
    isDynamicFilter = isDynamicFilter;

    ngOnInit() {
        if (this.workflow.placeholders?.widgetFilterVisibility) {
            this.selectedwidgetFilterVisibility = this.workflow.placeholders.widgetFilterVisibility;
        }
        this.widgetFilterVisibilityOptions.forEach(x => (x.checked = x.name === this.selectedwidgetFilterVisibility));
    }

    handleChange(index: number) {
        if (this.widgetFilterVisibilityOptions[index].name !== this.selectedwidgetFilterVisibility) {
            this.widgetFilterVisibilityOptions.forEach((x, i) => (x.checked = i === index));
            this.widgetFilterVisibility.emit(this.widgetFilterVisibilityOptions[index].name);
            this.selectedwidgetFilterVisibility = this.widgetFilterVisibilityOptions[index].name;
        }
    }
}
