import {
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    Input,
    Output, QueryList,
    TemplateRef,
    ViewChildren
} from '@angular/core';
import {NgbDropdown} from '@ng-bootstrap/ng-bootstrap';

import {SplitButtonItem} from '@dagility-ui/kit';


export interface NestedSplitButtonItem extends SplitButtonItem {
    children?: SplitButtonItem[];
}
@Component({
    selector: 'app-nested-split-button',
    templateUrl: './nested-split-button.component.html',
    styleUrls: ['./nested-split-button.component.scss']
})
export class NestedSplitButtonComponent<T extends NestedSplitButtonItem> {
    @Input() placement = 'bottom-right';
    @Input() buttonText: string;
    @Input() items: T[];
    @Input() disabled: boolean;
    @Input() isPrimary = true;
    @Input() isLink = false;
    @Input() container: 'body' | null = null;

    @Output() itemClick = new EventEmitter<T>();

    @ContentChild(TemplateRef, {static: true}) itemTemplate: TemplateRef<ElementRef>;

    @ViewChildren('dd') dds: QueryList<NgbDropdown>;

    nestedMenuItems: NestedSplitButtonItem[] = [
        {label: 'First Item', disabled: false, children: [{label: 'FirstOfFirstItem', disabled: false}]},
        {label: 'Second Item', disabled: false, children: [{label: 'SecondItem', disabled: false}]},
        {label: 'Third Item', disabled: false, children: [{label: 'ThirdItem', disabled: false}]},
        {label: 'Fourth Item', disabled: false, children: [{label: 'FourthItem', disabled: false}]},
        ];

    actionClick(node: T) {
        this.itemClick.emit(node);
        this.dds.forEach((dd) => {
            dd.close();
        });
    }
}
