<ng-template #dynamicTableHelp>
    <div>
        If field is enabled you should add {{ widgetType === type.TABLE ? "'columns'" : 'columns_{tabName}' }} object into placeholders.<br />
        This object should contain array of objects with the following fields:
        <ul>
            <li>title - The name of the column header;</li>
            <li>field - The field of the row to get the cells data from;</li>
            <li>headerTooltipTemplate - Tooltip for the column header (optional);</li>
            <li>filtertype - Filter component to use for this column (optional);</li>
            <li>cellTemplate - A Cell renderer script to use for this column (optional);</li>
            <li>columnVisible - Column visibility (optional).</li>
        </ul>
    </div>
</ng-template>
<ng-container [formGroup]="chartOptionsControl">
    <ng-container *ngIf="basicChartOptionControlsAvailable">
        <div class="row mx-0 field-container align-items-start">
            <lib-input class="col px-0" formControlName="title" label="Title"></lib-input>
            <div class="checkbox-container ml-3">
                <checkbox formControlName="hideTitle">Hide Title</checkbox>
            </div>
        </div>
        <lib-input *ngIf="!hideDescription" formControlName="description" label="Description"></lib-input>
    </ng-container>

    <ng-container [formGroup]="widgetFormGroup">
        <lib-dropdown
            class="col px-0 "
            *ngIf="!globalFiltersMode && isRoot"
            label="Tool Category Type"
            placeholder="Select Tool Category Type"
            formControlName="toolCategoryType"
            [ngSelectItemsFilter]="true"
            [clearable]="false"
            [items]="TOOL_CATEGORY_TYPES"
        ></lib-dropdown>
        <div class="row mx-0 field-container align-items-start">
            <ng-container *ngIf="!globalFiltersMode && displaySettingsConfig.showChartType" formGroupName="common">
                <lib-dropdown
                    class="col px-0"
                    label="Chart Template"
                    placeholder="Select Chart Template"
                    formControlName="type"
                    [ngSelectItemsFilter]="true"
                    [items]="widgetTypes"
                    [clearable]="false"
                    (change)="typeChange.emit($event)"
                ></lib-dropdown>
            </ng-container>
            <div *ngIf="isRoot && !displaySettingsConfig.enabled" class="ml-3 checkbox-container">
                <checkbox class="ml-3" formControlName="server">Server {{ globalFiltersMode ? 'Filters' : 'Widget' }}</checkbox>
            </div>
        </div>
    </ng-container>

    <lib-dropdown
        [formControl]="inputPlaceholdersControl"
        label="Input placeholders"
        [addTag]="true"
        [items]="inputPlaceholdersItems"
        [labelRemovable]="true"
        [addTagFunction]="addInputPlaceholdersFn"
        [clearable]="true"
        [multiple]="true"
        (valueChange)="handleInputPlaceholdersChange($event)"
    ></lib-dropdown>

    <lib-input label="No Data Message" formControlName="nodatamessage"></lib-input>

    <checkbox *ngIf="type.LOG === widgetType" formControlName="scrollToBottom">Scroll To Bottom</checkbox>
    <checkbox *ngIf="type.DOUGHNUT_CHART === widgetType" formControlName="showTotal" (click)="showTotalCountToggle($event, 'total')"
        >Show Total</checkbox
    >
    <checkbox *ngIf="type.DOUGHNUT_CHART === widgetType" formControlName="showCount" (click)="showTotalCountToggle($event, 'count')"
        >Show Count</checkbox
    >
    <checkbox *ngIf="type.ACCORDION === widgetType || type.ACCORDION_WITH_TABS === widgetType" formControlName="fixedLastRow"
        >Fixed Last Row</checkbox
    >
    <checkbox *ngIf="type.ACCORDION_WITH_TABS === widgetType" formControlName="openFirstRow">Open First Row</checkbox>
    <div class="row field-container">
        <div class="col d-flex align-items-center" *ngIf="[type.TABLE_WITH_TABS, type.TABLE].includes($any(widgetType))">
            <checkbox formControlName="isDynamicTable">Dynamic table</checkbox>

            <fa-icon class="ml-3 font-16px" icon="QuestionInCircle" container="body" [ngbTooltip]="dynamicTableHelp"></fa-icon>
        </div>
        <ng-container *ngIf="type.TABLE === widgetType">
            <checkbox class="col" formControlName="gridPagination">Grid Pagination</checkbox>
            <checkbox class="col" *ngIf="pagination.value" formControlName="serverSidePagination">Server Side Pagination</checkbox>
        </ng-container>
    </div>

    <div *ngIf="[type.TABLE, type.TABLE_WITH_TABS].includes(widgetType)" class="row">
        <checkbox class="col" formControlName="hasResetFilter">Reset Filter Button</checkbox>
    </div>

    <div *ngIf="[type.LINE_CHART, type.SCATTER_CHART, type.METRIC_LINE].includes(widgetType)" class="row align-items-end">
        <lib-dropdown
            class="col-9"
            formControlName="xaxistype"
            label="X Axis Type"
            placeholder="Select X Axis type"
            [items]="xAxisType"
        ></lib-dropdown>

        <checkbox class="ml-3 mb-2 mt-2" formControlName="showArea">Show Area</checkbox>
        <checkbox class="ml-3 mb-2 mt-2" formControlName="showStacked">Show Stacked</checkbox>
        <checkbox class="ml-3 mb-2 mt-2" formControlName="dataZoom">Data Zoom</checkbox>
    </div>

    <div *ngIf="widgetType === type.TREEMAP" class="row no-gutters">
        <checkbox class="mb-2 mt-2 mr-3" formControlName="hideBreadcrumb">Hide Breadcrumb</checkbox>
        <checkbox class="mb-2 mt-2" formControlName="hideUpperLabel">Hide Upper Label</checkbox>
    </div>

    <ng-container *libLet="[type.RADIAL, type.SCORE_DOUGHNUT].includes(widgetType) as radialWidget">
        <div *ngIf="radialWidget || type.PROGRESS === widgetType" class="row">
            <lib-input *ngIf="radialWidget" class="col" type="number" formControlName="maxValue" label="Max Value"></lib-input>
            <lib-input class="col" formControlName="measure" label="Measure"></lib-input>
        </div>
    </ng-container>
    <lib-input *ngIf="[type.LINE_CHART].includes(widgetType)" formControlName="gridBottom" label="Grid bottom"></lib-input>
    <ng-container *ngIf="widgetType | mapper: hasThreshold">
        <div class="row">
            <checkbox class="ml-3" formControlName="relatedThreshold">Threshold Related Color</checkbox>
            <checkbox class="ml-5" formControlName="disableBottomLine">Disable Bottom Line</checkbox>
        </div>
        <ng-container [formGroup]="widgetFormGroup">
            <dp-widget-builder-color-thresholds class="thresholds-control-block "></dp-widget-builder-color-thresholds>
        </ng-container>
    </ng-container>
    <lib-dropdown
        *ngIf="widgetType === type.COMPLEX"
        formControlName="layout"
        label="Layout"
        placeholder="Select layout"
        [items]="layouts"
    ></lib-dropdown>
    <lib-input *ngIf="widgetType === type.PROGRESS" label="No Data Message" formControlName="nodatamessage"></lib-input>
    <div
        *libLet="
            [type.BAR_CHART, type.LINE_CHART, type.SCATTER_CHART, type.BOXPLOT, type.METRIC_LINE, type.MULTIPLE_SERIES_TYPE].includes(
                widgetType
            ) as hasLabel
        "
        class="row field-container"
    >
        <lib-input
            *ngIf="hasLabel || type.MULTIPLE_Y_AXIS === widgetType"
            class="col"
            formControlName="xlabel"
            label="X Axis Label"
        ></lib-input>
        <lib-input *ngIf="hasLabel" class="col" formControlName="ylabel" label="Y Axis Label"></lib-input>
    </div>
    <ng-container *ngIf="widgetType | mapper: hasHideLegendControl">
        <div class="row">
            <checkbox class="ml-3" formControlName="hideLegend">Hide Legend</checkbox>
        </div>
        <div class="row">
            <lib-input class="col" type="number" formControlName="minYAxisValue" label="Min Y Axis Value"></lib-input>
            <lib-input class="col" type="number" formControlName="maxYAxisValue" label="Max Y Axis Value"></lib-input>
        </div>
    </ng-container>
    <ng-container *ngIf="widgetType | mapper: isBarChartWidget">
        <div class="row">
            <lib-dropdown
                class="col"
                formControlName="orientation"
                label="Chart orientation"
                placeholder="Select chart orientation"
                [items]="orientation"
            ></lib-dropdown>
            <lib-dropdown
                class="col"
                formControlName="barsPosition"
                label="Bars Position"
                placeholder="Select bars position"
                [items]="barsPositions"
            ></lib-dropdown>
        </div>
        <div class="row mt-3">
            <checkbox class="ml-3" formControlName="isBarSeriesClick">Use bar as event emitter</checkbox>
            <checkbox class="ml-5" formControlName="withEmptyBars">Use empty bars for line series</checkbox>
        </div>
    </ng-container>

    <div *ngIf="widgetType === type.ACCORDION_WITH_TABS" class="row">
        <lib-input class="col" label="Checkbox Label" formControlName="piCheckboxLabel"></lib-input>
    </div>

    <ng-container [formGroup]="widgetFormGroup">
        <dp-widget-builder-event-dependencies></dp-widget-builder-event-dependencies>
    </ng-container>

    <ng-container *ngIf="tags">
        <h3 class="mb-2 mt-0">Tags</h3>
        <div class="row">
            <lib-dropdown
                class="mb-16px col"
                [items]="tags"
                [formControl]="tagsControl"
                [placeholder]="'Search'"
                label="Tags"
                [addTag]="true"
                [addTagFunction]="addTagFn"
                [searchable]="true"
                [clearable]="true"
                [multiple]="true"
                (filter)="searchTags($event)"
            ></lib-dropdown>
        </div>
    </ng-container>

    <ng-container [formGroup]="widgetFormGroup">
        <dp-widget-builder-chart-series *ngIf="widgetType | mapper: isWidgetWithSeries"></dp-widget-builder-chart-series>
        <dp-widget-builder-statuses
            *ngIf="[type.PI_FEATURE_COMPLETION_STATUS, type.PI_ISSUE_LIFE_CYCLE].includes(widgetType)"
        ></dp-widget-builder-statuses>
        <dp-widget-builder-accordion-columns
            *ngIf="[type.ACCORDION_WITH_TABS, type.PI_FEATURE_COMPLETION_STATUS].includes(widgetType)"
        ></dp-widget-builder-accordion-columns>
        <dp-widget-builder-columns
            [block]="block"
            *ngIf="
                [type.TABLE, type.TABLE_WITH_TABS, type.ACCORDION, type.ACCORDION_WITH_TABS, type.PI_FEATURE_COMPLETION_STATUS].includes(
                    widgetType
                )
            "
        ></dp-widget-builder-columns>
        <dp-widget-builder-templates *ngIf="widgetType | mapper: hasAdditionalTemplates"></dp-widget-builder-templates>
        <dp-widget-builder-tile-form *ngIf="widgetType === type.TILE_CHART"></dp-widget-builder-tile-form>
    </ng-container>
    <ng-container *ngIf="[type.TWO_DIMENSIONAL_TABLE].includes(widgetType)">
        <div class="row ">
            <lib-input class="col" formControlName="verticalHeader" label="Vertical Header"></lib-input>
            <lib-input class="col" formControlName="horizontalHeader" label="Horizontal Header"></lib-input>
            <lib-input class="col" formControlName="gridValue" label="Grid Value"></lib-input>
        </div>
        <lib-codemirror-editor
            label="Cell Template"
            [needRefresh]="true"
            mode="text/javascript"
            formControlName="cellTemplate"
        ></lib-codemirror-editor>
    </ng-container>
    <ng-container
        *ngIf="
            (widgetType | mapper: isWidgetWithColors) &&
            !(type.LINE_CHART && chartOptionsControl.get('xaxistype') && chartOptionsControl.get('xaxistype').value === 'time')
        "
    >
        <h3 class="mb-2 mt-0">Colors</h3>
        <div class="d-flex flex-wrap pb-10px">
            <div *ngFor="let color of colors.controls; let i = index" class="align-items-center color-block mr-2">
                <dp-widget-color-picker [formControl]="color"></dp-widget-color-picker>
                <span class="fa fa-close cursor-pointer" (click)="deleteColor(i)"></span>
            </div>
        </div>
        <button
            class="btn btn-primary align-self-start"
            [(colorPicker)]="color"
            (colorPickerSelect)="colorPickerSelect()"
            [cpOKButton]="true"
            cpPosition="top-right"
        >
            Add
        </button>
        <lib-dropdown
            *ngIf="widgetType | mapper: isBarChartWidget"
            class="d-block mt-3"
            label="Color By"
            placeholder="Select Color By"
            formControlName="colorBy"
            [items]="colorByOptions"
            [clearable]="false"
        ></lib-dropdown>
    </ng-container>

    <div *ngIf="(widgetType | mapper: isPiWorkChart) || widgetType === type.DOUGHNUT_CHART" class="row mt-5px">
        <lib-input class="col" label="Chart Label" formControlName="piWorkChartLabel"></lib-input>
    </div>

    <lib-codemirror-editor
        *ngIf="widgetType | mapper: hasHeader"
        class="mt-3"
        label="Header Template"
        mode="text/html"
        formControlName="headertemplate"
        [needRefresh]="true"
    ></lib-codemirror-editor>

    <ng-container *ngIf="widgetType | mapper: hasTooltipFormatter">
        <checkbox formControlName="enterableTooltip">Enterable Tooltip</checkbox>

        <lib-dropdown
            class="d-block"
            label="Tooltip Trigger"
            placeholder="Select tooltip trigger"
            formControlName="tooltipTrigger"
            [items]="tooltipTriggers"
        ></lib-dropdown>

        <lib-codemirror-editor
            class="mt-3"
            label="Tooltip Formatter"
            mode="text/javascript"
            formControlName="tooltipFormatter"
            [needRefresh]="true"
        ></lib-codemirror-editor>
    </ng-container>

    <ng-container *ngIf="widgetType === type.TREEMAP">
        <lib-codemirror-editor
            class="mt-3"
            label="Label Formatter"
            mode="text/javascript"
            formControlName="labelFormatter"
            [needRefresh]="true"
        ></lib-codemirror-editor>
    </ng-container>

    <ng-container *ngIf="widgetType === type.MULTIPLE_SERIES_TYPE">
        <lib-codemirror-editor
            class="mt-3"
            label="X Axis Label Formatter"
            mode="text/javascript"
            formControlName="xAxisLabelFormatter"
            [needRefresh]="true"
        ></lib-codemirror-editor>
    </ng-container>

    <lib-codemirror-editor
        *ngIf="widgetType !== type.COMPLEX"
        class="mt-3"
        label="Mock data"
        mode="application/json"
        formControlName="mockData"
        [needRefresh]="true"
    ></lib-codemirror-editor>
</ng-container>
