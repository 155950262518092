<lib-stream-wrapper class="d-flex flex-1 overflow-hidden" [dataStream$]="data$">
    <ng-template contentTemplate>
        <ng-container *ngIf="model$ | async as model">
            <div *ngIf="showBackLink && !globalFiltersMode" class="widget-header d-flex align-items-center cursor-pointer" (click)="back()">
                <fa-icon icon="facArrowLeftRegular" class="fa font-16px back-icon"></fa-icon>
                <h1 class="ml-2 mt-0">{{ model.layers[model.root]?.chartOptions.title }}</h1>
            </div>

            <div class="flex-1 d-flex flex-column overflow-hidden">
                <dp-widget-builder-flow
                    class="position-relative flex-grow-1"
                    [model]="model"
                    [activeBlock]="activeBlock"
                    [actionsTemplate]="actionsTemplate"
                    (editBlock)="handleEditBlock($event)"
                    (addFilter)="handleAddFilter($event)"
                    (addQuery)="handleAddQuery($event)"
                    (addAsyncQuery)="handleAddAsyncQuery($event)"
                    (addDrilldown)="handleAddDrilldown($event)"
                    (addEventHandler)="handleAddEventHandler($event)"
                    (editDrilldown)="handleEditDrilldown($event)"
                    (editEventHandler)="handleEditEventHandler($event)"
                    (debug)="handleStartDebug()"
                    (save)="handleSaveFilters($event)"
                    (stopDebug)="handleStopDebug()"
                    (saveAndClose)="handleSaveAndClose($event)"
                ></dp-widget-builder-flow>
                <div
                    *ngIf="debuggerContainer.debugger || facade.currentWidget"
                    class="overflow-hidden debug-container d-flex flex-column"
                    #container
                >
                    <div libResize [parentItem]="container" class="vertical-resize border-bottom"></div>
                    <div class="d-flex flex-1 overflow-hidden">
                        <perfect-scrollbar class="variables p-2">
                            <dp-debug-variables
                                *ngIf="placeholders$ | async as placeholders"
                                [variables]="placeholders"
                            ></dp-debug-variables>
                        </perfect-scrollbar>

                        <perfect-scrollbar class="flex-1 p-2 border-left">
                            <ng-container *ngIf="logs$ | async as logs">
                                <span class="debug-log d-block my-2" *ngFor="let log of logs">{{ log }}</span>
                            </ng-container>
                        </perfect-scrollbar>
                    </div>
                </div>
            </div>

            <div *ngIf="activeBlock && !debuggerContainer.debugger" class="right-form-container d-flex mt-1">
                <dp-widget-builder-block-form
                    class="flex-1 overflow-hidden"
                    [block]="activeBlock"
                    [extensionsEnabled]="extensionsEnabled"
                    (close)="handleCloseEdit()"
                    (save)="handleSaveBlock($event)"
                    (delete)="handleRemoveItem()"
                    (preview)="handlePreview()"
                >
                </dp-widget-builder-block-form>
            </div>

            <div
                *ngIf="(debuggerContainer.debugger && debuggerContainer.debugger.widget$ | async) || facade.currentWidget as widget"
                class="right-form-container d-flex mt-1"
            >
                <div class="widget-preview-container p-4">
                    <div class="widget-preview-label mb-2">
                        <div>{{ globalFiltersMode ? 'Dashboard Filters' : 'Widget' }} Preview</div>
                        <button type="button" class="close ml-auto" (click)="handleStopDebug()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <ng-container *ngIf="globalFiltersMode; else widgetTemplate">
                        <dp-debug-global-filters *ngIf="!widget.server" class="mx-auto" [widget]="widget"></dp-debug-global-filters>
                        <dp-dashboard-filter-set
                            *ngIf="widget.server"
                            [filters]="widget.filters"
                            [server]="true"
                            [debug]="true"
                            [complexNamedDropdown]="widget.complexNamedDropdown"
                            [complexNamedDropdownLabel]="widget.complexNamedDropdownLabel"
                            [complexNamedDropdownDependencies]="widget.complexNamedDropdownDependencies"
                            [complexNamedDropdownFilterDependency]="widget.complexNamedDropdownFilterDependency"
                            [breakpoints]="store.positionBreakpoints"
                        ></dp-dashboard-filter-set>
                    </ng-container>

                    <ng-template #widgetTemplate>
                        <dp-any-widget-wrapper
                            class="widget-preview mt-auto mb-auto fixed-size"
                            [placeholders]="{}"
                            [breakpoints]="store.positionBreakpoints"
                            [options]="widget"
                            [separatedView]="true"
                            [externalOptions]="facade.externalOptions"
                        ></dp-any-widget-wrapper>
                    </ng-template>
                </div>
            </div>
        </ng-container>
    </ng-template>
</lib-stream-wrapper>
