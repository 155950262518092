import {FormControl, FormGroup, ValidatorFn} from '@angular/forms';
import {DropdownItem} from '@dagility-ui/kit';
import {DatePipe} from '@angular/common';

export type EXPORT_SELF_TEST_TYPE = 'XLSX' | 'PDF';

export type resultType = 'SUCCESS' | 'SUCCESS_WITH_WARNING' | 'SKIPPED' | 'FAILED';

export enum testType {
    WIDGET_CHECK = 'Widget check',
    WIDGET_RESPONSE_CHECK = 'Widget response check',
    WIDGET_BODY_CHECK = 'Widget body check',
    FILTER_BODY_CHECK = 'Filter body check',
    FILTER_RESPONSE_CHECK = 'Filter response check'
}

export interface TestExecutionPanel {
    id: number;
    executedTime: string;
    widgets: number;
    projects: number;
    failed: number;
    status?: string;
    hidden?: boolean;
    hiddenByDate?: boolean;
}

export interface TestExecution {
    id: number;
    headers: string[];
    tableData: Widget[];
}

export interface Widget {
    widgetName: string;
    imageUrl?: string;
    title?: string;
    description?: string;
    tests: Test[];
    hidden?: boolean;
}

export interface Test {
    result: resultType;
    details?: Details[];
    hidden?: boolean;
}

export type LogTypeString = keyof typeof testType;

export interface Details {
    type: LogTypeString;
    level: string;
    invokeParameters: string;
    parametersDescription: string;
    message: string;
}

export const DEFAULT_CATEGORY_ITEM: DropdownItem = { label: 'All', value: 'ALL' };

export interface DefaultSelectionItem {
    id: number;
    name: string;
    detailedDescription?: string;
}

export interface ISelectionWidget extends DefaultSelectionItem {
    widgetId: string;
    category?: string;
    imageUrl: string;
}

export interface ISelectionProject extends DefaultSelectionItem {
    createdDate?: string;
}

export type WidgetCategory = 'ALL' | 'PORTFOLIO' | 'APPLICATION' | 'TEAM' | 'RELEASE';

export interface WidgetSelectorState {
    searchTerm: string;
    category: WidgetCategory;
}

export interface ProjectSelectorState {
    enabled: boolean;
    searchTerm: string;
    dateStart: Date;
    dateEnd: Date;
}

export interface ISelfTest {
    widgetsIds: string[];
    projectsIds: number[];
}

export function toDate(str: any): Date {
    return !str ? null : new Date(str);
}

export function getLocalDate(dateVal: string) {
    return new DatePipe('en-US').transform(dateVal, 'd MMM y HH:mm');
}

export const dateTimeValidator = (fc: FormControl) => {
    const date = toTimeStamp(fc.value);
    const isValid = fc.value === '' || date && !isNaN(date) && (date < new Date().getTime());
    return isValid == null ? null : isValid ? null : {isValid: false};
};

export const dateRangeValidator: ValidatorFn = (fg: FormGroup) => {
    const start = fg.get('startDate');
    const end = fg.get('endDate');
    if ((!start || !end) || (!start.valid || !end.valid)) {
        return null;
    } else {
        const startTime = toTimeStamp(start.value);
        const endTime = toTimeStamp(end.value);
        if (!startTime || !endTime || startTime <= endTime) {
            return null;
        }
        return {range: true};
    }
};

function toTimeStamp(str: string): number {
    if (!str) {
        return null;
    }
    const result = Date.parse(str);
    return (result && !isNaN(result)) ? result : null;
}

function pad(num: number) {
    if (num < 10) {
        return `0${num}`;
    }
    return `${num}`;
}

export function toIsoString(date: Date): string {
    return `${date.getUTCFullYear()}-${pad(date.getUTCMonth() + 1)}-${pad(date.getUTCDate())}T${pad(date.getUTCHours())}:${pad(date.getUTCMinutes())}:${pad(date.getUTCSeconds())}Z`;
}
