import { EnvironmentInjector, inject, InjectionToken, Provider } from '@angular/core';
import { AnyWidgetModel, Placeholders, WidgetFilter } from 'data-processor';
import { merge, Observable } from 'rxjs';
import { filter, map, mapTo, shareReplay } from 'rxjs/operators';

import { WidgetWorkflow } from '../../../widget-builder/services/widget.flow';
import { globalFilterFactory } from './global-filters/global-filter.factory';

export const VISIBLE_FILTERS = new InjectionToken<Observable<WidgetFilter[]>>('VISIBLE FILTERS');
export const GLOBAL_FILTERS_LOADING = new InjectionToken<Observable<boolean>>('GLOBAL FILTERS LOADING');
export const WIDGET_FILTER_VISIBILITY_DEFAULT = 'showAll';
export const FILTER_PANEL_WIDTH = new InjectionToken<number>('Filter panel width');

export function withPanelWidth(width: number): Provider {
    return {
        provide: FILTER_PANEL_WIDTH,
        useValue: width,
    };
}

export abstract class FiltersDirtyStateToken {
    abstract saveEnabled$: Observable<boolean>;
    abstract resetToLastSavedEnabled$: Observable<boolean>;
    abstract resetToDefaultEnabled$: Observable<boolean>;
    abstract applyEnabled$: Observable<boolean>;

    /* it's only for disabled state*/
    abstract updateLastSavedFilters(placeholders: Placeholders): void;

    /* it's only for disabled state*/
    /* todo: need some generic algorithm for global filters storing + calculation */
    abstract updateAppliedFilters(placeholders: Placeholders): void;
}

export const VISIBLE_FILTERS_PROVIDER: Provider = {
    provide: VISIBLE_FILTERS,
    useFactory: () => {
        const env = inject(EnvironmentInjector);

        return inject(WidgetWorkflow).widget$.pipe(
            filter(Boolean),
            map((widget: AnyWidgetModel) => env.runInContext(() => widget.filters.filter(filter => globalFilterFactory(filter)))),
            shareReplay(1)
        );
    },
};

export const GLOBAL_FILTERS_LOADING_PROVIDER: Provider = {
    provide: GLOBAL_FILTERS_LOADING,
    useFactory: () => {
        const workflow = inject(WidgetWorkflow);

        return merge(workflow.startLoading$.pipe(mapTo(true)), workflow.loaded$.pipe(mapTo(false)));
    },
};
