<div *ngIf="isLoading; else successOrErrorMsg" class="mx-auto width-fit-content" [style.height.px]="height">
    <div class="modal-body ml-15px text-center" style="top: 20%">
        <div class="mb-15px">
            <div class="loader"></div>
        </div>
        <h6 class="modal-title">{{ textConfig.loadingText }}</h6>
    </div>
</div>
<ng-template #successOrErrorMsg>
    <div class="modal-header border-bottom-0">
        <h6 class="modal-title mt-10px" id="modal-title">{{ textConfig.titleText }}</h6>
    </div>

    <div class="modal-body">
        <div *ngIf="isError; else success" class="font-12px">
            <fa-icon class="fa font-14px color-red mr-5px" icon="facExclamationCircle"></fa-icon>
            <span class="font-12px"> {{ textConfig.errorText }}. {{ textConfig.errorType }} Failed </span>
        </div>
        <ng-template #success>
            <fa-icon class="fa font-14px color-green mr-5px" icon="facBuildSuccess"></fa-icon>
            <span class="font-12px">
                {{ textConfig.successText }}
            </span>
        </ng-template>
    </div>

    <div class="modal-footer font-11px">
        <button type="button" class="btn  btn-primary" (click)="activeModal.dismiss()">Ok</button>
    </div>
</ng-template>
