import { Directive, OnDestroy } from '@angular/core';
import { CKEditorComponent } from 'ngx-ckeditor';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { noop } from 'rxjs';

declare const CKEDITOR: any;

const INSTANCE_READY_EVENT = 'instanceReady';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'ck-editor[intoScrollContainer]',
})
export class CkeditorPreventScrollDirective implements OnDestroy {
    private unListenObject = {
        removeListener: noop,
    };

    private instanceReadyListener = ({ editor }: { editor: any }) => {
        if (editor.name !== this.editorWrapper['identifier']) {
            return;
        }

        this.unListenObject = editor.document.on('mousewheel', this.mouseWheelListener(editor.document.$.documentElement));
    };

    private mouseWheelListener = (document: Element) => (e: {
        data: {
            $: WheelEvent;
        };
    }) => {
        const delta = e.data.$.deltaY;
        const offset = document.scrollHeight - document.scrollTop;
        if (
            (document.scrollTop === 0 && delta < 0) ||
            (delta > 0 && (Math.floor(offset) === document.clientHeight || Math.ceil(offset) === document.clientHeight)) ||
            (Number.isInteger(offset) && offset === document.clientHeight - 1)
        ) {
            this.perfectScrollbarComponent.directiveRef.scrollToTop(this.perfectScrollbarComponent.directiveRef.geometry().y + delta);
        }
    };

    constructor(private editorWrapper: CKEditorComponent, private perfectScrollbarComponent: PerfectScrollbarComponent) {
        CKEDITOR.on(INSTANCE_READY_EVENT, this.instanceReadyListener);
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    ngOnDestroy() {
        this.unListenObject.removeListener();
        CKEDITOR.removeListener(INSTANCE_READY_EVENT, this.instanceReadyListener);
    }
}
