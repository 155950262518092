<perfect-scrollbar class="plugin-content-wrapper" [config]="perfectScrollBarConfig" [style.minWidth.px]="500">
    <h1 *ngIf="isInIframe" class="main-content-header">Tools</h1>

    <div class="plugins-tab">
        <nav
            ngbNav
            #nav="ngbNav"
            class="nav nav-tabs justify-content-start"
            [ngClass]="{
                'main-content-header': !isInIframe,
                'main-content-header-shadow': !isInIframe,
                'mb-4': !isInIframe,
                'pb-0': !isInIframe,
                'nav-tabs-in-iframe': isInIframe
            }"
        >
            <div *ngIf="!isInIframe" class="w-100">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="'../'">Settings</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Integrations: Tools</li>
                    </ol>
                </nav>

                <h1>Tools</h1>
            </div>

            <ng-container ngbNavItem="configured">
                <a ngbNavLink>Configured</a>
                <ng-template ngbNavContent>
                    <dp-configured
                        [showProjects]="showProjects"
                        [showExternalCredentials]="showExternalCredentials"
                        (runToolWizard)="runToolWizard.emit($event)"
                    >
                    </dp-configured>
                </ng-template>
            </ng-container>
            <ng-container ngbNavItem="toolLibrary">
                <a ngbNavLink>Library</a>
                <ng-template ngbNavContent>
                    <dp-tool-library [isInIframe]="isInIframe" (openConfigured)="openTab('configured')"></dp-tool-library>
                </ng-template>
            </ng-container>
            <ng-container *ngIf="dpTab" ngbNavItem="dataProcessor">
                <a ngbNavLink>DataMorph</a>
                <ng-template ngbNavContent>
                    <dp-processor-monitoring [isInIframe]="!!dpTab" class="data-processor"></dp-processor-monitoring>
                </ng-template>
            </ng-container>
        </nav>
        <div [ngbNavOutlet]="nav"></div>
    </div>
</perfect-scrollbar>
