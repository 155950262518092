<div class="modal-header">
        <h1 class="header-text mt-0">{{ title }}</h1>
        <button
            type="button"
            class="close ml-auto"
            aria-label="Close button"
            aria-describedby="modal-title"
            (click)="modalService.dismiss()"
        >
            <fa-icon [icon]="icons.facClose" class="close-modal-window-icon font-14px"></fa-icon>
        </button>
</div>

<div class="modal-body">
    <lib-stream-wrapper [dataStream$]="jobSets$" class="table-wrapper">
        <ng-template contentTemplate let-jobSets="data">
            <lib-data-grid
                class="d-block"
                bodyHeight="300px"
                [dataSource]="jobSets"
                [columns]="pluginGridColumns"
                sticky="true"
                [formGroup]="form"
            >
                <ng-template rowTemplate let-set="item">
                    <td style="width: 50%" class="text-truncate table-cells">
                        <lib-radio-button
                            name="jobSet"
                            testId="jobset"
                            [checked]="set.id === jobSetIdControl.value"
                            [value]="set.id"
                            formControlName="jobSetId"
                            (click)="handleSetChange($event, set)"
                        >
                            {{ set.name }}
                        </lib-radio-button>
                    </td>
                </ng-template>
            </lib-data-grid>
        </ng-template>
    </lib-stream-wrapper>
</div>

<div class="modal-footer">
    <button (click)="modalService.dismiss()" type="button" class="btn btn-secondary  ml-auto font-11px">
        Cancel
    </button>
    <button (click)="handleAction()" type="button" class="btn btn-primary  ml-4 font-11px">
        Swap
    </button>
</div>
