<div [formGroup]="form">
    <ng-container *ngIf="!loading; else loader" formArrayName="eventConditions">
        <div *ngFor="let conditionForm of conditions.controls; let i = index" class="d-flex align-items-start mb-3" [formGroupName]="i">
            <lib-dropdown
                *ngIf="i === 0"
                class="mr-3"
                width="204"
                label="Select Event"
                placeholder="Select"
                valueKey="code"
                labelKey="display"
                formControlName="event"
                [items]="events"
                (change)="onEventChange($event)"
            ></lib-dropdown>
            <lib-dropdown
                *ngIf="i !== 0"
                class="mr-3"
                width="204"
                label="Apply by"
                formControlName="logicOperator"
                [items]="logicOperators"
                [clearable]="false"
            ></lib-dropdown>
            <lib-dropdown
                class="mr-3"
                width="204"
                label="Select Condition"
                placeholder="Select"
                valueKey="type"
                labelKey="name"
                formControlName="condition"
                [items]="eventConditions"
                (change)="onConditionChange($event, i)"
            ></lib-dropdown>
            <lib-dropdown
                class="mr-3"
                width="204"
                label="Select Operator"
                placeholder="Select"
                valueKey="code"
                labelKey="name"
                formControlName="operator"
                [items]="operators[i]"
                (change)="onOperatorChange($event, i)"
            ></lib-dropdown>
            <lib-input
                *ngIf="!values[i] || values[i].valueType.inputType === 'NUMBER'"
                type="number"
                class="mr-4"
                label="Input value"
                formControlName="value"
                [style.width.px]="204"
            ></lib-input>
            <lib-input
                *ngIf="values[i]?.valueType.inputType === 'TEXT'"
                type="text"
                class="mr-4"
                label="Input value"
                formControlName="value"
                [style.width.px]="204"
            ></lib-input>
            <lib-dropdown
                *ngIf="values[i] && values[i].valueType.inputType === 'DROPDOWN'"
                class="mr-4"
                width="204"
                label="Select Value"
                placeholder="Select"
                valueKey="value"
                labelKey="label"
                formControlName="value"
                [items]="values[i].values"
                [multiple]="isMultiple(i)"
            ></lib-dropdown>
            <fa-icon
                *ngIf="i !== 0"
                class="font-16px secondary-text-and-icons cursor-pointer"
                icon="facTrash"
                style="margin-top: 29px"
                (click)="deleteCondition(i)"
            ></fa-icon>
        </div>

        <div class="mb-20px">
            <lib-dropdown
                width="204"
                placeholder="Add Condition"
                [formControl]="addConditionControl"
                [items]="logicOperators"
                (change)="addCondition($event)"
            ></lib-dropdown>
        </div>
    </ng-container>
</div>

<ng-template #loader>
    <div class="d-flex justify-content-center align-items-center">
        <lib-loader></lib-loader>
    </div>
</ng-template>
