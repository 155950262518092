/**
 * check enums
 */
import { CommunicationChannel } from '@app/services';

import {
    CI_CD_MODULE_SEGMENT,
    INSIGHT_MODULE_SEGMENT,
    OBSERVATORY_MODULE_SEGMENT,
    PROJECT_MODULE_SEGMENT,
} from '../../app-routing.constants';

export type UserStatus = 'ACTIVE' | 'INACTIVE' | 'PENDING';

/**
 * this is a security-related information of a user
 */
export interface AuthenticatedUser {
    readonly id: string;
    readonly name: string;
    readonly authorities: string[]; //
    variables: UserVariables;
}

export interface UserVariables {
    defaultPage?: any;
    defaultDashboard?: string;
    defaultTheme?: string;
    needToSaveProfile?: any;
    needToSaveScope?: any;
    hideDashboardFilterHint?: any;
    hideWorkflowInfoAlert?: any;

    [key: string]: any;
}

export class UserVariablesHelper {
    static needToSaveProfile(vars: UserVariables): boolean {
        return !vars || vars.needToSaveProfile !== false;
    }

    static needToSaveScope(vars: UserVariables): boolean {
        return !vars || vars.needToSaveScope !== false;
    }
}

/**
 * user structure that corresponds to database
 */
export interface DefaultUser {
    id: string;
    login: string;
    firstName: string;
    lastName: string;
    email: string;
    imagePath: string;
    status: UserStatus;
    roles: string[]; // business role, they differs from CurrentUser.authorities
    position?: string;
    managerId?: string;
    businessRoles?: number[];
    communicationChannels?: CommunicationChannel[];
    readonly keycloakId?: string;
    readonly createdOn?: number;
    readonly lastLoginTime?: number;
}

export interface ShortUser {
    id: string;
    firstName: string;
    lastName: string;
    managerId: string;
    position: string;
    imagePath: string;
}

export interface IFullNameUser {
    id: string;
    firstName: string;
    lastName: string;
    fullName?: string;
    login?: string;
    email?: string;
}

export interface IDefaultRoute {
    id: number;
    route: string;
    label: string;
    adminOnly: boolean;
}

export const UserDefaultRoute = {
    PROJECT: { id: 0, route: `${PROJECT_MODULE_SEGMENT}`, label: 'Projects', adminOnly: false },
    INSIGHTS: { id: 1, route: `${INSIGHT_MODULE_SEGMENT}`, label: 'Illuminate', adminOnly: false },
    OBSERVATORY: { id: 3, route: `${OBSERVATORY_MODULE_SEGMENT}`, label: 'Observatory', adminOnly: false },
    SETTINGS: { id: 4, route: 'admin', label: 'Settings', adminOnly: true },
    CONVEYOR: { id: 5, route: `${CI_CD_MODULE_SEGMENT}`, label: 'Conveyor', adminOnly: false },
} as const;

export const UserDefaultRoutes: IDefaultRoute[] = [
    UserDefaultRoute.PROJECT,
    UserDefaultRoute.INSIGHTS,
    UserDefaultRoute.OBSERVATORY,
    UserDefaultRoute.SETTINGS,
    UserDefaultRoute.CONVEYOR,
];
