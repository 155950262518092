<div class="mb-15px">
    <trigger class="size-s" [value]="_onlyLocal" (click)="onlyLocal = !onlyLocal; reloadWidget();" *ngIf="isDrillDown">
        Render drill down widget only
    </trigger>
</div>
<ng-container *ngIf="showChart; else noDataForShow">
    <dp-any-widget-wrapper
        *ngIf="anyWidgetModel && syncShow; else widgetLoading"
        class="position-relative m-auto fixed-size widget"
        [options]="anyWidgetModel"
        [placeholders]="{}"
        [separatedView]="true"
    ></dp-any-widget-wrapper>
</ng-container>
<ng-template #widgetLoading>
    <div class="lib-card w-100 d-flex justify-content-center align-items-center">
        <lib-loader></lib-loader>
    </div>
</ng-template>
<ng-template #noDataForShow>
    <div class="multi-tool-warning mb-15px row no-gutters">
        <div class="multi-tool-warning-title body5 color-font-4">
            <fa-icon icon="facExclamationCircle"></fa-icon>
        </div>
        <div class="multi-tool-warning-content body4 nothing-show-message">
            To preview the widget please map dataset fields to the chart configuration
        </div>
    </div>
</ng-template>
