import { NgModule } from '@angular/core';
import { AppHealthDashboardComponent } from '@app/shared/modules/application-scores/app-health-dashboard/app-health-dashboard.component';
import { AppScoreWidget } from '@app/shared/modules/application-scores/app-score-widget/app-score-widget';
import { CommonModule } from '@angular/common';
import { AppScoreConfigComponent } from '@app/shared/modules/application-scores/app-score-config/app-score-config.component';
import { ChartsModule, SharedComponentsModule } from '@dagility-ui/shared-components';


@NgModule({
    imports: [CommonModule, ChartsModule, SharedComponentsModule],
    declarations: [AppHealthDashboardComponent, AppScoreWidget, AppScoreConfigComponent],
    exports: [AppHealthDashboardComponent, AppScoreWidget, AppScoreConfigComponent],
})
export class ApplicationScoreModule {}
