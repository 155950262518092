import { Component, HostListener, Input } from '@angular/core';
import { SREGanttValue } from '../../../models/sre-gantt.model';
import { SreGanttService } from '../../../services/sre-gantt.service';

@Component({
    selector: 'dp-sre-gantt-value',
    templateUrl: './sre-gantt-value.component.html',
    styleUrls: ['./sre-gantt-value.component.scss'],
})
export class SreGanttValueComponent {
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _value: SREGanttValue;

    @Input() set value(value) {
        this._value = value;
    }

    get value() {
        return this._value;
    }

    get isSelected() {
        const selectedValue = this.service.selectedValue;
        if (!selectedValue) {
            return false;
        }

        return this.value.parentTitle === selectedValue.parentTitle && this.value.title === selectedValue.title;
    }

    constructor(public service: SreGanttService) {}

    @HostListener('click') onClick() {
        this.service.updateSelectedValue(this.value);
    }
}
