import { defaultEnvironment, getDefaultRuntimeEnv } from './env.common';

export const environment: StaticEnv = {
    ...defaultEnvironment,
    production: true,
};

export const runtimeEnv: Partial<RuntimeEnv> = {
    ...getDefaultRuntimeEnv('Prod'),
    serviceUrl: 'https://dev.ustpace.com',
    xpressoUrl: 'https://xpresso-cc.dagility.com',
    noSkriptUrl: 'https://noskript.ust-darwin.com/scriptless/index.html#/design',
    mcmUrl: 'https://mcmdev.ust-darwin.com/',
};
