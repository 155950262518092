<div class="modal-main-container" libOutsideClick>
    <div class="w-100 content-container">
        <div class="modal-header border-bottom-0">
            <div class="d-block">
                <h2 class="modal-title mt-0" id="modal-basic-title">{{ title }}</h2>
            </div>

            <button tabindex="-1" type="button" class="close" aria-label="Close" (click)="handleCloseClick()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <ng-container *ngIf="scriptVersion$ | obsWithStatus | async as versions">
            <ng-container *ngIf="!versions.loading; else loadingTemplate">
                <perfect-scrollbar class="wrapper-max-height-100vh-75">
                    <div
                        *ngFor="let version of versions.value.content; let first = first; let last = last"
                        class="version-card"
                        [ngClass]="{ 'version-card-first': first, 'version-card-last': last }"
                    >
                        <div class="w-100">
                            <div class="version-card-icon" [innerHTML]="icons.facCircle | safeHtml: 'html'"></div>
                            <ng-template
                                *ngTemplateOutlet="first ? currentVersion : defaultTemplate; context: { version: version }"
                            ></ng-template>
                        </div>
                    </div>
                    <button *ngIf="!versions.value.last && !loading" class="btn btn-link m-3" (click)="showOlder(versions.value.size)">
                        Show older
                    </button>
                </perfect-scrollbar>
            </ng-container>
        </ng-container>
    </div>
</div>

<ng-template #defaultTemplate let-version="version">
    <div class="version-card-content">
        <div class="flex flex-column">
            <div class="row no-gutters">
                <div class="col">
                    <div class="body2 title font-weight-600">{{ version.createdDate | date: 'lastDateTimeCustom' }}</div>
                    <div class="body2 mt-5px">{{ version.createdBy }}</div>
                </div>
                <div *ngIf="modalType === 'PIPELINE'" class="col d-inline-flex justify-content-end" ngbAutofocus>
                    <button
                        class="btn btn-link size-s"
                        [ngbTooltip]="'Apply version'"
                        placement="top-right"
                        tooltipClass="custom-tooltip-font"
                        [openDelay]="800"
                        container="body"
                        (click)="useVersion(version.version)"
                    >
                        <span class="branded-icon" [innerHTML]="icons.facArrowInCircle | safeHtml: 'html'"></span>
                    </button>
                    <button
                        class="btn btn-link size-s ml-5px"
                        [ngbTooltip]="'Compare with current version'"
                        placement="top-right"
                        tooltipClass="custom-tooltip-font"
                        [openDelay]="800"
                        container="body"
                        (click)="compareWithVersion(version.version)"
                    >
                        <span class="branded-icon" [innerHTML]="icons.facTransfer | safeHtml: 'html'"></span>
                    </button>
                    <button
                        class="btn btn-link size-s ml-5px"
                        [ngbTooltip]="'Delete version'"
                        placement="top-right"
                        tooltipClass="custom-tooltip-font"
                        [openDelay]="800"
                        container="body"
                        (click)="deleteVersion(version.version)"
                    >
                        <fa-icon icon="facTrash"></fa-icon>
                    </button>
                </div>
            </div>
            <div *ngIf="version.name" class="row no-gutters mt-10px">
                <div class="col primary-text body2">
                    {{ version.name }}
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #currentVersion>
    <div class="version-card-content">
        <h4 class="mt-1">Сurrent Version</h4>
    </div>
</ng-template>

<ng-template #loadingTemplate>
    <div class="d-inline-flex justify-content-center align-items-center w-100 h-100">
        <lib-spinner></lib-spinner>
    </div>
</ng-template>
