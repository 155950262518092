import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';

import { lightOrDark } from '@dagility-ui/kit';

@Component({
    selector: 'dp-progress-column.row.m-0.w-100',
    template: `
        <div
            *ngFor="let progressPiece of progressPieces; let i = index; let first = first; let last = last"
            [ngClass]="progressPieces.length === 1 ? 'rounded-both' : first ? 'rounded-start' : last ? 'rounded-end' : ''"
            class="progress-piece"
            [ngStyle]="{
                width: this.params.colDef.cellRendererParams.percentWidth ? percentWidth[i] : equalWidth,
                'background-color': pieceColors[i],
                color: getLightOrDark(pieceColors[i])
            }"
            [ngbTooltip]="tooltip[i]"
            container="body"
            [innerHTML]="innerHTML[i] | safeHtml: 'html'"
        ></div>
    `,
    styleUrls: ['./progress-column.component.scss'],
})
export class ProgressColumnComponent implements ICellRendererAngularComp {
    progressPieces: any = [];
    params: ICellRendererParams;
    placeholders: any;
    pieceColors: string[] = [];
    percentWidth: string[] = [];
    equalWidth: string;
    innerHTML: any[] = [];
    tooltip: string[] = [];

    agInit(params: ICellRendererParams) {
        this.params = params;
        this.placeholders = this.params.colDef.cellRendererParams.placeholders;
        const incomingValues = JSON.parse(this.params.value);
        for (const incomingValue of incomingValues) {
            if (incomingValue.value !== 0) {
                this.progressPieces.push(incomingValue);
            }
        }

        this.equalWidth = this.getProgressPieceWidth();
        for (let i = 0; i < this.progressPieces.length; i++) {
            this.innerHTML.push(this.params.colDef.cellRendererParams.progressTemplate(this.progressPieces[i], this.placeholders));
            this.pieceColors.push(this.getProgressPieceColor(i));
            this.tooltip.push(this.params.colDef.cellRendererParams.progressTooltip(this.progressPieces[i], this.placeholders));
            this.percentWidth.push(this.getProgressPieceProportionatelyWidth(this.progressPieces[i].value));
        }
    }

    refresh(params: any): boolean {
        return false;
    }

    getProgressPieceWidth() {
        return (100 / this.progressPieces.length).toString() + '%';
    }

    getProgressPieceProportionatelyWidth(pieceValue: any) {
        let sum = 0;
        for (const progressPiece of this.progressPieces) {
            sum += progressPiece.value;
        }
        return ((pieceValue / sum) * 100).toString() + '%';
    }

    getProgressPieceColor(index: number) {
        const searchLabel = JSON.parse(this.params.value)[index].label.toLowerCase();
        if (
            this.placeholders &&
            this.placeholders.progressColors &&
            this.placeholders.progressColors.find((element: any) => searchLabel === element.label.toLowerCase())
        ) {
            return this.placeholders.progressColors.find((element: any) => searchLabel === element.label).color;
        } else if (
            this.params.colDef.cellRendererParams.progressColors &&
            this.params.colDef.cellRendererParams.progressColors.length &&
            this.params.colDef.cellRendererParams.progressColors.find((element: any) => searchLabel === element.label.toLowerCase())
        ) {
            return this.params.colDef.cellRendererParams.progressColors.find((element: any) => searchLabel === element.label).color;
        } else if (
            this.params.colDef.cellRendererParams.randomizedProgressColors &&
            this.params.colDef.cellRendererParams.randomizedProgressColors.length &&
            this.params.colDef.cellRendererParams.randomizedProgressColors.find(
                (element: any) => searchLabel === element.label.toLowerCase()
            )
        ) {
            return this.params.colDef.cellRendererParams.randomizedProgressColors.find((element: any) => searchLabel === element.label)
                .color;
        } else {
            const randomizedColor = this.randomColor();
            this.params.colDef.cellRendererParams.randomizedProgressColors.push({ label: searchLabel, color: randomizedColor });
            return randomizedColor;
        }
    }

    randomColor() {
        const r = Math.floor(Math.random() * 255);
        const g = Math.floor(Math.random() * 255);
        const b = Math.floor(Math.random() * 255);
        return `rgb(${r},${g},${b})`;
    }

    getLightOrDark(color: string) {
        return lightOrDark(color);
    }
}
