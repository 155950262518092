<ng-container *ngIf="form" [formGroup]="form">
    <checkbox
        *ngIf="isRoot && globalFiltersMode"
        class="mt-2 d-block"
        formControlName="complexNamedDropdown"
        (change)="handleComplexNamedDropdownCheckbox($event)"
        >Complex Named Dropdown
    </checkbox>

    <div *ngIf="showComplexNamedDropdown" class="row">
        <lib-input class="mt-3 col" label="Complex Named Dropdown Label" formControlName="complexNamedDropdownLabel"></lib-input>

        <lib-input
            class="mt-3 col"
            label="Complex Named Dropdown Dependencies"
            formControlName="complexNamedDropdownDependencies"
        ></lib-input>

        <lib-input
            *ngIf="!hideComplexNamedDropdownFilterDependency"
            class="mt-3 col"
            label="Complex Named Dropdown Filter Dependency"
            formControlName="complexNamedDropdownFilterDependency"
        ></lib-input>
    </div>

    <ng-container *ngIf="!globalFiltersMode">
        <h3 class="mt-0">Chart Settings</h3>
        <dp-widget-builder-chart-options
            class="mt-3"
            [block]="block"
            [widgetType]="widgetType"
            [basicChartOptionControlsAvailable]="basicChartOptionControlsAvailable || getDisplaySettingsConfig.showBasicChartOptions"
            [hideDescription]="hideDescription$ | async"
            [isRoot]="isRoot"
            [displaySettingsConfig]="getDisplaySettingsConfig"
            (typeChange)="handleTypeChange($event)"
        ></dp-widget-builder-chart-options>
    </ng-container>

    <dp-widget-builder-size-form
        *ngIf="(isRoot && !globalFiltersMode) || getDisplaySettingsConfig.showSizeChanger"
    ></dp-widget-builder-size-form>

    <h3 *ngIf="ftTags" class="mb-3">Visibility tags</h3>
    <div *ngIf="ftTags" class="row border-bottom mt-2">
        <lib-dropdown
            *ngIf="ftTagsActive"
            class="mb-16px col"
            [items]="ftTags"
            [formControl]="ftTagsControl"
            [placeholder]="'Search'"
            label="Visibility tags"
            [labelRemovable]="true"
            [addTag]="true"
            [addTagFunction]="addFtTagFn"
            [searchable]="true"
            [clearable]="true"
            [multiple]="true"
            (valueChange)="handleFtTagsChange($event)"
        ></lib-dropdown>
    </div>
</ng-container>
