import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiKitModule } from '@dagility-ui/kit';
import { ContextHelpModule, MapperPipeModule } from '@dagility-ui/shared-components';

import { DashboardFilterSetComponent } from './dashboard-filter-set.component';
import { FilterSetAccordionComponent } from './filter-set-accordion/filter-set-accordion.component';
import { FilterSetPanelComponent } from './filter-set-panel/filter-set-panel.component';
import { GlobalFiltersModule } from './global-filters/global-filters.module';
import { ItemsWithMoreComponent } from './items-with-more/items-with-more.component';
import { FilterDataSourceWithState } from './filter-data-source-with-state/filter-data-source-with-state.directive';

@NgModule({
    imports: [
        CommonModule,
        UiKitModule,
        GlobalFiltersModule,
        ItemsWithMoreComponent,
        FilterDataSourceWithState,
        ContextHelpModule,
        MapperPipeModule,
    ],
    declarations: [DashboardFilterSetComponent, FilterSetAccordionComponent, FilterSetPanelComponent],
    exports: [DashboardFilterSetComponent],
})
export class DashboardFilterSetModule {}
