import { Component, Input, OnInit } from '@angular/core';
import { FeatureToggleService } from '@app/services';
import { faHandsHelping } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormElementComponent } from './form-element/form-element.component';

@Component({
    selector: '[app-voc-form]',
    templateUrl: './voc-form.component.html',
    styleUrls: ['./voc-form.component.scss'],
})
export class VocFormComponent implements OnInit {
    @Input('app-voc-form') id: string;
    icons: Record<string, IconDefinition | CustomIcon> = {
        faHandsHelping: faHandsHelping,
    };
    showForms = true;
    constructor(private featuretoggleservice: FeatureToggleService, private modalService: NgbModal) {
        this.featuretoggleservice.isActive('VoiceOfCustomer', true).subscribe((res: any) => {
            this.showForms = res;
        });
    }

    ngOnInit(): void {}

    enableForm() {
        let modeVal = this.modalService.open(FormElementComponent, {
            centered: true,
            windowClass: 'voc-modal',
            backdrop: 'static',
            keyboard: false,
            size: 'sm',
        });
        modeVal.componentInstance.modalService = this.modalService;
    }

    enableHelpForm() {
        let elements = Array.from(document.querySelectorAll<HTMLElement>('[keepni-path-type]')).filter(item => {
            return item.getAttribute('keepni-path-type').startsWith('MODULE');
        });
        if (elements.length) {
            elements[0].click();
        }
        this.enableForm();
    }
}
