import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
    navigateTo: string;

    constructor(private router: Router, private location: Location) {
        this.navigateTo = this.router.getCurrentNavigation()?.extras?.state?.navigateTo;
    }

    handleGoBack() {
        this.router.navigateByUrl(this.navigateTo);
    }

    handleRefresh() {
        this.location.back();
    }
}
