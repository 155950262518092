import { Component, ViewChild, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { CKEditorComponent } from 'ngx-ckeditor';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LazyLoadingScriptService } from '@dagility-ui/shared-components';
import { catchError, mapTo } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FilesAttacherComponent } from '@app/shared/components/files-attacher/files-attacher.component';
import { AnnouncementService, AnnouncementData } from '../announcement.service';
import { RecipientsEditorComponent } from '../../recipients-editor/recipients-editor.component';
import { RecipientsEditorService } from '../../recipients-editor/recipients-editor.service';

@Component({
    selector: 'app-announcement-settings-modal',
    templateUrl: './announcement-settings-modal.component.html',
    styleUrls: ['./announcement-settings-modal.component.scss'],
})
export class AnnouncementSettingsModalComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('editor') ckEditor: CKEditorComponent;
    @ViewChild(RecipientsEditorComponent) recipientsEditorComponent: RecipientsEditorComponent;

    title: string = 'New Announcement';
    ckeditorData: string = '';

    weekDays = this.announcementService.weekDays;
    specificDayTypes = this.announcementService.specificDayTypes;
    importanceTypes = this.announcementService.importanceTypes;
    categoryTypes = this.announcementService.categoryTypes;
    hideBannerTypes = this.announcementService.hideBannerTypes;
    acceptanceTypes = this.announcementService.acceptanceTypes;

    dateTime: Date = null;
    recipientTypes: { label: string; value: any }[] = [
        { label: 'Portfolios', value: 'portfolios' },
        { label: 'Applications', value: 'applications' },
        { label: 'Teams', value: 'teams' },
    ];

    repeatsPeriodTypes: { label: string; value: any }[] = this.announcementService.repeatTypes;
    remindRecipientTypesFrom: { label: string; value: any }[] = this.announcementService.remindTypesFrom;
    remindRecipientTypesTo: { label: string; value: any }[] = this.announcementService.remindTypesTo;

    @ViewChild(FilesAttacherComponent) fileAttachments: FilesAttacherComponent;

    previewData?: AnnouncementData;
    announcementForm?: FormGroup;
    readonly editorOptions = {
        toolbarGroups: [
            { name: 'basicstyles', groups: ['basicstyles'] },
            { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'paragraph'] },
            { name: 'links', groups: ['links'] },
            { name: 'customGroup' },
        ],
        removeButtons: 'Strike,Subscript,Superscript,Unlink,Anchor',
        removePlugins: 'elementspath',
        extraPlugins: 'edgeopsemailnotifications',
        entities: false,
    };

    private ckEditorScriptReady$ = this.lazyLoad.loadScript('/assets/js/ckeditor/ckeditor.js');
    ckEditorLoaded$ = this.ckEditorScriptReady$.pipe(
        mapTo(true),
        catchError(() => of(false))
    );

    destroy$ = new Subject();
    constructor(
        private announcementService: AnnouncementService,
        private recipientsEditorService: RecipientsEditorService,
        public modal: NgbActiveModal,
        private fb: FormBuilder,
        private lazyLoad: LazyLoadingScriptService
    ) {}

    ngOnInit() {
        this.previewData = this.announcementService.getPreviewAnnouncementData();
        this.resetToInitialState();
    }

    setDateTo(event: any) {
        console.log('event', event);
    }

    setDateFrom(event: any) {
        console.log('event', event);
    }

    getDateFormat(): string {
        const includeTime = this.announcementForm.get('includeTimeTrigger').value;
        return `yyyy-MM-dd${includeTime ? ' HH:MM aa' : ''}`;
    }
    getTriggerValue(name: string): boolean {
        return this.announcementForm.get(name).value;
    }

    ngAfterViewInit() {
        this.recipientsEditorComponent.onRolesChange();
    }

    resetToInitialState() {
        this.announcementForm = this.fb.group({
            dateTo: new FormControl(new Date()),
            dateFrom: new FormControl(new Date()),
            remindTo: new FormControl({}),
            remindFrom: new FormControl({}),
            endDateTrigger: new FormControl(true),
            includeTimeTrigger: new FormControl(true),
            recurringEventTrigger: new FormControl(true),
            repeatsCount: new FormControl(1),
            occurenceLimit: new FormControl(1),
            repeatsPeriod: new FormControl('Weeks'),
            endType: new FormControl('never'),
            repeatDayOfMonth: new FormControl(this.specificDayTypes[0].value),
            endDate: new FormControl(new Date()),
            hideBanner: new FormControl(''),
            acceptanceType: new FormControl(''),
            importance: new FormControl('', Validators.required),
            category: new FormControl('', Validators.required),
            text: new FormControl('', Validators.required),

            ...this.recipientsEditorService.getDefaultFormGroup(),
        });
    }

    onSelectEndType(event: any) {
        this.announcementForm.controls['endType'].setValue(event.target.name);
    }

    onChangeEditor(data: any) {
        this.ckeditorData = data;
    }

    onSave() {
        /**/
    }

    onCancel() {
        this.modal.dismiss('data saved');
    }

    onClose() {
        this.modal.dismiss('data saved');
    }

    ngOnDestroy() {
        this.destroy$.next();
    }
}
