import { Component, Input } from '@angular/core';

interface PortfolioStatistics {
    itemName: string;
    itemCount: number;
    total: number;
    grade: 'a' | 'b' | 'c';
}

@Component({
    selector: 'dp-portfolio-statistics-widget',
    templateUrl: './portfolio-statistics-widget.component.html',
    styleUrls: ['./portfolio-statistics-widget.component.scss'],
})
export class PortfolioStatisticsWidgetComponent {
    @Input() data: PortfolioStatistics[];
}
