import { Inject, Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ENV_TOKEN } from '@app/tokens';

@Injectable({
    providedIn: 'root',
})
export class PlatformSettingsService {
    private readonly apiUrl = this.env.adminApiURL + '/platform-settings';

    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {}

    getAllSettings(): Observable<Record<string, any>> {
        return this.http.get<Record<string, any>>(`${this.apiUrl}`);
    }

    getValue(key: string): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/${key}`);
    }

    setValue(properties: PlatformProperty[]) {
        return this.http.put(`${this.apiUrl}`, properties);
    }
}

export interface PlatformProperty {
    key: string;
    value: any;
}

export interface RouteProperty {
    route: string;
    label: string;
}

export interface DashboardProperty {
    id: string;
    name: string;
}

export const updatePluginsPropName = 'UPDATE_PLUGIN_ON_STARTUP';
export const configureScopeOfInterestPropName = 'CONFIGURE_SCOPE_OF_INTEREST';
export const showWelcomeTourPropName = 'SHOW_WELCOME_TOUR';
export const redirectToDefaultDashboardPropName = 'REDIRECT_AFTER_FIRST_LOGIN_TO_DEFAULT_DASHBOARD';
export const defaultLandingPagePropName = 'DEFAULT_LANDING_PAGE';
export const defaultDashboardPropName = 'DEFAULT_DASHBOARD';
