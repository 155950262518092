import { Directive, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { merge } from 'rxjs';
import { map } from 'rxjs/operators';

import { FieldTypes, MetaInformation } from './models/common';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractJobDefinitionTemplate<T = any, Y extends AbstractControl = FormGroup> {
    @Input() formSlice: Y;
    @Input() definition: T;
    @Input() meta: MetaInformation;

    fieldTypes: FieldTypes;

    formChanges = (fieldTypes: FieldTypes) =>
        merge(
            ...Object.keys(fieldTypes)
                .filter(key => ['string', 'enum', 'number', 'boolean'].includes(fieldTypes[key].type))
                .map(key => this.formSlice.get(key).valueChanges.pipe(map(value => ({ key, value }))))
        );

    handleNumericChange({ target: { value } }: any, key: string) {
        (this.definition as Record<string, any>)[key] = value === '' ? null : +value;
        this.formSlice.get(key).setValue((this.definition as Record<string, any>)[key], {
            emitEvent: false,
        });
    }
}
