import { CiCdFieldDescription } from '@dagility-ui/shared-components';

export interface IApplicationSourceShort {
    id: number;
    name: string;
}

export interface IApplicationSourceShortAndCount extends IApplicationSourceShort {
    templateComponentCount: number;
    componentCount: number;
    readOnly: boolean;
}

export interface IApplicationSourceConfiguration extends IApplicationSourceShort {
    sourceId: string;
    templateId: number;
    fields: CiCdFieldDescription[];
    pluginServices: string[];
    description: string;
    readOnly: boolean;
}

export interface SourceFolderItem {
    name: string;
    size: number;
    lastModifiedDate: Date;
    version: string;
    isFolder: boolean;
    contentType: string;
}

export enum SourcesBelongType {
    PROJECT_COMPONENT = 'PROJECT_COMPONENT',
    TEMPLATE_COMPONENT = 'TEMPLATE_COMPONENT',
    SHARED_LIBRARY = 'SHARED_LIBRARY',
}
