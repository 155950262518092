import { Command } from './commands.model';

export class CommandHistory {
    history: Array<Command> = [];

    push(command: Command) {
        this.history.push(command);
    }

    pop() {
        return this.history.pop();
    }

    getHistory() {
        return this.history;
    }

    reset() {
        this.history = [];
    }

    isEmpty() {
        return this.history.length === 0;
    }
}
