import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

import { Placeholders, WidgetTile } from 'data-processor';

@Component({
    selector: 'dp-widget-tiles[new]',
    templateUrl: './widget-tiles-new.component.html',
    styleUrls: ['./widget-tiles-new.component.scss'],
})
export class WidgetTilesNewComponent implements OnInit {
    @Input() placeholders: Placeholders & Partial<{ tiles: WidgetTile[] }>;
    @Input() position: string;

    @Output() drilldown = new EventEmitter<WidgetTile>();

    horizontalOrientation: boolean = false;

    @HostBinding('class.vertical-tiles') get isVertical() {
        return !this.horizontalOrientation;
    }

    get tiles() {
        return this.placeholders?.tiles ?? [];
    }

    ngOnInit() {
        this.horizontalOrientation = this.position === 'top' || this.position === 'bottom';
    }

    handleTileClicked(tile: WidgetTile) {
        this.drilldown.emit(tile);
    }

    getTruncatedElements(el: Element) {
        return Array.from(el.querySelectorAll('.tile-block .text-truncate'));
    }
}
