<div class="editor" [class.not-visible]="!isViewEditor">
    <app-qb-model-expression-helper
        [selection]="selection"
        [cursor]="expressionCursor"
        [active]="!isSwitchFunctionEditor || isGroovyMode"
        [expression]="expressionString"
        (expressionChanged)="onExpressionChanged($event)"
    ></app-qb-model-expression-helper>

    <app-qb-model-expression-helper-modal
        [cursor]="expressionCursor"
        [active]="isSwitchFunctionEditor && !isGroovyMode"
        [expression]="expressionString"
        (expressionChanged)="onExpressionChanged($event)"
    ></app-qb-model-expression-helper-modal>

    <app-expression-helper-menu [mode]="expressionMode" (cancel)="onCancel()" (apply)="onApply()"></app-expression-helper-menu>

    <lib-input
        id="expressionInputRef"
        [clearable]="false"
        placeholder="expression"
        [formControl]="expressionControl"
        [propertiesCompletion]="[]"
        class="expression-input"
        [readonly]="isSwitchFunctionEditor && !isGroovyMode"
        (click)="onClickInput()"
        (keydown)="onKeyPressed($event)"
        (input)="onInput()"
        (mouseup)="onInputMouseUp()"
    ></lib-input>
</div>
