import { FormControl, FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { isPlainObject } from 'lodash';
import { GridApi } from '@ag-grid-community/core';

import { isDefined } from '@dagility-ui/kit';

import { BaseWidgetFilter } from '../components/widget/filters/base-widget-filter.component';
import {
    AnyWidgetModel,
    DropdownFilter,
    HiddenFilter,
    TextColumnFilterType,
    WidgetColorThreshold,
    WidgetEventHandler,
    WidgetFilter,
    widgetFilterMatcher,
    WidgetFilterType,
    WidgetType,
} from '../models/any-widget.model';
import { WidgetScriptExecutor } from './widget-script.executor';
import { moveLineFieldsToOldFields } from './widget.lines';

export enum BasePlaceholders {
    PAGEABLE_PLACEHOLDER = 'pageable',
    SORT_PLACEHOLDER = 'sort',
    FILTER_PLACEHOLDER = 'filter',
    GRID_ELEMENTS_COUNT = 'totalElements',
    OPTIONS = '_options',
    RESULT = 'result',
    LEGEND_STATE = 'legend_filter',
}

export enum ThresholdRelatedColors {
    CRITICAL = 'var(--da-error-base)',
    MEDIUM = 'var(--da-warning-base)',
    SUCCESS = 'var(--da-success-base)',
    WITH_ISSUES = 'var(--da-gray-3)',
}

const DEFAULT_PAGING = {
    offset: 0,
    limit: 10,
};

export function getFiltersForm({
    filters,
    scriptExecutor,
    defaultValuesMap,
    gridWithPagination = false,
    initDefaultValues = true,
    complexNamedDropdown = false,
    placeholders,
    useDefaultFromPlaceholders,
}: {
    filters: WidgetFilter[];
    defaultValuesMap: Record<string, any>;
    scriptExecutor: WidgetScriptExecutor;
    gridWithPagination?: boolean;
    initDefaultValues?: boolean;
    complexNamedDropdown?: boolean;
    placeholders?: Record<string, any>;
    useDefaultFromPlaceholders?: boolean;
}): FormGroup {
    const form = new FormGroup(
        (filters || []).reduce<Record<string, any>>((acc, filter) => {
            const filterValueFromDrilldown = initDefaultValues
                ? (defaultValuesMap || {})[filter.placeholder] ||
                  (useDefaultFromPlaceholders ? placeholders?.[filter.placeholder] : undefined)
                : undefined;
            let defaultValueObj: any = null;
            if (filter.defaultValue && filter.type !== WidgetFilterType.DROPDOWN && initDefaultValues) {
                if (filterValueFromDrilldown && placeholders) {
                    defaultValueObj = filterValueFromDrilldown;
                    delete (defaultValuesMap || {})[filter.placeholder];
                } else {
                    defaultValueObj = scriptExecutor.evaluateScript(
                        {
                            context: {
                                placeholders: placeholders ?? {},
                            },
                            expression: filter.defaultValue,
                            type: 'default value',
                            placeholder: filter.placeholder,
                        },
                        {
                            logError: true,
                        }
                    );
                }
            }

            acc[filter.placeholder] = new FormControl(
                filterValueFromDrilldown !== undefined
                    ? filter.type === WidgetFilterType.DROPDOWN && (filter as DropdownFilter).multiple
                        ? filterValueFromDrilldown !== null
                            ? Array.isArray(filterValueFromDrilldown)
                                ? filterValueFromDrilldown
                                : [filterValueFromDrilldown]
                            : []
                        : filterValueFromDrilldown
                    : widgetFilterMatcher({
                          DROPDOWN: ({ defaultValue }) => null,
                          RANGE: ({ defaultValue }) => {
                              if (defaultValue) {
                                  if (!defaultValueObj || !isPlainObject(defaultValueObj)) {
                                      return {
                                          minimum: null,
                                          maximum: null,
                                      };
                                  }

                                  return {
                                      minimum: BaseWidgetFilter.transformDate(defaultValueObj.minimum),
                                      maximum: BaseWidgetFilter.transformDate(defaultValueObj.maximum),
                                  };
                              }
                              const now = new Date();
                              const minimum = new Date();
                              minimum.setDate(now.getDate() - 6);

                              return {
                                  minimum: BaseWidgetFilter.transformDate(minimum),
                                  maximum: BaseWidgetFilter.transformDate(now),
                              };
                          },
                          DATE: ({ defaultValue }) => {
                              if (defaultValue) {
                                  return BaseWidgetFilter.transformDate(defaultValueObj);
                              }

                              return null;
                          },
                          INPUT: ({ defaultValue }) => {
                              if (defaultValue) {
                                  return defaultValueObj;
                              }

                              return null;
                          },
                          CHECKBOX: ({ defaultValue }) => {
                              if (defaultValue) {
                                  return defaultValueObj;
                              }

                              return null;
                          },
                          HIDDEN: () => null,
                          NEW_RANGE: ({ defaultValue }) => {
                              if (defaultValue) {
                                  return defaultValueObj;
                              }

                              return null;
                          },
                          COMPLEX_NAMED_DROPDOWN: () => null,
                          RADIO_GROUP: ({ defaultValue }) => {
                              if (defaultValue) {
                                  return defaultValueObj;
                              }

                              return null;
                          },
                          _: () => null,
                      })(filter)
            );

            return acc;
        }, {})
    );

    if (gridWithPagination) {
        form.addControl(
            BasePlaceholders.PAGEABLE_PLACEHOLDER,
            (defaultValuesMap || {})[BasePlaceholders.PAGEABLE_PLACEHOLDER] ?? new FormControl(DEFAULT_PAGING)
        );
        form.addControl(
            BasePlaceholders.SORT_PLACEHOLDER,
            new FormControl((defaultValuesMap || {})[BasePlaceholders.SORT_PLACEHOLDER] ?? [])
        );
        form.addControl(
            BasePlaceholders.FILTER_PLACEHOLDER,
            new FormControl((defaultValuesMap || {})[BasePlaceholders.FILTER_PLACEHOLDER] ?? {})
        );
    }

    if (complexNamedDropdown) {
        form.addControl('complexNamedDropdown', new FormControl(true));
    }

    return form;
}

export class SandBox {
    private readonly blanklist: any[] = [];
    private readonly blacklist: any[] = [];
    private readonly listlen: number = 0;
    private readonly whitelist = ['Math', 'Number', 'Object', 'Boolean', 'Array', 'console', 'Date', 'Intl', 'Set', 'Map', 'JSON'];

    constructor() {
        this.blacklist = Object.getOwnPropertyNames(window).filter(x => !this.whitelist.includes(x) && !/^[^a-zA-Z]|\W/.test(x));
        this.listlen = this.blacklist.length;
        this.blanklist = new Array(this.listlen + 1).fill(undefined);
    }

    buildFn(...args: any[]) {
        'use-strict';
        const fnBlackList = [...this.blacklist, ...args];
        fnBlackList[fnBlackList.length - 1] = '"use-strict";' + args[args.length - 1];
        const newFunc = Function.apply(Function, fnBlackList);
        fnBlackList.length = this.listlen;
        return newFunc.bind.apply(newFunc, this.blanklist as any)();
    }
}

export function isDynamicFilter(f: WidgetFilter): f is DropdownFilter | HiddenFilter {
    return [WidgetFilterType.DROPDOWN, WidgetFilterType.HIDDEN, WidgetFilterType.RADIO_GROUP].includes(f.type);
}

export function isDropdownFilter(f: WidgetFilter): f is DropdownFilter {
    return f.type === WidgetFilterType.DROPDOWN || f.type === WidgetFilterType.RADIO_GROUP;
}

export function isWidgetWithSeries(type: WidgetType) {
    return [
        WidgetType.LINE_CHART,
        WidgetType.BAR_CHART,
        WidgetType.SCATTER_CHART,
        WidgetType.PROGRESS,
        WidgetType.MULTIPLE_Y_AXIS,
        WidgetType.ACCORDION_WITH_TABS,
        WidgetType.PI_GANTT,
        WidgetType.METRIC_LINE,
        WidgetType.MULTIPLE_SERIES_TYPE,
    ].includes(type);
}

export function isWidgetWithColors(type: WidgetType) {
    return (
        (isWidgetWithSeries(type) && ![WidgetType.ACCORDION_WITH_TABS, WidgetType.PI_GANTT].includes(type)) ||
        [WidgetType.PIE_CHART, WidgetType.DOUGHNUT_CHART].includes(type)
    );
}

export function isBarChartWidget(type: WidgetType) {
    return [WidgetType.BAR_CHART, WidgetType.STACKED_BAR_CHART].includes(type);
}

export function isDynamicWidthFilter(type: WidgetFilterType) {
    return [WidgetFilterType.DROPDOWN, WidgetFilterType.INPUT].includes(type);
}

export const getWidgetWidth = (defaultWidth: number) => (width: string | number | undefined) => {
    if (!isDefined(width)) {
        return defaultWidth;
    }
    let newWidth = 0;

    if (typeof width === 'string') {
        newWidth = parseInt(width, 10);
    } else if (typeof width === 'number') {
        newWidth = width;
    }

    if (width <= 0) {
        return defaultWidth;
    }

    return newWidth;
};

export function hasTooltipFormatter(type: WidgetType) {
    return [
        WidgetType.BAR_CHART,
        WidgetType.BOXPLOT,
        WidgetType.DOUGHNUT_CHART,
        WidgetType.PIE_CHART,
        WidgetType.LINE_CHART,
        WidgetType.NESTED_PIE_CHART,
        WidgetType.STACKED_BAR_CHART,
        WidgetType.SUNBURST_CHART,
        WidgetType.TREEMAP,
        WidgetType.MULTIPLE_Y_AXIS,
        WidgetType.SCATTER_CHART,
        WidgetType.METRIC_LINE,
        WidgetType.MULTIPLE_SERIES_TYPE
    ].includes(type);
}

export function parseError(e: unknown): string {
    if (typeof e === 'string') {
        return e;
    }

    if (e instanceof HttpErrorResponse) {
        return e.error.message ?? e.message;
    }

    if (e instanceof Error) {
        return e.message;
    }

    return JSON.stringify(e);
}

export class SilenceWidgetError {}

export function createFakeWidget(data: {
    filters: WidgetFilter[];
    name: string;
    handlers: WidgetEventHandler[];
    server: boolean;
    complexNamedDropdown?: boolean;
    complexNamedDropdownLabel?: string;
    complexNamedDropdownDependencies?: string;
    complexNamedDropdownFilterDependency?: string;
}): AnyWidgetModel {
    const model: AnyWidgetModel = {
        toolCategoryType: null,
        filters: data.filters ?? [],
        type: WidgetType.BAR_CHART,
        query: [],
        series: [],
        colorThreshold: [],
        chartOptions: {
            title: `${data.name} Dashboard Filters`,
            description: 'desc',
            orientation: 'Horizontal',
        } as any,
        drilldownList: [],
        widgets: [],
        tiles: [],
        server: !!data.server,
        handlers: data.handlers ?? [],
        complexNamedDropdown: !!data.complexNamedDropdown,
        complexNamedDropdownLabel: data.complexNamedDropdownLabel,
        complexNamedDropdownDependencies: data.complexNamedDropdownDependencies,
        complexNamedDropdownFilterDependency: data.complexNamedDropdownFilterDependency,
    };
    moveLineFieldsToOldFields(model);

    return model;
}

export function hasHeader(type: WidgetType) {
    return [WidgetType.LOG, WidgetType.PROGRESS, WidgetType.TILE_CHART, WidgetType.ACCORDION].includes(type);
}

export function hasStatusValueAndIcon(type: WidgetType) {
    return [WidgetType.PI_FEATURE_COMPLETION_STATUS].includes(type);
}

export function hasStatusOrderAndStatusColor(type: WidgetType) {
    return [WidgetType.PI_ISSUE_LIFE_CYCLE].includes(type);
}

export function randomColor() {
    const r = Math.floor(Math.random() * 210);
    const g = Math.floor(Math.random() * 210);
    const b = Math.floor(Math.random() * 210);
    return `rgb(${r},${g},${b})`;
}

export function randomLightColor() {
    const r = Math.floor((256 - 200) * Math.random()) + 201;
    const g = Math.floor((256 - 200) * Math.random()) + 201;
    const b = Math.floor((256 - 200) * Math.random()) + 201;
    return `rgb(${r},${g},${b})`;
}

export function convertHexToRGBA(hex: string, alpha: number) {
    return `rgba(${parseInt(hex.slice(1, 3), 16)}, ${parseInt(hex.slice(3, 5), 16)}, ${parseInt(hex.slice(5, 17), 16)}, ${alpha})`;
}

export function isGridWithPagination(widget: AnyWidgetModel) {
    return widget.type === WidgetType.TABLE && !!widget.chartOptions.gridPagination;
}

export function isServerSideGridWithPagination(widget: AnyWidgetModel) {
    return isGridWithPagination(widget) && widget.chartOptions.serverSidePagination;
}

export function hasInfiniteScroll({ type }: AnyWidgetModel) {
    return ['piFeatureCompletionStatus', 'accordionWithTabs'].includes(type);
}

export function hasAdditionalTemplates(type: WidgetType) {
    return [
        WidgetType.BAR_CHART,
        WidgetType.LINE_CHART,
        WidgetType.STACKED_BAR_CHART,
        WidgetType.RADIAL,
        WidgetType.HEALTH_SCORE,
        WidgetType.SCATTER_CHART,
        WidgetType.DOUGHNUT_CHART,
        WidgetType.COMPLEX,
        WidgetType.TABLE,
        WidgetType.MULTIPLE_SERIES_TYPE
    ].includes(type);
}

export function hasHideLegendControl(type: WidgetType) {
    return [
        WidgetType.LINE_CHART,
        WidgetType.BAR_CHART,
        WidgetType.SCATTER_CHART,
        WidgetType.STACKED_BAR_CHART,
        WidgetType.RADIAL,
        WidgetType.METRIC_LINE,
        WidgetType.DOUGHNUT_CHART,
    ].includes(type);
}

export function hasGridOptions(type: WidgetType) {
    return [WidgetType.LINE_CHART].includes(type);
}

export function hasThreshold(type: WidgetType) {
    return [
        WidgetType.BAR_CHART,
        WidgetType.STACKED_BAR_CHART,
        WidgetType.LINE_CHART,
        WidgetType.RADIAL,
        WidgetType.HEALTH_SCORE,
        WidgetType.TREEMAP,
        WidgetType.SCORE_DOUGHNUT,
        WidgetType.METRIC_LINE,
    ].includes(type);
}

export function isPiWorkChart(type: WidgetType) {
    return [WidgetType.PI_WORK_CHART, WidgetType.PI_WORK_DISTRIBUTION].includes(type);
}

export function buildFilterExpression(filterModel: ReturnType<GridApi['getFilterModel']>) {
    if (!filterModel || !Object.keys(filterModel).length) {
        return '';
    }

    return Object.entries(filterModel)
        .map(([field, filter]) => {
            switch (filter.type as TextColumnFilterType) {
                case TextColumnFilterType.EQUALS: {
                    return `${field} = ${filter.filter}`;
                }
                case TextColumnFilterType.STARTS_WITH: {
                    return `${field} ILIKE '${filter.filter}%'`;
                }
            }
        })
        .join(' AND ');
}

export function resetGridPagination(form: FormGroup) {
    form.get(BasePlaceholders.PAGEABLE_PLACEHOLDER)?.patchValue(DEFAULT_PAGING, { emitEvent: false });
    form.get(BasePlaceholders.SORT_PLACEHOLDER)?.patchValue([], { emitEvent: false });
    form.get(BasePlaceholders.FILTER_PLACEHOLDER)?.patchValue({}, { emitEvent: false });
}

export function buildFilterDependenciesMap(filters: WidgetFilter[]) {
    return (filters || []).reduce<Record<string, string[]>>((acc, filter) => {
        ((filter as DropdownFilter).dependencies || []).forEach(placeholder => {
            if (!acc[placeholder]) {
                acc[placeholder] = [];
            }

            acc[placeholder].push(filter.placeholder);
        });

        return acc;
    }, {});
}

export function getColorForThresholdRelatedChart(
    value: number,
    higherIsBetter: boolean,
    mediumThreshold: number,
    criticalThreshold: number,
    thresholds?: WidgetColorThreshold[]
): string {
    if (!!criticalThreshold && !!mediumThreshold && !thresholds?.length) {
        if (higherIsBetter) {
            thresholds = [
                { from: -Infinity, to: mediumThreshold, includingFrom: false, includingTo: false, color: ThresholdRelatedColors.CRITICAL },
                {
                    from: mediumThreshold,
                    to: criticalThreshold,
                    includingFrom: false,
                    includingTo: false,
                    color: ThresholdRelatedColors.MEDIUM,
                },
                { from: criticalThreshold, to: Infinity, includingFrom: false, includingTo: false, color: ThresholdRelatedColors.SUCCESS },
            ];
        } else {
            thresholds = [
                { from: -Infinity, to: mediumThreshold, includingFrom: false, includingTo: false, color: ThresholdRelatedColors.SUCCESS },
                {
                    from: mediumThreshold,
                    to: criticalThreshold,
                    includingFrom: false,
                    includingTo: false,
                    color: ThresholdRelatedColors.MEDIUM,
                },
                { from: criticalThreshold, to: Infinity, includingFrom: false, includingTo: false, color: ThresholdRelatedColors.CRITICAL },
            ];
        }
    }

    if (thresholds) {
        for (const threshold of thresholds) {
            const lowBorder = threshold.includingFrom ? value >= +threshold.from : value > +threshold.from;
            const highBorder = threshold.includingTo ? value <= +threshold.to : value < +threshold.to;
            if (lowBorder && highBorder) {
                return threshold.color;
            }
        }
    }
    return '';
}

export function findStringInObject(obj: any, searchStr: string): boolean {
    if (!obj) {
        return;
    }

    return Object.keys(obj).some(key => {
        if (typeof obj[key] === 'string') {
            return obj[key].toLowerCase().includes(searchStr);
        }

        if (typeof obj[key] === 'object') {
            return findStringInObject(obj[key], searchStr);
        }
    });
}

export function removeHTMLTags(value: string) {
    return value.replace(/<\/?[^>]+(>|$)/g, '').replace('&amp;', '&');
}

export function checkWidgetIsValid(widget: AnyWidgetModel): boolean {
    if (!widget.type) {
        return false;
    }

    if (widget.type === WidgetType.COMPLEX) {
        return (widget.widgets || []).every(checkWidgetIsValid);
    }

    return !!widget.query && !!widget.query.length;
}
