import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { pickBy } from 'lodash';
import { ENV_TOKEN } from '@app/tokens';
import { JobsOfProjectsRequest } from '../api/ci-cd-summary.service';
import { Workflow } from './workflows.component';

@Injectable()
export class WorkflowsService {
    baseApi = `${this.env.buildApiURL}/ci-cd`;

    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {}

    getWorkflows(): Observable<Workflow[]> {
        return this.http.get<Workflow[]>(this.baseApi + '/workflows');
    }

    getWorkflowsByProjectId(projectId: string): Observable<Workflow[]> {
        return this.http.get<Workflow[]>(this.baseApi + `/workflows/${projectId}`);
    }

    runWorkflow(projectId: number, workflowId: number) {
        return this.http.post<any>(`${this.baseApi}/projects/${projectId}/workflow/${workflowId}/run`, {});
    }

    stopWorkflow(projectId: number, workflowId: number) {
        return this.http.post<any>(`${this.baseApi}/projects/${projectId}/workflow/${workflowId}/stop`, {});
    }

    getWorkflowSummary(request: JobsOfProjectsRequest): Observable<any> {
        return this.http.post<any>(
            `${this.baseApi}/workflows/summary`,
            pickBy(request, val => !(val === undefined || val === '' || (Array.isArray(val) && !val.length)))
        );
    }

    getWorkflowSummaryFilters(): Observable<any> {
        return this.http.get<any>(`${this.baseApi}/workflows/summary/filters`, {});
    }

    getWorkflowSummaryTiles(request: JobsOfProjectsRequest): Observable<WorkflowSummaryMetrics> {
        return this.http.post<WorkflowSummaryMetrics>(
            `${this.baseApi}/workflows/summary/tiles`,
            pickBy(request, val => !(val === undefined || val === '' || (Array.isArray(val) && !val.length)))
        );
    }
}

export interface WorkflowSummaryMetrics {
    workflows: number[];
    duration: number;
    succeeded: number[];
    withWorkflow: number;
    withNoWorkflow: number;
    needsAttention: number[];
}
