import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AbstractJobDefinitionTemplate } from '../abstract-job-definition-template';
import { Condition, Operand } from '../job-definition-builder/edit-workflow-action/models/operand';
import { OperandType } from '../job-definition-builder/edit-workflow-action/models/operand-type';
import { createFormGroup, enumToArray } from '../utils';
import { EditJobDefinitionService } from 'data-processor/lib/processor-monitoring/edit-job-definition-form/edit-job-definition.service';

@Component({
    selector: 'dp-edit-operand-action',
    templateUrl: './edit-operand-action.component.html',
    styleUrls: ['../templates.scss'],
})
export class EditOperandActionComponent extends AbstractJobDefinitionTemplate<Operand> implements OnInit, OnDestroy {
    get type() {
        return this.formSlice.get('type').value;
    }

    readonly types = enumToArray(OperandType);
    readonly operandType: typeof OperandType = OperandType;
    readonly destroyed$ = new Subject<void>();

    constructor(public store: EditJobDefinitionService) {
        super();
    }

    ngOnInit() {
        this.listenTypeChange();
    }

    goToStep(step: string) {
        this.store.goToStep([step]);
    }

    private listenTypeChange() {
        this.formSlice
            .get('type')
            .valueChanges.pipe(takeUntil(this.destroyed$))
            .subscribe((type: OperandType) => {
                this.definition.type = type;

                if (type === OperandType.CONDITION) {
                    this.definition.condition = new Condition();
                    this.definition.value = null;
                    this.formSlice.setControl(
                        'condition',
                        createFormGroup(this.store.fb, this.definition.condition, this.definition.condition, true)
                    );
                    this.formSlice.removeControl('value');
                } else if (type === OperandType.VALUE) {
                    this.definition.value = [];
                    this.definition.condition = null;
                    this.formSlice.removeControl('condition');
                    this.formSlice.addControl('value', new FormArray([]));
                } else {
                    this.definition.condition = null;
                    this.definition.value = null;
                    this.formSlice.removeControl('condition');
                    this.formSlice.removeControl('value');
                }
            });
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }
}
