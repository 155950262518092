import { Deserializable } from '../../../models/job-definition';

export class LoopAction extends Deserializable {
    _class = {};
    _enums = {};
    _arrays = {};
    _maps = {};
    meta = {
        _class: this._class,
        _enums: this._enums,
        _arrays: this._arrays,
        _maps: this._maps,
    };

    variable = '';
    item = '';

    constructor(instance?: any) {
        super();
        this.fillByInstance(LoopAction, instance);
    }
}
