import { NgModule } from '@angular/core';

import { PortfolioHealthComponent } from './portfolio-health/portfolio-health.component';
import { PortfolioScoreWidgetComponent } from './portfolio-score-widget/portfolio-score-widget.component';
import { PortfolioScoresService } from './portfolio-scores.service';
import { GlobalScoreFilterComponent } from './global-score-filter/global-score-filter.component';
import { SharedComponentsModule } from '@dagility-ui/shared-components';

@NgModule({
    declarations: [PortfolioHealthComponent, PortfolioScoreWidgetComponent, GlobalScoreFilterComponent],
    imports: [SharedComponentsModule],
    exports: [PortfolioHealthComponent, PortfolioScoreWidgetComponent, GlobalScoreFilterComponent],
    providers: [PortfolioScoresService],
})
export class PortfolioScoresModule {}
