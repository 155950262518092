import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { ProjectSettingsService } from '@app/shared/services/plan/project-settings.service';
import { map, refCount, shareReplay, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PROJECT_MODULE_SEGMENT } from 'src/app/app-routing.constants';
import { PROJECT_EDIT_BACK_DEFAULT_ROUTE } from './project-edit.constants';

@Injectable({
    providedIn: 'root',
})
export class ProjectStore {
    projectName: string = '';
    projectName$: Observable<string>;
    projectId: number;
    projectExist$: Observable<boolean>;
    projectExist: boolean;
    projectPrefix: string;
    projectNameInitialized$ = new Subject<boolean>();

    previousUrl = PROJECT_EDIT_BACK_DEFAULT_ROUTE;

    constructor(private projectSettingsService: ProjectSettingsService, private router: Router) {}

    setProjectName(name: string) {
        this.projectName = name;
    }

    setPreviousUrl(previousUrl?: string) {
        this.previousUrl = previousUrl;
    }

    loadProjectName(projectId: number) {
        this.projectName$ = this.projectSettingsService.getProjectGeneralSettingsResponse(projectId).pipe(
            map(response => {
                if (response.status !== 204) {
                    this.projectPrefix = response.body.prefix;
                    return response.body.name;
                } else {
                    this.router.navigateByUrl(`not-found`, {
                        state: { navigateTo: `${PROJECT_MODULE_SEGMENT}` },
                    });
                    return null;
                }
            }),
            tap(projectName => {
                this.projectName = projectName;
                this.projectExist = !!projectName;
                this.projectExist$ = projectName ? of(true) : of(false);
                this.projectNameInitialized$.next();
            }),
            shareReplay(1),
            refCount()
        );
    }
}
