import { FormControl } from '@angular/forms';
import { ChangeDetectorRef, Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { map, shareReplay, skip, take, takeUntil } from 'rxjs/operators';

import { DateAdapter } from '@dagility-ui/shared-components';

import {
    ExecutionContext,
    getQueryExecutor,
    WidgetExecutionPosition,
    WidgetFlow,
    WidgetQueryExecutor,
    WidgetScript,
} from './widget-query.executor';
import { DropdownFilter, WidgetDrilldown, WidgetType } from '../models/any-widget.model';
import { convertGraphToAnyWidget, WidgetAction, WidgetGraph, WidgetLayer } from '../models/widget.graph';
import { WidgetBuilderStore } from './widget-builder.store';
import { getFiltersForm, isDynamicFilter, isServerSideGridWithPagination, resetGridPagination } from './widget-builder.util';
import { DrilldownEvent } from './widget.drilldown';
import { DefaultPlaceholders } from '../providers/default-placeholders.provider';
import { WidgetBuilderService } from './widget-builder.service';
import { WidgetLogger, WidgetScriptExecutor } from './widget-script.executor';
import { WidgetDebuggerState } from './widget.debugger';

@Injectable()
export class WidgetDebuggerContainer {
    debugger: WidgetDebugger;
}

export class WidgetDebugger {
    private position: WidgetExecutionPosition = this.getInitiallyPosition();

    private placeholders$ = new BehaviorSubject({});

    private destroyed$ = new Subject<void>();

    private currentLayer: WidgetLayer;

    private layers: string[] = [];

    private placeholdersHistory: any[] = [];

    private executor: WidgetQueryExecutor;

    breakpoints: Set<string> = new Set();

    context: ExecutionContext;

    subscription = Subscription.EMPTY;

    widget$ = new BehaviorSubject(null);

    currentPlaceholders$ = this.placeholders$.pipe(map(() => (this.context ? this.context.scriptContext : {})));

    get graph(): WidgetGraph {
        return this.store.value;
    }

    get logs$() {
        return this.logger.getLogs();
    }

    constructor(
        // NOSONAR
        private defaultPlaceholders$: DefaultPlaceholders,
        private debuggerState: WidgetDebuggerState,
        private store: WidgetBuilderStore,
        private options: Record<string, any>,
        private api: WidgetBuilderService,
        private scriptExecutor: WidgetScriptExecutor,
        private logger: WidgetLogger,
        private globalFiltersDebug: boolean,
        private cdr: ChangeDetectorRef,
        private dateAdapter: DateAdapter
    ) {
        this.debuggerState.dataSources = {};
        this.debuggerState.placeholders$ = this.placeholders$;

        this.init(this.graph.root);

        this.widget$.next(convertGraphToAnyWidget(this.graph.root, this.graph));

        this.debuggerState.filterChanged$.pipe(takeUntil(this.destroyed$)).subscribe(this.handleFilterChange);
        this.debuggerState.drilldown$.pipe(takeUntil(this.destroyed$)).subscribe(this.handleDrilldown);
    }

    reset() {
        this.placeholders$.next({});
        this.init(this.graph.root);
        this.widget$.next(convertGraphToAnyWidget(this.graph.root, this.graph));
        this.context = null;
        this.position = this.getInitiallyPosition();
        this.startLoadData([this.graph.root]);
    }

    async debug(defaultValues = true) {
        if (!this.context) {
            let defaultPlaceholders = null;
            try {
                const defaultPlaceholders$ = this.defaultPlaceholders$(
                    this.globalFiltersDebug
                        ? null
                        : {
                              ...this.widget$.value,
                              options: this.options,
                          },
                    true
                ).pipe(shareReplay(1));

                defaultPlaceholders = await defaultPlaceholders$.pipe(take(1)).toPromise();

                if (!this.dateAdapter) {
                    defaultPlaceholders.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                    const date = new Date();
                    date.setHours(0, 0, 0, 0);
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    defaultPlaceholders.local_start_day = date.getTime();
                } else {
                    defaultPlaceholders.timezone = this.dateAdapter.timezone;
                    defaultPlaceholders.local_start_day = this.dateAdapter.getStartOfDay();
                }

                this.subscription.unsubscribe();

                this.subscription = defaultPlaceholders$.pipe(skip(1), take(1)).subscribe(async () => {
                    this.reset();
                    await this.debug();
                    this.cdr.markForCheck();
                });
            } catch (e) {
                defaultPlaceholders = {};
            }

            this.context = new ExecutionContext({
                defaultPlaceholders,
                filters: {} as any,
                placeholders: cloneDeep(defaultPlaceholders) as any,
            });

            this.context.debug = true;
            this.context.position = this.position;
            this.context.breakpoints = this.breakpoints;
        }

        this.context.breakpoints = new Set(this.store.value.breakpoints);

        for (; this.position.widgetIndex < this.layers.length; this.position.widgetIndex++) {
            const layer = this.graph.layers[this.layers[this.position.widgetIndex]];
            this.context.filters = { ...this.debuggerState.filtersGroupMap[layer.id].value };

            if (
                defaultValues &&
                !!this.context?.position &&
                this.context.position.flow === WidgetFlow.FILTERS &&
                this.context.position.scriptIndex === WidgetScript.BEFORE &&
                this.context.position.filterIndex === 0
            ) {
                const filters = layer.filters.map(id => this.graph.filters[id]);
                const filterForm = this.debuggerState.filtersGroupMap[layer.id];
                const defaultValuesMap = getFiltersForm({
                    filters,
                    defaultValuesMap: {},
                    scriptExecutor: this.scriptExecutor,
                    gridWithPagination: false,
                    placeholders: this.context.placeholders,
                }).value;

                filters
                    .filter(filter => !isDynamicFilter(filter))
                    .forEach(filter => {
                        (filterForm.get(filter.placeholder) as FormControl).patchValue(defaultValuesMap[filter.placeholder], {
                            emitEvent: false,
                        });
                    });
            }

            this.context.filters = { ...this.debuggerState.filtersGroupMap[layer.id].value };

            this.executor = new (getQueryExecutor(layer as any))({
                api: this.api,
                scriptExecutor: this.scriptExecutor,
                logger: this.logger,
                queries: [],
            });

            if (layer.id !== this.currentLayer.id) {
                this.fillFilters();
            }

            while (this.position.flow < 2) {
                if (this.position.flow === WidgetFlow.FILTERS) {
                    for (; this.position.filterIndex < layer.filters.length; this.position.filterIndex++) {
                        const filter = this.graph.filters[layer.filters[this.position.filterIndex]];
                        if (!isDynamicFilter(filter)) {
                            continue;
                        }
                        let items = [];

                        if (filter.query) {
                            this.executor.queries = [this.graph.queries[filter.query]];

                            this.executor.filterPlaceholder = filter.placeholder;
                            const placeholders = await this.getPlaceholders();

                            this.context.position = this.position;

                            if (this.context.stopped && placeholders !== undefined) {
                                this.placeholders$.next({ ...this.context.placeholders });

                                return;
                            }
                            items = Array.isArray(placeholders) ? placeholders : placeholders?.result ?? [];
                            this.placeholders$.next({ ...this.context.placeholders });
                        } else {
                            items = (filter as DropdownFilter).items;
                        }

                        (this.debuggerState.dataSources[filter.placeholder] as Subject<any>).next(items);

                        if (defaultValues && filter.defaultValue) {
                            try {
                                const defaultValue = this.scriptExecutor.evaluateFilterDefaultValue(
                                    items,
                                    filter,
                                    this.context.placeholders
                                );
                                this.debuggerState.filtersGroupMap[this.currentLayer.id].get(filter.placeholder).patchValue(defaultValue);
                                this.fillFilters();
                            } catch (e) {}
                        }

                        this.position.scriptIndex = 0;
                        await new Promise(resolve => setTimeout(resolve));
                    }

                    ++this.position.flow;

                    this.position.filterIndex = 0;
                    this.position.index = 0;
                    this.position.scriptIndex = WidgetScript.BEFORE;
                    this.context.position = this.position;
                } else {
                    this.executor.filterPlaceholder = null;

                    await new Promise(resolve => setTimeout(resolve));
                    if (this.position.index === 0) {
                        this.fillFilters();
                    }

                    this.executor.queries = layer.queries.map(id => this.graph.queries[id]);
                    const placeholders = await this.getPlaceholders();

                    if (this.context.stopped && placeholders !== undefined) {
                        this.placeholders$.next({ ...placeholders });

                        return;
                    }

                    this.placeholders$.next({ ...this.context.placeholders });
                    (this.debuggerState.dataSources[layer.id] as Subject<any>).next(placeholders);

                    this.position.flow++;
                }
            }

            this.position.flow = WidgetFlow.FILTERS;
            this.position.filterIndex = 0;
            this.position.index = 0;
            this.position.scriptIndex = WidgetScript.BEFORE;
        }

        this.placeholders$.next(this.context.placeholders);
    }

    async resume() {
        return await this.debug();
    }

    destroy(): void {
        this.debuggerState.filtersGroupMap = {};
        this.destroyed$.next();
        this.subscription.unsubscribe();
    }

    private async getPlaceholders() {
        try {
            return await this.executor.execute(this.context);
        } catch (e) {
            return [];
        }
    }

    private fillFilters() {
        this.context.filters = {
            ...this.context.filters,
            ...this.debuggerState.filtersGroupMap[this.currentLayer.id].value,
        };
    }

    private init(layerId: string): void {
        this.currentLayer = this.graph.layers[layerId];
        const filters = this.currentLayer.filters.map(id => this.graph.filters[id]);
        this.layers = [layerId, ...this.currentLayer.widgets];

        try {
            this.layers.forEach(id => {
                this.debuggerState.filtersGroupMap[id] = getFiltersForm({
                    filters: this.graph.layers[id].filters.map(filterId => this.graph.filters[filterId]),
                    defaultValuesMap: this.debuggerState.filtersGroupMap[id] ? this.debuggerState.filtersGroupMap[id].value : {},
                    scriptExecutor: this.scriptExecutor,
                    gridWithPagination: isServerSideGridWithPagination(this.graph.layers[id] as any),
                });
            });
        } catch (e) {}

        [...this.layers, ...filters.map(({ placeholder }) => placeholder)].forEach(
            id => (this.debuggerState.dataSources[id] = this.debuggerState.dataSources[id] || new Subject())
        );
    }

    private getInitiallyPosition() {
        return {
            widgetIndex: 0,
            flow: WidgetFlow.FILTERS,
            index: 0,
            filterIndex: 0,
            scriptIndex: WidgetScript.BEFORE,
        };
    }

    private handleFilterChange = async ([layerId, placeholder]: [string, string]) => {
        if (this.context.position) {
            return;
        }
        let filterIndex = this.graph.layers[layerId].filters.findIndex(filterId => {
            const filter = this.graph.filters[filterId];

            return filter.placeholder === placeholder;
        });

        if (placeholder) {
            resetGridPagination(this.debuggerState.filtersGroupMap[layerId]);
        } else {
            filterIndex = this.graph.layers[layerId].filters.length;
        }

        this.position.widgetIndex = this.layers.indexOf(layerId);
        this.position.flow = WidgetFlow.FILTERS;
        this.position.filterIndex = filterIndex + 1;
        this.position.scriptIndex = WidgetScript.BEFORE;
        this.position.index = 0;
        this.context.position = this.position;

        this.startLoadData(layerId === this.currentLayer.id ? this.layers : [layerId]);

        await this.debug();
    };

    private handleDrilldown = async ({
        event,
        id,
        type,
        to,
    }: {
        event: DrilldownEvent;
        id: string;
        type: 'drilldown' | 'back';
        to: string;
    }) => {
        this.position.widgetIndex = 0;
        this.position.flow = WidgetFlow.FILTERS;
        this.position.filterIndex = 0;
        this.position.scriptIndex = WidgetScript.BEFORE;
        this.position.index = 0;
        this.context.position = this.position;

        if (type === 'drilldown') {
            const { data }: WidgetAction<WidgetDrilldown> = this.graph.actions.find(a => a.from === id && a.to === to);
            const scriptContext = {
                placeholders: {
                    ...this.placeholders$.value,
                    ...(this.debuggerState.filtersGroupMap[id] ? this.debuggerState.filtersGroupMap[id].value : {}),
                },
                event: event.payload,
            };

            this.scriptExecutor.evaluateScript({
                context: scriptContext,
                expression: data.drilldownScript,
                type: 'drilldown',
                placeholder: '',
            });
            this.placeholdersHistory.push({
                placeholders: cloneDeep(this.placeholders$.value),
                layer: this.currentLayer.type === WidgetType.COMPLEX ? this.currentLayer.id : id,
            });
            this.placeholders$.next({ ...scriptContext.placeholders });
            this.context.placeholders = this.placeholders$.value;
            this.init(to);
        } else {
            const { layer, placeholders } = this.placeholdersHistory.pop();

            this.placeholders$.next({ ...placeholders });
            this.context.placeholders = this.placeholders$.value;
            this.init(layer);
        }

        this.startLoadData(id === this.currentLayer.id ? this.layers : [id]);

        await this.debug(type !== 'back');
    };

    startLoadData(layerIds: string[]): void {
        layerIds.forEach((id: string) =>
            (this.debuggerState.dataSources[id] as Subject<any>).next({
                type: 'start',
            })
        );
    }
}
