import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiKitModule } from '@dagility-ui/kit';

import { ContextHelpComponent } from './context-help.component';
import { RemoveEmptyTagsDirective } from './remove-empty-tags.directive';

@NgModule({
    declarations: [ContextHelpComponent],
    imports: [CommonModule, UiKitModule, RemoveEmptyTagsDirective],
    exports: [ContextHelpComponent],
})
export class ContextHelpModule {}
