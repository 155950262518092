<div class="d-flex align-items-center px-1">
    <h2 class="my-0">Add widgets</h2>
    <button *ngIf="importAllowed" class="btn btn-primary ml-auto" (click)="handleImportWidget()">Import Widget</button>
</div>

<lib-stream-wrapper class="flex-1 d-flex flex-column overflow-hidden" [dataStream$]="data$">
    <ng-template contentTemplate>
        <lib-search class="py-4 d-block px-1" (searchText)="search$.next($event)"></lib-search>

        <cdk-virtual-scroll-viewport class="flex-1 mt-3 px-1" perfectScrollbar itemSize="141" [dpDisableScroll]="dragged">
            <dp-widget-list-item
                *cdkVirtualFor="let widget of widgets$ | async"
                class="border-bottom"
                ngxGridsterItemPrototype
                [w]="2"
                [h]="2"
                [widget]="widget"
                [dashboardMode]="true"
                [hideDescription]="hideDescription$ | async"
                [config]="{ helper: true }"
                (start)="start($event)"
                (enter)="over($event)"
                (out)="out($event)"
                (cancel)="cancel()"
                (drop)="drop($event, widget)"
            >
            </dp-widget-list-item>
        </cdk-virtual-scroll-viewport>
    </ng-template>
</lib-stream-wrapper>
