import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecipientsEditorComponent } from './recipients-editor.component';
import { RecipientsEditorService } from './recipients-editor.service';
import { SharedComponentsModule } from '@dagility-ui/shared-components';

@NgModule({
    imports: [CommonModule, SharedComponentsModule],
    declarations: [RecipientsEditorComponent],
    exports: [RecipientsEditorComponent],
    providers: [RecipientsEditorService],
})
export class RecipientsEditorModule {}
