import { isObject } from 'lodash';

import { WidgetType } from '../../../models/any-widget.model';
import { AnyWidgetChartModel, isTimeSeriesChartModel } from '../../query-to-options.mapper';
import { convertToGridData } from '../widget.exporter.util';

export const ELEMENT_DELIMITER = ',';

abstract class ToTableDataConverter {
    constructor(public readonly data: AnyWidgetChartModel) {}

    parseElement(element: any): string {
        if (Array.isArray(element)) {
            return `"${element.map(this.parseElement).join(ELEMENT_DELIMITER)}"`;
        }
        if (isObject(element)) {
            return `"${JSON.stringify(element)}"`;
        }

        if (typeof element === 'string' && /"|,|(\r\n|\n|\r)/.test(element)) {
            return `"${element.replace(new RegExp('"', 'g'), '""')}"`;
        }

        return element;
    }

    abstract process(widgetType?: WidgetType): string[][];
}

export class BarLineTable extends ToTableDataConverter {
    getSeriesData(data: unknown) {
        if (isTimeSeriesChartModel(this.data) && Array.isArray(data)) {
            return data[1];
        }

        if (typeof data === 'object' && data.hasOwnProperty('value')) {
            return (data as any).value;
        }

        return data.toString();
    }

    process() {
        const table = [[''].concat(this.data.options.categories)];
        for (let i = 0; i < this.data.options.series.length; i++) {
            const row = [this.data.options.series[i].name];
            const seriesLength = this.data.options.series[i].data.length;
            for (let j = 0; j < seriesLength; j++) {
                row.push(this.getSeriesData(this.data.options.series[i].data[j] ?? 0));
            }
            table.push(row);
        }

        return table;
    }
}

export class GridChartToTable extends ToTableDataConverter {
    process(): string[][] {
        const data = convertToGridData(this.data, 0);

        return data.map(row => row.map(item => this.parseElement(item)));
    }
}

export function toTableConverterFactory(widget: AnyWidgetChartModel) {
    const { type } = widget.options;

    if (type === WidgetType.LINE_CHART || type === WidgetType.BAR_CHART) {
        return new BarLineTable(widget);
    }

    if (type === WidgetType.TABLE) {
        return new GridChartToTable(widget);
    }

    throw new Error(`Export to grid data for ${type} is not provided`);
}
