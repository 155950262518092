import { Component, Input } from '@angular/core';
import { AnnouncementService, AnnouncementData } from '../announcement.service';
@Component({
    selector: 'app-announcement-banner',
    templateUrl: './announcement-banner.component.html',
    styleUrls: ['./announcement-banner.component.scss'],
})
export class AnnouncementBannerComponent {
    @Input() color: string = 'primary';
    @Input() preview: boolean = false;
    @Input() filled: boolean = false;
    @Input() data?: AnnouncementData;

    timeMessage: string = 'It will start in 00:00:00';
    constructor(private announcementService: AnnouncementService) {
        this.announcementService.message$.subscribe(data => {
            if (!this.preview) {
                this.data = data;
            }
        });
    }

    onClose() {
        if (!this.preview) {
            this.data = null;
        }
    }
}
