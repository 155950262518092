import { Component } from '@angular/core';
import { ControlContainer, FormArray, FormGroup, FormGroupDirective } from '@angular/forms';

import { DropdownItem } from '@dagility-ui/kit';

import { WidgetBuilderFacade } from '../../../state/widget-builder.facade';
import { MultipleChartType, WidgetSerie, WidgetType } from '../../../models/any-widget.model';

@Component({
    selector: 'dp-widget-builder-chart-series',
    templateUrl: './widget-builder-chart-series.component.html',
    styleUrls: ['./widget-builder-chart-series.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class WidgetBuilderChartSeriesComponent {
    get formGroup(): FormGroup {
        return this.parent.control;
    }

    get chartType(): WidgetType {
        return this.formGroup.value.common.type;
    }

    type = WidgetType;

    get seriesArray(): FormArray {
        const seriesArray = this.formGroup.get('series') as FormArray;
        this.areaChartOptionDisabled = false;
        this.combinedBarStackEnabled = false;
        this.colorControlDisabled = false;
        if (this.formGroup.value.chartOptions.showArea) {
            this.areaChartOptionDisabled = true;
        }
        if (this.formGroup.value.chartOptions.barsPosition === 'Combined') {
            this.combinedBarStackEnabled = true;
        }
        for (const series of seriesArray.value) {
            if (series.column === '*') {
                this.colorControlDisabled = true;
                break;
            }
        }
        return seriesArray;
    }

    types: DropdownItem<MultipleChartType>[] = [
        {
            label: 'Bar Chart',
            value: 'bar',
        },
        {
            label: 'Line Chart',
            value: 'line',
        },
        {
            value: 'scatter',
            label: 'Scatter Chart',
        },
    ];

    colorControlDisabled = false;
    areaChartOptionDisabled = false;
    combinedBarStackEnabled = false;

    constructor(public facade: WidgetBuilderFacade, private parent: FormGroupDirective) {}

    addSeries(insertAbove: boolean): void {
        insertAbove
            ? this.seriesArray.insert(0, this.facade.buildSeriesGroup({} as WidgetSerie, this.chartType))
            : this.seriesArray.push(this.facade.buildSeriesGroup({} as WidgetSerie, this.chartType));
    }

    removeSeries(index: number): void {
        this.seriesArray.removeAt(index);
    }
}
