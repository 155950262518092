<ng-container
    *ngIf="{
        editDashboard: editDashboard$ | async
    } as vm"
>
    <dp-any-widget-filter-wrapper
        #filtersPortalContent
        [editDashboard]="vm.editDashboard"
        [options]="options"
        [filters]="filters"
        [isSmallWidget]="isSmallWidget"
        [filtersGroup]="filtersGroup"
        [defaultPlaceholders$]="defaultPlaceholders$"
        [hasParent]="hasParent"
        [intersect$]="intersect$"
        [previewMode]="previewMode"
        [funnelIconEnabled]="store.workflow?.isFunnelIconEnabled"
        (filtersLoaded)="handleFiltersLoaded($event)"
    ></dp-any-widget-filter-wrapper>

    <img
        class="w-100"
        style="height: 360px"
        *ngIf="vm.editDashboard && options.type !== type.COMPLEX && !showingOnlyFiltersBlock; else chartTemplate"
        [src]="options.type | widgetPreview"
        [alt]="options.type"
    />
</ng-container>

<ng-template #chartTemplate>
    <lib-stream-wrapper
        *ngIf="options.type !== type.COMPLEX && !showingOnlyFiltersBlock; else complexTmpl"
        [dataStream$]="chartData$"
        class="widget-stream-wrapper flex-1"
    >
        <lib-insight-loader *loaderTemplate class="w-100"></lib-insight-loader>
        <ng-template contentTemplate let-chartModel="data">
            <ng-container *ngIf="!chartModel.empty; else noData" [ngSwitch]="chartModel.options.type">
                <dp-accordion-wrapper
                    *ngSwitchCase="type.ACCORDION"
                    class="d-block"
                    [options]="chartModel.options"
                    [placeholders]="ph"
                    (cellClicked)="handleDrilldownEvent($event)"
                >
                </dp-accordion-wrapper>
                <dp-log-template
                    *ngSwitchCase="type.LOG"
                    [logs$]="chartModel.options.result"
                    [header]="chartModel.options.header"
                    [scrollToBottom]="chartModel.options.scrollToBottom"
                >
                </dp-log-template>
                <dp-two-dimensional-grid
                    *ngSwitchCase="type.TWO_DIMENSIONAL_TABLE"
                    [tableData]="chartModel.options"
                    (cellClick)="handleDrilldownEvent($event)"
                ></dp-two-dimensional-grid>
                <lib-progress-chart
                    class="custom-chart"
                    *ngSwitchCase="type.PROGRESS"
                    [data]="chartModel.options"
                    (barClicked)="handleDrilldownEvent($event)"
                ></lib-progress-chart>
                <dp-tile-template
                    class="custom-chart d-block"
                    *ngSwitchCase="type.TILE_CHART"
                    [data]="chartModel.options.result"
                    [header]="chartModel.options.header"
                    (cellClick)="handleDrilldownEvent($event)"
                ></dp-tile-template>
                <dp-work-distribution-widget
                    *ngSwitchCase="type.PI_WORK_DISTRIBUTION"
                    [options]="chartModel.options"
                    (linkClicked)="handleLinkClicked($event)"
                ></dp-work-distribution-widget>
                <dp-work-progress-widget *ngSwitchCase="type.PI_WORK_CHART" [options]="chartModel.options"></dp-work-progress-widget>
                <dp-multiple-radials
                    *ngSwitchCase="type.PI_MULTIPLE_RADIALS"
                    [options]="chartModel.options"
                    [hasDrilldown]="!!options.drilldownList?.length"
                    (radialClicked)="handleDrilldownEvent($event)"
                    (linkClicked)="handleLinkClicked($event)"
                >
                </dp-multiple-radials>
                <dp-progress-menu
                    *ngSwitchCase="type.PI_PROGRESS_MENU"
                    [options]="chartModel.options"
                    (drillDownClicked)="handleDrilldownEvent($event)"
                >
                </dp-progress-menu>
                <dp-issue-life-cycle *ngSwitchCase="type.PI_ISSUE_LIFE_CYCLE" [options]="chartModel.options"></dp-issue-life-cycle>
                <dp-grid-with-tabs
                    *ngSwitchCase="type.TABLE_WITH_TABS"
                    [items]="chartModel.options.items"
                    [placeholders]="ph"
                    (cellClicked)="handleDrilldownEvent($event)"
                >
                </dp-grid-with-tabs>
                <dp-accordion-with-tabs
                    *ngSwitchCase="type.ACCORDION_WITH_TABS"
                    [placeholders]="ph"
                    [infiniteScrollChartData$]="infiniteScrollChartData$"
                    [serverSearchChartData$]="serverSearchChartData$"
                    [serverSortChartData$]="serverSortChartData$"
                    [options]="chartModel.options"
                    (linkClicked)="handleLinkClicked($event)"
                    (scrollEnded)="handleScrollEnded($event)"
                    (serverSearch)="handleServerSearch($event)"
                    (serverSort)="handleServerSort($event)"
                >
                </dp-accordion-with-tabs>
                <dp-pi-gantt
                    *ngSwitchCase="type.PI_GANTT"
                    [chartOptions]="chartModel.options"
                    (linkClicked)="handleLinkClicked($event)"
                ></dp-pi-gantt>
                <dp-feature-completion-status
                    *ngSwitchCase="type.PI_FEATURE_COMPLETION_STATUS"
                    [options]="chartModel.options"
                    [infiniteScrollChartData]="infiniteScrollChartData$ | async"
                    (handleDrilldown)="handleDrilldownEvent($event)"
                    (linkClicked)="handleLinkClicked($event)"
                    (scrollEnded)="handleScrollEnded($event)"
                ></dp-feature-completion-status>
                <dp-sre-squares
                    *ngSwitchCase="type.SRE_SQUARES"
                    [squares]="chartModel.options.squares"
                    (squareClicked)="handleDrilldownEvent($event)"
                ></dp-sre-squares>
                <dp-factor-scores
                    *ngSwitchCase="type.FACTOR_SCORES"
                    [data]="chartModel.options.values"
                    (rowClicked)="handleLinkClicked($event)"
                >
                </dp-factor-scores>
                <dp-code-issue-details *ngSwitchCase="type.CODE_ISSUE_DETAILS" [data]="chartModel.options.data"></dp-code-issue-details>
                <dp-security-posture-details
                    *ngSwitchCase="type.SECURITY_POSTURE_DETAILS"
                    [data]="chartModel.options.data"
                    (itemClick)="handleDrilldownEvent($event)"
                ></dp-security-posture-details>
                <dp-sre-hexagons
                    *ngSwitchCase="type.SRE_HEXAGONS"
                    [hexagons]="chartModel.options.values"
                    [bounds]="chartModel.options.bounds"
                    [invertColors]="chartModel.options.invertColors"
                    (hexagonClicked)="handleDrilldownEvent($event)"
                ></dp-sre-hexagons>
                <dp-sre-gantt
                    *ngSwitchCase="type.SRE_GANTT"
                    [data]="chartModel.options"
                    class="widget-without-padding"
                    (drilldownEvent$)="handleDrilldownEvent($event)"
                ></dp-sre-gantt>
                <dp-code-quality-summary
                    *ngSwitchCase="type.CODE_QUALITY_SUMMARY"
                    [item]="chartModel.options.data"
                ></dp-code-quality-summary>
                <dp-portfolio-health-scores
                    *ngSwitchCase="type.PORTFOLIO_HEALTH_SCORES"
                    [data]="chartModel.options"
                    (categoryClicked)="handleCategoryItemClicked($event)"
                ></dp-portfolio-health-scores>
                <dp-portfolio-score-subcategories
                    *ngSwitchCase="type.PORTFOLIO_SCORE_SUBCATEGORIES"
                    [data]="chartModel.options"
                ></dp-portfolio-score-subcategories>
                <dp-portfolio-statistics-widget
                    *ngSwitchCase="type.PORTFOLIO_STATISTICS"
                    [data]="chartModel.options.data"
                ></dp-portfolio-statistics-widget>
                <dp-risks-header *ngSwitchCase="type.PORTFOLIO_RISK_SCORES" [data]="chartModel.options.data"></dp-risks-header>
                <dp-compare-metrics-widget
                    *ngSwitchCase="type.COMPARE_METRICS"
                    [placeholders]="ph"
                    (reload)="handleReload($event)"
                    (cellClicked)="handleDrilldownEvent($event, true)"
                >
                </dp-compare-metrics-widget>
                <dp-score-doughnut *ngSwitchCase="type.SCORE_DOUGHNUT" class="chart" [model]="chartModel.options"></dp-score-doughnut>
                <dp-top-priority-risks *ngSwitchCase="type.TOP_PRIORITY_RISKS" [data]="chartModel.options"></dp-top-priority-risks>
                <dp-metric-line-template class="chart h-100" *ngSwitchCase="type.METRIC_LINE" [options]="chartModel.options">
                </dp-metric-line-template>
                <dp-metric-tile *ngSwitchCase="type.METRIC_TILE" [data]="chartModel.options"></dp-metric-tile>
                <dp-merge-request-info *ngSwitchCase="type.MERGE_REQUEST_INFO" [data]="chartModel.options.data"></dp-merge-request-info>
                <dp-widget-template-wrapper
                    *ngSwitchDefault
                    [placeholders]="ph"
                    [templates]="options.additionalTemplates || []"
                    [isComplexPart]="false"
                    (drilldown)="handleDrilldownEvent($event, true)"
                >
                    <dp-grid-widget-wrapper
                        #agGrid
                        *ngIf="chartModel.options.type === type.TABLE"
                        class="d-block spacing-optimized pb-2"
                        [chartModel]="chartModel"
                        [paginationState]="options.chartOptions.gridPagination"
                        [serverSideWidget]="options.server"
                        [filtersGroup]="filtersGroup"
                        [placeholders]="ph"
                        [serverSidePagination]="options.chartOptions.serverSidePagination"
                        (cellClicked)="handleDrilldownEvent($event)"
                        (stateChanged)="handleFilterChange($event)"
                    >
                    </dp-grid-widget-wrapper>
                    <dp-radial-template
                        class="custom-chart"
                        *ngIf="chartModel.options.type === type.RADIAL"
                        [options]="chartModel.options"
                    ></dp-radial-template>
                    <dp-health-score-template
                        *ngIf="chartModel.options.type === type.HEALTH_SCORE"
                        class="custom-chart"
                        [options]="chartModel.options"
                    >
                    </dp-health-score-template>
                    <lib-chart
                        *ngIf="
                            chartModel.options.type !== type.RADIAL &&
                            chartModel.options.type !== type.HEALTH_SCORE &&
                            chartModel.options.type !== type.TABLE
                        "
                        class="chart"
                        [type]="chartModel.options.type"
                        [options]="chartModel.options"
                        [checkHostResize]="true"
                        (event)="handleDrilldownEvent($event)"
                    >
                    </lib-chart>
                </dp-widget-template-wrapper>
            </ng-container>
        </ng-template>
    </lib-stream-wrapper>
</ng-template>

<ng-template #complexTmpl>
    <dp-widget-template-wrapper
        [placeholders]="ph"
        [templates]="options.additionalTemplates || []"
        [isComplexPart]="true"
        (drilldown)="handleDrilldownEvent($event, true)"
    >
        <div class="grid-container grid-container__{{ options.chartOptions.layout }}" *ngIf="!showingOnlyFiltersBlock">
            <dp-any-widget
                *ngFor="let widget of options.widgets; let i = index"
                class="p-0"
                hasParent="true"
                [style.grid-area]="'widget--' + i"
                [options]="widget"
                [parentFilters]="filtersGroup"
                [parentFiltersLoaded]="filtersLoaded$"
                [parentTitle]="options.chartOptions.title"
                [separatedView]="separatedView"
                [parentWidget]="this"
                [subWidgetIndex]="i"
                [previewMode]="previewMode"
                (drilldown)="drilldown.emit($event)"
            ></dp-any-widget>
        </div>
    </dp-widget-template-wrapper>
</ng-template>

<ng-template #noData>
    <div
        *ngIf="separatedView; else insightNoData"
        class="h-100 d-flex align-items-center justify-content-center text-center"
        [class]="isSmallWidgetWithoutFilter ? 'no-data-text--small' : 'no-data-text'"
        data-test-id="no-data-message"
    >
        {{ chartModel.noDataMessage }}
    </div>

    <ng-template #insightNoData>
        <lib-widget-message [message]="chartModel.noDataMessage"></lib-widget-message>
    </ng-template>
</ng-template>

<div
    *ngIf="!chartModel?.options?.disableBottomLine"
    class="colored-bottom-line"
    [style.background]="
        chartModel?.options && chartModel?.options.relatedThreshold && chartModel?.options.scoreColor ? chartModel.options.scoreColor : ''
    "
></div>
