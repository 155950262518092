import { Component } from '@angular/core';
import { ControlContainer, FormArray, FormGroupDirective } from '@angular/forms';
import { WidgetStatus, WidgetType } from '../../../models/any-widget.model';
import { WidgetBuilderFacade } from '../../../state/widget-builder.facade';
import { CardStatusValue, DropdownItem } from '@dagility-ui/kit';

@Component({
    selector: 'dp-widget-builder-statuses',
    templateUrl: './widget-builder-statuses.component.html',
    styleUrls: ['./widget-builder-statuses.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class WidgetBuilderStatusesComponent {
    get statuses(): FormArray {
        return this.parent.control.get('statuses') as FormArray;
    }

    get widgetType(): WidgetType {
        return this.parent.control.get('common.type').value;
    }

    type = WidgetType;

    statusItems: DropdownItem<CardStatusValue>[] = [
        {
            label: 'Approved',
            value: 'approved',
        },
        {
            label: 'Waiting',
            value: 'waiting',
        },
        {
            label: 'Rejected',
            value: 'rejected',
        },
        {
            label: 'To Do',
            value: 'to-do',
        },
        {
            label: 'Default',
            value: 'default',
        },
    ];

    iconItems: DropdownItem<string>[] = [
        {
            label: 'Check Mark',
            value: 'simpleCheck',
        },
        {
            label: 'Clock',
            value: 'facPending',
        },
        {
            label: 'Cross',
            value: 'facBuildFailure',
        },
    ];

    constructor(public parent: FormGroupDirective, public facade: WidgetBuilderFacade) {}

    addStatus(): void {
        this.statuses.push(this.facade.buildStatusGroup({} as WidgetStatus, this.widgetType));
    }

    removeStatus(index: number): void {
        this.statuses.removeAt(index);
    }
}
