import { sum } from 'lodash';
import { SVGBlock, SVGEdge } from '@dagility-ui/shared-components';

import { WidgetDrilldown, WidgetFilterType, WidgetQuery, WidgetType } from '../models/any-widget.model';
import {
    AsyncQueries,
    AsyncQueryBlock,
    ComplexCellBlock,
    DrilldownAction,
    FilterBlock,
    FiltersBlock,
    LayerBlock,
    QueriesBlock,
    QueryBlock,
} from '../models/query.block';
import { WidgetAction, WidgetLayer } from '../models/widget.graph';
import { COMPLEX_LEVELS_COUNT, WidgetBuilderState } from './widget-builder.store';
import { getDrilldownConverter } from '../services/widget.drilldown';
import { generateUUID } from '@dagility-ui/kit';

const SCRIPTS_PADDING = 4;
const LAYER_PADDING = 35;
const FILTER_PADDING = 10;
const ADD_BUTTON_AREA = 36;
const HEADER_HEIGHT = 28;
const COMPLEX_WIDTH_COEFFICIENT = 2.5;
const BLOCK_HEIGHT = 28;
const COMPLEX_CELL_PADDING = 10;
export interface WidgetBuilderLayout {
    edges: SVGEdge[];
    blocks: SVGBlock[];
}

export class QueryLayoutBuilder {
    edges: SVGEdge[] = [];
    nodes: SVGBlock[] = [];
    outputActionsMap: Record<string, WidgetAction[]> = {};
    queryBreakpoints: Record<string, number[]> = {};
    complexPartMap: Record<string, boolean> = {};
    isFilters = false;

    constructor(private state: WidgetBuilderState) {
        this.outputActionsMap = this.state.actions.reduce<Record<string, WidgetAction[]>>((acc, action) => {
            if (!acc[action.from]) {
                acc[action.from] = [];
            }
            acc[action.from].push(action);

            return acc;
        }, {});

        this.queryBreakpoints = this.state.breakpoints.reduce<Record<string, number[]>>((acc, breakpoint) => {
            const [id, position] = breakpoint.split('|');

            if (acc[id]) {
                acc[id].push(+position);
            } else {
                acc[id] = [+position];
            }

            return acc;
        }, {});

        this.complexPartMap = this.initComplexPartMap();
    }

    build(): WidgetBuilderLayout {
        this.buildTree(this.state.layers[this.state.root], true, undefined, 0);
        this.coerceNodePositions();
        this.buildEdges();

        return {
            blocks: this.nodes.reverse(),
            edges: this.edges,
        };
    }

    buildTree(
        rootLayer: WidgetLayer,
        move: boolean = false,
        prev: {
            maxX: number;
            minX: number;
        } = {
            minX: Infinity,
            maxX: -Infinity,
        },
        flow: number
    ): LayerBlock {
        const nodes: SVGBlock[] = [];
        const root = this.wrap(rootLayer, nodes, flow);
        this.layoutChildren(root);
        this.resolveX(root);
        this.nodes = this.nodes.concat(nodes);
        const layersMap: Record<string, LayerBlock> = this.nodes.reduce<Record<string, LayerBlock>>((acc, node) => {
            if (node instanceof LayerBlock) {
                acc[node.id] = node;
            }

            return acc;
        }, {});

        nodes.forEach(node => {
            if (node instanceof LayerBlock) {
                node.x -= node.width / 2;

                prev.maxX = Math.max(prev.maxX, node.x + node.width);
                prev.minX = Math.min(prev.minX, node.x);
            }
        });

        if (!move) {
            return root;
        }

        for (const node of this.nodes) {
            if (node instanceof LayerBlock) {
                continue;
            }

            const layer = layersMap[(node as any).layerId];

            if (layer) {
                node.x += layer.x;
                node.y += layer.y;
            }

            if (node instanceof ComplexCellBlock) {
                const parentLayer = this.state.layers[node.layerId];
                const rootNode = layersMap[parentLayer.widgets[node.index]];
                if (rootNode) {
                    this.move(rootNode, node.x - rootNode.minX, 'x');
                    this.move(rootNode, node.y - rootNode.y, 'y');
                }
            }
        }

        return root;
    }

    buildEdges(): void {
        (this.edges as DrilldownAction[]).forEach(edge => edge.draw());
    }

    wrap(treeData: WidgetLayer, nodes: SVGBlock[], i: number): LayerBlock {
        const _wrap = (data: WidgetLayer, parent: LayerBlock, actionId: string, flow: number) => {
            const node = new LayerBlock(data);
            if (parent) {
                const action = new DrilldownAction(parent, node);
                action.id = actionId;
                this.edges.push(action);
            }
            this.buildNode(node, data, null, nodes, { y: 0 }, flow);
            nodes.push(node);
            Object.assign(node, {
                parent,
            });
            const kidsData = this.children(data.id) || [];
            node.children = kidsData.length === 0 ? null : kidsData.map(kd => _wrap(this.state.layers[kd.to], node, kd.id, 0));

            return node;
        };
        return _wrap(treeData, null, null, i);
    }

    layoutChildren(w: LayerBlock, y = 0): LayerBlock {
        w.y = y;

        let acc = [0, null];
        (w.children || []).forEach(kid => {
            const [i, lastLows] = acc;
            this.layoutChildren(kid, w.y + w.height + LAYER_PADDING);
            const lowY = (i === 0 ? kid.lExt : kid.rExt).bottom;
            if (i !== 0) {
                this.separate(w, i, lastLows);
            }
            const lows = this.updateLows(lowY, i, lastLows);
            acc = [i + 1, lows];
        });

        this.shiftChange(w);
        this.positionRoot(w);
        return w;
    }

    resolveX(w: LayerBlock, prevSum?: number, parentX?: number): LayerBlock {
        if (typeof prevSum === 'undefined') {
            prevSum = -w.relX - w.prelim;
            parentX = 0;
        }
        const sum = prevSum + w.relX;
        w.relX = sum + w.prelim - parentX;
        w.prelim = 0;
        w.x = parentX + w.relX;
        (w.children || []).forEach(k => this.resolveX(k, sum, w.x));
        return w;
    }

    shiftChange(w: LayerBlock): void {
        let acc = [0, 0];
        (w.children || []).forEach(child => {
            const [lastShiftSum, lastChangeSum] = acc;
            const shiftSum = lastShiftSum + child.shift;
            const changeSum = lastChangeSum + shiftSum + child.change;
            child.relX += changeSum;
            acc = [shiftSum, changeSum];
        });
    }

    separate(w: LayerBlock, i: number, lows: any): void {
        const lSib = w.children[i - 1];
        const curSubtree = w.children[i];
        let rContour = lSib;
        let rSumMods = lSib.relX;
        let lContour = curSubtree;
        let lSumMods = curSubtree.relX;
        let isFirst = true;
        while (rContour && lContour) {
            if (rContour.bottom > lows.lowY) {
                lows = lows.next;
            }
            const dist =
                rSumMods + rContour.prelim - (lSumMods + lContour.prelim) + rContour.width / 2 + lContour.width / 2 + LAYER_PADDING;
            if (dist > 0 || (dist < 0 && isFirst)) {
                lSumMods += dist;
                this.moveSubtree(curSubtree, dist);
                this.distributeExtra(w, i, lows.index, dist);
            }
            isFirst = false;
            const rightBottom = rContour.bottom;
            const leftBottom = lContour.bottom;
            if (rightBottom <= leftBottom) {
                rContour = this.nextRContour(rContour);
                if (rContour) {
                    rSumMods += rContour.relX;
                }
            }
            if (rightBottom >= leftBottom) {
                lContour = this.nextLContour(lContour);
                if (lContour) {
                    lSumMods += lContour.relX;
                }
            }
        }
        if (!rContour && lContour) {
            this.setLThr(w, i, lContour, lSumMods);
        } else if (rContour && !lContour) {
            this.setRThr(w, i, rContour, rSumMods);
        }
    }

    moveSubtree(subtree: LayerBlock, distance: number): void {
        subtree.relX += distance;
        subtree.lExtRelX += distance;
        subtree.rExtRelX += distance;
    }

    distributeExtra(w: LayerBlock, curSubtreeI: number, leftSibI: number, dist: number): void {
        const curSubtree = w.children[curSubtreeI];
        const n = curSubtreeI - leftSibI;
        if (n > 1) {
            const delta = dist / n;
            w.children[leftSibI + 1].shift += delta;
            curSubtree.shift -= delta;
            curSubtree.change -= dist - delta;
        }
    }

    leftLeaf(w: LayerBlock): LayerBlock {
        return w.hasChildren ? this.leftLeaf(w.firstChild) : w;
    }

    nextLContour(w: LayerBlock): LayerBlock {
        return w.hasChildren ? w.firstChild : w.lThr;
    }

    nextRContour(w: LayerBlock): LayerBlock {
        return w.hasChildren ? w.lastChild : w.rThr;
    }

    setLThr(w: LayerBlock, i: number, lContour: LayerBlock, lSumMods: number): void {
        const firstChild = w.firstChild;
        const lExt = firstChild.lExt;
        const curSubtree = w.children[i];
        lExt.lThr = lContour;
        const diff = lSumMods - lContour.relX - firstChild.lExtRelX;
        lExt.relX += diff;
        lExt.prelim -= diff;
        firstChild.lExt = curSubtree.lExt;
        firstChild.lExtRelX = curSubtree.lExtRelX;
    }

    setRThr(w: LayerBlock, i: number, rContour: LayerBlock, rSumMods: number): void {
        const curSubtree = w.children[i];
        const rExt = curSubtree.rExt;
        const lSib = w.children[i - 1];
        rExt.rThr = rContour;
        const diff = rSumMods - rContour.relX - curSubtree.rExtRelX;
        rExt.relX += diff;
        rExt.prelim -= diff;
        curSubtree.rExt = lSib.rExt;
        curSubtree.rExtRelX = lSib.rExtRelX;
    }

    positionRoot(w: LayerBlock): void {
        if (w.hasChildren) {
            const k0 = w.firstChild;
            const kf = w.lastChild;
            const prelim = (k0.prelim + k0.relX - k0.width / 2 + kf.relX + kf.prelim + kf.width / 2) / 2;
            Object.assign(w, {
                prelim,
                lExt: k0.lExt,
                lExtRelX: k0.lExtRelX,
                rExt: kf.rExt,
                rExtRelX: kf.rExtRelX,
            });
        }
    }

    updateLows(lowY: number, index: number, lastLows: any): any {
        while (lastLows !== null && lowY >= lastLows.lowY) {
            lastLows = lastLows.next;
        }
        return {
            lowY,
            index,
            next: lastLows,
        };
    }

    buildNode(layerBlock: LayerBlock, layer: WidgetLayer, prevItem: SVGBlock, nodes: SVGBlock[], endPos = { y: 0 }, flow: number): number {
        layerBlock.width *= layer.type === WidgetType.COMPLEX ? COMPLEX_WIDTH_COEFFICIENT : 1;
        let complexPadding = 0;
        let complexYPadding = 0;

        if (prevItem) {
            complexPadding = prevItem instanceof ComplexCellBlock ? (prevItem.width - layerBlock.width) / 2 : 0;
            complexYPadding = prevItem instanceof ComplexCellBlock ? Math.min(complexPadding, 30) : 0;
            layerBlock.y = (prevItem as any).end.y + (prevItem instanceof ComplexCellBlock ? complexYPadding : LAYER_PADDING);
            layerBlock.end.y = layerBlock.y;

            layerBlock.x = prevItem.x + complexPadding;
        }
        layerBlock.end.y += HEADER_HEIGHT;

        if (!this.complexPartMap[layer.id]) {
            const filtersBlock = new FiltersBlock(layer);
            filtersBlock.y = layerBlock.end.y;
            filtersBlock.width = layerBlock.width;
            filtersBlock.x = layerBlock.x;
            filtersBlock.layerId = layer.id;

            layerBlock.innerElements.push(filtersBlock);

            const filters = layer.filters.map(id => this.state.filters[id]);
            layerBlock.end.y += HEADER_HEIGHT;

            filters.forEach(filter => {
                const block = new FilterBlock(filter);
                block.layerId = layerBlock.id;
                layerBlock.innerElements.push(block);

                block.y = layerBlock.end.y;
                layerBlock.end.y += HEADER_HEIGHT;
                block.x = layerBlock.x;
                block.width = layerBlock.width;

                if ((filter.type === WidgetFilterType.DROPDOWN && !!(filter as any).dynamic) || filter.type === WidgetFilterType.HIDDEN) {
                    block.viewType = 'filter-dynamic';
                    this.buildQueryBlock(this.state.queries[filter.query], layerBlock, nodes, true, filter.placeholder, flow);
                } else {
                    layerBlock.end.y += BLOCK_HEIGHT + SCRIPTS_PADDING;
                }
                nodes.push(block);
                block.height = layerBlock.end.y - block.y;
                layerBlock.end.y += FILTER_PADDING;
            });
            nodes.push(filtersBlock);

            filtersBlock.height = layerBlock.end.y - filtersBlock.y + ADD_BUTTON_AREA;
            layerBlock.end.y = filtersBlock.y + filtersBlock.height;
        }

        if (layer.type === WidgetType.COMPLEX) {
            const [firstLevelCount, secondLevelCount] = COMPLEX_LEVELS_COUNT[layer.chartOptions.layout];
            const firstLevel = this.buildComplexLevel(firstLevelCount, layerBlock, layer, 0, nodes);
            const secondLevel = this.buildComplexLevel(secondLevelCount, layerBlock, layer, firstLevelCount, nodes);

            if (!secondLevel.length) {
                layerBlock.width = sum(firstLevel.map(n => n.width));
            } else if (firstLevel.length === 2 && secondLevel.length === 2) {
                this.buildPairedLevels(firstLevel, secondLevel, layerBlock);
            } else {
                const [paired, single] = firstLevel.length === 2 ? [firstLevel, secondLevel] : [secondLevel, firstLevel];
                this.buildLevels(paired, single[0], layerBlock);
            }

            [...firstLevel, ...(secondLevel || [])].forEach(cell => {
                if (!cell || !cell.root) {
                    return;
                }

                const treeMiddle = cell.root.treeWidth / 2;
                const cellMiddle = cell.width / 2;

                this.move(cell.root, cellMiddle - treeMiddle, 'x', true);
            });

            layerBlock.innerElements.forEach(block => {
                if (block instanceof FiltersBlock || block instanceof FilterBlock || block instanceof QueryBlock) {
                    block.width = layerBlock.width;
                }
            });
        } else if (!this.isFilters) {
            const queriesBlock = this.buildQueriesBlock(layerBlock, layer);

            layer.queries
                .map(id => this.state.queries[id])
                .forEach(query => this.buildQueryBlock(query, layerBlock, nodes, false, query.placeholder, flow + 1));
            nodes.push(queriesBlock);

            layerBlock.end.y += ADD_BUTTON_AREA;
            queriesBlock.height = layerBlock.end.y - queriesBlock.y;
        }

        if (!this.complexPartMap[layer.id]) {
            if (!this.isFilters) {
                this.buildAsyncQueries(layerBlock, layer, nodes);
            }

            this.buildMessageBus(layerBlock, layer);

            layerBlock.end.y += ADD_BUTTON_AREA + HEADER_HEIGHT;
        }

        if (!this.isFilters && !!getDrilldownConverter(layer.type)) {
            this.buildDrilldown(layerBlock);
            layerBlock.end.y += ADD_BUTTON_AREA + HEADER_HEIGHT;
        }

        layerBlock.height = layerBlock.end.y - layerBlock.y;
        endPos.y = Math.max(endPos.y, layerBlock.end.y);

        return complexYPadding;
    }

    private buildPairedLevels([c0, c1]: ComplexCellBlock[], [c2, c3]: ComplexCellBlock[], layer: LayerBlock) {
        const aMax = Math.max(c0.width, c2.width);
        const bMax = Math.max(c1.width, c3.width);

        c1.x += aMax - c0.width;
        c3.x += aMax - c2.width;
        c0.width = aMax;
        c2.width = aMax;
        [c1, c3].forEach(c => (c.width = bMax));
        layer.width = aMax + bMax;
    }

    private buildLevels([c0, c1]: ComplexCellBlock[], singleLevel: ComplexCellBlock, layer: LayerBlock) {
        const pairedLevelWidth = sum([c0, c1].map(n => n?.width));

        if (pairedLevelWidth > singleLevel.width) {
            singleLevel.width = pairedLevelWidth;
            layer.width = pairedLevelWidth;
        } else {
            const m = (singleLevel.width - pairedLevelWidth) / 2;

            c0.width += m;

            if (c1) {
                c1.x += m;
                c1.width += m;
            }

            layer.width = singleLevel.width;
        }
    }

    private buildDrilldown(layer: LayerBlock) {
        layer.actions = (this.outputActionsMap[layer.id] || []).map((action: WidgetAction<WidgetDrilldown>) => ({
            ...action,
            name: `${this.state.layers[action.to].chartOptions.title} ${action.data.default ? '(default)' : ''}`,
            type: 'drilldown',
        }));
        layer.end.y += (BLOCK_HEIGHT + SCRIPTS_PADDING) * layer.actions.length;
    }

    private buildAsyncQueries(layerBlock: LayerBlock, layer: WidgetLayer, nodes: SVGBlock[]) {
        const queriesBlock = this.buildQueriesBlock(layerBlock, layer, AsyncQueries);
        (layer.asyncQueries || []).forEach(query => {
            this.buildQueryBlock(query, layerBlock, nodes, false, query.placeholder, 0, AsyncQueryBlock);
        });
        nodes.push(queriesBlock);

        layerBlock.end.y += ADD_BUTTON_AREA;
        queriesBlock.height = layerBlock.end.y - queriesBlock.y;
    }

    private buildQueriesBlock(layerBlock: LayerBlock, layer: WidgetLayer, clazz: Function = QueriesBlock) {
        const queriesBlock = Reflect.construct(clazz, [layer]);
        queriesBlock.y = layerBlock.end.y;
        queriesBlock.x = layerBlock.x;
        queriesBlock.width = layerBlock.width;
        queriesBlock.layerId = layer.id;

        layerBlock.end.y += HEADER_HEIGHT;
        layerBlock.innerElements.push(queriesBlock);

        return queriesBlock;
    }

    private buildMessageBus(layerBlock: LayerBlock, layer: WidgetLayer) {
        layerBlock.handlers = (layer.handlers || []).map((handler, idx) => ({
            ...handler,
            viewType: 'handler',
            id: generateUUID(),
            layerId: layerBlock.id,
            index: idx,
        }));
        layerBlock.messageBus = true;
        layerBlock.end.y += (BLOCK_HEIGHT + SCRIPTS_PADDING) * (layer.handlers || []).length;
    }

    move(l: LayerBlock, distance: number, coordinate: 'x' | 'y', onlyRoot = false): void {
        l[coordinate] += distance;
        l.innerElements.forEach(n => {
            if (onlyRoot) {
                return;
            }

            if (coordinate === 'x') {
                if (n instanceof ComplexCellBlock) {
                    n.x += distance;
                } else {
                    n.x = l.x;
                }
            } else {
                n[coordinate] += distance;
            }

            if (n instanceof ComplexCellBlock) {
                this.move(n.root, distance, coordinate);
            }
        });
        (l.children || []).forEach(n => this.move(n, distance, coordinate, onlyRoot));
    }

    private buildQueryBlock(
        query: WidgetQuery,
        layer: LayerBlock,
        nodes: SVGBlock[],
        filterQuery: boolean,
        placeholder: string,
        flow: number,
        clazz: Function = QueryBlock
    ): void {
        const queryBlock = Reflect.construct(clazz, [query, filterQuery]);
        queryBlock.width = layer.width;
        queryBlock.placeholder = placeholder;
        queryBlock.debuggingScripts = this.queryBreakpoints[query.id] || [];
        queryBlock.y = layer.end.y;
        queryBlock.flow = flow;
        nodes.push(queryBlock);
        queryBlock.layerId = layer.id;

        if (!filterQuery) {
            layer.end.y += HEADER_HEIGHT;
        }

        queryBlock.x = layer.x;

        layer.end.y += (BLOCK_HEIGHT + SCRIPTS_PADDING) * 3;
        layer.innerElements.push(queryBlock);
        queryBlock.height = layer.end.y - queryBlock.y;
    }

    private buildComplexLevel(
        countInLevel: number,
        layerBlock: LayerBlock,
        layer: WidgetLayer,
        offset: number,
        nodes: SVGBlock[]
    ): ComplexCellBlock[] {
        let prevCellWidth = 0;
        let maxHeight = 0;

        const startY = layerBlock.end.y;
        const cells = Array.from({ length: countInLevel }).map((_, index) => {
            const cell = new ComplexCellBlock();
            cell.x = layerBlock.x + prevCellWidth;
            cell.y = startY;
            cell.end.y = startY;
            cell.layerId = layer.id;
            cell.index = offset + index;
            nodes.push(cell);
            layerBlock.innerElements.push(cell);

            const complexCellId = layer.widgets[cell.index];

            if (!!complexCellId) {
                const prev = { minX: 0, maxX: 0 };
                const complexRoot = this.buildTree(this.state.layers[complexCellId], false, prev, cell.index);
                cell.root = complexRoot;
                prevCellWidth = prev.maxX - prev.minX + COMPLEX_CELL_PADDING * 2;
                cell.width = prevCellWidth;
                const height = complexRoot.lExt.bottom - complexRoot.y;
                maxHeight = Math.max(maxHeight, height);
                complexRoot.minX = prev.minX;
                complexRoot.maxX = prev.maxX;
            }

            return cell;
        });
        maxHeight += COMPLEX_CELL_PADDING * 2;
        cells.forEach(cell => (cell.height = maxHeight));

        layerBlock.end.y += maxHeight;

        return cells;
    }

    private coerceNodePositions() {
        const x0 = Math.min(...this.nodes.map(({ x }) => x));
        if (x0 > 0) {
            return;
        }

        const delta = Math.abs(x0);

        this.nodes.forEach(node => {
            node.x += delta;
        });
    }

    private initComplexPartMap() {
        const complexPartMap: Record<string, boolean> = {};

        const walk = (id: string, complexPart = false) => {
            complexPartMap[id] = complexPart;
            const layer = this.state.layers[id];

            (layer.widgets || []).forEach(widgetId => walk(widgetId, true));

            this.children(id).forEach(action => {
                walk(action.to);
            });
        };

        walk(this.state.root);

        return complexPartMap;
    }

    children(id: string): WidgetAction[] {
        return this.outputActionsMap[id] || [];
    }
}
