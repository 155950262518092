import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { QueryBuilderStore } from '@app/shared/components/query-builder/store/query-builder.store';
import { DataSetFieldRole } from '@app/shared/components/query-builder/models/model-graph.model';
import { QueryBuilderWidgetService } from '@app/shared/components/query-builder/services/query-builder-widget.service';
import { WidgetConfigRequest } from '@app/shared/components/query-builder/models/query-builder-widget.model';
import { GetRolesByWidgetType } from '@app/shared/components/query-builder/qb-visualization/components/mapping-container/field-role-registry/field-role.registry';
import { AnyWidgetModel } from 'data-processor';

@Component({
    selector: 'app-qb-visualization-widget-viewer',
    templateUrl: './widget-viewer.component.html',
    styleUrls: ['./widget-viewer.component.scss'],
})
export class WidgetViewerComponent implements OnInit {
    syncShow: boolean = false;
    showChart: boolean = false;

    constructor(
        private cdr: ChangeDetectorRef,
        private queryBuilderStore: QueryBuilderStore,
        private queryBuilderWidgetService: QueryBuilderWidgetService
    ) {}

    _onlyLocal: boolean = true;

    get onlyLocal() {
        return this._onlyLocal;
    }

    set onlyLocal(b: boolean) {
        this._onlyLocal = b;
        this.queryBuilderStore.setLocalWidget(this._onlyLocal);
    }

    get anyWidgetModel(): AnyWidgetModel {
        return this.onlyLocal ? this.queryBuilderStore.drilldownGraph.widgetConfig : this.queryBuilderStore.globalModelGraph.widgetConfig;
    }

    get isDrillDown(): boolean {
        return !!this.queryBuilderStore.drillDownId;
    }

    ngOnInit(): void {
        this.checkShowChart();
        if (this.showChart) {
            const ws = this.queryBuilderStore.widgetSettings;
            delete ws.query;
            this.queryBuilderStore.setState({ widgetSettings: { data: ws } });
            this.getWidgetConfig();
        }
    }

    reloadWidget() {
        this.syncShow = false;
        this.cdr.detectChanges();
        this.syncShow = true;
    }

    @HostListener('document:queryBuilderChanged', ['$event'])
    private validateMapping(event: CustomEvent) {
        this.checkShowChart();
        if (
            this.showChart &&
            (event.detail.changed === 'MODEL_GRAPH' || event.detail.changed === 'BOTH' || event.detail.changed === 'SUBQUERY')
        ) {
            this.getWidgetConfig();
        }
    }

    private checkShowChart() {
        const widgetSettings = this.queryBuilderStore.widgetSettings;
        const modelGraph = this.queryBuilderStore.modelGraph;
        if (!modelGraph) {
            this.showChart = false;
            return;
        }
        const distinct = (value: any, index: number, array: any) => {
            return array.indexOf(value) === index;
        };
        const cmpDataSetFieldRoles: DataSetFieldRole[] = [];
        modelGraph.dataSetFields.fields.forEach(field => {
            cmpDataSetFieldRoles.push(...field.roles.map(role => role.name).filter(distinct));
        });
        const requiredDataSetFieldRoles: DataSetFieldRole[] = GetRolesByWidgetType(widgetSettings.type);
        this.showChart = requiredDataSetFieldRoles.every(cmp => cmpDataSetFieldRoles.includes(cmp));
    }

    private getWidgetConfig() {
        const widgetConfigRequest: WidgetConfigRequest = {
            graph: this.queryBuilderStore.globalModelGraph,
            widgetConfig: this.queryBuilderStore.drilldownGraph.widgetConfig,
            drillDownId: this.queryBuilderStore.drillDownId,
            widgetId: null,
        };
        this.queryBuilderWidgetService.getWidgetDataConfigNew(widgetConfigRequest).subscribe(result => {
            this.queryBuilderStore.updateStateSilent(result);
            this.reloadWidget();
        });
    }
}
