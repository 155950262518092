import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { SidenavMode, SidenavStorageService } from '@dagility-ui/kit';

import { UserService } from '@app/services';
import { AuthService } from '@app/auth';

@Injectable()
export class SidenavUserStorageService implements SidenavStorageService {
    constructor(private userService: UserService, private authService: AuthService) {}

    getMode(): Observable<SidenavMode> {
        return this.authService.userChanged.pipe(
            startWith(null as unknown),
            map(() => this.authService.getAuthenticatedUser()),
            map(user => (user?.variables?.pinnedSideMenu ? 'push' : 'over'))
        );
    }

    setMode(mode: SidenavMode): void {
        const pinnedSideMenu = mode === 'push';

        this.userService
            .updateUserVariables({
                pinnedSideMenu,
            })
            .subscribe(() => {
                this.authService.getAuthenticatedUser().variables.pinnedSideMenu = pinnedSideMenu;
            });
    }
}
