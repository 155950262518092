import {
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    Renderer2,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { NgbAccordion, NgbModal, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfig } from 'ngx-perfect-scrollbar';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { faAngleDown, faAngleUp, faExclamationTriangle, faLink, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { finalize } from 'rxjs/operators';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { uniqBy } from 'lodash';

import { ModalConfirmComponent, warning, CustomIcon, facTrash } from '@dagility-ui/kit';
import {
    ProjectService,
    sortObjectArrayLikeMapValuesOrder,
    ToasterService,
    TOOL_CATEGORY_NAMES,
    ToolCategory,
    ToolCategoryType,
    ToolService,
    ToolTemplate,
    ToolTemplateService,
} from '@dagility-ui/shared-components';

import { DATA_MORPH_FEATURE_TOGGLE, DataMorphFeatureToggleService } from 'data-processor/tokens';

import { ConfigureAccessPopupComponent } from '../tool-library/configure-access-popup/configure-access-popup.component';

function generateCategoryId(str: string) {
    return `tool-category-${str.toLowerCase().replace(/\W/g, '-')}`;
}

@Component({
    selector: 'dp-configured',
    templateUrl: './configured.component.html',
    styleUrls: ['./configured.component.scss'],
})
export class ConfiguredComponent implements OnInit, OnDestroy {
    @Input() showProjects = true;
    @Input() showExternalCredentials = true;

    @Output() runToolWizard: EventEmitter<string> = new EventEmitter();

    private readonly firstLevelClosedHeight: number = 41;
    private readonly contentItemHeight: number = 37;
    private readonly titleItemHeight: number = 38;
    private readonly secondLevelPaddings: number = 40;
    private readonly minRowsCount: number = 7;
    private readonly secondLevelMaxHieght: number = this.contentItemHeight * this.minRowsCount + 1;
    private readonly firstLevelPanelsGap: number = 10;
    private readonly secondLevelCardGap: number = 20;

    @ViewChild('accTools') accTools: NgbAccordion;
    @ViewChild('accToolsWrapperER') accToolsWrapperER: ElementRef;
    @ViewChildren('accPlugins') accPlugins: QueryList<NgbAccordion>;

    @Input() imagePath = 'assets/images/icons/github.png';
    icons: Record<string, IconDefinition | CustomIcon> = {
        faAngleUp,
        faAngleDown,
        faExclamationTriangle,
        faLink,
        facTrash
    };

    data: {
        category: ToolCategory;
        categoryType: ToolCategoryType;
        id: string | number;
        plugins: any[];
    }[];

    toolsconfig: any;
    search = '';
    toolsLoaded = false;
    toolTemplatesLoaded = false;
    toolTemplatesLoaded$ = new BehaviorSubject(false);
    toolSubscription: Subscription;

    perfectScrollBarConfig: PerfectScrollbarConfig = new PerfectScrollbarConfig({
        scrollYMarginOffset: 5,
    });

    private toolTemplates: ToolTemplate[];

    selected$ = new Subject();

    constructor(
        private projectService: ProjectService,
        private pluginService: ToolService,
        private toolTemplateService: ToolTemplateService,
        private spinnerservice: SpinnerVisibilityService,
        private modalService: NgbModal,
        private toster: ToasterService,
        private renderer: Renderer2,
        @Inject(DATA_MORPH_FEATURE_TOGGLE) private featureToggleService: DataMorphFeatureToggleService
    ) {}

    ngOnInit() {
        this.loadToolTemplates();
        this.getData();
        this.setParentClass();
    }

    setParentClass(isExist = true) {
        const outletContainer = document.getElementsByClassName('outlet')?.item(0);
        const settingContainer = document.getElementsByClassName('setting-container')?.item(0);
        if (!outletContainer || !settingContainer) {
            return;
        }

        if (isExist) {
            outletContainer.classList.remove('outlet');
            settingContainer.classList.remove('setting-container');
            return;
        }

        outletContainer.classList.add('outlet');
        settingContainer.classList.add('setting-container');
    }

    loadToolTemplates() {
        this.spinnerservice.show();
        this.toolTemplateService
            .findAll()
            .pipe(finalize(() => this.spinnerservice.hide()))
            .subscribe(
                result => {
                    this.toolTemplates = result;
                    this.checkForMultiTools();
                    this.toolTemplatesLoaded = true;
                    this.toolTemplatesLoaded$.next(true);
                },
                () => {
                    this.toolTemplates = [];
                    this.toolTemplatesLoaded = true;
                    this.toolTemplatesLoaded$.next(true);
                }
            );
    }

    reloadList(): void {
        this.toolTemplatesLoaded$.next(false);
        this.toolTemplatesLoaded = false;
        this.loadToolTemplates();
        this.getData();
    }

    checkForMultiTools(forToolsConfig?: boolean) {
        if (forToolsConfig) {
            const multiTools: Map<ToolCategoryType, any[]> = new Map<ToolCategoryType, any[]>();
            this.toolsconfig.forEach((x: any) =>
                x.plugins.forEach((y: any) => {
                    if (y.pluginCategories && y.pluginCategories.length > 1) {
                        for (let i = 1; i < y.pluginCategories.length; i++) {
                            multiTools.has(y.pluginCategories[i])
                                ? multiTools.get(y.pluginCategories[i]).push(y)
                                : multiTools.set(y.pluginCategories[i], [y]);
                        }
                    }
                })
            );
            multiTools.forEach((k, v) => {
                const toolGroup = this.toolsconfig.find((x: any) => x.groupCategory === v);
                if (toolGroup) {
                    Array.prototype.push.apply(toolGroup.plugins, k);
                } else {
                    this.toolsconfig.push({
                        category: TOOL_CATEGORY_NAMES.get(v),
                        groupCategory: v,
                        sequence: '1',
                        plugins: k,
                    });
                }
            });
        } else {
            const multiTools: ToolTemplate[] = [];
            this.toolTemplates.forEach(x => {
                x.groupCategory = x.pluginCategories[0];
                if (x.pluginCategories.length > 1) {
                    for (let i = 1; i < x.pluginCategories.length; i++) {
                        multiTools.push({ ...x, groupCategory: x.pluginCategories[i] });
                    }
                }
            });
            this.toolTemplates.push(...multiTools);
        }
    }

    getToolTemplatesByCategory(category: string) {
        return this.toolTemplatesLoaded ? this.toolTemplates.filter(x => TOOL_CATEGORY_NAMES.get(x.groupCategory) === category) : [];
    }

    getData() {
        this.toolsLoaded = false;
        const search = this.search;
        this.pluginService.getAllTools(search).subscribe(
            (response: any) => {
                this.toolsLoaded = true;
                if (response['errors']) {
                    this.toolsconfig = [];
                } else {
                    this.toolsconfig = response.result['content'];
                    this.checkForMultiTools(true);

                    this.getToolConfigFromToolTemplates();

                    if (!this.showProjects) {
                        setTimeout(() => this.handleToolsPanelChange(null, 0));
                        return;
                    }
                }
            },
            () => {
                this.toolsLoaded = true;
                this.toolsconfig = [];
                this.getToolConfigFromToolTemplates();
            }
        );
    }

    getToolConfigFromToolTemplates() {
        if (this.toolTemplatesLoaded) {
            return this.loadToolConfig();
        } else {
            if (this.toolSubscription) {
                this.toolSubscription.unsubscribe();
            }

            this.toolSubscription = this.toolTemplatesLoaded$.subscribe(loaded => {
                if (loaded) {
                    this.loadToolConfig();
                }
            });
        }
    }

    loadToolConfig() {
        const categories = this.toolTemplates.map(x => {
            return { category: TOOL_CATEGORY_NAMES.get(x.groupCategory), categoryType: x.groupCategory };
        });
        const uniqCategories = uniqBy(categories, 'category');

        if (this.toolsconfig) {
            this.data = uniqCategories.map(category => {
                const tool = this.toolsconfig.find((x: any) => TOOL_CATEGORY_NAMES.get(x.groupCategory) === category.category);
                const plugins: any[] = tool && tool.plugins ? tool.plugins : [];

                return {
                    ...category,
                    plugins,
                    id: generateCategoryId(category.category),
                };
            });

            if (this.search) {
                this.data = this.data.filter(({ plugins }) => plugins?.length);
            }

            this.data = sortObjectArrayLikeMapValuesOrder(this.data, 'category', TOOL_CATEGORY_NAMES);

            if (this.search) {
                for (const tools of this.data) {
                    this.accTools.expand(tools.id as any);
                }
            }
        } else {
            this.data = [];
        }

        if (this.showProjects) {
            this.loadProjects();
        }
    }

    loadProjects() {
        if (this.data && this.data.length > 0) {
            for (let i = 0; i < this.data.length; i++) {
                if (this.data[i].plugins.length > 0) {
                    for (let j = 0; j < this.data[i].plugins.length; j++) {
                        this.getProjects(this.data[i].plugins[j]);
                    }
                }
            }
        }
    }

    handlePluginToggle(plugin: any, opened: boolean) {
        if (!this.showProjects) {
            this.handleToolsPanelChange(null, 0);
            return;
        }

        if (!plugin.projects) {
            this.getProjects(plugin);
        } else {
            let projectsCount = plugin.projects && plugin.projects.length ? plugin.projects.length : 1;
            projectsCount = !opened ? projectsCount : 0;
            this.handleToolsPanelChange(null, projectsCount);
        }
    }

    getProjects(plugin: any) {
        if (!this.showProjects) {
            this.handleToolsPanelChange(null, 0);
            return;
        }

        plugin.projects = [];
        plugin.prjectsLoaded = false;
        if (plugin) {
            this.pluginService.getAllProjectsForPlugin(plugin.toolId).subscribe(result => {
                plugin.projects = result;
                plugin.prjectsLoaded = true;
                const projectsCount = plugin.projects.length ? plugin.projects.length : 1;
                this.handleToolsPanelChange(null, projectsCount);
            });
        }
    }

    deletePlugin(toolId: string, toolName: string) {
        const modalRef = this.modalService.open(ModalConfirmComponent, {
            centered: true,
            backdrop: 'static',
        });

        const modalComponent: ModalConfirmComponent = modalRef.componentInstance;

        modalComponent.message = {
            title: 'Tool Deletion — Warning',
            additionalTitleHtml: `<span class="mr-2">${warning}</span>`,
            content: `Please confirm you want to delete ${toolName}. This will delete the tool and all associated data, which may impact the Illuminate widgets. For example, if you only have one source code management tool and choose to delete it, then all code commit widgets will no longer work.

                If you delete a Project Management (PM) tool, all associated PACE projects and their data will be removed. If you reconfigure this tool and its projects again, this may take minutes or up to several days to recollect data, depending on the size of your PM tool and network bandwidth.`,
        };
        modalComponent.confirmButtonText = 'Confirm Deletion';
        modalComponent.closeButtonText = 'Cancel';

        modalComponent.confirmOk.subscribe(value => {
            if (!value) {
                return;
            }

            this.pluginService.deleteTool(toolId, 'FULL').subscribe((result: any) => {
                if (result.status === 200) {
                    this.toster.successToast({
                        title: 'Tool and collected data deleted',
                        content: `Tool and collected data has been deleted successfully!`,
                    });
                    this.reloadList();
                } else {
                    const error = result.errors[0];
                    this.toster.errorToast({
                        title: 'Error',
                        content: error.errorDescription,
                    });
                }
            });
        });
    }

    togglePluginActive(e: any, plugin: any) {
        const flag = !plugin.enabled;
        const message = flag ? 'Enabled' : 'Disabled';
        plugin.toggleLoading = true;
        this.pluginService.enabledisableTool(plugin.toolId, flag).subscribe(
            () => {
                plugin.toggleLoading = false;
                plugin.enabled = flag;
                this.toster.successToast({
                    title: message,
                    content: `${message} Successfully!`,
                });
            },
            () => {
                plugin.toggleLoading = false;
                plugin.enabled = !flag;
                this.toster.errorToast({
                    title: `Error`,
                    content: `Tool isn't ${message.toLowerCase()}`,
                });
            }
        );
    }

    configureAccess(plugin: any) {
        this.pluginService.getToolById(plugin.toolId).subscribe((response: any) => {
            const data = response.result;

            if (data) {
                const modalRef = this.modalService.open(ConfigureAccessPopupComponent, {
                    centered: true,
                    backdrop: 'static',
                    windowClass: 'widget-modal',
                });
                modalRef.componentInstance.editForm = true;
                modalRef.componentInstance.plugin = Object.assign({}, data);
                modalRef.componentInstance.name = data.name;
                modalRef.componentInstance.updateStatus.subscribe(() => {
                    this.getData();
                });
                modalRef.result.catch(() => {
                    /* rejected */
                });
            }
        });
    }

    searchData(data: any) {
        this.search = data;

        if (!this.search) {
            this.accTools.collapseAll();
        }

        this.getData();
    }

    toggleProjectActive(project: any, enable: boolean) {
        project.toggleLoading = true;
        const message = enable ? 'Enabled' : 'Disabled';
        this.projectService.setProjectEnabled(project.pk, enable).subscribe(
            () => {
                project.toggleLoading = false;
                project.enabled = enable;
                this.toster.successToast({
                    title: message,
                    content: `${message} Successfully!`,
                });
            },
            () => {
                project.toggleLoading = false;
                project.enabled = !enable;
                this.toster.errorToast({
                    title: `Error`,
                    content: `Project isn't ${message.toLowerCase()}`,
                });
            }
        );
    }

    handleToolsPanelChange(e: NgbPanelChangeEvent, secondLevelContentItemsCount = 0) {
        let accordionMinHeight = 0;
        let accToolsElRef: ElementRef;
        accToolsElRef = this.accToolsWrapperER
            ? this.accToolsWrapperER.nativeElement
                ? this.accToolsWrapperER.nativeElement.children[0]
                : null
            : null;
        let secondLevelOpenedHeight = 0;

        if (accToolsElRef) {
            const panelElRefArray = this.accToolsWrapperER.nativeElement.children[0].children;
            if (panelElRefArray && panelElRefArray.length > 0) {
                for (let i = 0; i < panelElRefArray.length; i++) {
                    const panelElRef = panelElRefArray[i];
                    if ((e && e.nextState && e.panelId === `tool-${i}`) || (!e && this.accTools.isExpanded(`tool-${i}`))) {
                        const secondLevelTopicsCount =
                            this.toolsconfig[i] && this.toolsconfig[i].plugins ? this.toolsconfig[i].plugins.length : 0;
                        const secondLevelElementHeight =
                            secondLevelTopicsCount * this.titleItemHeight +
                            secondLevelContentItemsCount * this.contentItemHeight +
                            (secondLevelContentItemsCount > 0 && secondLevelContentItemsCount < this.minRowsCount
                                ? this.secondLevelCardGap
                                : 0);
                        secondLevelOpenedHeight =
                            secondLevelElementHeight < this.secondLevelMaxHieght ? secondLevelElementHeight : this.secondLevelMaxHieght;
                        secondLevelOpenedHeight = secondLevelOpenedHeight > 0 ? secondLevelOpenedHeight + this.secondLevelPaddings : 0;
                        this.renderer.setStyle(panelElRef, 'min-height', secondLevelOpenedHeight + this.firstLevelClosedHeight + 'px');
                        accordionMinHeight += secondLevelOpenedHeight + this.firstLevelClosedHeight + this.firstLevelPanelsGap;
                    } else {
                        this.renderer.setStyle(panelElRef, 'min-height', this.firstLevelClosedHeight + 'px');
                        accordionMinHeight += this.firstLevelClosedHeight + this.firstLevelPanelsGap;
                    }
                }
            } else {
                accordionMinHeight = this.toolsconfig
                    ? this.toolsconfig.length * (this.firstLevelClosedHeight + this.firstLevelPanelsGap)
                    : this.firstLevelClosedHeight + this.firstLevelPanelsGap;
            }
            this.renderer.setStyle(accToolsElRef, 'min-height', accordionMinHeight + 'px');
        }

        // to fix min height when item is collapsed
        setTimeout(() => {
            if (!e) {
                return;
            }

            const cardEl = document.getElementById(e.panelId)?.parentElement;
            if (cardEl?.style?.minHeight) {
                cardEl.style.minHeight = e.nextState ? '204px' : '41px';
            }
        });
    }

    handlePluginPanelChange(e: NgbPanelChangeEvent, parentIndex: any) {
        const contentItemsCount = e.nextState || (0 as any);

        const event: NgbPanelChangeEvent = {
            nextState: true,
            panelId: parentIndex,
            preventDefault: null,
        };
        this.handleToolsPanelChange(event, contentItemsCount);
    }

    ngOnDestroy() {
        this.setParentClass(false);

        if (this.toolSubscription) {
            this.toolSubscription.unsubscribe();
        }
    }
}
