import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

import { ENV_TOKEN } from '@app/tokens';


export const FEATURE_TOGGLE_SYNC_KEYS = new InjectionToken('FEATURE_TOGGLE_SYNC_KEYS');

@Injectable({
    providedIn: 'root',
})
export class FeatureToggleService {
    static readonly REDIRECT_URL = '';

    private readonly api = `${this.env.adminApiURL}/feature-toggle`;

    private readonly featureOnOffApi = `${this.env.adminApiURL}/feature-on-off`;

    private features = new Map<string, Observable<boolean>>();
    private featuresArr = new Map<string, Observable<boolean[]>>();

    private featuresSync = new Map<string, boolean>();
    private featuresOnOffSync = new Map<string, boolean>();

    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {}

    isActive(featureName: string, isFeatureOnOff?: boolean): Observable<boolean> {
        let feature = this.features.get(featureName);

        if (!feature) {
            const path = isFeatureOnOff ? `${this.featureOnOffApi}/status/${featureName}` : `${this.api}/is-active/${featureName}`;

            feature = this.http.get<boolean>(path).pipe(shareReplay(1));
            this.features.set(featureName, feature);
        }

        return feature;
    }

    isActiveSync(featureName: string): boolean {
        return !!this.featuresSync.get(featureName);
    }

    isFeatureOnOffActiveSync(featureName: string): boolean {
        return !!this.featuresOnOffSync.get(featureName);
    }

    areActive(featureNames: string[]): Observable<boolean[]> {
        return combineLatest(featureNames.map(name => this.isActive(name)));
    }

    areFeaturesActive(featureNames: string[]) {
        const featuresNamesParam = featureNames.join(',');
        let features$ = this.featuresArr.get(featuresNamesParam);
        if (!features$) {
            features$ = this.fetchFeatures(featureNames).pipe(shareReplay(1));

            featureNames.forEach((featureName, i) => {
                const feature = this.features.get(featureName);
                if (!feature) {
                    this.features.set(featureName, features$.pipe(map(results => results[i])));
                }
            });

            this.featuresArr.set(featuresNamesParam, features$);
        }

        return features$;
    }

    preFetchFeatures(featureNames: string[]) {
        return this.fetchFeatures(featureNames).pipe(
            tap(results => {
                featureNames.forEach((featureName, i) => {
                    this.featuresSync.set(featureName, results[i]);
                });
            })
        );
    }

    preFetchOnOffFeatures() {
        return this.http.get<{ name: string; turnedOn: boolean}[]>(this.featureOnOffApi).pipe(
            tap(results => {
                for (const feature of results) {
                    this.updateOnOffFeature(feature.name, feature.turnedOn);
                }
            })
        );
    }

    updateOnOffFeature(name: string, enabled: boolean) {
        this.featuresOnOffSync.set(name, enabled);
    }

    clearCacheItems() {
        this.features.clear();
    }

    private fetchFeatures(featureNames: string[]) {
        return this.http.get<boolean[]>(`${this.api}/are-active?feature_names=${featureNames.join(',')}`);
    }
}
