import { Provider } from '@angular/core';

import { AnyWidgetStore } from '../components/widget/any-widget/any-widget.store';
import { ClientWidgetScriptExecutor, WidgetErrorLogger, WidgetLogger, WidgetScriptExecutor } from '../services/widget-script.executor';
import { PlaceholdersProvider } from '../directives/filter-set.directive';
import { WidgetWorkflow } from '../services/widget.flow';
import { WidgetEventManager } from '../services/message-bus/widget-event.manager';
import { WidgetDOMEventsService } from '../services/message-bus/widget-dom-events.service';
import { AsyncQueriesFlow } from '../services/async-queries.flow';

export const anyWidgetProviders: Provider[] = [
    WidgetDOMEventsService,
    AnyWidgetStore,
    WidgetEventManager,
    { provide: PlaceholdersProvider, useExisting: AnyWidgetStore },
    { provide: WidgetLogger, useClass: WidgetErrorLogger },
    { provide: WidgetScriptExecutor, useClass: ClientWidgetScriptExecutor },
    WidgetWorkflow,
    AsyncQueriesFlow,
];
