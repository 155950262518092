<div class="editor-container" [class.not-active]="!active">
    <div class="tree-block" id="treeBlockRef">
        <div (mousedown)="onSelectExpressionNode($event, expressionTree)">
            <ng-container [ngTemplateOutlet]="nodeFunc" [ngTemplateOutletContext]="{ node: expressionTree, types, offset: 0, typeIcons } ">
            </ng-container>
        </div>
        <ng-container
            *ngIf="contextMenuTarget"
            [class.visible]="contextMenuTarget"
            [ngTemplateOutlet]="contextMenu"
            [ngTemplateOutletContext]="{ position: contextMenuPosition }"
        >
        </ng-container>
    </div>

    <div class="divider"></div>

    <div class="grid-block">
        <div class="switch ml-10px">
            <div
                *ngFor="let type of typesArray; let i = index"
                class="switch-button"
                [class.selected]="selectedNode.type === i"
                (click)="onSwitchExpressionType(i)"
            >
                <img class="switch-icon" alt="" [src]="typeIcons[i]" />
                <div class="m-1">{{ typeDefenitions[i] }}</div>
            </div>
        </div>

        <div class="header">
            <img alt="" [src]="typeIcons[selectedNode.type]" />
            <div class="m-2">{{ typeDefenitions[selectedNode.type] }}</div>
            <lib-input
                id="filterControlRef"
                [clearable]="true"
                [placeholder]="filterPlaceholder"
                [formControl]="filterControl"
                [propertiesCompletion]="[]"
                (clear)="onClearFunctionFilter()"
                [style.width]="'100%'"
            ></lib-input>
            <checkbox class="mb-5px ml-10px mr-10px" [value]="showTemplates" (click)="onTemplateMode()">Show templates</checkbox>
        </div>
        <div>
            <lib-data-grid id="dataGridHelperRef" *ngIf="gridData.length" [dataSource]="gridData" [sticky]="true" [bodyHeight]="'200px'">
                <ng-template rowTemplate let-item="item">
                    <td (click)="onSelectGridData(item)" [class.selected]="item.selected">
                    <span class="template-icon" *ngIf="item.isTemplate">
                        <img [src]="typeIcons[types.template]" alt=""/>
                    </span>
                        <span [class.selected-item]="selectedItem === item" [class.template-item]="item.isTemplate"> {{ item.label }} </span>
                        <span class="color-font-4"> {{ item.paramsList }} </span>
                        <span class="color-font-4"> {{ item.description }} </span>
                        <fa-icon
                            class="remove-template-icon"
                            *ngIf="item.isTemplate"
                            icon="facTrash"
                            (click)="onRemoveTemplate($event, item)"
                        ></fa-icon>
                    </td>
                </ng-template>
            </lib-data-grid>
        </div>
    </div>
</div>

<ng-template let-node="node" let-types="types" let-offset="offset" let-typeIcons="typeIcons" #nodeFunc>
    <div class="expression-container">
        <div class="expression-item ml-15px" *ngIf="node.parentNode">
            <img alt="" [src]="typeIcons[node.type]" />
            <span> {{ node.value }} </span>
        </div>
        <div
            *ngFor="let child of node.arguments"
            [ngSwitch]="child.type"
            [class.selected]="child.selected"
            (mousedown)="onSelectExpressionNode($event, child)"
            (contextmenu)="onContextMenuExpressionNode($event, child, node)"
            [style.paddingLeft.px]="offset"
        >
            <div
                *ngIf="child.selected"
                id="contextMenuButtonRef"
                class="menu-button"
                (mousedown)="onContextMenuExpressionNode($event, child, node)"
            >
                <img src="assets/images/icons/icon_context_menu.svg" alt=""/>
            </div>
            <ng-container
                *ngSwitchCase="types.func"
                [ngTemplateOutlet]="nodeFunc"
                [ngTemplateOutletContext]="{ node: child, types, offset: 25, typeIcons}"
            ></ng-container>
            <ng-container
                *ngSwitchCase="types.operator"
                [ngTemplateOutlet]="nodeFunc"
                [ngTemplateOutletContext]="{ node: child, types, offset: 25, typeIcons}"
            ></ng-container>
            <ng-container
                *ngSwitchCase="types.const"
                [ngTemplateOutlet]="nodeBlock"
                [ngTemplateOutletContext]="{ node: child, types, typeIcons }"
            ></ng-container>
            <ng-container
                *ngSwitchCase="types.field"
                [ngTemplateOutlet]="nodeBlock"
                [ngTemplateOutletContext]="{ node: child, types, typeIcons }"
            >
            </ng-container>
            <ng-container
                *ngSwitchCase="types.param"
                [ngTemplateOutlet]="nodeBlock"
                [ngTemplateOutletContext]="{ node: child, types, typeIcons }"
            >
            </ng-container>
        </div>
    </div>
</ng-template>

<ng-template #contextMenu let-position="position">
    <div class="flex-column context-menu" [style.top.px]="position.top">
        <span *ngFor="let item of contextMenuOptions" (click)="onContextOption(item)"> {{ item.label }}</span>
    </div>
</ng-template>

<ng-template let-node="node" let-types="types" let-typeIcons="typeIcons" #nodeBlock>
    <div class="expression-item ml-15px">
        <img alt="" [src]="typeIcons[node.type]" />
        <span class="item-label"> {{ node.value }} </span>
    </div>
</ng-template>
