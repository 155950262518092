<svg #svg zoom [shiftX]="zoomShiftX" [shiftY]="zoomShiftY" [zoomViewMode]="zoomViewMode" [zoomDisabled]="zoomDisabled">
    <g class="viewport" fill="none" stroke="#d8d8d8">
        <defs>
            <marker
                id="arrow"
                markerWidth="10"
                markerHeight="10"
                refX="8"
                refY="3"
                orient="auto"
                markerUnits="strokeWidth"
                style="stroke-width: 0;"
            >
                <path d="M0,0 L0,6 L9,3 z" fill="#D7D7D7"></path>
            </marker>
            <marker
                id="arrow-selected"
                markerWidth="10"
                markerHeight="10"
                refX="8"
                refY="3"
                orient="auto"
                markerUnits="strokeWidth"
                style="stroke-width: 0;"
            >
                <path d="M0,0 L0,6 L9,3 z" fill="#327DE2"></path>
            </marker>
        </defs>
        <g class="edges" stroke-width="1" pointer-events="all">
            <g
                [attr.id]="edge.id"
                *ngFor="let edge of edges; trackBy: trackByEdge"
                [class.edge--selected]="activeEdgeId && edge.id === activeEdgeId"
            >
                <ng-container
                    *ngTemplateOutlet="edgeTemplate ? edgeTemplate.ref : defaultEdgeTemplate; context: { $implicit: edge, selected: activeEdgeId && edge.id === activeEdgeId }"
                ></ng-container>
            </g>
        </g>
        <g class="blocks">
            <g *ngFor="let block of blocks; trackBy: trackByBlock">
                <foreignObject
                    [attr.x]="block.x"
                    [attr.y]="block.y"
                    [attr.width]="block.width"
                    [attr.height]="block.height"
                    overflow="visible"
                >
                    <xhtml:div
                        class="block block--{{ block.viewType }}"
                        [libDrag]="block"
                        [libDragDisabled]="readonly || !block.draggable"
                        [attr.data-id]="block.id"
                        [class.block--active]="activeBlock && activeBlock.id === block.id"
                        [class.cursor-pointer]="!readonly && block.draggable"
                        (dropped)="dropped.emit($event)"
                        (click)="handleBlockClick(block, $event)"
                    >
                        <xhtml:em *ngIf="block.error" class="fa fa-exclamation-circle error--icon"></xhtml:em>
                        <ng-container
                            *ngTemplateOutlet="blockTemplate.ref; context: { $implicit: block }"></ng-container>
                        <div *ngIf="!readonly" class="drop-areas">
                            <div
                                class="drop-area drop-area--{{ direction }}"
                                *ngFor="let direction of block.directions"
                                [attr.data-id]="block.id"
                                [attr.data-direction]="direction"
                                data-zone="drop"
                            ></div>
                        </div>
                    </xhtml:div>
                </foreignObject>
            </g>
        </g>
        <ng-content></ng-content>
        <use [attr.xlink:href]="'#' + activeEdgeId"></use>
    </g>
</svg>

<ng-template #defaultEdgeTemplate let-edge let-selected="selected">
    <svg:path
        [attr.d]="edge.path"
        [attr.marker-end]="!!edge.needArrow ? (selected ? 'url(#arrow-selected)' : 'url(#arrow)') : ''"
    ></svg:path>
</ng-template>
