import { CiCdFieldDescription, CiCdProperty } from '../../../model/fields.model';
import { convertToBoolean } from '../../../utils/utilities';

export function normalizeField(field: CiCdFieldDescription | CiCdProperty): CiCdFieldDescription | CiCdProperty {
    switch (field.type) {
        case 'BOOLEAN':
            if (field.defaultValue && typeof field.defaultValue === 'string') {
                field.defaultValue = convertToBoolean(field.defaultValue.toLowerCase());
            }
            if (field.options && field.options.length) {
                field.options.forEach(option => {
                    if (option.value && typeof option.value === 'string') {
                        option.value = convertToBoolean(option.value.toLowerCase());
                    }
                });
            }
            break;
        case 'NUMBER':
            if (field.defaultValue && typeof field.defaultValue === 'string') {
                field.defaultValue = Number.parseInt(field.defaultValue, null);
            }
            if (field.options && field.options.length) {
                field.options.forEach(option => {
                    if (option.value && typeof option.value === 'string') {
                        option.value = Number.parseInt(option.value, null);
                    }
                });
            }
            break;
        case 'GROOVY':
        case 'STRING':
        default:
            break;
    }
    return field;
}

export function normalizeFields(fields: CiCdFieldDescription[] | CiCdProperty[]): CiCdFieldDescription[] | CiCdProperty[] {
    (fields || []).forEach(field => {
        normalizeField(field);
    });
    return fields;
}
