<div class="modal-header">
    <h4 class="modal-title">Edit Tool Template</h4>
    <button
        type="button"
        class="close"
        aria-label="Close button"
        aria-describedby="modal-title"
        (click)="onCrossClick()"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <perfect-scrollbar class="pr-3">
        <form *ngIf="facade.form" [formGroup]="facade.form" class="edit-tool-template-form px-2">
            <div class="row">
                <lib-input formControlName="name" label="Name" class="col">
                    <span context-help-id="tool-template-name"></span>
                </lib-input>
                <lib-input formControlName="verificationPath" label="Verification Path" class="col">
                    <span context-help-id="tool-template-verification-path"></span>
                </lib-input>
            </div>

            <lib-textarea
                maxlength="1000"
                formControlName="description"
                label="Description"
                class="mt-4"
                resizeMode="none"
                [resize]="false"
            >
                <span context-help-id="tool-template-description"></span>
            </lib-textarea>

            <div class="row">
                <div class="col choose-image-group d-flex">
                    <ng-container *ngIf="facade.form.get('imageUrl').value; else noImage">
                        <img class="tool-template-image mr-10px" [src]="facade.form.get('imageUrl').value | imagePath" alt="" />
                        <button type="button" class="btn btn-link choose-image-button" (click)="handleChooseImage()">
                            <fa-icon icon="rotationRight" class="mr-10px"></fa-icon>
                            Change image
                        </button>
                        <button type="button" class="btn btn-link" (click)="handleDeleteImage()">
                            <fa-icon icon="facTrash" class="mr-10px"></fa-icon>
                            Delete
                        </button>
                    </ng-container>
                    <ng-template #noImage>
                        <button type="button" class="btn btn-link upload-image-button ml-5px" (click)="handleChooseImage()">
                            <fa-icon icon="plus" class="mr-10px"></fa-icon>
                            Upload Image
                        </button>
                    </ng-template>
                </div>
            </div>

            <div class="row mt-4">
                <lib-input type="number" formControlName="sequence" label="Sequence" class="col">
                    <span context-help-id="tool-template-sequence"></span>
                </lib-input>
            </div>

            <div class="row mt-4">
                <lib-dropdown
                    class="col"
                    label="Tool Categories"
                    placeholder="Select Tool Categories"
                    formControlName="pluginCategories"
                    appendTo="body"
                    [items]="pluginCategoryTypes"
                    [clearable]="true"
                    [labelRemovable]="true"
                    [multiple]="true"
                    [addTag]="true"
                    [ngSelectItemsFilter]="true"
                    [multiline]="true"
                >
                    <span context-help-id="tool-template-category"></span>
                </lib-dropdown>
                <lib-input formControlName="pluginType" label="Tool Type" class="col">
                    <span context-help-id="tool-template-type"></span>
                </lib-input>
            </div>

            <div class="row my-4">
                <lib-input formControlName="componentCollection" label="Component Collection" class="col">
                    <span context-help-id="tool-template-component-collection"></span>
                </lib-input>
                <lib-input formControlName="subCollection" label="Sub Collection" class="col">
                    <span context-help-id="tool-template-sub-collection"></span>
                </lib-input>
            </div>

            <dp-edit-tool-template-auth-type (authTypeAdded)="handleAddAuthType()"></dp-edit-tool-template-auth-type>
        </form>
    </perfect-scrollbar>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('Cross click')">Cancel</button>
    <button type="button" ngbAutofocus class="btn btn-primary" (click)="handleSaveToolTemplate()">
        Save and close
    </button>
</div>
