import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

import { ResizeObserverService } from '@dagility-ui/kit';

import { MergeRequestInfo } from './models/merge-request-info';

const MEDIUM_BREAKPOINT = 576;

@Component({
    selector: 'dp-merge-request-info',
    templateUrl: './merge-request-info.component.html',
    styleUrls: ['./merge-request-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ResizeObserverService],
})
export class MergeRequestInfoComponent {
    @Input() data: MergeRequestInfo;

    colCount$ = this.resizeObserverService.observe$(this.elementRef).pipe(
        startWith(null as unknown),
        map(() => this.elementRef.nativeElement.offsetWidth),
        distinctUntilChanged(),
        map(width => {
            return width < MEDIUM_BREAKPOINT ? 2 : 3;
        }),
        distinctUntilChanged()
    );

    readonly itemTextSelector = (element: Element) => element.querySelector('.tag > .text');

    constructor(private resizeObserverService: ResizeObserverService, private elementRef: ElementRef<HTMLElement>) {}

    handleBlur(event: MouseEvent) {
        (event.currentTarget as HTMLLinkElement).blur();
    }
}
