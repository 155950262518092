<div *ngIf="modalService.hasOpenModals()" class="modal-header border-0 align-items-center">
    <h2 class="modal-title mt-0">Add Widget</h2>
    <button class="close" (click)="handleCancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" *ngIf="form" [formGroup]="form">
    <div class="flex-wrap" [class.row]="!modalService.hasOpenModals()" formGroupName="options">
        <ng-container *ngIf="widgetImport">
            <lib-dropdown
                *ngIf="groups$ | obsWithStatus | async as groups"
                class="mb-3 d-block"
                [ngClass]="{ 'col-xl-4 col-md-4 col-sm-6': !modalService.hasOpenModals() }"
                label="Group"
                placeholder="Select Group"
                formControlName="groupId"
                [items]="groups.value"
                [loading]="groups.loading"
            >
                <ng-template libDropdownHeaderTemplate>
                    <button class="btn btn-link" (click)="handleAddNewGroup()">
                        <fa-icon icon="plus" class="mr-1"></fa-icon> Create New Group
                    </button>
                </ng-template>
            </lib-dropdown>
        </ng-container>
    </div>
    <ng-template #formExtension></ng-template>
</div>

<div class="modal-footer border-0">
    <button *ngIf="modalService.hasOpenModals()" class="btn btn-secondary mr-4" (click)="handleCancel()">Cancel</button>
    <button class="btn btn-primary" (click)="handleSave()">Save</button>
</div>
