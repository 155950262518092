import { ColDef } from '@ag-grid-community/core';

import { AnyWidgetChartModel } from '../query-to-options.mapper';

export const BOXPLOT_CATEGORIES = ['Low', 'Q1', 'Q2', 'Q3', 'High', 'Outliers'];

export function convertToGridData({ options }: AnyWidgetChartModel, defaultValue: unknown = null): any[][] {
    const data: unknown[][] = [];
    const columnsHeaders: ColDef[] = options.tableData.columnDefs.filter((column: ColDef) => column.headerName !== undefined);
    data.push(columnsHeaders.map(column => column.headerName));
    const columnValues: string[] = columnsHeaders.map((column: ColDef) => column.field);
    const gridRows: Array<Record<string, unknown>> = options.tableData.rowData;

    return data.concat(
        gridRows.map(row =>
            columnValues.reduce((acc, field) => {
                acc.push(row[field] ?? defaultValue);

                return acc;
            }, [])
        )
    );
}

export function convertToTwoDimensionalGridData({ options }: AnyWidgetChartModel): any[][] {
    const data: string[][] = [];
    data.push([' ', ...options.verticalColumns]);
    for (const horizontal of options.horizontalColumns) {
        const row = [horizontal];
        for (const vertical of options.verticalColumns) {
            const value = (options.data[horizontal][vertical] || {}).value;
            row.push(value === undefined ? ' ' : value);
        }
        data.push(row);
    }

    return data;
}

export function copyContentFromCanvas(oldCanvas: HTMLCanvasElement) {
    const newCanvas = document.createElement('canvas');
    document.body.appendChild(newCanvas);
    const context = newCanvas.getContext('2d');

    newCanvas.width = oldCanvas.width;
    newCanvas.height = oldCanvas.height;
    context.drawImage(oldCanvas, 0, 0);

    return newCanvas;
}

export function getOutliersMap(data: AnyWidgetChartModel): Record<number, number[]> {
    return (data.options.series[1].data as number[][]).reduce<Record<number, number[]>>((acc, [idx, outlier]) => {
        if (!acc[idx]) {
            acc[idx] = [];
        }

        acc[idx].push(outlier);

        return acc;
    }, {});
}
