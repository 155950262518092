<app-qb-model-expression-builder
    id="expressionBuilderRef"
    [cursor]="expressionCursor"
    [expression]="expressionString"
    [isViewEditor]="isViewEditor"
    [isSwitchFunctionEditor]="isSwitchFunctionEditor"
    (expressionChanged)="onExpressionChanged($event)"
    [style.top.px]="expressionBuilderPosition.top"
    [style.left.px]="expressionBuilderPosition.left"
>
</app-qb-model-expression-builder>

<div class="checkboxContainer">
    <checkbox class="size-s" *ngIf="isCheckboxExists()" [value]="isCheckboxSelected()" (click)="handleCheckbox()">
        Unique records
    </checkbox>
</div>
<div class="table">
    <lib-data-grid id="dataGridRef" [dataSource]="gridData" [columns]="gridColumns" [sticky]="true" [bodyHeight]="'170px'">
        <ng-template headerTemplate>
            <th *ngFor="let header of gridColumns" [class.sort-field]="header.sortingField" (click)="header.sortAsc = !header.sortAsc; sort(header.sortingField);">
                {{ header.title }}
                <span
                    *ngIf="header.sortingField"
                    class="sorting-button"
                    [innerHTML]="(header.sortAsc ? sortIcons.sortingDown : sortIcons.sortingUp) | safeHtml: 'html'"
                >
                </span>
            </th>
        </ng-template>
        <ng-template rowTemplate let-row="item">
            <td
                [class.selected]="row.selected"
                [class.editable]="isEditableColumn(column, row)"
                (mouseover)="selectedRow = row"
                *ngFor="let column of gridValues"
                (click)="row.selected = !row.selected"
            >
                <ng-container [ngSwitch]="column">
                    <ng-container *ngSwitchCase="'edit'">
                        <fa-icon
                            *ngIf="row.selected && row.calculated"
                            class="cursor-pointer"
                            icon="facEdit"
                            (click)="onEditRow(row)"
                        ></fa-icon>
                    </ng-container>

                    <ng-container *ngSwitchCase="'remove'">
                        <fa-icon
                            *ngIf="row.selected || selectedRow === row"
                            class="cursor-pointer"
                            icon="facTrash"
                            (click)="onRemoveRows()"
                        ></fa-icon>
                    </ng-container>

                    <ng-container
                        *ngSwitchDefault
                        [ngTemplateOutlet]="isEditableColumn(column, row) ? columnInput : columnText"
                        [ngTemplateOutletContext]="{ row, column }"
                    >
                    </ng-container>
                </ng-container>
            </td>
        </ng-template>
    </lib-data-grid>
</div>

<span class="btn button-add" (click)="onAddNew()"><span class="fa fa-plus mr-1"></span>Add calculated field</span>

<ng-template #columnInput let-column="column" let-row="row">
    <div class="d-inline-flex">
        <lib-input
            [propertiesCompletion]="[]"
            [id]="column + 'InputRef'"
            [(ngModel)]="row[column]"
            class="input-column"
            (click)="onClickInput(column)"
            [class.invalid]="row[column] === '' && isEmptyRow"
            (keydown)="onKeyPressed($event)"
        ></lib-input>
        <div
            class="fx-button"
            [class.selected]="isSwitchFunctionEditor"
            *ngIf="column === 'expressionView'"
            (click)="onOpenFunctionEditor()"
            id="fxButtonRef"
        >
            <span class="m-2 fa">
                <img alt="" src="assets/images/icons/icon_function.svg" />
            </span>
        </div>
    </div>
</ng-template>

<ng-template #columnText let-column="column" let-row="row">
    <div>{{ row[column] }}</div>
</ng-template>
