import { Component, Input, Output, EventEmitter, Inject } from '@angular/core';
import { ControlContainer, FormGroup, FormGroupDirective } from '@angular/forms';
import { FORM_ERRORS, validateFormAndDisplayErrors } from '@dagility-ui/kit';

import { AnyWidgetModel, WidgetHelp } from 'data-processor';
import { WidgetBuilderStore } from 'data-processor/lib/widget-library/widget-builder/services/widget-builder.store';

import { WidgetBuilderFacade } from '../../../state/widget-builder.facade';
import { HELP_FORM_ERRORS_PROVIDER } from './widget-builder-help-errors.provider';

const DEFAULT_HELP: WidgetHelp = {
    summary: '',
    description: '',
    howScoreCalculated: '',
    howScoreCanImproved: '',
    insights: [
        {
            insight: '',
            recommendations: [''],
        },
    ],
};

@Component({
    selector: 'dp-widget-builder-help-form',
    templateUrl: './widget-builder-help-form.component.html',
    styleUrls: ['./widget-builder-help-form.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
    providers: [HELP_FORM_ERRORS_PROVIDER],
})
export class WidgetBuilderHelpFormComponent {
    @Input() set data(widget: AnyWidgetModel) {
        this.form = this.facade.buildHelpForm(widget.help ?? DEFAULT_HELP);
    }

    @Input() layerId: string;

    @Output() save = new EventEmitter();

    @Output() preview = new EventEmitter();

    form: FormGroup;

    isRequiredText: string;

    constructor(
        private facade: WidgetBuilderFacade,
        private store: WidgetBuilderStore,
        @Inject(FORM_ERRORS) formErrors: Record<string, () => string>
    ) {
        this.isRequiredText = formErrors.required();
    }

    handlePreview() {
        if (!this.form.valid) {
            validateFormAndDisplayErrors(this.form);

            return;
        }

        this.preview.emit(this.form.value);
    }

    handleReset() {
        this.form = this.facade.buildHelpForm(this.store.lastSavedState?.layers[this.layerId]?.help ?? DEFAULT_HELP);
    }

    handleSave() {
        if (!this.form.valid) {
            validateFormAndDisplayErrors(this.form);

            return;
        }

        this.save.emit(this.form.value);
    }
}
