import { FormArray, FormGroup, ValidationErrors } from '@angular/forms';
import { isEmpty } from 'lodash';

import { Deserializable } from './abstract-job-definition';
import { IsString, Type } from '../type.decorator';
import { Validate, NotEmpty, Required, mapRequired } from '../validate.decorator';
import { JobDefinitionDataType } from './enums';
import { appendError, removeError } from '@dagility-ui/kit';

export enum FieldType {
    OBJECT = 'OBJECT',
    STRING = 'STRING',
    INTEGER = 'INTEGER',
    LONG = 'LONG',
    DOUBLE = 'DOUBLE',
    DATE = 'DATE',
    DATETIME = 'DATETIME',
    BOOLEAN = 'BOOLEAN',
    STRING_ARRAY = 'STRING_ARRAY',
    BLOB = 'BLOB',
    BYTE = 'BYTE',
    PARTITIONER = 'PARTITIONER',
}

export class DataSchema extends Deserializable {
    _class = {};
    _enums = {
        columns: FieldType,
    };
    _maps = {
        columns: FieldType,
        mappings: 'string',
    };
    _arrays = {
        keys: 'string',
        excluded: 'string',
    };
    _nameMapping = {
        columns: {
            key: 'Name',
            value: 'Type',
        },
        mappings: {
            key: 'Target Column',
            value: 'Variable Field',
        },
    };

    meta = {
        _arrays: this._arrays,
        _enums: this._enums,
        _class: this._class,
        _maps: this._maps,
        _nameMapping: this._nameMapping,
    };

    // @MapRequired()
    @Validate([NotEmpty(isEmpty)])
    @Type(FieldType, { enum: true })
    columns: Map<string, FieldType> = {} as any;

    @Required()
    @Validate([NotEmpty(isEmpty)])
    @Type(String)
    keys: string[] = [];

    @Type(String)
    excluded: string[] = [];

    @Type(String)
    mappings: Map<string, string> = {} as any;

    @IsString()
    version: string = '';

    static KeysInColumns(group: FormGroup): ValidationErrors | null {
        const schema = group.get('schema');

        if (schema) {
            const keysControl = schema.get('keys');
            const columns = Object.keys(schema.get('columns').value || {});

            if (group.get('dataType').value !== JobDefinitionDataType.ELASTICSEARCH) {
                (keysControl as FormArray).controls.forEach(control => {
                    if (columns.includes(control.value)) {
                        removeError(control, 'keyInColumns');
                    } else {
                        appendError(control, 'keyInColumns');
                    }
                }, true);
            } else {
                (keysControl as FormArray).controls.forEach(control => {
                    removeError(control, 'keyInColumns');
                });
            }
        }

        return null;
    }

    static ColumnsNotEmpty(group: FormGroup): ValidationErrors | null {
        const schema = group.get('schema');

        if (!!schema) {
            const columns = schema.get('columns') as FormGroup;
            if (columns) {
                if (group.get('dataType').value !== JobDefinitionDataType.ELASTICSEARCH) {
                    if (mapRequired(columns)) {
                        appendError(columns, 'required');
                    } else {
                        removeError(columns, 'required');
                    }
                } else {
                    removeError(columns, 'required');
                }
            }
        }

        return null;
    }

    getType() {
        return 'DataSchema';
    }

    getInfo() {
        return 'Data Schema';
    }

    constructor(instanceData?: any) {
        super();
        this.fillByInstance(DataSchema, instanceData);
    }
}
