import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '@app/auth';
import { tap } from 'rxjs/operators';
import { RequiredRights } from '../../models/permission-types.model';
import { UserService } from '@app/shared/services/admin/user.service';

@Injectable({
    providedIn: 'root',
})
export class PermissionGuard implements CanActivate, CanActivateChild {
    constructor(
        private authService: AuthService,
        private router: Router,
        private toastrService: ToastrService,
        private userService: UserService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        const permissions = <RequiredRights>route.data.permissions;
        const defaultPage = this.userService.defaultPage;

        return this.authService.hasRights(permissions).pipe(
            tap(result => {
                if (!result) {
                    this.toastrService.error('You can`t access this location.', 'Access denied');
                    this.router.navigate([defaultPage], { replaceUrl: true });
                }
            })
        );
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const defaultPage = this.userService.defaultPage;

        return this.authService.hasLocationPermission(state.url).pipe(
            tap(value => {
                if (!value) {
                    this.toastrService.error('You can`t access this location.', 'Access denied');
                    this.router.navigate([defaultPage], { replaceUrl: true });
                }
            })
        );
    }
}
