import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { GridColumn, sortingDown, sortingUp } from '@dagility-ui/kit';
import { Sort, sortArrayByFieldAndDirection, sortGridColumn } from '@dagility-ui/shared-components/utils/sorting.utils';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'dp-code-issue-details',
    templateUrl: './code-issue-details-template.component.html',
    styleUrls: ['./code-issue-details-template.component.scss'],
})
export class CodeIssueDetailsTemplateComponent {
    @ViewChild('templateContent') templateContent: ElementRef;

    @Input() data: any;

    activeTab: string;

    defects$: Observable<any[]>;
    sort$ = new BehaviorSubject({} as Sort);

    dataKeys = [
        {
            value: 'severity',
            label: 'Severity',
        },
        {
            value: 'date',
            label: 'Date',
        },
        {
            value: 'age',
            label: 'Age',
        },
        {
            value: 'found_by',
            label: 'Found by',
        },
        {
            value: 'name',
            label: 'Name',
        },
        {
            value: 'cwe',
            label: 'CWE',
        },
        {
            value: 'component',
            label: 'Component',
        },
        {
            value: 'location',
            label: 'Location',
        },
        {
            value: 'state',
            label: 'Status',
        },
        {
            value: 'cve',
            label: 'CVE',
        },
        {
            value: 'defect',
            label: 'Defect ID',
            prefix: 'pm',
            isLink: true,
            openTab: 'tab-Defect-Details',
            showIf: 'defectList'
        },
        {
            value: 'source_url',
            label: 'Source URL',
        },
    ];

    defectColumns: Array<GridColumn & { sort?: string }> = [
        { title: 'Defect ID', width: '15%', sortingField: 'pm_key' },
        { title: 'Status', width: '15%', sortingField: 'state' },
        { title: 'Component', width: '31%', sortingField: 'component' },
        { title: 'Source URL', width: '13%', sortingField: 'source_url' },
        { title: 'Found by', width: '13%', sortingField: 'found_by' },
        { title: 'Assignee', width: '13%', sortingField: 'assignee' },
    ];

    icons = {
        sortingUp,
        sortingDown,
    };

    get gridHeight() {
        return this.templateContent.nativeElement.clientHeight - 230 + 'px';
    }

    get scrollbarHeight() {
        return this.templateContent ? 'calc(100vh - ' + (document.documentElement.clientHeight - this.templateContent.nativeElement.clientHeight + 190 + 'px') + ')' : '0px';
    }

    ngOnInit() {
        this.defects$ = this.data.defectList ? of(this.data.defectList).pipe(switchMap(this.sortDefects.bind(this))) : of(null);
    }

    sortDefects(defects: any[]) {
        return this.sort$.pipe(map(({ field, direction }) => sortArrayByFieldAndDirection(defects, field, direction)));
    }

    sortClick(index: number) {
        sortGridColumn(index, this.defectColumns, this.sort$);
    }

    changeActiveTab(event: any) {
        event.preventDefault();
        this.activeTab = event.nextId;
    }

    openTab(tab: string) {
        this.activeTab = tab;
    }

    openLink(url: string) {
        if (url && url != '--') {
            window.open(url);
        }
    }

    cleanRemediation(value: string) {
        return value ? value.replace(/\\/g, '') : "";
    }
}
