<lib-stream-wrapper class="d-flex p-3 flex-column flex-1 overflow-hidden" [dataStream$]="data$">
    <ng-template contentTemplate>
        <div class="header-container d-flex align-items-center cursor-pointer pb-4">
            <div class="cursor-pointer d-flex" (click)="quit()">
                <fa-icon icon="facArrowLeftRegular" class="fa font-16px back-icon"></fa-icon>
                <h3 class="ml-2 mt-0 mb-0 font-weight-bold">{{ dashboardName }} Dashboard Filters</h3>
            </div>

            <ng-template [ngIf]="filters" [ngTemplateOutlet]="filters"> </ng-template>
        </div>
        <dp-widget-builder
            class="widget-builder"
            (saveFilters)="handleSave($event)"
            (saveAndClose)="handleSaveAndClose($event)"
        ></dp-widget-builder>
    </ng-template>
</lib-stream-wrapper>
