import { Directive, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';

import { DpDashboardState } from '../state/dp-dashboard.state.model';

interface DashboardFilterContext {
    dashboard: Pick<DpDashboardState, 'options' | 'id' | 'dynamic' | 'uuid'>;
    event: Observable<unknown>;
}

@Directive({
    selector: 'ng-template[dashboardExternalFilter]',
})
export class DashboardExternalFilterDirective {
    constructor(public template: TemplateRef<DashboardFilterContext>) {}
}
