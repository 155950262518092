import { Component, ContentChild, HostBinding, inject, Input, TemplateRef } from '@angular/core';
import pick from 'lodash/pick';

import { Mapper } from '@dagility-ui/shared-components';
import { isDefined } from '@dagility-ui/kit';
import { MultiValueTile, SingleValueTile, TileValue, WidgetTile } from 'data-processor';
import { DATA_MORPH_FEATURE_TOGGLE } from 'data-processor/tokens';
import { identity } from 'lodash';

@Component({
    selector: 'button[widget-tile]',
    templateUrl: './widget-tile.component.html',
    styleUrls: ['./widget-tile.component.scss'],
})
export class WidgetTileComponent {
    @Input('widget-tile') set tile(value: WidgetTile) {
        if (isSingleValueTile(value)) {
            ((value as unknown) as MultiValueTile).values = [
                pick<SingleValueTile, keyof TileValue>(
                    value,
                    'value',
                    'delta',
                    'deltaPostfix',
                    'postfix',
                    'additionalText',
                    'deltaIconInversed',
                    'deltaInversed',
                    'valueColor'
                ),
            ];

            delete value.value;
        }

        this._tile = value as MultiValueTile;
    }
    @Input() tileValueFormatter: Mapper<TileValue['value'], string> = v => v as string;

    @HostBinding('attr.data-colored-delta') coloredDelta = true;

    @Input() tileDeltaFormatter: Mapper<TileValue['delta'], string> = identity;

    @HostBinding('attr.vertical-tile') @Input() vertical: boolean;

    @ContentChild(TemplateRef) valueTemplate: TemplateRef<{ $implicit: TileValue['value'] }>;

    private _tile: MultiValueTile;

    get tile(): MultiValueTile {
        return this._tile;
    }

    @HostBinding('attr.card-mode') @Input() cardMode: boolean;

    @HostBinding('attr.disabled') get disabled() {
        return (!this.cardMode && !this.tile?.targetDrilldown) || null;
    }

    @HostBinding('attr.tile-background') get tileBackground() {
        return this.tile?.backgroundColor;
    }

    isIncrease: Mapper<TileValue, boolean> = (tile, inversedField: keyof TileValue) =>
        this.deltaSign(tile) * (tile[inversedField] ? -1 : 1) > 0;

    deltaSignCharacter = (tile: TileValue) => {
        const sign = this.deltaSign(tile);

        if (typeof tile.value === 'string' && tile.value.startsWith('+')) {
            return '';
        }

        return sign === 1 ? '+' : '';
    };

    deltaSign(tile: TileValue) {
        if (isDefined(tile.deltaSign)) {
            return tile.deltaSign;
        }
        tile.deltaSign = Math.sign(parseFloat((tile.delta as unknown) as string));

        return tile.deltaSign;
    }
}

function isSingleValueTile(tile: WidgetTile): tile is SingleValueTile {
    return Object.prototype.hasOwnProperty.call(tile, 'value');
}
