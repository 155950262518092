<perfect-scrollbar [config]="perfectScrollbarConfig" [style.maxWidth.vw]="95">
    <div [style.minWidth.px]="bodyWidth">
        <ng-container
            *ngTemplateOutlet="headerTemplateRef || defaultHeaderTemplate; context: { title: title, modal: modal }"
        ></ng-container>
        <ng-template #defaultHeaderTemplate>
            <div class="modal-header p-25px">
                <ng-container
                    *ngTemplateOutlet="titleTemplateRef || defaultTitleTemplate; context: { title: title, modal: modal }"
                ></ng-container>

                <ng-template #defaultTitleTemplate>
                    <h6 class="modal-title">{{ title }}</h6>
                </ng-template>

                <div class="pull-right">
                    <fa-icon
                        class="fa zoom"
                        icon="{{ isExpanded ? 'facCompress' : 'facExpand' }}"
                        (click)="isExpanded = !isExpanded"
                    ></fa-icon>
                    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        </ng-template>
        <div class="modal-body p-0" [style.width.px]="bodyWidth" [style.maxHeight.px]="bodyHeight">
            <div *ngIf="subHeaderData" class="d-flex my-3 px-4">
                <div class="mr-4">
                    Build Id: <strong>{{ subHeaderData?.buildNumber }}</strong>
                </div>
                <div>
                    Execution Date: <strong>{{ subHeaderData?.startingTime | date: 'dateTimeCustom' }}</strong>
                </div>
            </div>

            <lib-log
                *ngIf="logs$"
                [logs$]="logs$"
                [contentTemplateRef]="contentTemplateRef"
                [title]="title"
                [request$]="request$"
                [height]="logHeight"
                [fileName]="fileName"
            ></lib-log>
        </div>
    </div>
</perfect-scrollbar>
