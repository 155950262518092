import { TraceabilityService } from '../api/traceability.service';
import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { Traceability } from '../model/traceability.model';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-chart-node',
    templateUrl: './chart-node.component.html',
    styleUrls: ['./chart-node.component.scss'],
    host: {
        '(window:resize)': 'nodeConnector($event)',
    },
})
export class ChartNodeComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() traceabilityData: Traceability = {};
    @ViewChild('containerHead') containerHead: ElementRef;
    @ViewChildren('containerChild') containerChild: QueryList<ElementRef>;
    selectedId: number = null;
    selectednode: number = null;
    private subscription: Subscription[] = [];
    constructor(private traceabilityService: TraceabilityService) {}

    ngOnInit() {
        this.subscription.push(
            this.traceabilityService.closeNode.subscribe(obj => {
                this.removeNodeSelection();
            })
        );
    }

    nodeConnector() {
        const headerData = this.traceabilityData.statusHeader;
        const contentData = this.traceabilityData.traceabilityNode;
        const nodeWidth = 260;
        const nodespace = 5;

        this.containerChild.forEach((obj, index) => {
            let top = '';
            let left = '';
            let right = '';
            let width = '';
            let height = '';
            const border = '1px dotted';
            let borderTop = '';
            let borderLeft = '';
            let borderRight = '';
            if (!contentData[index + 1]) {
                return;
            }
            const currentStatus = contentData[index].status;
            const nextStatus = contentData[index + 1].status;
            const currentIndex = headerData.findIndex(item => item.status === currentStatus);
            const nextIndex = headerData.findIndex(item => item.status === nextStatus);

            if (currentIndex === nextIndex) {
                top = '100%';
                left = '50%';
                height = '40px';
                borderRight = border;
            } else if (currentIndex < nextIndex) {
                const indexDifference = nextIndex - currentIndex;
                const elementLinkWidth = nodespace * indexDifference + nodeWidth * (indexDifference - 1) + nodeWidth / 2;
                const blockwidth = elementLinkWidth;
                left = '100%';
                top = '50%';
                height = '55px';
                borderTop = border;
                width = blockwidth + 'px';
                borderRight = border;
            } else if (currentIndex > nextIndex) {
                const indexDifference = currentIndex - nextIndex;
                const elementLinkWidth = nodespace * indexDifference + nodeWidth * (indexDifference - 1) + nodeWidth / 2;
                const blockwidth = elementLinkWidth;
                right = '100%';
                top = '50%';
                height = '55px';
                borderTop = border;
                width = blockwidth + 'px';
                borderLeft = border;
            }
            obj.nativeElement.style.setProperty('--block-top', top);
            obj.nativeElement.style.setProperty('--block-left', left);
            obj.nativeElement.style.setProperty('--block-height', height);
            obj.nativeElement.style.setProperty('--block-border-right', borderRight);
            obj.nativeElement.style.setProperty('--block-border-top', borderTop);
            obj.nativeElement.style.setProperty('--block-width', width);
            obj.nativeElement.style.setProperty('--block-right', right);
            obj.nativeElement.style.setProperty('--block-border-left', borderLeft);
        });

        this.scrollHandler();
    }

    scrollHandler() {
        let scroll_div = document.getElementById('traceability-grid');
        scroll_div.addEventListener('scroll', () => {
            let i;
            const translate_y = 'translate(0,' + scroll_div.scrollTop + 'px)';
            const translate_x = 'translate(' + scroll_div.scrollLeft + 'px,0px)';
            const translate_xy = 'translate(' + scroll_div.scrollLeft + 'px,' + scroll_div.scrollTop + 'px)';

            const fixed_vertical_elts = document.getElementsByClassName('freeze_vertical') as HTMLCollectionOf<HTMLElement>;
            const fixed_horizontal_elts = document.getElementsByClassName('freeze_horizontal') as HTMLCollectionOf<HTMLElement>;
            const fixed_both_elts = document.getElementsByClassName(' freeze') as HTMLCollectionOf<HTMLElement>;

            for (i = 0; i < fixed_horizontal_elts.length; i++) {
                fixed_horizontal_elts[i].style.webkitTransform = translate_x;
                fixed_horizontal_elts[i].style.transform = translate_x;
            }

            for (i = 0; i < fixed_vertical_elts.length; i++) {
                fixed_vertical_elts[i].style.webkitTransform = translate_y;
                fixed_vertical_elts[i].style.transform = translate_y;
            }

            for (i = 0; i < fixed_both_elts.length; i++) {
                fixed_both_elts[i].style.webkitTransform = translate_xy;
                fixed_both_elts[i].style.transform = translate_xy;
            }
        });
    }

    ngAfterViewInit() {
        this.nodeConnector();
    }

    onClickNode(traceabilityNode: Record<string, any>, id: number) {
        this.selectedId = id;
        this.setSelectedNode(traceabilityNode, id);
    }

    setSelectedNode(traceabilityNode: Record<string, any>, id: number) {
        let nodeId = id;
        if (this.dateDuplicateCheck(traceabilityNode, nodeId)) {
            nodeId = nodeId - 1;
            this.setSelectedNode(traceabilityNode, nodeId);
        } else {
            this.selectednode = nodeId;
        }
    }

    dateDuplicateCheck = (traceabilityNode: Record<string, any>, index: number) => {
        if (!traceabilityNode[index - 1]) {
            return false;
        }
        const currrentTimeStamp = traceabilityNode[index].timeStamp;
        const previousTimeStamp = traceabilityNode[index - 1].timeStamp;
        const currentDate = new DatePipe('en-US').transform(currrentTimeStamp, 'MMM dd');
        const previousDate = new DatePipe('en-US').transform(previousTimeStamp, 'MMM dd');
        if (currentDate === previousDate) {
            return true;
        } else {
            return false;
        }
    };

    removeNodeSelection() {
        this.selectedId = null;
        this.selectednode = null;
    }

    ngOnDestroy() {
        this.subscription.forEach(s => s.unsubscribe());
    }
}
