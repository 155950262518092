import { GridsterItemPrototypeDirective, GridsterPrototypeService, GridsterService } from 'angular2gridster';
import { filter, takeUntil, tap } from 'rxjs/operators';
import {
    AfterContentInit,
    ContentChildren,
    Directive,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    QueryList,
    SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';

export const GRIDSTER_RESIZE_EVENT = 'gridster-resize-event';
/**
 * Since there is not built-in implementation for dnd between dashboards in angular2gridster
 * We use customized implementation based on built-in ngxGridsterItemPrototype directive
 */
const enableDragDrop = GridsterItemPrototypeDirective.prototype['enableDragDrop'];
GridsterItemPrototypeDirective.prototype['enableDragDrop'] = function(...args: any[]) {
    if (this.elementRef.nativeElement.tagName === 'NGX-GRIDSTER-ITEM') {
        return;
    }
    return enableDragDrop.apply(this, args);
};

@Directive({
    // eslint-disable-next-line
    selector: 'ngx-gridster[widgets]',
})
export class GridsterWidgetsDirective implements OnChanges, AfterContentInit, OnDestroy {
    @Input() set widgets(value: any) {
        (this.gridsterService as any)['widgets'] = value;
    }

    @Input() dragAndDrop: boolean;

    @ContentChildren(GridsterItemPrototypeDirective) gridsterItemPrototypeDirs: QueryList<GridsterItemPrototypeDirective>;

    private destroy$ = new Subject<void>();

    constructor(private gridsterService: GridsterService, private zone: NgZone) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dragAndDrop) {
            this.toggleDragAndDrop();
        }
    }

    toggleDragAndDrop() {
        if (!this.gridsterItemPrototypeDirs?.length) {
            return;
        }

        this.gridsterItemPrototypeDirs.forEach((dir: any) => {
            dir.ngOnDestroy();
            if (this.dragAndDrop) {
                this.zone.runOutsideAngular(() => enableDragDrop.apply(dir));
            }
        });
    }

    ngAfterContentInit() {
        this.toggleDragAndDrop();
        this.gridsterItemPrototypeDirs.changes.pipe(takeUntil(this.destroy$)).subscribe(() => this.toggleDragAndDrop());
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.gridsterItemPrototypeDirs.forEach((dir: any) => dir.ngOnDestroy());
    }
}

GridsterPrototypeService.prototype.observeDropOut = function(gridster) {
    return this['dragStopSubject'].pipe(
        filter((data: any) => {
            const gridsterEl = gridster.gridsterComponent.$element;

            return !this['isOverGridster'](data.item, gridsterEl, data.event, gridster.options);
        }),
        tap((data: any) => {
            data.item.cancel.emit({ item: data.item, gridster });
        }),
    );
};

GridsterPrototypeService.prototype['isOverGridster'] = function(
    item: GridsterItemPrototypeDirective,
    gridsterEl: HTMLElement,
    event: any,
    options: any,
): boolean {
    const parentItem = <HTMLElement>gridsterEl.parentElement && <HTMLElement>gridsterEl.parentElement.closest('gridster-item');

    if (parentItem) {
        return this.isOverGridster(item, parentItem, event, options);
    }

    return isCursorAboveElement(event, gridsterEl);
};

function isCursorAboveElement(event: any, element: Element): boolean {
    const elRect = element.getBoundingClientRect();
    const scrollTop = window.scrollY;
    const scrollLeft = window.scrollX;
    return (
        event.pageX - scrollLeft > elRect.left &&
        event.pageX - scrollLeft < elRect.right &&
        event.pageY - scrollTop > elRect.top &&
        event.pageY - scrollTop < elRect.bottom
    );
}

GridsterItemPrototypeDirective.prototype['setElementPosition'] = function(element: HTMLElement, position: { x: number; y: number }) {
    this.positionX = position.x;
    this.positionY = position.y;

    let shiftX = 0;
    let shiftY = 0;
    if (element.style.transform) {
        const matches = element.style.transform.match(/translate\(([+-]?\d+(\.\d+)?)px, ([+-]?\d+(\.\d+)?)px\)/);

        if (matches) {
            shiftX = +matches[1];
            shiftY = +matches[3];
        }
    }

    element.style.left = position.x - shiftX + 'px';
    element.style.top = position.y - shiftY + 'px';
};


// patches for showing of tooltip with current sizes

const onResizeDrag = GridsterService.prototype['onResizeDrag'];
GridsterService.prototype.onResizeDrag = function(...args: any[]) {
    onResizeDrag.apply(this, args);
    const [item] = args;
    (item.$element as HTMLElement).dispatchEvent(new CustomEvent(GRIDSTER_RESIZE_EVENT, {}));
};
