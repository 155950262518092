<ng-container *ngIf="!isLoading; else loaderTemplate">
    <div class="modal-header">
        <div [ngClass]="isLibrary ? 'cursor-pointer d-flex' : ''">
            <fa-icon class="font-16px mr-10px" *ngIf="isLibrary" (click)="closeLibrary()" [icon]="'facArrowLeftRegular'"></fa-icon>
            <h2 class="mt-0">{{ isLibrary ? 'Alert Library' : 'Set Thresholds for Alerts' }}</h2>
        </div>

        <button type="button" class="close" aria-describedby="modal-title" (click)="onClose()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div *ngIf="thresholds && isLibrary" class="modal-body custom-modal-body d-flex align-items-center">
        <lib-input
            placeholder="Search"
            class="mr-20px w-100"
            [(ngModel)]="alertSearchText"
            (change)="onAlertSearchInputChange()"
        ></lib-input>
        <checkbox class="text-nowrap" [value]="allAlerts" (click)="selectAllAlerts()">Select all alerts</checkbox>
    </div>
    <div class="modal-body p-0" [style.height]="isLibrary ? 'calc(100% - 214px)' : 'calc(100% - 65px)'">
        <perfect-scrollbar>
            <ngb-accordion #accordion [destroyOnHide]="false" class="custom-ngb-accordion">
                <ngb-panel *ngIf="!isLibrary">
                    <ng-template ngbPanelHeader>
                        <div class="cursor-pointer color-brand" (click)="createAlert()">
                            <fa-icon [icon]="'plus'"></fa-icon>
                            <span class="ml-12px">Add one more alert</span>
                        </div>
                    </ng-template>
                </ngb-panel>
                <ngb-panel *ngIf="!isLibrary">
                    <ng-template ngbPanelHeader>
                        <div class="cursor-pointer color-brand" (click)="openLibrary()">
                            <fa-icon [icon]="'plus'"></fa-icon>
                            <span class="ml-12px">Add from library</span>
                        </div>
                    </ng-template>
                </ngb-panel>
                <ngb-panel
                    *ngFor="let threshold of isLibrary ? thresholds : alertsById; let i = index"
                    [id]="'panel-' + threshold.id"
                    [cardClass]="accordion.isExpanded('panel-' + threshold.id) ? 'panel-expanded' : 'panel-collapsed'"
                >
                    <ng-template ngbPanelHeader>
                        <div
                            class="cursor-pointer d-flex col px-0"
                            style="overflow-wrap: anywhere"
                            [ngClass]="accordion.isExpanded('panel-' + threshold.id) ? 'color-brand' : 'primary-text'"
                            (click)="accordion.toggle('panel-' + threshold.id)"
                        >
                            <fa-icon [icon]="accordion.isExpanded('panel-' + threshold.id) ? 'chevron-up' : 'chevron-down'"></fa-icon>
                            <span class="ml-12px">{{ threshold.name }}</span>
                        </div>
                        <div class="d-flex justify-content-end col px-0">
                            <span [ngClass]="'alert-active-label alert' + (threshold.conditions ? '-metric' : '-event')">{{
                                threshold.conditions ? 'By Metric' : 'By Event'
                            }}</span>
                            <span class="mr-8px" [ngClass]="'alert-active-label alert' + (threshold.enabled ? '-active' : '-inactive')">{{
                                threshold.enabled ? 'Active' : 'Inactive'
                            }}</span>
                            <checkbox
                                class="text-nowrap"
                                *ngIf="isLibrary"
                                [value]="findSelected(threshold.id)"
                                (click)="onSelectAlert(threshold.id)"
                            >
                                Select alert
                            </checkbox>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <app-alert-card
                            [alert]="threshold"
                            [importance]="getImportance(threshold.importance)"
                            [frequency]="getFrequency(threshold.frequency)"
                        ></app-alert-card>
                        <div class="d-flex justify-content-around pb-4">
                            <button (click)="editAlert(threshold.id)" class="alert_button btn btn-link" *ngIf="threshold.editEnabled">
                                <fa-icon icon="facEdit" class="font-16px mr-10px"></fa-icon>
                                <span class="font-14px">Edit alert</span>
                            </button>

                            <button (click)="deleteAlert(threshold.id)" class="alert_button btn btn-link" *ngIf="threshold.editEnabled">
                                <fa-icon icon="facTrash" class="font-16px mr-10px"></fa-icon>
                                <span class="font-14px">Delete alert</span>
                            </button>
                        </div>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </perfect-scrollbar>
    </div>
    <div class="modal-footer" *ngIf="thresholds && isLibrary">
        <div class="d-inline-flex justify-content-end align-items-center">
            <span class="font-14px mr-3">{{ 'Selected: ' + selected.length + ' ' + (selected.length === 1 ? 'alert' : 'alerts') }}</span>
            <button id="custom-button-cancel" class=" btn btn-secondary size-m mr-3" (click)="onClose()">
                Cancel
            </button>
            <button id="custom-button-add" class=" btn btn-primary size-m" [disabled]="!selected.length" (click)="onSave()">
                Add
            </button>
        </div>
    </div>
</ng-container>
<ng-template #loaderTemplate>
    <div class="d-flex justify-content-center my-4">
        <lib-spinner></lib-spinner>
    </div>
</ng-template>
