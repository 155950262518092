import { Component, ElementRef, NgZone, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { GridsterItemComponent } from 'angular2gridster';

import { GRIDSTER_RESIZE_EVENT } from '../angular2grister-patch';

@Component({
    selector: 'dp-gridster-item-size',
    template: `
        <div class='pixel-size info3 pb-1' #pixelSizeContainer></div>
        <div class='units-size body2' #unitSizeContainer></div>
    `,
    styleUrls: ['./gridster-item-size.component.scss'],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'p-2',
    },
})
export class GridsterItemSizeComponent implements OnDestroy {
    @ViewChild('pixelSizeContainer', { read: ElementRef }) pixelSizeContainer: ElementRef<HTMLDivElement>;
    @ViewChild('unitSizeContainer', { read: ElementRef }) unitSizeContainer: ElementRef<HTMLDivElement>;

    constructor(item: GridsterItemComponent, zone: NgZone, renderer2: Renderer2) {
        zone.runOutsideAngular(() => {
            this.unListenFn = renderer2.listen(item.$element, GRIDSTER_RESIZE_EVENT, () => {
                this.pixelSizeContainer.nativeElement.textContent = `${Math.round(
                    item.$element.offsetWidth,
                )}x${Math.round(item.$element.offsetHeight)} px`;
                this.unitSizeContainer.nativeElement.textContent = `${item.item.w}x${item.item.h} units`;
            });
        });
    }

    ngOnDestroy() {
        this.unListenFn();
    }

    private unListenFn = () => {
        // empty
    };
}
