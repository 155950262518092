import { NgModule } from '@angular/core';

import { EditJobDefinitionFormComponent } from './edit-job-definition-form.component';
import { EditJobDefinitionTemplateComponent } from './edit-job-definition-template/edit-job-definition-template.component';
import { EditJobDefinitionSideMenuComponent } from './edit-job-definition-side-menu/edit-job-definition-side-menu.component';
import { EditJobDefinitionSetupComponent } from './edit-job-definition-setup/edit-job-definition-setup.component';
import { EditJobDefinitionIterativeTemplateComponent } from './edit-job-definition-iterative-template/edit-job-definition-iterative-template.component';
import { EditJobDefinitionIssueListComponent } from './edit-job-definition-issue-list/edit-job-definition-issue-list.component';
import { ConfigureJobDefinitionSelectTypeComponent } from './configure-job-definition-select-type/configure-job-definition-select-type.component';
import { NormalizePathPipe } from './edit-job-definition-issue-list/normalize-path.pipe';
import { EditJobDefinitionBreadcrumbComponent } from './edit-job-definition-breadcrumb/edit-job-definition-breadcrumb.component';
import { EditJobDefinitionGroovyComponent } from './edit-job-definition-groovy/edit-job-definition-groovy.component';
import { JobDefinitionBuilderComponent } from './job-definition-builder/job-definition-builder.component';
import { JobDefinitionBuilderViewComponent } from './job-definition-builder/job-definition-builder-view/job-definition-builder-view.component';
import { JobDefinitionActionsboxComponent } from './job-definition-builder/job-definition-actionsbox/job-definition-actionsbox.component';
import { JdActionBlockComponent } from './job-definition-builder/job-definition-builder-view/jd-action-block/jd-action-block.component';
import { EditWorkflowActionComponent } from './job-definition-builder/edit-workflow-action/edit-workflow-action.component';
import { PropertiesEditorModule, SharedComponentsModule } from '@dagility-ui/shared-components';
import { JobDefinitionBuilderDebugComponent } from './job-definition-builder/job-definition-builder-debug/job-definition-builder-debug.component';
import { DebugLogComponent } from './job-definition-builder/job-definition-builder-debug/debug-log/debug-log.component';
import { StartDebugVariablesComponent } from './job-definition-builder/start-debug-variables/start-debug-variables.component';
import { JobDefinitionIssueButtonComponent } from './job-definition-issue-button/job-definition-issue-button.component';
import { DebugWatchComponent } from './job-definition-builder/job-definition-builder-debug/debug-watch/debug-watch.component';
import { DebugEvaluateComponent } from './job-definition-builder/job-definition-builder-debug/debug-evaluate/debug-evaluate.component';
import { DebugVariablesModule } from '../debug-variables/debug-variables.module';
import { EditJdValueComponent } from './edit-jd-value/edit-jd-value.component';
import { EditOperandActionComponent } from './edit-operand-action/edit-operand-action.component';
import { EditQueryActionComponent } from './edit-query-action/edit-query-action.component';
import { UiKitModule } from '@dagility-ui/kit';
import { JobDefinitionCommentsComponent } from './job-definition-comments/job-definition-comments.component';

@NgModule({
    imports: [SharedComponentsModule, PropertiesEditorModule, DebugVariablesModule, UiKitModule],
    declarations: [
        EditJobDefinitionFormComponent,
        EditJobDefinitionTemplateComponent,
        EditJobDefinitionSideMenuComponent,
        EditJobDefinitionSetupComponent,
        EditJobDefinitionBreadcrumbComponent,
        EditJobDefinitionIterativeTemplateComponent,
        EditJobDefinitionIssueListComponent,
        ConfigureJobDefinitionSelectTypeComponent,
        NormalizePathPipe,
        EditJobDefinitionGroovyComponent,
        JobDefinitionBuilderComponent,
        JobDefinitionBuilderViewComponent,
        JobDefinitionActionsboxComponent,
        JdActionBlockComponent,
        EditWorkflowActionComponent,
        JobDefinitionBuilderDebugComponent,
        StartDebugVariablesComponent,
        JobDefinitionIssueButtonComponent,
        DebugWatchComponent,
        DebugLogComponent,
        DebugEvaluateComponent,
        EditJdValueComponent,
        EditOperandActionComponent,
        EditQueryActionComponent,
        JobDefinitionCommentsComponent,
    ],
    exports: [EditJobDefinitionFormComponent]
})
export class EditJobDefinitionModule {}
