import { ElementRef, QueryList } from '@angular/core';

import { WidgetType } from '../../models/any-widget.model';
import { AnyWidgetComponent } from '../../components/widget/any-widget/any-widget.component';
import { AnyWidgetChartModel } from '../query-to-options.mapper';
import { removeHTMLTags } from 'data-processor/lib/widget-library/widget-builder/services/widget-builder.util';
import { facThreeDots } from '@dagility-ui/kit';
// eslint-disable-next-line @typescript-eslint/naming-convention
type jsPDF = import('./jspdf.imports').jsPDF;

interface Widget {
    chartModel: AnyWidgetChartModel;
    title: string;
    elementRef: ElementRef<HTMLElement>;
    complexWidgets: QueryList<AnyWidgetComponent>;
}

const ADDED_WIDGET_TYPES_FOR_EXPORT: WidgetType[] = [
    WidgetType.TABLE,
    WidgetType.TWO_DIMENSIONAL_TABLE,
    WidgetType.STACKED_BAR_CHART,
    WidgetType.BAR_CHART,
    WidgetType.DOUGHNUT_CHART,
    WidgetType.PIE_CHART,
    WidgetType.LINE_CHART,
    WidgetType.SCATTER_CHART,
    WidgetType.NESTED_PIE_CHART,
    WidgetType.SUNBURST_CHART,
    WidgetType.TREEMAP,
    WidgetType.COMPLEX,
    WidgetType.BOXPLOT,
    WidgetType.TILE_CHART,
    WidgetType.PROGRESS,
    WidgetType.ACCORDION,
    WidgetType.MULTIPLE_Y_AXIS,
    WidgetType.TABLE_WITH_TABS,
    WidgetType.METRIC_TILE,
];

const RESET_BUTTON_SELECTOR = `[data-export-id='reset-filter-button-container']`;

function ignoreDropdownArrowButton(element: Element) {
    return ['ng-arrow-wrapper'].some(selector => element.classList.contains(selector));
}

export class PDFExporter {
    private pdf: jsPDF;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    private IGNORED_ELEMENTS: string[] = ['.widget-filter'];

    private widgets: Widget[] = [];

    dashboardExport: string | null;
    images: any[] = [];

    add(
        chartModel: AnyWidgetChartModel,
        title: string,
        elementRef: ElementRef<HTMLElement>,
        complexWidgets: QueryList<AnyWidgetComponent>
    ) {
        this.widgets.push({ chartModel, title, elementRef, complexWidgets });
    }

    getHtmlElement(elementRef: ElementRef<HTMLElement>): HTMLElement {
        return elementRef.nativeElement.closest('dp-any-widget-wrapper');
    }

    postProcessElement(element: HTMLElement): void {
        // empty by default
    }

    constructor(
        chartModel: AnyWidgetChartModel,
        title: string,
        elementRef: ElementRef<HTMLElement>,
        complexWidgets: QueryList<AnyWidgetComponent>,
        _: any,
    ) {
        this.add(chartModel, title, elementRef, complexWidgets);
    }

    async buildTitle(title: string): Promise<jsPDF> {
        if (!this.pdf) {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const { jsPDF } = await import('./jspdf.imports');
            this.pdf = new jsPDF('p', 'mm', 'a4', true);
        }
        this.pdf.setFontSize(14);
        this.pdf.text(this.pdf.splitTextToSize(title, 200), 10, 10);

        return Promise.resolve(this.pdf);
    }

    fixFilterPaddings(document: Document) {
        const filterInputs = Array.from(document.querySelectorAll<HTMLInputElement>('lib-datepicker input')).concat(
            Array.from(document.querySelectorAll<HTMLInputElement>('lib-input input'))
        );

        // pdf export cuts off input text. We reduce padding for displaying text fully
        for (const input of filterInputs) {
            input.style.paddingTop = '2px';
            input.style.paddingBottom = '2px';
        }
    }

    fixBackgroundColor(document: Document, element: HTMLElement) {
        // we override background color because it's transparent by default
        element.style.backgroundColor = getComputedStyle(element, null).getPropertyValue('--da-cards-and-blocks');
    }

    async snapshotFilters(
        container: HTMLElement,
        html2canvas: (element: HTMLElement, options: Record<string, any>) => Promise<HTMLCanvasElement>
    ) {
        let canvas: HTMLCanvasElement;
        const filtersElement: HTMLElement = container.querySelector('.widget-filters');

        if (!filtersElement || !filtersElement.childElementCount) {
            return null;
        }

        const filtersElementClone = filtersElement.cloneNode(true) as HTMLElement;
        filtersElementClone.style.width = 'max-content';
        filtersElementClone.style.maxWidth = `${filtersElement.offsetWidth}px`;
        filtersElementClone.style.zIndex = '-1';

        document.body.appendChild(filtersElementClone);

        try {
            canvas = await html2canvas(filtersElementClone, { onclone: this.fixFilterPaddings, ignoreElements: ignoreDropdownArrowButton });
        } finally {
            document.body.removeChild(filtersElementClone);
        }

        return canvas;
    }

    async buildBody(id: string, widgetType: WidgetType, widget: Widget): Promise<jsPDF> {
        const wrapperNode = widget.elementRef.nativeElement.closest('dp-any-widget-wrapper') as HTMLElement;
        const title = wrapperNode.getElementsByClassName('chart-header').item(0) as HTMLElement;
        const titleText = removeHTMLTags(title.querySelector('.chart-header__title').innerHTML || '');
        const html2canvas = (await import('html2canvas')).default;
        const titleCanvas = await html2canvas(title, {});
        const filterCanvas = await this.snapshotFilters(wrapperNode, html2canvas);
        const headerCanvas = filterCanvas ? this.concatCanvases(titleCanvas, filterCanvas, 0, 0, false) : titleCanvas;

        if (widgetType === WidgetType.TABLE) {
            await this.exportTableWidget({
                id,
                elementRef: widget.elementRef,
                chartModel: widget.chartModel,
                headerCanvas,
                widgetTitle: titleText,
            });
        } else if (widgetType === WidgetType.TABLE_WITH_TABS) {
            await this.exportTableWithTabsWidget(
                id,
                widget.elementRef,
                widget.chartModel,
                headerCanvas,
                titleText
            );
        } else if (widgetType === WidgetType.TWO_DIMENSIONAL_TABLE) {
            await this.exportTwoDimensionalTableWidget(id, widget.elementRef, headerCanvas);
        } else if (widgetType === WidgetType.COMPLEX) {
            for (const complexWidget of widget.complexWidgets) {
                if (complexWidget.options.type === WidgetType.TABLE) {
                    await this.exportTableWidget({
                        id: complexWidget.id,
                        elementRef: complexWidget.elementRef,
                        chartModel: complexWidget.chartModel,
                        headerCanvas,
                        widgetTitle: titleText,
                    });
                } else if (complexWidget.options.type === WidgetType.TWO_DIMENSIONAL_TABLE) {
                    await this.exportTwoDimensionalTableWidget(
                        complexWidget.id,
                        complexWidget.elementRef,
                        headerCanvas,
                        titleText
                    );
                } else if (ADDED_WIDGET_TYPES_FOR_EXPORT.includes(complexWidget.options.type)) {
                    await this.exportBaseWidget(
                        complexWidget.elementRef,
                        complexWidget.chartModel.options.type,
                        titleText,
                        headerCanvas,
                        true
                    );
                }
                if (widget.complexWidgets.last !== complexWidget) {
                    this.pdf.addPage();
                }
            }
        } else if (ADDED_WIDGET_TYPES_FOR_EXPORT.includes(widgetType)) {
            await this.exportBaseWidget(widget.elementRef, widgetType, titleText);
        }
        this.widgets[0].title = titleText;
        return this.pdf;
    }

    replaceArrows(htmlElement: Document): void {
        //html2canvas support for transform properties are limited. This function replaces rotated icons with a different orientation-independent icon.
        let iconList = Array.from(htmlElement.querySelectorAll('fa-icon[icon="facArrowDownInCircle"]')).concat(
            Array.from(htmlElement.querySelectorAll('fa-icon[icon="facArrowUpInCircle"]'))
        );
        iconList.forEach(function(originalElement: Element){
            let newSVG = document.createElement('svg');
            newSVG.setAttribute('width', '24');
            newSVG.setAttribute('height' , '24');
            newSVG.setAttribute('xmlns', "http://www.w3.org/2000/svg");
            newSVG.setAttribute('stroke', 'currentColor');
            newSVG.setAttribute('stroke-linecap', 'round');
            newSVG.setAttribute('stroke-linejoin', 'round');

            let newPath = document.createElement('path');
            newPath.setAttribute('d', facThreeDots);
            newPath.setAttribute('fill', 'none');

            newSVG.append(newPath);
            newSVG.style.transform = "rotate(0)";

            originalElement.innerHTML = newSVG.outerHTML;
        });
    }

    async exportBaseWidget(
        elementRef: ElementRef<HTMLElement>,
        widgetType: WidgetType,
        title: string,
        headerCanvas?: HTMLCanvasElement,
        isComplexWidget = false
    ) {
        const htmlElement = headerCanvas
            ? (elementRef.nativeElement.querySelector('dp-widget-template-wrapper') as HTMLElement) ||
              (elementRef.nativeElement.querySelector('lib-chart .chart') as HTMLElement) ||
              elementRef.nativeElement.querySelector('.custom-chart')
            : this.getHtmlElement(elementRef);
        const html2canvas = (await import('html2canvas')).default;
        let canvas = await html2canvas(htmlElement, {
            onclone: (document, element) => {
                this.replaceArrows(document);
                this.fixFilterPaddings(document);
                this.fixBackgroundColor(document, isComplexWidget ? element : (element.firstElementChild as HTMLElement));
            },
            ignoreElements: ignoreDropdownArrowButton,
        });

        const needToChangeOrientation = htmlElement.getBoundingClientRect().width / window.innerWidth > 0.5;
        const PDF_PAGE_WIDTH = needToChangeOrientation && !isComplexWidget ? 284 : 198;

        if (needToChangeOrientation && !isComplexWidget) {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const { jsPDF } = await import('./jspdf.imports');
            this.pdf = new jsPDF('l', 'mm', 'a4', true);
        }

        await this.buildTitle(title);

        if (headerCanvas) {
            canvas = this.concatCanvases(headerCanvas, canvas, 0, 0, false);
        }
        const height = (canvas.height * PDF_PAGE_WIDTH) / canvas.width;

        const textOffset = 20;

        this.addImage(canvas, 10, textOffset, PDF_PAGE_WIDTH, height, title, true);

        this.postProcessElement(htmlElement);
    }

    async exportTableWidget(params: {
        id: string;
        elementRef: ElementRef<HTMLElement>;
        chartModel: AnyWidgetChartModel;
        headerCanvas?: HTMLCanvasElement;
        widgetTitle?: string;
        gridIndex?: number; // is used only for exporting grid with tabs
    }) {
        const { id, elementRef, chartModel, headerCanvas, widgetTitle, gridIndex = 0 } = params;
        const ROW_HEIGHT = 55;
        const HEADER_HEIGHT = 37;
        const node = elementRef.nativeElement.cloneNode(true) as HTMLElement;
        const grid = node.getElementsByTagName('ag-grid-angular').item(gridIndex) as HTMLElement;

        if (grid) {
            let rowsCount = chartModel.options.tableData.rowData.length;
            let agGridHeight = 0;

            if (node.querySelector('lib-pagination')) {
                rowsCount = node.querySelectorAll('.ag-center-cols-container > .ag-row').length;
                (grid.parentNode as HTMLElement).style.maxHeight = 'unset';
                agGridHeight += 75;
                grid.style.height = '100%';
                grid.style.maxHeight = '100%';
            }
            const tilesElement = elementRef.nativeElement.querySelector('dp-widget-tiles') as HTMLElement;
            const filtersElement = elementRef.nativeElement.querySelector('.widget-filters') as HTMLElement;

            agGridHeight += elementRef.nativeElement.querySelector('nav')?.offsetHeight ?? 0;
            agGridHeight += elementRef.nativeElement.querySelector(`[data-export-id='reset-filter-button-container']`) ? 48 : 0;
            agGridHeight += rowsCount * ROW_HEIGHT + HEADER_HEIGHT;
            agGridHeight += tilesElement?.offsetHeight ?? 0;
            agGridHeight += filtersElement?.offsetHeight ?? 0;

            node.classList.add('pdf-export');
            node.classList.add('p-0');
            node.style.height = node.style.maxHeight = `${agGridHeight}px`;
            grid.style.height = grid.style.maxHeight = `${agGridHeight}px`;
            node.style.zIndex = '-1';

            try {
                document.body.appendChild(node);
                const html2canvas = (await import('html2canvas')).default;
                const canvas = await html2canvas(node, {
                    ignoreElements: element => [...this.IGNORED_ELEMENTS, '.ps__rail-x'].some(selector => element.matches(selector)),
                    onclone(cloneDoc) {
                        cloneDoc.getElementById(id).style.zIndex = '999';
                    },
                });

                const tableColumns = grid.getElementsByClassName('ag-header-cell');

                const columnWidths = [];
                let currentWidth = 0;
                let columnsCount = 0;
                for (let i = 0; i < tableColumns.length - 1; i++) {
                    currentWidth = currentWidth + tableColumns.item(i).clientWidth;
                    columnsCount++;
                    const mainWidth = this.dashboardExport === 'PPTX' ? 1600 : 600;
                    if (currentWidth > mainWidth) {
                        const widthObject = { currentWidth, columnsCount };
                        columnWidths.push(widthObject);
                        currentWidth = 0;
                    }
                }

                if (currentWidth > 0) {
                    columnWidths.push({ currentWidth, columnsCount });
                }

                const canvasArray = [];
                let startXPoint = 0;
                let endXPoint = 0;
                const scaleCoefficient = canvas.width / grid.offsetWidth;
                for (let i = 0; i < columnWidths.length; i++) {
                    startXPoint = endXPoint;
                    endXPoint += columnWidths[i].currentWidth * scaleCoefficient;

                    canvasArray.push({
                        canvas: this.cropCanvas(canvas, startXPoint, 0, endXPoint - startXPoint, canvas.height),
                        columnsCount: columnWidths[i].columnsCount,
                    });
                }

                const textOffset = 20;
                const pdfPageWidth = 198;

                if (canvasArray.length) {
                    canvasArray[0].canvas = this.concatCanvases(headerCanvas, canvasArray[0].canvas, 0, 0, false);
                }
                for (let i = 0; i < canvasArray.length; i++) {
                    const imgWidth =
                        this.dashboardExport === 'PPTX'
                            ? canvasArray[i].canvas.width
                            : Math.min(pdfPageWidth, canvasArray[i].canvas.width * 0.26);
                    const pageHeight = this.dashboardExport === 'PPTX' ? 620 : 295;
                    const imgHeight = (canvas.height * imgWidth) / canvasArray[i].canvas.width;
                    let heightLeft = imgHeight + textOffset;

                    let position = textOffset;

                    this.pdf.setFontSize(14);
                    let titleText: string;
                    if (i === 0) {
                        titleText =
                            canvasArray[i].columnsCount === 1
                                ? 'Column 1'
                                : widgetTitle + ' (Columns ' + '1' + ' - ' + canvasArray[i].columnsCount + ')';
                        this.pdf.text(this.pdf.splitTextToSize(titleText, 200), 10, 10);
                    } else {
                        titleText =
                            canvasArray[i].columnsCount - canvasArray[i - 1].columnsCount === 1
                                ? widgetTitle + ' (Column ' + canvasArray[i].columnsCount + ')'
                                : widgetTitle +
                                  ' (Columns ' +
                                  (canvasArray[i - 1].columnsCount + 1) +
                                  ' - ' +
                                  canvasArray[i].columnsCount +
                                  ')';
                        this.pdf.text(this.pdf.splitTextToSize(titleText, 200), 10, 10);
                    }

                    this.addImage(
                        canvasArray[i].canvas,
                        10,
                        position,
                        imgWidth,
                        this.dashboardExport === 'PPTX' ? pageHeight : imgHeight,
                        titleText
                    );
                    heightLeft -= pageHeight;

                    while (heightLeft >= 0) {
                        position = this.dashboardExport === 'PPTX' ? position + pageHeight : heightLeft - imgHeight;
                        this.pdf.addPage();
                        this.addImage(
                            canvasArray[i].canvas,
                            10,
                            position,
                            imgWidth,
                            this.dashboardExport === 'PPTX' ? pageHeight : imgHeight
                        );
                        heightLeft -= pageHeight;
                    }
                    if (canvasArray.length - 1 !== i) {
                        this.pdf.addPage();
                    }
                }
            } finally {
                document.body.removeChild(node);
            }
        }
    }

    async exportTableWithTabsWidget(
        id: string,
        elementRef: ElementRef<HTMLElement>,
        chartModel: AnyWidgetChartModel,
        headerCanvas?: HTMLCanvasElement,
        widgetTitle?: string
    ) {
        const node = elementRef.nativeElement;
        const gridWithTabs = node.querySelector('dp-grid-with-tabs') as HTMLElement;
        const grids = node.querySelectorAll('ag-grid-angular');

        if (gridWithTabs && grids.length) {
            const navContainer = gridWithTabs.querySelector('nav') as HTMLElement;
            const scrollerContainer = navContainer.firstElementChild as HTMLElement;
            const selectedTabIndex = Array.from(scrollerContainer.children).findIndex(item => item.classList.contains('active'));
            const isActiveGridExists = Array.from(grids).some(item => item.closest('.tab-pane.fade.active'));

            if (!isActiveGridExists) {
                return;
            }

            try {
                this.IGNORED_ELEMENTS.push(RESET_BUTTON_SELECTOR);

                await this.exportTableWidget({
                    id,
                    elementRef: new ElementRef<HTMLElement>(node),
                    chartModel: {
                        options: {
                            tableData: {
                                rowData: chartModel.options?.items?.[0]?.table.tableData.rowData ?? [],
                            },
                        },
                    } as any,
                    headerCanvas,
                    widgetTitle,
                    gridIndex: selectedTabIndex,
                });
            } finally {
                this.IGNORED_ELEMENTS.pop();
            }
        }
    }

    async exportTwoDimensionalTableWidget(
        id: string,
        elementRef: ElementRef<HTMLElement>,
        headerCanvas?: HTMLCanvasElement,
        widgetTitle?: string
    ) {
        const node = elementRef.nativeElement.closest('dp-any-widget-wrapper').cloneNode(true) as HTMLElement;
        const grid = node.getElementsByTagName('dp-two-dimensional-grid').item(0) as HTMLElement;

        node.style.position = 'absolute';
        node.classList.add('p-0');
        grid.style.height = 'auto';
        node.style.zIndex = '-1';
        document.body.appendChild(node);

        try {
            const html2canvas = (await import('html2canvas')).default;
            const canvas = await html2canvas(node, {
                ignoreElements: element => this.IGNORED_ELEMENTS.some(selector => element.matches(selector)),
                onclone(cloneDoc) {
                    cloneDoc.getElementById(id).style.zIndex = '999';
                },
            });
            const tableColumns = node.getElementsByTagName('th');

            const columnWidths = [];
            let currentWidth = 0;
            let columnsCount = 0;
            for (let i = 0; i < tableColumns.length; i++) {
                currentWidth = currentWidth + tableColumns.item(i).offsetWidth;
                columnsCount++;
                if (currentWidth > 600) {
                    columnWidths.push({ currentWidth, columnsCount });
                    currentWidth = 0;
                }
            }

            if (currentWidth > 0) {
                columnWidths.push({ currentWidth, columnsCount });
            }
            const canvasArray = [];
            canvasArray.push({
                canvas: this.cropCanvas(canvas, 0, 0, tableColumns.item(0).offsetWidth, canvas.height),
                columnsCount: 1,
            });
            let startXPoint = 0;
            let endXPoint = 0;
            for (let i = 0; i < columnWidths.length; i++) {
                startXPoint = endXPoint;
                endXPoint = endXPoint + columnWidths[i].currentWidth;

                canvasArray.push({
                    canvas: this.cropCanvas(canvas, startXPoint, 0, endXPoint - startXPoint, canvas.height),
                    columnsCount: columnWidths[i].columnsCount,
                });
            }

            for (let i = 2; i < canvasArray.length; i++) {
                canvasArray[i] = {
                    canvas: this.concatCanvases(canvasArray[0].canvas, canvasArray[i].canvas, 0, 0, true),
                    columnsCount: canvasArray[i].columnsCount,
                };
            }

            const tableRows = node.getElementsByTagName('tr');

            const rowHeights = [];
            let currentHeight = 0;
            let pageLimit = 900;
            for (let i = 0; i < tableRows.length; i++) {
                currentHeight = currentHeight + tableRows.item(i).offsetHeight;
                if (currentHeight > pageLimit) {
                    rowHeights.push(currentHeight);
                    currentHeight = 0;
                    pageLimit = 1000;
                }
            }
            if (currentHeight > 0) {
                rowHeights.push(currentHeight);
            }

            const canvases = [];
            canvases.push({ canvas: null, columnsCount: 1, mainCanvas: false });
            for (let i = 1; i < canvasArray.length; i++) {
                let startYPoint = 0;
                let endYPoint = 0;
                for (let j = 0; j < rowHeights.length; j++) {
                    startYPoint = endYPoint;
                    endYPoint = endYPoint + rowHeights[j];

                    canvases.push({
                        canvas: this.cropCanvas(canvasArray[i].canvas, 0, startYPoint, canvas.width, endYPoint - startYPoint),
                        columnsCount: canvasArray[i].columnsCount,
                        mainCanvas: j === 0,
                    });
                }
            }

            const textOffset = 20;

            for (let i = 1; i < canvases.length; i++) {
                const imgWidth = Math.min(210, canvases[i].canvas.width * 0.26);
                const imgHeight = Math.min(295, canvases[i].canvas.height * 0.26);

                const position = textOffset;

                let titleText: string;
                if (canvases[i].mainCanvas) {
                    this.pdf.setFontSize(14);
                    titleText =
                        canvases[i].columnsCount - canvases[i - 1].columnsCount === 1
                            ? 'Column ' + (canvases[i].columnsCount - 1)
                            : widgetTitle + ' (Columns ' + canvases[i - 1].columnsCount + ' - ' + (canvases[i].columnsCount - 1) + ')';
                    this.pdf.text(this.pdf.splitTextToSize(titleText, 200), 10, 10);
                }
                this.addImage(canvases[i].canvas, 10, canvases[i].mainCanvas ? position : 20, imgWidth, imgHeight, titleText);
                if (canvases.length - 1 !== i) {
                    this.pdf.addPage();
                }
            }
        } finally {
            document.body.removeChild(node);
        }
    }

    cropCanvas(canvas: HTMLCanvasElement, offsetX: number, offsetY: number, width: number, height: number) {
        const buffer = document.createElement('canvas');
        const bCtx = buffer.getContext('2d');
        buffer.width = width;
        buffer.height = height;
        bCtx.drawImage(canvas, offsetX, offsetY, width, height, 0, 0, buffer.width, buffer.height);
        return buffer;
    }

    concatCanvases(
        firstCanvas: HTMLCanvasElement,
        secondCanvas: HTMLCanvasElement,
        offsetX: number,
        offsetY: number,
        horizontalConcat: boolean
    ) {
        if (!firstCanvas.height || !firstCanvas.width) {
            return secondCanvas;
        } else if (!secondCanvas.height || !secondCanvas.width) {
            return firstCanvas;
        }
        const buffer = document.createElement('canvas');
        const bCtx = buffer.getContext('2d');
        buffer.width = horizontalConcat ? firstCanvas.width + secondCanvas.width : Math.max(firstCanvas.width, secondCanvas.width);
        buffer.height = horizontalConcat ? firstCanvas.height : firstCanvas.height + secondCanvas.height;
        bCtx.fillStyle = '#FFFFFF';
        bCtx.fillRect(0, 0, buffer.width, buffer.height);
        bCtx.drawImage(
            firstCanvas,
            offsetX,
            offsetY,
            firstCanvas.width,
            firstCanvas.height,
            offsetX,
            offsetY,
            firstCanvas.width,
            firstCanvas.height
        );
        bCtx.drawImage(
            secondCanvas,
            offsetX,
            offsetY,
            secondCanvas.width,
            secondCanvas.height,
            horizontalConcat ? firstCanvas.width : offsetX,
            horizontalConcat ? offsetY : firstCanvas.height,
            secondCanvas.width,
            secondCanvas.height
        );
        return buffer;
    }

    addImage(canvas: HTMLCanvasElement, x: number, y: number, width: number, height: number, title?: string, baseWidget?: boolean) {
        if (this.dashboardExport) {
            const image = this.cropCanvas(
                canvas,
                x,
                y,
                canvas.width,
                this.dashboardExport === 'PPTX' && !baseWidget ? height : canvas.height
            ).toDataURL('image/png');
            this.images.push({
                name: this.widgets[0].title,
                additionalInfo: image,
                x,
                y,
                width,
                height,
                title,
            });
        } else {
            this.pdf.addImage(canvas.toDataURL('image/png'), 'PNG', x, y, width, height);
        }
    }

    async export(id: any, widgetType: WidgetType, filterComponents: any, dashboardExport: string): Promise<any> {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const { jsPDF } = await import('./jspdf.imports');
        this.pdf = new jsPDF('p', 'mm', 'a4', true);

        this.dashboardExport = dashboardExport;
        for (let index = 0; index < this.widgets.length; index += 1) {
            if (index > 0) {
                this.pdf.addPage();
            }
            await this.buildBody(id, widgetType, this.widgets[index]);
        }

        if (dashboardExport) {
            return this.images;
        } else {
            this.pdf.save(`${this.widgets.length === 1 ? this.widgets[0].title : 'Dashboard'}.pdf`);
            return null;
        }
    }
}

export class CustomHTMLChartPDF extends PDFExporter {
    postProcessElement() {
        // empty impl
    }
}
