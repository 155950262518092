import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';

import { TileChartComponent, TileChartDataModel } from '@dagility-ui/shared-components';

@Component({
    selector: 'dp-tile-template',
    template: `
        <div *ngIf="header" [innerHTML]="header | safeHtml: 'html'"></div>
        <lib-tile-chart
            *ngIf="show"
            [data]="data"
            (tileClick)="cellClick.emit($event)"
            [tilesInRow]="data.tileData.headers.length < 3 ? data.tileData.headers.length : 3"
        ></lib-tile-chart>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileTemplateComponent implements OnChanges {
    @Input() data: TileChartDataModel;
    @Input() header: string;
    @Output() cellClick = new EventEmitter<string>();
    @ViewChild(TileChartComponent) tileChartComponent: TileChartComponent;
    show = true;

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges) {
        if (this.tileChartComponent && !changes.data?.isFirstChange()) {
            this.show = false;
            this.cdr.detectChanges();

            setTimeout(() => {
                this.show = true;
                this.cdr.detectChanges();
            });
        }
    }
}
