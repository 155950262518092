<div
    *libLet="{
        viewportSize: viewportSize$ | async,
        disabled: facade.dirty$ | async
    } as vm"
    class="actions-container align-items-center"
    [class.active-block]="activeBlock || facade.widgetDebugger"
>
    <ng-template
        [ngTemplateOutlet]="actionsTemplate"
        [ngTemplateOutletContext]="{ hide: activeBlock || facade.widgetDebugger }"
    ></ng-template>

    <ng-container *ngIf="noDebug; else debugButtons">
        <button
            class="btn btn-primary mr-4"
            [routerLink]="'/admin/data-morph/query-builder'"
            *ngIf="modelGraphId"
            [queryParams]="{ modelGraphId: modelGraphId, widgetId: anyWidgetId }"
        >
            Basic mode
        </button>
        <button class="btn btn-primary mr-4" [routerLink]="'/admin/data-morph/query-builder'" *ngIf="!modelGraphId">Basic mode</button>

        <button class="btn btn-primary mr-4" (click)="handleDebug()">Debug</button>

        <lib-split-button
            *ngIf="vm.viewportSize.isSmall"
            buttonText="Save"
            [items]="actions"
            (itemClick)="handleSave($event)"
        ></lib-split-button>

        <ng-container *ngIf="!vm.viewportSize.isSmall">
            <button type="submit" [disabled]="vm.disabled" class="btn btn-primary" (click)="handleSaveWidget()">
                Save
            </button>

            <button type="submit" [disabled]="vm.disabled" class="btn btn-primary ml-4" (click)="handleSaveAndCloseWidget()">
                Save and Close
            </button>
        </ng-container>

        <lib-split-button
            class="ml-4"
            *ngIf="vm.viewportSize.isXSmall"
            buttonText="Actions"
            [items]="importExportActions"
            (itemClick)="handleImportExport($event)"
        ></lib-split-button>

        <ng-container *ngIf="!vm.viewportSize.isXSmall">
            <button class="btn btn-secondary ml-4" (click)="handleImport()">
                Import
            </button>

            <button class="btn btn-secondary ml-4" (click)="handleExport()">
                Export
            </button>
        </ng-container>
    </ng-container>
    <ng-template #debugButtons>
        <button *ngIf="isServer" class="btn btn-secondary mr-4" (click)="handleExportToGroovy()">
            Export Placeholders
        </button>
        <button *ngIf="showResume" class="btn btn-secondary mr-4" (click)="handleResume()">
            Resume
        </button>
        <button class="btn btn-secondary" (click)="handleStop()">Stop</button>
    </ng-template>
</div>

<lib-svg-builder
    [activeBlock]="activeBlock"
    [blocks]="layout?.blocks"
    [edges]="layout?.edges"
    [readonly]="!noDebug"
    [activeEdgeId]="activeBlock?.id"
    zoomShiftX="75"
    zoomShiftY="50"
    class="lib-svg-builder"
    (dropped)="handleDropped($event)"
>
    <dp-widget-flow-block
        *libSvgBlock="let block; trackBy: trackByBlocks"
        [activeBlockId]="activeBlock?.id"
        [block]="block"
        [debugPosition]="currentPosition$ | async"
        [showDrilldown]="!globalFiltersMode"
        [readonly]="!noDebug"
        (editBlock)="editBlock.emit($event)"
        (addFilter)="addFilter.emit($event)"
        (addQuery)="addQuery.emit($event)"
        (addAsyncQuery)="addAsyncQuery.emit($event)"
        (addDrilldown)="addDrilldown.emit(block.id)"
        (addEventHandler)="addEventHandler.emit(block.id)"
        (editDrilldown)="editDrilldown.emit($event)"
        (editEventHandler)="editEventHandler.emit($event)"
        (toggleBreakpoint)="handleToggleBreakpoint($event)"
    ></dp-widget-flow-block>
</lib-svg-builder>
