import { ToolType } from '@dagility-ui/shared-components';

export enum GitProtocolType {
    'HTTP' = 'HTTP',
    'SSH' = 'SSH',
    'CUSTOM' = 'CUSTOM',
}

export interface GitProtocol {
    label: string;
    value: GitProtocolType;
}

export interface PipelineScriptVersion {
    version: number;
    createdDate: string;
    createdBy: string;
    name: string;
}

export enum VersionHistoryType{
    COMPONENT = 'COMPONENT',
    PIPELINE = 'PIPELINE'
}

export enum PipelineBelongType {
    PROJECT = 'PROJECT',
    TEMPLATE = 'TEMPLATE'
}

export interface ComparePipelineScripts {
    pipelineId: number;
    pipelineType: PipelineBelongType;
    pipelineVersion?: string;
    pipelineContent?: string;

    comparedPipelineId: number;
    comparedPipelineType: PipelineBelongType;
    comparedPipelineVersion?: string;
    comparedPipelineContent?: string;
}

export interface PipelineScript {
    script: string;
    assigned: boolean;
    synced: boolean;
}

export interface PipelineScriptWithComment {
    script: string;
    comment: string;
}

export interface PipelineDetails {
    id: number;
    projectId: number;
    projectName: string;
    name: string;
    componentId: number;
    componentName: string;
    toolId: number; //
    toolType: ToolType;
    toolName: string;
    unitGroupId: number;
    unitGroupName: string;
    unitId: number; //
    unitName: string; //
    smallImagePath: string;
    bigImagePath: string;
    triggerCount: number;
    pluginServices: string[];
    isOwnedUnit: boolean;
    appLevel: boolean;
    isOutOfSync: boolean;
    gitUrlProtocol: GitProtocolType;
    customGitUrl: string;
    templatePipelineGuid: string;
}

export interface TemplateGuidAndName {
    value: string;
    label: string;
    items?: TemplateGuidAndName[];
}

export interface CiCdTool {
    id: number;
    toolId: string;
    toolName: string;
    unitId: number;
    unitName: string;
    projectName: string;
    imageUrl: string;
    url: string;
    isManaged: boolean;
}

export interface ComponentWithPipelines {
    componentId: number;
    componentName: string;
    pipelines: Pipeline[];
}

export interface Pipeline {
    projectId: number;
    projectName: string;
    toolId: number;
    project: any;
    jobName: string;
    buildId: number;
    componentId: number;
    componentName: string;
    pipelineId: number;
    pipelineName: string;
    lastExecutedTime: number;
    lastDuration: number;
    lastStatus: string;
    progress: boolean;
    progressPercent: number;
    last5Statuses: any;
    loading?: boolean;
    startSupported?: boolean;
    stopSupported?: boolean;
    pauseSupported?: boolean;
    haveGenerator?: boolean;
    outOfSync?: boolean;
}

export interface ProjectComponent {
    id: number;
    name: string;
    code: string;
    numberOfPipelines: number;
}

export interface PipelineAdditionalInformation {
    triggerCount: number;
    startSupported: boolean;
    stopSupported: boolean;
    pauseSupported: boolean;
    haveGenerator: boolean;
}

export interface TemplateFilterItems {
    stages: string[];
    tags: string[];
    instruments: string[];
}

export interface Tag {
    label: string;
    value: string;
}

export interface CiCdUnit {
    unitId: number;
    unitName: string;
}

export interface CiCdUnitsWithTool {
    toolId: number;
    toolName: string;
    units: CiCdUnit[];
}

export interface Indexes {
    componentIndex: number;
    pipelineIndex: number;
}
