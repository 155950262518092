import { inject, Provider } from '@angular/core';
import { defer, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ModalService } from '@dagility-ui/kit';
import { DateAdapter } from '@dagility-ui/shared-components';

import { SilenceWidgetError } from '../services/widget-builder.util';
import { NewPlaceholdersComponent } from '../components/new-placeholders/new-placeholders.component';
import { DEFAULT_PLACEHOLDERS, DefaultPlaceholders } from './default-placeholders.provider';
import { addTimeZonePlaceholderFactory } from './timezone-placeholders.provider';

export function addInputPlaceholdersProvider(): DefaultPlaceholders {
    const defaultPlaceholders = inject(DEFAULT_PLACEHOLDERS, { skipSelf: true }) || (() => of({}));
    const modalService = inject(ModalService);
    const dateAdapter = inject(DateAdapter);

    return widget =>
        addTimeZonePlaceholderFactory(
            defaultPlaceholders,
            dateAdapter
        )(widget).pipe(
            switchMap(placeholders => {
                const inputPlaceholders = widget.inputPlaceholders;

                if (!inputPlaceholders || !inputPlaceholders.length) {
                    return of(placeholders);
                }

                const emptyPlaceholders = inputPlaceholders.filter(placeholder => placeholders[placeholder] === undefined);

                if (!emptyPlaceholders.length) {
                    return of(placeholders);
                }

                return defer(
                    () =>
                        modalService.open(NewPlaceholdersComponent, null, {
                            placeholders: emptyPlaceholders.map(label => ({
                                label,
                                name: label,
                                type: 'STRING',
                                value: '',
                            })),
                        }).result
                ).pipe(
                    map(addedPlaceholders => ({
                        ...placeholders,
                        ...addedPlaceholders,
                    })),
                    catchError(() => throwError(new SilenceWidgetError()))
                );
            })
        );
}

export const ADD_INPUT_PLACEHOLDERS_PROVIDER: Provider = {
    provide: DEFAULT_PLACEHOLDERS,
    useFactory: addInputPlaceholdersProvider,
};
