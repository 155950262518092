import { Injectable } from '@angular/core';
import { DropdownItem } from '@dagility-ui/kit';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isEmpty } from 'lodash';

import { WidgetFilter } from '../models/any-widget.model';
import { SandBox } from './widget-builder.util';

export abstract class WidgetScriptExecutor {
    abstract evaluateScript(params: EvaluateScriptParams, options?: { logError: boolean }): any;

    abstract buildFn(expression: string): any;

    evaluateFilterDefaultValue(items: DropdownItem[], filter: WidgetFilter, placeholders: Record<string, any>) {
        return this.evaluateScript(
            {
                context: {
                    items,
                    placeholders,
                },
                expression: filter.defaultValue,
                placeholder: filter.placeholder,
                type: 'default value',
            },
            {
                logError: true,
            }
        );
    }
}

export abstract class WidgetLogger {
    abstract get empty$(): Observable<boolean>;

    abstract getLogs(): Observable<string[]>;

    abstract log(msg: string): void;

    abstract clear(): void;
}

interface EvaluateScriptParams {
    context: Record<string, any>;
    expression: string;
    type: 'drilldown' | 'before' | 'after' | 'default value' | 'sql';
    placeholder: string;
}

@Injectable()
export class ClientWidgetScriptExecutor extends WidgetScriptExecutor {
    private readonly sandbox = new SandBox();

    constructor(private logger: WidgetLogger) {
        super();
    }

    evaluateScript(params: EvaluateScriptParams, options?: { logError: boolean }): any {
        let result: any = null;
        try {
            result = this.buildFn(`return (function() { with(this) { ${params.expression} } })`).call(params.context);
        } catch (e) {
            if (options?.logError) {
                this.logger.log(
                    `Error in ${params.type} script for "${params.placeholder}" ${
                        ['drilldown', 'sql'].includes(params.type) ? 'widget' : 'placeholder'
                    }\r\n${e}`
                );
            }

            throw e;
        }

        return result;
    }

    buildFn(expression: string) {
        return this.sandbox.buildFn(expression);
    }
}

@Injectable()
export class WidgetErrorLogger extends WidgetLogger {
    readonly empty$: Observable<boolean>;

    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private readonly _logs$ = new BehaviorSubject<string[]>([]);

    constructor() {
        super();
        this.empty$ = this.getLogs().pipe(map(isEmpty));
    }

    getLogs() {
        return this._logs$.asObservable();
    }

    log(msg: string) {
        this._logs$.next([...this._logs$.value, msg]);
    }

    clear() {
        this._logs$.next([]);
    }
}

export class ConsoleLogger extends WidgetLogger {
    get empty$(): Observable<boolean> {
        return of(true);
    }

    clear() {
        //empty
    }

    log(msg: string) {
        console.error(msg);
    }

    getLogs(): Observable<string[]> {
        return of([]);
    }
}
