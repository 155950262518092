import { Component, ContentChild, TemplateRef, Input, ChangeDetectionStrategy, Directive } from '@angular/core';
import { AnimationEvent } from '@angular/animations';
import { IconsModule } from '@dagility-ui/kit';
import { CommonModule } from '@angular/common';

import { animations } from './animations';

interface ExpandButtonContext {
    collapsed: boolean;
    diff: number;
}

@Directive({
    selector: 'ng-template[dpItemsWithMoreExpandButton]',
    standalone: true,
})
export class ExpandButtonTemplateDirective {
    static ngTemplateContextGuard(dir: ExpandButtonTemplateDirective, ctx: unknown): ctx is ExpandButtonContext {
        return true;
    }
}

@Component({
    selector: 'dp-items-with-more',
    template: `
        <div data-test-id="container" class="d-flex flex-column overflow-hidden">
            <div data-test-id="visible-item" class="item" *ngFor="let item of items | slice: 0:visibleItemsCount">
                <ng-template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ $implicit: item }"></ng-template>
            </div>
            <div
                #expandCollapsePanel
                hidden
                data-test-id="expand-collapse-panel"
                [@collapse]="collapsed"
                (@collapse.done)="animationEnd($event, expandCollapsePanel)"
            >
                <div data-test-id="invisible-item" class="item" *ngFor="let item of items | slice: visibleItemsCount">
                    <ng-template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ $implicit: item }"></ng-template>
                </div>
            </div>
        </div>
        <ng-container *ngIf="visibleItemsCount < items.length">
            <button
                *ngIf="{ diff: items.length - visibleItemsCount } as vm"
                class="btn btn-link expand-button color-brand body2 justify-content-start"
                data-test-id="toggle-button"
                [class.collapsed]="collapsed"
                (click)="toggle(expandCollapsePanel)"
            >
                <ng-container
                    *ngTemplateOutlet="
                        expandButtonTemplate || defaultExpandButtonTemplate;
                        context: { collapsed: collapsed, diff: vm.diff }
                    "
                ></ng-container>
            </button>
        </ng-container>
        <ng-template #defaultExpandButtonTemplate let-collapsed="collapsed" let-diff="diff">
            <fa-icon icon="chevron-right" class="d-inline-flex"></fa-icon>
            <div class="ml-2">{{ collapsed ? 'Show' : 'Hide' }} {{ diff }} {{ collapsed ? 'more' : 'item' + (diff === 1 ? '' : 's') }}</div>
        </ng-template>
    `,
    styleUrls: ['./items-with-more.component.css'],
    animations: [animations.collapse],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [IconsModule, CommonModule, ExpandButtonTemplateDirective],
})
export class ItemsWithMoreComponent<T> {
    @Input() items: T[];
    @Input() visibleItemsCount = 7;

    @ContentChild(TemplateRef) itemTemplate: TemplateRef<{ $implicit: T }>;
    @ContentChild(ExpandButtonTemplateDirective, { read: TemplateRef }) expandButtonTemplate: TemplateRef<ExpandButtonContext>;

    collapsed = true;

    toggle(el: HTMLDivElement) {
        if (this.collapsed) {
            el.removeAttribute('hidden');
        }

        this.collapsed = !this.collapsed;
    }

    animationEnd(event: AnimationEvent, el: HTMLDivElement) {
        if (((event.toState as unknown) as boolean) === true) {
            el.setAttribute('hidden', null);
        }
    }
}
