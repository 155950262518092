import { Action, WorkflowAction } from '../models/actions';

type ActionType = Action['type'];

type ActionMap<U> = {
    [K in ActionType]: U extends { type: K } ? U : never;
};

export type TransformActionTo<T> = (action: WorkflowAction) => T;

type ActionTypeMap = ActionMap<Action>;
type Pattern<T> = {
    [K in keyof ActionTypeMap]: (action: ActionTypeMap[K]) => T;
};

export function matcher<T>(pattern: Partial<Pattern<T>> & { _: (action: WorkflowAction) => T }): TransformActionTo<T> {
    return action => ((pattern as Record<string, any>)[action.type] ? (pattern as Record<string, any>)[action.type](action) : pattern['_'](action));
}
