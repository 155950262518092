import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'dp-divider',
    template: '',
    styleUrls: ['./divider.component.scss'],
    host: {
        role: 'separator',
    },
})
export class DividerComponent {
    @HostBinding('attr.divider-type') @Input() type: 'circle' | 'line' = 'line';

    @HostBinding('style.--divider-radius.px') @Input() radius = 4;
}
