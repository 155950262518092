import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataSetField, Model, ModelGraph } from '@app/shared/components/query-builder/models/model-graph.model';
import {
    DataPreviewResponse,
    ExpressionParts,
    ExpressionTemplate,
    GraphMutationResult,
    ModelFilterCandidate,
    ModelGraphAddCalculatedDataSetFieldCommand,
    ModelGraphAddDataSetFieldRoleCommand,
    ModelGraphAddDrilldownCommand,
    ModelGraphAddGroupByCommand,
    ModelGraphAddReferencedModelCommand,
    ModelGraphAddWCFilterCommand,
    ModelGraphAttachModelFilterCommand,
    ModelGraphUpdateReferenceCommand,
    ModelGraphCreateCommand,
    ModelGraphCreateParameterCommand,
    ModelGraphDefaultDrilldownCommand,
    ModelGraphDeleteDrilldownCommand,
    ModelGraphDeleteParameterCommand,
    ModelGraphDeleteWCFilterCommand,
    ModelGraphDetachModelFilterCommand,
    ModelGraphFieldsRequest,
    ModelGraphMutationCommand,
    ModelGraphNewDrillDownResponse,
    ModelGraphPreviewDataRequest,
    ModelGraphRemoveDataSetFieldRoleCommand,
    ModelGraphRemoveGroupByCommand,
    ModelGraphRemoveModelCommand,
    ModelGraphRemoveNotAllowedDatasetRolesCommand,
    ModelGraphRenameModelCommand,
    ModelGraphReorderDataSetFieldRoleCommand,
    ModelGraphReorderWCFilterCommand,
    ModelGraphSelectFieldCommand,
    ModelGraphUnselectFieldCommand,
    ModelGraphUpdateCalculatedDataSetFieldCommand,
    ModelGraphUpdateDrilldownCommand,
    ModelGraphUpdateFilterConditionCommand,
    ModelGraphUpdateOrderByFieldsCommand,
    ModelGraphUpdateParameterCommand,
    ModelGraphUpdateUniqueSelectionCommand,
    ModelGraphUpdateWCFilterCommand,
    ModelRelationCandidate,
    Parameter,
} from '@app/shared/components/query-builder/models/model-graph-actions.model';
import { ENV_TOKEN } from '@app/tokens';
import { QueryBuilderStore } from '@app/shared/components/query-builder/store/query-builder.store';
import { tap } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { LockerActionsService } from '@app/shared/components/query-builder/store/locker-actions/locker-actions.service';

@Injectable({
    providedIn: 'root',
})
export class ModelGraphActionsService {
    private readonly baseUrl = `${this.env.querybuilderApiURL}/qb/graph`;
    private locked = false;

    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient, private queryBuilderStore: QueryBuilderStore) {}

    mutationNew(command: ModelGraphCreateCommand): Observable<GraphMutationResult> {
        QueryBuilderStore.relationCandidatesChecking = true;
        return this.doAction('Adding a new model', command, 'POST', '/mutation/new', false);
    }

    updateReference(command: ModelGraphUpdateReferenceCommand): Observable<GraphMutationResult> {
        QueryBuilderStore.relationCandidatesChecking = true;
        return this.doAction('Updating a model reference', command, 'POST', '/mutation/reference');
    }

    addReference(command: ModelGraphAddReferencedModelCommand): Observable<GraphMutationResult> {
        QueryBuilderStore.relationCandidatesChecking = true;
        return this.doAction('Adding a model reference', command, 'POST', '/mutation/model');
    }

    removeReference(command: ModelGraphRemoveModelCommand): Observable<GraphMutationResult> {
        QueryBuilderStore.relationCandidatesChecking = true;
        return this.doAction('Removing a model reference', command, 'DELETE', '/mutation/model');
    }

    addDsField(command: ModelGraphAddDataSetFieldRoleCommand): Observable<GraphMutationResult> {
        return this.doAction('Mapping dataset fields', command, 'POST', '/mutation/map-ds-field');
    }

    removeDsField(command: ModelGraphRemoveDataSetFieldRoleCommand): Observable<GraphMutationResult> {
        return this.doAction('Mapping dataset fields', command, 'DELETE', '/mutation/map-ds-field');
    }

    reorderDsField(command: ModelGraphReorderDataSetFieldRoleCommand): Observable<GraphMutationResult> {
        return this.doAction('Updating dataset fields order', command, 'POST', '/mutation/reorder-ds-field');
    }

    removeNotAllowedDsFieldsRoles(
        command: ModelGraphRemoveNotAllowedDatasetRolesCommand
    ): Observable<GraphMutationResult | ModelGraphNewDrillDownResponse> {
        return this.doAction('Updating data mappings', command, 'DELETE', '/mutation/not-allowed-roles');
    }

    addGroupByField(command: ModelGraphAddGroupByCommand): Observable<GraphMutationResult> {
        return this.doAction('Applying grouping settings', command, 'POST', '/mutation/group-by-field');
    }

    removeGroupByField(command: ModelGraphRemoveGroupByCommand): Observable<GraphMutationResult> {
        return this.doAction('Applying grouping settings', command, 'DELETE', '/mutation/group-by-field');
    }

    selectDsField(command: ModelGraphSelectFieldCommand): Observable<GraphMutationResult> {
        return this.doAction('Updating fields selection', command, 'POST', '/mutation/select-ds-field');
    }

    unselectDsField(command: ModelGraphUnselectFieldCommand): Observable<GraphMutationResult> {
        return this.doAction('Updating fields selection', command, 'DELETE', '/mutation/select-ds-field');
    }

    rootModels(): Observable<Model[]> {
        return this.doAction('Loading model list', null, 'GET', '/models/candidates/root', false);
    }

    getExpressionParts(): Observable<ExpressionParts> {
        const command = {} as ModelGraphFieldsRequest;
        this.insertSubGraphUidIntoCommand((command as unknown) as ModelGraphMutationCommand);
        return this.http.post<ExpressionParts>(`${this.baseUrl}/expression-parts`, command);
    }

    saveExpressionTemplates(template: ExpressionTemplate): Observable<ExpressionTemplate> {
        return this.http.post<ExpressionTemplate>(`${this.baseUrl}/expression-templates`, template);
    }

    expressionTemplatesUsed(uid: string): Observable<any> {
        return this.http.post(`${this.baseUrl}/expression-templates/${uid}/used`, {});
    }

    deleteExpressionTemplates(uid: string): Observable<any> {
        return this.http.delete(`${this.baseUrl}/expression-templates/${uid}`);
    }

    getParameters(command: ModelGraphFieldsRequest): Observable<Parameter[]> {
        return this.http.post<Parameter[]>(`${this.baseUrl}/parameters-list`, command);
    }

    createParameter(command: ModelGraphCreateParameterCommand): Observable<GraphMutationResult> {
        return this.http.post<GraphMutationResult>(`${this.baseUrl}/mutation/parameters`, command);
    }

    updateParameter(command: ModelGraphUpdateParameterCommand): Observable<GraphMutationResult> {
        return this.http.put<GraphMutationResult>(`${this.baseUrl}/mutation/parameters`, command);
    }

    deleteParameter(command: ModelGraphDeleteParameterCommand): Observable<GraphMutationResult> {
        return this.http.request<GraphMutationResult>('DELETE', `${this.baseUrl}/mutation/parameters`, { body: command });
    }

    expressionFields(): Observable<DataSetField[]> {
        const command = {} as ModelGraphFieldsRequest;
        this.insertSubGraphUidIntoCommand((command as unknown) as ModelGraphMutationCommand);
        return this.http.post<DataSetField[]>(`${this.baseUrl}/expression-fields`, command);
    }

    relationCandidates(modelUid: string, graph: ModelGraph): Observable<ModelRelationCandidate[]> {
        return this.http.post<ModelRelationCandidate[]>(`${this.baseUrl}/models/${modelUid}/candidates/relation`, graph);
    }

    allRelationCandidates(graph: ModelGraph): Observable<ModelRelationCandidate[]> {
        return this.doAction('Loading relations', graph, 'POST', `/candidates/relation`, false);
    }

    filterCandidates(modelUid: string, graph: ModelGraph): Observable<ModelFilterCandidate[]> {
        return this.doAction('Loading relations', graph, 'POST', `/models/${modelUid}/candidates/filter`, false);
    }

    attachFilter(command: ModelGraphAttachModelFilterCommand): Observable<GraphMutationResult> {
        return this.doAction('Updating model references', command, 'POST', '/models/filters');
    }

    detachFilter(command: ModelGraphDetachModelFilterCommand): Observable<GraphMutationResult> {
        return this.doAction('Updating model references', command, 'DELETE', '/models/filters');
    }

    dataPreview(previewRequest: ModelGraphPreviewDataRequest): Observable<DataPreviewResponse> {
        return this.http.post<DataPreviewResponse>(`${this.baseUrl}/preview`, previewRequest.body, { params: previewRequest.params });
    }

    addCalculateField(
        command: ModelGraphAddCalculatedDataSetFieldCommand
    ): Observable<GraphMutationResult | ModelGraphNewDrillDownResponse> {
        return this.doAction('Adding calculated field', command, 'POST', '/mutation/calculated-field');
    }

    updateCalculateField(
        command: ModelGraphUpdateCalculatedDataSetFieldCommand
    ): Observable<GraphMutationResult | ModelGraphNewDrillDownResponse> {
        return this.doAction('Updating calculated field', command, 'PUT', '/mutation/calculated-field');
    }

    updateOrderByFields(command: ModelGraphUpdateOrderByFieldsCommand): Observable<GraphMutationResult> {
        return this.doAction('Updating field list order', command, 'POST', '/mutation/order-by');
    }

    createSubquery(command: ModelGraphMutationCommand): Observable<GraphMutationResult> {
        QueryBuilderStore.relationCandidatesChecking = true;
        return this.doAction('Creating a new subquery', command, 'POST', '/mutation/subquery');
    }

    setWhere(command: ModelGraphUpdateFilterConditionCommand): Observable<GraphMutationResult> {
        return this.doAction('Updating filtering conditions', command, 'POST', '/mutation/where');
    }

    setUniqueSelection(command: ModelGraphUpdateUniqueSelectionCommand): Observable<GraphMutationResult> {
        return this.doAction('Updating selected fields', command, 'POST', '/mutation/unique');
    }

    renameModel(command: ModelGraphRenameModelCommand): Observable<GraphMutationResult> {
        return this.doAction('Updating model name', command, 'POST', '/mutation/model/name');
    }

    createEmptySubquery(command: ModelGraphMutationCommand): Observable<GraphMutationResult> {
        QueryBuilderStore.relationCandidatesChecking = true;
        return this.doAction('Creating a new subquery', command, 'POST', '/mutation/subquery/new');
    }

    addWCFilter(command: ModelGraphAddWCFilterCommand): Observable<GraphMutationResult> {
        return this.doAction('Creating a new filter', command, 'POST', '/mutation/wc-filters');
    }

    updateWCFilter(command: ModelGraphUpdateWCFilterCommand): Observable<GraphMutationResult> {
        return this.doAction('Updating filter settings', command, 'PUT', '/mutation/wc-filters');
    }

    deleteWCFilter(command: ModelGraphDeleteWCFilterCommand): Observable<GraphMutationResult> {
        return this.doAction('Deleting a filter', command, 'DELETE', '/mutation/wc-filters');
    }

    addDrilldown(command: ModelGraphAddDrilldownCommand): Observable<GraphMutationResult> {
        QueryBuilderStore.relationCandidatesChecking = true;
        return this.doAction('Creating a new drilldown', command, 'POST', '/mutation/drilldown');
    }

    updateDrilldown(command: ModelGraphUpdateDrilldownCommand): Observable<GraphMutationResult> {
        return this.doAction('Updating drilldown configuration', command, 'PUT', '/mutation/drilldown');
    }

    deleteDrilldown(command: ModelGraphDeleteDrilldownCommand): Observable<GraphMutationResult> {
        QueryBuilderStore.relationCandidatesChecking = true;
        return this.doAction('Deleting a drilldown', command, 'DELETE', '/mutation/drilldown');
    }

    defaultDrilldown(command: ModelGraphDefaultDrilldownCommand): Observable<ModelGraphNewDrillDownResponse> {
        return this.doAction<ModelGraphNewDrillDownResponse>('Updating drilldown settings', command, 'POST', '/mutation/drilldown/new');
    }

    reorderWCFilters(command: ModelGraphReorderWCFilterCommand): Observable<GraphMutationResult> {
        return this.doAction<GraphMutationResult>('Updating order of filters', command, 'POST', '/mutation/wc-filters/reorder');
    }

    getRequiredParameters(request: ModelGraphFieldsRequest): Observable<string[]> {
        return this.http.post<string[]>(`${this.baseUrl}/required-params`, request);
    }

    private insertSubGraphUidIntoCommand(command: ModelGraphMutationCommand) {
        command.sourceGraph = this.queryBuilderStore.globalModelGraph;
        const subGraphUid = cloneDeep(this.queryBuilderStore.subGraphUid);
        const drillDownId = cloneDeep(this.queryBuilderStore.drillDownId);
        if (subGraphUid) {
            command.subGraphId = subGraphUid;
        }
        if (drillDownId && command.drillDownId === undefined) {
            command.drillDownId = drillDownId;
        }
    }

    private doAction<T>(
        lockReason: string,
        request: any,
        type: 'POST' | 'GET' | 'DELETE' | 'PUT',
        url: string,
        insertSubGraphUidIntoCommand: boolean = true
    ): Observable<T> {
        if (insertSubGraphUidIntoCommand) {
            this.insertSubGraphUidIntoCommand(request as ModelGraphMutationCommand);
        }
        LockerActionsService.lock(lockReason);
        return this.http
            .request<T>(type, `${this.baseUrl}${url}`, {
                body: request,
            })
            .pipe(
                tap(
                    () => {
                        // noop
                    },
                    () => {
                        this.locked = false;
                        LockerActionsService.unlock(lockReason);
                    },
                    () => {
                        LockerActionsService.unlock(lockReason);
                    }
                )
            );
    }
}
