import Keycloak from 'keycloak-js';
import { Subject } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { ENV_TOKEN } from '../../app.tokens';

@Injectable({
    providedIn: 'root',
})
export class KeycloakService {
    private readonly instance = new Keycloak(this.env.keycloakParams);

    private timerHandler: number;
    private logEnabled = false;

    readonly onRefreshToken$ = new Subject<void>();

    constructor(@Inject(ENV_TOKEN) private env: Env) {
        this.instance.onAuthRefreshSuccess = () => {
            this.log('The Keycloak token has been refreshed successfully');
        };
        this.instance.onAuthRefreshError = () => {
            console.error('Cannot refresh the Keycloak authorization token.');
        };
        this.instance.onReady = (result: boolean) => {
            this.log('The Keycloak service is ready with result =' + result);
        };
        this.instance.onTokenExpired = () => {
            this.log('The Keycloak token has expired.');
            this.clearRefreshTimeout();
            this.instance.updateToken(-1).success(() => {
                this.log('The Keycloak token has been refreshed.');
                this.onRefreshToken$.next();
            });
        };
        this.instance.onAuthLogout = () => {
            this.clearRefreshTimeout();
        };
    }

    /**
     * init and login
     */
    login(): Promise<any> {
        this.clearRefreshTimeout();
        this.instance.clearToken();
        return this.instance.init({ onLoad: 'login-required', checkLoginIframe: !!this.env.production, redirectUri: location.href });
    }

    /**
     * logout
     */
    logout(): Promise<void> {
        this.clearRefreshTimeout();
        return new Promise<void>((resolve, reject) => {
            if (this.instance.authenticated) {
                this.instance
                    .logout()
                    .then(() => {
                        resolve();
                    })
                    .catch(() => {
                        reject('Failed to logout');
                    });
            } else {
                resolve();
            }
        });
    }

    getTokenApi() {
        return this.instance.token || '';
    }

    private clearRefreshTimeout() {
        if (this.timerHandler) {
            this.log('The Keycloak token refresh timer is OFF.');
            window.clearTimeout(this.timerHandler);
            this.timerHandler = null;
        }
    }

    private log(msg: string) {
        if (this.logEnabled) {
            console.log(msg);
        }
    }
}
