<div class="dashboard-actions-panel" [@filtersPanelOpen]="{ value: expanded && !edit, params: { distance: panelWidth } }">
    <ng-template
        [ngIf]="externalFilter"
        [ngTemplateOutlet]="externalFilter?.template"
        [ngTemplateOutletContext]="{ dashboard: dashboard, events: event }"
    >
    </ng-template>

    <button
        data-test-id="funnel-button"
        [hidden]="!(visibleFilters$ | async).length"
        *ngIf="{ loading: loading$ | async } as vm"
        #funnelButton
        class="btn btn-sm btn-primary funnel-button"
        [class.loading]="vm.loading"
        style="width: 32px !important;"
        [btnWithLoader]="vm.loading"
        (click)="!vm.loading && expand()"
    >
        <ng-template>
            <lib-waiting-loader class="waiting-loader"></lib-waiting-loader>
        </ng-template>
        <ng-container *ngIf="!vm.loading">
            <fa-icon class="font-16px d-inline-flex" icon="facFilter"></fa-icon>

            <div class="warning-marker" data-test-id="warning-dot" *ngIf="isSomeFilterIsNotAvailable$ | async"></div>
        </ng-container>
    </button>

    <ng-content></ng-content>
</div>

<dp-filter-set-panel
    *ngIf="expanded"
    [@slideInOut]="'enter'"
    [edit]="edit"
    [origin]="funnelButtonElement.nativeElement"
    (widgetFilterVisibility)="getWidgetFilterVisibility($event)"
    (collapse)="collapse()"
    (apply)="apply()"
    (save)="save()"
    (reset)="resetToDefault()"
    (resetToLastSaved)="resetToLastSaved()"
>
</dp-filter-set-panel>
