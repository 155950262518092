<div *ngIf="data; else loadingTmpl" class="portfolio-scores-health">
    <dp-risk-count-square
        [data]="data.portfolioRisk"
    >
    </dp-risk-count-square>
    <div class="vertical-line"></div>
    <dp-risk-count-square
        *ngFor="let risk of data.risks"
        class="mr-4 mb-1"
        [data]="risk"
    >
    </dp-risk-count-square>
</div>
<ng-template #loadingTmpl>
    <lib-spinner class="spinner"></lib-spinner>
</ng-template>
