<div class="work-distribution d-flex">
    <div class="position-relative w-100 mr-4">
        <div class="ml-1 d-flex flex-column justify-content-end body2" style="margin-top: 39px">
            <div>
                <span class="mr-2 secondary-text-and-icons">Remaining Items(s):</span>
                <span class="title">{{ options.remainingIssues || 0 }}</span>
            </div>
            <div class="mt-2">
                <span class="mr-2  secondary-text-and-icons">Remaining Story Point(s):</span>
                <span class="title">{{ options.remainingStoryPoints || 0 }} SP</span>
            </div>
        </div>
        <div class="main-chart position-absolute w-100 h-100" [class.mt-3]="isMediumScreen">
            <dp-work-distribution-chart
                [chartOptions]="mainChartOptions"
                [legendTitle]="legendTitle"
                [chartData]="mainChart"
                [width]="'80%'"
                [height]="'70%'"
                (selected$)="onChange($event)"
            >
            </dp-work-distribution-chart>
        </div>
    </div>
    <div class="position-relative w-100 d-flex flex-column justify-content-between">
        <div>
            <div class="sub-header">
                <h4 class="mt-0 mb-3">{{ subProgressHeader | titlecase }}</h4>
                <div class="d-flex justify-content-start body2">
                    <div class="pt-2  secondary-text-and-icons">
                        <div>Item(s) Closed:</div>
                        <div class="pt-2">Total Items:</div>
                    </div>
                    <div class="pt-2 pl-4">
                        <div class="title">
                            {{ issueCompleted }} <span class="percent">({{ issueCompleted / totalIssues | percent }})</span>
                        </div>
                        <div class="title pt-2">{{ totalIssues }}</div>
                    </div>
                </div>
            </div>
            <div class="sub-chart position-absolute w-100 h-100" [class.mt-10px]="isMediumScreen">
                <span *ngIf="!subChart.length" class="no-issues-available">No Status Data Available</span>
                <dp-work-distribution-chart
                    *ngIf="subChart.length"
                    [chartData]="subChart"
                    [chartOptions]="subChartOptions"
                    [width]="'80%'"
                    [height]="'70%'"
                >
                </dp-work-distribution-chart>
            </div>
        </div>

        <div
            *ngIf="showLink && !subProgressHeader.includes('All')"
            class="cursor-pointer mt-1 link-normal"
            [class.link-medium-screen]="isMediumScreen"
            style="z-index: 1;"
            (click)="handleLinkClicked()"
        >
            <fa-icon icon="facArrowRightRegular" class="fa mr-1"></fa-icon>
            {{ 'View ' + options.workDistributionType + ' Progress' | titlecase }}
        </div>
    </div>
</div>
