<div class="p-10px">
    <span class="tour-menu-heading">{{ tourTitle }}</span>
    <div class="list-group">
        <span *ngFor="let menu of steps" class="list-item" [style.color]="menu.keyId <= currentStep ? '#327DE2' : '#515151'">
            <span (click)="menu.keyId != currentStep && switchToStep(menu.keyId)">
                <span style="line-height: 1.5rem;" [ngClass]="menu.keyId < currentStep ? 'pr-10px' : 'pr-30px'">
                    <span *ngIf="menu.keyId < currentStep">
                        <fa-icon class="icon icon-font" [icon]="menu.keyId < currentStep ? 'simpleCheck' : ''"></fa-icon>
                    </span>
                </span>
                <span class="item-text">{{ menu.title }}</span>
            </span>
        </span>
    </div>
</div>
