import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    Validator,
    Validators,
} from '@angular/forms';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

import { CiCdFieldDescription, CiCdFieldType, CiCdOption, CiCdProperty, HierarchyLevel } from '../fields-grid/model/fields.model';
import { addTagTypeMap } from './typing-utils';
import { normalizeFields } from '../fields-grid/component/fields-grid/utils/fields-grid-utils';
import { ExternalCredentialsApi, ToolCredentialsModel } from '../../services/external-credentials-api';
import { FieldTypeMap, getOrDefault } from './utils';
import { DropdownComponent, DropdownItem, toDropDownItem, validateFormAndDisplayErrors } from '@dagility-ui/kit';
import { CompletionSourceItem } from '../../directives/properties-completion';

const resolvedPromise = Promise.resolve(null);

@Component({
    selector: 'lib-properties-editor',
    templateUrl: './properties-editor.component.html',
    styleUrls: ['./properties-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PropertiesEditorComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PropertiesEditorComponent),
            multi: true,
        },
        ExternalCredentialsApi,
    ],
})
export class PropertiesEditorComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
    @Input() fieldsEditAllowed = true;
    @Input() hideGroovyScriptFields = true;
    @Input() addSystemProperties = false;
    @Input() hierarchyLevel: HierarchyLevel;

    @Input() set setDisable(value: boolean) {
        this.disabled = value;
        this.applyDisabledState();
    }

    @Input() fieldConfigContextHelpTemplate: TemplateRef<any> = null;
    @Input() fieldOptionsContextHelpTemplate: TemplateRef<any> = null;
    @Input() toolId: number;
    @Input() propertiesCompletion: CompletionSourceItem[] = [];
    @Input() propertiesControl: AbstractControl;
    @Input() emptyMandatoryFields: boolean = false;

    @Input() uploadFn: (file: File) => Promise<any>;

    @Output() modalWindowOpened: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChildren(DropdownComponent) dropdownComponents: QueryList<DropdownComponent>;

    private readonly DEFAULT_VALUE_MAP: FieldTypeMap<any> = {
        STRING: '',
        STRING_MULTILINE: '',
        CREDENTIAL: '',
        GROOVY: '',
        BOOLEAN: null,
        NUMBER: null,
    };

    addTagFns = addTagTypeMap;

    propertyOptionsMap: Record<string, CiCdOption[]> = {};

    identifiersMap: { [toolId: string]: Observable<any[]> } = {};

    initialCredentials: ToolCredentialsModel[] = [];

    dropdownItems: Record<string, DropdownItem[]> = {};

    gridMode = false;
    properties: CiCdProperty[] = [];

    subscription: Subscription;

    form: FormGroup;

    initializedWithDefaultValues = false;

    private disabled = false;

    onChange = (_: any) => {};
    onTouched = () => {};

    get isDisabled() {
        return this.disabled;
    }

    get formProperties(): FormArray {
        return this.form.get('properties') as FormArray;
    }

    constructor(private fb: FormBuilder, private externalCredentialsApi: ExternalCredentialsApi) {}

    ngOnInit() {
        this.initForm();
    }

    setDisabledState(isDisabled: boolean): void {
        this.setDisable = isDisabled;
    }

    handleToggleFieldGrid() {
        this.gridMode = false;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: CiCdProperty[]) {
        this.properties = obj;
        this.initForm();
    }

    validate() {
        return (
            !this.form.valid && {
                required: true,
            }
        );
    }

    validateFormAndDisplayErrors() {
        this.gridMode = false;
        validateFormAndDisplayErrors(this.form);
    }

    handleGridValueChange(options: CiCdFieldDescription[]) {
        this.properties = options as any;
        this.initForm();
        this.onChange(this.properties);
        this.propertiesControl?.markAsDirty();
    }

    handleModalWindowOpened(opened: boolean) {
        this.modalWindowOpened.next(opened);
    }

    setDefaultValue(property: AbstractControl, index: number) {
        if (property) {
            const defaultValue = this.getPropertyValue(property.value, true);
            property.patchValue({ value: defaultValue });
            if (Array.isArray(defaultValue)) {
                const dropdown = this.dropdownComponents.find(x => x.id === 'dropdown-field-' + index);
                if (dropdown) {
                    dropdown.items = defaultValue.map(item => ({
                        [dropdown.labelKey]: item,
                        [dropdown.valueKey]: item,
                    }));
                }
            }
        }
    }

    getCheckboxValue = (value: boolean | null) => (value === null ? undefined : value);

    handleCheckboxValueChange = (control: AbstractControl, value: boolean | undefined) =>
        control.get('value').patchValue(value === undefined ? null : value);

    handleNumberInputValueChange = (control: AbstractControl, { target: { value } }: any) =>
        control.get('value').patchValue(value === '' ? null : +value);

    handleArrayDropdownChange = (control: AbstractControl, values: any[]) => control.get('value').patchValue(values);

    handleOptionArrayDropdownChange = (control: AbstractControl, values: any[]) => {
        const controlValue = control.value;
        if (controlValue.array) {
            control.get('value').patchValue(values);
        }
    };

    private initForm() {
        this.properties = normalizeFields(this.properties) as CiCdProperty[];
        this.initializedWithDefaultValues = false;
        this.initDropdownItems(this.properties);
        this.propertyOptionsMap = {};

        this.properties.forEach(property => this.initOptions(property));

        this.properties = this.properties.map(property => ({
            ...property,
            value: this.getPropertyValue(property),
        }));

        this.form = this.fb.group({
            properties: this.fb.array(this.properties.map(this.propertyToFormGroup.bind(this))),
        });
        if (this.initializedWithDefaultValues) {
            resolvedPromise.then(() => {
                this.onChange(this.properties);
                this.onTouched();
            });
        }
        this.subscribeValueChanges();
    }

    private propertyToFormGroup(property: CiCdProperty) {
        return this.fb.group({
            ...property,
            value: [
                property.value,
                property.mandatory
                    ? property.type === 'GROOVY' && this.hideGroovyScriptFields
                        ? []
                        : property.type === 'BOOLEAN' && !property.array
                        ? [ProjectPropertiesValidators.validateBoolean]
                        : this.emptyMandatoryFields
                        ? []
                        : [Validators.required]
                    : [],
            ],
            options: [property.options || []],
        });
    }

    private getPropertyValue(property: CiCdProperty, setDefault: boolean = false) {
        const isArrayOrDefault = getOrDefault(Array.isArray)([]);
        if (property.value === undefined || setDefault) {
            if (property.defaultValue !== undefined && property.defaultValue !== '') {
                this.initializedWithDefaultValues = true;
                if (property.array) {
                    this.addToDropdownItems(property.name, property.defaultValue);

                    return [property.defaultValue];
                } else {
                    return property.defaultValue;
                }
            } else {
                if (property.options && property.options.length) {
                    return property.array ? [] : null;
                } else {
                    const typeDefaultValue = this.DEFAULT_VALUE_MAP[property.type];

                    if (property.array) {
                        // commented out due to incorrect dropdown item
                        // this.addToDropdownItems(property.name, toDropDownItem(typeDefaultValue));

                        return null;
                    } else {
                        return typeDefaultValue;
                    }
                }
            }
        } else {
            if (property.options && property.options.length) {
                if (property.array) {
                    if (!Array.isArray(property.value)) {
                        return [];
                    }

                    return property.value.filter(v => this.propertyOptionsMap[property.name].some(option => option.value === v));
                } else {
                    if (this.propertyOptionsMap[property.name].some(option => option.value === property.value)) {
                        return property.value;
                    } else {
                        return null;
                    }
                }
            } else {
                if (property.array) {
                    return isArrayOrDefault(property.value);
                } else {
                    return property.value;
                }
            }
        }
    }

    getIdentifiers(toolId: string) {
        if (!toolId) {
            return of([]);
        }
        if (!this.identifiersMap[toolId]) {
            this.identifiersMap[toolId] = this.externalCredentialsApi.getToolIdentifiers(toolId).pipe(
                map((identifiers: any[]) => {
                    const identifierIds = identifiers.map((identifier: any) => identifier.id);
                    this.initialCredentials.forEach(credential => {
                        if (!identifierIds.includes(credential.identifier)) {
                            identifiers.push({
                                id: credential.identifier,
                                displayName: credential.identifier,
                            });
                        }
                    });
                    return identifiers;
                }),
                catchError(() => of([])),
                shareReplay(1)
            );
        }

        return this.identifiersMap[toolId];
    }

    private initOptions(property: CiCdProperty) {
        if (property.options && property.options.length) {
            this.propertyOptionsMap[property.name] = property.options;
        }
    }

    private initDropdownItems(properties: CiCdProperty[]) {
        this.dropdownItems = properties.reduce((acc: Record<string, any>, prop) => {
            if (prop.array && !(prop.options && prop.options.length)) {
                acc[prop.name] = (!!prop.value && Array.isArray(prop.value) ? prop.value : []).map(value => ({
                    label: value,
                    value,
                }));
            }

            return acc;
        }, {});
    }

    private addToDropdownItems(propName: string, value: any) {
        if (!this.dropdownItems[propName]) {
            this.dropdownItems[propName] = [];
        }

        this.dropdownItems[propName].push(toDropDownItem(value));
    }

    private subscribeValueChanges() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.subscription = this.form.valueChanges.subscribe(value => {
            this.onChange(value.properties);
            this.onTouched();
        });
    }

    private applyDisabledState() {
        if (this.form) {
            const propertiesForm = this.formProperties;
            if (propertiesForm) {
                propertiesForm.controls.forEach(control => {
                    if (this.disabled) {
                        control.disable({ onlySelf: true });
                    } else {
                        control.enable({ onlySelf: true });
                    }
                });
            }
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    getCiCdFieldType(type: CiCdFieldType): CiCdFieldType {
        return type;
    }
}

class ProjectPropertiesValidators {
    static validateBoolean = (control: FormControl) => {
        return control.value === null
            ? {
                  required: true,
              }
            : null;
    };
}
