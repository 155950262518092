import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';

import { KeycloakService } from './keycloak.service';
import { AuthenticatedUser, DefaultUser, RequiredRights, RequiredRightsItem, RequiredRightsKind } from '@app/models';
import { RightsService, UserService } from '@app/services';
import { AuthorityPermissions, RbacAction, RbacStaticType, RbacType } from '@dagility-ui/kit';
import { kni } from '@dagility-ui/keep-ni';
import { ENV_TOKEN } from '@app/tokens';
import { DEFAULT_THEME, THEMES } from 'src/app/pages/user/user.themes';


@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public readonly onAfterLogin = new ReplaySubject<DefaultUser>(1, 5000);
    public readonly onBeforeLogoff = new Subject<void>();
    public readonly onBeforeLogoff$ = this.onBeforeLogoff.asObservable();
    public readonly userChanged = new Subject<DefaultUser>();

    private authenticatedUser: AuthenticatedUser;

    private user: DefaultUser;

    public static isStaticType(type: string): boolean {
        return type === 'OBJECT' || type === 'LOCATION' || type === 'ENDPOINT';
    }

    getUser(): DefaultUser {
        return this.userService.user;
    }

    getAuthenticatedUser(): AuthenticatedUser {
        if (this.authenticatedUser.variables.defaultTheme && !THEMES.some(e => e.value === this.authenticatedUser.variables.defaultTheme)) {
            this.authenticatedUser.variables.defaultTheme = DEFAULT_THEME;
        }
        return this.authenticatedUser;
    }

    constructor(
        @Inject(ENV_TOKEN) private env: Env,
        private userService: UserService,
        private rightsService: RightsService,
        private httpClient: HttpClient,
        private keycloakService: KeycloakService
    ) {
        window.logout = () => this.logout();
    }

    public init(reloadRoles = false): Observable<DefaultUser> {
        return this.userService.getCurrentUser(reloadRoles).pipe(
            tap(authUser => {
                if (!authUser.variables) {
                    authUser.variables = {};
                }
                this.authenticatedUser = authUser;
            }),
            switchMap(authUser => this.userService.getUser(authUser.id)),
            tap(user => {
                if (!user || user.status !== 'ACTIVE') {
                    // console.log('User is inactive!');
                    this.logout();
                }
            }),
            tap(user => (this.userService.user = user)),
            tap(user => setTimeout(() => this.onAfterLogin.next(user), 10))
        );
    }

    /**
     * It checks that the current user has permission on specified action of the object.
     *
     * @param type type of object
     * @param objectLocator object identifier
     * @param action desired actions
     */
    public hasPermission(type: RbacType, objectLocator: string, action: RbacAction): Observable<boolean> {
        if (AuthService.isStaticType(type)) {
            return this.rightsService.getCurrentUserRights().pipe(
                // eslint-disable-next-line eqeqeq
                map(arr => arr.find(item => item.type == type && item.objId == objectLocator)),
                map(item => (item ? !!item.permissions[action] : false))
            );
        } else {
            return this.rightsService
                .getCurrentUserPermissions(type, objectLocator)
                .pipe(map(permissions => (permissions ? !!permissions[action] : false)));
        }
    }

    /**
     * Returns all current user permissions on the object.
     *
     * @param type type of object
     * @param objectLocator object identifier
     * @return [can return null]
     */
    public getRights(type: RbacType, objectLocator: string): Observable<AuthorityPermissions> {
        if (AuthService.isStaticType(type)) {
            return this.rightsService.getCurrentUserRights().pipe(
                // eslint-disable-next-line eqeqeq
                map(arr => arr.find(item => item.type == type && item.objId == objectLocator)),
                filter(item => !!item),
                map(item => item.permissions)
            );
        } else {
            return this.rightsService.getCurrentUserPermissions(type, objectLocator);
        }
    }

    /**
     * It checks that the current user has all desired permissions on specified actions of the object.
     *
     * @param type type of object
     * @param objectLocator object identifier
     * @param actions desired actions
     */
    public hasAllPermissions(type: RbacType, objectLocator: string, actions: RbacType[]): Observable<boolean> {
        if (AuthService.isStaticType(type)) {
            return this.rightsService.getCurrentUserRights().pipe(
                // eslint-disable-next-line eqeqeq
                map(arr => arr.find(item => item.type == type && item.objId == objectLocator)),
                map(item => (item ? this.hasAll(item.permissions, actions) : false))
            );
        } else {
            return this.rightsService
                .getCurrentUserPermissions(type, objectLocator)
                .pipe(map(permissions => (permissions ? this.hasAll(permissions, actions) : false)));
        }
    }

    /**
     * It check permission for location
     *
     * @param url location string
     */
    public hasLocationPermission(url: string): Observable<boolean> {
        return this.rightsService.getCurrentUserRights().pipe(
            map(arr => arr.filter(item => item.type === RbacStaticType.LOCATION)),
            // filter and sort related location
            map(arr => arr.filter(item => url.includes(item.objId)).sort((a, b) => b.objId.length - a.objId.length)),
            // return most related permission or true
            map(locations => (locations.length ? locations[0].permissions.allow : true))
        );
    }

    public isAdmin(): boolean {
        return this.hasRole('ROLE_ADMIN');
    }
    public isSubscriptionAdmin(): boolean {
        return this.hasRole('ROLE_SUBSCRIPTION_ADMIN');
    }
    public hasRole(role: string): boolean {
        return this.authenticatedUser?.authorities.includes(role);
    }

    public hasAnyRole(roles: string[]): boolean {
        return roles.some(role => this.hasRole(role));
    }

    public hasAllRoles(roles: string[]): boolean {
        return roles.every(role => this.hasRole(role));
    }

    hasRights(rights: RequiredRights): Observable<boolean> {
        if (!rights.isPrepared()) {
            rights.prepare(new Builder(this, rights).build());
        }
        return rights.prepared$;
    }

    logout() {
        const logoutCallback = () => {
            this.setUser = null;
            this.authenticatedUser = null;
            this.onBeforeLogoff.next();
            setTimeout(() => this.keycloakService.logout(), 7000); // force logout if the javaLogout has not finished yet
            this.javaLogout().subscribe(
                () => {
                    this.keycloakService.logout().then();
                },
                error => {
                    console.error(error);
                    this.keycloakService.logout().then();
                }
            );
        };
        kni().api.trackLogout(logoutCallback, logoutCallback);
    }

    private javaLogout(): Observable<any> {
        return this.httpClient.get<any>(`${this.env.adminURL}/sso/logout`);
    }

    /**
     * It checks that the permissions have all specified actions are granted.
     *
     * @param permissions map [key: string]: boolean;
     * @param actions desired action
     */
    private hasAll(permissions: AuthorityPermissions, actions: RbacAction[] | string[]): boolean {
        return actions.every(action => permissions[action]);
    }

    private setUser(user: DefaultUser) {
        this.user = user;
        this.userChanged.next(user);
    }
}

class Builder {
    constructor(private authService: AuthService, private rights: RequiredRights) {}

    build(): Observable<boolean> {
        return this.makeOr(this.rights.permissions);
    }

    private factory(b: RequiredRightsItem): Observable<boolean> {
        switch (b.kind) {
            case RequiredRightsKind.PERMISSION:
                if (b.action instanceof Array) {
                    return this.authService.hasAllPermissions(b.type, b.objId, b.action);
                } else {
                    return this.authService.hasPermission(b.type, b.objId, b.action);
                }
            case RequiredRightsKind.ROLE:
                return of(this.authService.hasRole(b.role));
            case RequiredRightsKind.ALL_ROLES:
                return of(this.authService.hasAllRoles(b.roles));
            case RequiredRightsKind.ANY_ROLE:
                return of(this.authService.hasAnyRole(b.roles));
        }
        return of(false);
    }

    private makeAnd(a: RequiredRightsItem[]): Observable<boolean> {
        if (a.length) {
            return from(a).pipe(
                switchMap(x => this.factory(x)),
                first(x => !x, true)
            );
        } else {
            return of(false);
        }
    }

    private makeOr(a: RequiredRightsItem[][]): Observable<boolean> {
        if (a.length) {
            return from(a).pipe(
                switchMap(x => this.makeAnd(x)),
                first(x => x, false)
            );
        } else {
            return of(true);
        }
    }
}
