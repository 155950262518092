import { Injectable, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import {
    ModalConfirmComponent,
    ModalService,
    RbacStaticType,
    SidenavItems,
    sortObjectArrayByKey,
    validateFormAndDisplayErrors,
    writeContents,
} from '@dagility-ui/kit';
import { CompletionSourceItem, HierarchyLevel, PublishProperties } from '@dagility-ui/shared-components';
import { AuthService } from '@app/auth';

import { FileSizePipe } from '@app/shared/pipes/file-size.pipe';
import { PublishInfo, PublishModalComponent } from '@app/shared/components/publish-modal/publish-modal.component';
import { MenuItem, TemplateEditorFilter } from '../../models/template-editor.model';
import { CiCdTemplateService, MarketplaceTemplateItem, MarketplaceTemplateRating } from '../../api/ci-cd-template.service';
import {
    COMPONENT_IMAGE_MAX_FILE_SIZE,
    DEFAULT_TEMPLATE,
    DEFAULT_TEMPLATE_ITEM_MAP,
    TEMPLATES_EDITOR_ROUTE,
    TEMPLATES_LIST_ROUTE,
} from '../constants/template-editor-constants';
import { ICiCdTemplateDetails, ICiCdTemplateEditor, IPipelineDetails } from '../../models/cicd-template-configuration.model';
import { ComponentTypeItem } from '../../models/component.model';
import { CiCdPluginService } from '../../api/ci-cd-plugin.service';
import { mergeStringArrays, showAllMenuItems } from '../../ci-cd-shared/utils/utilities';
import { CI_CD_MODULE_SEGMENT, TEMPLATE_EDITOR_SEGMENT } from '../../../../app-routing.constants';
import { MarketplaceService } from '../../api/marketplace.service';
import { AutoCompletionService } from '../../api/auto-completion.service';
import { ProjectTemplate } from '../../project-templates/project-templates.model';

declare const _: any;

@Injectable()
export class TemplateEditorService implements OnDestroy {
    template: ICiCdTemplateEditor;
    templateType: 'standard' | 'marketplace' = 'standard';
    currentTemplateId: number;
    serviceInitialized$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    tagsCacheLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    componentTypes$: Observable<ComponentTypeItem[]>;

    hierarchyLevel: HierarchyLevel;
    autoCompletionId: string;
    autoCompletion: CompletionSourceItem[] = [];
    autoCompletionEnabled: boolean = false;

    dataLoading: boolean;
    saveLastState = false;

    selectedMenuItem$: BehaviorSubject<MenuItem> = new BehaviorSubject(null);
    selectedSubMenuItem$: BehaviorSubject<MenuItem> = new BehaviorSubject<MenuItem>(null);
    menuItemData: any;

    sideMenuData: MenuItem[];
    sidenavItems: SidenavItems = [];

    formGroup: FormGroup = this.fb.group({});

    filter: TemplateEditorFilter = {
        label: '',
        tagId: null,
        tagCaption: '',
        tagAdditionalInfo: '',
    };

    discardChangesTitle = 'Discard';
    discardChangesMessage = 'Are you sure you want to discard changes?';

    templateTagsCache: string[];

    file: File | null = null;
    previewUrl: any;

    previousUrl = '';
    readonly = false;
    marketplaceActive$: Observable<boolean>;

    canPublishChanges = false;
    script: string;
    needToSave = false;
    cancelClicked$: Subject<boolean> = new Subject<boolean>();
    saveClicked$: Subject<boolean> = new Subject<boolean>();

    // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
    private _selectedMenuItem: MenuItem;
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _selectedSubMenuItem: MenuItem;

    destroyed$ = new Subject();

    constructor(
        private autoCompletionService: AutoCompletionService,
        private templateService: CiCdTemplateService,
        private pluginService: CiCdPluginService,
        private toaster: ToastrService,
        private modalService: ModalService,
        private fb: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private fileSizePipe: FileSizePipe,
        private marketplaceService: MarketplaceService,
        private authService: AuthService
    ) {
        this.canPublishChanges = this.authService.hasAnyRole(['ROLE_BUILD_ENGINEER', 'ROLE_ADMIN']);
        this.marketplaceActive$ = this.marketplaceService.getIsMarketplaceAvailable().pipe(takeUntil(this.destroyed$));
        authService
            .hasPermission(RbacStaticType.OBJECT, 'ProjectTemplate', 'modify')
            .subscribe(value => (this.autoCompletionEnabled = value));
    }

    set selectedMenuItem(menuItem: MenuItem) {
        Promise.resolve().then(() => {
            this._selectedMenuItem = menuItem;
            this.selectedMenuItem$.next(menuItem);
        });
    }

    get selectedMenuItem() {
        return this._selectedMenuItem;
    }

    set selectedSubMenuItem(menuItem: MenuItem) {
        this._selectedSubMenuItem = menuItem;
        this.selectedSubMenuItem$.next(menuItem);
    }

    get selectedSubMenuItem() {
        return this._selectedSubMenuItem;
    }

    get fieldControls() {
        return this.formGroup.get(`fields`);
    }

    getRatingStatistics(guid: string, version: number): Observable<MarketplaceTemplateRating> {
        return this.templateService.getRatingStatistics(guid, version);
    }

    getTemplateHistory(guid: string, version: number): Observable<MarketplaceTemplateItem[]> {
        return this.templateService.getTemplateHistory(guid, version);
    }

    initService(templateId: number, templateInput?: ICiCdTemplateEditor) {
        this.serviceInitialized$.next(false);
        this.initTagsCache();
        this.componentTypes$ = this.templateService.getComponentTypeList().pipe(shareReplay(1));
        this.formGroup.markAsPristine();
        this.currentTemplateId = templateId;

        if (templateId) {
            this.dataLoading = true;
            const sourceSubject = this.templateType === 'marketplace' ? new BehaviorSubject(true) : new BehaviorSubject(false);
            sourceSubject
                .pipe(
                    takeUntil(this.destroyed$),
                    switchMap(val =>
                        val
                            ? this.templateService.getMarketplaceTemplateWithChildrenById(templateId)
                            : this.templateService.getTemplateWithChildrenById(templateId)
                    )
                )
                .subscribe(
                    template => {
                        this.template = template;
                        this.initTemplateInformation(this.currentTemplateId);
                    },
                    () => {
                        this.dataLoading = false;
                        this.serviceInitialized$.next(false);
                        this.router.navigateByUrl(`${CI_CD_MODULE_SEGMENT}/not-found`, {
                            state: { navigateTo: `${CI_CD_MODULE_SEGMENT}/templates` },
                        });
                    }
                );
        } else {
            this.template = templateInput ? _.cloneDeep(templateInput) : { ...DEFAULT_TEMPLATE };
            this.initSideMenuData();
            this.serviceInitialized$.next(true);
        }
        return this.serviceInitialized$;
    }

    initTagsCache() {
        this.templateService
            .getFilterItems()
            .pipe(takeUntil(this.destroyed$))
            .subscribe(
                result => {
                    this.templateTagsCache = mergeStringArrays(this.templateTagsCache || [], result.tags);
                    this.tagsCacheLoaded$.next(true);
                },
                () => this.tagsCacheLoaded$.next(false)
            );
    }

    initTemplateInformation(templateId: number) {
        this.templateService
            .getTemplatePipelineGeneratorExist(templateId)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(response => {
                this.updateTemplatePipelines(response);
                this.setReadonly();
                this.initSideMenuData();
                this.dataLoading = false;
                this.serviceInitialized$.next(true);
            });
    }

    initSideMenuData() {
        this.sideMenuData = [];

        const mainCategoryItem = new MenuItem('details', 'Template Details', 'details', this.template, 'details', null, null, true, true);
        this.sideMenuData.push(mainCategoryItem);

        const componentItems: any[] = [];
        const componentCategoryItem = new MenuItem(
            'components',
            'Components',
            'components',
            null,
            'components',
            componentItems,
            null,
            true,
            true
        );
        sortObjectArrayByKey(this.template.components, 'name');
        this.template.components.forEach(component => {
            componentItems.push(
                new MenuItem(
                    component.id.toString(),
                    component.name,
                    'components',
                    component,
                    'components',
                    null,
                    componentCategoryItem,
                    false,
                    false,
                    true,
                    null,
                    false,
                    this.template.readOnly
                )
            );
        });
        componentCategoryItem.show = !!this.template.id;
        this.sideMenuData.push(componentCategoryItem);

        const pipelineItems: any[] = [];
        const pipelineCategoryItem = new MenuItem(
            'pipelines',
            'Pipelines',
            'pipelines',
            null,
            'pipelines',
            pipelineItems,
            null,
            true,
            true
        );
        sortObjectArrayByKey(this.template.pipelines, 'name');
        this.template.pipelines.forEach(pipeline => {
            pipelineItems.push(
                new MenuItem(
                    pipeline.id.toString(),
                    pipeline.name,
                    'pipelines',
                    pipeline,
                    'pipelines',
                    null,
                    pipelineCategoryItem,
                    false,
                    false,
                    true,
                    null,
                    false,
                    this.template.readOnly
                )
            );
        });
        pipelineCategoryItem.show = !!this.template.id;
        this.sideMenuData.push(pipelineCategoryItem);

        const workflowItems: any[] = [];
        const workflowCategoryItem = new MenuItem(
            'workflows',
            'Workflows',
            'workflows',
            null,
            'workflows',
            workflowItems,
            null,
            true,
            true
        );
        sortObjectArrayByKey(this.template.workflows, 'name');
        this.template.workflows.forEach(workflow => {
            workflowItems.push(
                new MenuItem(
                    workflow.id.toString(),
                    workflow.name,
                    'workflows',
                    workflow,
                    'workflows',
                    null,
                    workflowCategoryItem,
                    false,
                    false,
                    true,
                    null,
                    false,
                    this.template.readOnly
                )
            );
        });
        workflowCategoryItem.show = !!this.template.id;
        this.sideMenuData.push(workflowCategoryItem);

        const newSideMenuData = this.sideMenuData.map(menuItem => ({
            routerLink: menuItem.routerLink,
            title: menuItem.label,
        }));

        this.sidenavItems = [
            {
                title: this.template.name || 'New Template',
                search: false,
                items: newSideMenuData,
                height: '30vh',
            },
        ];

        this.calcMenuItemTags();
    }

    selectLoadedItem(menuItem: MenuItem) {
        if (menuItem && !menuItem.parent) {
            this.selectedMenuItem = menuItem;
            this.selectedSubMenuItem = null;
        } else {
            this.selectedSubMenuItem = menuItem;
        }
        this.menuItemData = menuItem ? menuItem.data : null;
    }

    dataToMenuItem(data: any, menuItem: MenuItem) {
        Object.keys(data).forEach(key => {
            menuItem.data[key] = data[key];
        });
        menuItem.isLoaded = true;
        menuItem.loading = false;
        this.selectLoadedItem(menuItem);
    }

    confirmToDoAction(
        requestCondition: boolean = true,
        callback: () => void = () => {
            /*empty*/
        },
        title: string = this.discardChangesTitle,
        message: string = this.discardChangesMessage
    ) {
        if (requestCondition) {
            this.modalService
                .open(
                    ModalConfirmComponent,
                    {
                        centered: true,
                        backdrop: 'static',
                    },
                    {
                        message: {
                            title,
                            content: message,
                        },
                    }
                )
                .result.then(
                    () => {
                        callback();
                    },
                    () => {
                        /* rejected */
                    }
                );
        } else {
            callback();
        }
    }

    checkIdAndGetMenuItem(menuItemId: string, menuItemsArray: MenuItem[]): MenuItem {
        let menuItem: MenuItem;
        const isMenuItemsArray = menuItemsArray && menuItemsArray.length;
        if (!menuItemId) {
            menuItem = isMenuItemsArray ? menuItemsArray[0] : null;
        } else {
            menuItem = isMenuItemsArray ? menuItemsArray.find(el => el.id === menuItemId) : null;
            menuItem = !menuItem && isMenuItemsArray ? menuItemsArray[0] : menuItem;
        }
        return menuItem;
    }

    navigateTo(link: string, route: ActivatedRoute, replace: boolean = false) {
        this.router.navigate([link], { relativeTo: route, replaceUrl: replace });
    }

    selectMenuItem(
        menuItem: MenuItem,
        successCallBack: () => void = () => {
            /* empty */
        }
    ) {
        this.selectedMenuItem$.next(null);
        this.selectedSubMenuItem$.next(null);

        this.resetComponentImagePreview();

        if (menuItem) {
            if (menuItem.isLoaded || menuItem.isCategory) {
                this.selectLoadedItem(menuItem);
                successCallBack();
            } else {
                menuItem.loading = true;
                let getMethodsMap: Record<string, any>;

                if (this.templateType === 'marketplace') {
                    getMethodsMap = {
                        components: this.templateService.getMarketplaceTemplateComponent(
                            menuItem.data.id,
                            this.template.originalTemplateId
                        ),
                        pipelines: this.templateService.getMarketplaceTemplatePipeline(menuItem.data.id, this.template.originalTemplateId),
                        workflows: this.templateService.getMarketplaceWorkflow(menuItem.data.id, this.template.originalTemplateId),
                    };
                } else {
                    getMethodsMap = {
                        components: this.templateService.getComponentById(menuItem.data.id, this.template.id),
                        pipelines: this.templateService.getPipelineById(menuItem.data.id, this.template.id),
                        workflows: this.templateService.getWorkflowById(menuItem.data.id, this.template.id),
                    };
                }

                if (menuItem.itemType in getMethodsMap) {
                    getMethodsMap[menuItem.itemType].pipe(takeUntil(this.destroyed$)).subscribe((data: any) => {
                        if (data) {
                            this.dataToMenuItem(data, menuItem);
                            successCallBack();
                        } else {
                            this.toaster.error('No data', `Error while getting "${menuItem.label}"`);
                            menuItem.loading = false;
                        }
                    });
                } else {
                    menuItem.loading = false;
                    this.toaster.error(`Error at template-editor.service.ts selectMenuItem`);
                }
            }
        } else {
            this.selectLoadedItem(menuItem);
        }
    }

    addMenuItem(categoryItem: MenuItem) {
        const newTemplateSubItem: any = { ...DEFAULT_TEMPLATE_ITEM_MAP[categoryItem.itemType] };
        if (!newTemplateSubItem) {
            this.toaster.error(`Something went wrong at template-editor.service.ts addMenuItem: no default items of type`);
            return;
        }

        newTemplateSubItem.templateId = this.template.id;

        const newMenuItem = new MenuItem(
            '',
            newTemplateSubItem.name,
            categoryItem.itemType,
            newTemplateSubItem,
            '',
            null,
            categoryItem,
            true,
            false,
            true,
            null,
            true
        );
        this.selectMenuItem(newMenuItem);
    }

    addMenuItemAfterSave(categoryItem: MenuItem, subMenuItem: MenuItem, formValue: any, labelName: string, currentRoute?: ActivatedRoute) {
        const addData = _.cloneDeep(formValue);
        subMenuItem.id = addData.id.toString();
        subMenuItem.data = addData;
        subMenuItem.label = addData[labelName];
        (this.template as Record<string, any>)[categoryItem.itemType].push(addData);
        categoryItem.items.push(subMenuItem);
        this.menuItemData = subMenuItem.data;
        subMenuItem.loading = false;
        subMenuItem.isNew = false;
        this.sortMenu(subMenuItem, labelName);
        this.calcMenuItemTags();
        this.applyFilters(this.filter, categoryItem.items);
        if (currentRoute) {
            if (subMenuItem.itemType === 'components') {
                const subStr = 'components/';
                const url =
                    this.router.url.slice(0, this.router.url.indexOf(subStr) + subStr.length) + 'list/' + subMenuItem.id + '/general';
                this.router.navigateByUrl(url);
            } else {
                const url = currentRoute.parent;
                this.navigateTo(`list/${subMenuItem.id}`, url, true);
            }
        } else {
            const url = `${TEMPLATES_EDITOR_ROUTE}/${this.template.id}/${categoryItem.id}/list/${subMenuItem.id}`;
            this.navigateTo(url, this.route, true);
        }
        this.formGroup.markAsPristine();
        this.initService(this.template.id);
    }

    save(currentRoute?: ActivatedRoute) {
        validateFormAndDisplayErrors(this.formGroup);
        if (this.formGroup.valid) {
            this.saveScreen(this.formGroup.value, currentRoute);
        }
    }

    turnFormValueToMenuItemData(formValue: any) {
        Object.keys(formValue).forEach(key => {
            if (Array.isArray(formValue[key]) || typeof formValue[key] === 'object') {
                this.menuItemData[key] = _.cloneDeep(formValue[key]);
            } else {
                this.menuItemData[key] = formValue[key];
            }
        });
    }

    patchFormValueFromItemData(itemData: any) {
        this.formGroup.reset();
        this.formGroup.patchValue(_.cloneDeep(itemData));
    }

    saveScreen(formValue: any, currentRoute?: ActivatedRoute) {
        const menuItem = this.selectedMenuItem;

        if (menuItem.itemType === 'details') {
            menuItem.loading = true;
            const saveData: ICiCdTemplateDetails = {
                id: formValue.id,
                name: formValue.name,
                description: formValue.description,
                type: formValue.type,
                tags: formValue.tags,
                pluginServices: formValue.pluginServices,
                fields: formValue.fields,
            };
            if (this.currentTemplateId) {
                this.templateService
                    .saveTemplateDetails(saveData)
                    .pipe(takeUntil(this.destroyed$))
                    .subscribe(
                        () => {
                            this.getAutoCompletionProperties(formValue.id.toString(), this.hierarchyLevel, true);
                            menuItem.loading = false;
                            this.turnFormValueToMenuItemData(formValue);
                            this.toaster.success(`Template details are successfully saved`);
                            this.formGroup.markAsPristine();
                            this.updateMarketplaceFlags(this.currentTemplateId);
                        },
                        () => {
                            menuItem.loading = false;
                        }
                    );
            } else {
                this.templateService
                    .createTemplate(saveData)
                    .pipe(takeUntil(this.destroyed$))
                    .subscribe(
                        templateId => {
                            menuItem.loading = false;
                            formValue.id = templateId;
                            this.turnFormValueToMenuItemData(formValue);
                            this.toaster.success(`Template details are successfully saved`);
                            this.formGroup.markAsPristine();
                            this.updateMarketplaceFlags(templateId);
                            this.router.navigate([`/${CI_CD_MODULE_SEGMENT}/templates/${TEMPLATE_EDITOR_SEGMENT}`, templateId], {
                                replaceUrl: true,
                            });
                        },
                        () => {
                            menuItem.loading = false;
                        }
                    );
            }
            return;
        }

        if (formValue.id) {
            this.saveItem(this.selectedSubMenuItem, formValue);
        } else {
            this.createItem(menuItem, this.selectedSubMenuItem, formValue, currentRoute);
        }
    }

    private createTemplateItem(menuItem: MenuItem, subMenuItem: MenuItem, formValue: any, currentRoute?: ActivatedRoute) {
        const createMethodsMap = {
            components: this.templateService.createComponents([formValue], this.template.id),
            pipelines: this.templateService.createPipeline(formValue, this.template.id),
            workflows: this.templateService.createWorkflow(formValue, this.template.id),
        };
        subMenuItem.loading = true;
        if (subMenuItem.itemType in createMethodsMap) {
            (createMethodsMap as Record<string, any>)[subMenuItem.itemType].pipe(takeUntil(this.destroyed$)).subscribe(
                (ids: any) => {
                    formValue.id = ids ? (Array.isArray(ids) ? ids[0] : ids) : null;
                    let prefix = '';
                    if (subMenuItem.itemType === 'pipelines') {
                        this.initTagsCache();
                        prefix = 'Pipeline';
                    } else if (subMenuItem.itemType === 'components') {
                        this.resetComponentImagePreview();
                        prefix = 'Component';
                    } else if (subMenuItem.itemType === 'workflows') {
                        prefix = 'Workflow';
                    }
                    this.addMenuItemAfterSave(menuItem, subMenuItem, formValue, 'name', currentRoute);
                    this.toaster.success(`${prefix} "${subMenuItem.label}" is successfully created`);
                    this.updateMarketplaceFlags(this.currentTemplateId);
                },
                () => {
                    subMenuItem.loading = false;
                }
            );
        } else {
            subMenuItem.loading = false;
            this.toaster.error(`Error while creating item`);
        }
    }

    createItem(menuItem: any, subMenuItem: any, formValue: any, currentRoute?: ActivatedRoute) {
        if (subMenuItem.itemType === 'components' && this.file) {
            subMenuItem.loading = true;
            let imageSrc = '';
            this.templateService
                .uploadImage(this.file)
                .pipe(
                    takeUntil(this.destroyed$),
                    finalize(() => {
                        formValue.imagePath = imageSrc;
                        this.createTemplateItem(menuItem, subMenuItem, formValue, currentRoute);
                    })
                )
                .subscribe(
                    (result: any) => {
                        imageSrc = result['imagePath'];
                        this.updateMarketplaceFlags(this.currentTemplateId);
                    },
                    error => {
                        this.toaster.error(error['message'], 'Error occurred during uploading Image: ');
                        subMenuItem.loading = false;
                    }
                );
        } else {
            this.createTemplateItem(menuItem, subMenuItem, formValue, currentRoute);
        }
    }

    saveTemplateItem(subMenuItem: any, formValue: any) {
        const saveMethodsMap = {
            components: this.templateService.saveComponent(formValue, this.template.id),
            pipelines: this.templateService.savePipeline(formValue, this.template.id),
            workflows: this.templateService.saveWorkflow(formValue, this.template.id),
        };

        subMenuItem.loading = true;
        if (subMenuItem.itemType in saveMethodsMap) {
            (saveMethodsMap as Record<string, any>)[subMenuItem.itemType].pipe(takeUntil(this.destroyed$)).subscribe(
                () => {
                    this.getAutoCompletionProperties(formValue.id.toString(), this.hierarchyLevel, true);
                    this.turnFormValueToMenuItemData(formValue);
                    if (subMenuItem.itemType === 'pipelines') {
                        this.initTagsCache();
                        this.updateGeneratorExist();
                    } else if (subMenuItem.itemType === 'components') {
                        this.resetComponentImagePreview();
                    }
                    this.finishSaveItem(subMenuItem, this.menuItemData, 'name');
                    this.updateMarketplaceFlags(this.currentTemplateId);
                },
                () => {
                    subMenuItem.loading = false;
                }
            );
        } else {
            subMenuItem.loading = false;
            this.toaster.error(`Error while saving item`);
        }
    }

    updateGeneratorExist() {
        this.templateService
            .getTemplatePipelineGeneratorExist(this.currentTemplateId)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(response => {
                this.updateTemplatePipelines(response);
                this.selectedMenuItem.items.find(x => x.data.id === this.menuItemData.id).data.haveGenerator =
                    response[this.menuItemData.id];
            });
    }

    protected updateTemplatePipelines(response: NumberMap<boolean>): void {
        this.template.pipelines = this.template.pipelines.map(pipeline => ({
            ...pipeline,
            haveGenerator: response[pipeline.id],
        }));
    }

    saveItem(subMenuItem: any, formValue: any) {
        if (subMenuItem.itemType === 'components' && this.file) {
            subMenuItem.loading = true;
            let minioImageSrc = '';
            this.templateService
                .uploadImage(this.file)
                .pipe(
                    takeUntil(this.destroyed$),
                    finalize(() => {
                        formValue.imagePath = minioImageSrc;
                        this.saveTemplateItem(subMenuItem, formValue);
                    })
                )
                .subscribe(
                    (result: any) => {
                        minioImageSrc = result['imagePath'];
                        this.updateMarketplaceFlags(this.currentTemplateId);
                    },
                    error => this.toaster.error(error['message'], 'Error occurred during uploading Image: ')
                );
        } else if (subMenuItem.itemType === 'pipelines' && this.script != null) {
            this.templateService.savePipelineScript(this.script, this.menuItemData.id, this.currentTemplateId).subscribe(() => {
                (this.template.pipelines.find(x => x.id === this.menuItemData.id) as IPipelineDetails).script = this.script;
                this.menuItemData.script = this.script;
                this.saveClicked$.next();
                this.toaster.success('Pipeline script was saved');
            });
        } else {
            this.saveTemplateItem(subMenuItem, formValue);
        }
    }

    finishSaveItem(subMenuItem: MenuItem, menuItemData: any, labelName: string) {
        subMenuItem.loading = false;
        subMenuItem.id = menuItemData.id ? menuItemData.id.toString() : subMenuItem.id;
        subMenuItem.label = menuItemData.name;
        this.toaster.success(`${subMenuItem.parent.label.slice(0, -1)} "${subMenuItem.label}" is successfully saved`);
        this.formGroup.markAsPristine();
        this.sortMenu(subMenuItem, labelName);
        this.calcMenuItemTags();
    }

    cancel(value: any) {
        this.resetComponentImagePreview();
        if (value.id) {
            this.patchFormValueFromItemData(value.data || value);
            this.formGroup.markAsPristine();
        } else {
            this.formGroup.markAsPristine();
            this.cancelSaveScreen();
        }
        this.cancelClicked$.next();
    }

    cancelSaveScreen() {
        if (!this.menuItemData.id) {
            this.menuItemData = null;
            this.selectedSubMenuItem = null;
            const url = this.template.id
                ? `${TEMPLATES_EDITOR_ROUTE}/${this.template.id}/${this.selectedMenuItem.id}`
                : TEMPLATES_LIST_ROUTE;
            this.navigateTo(url, this.route.parent, true);
        }
    }

    removeMenuItem(removedItem: MenuItem) {
        if (removedItem.parent) {
            removedItem.loading = true;

            const removeMethodsMap = {
                components: this.templateService.removeComponentById(removedItem.data.id, this.template.id),
                pipelines: this.templateService.removePipelineById(removedItem.data.id, this.template.id),
                workflows: this.templateService.removeWorkflowById(removedItem.data.id, this.template.id),
            };

            if (removedItem.itemType in removeMethodsMap) {
                (removeMethodsMap as Record<string, any>)[removedItem.itemType].pipe(takeUntil(this.destroyed$)).subscribe(
                    () => {
                        removedItem.loading = false;
                        this.initService(this.template.id);
                        this.toaster.success(`${removedItem.parent.label.slice(0, -1)} "${removedItem.label}" is successfully removed`);
                    },
                    () => {
                        removedItem.loading = false;
                    }
                );
            } else {
                removedItem.loading = false;
                this.toaster.error(`Error while removing item`);
            }
        }
    }

    getCurrentSubmenuRoute(): string {
        let url = `${CI_CD_MODULE_SEGMENT}/templates/${TEMPLATE_EDITOR_SEGMENT}/${this.template.id}`;
        url = this.selectedMenuItem ? `${url}/${this.selectedMenuItem.itemType}` : url;
        return url;
    }

    duplicateMenuItem(duplicatedItem: MenuItem) {
        if (duplicatedItem.parent) {
            duplicatedItem.loading = true;

            const duplicateMethodsMap = {
                components: this.templateService.duplicateComponent(duplicatedItem.data.id, this.template.id),
                pipelines: this.templateService.duplicatePipeline(duplicatedItem.data.id, this.template.id),
            };

            if (duplicatedItem.itemType in duplicateMethodsMap) {
                (duplicateMethodsMap as Record<string, any>)[duplicatedItem.itemType].pipe(takeUntil(this.destroyed$)).subscribe(
                    (newId: any) => {
                        const newSelectedSubItem = new MenuItem(newId, duplicatedItem.label);
                        duplicatedItem.loading = false;
                        const subscription = this.initService(this.template.id).subscribe(initialized => {
                            if (!initialized) {
                                return;
                            }

                            const baseUrl = `${CI_CD_MODULE_SEGMENT}/templates/${TEMPLATE_EDITOR_SEGMENT}`;
                            const url = `${baseUrl}/${this.template.id}/${this.selectedMenuItem.itemType}/list/${newSelectedSubItem.id}`;
                            this.navigateTo(url, this.route);
                            this.updateMarketplaceFlags(this.currentTemplateId);
                            subscription.unsubscribe();
                        });
                        this.toaster.success(
                            `${duplicatedItem.parent.label.slice(0, -1)} "${duplicatedItem.label}" is successfully duplicated`
                        );
                    },
                    () => {
                        duplicatedItem.loading = false;
                    }
                );
            } else {
                duplicatedItem.loading = false;
                this.toaster.error(`Error while duplicating item`);
            }
        }
    }

    navigateToMenuItem(strCurrentId: number) {
        if (strCurrentId && this.selectedMenuItem) {
            const menuItem = this.checkIdAndGetMenuItem(strCurrentId.toString(), this.selectedMenuItem.items);
            if (menuItem) {
                if (menuItem.id !== strCurrentId.toString()) {
                    this.navigateTo(`list/${menuItem.id}`, this.route.parent);
                    return;
                }
                if (!menuItem.loading) {
                    this.selectMenuItem(menuItem);
                }
            } else {
                this.router.navigateByUrl(this.getCurrentSubmenuRoute()).then();
            }
        }
    }

    sortMenu(menuItem: MenuItem, labelName: string) {
        sortObjectArrayByKey((this.template as Record<string, any>)[menuItem.itemType], labelName);
        sortObjectArrayByKey(menuItem.parent.items, 'label');
    }

    handleFieldGridChange(e: any) {
        this.fieldControls.patchValue(e);
        this.fieldControls.markAsDirty();
    }

    publishProperties(event: PublishProperties) {
        this.templateService
            .publishProperties(event)
            .subscribe(() => this.toaster.success(`Template properties were successfully published`, 'Success'));
    }

    calcMenuItemTags() {
        const componentMenuItems = this.sideMenuData.find(menu => menu.itemType === 'components').items;
        componentMenuItems.forEach(item => {
            const pipelinesCount = this.template.pipelines.filter(pipeline => pipeline.componentId && pipeline.componentId === item.data.id)
                .length;
            item.tag.additionalInfo = `${pipelinesCount} pipeline` + (pipelinesCount > 1 ? 's' : '');
            item.tag.caption = `${item.data.name}`;
            item.tag.id = item.data.id;
        });

        const pipelineMenuItems = this.sideMenuData.find(menu => menu.itemType === 'pipelines').items;
        const appLevelPipelinesCount = this.template.pipelines.filter(
            pipeline =>
                !pipeline.componentId || this.template.components.filter(component => component.id !== pipeline.componentId).length === 0
        ).length;

        pipelineMenuItems.forEach(item => {
            const relatedComponentMenuItem = componentMenuItems.find(component => component.data.id === item.data.componentId);
            item.tag.caption = relatedComponentMenuItem ? relatedComponentMenuItem.data.name : 'App Level';
            item.tag.additionalInfo = relatedComponentMenuItem
                ? relatedComponentMenuItem.tag.additionalInfo
                : `${appLevelPipelinesCount} pipeline` + (appLevelPipelinesCount > 1 ? 's' : '');
            item.tag.id = relatedComponentMenuItem ? relatedComponentMenuItem.data.id : null;

            if (item.tag.caption === this.filter.tagCaption && item.tag.id === this.filter.tagId) {
                this.filter.tagAdditionalInfo = item.tag.additionalInfo;
            }
        });
    }

    uploadComponentImage(): void {
        this.resetComponentImagePreview();
        const input = document.createElement('input');
        input.accept = 'image/png,image/x-png,image/jpeg,image/webp';
        input.type = 'file';
        input.multiple = false;
        input.onchange = () => {
            const file: File = input.files[0];
            const fileSizeString = this.fileSizePipe.transform(COMPONENT_IMAGE_MAX_FILE_SIZE);
            if (file.size && file.size > COMPONENT_IMAGE_MAX_FILE_SIZE) {
                this.toaster.error(`You have selected a file greater then ${fileSizeString} upload.`);
                return;
            }
            this.file = file;
            this.formGroup.markAsDirty();
            const reader = new FileReader();
            reader.readAsDataURL(this.file);
            reader.onload = () => {
                this.previewUrl = reader.result;
            };
        };
        input.click();
    }

    publishTemplate() {
        const templateId = this.template.originalTemplateId;
        if (templateId) {
            const modalRef = this.modalService.open(PublishModalComponent, {
                centered: true,
                backdrop: 'static',
            });
            modalRef.componentInstance.isSent.pipe(takeUntil(this.destroyed$)).subscribe((publishInfo: PublishInfo) => {
                if (publishInfo) {
                    this.templateService.uploadToMarketplace(templateId, publishInfo.changelog, publishInfo.comment).subscribe(() => {
                        this.toaster.success(`Template ${this.template.name} was successfully published to Marketplace`, 'Success Publish');
                        this.updateMarketplaceFlags(templateId);
                    });
                }
            });
        }
    }

    resetComponentImagePreview() {
        this.previewUrl = null;
        this.file = null;
    }

    clearComponentImage(componentForm: FormGroup): void {
        componentForm.get('imagePath').setValue('');
        this.resetComponentImagePreview();
        this.formGroup.markAsDirty();
    }

    onMultiSelectValueChange(value: any, formControlName: string) {
        const dropDownControl = this.formGroup.get(formControlName);
        dropDownControl.setValue(value);
        this.formGroup.markAsDirty();
    }

    // filter methods
    applyFilters(filter: TemplateEditorFilter, menuItems: MenuItem[]) {
        const labelRE = new RegExp('.*' + filter.label.toLowerCase() + '.*');
        menuItems.forEach(item => {
            item.show =
                (labelRE.test(item.label.toLowerCase()) || labelRE.test(item.tag.caption.toLowerCase())) &&
                (filter.tagId
                    ? item.tag.id === filter.tagId
                    : filter.tagCaption === 'App Level'
                    ? filter.tagCaption === item.tag.caption
                    : true);
        });
    }

    setLabelFilter(e: string, menuItems: MenuItem[]) {
        this.filter.label = e;
        this.applyFilters(this.filter, menuItems);
    }

    setTagFilter(menuItem: MenuItem) {
        if (menuItem.itemType === 'pipelines' && menuItem.tag.id) {
            const url = `${TEMPLATES_EDITOR_ROUTE}/${this.template.id}/components/list/${menuItem.tag.id}`;
            this.filter.tagId = null;
            this.filter.tagCaption = '';
            this.filter.tagAdditionalInfo = '';
            this.navigateTo(url, this.route);
        }

        if (menuItem.itemType === 'components') {
            this.confirmToDoAction(this.formGroup.dirty, () => {
                this.formGroup.markAsPristine();
                const url = `${TEMPLATES_EDITOR_ROUTE}/${this.template.id}/pipelines`;
                this.navigateTo(url, this.route);
                this.selectedMenuItem$.pipe(takeUntil(this.destroyed$)).subscribe(result => {
                    if (result) {
                        this.filter.tagId = menuItem.tag.id;
                        this.filter.tagCaption = menuItem.tag.caption;
                        this.filter.tagAdditionalInfo = menuItem.tag.additionalInfo;
                        this.applyFilters(this.filter, result.items);
                    }
                });
            });
        }
    }

    useTemplate(metaGuid: string, version: number) {
        this.templateService
            .importFromMarketplace(metaGuid, version)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => this.toaster.success('Template was successfully selected for using in Dagility Application', 'Success'));
    }

    updateTemplate(id: number, version: number) {
        this.templateService
            .updateMarketplaceTemplate(id, version)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.toaster.success(`Template was successfully updated`, 'Success');
            });
    }

    rateTemplate(guid: string, rate: number, version: number): BehaviorSubject<boolean> {
        const templateRated = new BehaviorSubject(null);
        this.templateService
            .rateTemplate(guid, rate, version)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.toaster.success('Template was successfully rated', 'Success');
                templateRated.next(true);
            });
        return templateRated;
    }

    private updateMarketplaceFlags(templateId: number) {
        this.marketplaceActive$.subscribe(isActive => {
            if (isActive) {
                this.templateService
                    .getTemplateWithChildrenById(templateId)
                    .pipe(takeUntil(this.destroyed$))
                    .subscribe((template: ICiCdTemplateEditor) => {
                        this.template.downloadedFromMarketplace = template.downloadedFromMarketplace;
                        this.template.updateMarketplaceTemplate = template.updateMarketplaceTemplate;
                        this.template.uploadedToMarketplace = template.uploadedToMarketplace;
                    });
            }
        });
    }

    private setReadonly(): void {
        if (this.templateType === 'marketplace') {
            this.template.readOnly = true;
            this.readonly = true;
            return;
        }

        if (this.template.readOnly) {
            if (localStorage.getItem('edit-allowed')) {
                this.readonly = localStorage.getItem('edit-allowed') === 'false';
                localStorage.removeItem('edit-allowed');
            } else {
                this.readonly = true;
            }
        } else {
            this.readonly = false;
        }
    }

    resetTagFilter(menuItems: MenuItem[]) {
        this.filter.tagId = null;
        this.filter.tagCaption = '';
        this.filter.tagAdditionalInfo = '';
        this.applyFilters(this.filter, menuItems);
    }

    resetFilters(menuItems: MenuItem[]) {
        this.filter.label = '';

        this.filter.tagId = null;
        this.filter.tagCaption = '';
        this.filter.tagAdditionalInfo = '';
        showAllMenuItems(menuItems);
    }

    getAutoCompletionProperties(id: string, hierarchyLevel: HierarchyLevel, refresh?: boolean) {
        if (id && this.autoCompletionEnabled && (this.hierarchyLevel !== hierarchyLevel || this.autoCompletionId !== id || refresh)) {
            this.hierarchyLevel = hierarchyLevel;
            this.autoCompletionService.getCodeCompletionProperties(id, hierarchyLevel, false).subscribe(response => {
                this.autoCompletion = response;
                this.autoCompletionId = id;
            });
        }
    }

    quit() {
        this.confirmToDoAction(this.formGroup.dirty, () => {
            this.formGroup.markAsPristine();
            sessionStorage.setItem('activatedTab', this.templateType === 'standard' ? 'my-templates' : 'marketplace');
            this.router.navigate([this.previousUrl]);
        });
    }

    deleteTemplate(template: ProjectTemplate): void {
        const ref = this.modalService.open(ModalConfirmComponent, {
            centered: true,
            backdrop: 'static',
            windowClass: 'modal-break-word',
        });
        ref.componentInstance.message = {
            title: `Delete Template`,
            content: `All projects and components created from this template will be disconnected and no longer work.\n\nDo you want to delete "${template.name}" from the system?`,
        };
        ref.componentInstance.confirmOk.subscribe(() => {
            this.templateService.removeTemplateById(+template.id).subscribe(() => {
                this.toaster.success('The template deleted successfully.');
                this.router.navigate(['conveyor/templates']);
            });
        });
    }

    downloadTemplate(template: ProjectTemplate) {
        const templateName = template.name.replace(/,/g, '').replace(/\./g, '');
        const fileName = `${templateName}.zip`;
        this.templateService.downloadTemplateAsZip(+template.id).subscribe(blob => {
            writeContents(blob, fileName);
        });
    }

    ngOnDestroy() {
        this.destroyed$.next();
        localStorage.removeItem('edit-allowed');
    }
}
