import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Traceability } from '../model/traceability.model';
import { ENV_TOKEN } from '@app/tokens';

@Injectable({
    providedIn: 'root',
})
export class TraceabilityService {
    appSourcesBaseUrl = `${this.env.insightApiURL}/reports/endToEndTraceability`;
    closeNode = new Subject<boolean>();

    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {}

    getTraceability(issueId: string): Observable<Traceability> {
        const data = { issueKey: issueId };
        return this.http.get<Traceability>(`${this.appSourcesBaseUrl}`, { params: data });
    }
}
