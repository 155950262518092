<div class="buttons">
    <div class="col main-content-header">
        <ng-container *ngIf="subGraphSelected">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li (click)="goToSubGraph(null)" class="breadcrumb-item cursor-pointer">Root</li>
                    <ng-container *ngFor="let breadcrumbItem of subGraphBreadcrumbItems; let i = index">
                        <li
                            class="breadcrumb-item cursor-pointer"
                            (click)="goToSubGraph(breadcrumbItem.subQueryGraph.uid)"
                            [class.active]="breadcrumbItem.subQueryGraph.uid === currentModelGraphUid"
                            aria-current="page"
                        >
                            {{ breadcrumbItem.name }}
                        </li>
                    </ng-container>
                </ol>
            </nav>
            <div (click)="goToSubGraph(null)" class="d-flex mt-8px cursor-pointer"
                 style="align-items: center; column-gap: 8px;">
                <img alt="" [src]="icons.backArrow" style="width: 16px; height: 16px;">
                <h1>{{ subGraphHeader }}</h1>
            </div>
        </ng-container>
    </div>
    <div *ngIf="modelGraph" class="d-flex align-items-end">
        <div class="scale-buttons font-12px">
            <span class="scale-btn scale-btn-plus" (click)="scale(10)">+</span>
            <div class="scale-value">
                {{ currentSvgScale | percent }}
            </div>
            <span class="scale-btn scale-btn-minus" (click)="scale(-10)">−</span>
        </div>
        <button class="btn btn-secondary mr-12px bg-white"
                (click)="onWrapToSubQueryClick()"
                [disabled]="!modelGraph || !modelGraph.models.length || actionsLocked"
        >
            <fa-icon icon="facSitemapO" class="font-18px mr-5px"></fa-icon>
            Wrap to Subquery
        </button>
        <button class="btn btn-secondary mr-12px bg-white"
                (click)="onNewSubQueryClick()"
                [disabled]="!modelGraph || !modelGraph.models.length || actionsLocked"
        >
            <fa-icon icon="facAddNewPipeline" class="font-18px mr-5px"></fa-icon>
            New Subquery
        </button>
        <button class="btn btn-secondary bg-white"
                (click)="openSaveGraphModal()"
                [disabled]="!modelGraph || !modelGraph.models.length || actionsLocked"
        >
            <fa-icon icon="exportWidgetData" class="font-18px mr-5px"></fa-icon>
            Save as Model
        </button>
    </div>
</div>
<div (dragover)="$event.preventDefault()"
     (drop)="attachModel($event)" #svgFixedContainer [style.margin-top]="this.globalModelGraph ? '-80px' : '-48px'" id="svgFixedContainer">
    <div class="primary-model-message-wrapper"
         *ngIf="!globalModelGraph || (modelGraph  && modelGraph.models.length === 0)">
        <div class="primary-model-message">
            <app-icon-remoter [width]="35" [height]="28" [iconName]="'DRAGDROP'"></app-icon-remoter>
            <p style="font-weight: 400; font-size: 14px; line-height: 18px;">Please, drag &
                drop {{!globalModelGraph ? 'root' : subGraphSelected ? 'subgraph' : drilldownSelected ? 'drilldown' : 'root'}}
                model</p>
        </div>
    </div>
    <svg
        *ngIf="globalModelGraph"
        (click)="setMoveFixedContainerEvent()"
        #svgContainer
        (dragover)="$event.preventDefault()"
        (drop)="attachModel($event)"
    >
        <g transform="scale(1)" *ngIf="loading">
            <foreignObject [attr.height]="'5'" [attr.width]="svgContainer.clientWidth" [attr.x]="0" [attr.y]="0">
                <div>
                    <lib-progress-spinner class="position-relative"></lib-progress-spinner>
                </div>
            </foreignObject>
        </g>
        <g #svgCommonBlock>
            <g *ngFor="let svgRelation of svgRelations">
                <defs>
                    <marker id="start-arrow" refX="0" refY="2.5" markerWidth="6" markerHeight="5"
                            markerUnits="strokeWidth" orient="auto">
                        <path d="M 0 0 L 6 2.5 L 0 5 z" class="relation-path-arrow"/>
                    </marker>
                    <marker id="end-arrow" refX="6" refY="2.5" markerWidth="6" markerHeight="5"
                            markerUnits="strokeWidth" orient="auto">
                        <path d="M 0 0 L 6 2.5 L 0 5 z" class="relation-path-arrow"/>
                    </marker>
                </defs>
                <path [attr.d]="getPath(svgRelation)" class="relation-path" marker-start="url(#start-arrow)"
                      marker-end="url(#end-arrow)"/>
                <text
                    [attr.x]="
                        (svgRelation.from.getBoundingClientRect().right +
                            svgRelation.to.getBoundingClientRect().left -
                            2 * svgContainer.getBoundingClientRect().left) /
                        (2 * currentSvgScale)
                    "
                    [attr.y]="
                        (svgRelation.fromField.getBoundingClientRect().top +
                            svgRelation.toField.getBoundingClientRect().top -
                            2 * svgContainer.getBoundingClientRect().top) /
                        (2 * currentSvgScale)
                    "
                    class="relation-label cursor-pointer"
                    text-anchor="middle"
                    (mousemove)="setIsHovered(true, parseRelationDefinition(svgRelation.lineInText), $event)"
                    (mouseout)="setIsHovered(false, '', $event)"
                    (click)="openEditorForm(svgRelation.data)"
                >
                    {{ svgRelation.lineInText }}
                </text>
            </g>
            <g *ngFor="let model of svgModels">
                <foreignObject
                    [id]="model.model.uid"
                    [attr.x]="model.positionX"
                    [attr.y]="model.positionY"
                    style="overflow: visible"
                >
                    <div style="user-select: none;" class="model">
                        <div id="{{ model.model.uid + '-header' }}" class="header">
                            <div class="d-flex flex-1">
                                <div class="mr-10px" style="width: 10px; height: 10px;">
                                    <app-icon-remoter *ngIf="model.model.type === 'MODEL'" [width]="12" [height]="12"
                                                      [iconName]="'TABLE'"></app-icon-remoter>
                                    <app-icon-remoter *ngIf="model.model.type === 'FILTER'" [width]="12" [height]="12"
                                                      [iconName]="'TABLE'"></app-icon-remoter>
                                </div>
                                <input
                                    #modelName
                                    id="{{ model.model.uid + '-name' }}"
                                    (input)="onModelNameChange($event, model.model.uid)"
                                    (blur)="onBlur(model.model.uid)"
                                    (keyup.enter)="changeModelName(model.model.uid)"
                                    [class]="modelName.readOnly ? 'color-brand' : 'primary-text'"
                                    [value]="model.model.name"
                                    [class.cursor-default]="modelName.readOnly"
                                    [ngbTooltip]="modelName.value"
                                    openDelay="800"
                                    maxlength="59"
                                    readonly
                                    container="body"
                                    class="flex-grow-1 text-truncate"
                                />
                            </div>
                            <div class="d-flex">
                                <fa-icon
                                    icon="facEdit"
                                    class="cursor-pointer"
                                    [class.color-brand]="!modelName.readOnly"
                                    (mousedown)="$event.preventDefault()"
                                    (click)="onEditClicked(model.model.uid, modelName.readOnly)"
                                ></fa-icon>
                                <span style="cursor: pointer; " (click)="detachModel(model)">✕</span>
                            </div>
                        </div>
                        <div class="container">
                            <div class="part">
                                <div class="part-header">
                                    <div style="width: 12px; height: 12px;">
                                        <app-icon-remoter [color]="'DISABLED'" [width]="12" [height]="12"
                                                          [iconName]="'FIELD'"></app-icon-remoter>
                                    </div>
                                    <span>Fields</span>
                                </div>
                                <div class="part-container">
                                    <table *ngIf="model.model.data.fields.length > 0 else noDataFields">
                                        <tr>
                                            <th>Key</th>
                                            <th style="padding-right: 1px">
                                                <checkbox
                                                    *ngIf="isMainCheckboxExists(model.model.uid)"
                                                    [value]="isMainCheckboxSelected(model.model.uid)"
                                                    (click)="handleMainCheckbox(model.model.uid)"
                                                >
                                                </checkbox>
                                            </th>
                                            <th>Field name</th>
                                            <th></th>
                                        </tr>
                                        <tr
                                            id="{{ model.model.uid + '-' + field.dataField }}"
                                            *ngFor="let field of model.model.data.fields"
                                        >
                                            <td [ngSwitch]="getFieldRole(field.roles)">
                                                <ng-template ngSwitchCase="PK">
                                                    <span
                                                        (mouseover)="setIsHovered(true, 'Primary Key: This field uniquely identifies data record', $event)"
                                                        (mouseout)="setIsHovered(false, '', $event)"
                                                    >
                                                            <app-icon-remoter [color]="'DISABLED'"
                                                                              [width]="16"
                                                                              [height]="16"
                                                                              [iconName]="'KEY'"
                                                            >
                                                            </app-icon-remoter>
                                                    </span>
                                                </ng-template>
                                                <ng-template ngSwitchCase="FK">
                                                    <span
                                                        (mouseover)="setIsHovered(true, 'Foreign Key: This value of this field refers to the primary key of another data record', $event)"
                                                        (mouseout)="setIsHovered(false, '', $event)"
                                                    >
                                                        FK
                                                    </span>
                                                </ng-template>
                                                <ng-template ngSwitchDefault></ng-template>
                                            </td>
                                            <td style="padding-right: 1px">
                                                <checkbox
                                                    *ngIf="isCheckboxExists(model.model.uid, field)"
                                                    [value]="isCheckboxSelected(model.model.uid, field)"
                                                    (click)="handleCheckbox(model.model.uid, field)"
                                                >
                                                </checkbox>
                                            </td>
                                            <td>{{ field.name }}</td>
                                            <td>
                                                <div
                                                    class="d-flex align-items-center justify-content-end"
                                                >
                                                        <span
                                                            *ngIf="isWithGroupByIcon(model.model.uid)"
                                                            class="model-field-icon mr-5px cursor-pointer"
                                                            [class]="isGroupByActive(model.model.uid, field.name) ? 'model-field-icon-active' : 'model-field-icon'"
                                                            [innerHTML]="customIcons.facHorizontalGrid | safeHtml: 'html'"
                                                            (click)="handleGroupByClick(model.model.uid, field.name)"
                                                            (mouseover)="setIsHovered(true, 'Group By: This field is used to group result rows with the same values into summary rows', $event)"
                                                            (mouseout)="setIsHovered(false, '', $event)"
                                                        ></span>
                                                    <app-sort-type-dropdown
                                                        *ngIf="isWithOrderByIcon(model.model.uid)"
                                                        [class.sort-disabled]="isSortDisabled(model.model.uid, field.name)"
                                                        [withSortOff]="true"
                                                        [selectedDirection]="getSelectedDirection(model.model.uid, field.name)"
                                                        (itemClicked)="handleOrderByChange($event, model.model.uid, field.name)"
                                                        (mouseover)="setIsHovered(true, 'Sort: This field\'s value is used to specify the order of rows in the result', $event)"
                                                        (mouseout)="setIsHovered(false, '', $event)"
                                                    ></app-sort-type-dropdown>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                    <button
                                        class="w-100 mt-8px btn btn-link size-s"
                                        *ngIf="model.model.subQueryGraph"
                                        (click)="goToSubGraph(model.model.subQueryGraph.uid)"
                                    >
                                        <img [src]="icons.eye" alt="" class="mr-5px">
                                        Detailed structure
                                    </button>
                                </div>
                            </div>
                            <div class="part" *ngIf="model.model.type === 'MODEL'"
                                 [hidden]="!relNotEmptyMap.get(model.model.uid)">
                                <div class="part-header">
                                    <img src="{{ icons.relations }}" alt=""/>
                                    <span>Relations</span>
                                </div>
                                <div class="part-container">
                                    <div class="relations">
                                        <div class="item" *ngFor="let relation of []">
                                            {{ relation.model.name }}
                                        </div>
                                        <app-qb-model-add-items
                                            (update)="updateModelSize(model.model.uid)"
                                            (itemSelected)="droppedStatus = true"
                                            (menuOpened)="onMenuOpened($event)"
                                            [rootModels]="rootModels"
                                            [modelUid]="model.model.uid"
                                            [candidateType]="'RELATION'"
                                            [updatingRelations]="updatingRelations"
                                        ></app-qb-model-add-items>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </foreignObject>
            </g>
        </g>
    </svg>
</div>
<div class="hint-box" id="hintBoxRef" [class.invisible]="!isHovered" [style.top.px]="hintBox.top"
     [style.left.px]="hintBox.left">
    <span>{{ hintData }}</span>
</div>
<ng-template #noDataFields>
    <div class="d-flex align-items-center justify-content-center">
        <span class="color-font-4 font-14px">No Fields</span>
    </div>
</ng-template>
