import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    EventEmitter,
    forwardRef,
    Input,
    OnDestroy,
    Optional,
    Output,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropdownItem, ModalService, validateFormAndDisplayErrors } from '@dagility-ui/kit';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataMorph } from '../../models/dp-dashboard.model';
import { DpDashboardService } from '../../services/dp-dashboard.service';
import { GridsterOptionsService } from '../../services/gridster/gridster-options.service';
import { WIDGET_BUILDER_TAB, WidgetBuilderTab } from '../../../widget-builder/providers/widget-builder-tab.token';

export abstract class DashboardWidgetSettingsFormExtension {
    form: FormGroup;

    abstract setData(dashboard: DataMorph.Dashboard, widget: DataMorph.IlluminateDashboardWidget): void;
}

@Component({
    selector: 'dp-dashboard-widget-settings',
    templateUrl: './dashboard-widget-settings.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: WIDGET_BUILDER_TAB,
            useExisting: forwardRef(() => DashboardWidgetSettingsComponent),
        },
    ],
})
export class DashboardWidgetSettingsComponent implements AfterViewInit, OnDestroy, WidgetBuilderTab {
    @Input() set settings(settings: DataMorph.IlluminateDashboardWidget) {
        this.initForm(settings);
        this.widget = settings;
    }

    @Input() modal = true;

    @Input() widgetImport = false;

    @Input() formExtensionType: Type<DashboardWidgetSettingsFormExtension>;

    @Input() dashboard: DataMorph.Dashboard;

    @Output() save = new EventEmitter();

    @ViewChild('formExtension', { read: ViewContainerRef }) formExtensionVcr: ViewContainerRef;

    result$ = new Subject<{
        action: 'SAVE' | 'ADD_NEW_GROUP' | 'CLOSE';
        state: { options: Record<string, any>; illuminateOptions: Record<string, any> };
    }>();

    groups$: Observable<DropdownItem<number>[]>;

    form: FormGroup;

    formExtension: ComponentRef<DashboardWidgetSettingsFormExtension>;

    widget: DataMorph.IlluminateDashboardWidget;

    get formExtensionFactory() {
        return this._formExtensionType || this.formExtensionType;
    }

    canDeactivate(): boolean {
        return !this.form.dirty;
    }

    constructor(
        private fb: FormBuilder,
        private dashboardApi: DpDashboardService,
        private activeModal: NgbActiveModal,
        public modalService: ModalService,
        private resolver: ComponentFactoryResolver,
        private cdr: ChangeDetectorRef,
        @Optional() private _formExtensionType: DashboardWidgetSettingsFormExtension,
        @Optional() private gridsterOptionsService: GridsterOptionsService
    ) {}

    ngAfterViewInit() {
        if (!this.formExtensionFactory || !this.formExtensionVcr) {
            return;
        }

        const factory: ComponentFactory<DashboardWidgetSettingsFormExtension> = this.resolver.resolveComponentFactory(
            this.formExtensionFactory as any
        );
        this.formExtension = this.formExtensionVcr.createComponent(factory);
        this.formExtension.instance.setData(this.dashboard, this.widget);
        Object.entries(this.formExtension.instance.form.controls).forEach(([key, formControl]) => {
            (this.form.get('illuminateOptions') as FormGroup).addControl(key, formControl);
        });
        this.cdr.detectChanges();
    }

    initForm(widget: DataMorph.IlluminateDashboardWidget) {
        this.groups$ = this.dashboardApi
            .getGroupsByDashboardId(widget.dashboardId)
            .pipe(map(groups => groups.map(({ id, name }) => ({ value: id, label: name }))));

        this.form = this.fb.group({
            options: this.fb.group({
                index: [widget.options?.index || 0, Validators.required],
                groupId: [widget.groupId || null, Validators.required],
            }),
            illuminateOptions: this.fb.group({}),
        });
    }

    handleSave() {
        if (this.form.valid) {
            this.result$.next({ action: 'SAVE', state: this.form.value });
            this.activeModal.close(this.form.value);
            this.save.emit(this.form.value);
        } else {
            validateFormAndDisplayErrors(this.form);
        }
    }

    handleCancel() {
        this.result$.next({ action: 'CLOSE', state: {} as any });
        this.activeModal.close();
    }

    handleAddNewGroup() {
        this.result$.next({ action: 'ADD_NEW_GROUP', state: this.form.value });
    }

    ngOnDestroy() {
        this.formExtension?.destroy();
    }
}
