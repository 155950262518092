<svg zoom [shiftY]="80" [shiftX]="70">
    <defs>
        <style>
            /* <![CDATA[ */
            .block {
                display: flex;
                position: relative;
                padding: 0 30px 0 8px;
                border-radius: 3px;
                background: #F1F9FE;
                box-shadow: -1px 5px 13px rgba(0, 0, 0, 0.06), 0 3px 9px rgba(0, 0, 0, 0.08);
                color: #313131;
                height: 28px;
                z-index: 1;
            }

            .block--fork {
                height: calc(100% + 40px);
                transform: translateY(-25px);
                border: 2px solid;
                background: none;
                box-shadow: none;
                border-radius: 0;
            }

            .block--fork,
            .block--loop {
                border-color: #327DE2 !important;
            }

            .block--loop {
                height: calc(100% + 30px);
                transform: translateY(-15px);
                background: none;
                border: 1px dashed;
                box-shadow: none;
                border-radius: 0;
            }

            g.viewport .breakpoint {
                position: absolute;
                top: 0;
                bottom: 0;
                width: 30px;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            g.viewport .breakpoint--icon {
                content: '';
                position: absolute;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background-color: #dfe1e5;
                cursor: pointer;
            }

            g.viewport .breakpoint--icon.active {
                background-color: #d32f2f;
            }

            .edit {
                color: #747474;
            }

            .edit svg {
                stroke-width: 1px !important;
            }
            /* ]]> */
        </style>
    </defs>

    <g *ngIf="layout.blocks" class="viewport" fill="none" stroke="#d7d7d7">
        <defs>
            <marker
                id="arrow"
                markerWidth="10"
                markerHeight="10"
                refX="8"
                refY="3"
                orient="auto"
                markerUnits="strokeWidth"
                style="stroke-width: 0;"
            >
                <path d="M0,0 L0,6 L9,3 z" fill="#D7D7D7"></path>
            </marker>
        </defs>

        <g class="lines" stroke-width="1" pointer-events="all">
            <path *ngFor="let line of layout.lines" [attr.d]="line.path" marker-end="url(#arrow)"></path>
        </g>
        <g class="actions">
            <g
                *ngFor="let block of layout.blocks; trackBy: trackByActions"
                actionBlock
                [block]="block"
                [activeBlock]="activeBlock"
                [debuggedBlockName]="debuggedBlockName"
                [debugMode]="debugMode"
                (dropped)="dropped.emit($event)"
                (activeBlockChange)="onActiveBlockChange($event)"
                (breakpointToggle)="breakpointToggle.emit(block.name)"
                (editAction)="onEditBlock(block)"
            ></g>
        </g>
    </g>
</svg>
