import { Inject, Injectable } from '@angular/core';
import { ENV_TOKEN } from '@app/tokens';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SlackNotificationsService {
    private readonly BASE_URL = `${this.env.notificationsApiURL}/slack-notifications`;
    private readonly REDIRECT_URL = `${this.env.notificationsApiURL}/slack-notifications/redirect-to-profile`;
    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {}

    getClientId() {
        return this.http.get(`${this.BASE_URL}/client-id`, { responseType: 'text'});
    }

    getSlackReference(params: { code: string }) {
        return this.http.get(`${this.BASE_URL}/save-slack-reference`, { params: params });
    }

    getAuthorizationUrl(clientId: any) {
        return `https://slack.com/openid/connect/authorize?scope=openid&response_type=code&redirect_uri=${this.REDIRECT_URL}&client_id=${clientId}`;
    }

    getCredentials(): Observable<SlackCredentials> {
        return this.http.get<SlackCredentials>(`${this.BASE_URL}/credentials`);
    }

    saveCredentials(slackCredentials: SlackCredentials): Observable<void> {
        return this.http.post<void>(`${this.BASE_URL}/credentials`, slackCredentials);
    }

    enableSlackSettings(enable: boolean): Observable<void> {
        return this.http.post<void>(`${this.BASE_URL}/credentials/enable`, { enable });
    }
}

export interface SlackCredentials {
    clientId: string;
    clientSecret: string;
    token: string;
    enabled: boolean;
}
