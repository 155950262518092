import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ENV_TOKEN } from '@app/tokens';
import { FormControl } from '@angular/forms';
import { UserService } from '@app/services';
import { AuthenticatedUser } from '@app/models';

@Injectable({
    providedIn: 'root',
})
export class RecipientsEditorService {
    baseUrl = `${this.env.notificationsApiURL}`;

    lockedRecipientListUserData: AuthenticatedUser = null;
    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient, private userService: UserService) {}

    getApplicationRoles() {
        return this.http.get<any>(`${this.baseUrl}/alert-settings/recipients/app-roles`);
    }

    getBusinessRoles() {
        return this.http.get<any>(`${this.baseUrl}/alert-settings/recipients/business-roles`);
    }

    getPortfolios(search: string) {
        const params = search ? { search } : null;
        return this.http.get<any>(`${this.baseUrl}/alert-settings/recipients/portfolios`, {
            params,
        });
    }

    getApplications(body: any, search: string) {
        const params = search ? { search } : null;
        return this.http.post<any>(`${this.baseUrl}/alert-settings/recipients/applications`, body, {
            params,
        });
    }

    getTeams(body: any, search: string) {
        const params = search ? { search } : null;
        return this.http.post<any>(`${this.baseUrl}/alert-settings/recipients/teams`, body, {
            params,
        });
    }

    getUsers(body: any, search: string) {
        const params = search ? { search } : null;
        return this.http.post<any>(`${this.baseUrl}/alert-settings/recipients/users?limit=999`, body, {
            params,
        });
    }

    getDefaultFormGroup() {
        return {
            portfolios: new FormControl({}),
            applications: new FormControl({}),
            teams: new FormControl({}),
            portfoliosDropdown: new FormControl({ value: 'portfolios', disabled: false }),
            applicationsDropdown: new FormControl({ value: 'applications', disabled: true }),
            teamsDropdown: new FormControl({ value: 'teams', disabled: true }),
            roles: new FormControl({}),
            users: new FormControl({}),
        };
    }

    checkLockEditRecipientBlockByUserRole(recipients: any) {
        this.userService.getCurrentUser(true).subscribe(userData => {
            if (userData.authorities.filter(authority => authority === 'ROLE_ADMIN').length === 0) {
                this.lockedRecipientListUserData = userData;
                this.applyDefaultRecipientList(recipients);
            }
        });
    }

    applyDefaultRecipientList(recipients: any) {
        if (this.lockedRecipientListUserData) {
            recipients.portfolios.reset([]);
            recipients.applications.reset([]);
            recipients.teams.reset([]);
            recipients.roles.reset({});
            recipients.users.reset([
                {
                    id: this.lockedRecipientListUserData.id,
                    name: this.lockedRecipientListUserData.name,
                },
            ]);
        }
        return recipients;
    }



    findByIdOrDefault(dict: { name: string; id: number }[], id: number, defaultVal?: any) {
        const found = dict.filter(p => p.id === id);
        return found.length !== 0 ? found[0] : defaultVal;
    }

    toFormValues(values: { all: boolean; ids: { id: number; name: string }[] }, dict: { id: number; name: string }[]) {
        if (!values || values.all) {
            return [];
        }
        return values.ids.map(r => this.findByIdOrDefault(dict, r.id, null)).filter(a => a);
    }

}
