import { isDefined } from '@dagility-ui/kit';

export function removeOldField<T>(obj: T, field: keyof T) {
    if (!obj) {
        return;
    }

    delete obj[field];
}

export function moveOldFieldToLinesField<T>(obj: T, field: keyof T, getLinesKey = (lineField: string) => `${lineField}Lines`) {
    if (!obj) {
        return;
    }

    const value = obj[field];
    (obj as Record<string, unknown>)[getLinesKey(field as string)] = isDefined(value) ? ((value as unknown) as string).split('\n') : null;
}

export function moveLineFieldToOldField<T>(obj: T, field: keyof T, getLinesKey = (lineField: string) => `${lineField}Lines`) {
    if (!obj) {
        return;
    }
    const value = obj[field];
    const linesValue = (obj as Record<string, unknown>)[getLinesKey(field as string)] as string[];

    obj[field] = (isDefined(linesValue) && Array.isArray(linesValue) ? linesValue.join('\n') : value) as any;
}
