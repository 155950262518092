<div class="row position-relative">
    <div class="col not-found"></div>
    <div class="col d-flex flex-wrap justify-content-center align-content-center" style="flex-direction:column;">
        <div class="title2">Page Not Found</div>
        <p class="info1 mt-16px">
            The page you are looking for is either unavailable or has been removed. <br />
            This may be due to a technical error, or the page has been deleted.
        </p>
        <div class="d-flex mt-25px">
            <button class="btn btn-primary" (click)="handleGoBack()">
                <fa-icon class="font-16px mr-5px" icon="facArrowLeftRegular"></fa-icon>
                Go back
            </button>
            <button class="btn btn-secondary ml-3" (click)="handleRefresh()">
                <i class="fa fa-refresh mr-5px"></i>
                Try again
            </button>
        </div>
    </div>
</div>