import { Component, HostListener, OnInit } from '@angular/core';
import { QueryBuilderStore } from '@app/shared/components/query-builder/store/query-builder.store';
import { facExclamationCircle, facExclamationTriangle, facInfoCircle } from '@dagility-ui/kit';
import {
    QueryBuilderErrorParserService,
    ValidationError,
    ValidationErrorLevel,
} from '@app/shared/components/query-builder/services/query-builder-error-parser.service';

@Component({
    selector: 'app-qb-validation',
    templateUrl: './qb-validation.component.html',
    styleUrls: ['./qb-validation.component.scss'],
})
export class QbValidationComponent implements OnInit {
    ui: ErrorsUI;

    opened: boolean = false;

    activeError: string | any;

    constructor(private queryBuilderStore: QueryBuilderStore, private errorParser: QueryBuilderErrorParserService) {}

    ngOnInit(): void {
        this.getValidationErrors();
    }

    get issues() {
        return this.queryBuilderStore.validationErrors;
    }

    @HostListener('document:queryBuilderChanged', ['$event'])
    getValidationErrors = (): any => {
        const notice: Notice = this.getNotice(this.issues);
        const warning: Warning = this.getWarning(this.issues);
        const error: Error = this.getError(this.issues);
        let topError;
        if (error.count > 0) {
            topError = error;
        } else if (warning.count > 0) {
            topError = warning;
        } else if (notice.count > 0) {
            topError = notice;
        }
        if (!topError) {
            this.opened = false;
        }
        this.activeError = topError?.title;
        this.ui = {
            buttonText: 'Issues',
            topErrorLevel: topError?.type,
            topErrorIcon: topError?.icon,
            topErrorCount: topError?.count,
            windowTitle: 'Widget Configuration Issues',
            notice: notice,
            warning: warning,
            error: error,
        };
    };

    private getNotice(validationErrors: ValidationError[]): Notice {
        return {
            type: 'NOTICE',
            title: 'Notice',
            icon: facInfoCircle,
            count: validationErrors?.filter(e => e.level === 'NOTICE').length,
            items: validationErrors
                ?.filter(e => e.level === 'NOTICE')
                .map(e => ({
                    context: this.errorParser.parseErrorContext(e),
                    text: this.errorParser.parseErrorText(e),
                })),
        };
    }

    private getWarning(validationErrors: ValidationError[]): Warning {
        return {
            type: 'WARNING',
            title: 'Warning',
            icon: facExclamationTriangle,
            count: validationErrors?.filter(e => e.level === 'WARNING').length,
            items: validationErrors
                ?.filter(e => e.level === 'WARNING')
                .map(e => ({
                    context: this.errorParser.parseErrorContext(e),
                    text: this.errorParser.parseErrorText(e),
                })),
        };
    }

    private getError(validationErrors: ValidationError[]): Error {
        return {
            type: 'ERROR',
            title: 'Error',
            icon: facExclamationCircle,
            count: validationErrors?.filter(e => e.level === 'ERROR').length,
            items: validationErrors
                ?.filter(e => e.level === 'ERROR')
                .map(e => ({
                    context: this.errorParser.parseErrorContext(e),
                    text: this.errorParser.parseErrorText(e),
                })),
        };
    }
}

export interface ErrorsUI {
    readonly buttonText: string;
    readonly windowTitle: string;
    topErrorLevel: ValidationErrorLevel;
    topErrorIcon: CustomIcon;
    topErrorCount: number;
    notice: Notice;
    warning: Warning;
    error: Error;
}

export interface Item {
    context: string;
    text: string;
}

export interface ErrorLevel {
    readonly title: string;
    readonly type: ValidationErrorLevel;
    readonly icon: CustomIcon;
    count: number;
    items: Item[];
}

export interface Notice extends ErrorLevel {
    readonly title: 'Notice';
    readonly type: 'NOTICE';
}

export interface Warning extends ErrorLevel {
    readonly title: 'Warning';
    readonly type: 'WARNING';
}

export interface Error extends ErrorLevel {
    readonly title: 'Error';
    readonly type: 'ERROR';
}
