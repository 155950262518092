<div
    *ngIf="!isLoading; else loadingTmpl"
    class="score-widget card-body d-flex flex-column"
    [ngClass]="{
      'health-score': isHealth,
      'bg-color-success': isHealth && data.grade === 'a',
      'bg-color-warning': isHealth && data.grade === 'b',
      'bg-color-failure': isHealth && data.grade === 'c'
    }"
>
    <div class="row no-gutters">
        <span class="score-title-text">{{ data.name }}</span>
    </div>

    <ng-container *ngIf="data.score || data.score === 0">
        <div class="d-inline-flex mt-1">
            <div class="col-8">
                <span class="score-value-text">{{ data.score === 'N/A' ? 'N/A' : data.score | number: '1.1'}}</span>
                <span class="score-title-text ml-2">{{ data.score === 'N/A' ? '' : '/' + data.outOf }}</span>
            </div>
            <div class="col-4 ml-2">
                <div class="score-value-text" [ngClass]="{
                'color-success': !isHealth && data.grade === 'a',
                'color-warning': !isHealth && data.grade === 'b',
                'color-failure': !isHealth && data.grade === 'c'
            }">{{ data.grade ? data.grade.toLocaleUpperCase() : '' }}</div>
            </div>
        </div>
    </ng-container>
</div>
<hr *ngIf="data && data.active" class="line-base">
<div *ngIf="data.score || data.score === 0" class="status-bar">
    <div
        *ngFor="let i of statusBars"
        class="status-bar-value"
        id="{{ i }}"
        [class.status-bar-failed]="(i <= 4 && i <= statusBarValue)"
        [class.status-bar-warning]="(i > 4 && i < 9 && i <= statusBarValue) || i === 5 && data.grade === 'b'"
        [class.status-bar-success]="(i >= 9 && i <= 11 && i <= statusBarValue) && data.grade === 'a'"
        [class.status-bar-none]="i > statusBarValue && i <= 12"
    ></div>
</div>
<ng-template #loadingTmpl>
    <lib-spinner class="spinner"></lib-spinner>
</ng-template>
