import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'dp-log-template',
    templateUrl: './log-template.component.html',
    styleUrls: ['./log-template.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'chart',
    },
})
export class LogTemplateComponent {
    @Input() logs$: Observable<any>;

    @Input() header: string;

    @Input() scrollToBottom = false;
}
