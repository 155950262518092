import {
    AnyWidgetModel,
    ChartOptions,
    WidgetAccordionColumn,
    WidgetAdditionalTemplate,
    WidgetDrilldown,
    WidgetEventHandler,
    WidgetFilter,
    WidgetHelp,
    WidgetQuery,
    WidgetTableColumn,
} from 'data-processor';
import { moveLineFieldToOldField, moveOldFieldToLinesField, removeOldField } from 'data-processor/lib/common';

import { isDynamicFilter } from './widget-builder.util';

export const LINES_FIELDS = {
    CHART_OPTIONS: Array.from<keyof ChartOptions>(['tooltipFormatter', 'labelFormatter']),
    QUERY: Array.from<keyof WidgetQuery>(['script', 'query', 'beforeScript']),
    FILTER: Array.from<keyof WidgetFilter>(['defaultValue']),
    TABLE_COLUMN: Array.from<keyof WidgetTableColumn>([
        'headerTooltipTemplate',
        'cellTemplate',
        'progressTemplate',
        'progressTooltip',
        'cellTooltipTemplate',
    ]),
    DRILLDOWN: Array.from<keyof WidgetDrilldown>(['drilldownScript', 'matcherScript']),
    ADDITIONAL_TEMPLATE: Array.from<keyof WidgetAdditionalTemplate>(['template']),
    EVENT_HANDLER: Array.from<keyof WidgetEventHandler>(['script']),
    ACCORDION_COLUMN: Array.from<keyof WidgetAccordionColumn>(['clickEventFunction']),
    HELP: Array.from<keyof WidgetHelp>(['summary', 'description', 'howScoreCalculated', 'howScoreCanImproved']),
};

export function transformFilters(filters: WidgetFilter[], transformer: Function) {
    for (const filter of filters ?? []) {
        for (const field of LINES_FIELDS.FILTER) {
            transformer(filter, field);
        }

        if (isDynamicFilter(filter)) {
            for (const field of LINES_FIELDS.QUERY) {
                transformer(filter.query, field);
            }
        }
    }
}

function transformWidget(widget: AnyWidgetModel, transformer: <T extends Record<string, unknown>>(obj: T, field: keyof T) => void) {
    for (const field of LINES_FIELDS.CHART_OPTIONS) {
        transformer(widget.chartOptions, field);
    }

    for (const query of (widget.query ?? []).concat(widget.asyncQueries ?? [])) {
        for (const field of LINES_FIELDS.QUERY) {
            transformer(query, field);
        }
    }

    transformFilters(widget.filters ?? [], transformer);

    for (const field of LINES_FIELDS.HELP) {
        transformer(widget.help, field);
    }

    for (const column of widget.columns ?? []) {
        for (const field of LINES_FIELDS.TABLE_COLUMN) {
            transformer(column, field);
        }
    }

    for (const template of widget.additionalTemplates ?? []) {
        for (const field of LINES_FIELDS.ADDITIONAL_TEMPLATE) {
            transformer(template, field);
        }
    }

    for (const handler of widget.handlers ?? []) {
        for (const field of LINES_FIELDS.EVENT_HANDLER) {
            transformer(handler, field);
        }
    }

    for (const accordionColumn of widget.accordionColumns ?? []) {
        for (const field of LINES_FIELDS.ACCORDION_COLUMN ?? []) {
            transformer(accordionColumn, field);
        }
    }

    for (const subWidget of widget.widgets ?? []) {
        transformWidget(subWidget, transformer);
    }

    for (const drilldown of widget.drilldownList ?? []) {
        for (const field of LINES_FIELDS.DRILLDOWN) {
            transformer(drilldown, field);
        }

        transformWidget(drilldown.widget, transformer);
    }
}

export function removeOldFields(widget: AnyWidgetModel) {
    return transformWidget(widget, removeOldField);
}

export function moveLineFieldsToOldFields(widget: AnyWidgetModel) {
    return transformWidget(widget, moveLineFieldToOldField);
}

export function moveOldFieldsToLinesFields(widget: AnyWidgetModel) {
    return transformWidget(widget, moveOldFieldToLinesField);
}
