export interface ToolList {
    id?: number;
    jobSetId?: number;
    toolId?: any;
    name?: string;
    description?: string;
    enabled?: boolean;
    sequence?: number;
    defaultSchedulerTime?: number;
    componentCollection?: string;
    subCollection?: string;
    baseUrl?: string;
    imageUrl?: string;
    verificationPath?: string;
    authHeaderName?: string;
    pluginType?: string;
    pluginCategories?: string[];
    authType?: string;
}

export interface Organization {
    id?: number;
    name?: string;
    imagePath?: string;
    imageBackgroundColor?: string;
    enabled?: boolean;
    modifiedBy?: string;
    modifiedDate?: number;
    createdBy?: string;
    createdDate?: number;
    adGroup?: string;
}

export interface RuleMenu {
    name: string;
    active: boolean;
    completed: boolean;
}

export interface Mask {
    type?: string;
    filter?: string;
    value?: string;
}

export interface PolicyRule {
    id?: number;
    toolId?: number;
    updated?: boolean;
    project_mask?: Mask[];
    unit_mask?: Mask[];
    idForTool?: string;
    componentCollectionName?: string;
    unitCollectionName?: string;
    componentCollection?: string;
    subCollection?: string;
}

export interface SelectedPolicy {
    id?: number;
    organizationId?: number;
    name?: string;
    description?: string;
    type?: string;
    policyRules?: PolicyRule[];
}

export class AddSelectedPolicy implements SelectedPolicy {
    id?: number;
    organizationId?: number;
    name?: string;
    description?: string;
    type?: string;
    policyRules?: PolicyRule[];

    constructor(selectedPolicy: SelectedPolicy = {}) {
        this.id = selectedPolicy.id ? selectedPolicy.id : null;
        this.organizationId = selectedPolicy.organizationId ? selectedPolicy.organizationId : null;
        this.name = selectedPolicy.name ? selectedPolicy.name : '';
        this.description = selectedPolicy.description ? selectedPolicy.description : '';
        this.type = selectedPolicy.type ? selectedPolicy.type : '';
        this.policyRules = (selectedPolicy.policyRules || [{}]).map(obj => {
            return new AddPolicyRule(obj);
        });
    }
}

export class AddPolicyRule implements PolicyRule {
    id?: number;
    toolId?: number;
    updated?: boolean;
    project_mask?: Mask[];
    unit_mask?: Mask[];
    idForTool?: string;
    componentCollectionName?: string;
    unitCollectionName?: string;

    constructor(policyRule: ToolList) {
        this.id = null;
        this.updated = false;
        this.toolId = policyRule.id ? policyRule.id : null;
        this.idForTool = policyRule.toolId ? policyRule.toolId.toString() : '';
        this.updated = false;
        if (policyRule.componentCollection) {
            this.componentCollectionName = policyRule.componentCollection;
            this.project_mask = [];
        }
        if (policyRule.subCollection) {
            this.unitCollectionName = policyRule.subCollection;
            this.unit_mask = [];
        }
    }
}

export class AddMask implements Mask {
    type: string;
    filter?: string;
    value: string;

    constructor(mask: Mask) {
        this.type = mask.value ? mask.type : '';
        this.filter = mask.filter ? mask.filter : '';
        this.value = mask.value ? mask.value : '';
    }
}

/**
 * Base set of secured actions.
 * You can use any string identifier as a secured action without even adding it to this list.
 */
export type OrganizationRbacAction =
    | 'editOrganizationPermissions'
    | 'readDefaultPolicy'
    | 'readOrganization'
    | 'importExportPolicy'
    | 'modifyDefaultPolicy'
    | 'readMasterPolicy'
    | 'listAllOrganizations'
    | 'changeAssignedPolicy'
    | 'modifyOrganization'
    | 'modifyUserPolicy'
    | 'readUserPolicy'
    | 'listUsersAcrossOrganizations'
    | 'modifyMasterPolicy'
    | 'readAssignedPolicy'
    | string;
