import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { Observable, Subject } from 'rxjs';

import { tap } from 'rxjs/operators';
import { ToasterService } from '@dagility-ui/shared-components';

@Component({
    selector: 'app-generated-script',
    templateUrl: './generated-script.component.html',
    styleUrls: ['./generated-script.component.scss'],
})
export class GeneratedScriptComponent implements OnInit {
    @ViewChild(PerfectScrollbarComponent) scrollbar: PerfectScrollbarComponent;
    @Input() height: number;
    @Input() jenkinsFile$: Observable<any>;
    @Input() downloadFileName: string = 'script';
    @Input() codeMirror: boolean = false;
    @Input() refresh$ = new Subject();

    constructor(private toaster: ToasterService) {}

    ngOnInit() {
        this.jenkinsFile$ = this.jenkinsFile$.pipe(
            tap(() => {
                setTimeout(() => {
                    if (this.scrollbar) {
                        this.scrollbar.directiveRef.scrollToBottom(0);
                    }
                });
            })
        );
    }

    copyToClipboard(): void  {
        const generatedScript  = this.scrollbar.directiveRef.elementRef.nativeElement.innerText;
        const selBox = document.createElement('textarea');
        selBox.style.position = 'absolute';
        selBox.style.left = '-9999px';
        selBox.value = generatedScript;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.toaster.successToast({title: 'Success', content: 'Copied to clipboard'});
    }

    downloadFile(): void {
        const generatedScript  = this.scrollbar.directiveRef.elementRef.nativeElement.innerText;
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(generatedScript));
        element.setAttribute('download', this.downloadFileName);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        this.toaster.successToast({title: 'Success', content: 'Successfully downloaded'});
    }
}
