import {Injectable} from '@angular/core';

export type ValidationErrorLevel = 'NOTICE' | 'WARNING' | 'ERROR' | any;

export type ValidationErrorContext = 'USER' | 'DEV' | any;

export type ValidationErrorCode =
    'FILTER_REQUIRED' |
    'AXIS_NOT_CHOSEN' |
    'DUPLICATE_DATASET_DATA_FIELD_NAME' |
    'DUPLICATE_DATASET_FIELD_NAME' |
    'DUPLICATE_MODEL_UID' |
    'DUPLICATE_MODEL_NAME' |
    'DUPLICATE_MODEL_TYPE' |
    'DUPLICATE_MODEL_DATA' |
    'DUPLICATE_MODEL_FIELD_NAME' |
    'DUPLICATE_MODEL_FIELD_DATA' |
    'MODEL_DB_SCRIPT_PARAMETER_REQUIRED' |
    'MODEL_GROOVY_SCRIPT_PARAMETER_NOT_USED' |
    'MODEL_GROOVY_SCRIPT_PARAMETER_NOT_FOUND' |
    'DUPLICATE_MODEL_DB_SCRIPT_DATA' |
    'DUPLICATE_MODEL_REQUIREMENTS_UID' |
    'DUPLICATE_MODEL_REQUIREMENTS_VALUE_VAR' |
    'EMPTY_GRAPH' |
    'MODEL_NOT_REACHABLE' |
    any;

export interface ValidationError {
    level: ValidationErrorLevel;
    context: ValidationErrorContext;
    code: ValidationErrorCode;
    details: ValidationErrorDetails;
}

export interface ValidationErrorDetails {
    UID?: string;
    NAME?: string;
    TYPE?: string;
    MODEL_UID?: string;
    FILTER_UID?: string;
    MODEL_NAME?: string;
    MODEL_TYPE?: string;
    MODEL_DATA?: string;
    FILTER_NAME?: string;
    FILTER_TYPE?: string;
    FILTER_DATA?: string;
    DATA_FIELD?: string;
    FIELD_NAME?: string;
    VALUE_VAR?: string;
    PARAMETER?: string;
    SCRIPT_DATA?: string;
    GRAPH_PATH?: string;
}

@Injectable({
    providedIn: 'root'
})
export class QueryBuilderErrorParserService {

    parseErrorContext(error: ValidationError): string {
        switch (error.context) {
            case 'USER':
                return 'Widget Config';
            case 'DEV':
                return 'Script Config';
            default:
                return 'Other';
        }
    }

    parseModelPath(error: ValidationError): string {
        return error.details.GRAPH_PATH ? ` [Model path: ${error.details.GRAPH_PATH}]` : '';
    }

    parseErrorText(error: ValidationError): string {
        return this.parseMainErrorMsg(error) + this.parseModelPath(error);
    }

    parseMainErrorMsg(error: ValidationError): string {
        switch (error.code) {
            case 'FILTER_REQUIRED':
                return `Filter '${error.details.FILTER_NAME}' is required for model (${error.details.MODEL_NAME})`;
            case 'AXIS_NOT_CHOSEN':
                return `Please map a field to ${error.details.NAME}`;
            case 'DUPLICATE_DATASET_DATA_FIELD_NAME':
                return `Data Set has data fields with the same internal name (${error.details.DATA_FIELD})`;
            case 'DUPLICATE_DATASET_FIELD_NAME':
                return `Data Set has two or more fields with the same name (${error.details.FIELD_NAME})`;
            case 'DUPLICATE_MODEL_UID':
                return error.details.MODEL_UID
                    ? `There are two models with the same unique ID (${error.details.MODEL_UID})`
                    : `There are two filters with the same unique ID (${error.details.FILTER_UID})`;
            case 'DUPLICATE_MODEL_NAME':
                return error.details.MODEL_NAME
                    ? `There are two models with the same model name (${error.details.MODEL_NAME})`
                    : `There are two filters with the same filter name (${error.details.FILTER_NAME})`;
            case 'DUPLICATE_MODEL_TYPE':
                return error.details.MODEL_TYPE
                    ? `There are two models with the same model type (${error.details.MODEL_TYPE})`
                    : `There are two filters with the same filter type (${error.details.FILTER_TYPE})`;
            case 'DUPLICATE_MODEL_DATA':
                return error.details.MODEL_DATA
                    ? `There are two models with the same internal name (${error.details.MODEL_DATA}) in model (${error.details.MODEL_NAME})`
                    : `There are two filters with the same internal name (${error.details.FILTER_DATA}) in filter (${error.details.FILTER_NAME})`;
            case 'DUPLICATE_MODEL_FIELD_NAME':
                return error.details.MODEL_NAME
                    ? `There are duplicate fields with name (${error.details.FIELD_NAME}) in model (${error.details.MODEL_NAME})`
                    : `There are duplicate fields with name (${error.details.FIELD_NAME}) in filter (${error.details.FILTER_NAME})`;
            case 'DUPLICATE_MODEL_FIELD_DATA':
                return error.details.MODEL_NAME
                    ? `There are duplicate fields with the same internal name (${error.details.DATA_FIELD}) in model (${error.details.MODEL_NAME})`
                    : `There are duplicate fields with the same internal name (${error.details.DATA_FIELD}) in filter (${error.details.FILTER_NAME})`;
            case 'MODEL_DB_SCRIPT_PARAMETER_REQUIRED':
                return error.details.MODEL_NAME
                    ? `Data Base script parameter (${error.details.PARAMETER}) is required for model (${error.details.MODEL_NAME})`
                    : `Data Base script parameter (${error.details.PARAMETER}) is required for filter (${error.details.FILTER_NAME})`;
            case 'MODEL_GROOVY_SCRIPT_PARAMETER_NOT_USED':
                return error.details.MODEL_NAME
                    ? `Groovy script parameter (${error.details.PARAMETER}) is declared but isn't used in model (${error.details.MODEL_NAME})`
                    : `Groovy script parameter (${error.details.PARAMETER}) is declared but isn't used in filter (${error.details.FILTER_NAME})`;
            case 'MODEL_GROOVY_SCRIPT_PARAMETER_NOT_FOUND':
                return error.details.MODEL_NAME
                    ? `Groovy script parameter (${error.details.PARAMETER}) is used but isn't declared in model (${error.details.MODEL_NAME})`
                    : `Groovy script parameter (${error.details.PARAMETER}) is used but isn't declared in filter (${error.details.FILTER_NAME})`;
            case 'DUPLICATE_MODEL_DB_SCRIPT_DATA':
                return `Duplicate Model variable found in model (${error.details.MODEL_NAME})`;
            case 'DUPLICATE_MODEL_REQUIREMENTS_UID':
                return `Duplicate Model Requirement ID (${error.details.UID}) found in model (${error.details.MODEL_NAME})`;
            case 'DUPLICATE_MODEL_REQUIREMENTS_VALUE_VAR':
                return `Duplicate Model Requirement variable (${error.details.VALUE_VAR}) found in model (${error.details.MODEL_NAME})`;
            case 'EMPTY_GRAPH':
                return `Dataset has no models defined. Please add at least one data model to it.`;
            case 'MODEL_NOT_REACHABLE':
                return `Model '${error.details.MODEL_NAME}' is not reachable from root. Please add a relation that connects it to the root or to the other model reachable from root.`;
            default:
                return error.code;
        }
    }
}
