<div class="plugin-content">
    <div class="control-container pt-20px pb-30px pl-3">
        <div class="col text pl-0">
            <span class="context-help-label" context-help-id="configured-tools"
                >Choose a supported tool integration and configure tool instances as data sources.</span
            >
        </div>
        <lib-search class="search" (searchText)="searchData($event)"></lib-search>
    </div>
    <perfect-scrollbar [style.maxHeight]="'calc(100vh - 300px)'" #accToolsWrapperER>
        <ngb-accordion
            #accTools="ngbAccordion"
            [closeOthers]="!search"
            [destroyOnHide]="true"
            [animation]="false"
            class="plugin-listing pl-3"
            (panelChange)="handleToolsPanelChange($event)"
            inttouranchor="itemsAccordion"
            [tourAnchorContext]="{ selected: selected$ }"
        >
            <ng-container *ngFor="let tools of data; let i = index">
                <ngb-panel id="{{ tools.id }}">
                    <ng-template ngbPanelHeader>
                        <button [ngbPanelToggle] class="btn btn-link w-100 d-inline-flex align-items-center px-0 py-12px">
                            <div class="col-4 p-0 overme">
                                {{ tools.category }}
                            </div>
                        </button>
                        <dp-configure-integration
                            class="configure-integration"
                            [disabled]="!toolTemplatesLoaded"
                            [items]="getToolTemplatesByCategory(tools.category)"
                            [selected$]="selected$"
                            (update)="getData()"
                            (runToolWizard)="runToolWizard.emit($event)"
                        >
                        </dp-configure-integration>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <perfect-scrollbar [perfectScrollbar]="perfectScrollBarConfig" class="panel-content d-flex flex-column pr-15px">
                            <div class="row no-gutters scrollable-div d-flex flex-column">
                                <div *ngIf="toolsLoaded && (!tools || !tools.plugins || !tools.plugins.length)" class="col">
                                    No configured tools
                                </div>
                                <ngb-accordion
                                    *ngIf="toolsLoaded; else loader"
                                    #accPlugins="ngbAccordion"
                                    [closeOthers]="true"
                                    class="w-100"
                                    (panelChange)="handlePluginPanelChange($event, 'tool' + i)"
                                >
                                    <ngb-panel
                                        *ngFor="let plugins of tools.plugins; let j = index"
                                        [disabled]="!showProjects"
                                        id="tool-plugin-{{ i }}-{{ j }}"
                                    >
                                        <ng-template ngbPanelHeader let-opened="opened">
                                            <div
                                                class="row no-gutters d-inline-flex flex-nowrap justify-content-between w-100 py-5px pr-10px border-bottom"
                                                [class.header-border]="opened"
                                            >
                                                <div class="flex-1 overme d-inline-flex p-0">
                                                    <button
                                                        ngbPanelToggle
                                                        class="btn btn-link w-100 d-inline-flex align-items-center p-0"
                                                        [class.no-expanded]="!showProjects"
                                                        (click)="handlePluginToggle(plugins, opened)"
                                                    >
                                                        <div
                                                            class="d-flex justify-content-center align-items-center mr-10px"
                                                            style="width: 25px; height: 30px"
                                                        >
                                                            <!-- tool loop logo -->
                                                            <img
                                                                *ngIf="toolsLoaded; else loader"
                                                                [src]="plugins.imageUrl | imagePath"
                                                                alt=""
                                                                class="tool-logo"
                                                            />
                                                        </div>
                                                        <div class="overme">
                                                            <!-- /tool loop logo -->
                                                            <span [ngClass]="plugins.enabled ? 'font-active' : 'font-inactive'">
                                                                {{ plugins.name }}
                                                            </span>
                                                            <fa-icon
                                                                [class.d-none]="plugins.enabled"
                                                                [icon]="icons.faExclamationTriangle"
                                                                class="icon-exclamation ml-10px"
                                                            >
                                                            </fa-icon>
                                                        </div>
                                                    </button>
                                                </div>
                                                <div
                                                    class="min-width-160px pull-right d-inline-flex justify-content-end align-items-center p-0"
                                                >
                                                    <!-- tool loop options -->
                                                    <div class="tool-options d-flex justify-content-end">
                                                        <fa-icon
                                                            (click)="configureAccess(plugins)"
                                                            ngbTooltip="Configure Access"
                                                            tooltipClass="tool-configuration-item-tooltip"
                                                            container="body"
                                                            class="cursor-pointer icon-regular fa font-16px mr-15px"
                                                            icon="facEdit"
                                                        >
                                                        </fa-icon>
                                                        <fa-icon
                                                            [icon]="icons.facTrash"
                                                            class="cursor-pointer icon-regular fa font-16px mr-15px"
                                                            (click)="deletePlugin(plugins.toolId, plugins.name)"
                                                            ngbTooltip="Delete"
                                                            tooltipClass="tool-configuration-item-tooltip"
                                                            container="body"
                                                        >
                                                        </fa-icon>
                                                        <trigger
                                                            [value]="plugins.enabled"
                                                            (click)="togglePluginActive($event, plugins)"
                                                            [followValue]="true"
                                                            style="height: 16px"
                                                        >
                                                        </trigger>
                                                        <div class="d-inline-flex justify-content-center pl-10px" style="width: 50px">
                                                            <div>
                                                                <span *ngIf="!plugins.toggleLoading; else inlineLoader">Active</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- /tool loop options -->
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <ng-container *ngIf="plugins.prjectsLoaded; else loader">
                                                <ng-container *ngIf="plugins.projects?.length > 0; else noItems">
                                                    <div class="col p-0">
                                                        <ng-container *ngFor="let projects of plugins.projects; even as isEven" class="">
                                                            <div
                                                                class="row no-gutters d-inline-flex flex-nowrap justify-content-between w-100 pt-10px pb-10px pr-10px content-item"
                                                                [class.dark]="isEven"
                                                                *ngIf="projects.name"
                                                            >
                                                                <div
                                                                    class="flex-1 overme p-0"
                                                                    [ngClass]="projects.enabled ? 'font-active' : 'font-inactive'"
                                                                >
                                                                    {{ projects.name }}
                                                                </div>
                                                                <div
                                                                    class="min-width-100px pull-right d-flex justify-content-end align-items-center p-0"
                                                                >
                                                                    <trigger
                                                                        [value]="projects.enabled"
                                                                        (click)="toggleProjectActive(projects, !projects.enabled)"
                                                                        [followValue]="true"
                                                                        style="height: 16px"
                                                                    >
                                                                    </trigger>
                                                                    <div
                                                                        class="d-inline-flex justify-content-center pl-10px"
                                                                        style="width: 50px"
                                                                    >
                                                                        <div>
                                                                            <span *ngIf="!projects.toggleLoading; else loader">Active</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                        </ng-template>
                                    </ngb-panel>
                                </ngb-accordion>
                            </div>
                        </perfect-scrollbar>
                    </ng-template>
                </ngb-panel>
            </ng-container>
        </ngb-accordion>
    </perfect-scrollbar>
</div>

<ng-template #loader>
    <div class="pl-20px pt-10px pb-10px">
        <div class="loader"></div>
    </div>
</ng-template>

<ng-template #inlineLoader>
    <div class="pl-20px">
        <div class="loader"></div>
    </div>
</ng-template>

<ng-template #noItems>
    <div class="pl-20px pt-10px pb-10px">
        No configured projects
    </div>
</ng-template>
