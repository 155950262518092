import {
    AfterContentInit,
    Component,
    ContentChild,
    HostListener,
    Inject,
    Input,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';

import { StepOption, TourService, TourState, TourStepService } from './tour.service';
import { HelpSupportService } from 'projects/shared-components/utils/help-support/help-support.service';
import { TourLibApiService } from './tour-lib-api.service';
import { EnvironmentModel } from '@dagility-ui/kit';

@Component({
    selector: 'app-tour-step-template',
    templateUrl: './tour.component.html',
    styleUrls: ['./tour.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TourStepComponent implements AfterContentInit {
    @ViewChild('tourStep', { read: TemplateRef, static: true })
    defaultTourStepTemplate: TemplateRef<any>;

    @Input()
    stepTemplate: TemplateRef<{ step: StepOption }>;

    @ContentChild(TemplateRef)
    stepTemplateContent: TemplateRef<{ step: StepOption }>;

    /**
     * Configures hot keys for controlling the tour with the keyboard
     */
    @HostListener('window:keydown.Escape')
    onEscapeKey(): void {
        // if (this.tourService.getStatus() === TourState.ON && this.tourService.isHotkeysEnabled()) {
        //     this.skipTour();
        // }
    }

    @HostListener('window:keydown.ArrowRight')
    onArrowRightKey(): void {
        if (
            this.tourService.getStatus() === TourState.ON &&
            this.tourService.hasNext(this.tourService.currentStep) &&
            this.tourService.isHotkeysEnabled()
        ) {
            this.tourService.next();
        }
    }

    @HostListener('window:keydown.ArrowLeft')
    onArrowLeftKey(): void {
        if (
            this.tourService.getStatus() === TourState.ON &&
            this.tourService.hasPrev(this.tourService.currentStep) &&
            this.tourService.isHotkeysEnabled()
        ) {
            this.tourService.prev();
        }
    }

    constructor(
        @Inject('environment') private env: EnvironmentModel,
        private tourStepService: TourStepService,
        public tourService: TourService,
        public helpSupportService: HelpSupportService,
        private tourApiService: TourLibApiService,
        private router: Router) {}

    ngAfterContentInit(): void {
        this.tourStepService.template = this.stepTemplate || this.stepTemplateContent || this.defaultTourStepTemplate;
    }

    handleContentClick(event: any) {
        const serviceUrl = event.target.host === 'localhost:4200' ? this.env.serviceUrl : event.target.host;
        if (this.env.serviceUrl.includes(serviceUrl) && event.target.tagName === 'A') {
            const hash = event.target.hash;
            if (hash !== '') {
                const key = decodeURIComponent(hash.slice(1));
                event.preventDefault();
                const filteredData = this.helpSupportService.getFilteredData(key);
                if (filteredData && filteredData.length) {
                    window.open(`#/help-support/${filteredData[0].id}`, '_blank');
                } else {
                    event.target.parentNode.removeChild(event.target);
                }
            }
        }
    }

    skipTour() {
        this.tourApiService.skipTour({ tourId: this.tourService.getTourId(), skippedStepId: this.tourService.currentStep.stepId }).subscribe(() => {
            this.tourService.end();
        });
    }

    endTour() {
        this.tourApiService.skipTour({ tourId: this.tourService.getTourId(), skippedStepId: 0 }).subscribe(() => {
            this.tourService.end();
        });
    }
}
