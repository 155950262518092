import { FormBuilder, FormGroup } from '@angular/forms';

import { ConditionWorkflowAction, ForkAction, LoopAction, WorkflowAction } from '../models/actions';
import { actionsFactory, calculateMaxBranchLength, generateMainActionsBranch } from './utils';
import { createFormGroup } from '../utils';
import { DropdownItem, generateUUID } from '@dagility-ui/kit';

export class WorkflowActionBlock<T extends WorkflowAction = WorkflowAction> {
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    _parent: WorkflowActionBlock;

    formGroup: FormGroup;

    get parent() {
        return this._parent;
    }

    set parent(v: any) {
        this._parent = v;
        this.debugging = this.canDebug();
    }

    x = 0;
    y = 0;

    end = {
        x: 0,
        y: 0,
    };

    height = 28;
    width = 148;

    breakpoint = false;
    debugging = true;
    isContainer = false;
    isStartBlock = false;
    isEndBlock = false;

    order = 0;
    level = 0;

    marginBottom = 0;
    countInLine = 0;

    get invalid() {
        return this.formGroup ? this.formGroup.invalid : false;
    }

    get next() {
        return this.action.next;
    }

    set next(v: string) {
        this.action.next = v;
    }

    get name() {
        return this.action.name;
    }

    get type() {
        return this.action.type;
    }

    constructor(public action: WorkflowAction) {
        this.isStartBlock = this instanceof StartBlock;
        this.isEndBlock = this instanceof EndBlock;

        this.debugging = this.canDebug();
        this.isContainer = this.isContainerBlock();
    }

    getCastedAction() {
        return <T>this.action;
    }

    public isForkBlock(): this is ForkWorkflowActionBlock {
        return this instanceof ForkWorkflowActionBlock;
    }

    public isConditionBlock(): this is ConditionWorkflowActionBlock {
        return this instanceof ConditionWorkflowActionBlock;
    }

    public isLoopBlock(): this is LoopWorkflowActionBlock {
        return this instanceof LoopWorkflowActionBlock;
    }

    public isFirstBlock(action: WorkflowAction) {
        return (this.isForkBlock() && this.isFirstInForkBranch(action)) || (this.isLoopBlock() && this.isFirstInLoop(action));
    }

    public middleCoords() {
        const x = Math.round((this.end.x - this.x) / 2 + this.x);
        const y = Math.round((this.end.y - this.y) / 2 + this.y);

        return { x, y };
    }

    public isContainerBlock(): this is ContainerBlock {
        return this.isForkBlock() || this.isLoopBlock();
    }

    public hasParent() {
        return !!this.parent;
    }

    public hasNext() {
        return !!this.next;
    }

    public canDebug() {
        if (this.isContainerBlock()) {
            return false;
        }

        const parents = this.findTopForkParent();

        if (!parents) {
            return true;
        }

        const [forkParent, parentInForkBranch] = parents;
        const indexInForkBranch = forkParent.getIndexForkBranch(parentInForkBranch.action);

        return indexInForkBranch === 0;
    }

    public findContainerParent(): WorkflowActionBlock {
        let currParent = this.parent;

        while (currParent) {
            if (currParent.isContainerBlock()) {
                return currParent;
            }

            currParent = currParent.parent;
        }

        return currParent;
    }

    public getContainerActions(): WorkflowAction[] | null {
        const parent = this.findContainerParent();

        if (!parent) {
            return null;
        }

        if (parent.isLoopBlock()) {
            return parent.getCastedAction().actions;
        } else if (parent.isForkBlock()) {
            const indexInForkBranch = parent.getIndexForkBranch(this.action);

            return parent.getCastedAction().actions[indexInForkBranch];
        }
    }

    public findTopForkParent(): [ForkWorkflowActionBlock, ForkWorkflowActionBlock] | null {
        const forks = [];
        let currParent = this.parent;
        let currBlock = this;

        while (currParent) {
            if (currParent.isForkBlock()) {
                forks.push([currParent, currBlock]);
            }

            currBlock = currParent;
            currParent = currParent.parent;
        }

        return forks.length ? forks[forks.length - 1] : (null as any);
    }

    getMaxChildrenCount() {
        return calculateMaxBranchLength(this.action);
    }

    initForm(fb: FormBuilder) {
        const constructor = actionsFactory[this.action.type];
        if (constructor) {
            const typingObject = new constructor(this.action);
            this.formGroup = createFormGroup(fb, typingObject, typingObject, true);
        }
    }

    getMainBranch(orFrom: WorkflowAction[]) {
        const actions = this.getContainerActions();

        return generateMainActionsBranch(actions || orFrom);
    }

    getNextBlockNames(actions: WorkflowAction[]): DropdownItem[] {
        const containerActions = this.getContainerActions();

        return (containerActions === null ? actions : containerActions).reduce<DropdownItem[]>((acc, { name }) => {
            if (name !== this.name) {
                acc.push({
                    label: name,
                    value: name,
                });
            }

            return acc;
        }, []);
    }
}

export class CircleBlock extends WorkflowActionBlock<never> {
    radius = 14;
    width = 2 * this.radius;
    height = 2 * this.radius;
    id: string;

    get center() {
        return {
            x: this.x + this.radius,
            y: this.y + this.radius,
        };
    }

    constructor() {
        super(undefined);
        this.id = generateUUID();
    }
}

export class StartBlock extends CircleBlock {
    get name() {
        return `start ${this.id}`;
    }

    get type() {
        return 'start' as any;
    }
}

export class EndBlock extends CircleBlock {
    get name() {
        return `end ${this.id}`;
    }

    get type() {
        return 'end' as any;
    }
}

export interface ContainerBlock {
    push(action: WorkflowAction): void;

    addAfter(name: string, action: WorkflowAction, skipNextSet?: boolean): void;

    delete(block: WorkflowActionBlock): void;
}

export class ConditionWorkflowActionBlock extends WorkflowActionBlock<ConditionWorkflowAction> {
    public addToElseBranch(item: WorkflowAction) {
        this.getCastedAction().elseNext = item.name;
    }
}

export class LoopWorkflowActionBlock extends WorkflowActionBlock<LoopAction> implements ContainerBlock {
    marginBottom = 20;

    public isFirstInLoop(action: WorkflowAction) {
        return (this.getCastedAction().actions || []).findIndex(({ name }) => name === action.name) === 0;
    }

    public push(action: WorkflowAction) {
        const last = this.findLast();

        if (last) {
            last.next = action.name;
        }

        this.getCastedAction().actions.push(action);
    }

    public addAfter(name: string, action: WorkflowAction, skipNextSet?: boolean) {
        if (!skipNextSet) {
            const prevAction = this.getCastedAction().actions.find(a => a.name === name);

            if (prevAction.next) {
                action.next = prevAction.next;
            }
            prevAction.next = action.name;
        }

        this.getCastedAction().actions.push(action);
    }

    private findLast() {
        return this.getCastedAction().actions.find(action => !action.next);
    }

    delete(block: WorkflowActionBlock) {
        const { actions } = this.getCastedAction();
        const deletedIndex = actions.findIndex(({ name }) => name === block.name);
        const prevAction = actions.find(a => a.next === block.name);

        if (prevAction) {
            prevAction.next = block.next;
        }

        actions.forEach(action => {
            if ((action as ConditionWorkflowAction).elseNext === block.name) {
                (action as ConditionWorkflowAction).elseNext = null;
            }
        });

        actions.splice(deletedIndex, 1);
    }

    findIndex(predicate: (a: WorkflowAction) => boolean) {
        return this.getCastedAction().actions.findIndex(predicate);
    }
}

export class ForkWorkflowActionBlock extends WorkflowActionBlock<ForkAction> implements ContainerBlock {
    marginBottom = 25;

    public getIndexForkBranch(action: WorkflowAction) {
        const { actions } = this.getCastedAction();

        return actions.findIndex(nestedActions => nestedActions.some(({ name }) => name === action.name));
    }

    public isFirstInForkBranch(action: WorkflowAction) {
        const firstActions = this.getCastedAction().actions.reduce<Record<string, string>>((acc, actions) => {
            const [firstAction] = actions;

            if (firstAction) {
                acc[firstAction.name] = firstAction.name;
            }

            return acc;
        }, {});

        return !!firstActions[action.name];
    }

    public push(action: WorkflowAction) {
        this.getCastedAction().actions.push([action]);
    }

    public addAfter(name: string, action: WorkflowAction, skipNextSet?: boolean) {
        const branchIndex = this.getIndexForkBranch({ name, next: null, type: null });
        const branch = this.getCastedAction().actions[branchIndex];

        const prevAction = branch.find(a => a.name === name);

        if (!skipNextSet) {
            if (prevAction.next) {
                action.next = prevAction.next;
            }
            prevAction.next = action.name;
        }

        branch.push(action);
    }

    public delete(node: WorkflowActionBlock) {
        const branchIndex = this.getIndexForkBranch(node.action);
        const branch = this.getCastedAction().actions[branchIndex];
        const removedIndex = branch.findIndex(a => a.name === node.name);

        const prevAction = branch.find(a => a.next === node.name);
        if (prevAction) {
            prevAction.next = node.next;
        }

        branch.splice(removedIndex, 1);

        if (!branch.length) {
            this.getCastedAction().actions.splice(branchIndex, 1);
        }
    }
}
