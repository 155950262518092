import 'reflect-metadata';
import { isPlainObject } from 'lodash';

enum MetadataKey {
    TYPING_METADATA = 'typeMetadata',
}

export const isArray = (value: any) => Array.isArray(value);
export const isString = (value: any) => typeof value === 'string';
export const isBoolean = (value: any) => typeof value === 'boolean';
export const isNumber = (value: any) => typeof value === 'number';
export const isObject = (value: any) => typeof value === 'object' && value !== null;

const typingErrorFunc = (required: any) => (received: any, isEnum = false) => ({
    required,
    received,
    isEnum,
});

export type TypeMetadata<T> = Partial<{
    reflectType: any;
    type: any;
    is: (obj: T) => boolean;
    default: T;
    message: (args?: any) => string;
    typingError: Function;
    childType: any;
}>;

const CHECK_TYPINGS: Readonly<{ [key: string]: TypeMetadata<any> }> = {
    Array: {
        is: isArray,
        default: [],
        message: value => `Array required, but got ${typeof value}.`,
        typingError: typingErrorFunc('array'),
    },
    String: {
        default: '',
        is: isString,
        message: value => `String required, but got ${typeof value}.`,
        typingError: typingErrorFunc('string'),
    },
    Number: {
        default: 0,
        is: value => isNumber(value) || value === null,
        message: value => `Number required, but got ${typeof value}.`,
        typingError: typingErrorFunc('number'),
    },
    Boolean: {
        default: false,
        is: isBoolean,
        message: value => `Boolean required, but got ${typeof value}.`,
        typingError: typingErrorFunc('boolean'),
    },
    Map: {
        default: {},
        is: isPlainObject,
        message: value => `Map required, but got ${typeof value}.`,
        typingError: typingErrorFunc('map'),
    },
    PrimitiveDataType: {
        default: '',
        is: value => isString(value) || isBoolean(value) || isNumber(value) || (isArray(value) && value.every(isString)),
        typingError: typingErrorFunc('string, boolean or number'),
    },
    Enum: {
        message: value => `Value ${value} not contain in enum.`,
        typingError: typingErrorFunc('enum'),
    },
};

export function Type<T>(constructor: (new (args?: any) => T) | any, options?: { enum: boolean }) {
    return function(target: any, propKey: string) {
        let reflectType = Reflect.getMetadata('design:type', target, propKey);
        let reflectTypeInfo = CHECK_TYPINGS[reflectType ? reflectType.name : ''];
        let childType = null;

        if (['Array', 'Map'].includes(reflectType?.name)) {
            if (options && options.enum) {
                childType = {
                    default: null,
                    is: (value: any) => value === null || isEnum(constructor)(value),
                };
            } else {
                childType = CHECK_TYPINGS[constructor.name];
            }
        } else {
            reflectType = constructor;
            reflectTypeInfo = CHECK_TYPINGS[constructor.name];
        }

        const typeMetadata: TypeMetadata<T> = {
            reflectType,
            type: reflectType,
            childType,
            ...(reflectTypeInfo || {}),
        };

        Reflect.defineMetadata(MetadataKey.TYPING_METADATA, typeMetadata, target, propKey);
    };
}

export function getFieldType(target: any, key: string) {
    return Reflect.getMetadata('type', target, key);
}

export function TypeValidator<T>(metadata: TypeMetadata<T>) {
    return function(target: any, key: string) {
        Reflect.defineMetadata(MetadataKey.TYPING_METADATA, metadata, target, key);
    };
}

export function getTypeMetadata<T, K extends keyof T>(target: T, key: K): TypeMetadata<T[K]> | null {
    return Reflect.getMetadata(MetadataKey.TYPING_METADATA, target, key as string);
}

export function IsInt(defaultValue = 0) {
    return TypeValidator({
        ...CHECK_TYPINGS['Number'],
        default: defaultValue,
        type: Number,
    });
}

export function IsString() {
    return TypeValidator({
        ...CHECK_TYPINGS['String'],
        default: '',
        type: String,
    });
}

export function IsBoolean() {
    return TypeValidator({
        ...CHECK_TYPINGS['Boolean'],
        default: false,
        is: isBoolean,
        type: Boolean,
    });
}

const isEnum = (entity: any) => (value: any) =>
    Object.keys(entity)
        .map(k => entity[k])
        .includes(value);

export function IsEnum(entity: any, defaultValue: any = null) {
    return TypeValidator({
        default: defaultValue,
        type: 'Enum',
        ...CHECK_TYPINGS['Enum'],
        is: value => value === null || isEnum(entity)(value),
    });
}
