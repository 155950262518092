import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';

import { EditJobDefinitionService } from '../edit-job-definition.service';
import { MenuItem } from '../models/common';

@Component({
    selector: 'dp-edit-job-definition-side-menu',
    templateUrl: './edit-job-definition-side-menu.component.html',
    styleUrls: ['./edit-job-definition-side-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditJobDefinitionSideMenuComponent {
    @Input() menuItems: MenuItem[];
    @Input() disabled: boolean;

    @Output() tabChange = new EventEmitter<{
        previous: string;
        current: string;
    }>();

    constructor(public editJDState: EditJobDefinitionService) {}

    goToStep(field: string, activeStep: string) {
        if (this.disabled) {
            return;
        }

        const index = this.menuItems.findIndex(item => item.field === field);
        this.editJDState.goToStep([field], index, true);

        this.tabChange.emit({
            previous: activeStep,
            current: field,
        });
    }
}
