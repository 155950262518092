<div *ngIf="tile" class="d-flex min-width-0">
    <div class="d-flex flex-column min-width-0">
        <div *ngIf="tile.tileTitle && !cardMode" data-test-id="tile-title" class="body2 title text-truncate tile-title">
            {{ tile.tileTitle }}
        </div>
        <div class="d-flex tile-values-container min-width-0">
            <div
                *ngFor="let tileValue of tile.values; last as last"
                data-test-id="tile-value-container"
                class="d-flex tile-body min-width-0"
            >
                <span data-test-id="tile-value" class="title1 primary-text tile-value text-truncate" [class.d-flex]="!!valueTemplate">
                    <ng-template
                        [ngIf]="valueTemplate"
                        [ngTemplateOutlet]="valueTemplate"
                        [ngTemplateOutletContext]="{ $implicit: tileValue.value }"
                        [ngIfElse]="defaultTemplate"
                    ></ng-template>
                    <ng-template #defaultTemplate>
                        {{ tileValue.value | mapper: tileValueFormatter }}
                    </ng-template>
                </span>
                <div
                    *ngIf="tileValue.delta || tileValue.additionalText || tileValue.postfix"
                    class="d-flex flex-column justify-content-end align-items-start mb-2 ml-2 overflow-hidden"
                >
                    <div
                        *ngIf="tileValue.delta"
                        data-test-id="tile-value-delta"
                        class="d-flex align-items-center delta-container text-nowrap"
                        [class.delta-success]="tileValue | mapper: isIncrease:'deltaInversed'"
                    >
                        <ng-container
                            *ngTemplateOutlet="arrow; context: { increase: tileValue | mapper: isIncrease:'deltaIconInversed' }"
                        ></ng-container>

                        <span data-test-id="delta" class="ml-1 title info1"
                            ><span data-test-id="delta-sign" class="delta-sign">{{ tileValue | mapper: deltaSignCharacter }}</span>
                            <span data-test-id="delta-value">{{ tileValue.delta | mapper: tileDeltaFormatter }}</span>
                        </span>
                    </div>
                    <div *ngIf="cardMode && tileValue.delta === 0" data-test-id="no-changes" class="title font-14px">
                        <fa-icon class="no-changes-icon" icon="minusCircle"></fa-icon> No change
                    </div>
                    <div style="max-width: 40ch" class="info2 text-secondary text-nowrap text-truncate">
                        {{ tileValue.postfix }}
                        {{ tileValue.additionalText }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <fa-icon *ngIf="tile.targetDrilldown && !cardMode" icon="open" class="ml-3 tile-drilldown-icon"></fa-icon>
</div>

<ng-template #arrow let-increase="increase">
    <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            [attr.d]="increase ? 'M1.5 9.5L6 2.5L10.5 9.5L1.5 9.5Z' : 'M10.5 2.5L6 9.5L1.5 2.5L10.5 2.5Z'"
            fill="var(--arrow-background)"
            stroke="var(--arrow-border)"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</ng-template>
