<div class="d-flex align-items-center">
    <button
        class="default-size px-0 min-width-0 w-100"
        [widget-tile]="data.tile"
        [tileDeltaFormatter]="formatDelta"
        type="button"
        cardMode="true"
    >
        <ng-template let-value>
            <div
                style="line-height: 1"
                full-text-width
                [availableWidth$]="metricWidth$"
                class="text-truncate align-self-center"
                [ngbTooltip]="tileTooltip"
                [disableTooltip]="data.tile | mapper: disableTooltip"
                container="body"
            >
                {{ value | mapper: formatMetric }}
            </div>
            <ng-template #tileTooltip>
                <ng-template
                    [ngTemplateOutlet]="relatedDetailsTooltip"
                    [ngTemplateOutletContext]="{ $implicit: data.tile.tooltip }"
                ></ng-template>
            </ng-template>
        </ng-template>
    </button>
</div>

<div class="border-bottom w-100"></div>

<div data-test-id="no-data" class="flex-1" style="max-height: 46px" *ngIf="!data.details?.length; else detailsTmpl"></div>

<ng-template #detailsTmpl>
    <div
        [perfectScrollbar]="{ useBothWheelAxes: true, suppressScrollX: false }"
        class="d-flex details-container align-items-start position-relative mb-n3 pb-3 mr-n3 pr-3"
    >
        <ng-container *ngFor="let detail of data.details | slice: 0:3; last as last; first as first">
            <div
                data-test-id="detail-container"
                class="d-flex flex-column flex-1 align-items-center justify-content-center position-relative detail-container"
                [class.pr-3]="!last"
                [class.pl-3]="!first"
                [class.last-child]="last"
            >
                <div
                    [ngbTooltip]="detailsTooltip"
                    [disableTooltip]="detail | mapper: disableTooltip"
                    container="body"
                    data-test-id="detail-value"
                    class="h2 detail-value mb-0"
                >
                    {{ detail.value | mapper: formatMetric }}
                </div>
                <ng-template #detailsTooltip>
                    <ng-template
                        [ngTemplateOutlet]="relatedDetailsTooltip"
                        [ngTemplateOutletContext]="{ $implicit: detail.tooltip }"
                    ></ng-template>
                </ng-template>
                <div data-test-id="detail-label" class="body1 mt-1 text-center text-nowrap primary-text">{{ detail.label }}</div>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #relatedDetailsTooltip let-breakdownDetail>
    <ng-container *ngIf="isMetricTooltip(breakdownDetail) as breakdownDetail">
        <h3 *ngIf="breakdownDetail.title" class="mt-0 mb-1">{{ breakdownDetail.title }}</h3>
        <ul *ngIf="breakdownDetail.items?.length" class="related-details-list">
            <li class="font-weight-normal" *ngFor="let detail of breakdownDetail.items">
                <div class="mr-2 circle" [style.backgroundColor]="detail.color"></div>
                <span data-test-id="metric-breakdown-label" class="body2"
                    >{{ detail.label }}: <span>{{ detail.value | mapper: formatMetricTooltip }}</span></span
                >
            </li>
        </ul>
    </ng-container>
</ng-template>
