<div class="visualization-container" *ngIf="(!hasErrors) else errorTemplate">
    <div class="composer">
        <perfect-scrollbar class="target">
            <h4 class="px-4 pt-4 my-0">Dataset</h4>
            <app-qb-visualization-dataset-items style="font-size: 12px"></app-qb-visualization-dataset-items>
        </perfect-scrollbar>
        <perfect-scrollbar class="target">
            <h4 class="px-4 pt-4 my-0">Mapping</h4>
            <div class="blocks px-4">
                <app-qb-visualization-mapping-container
                    style="font-size: 12px"></app-qb-visualization-mapping-container>
            </div>
        </perfect-scrollbar>
    </div>
    <div class="preview">
        <perfect-scrollbar class="p-0">
            <h4 style="margin: 0 0 24px !important;">Chart Preview</h4>
            <div class="show">
                <div class="show-inner">
                    <div class="basic-config">
                        <lib-dropdown
                            class="col pl-0"
                            [items]="allowedWidgetTypes"
                            [placeholder]="'Select chart type'"
                            [clearable]="false"
                            label="Chart type"
                            appendTo="body"
                            (change)="handleWidgetSelect($event)"
                            [value]="widgetConfig.type"
                        ></lib-dropdown>
                        <button
                            [disabled]="!widgetConfig.type"
                            (click)="openWidgetSettings()"
                            type="button"
                            class="btn btn-secondary"
                        >
                            Widget settings
                        </button>
                    </div>
                    <app-qb-visualization-widget-viewer></app-qb-visualization-widget-viewer>
                </div>
            </div>
        </perfect-scrollbar>
    </div>
    <ng-container *ngIf="widgetSettingsOpened">
        <div class="right-form-container d-flex mt-1">
            <dp-widget-builder-block-form
                class="flex-1 overflow-hidden"
                [block]="layerBlock"
                (save)="saveWidgetSettings($event)"
                (close)="closeWidgetSettings()"
            ></dp-widget-builder-block-form>
        </div>
    </ng-container>
</div>
<ng-template #errorTemplate>
    <div
        style="width: 100%; height: 80vh; display: flex; align-items: center; justify-content: center; flex-direction: column;">
        <img src="{{icons.error}}" alt="">
        <h2>Data Set configuration is invalid</h2>
        <br>
        <div class="body3 color-font-4">Please switch to Model tab and check the list of Issues to fix the
            configuration
        </div>
        <br>
        <button class="btn btn-primary" (click)="backButton.emit({nextId: 'Model'})">Back to Model tab</button>
    </div>
</ng-template>
