<div class="progress-bar-chart-header" *ngIf="data.header" [innerHTML]="data.header | safeHtml: 'html'"></div>
<div class="progress-bar-chart w-100">
    <div
        class="w-100 h-100 progress-bar-chart__no-data d-flex justify-content-center align-items-center"
        *ngIf="isEmpty; else dataTemplate"
    >
        {{ data.noDataMessage }}
    </div>
    <ng-template #dataTemplate>
        <div
            class="progress-bar-chart__item h-100 d-flex justify-content-center align-items-center"
            [class.cursor-pointer]="data.clickable"
            *ngFor="let progress of data.progress"
            (click)="barClicked.emit(progress.column)"
            [style.flex-basis.%]="(progress.value / sum) * 100"
            [style.background-color]="progress.color"
            [style.color]="lightOrDark(progress.color)"
        >
            {{ progress.value }} {{ data.measure || '' }}
        </div>
    </ng-template>
</div>

<perfect-scrollbar
    class="mt-2 progress-bar-chart__labels"
    style="height: auto"
    [config]="{ useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: true }"
>
    <div class="d-flex justify-content-center flex-column mr-2 mb-2" *ngFor="let label of data.labels">
        <div class="text-center font-16px">
            {{ label.value }}
        </div>
        <div class="text-center font-12px">
            {{ label.label }}
        </div>
    </div>
</perfect-scrollbar>

<div class="d-flex justify-content-center mt-3">
    <div class="d-flex mr-4 align-items-center" *ngFor="let legend of data.progress">
        <div class="rounded-circle progress-bar-chart-legend__icon mr-2" [style.background-color]="legend.color"></div>
        <div>{{ legend.label }}</div>
    </div>
</div>
