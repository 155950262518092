<div
    class="cursor-pointer d-flex align-items-center"
    (click)="openEditorForm()"
    [class.invisible]="isEditorForm"
    *ngIf="isRelAddable()"
>
    <ng-container *ngIf="!references.length; else plusTmp">
        <svg class="color-brand mr-15px" width="12" height="12" viewBox="0 0 12 12" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6 4V8M4 6H8M2.5 1.5H9.5C10.0523 1.5 10.5 1.94772 10.5 2.5V9.5C10.5 10.0523 10.0523 10.5 9.5 10.5H2.5C1.94772 10.5 1.5 10.0523 1.5 9.5V2.5C1.5 1.94772 1.94772 1.5 2.5 1.5Z"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
        Add Relation
    </ng-container>
    <ng-template #plusTmp>
        <fa-icon ngbTooltip="Add Relation" openDelay="500" container="body" class="secondary-text-and-icons"
                 icon="plus"></fa-icon>
    </ng-template>
</div>

<perfect-scrollbar #scrollBarRef [style.maxHeight.px]="260">
    <div class="d-flex">
        <svg #svgContainer class="ml-5px" [attr.height]="references.length * 16 * 4" [attr.width]="30">
            <g *ngFor="let any of references; let i = index">
                <path [attr.d]="getPath(i)" class="relation-path"></path>
                <foreignObject height="12" width="12" [attr.x]="14" [attr.y]="18 + i * 64">
                    <fa-icon class="path-marker" icon="facFilterGroup"></fa-icon>
                </foreignObject>
            </g>
        </svg>
        <div class="joins-wrapper ml-2">
            <ng-container *ngFor="let join of references; let i = index">
                <div class="model-link-data">
                    <div class="d-flex">
                        <span>
                            {{ join.leftModel.name }}
                            <span
                                class="join-type"
                                [id]="'spanJoin' + i"
                                (click)="isEditorForm ? closeEditorForm() : openEditorForm(join);"
                            >
                                {{ getJoinType(join.joinType) }}
                            </span>
                            {{ join.rightModel.name }}
                        </span>
                        <fa-icon
                            class="disable cursor-pointer ml-3"
                            icon="facTrash"
                            ngbTooltip="Delete"
                            openDelay="500"
                            container="body"
                            (click)="detachModel(join.rightModel)"
                        ></fa-icon>
                    </div>
                    <span
                    >{{ join.leftModel.name }}.{{ join.leftModelField }} <span class="join-fields"> = </span>
                        {{ join.rightModel.name }}.{{ join.rightModelField }}</span
                    >
                </div>
            </ng-container>
        </div>
    </div>
</perfect-scrollbar>
