import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { EnvironmentModel } from '@dagility-ui/kit';

@Injectable()
export class ExternalCredentialsApi {
    private readonly baseApiUrl = `${this.environment.buildApiURL}/ci-cd/external-credentials`;

    constructor(private http: HttpClient, @Inject('environment') private environment: EnvironmentModel) {}

    create(model: ToolCredentialsSaveModel) {
        return this.http.post(`${this.baseApiUrl}`, model);
    }

    update(model: ToolCredentialsSaveModel) {
        return this.http.put(`${this.baseApiUrl}/${model.id}`, model);
    }

    delete(id: string) {
        return this.http.delete(`${this.baseApiUrl}/${id}`);
    }

    getAllByToolId(toolId: string) {
        return this.get<ToolCredentialsModel[]>(`/tool/${toolId}`);
    }

    getAllByTargetToolId(targetToolId: string) {
        return this.get<ToolCredentialsModel[]>(`/target-tool/${targetToolId}`);
    }

    getToolIdentifiers(toolId: string) {
        return this.get<any[]>(`/identifiers/${toolId}`);
    }

    private get<T extends {}>(url: string): Observable<T> {
        return this.http.get<T>(`${this.baseApiUrl}${url}`);
    }
}

export interface ToolCredentialsModel {
    id: string;
    toolId: string;
    toolName: string;
    targetToolId: string;
    targetToolName: string;
    identifier: string;
}

interface ToolCredentialsSaveModel {
    id?: string;
    toolId: string;
    targetToolId: string;
    identifier: string;
}
