import { Component, HostBinding } from '@angular/core';

import { BaseWidgetFilter } from '../base-widget-filter.component';

@Component({
    selector: 'dp-checkbox-filter',
    template: `
        <span class="label label-top">
            {{ filter.description }}
        </span>
        <div class="d-flex align-items-center mt-auto" style="height: 24px">
            <trigger
                class="size-s d-block"
                [ngbTooltip]="filter.tooltip"
                [disableTooltip]="!(filter.isUiFilter && filter.tooltip)"
                [formControl]="formControl"
                >{{ filter.label }}</trigger
            >
        </div>
    `,
    styleUrls: ['./checkbox-filter.component.scss'],
})
export class CheckboxFilterComponent extends BaseWidgetFilter {
    @HostBinding('class') styles = 'pt-1 pr-3 widget-filter align-self-stretch d-flex flex-column';

    getFilterText(): { title: string; value: any } {
        return { title: this.filter.label, value: this.formControl.value ? 'Yes' : 'No' };
    }
}
