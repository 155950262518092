<ng-container *ngIf="state === 'expanded'">
    <div class="d-flex px-4 pt-3 justify-content-between align-items-center">
        <h2 class="mt-0">Publishing Changes to Repositories</h2>
        <button class="btn btn-link" (click)="toggleState()">
            Minimize
            <fa-icon [icon]="'facCompress'" class="font-20px ml-2"></fa-icon>
        </button>
    </div>

    <div class="d-flex justify-content-center py-4">
        <lib-circle-progress
            [radius]="90"
            [space]="-10"
            [centerIcon]="isSucceeded ? icons.facCheck : 'reject'"
            [centerIconHtml]="isSucceeded"
            [centerIconClass]="finished ? 'icon-fill-' + (isSucceeded ? 'success' : 'error') : ''"
            [showIcon]="finished"
            [outerStrokeWidth]="10"
            [outerStrokeColor]="!finished ? 'var(--da-brand-base)' : isSucceeded ? 'var(--da-success-base)' : 'var(--da-error-base)'"
            [innerStrokeColor]="'var(--da-brand-12)'"
            [innerStrokeWidth]="10"
            [title]="publishedComponents.length + failedComponents.length + '/' + allComponents.length"
            [showTitle]="!finished"
            [titleColor]="'var(--da-brand-base)'"
            [titleFontSize]="'32px'"
            [showSubtitle]="!finished"
            [subtitleColor]="'var(--da-secondary-text-and-icons)'"
            [subtitle]="'Component\n Repositories'"
            [subtitleFontSize]="'14px'"
            [showBackground]="false"
            [percent]="progress"
        >
        </lib-circle-progress>
    </div>
    <div *ngIf="finished" class="d-flex justify-content-end px-3 pb-3">
        <button class="btn btn-link" (click)="openDetails()">View Details</button>
    </div>
</ng-container>

<div *ngIf="state === 'minimized'" [class.minimized-state]="!finished" class="d-flex position-relative">
    <div
        class="d-flex align-items-center justify-content-center info"
        [class.info--succeeded]="finished && isSucceeded"
        [class.info--failed]="finished && !isSucceeded"
    >
        <fa-icon [icon]="!finished ? 'facInfoCircle' : isSucceeded ? 'check' : 'facExclamationCircle'"></fa-icon>
    </div>
    <div class="flex-1 p-3">
        <button type="button" class="close" data-test-id="cross-tick-btn" aria-describedby="modal-title" (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="body1">
            <span *ngIf="!finished">Publishing Changes to Repositories</span>
            <span *ngIf="finished && isSucceeded" class="success-msg">Success</span>
            <span *ngIf="finished && !isSucceeded" class="error-msg">Failed</span>
        </div>
        <div class="body2 mt-1">
            <div *ngIf="finished">Changes published to</div>
            {{
                !finished
                    ? publishedComponents.length + failedComponents.length + '/' + allComponents.length
                    : publishedComponents.length + '/' + allComponents.length
            }}
            component repositories.
        </div>
        <div class="d-flex mt-3">
            <div class="ml-auto d-flex">
                <button *ngIf="finished" (click)="openDetails()" class="btn btn-link">View Details</button>
                <button class="btn btn-link ml-2" (click)="toggleState()">Expand</button>
            </div>
        </div>
    </div>
</div>
