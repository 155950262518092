import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { ResizeObserverService } from '@dagility-ui/kit';

import { Placeholders, WidgetTile } from 'data-processor';

@Component({
    selector: 'dp-widget-scroll-container',
    templateUrl: './widget-scroll-container.component.html',
    providers: [ResizeObserverService],
    host: {
        class: 'd-block h-100',
    },
})
export class WidgetScrollContainerComponent implements OnInit {
    @Input() placeholders: Placeholders;
    @Input() position: string;

    @Output() drilldown = new EventEmitter<WidgetTile>();

    libScrollerOrientation: 'horizontal' | 'vertical' = 'vertical';

    height$ = this.resizeObserverService.observe$(this.elementRef).pipe(
        startWith(null as unknown),
        map(() => this.elementRef.nativeElement.offsetHeight),
        distinctUntilChanged()
    );

    constructor(private resizeObserverService: ResizeObserverService, private elementRef: ElementRef<HTMLElement>) {}

    ngOnInit() {
        this.libScrollerOrientation = this.position === 'left' || this.position === 'right' ? 'vertical' : 'horizontal';
    }

    handleDrilldown($event: WidgetTile) {
        this.drilldown.emit($event);
    }
}
