import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { EnvironmentModel, ModalService, TypedModalRef } from '@dagility-ui/kit';

import { HelpSupportService } from '../../utils/help-support/help-support.service';

export interface Help {
    anchorId: string;
    title: string;
    content: string;
}

export function removeContextHelp() {
    const contextHelpEl: any = document.querySelector('.context-help-wrap');
    if (contextHelpEl) {
        contextHelpEl.parentNode.removeChild(contextHelpEl);
    }
}

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[context-help-id]',
    templateUrl: './context-help.component.html',
    styleUrls: ['./context-help.component.scss'],
})
export class ContextHelpComponent implements OnDestroy {
    @Input('context-help-id') set id(value: string) {
        if (value) {
            this.contextHelpId = value;
            this.contextData = this.getContextData();
        }
    }

    private contextHelpId: string;

    @Input('default-context-help-id') set defaultId(value: string) {
        if (value) {
            this.contextHelpDefaultId = value;
            this.contextData = this.getContextData();
        }
    }

    private contextHelpDefaultId: string;

    @Input('context-help-default-color') defaultColor: boolean = true;

    @Input('context-help-data') data: Partial<Help>;

    @Input('context-help-keep-on-navigation') keepOnNavigation = false;

    /**
     * width of context-help panel in pixels
     */
    @Input('context-help-width') contextHelpWidth = 285;

    /**
     * paddings around context-help tooltip
     */
    @Input('context-help-padding') contextHelpPadding = 12.5;

    @ViewChild('contextHelpTmpl') contextHelpTmpl: TemplateRef<unknown>;

    showHelp = false;
    title = '';
    content = '';

    contextData: Help;

    get isLocatedInCheckbox() {
        return Boolean(this.el.nativeElement.closest('checkbox'));
    }

    private destroy$ = new Subject<void>();
    private modalRef: TypedModalRef<{}>;

    constructor(
        @Inject('environment') private env: EnvironmentModel,
        private el: ElementRef<HTMLElement>,
        public helpSupportService: HelpSupportService,
        router: Router,
        private modalService: ModalService,
        private cdr: ChangeDetectorRef
    ) {
        router.events.pipe(takeUntil(this.destroy$)).subscribe(val => {
            if (!this.keepOnNavigation && val instanceof NavigationStart) {
                this.closeContextHelp();
            }
        });
    }

    @HostListener('window:resize') onResize() {
        if (this.showHelp) {
            this.removeContextHelpsFromBody();
        }
    }

    onEscapeKey(): void {
        this.showHelp = false;
        this.removeContextHelpsFromBody();
    }

    onDocumentClick(targetElement: HTMLElement) {
        const clickedInside = targetElement.closest('.context-help-wrap');
        if (!clickedInside) {
            const contextHelpFromInput = document.getElementById('context-help-wrap-in-input');
            if (contextHelpFromInput && contextHelpFromInput.contains(targetElement)) {
                return;
            }

            this.showHelp = false;
            this.removeContextHelpsFromBody();
        }
    }

    private getContextData(): Help {
        const contextArray = (JSON.parse(sessionStorage.getItem('contextHelp')) || []).filter((it: any) =>
            it.anchorId != null
                ? it.anchorId === this.contextHelpId
                    ? true
                    : this.contextHelpDefaultId
                    ? it.anchorId === this.contextHelpDefaultId
                    : false
                : false
        );
        return contextArray.length
            ? contextArray.length > 1
                ? contextArray.find((x: Help) => x.anchorId === this.contextHelpId)
                : contextArray[0]
            : null;
    }

    // need to remove from div if context help was in accordion and then was added to body,
    // in this case onClick and onEscapeKey now working with tabs or links
    removeContextHelpsFromBody() {
        const contextHelpEls: any = document.getElementsByClassName('context-help-wrap');
        Object.entries(contextHelpEls).forEach((entry: any) => {
            const contextHelpEl = entry[1];
            contextHelpEl.parentNode.removeChild(contextHelpEl);
        });
    }

    showContextHelp() {
        this.closeContextHelp();
        this.showHelp = !this.showHelp;
        this.el.nativeElement.classList.add('context-help-trigger');

        setTimeout(() => {
            const contextHelpEl: any = document.querySelector('.context-help-wrap');
            if (contextHelpEl) {
                // code bellow need to append div to body, because by default it's append
                // to accordion and content sometimes not shown full height
                const isLocatedInInput = !!contextHelpEl.closest('lib-input');
                contextHelpEl.classList.remove('positioned-right');
                const spanEl: any = contextHelpEl.closest('.position-relative').getBoundingClientRect();
                if (isLocatedInInput) {
                    contextHelpEl.id = 'context-help-wrap-in-input';
                }

                document.body.append(contextHelpEl);
                const newContextHelpEls: any = document.getElementsByClassName('context-help-wrap');
                const newContextHelpEl: HTMLElement = newContextHelpEls[newContextHelpEls.length - 1];
                let contextHelpWidthWithMargins = this.contextHelpWidth + 2 * this.contextHelpPadding;
                newContextHelpEl.style.left =
                    window.innerWidth - spanEl.x >= contextHelpWidthWithMargins
                        ? spanEl.x + 25 + 'px'
                        : window.innerWidth - contextHelpWidthWithMargins + 'px';
                newContextHelpEl.style.top = spanEl.y - 5 + 'px';

                if (window.outerWidth - (this.el.nativeElement.offsetLeft + this.el.nativeElement.offsetWidth) < 800) {
                    contextHelpEl.classList.add('positioned-right');
                }

                if (this.needToShowModal(newContextHelpEl)) {
                    this.showHelp = false;
                    this.cdr.detectChanges();
                    this.showContextHelpModal();
                } else {
                    newContextHelpEl.classList.add('shown');
                }
            }
        });
    }

    /**
     * show context-help modal if popup doesn't fit to current viewport
     */
    private needToShowModal(element: HTMLElement) {
        const { right, bottom } = element.getBoundingClientRect();

        return this.contextHelpTmpl && (right > window.innerWidth || bottom > window.innerHeight);
    }

    handleContentClick(event: any) {
        const serviceUrl = event.target.host === 'localhost:4200' ? this.env.serviceUrl : event.target.host;
        if (this.env.serviceUrl.includes(serviceUrl) && event.target.tagName === 'A') {
            const hash = event.target.hash;
            if (hash !== '') {
                const key = decodeURIComponent(hash.slice(1));
                event.preventDefault();
                const filteredData = this.helpSupportService.getFilteredData(key);
                if (filteredData && filteredData.length) {
                    window.open(`#/help-support/${filteredData[0].id}`, '_blank');
                } else {
                    event.target.parentNode.removeChild(event.target);
                }
            }
        }
    }

    dismiss = () => {
        this.showHelp = false;
    };

    preventClickBubbling(event: MouseEvent) {
        event.stopPropagation();

        // prevent trigger checkbox toggle when we click on context-help icon
        if (this.isLocatedInCheckbox) {
            event.preventDefault();
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.closeContextHelp();
    }

    private closeContextHelp() {
        this.removeContextHelpsFromBody();
        this.closeContextHelpModal();
    }

    private showContextHelpModal() {
        this.modalRef = this.modalService.open(
            this.contextHelpTmpl as any,
            {
                centered: true,
                size: 'lg',
                windowClass: 'context-help-modal',
            },
            {}
        );
    }

    private closeContextHelpModal() {
        this.modalRef?.close();
        this.modalRef = null;
    }
}
