import { ModelGraph } from '@app/shared/components/query-builder/models/model-graph.model';
import { WidgetDrilldown } from '@app/shared/components/query-builder/models/model-graph-actions.model';

export class GraphPath {
    private subQueryGraphId: string;
    private drillDownId: string;
    private graphUid: string;

    private graphsWithParents: Record<string, GraphPathEntry>;
    private _path: string[] = null;

    get path() {
        return this._path;
    }

    updateIfNeeded(root: ModelGraph, subQueryGraphId: string, drillDownId: string) {
        if (root != null && (root.uid !== this.graphUid || this.subQueryGraphId !== subQueryGraphId || this.drillDownId !== drillDownId)) {
            this.subQueryGraphId = subQueryGraphId;
            this.drillDownId = drillDownId;
            this.graphsWithParents = {};
            this.graphsWithParents[root.uid] = { parentGraphUid: null, graph: root };
            this.collectDrillDowns(root);
            this.updatePath(root);
        }
    }

    updatePath(root: ModelGraph) {
        this._path = [];
        const g = GraphPath.findGraph(root, this.drillDownId, null);
        if (g && this.drillDownId) {
            let p = this.graphsWithParents[g.uid];
            while (p) {
                p.graph.widgetConfig && this._path.unshift(p.graph.widgetConfig.chartOptions.title);
                p = p.parentGraphUid ? this.graphsWithParents[p.parentGraphUid] : null;
            }
        }
    }

    private collectDrillDowns(graph: ModelGraph) {
        const drillDowns: WidgetDrilldown[] = [];
        drillDowns.push(...(graph?.drillDowns || []));
        this.processSubQueries(graph);
        for (const dd of drillDowns) {
            this.graphsWithParents[dd.graph.uid] = { parentGraphUid: graph.uid, graph: dd.graph };
            this.processSubQueries(dd.graph);
            this.collectDrillDowns(dd.graph);
        }
    }

    private processSubQueries(graph: ModelGraph) {
        const subGraphs: ModelGraph[] = graph?.models.map(model => model.subQueryGraph).filter(g => g !== null) || [];
        for (const subGraph of subGraphs) {
            this.graphsWithParents[subGraph.uid] = { parentGraphUid: graph.uid, graph: subGraph };
            this.processSubQueries(subGraph);
        }
    }

    static findGraph(root: ModelGraph, drillDownId: string, subQueryGraphId: string) {
        let drillDownOrCurrentGraph = root;
        if (drillDownId) {
            const drillDowns: WidgetDrilldown[] = [];
            drillDowns.push(...root.drillDowns);
            for (const dd of drillDowns) {
                if (dd.uid === drillDownId) {
                    drillDownOrCurrentGraph = {
                        ...dd.graph,
                        drilldown: {
                            ...dd.drilldown,
                            drillDownName: dd.drillDownName,
                            drillDownParamName: dd.drillDownParamName,
                            uid: dd.uid,
                        },
                    };
                    break;
                } else {
                    drillDowns.push(...dd.graph.drillDowns);
                }
            }
        }
        let subQueryGraph = drillDownOrCurrentGraph;
        if (subQueryGraphId) {
            const subGraphs: ModelGraph[] = drillDownOrCurrentGraph.models.map(model => model.subQueryGraph).filter(g => g !== null);
            for (const subGraph of subGraphs) {
                if (subGraph.uid === subQueryGraphId) {
                    subQueryGraph = subGraph;
                    break;
                } else {
                    subGraphs.push(...subGraph.models.map(model => model.subQueryGraph).filter(g => g !== null));
                }
            }
        }
        return subQueryGraph;
    }
}

interface GraphPathEntry {
    parentGraphUid: string;
    graph: ModelGraph;
}
