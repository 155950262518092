<div class="modal-header">
    <h1 class="header-text mt-0">{{ title }}</h1>
    <button
        type="button"
        class="close ml-auto"
        aria-label="Close button"
        aria-describedby="modal-title"
        (click)="modalService.dismiss()"
    >
        <fa-icon [icon]="icons.facClose" class="close-modal-window-icon font-14px"></fa-icon>
    </button>
</div>

<div class="modal-body">
    <div *ngIf="action === 'duplicate'" class="message-text">{{ message }}</div>

    <div [formGroup]="form">
        <lib-input [label]="'Name'" maxlength="100" formControlName="setName" id="set-name"></lib-input>
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-secondary ml-auto font-11px" (click)="modalService.dismiss()">
        Cancel
    </button>
    <button type="button" class="btn btn-primary form-button ml-25px font-11px" (click)="handleSave()">
        {{ action === 'import' ? 'Import' : 'Save' }}
    </button>
</div>
