<div *ngIf="showChart" class="container p-0 h-100">
    <div class="row align-items-start flex-nowrap h-100">
        <div class="col-9 p-0 h-100">
            <lib-chart
                #chart
                [style.width]="width"
                [style.height]="height"
                [checkHostResize]="true"
                class="position-relative chart"
                [type]="'doughnutchart'"
                [attr.type]="'doughnutchart'"
                [options]="chartOptions"
                (event)="onChartEvent($event.payload.barName)"
            >
            </lib-chart>
        </div>
        <div class="col-3 p-0">
            <div
                class="position-relative"
                [style.width]="'100%'"
                (mouseleave)="cancelTooltip($event, 'work-distribution-chart-info'); mouseOnTooltip = false"
            >
                <perfect-scrollbar
                    stopScrollPropagation
                    class="legend-icons"
                    [class.mt-5]="!isMediumScreen"
                    [class.mt-2]="isMediumScreen"
                    [style.max-height]="isMediumScreen ? '10vh' : '130px'"
                    style="overflow: visible"
                >
                    <div class="d-flex flex-column progress-bars-container">
                        <div
                            *ngIf="legendTitle"
                            [ngClass]="selected === 'all' ? 'activeLegend' : ''"
                            (click)="onChartEvent('all')"
                            class="font-10px cursor-pointer progress-bar-chart-legend pl-3  align-items-center text-capitalize"
                        >
                            {{ legendTitle }}
                        </div>
                        <div
                            *ngFor="let legend of chartData; index as i"
                            class="d-flex pl-1 cursor-pointer progress-bar-chart-legend  align-items-center"
                            [class.active-legend]="legend.name === selected"
                            (mouseover)="generateTooltipData(legend.name); mouseOnTooltip = true"
                            (mouseenter)="mouseEnter(legend, chart.chartInstance)"
                            (mouseleave)="mouseLeave(legend, chart.chartInstance)"
                            (click)="onChartEvent(legend.name)"
                        >
                            <div
                                *ngIf="chartOptions?.colors"
                                class="progress-bar-chart-legend__icon p-1"
                                [style.background-color]="chartOptions.colors[i]"
                            ></div>

                            <div class="font-10px my-1 px-1 legend-hover">
                                <div
                                    container="body"
                                    class="text-truncate"
                                    style="max-width: 50px"
                                    [ngbTooltip]="
                                        !formattedTooltipData?.length
                                            ? (legend.name | titlecase) +
                                              ': ' +
                                              (legend.count || '') +
                                              (legend.count ? ' (' + ((legend.count / totalCount | percent: '.2') || '') + ')' : '')
                                            : null
                                    "
                                >
                                    {{ legend.name | titlecase }}
                                </div>

                                <div
                                    class="work-distribution-chart-info position-absolute"
                                    *ngIf="formattedTooltipData?.length && formattedTooltipHeader.name === legend.name"
                                    (mouseover)="mouseOnTooltip = true"
                                    (mouseleave)="cancelTooltip($event, 'progress-bars-container'); mouseOnTooltip = false"
                                >
                                    <div
                                        (click)="$event.stopPropagation()"
                                        class="py-2 work-distribution-chart-info-container position-relative w-100 d-flex flex-column pl-3 align-items-start"
                                    >
                                        <div class="font-11px header-text">{{ formattedTooltipHeader.name }}</div>
                                        <div class="font-10px font-weight-400 d-flex justify-content-between w-100">
                                            <span>Work distribution</span>
                                            <span class="font-weight-600 pr-3"
                                                >({{ formattedTooltipHeader.count / totalCount | percent: '.2' }})</span
                                            >
                                        </div>
                                        <perfect-scrollbar class="height-250px pr-2">
                                            <div class="pt-2 d-flex justify-content-start flex-column">
                                                <div
                                                    class="font-11px font-weight-400 pb-3px"
                                                    *ngFor="let toolTipData of formattedTooltipData"
                                                >
                                                    <ng-container *ngIf="toolTipData.value; else notObjectTmpl">
                                                        <a class="link-normal" [href]="toolTipData.linkValue" target="_blank">{{
                                                            toolTipData.value
                                                        }}</a>
                                                        <span> : {{ toolTipData.tooltip }}</span>
                                                    </ng-container>

                                                    <ng-template #notObjectTmpl>
                                                        {{ toolTipData }}
                                                    </ng-template>
                                                </div>
                                            </div>
                                        </perfect-scrollbar>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
    </div>
</div>
