import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, Optional, Output, ViewChild } from '@angular/core';
import { coerceBooleanProperty, isDefined } from '@dagility-ui/kit';
import { defer, EMPTY, merge, Observable, of, Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { debounce, isEqual, noop } from 'lodash';
import { map, mapTo, skip, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AnyWidgetStore } from 'data-processor/lib/widget-library/widget-builder/components/widget/any-widget/any-widget.store';
import { FilterSetDirective } from 'data-processor/lib/widget-library/widget-builder/directives/filter-set.directive';
import { AnyWidgetModel, WidgetFilter, WidgetFilterType, WidgetType } from '../../../models/any-widget.model';
import { PerfectScrollbarEventPropagationDirective } from 'data-processor/lib/widget-library/dashboard/directives/perfect-scrollbar-event-propagation.directive';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'dp-any-widget-filter-wrapper',
    templateUrl: './any-widget-filter-wrapper.component.html',
    styleUrls: ['./any-widget-filter-wrapper.component.scss'],
})
export class AnyWidgetFilterWrapperComponent {
    @Input() funnelIconEnabled = false;

    @Input() options: AnyWidgetModel;

    @Input() filters: WidgetFilter[] = [];

    @Input() editDashboard: boolean;

    @Input() defaultPlaceholders$: Observable<Record<string, any>> = EMPTY;

    @Input() hasParent: string;

    @Input() intersect$: Observable<any>;

    @Input() filtersGroup: FormGroup;

    @Input() previewMode: boolean;

    @Input() isSmallWidget: boolean;

    @Output() filtersLoaded = new EventEmitter();

    @ViewChild(FilterSetDirective, { static: false }) filterSet: FilterSetDirective;

    @ViewChild(NgbDropdown) dropdown: NgbDropdown;

    isDirty$ = defer(() => {
        if (!this.options.server) {
            return of(false);
        }
        return this.store.workflow.loaded$.pipe(
            map(() => this.store.workflow.filters.value),
            switchMap(originValue => this.isDirty(originValue))
        )
    }) ;

    isDefaultValueDirty$ = defer(() => {
        if (!this.options.server) {
            return of(false);
        }
        return this.store.workflow.defaultValue$.pipe(switchMap(defaultValue => this.isDirty(defaultValue)))
    });

    isDirty = (originValue: any) =>
        this.activeForm.valueChanges.pipe(
            startWith(this.activeForm.value),
            map((newValue: any) => {
                return Object.keys(newValue).some(key => {
                    if (isEmpty(newValue[key]) && isEmpty(originValue[key])) {
                        return false;
                    }

                    return !isEqual(newValue[key], originValue[key]);
                });
            })
        );

    expandedFilter: WidgetFilter = null;

    dropdownOpened = false;

    activeForm = new FormGroup({});

    get filtersGroup$() {
        return !this.isComplexPart && this.options.server ? this.debuggerState.filters$ : of(this.filtersGroup);
    }

    loading$ = defer(() => {
        if (!this.options.server) {
            return of(false);
        }
        return merge(
            this.store.workflow.filterValueChanged$.pipe(mapTo(true)),
            this.store.workflow.resume$.pipe(skip(1), mapTo(true)),
            this.store.workflow.placeholdersUpdated$.pipe(skip(1), mapTo(false)),
            this.store.workflow.loaded$.pipe(mapTo(false))
        )
    });

    private readonly destroyed$ = new Subject();

    constructor(
        public store: AnyWidgetStore,
        @Optional() private scrollDir: PerfectScrollbarEventPropagationDirective,
        private zone: NgZone,
        private cdRef: ChangeDetectorRef,
        private elRef: ElementRef
    ) {
        if (this.scrollDir) {
            this.scrollDir.scroll
                .pipe(
                    tap(() => {
                        if (this.dropdownOpened) {
                            this.zone.run(() => {
                                this.dropdownOpened = false;
                                this.cdRef.markForCheck();
                            });
                        }
                    }),
                    takeUntil(this.destroyed$)
                )
                .subscribe();
        }
    }

    openFilters() {
        this.dropdown.open();
    }

    get debuggerState() {
        return this.store.debuggerState;
    }

    get maxWidth() {
        if (this.expandedFilter && [WidgetFilterType.NEW_RANGE, WidgetFilterType.RANGE].includes(this.expandedFilter.type)) {
            return '';
        }

        return '300px';
    }

    maxHeight = 500;

    type = WidgetType;

    widgetFilterType = WidgetFilterType;

    hasVisibleFilter = hasVisibleFilter;

    get isComplexPart() {
        return coerceBooleanProperty(this.hasParent);
    }

    handleFilterChange = this.debuggerState
        ? debounce((filter: WidgetFilter) => {
              this.debuggerState.filterChanged$.next([this.options.id, filter.placeholder]);
          }, 300)
        : noop;

    dropdownToggled(opened: boolean, originForm: FormGroup) {
        this.dropdownOpened = opened;
        if (!opened) {
            this.updateOriginControl(this.expandedFilter, originForm);
            this.expandedFilter = null;
        }
        if (opened) {
            const { top, bottom } = this.elRef.nativeElement.getBoundingClientRect();
            this.maxHeight = Math.max(top - 20, document.documentElement.clientHeight - bottom - 20, 20);
        }
    }

    apply(originForm: FormGroup) {
        this.updateOriginControl(this.expandedFilter, originForm);
        this.store.workflow.apply();
        this.expandedFilter = null;
        this.dropdown.close();
    }

    reset() {
        this.expandedFilter = null;
        this.store.workflow.reset();
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }

    updateExpandedFilter(filter: WidgetFilter, originForm: FormGroup) {
        if (!filter) {
            this.updateOriginControl(this.expandedFilter, originForm);
        }
        this.expandedFilter = filter;
    }

    updateOriginControl(filter: WidgetFilter, originForm: FormGroup) {
        if (filter) {
            const originControl = originForm.get(filter.placeholder);
            const activeControl = this.activeForm.get(filter.placeholder);
            if (originControl.value !== activeControl.value) {
                originControl.setValue(activeControl.value);
            }
        }
    }
}

function isEmpty(value: string) {
    return value === '' || !isDefined(value);
}

export function hasVisibleFilter(filters: WidgetFilter[]) {
    return !!filters?.length && filters.some(({ type }) => type !== WidgetFilterType.HIDDEN);
}
