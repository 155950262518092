import { Deserializable } from '../../../models/job-definition';
import { Required } from '../../../models/validate.decorator';

export class QueryAction extends Deserializable {
    _arrays = {};
    _class = {};
    _enums = {};
    _maps = {};
    meta = {
        _arrays: this._arrays,
        _class: this._class,
        _enums: this._enums,
        _maps: this._maps,
    };

    @Required()
    query: string = '';

    variable: string = '';

    @Required()
    from: string = '';

    constructor(instance?: any) {
        super();
        this.fillByInstance(QueryAction, instance);
    }
}
