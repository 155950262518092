import { Component, Input } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ComparePipelineScripts } from '../../../models/project-pipeline-items';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'app-pipeline-script-compare',
    templateUrl: './pipeline-script-compare-modal.component.html',
})
export class PipelineScriptCompareModalComponent {
    @Input() leftPanelTitle: string = 'Current Version';
    @Input() rightPanelTitle: string = 'Selected Version';
    @Input() scripts$: Observable<ComparePipelineScripts>;

    refresh$ = new Subject();
    private destroy$ = new Subject<boolean>();

    constructor(public activeModal: NgbActiveModal) {}

    ngOnInit() {
        this.scripts$ = this.scripts$.pipe(
            takeUntil(this.destroy$),
            tap(() => {
                setTimeout(() => this.refresh$.next());
            })
        );
    }

    handleCloseClick() {
        this.activeModal.dismiss('Cross clicked');
    }

    ngOnDestroy() {
        this.destroy$.next();
    }
}
