import { InjectionToken, Provider } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AnyWidget, WidgetBuilderService } from '../services/widget-builder.service';

export const WIDGET_DATA_TOKEN = new InjectionToken<WidgetBuilderData>('Should return widget state for WC editor');

export function widgetDataFactory({ params }: ActivatedRoute, api: WidgetBuilderService): WidgetBuilderData {
    return params.pipe(
        switchMap(({ widgetId }: WidgetBuilderFactoryParams) =>
            widgetId === 'new'
                ? of<AnyWidget>({
                      id: null,
                      dashboardId: null,
                      uuid: null,
                      data: null,
                      groupId: null,
                  })
                : api.getWidgetFromLibrary(+widgetId)
        )
    );
}

export const WIDGET_DATA_PROVIDER: Provider = {
    provide: WIDGET_DATA_TOKEN,
    deps: [ActivatedRoute, WidgetBuilderService],
    useFactory: widgetDataFactory,
};

export type WidgetBuilderData = Observable<AnyWidget>;

interface WidgetBuilderFactoryParams {
    widgetId: string;
}
