import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject, Injector } from '@angular/core';
import { NgbPanel } from '@ng-bootstrap/ng-bootstrap';
import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ModalService } from '@dagility-ui/kit';
import { ToasterService } from '@dagility-ui/kit/toastr';
import { DATA_MORPH_FEATURE_TOGGLE } from 'data-processor/tokens';
import { TreeLikeMenuGroup, TreelikeMenuItem } from '@dagility-ui/kit/modules/forms/controls/treelike-menu/treelike-menu.component';
import { DpDashboardFacade } from '../../state/dp-dashboard.facade';
import { DashboardGroupState } from '../../state/dp-dashboard.state.model';

import { DpDashboardStore } from '../../state/dp-dashboard.store';
import { DpEditGroupComponent } from '../dp-edit-group/dp-edit-group.component';
import { DpDashboardService } from '../../services/dp-dashboard.service';

@Component({
    selector: 'dp-dashboard-group-header',
    templateUrl: './dp-dashboard-group-header.component.html',
    styleUrls: ['./dp-dashboard-group-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'w-100 d-flex align-items-center',
    },
})
export class DpDashboardGroupHeaderComponent {
    @Input() group: DashboardGroupState;

    @Input() opened: boolean;

    @Input() edit: boolean;

    @Output() save = new EventEmitter<DashboardGroupState>();

    groupActions$: Observable<TreeLikeMenuGroup[]> = getGroupActions(this);

    get icon(): string {
        return `chevron-${this.opened ? 'up' : 'down'}`;
    }

    get fontSize$(): Observable<number> {
        return of(this.group.definition.headerFontSize);
    }

    get groupName$(): Observable<string> {
        return of(this.group.name);
    }

    constructor(
        public panel: NgbPanel,
        private facade: DpDashboardFacade,
        public store: DpDashboardStore,
        private injector: Injector,
        private modalService: ModalService,
        private toaster: ToasterService,
        private dpDashboardService: DpDashboardService
    ) {}

    handleSave(group: any) {
        this.save.emit({
            ...this.group,
            ...group,
        });
    }

    handleSaveNew(dashboard: any) {
        this.save.emit({
            ...this.group,
            ...dashboard.group,
        });
    }

    handleDelete() {
        this.facade.deleteGroup(this.group);
    }

    async handleAddGroup(dashboard: DpDashboardGroupHeaderComponent) {
        const { id, tabId, dashboardId } = dashboard.group;

        try {
            let editedGroup = await this.modalService.open(
                DpEditGroupComponent,
                { injector: this.injector },
                { modal: true, group: dashboard.group }
            ).result;
            const group = await this.dpDashboardService
                .updateGroup({
                    ...editedGroup,
                    id,
                    dashboardId,
                    tabId,
                })
                .toPromise();

            this.toaster.successToast({
                title: 'Success',
                content: 'Group created successfully',
            });
            this.group.name = await editedGroup.name;
            this.group.definition.type = await editedGroup?.definition?.type;
            editedGroup = {...editedGroup, widgets: this.group.widgets, id, dashboardId, tabId};
            this.store.setGroup(id, editedGroup, tabId);
        } catch {
            // noop
        }
    }
}

export function getGroupActions(dpDashboardGroup: DpDashboardGroupHeaderComponent): Observable<TreeLikeMenuGroup[]> {
    return combineLatest<TreelikeMenuItem[]>([
        of({
            label: 'Edit Group',
            icon: 'facEdit',
            actionHandler: () => dpDashboardGroup.handleAddGroup(dpDashboardGroup),
        }),
        of({
            label: 'Delete Group',
            icon: 'facTrash',
            actionHandler: () => dpDashboardGroup.handleDelete(),
        })
    ]).pipe(
        map(([editItem, deleteItem]) => {
            const manageGroupItems = [];
            if (editItem !== null) {
                manageGroupItems.push(editItem);
            }
            if (deleteItem !== null) {
                manageGroupItems.push(deleteItem);
            }
            return [
                {
                    label: 'Manage Group',
                    items: manageGroupItems,
                },
            ].filter(group => group.items.length);
        })
    );
}
