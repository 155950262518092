import { Component, Input } from '@angular/core';

import { PortfolioScore } from '../../components/portfolio-health-scores/models/portfolio-scores.model';

@Component({
  selector: 'dp-portfolio-score-subcategories',
  templateUrl: './portfolio-score-subcategories.component.html',
  styleUrls: ['./portfolio-score-subcategories.component.scss']
})
export class PortfolioScoreSubcategoriesComponent {
    @Input() data: { subCategories: PortfolioScore[] };

    constructor() { }
}
