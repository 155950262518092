import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject, zip } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { cloneDeep, isEmpty } from 'lodash';

import { ExecutionResult, Placeholders } from 'data-processor';

import { AnyWidgetModel, AsyncQuery } from '../models/any-widget.model';

import { WidgetLogger, WidgetScriptExecutor } from './widget-script.executor';
import { parseError } from './widget-builder.util';

@Injectable()
export class AsyncQueriesFlow {
    asyncQueriesLoader$ = new Subject();

    constructor(private scriptExecutor: WidgetScriptExecutor, private logger: WidgetLogger, private http: HttpClient) {}

    call(queries: AsyncQuery[], result: ExecutionResult, widget: AnyWidgetModel) {
        const { queries: filteredQueries, results } = this.executeBeforeScripts(queries ?? [], result, widget);

        if (!isEmpty(filteredQueries)) {
            this.asyncQueriesLoader$.next(true);
        }

        return this.executeDataSourceScripts(results, filteredQueries, result.placeholders).pipe(
            tap(executedScripts => {
                this.executeAfterScripts(executedScripts, result);
            }),
            tap(() => {
                this.asyncQueriesLoader$.next(false);
            })
        );
    }

    private executeBeforeScripts(queries: AsyncQuery[], executionResult: ExecutionResult, widget: AnyWidgetModel) {
        return queries.reduce(
            (acc, query) => {
                try {
                    this.scriptExecutor.buildFn(`return function(executionResult, widget){${query.beforeScript}}`)(executionResult, widget);

                    acc.results.push(cloneDeep(executionResult));
                    acc.queries.push(query);
                } catch (e) {
                    this.log(e);
                }

                return acc;
            },
            {
                queries: [],
                results: [],
            }
        );
    }

    private executeDataSourceScripts(
        results: ExecutionResult[],
        queries: AsyncQuery[],
        placeholders: Placeholders
    ): Observable<AsyncQuery[]> {
        return zip(
            ...results.map((result, idx) => {
                const query = queries[idx];

                return this.callHTTP(queries[idx], result.placeholders[query.placeholder]).pipe(
                    map(response => {
                        placeholders[query.placeholder] = response;

                        return query;
                    }),
                    catchError(e => {
                        this.log(e);

                        return of(false);
                    })
                );
            })
        ).pipe(map(httpResults => httpResults.filter(Boolean) as AsyncQuery[]));
    }

    private executeAfterScripts(queries: AsyncQuery[], executionResult: ExecutionResult) {
        queries.forEach(query => {
            try {
                this.scriptExecutor.buildFn(`return function(executionResult){${query.script}}`)(executionResult);
            } catch (e) {
                this.log(e);
            }
        });
    }

    private callHTTP(query: AsyncQuery, body: any) {
        return this.http.request(query.requestType, query.query, { body });
    }

    private log(e: unknown) {
        if (typeof e === 'string' && e === 'skip-error') {
            return;
        }

        this.logger.log(parseError(e));
    }
}
