import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class LockerActionsService {
    private static _lastReason: string = "";

    private static lockedMapCounterQueue: Map<string, number> = new Map<string, number>();

    private static readonly lockDelayMs: number = 100;
    private static readonly unlockDelayMs: number = 100;
    private static sync: NodeJS.Timeout = null;

    public static releaseLock() {
        LockerActionsService.lockedMapCounterQueue = new Map<string, number>();
    }

    static get lastReason(): string {
        return this._lastReason;
    }

    public static lockedStatus(lockPhrase?: string) {
        if (lockPhrase) {
            const val = LockerActionsService.lockedMapCounterQueue.get(lockPhrase);
            return val ? val > 0 : false;
        } else {
            let c = 0;
            LockerActionsService.lockedMapCounterQueue.forEach(value => c += value);
            return c > 0;
        }
    }

    public static lock(lockPhrase: string) {
        this._lastReason = lockPhrase;
        LockerActionsService.sync = setTimeout(() => {
            LockerActionsService.sync = null;
            const val = LockerActionsService.lockedMapCounterQueue.get(lockPhrase);
            LockerActionsService.lockedMapCounterQueue.set(lockPhrase, val && val >= 0 ? val + 1 : 1);
        }, LockerActionsService.lockDelayMs);
    }

    public static unlock(lockPhrase: string) {
        if(LockerActionsService.sync !== null && lockPhrase === this._lastReason){
            clearTimeout(LockerActionsService.sync);
            LockerActionsService.sync = null;
            return;
        }
        setTimeout(() => {
            const val = LockerActionsService.lockedMapCounterQueue.get(lockPhrase);
            LockerActionsService.lockedMapCounterQueue.set(lockPhrase, val && val > 0 ? val - 1 : 0);
        }, LockerActionsService.unlockDelayMs);
    }
}
