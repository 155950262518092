<div class="modal-header">
    <h2 class="mt-0">
        {{ header }}
    </h2>
    <button
        data-test-id="cross-button"
        type="button"
        class="close"
        aria-label="Close button"
        aria-describedby="modal-title"
        (click)="close()"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body d-flex flex-column" [formGroup]="form">
    <ng-content select="[organization-info]"></ng-content>

    <lib-input label="Name" formControlName="name" data-test-id="org-name" [maxlength]="maxLength.name.toString()"></lib-input>
    <lib-textarea
        label="Description"
        formControlName="description"
        resizeMode="none"
        data-test-id="org-description"
        [resize]="false"
        [maxlength]="maxLength.description.toString()"
    ></lib-textarea>
</div>

<div class="modal-footer">
    <button data-test-id="cancel-button" class="btn btn-secondary" (click)="close()">Cancel</button>
    <button data-test-id="save-button" [btnWithLoader]="form.pending" class="btn btn-primary" [disabled]="form.invalid" (click)="save()">
        {{ saveButtonText }}
    </button>
</div>
