import { Component, HostListener, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { WorkflowAction } from '../models/actions';
import { EditJobDefinitionService } from '../edit-job-definition.service';
import { WorkflowActionsConverter } from './workflow-actions.converter';
import { WorkflowActionBlock } from './workflow-action.block';
import { EditWorkflowActionComponent } from './edit-workflow-action/edit-workflow-action.component';
import { IssueElement } from '../models/issue-list.model';
import { JobDefinitionBuilderViewComponent } from './job-definition-builder-view/job-definition-builder-view.component';
import { WorkflowActionLine } from './workflow-actions.line';
import { DebugContext } from '../state/job-definition-debug.state';
import { JobDefinitionFacade } from '../state/job-definition.facade';

interface WorkflowBuilderLayout {
    blocks: WorkflowActionBlock[];
    lines: WorkflowActionLine[];
}

@Component({
    selector: 'dp-job-definition-builder',
    templateUrl: './job-definition-builder.component.html',
    styleUrls: ['./job-definition-builder.component.scss'],
    providers: [WorkflowActionsConverter],
})
export class JobDefinitionBuilderComponent {
    @Input() panelCollapsed: boolean;
    @Input() debugContext: DebugContext;
    @Input() loading: boolean;
    @Input() publish: boolean;
    @Input() debug: boolean;
    @Input() debuggedBlockName: string = null;

    @Output() forceRun = new EventEmitter();

    @ViewChild(JobDefinitionBuilderViewComponent, { static: false }) view: JobDefinitionBuilderViewComponent;

    layout$: Observable<WorkflowBuilderLayout> = this.store.builder$.pipe(
        map(({ blocks }) => {
            this.converter.reset();

            try {
                this.converter.init(blocks, this.store.breakpoints);
                this.converter.convert();

                return {
                    blocks: this.converter.generateBlocks(),
                    lines: this.converter.generateLines(),
                } as WorkflowBuilderLayout;
            } catch (e) {
                console.error(e);

                return {
                    blocks: [],
                    lines: [],
                };
            }
        }),
        tap(layout => this.handleDebugIssues(layout))
    );

    @HostListener('document:keydown', ['$event'])
    onKeydownHandler(event: KeyboardEvent) {
        if (event.code === 'Backquote') {
            this.forceRun.emit();
        }
    }

    constructor(
        public facade: JobDefinitionFacade,
        private store: EditJobDefinitionService,
        private modalService: NgbModal,
        private converter: WorkflowActionsConverter
    ) {}

    onDrop({ meta, to }: { meta: WorkflowAction; to: HTMLElement; toBlock: WorkflowActionBlock }) {
        if (!to) {
            return;
        }

        const { connector, name } = to.dataset;

        const block = this.converter.blocks.get(name);

        this.store.drop(meta, block, connector as any, this.converter.actionsNamesMap);
    }

    onDelete(block: WorkflowActionBlock) {
        this.store.delete(block);
    }

    onBreakpointToggle(name: string) {
        this.store.toggleBreakpoint(name);
    }

    onEditAction(block: WorkflowActionBlock, issue?: IssueElement) {
        const name = block.name;
        const nextBlockNames = block.getNextBlockNames(this.store.value.builder.blocks);

        const modalRef = this.modalService.open(EditWorkflowActionComponent, {
            windowClass: 'edit-action-modal',
            centered: true,
        });

        modalRef.componentInstance.action = block.action;
        modalRef.componentInstance.blockNames = this.converter.actionsNamesMap;
        modalRef.componentInstance.nextBlockNames = nextBlockNames;
        modalRef.componentInstance.elseNextBlockNames = nextBlockNames;
        modalRef.componentInstance.issue = issue;

        modalRef.result
            .then(({ data, deleted }) => {
                if (deleted) {
                    this.store.delete(block);
                } else {
                    this.store.workflowBuilder.changeBlock(name, block, data);
                }
            })
            .catch(() => {});
    }

    handleDebugIssues({ blocks }: WorkflowBuilderLayout) {
        const issues = blocks.reduce<IssueElement[]>((acc, block) => {
            if (block.formGroup && block.invalid) {
                acc = [
                    ...acc,
                    ...this.store.getIssuesList(block.formGroup, null).map(issue => ({
                        ...issue,
                        isActionBlock: true,
                        errorPath: ['actions', block.name, ...issue.errorPath],
                        block,
                    })),
                ];
            }

            return acc;
        }, []);

        setTimeout(() => {
            const newIssues = [...this.store.formIssues$.getValue().filter(({ isActionBlock }) => !isActionBlock), ...issues];
            this.store.formIssues$.next(newIssues);
            this.store.issueType = this.store.getIssueType(newIssues);
        });
    }
}
