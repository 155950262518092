import { GridListItem, GridsterService } from 'angular2gridster';

export interface GridsterEvent {
    gridster: GridsterService;
    item: GridListItem;
}

export class GridsterUtils {
    static patchApplySizeFn(event: GridsterEvent) {
        const applySizeFn = event.item.applySize;

        event.item.applySize = function(gridster) {
            if (!gridster) {
                return;
            }
            applySizeFn(gridster);
        };
    }

    static setGridElementSize(item: GridListItem, width: string, height: string) {
        const element = item.itemPrototype.$element;
        element.style.width = width;
        element.style.height = height;
    }

    static getGroupId(e: GridsterEvent): number {
        return parseInt(e.gridster.gridsterComponent.$element.dataset['groupId'], 10);
    }
}
