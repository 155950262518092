<div class="plugin-content px-1">
    <div class="control-container pt-20px pb-30px justify-content-end">
        <div class="col text pl-0 pr-0">
            <span context-help-id="tool-library"
                >Tool integration templates are required to configure tools. Modify an existing supported tool integration template or add
                your own.</span
            >
        </div>
        <div class="col pl-0 pr-0">
            <div class="row no-gutters">
                <button *ngIf="!dellBuild" type="button" class="btn btn-primary add-btn" (click)="handleAddToolTemplate()">
                    Add New Tool Template
                </button>
                <lib-search class="search mb-2" (searchText)="searchData($event)"></lib-search>
            </div>
        </div>
    </div>
    <button *ngIf="dellBuild" type="button" class="btn btn-primary add-btn ml-auto mt-2" (click)="handleAddToolTemplate()">
        Add New Tool Template
    </button>
    <perfect-scrollbar class="tool-content pr-3">
        <div *ngFor="let group of groups" class="pl-3">
            <ng-container>
                <span class="font-category pb-10px mb-20px d-block border-bottom">{{ group.category }}</span>
                <ul class="p-0 tool-listing clearfix mb-25px">
                    <li
                        *ngFor="let template of group.templates"
                        (click)="configureAccess(template)"
                        class="d-inline-flex align-items-center cursor-pointer"
                        ngbTooltip="{{ template.name }}"
                        placement="bottom-left"
                        tooltipClass="tool-tooltip"
                    >
                        <img [src]="template.imageUrl | imagePath" alt="" class="mr-10px" />
                        <div class="label flex-1">
                            {{ template.name }}
                        </div>
                    </li>
                </ul>
            </ng-container>
        </div>
    </perfect-scrollbar>
</div>
