import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectPipelinesService } from '../../../project-pipelines.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { PipelineBelongType, PipelineScriptVersion, VersionHistoryType } from '../../../models/project-pipeline-items';
import { facArrowInCircle, facCircle, facTransfer } from '@dagility-ui/shared-components/icons';
import { ModalService, Page, Pageable } from '@dagility-ui/kit';
import { map, switchMap, tap } from 'rxjs/operators';
import { ToasterService } from '@dagility-ui/shared-components';
import { PipelineScriptCompareModalComponent } from '../pipeline-script-compare-modal/pipeline-script-compare-modal.component';
import { CiCdAppSourcesService } from 'src/app/pages/ci-cd/api/ci-cd-app-sources.service';
import { SourcesBelongType } from 'src/app/pages/ci-cd/models/application-source.model';

@Component({
    selector: 'app-project-pipeline-script-versions',
    templateUrl: './project-pipeline-script-versions.component.html',
    styleUrls: ['./project-pipeline-script-versions.component.scss'],
    host: {
        class: 'h-100 d-flex flex-column',
    },
})
export class ProjectPipelineScriptVersionsComponent {
    @Input() projectId: number;
    @Input() pipelineId: number;
    @Input() modalType: VersionHistoryType = VersionHistoryType.COMPONENT || VersionHistoryType.PIPELINE;
    @Input() componentId: number;
    @Output() changeVersion = new EventEmitter();

    icons = {
        facCircle,
        facArrowInCircle,
        facTransfer,
    };

    loading = true;

    private pageSize = Math.floor((document.documentElement.clientHeight - 150) / 100);
    private pageable = new BehaviorSubject(this.createPageable(this.pageSize));
    private destroy$ = new Subject<boolean>();

    scriptVersion$: Observable<Page<PipelineScriptVersion>>;

    constructor(
        public activeModal: NgbActiveModal,
        private pipelineService: ProjectPipelinesService,
        private toaster: ToasterService,
        private modalService: ModalService,
        private ciCdAppSourcesService: CiCdAppSourcesService
    ) {}

    get title() {
        return this.modalType === VersionHistoryType.COMPONENT ? 'Change Log' : 'Version History';
    }

    ngOnInit() {
        this.scriptVersion$ = this.pageable.asObservable().pipe(
            switchMap(pageable => {
                if (this.modalType === VersionHistoryType.PIPELINE) {
                    return this.pipelineService.getPipelineScriptVersions(this.projectId, this.pipelineId, pageable);
                }
                if (this.modalType === VersionHistoryType.COMPONENT) {
                    return this.ciCdAppSourcesService.getProjectComponentScriptVersions(
                        this.componentId,
                        SourcesBelongType.PROJECT_COMPONENT,
                        pageable
                    );
                }
            }),
            map(response => {
                response.content.unshift({} as PipelineScriptVersion);
                return response;
            }),
            tap(() => (this.loading = false))
        );
    }

    showOlder(totalShowed: number) {
        this.loading = true;
        this.pageable.next(this.createPageable(totalShowed + this.pageSize));
    }

    createPageable(size: number) {
        return Pageable.pageAndSort('DESC', ['version'], 0, size);
    }

    useVersion(version: number) {
        this.pipelineService.usePipelineScriptVersion(this.projectId, this.pipelineId, version).subscribe(() => {
            this.toaster.successToast({ title: 'Success', content: 'Script version was changed' });
            this.pageable.next(this.createPageable(this.pageable.value.size));
            this.changeVersion.next();
        });
    }

    deleteVersion(version: number) {
        this.pipelineService.deletePipelineScriptVersion(this.projectId, this.pipelineId, version).subscribe(() => {
            this.toaster.successToast({ title: 'Success', content: 'Script version was deleted' });
            this.pageable.next(this.createPageable(this.pageable.value.size));
        });
    }

    compareWithVersion(version: number) {
        this.modalService.open(
            PipelineScriptCompareModalComponent,
            {
                centered: true,
                backdrop: 'static',
                size: 'lg',
                windowClass: 'pipeline-compare-modal',
            },
            {
                scripts$: this.pipelineService.comparePipelineScripts({
                    pipelineId: this.pipelineId,
                    pipelineType: PipelineBelongType.PROJECT,
                    comparedPipelineId: this.pipelineId,
                    comparedPipelineType: PipelineBelongType.PROJECT,
                    comparedPipelineVersion: version.toString(),
                }),
            }
        );
    }

    handleClickOutside() {
        this.activeModal.dismiss();
    }

    handleCloseClick() {
        this.activeModal.dismiss('Cross clicked');
    }

    ngOnDestroy() {
        this.destroy$.next();
    }
}
