<svg class="w-100 h-100" [attr.viewBox]="'0 0 ' + RADIUS * 2 + ' ' + height">
    <mask id="inner-circle">
        <circle [attr.cx]="CENTER.x" [attr.cy]="CENTER.y" [attr.r]="RADIUS - 0.5" fill="white"></circle>
        <circle [attr.cx]="CENTER.x" [attr.cy]="CENTER.y" [attr.r]="radius - 0.5" fill="black"></circle>
    </mask>

    <g mask="url(#inner-circle)">
        <circle [attr.cx]="CENTER.x" [attr.cy]="CENTER.y" [attr.r]="RADIUS" [attr.fill]="baseColor"></circle>
        <path [attr.d]="gaugePath" [attr.fill]="gaugeColor"></path>
        <path [attr.d]="whitePath" fill="white"></path>
    </g>
</svg>
