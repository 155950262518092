<div class="wrapper h-100 mt-2" *ngIf="options.issue; else loadingTmpl">
    <div class="issue d-inline-block h-100 position-relative" [class.action-selected]="flow[selectedFlowItem] || selectedSprint">
        <div #header class="issue-header mb-4">
            <span class="custom-badge custom-badge-background text-white size-s text-capitalize mr-2" [style.backgroundColor]="lastColor">
                {{ this.lastState?.to }}
            </span>
            <ng-container *ngIf="toolImages[options.issue.toolId] as image; else issueWithoutToolImage">
                <img
                    class="tool-icon"
                    [src]="image"
                    alt=""
                />
            </ng-container>
            <ng-template #issueWithoutToolImage>
                <fa-icon
                    class="tool-icon"
                    icon="QuestionInCircle"
                ></fa-icon>
            </ng-template>
            <ng-container *ngIf="!checkLinkWithNull(options.issue.linkValue); else issueWithoutLinkTmpl">
                <a [href]="options.issue.linkValue" target="_blank" class="issue-id mx-2">{{ options.issue.key }}</a>
            </ng-container>

            <ng-template #issueWithoutLinkTmpl>
                <span class="mx-2">{{ options.issue.key }}</span>
            </ng-template>
            <fa-icon class="issue-link-icon mr-2" icon="facLink"></fa-icon>
            <span class="mr-3">{{ options.issue.title }}</span>
            <span class="additional-info color-brand p-1 cursor-default" (mouseover)="showTooltip()" (mouseout)="hideTooltip($event)">
                <fa-icon icon="facExclamationCircle" class="mr-1"></fa-icon>
                <span>More info</span>
            </span>

            <div *ngIf="tooltip" class="issue-life-cycle-tooltip position-absolute" (mouseout)="hideTooltip($event)">
                <div class="row no-gutters mb-2">
                    <div class="col secondary-text-and-icons">Type:</div>
                    <div class="col">{{ options.issue.issueType ? options.issue.issueType : '-' }}</div>
                </div>
                <div class="row no-gutters mb-2">
                    <div class="col secondary-text-and-icons">Created:</div>
                    <div class="col">{{ options.issue.created ? (options.issue.created | date: 'dd MMM yyyy') : '-' }}</div>
                </div>
                <div class="row no-gutters mb-2">
                    <div class="col secondary-text-and-icons">Assignee:</div>
                    <div class="col">{{ options.issue.assignee ? options.issue.assignee : '-' }}</div>
                </div>
                <div class="row no-gutters mb-2">
                    <div class="col secondary-text-and-icons">Reporter:</div>
                    <div class="col">{{ options.issue.author ? options.issue.author : '-' }}</div>
                </div>
                <div class="row no-gutters mb-2">
                    <div class="col secondary-text-and-icons">Team:</div>
                    <div class="col">{{ options.issue.team ? options.issue.team : '-' }}</div>
                </div>
                <div class="row no-gutters mb-2">
                    <div class="col secondary-text-and-icons">Item Associated:</div>
                    <div class="col">{{ options.issue.associatedIssuesCount ? options.issue.associatedIssuesCount : '-' }}</div>
                </div>
                <div class="row no-gutters mb-2">
                    <div class="col secondary-text-and-icons">Total Commits:</div>
                    <div class="col">{{ options.issue.totalCommits ? options.issue.totalCommits : '-' }}</div>
                </div>
                <div class="row no-gutters mb-2">
                    <div class="col secondary-text-and-icons">Environment:</div>
                    <div class="col">{{ options.issue.environment ? options.issue.environment : '-' }}</div>
                </div>
            </div>
        </div>

        <!--20px * 2 - header's margins-->
        <perfect-scrollbar
            class="horizontal-scrollbar pr-3"
            [config]="horizontalScrollbarConfig"
            [style.height]="'calc(100% - ' + (header.offsetHeight + 20 * 2) + 'px)'"
        >
            <div class="flow">
                <div #flowHeader class="row no-gutters flow-header align-items-center">
                    <div class="col-date font-weight-bold">Status Categories:</div>
                    <div *ngFor="let status of options.statuses" class="col status">
                        <span
                            class="custom-badge custom-badge-background text-white float-left ml-15px"
                            [style.backgroundColor]="status.color"
                        >
                            {{ status.label }}
                        </span>
                    </div>
                </div>
                <perfect-scrollbar
                    *ngIf="!unconfiguredStatuses.length; else unconfiguredTmpl"
                    class="min-height-fit-content position-relative pb-20px"
                    [style.minWidth.px]="128 * options.statuses?.length"
                    [style.height]="'calc(100% - ' + (flowHeader.offsetHeight + 'px)')"
                >
                    <ng-container *ngFor="let item of flow; let i = index; let first = first; let last = last">
                        <div class="row no-gutters flow-item {{ first ? 'first' : '' }}" [class.first-splitter]="item.field === 'Sprint'">
                            <div
                                *ngIf="item.field === 'Sprint' as splitter"
                                class="sprint-splitter cursor-pointer"
                                [class.active]="selectedSprint?.id === i"
                                (click)="onSprintClick(i)"
                            >
                                Sprint Changed: {{ item.to }}
                            </div>
                            <div class="col-date position-relative">
                                <span *ngIf="!first" class="axis" [class.axis-with-sprint-splitter]="item.field === 'Sprint'">
                                    <div class="line"></div>
                                    <div class="line"></div>
                                    <div class="line"></div>
                                    <div class="line"></div>

                                    <ng-container *ngIf="item.field === 'Sprint'">
                                        <div class="line"></div>
                                        <div class="line"></div>
                                    </ng-container>
                                </span>
                                <span
                                    *ngIf="item.created; else lines"
                                    [ngClass]="{ 'custom-badge custom-badge-background text-white size-s': selectedFlowItem === i }"
                                >
                                    {{ item.created | date: 'dd MMM' }}
                                </span>

                                <ng-template #lines>
                                    <span class="axis axis-for-duplicate">
                                        <div class="line"></div>
                                        <div class="line"></div>
                                    </span>
                                </ng-template>

                                <ng-container *ngIf="item.type === 'Group' && !item.stepsCollapsed">
                                    <span
                                        *ngFor="let i of item.steps; let index = index"
                                        class="axis axis-for-duplicate"
                                        [style.margin-top]="40 + 24 * index + 'px'"
                                    >
                                        <div class="line"></div>
                                        <div class="line"></div>
                                    </span>
                                </ng-container>
                            </div>
                            <div
                                #col
                                *ngFor="let status of statusValues; let statusIndex = index"
                                class="col"
                                [class.col-with-splitter]="flow[i + 1]?.field === 'Sprint'"
                                [style.width]="'calc((100% - 100px) / ' + statusValues.length + ' )'"
                                [style.padding-bottom]="col.offsetWidth < smallColWidth ? '22px' : '20px'"
                                [style.margin-bottom]="col.offsetWidth < smallColWidth ? '-22px' : '-20px'"
                            >
                                <ng-container *ngIf="getColWidth(col) as colWidth">
                                    <ng-container *ngIf="item.status === status">
                                        <div
                                            class="flow-block has-details {{ item.field === 'Sprint' ? 'flow-block-sprint' : '' }}
                           {{
                                                last
                                                    ? ''
                                                    : 'link link-to-' +
                                                      (!flow[i + 1].status ||
                                                      statusValues.indexOf(status) === statusValues.indexOf(flow[i + 1].status)
                                                          ? 'down'
                                                          : statusValues.indexOf(status) < statusValues.indexOf(flow[i + 1].status)
                                                          ? 'right'
                                                          : 'left')
                                            }}

                        "
                                            [class.active]="selectedFlowItem === i"
                                            [class.compact]="colWidth < mediumColWidth"
                                            [class.link-with-splitter]="flow[i + 1]?.field === 'Sprint'"
                                            [class.flow-block-group]="colWidth <= mediumColWidth && item.type === 'Group'"
                                            [style.borderColor]="options.statuses[statusIndex].color"
                                            [style.min-width.px]="
                                                colWidth > mediumColWidth ? smallColWidth : colWidth > smallColWidth ? 70 : 30
                                            "
                                            [style.max-width.px]="
                                                colWidth > mediumColWidth ? smallColWidth : colWidth > smallColWidth ? 70 : 30
                                            "
                                            [style.width]="
                                                last
                                                    ? 'initial'
                                                    : getLinkPosition(
                                                          colWidth,
                                                          statusValues.indexOf(status),
                                                          flow[i + 1].status
                                                              ? statusValues.indexOf(flow[i + 1].status)
                                                              : statusValues.indexOf(status),
                                                          flow[i + 1].type === 'Group'
                                                      )
                                            "
                                            (click)="onFlowItemClick(i)"
                                        >
                                            <ng-container *ngIf="item.type !== 'Group'; else group">
                                                <div
                                                    class="flow-block-content"
                                                    [ngbTooltip]="
                                                        colWidth > mediumColWidth && !item.action
                                                            ? null
                                                            : item.action +
                                                              (item.type === 'CI/CD' || item.type === 'SCM'
                                                                  ? ' (' + item.data?.length + ')'
                                                                  : '') +
                                                              (item.logTime ? '\n' + item.logTime : '')
                                                    "
                                                    container="body"
                                                >
                                                    <ng-container
                                                        [ngTemplateOutlet]="itemIconTmpl"
                                                        [ngTemplateOutletContext]="{ $implicit: item }"
                                                    >
                                                    </ng-container>
                                                    <div *ngIf="colWidth > mediumColWidth" class="flow-block-action">
                                                        {{ item.action | titlecase }}
                                                        <span *ngIf="item.type === 'CI/CD' || item.type === 'SCM'">
                                                            ({{ item.data?.length }})
                                                        </span>
                                                    </div>
                                                    <div
                                                        *ngIf="colWidth > smallColWidth"
                                                        class="flow-block-time font-10px mr-5px"
                                                        style="white-space: break-spaces"
                                                    >
                                                        {{ item.logTime }}
                                                    </div>
                                                </div>
                                            </ng-container>

                                            <ng-template #group>
                                                <div class="d-flex flex-column flow-block-content h-100 ">
                                                    <div
                                                        class="d-flex align-items-center h-100 group-header"
                                                        (click)="handleStepsExpandingIcon(item)"
                                                    >
                                                        <svg
                                                            class="ml-1"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <circle
                                                                cx="12"
                                                                cy="12"
                                                                r="12"
                                                                [attr.fill]="options.statuses[statusIndex].color"
                                                            />
                                                            <path
                                                                d="M9.575 8.37818L12 9.78096L14.425 8.37818M12 12.5782V9.77818M14.5 10.8893V8.66707C14.4999 8.56965 14.4742 8.47396 14.4254 8.38962C14.3767 8.30527 14.3066 8.23523 14.2222 8.18652L12.2778 7.07541C12.1933 7.02665 12.0975 7.00098 12 7.00098C11.9025 7.00098 11.8067 7.02665 11.7222 7.07541L9.77778 8.18652C9.69341 8.23523 9.62333 8.30527 9.57457 8.38962C9.52582 8.47396 9.5001 8.56965 9.5 8.66707V10.8893C9.5001 10.9867 9.52582 11.0824 9.57457 11.1667C9.62333 11.2511 9.69341 11.3211 9.77778 11.3699L11.7222 12.481C11.8067 12.5297 11.9025 12.5554 12 12.5554C12.0975 12.5554 12.1933 12.5297 12.2778 12.481L14.2222 11.3699C14.3066 11.3211 14.3767 11.2511 14.4254 11.1667C14.4742 11.0824 14.4999 10.9867 14.5 10.8893Z"
                                                                stroke="white"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                            />
                                                            <path
                                                                d="M7.075 12.6723L9.5 14.075L11.925 12.6723M9.5 16.8723V14.0723M12 15.1834V12.9611C11.9999 12.8637 11.9742 12.768 11.9254 12.6837C11.8767 12.5993 11.8066 12.5293 11.7222 12.4806L9.77778 11.3695C9.69332 11.3207 9.59752 11.295 9.5 11.295C9.40248 11.295 9.30668 11.3207 9.22222 11.3695L7.27778 12.4806C7.19341 12.5293 7.12333 12.5993 7.07457 12.6837C7.02582 12.768 7.0001 12.8637 7 12.9611V15.1834C7.0001 15.2808 7.02582 15.3765 7.07457 15.4608C7.12333 15.5452 7.19341 15.6152 7.27778 15.6639L9.22222 16.775C9.30668 16.8238 9.40248 16.8495 9.5 16.8495C9.59752 16.8495 9.69332 16.8238 9.77778 16.775L11.7222 15.6639C11.8066 15.6152 11.8767 15.5452 11.9254 15.4608C11.9742 15.3765 11.9999 15.2808 12 15.1834Z"
                                                                stroke="white"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                            />
                                                            <path
                                                                d="M12.075 12.6723L14.5 14.075L16.925 12.6723M14.5 16.8723V14.0723M17 15.1834V12.9611C16.9999 12.8637 16.9742 12.768 16.9254 12.6837C16.8767 12.5993 16.8066 12.5293 16.7222 12.4806L14.7778 11.3695C14.6933 11.3207 14.5975 11.295 14.5 11.295C14.4025 11.295 14.3067 11.3207 14.2222 11.3695L12.2778 12.4806C12.1934 12.5293 12.1233 12.5993 12.0746 12.6837C12.0258 12.768 12.0001 12.8637 12 12.9611V15.1834C12.0001 15.2808 12.0258 15.3765 12.0746 15.4608C12.1233 15.5452 12.1934 15.6152 12.2778 15.6639L14.2222 16.775C14.3067 16.8238 14.4025 16.8495 14.5 16.8495C14.5975 16.8495 14.6933 16.8238 14.7778 16.775L16.7222 15.6639C16.8066 15.6152 16.8767 15.5452 16.9254 15.4608C16.9742 15.3765 16.9999 15.2808 17 15.1834Z"
                                                                stroke="white"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                            />
                                                        </svg>
                                                        <span *ngIf="colWidth > mediumColWidth" class="ml-2"
                                                            >{{ item.steps.length }} steps</span
                                                        >
                                                        <fa-icon
                                                            class="ml-auto steps-expanding-icon mr-10px"
                                                            [icon]="item.stepsCollapsed ? 'chevron-down' : 'chevron-up'"
                                                        ></fa-icon>
                                                    </div>

                                                    <div *ngIf="!item.stepsCollapsed" class="border-top pb-2">
                                                        <div
                                                            *ngFor="let step of item.steps"
                                                            class="mt-2 step-row px-1"
                                                            [ngbTooltip]="
                                                                colWidth > mediumColWidth && !step.action
                                                                    ? null
                                                                    : step.action +
                                                                      (step.type === 'CI/CD' || step.type === 'SCM'
                                                                          ? ' (' + step.data?.length + ')'
                                                                          : '') +
                                                                      (step.logTime ? '\n' + step.logTime : '')
                                                            "
                                                            container="body"
                                                            (click)="onStepGroupClick(step); $event.stopPropagation()"
                                                        >
                                                            <ng-container
                                                                [ngTemplateOutlet]="itemIconTmpl"
                                                                [ngTemplateOutletContext]="{ $implicit: step }"
                                                            >
                                                            </ng-container>
                                                            <span *ngIf="colWidth > mediumColWidth" class="ml-2 text-truncate">
                                                                {{ step.action }}
                                                                <span *ngIf="step.type === 'CI/CD' || step.type === 'SCM'">
                                                                    ({{ step.data?.length }})
                                                                </span>
                                                            </span>
                                                            <div
                                                                class="flow-block-time font-10px ml-auto"
                                                                style="white-space: nowrap; margin-right: -2px"
                                                            >
                                                                {{ step.logTime }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </div>
                                        <fa-icon
                                            *ngIf="item.field !== 'Sprint' && (!last || item.collapsed)"
                                            class="expanding-icon cursor-pointer"
                                            [icon]="item.collapsed ? icons.faPlusSquare : icons.faMinusSquare"
                                            (click)="handleExpandingIcon(item)"
                                        ></fa-icon>

                                        <ng-template #itemIconTmpl let-item>
                                            <ng-container *ngIf="toolImages[item.toolId] as image">
                                                <img
                                                    class="tool-icon mt-5px mr-5px float-left"
                                                    [style.margin-left]="colWidth > smallColWidth ? '5px' : '2px'"
                                                    [src]="image"
                                                    alt=""
                                                />
                                            </ng-container>
                                            <ng-container
                                                *ngIf="!item.toolId || (!toolImages[item.toolId] && item.toolId !== 'BUILD_STATUSES')"
                                            >
                                                <fa-icon
                                                    class="tool-icon unknown mr-5px mt-5px float-left"
                                                    icon="QuestionInCircle"
                                                    [style.margin-left]="colWidth > smallColWidth ? '5px' : '2px'"
                                                ></fa-icon>
                                            </ng-container>
                                            <span
                                                *ngIf="item.toolId === 'BUILD_STATUSES'"
                                                class="tool-icon mr-5px mt-5px float-left"
                                                [style.margin-left]="colWidth > smallColWidth ? '5px' : '2px'"
                                            >
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="#327de2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M8.88 16.5299L7.47 15.1199M15.13 22.1899L13.5 18.3599C15.07 17.7799 16.54 16.9999 17.9 16.0899L15.13 22.1899ZM5.64 10.4999L1.81 8.86991L7.91 6.09991C7 7.45991 6.22 8.92991 5.64 10.4999ZM21.61 2.38991C21.61 2.38991 16.66 0.268911 11 5.92991C8.81 8.11991 7.5 10.5299 6.65 12.6399C6.37 13.3899 6.56 14.2099 7.11 14.7699L9.24 16.8899C9.79 17.4499 10.61 17.6299 11.36 17.3499C13.5 16.5299 15.88 15.1899 18.07 12.9999C23.73 7.33991 21.61 2.38991 21.61 2.38991ZM14.54 9.45991C13.76 8.67991 13.76 7.40991 14.54 6.62991C15.32 5.84991 16.59 5.84991 17.37 6.62991C18.14 7.40991 18.15 8.67991 17.37 9.45991C16.59 10.2399 15.32 10.2399 14.54 9.45991ZM7.24 22.9999L10.88 19.3599C10.54 19.2699 9.21 19.1199 8.91 18.9099L4.83 22.9999H7.24ZM2 21.9999H3.41L7.18 18.2399L5.76 16.8299L2 20.5899V21.9999ZM1 19.1699L5.09 15.0899C4.88 14.7899 4.73 13.4699 4.64 13.1199L1 16.7599V19.1699Z"
                                                        stroke="none"
                                                        stroke-linejoin="round"
                                                    />
                                                </svg>
                                            </span>
                                        </ng-template>
                                    </ng-container>
                                </ng-container>
                                <div
                                    *ngIf="!item.status && flow[i - 1]?.status === status"
                                    class="{{
                                        last
                                            ? ''
                                            : 'splitter-link link link-' +
                                              statusValues.indexOf(flow[i - 1].status) +
                                              '-' +
                                              statusValues.indexOf(flow[i + 1].status)
                                    }}"
                                ></div>
                            </div>
                        </div>
                    </ng-container>
                </perfect-scrollbar>

                <ng-template #unconfiguredTmpl>
                    <div class="h-100 d-flex align-items-center justify-content-center no-data-text">
                        These statuses: {{ unconfiguredStatuses | json }} need to be added to layers settings
                    </div>
                </ng-template>
            </div>
        </perfect-scrollbar>
    </div>
    <div *ngIf="flow[selectedFlowItem] || selectedSprint || selectedGroupStep as action" class="side-panel d-inline-block">
        <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="closeSidePanel()">
            <span aria-hidden="true">&times;</span>
        </button>
        <perfect-scrollbar class="side-panel-scrollbar">
            <div class="px-25px py-15px">
                <dp-status-changed-details [actions]="action" [statuses]="options.statuses" [toolImages]="toolImages">
                </dp-status-changed-details>
            </div>
        </perfect-scrollbar>
    </div>
</div>

<ng-template #loadingTmpl>
    <lib-insight-loader class="w-100 loader"></lib-insight-loader>
</ng-template>
