import { AbstractControl, FormArray, FormGroup, ValidatorFn } from '@angular/forms';

import { appendError, ArrayByFieldDuplication, removeError } from '@dagility-ui/kit';

// @dynamic
export class FieldsGridValidators {
    static FieldOptionsLabelDuplication(formGroup: FormGroup) {
        const fieldsFormGroup = formGroup.get('fieldConfiguration') as FormGroup;
        return ArrayByFieldDuplication(fieldsFormGroup, 'options', 'label', 'duplicateLabel');
    }

    static FieldOptionsValueDuplication(formGroup: FormGroup) {
        const fieldsFormGroup = formGroup.get('fieldConfiguration') as FormGroup;
        return ArrayByFieldDuplication(fieldsFormGroup, 'options', 'value', 'duplicateValue');
    }

    static FieldNameUniqueValidation: ValidatorFn = (fg: FormGroup) => {
        const fieldsArray = fg.get('_fieldsArray').value;
        const fieldConfiguration = fg.get('fieldConfiguration');
        const controlName = fieldConfiguration ? fieldConfiguration.get('name') : null;
        const fieldName = controlName ? controlName.value : null;
        const oldFieldName = fg.get('_oldFieldName').value;
        const existElement =
            !fieldsArray || !fieldName
                ? null
                : fieldsArray.find((el: any) => el.name === fieldName || el.name.startsWith(fieldName + ".") || fieldName.startsWith(el.name + "."));
        const isValid = existElement && existElement.name !== oldFieldName;
        FieldsGridValidators.setOrRemoveError(controlName, 'unique', isValid);
        return isValid ? { unique: true } : null;
    };

    private static setOrRemoveError(control: AbstractControl, errorKey: string, set: boolean) {
        if (control) {
            if (set) {
                appendError(control, errorKey);
            } else {
                removeError(control, errorKey);
            }
        }
    }

    private static typeValidation(type: string, value: string): boolean {
        let typeRE: RegExp;
        switch (type) {
            case 'NUMBER':
                typeRE = new RegExp(`^[+-]?(?=\\.\\d|\\d)(?:\\d+)?(?:\\.?\\d*)(?:[eE][+-]?\\d+)?$`);
                break;
            case 'BOOLEAN':
                typeRE = new RegExp(`^(true|false)$`);
                break;
            case 'STRING':
            default:
                typeRE = new RegExp(`.*`);
                break;
        }
        return typeRE.test(value.toLowerCase());
    }

    private static formControlValueTypeValidation(typeControl: AbstractControl, valueControl: AbstractControl) {
        let result = false;
        if (valueControl && typeControl) {
            const value = valueControl.value;
            const typeValue = typeControl.value;
            result = value ? FieldsGridValidators.typeValidation(typeValue, value) : true;
            if (!result) {
                appendError(valueControl, 'wrongType');
                appendError(typeControl, 'wrongType');
            } else {
                removeError(valueControl, 'wrongType');
                removeError(typeControl, 'wrongType');
            }
        }
    }

    static FieldDefaultValueTypeValidation(formGroup: FormGroup) {
        const fieldFormGroup = formGroup.get('fieldConfiguration');
        const defaultValueControl = fieldFormGroup.get('defaultValue');
        const typeControl = fieldFormGroup.get('type');
        FieldsGridValidators.formControlValueTypeValidation(typeControl, defaultValueControl);
    }

    static FieldOptionsValueTypeValidation(formGroup: FormGroup) {
        const fieldFormGroup = formGroup.get('fieldConfiguration');
        const optionsArray = fieldFormGroup.get('options') as FormArray;
        const typeControl = fieldFormGroup.get('type');
        optionsArray.controls.forEach(group => {
            const valueControl = group.get('value');
            FieldsGridValidators.formControlValueTypeValidation(typeControl, valueControl);
        });
    }

    static FieldDefaultInOptionsValues(formGroup: FormGroup) {
        const fieldFormGroup = formGroup.get('fieldConfiguration');
        const optionsArray = fieldFormGroup.get('options') as FormArray;
        const defaultValueControl = fieldFormGroup.get('defaultValue');
        let valid = true;
        if (optionsArray && optionsArray.controls.length && defaultValueControl.value) {
            const optionsValueArray = optionsArray.controls.map(group => {
                return group.value.value;
            });

            valid = optionsValueArray.some(value => value === defaultValueControl.value);
        }
        if (!valid) {
            appendError(defaultValueControl, 'defaultOption');
        } else {
            removeError(defaultValueControl, 'defaultOption');
        }
    }
}
