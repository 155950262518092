import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ENV_TOKEN } from '@app/tokens';

@Injectable({
    providedIn: 'root',
})
export class WorkflowsPipelinesService {
    private appSourcesBaseUrl = `${this.env.buildApiURL}/jobs-management`;

    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {}

    getPipelines(workflowId: string): Observable<any> {
        return this.http.get(this.appSourcesBaseUrl + `/get-jobs-by-workflow/${workflowId}`);
    }
}
