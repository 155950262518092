<dp-edit-job-definition-breadcrumb [path]="store.path$ | async"></dp-edit-job-definition-breadcrumb>

<perfect-scrollbar class="flex-1 mt-3" [formGroup]="formSlice">
    <lib-dropdown
        class="item-container pb-3 d-block"
        label="Type"
        [items]="types"
        valueKey="id"
        labelKey="name"
        id="name"
        formControlName="type"
    >
    </lib-dropdown>

    <dp-configure-job-definition-select-type
        *ngIf="formSlice.get('type').value === valueType.VALUE; else stringTemplate"
        class="mb-3 item-container d-block"
        label="Value"
        [formControl]="formSlice.get('value')"
        [showError]="false"
        (change)="handleTypeChange($event)"
    >
    </dp-configure-job-definition-select-type>

    <ng-template #stringTemplate>
        <lib-input
            class="mb-3"
            label="Value"
            formControlName="value"
            [warningMessage]="definition.typingErrors.value ? warnings.typingError(definition.typingErrors.value) : null"
        ></lib-input>
    </ng-template>

    <div class="items-container" [formGroup]="formSlice">
        <dp-edit-job-definition-iterative-template
            [definition]="definition.parameters"
            [meta]="definition.meta"
            [formSlice]="formSlice.get('parameters')"
            key="parameters"
        >
        </dp-edit-job-definition-iterative-template>
    </div>
</perfect-scrollbar>
