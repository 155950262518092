import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { cloneDeep } from 'lodash';

import { IFullNameUser, ShortUser } from 'src/app/models/admin/user.models';

import { ICheckboxGroupItem } from '../checkbox-group/checkbox-group.interface';
import { CommunicationChannel, DEFAULT_COMMUNICATION_CHANNELS, FeatureToggleService, UserService } from '@app/services';
import { AuthService } from '@app/auth';
import { ENV_TOKEN } from '@app/tokens';
import { HealthService } from '@app/shared/services/admin/health-service';
import { ModalConfirmComponent, ModalService } from '@dagility-ui/kit';
import { DOCUMENT } from '@angular/common';
import { SlackNotificationsService } from '@app/shared/services/user/slack-notifications.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TeamsNotificationsService } from '@app/shared/services/user/teams-notifications.service';
import { ToasterService } from '@dagility-ui/shared-components';
import { ToastrService } from 'ngx-toastr';

declare const _: any;

@Component({
    selector: 'app-user-edit-form',
    templateUrl: './user-edit-form.component.html',
    styleUrls: ['./user-edit-form.component.scss'],
})
export class UserEditFormComponent implements OnInit, OnDestroy {
    @Input() userId: string;
    emailExist: boolean = false;
    @Input() disabledFields: string[];
    @Input() submit: EventEmitter<null>;
    @Input() profileUnsaved: boolean;
    @Output() save: EventEmitter<any> = new EventEmitter();
    @Output() isValid = new EventEmitter();
    public rolesCheckboxes$: Observable<ICheckboxGroupItem[]>;
    public managers$: Observable<IFullNameUser[]>;
    public managersLoading: boolean;
    public isAdmin: boolean;
    public formControl: FormGroup = this.fb.group({
        id: [''],
        firstName: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
        lastName: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
        email: ['', Validators.compose([Validators.email, Validators.required, Validators.maxLength(100)])],
        position: ['', Validators.maxLength(100)],
        managerId: [{ value: null, disabled: !this.authService.isAdmin() }, Validators.nullValidator],
        businessRoleId: [null, Validators.compose([Validators.required, Validators.nullValidator])],
        roles: [{}, Validators.minLength(1)],
        communicationChannels: [[], Validators.nullValidator],
    });
    public isCalibrate: boolean;
    businessRoles: any = [];
    businessRolesBeforeSearch: any = [];
    selectedCommunicationChannels: CommunicationChannel[] = [];
    channelReferences: any;
    notificationServiceActive: boolean = false;
    private userManagerId: string;
    private subscription: Subscription;
    private slackCode: string;

    constructor(
        @Inject(ENV_TOKEN) private env: Env,
        @Inject(DOCUMENT) private document: any,
        @Inject(DEFAULT_COMMUNICATION_CHANNELS) public defaultCommunicationChannels: CommunicationChannel[],
        private healthService: HealthService,
        private fb: FormBuilder,
        private userService: UserService,
        private authService: AuthService,
        private featureToggleService: FeatureToggleService,
        private modalService: ModalService,
        private slackService: SlackNotificationsService,
        private teamsService: TeamsNotificationsService,
        private route: ActivatedRoute,
        private router: Router,
        private toaster: ToasterService,
        private toastr: ToastrService,
        private teamsNotificationsService: TeamsNotificationsService
    ) {
        this.isCalibrate = this.env.calibrate?.enabled;
        if (!this.isCalibrate) {
            this.healthService.getIsServiceAlive('notification').subscribe(notificationServiceActive => {
                this.notificationServiceActive = notificationServiceActive;
                this.route.queryParams.subscribe(params => (this.slackCode = params['slack_code']));
            });
        }
    }

    ngOnInit() {
        this.userService.getUser(this.userId).subscribe(u => {
            this.userManagerId = u.managerId;
            this.getBusinessRoles();
            this.emailExist = !!u.email;
            const user = _.cloneDeep(u);
            user.businessRoleId = u.businessRoles && u.businessRoles.length ? u.businessRoles[0] : null;
            user.roles = {};
            u.roles.forEach(role => {
                user.roles[role] = true;
            });
            this.confirmTeamsAccount(user);
            if (this.notificationServiceActive) {
                this.userService.getUserCommunicationChannels().subscribe(
                    data => {
                        this.channelReferences = data.channelReferences;
                        this.selectedCommunicationChannels.push(
                            ...this.userService.getSelectedChannelsFromTransportList(data.communicationChannels)
                        );
                        user.communicationChannels = this.selectedCommunicationChannels;
                        this.formControl.patchValue(user);
                    },
                    () => this.formControl.patchValue(user)
                );
            } else {
                this.formControl.patchValue(user);
            }
            this.getManagers('');
        });

        this.disableFields();
        this.getUserRoles();
        this.onValueChanges();
        this.subscription = this.submit.subscribe(() => {
            this.onSubmit();
        });
    }

    isSelectedChannel(item: any) {
        return this.selectedCommunicationChannels.includes(item);
    }

    communicationChannelsHandler(event: any, item: CommunicationChannel) {
        if (item.defaultValues && item.defaultValues.notSelectable) {
            event.stopPropagation();
            event.preventDefault();
            return;
        }
        if ((item.transport.includes('slack') || item.transport.includes('teams')) && !this.isSelectedChannel(item)) {
            this.authorizeSlackOrTeams(item);
            return;
        }
        const itemPosition = this.selectedCommunicationChannels.indexOf(item);
        if (itemPosition === -1) {
            this.selectedCommunicationChannels.push(item);
            this.selectedCommunicationChannels = [...new Set(this.selectedCommunicationChannels)];
        } else {
            this.selectedCommunicationChannels.splice(itemPosition, 1);
        }
    }

    getBusinessRoles() {
        this.userService.getBusinessRoles().subscribe(obj => {
            this.businessRoles = obj;
            this.businessRolesBeforeSearch = obj;
        });
    }

    onValueChanges(): void {
        this.formControl.valueChanges.subscribe(() => {
            this.isValid.emit(this.formControl.status);
        });
    }

    getManagers(text: string): void {
        this.managersLoading = true;
        this.managers$ = this.userService.getUsersForManager(this.userId, text).pipe(
            mergeMap(result => {
                const itemsArray = result.content;
                this.managersLoading = false;

                if (this.userManagerId) {
                    return this.userService.getUser(this.userManagerId).pipe(
                        map(manager => {
                            if (itemsArray) {
                                itemsArray.push((manager as any) as ShortUser);
                                return itemsArray;
                            } else {
                                return [manager];
                            }
                        })
                    );
                } else {
                    return of(itemsArray || []);
                }
            }),
            map((content: IFullNameUser[]) => {
                return content.map((item: IFullNameUser) => {
                    item.fullName = ((item.firstName || '') + ' ' + (item.lastName || '')).trim();
                    return item;
                });
            })
        );
    }

    searchBusinessRoles(searchValue: any) {
        if (searchValue) {
            this.businessRoles = cloneDeep(
                this.businessRoles.filter((item: any) => {
                    return item.name.toLowerCase().includes(searchValue.toLowerCase());
                })
            );
        } else {
            this.businessRoles = cloneDeep(this.businessRolesBeforeSearch);
        }
    }

    private authorizeSlackOrTeams(item: CommunicationChannel) {
        let appName = item.transport[0].toUpperCase() + item.transport.slice(1);
        this.modalService
            .open(
                ModalConfirmComponent,
                {
                    centered: true,
                    backdrop: 'static',
                    windowClass: `login-modal`,
                },
                {
                    message: {
                        title: `Do you want to authorise in ${appName}?`,
                    },
                }
            )
            .result.then(() => {
                let redirectUrl = new Subject<string>();
                const slackAuth = () =>
                    this.slackService.getClientId().subscribe(clientId => {
                        const appLinkUrl = this.slackService.getAuthorizationUrl(clientId);
                        redirectUrl.next(appLinkUrl);
                    });
                const teamsAuth = () =>
                    this.teamsService.getTeamsAppLink().subscribe(appLinkUrl => {
                        redirectUrl.next(appLinkUrl);
                    });
                redirectUrl.subscribe(
                    url => {
                        this.document.location.assign(url);
                    },
                    () => {
                        this.toaster.errorToast({
                            title: 'Notification service',
                            content: `Can't attach ${appName} notifications channel. Please try again`,
                        });
                    }
                );
                switch (item.transport) {
                    case 'teams':
                        teamsAuth();
                        break;
                    case 'slack':
                        slackAuth();
                        break;
                    default:
                        return;
                }
            })
            .catch(() => this.formControl.controls['communicationChannels'].setValue(this.selectedCommunicationChannels));
    }

    private onSubmit(): void {
        this.touchControls(this.formControl);
        if (this.formControl.valid) {
            const user = _.cloneDeep(this.formControl.getRawValue());
            const roles: any[] = [];
            Object.entries(user.roles).forEach(role => {
                if (role[1]) {
                    roles.push(role[0]);
                }
            });
            user.roles = roles;
            user.email = user.email.toLowerCase();

            user.businessRoles = [user.businessRoleId];
            delete user.businessRoleId;
            this.emailExist = !!user.email;
            this.save.emit(user);
        }
    }

    private getUserRoles(): void {
        this.rolesCheckboxes$ = this.userService.getRoles().pipe(
            map(result => {
                const rolesArray = result
                    .filter(item => !item.dynamic && item.description)
                    .map(item => {
                        return {
                            label: item.description,
                            value: item.name,
                            disabled: item.persistent,
                        };
                    });
                if (this.authService.isAdmin() && this.authService.getUser().id === this.userId) {
                    const adminRole = rolesArray.find(role => role.value === 'ADMIN');
                    adminRole.disabled = true;
                }
                if ((this.authService.isAdmin() && !this.authService.isSubscriptionAdmin()) || !this.authService.isSubscriptionAdmin()) {
                    const notSubAdminRole = rolesArray.find(role => role.value === 'SUBSCRIPTION_ADMIN');
                    if (notSubAdminRole) {
                        notSubAdminRole.disabled = true;
                    }
                }
                if ((this.authService.isAdmin() && !this.authService.isSubscriptionAdmin()) || !this.authService.isSubscriptionAdmin()) {
                    const notSubUserRole = rolesArray.find(role => role.value === 'SUBSCRIPTION_USER');
                    if (notSubUserRole) {
                        notSubUserRole.disabled = true;
                    }
                }
                if (this.authService.isSubscriptionAdmin() && this.authService.getUser().id === this.userId) {
                    const subAdminRole = rolesArray.find(role => role.value === 'SUBSCRIPTION_ADMIN');
                    if (subAdminRole) {
                        subAdminRole.disabled = true;
                    }
                }
                if (this.authService.isSubscriptionAdmin() && this.authService.getUser().id === this.userId) {
                    const subUserRole = rolesArray.find(role => role.value === 'SUBSCRIPTION_USER');
                    if (subUserRole) {
                        subUserRole.disabled = false;
                    }
                }
                return rolesArray;
            })
        );
    }

    private touchControls(control: AbstractControl): void {
        control.markAsTouched();
        if ((control as any).controls) {
            Object.values((control as any).controls).forEach((ctl: AbstractControl) => {
                this.touchControls(ctl);
            });
        }
    }

    private disableFields(): void {
        if (this.disabledFields) {
            this.disabledFields.forEach(field => {
                this.formControl.get(field).disable();
            });
        }
    }

    private confirmTeamsAccount(user: any) {
        if (this.slackCode) {
            this.slackService.getSlackReference({ code: this.slackCode }).subscribe(() => {
                const slackChannel: CommunicationChannel = this.defaultCommunicationChannels.filter(
                    channel => channel.transport === 'slack'
                )[0];
                this.selectedCommunicationChannels.push(slackChannel);
                user.communicationChannels = this.selectedCommunicationChannels;
                this.formControl.patchValue(user);
            });
        }
        this.route.queryParams.subscribe(params => {
            const teamsConversationId = params.teamsConversationId;
            if (!teamsConversationId) {
                return;
            }
            this.teamsNotificationsService.confirmTeamsAccount(teamsConversationId).subscribe({
                complete: () => {
                    this.toastr.success(
                        'Your Teams account has been linked to PACE account successfully',
                        'Configuring Teams Notifications',
                        { disableTimeOut: true }
                    );
                    const slackChannel: CommunicationChannel = this.defaultCommunicationChannels.filter(
                        channel => channel.transport === 'teams'
                    )[0];
                    this.selectedCommunicationChannels.push(slackChannel);
                    user.communicationChannels = this.selectedCommunicationChannels;
                    this.formControl.patchValue(user);
                    this.onSubmit();
                },
                error: () => {
                    this.toastr.error('Can`t link with Teams account', 'Configuring Teams Notifications', { disableTimeOut: true });
                },
            });
        });
        this.router.onSameUrlNavigation = 'ignore';
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { slack_code: null, teamsConversationId: null },
            replaceUrl: true,
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.formControl.reset();
    }
}
