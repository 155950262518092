import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Model, ModelGraph } from '@app/shared/components/query-builder/models/model-graph.model';
import { ENV_TOKEN } from '@app/tokens';
import { ModelGraphSaveAsModelRequest } from '@app/shared/components/query-builder/models/model-graph-actions.model';

@Injectable({
    providedIn: 'root',
})
export class ModelGraphService {
    private readonly baseUrl = `${this.env.querybuilderApiURL}/qb/graph`;

    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {}

    getGraph(id: string): Observable<ModelGraph> {
        return this.http.get<ModelGraph>(`${this.baseUrl}/${id}`);
    }

    createGraph(model: ModelGraph): Observable<ModelGraph> {
        return this.http.post<ModelGraph>(`${this.baseUrl}`, model);
    }

    updateGraph(modelGraph: ModelGraph, id: string): Observable<ModelGraph> {
        return this.http.put<ModelGraph>(`${this.baseUrl}/${id}`, modelGraph);
    }

    deleteGraph(): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/{$id}`);
    }

    saveAsModel(request: ModelGraphSaveAsModelRequest): Observable<Model> {
        return this.http.post<Model>(`${this.baseUrl}/as-model`, request);
    }

    cloneGraph(modelGraphUid: ModelGraph): Observable<ModelGraph> {
        return this.http.post<ModelGraph>(`${this.baseUrl}/${modelGraphUid}/clone`, null);
    }
}
