<ng-container [formGroup]="form">
    <lib-dropdown
        *ngIf="sources$ | obsWithStatus | async as sourcesObs"
        class="mb-3"
        formControlName="source"
        [items]="sourcesObs.value"
        [loading]="sourcesObs.loading"
        [clearable]="false"
        placeholder="Select Source"
        label="Source"
        appendTo="body"
    ></lib-dropdown>
    <lib-textarea class="mb-3" formControlName="query" label="Query" [resize]="false" resizeMode="vertical"></lib-textarea>
</ng-container>
<button class="btn  btn-primary align-self-end" (click)="handleExecute()">Execute</button>

<lib-stream-wrapper class="mt-3 flex-1 d-flex overflow-auto" *ngIf="dataSource$" [dataStream$]="dataSource$" errorCssClass="w-100">
    <ng-template contentTemplate let-rs="data">
        <table>
            <thead>
                <tr>
                    <th [id]="c.title" *ngFor="let c of rs.columns">
                        {{ c.title }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of rs.data">
                    <td *ngFor="let c of rs.columns">
                        {{ row[c.title] | json | slice: 0:100 }}
                    </td>
                </tr>
            </tbody>
        </table>
    </ng-template>
</lib-stream-wrapper>
