import { EndBlock, WorkflowActionBlock } from './workflow-action.block';

const RADIUS = 10;
const ARROW_LENGTH = 0;

const INTERLEVEL_X_GAP = 20;
const INTERLEVEL_Y_GAP = 25;
const TO_CONTAINER_LINE_GAP = 10;
const X_GAP = 30;
const ELSE_THIS_BRANCH_GAP = 15;

interface Point {
    x: number;
    y: number;
}

export class WorkflowActionLine {
    public path: string = '';
    public isLoopLine = false;
    name: string = '';

    private generateLines = {
        block: {
            block: (from: Point, to: Point) => {
                return `M ${from.x},${from.y} L ${to.x - ARROW_LENGTH},${from.y}`;
            },
            container: (from: Point, to: Point) => this.containerConnector(from, to, 'right'),
        },
        container: {
            block: (from: Point, to: Point) => this.containerConnector(from, to, 'left'),
            container: (from: Point, to: Point) => this.containerConnector(from, to, 'left'),
        },
    };

    constructor(
        public from: WorkflowActionBlock,
        public to: WorkflowActionBlock,
        public interlevelLine = false,
        public isElseEndLine?: boolean,
        public lineToBranchFromElse = false
    ) {
        this.name = `${from.name}-${to.name}`;
    }

    generate() {
        if (this.path !== '') {
            return;
        }

        if (!this.from || !this.to) {
            this.path = '';
            return;
        }

        if (Math.abs(this.from.y - this.to.y) <= RADIUS && Math.abs(this.to.x - this.from.end.x) <= X_GAP) {
            this.path = this.generateLines.block.block(
                { x: this.from.end.x, y: this.from.middleCoords().y },
                { x: this.to.x, y: this.to.middleCoords().y }
            );

            return;
        }

        if (
            this.lineToBranchFromElse ||
            this.from.y > this.to.y ||
            (this.from.y < this.to.y && this.to.x - (this.from.x + this.from.width) === X_GAP)
        ) {
            this.generateElseThisBranchLine();
            return;
        }

        if (this.interlevelLine || (this.from.isConditionBlock() && this.from.y < this.to.y && this.from.x > this.to.x)) {
            const { y: y1 } = this.from.middleCoords();
            const x1 = this.from.end.x + INTERLEVEL_X_GAP;
            const mid = this.to.y - INTERLEVEL_Y_GAP - (this.to.isContainerBlock() ? TO_CONTAINER_LINE_GAP : 0);
            const y2 = mid - RADIUS;
            const { y: y3 } = this.to.middleCoords();
            const endX = this.to.x - INTERLEVEL_X_GAP;

            this.path = `M ${this.from.end.x},${y1}
                        L ${x1 - RADIUS},${y1}
                        A ${RADIUS},${RADIUS} 0 0 1 ${x1},${y1 + RADIUS}
                        L ${x1},${y2}
                        A ${RADIUS}, ${RADIUS} 0 0 1 ${x1 - RADIUS},${mid}
                        L ${endX + RADIUS},${mid}
                        A ${RADIUS},${RADIUS} 0 0 0 ${endX},${mid + RADIUS}
                        L ${endX},${y3 - RADIUS}
                        A ${RADIUS},${RADIUS} 0 0 0 ${endX + RADIUS},${y3}
                        L ${this.to.x - ARROW_LENGTH},${y3}
            `;
        } else if (this.to instanceof EndBlock) {
            if (this.isElseEndLine) {
                this.generateElseThisBranchLine();
            } else if (this.to.y === this.from.y) {
                this.path = this.generatePath();
            } else {
                const { y: y1 } = this.from.middleCoords();
                const { x: x1 } = this.to.middleCoords();

                this.path = `M ${this.from.end.x},${y1}
                             L ${x1 - RADIUS},${y1}
                             A ${RADIUS},${RADIUS} 0 0 1 ${x1},${y1 + RADIUS}
                             L ${x1},${this.to.y - ARROW_LENGTH}
                `;
            }
        } else if (this.from.y > this.to.y) {
            this.generateLineFromElseBranch();
        } else {
            this.path = this.generatePath();
        }

        this.isLoopLine = this.from.end.x >= this.to.end.x || this.from.y >= this.to.y;
    }

    generateLoopLinePath(padding: number) {
        const isXLoop = this.from.end.x >= this.to.end.x && this.from.y < this.to.y;

        const { y: startY } = this.from.middleCoords();
        const { y: endY } = this.to.middleCoords();
        const y1 = Math.max(this.to.end.y, this.from.end.y) + INTERLEVEL_Y_GAP - 5;
        const maxY = isXLoop ? Math.max(this.to.end.y, this.from.end.y) + padding : Math.max(startY, endY);
        const x = this.to.x - INTERLEVEL_X_GAP - 5;
        const toNext = this.to.x > this.from.x;
        const midX = this.from.end.x + (this.to.x - this.from.end.x) / 2;
        if (Math.abs(startY - endY) <= RADIUS && Math.abs(this.to.x - this.from.end.x) <= X_GAP) {
            this.path = this.generateLines.block.block({ x: this.from.end.x, y: startY }, { x: this.to.x, y: endY });

            return;
        }
        this.path = `M ${this.from.end.x},${startY}
                    ${
                        toNext
                            ? `L ${midX - RADIUS},${startY}
                               A ${RADIUS},${RADIUS} 0 0 0 ${midX},${startY - RADIUS}
                               L ${midX},${endY + RADIUS}
                               A ${RADIUS},${RADIUS} 0 0 1 ${midX + RADIUS},${endY}
                            `
                            : `L ${this.from.end.x + ELSE_THIS_BRANCH_GAP - RADIUS},${startY}
                        A ${RADIUS},${RADIUS} 0 0 1 ${this.from.end.x + ELSE_THIS_BRANCH_GAP},${startY + RADIUS}
                        L ${this.from.end.x + ELSE_THIS_BRANCH_GAP},${y1 - RADIUS}
                        A ${RADIUS},${RADIUS} 0 0 1 ${this.from.end.x + ELSE_THIS_BRANCH_GAP - RADIUS},${y1}
                        L ${x + RADIUS},${y1}
                        A ${RADIUS},${RADIUS} 0 0 1 ${x},${y1 - RADIUS}
                        L ${x},${endY + RADIUS}
                        A ${RADIUS},${RADIUS} 0 0 1 ${x + RADIUS},${endY}`
                    }
                    L ${this.to.x - ARROW_LENGTH},${endY}
        `;

        return maxY;
    }

    generateLineFromElseBranch() {
        const { x: startX } = this.from.end;
        const { y: startY } = this.from.middleCoords();
        const { x: endX } = this.to.middleCoords();

        this.path = `M ${startX},${startY}
                    L ${endX - RADIUS},${startY}
                    A ${RADIUS},${RADIUS} 0 0 0 ${endX},${startY - RADIUS}
                    L ${endX},${this.to.end.y + ARROW_LENGTH}
        `;
    }

    generateElseThisBranchLine() {
        const { y: startY } = this.from.middleCoords();
        const isInterLevelLine = this.to.y !== this.from.y;
        const isNext = this.from.x < this.to.x;
        const up = this.from.y >= this.to.end.y;
        const back = this.from.x >= this.to.x;
        const isNextInterLevel = isInterLevelLine && isNext;
        const sign = isNextInterLevel ? -1 : 1;
        const isNextBlock = Math.abs(this.from.end.x - this.to.x) < 50;
        const direction = isInterLevelLine ? -1 : 1;
        const { y } = this.to.middleCoords();

        if (Math.abs(startY - y) <= RADIUS && Math.abs(this.to.x - this.from.end.x) <= X_GAP) {
            this.path = this.generateLines.block.block({ x: this.from.end.x, y: startY }, { x: this.to.x, y });

            return;
        }

        const x1 = this.from.end.x + ELSE_THIS_BRANCH_GAP;
        const y1 = (isInterLevelLine && !up ? this.to.y : this.to.end.y) + (up ? 1 : direction) * INTERLEVEL_Y_GAP - 5;
        const x2 = this.to.x - ELSE_THIS_BRANCH_GAP;
        const x3 = x2 + RADIUS;
        const endX = this.to.x - ARROW_LENGTH;

        this.path = `M ${this.from.end.x},${startY}
                    L ${x1 - RADIUS},${startY}
                    A ${RADIUS},${RADIUS} 0 0 ${up ? 0 : 1} ${x1},${startY + (up ? -1 : 1) * RADIUS}
                    L ${x1},${y1 + (up ? 1 : -1) * RADIUS}
                    ${
                        isNextBlock && isInterLevelLine
                            ? ''
                            : `A ${RADIUS},${RADIUS} 0 0 ${up && back ? 0 : up || back ? 1 : direction * sign === 1 ? 0 : 1} ${x1 +
                                  (back ? -1 : direction * sign) * RADIUS},${y1}
                        L ${x2 + (back ? 1 : -direction * sign) * RADIUS},${y1}
                        A ${RADIUS},${RADIUS} 0 0 ${
                                  up && !back ? 0 : back && this.from.y >= this.to.y ? 1 : sign === 1 ? 0 : 1
                              } ${x2},${y1 + (up ? -1 : -direction) * RADIUS}`
                    }
                    L ${x2},${y + (up ? 1 : direction) * RADIUS}
                    A ${RADIUS},${RADIUS} 0 0 ${up ? 1 : direction === 1 ? 1 : 0} ${x3},${y}
                    L ${x3 === endX ? endX + 1 : endX},${y}
        `;
    }

    containerConnector = (from: Point, to: Point, stickSide: 'left' | 'right') => {
        const d = ({ x, y }: any) => `${x},${y}`;

        if (Math.abs(from.y - to.y) <= RADIUS) {
            return `M ${from.x},${from.y} L ${to.x - ARROW_LENGTH},${to.y}`;
        }

        const toBottom = from.y < to.y;

        const mid = stickSide === 'left' ? from.x + 20 - RADIUS * 2 : to.x - 20;

        return `M ${d(from)}
             ${mid},${from.y}
             A${RADIUS},${RADIUS} 0 0 ${toBottom ? 1 : 0} ${mid + RADIUS},${from.y + (toBottom ? RADIUS : -RADIUS)}
             L ${mid + RADIUS},${from.y + (toBottom ? RADIUS : -RADIUS)}
             ${mid + RADIUS},${to.y + (toBottom ? -RADIUS : RADIUS)}
             A${RADIUS},${RADIUS} 0 0 ${toBottom ? 0 : 1} ${mid + RADIUS + RADIUS},${to.y}
             L${mid + RADIUS * 2},${to.y}
             ${to.x - ARROW_LENGTH},${to.y}`;
    };

    private generatePath() {
        const fromCoords = {
            x: this.from.end.x,
            y: this.from.middleCoords().y,
        };
        const toCoords = {
            x: this.to.x,
            y: this.to.middleCoords().y,
        };

        return this.generateLines[this.getBlockType(this.from)][this.getBlockType(this.to)](fromCoords, toCoords);
    }

    getBlockType(block: WorkflowActionBlock) {
        return block.isContainerBlock() || block.isConditionBlock() ? 'container' : 'block';
    }
}
