import { AfterViewInit, Component, ElementRef, HostBinding, Inject, NgZone, ViewChild } from '@angular/core';
import { fromEvent, merge, of } from 'rxjs';
import { startWith, map, delay, catchError, distinctUntilChanged } from 'rxjs/operators';
import { filter as filterObs } from 'rxjs/operators';

import { WidgetDebuggerState } from 'data-processor/lib/widget-library/widget-builder/services/widget.debugger';
import { WidgetTemplateInterpolationManager } from 'data-processor/lib/widget-library/widget-builder/services/widget-template.interpolation';
import { FILTER_DEFAULT_SIZES, InputFilter } from '../../../../models/any-widget.model';
import { BaseWidgetFilter } from '../base-widget-filter.component';
import { getWidgetWidth } from '../../../../services/widget-builder.util';
import { AnyWidgetStore } from '../../any-widget/any-widget.store';

@Component({
    selector: 'dp-input-filter.d-flex.align-items-end',
    template: `
        <lib-input
            class="size-s mr-2"
            [class.w-100]="filter.buttonText"
            [formControl]="formControl"
            [label]="filter.hideLabel ? '' : filter.label"
            [placeholder]="placeholdersText$ | async"
        ></lib-input
        ><button #btn *ngIf="filter.buttonText" class="btn btn-primary size-s min-width-fit-content">
            {{ filter.buttonText }}
        </button>
    `,
    styleUrls: ['./input-filter.component.scss']
})
export class InputFilterComponent extends BaseWidgetFilter implements AfterViewInit {
    private onChange: (_: any) => void;

    @ViewChild('btn', { read: ElementRef }) btn: ElementRef<HTMLButtonElement>;

    @HostBinding('style.width.px') get width() {
        const width = getWidgetWidth(FILTER_DEFAULT_SIZES.INPUT)(this.filter.width);

        return this.filter.buttonText ? width + this.buttonElement?.offsetWidth : width;
    }

    get buttonElement() {
        return this.btn?.nativeElement;
    }

    placeholdersText$ = (this.debuggerState && this.store?.workflow
        ? this.debuggerState.placeholders$.pipe(
              delay(0),
              map(() => this.store.workflow.placeholders),
              map(placeholders => new WidgetTemplateInterpolationManager(placeholders || {}).interpolate(this.filter.placeholderText)),
              catchError(err => {
                  console.error(err);

                  return of(this.filter.placeholderText);
              }),
              startWith(this.filter.placeholderText),
              map(placeholderText => ((placeholderText || '').includes('{') ? 'Search' : placeholderText))
          )
        : of(this.filter.placeholderText)
    ).pipe(
        map(placeholdersText => placeholdersText || ''),
        distinctUntilChanged()
    );

    constructor(
        @Inject(BaseWidgetFilter.FILTER_TOKEN) public filter: InputFilter,
        private store: AnyWidgetStore,
        private elementRef: ElementRef<HTMLElement>,
        private zone: NgZone,
        private debuggerState: WidgetDebuggerState
    ) {
        super(filter);
    }

    ngAfterViewInit(): void {
        this.zone.runOutsideAngular(() => {
            this.subscription = merge(
                !this.filter.buttonText ? fromEvent(this.elementRef.nativeElement, 'focusout') : fromEvent(this.buttonElement, 'click'),
                fromEvent(this.elementRef.nativeElement, 'keyup').pipe(
                    filterObs(({ code }: KeyboardEvent) => ['ENTER'].includes(code.toUpperCase()))
                )
            )
                .pipe(
                    BaseWidgetFilter.filterValueChanged(
                        () => this.formControl.value,
                        () => this.formControl.value
                    )
                )
                .subscribe(value => this.zone.run(() => this.onChange(value)));
        });
    }

    getFilterText(): { title: string; value: any } {
        return { title: this.filter.label, value: this.formControl.value.toString() };
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
}
