<app-header></app-header>
<section id="main-container-id" class="main-container position-relative">
    <app-announcement-banner></app-announcement-banner>
    <router-outlet></router-outlet>
</section>
<ng-http-loader
    id="globalHttpLoader"
    *ngIf="true"
    [backdrop]="true"
    [backgroundColor]="'#767676'"
    [entryComponent]="spinner"
    [debounceDelay]="1000"
    [minDuration]="500"
    [extraDuration]="0"
    [filteredUrlPatterns]="['user-tracking']"
>
</ng-http-loader>
<span app-voc-form="helpbutton"></span>
<app-tour-step-template></app-tour-step-template>
