import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CKEditorComponent } from 'ngx-ckeditor';
import { of } from 'rxjs';
import { catchError, mapTo, take } from 'rxjs/operators';
import { LazyLoadingScriptService } from '@dagility-ui/shared-components';

@Component({
    selector: 'app-ckeditor-modal',
    templateUrl: './ckeditor-modal.component.html',
    styleUrls: ['./ckeditor-modal.component.scss'],
})
export class CKEditorModalComponent implements OnInit {
    @Input() ckeditorPlaceholders: any;

    @ViewChild('editor') ckEditor: CKEditorComponent;

    readonly editorOptions = {
        toolbarGroups: [
            { name: 'document', groups: ['mode', 'document', 'doctools'] },
            { name: 'basicstyles', groups: ['basicstyles'] },
            { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'paragraph'] },
            { name: 'links', groups: ['links'] },
            { name: 'customGroup' },
        ],
        removeButtons: 'Strike,Subscript,Superscript,Unlink,Anchor',
        removePlugins: 'elementspath',
        extraPlugins: 'edgeopsemailnotifications',
        entities: false,
    };

    ckeditorData = '';

    private ckEditorScriptReady$ = this.lazyLoad.loadScript('/assets/js/ckeditor/ckeditor.js');
    ckEditorLoaded$ = this.ckEditorScriptReady$.pipe(
        mapTo(true),
        catchError(() => of(false))
    );

    constructor(
        private lazyLoad: LazyLoadingScriptService,
        public modal: NgbActiveModal
    ) {}

    change(data: any) {
        this.ckeditorData = data;
    }

    save() {
        this.modal.close({ cancel: false, value: this.ckeditorData });
    }

    cancel() {
        this.modal.dismiss({ cancel: true, value: null });
    }

    ngOnInit() {
        this.ckEditorLoaded$.pipe(take(1)).subscribe(() => {
            setTimeout(() => {
                this.addPlaceholderButton();
                this.ckEditor.writeValue(this.ckeditorData);
            });
        });
    }

    addPlaceholderButton() {
        const editor = this.ckEditor && this.ckEditor.instance;
        if (!editor) {
            return;
        }

        const placeholders = this.ckeditorPlaceholders;

        editor.ui.addRichCombo('my-combo', {
            label: 'Placeholders',
            title: 'Placeholders',
            toolbar: 'customGroup',

            panel: {
                multiSelect: false,
                attributes: { 'aria-label': 'Placeholders' },
            },

            init() {
                placeholders.forEach((placeholder: any) => this.add(placeholder.key, placeholder.value));
                setTimeout(() => {
                    const placeholderIframe = document.querySelector('.cke.cke_panel[id*="cke"]').children[0] as HTMLIFrameElement;

                    if (!placeholderIframe) {
                        return;
                    }

                    const style = document.createElement('style');
                    style.textContent =
                        '* {outline: none;}' +
                        '.cke_panel_list {     list-style-type: none;\n' +
                        '    font-size: 14px;\n' +
                        '    padding-left: 1rem;' +
                        '}' +
                        '.cke_panel_list li {margin-bottom: 0.25rem}' +
                        '.cke_panel_list a {' +
                        '    color: black;' +
                        '    text-decoration: none;' +
                        '}' +
                        '.cke_panel_list a:hover {' +
                        '    color: blue;' +
                        '}';
                    placeholderIframe.contentDocument.head.appendChild(style);
                });
            },

            onClick(value: any) {
                editor.focus();
                editor.insertHtml(`$\{${value}}`);
            },
        });
    }

    onClose() {
        this.modal.dismiss('closed');
    }
}
