<svg zoom #zoom="zoom">
    <style>
        .block {
            display: flex;
            position: relative;
            padding: 0 30px 0 15px;
            border-radius: 3px;
            background: #F1F9FE;
            box-shadow: -1px 5px 13px rgba(0, 0, 0, 0.06), 0 3px 9px rgba(0, 0, 0, 0.08);
            color: #311311;
            height: 28px;
        }
    </style>
    <g fill="none" stroke="#d8d8d8">
        <g stroke-width="1" pointer-events="all">
            <g *ngFor="let line of lines">
                <line [attr.x1]="line.x1" [attr.y1]="line.y1" [attr.x2]="line.x2" [attr.y2]="line.y2" />
            </g>
        </g>
        <g class="actions">
            <g *ngFor="let block of blocks" jdBlock [block]="block"></g>
        </g>
        <g class="labels">
            <g *ngFor="let label of channels" jdLabel [label]="label"></g>
        </g>
    </g>
</svg>
