import { Injectable, NgZone } from '@angular/core';
import { fromEvent } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { ModalService, ModalWarningComponent, TypedModalRef } from '@dagility-ui/kit';
import { AuthService } from '@app/auth';

const MIN_VIEWPORT_WIDTH = 1366;

@Injectable({
    providedIn: 'root',
})
export class ResolutionChecker {
    modalRef: TypedModalRef<any>;

    proceedClicked = false;
    constructor(private zone: NgZone, private modalService: ModalService, private authService: AuthService) {
        this.zone.runOutsideAngular(() => {
            fromEvent(window, 'resize')
                .pipe(
                    filter(() => !this.proceedClicked),
                    tap(() => {
                        const viewportWidth = document.documentElement.clientWidth;
                        if (viewportWidth < MIN_VIEWPORT_WIDTH && !this.modalRef) {
                            this.modalRef = this.modalService.open(
                                ModalWarningComponent,
                                { windowClass: 'restrict-delete-dialog' },
                                {
                                    message: {
                                        title: 'Your device does not currently meet UST PACE requirements',
                                        content:
                                            'For the best UST PACE experience, check that your resolution is set to 1920 x 1080 or 1366 x 768.',
                                        icon: 'facInfoCircle',
                                        iconFontSize: '20px',
                                        iconColor: 'var(--da-warning-base)',
                                    },
                                    confirmButtonText: 'Proceed',
                                    closeButtonText: 'Logout',
                                }
                            );
                            this.modalRef.result
                                .then((res: any) => {
                                    if (!res) {
                                        this.proceedClicked = true;
                                    }

                                    this.modalRef = null;
                                })
                                .catch(reason => {
                                    if (reason === 'cancel click') {
                                        this.zone.run(() => this.authService.logout());
                                    }
                                    this.modalRef = null;
                                });
                            return;
                        }
                        if (viewportWidth >= MIN_VIEWPORT_WIDTH && this.modalRef) {
                            this.modalRef.close(1);
                        }
                    }),
                )
                .subscribe();
        });
    }
}
