<form *ngIf="form" class="h-100 d-flex flex-column" [formGroup]="form">
    <perfect-scrollbar>
        <dp-widget-builder-help-form-block sectionControlName="summary" label="Summary"></dp-widget-builder-help-form-block>
        <lib-validation-errors class="d-block px-4" control="summary">
            <ng-template valError="required"> {{ isRequiredText }} </ng-template>
        </lib-validation-errors>
        <dp-widget-builder-help-form-block
            sectionControlName="description"
            label="How is this widget useful?"
        ></dp-widget-builder-help-form-block>
        <lib-validation-errors class="d-block px-4" control="description">
            <ng-template valError="required">{{ isRequiredText }}</ng-template>
        </lib-validation-errors>
        <dp-widget-builder-help-form-block
            sectionControlName="howScoreCalculated"
            label="How is this Score Calculated?"
        ></dp-widget-builder-help-form-block>
        <dp-widget-builder-help-form-block
            sectionControlName="howScoreCanImproved"
            label="How can this Score be Improved?"
        ></dp-widget-builder-help-form-block>
        <dp-widget-builder-help-form-insight></dp-widget-builder-help-form-insight>
    </perfect-scrollbar>

    <div class="d-flex px-4 py-3">
        <button class="btn btn-link mr-auto ml-1 mb-1" (click)="handlePreview()">
            <fa-icon class="mr-2" icon="facEyeOpen"></fa-icon> Preview
        </button>
        <button class="btn btn-secondary mr-3" (click)="handleReset()">Reset</button>
        <button [disabled]="!form.valid" type="submit" class="btn btn-primary mr-1" (click)="handleSave()">Save</button>
    </div>
</form>
