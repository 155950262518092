import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SRESquare} from 'data-processor/lib/widget-library/widget-builder/components/sre-components/sre-squares/models/sre-square.model';

@Component({
    selector: 'dp-sre-squares',
    templateUrl: './sre-squares.component.html',
    styleUrls: ['./sre-squares.component.scss'],
})
export class SreSquaresComponent {
    @Input() squares: SRESquare[];

    @Output() squareClicked = new EventEmitter();

    squareClickedHandler(value: any) {
        this.squareClicked.emit(value);
    }
}
