<div *ngIf="modal && !createTab" class="modal-header align-items-center border-0">
    <h2 class="modal-title mt-0">Create New Group</h2>
    <button class="close" (click)="handleCancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" [formGroup]="form">
    <lib-input
        class="mb-3"
        entityNameControl
        label="Group Name"
        formControlName="name"
        [customErrors]="{ unique: 'Group name already exists' }"
    ></lib-input>
    <ng-container formGroupName="definition">
        <lib-dropdown
            class="mb-3 d-block"
            label="Group Visualization"
            [items]="groupTypes"
            formControlName="type"
            [clearable]="false"
            [appendTo]="modal || createTab ? null : '.group-settings'"
        ></lib-dropdown>
    </ng-container>
</div>
<div *ngIf="!createTab" class="modal-footer border-0">
    <button class="btn btn-secondary mr-4" (click)="handleCancel()">Cancel</button>
    <button data-test-id="action-button" class="btn btn-primary" (submitAfterAsyncValidation)="handleSave()" [submittedControl]="form">
        Save
    </button>
</div>
