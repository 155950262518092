import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

@Component({
    selector: 'dp-debug-log',
    templateUrl: './debug-log.component.html',
    styleUrls: ['./debug-log.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'p-3',
    },
})
export class DebugLogComponent {
    @Input() set logs(logs: string[]) {
        this._logs = logs;
        if (!!this.perfectScrollbar) {
            requestAnimationFrame(() => {
                this.perfectScrollbar.directiveRef.scrollToBottom();
            });
        }
    }
    get logs() {
        return this._logs;
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _logs: string[] = [];

    @ViewChild(PerfectScrollbarComponent, { static: true }) perfectScrollbar: PerfectScrollbarComponent;
}
