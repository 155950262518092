import {
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    EnvironmentInjector,
    EventEmitter,
    Host,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    SkipSelf,
    Type,
    ViewContainerRef,
} from '@angular/core';
import { AbstractControl, ControlContainer, FormGroupDirective, NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';

import { AnyWidgetStore } from '../../../../lib/widget-library/widget-builder/components/widget/any-widget/any-widget.store';
import { WidgetWorkflow } from '../services/widget.flow';
import { BaseWidgetFilter } from '../components/widget/filters/base-widget-filter.component';
import { WidgetDebuggerState } from '../services/widget.debugger';
import { WidgetFilter } from '../models/any-widget.model';
import { widgetFiltersMap } from './widget-filters.map';
import { FilterSetDirective } from './filter-set.directive';

@Directive({
    selector: '[dpChartFilter]',
})
export class ChartFilterDirective extends NgControl implements OnInit, OnDestroy {
    @Input('dpChartFilter') filter: WidgetFilter;
    @Input() widgetId: number;
    @Input() widgetDebugger: WidgetDebuggerState;
    @Input() dummyFilter = false;
    @Input() popupFilter = false;
    @Input() filtersReset: EventEmitter<any>;
    @Input() filterComponentFactory: Function;

    // eslint-disable-next-line @angular-eslint/no-output-rename
    @Output('ngModelChange') update = new EventEmitter();

    control: AbstractControl;
    component: ComponentRef<BaseWidgetFilter>;
    subscription: Subscription = Subscription.EMPTY;

    get formDirective(): any {
        return this.parent ? this.parent.formDirective : null;
    }

    get path(): string[] {
        return [...this.parent.path, this.filter.placeholder];
    }

    get validator(): any {
        return null;
    }

    get asyncValidator(): any {
        return null;
    }

    get debuggerState() {
        return this.store?.debuggerState ?? this.widgetDebugger;
    }

    constructor(
        @Optional() @Host() @SkipSelf() private parent: ControlContainer,
        @Optional() private store: AnyWidgetStore,
        @Optional() private workflow: WidgetWorkflow,
        private resolver: ComponentFactoryResolver,
        private container: ViewContainerRef,
        @Optional() private filterSet: FilterSetDirective,
        private envInjector: EnvironmentInjector
    ) {
        super();
    }

    ngOnInit(): void {
        const factory = this.filterComponentFactory
            ? this.envInjector.runInContext(() => this.filterComponentFactory(this.filter) as Type<BaseWidgetFilter>)
            : widgetFiltersMap[this.filter.type];
        if (!factory) {
            return;
        }
        const component = this.resolver.resolveComponentFactory(factory);
        this.component = this.container.createComponent(
            component,
            0,
            Injector.create({
                providers: [
                    {
                        provide: BaseWidgetFilter.FILTER_TOKEN,
                        useValue: this.filter,
                    },
                    {
                        provide: WidgetDebuggerState,
                        useValue: this.debuggerState,
                    },
                    {
                        provide: FilterSetDirective,
                        useValue: this.filterSet,
                    },
                    {
                        provide: AnyWidgetStore,
                        useValue: this.store,
                    },
                ],
            })
        );
        this.valueAccessor = this.component.instance;
        this.component.instance.popup = this.popupFilter;
        this.component.instance.filtersReset = this.filtersReset;

        if (this.dummyFilter && !!this.workflow) {
            const control = (this.formDirective as FormGroupDirective).form.get(this.filter.placeholder);

            this.subscription = this.workflow.loaded$.pipe(startWith(null as unknown)).subscribe(() => {
                this.component.instance.writeValue(control.value);
            });
        }

        if (!this.dummyFilter) {
            this.control = this.formDirective.addControl(this);
        }
    }

    viewToModelUpdate(newValue: any) {
        this.update.emit(newValue);
    }

    destroy() {
        if (this.formDirective) {
            this.formDirective.removeControl(this);
        }
        if (this.component) {
            this.component.destroy();
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.destroy();
    }
}
