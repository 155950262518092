import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { MergeRequestApprover } from '../models/merge-request-approver';

@Component({
    selector: 'dp-merge-request-approvers',
    templateUrl: './merge-request-approvers.component.html',
    styleUrls: ['./merge-request-approvers.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MergeRequestApproversComponent implements OnInit {
    @Input() approvers: MergeRequestApprover[];

    readonly defaultAppoversCount = 5;

    showMoreButtonAvailable = false;
    showAll = false;

    ngOnInit() {
        this.showMoreButtonAvailable = this.approvers.length > this.defaultAppoversCount;
        this.showAll = !this.showMoreButtonAvailable;
    }

    showAllToggle() {
        this.showAll = !this.showAll;
    }
}
