import { Type } from '@angular/core';

import { BaseWidgetFilter } from '../components/widget/filters/base-widget-filter.component';
import { CheckboxFilterComponent } from '../components/widget/filters/checkbox-filter/checkbox-filter.component';
import { DateFilterComponent } from '../components/widget/filters/date-filter/date-filter.component';
import { DropdownFilterComponent } from '../components/widget/filters/dropdown-filter/dropdown-filter.component';
import { InputFilterComponent } from '../components/widget/filters/input-filter/input-filter.component';
import { RangeFilterComponent } from '../components/widget/filters/range-filter/range-filter.component';
/* eslint-disable */
import { ComplexNamedDropdownFilterComponent } from '../components/widget/filters/complex-named-dropdown-filter/complex-named-dropdown-filter.component';
import { RadioButtonGroupFilterComponent } from '../components/widget/filters/radio-button-group-filter/radio-button-group-filter.component';
import { NewRangeFilterComponent } from '../components/widget/filters/new-range-filter/new-range-filter.component';

import { WidgetFilterType } from '../models/any-widget.model';

export const widgetFiltersMap: Record<WidgetFilterType, Type<BaseWidgetFilter>> = {
    INPUT: InputFilterComponent,
    DROPDOWN: DropdownFilterComponent,
    RANGE: RangeFilterComponent,
    DATE: DateFilterComponent,
    CHECKBOX: CheckboxFilterComponent,
    HIDDEN: undefined,
    NEW_RANGE: NewRangeFilterComponent,
    COMPLEX_NAMED_DROPDOWN: ComplexNamedDropdownFilterComponent,
    RADIO_GROUP: RadioButtonGroupFilterComponent,
};
