import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { GridColumn, sortingDown, sortingUp } from '@dagility-ui/kit';
import { QueryBuilderStore } from '@app/shared/components/query-builder/store/query-builder.store';
import { ModelGraphActionsService } from '@app/shared/components/query-builder/services/model-graph-actions.service';
import { DataSetField } from '@app/shared/components/query-builder/models/model-graph.model';
import { FieldRef } from '@app/shared/components/query-builder/models/model-graph-actions.model';
import { ExpressionBuilderService, ExpressionHelperEvent } from '../expression-builder/expression-builder.service';
import { ExpressionBuilderComponent } from '../expression-builder/expression-builder.component';

interface GridRow {
    column: string;
    alias: string;
    table: string;
    orderby: string;
    selected: boolean;
    calculated: boolean;
    expression: string;
    expressionView: string;
    uid: string;
    srcModelUid?: string;
}

@Component({
    selector: 'app-qb-model-selection-table',
    templateUrl: './selection-table.component.html',
    styleUrls: ['./selection-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectionTableComponent {
    gridData: GridRow[] = [];
    gridColumns: GridColumn[] = [
        { title: '', field: 'edit', width: 'auto' },
        { title: 'Model', field: 'table', width: 'auto', sortingField: 'table', sortAsc: true },
        { title: 'Field', field: 'column', width: 'auto' },
        { title: 'Alias', field: 'alias', width: 'auto' },
        { title: 'Expression', field: 'expressionView', width: 'auto' },
        { title: 'Sort', field: 'orderby', width: 'auto' },
        { title: '', field: 'remove', width: 'auto' },
    ];
    gridValues: string[] = ['edit', 'table', 'column', 'alias', 'expressionView', 'orderby', 'remove'];
    sortIcons = { sortingUp, sortingDown };

    selectedRow?: GridRow;
    editableRow?: GridRow;
    isSwitchFunctionEditor: boolean = false;
    isViewEditor: boolean;
    isNewRowAdded: boolean;

    isEmptyRow: boolean = false;
    expressionCursor: number = 0;
    expressionString: string = null;
    expressionBuilderPosition: { left: number; top: number } = { left: 0, top: 0 };
    expressionInputElement?: HTMLInputElement;

    @ViewChild(ExpressionBuilderComponent) expressionBuilderComponentRef: ExpressionBuilderComponent;

    constructor(
        private elRef: ElementRef,
        private queryBuilderStore: QueryBuilderStore,
        private modelGraphActionsService: ModelGraphActionsService,
        private cdr: ChangeDetectorRef,
        private expressionBuilderService: ExpressionBuilderService
    ) {}

    @HostListener('document:queryBuilderChanged', ['$event'])
    gridDataFromFields = () => {
        const modelGraph = this.queryBuilderStore.modelGraph;

        this.gridData = modelGraph?.dataSetFields?.fields
            .filter(field => field.selected)
            .map(field => ({
                column: field.dsFieldName,
                alias: field.dsDataField,
                table: field.srcModelUid ? modelGraph.models.find(model => model.uid === field.srcModelUid).name : '',
                orderby: SelectionTableComponent.getOrderByDirectionName(field),
                calculated: field.calculated,
                selected: false,
                expression: field.expression || '',
                expressionView: this.expressionBuilderService.updateExpressionView(field.expression || ''),
                uid: field.uid,
                srcModelUid: field.srcModelUid,
            }));
        this.sort('table');
        if (modelGraph) {
            this.expressionBuilderService.updateGraphData(modelGraph);
        }
        this.cdr.detectChanges();
    };

    ngAfterViewInit() {
        this.gridDataFromFields();
    }

    private static getOrderByDirectionName(field: DataSetField): string {
        switch (field.orderBy?.dir) {
            case 'ASC':
                return 'Ascending';
            case 'DESC':
                return 'Descending';
            default:
                return '';
        }
    }

    sort(field: string) {
        if (field) {
            let column = this.gridColumns.find(c => c.sortingField === field);
            this.gridData?.sort((a: GridRow, b: GridRow) =>
                column.sortAsc ? a.table.localeCompare(b.table) : b.table.localeCompare(a.table)
            );
        }
    }

    saveEditableRow() {
        const sourceGraph = this.queryBuilderStore.globalModelGraph;
        const editableRow = this.editableRow;
        if (editableRow && sourceGraph) {
            if (this.isNewRowAdded) {
                if (editableRow.column && editableRow.expressionView) {
                    this.isNewRowAdded = false;
                    this.modelGraphActionsService
                        .addCalculateField({
                            sourceGraph: this.queryBuilderStore.globalModelGraph,
                            fieldName: editableRow.column,
                            dataField: editableRow.column,
                            expression: editableRow.expression,
                        })
                        .subscribe(result => this.queryBuilderStore.updateState(result));
                } else {
                    if (editableRow.column || editableRow.expressionView) {
                        this.isEmptyRow = true;
                        return;
                    }
                    this.gridData.splice(this.gridData.length - 1, 1);
                }
            } else {
                this.modelGraphActionsService
                    .updateCalculateField({
                        sourceGraph: this.queryBuilderStore.globalModelGraph,
                        dsFieldUid: editableRow.uid,
                        fieldName: editableRow.column,
                        dataField: editableRow.column,
                        expression: editableRow.expression,
                    })
                    .subscribe(result => this.queryBuilderStore.updateState(result));
            }
            this.closeEditor();
        }
    }

    closeEditor() {
        this.isEmptyRow = false;
        this.isNewRowAdded = false;
        this.isViewEditor = false;
        this.editableRow = null;
        this.expressionString = null;
    }

    isEditableColumn(column: string, row: GridRow) {
        return ['expressionView', 'column'].includes(column) && row.uid === this.editableRow?.uid;
    }

    onClickInput(column: string) {
        this.expressionInputElement = this.elRef.nativeElement.querySelector('#expressionViewInputRef');
        const expressionBuilderElement: Element = this.elRef.nativeElement.querySelector('#expressionBuilderRef');

        this.isViewEditor = column === 'expressionView';
        if (this.expressionInputElement && expressionBuilderElement) {
            this.cdr.detectChanges();
            const editorRect = expressionBuilderElement.getBoundingClientRect();
            const inputRect = this.expressionInputElement.getBoundingClientRect();
            this.expressionBuilderPosition.left = inputRect.x - editorRect.width + inputRect.width;
            this.expressionBuilderPosition.top = inputRect.y - editorRect.height + inputRect.height;
            const expressionView = this.editableRow['expression'];
            this.expressionString = expressionView;
            this.expressionCursor = this.expressionInputElement.selectionStart;
            if (expressionView && expressionView[0] === '{') {
                this.expressionBuilderComponentRef.setExpressionData(expressionView);
            } else {
                this.expressionBuilderComponentRef.focus(this.expressionCursor);
            }
        }
    }

    @HostListener('document:mousedown', ['$event'])
    onMouseDown(event: any) {
        if (this.isViewEditor || this.editableRow) {
            const expressionBuilderRef: Element = this.elRef.nativeElement.querySelector('#expressionBuilderRef');
            if (expressionBuilderRef && this.isViewEditor) {
                if (
                    !expressionBuilderRef.contains(event.target) &&
                    !['columnInputRef', 'expressionViewInputRef', 'fxButtonRef', 'expressionBuilderRef'].includes(event.target.id) &&
                    !(event.target.classList.contains('option-label') || event.target.classList.contains('ng-option')) &&
                    event.target.title !== 'Clear all'
                ) {
                    this.saveEditableRow();
                }
                return;
            }
            if (!['columnInputRef', 'expressionViewInputRef', 'fxButtonRef', 'expressionBuilderRef'].includes(event.target.id)) {
                return this.saveEditableRow();
            }
        }
    }

    onMouseOverRow(item: GridRow) {
        if (this.selectedRow) {
            this.selectedRow.selected = false;
        }
        item.selected = true;
        this.selectedRow = item;
    }

    onExpressionChanged(event: ExpressionHelperEvent) {
        if (event.type === 'expression-changed') {
            if (this.editableRow) {
                this.editableRow['expression'] = this.expressionString = event.expression;
                this.editableRow['expressionView'] = event.expressionView;
                this.cdr.detectChanges();
            }
            return;
        }
        if (event.type === 'expression-canceled') {
            if (this.editableRow['column'] && this.editableRow['expression'] && !this.isNewRowAdded) {
                this.closeEditor();
                return;
            }
            this.editableRow['column'] = '';
            this.editableRow['expression'] = '';
            this.editableRow['expressionView'] = '';
            this.saveEditableRow();
            return;
        }

        if (event.type === 'expression-complete') {
            this.saveEditableRow();
        } else {
            if (this.editableRow) {
                this.editableRow['expression'] = this.expressionString = event.expression;
                this.editableRow['expressionView'] = event.expression;
                this.cdr.detectChanges();
            }
        }
    }

    onEditRow(item: GridRow) {
        this.isViewEditor = false;
        if (this.editableRow) {
            if (this.editableRow.uid !== item.uid) {
                this.saveEditableRow();
                this.editableRow = item;
            } else {
                this.saveEditableRow();
            }
        } else {
            this.editableRow = item;
        }
    }

    onRemoveRows() {
        const fields: FieldRef[] = this.gridData
            .filter(f => f.selected)
            .map(f => ({
                srcModelUid: f.srcModelUid,
                dsFieldUid: f.uid,
            }));
        fields.push({ srcModelUid: this.selectedRow.srcModelUid, dsFieldUid: this.selectedRow.uid });
        this.modelGraphActionsService
            .unselectDsField({ fields, sourceGraph: this.queryBuilderStore.globalModelGraph })
            .subscribe(result => this.queryBuilderStore.updateState(result));

        this.closeEditor();
    }

    onKeyPressed(event: any) {
        switch (event.key) {
            case 'Enter':
                event.preventDefault();
                if (!this.isViewEditor && this.editableRow?.column && this.editableRow?.expressionView) {
                    this.saveEditableRow();
                }
                break;
        }
    }

    onAddNew() {
        if (!this.isNewRowAdded && this.queryBuilderStore.modelGraph) {
            const newRow = {
                column: '',
                alias: '',
                table: '',
                orderby: '',
                calculated: true,
                selected: false,
                expression: '',
                expressionView: '',
                uid: '',
            };
            this.gridData.push(newRow);
            this.editableRow = newRow;
            this.isNewRowAdded = true;

            setTimeout(() => {
                const grid = this.elRef.nativeElement.querySelector('#dataGridRef');
                if (grid) {
                    const tbody = grid.getElementsByTagName('tbody')[0];
                    if (tbody) {
                        tbody.scrollTop = tbody.scrollHeight - tbody.clientHeight;
                    }
                }
            }, 50);
        }
    }

    handleCheckbox() {
        this.modelGraphActionsService
            .setUniqueSelection({
                unique: !this.isCheckboxSelected(),
                sourceGraph: this.queryBuilderStore.globalModelGraph,
            })
            .subscribe(result => this.queryBuilderStore.updateState(result));
    }

    isCheckboxExists() {
        return this.queryBuilderStore.modelGraph;
    }

    isCheckboxSelected() {
        return this.queryBuilderStore.modelGraph?.dataSetFields?.unique;
    }

    onOpenFunctionEditor() {
        this.isSwitchFunctionEditor = !this.isSwitchFunctionEditor;
        this.onClickInput('expressionView');
    }
}
