import { Component, Inject } from '@angular/core';
import { ComponentOutletDirective, WidgetExtensionInnerViewContext } from 'data-processor';

@Component({
    selector: 'app-alert-list-modal-wrapper',
    template: `
        <app-alert-list-modal [context]="context" (close)="context.onClickOutside()"></app-alert-list-modal>
    `,
    styleUrls: ['alert-list-modal-wrapper.component.scss'],
})
export class AlertListModalWrapperComponent {
    constructor(@Inject(ComponentOutletDirective.COMPONENT_OUTLET_CONTEXT) readonly context: WidgetExtensionInnerViewContext) {}
}
