import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BasicWidgetHttpClient } from './basic-widget-http-client';

const headers = new HttpHeaders({
    'skip-global-error': 'true',
});
const options = { headers };

@Injectable({
    providedIn: 'root',
})
export class WidgetHelpSupportService extends BasicWidgetHttpClient {
    getFilteredData(title: string) {
        return (JSON.parse(sessionStorage.getItem('helpSupportData'))['data'] || []).filter((it: any) => it.attributes.title.trim() === title);
    }
}
