import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

import { ResizeObserverService } from '@dagility-ui/kit';

const SMALL_VIEW_WIDTH = 390;

@Component({
    selector: 'lib-tile-chart',
    templateUrl: './tile-chart.component.html',
    styleUrls: ['./tile-chart.component.scss'],
    providers: [ResizeObserverService],
})
export class TileChartComponent implements OnInit {
    @Input() data: any;
    @Input() tilesInRow = 3;
    @Output() tileClick = new EventEmitter<Record<string, any>>();

    dataLoad = false;

    headerData: any[] = [];
    valueData: any[] = [];
    ratingData: any[] = [];
    colorData: any[] = [];
    tempHeaderData: any[] = [];
    measuresToolTipData: any[] = [];

    smallView$ = this.resizeObserverService.observe$(this.elementRef).pipe(
        startWith(true as boolean),
        map(() => this.elementRef.nativeElement.offsetWidth <= SMALL_VIEW_WIDTH),
        distinctUntilChanged()
    );

    constructor(private resizeObserverService: ResizeObserverService, private elementRef: ElementRef<HTMLElement>) {}

    ngOnInit() {
        let resultData;
        if (this.data.result) {
            resultData = this.data.result;
        } else {
            resultData = this.data;
        }

        this.processData(resultData);
    }

    processTileData(tileData: TileChartDataModel['tileData']) {
        let tempHeaderData = [];
        let tempValueData = [];
        let tempRatingData = [];
        let tempColorData = [];
        let i = 0;

        for (const value of tileData['headers']) {
            tempHeaderData.push(value);
            tempValueData.push(tileData['value'][i]);
            tempRatingData.push(tileData['rating'][i]);
            tempColorData.push(this.getColor(value, tileData['rating'][i]));
            if ((i + 1) % this.tilesInRow === 0 || tileData['headers'].length - 1 === i) {
                this.headerData.push(tempHeaderData);
                this.valueData.push(tempValueData);
                this.ratingData.push(tempRatingData);
                this.colorData.push(tempColorData);
                tempHeaderData = [];
                tempValueData = [];
                tempRatingData = [];
                tempColorData = [];
            }

            i = i + 1;
        }

        this.processRankingData(this.data.ratingData);
    }

    processRankingData(ratingData: TileChartDataModel['ratingData']) {
        let tempParamData = [];
        let k = 0;

        for (const key in ratingData) {
            if (this.data.ratingData.hasOwnProperty(key)) {
                const element = this.data.ratingData[key];
                tempParamData.push(element);
                if ((k + 1) % this.tilesInRow === 0 || ratingData.length - 1 === k) {
                    this.measuresToolTipData.push(tempParamData);
                    tempParamData = [];
                }
                k = k + 1;
            }
        }
    }

    processData(data: TileChartDataModel) {
        this.processTileData(data.tileData);

        setTimeout(() => {
            this.dataLoad = true;
        }, 200);
    }

    getColor(type: string, rating: any) {
        const colorData = this.data.colorData;
        let dynamicData;
        Object.keys(colorData).forEach((el: string) => {
            if (el.split(' ')[0] === '{seriesVal}') {
                dynamicData = el;
            }
        });

        if (type in colorData) {
            if (this.isAlphabet(rating) && colorData[type].hasOwnProperty(rating)) {
                return colorData[type][rating];
            } else {
                return colorData[type]['0'];
            }
        } else if (dynamicData) {
            return colorData[dynamicData]['0'];
        } else {
            return '';
        }
    }

    isAlphabet(value: string) {
        return /^[A-Z]$/.test(value) || value === '';
    }

    isEmpty(objectName: any) {
        return !Object.keys(objectName).length;
    }
}

export interface TileChartDataModel {
    tileData: {
        headers: string[];
        rating: any[];
        value: any[];
    };
    ratingData: Record<string, any>[];
    colorData: Record<string, Record<string, string>>;
    progress?: Record<string, boolean>;
}
