<ng-template *ngTemplateOutlet="gridMode ? toggleEnabled : toggleDisabled"></ng-template>

<ng-template #toggleDisabled>
    <div *ngIf="fieldsEditAllowed" class="header d-flex justify-content-between pt-10px mb-3">
        <trigger [(ngModel)]="gridMode"></trigger>
        <div class="title mr-auto" data-test-id="title">
            Manage Variables
            <ng-container *ngIf="fieldConfigContextHelpTemplate">
                <ng-template
                    *ngTemplateOutlet="fieldConfigContextHelpTemplate; context: { contextHelpId: 'field-configuration-properties' }"
                >
                </ng-template>
            </ng-container>
        </div>
    </div>
    <div
        *ngIf="formProperties.controls && formProperties.controls.length > 0"
        class="overflow-hidden start-form"
        style="min-height: 0;"
        [ngClass]="gridMode ? 'd-none' : 'd-flex'"
        [formGroup]="form"
    >
        <div class="d-flex w-100 overflow-hidden" formArrayName="properties">
            <perfect-scrollbar>
                <div class="pr-20px px-1 pb-3" *ngFor="let property of formProperties.controls; let i = index">
                    <div
                        [ngClass]="{
                            'align-items-end': property.valid,
                            'align-items-center': property.invalid || property.value.type === 'BOOLEAN'
                        }"
                        class="d-flex no-gutters"
                        [formGroupName]="'' + i"
                    >
                        <ng-container *ngIf="propertyOptionsMap[property.value.name] as options; else primitiveTmp">
                            <lib-dropdown
                                [setDisable]="isDisabled || property.value.readonly"
                                [appendTo]="'body'"
                                class="col"
                                [label]="property.value.name"
                                formControlName="value"
                                [items]="options"
                                valueKey="value"
                                labelKey="label"
                                id="field"
                                [multiple]="property.value.array"
                                (valueChange)="handleOptionArrayDropdownChange(property, $event)"
                            >
                            </lib-dropdown>
                        </ng-container>
                        <ng-template #primitiveTmp>
                            <lib-dropdown
                                *ngIf="property.value.array && !propertyOptionsMap[property.value.name]"
                                [setDisable]="isDisabled || property.value.readonly"
                                [appendTo]="'body'"
                                valueKey="value"
                                labelKey="label"
                                [items]="dropdownItems[property.value.name] || []"
                                [clearable]="true"
                                [multiple]="true"
                                [multiline]="true"
                                [addTag]="true"
                                [addTagFunction]="addTagFns[getCiCdFieldType(property.value.type)]"
                                [ngSelectItemsFilter]="true"
                                [labelRemovable]="true"
                                formControlName="value"
                                [label]="property.value.name"
                                class="col"
                                id="dropdown-field-{{ i }}"
                                (valueChange)="handleArrayDropdownChange(property, $event)"
                            >
                            </lib-dropdown>

                            <ng-container *ngIf="!property.value.array" [ngSwitch]="property.value.type">
                                <lib-input
                                    [readonly]="isDisabled || property.value.readonly"
                                    class="col"
                                    *ngSwitchCase="'STRING'"
                                    [label]="property.value.name"
                                    [propertiesCompletion]="propertiesCompletion"
                                    formControlName="value"
                                    id="field-1"
                                ></lib-input>
                                <lib-textarea
                                    [readonly]="isDisabled || property.value.readonly"
                                    class="col"
                                    *ngSwitchCase="'STRING_MULTILINE'"
                                    [label]="property.value.name"
                                    (keydown.enter)="$event.stopPropagation()"
                                    [customHeight]="true"
                                    [resize]="false"
                                    [propertiesCompletion]="propertiesCompletion"
                                    formControlName="value"
                                ></lib-textarea>
                                <lib-dropdown
                                    [setDisable]="isDisabled || property.value.readonly"
                                    *ngSwitchCase="'CREDENTIAL'"
                                    [items]="getIdentifiers(toolId != null ? this.toolId.toString() : null) | async"
                                    [label]="property.value.name"
                                    [addTag]="true"
                                    [ngSelectItemsFilter]="true"
                                    [placeholder]="'Select credentials'"
                                    valueKey="id"
                                    labelKey="displayName"
                                    formControlName="value"
                                    class="col overflow-x-auto"
                                    appendTo="body"
                                ></lib-dropdown>
                                <lib-input
                                    [readonly]="isDisabled || property.value.readonly"
                                    class="col"
                                    *ngSwitchCase="'NUMBER'"
                                    [label]="property.value.name"
                                    type="number"
                                    formControlName="value"
                                    [propertiesCompletion]="propertiesCompletion"
                                    (change)="handleNumberInputValueChange(property, $event)"
                                    id="field-3"
                                ></lib-input>
                                <div *ngSwitchCase="'BOOLEAN'">
                                    <checkbox
                                        class="col checkbox d-block px-0 pb-3"
                                        [undefinedState]="!property.get('mandatory').value"
                                        [value]="getCheckboxValue(property.value.value)"
                                        [disabled]="isDisabled || property.value.readonly"
                                        (change)="handleCheckboxValueChange(property, $event)"
                                    >
                                        {{ property.value.name }}
                                    </checkbox>
                                    <lib-validation-errors class="validation-errors" [control]="'value'">
                                        <ng-template valError="required">This field is required</ng-template>
                                    </lib-validation-errors>
                                </div>

                                <ng-container *ngSwitchCase="'GROOVY'">
                                    <lib-codemirror-editor
                                        [readonly]="isDisabled || property.value.readonly"
                                        class="col"
                                        *ngIf="!hideGroovyScriptFields"
                                        [label]="property.value.name"
                                        [mode]="'text/x-groovy'"
                                        [propertiesCompletion]="propertiesCompletion"
                                        formControlName="value"
                                    >
                                    </lib-codemirror-editor>
                                </ng-container>
                            </ng-container>
                        </ng-template>
                        <div
                            *ngIf="!property.value.readonly && !!property.value.description && !isDisabled"
                            class="ml-3 mb-1 font-14px description-icon"
                            [class.description-icon__boolean]="
                                property.value.type === 'BOOLEAN' && !property.value.array && !property.value.options?.length
                            "
                            placement="left"
                            container="body"
                            [ngbTooltip]="property.value.description"
                            tooltipClass="description-icon__tooltip"
                        >
                            <fa-icon
                                class="fa cursor-pointer"
                                icon="QuestionInCircle"
                                [class.align-text-top]="
                                    property.value.type === 'BOOLEAN' && !property.value.array && !property.value.options?.length
                                "
                                [class.align-text-middle]="
                                    !(property.value.type === 'BOOLEAN' && !property.value.array && !property.value.options?.length)
                                "
                            ></fa-icon>
                        </div>
                        <div
                            *ngIf="
                                !property.value.readonly &&
                                (!!property.value.defaultValue || property.value.type === 'BOOLEAN') &&
                                !isDisabled
                            "
                            class="ml-3 mb-1 font-14px description-icon default-value"
                            ngbTooltip="Restore default value"
                            [openDelay]="800"
                            placement="left"
                            container="body"
                            tooltipClass="description-icon__tooltip"
                            (click)="setDefaultValue(property, i)"
                            [class.description-icon__boolean]="
                                property.value.type === 'BOOLEAN' && !property.value.array && !property.value.options?.length
                            "
                        >
                            <fa-icon class="default-svg" icon="rotationLeftWithDot"></fa-icon>
                        </div>
                    </div>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
</ng-template>

<ng-template #toggleEnabled>
    <lib-fields-grid
        showToggle="true"
        [title]="'Manage Variables'"
        lockEmbedded="false"
        propertyMode="true"
        [gridBodyHeight]="'550px'"
        [addSystemProperties]="addSystemProperties"
        [hierarchyLevel]="hierarchyLevel"
        [fields]="formProperties.value"
        [readonly]="isDisabled"
        [uploadFn]="uploadFn"
        [propertiesCompletion]="propertiesCompletion"
        [fieldOptionsContextHelpTemplate]="fieldOptionsContextHelpTemplate"
        [fieldConfigContextHelpTemplate]="fieldConfigContextHelpTemplate"
        [notClickableCheckBox]="true"
        (toggleChange)="handleToggleFieldGrid()"
        (valueChange)="handleGridValueChange($event)"
        (modalWindowOpened)="handleModalWindowOpened($event)"
    >
    </lib-fields-grid>
</ng-template>
