import { isObject } from 'lodash';
import { ElementRef, QueryList } from '@angular/core';
import { DropdownItem, isDefined } from '@dagility-ui/kit';
import { ProgressItem, TileChartDataModel } from '@dagility-ui/shared-components';
import { GridOptions } from '@ag-grid-community/core';

import { WidgetType } from '../../models/any-widget.model';
import { AnyWidgetComponent } from '../../components/widget/any-widget/any-widget.component';
import { AnyWidgetChartModel, isTimeSeriesChartModel } from '../query-to-options.mapper';
import { BOXPLOT_CATEGORIES, convertToGridData, convertToTwoDimensionalGridData, getOutliersMap } from './widget.exporter.util';

export const ELEMENT_DELIMITER = ',';
const CSV_TYPE = 'text/csv';
export const LINE_SEPARATOR = '\r\n';

abstract class CSVExporter {
    exportHeader = true;

    constructor(
        public readonly data: AnyWidgetChartModel,
        protected readonly title: string,
        public elementRef: ElementRef<HTMLElement>,
        public complexWidgets: QueryList<AnyWidgetComponent>
    ) {}

    public parseCSVElement(element: any): string {
        if (Array.isArray(element)) {
            return `"${element.map(this.parseCSVElement).join(ELEMENT_DELIMITER)}"`;
        }
        if (isObject(element)) {
            return `"${JSON.stringify(element)}"`;
        }

        if (typeof element === 'string' && /"|,|(\r\n|\n|\r)/.test(element)) {
            return `"${element.replace(new RegExp('"', 'g'), '""')}"`;
        }

        return element;
    }

    export(id: any, widgetType: WidgetType, filterComponents: any): Promise<Blob> {
        const dataBlob = this.process(widgetType);
        return Promise.resolve(new Blob([dataBlob], { type: CSV_TYPE }));
    }

    abstract process(widgetType: WidgetType): string;
}

export class GridCSV extends CSVExporter {
    process(widgetType: WidgetType): string {
        const data = convertToGridData(this.data);
        return (this.exportHeader ? data : data.slice(1))
            .map((arr: any) => arr.map(this.parseCSVElement.bind(this)).join(ELEMENT_DELIMITER))
            .join(LINE_SEPARATOR);
    }
}

export class GridTabCSV extends CSVExporter {
    process(widgetType: WidgetType): string {
        const tableData: { columnDefs: any; pinnedBottomRowData: any; rowData: any } = {
            columnDefs: [],
            pinnedBottomRowData: [],
            rowData: [],
        };
        this.data.options.items.forEach((element: any) => {
            tableData.columnDefs = [...element.table.tableData.columnDefs];
            tableData.pinnedBottomRowData = [...tableData.pinnedBottomRowData, ...element.table.tableData.pinnedBottomRowData];
            tableData.rowData = [...tableData.rowData, ...element.table.tableData.rowData];
        });
        this.data.options.tableData = tableData;
        const data = convertToGridData(this.data);
        return (this.exportHeader ? data : data.slice(1))
            .map((arr: any) => arr.map(this.parseCSVElement.bind(this)).join(ELEMENT_DELIMITER))
            .join(LINE_SEPARATOR);
    }
}

export class TwoDimensionalGridCSV extends CSVExporter {
    process(widgetType: WidgetType): string {
        const data = convertToTwoDimensionalGridData(this.data);
        return (this.exportHeader ? data : data.slice(1))
            .map((arr: any) => arr.map(this.parseCSVElement.bind(this)).join(ELEMENT_DELIMITER))
            .join(LINE_SEPARATOR);
    }
}

export class PieDoughnutCSV extends CSVExporter {
    process(widgetType: WidgetType): string {
        const [series] = this.data.options.series;
        let values = '';
        let seriesHeader = '';

        for (let i = 0; i < series.data.length; i++) {
            const header = series.data[i].name;
            const delimiter = i !== series.data.length - 1 ? ELEMENT_DELIMITER : '';
            seriesHeader += header + delimiter;
            const value = series.data[i].value.toString();
            values += value + delimiter;
        }

        return seriesHeader + LINE_SEPARATOR + values;
    }
}

export class BarLineCSV extends CSVExporter {
    getSeriesData(data: unknown) {
        if (isTimeSeriesChartModel(this.data) && Array.isArray(data)) {
            return data[1];
        }

        if (typeof data === 'object' && data.hasOwnProperty('value')) {
            return (data as any).value;
        }

        return data.toString();
    }

    process(widgetType: WidgetType): string {
        let dataString = ` ${ELEMENT_DELIMITER}`;
        for (let i = 0; i < this.data.options.categories.length; i++) {
            const cell = this.data.options.categories[i];
            const delimiter = i !== this.data.options.categories.length - 1 ? ELEMENT_DELIMITER : '';
            dataString += `${cell}${delimiter}`;
        }
        dataString += LINE_SEPARATOR;
        for (let i = 0; i < this.data.options.series.length; i++) {
            dataString += `${this.data.options.series[i].name}${ELEMENT_DELIMITER}`;
            const seriesLength = this.data.options.series[i].data.length;
            for (let j = 0; j < this.data.options.series[i].data.length; j++) {
                const dataDelimiter = j !== seriesLength - 1 ? ELEMENT_DELIMITER : '';
                dataString += `${this.getSeriesData(this.data.options.series[i].data[j] ?? '')}${dataDelimiter}`;
            }
            dataString += i === this.data.options.series.length - 1 ? '' : LINE_SEPARATOR;
        }
        return dataString;
    }
}

export class NestedCSV extends CSVExporter {
    process(widgetType: WidgetType): string {
        let dataString = '';
        for (const data of this.data.options.series.data) {
            let stringPart = '';
            stringPart = this.parseNodeElement(data);
            dataString += stringPart;
            dataString += LINE_SEPARATOR;
        }
        return dataString;
    }

    parseNodeElement(node: any) {
        let stringRow = '';
        stringRow += node.name + ' [' + node.value + `]${ELEMENT_DELIMITER}`;
        if (node.children.length) {
            for (const children of node.children) {
                stringRow += this.parseNodeElement(children);
            }
        }
        return stringRow;
    }
}

export class BoxplotCSV extends CSVExporter {
    process(widgetType: WidgetType): string {
        const points: number[][] = this.data.options.series[0].data;
        const categories: string[] = this.data.options.categories;
        const outliersByIdx = getOutliersMap(this.data);

        return (
            ['Label', ...BOXPLOT_CATEGORIES].join(ELEMENT_DELIMITER) +
            `${LINE_SEPARATOR}${categories
                .reduce<string[]>((rows, category, i) => {
                    const outliers = outliersByIdx[i] ?? [];
                    const outliersTemplate = outliers.length ? `${ELEMENT_DELIMITER}${outliers.join(ELEMENT_DELIMITER)}` : '';

                    return [...rows, `${category}${ELEMENT_DELIMITER}${points[i].join(ELEMENT_DELIMITER)}${outliersTemplate}`];
                }, [])
                .join(LINE_SEPARATOR)}`
        );
    }
}

export class TileCSV extends CSVExporter {
    process(widgetType: WidgetType): string {
        const tileChartData = (this.data.options.result as TileChartDataModel).tileData;

        return (
            ['Label', 'Value', 'Rating'].join(ELEMENT_DELIMITER) +
            LINE_SEPARATOR +
            tileChartData.headers
                .reduce((acc, header, idx) => {
                    const value = tileChartData.value[idx];

                    acc.push(
                        `${header}${ELEMENT_DELIMITER}${isDefined(value) ? value : ''}${ELEMENT_DELIMITER}${tileChartData.rating[idx]}`
                    );

                    return acc;
                }, [])
                .join(LINE_SEPARATOR)
        );
    }
}

export class ProgressCSV extends CSVExporter {
    process(widgetType: WidgetType): string {
        const progressData = this.data.options.progress as ProgressItem[];
        const labels = this.data.options.labels as DropdownItem[];

        return (
            ['Label', 'Value'].join(ELEMENT_DELIMITER) +
            LINE_SEPARATOR +
            progressData.map(({ label, value }) => `${label}${ELEMENT_DELIMITER}${value}`).join(LINE_SEPARATOR) +
            LINE_SEPARATOR.repeat(3) +
            (labels ?? []).map(({ label, value }) => `${label}${ELEMENT_DELIMITER}${value}`).join(LINE_SEPARATOR)
        );
    }
}

export class AccordionCSV extends CSVExporter {
    process(widgetType: WidgetType): string {
        const items = this.data.options.items as Array<{ header: string; table: Partial<GridOptions> }>;

        return items
            .map(item => {
                const tableCSV = new GridCSV(
                    {
                        empty: false,
                        options: item.table as any,
                    },
                    null,
                    null,
                    null
                ).process(null);

                return `${item.header}${LINE_SEPARATOR}${tableCSV}`;
            })
            .join(LINE_SEPARATOR.repeat(3));
    }
}

export class MultipleYAxisCSV extends CSVExporter {
    process(widgetType: WidgetType): string {
        return new BarLineCSV(this.data, this.title, this.elementRef, this.complexWidgets).process(widgetType);
    }
}

export class ComplexCSV extends CSVExporter {
    process(widgetType: WidgetType): string {
        let dataString = '';
        for (const widget of this.complexWidgets) {
            const type = widget.chartModel.options.type;

            if ([WidgetType.BAR_CHART, WidgetType.LINE_CHART, WidgetType.STACKED_BAR_CHART, WidgetType.SCATTER_CHART].includes(type)) {
                const exportObject = new BarLineCSV(widget.chartModel, this.title, this.elementRef, this.complexWidgets);
                dataString += exportObject.process(widgetType);
            } else if ([WidgetType.TWO_DIMENSIONAL_TABLE].includes(type)) {
                const exportObject = new TwoDimensionalGridCSV(widget.chartModel, this.title, this.elementRef, this.complexWidgets);
                dataString += exportObject.process(widgetType);
            } else if ([WidgetType.TABLE].includes(type)) {
                const exportObject = new GridCSV(widget.chartModel, this.title, this.elementRef, this.complexWidgets);
                dataString += exportObject.process(widgetType);
            } else if ([WidgetType.TREEMAP, WidgetType.NESTED_PIE_CHART, WidgetType.SUNBURST_CHART].includes(type)) {
                const exportObject = new NestedCSV(widget.chartModel, this.title, this.elementRef, this.complexWidgets);
                dataString += exportObject.process(widgetType);
            } else if ([WidgetType.PIE_CHART, WidgetType.DOUGHNUT_CHART].includes(type)) {
                const exportObject = new PieDoughnutCSV(widget.chartModel, this.title, this.elementRef, this.complexWidgets);
                dataString += exportObject.process(widgetType);
            } else if (WidgetType.PROGRESS === type) {
                const exportObject = new ProgressCSV(widget.chartModel, this.title, this.elementRef, this.complexWidgets);
                dataString += exportObject.process(widgetType);
            } else if (WidgetType.TILE_CHART === type) {
                const exportObject = new TileCSV(widget.chartModel, this.title, this.elementRef, this.complexWidgets);
                dataString += exportObject.process(widgetType);
            } else if (WidgetType.BOXPLOT === type) {
                const exportObject = new BoxplotCSV(widget.chartModel, this.title, this.elementRef, this.complexWidgets);
                dataString += exportObject.process(widgetType);
            } else if (WidgetType.ACCORDION === type) {
                const exportObject = new AccordionCSV(widget.chartModel, this.title, this.elementRef, this.complexWidgets);
                dataString += exportObject.process(widgetType);
            } else if (WidgetType.MULTIPLE_Y_AXIS === type) {
                const exportObject = new MultipleYAxisCSV(widget.chartModel, this.title, this.elementRef, this.complexWidgets);
                dataString += exportObject.process(widgetType);
            }

            dataString += `${LINE_SEPARATOR}${LINE_SEPARATOR}${LINE_SEPARATOR}`;
        }
        return dataString;
    }
}
