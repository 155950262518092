import { ElementRef, inject } from '@angular/core';
import { zip } from 'lodash';

import { WidgetType } from 'data-processor';

import { AnyWidgetChartModel } from '../../services/query-to-options.mapper';
import { AutoTableGenerator } from './auto-table-generator';
import { toTableConverterFactory } from './to-table-data-converter/widget-to-table-data.converter';

type jsPDF = import('./jspdf.imports').jsPDF;

const PAGE_MARGIN = 10;

export abstract class WidgetExporterPdf {
    protected lastY = PAGE_MARGIN;

    constructor(protected chartData: AnyWidgetChartModel, protected pdf: jsPDF | null, protected element: ElementRef<HTMLElement>) {}

    async export(): Promise<jsPDF> {
        this.pdf = await this.initJsPdf();

        this.buildTitle();
        await this.buildBody();

        return this.pdf;
    }

    protected buildTitle() {
        const { text } = this.chartData.options.title;
        this.pdf.text(this.pdf.splitTextToSize(text, this.pdf.internal.pageSize.width), PAGE_MARGIN, this.lastY);
        this.lastY += this.pdf.getTextDimensions(text).h + 5;
    }

    protected abstract buildBody(): Promise<void>;

    protected async initJsPdf() {
        return (
            this.pdf ??
            new (await import('./jspdf.imports')).jsPDF({
                compress: true,
                orientation: 'p',
                format: 'a4',
            })
        );
    }
}

class GridExporter extends WidgetExporterPdf {
    async export(): Promise<jsPDF> {
        this.pdf = await this.initJsPdf();

        if (this.chartData.empty) return this.pdf;

        if (this.pdf.getNumberOfPages() > 1) this.pdf.addPage('a4', 'p');

        this.buildTitle();
        await this.buildBody();

        return this.pdf;
    }
    async buildBody(): Promise<void> {
        await new AutoTableGenerator(this.pdf, this.lastY).generateTable(toTableConverterFactory(this.chartData).process());
    }
}

class EChartWidgetExporter extends WidgetExporterPdf {
    protected async exportEChartWidget(): Promise<void> {
        const eChartApi = await import('@dagility-ui/shared-components/modules/charts/chart-libraries/echarts/echarts.imports');
        const eChartContainer = this.element.nativeElement.querySelector<HTMLElement>('[_echarts_instance_]');

        if (!eChartContainer) return null;

        const eChartInstance = eChartApi.getInstanceByDom(eChartContainer);

        if (!eChartInstance) return null;

        this.pdf.addPage('a4', 'l');
        this.buildTitle();
        const chartContainer = document.createElement('div');
        const hiddenContainer = document.createElement('div');
        hiddenContainer.append(chartContainer);
        chartContainer.style.width = '648px';
        chartContainer.style.height = '480px';
        hiddenContainer.style.visibility = 'hidden';
        hiddenContainer.style.position = 'absolute';
        hiddenContainer.style.right = '100%';
        hiddenContainer.style.bottom = '100%';

        try {
            document.body.append(hiddenContainer);
            const chartForExport = eChartApi.init(chartContainer);
            chartForExport.setOption(eChartInstance.getOption());
            const chartBase64 = chartForExport.getConnectedDataURL({
                type: 'png',
                backgroundColor: 'transparent',
                pixelRatio: 2,
            });
            this.pdf.addImage(
                chartBase64,
                PAGE_MARGIN,
                this.lastY,
                this.pdf.internal.pageSize.width - PAGE_MARGIN * 2,
                this.pdf.internal.pageSize.height - this.lastY - PAGE_MARGIN
            );
        } finally {
            hiddenContainer.remove();
        }
    }

    protected async exportChartData() {
        try {
            let data = toTableConverterFactory(this.chartData).process();
            data = zip(...data);
            this.pdf.addPage('a4', 'p');
            await new AutoTableGenerator(this.pdf).generateTable(data);
        } catch {}
    }

    async buildBody() {
        await this.exportEChartWidget();
        await this.exportChartData();
    }

    async export(): Promise<jsPDF> {
        this.pdf = await this.initJsPdf();

        if (this.chartData.empty) return;

        await this.buildBody();
        return this.pdf;
    }
}

export function pdfExporterFactory(...params: ConstructorParameters<typeof WidgetExporterPdf>) {
    const [model] = params;

    if (model.options.type === WidgetType.BAR_CHART || model.options.type === WidgetType.LINE_CHART) {
        return new EChartWidgetExporter(...params);
    }

    if (model.options.type === WidgetType.TABLE) {
        return new GridExporter(...params);
    }
}
