import { Component, Input, NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@angular/common';

import { MultiLevelFilterRelationshipType } from "../../../models/filters/multi-level-filter-model";
import { RelationshipLegendItem } from "../../../models/legend/relationship-legend-model";
import { relationshipLegendDefaultIcon } from "../../../models/legend/relationship-legend-model";
@Component({
    selector: 'app-relationship-legend',
    templateUrl: './relationship-legend.component.html',
    styleUrls: ['./relationship-legend.component.scss']
})
export class RelationshipLegendComponent {
    @Input() title: string = 'Legend:';
    @Input() legendItemsArray: RelationshipLegendItem[] = [];
    @Input() isMyScope = false;

    relationshipType = MultiLevelFilterRelationshipType;
    relationshipLegendDefaultIcon = relationshipLegendDefaultIcon;
}

@NgModule({
    exports: [RelationshipLegendComponent],
    declarations: [RelationshipLegendComponent],
    imports: [CommonModule, FontAwesomeModule]
})
export class RelationshipLegendModule {

}
