import { Component, EmbeddedViewRef, Input, TemplateRef, ViewChild } from '@angular/core';

import { getColorForThresholdRelatedChart } from '../../../services/widget-builder.util';

@Component({
    selector: 'dp-metric-line-template',
    templateUrl: './metric-line-template.component.html',
    styleUrls: ['./metric-line-template.component.scss'],
})
export class MetricLineTemplateComponent {
    @Input() set options(value: any) {
        this._options = value;

        if (!this._options.lineOptions.tooltipFormatter) {
            this._options.lineOptions.tooltipFormatter = (params: any) => {
                this.view?.destroy();
                this.view = this.templateTooltip.createEmbeddedView({
                    title: this._options.title.text,
                    currentScore: params.data,
                    date: params.name,
                    currentScoreColor: getColorForThresholdRelatedChart(
                        params.data,
                        value.widget.chartOptions.higherIsBetter,
                        value.widget.chartOptions.mediumThreshold,
                        value.widget.chartOptions.criticalThreshold
                    ),
                });
                this.view.detectChanges();

                return this.view.rootNodes[0];
            };
        }
    }

    @ViewChild('tooltipTemplate', { read: TemplateRef }) templateTooltip: TemplateRef<any>;

    view: EmbeddedViewRef<any>;

    get options() {
        return this._options;
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    _options: any;
}
