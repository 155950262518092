import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { from, Subject, zip } from 'rxjs';
import { take } from 'rxjs/operators';
import { ModalService } from '@dagility-ui/kit';
import { cloneDeep } from 'lodash';
import { FilesAttacherComponent } from '@app/shared/components/files-attacher/files-attacher.component';
import { CKEditorModalComponent } from '@app/shared/components/ckeditor-modal/ckeditor-modal.component';
import { RecipientsEditorComponent } from '@app/shared/modules/recipients-editor/recipients-editor.component';
import { RecipientsEditorService } from '@app/shared/modules/recipients-editor/recipients-editor.service';
import { RecipientListValidator } from './recipient-list-validator';
import { ThresholdSettingsModalService } from './services/threshold-settings-modal.service';
import { EventConditionsComponent } from './event-conditions/event-conditions.component';
import { AuthService } from '@app/auth';

@Component({
    selector: 'app-threshold-settings-modal',
    templateUrl: './threshold-settings-modal.component.html',
    styleUrls: ['./threshold-settings-modal.component.scss'],
})
export class ThresholdSettingsModalComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() widgetTags: string[];
    @Input() predefinedConditions: any[];
    @Input() enableEventConditions: boolean = false;
    @Input() pipelineId: number;

    @ViewChild(RecipientsEditorComponent) recipientsEditorComponent: RecipientsEditorComponent;
    @ViewChild(FilesAttacherComponent) fileAttachments: FilesAttacherComponent;
    @ViewChild(EventConditionsComponent) eventConditions: EventConditionsComponent;

    textBody: string = '';
    textData: string = '';
    recipientForm: FormGroup;

    aggregatorTypes: any[] = [];
    operatorTypes: any[] = [];
    conditionTypes: { label: string; value: any }[] = [
        { value: 'AND', label: 'AND' },
        { value: 'OR', label: 'OR' },
    ];
    frequencyTypes: { code: string; id: number }[] = [];
    priorityTypes: { code: string; id: number; description: string }[] = [
        {
            code: 'LOW',
            description: 'Low',
            id: 100,
        },
        {
            code: 'HIGH',
            description: 'High',
            id: 300,
        },
    ];
    metricTypes: any[] = [];
    editAlertId: number;
    editedAlert: any;
    findAlert: any = false;
    destroy$ = new Subject();
    setByMetrics: boolean = true;
    isAdmin: boolean;
    loading: boolean;

    constructor(
        private thresholdService: ThresholdSettingsModalService,
        private recipientsEditorService: RecipientsEditorService,
        private fb: FormBuilder,
        public modalService: ModalService,
        public modal: NgbActiveModal,
        private cdr: ChangeDetectorRef,
        private authService: AuthService
    ) {
        this.isAdmin = authService.isAdmin();
    }

    get conditions() {
        return this.recipientForm.get('conditions') as FormArray;
    }

    ngOnInit() {
        this.loading = true;
        this.resetToInitialState();
        zip(
            this.thresholdService.getFrequencies(),
            this.thresholdService.getAggregators(),
            this.thresholdService.getConditions(),
            this.thresholdService.getMetrics()
        ).subscribe(([frequency, aggregator, condition, metrics]) => {
            this.frequencyTypes = frequency;
            this.aggregatorTypes = aggregator;
            this.operatorTypes = cloneDeep(condition);
            this.metricTypes = metrics.filter((metric: any) => (metric.tags || []).some((tag: string) => this.widgetTags?.includes(tag)));

            if (!this.metricTypes.length) {
                this.metricTypes = metrics;
            }

            if (this.editAlertId) {
                this.loadAlertData(this.editAlertId);
            } else {
                this.loading = false;
            }

            if (this.predefinedConditions) {
                this.addPredefinedConditionsToForm();
            }
        });
    }

    ngAfterViewInit() {
        this.loadAndApplyAlertData();
    }

    addCondition(condition: string) {
        (this.recipientForm.get('conditions') as FormArray).push(
            this.fb.group({
                [`condition`]: new FormControl({ value: condition, disabled: false }, Validators.required),
                [`aggregator`]: new FormControl({ value: null, disabled: true }),
                [`metric`]: new FormControl(null, Validators.required),
                [`operator`]: new FormControl(null, Validators.required),
                [`valuefrom`]: new FormControl('', Validators.required),
                [`valueto`]: new FormControl(''),
            })
        );
    }

    changeConditions(condition: { value: 'ADD' | 'OR' }) {
        if (condition) {
            this.addCondition(condition.value);
        }
        this.recipientForm.controls['conditionAdd'].patchValue(null);
    }

    openCKEditor() {
        this.thresholdService
            .getPlaceholders()
            .pipe(take(1))
            .subscribe((placeholders: any) => {
                const dialogRef = this.modalService.open(
                    CKEditorModalComponent,
                    {
                        centered: true,
                        backdrop: 'static',
                        size: 'lg',
                    },
                    {
                        ckeditorPlaceholders: placeholders,
                    }
                );
                dialogRef.componentInstance.ckeditorData = this.textData;
                from(dialogRef.result).subscribe(result => {
                    if (!result.cancel) {
                        this.textData = result.value;
                        const clearedData = result.value.replace(/<[^>]*>?/gm, '');
                        if (this.recipientForm) {
                            this.recipientForm.controls['text'].patchValue(clearedData);
                        }
                    }
                });
            });
    }

    onOperatorChange({ multiple }: { multiple: string }, index: number) {
        const controls: any = this.recipientForm.controls['conditions'];
        if (multiple) {
            controls.controls[index].controls['valueto'].enable();
        } else {
            controls.controls[index].controls['valueto'].disable();
        }
    }

    onMetricChange({ historical }: { historical: boolean }, index: number) {
        const controls: any = this.recipientForm.controls['conditions'];
        if (historical) {
            controls.controls[index].controls['aggregator'].enable();
            if (!controls.controls[index].controls['aggregator'].value) {
                controls.controls[index].controls['aggregator'].patchValue(null);
            }
            controls.controls[index].controls['aggregator'].setValidators(Validators.required);
            controls.controls[index].controls['aggregator'].updateValueAndValidity();
        } else {
            controls.controls[index].controls['aggregator'].disable();
            controls.controls[index].controls['aggregator'].patchValue(null);
            controls.controls[index].controls['aggregator'].setValidators(null);
            controls.controls[index].controls['aggregator'].updateValueAndValidity();
        }
    }

    onClose() {
        this.modal.dismiss('data saved');
    }

    onDelete(index: number) {
        (this.recipientForm.get('conditions') as FormArray).removeAt(index);
    }

    addPredefinedConditionsToForm(): void {
        let condition = this.predefinedConditions[0];
        condition.metric = this.metricTypes.find(({ name }) => name === condition.metric)?.code || this.metricTypes[0].code;
        let control = (this.recipientForm.get('conditions') as FormArray).controls[0];
        editControl(control, condition);
        this.recipientForm
            .get('name')
            .patchValue('Taxonomy' + (this.predefinedConditions.length === 2 ? ' Score ' : ' Deviation  ') + condition.name);

        if (this.predefinedConditions.length === 2) {
            condition = this.predefinedConditions[1];
            condition.metric = this.metricTypes.find(({ name }) => name === condition.metric)?.code || this.metricTypes[1].code;
            this.addCondition(condition.condition);
            control = (this.recipientForm.get('conditions') as FormArray).controls[
                (this.recipientForm.get('conditions') as FormArray).controls.length - 1
            ];
            editControl(control, condition);
        }

        function editControl(control: AbstractControl, condition: any) {
            control.get('metric').patchValue(condition.metric);
            control.get('operator').patchValue(condition.operator);
            control.get('valuefrom').patchValue(condition.valuefrom);
            control.get('valueto').disable();
        }
    }

    checkUniqueName({ target: { value } }: any) {
        return this.thresholdService.getAlertByName(value.trim()).subscribe(alertData => {
            this.findAlert = alertData;
        });
    }

    loadAlert() {
        this.loadAlertData(this.findAlert.id);
    }

    transformValue(value: number | string) {
        return typeof value === 'string' ? parseFloat(value) : value;
    }

    onConditionsTypeChange(value: boolean) {
        this.setByMetrics = value;
        if (this.setByMetrics) {
            this.recipientForm.setControl('conditions', this.getConditionsFormGroup());
            this.recipientForm.addControl('frequency', new FormControl(null, Validators.required));
        } else {
            this.recipientForm.removeControl('conditions');
            this.recipientForm.removeControl('frequency');
        }
    }

    onSave() {
        const data = this.recipientForm.value;
        const attachedFiles = this.fileAttachments.files;

        const settings: any = {
            id: this.editAlertId,
            attachments: attachedFiles.map(fileData => ({
                id: fileData.id,
                contentType: fileData.contentType,
                name: fileData.name,
                size: fileData.size,
            })),
            recipients: this.recipientsEditorComponent.getRecipientsData(data),
            enabled: data.enabled,
            name: data.name.trim(),
            importance: data.priority,
            frequency: this.setByMetrics ? data.frequency : null,
            text: this.textData,
        };

        if (this.setByMetrics) {
            settings.conditions = data.conditions.map((condition: any) => ({
                aggregator: condition['aggregator'],
                logicOperator: condition['condition'],
                metric: condition['metric'],
                operator: condition['operator'],
                value: [this.transformValue(condition['valuefrom']), this.transformValue(condition['valueto'])],
            }));
        } else {
            settings.eventConditions = this.eventConditions.form.value['eventConditions'].map((eventCondition: any) => ({
                event: eventCondition['event'] ? eventCondition['event'] : null,
                logicOperator: eventCondition['logicOperator'] ? eventCondition['logicOperator'] : null,
                condition: eventCondition['condition'],
                operator: eventCondition['operator'],
                value: eventCondition['value'] && eventCondition['value'][0] && eventCondition['value'][0].label ? eventCondition['value'].map((f: any) => f.value) :Array.isArray(eventCondition['value']) ? eventCondition['value'] : [eventCondition['value']],
            }));
        }

        if (!this.editAlertId) {
            this.thresholdService.saveSettings(settings).subscribe(a => {
                this.modal.close(a);
            });
        } else {
            this.thresholdService.updateSettings(settings).subscribe(a => {
                this.modal.close(a);
            });
        }
    }

    deleteAlert(editAlertId: number) {
        this.thresholdService.deleteAlert(editAlertId).subscribe(() => {
            this.modal.close(0);
        });
    }

    private resetToInitialState() {
        this.findAlert = null;
        this.editedAlert = null;
        this.recipientForm = this.fb.group(
            {
                conditionAdd: new FormControl({}),
                name: new FormControl('', Validators.required),
                enabled: new FormControl(true),
                conditions: this.getConditionsFormGroup(),
                ...this.recipientsEditorService.getDefaultFormGroup(),
                priority: new FormControl(null, Validators.required),
                frequency: new FormControl(null, Validators.required),
                text: new FormControl('', Validators.required),
            },
            {
                validators: [RecipientListValidator.validateRecipientList()],
            }
        );
        this.setByMetrics = !this.pipelineId;
        if (!this.setByMetrics) {
            this.recipientForm.removeControl('conditions');
            this.recipientForm.removeControl('frequency');
        }
    }

    private getConditionsFormGroup() {
        return this.fb.array([
            this.fb.group({
                condition: new FormControl({ value: 'WHEN', disabled: true }),
                aggregator: new FormControl({ value: null, disabled: true }),
                metric: new FormControl(null, Validators.required),
                operator: new FormControl(null, Validators.required),
                valuefrom: new FormControl(null, Validators.required),
                valueto: new FormControl(''),
            }),
        ]);
    }

    private loadAlertData(editAlertId: number) {
        this.thresholdService.getSettingsById(editAlertId).subscribe(a => {
            this.editedAlert = a;
            this.setByMetrics = !a.eventExpression;
            this.loading = false;
            this.cdr.detectChanges();
            this.loadAndApplyAlertData();
        });
    }

    private loadAndApplyAlertData() {
        const a = this.editedAlert;
        if (!a || !this.recipientForm) {
            return;
        }
        this.fileAttachments.files = a.attachments || [];
        this.textData = a.text;

        const basicFields = {
            name: new FormControl(a.name, Validators.required),
            enabled: new FormControl(a.enabled),
            priority: new FormControl(this.findByCodeOrDefault(this.priorityTypes, a.importance, {}), Validators.required),
            frequency: new FormControl(this.findByCodeOrDefault(this.frequencyTypes, a.frequency, {}), Validators.required),
            text: new FormControl(a.text, Validators.required),
        };

        const conditions = a.eventExpression
            ? this.eventConditions.eventConditionsToFormValues(a.eventConditions)
            : this.conditionsToFormValues(a.conditions);

        const recipients = this.recipientsEditorService.applyDefaultRecipientList(
            this.recipientsEditorComponent.recipientsToFormValues(a.recipients)
        );

        this.recipientsEditorService.applyDefaultRecipientList(recipients);

        this.recipientForm = this.fb.group(
            {
                ...basicFields,
                ...conditions,
                ...recipients,
                conditionAdd: new FormControl({}),
            },
            {
                validators: [RecipientListValidator.validateRecipientList()],
            }
        );
        this.recipientsEditorComponent.onRolesChange();

        const firstRecipientLevel = {
            value: recipients.portfolios
                ? 'portfolios'
                : recipients.applications
                ? 'applications'
                : recipients.teams
                ? 'teams'
                : 'portfolios',
        };
        this.recipientsEditorComponent.changeRecipients(firstRecipientLevel, false);
    }

    private conditionsToFormValues(conditions: any[]) {
        return {
            conditions: this.fb.array(
                conditions.map((c: any, ix: number) =>
                    this.fb.group({
                        condition: new FormControl(
                            ix === 0
                                ? {
                                      value: 'WHEN',
                                      disabled: true,
                                  }
                                : { value: c.logicOperator, disabled: false }
                        ),
                        aggregator: new FormControl({
                            value: c.aggregator,
                            disabled: !this.metricTypes.find(metric => metric.code === c.metric).historical,
                        }),
                        metric: new FormControl({ value: c.metric, disabled: false }, Validators.required),
                        operator: new FormControl({ value: c.operator, disabled: false }, Validators.required),
                        valuefrom: new FormControl(c.value.length > 0 ? c.value[0] : null, Validators.required),
                        valueto: new FormControl({
                            value: c.value.length > 1 ? c.value[1] : null,
                            disabled: this.predefinedConditions,
                        }),
                    })
                )
            ),
        };
    }

    private findByCodeOrDefault(dict: { code: string; id: number }[], code: string, defaultVal: {} = {}) {
        const found = dict.filter(p => p.code === code);
        return found.length !== 0 ? found[0].code : defaultVal;
    }

    ngOnDestroy() {
        this.destroy$.next();
    }
}
