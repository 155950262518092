import { Component, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { WidgetDebuggerState } from '../../../../../widget-builder/services/widget.debugger';
import { BaseWidgetFilter } from '../../../../../widget-builder/components/widget/filters/base-widget-filter.component';
import { DropdownOptionItem } from '../../../../../widget-builder/services/widget-builder.service';

@Component({
    templateUrl: './dropdown-filter.component.html',
    styleUrls: ['./dropdown-filter.component.scss'],
})
export class DropdownFilterComponent extends BaseWidgetFilter {
    items$: Observable<DropdownOptionItem[]> = inject(WidgetDebuggerState).dataSources[this.filter.placeholder];

    getFilterText(): { title: string; value: any } {
        return undefined;
    }

    registerOnChange(fn: (_: any) => void) {
        this.subscription.unsubscribe();

        this.subscription = this.formControl.valueChanges
            .pipe(
                BaseWidgetFilter.filterValueChanged(
                    () => this.formControl.value,
                    () => this.formControl.value
                )
            )
            .subscribe(value => fn(value));
    }
}
