import {
    ChangeDetectorRef,
    Component,
    Directive,
    inject,
    Input,
    OnInit,
    Optional,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { ControlContainer, FormGroupDirective, FormGroup, FormControl } from '@angular/forms';
import { combineLatest, of } from 'rxjs';
import { map, share, shareReplay, startWith } from 'rxjs/operators';
import { groupBy } from 'lodash';

import { ObsWithStatusResult } from '@dagility-ui/kit';
import { moveLineFieldToOldField } from 'data-processor/lib/common';

import { WidgetBuilderStore } from '../../../services/widget-builder.store';
import { LINES_FIELDS } from '../../../services/widget.lines';
import { WidgetBuilderService, WidgetQueryDto } from '../../../services/widget-builder.service';
import { WidgetBuilderFacade } from '../../../state/widget-builder.facade';
import { WidgetQuery, WidgetType } from '../../../models/any-widget.model';
import { BlockForm } from '../widget-builder-block-form/widget-builder-block-form.component';
import { QueryBlock } from 'data-processor/lib/widget-library/widget-builder/models/query.block';

@Directive({
    selector: 'ng-template[helpForTemplateQuery]',
})
export class HelpForTemplateQueryDirective {
    @Input('helpForTemplateQuery') templates: WidgetType[] = [];
    template = inject(TemplateRef);
}

@Component({
    selector: 'dp-widget-builder-query',
    templateUrl: './widget-builder-query.component.html',
    styleUrls: ['./widget-builder-query.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
    providers: [{ provide: BlockForm, useExisting: WidgetBuilderQueryComponent }],
})
export class WidgetBuilderQueryComponent extends BlockForm implements OnInit {
    @Input() set data(query: WidgetQuery) {
        this.form = this.facade.buildQueryForm(query);
    }

    @Input() filterQuery: boolean;

    get queryControl(): FormGroup {
        return this.parent.form.get('query') as FormGroup;
    }

    get queryForm() {
        return this.filterQuery ? this.queryControl : this.form;
    }

    get placeholderControl() {
        return this.queryForm.get('placeholder') as FormControl;
    }

    get hasParent() {
        return this.isQueryLibraryEnabled && Boolean(this.queryForm?.get('parent')?.value);
    }

    @ViewChildren(HelpForTemplateQueryDirective) helps: QueryList<HelpForTemplateQueryDirective>;
    @ViewChild('defaultHelp') defaultHelp: TemplateRef<unknown>;

    form: FormGroup;
    scriptType = 'text/javascript';
    language = 'Javascript';
    type = WidgetType;
    isQueryLibraryEnabled = this.ft.isActiveSync('query_library');

    constructor(
        public facade: WidgetBuilderFacade,
        @Optional() public parent: FormGroupDirective,
        private store: WidgetBuilderStore,
        private api: WidgetBuilderService,
        private cdr: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {
        if (this.store.value.layers[this.store.value.root].server) {
            this.scriptType = 'text/x-groovy';
            this.language = 'Groovy';
        }

        if (!this.facade.queries$) {
            this.facade.queries$ = this.api.getAllWidgetQueries().pipe(
                map(queries => queries.sort((a, b) => a.name.localeCompare(b.name))),
                shareReplay(1)
            );
            this.facade.queriesMap$ = this.facade.queries$.pipe(
                map(queries => groupBy(queries, 'name')),
                share()
            );
        }
    }

    clearScriptFields() {
        this.queryForm.patchValue({
            query: '',
            type: null,
            script: '',
            beforeScript: '',
        });
        this.facade.checkQueryFormStatus(this.queryForm);

        this.cdr.markForCheck();
    }

    getQueryFromLibrary = (form: FormGroup) => {
        if (!form) {
            return of(null);
        }

        return combineLatest([
            form.get('parent').valueChanges.pipe(startWith(form.get('parent').value as string)),
            this.facade.queriesMap$,
        ]).pipe(map(([name, queriesMap]) => (name ? queriesMap[name]?.[0] : null)));
    };

    buildPreviewForm = (queryObs: ObsWithStatusResult<WidgetQueryDto>) => {
        if (queryObs.loading) {
            return queryObs;
        }

        const { value: query } = queryObs;

        LINES_FIELDS.QUERY.forEach(field => moveLineFieldToOldField(query.data, field));

        return { value: this.facade.buildQueryForm(query.data) };
    };

    getHelpTemplate = (block: any): TemplateRef<unknown> => {
        if (block instanceof QueryBlock) {
            const layer = this.store.value.layers[block.layerId];

            return this.helps.find(item => item.templates.includes(layer.type))?.template ?? this.defaultHelp;
        }

        return null;
    };
}
