<div
    *ngIf="radial.title"
    class="mt-0 mb-3 mw-100 text-truncate h4"
    [ngbTooltip]="radial.title"
    libTooltipWhenOverflow
    triggers="manual"
    container="body"
>
    {{ radial.title }}
</div>
<div [style.color]="thresholdColor" class="flex-1 position-relative chart-container">
    <lib-radial-chart class="h-100 w-auto" [value]="value" [startAngle]="-150" [endAngle]="150"></lib-radial-chart>
    <div class="text-container">
        <div class="progress__value-text">
            <ng-container>{{ value }}</ng-container>
            <span class="h3" style="color: inherit">%</span>
        </div>
    </div>
</div>
