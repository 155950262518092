import { Injectable } from '@angular/core';

import { WidgetPreview } from './widget-library.page';
import { Store } from '@dagility-ui/kit';

interface WidgetLibraryState {
    widgets: WidgetPreview[];
}

@Injectable()
export class WidgetLibraryStore extends Store<WidgetLibraryState> {
    constructor() {
        super({
            widgets: [],
        });
    }

    deleteWidget(id: number) {
        this.setState({
            widgets: this.value.widgets.filter(widget => widget.id !== id),
        });
    }
}
