<div class="flex-1 d-flex flex-column">
    <div class="main-content-header main-content-header-shadow">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a [routerLink]="'../../../../'">Settings</a></li>
                <li class="breadcrumb-item active" aria-current="page">Reporting: Widget Preview</li>
            </ol>
        </nav>

        <h1>Widget Preview</h1>
    </div>

    <lib-stream-wrapper
        *ngIf="selectedWidget$"
        class="stream-wrapper flex-1 d-flex mt-2 overflow-auto"
        errorCssClass="w-100"
        [dataStream$]="selectedWidget$"
    >
        <ng-template contentTemplate let-vm="data">
            <dp-any-widget-wrapper
                *ngIf="typeGuard(vm) as dashboardWidget"
                style="width: 80%;"
                class="position-relative m-auto fixed-size"
                [options]="dashboardWidget.data"
                [placeholders]="{}"
                [actionsTemplate]="widgetActions"
                [separatedView]="true"
                [mock]="true"
            ></dp-any-widget-wrapper>

            <ng-template #widgetActions let-widget>
                <button *ngIf="!vm.data.system" class="btn btn-link px-1" ngbTooltip="Edit widget" container="body">
                    <fa-icon
                        class="font-14px"
                        icon="facEdit"
                        [routerLink]="activeWidgetId"
                        [state]="{
                            fromLink: router.url
                        }"
                    ></fa-icon>
                </button>
            </ng-template>
        </ng-template>
    </lib-stream-wrapper>
</div>
<dp-widget-list
    class="widget-list p-4"
    [activeWidgetId]="activeWidgetId"
    [clonedId]="clonedId"
    (widgetSelect)="handleWidgetSelect($event)"
    (deleteWidget)="handleDeleteWidget($event)"
></dp-widget-list>
