<div
    class="position-relative d-flex align-items-center"
    *ngFor="let tile of tiles"
    [ngbTooltip]="tooltipTemplate"
    libTooltipWhenOverflow
    [textElementSelectorFn]="getTruncatedElements"
    triggers="manual"
    container="body"
>
    <button
        [widget-tile]="tile"
        [vertical]="!horizontalOrientation"
        class="tile-block default-size pt-1 pb-0 pl-20px pr-1"
        type="button"
        (click)="handleTileClicked(tile)"
    ></button>
    <ng-template #tooltipTemplate>
        <div class="body2 mb-1">{{ tile.tileTitle }}</div>
        <div *ngFor="let tileValue of tile.values" class="body2">
            <ng-container *ngIf="tileValue.postfix">{{ tileValue.postfix }}:</ng-container> {{ tileValue.value }}
        </div>
    </ng-template>
</div>
