<app-qb-visualization-filter-item
        [iconUrl]="icons.filter"
        [name]="'Filters'"
></app-qb-visualization-filter-item>
<ng-container *ngFor="let roleConvention of widgetTypeRolesConventions">
        <app-qb-visualization-field-item
            [datasetFieldRole]="roleConvention.dataSetFieldRole"
            [name]="roleConvention.name"
            [iconName]="roleConvention.iconName"
            [maxDroppedItems]="roleConvention.maxDroppedItems"
            [ordered]="roleConvention.ordered"
        ></app-qb-visualization-field-item>
</ng-container>
