import { Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { Subscription } from 'rxjs';

@Directive({
    selector: 'perfect-scrollbar[dpPerfectScrollbarEventPropagation]',
})
export class PerfectScrollbarEventPropagationDirective implements OnDestroy {
    private subscription: Subscription = Subscription.EMPTY;

    @Output() scroll = new EventEmitter();

    constructor({ psScrollY }: PerfectScrollbarComponent, public elementRef: ElementRef<HTMLElement>) {
        this.subscription = psScrollY.subscribe(() => {
            elementRef.nativeElement.dispatchEvent(new Event('scroll'));
            this.scroll.emit();
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
