import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    inject,
    Inject,
    Injector,
    Input,
    OnDestroy,
    Optional,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormDirtyDirective } from '@dagility-ui/kit';

import { DASHBOARD_FORM_EXTENSION, DashboardFormExtension, DATA_MORPH_FEATURE_TOGGLE } from 'data-processor/tokens';

import { DataMorph } from '../../models/dp-dashboard.model';
import { ENTITY_NAME } from '../../directives/entity-name.control';
import { FormValidation } from '../../services/form-validation';

type SaveDashboardFn = (dashboard: DataMorph.Dashboard) => Observable<DataMorph.Dashboard>;

@Component({
    selector: 'dp-create-dashboard',
    templateUrl: './dp-create-dashboard.component.html',
    styleUrls: ['./dp-create-dashboard.component.scss'],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'd-block',
    },
    providers: [{ provide: ENTITY_NAME, useValue: 'Dashboard' }],
})
export class DpCreateDashboardComponent implements AfterViewInit, OnDestroy {
    @Input() dashboard: DataMorph.Dashboard;

    @Input() headerText: string;

    @Input() actionButtonText = 'Save';

    @Input() saveFunction: SaveDashboardFn = of;

    @Input() checkDirty: boolean = true;

    @ViewChild('formExtension', { read: ViewContainerRef }) formExtensionVcr: ViewContainerRef;

    @ViewChild(FormDirtyDirective) formDirty: FormDirtyDirective;

    readonly descriptionBounds = {
        min: 0,
        max: 250,
    };

    form = this.formBuilder.record({
        name: '',
        description: ['', Validators.maxLength(this.descriptionBounds.max)],
    });

    extensionInstances: ComponentRef<DashboardFormExtension>[] = [];
    checkDirtyFt = inject(DATA_MORPH_FEATURE_TOGGLE).isActiveSync('eo_48475');
    private destroyed$ = new Subject<void>();
    private formValidation = inject(FormValidation);

    constructor(
        private formBuilder: FormBuilder,
        @Optional() @Inject(DASHBOARD_FORM_EXTENSION) private extensions: Type<DashboardFormExtension>[],
        private injector: Injector,
        private cdr: ChangeDetectorRef,
        public modal: NgbActiveModal
    ) {}

    ngAfterViewInit() {
        (this.extensions || []).forEach(extension => {
            const component = this.formExtensionVcr.createComponent(extension, {
                injector: this.injector,
            });
            this.extensionInstances.push(component);
            this.form.addControl(component.instance.controlName, component.instance.form as any);
            component.instance.setDashboard(this.dashboard);
        });
        this.form.patchValue({
            name: this.dashboard?.name ?? '',
            description: this.dashboard?.description ?? '',
        });
        this.formDirty.reset();
        this.cdr.detectChanges();
    }

    toSave() {
        return Object.assign(
            {},
            this.dashboard,
            this.form.value,
            this.extensionInstances.reduce<Record<string, unknown>>((acc, { instance }) => {
                acc[instance.controlName] = {
                    ...((this.dashboard as any)?.[instance.controlName] ?? {}),
                    ...instance.toSave(),
                };

                return acc;
            }, {})
        );
    }

    handleSave() {
        if (this.form.valid) {
            this.saveDashboard();
        } else {
            this.formValidation.validateFormAndDisplayErrors(this.form);
        }
    }

    ngOnDestroy() {
        this.extensionInstances.forEach(instance => {
            instance.destroy();
        });
    }

    private saveDashboard() {
        this.saveFunction(this.toSave())
            .pipe(takeUntil(this.destroyed$))
            .subscribe({
                next: dashboard => {
                    this.modal.close(dashboard);
                },
                error: e => this.handleSaveError(e),
            });
    }

    private handleSaveError(error: unknown) {
        if (error instanceof HttpErrorResponse) {
            if (error.error?.errors && Array.isArray(error.error.errors)) {
                this.form.get('name').markAsDirty();
                this.formValidation.showServerValidationErrors(this.form, error);
                this.cdr.detectChanges();
            }
        } else {
            console.error(error);
        }
    }
}
