import { Injectable } from '@angular/core';
import { DropdownItem } from '@dagility-ui/kit';
import { DataSetField, Model, ModelGraph, DataSetFields } from '@app/shared/components/query-builder/models/model-graph.model';
import {
    DBFunction,
    ExpressionParts,
    ExpressionTemplate as ExpressionTemplateDto,
} from '@app/shared/components/query-builder/models/model-graph-actions.model';
import { ModelGraphActionsService } from '@app/shared/components/query-builder/services/model-graph-actions.service';
import { BehaviorSubject } from 'rxjs';

export interface ExpressionHelperEvent {
    type: string;
    expression: string;
    expressionView: string;
    function?: FunctionItem;
    selectionEnd?: number;
    selectionStart?: number;
}

export interface FunctionItem {
    name: string;
    uid: string;
    selected: boolean;
    argTypes: string[];
    operator: boolean;
    description: string;
}

export interface ExpressionData {
    type: string;
    value: string | number;
    operator?: boolean;
    args?: ExpressionData[];
}

export enum ExpressionNodeType {
    const = 0,
    func,
    field,
    param,
    operator,
    template = 100,
}

export interface ExpressionNode {
    type: ExpressionNodeType;
    value: string | number;
    arguments?: ExpressionNode[];
    parentNode: ExpressionNode | null;
    operator?: boolean;
    returnType?: string;
    selected?: boolean;
}

export interface ParamItem {
    name: string;
    uid: string;
    selected: boolean;
    description: string;
}

export interface ExpressionTemplate {
    uid: string;
    text: string;
    content: string;
    node: ExpressionNode;
}

export interface GridItem {
    data?: DataSetField | FunctionItem | ExpressionTemplate | string;
    selected?: boolean;
    label: string;
    type?: ExpressionNodeType;
    isTemplate?: boolean;
    paramsList?: string;
    description?: string;
}

@Injectable({
    providedIn: 'root',
})
export class ExpressionBuilderService {
    iconPath: string = 'assets/images/icons/';
    modelNames: { [key: string]: string } = {};
    dataTables: DropdownItem<Model>[] = [];
    typeIcons: { [key: number]: string } = {
        [ExpressionNodeType.func]: this.iconPath + 'icon_function.svg',
        [ExpressionNodeType.const]: this.iconPath + 'icon_constant.svg',
        [ExpressionNodeType.field]: this.iconPath + 'icon_field.svg',
        [ExpressionNodeType.param]: this.iconPath + 'icon_parameter.svg',
        [ExpressionNodeType.operator]: this.iconPath + 'icon_asterisk.svg',
        [ExpressionNodeType.template]: this.iconPath + 'icon_asterisk.svg',
    };
    typeDefenitions: { [key: number]: string } = {
        [ExpressionNodeType.func]: 'Function',
        [ExpressionNodeType.const]: 'Constant',
        [ExpressionNodeType.field]: 'Field',
        [ExpressionNodeType.param]: 'Parameter',
        [ExpressionNodeType.operator]: 'Operator',
    };
    typesMap: { [key: number]: string } = {
        [ExpressionNodeType.func]: 'func',
        [ExpressionNodeType.const]: 'const',
        [ExpressionNodeType.field]: 'field',
        [ExpressionNodeType.param]: 'param',
        [ExpressionNodeType.operator]: 'operator',
    };

    expressionParts$: BehaviorSubject<ExpressionParts> = new BehaviorSubject<ExpressionParts>({
        fields: [],
        parameters: [],
        functions: [],
        operators: [],
        mode: null,
        templates: [],
    });
    private prevDataFields: DataSetFields;
    constructor(private modelGraphActionsService: ModelGraphActionsService) {}

    updateGraphData(graph: ModelGraph) {
        if (this.prevDataFields !== graph.dataSetFields) {
            this.getExpressionParts();
            this.updateModelNames(graph);
        } else {
            this.expressionParts$.next(this.expressionParts$.value);
        }
        this.prevDataFields = graph.dataSetFields;
    }

    getExpressionParts() {
        const sub = this.modelGraphActionsService.getExpressionParts().subscribe(data => {
            sub.unsubscribe();
            this.expressionParts$.next(data);
        });
    }

    updateModelNames(graph: ModelGraph) {
        this.dataTables = graph.models.map(item => ({ value: item, label: item.name }));
        this.modelNames = graph.models.reduce((prev: { [key: string]: string }, current) => {
            prev[current.uid] = current.name;
            return prev;
        }, {});
    }

    parseData(value: string): ExpressionNode {
        if (value && value[0] === '{') {
            const expressionData: ExpressionData = JSON.parse(value);
            if (expressionData) {
                const convertToNode = (exp: ExpressionData, parentNode: ExpressionNode) => {
                    const expression: ExpressionNode = {
                        type: ExpressionNodeType[exp.type as keyof typeof ExpressionNodeType],
                        value: exp.value,
                        operator: exp.operator,
                        parentNode,
                        arguments: [],
                    };
                    if (exp.args?.length) {
                        expression.arguments = exp.args.map(arg => convertToNode(arg, expression));
                    }
                    return expression;
                };

                return convertToNode(expressionData, null);
            }
        }
        return null;
    }

    selectItem(key: string, grid: any[], elementRef: any, prevItem: any) {
        let selectedItem = prevItem;
        let nextIndex = 0;
        if (!selectedItem) {
            if (grid.length) {
                selectedItem = grid[0];
                selectedItem.selected = true;
            }
        } else {
            selectedItem.selected = false;
            const index = grid.indexOf(selectedItem);
            if (key === 'ArrowDown') {
                nextIndex = index + 1 === grid.length ? 0 : index + 1;
            } else {
                nextIndex = index - 1 < 0 ? grid.length - 1 : index - 1;
            }
            selectedItem = grid[nextIndex];
            if (selectedItem) {
                selectedItem.selected = true;
            }
        }

        if (elementRef) {
            const tbody = elementRef.getElementsByTagName('tbody')[0];
            if (tbody) {
                tbody.scrollTop = nextIndex * 32;
            }
        }
        return selectedItem;
    }

    updateExpressionView(expression: string): string {
        if (expression) {
            const expressionNode = this.parseData(expression);
            return this.convertNodeToFunctionView(expressionNode) || expression;
        }
        return expression;
    }

    convertDataToJson(data: ExpressionNode) {
        const convertToData = (node: ExpressionNode) => {
            const exData: ExpressionData = { type: this.typesMap[node.type], value: node.value };
            if (node.arguments?.length) {
                exData.operator = node.operator || false;
                exData.args = node.arguments.map(arg => convertToData(arg));
            }
            return exData;
        };

        return JSON.stringify(convertToData(data));
    }

    convertNodeToFunctionView(data: ExpressionNode): string {
        if (data) {
            switch (data.type) {
                case ExpressionNodeType.operator:
                    if ('is not null' === data.value || 'is null' === data.value) {
                        return `${this.convertNodeToFunctionView(data.arguments[0])} ${data.value}`;
                    } else if ('cast' === data.value) {
                        return `${data.value}(${this.convertNodeToFunctionView(data.arguments[0])} as ${this.convertNodeToFunctionView(
                            data.arguments[1]
                        )})`;
                    } else {
                        return data.arguments.length === 1
                            ? `${data.value} ${this.convertNodeToFunctionView(data.arguments[0])}`
                            : `${this.convertNodeToFunctionView(data.arguments[0])} ${data.value} ${data.arguments
                                  .slice(1)
                                  .map(arg => this.convertNodeToFunctionView(arg))
                                  .join(',')}`;
                    }
                case ExpressionNodeType.func:
                    return `${data.value}(${data.arguments.map(arg => this.convertNodeToFunctionView(arg)).join(',')})`;
                case ExpressionNodeType.param:
                    return ':' + String(data.value);
                default:
                    return String(data.value);
            }
        }
        return '';
    }

    mapFunctions(functions: DBFunction[], operator: boolean = false) {
        return functions
            .map(func => ({
                operator,
                selected: false,
                name: func.name,
                uid: func.uid,
                argTypes: func.args.argTypes || [],
                description: func.description,
            }))
            .sort((a, b) => (a.name > b.name ? 1 : -1));
    }

    mapTemplates(data: ExpressionTemplateDto[]) {
        return data
            .sort((a, b) => (a.text > b.text ? 1 : -1))
            .map(t => ({
                uid: t.uid,
                text: t.text,
                content: t.content,
                node: this.parseData(t.content),
            }));
    }
}
