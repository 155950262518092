<ng-container *ngIf="!loading; else loader">
    <div class="modal-header settings-header">
        <ng-container>
            <h2 class="modal-title settings-title">{{ editedAlert ? 'Edit Alert' : 'New Alert' }}</h2>
        </ng-container>

        <div class="pull-right">
            <button type="button" class="close" aria-describedby="modal-title" (click)="onClose()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <form *ngIf="recipientForm" [formGroup]="recipientForm">
        <div class="modal-body">
            <div class="d-flex align-items-center">
                <lib-input
                    (change)="checkUniqueName($event)"
                    class="mb-16px mr-15px"
                    style="width: 100%"
                    formControlName="name"
                    id="name"
                    label="Alert Name"
                >
                    <span context-help-id="threshold-settings-alert-name"></span>
                </lib-input>
                <trigger class="mt-8px" formControlName="enabled">Active</trigger>
            </div>
            <div *ngIf="findAlert && !editedAlert" class="multi-tool-warning mb-15px row no-gutters">
                <div class="multi-tool-warning-title body5 color-font-4">
                    <fa-icon icon="facExclamationCircle"></fa-icon>
                </div>
                <div class="multi-tool-warning-content body4">
                    Alert with name {{ findAlert.name }} already exists.
                    <span *ngIf="findAlert?.editEnabled"
                    >Do you want to edit <span class="cursor-pointer color-brand" (click)="loadAlert()">{{ findAlert.name }}</span
                    >?
                </span>
                </div>
            </div>
        </div>

        <div class="modal-body">
            <h3>Conditions</h3>
            <div *ngIf="enableEventConditions && isAdmin" class="d-flex mb-4">
                <lib-radio-button
                    label="Set by Metrics"
                    class="mr-4"
                    [id]="'setByMetrics'"
                    [name]="'setByRadioButton'"
                    [checked]="setByMetrics"
                    [disabled]="editedAlert && !setByMetrics"
                    [class.rb-disabled]="editedAlert && !setByMetrics"
                    (click)="onConditionsTypeChange(true)"
                ></lib-radio-button>
                <lib-radio-button
                    label="Set by Events"
                    [id]="'setByEvents'"
                    [name]="'setByRadioButton'"
                    [checked]="!setByMetrics"
                    [disabled]="editedAlert && setByMetrics"
                    [class.rb-disabled]="editedAlert && setByMetrics"
                    (click)="onConditionsTypeChange(false)"
                ></lib-radio-button>
            </div>
            <ng-container *ngIf="setByMetrics; else eventConditionsTmpl">
                <div formArrayName="conditions">
                    <div *ngFor="let ctl of conditions?.controls; let i = index">
                        <div class="d-flex justify-content-start align-items-start mb-4" [formGroupName]="i">
                            <lib-input
                                style="min-width: 80px; width: 80px"
                                *ngIf="i === 0"
                                class="mr-4 mt-25px"
                                formControlName="condition"
                            ></lib-input>
                            <lib-dropdown
                                *ngIf="i !== 0"
                                class="mr-4"
                                style="min-width: 80px; width: 80px; display: inline-block"
                                [items]="conditionTypes"
                                [formControlName]="'condition'"
                                [searchable]="true"
                                [clearable]="false"
                                valueKey="value"
                                labelKey="label"
                            >
                            </lib-dropdown>
                            <lib-dropdown
                                class="w-100 mr-4"
                                [items]="aggregatorTypes"
                                [formControlName]="'aggregator'"
                                valueKey="code"
                                labelKey="name"
                                label="{{ i === 0 ? 'Aggregator' : '' }}"
                                [searchable]="true"
                                [placeholder]="'Select'"
                            >
                                <span context-help-id="threshold-settings-aggregator"></span>
                            </lib-dropdown>
                            <lib-dropdown
                                (change)="onMetricChange($event, i)"
                                class="mr-4"
                                [width]="180"
                                [items]="metricTypes"
                                [formControlName]="'metric'"
                                valueKey="code"
                                labelKey="name"
                                label="{{ i === 0 ? 'Metric' : '' }}"
                                [searchable]="true"
                                [placeholder]="'Select'"
                            >
                                <span context-help-id="threshold-settings-metric"></span>
                            </lib-dropdown>
                            <lib-dropdown
                                (change)="onOperatorChange($event, i)"
                                class="mr-4"
                                [width]="180"
                                [items]="operatorTypes"
                                [formControlName]="'operator'"
                                valueKey="code"
                                labelKey="name"
                                label="{{ i === 0 ? 'Operator' : '' }}"
                                [searchable]="true"
                                [placeholder]="'Select'"
                            >
                                <span context-help-id="threshold-settings-operator"></span>
                            </lib-dropdown>
                            <lib-input
                                style="min-width: 70px; width: 70px"
                                class="mr-2"
                                [formControlName]="'valuefrom'"
                                label="{{ i === 0 ? 'Value' : '' }}"
                                [type]="'number'"
                            ></lib-input>
                            <div class="d-flex align-items-center" [class.mt-25px]="i === 0">
                                <span class="mr-2 dash">-</span>
                                <lib-input style="min-width: 70px; width: 70px" class="mr-4" [formControlName]="'valueto'" [type]="'number'">
                                </lib-input>
                                <div *ngIf="i === 0" class="dummy"></div>
                                <fa-icon
                                    *ngIf="i !== 0"
                                    icon="facTrash"
                                    class="color-font-4 font-16px cursor-pointer"
                                    (click)="onDelete(i)"
                                ></fa-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="max-width: 80px; width: 80px">
                    <lib-dropdown
                        (change)="changeConditions($event)"
                        class="mr-4 mb-16px"
                        style="max-width: 80px; width: 80px"
                        [items]="conditionTypes"
                        valueKey="value"
                        labelKey="label"
                        placeholder="Add"
                        [formControlName]="'conditionAdd'"
                    >
                    </lib-dropdown>
                </div>
            </ng-container>
            <ng-template #eventConditionsTmpl>
                <app-event-conditions [form]="recipientForm" [pipelineId]="pipelineId"></app-event-conditions>
            </ng-template>
        </div>

        <div class="modal-body">
            <h3>
                Recipients
                <span context-help-id="threshold-settings-recipients"></span>
            </h3>
            <app-recipients-editor [recipientForm]="recipientForm"></app-recipients-editor>
        </div>

        <div class="modal-body">
            <h3>
                Alert Settings
                <span context-help-id="threshold-settings-alert-settings"></span>
            </h3>
            <div>
                <div class="d-flex">
                    <lib-dropdown
                        class="mb-16px"
                        [class.mr-4]="setByMetrics"
                        [style.width]="'20%'"
                        [items]="priorityTypes"
                        [formControlName]="'priority'"
                        valueKey="code"
                        labelKey="description"
                        label="Importance"
                        [searchable]="true"
                        [clearable]="true"
                        [optionTemplateRef]="optionTemplateRef"
                        [labelTemplateRef]="optionTemplateRef"
                    >
                        <ng-template #optionTemplateRef let-item="item">
                            <div class="d-inline-flex font-14px justify-content-between align-items-center">
                                <fa-icon *ngIf="item.code === 'HIGH'" icon="facfilledExclamatory" style="color:#C62828"></fa-icon>
                                <span class="ml-10px color-gray-3">{{ item.description }}</span>
                            </div>
                        </ng-template>
                    </lib-dropdown>
                    <lib-dropdown
                        *ngIf="setByMetrics"
                        class="mb-16px"
                        style="width: 100%"
                        [items]="frequencyTypes"
                        [formControlName]="'frequency'"
                        valueKey="code"
                        labelKey="description"
                        [placeholder]="'Search'"
                        label="Notifications Frequency"
                        [searchable]="true"
                        [clearable]="true"
                    ></lib-dropdown>
                    <div class="ml-15px w-100 d-flex align-items-center" *ngIf="!setByMetrics">
                        <lib-input
                            class="mb-16px"
                            style="width: 100%"
                            [formControlName]="'text'"
                            [placeholder]="'Text'"
                            label="Alert Text"
                            [readonly]="true"
                        ></lib-input>
                        <fa-icon class="edit-alert font-14px" style="top: 93px" icon="facEdit" (click)="openCKEditor()"></fa-icon>
                    </div>
                </div>
                <div class="d-flex align-items-center" *ngIf="setByMetrics">
                    <lib-input
                        class="mb-16px"
                        style="width: 100%"
                        [formControlName]="'text'"
                        [placeholder]="'Text'"
                        label="Alert Text"
                        [readonly]="true"
                    ></lib-input>
                    <fa-icon class="edit-alert font-14px" icon="facEdit" (click)="openCKEditor()"></fa-icon>
                </div>
                <app-files-attacher path="alert-attachments"></app-files-attacher>
            </div>
        </div>
    </form>

    <div class="modal-footer" *ngIf="recipientForm">
        <div style="width: 100%;" class="d-inline-flex justify-content-between align-items-center">
            <div style="width: 50%;" class="d-inline-flex justify-content-start align-items-center">
                <button *ngIf="editedAlert" class="btn btn-link" (click)="deleteAlert(editAlertId)">
                    <fa-icon class="font-12px mr-8px" icon="facTrash"></fa-icon>
                    Delete this alert
                </button>
            </div>
            <div style="width: 50%;" class="d-inline-flex justify-content-end align-items-center">
                <lib-loader *ngIf="false" [size]="'15px'"></lib-loader>
                <button class="btn btn-secondary font-11px ml-20px" (click)="onClose()">Cancel</button>
                <button
                    [disabled]="recipientForm.invalid || recipientForm.errors?.recipientListInvalid || findAlert"
                    class="btn btn-primary font-11px ml-20px"
                    (click)="onSave()"
                >
                    Save
                </button>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #loader>
    <div class="d-flex justify-content-center align-items-center py-4">
        <lib-loader></lib-loader>
    </div>
</ng-template>
