import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, of } from 'rxjs';
import { concatMap, map, publishReplay, refCount } from 'rxjs/operators';

import { ModuleConfig, ModulesApiService } from '@app/core/services/modules-api.service';
import { DYNAMIC_ROUTES, RouteWithStatus } from '../../app-routes';

@Injectable({
    providedIn: 'root',
})
export class ModulesResolver implements CanActivate {
    modules$: Observable<ModuleConfig[]> = this.modulesApi.getAllModules().pipe(publishReplay(1), refCount());

    constructor(private modulesApi: ModulesApiService, private router: Router, private toast: ToastrService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const { moduleId } = route.params;

        return this.resolveModule(moduleId).pipe(
            map(resolved => {
                return resolved ? this.router.createUrlTree([state.url]) : false;
            })
        );
    }

    resolveModule(moduleId: string): Observable<boolean> {
        return this.modules$.pipe(
            concatMap((modules: ModuleConfig[]) => {
                const suitableModule = modules.find(m => m.contextPath === moduleId);
                const internalModule: any = DYNAMIC_ROUTES.find(m => m.path === moduleId);
                if (!suitableModule) {
                    return of(false);
                }

                // todo: create Record<ModuleName, ExtensionElement[]> after retrieving ExtensionElement from getModules REST

                if (internalModule && internalModule.loaded) {
                    return of(true);
                }

                return forkJoin([suitableModule.isActive$, suitableModule.appUrl$ || of(suitableModule.appUrl)]).pipe(
                    map(([isActive, appUrl]) => {
                        if (isActive) {
                            const moduleToRegister = internalModule
                                ? internalModule
                                : {
                                      path: suitableModule.contextPath.startsWith('/')
                                          ? suitableModule.contextPath.substring(1)
                                          : suitableModule.contextPath,
                                      loadChildren: () => import('../../pages/external/external.module').then(m => m.ExternalModule),
                                      data: {
                                          contextPath: suitableModule.contextPath,
                                          iframeUrl: appUrl,
                                      },
                                  };
                            this.registerModule(moduleToRegister);
                            return true;
                        }
                        this.toast.error('', `Module ${suitableModule.displayName} is not available`);
                        return false;
                    })
                );
            })
        );
    }

    private registerModule(mod: RouteWithStatus) {
        mod.loaded = true;
        const config = this.router.config;
        config[0].children.unshift(mod);
        this.router.resetConfig(config);
    }
}
