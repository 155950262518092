import { Directive, Input, OnChanges, OnInit } from '@angular/core';
import { GridColumn, Pageable, Pager, Pagination } from '@dagility-ui/kit';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractPipelineListComponent implements OnInit, OnChanges {
    @Input() search: string;
    @Input() projectFilter: number;
    @Input() templateFilter: number;

    loading = false;

    pagination: Pagination;
    pageable = new Pageable(0, 5, [{ direction: 'ASC', property: 'lastExecutedTime' }]);

    abstract gridColumns: GridColumn[];

    abstract getJobs(pageable: Pageable): void;

    ngOnInit() {
        this.getJobs(this.pageable);
    }

    ngOnChanges() {
        if (this.search !== undefined || this.projectFilter !== undefined || this.templateFilter !== undefined) {
            if (this.search || this.projectFilter || this.templateFilter) {
                this.pageable.page = 0;
            }
            this.getJobs(this.pageable);
        }
    }

    onPageChange(data: Pager) {
        this.pageable.update(data);
        this.getJobs(this.pageable);
    }

    sort(fieldName: string) {
        this.pageable.addSortField(fieldName);
        this.getJobs(this.pageable);
    }
}
