import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { KeyValue } from '@angular/common';
import { ControlContainer, FormGroup, FormGroupDirective, AbstractControl, FormArray, FormControl } from '@angular/forms';
import { dropRight, get, isEmpty, startCase } from 'lodash';
import { Subscription } from 'rxjs';
import { first, map, take } from 'rxjs/operators';

import { EditJobDefinitionService } from '../edit-job-definition.service';
import { createFormGroup, enumToArray, isPrimitive } from '../utils';
import { PrimitiveDataType } from '../models/job-definition';
import { DEFINITION_ERRORS, FIELD_NAMES, WARNING_NAMES } from '../field-names';
import { getValidatorsCollectionElement } from '../models/validate.decorator';
import { MetaInformation } from '../models/common';
import { generateUUID } from '@dagility-ui/kit';

@Component({
    selector: 'dp-edit-job-definition-iterative-template',
    templateUrl: './edit-job-definition-iterative-template.component.html',
    styleUrls: ['./edit-job-definition-iterative-template.component.scss', '../templates.scss'],
    host: {
        class: 'd-flex flex-column',
        '[class.template-container]': '!isFirstLevel',
        '[class.p-4]': '!isFirstLevel',
        '[class.pl-0]': 'isFirstLevel',
    },
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditJobDefinitionIterativeTemplateComponent implements OnInit, OnChanges, OnDestroy {
    @Input() definition: any;
    @Input() meta: MetaInformation;
    @Input() key: string;
    @Input() isFirstLevel = false;
    @Input() formSlice: any;
    @Input() parent: any;

    isPrimitiveField = false;
    fieldValueType: string;
    isMap = false;
    headerName = '';

    isNotTypingMap = false;
    isEnumMap = false;
    canAddMapItem = true;

    subscription$: Subscription;

    items: any[] = [];

    orderMap: Map<string, number> = new Map();
    idsMap: Map<string, string> = new Map();

    nameMapping: Record<string, any> = {};

    constructor(
        @Inject(FIELD_NAMES) private headerNames: any,
        @Inject(DEFINITION_ERRORS) public definitionErrors: any,
        @Inject(WARNING_NAMES) public warnings: any,
        public jdState: EditJobDefinitionService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.subscription$ = this.jdState.updatingRequest.subscribe(() => {
            this.cdr.markForCheck();
        });

        if (!this.parent) {
            this.jdState.path$.pipe(take(1)).subscribe(path => {
                this.parent = get(this.jdState.formJD, this.isFirstLevel ? dropRight(path) : path);
            });
        }

        this.checkCanAddMapItem();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.headerName = this.headerNames[this.key] || startCase(this.key);

        if (!!this.definition && changes['definition']) {
            this.isMap = this.key in this.meta._maps;
            this.isEnumMap = this.isMap && this.key in this.meta._enums;
            if (this.isEnumMap) {
                this.items = enumToArray(this.meta._enums[this.key]);
            }
            const fieldClass = this.meta[!this.isMap ? '_arrays' : '_maps'][this.key];
            this.isPrimitiveField = isPrimitive(fieldClass) || this.isEnumMap;
            this.fieldValueType = this.isPrimitiveField && !this.isEnumMap ? typeof fieldClass : this.isEnumMap ? 'enum' : 'object';
            this.isNotTypingMap = this.isMap && PrimitiveDataType.isPrimitiveDataType(fieldClass);

            if (this.isMap) {
                this.orderMap.clear();
                this.orderMap = new Map(
                    Object.keys((this.formSlice as FormGroup).value)
                        .sort((a, b) => a.localeCompare(b))
                        .map((key, i) => [key, i])
                );
                this.idsMap = new Map(Object.keys((this.formSlice as FormGroup).value).map(key => [key, generateUUID()]));
                this.nameMapping = (this.meta._nameMapping || {})[this.key] ?? {};
            }
        }
    }

    handleDeleteWarningMessage(key: string) {
        if (this.parent) {
            delete this.parent.typingErrors[key];
            this.jdState.form.updateValueAndValidity();
        }
    }

    handleMapKeyChange(oldKey: string, { target: { value: newKey } }: any) {
        const control = (this.formSlice as FormGroup).get(oldKey);
        if (!!(this.formSlice as FormGroup).get(newKey)) {
            return;
        }
        const order = this.orderMap.get(oldKey);
        const id = this.idsMap.get(oldKey);
        this.orderMap.delete(oldKey);
        this.idsMap.delete(oldKey);
        this.orderMap.set(newKey, order);
        this.idsMap.set(newKey, id);

        (this.formSlice as FormGroup).removeControl(oldKey);
        this.addMapControl(this.formSlice, newKey, control);

        const mapValue = this.definition[oldKey];
        delete this.definition[oldKey];
        this.definition[newKey] = mapValue;
        this.checkCanAddMapItem();
    }

    addMapControl(to: FormGroup, name: string, control: AbstractControl) {
        if (to.controls.hasOwnProperty(name)) {
            return;
        }
        to.controls[name] = control;
        control.setParent(to);
        (control as any)['_registerOnCollectionChange']((to as any)['_onCollectionChange']);
        to.updateValueAndValidity();
        (to as any)['_onCollectionChange']();
    }

    handleMapValueChange = (key: string, { target: { value } }: any) => this.setMapValueChange(key, value);

    handleMapDropdownValueChange = (key: string, { id }: any) => this.setMapValueChange(key, id);

    setMapValueChange(key: string, value: any) {
        if (!(value instanceof Event)) {
            this.definition[key] = value;
        }
    }

    checkCanAddMapItem() {
        this.canAddMapItem = !Object.keys(this.definition).some(isEmpty);
    }

    addMapItem() {
        if (this.canAddMapItem) {
            this.subscription$.add(
                this.jdState.path$
                    .pipe(
                        first(),
                        map(path => get(this.jdState.formJD, this.isFirstLevel ? dropRight(path) : path, this.jdState.formJD))
                    )
                    .subscribe(parent => {
                        const validators = getValidatorsCollectionElement(parent, this.key);
                        const value =
                            this.isPrimitiveField || this.isNotTypingMap ? this.createPrimitiveField() : this.createObjectField('_maps');
                        const key = '';
                        this.definition[key] = value;
                        this.checkCanAddMapItem();
                        (this.formSlice as FormGroup).addControl(
                            key,
                            this.isPrimitiveField || this.isNotTypingMap
                                ? this.jdState.fb.control(value)
                                : createFormGroup(this.jdState.fb, value, value, true, validators)
                        );
                        this.orderMap.set(key, Math.max(...this.orderMap.values()) + 1);
                        this.idsMap.set(key, generateUUID());
                        this.formSlice.markAsDirty();
                    })
            );
        }
    }

    removeMapItem(key: string) {
        (this.formSlice as FormGroup).removeControl(key);
        this.orderMap.delete(key);
        this.idsMap.delete(key);
        delete this.definition[key];
        this.formSlice.markAsDirty();
        this.checkCanAddMapItem();
    }

    removeArrayItem(index: number) {
        (this.formSlice as FormArray).removeAt(index);
        this.formSlice.markAsDirty();
        this.definition.splice(index, 1);
    }

    setArrayElement(index: number, { target: { value } }: any) {
        this.definition[index] = value;
    }

    addArrayItem() {
        this.subscription$.add(
            this.jdState.path$
                .pipe(
                    first(),
                    map(path => get(this.jdState.formJD, this.isFirstLevel ? dropRight(path) : path, this.jdState.formJD))
                )
                .subscribe(parent => {
                    const validators = getValidatorsCollectionElement(parent, this.key);
                    const value = this.isPrimitiveField ? this.createPrimitiveField() : this.createObjectField('_arrays');
                    (this.formSlice as FormArray).push(
                        this.isPrimitiveField
                            ? this.jdState.fb.control(value)
                            : createFormGroup(this.jdState.fb, value, value, true, validators)
                    );
                    this.formSlice.markAsDirty();
                    this.definition.push(value);
                })
        );
    }

    createPrimitiveField() {
        switch (this.fieldValueType) {
            case 'string':
                return '';
            case 'number':
                return 0;
            case 'boolean':
                return false;
            default:
                return '';
        }
    }

    createObjectField(metaCollectionName: string) {
        return Reflect.construct((this.meta as Record<string, any>)[metaCollectionName][this.key], []);
    }

    goToElement(i: number) {
        if (this.isFirstLevel) {
            this.jdState.goToStep([i.toString()]);
        } else {
            this.jdState.goToStep([this.key, i.toString()]);
        }
    }

    trackFunction(index: number) {
        return index;
    }

    trackMapFunction = (index: number, kv: KeyValue<string, FormControl>) => {
        return this.idsMap.get(kv.key);
    };

    sortByKey = (a: KeyValue<string, AbstractControl>, b: KeyValue<string, AbstractControl>) => {
        return this.orderMap.get(a.key) - this.orderMap.get(b.key);
    };

    ngOnDestroy() {
        if (this.subscription$) {
            this.subscription$.unsubscribe();
        }
    }
}
