import { AfterViewInit, ChangeDetectorRef, Component, ComponentRef, Injector, OnDestroy, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DpEditTabComponent } from '../dp-edit-tab/dp-edit-tab.component';
import { DpEditGroupComponent } from '../dp-edit-group/dp-edit-group.component';

export interface CreateTabStepComponent {
    form: FormGroup;
    createTab: boolean;
    runSaveValidators: () => void;
}

interface CreateTabStep {
    component: Type<CreateTabStepComponent>;
    header: string;
    key: string;
}

@Component({
    selector: 'dp-create-tab',
    templateUrl: './create-tab.component.html',
})
export class CreateTabComponent implements AfterViewInit, OnDestroy {
    @ViewChild('formBody', { read: ViewContainerRef, static: true }) formBody: ViewContainerRef;

    get activeStep() {
        return this.steps[this.step];
    }

    get isLastStep() {
        return this.steps.length - 1 === this.step;
    }

    get activeForm() {
        return this.form.get(this.activeStep.key) as FormGroup;
    }

    step = 0;
    steps: CreateTabStep[] = [
        {
            component: DpEditTabComponent,
            header: 'Add New Tab',
            key: 'tab',
        },
        {
            component: DpEditGroupComponent,
            header: 'Create New Group',
            key: 'group',
        },
    ];
    form = new FormGroup({});
    compRef: ComponentRef<CreateTabStepComponent>;

    constructor(private injector: Injector, public modal: NgbActiveModal, private cdref: ChangeDetectorRef) {}

    ngAfterViewInit() {
        this.goToStep();
        this.cdref.detectChanges();
    }

    back() {
        this.step -= 1;
        this.goToStep();
    }

    next() {
        this.compRef.instance.runSaveValidators();

        if (!this.activeForm.valid) {
            return;
        }

        if (this.isLastStep) {
            this.modal.close(this.form.value);
        } else {
            this.step += 1;
            this.goToStep();
        }
    }

    goToStep() {
        const { component, key } = this.activeStep;

        this.compRef?.destroy();
        this.formBody.clear();
        this.compRef = this.formBody.createComponent(component, { index: 0, injector: this.injector });

        if (this.activeForm) {
            this.compRef.instance.form = this.activeForm;
        } else {
            this.form.addControl(key, this.compRef.instance.form);
        }

        this.compRef.setInput('createTab', true);
    }

    ngOnDestroy() {
        this.compRef?.destroy();
    }
}
