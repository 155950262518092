import { LayerBlock } from 'data-processor/lib/widget-library/widget-builder/models/query.block';

export const INIT_WIDGET_SETTINGS: any = {
    id: null,
    data: {
        id: () => uuid(),
        common: {
            type: null,
        },
        chartOptions: {
            title: 'QB Widget 1',
            description: 'QB Widget 1',
            mockData: '',
            orientation: 'Vertical',
            barsPosition: null,
            xlabel: 'X Axis',
            ylabel: 'Y Axis',
            minYAxisValue: null,
            maxYAxisValue: null,
            tooltipFormatter: '',
            tooltipTrigger: null,
            enterableTooltip: false,
            relatedThreshold: false,
            higherIsBetter: false,
            disableBottomLine: false,
            mediumThreshold: null,
            criticalThreshold: null,
            hideLegend: false,
            id: null,
            showTotal: false,
            showCount: false,
            showArea: false,
            showStacked: false,
            gridPagination: false,
            scrollToBottom: false,
            serverSidePagination: false,
            dataZoom: false,
            hasResetFilter: false,
            colorBy: 'series',
        },
        groupIndex: 0,
        series: [],
        colorThreshold: [],
        accordionColumns: [],
        columns: [],
        eventDependencies: [],
        colors: ['#ff0000', '#00ff00', '#0000ff', '#ffff00', '#ff00ff', '#00ffff', '#000000'],
        tiles: [],
        additionalTemplates: [],
        statuses: [],
        tags: [],
        server: true,
        complexNamedDropdown: false,
        complexNamedDropdownLabel: '',
        complexNamedDropdownDependencies: '',
        complexNamedDropdownFilterDependency: '',
        filters: [],
        type: 'stackedbarchart',
        widgets: [],
        query: [],
        drilldownList: [],
    },
};

export function getLayerBlock(anyWidgetModel: any): LayerBlock {
    const layerBlock = new LayerBlock(anyWidgetModel);
    layerBlock.displaySettingsConfig = {
        enabled: true,
        showChartType: false,
        showBasicChartOptions: true,
        dataSeries: {
            showLabel: true,
            showColumn: false,
            showColorPicker: true,
        },
        showSizeChanger: true,
    };
    return layerBlock;
}

const uuid = () =>
    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
