import { Component } from '@angular/core';

import { BaseWidgetFilter } from '../base-widget-filter.component';

@Component({
    selector: 'dp-date-filter',
    template: `
        <lib-datepicker
            [formControl]="formControl"
            [label]="filter.hideLabel ? '' : filter.label"
            [placeholder]="filter.placeholderText || 'Select Date'"
            [withoutMaxDate]="true"
            inputDatetimeFormat="yyyy-MM-dd"
            [style.width]="popup ? '100%' : '150px'"
            class="size-s"
        ></lib-datepicker>
    `,
})
export class DateFilterComponent extends BaseWidgetFilter {
    getFilterText(): { title: string; value: any } {
        return { title: this.filter.label, value: this.formControl.value };
    }

    registerOnChange(fn: any): void {
        this.subscription.unsubscribe();
        this.subscription = this.formControl.valueChanges.subscribe(v => fn(BaseWidgetFilter.transformDate(v)));
    }
}
