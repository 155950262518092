import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {IEnvResolver, IEnv, kni} from '@dagility-ui/keep-ni';
import { ENV_TOKEN } from '@app/tokens';

interface IConfig {
    frontUrl: string;
    widgets: {
        activityTime: { inactiveMins: number };
        activityTimeOnPages: { inactiveMins: number };
        simultaneousUsers: { inactiveMins: number };
    };
}

interface IDAgilityEnvId {
    envId: string;
    envMPId: string;
}

@Injectable({
    providedIn: 'root',
})
export class KeepNIConfigService {
    private readonly baseUrl = this.env.webConsoleApiUrl;
    private config: IConfig;
    private envId: string;
    private frontUrl$: Observable<string>;

    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {
        this.frontUrl$ = this.baseUrl ? this.loadAll() : of(null);
    }

    private loadAll(): Observable<string> {
        return this.loadConfig().pipe(
            switchMap((c: IConfig) => {
                this.config = c;
                return this.loadEnvironment().pipe(
                    map((e: IDAgilityEnvId) => {
                        this.envId = e.envId;
                        // Setup Env resolver
                        kni().envResolver(new class implements IEnvResolver {
                            getEnv(): Observable<IEnv> {
                                return of({
                                    environment: location.origin,
                                    envId: e.envMPId
                                });
                            }
                        });
                        return e.envId;
                    }),
                    map(() => `${this.config.frontUrl}/${this.envId}`)
                );
            })
        );
    }

    private loadConfig(): Observable<IConfig> {
        return !this.baseUrl ? of({} as IConfig) : this.http.get<IConfig>(
            `${this.baseUrl}/config`,
            {
                headers: {'skip-global-error': 'true'},
            },
        ).pipe(
            catchError(() => of({} as IConfig)),
        );
    }

    public loadEnvironment(): Observable<IDAgilityEnvId> {
        return !this.baseUrl ? of({envId: null}) : this.http.get<any>(
            `${this.baseUrl}/data/environment?url=${this.env.serviceUrl}`,
            {
                headers: {'skip-global-error': 'true'},
            },
        ).pipe(
            catchError(() => of({envId: null})),
        );
    }

    getEnvironmentId() {
        return this.envId;
    }

    getFrontUrl(): Observable<string> {
        return this.frontUrl$;
    }
}
