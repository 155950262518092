<ng-container [formGroup]="form">
    <lib-input class="d-block mb-3" formControlName="placeholder" label="Placeholder"></lib-input>
    <div class="row mb-3">
        <lib-dropdown class="col-2" formControlName="requestType" label="Type" [items]="types"></lib-dropdown>
        <lib-input class="col-10" formControlName="query" label="URL"></lib-input>
    </div>
    <lib-codemirror-editor
        class="mb-3 d-block"
        label="Before script"
        [needRefresh]="true"
        mode="text/javascript"
        formControlName="beforeScript"
    ></lib-codemirror-editor>
    <lib-codemirror-editor
        class="mb-3 d-block"
        label="After script"
        [needRefresh]="true"
        mode="text/javascript"
        formControlName="script"
    ></lib-codemirror-editor>
</ng-container>
