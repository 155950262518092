import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropdownItem, validateFormAndDisplayErrors } from '@dagility-ui/kit';
import { IRelation, JoinType, Model } from '@app/shared/components/query-builder/models/model-graph.model';
import { QueryBuilderStore } from '@app/shared/components/query-builder/store/query-builder.store';
import { ModelGraphActionsService } from '@app/shared/components/query-builder/services/model-graph-actions.service';
import { cloneDeep } from 'lodash';

const joinTypes: Record<string, string> = {
    INNER: 'Inner Join',
    LEFT: 'Left Join',
    RIGHT: 'Right Join',
    FULL: 'Full Outer Join',
    CROSS: 'Cross Join',
};

@Component({
    selector: 'app-relation-editor',
    templateUrl: './relation-editor.component.html',
    styleUrls: ['./relation-editor.component.scss'],
})
export class RelationEditorComponent implements OnInit {
    @Input() rootModels: Model[];
    @Input() relation: IRelation;
    @Output() close = new EventEmitter<any>();
    isEditorMode: boolean;

    selectedJoinType: JoinType;
    selectedParentModel: Model;
    selectedChildModel: Model;
    selectedChildField: string;
    selectedParentField: string;
    parentModelItems: Model[];
    childModelItems: Model[];
    parentFieldItems: DropdownItem[];
    childFieldItems: DropdownItem[];
    joinTypeItems: DropdownItem[] = Object.keys(joinTypes).map(key => ({ value: key, label: key }));
    editorForm: FormGroup;
    toolItems: DropdownItem<string>[];
    schemaItems: DropdownItem<string>[];
    selectedTools: DropdownItem<string>[];
    selectedSchemas: DropdownItem<string>[];
    private toolsAndSchemas: { tool?: { value: string; label: string }; schemas?: { value: string; label: string }[] }[];

    constructor(
        private queryBuilderStore: QueryBuilderStore,
        private modelGraphActionsService: ModelGraphActionsService,
        private cdr: ChangeDetectorRef,
        private fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.resetFields();
        this.openForm();
    }

    @HostListener('document:queryBuilderChanged', ['$event'])
    onClose = () => {
        this.closeForm();
        this.cdr.detectChanges();
    };

    openForm() {
        this.editorForm = this.fb.group({
            pm: [this.selectedParentModel, Validators.required],
            pmf: [this.selectedParentField, Validators.required],
            cm: [this.selectedChildModel, Validators.required],
            cmf: [this.selectedChildField, Validators.required],
            tools: [this.selectedTools],
            schemas: [this.selectedSchemas],
        });
        this.initFields();
    }

    closeForm() {
        this.resetFields();
        this.close.emit();
    }

    private initFields() {
        this.initParentModelItems();
        if (this.relation?.leftModel) {
            this.selectedParentModel = this.relation.leftModel;
            this.editorForm.controls['pm'].patchValue(this.selectedParentModel.uid);
            this.initParentModelFieldItems();
            if (this.relation.leftModelField) {
                this.selectedParentField = this.selectedParentModel.data.fields.find(
                    f => f.dataField === this.relation.leftModelField
                )?.name;
                this.isEditorMode = true;
            } else {
                this.selectedParentField = null;
            }
        }
        this.initSelectedJoinTypeItems();
        this.prepareToolsAndSchemas();
        this.initChildModelItems();
        if (this.relation?.rightModel) {
            this.selectedChildModel = this.relation.rightModel;
            this.initChildModelFieldItems();
            this.selectedTools = this.toolItems.filter(i =>
                !this.selectedChildModel.userDefined ? i.value === this.selectedChildModel.data.dbToolId : !i.value
            );
            this.initSchemaItems(this.selectedTools);
            this.selectedSchemas = this.schemaItems.filter(i => i.value.split(':')[1] === this.selectedChildModel.data.schemaName);
            this.initChildModelItems();
            this.selectedChildModel = this.relation.rightModel;
            this.editorForm.controls['cm'].patchValue(this.selectedChildModel.originalUid);
            this.initChildModelFieldItems();
            if (this.relation.rightModelField) {
                this.selectedChildField = this.selectedChildModel.data.fields.find(
                    f => f.dataField === this.relation.rightModelField
                )?.name;
                this.isEditorMode = true;
            } else {
                this.selectedChildField = null;
            }
        }
    }

    private resetFields() {
        this.selectedParentModel = null;
        this.selectedParentField = null;
        this.selectedJoinType = null;
        this.selectedChildModel = null;
        this.selectedChildField = null;
        this.selectedTools = null;
        this.selectedSchemas = null;
        this.parentModelItems = [];
        this.childModelItems = [];
        this.childFieldItems = [];
        this.parentFieldItems = [];
        this.toolItems = [];
        this.schemaItems = [];
        this.isEditorMode = null;
    }

    onChangeForm(event: any, type: string) {
        switch (type) {
            case 'leftModel':
                this.selectedParentModel = event;
                this.initParentModelFieldItems();
                break;
            case 'rightModel':
                this.selectedChildModel = event;
                this.initChildModelFieldItems();
                break;
            case 'leftModelField':
                this.selectedParentField = event.label;
                break;
            case 'rightModelField':
                this.selectedChildField = event.label;
                break;
            case 'joinType':
                this.selectedJoinType = event.label;
                break;
        }
    }

    handleSave() {
        validateFormAndDisplayErrors(this.editorForm);
        if (this.editorForm.valid) {
            if (!this.isEditorMode) {
                this.addReference();
            } else {
                this.updateReference();
            }
        }
    }

    private addReference() {
        const parentField = this.selectedParentModel.data.fields.find(f => f.name === this.selectedParentField);
        const childField = this.selectedChildModel.data.fields.find(f => f.name === this.selectedChildField);
        this.modelGraphActionsService
            .addReference({
                sourceGraph: this.queryBuilderStore.globalModelGraph,
                relation: {
                    modelName: this.selectedParentModel.name,
                    fieldName: this.selectedParentField,
                    parentModelUid: this.selectedParentModel.uid,
                    referencedModelUid: this.selectedChildModel.uid,
                    joinType: this.selectedJoinType,
                    dataVar: '',
                    joinDescription: '',
                    joinCondition: {
                        joinOperator: 'EQ',
                        leftModelField: parentField?.dataField,
                        rightModelField: childField?.dataField,
                    },
                },
            })
            .subscribe(result => {
                this.closeForm();
                this.queryBuilderStore.updateState(result);
            });
    }

    private updateReference() {
        const parentField = this.selectedParentModel.data.fields.find(f => f.name === this.selectedParentField);
        const childField = this.selectedChildModel.data.fields.find(f => f.name === this.selectedChildField);
        this.modelGraphActionsService
            .updateReference({
                sourceGraph: this.queryBuilderStore.globalModelGraph,
                reference: {
                    parentModelUid: this.selectedParentModel.uid,
                    referencedModelUid: this.selectedChildModel.uid,
                    joinType: this.selectedJoinType,
                    dataVar: '',
                    joinCondition: {
                        joinOperator: 'EQ',
                        leftModelField: parentField?.dataField,
                        rightModelField: childField?.dataField,
                    },
                },
            })
            .subscribe(result => {
                this.closeForm();
                this.queryBuilderStore.updateState(result);
            });
    }

    filterChildModelsList(param: 'TOOL' | 'SCHEMA') {
        this.selectedTools = this.toolsAndSchemas
            .filter(tns => this.selectedTools.map(t => (t instanceof Object ? t.value : t)).includes(tns.tool.value))
            .map(tns => tns.tool);
        this.selectedSchemas = this.toolsAndSchemas
            .filter(tns => this.selectedTools.map(t => t.value).includes(tns.tool.value))
            .map(tns => tns.schemas)
            .reduce((a, b) => a.concat(b), [])
            .filter(schema => this.selectedSchemas.map(s => (s instanceof Object ? s.value : s)).includes(schema.value));

        if (param === 'TOOL') {
            if (this.selectedTools.length > 0) {
                this.initSchemaItems(this.selectedTools);
                if (this.selectedSchemas.length > 0) {
                    this.selectedSchemas = this.selectedSchemas.filter(s => this.schemaItems.map(t => t.value).includes(s.value));
                }
            } else {
                this.schemaItems = [];
                this.selectedSchemas = [];
            }
        }
        this.initChildModelItems();
        this.initChildModelFieldItems();
    }

    private initParentModelItems() {
        this.parentModelItems = RelationEditorComponent.setTrueModelName(this.queryBuilderStore.modelGraph.models);
        this.selectedParentModel = null;
    }

    private initParentModelFieldItems() {
        this.parentFieldItems = this.selectedParentModel?.data.fields.map(field => ({
            value: field.name,
            label: field.name,
        }));
        this.selectedParentField = null;
    }

    private initSelectedJoinTypeItems() {
        this.joinTypeItems = Object.keys(joinTypes).map(key => ({ value: key, label: key }));
        this.selectedJoinType = this.relation?.joinType ? (this.relation.joinType as JoinType) : 'INNER';
    }

    private prepareToolsAndSchemas() {
        this.toolsAndSchemas = this.rootModels
            .filter(
                (model, i, models) =>
                    models
                        .map(m => (m.userDefined || (!m.data.dbToolNameHint && !m.userDefined) ? undefined : m.data.dbToolId))
                        .indexOf(
                            model.userDefined || (!model.data.dbToolNameHint && !model.userDefined) ? undefined : model.data.dbToolId
                        ) === i
            )
            .map(m => ({
                tool:
                    m.userDefined || (!m.data.dbToolNameHint && !m.userDefined)
                        ? {
                              value: undefined,
                              label: 'Custom',
                          }
                        : {
                              value: m.data.dbToolId,
                              label: m.data.dbToolNameHint,
                          },
            }));
        this.toolsAndSchemas.forEach(
            tns =>
                (tns.schemas = this.rootModels
                    .filter(m =>
                        tns.tool.value ? !m.userDefined && m.data.dbToolId === tns.tool.value : !m.data.dbToolId || m.userDefined
                    )
                    .map(m => ({
                        value: `${tns.tool.value}:${tns.tool.value ? m.data.schemaName : undefined}`,
                        label: m.data.schemaName,
                    }))
                    .filter((schema, i, a) => a.map(s => s.value).indexOf(schema.value) === i))
        );
        const allSchemas = this.toolsAndSchemas.map(tns => tns.schemas).reduce((a, b) => a.concat(b));
        this.toolsAndSchemas.forEach(tns =>
            tns.schemas
                .filter(schema => allSchemas.filter(s => s.value.split(':')[1] === schema.value.split(':')[1]).length > 1)
                .forEach(schema => (schema.label = `${schema.label} [${tns.tool.label}]`))
        );

        this.toolItems = this.toolsAndSchemas.map(tns => tns.tool);
        this.selectedTools = this.toolItems.filter(i =>
            this.selectedChildModel
                ? this.selectedChildModel.data.dbToolId === i.value
                : this.queryBuilderStore.modelGraph.models.map(m => m.data.dbToolId).includes(i.value)
        );
        this.initSchemaItems(this.selectedTools);
        this.selectedSchemas = this.schemaItems.filter(i =>
            this.selectedChildModel
                ? this.selectedChildModel.data.schemaName === i.value.split(':')[1]
                : this.queryBuilderStore.modelGraph.models
                      .filter(m => m.data.dbToolId === i.value.split(':')[0])
                      .map(m => m.data.schemaName)
                      .includes(i.value.split(':')[1])
        );
    }

    private initSchemaItems(selectedTools: DropdownItem<string>[]) {
        this.schemaItems = this.toolsAndSchemas
            .filter(tns => selectedTools.map(t => t.value).includes(tns.tool.value))
            .map(tns => tns.schemas)
            .reduce((a, b) => a.concat(b))
            .filter(schema => schema.value.split(':')[0] !== 'undefined');
    }

    private initChildModelItems() {
        const modelUids: string[] = !this.isEditorMode ? this.parentModelItems.map(item => item.uid) : [];
        if (
            (this.selectedTools?.length === 0 && this.selectedSchemas?.length === 0) ||
            (this.selectedTools?.length === this.toolItems.length && this.selectedSchemas?.length === 0)
        ) {
            this.childModelItems = RelationEditorComponent.setTrueModelName(
                this.rootModels.filter(model => !modelUids.includes(model.uid))
            );
        } else {
            this.childModelItems = RelationEditorComponent.setTrueModelName(
                this.rootModels
                    .filter(model => this.selectedTools?.map(t => t.value).includes(model.userDefined ? undefined : model.data.dbToolId))
                    .filter(model =>
                        !model.userDefined && model.data.dbToolId && this.selectedSchemas?.length > 0
                            ? this.selectedSchemas?.find(
                                  s =>
                                      s.value.split(':')[0] === (model.userDefined ? 'undefined' : model.data.dbToolId + '') &&
                                      s.value.split(':')[1] === model.data.schemaName + ''
                              )
                            : true
                    )
                    .filter(model => !modelUids.includes(model.uid))
            );
        }
        this.queryBuilderStore.modelGraph.models
            .filter(m => m.subQueryGraph && !this.queryBuilderStore.modelGraph.references.find(r => r.referencedModelUid === m.uid))
            .forEach(m => this.childModelItems.push(m));

        if (!this.selectedChildModel && !this.childModelItems?.map(i => i.uid).includes(this.selectedChildModel?.uid)) {
            this.selectedChildModel = null;
        } else {
            this.selectedChildModel = this.childModelItems?.map(i => i.uid).includes(this.selectedChildModel?.uid)
                ? this.selectedChildModel
                : null;
            this.editorForm.controls['cm'].patchValue(this.selectedChildModel?.uid);
        }
    }

    private initChildModelFieldItems() {
        this.childFieldItems = this.selectedChildModel?.data.fields.map(field => ({
            value: field.name,
            label: field.name,
        }));
        if (!this.selectedChildModel) {
            this.selectedChildField = null;
        }
    }

    private static setTrueModelName(models: Model[]): Model[] {
        models = models.map(m => {
            let newModel = cloneDeep(m);
            newModel.name = newModel.data.schemaName ? `${newModel.data.schemaName}: ${newModel.name}` : newModel.name;
            return newModel;
        });
        let duplicates: string[] = [];
        models.forEach(model => {
            if (models.filter(m => m.name === model.name).length > 1) {
                duplicates.push(model.uid);
            }
        });
        return models.map(m => {
            let newModel = cloneDeep(m);
            newModel.name =
                newModel.data.dbToolId && duplicates.includes(newModel.uid)
                    ? `[${newModel.data.dbToolNameHint}] ${newModel.name}`
                    : newModel.name;
            return newModel;
        });
    }

    getJoinType = (joinType: string): string => joinTypes[joinType];
}
