import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    Optional,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { AgGridComponent } from '@dagility-ui/shared-components';
import { map, take, takeUntil } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { isDefined } from '@dagility-ui/kit';

@Component({
    selector: 'dp-grid-with-tabs',
    templateUrl: './grid-with-tabs.component.html',
    styleUrls: ['./grid-with-tabs.component.scss'],
})
export class GridWithTabsComponent implements OnDestroy, AfterViewInit {
    @ViewChild('widgetsContent', { read: ElementRef }) widgetsContent: ElementRef;
    @Input() items: Array<{ tabName: string; table: any; tabId?: string; tabCount?: number }> = [];

    @Input() placeholders: any;

    @Output() cellClicked = new EventEmitter();

    @ViewChildren(AgGridComponent) grids: QueryList<AgGridComponent>;

    destroyed$ = new Subject<void>();
    activeTab: string;

    tabStatus: Record<string, boolean> = {};

    constructor(@Optional() @Inject('EXPORTED_DATA_OPTIONS') private exportedDataOptions: { filter: (items: unknown[]) => unknown[] }) {
        if (exportedDataOptions) {
            exportedDataOptions.filter = (items: unknown[]) => items.filter((item: { tabName: string }) => item.tabName === this.activeTab);
        }
    }

    ngAfterViewInit() {
        if (isDefined(this.placeholders.defaultTabName)) {
            this.activeTab = this.placeholders.defaultTabName;
            this.tabStatus[this.activeTab] = true;
            if (this.widgetsContent) {
                const scrollerContainer = this.widgetsContent.nativeElement.querySelector('.scroller-container');
                if (scrollerContainer) {
                    const index = this.items.findIndex(x => x.tabName === this.activeTab);
                    if (index > -1) {
                        const scrollToElem = scrollerContainer.children[index];
                        scrollerContainer.scrollLeft = scrollToElem.offsetLeft;
                    }
                }
            }
        }

        if (!isDefined(this.placeholders.selectedRowId)) {
            return;
        }

        combineLatest(
            this.grids.toArray().map(grid => {
                return grid.gridReady.asObservable().pipe(map(() => grid.gridApi));
            })
        )
            .pipe(take(1), takeUntil(this.destroyed$))
            .subscribe(apis => {
                apis.forEach(gridApi => {
                    gridApi.forEachNode(node => {
                        if (node.data.id === this.placeholders.selectedRowId) {
                            node.setSelected(true);
                        }
                    });
                });
            });
    }

    handleDrilldownEvent(event: any, index: number) {
        event.tabIndex = index;

        for (const grid of this.grids.toArray()) {
            if (grid.gridApi !== event.api) {
                grid.gridApi.deselectAll();
            }
        }

        this.cellClicked.emit(event);
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }
}
