<div *ngIf="logs$ | obsWithStatus | async as logsVm">
    <div *ngIf="logsVm.loading" class="d-inline-flex justify-content-center align-items-center w-100 h-100 pb-4">
        <lib-spinner></lib-spinner>
    </div>
    <perfect-scrollbar *ngIf="logsVm.value" class="log scrollbar pb-4 bottom-border-radius">
        <div class="log-content p-4 bottom-border-radius" [style.maxHeight.px]="height">
            <ng-container *ngTemplateOutlet="contentTemplateRef || defaultTemplate; context: { logsVm: logsVm }"></ng-container>

            <ng-template #defaultTemplate>
                <div *ngIf="searchable && logsVm.value['fullLog']" class="pb-4">
                    <lib-search [(ngModel)]="searchQuery"></lib-search>
                </div>
                <div
                    *ngIf="logsVm.value['fullLog']; else noData"
                    (click)="$event.preventDefault()"
                    [innerHtml]="logsVm.value['fullLog'] | textHighlight: searchQuery"
                ></div>
            </ng-template>
        </div>
    </perfect-scrollbar>

    <div class="modal-footer">
        <div
            *ngIf="logsVm.value"
            class="d-inline-block float-right m-0"
            [ngbTooltip]="
                logsVm.value && logsVm.value['fullLog'] && sizeof(logsVm.value['fullLog']) > maxLogSize
                    ? 'Copy functionality disabled due to large size of logs'
                    : ''
            "
            [openDelay]="300"
        >
            <button
                class="btn btn-secondary m-0"
                [disabled]="logsVm.value && logsVm.value['fullLog'] && sizeof(logsVm.value['fullLog']) > maxLogSize"
                (click)="copyToClipboard()"
            >
                <fa-icon icon="facClone" class="mr-8px"></fa-icon>
                Copy to Clipboard
            </button>
        </div>
        <button *ngIf="logsVm.value && request$" class="btn btn-secondary pull-right btn-download" (click)="download()">
            <fa-icon icon="facDownload" class="mr-8px"></fa-icon>
            Download
        </button>
    </div>

    <ng-template #noData>
        <span class="ml-15px no-components-text">There are no logs</span>
    </ng-template>
</div>
