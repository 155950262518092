import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SreGanttService } from './services/sre-gantt.service';
import { SREGanttOptions } from './models/sre-gantt.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PerfectScrollbarConfig } from 'ngx-perfect-scrollbar';

@Component({
    selector: 'dp-sre-gantt',
    templateUrl: './sre-gantt.component.html',
    styleUrls: ['./sre-gantt.component.scss'],
    providers: [SreGanttService],
})
export class SreGanttComponent implements OnInit, OnDestroy {
    @Input() data: SREGanttOptions;
    @Output() drilldownEvent$ = new EventEmitter();

    readonly perfectScrollbarConfig = new PerfectScrollbarConfig({
        suppressScrollX: false,
        suppressScrollY: true,
    });

    private destroy$ = new Subject<void>();

    constructor(public service: SreGanttService) {}

    ngOnInit() {
        this.service.init(this.data);
        this.service.drilldownEvent$.pipe(takeUntil(this.destroy$)).subscribe(e => this.drilldownEvent$.next(e));
    }

    ngOnDestroy() {
        this.destroy$.next();
    }
}
