<div class="d-flex justify-content-between my-3">
    <h3 class="mt-0">Series</h3>
    <span class="btn-link cursor-pointer ml-auto" (click)="addSeries(true)">+ Add series</span>
</div>

<ng-container formArrayName="series">
    <div class="mb-2 d-flex row p-2" *ngFor="let series of seriesArray.controls; let i = index" [formGroup]="series">
        <dp-widget-color-picker
            class="align-self-start pl-2"
            *ngIf="!colorControlDisabled"
            label="Color"
            formControlName="color"
        ></dp-widget-color-picker>
        <lib-input class="col" label="Column" formControlName="column"></lib-input>
        <lib-input class="col" label="Label" formControlName="label"></lib-input>
        <lib-input *ngIf="combinedBarStackEnabled" class="col" label="Stack Name" formControlName="seriesBarStack"></lib-input>
        <lib-dropdown
            *ngIf="chartType === type.MULTIPLE_Y_AXIS || chartType === type.BAR_CHART || chartType === type.MULTIPLE_SERIES_TYPE"
            class="col"
            label="Chart Type"
            formControlName="type"
            [items]="types"
        ></lib-dropdown>
        <checkbox
            *ngIf="chartType === type.LINE_CHART && !colorControlDisabled && !areaChartOptionDisabled"
            class="ml-auto mr-4 mt-30px"
            formControlName="seriesArea"
            >Area Chart</checkbox
        >
        <ng-container *ngIf="chartType === type.LINE_CHART || chartType === type.BAR_CHART">
            <lib-input class="col" label="Markline Label" formControlName="markLineLabel"></lib-input>
            <lib-input class="col" label="Markline Value" formControlName="markLineValue"></lib-input>
        </ng-container>
        <button
            class="btn btn-link default-size delete-column-button mr-3 mt-4"
            [style.padding]="'0 8px !important'"
            (click)="removeSeries(i)"
            container="body"
        >
            <fa-icon
                icon="facTrash"
                class="delete-series-icon mt-30px"
                aria-hidden="true"
                [ngbTooltip]="'Delete series'"
                tooltipClass="project-tooltip"
                placement="top"
                [openDelay]="800"
            ></fa-icon>
        </button>
    </div>
    <div class="mt-2">
        <span class="btn-link cursor-pointer d-block add-series-button" (click)="addSeries(false)">+ Add series</span>
    </div>
</ng-container>
