import { Component, Input } from '@angular/core';

import {
    CriteriaRiskItem,
    PortfolioRiskSummary,
} from 'data-processor/lib/widget-library/widget-builder/components/risks-header/risks-header.model';

@Component({
    selector: 'dp-risks-header',
    templateUrl: './risks-header.component.html',
    styleUrls: ['./risks-header.component.scss'],
})
export class RisksHeaderComponent {
    @Input() data: { portfolioRisk: PortfolioRiskSummary, risks: CriteriaRiskItem[] };
}
