import { MenuItem } from '../../models/template-editor.model';

export function showAllMenuItems(menuItems: MenuItem[]) {
    (menuItems || []).forEach(item => {
        item.show = true;
    });
}

export function camelizeString(str: string) {
    return str
        .split(' ')
        .map(function(word, index) {
            if (index === 0) {
                return word.toLowerCase();
            }
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join('');
}

export function mergeStringArrays(source: string[], merged: string[]): string[] {
    let result = [...source];
    if (!merged) {
        return result;
    }
    result = result.concat(
        merged.filter(mergeEl => {
            return source.indexOf(mergeEl) < 0;
        })
    );
    return result;
}
