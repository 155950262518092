import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ENV_TOKEN } from '@app/tokens';

@Injectable({
    providedIn: 'root',
})
export class WorkflowsLogsService {
    private baseUrl = `${this.env.adminApiURL}/logs`;

    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {}

    getLogs(workflowId: string, buildId?: string): Observable<any> {
        const params = new HttpParams()
            .append('sources', buildId ? `buildNumber${buildId}` : '')
            .append('objectTypes', 'workflow')
            .append('sortBy', 'OLDEST')
            .append('objectIds', workflowId);

        return this.http.get(this.baseUrl, {
            params,
        });
    }
}
