import { ChangeDetectionStrategy, Component, TrackByFunction } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';

import { generateUUID } from '@dagility-ui/kit';
import { ToolService } from '@dagility-ui/shared-components';

@Component({
    selector: 'dp-sql-executor.p-4.d-flex.flex-column.overflow-hidden',
    templateUrl: './sql-executor.component.html',
    styleUrls: ['./sql-executor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SqlExecutorComponent {
    counter: Record<string, any> = {};
    sources$ = this.toolService.getJdbcTools().pipe(
        map(toolIds => [
            ...['PostgreSql', 'ClickHouse'].map(value => ({
                label: value,
                value: value.toUpperCase(),
            })),
            ...toolIds.map(value => ({
                value,
                label: value,
            })),
        ]),
        shareReplay()
    );

    tabs$ = new BehaviorSubject<Tab[]>([this.newTab(generateUUID())]);
    activeTab$ = new BehaviorSubject<string>(this.tabs$.value[0].id);

    constructor(private toolService: ToolService, public modalService: NgbActiveModal, private fb: FormBuilder) {}

    addTab() {
        const id = generateUUID();
        this.tabs$.next([...this.tabs$.value, this.newTab(id)]);
        this.activeTab$.next(id);
    }

    newTab(id: string) {
        const form = this.newForm();

        return {
            id,
            form,
            name$: form.get('source').valueChanges.pipe(
                startWith(form.get('source').value as {}),
                map(source => source || 'Query'),
                distinctUntilChanged(),
                map(source => {
                    if (!this.counter[source]) {
                        this.counter[source] = 0;
                    }

                    return `${source}-${this.counter[source]++}`;
                })
            ),
        };
    }

    newForm(): FormGroup {
        return this.fb.group({
            source: [null, Validators.required],
            query: ['', Validators.required],
        });
    }

    changeActiveTab(tabId: string) {
        this.activeTab$.next(tabId);
    }

    deleteTab(tabId: string, index: number) {
        const last = this.tabs$.value.length - 1 === index;
        this.tabs$.next(this.tabs$.value.filter(({ id }) => id !== tabId));

        if (this.activeTab$.value === tabId && this.tabs$.value.length) {
            this.activeTab$.next(this.tabs$.value[last ? index - 1 : index].id);
        }
    }

    trackByTabFn: TrackByFunction<Tab> = (_: number, tab: Tab) => tab.id;
}

interface Tab {
    id: string;
    name$: Observable<string>;
    form: FormGroup;
}
