import { ChangeDetectorRef, Component, EventEmitter, Inject, Injector, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

import { CHART_LIBRARY, ChartLibrary, ChartType } from '../chart-libraries/chart-library';
import { isDarkTheme } from '../chart-libraries/echarts/charts-colors';
import { CustomEchartsChart } from '../chart-libraries/echarts/charts/custom-echarts.chart';

@Component({
    selector: 'lib-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.scss'],
    providers: [
        {
            provide: 'chartComponent',
            useValue: ChartComponent,
        },
    ],
})
export class ChartComponent implements OnInit, OnChanges, OnDestroy {
    @Input() options: Record<string, any>;
    @Input() type: ChartType;
    @Input() filterCount: number = 0;
    @Input() height: string = '';
    @Input() zoomEventSubject: Subject<any>;
    @Input() checkHostResize = false;
    @Input() disableMarginsAdjustment = false;

    @Output() event = new EventEmitter();
    @Output() initialized = new EventEmitter();

    chartOptions: any;

    chartInstance: any;

    chart: any;

    destroy$ = new Subject<void>();

    showTable = false;

    chartTableData: Record<string, any> = {};

    pinnedBottomRowData: any = [];

    needTreemapHint = false;

    constructor(@Inject(CHART_LIBRARY) public library: ChartLibrary, private injector: Injector, private cdr: ChangeDetectorRef) {}

    async ngOnInit() {
        this.chart = new this.library.charts[this.type]();
        isDarkTheme()
            ? Object.assign(this.options?.legend?.textStyle || {}, { color: '#DEDEDE' })
            : Object.assign(this.options?.legend?.textStyle || {}, { color: '#4F4C4D' });
        this.setNeedTreemapHint();
        await this.chart.assignOptions(this.options, this.chart instanceof CustomEchartsChart);
        if (typeof this.chart.handleEvents === 'function') {
            this.chart.handleEvents(this.event);
        }
        this.chartOptions = this.chart.options;
        this.showTable = this.showGraphTable(this.options.zoomSize);
        if (this.showTable) {
            this.generateTableData();
        }

        if (!this.zoomEventSubject) {
            return;
        }

        this.zoomEventSubject.subscribe((event: any) => {
            this.showTable = this.showGraphTable(event);
            if (this.showTable) {
                this.generateTableData();
            }
        });
    }

    ngOnChanges() {
        if (this.chartOptions) {
            this.setNeedTreemapHint();
            this.chart.assignOptions(this.options);
            this.chartOptions = { ...this.chart.options };
        }

        setTimeout(() => {
            this.cdr.detectChanges();
        });
    }

    showGraphTable(event: any) {
        return !!(!!event && this.options.hasOwnProperty('showTable') && this.options.showTable);
    }

    onEChartInitialized(chartInstance: any) {
        if (typeof this.chart.handleEvents === 'function') {
            this.chart.handleEvents(this.event, chartInstance, this.injector);
        }
        this.chartInstance = chartInstance;

        this.initialized.emit();
    }

    getCategoryName() {
        if (this.chartOptions.hasOwnProperty('xAxis') && this.chartOptions.xAxis.type === 'category') {
            return this.chartOptions.xAxis.name;
        } else if (this.chartOptions.hasOwnProperty('yAxis') && this.chartOptions.yAxis.type === 'category') {
            return this.chartOptions.yAxis.name;
        }
        return '';
    }

    getCategories() {
        if (this.chartOptions.hasOwnProperty('xAxis') && this.chartOptions.xAxis.type === 'category') {
            return this.chartOptions.xAxis.data;
        } else if (this.chartOptions.hasOwnProperty('yAxis') && this.chartOptions.yAxis.type === 'category') {
            return this.chartOptions.yAxis.data;
        }
        return [];
    }

    getSumOfSeries(chartData: any) {
        return chartData.reduce((acc: any, cur: any) => (acc.hasOwnProperty('value') ? acc.value : acc + Number(cur.value)), 0);
    }

    generateTableData() {
        const graphCategories = this.getCategories();
        let columnDefs: any = [];
        let rowData: any = [];

        columnDefs.push({
            headerName: this.getCategoryName(),
            field: this.getCategoryName(),
            valueFormatter: function(params: any) {
                return params.value;
            },
            minWidth: 150,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressAndOrCondition: true,
                filterOptions: ['contains'],
            },
            headerTooltip: this.getCategoryName(),
            cellRenderer: (params: any) => {
                return params.value ? params.value : params.value === 0 ? 0 : '';
            },
            tooltip: (params: any) => {
                return params.value ? params.value : params.value === 0 ? 0 : '';
            },
        });
        for (let i = 0; i < this.chartOptions.series.length; i++) {
            const header = {
                headerName: this.chartOptions.series[i].name,
                field: this.chartOptions.series[i].name,
                valueFormatter: (params: any) => {
                    return params.value;
                },
                minWidth: 100,
                filter: 'agTextColumnFilter',
                filterParams: {
                    suppressAndOrCondition: true,
                    filterOptions: ['contains'],
                },
                headerTooltip: this.chartOptions.series[i].name,
                cellRenderer: (params: any) => {
                    return params.value ? params.value : params.value === 0 ? 0 : '';
                },
                tooltip: (params: any) => {
                    return params.value ? params.value : params.value === 0 ? 0 : '';
                },
            };
            columnDefs.push(header);
        }

        const totalRecord: any = [];
        totalRecord[columnDefs[0].field] = 'Total';
        for (let n = 0; n < this.chartOptions.series.length; n++) {
            if (this.chartOptions.series[n].data[0].hasOwnProperty('value')) {
                totalRecord[columnDefs[n + 1].field] = this.getSumOfSeries(this.chartOptions.series[n].data);
            } else {
                totalRecord[columnDefs[n + 1].field] = this.chartOptions.series[n].data.reduce(
                    (acc: any, cur: any) => acc + Number(cur),
                    0
                );
            }
        }
        this.pinnedBottomRowData = [];
        this.pinnedBottomRowData.push(totalRecord);

        for (let j = 0; j < graphCategories.length; j++) {
            const record: any = [];
            record[columnDefs[0].field] = graphCategories[j];
            for (let k = 0; k < this.chartOptions.series.length; k++) {
                if (this.chartOptions.series[k].data[j].hasOwnProperty('value')) {
                    record[columnDefs[k + 1].field] = this.chartOptions.series[k].data[j].value;
                } else {
                    record[columnDefs[k + 1].field] = this.chartOptions.series[k].data[j];
                }
            }
            rowData.push(record);
        }

        this.chartTableData = {
            columnDefs: columnDefs,
            rowData: rowData,
        };
    }

    setNeedTreemapHint() {
        if (this.type !== 'treemap' || !this.options.breadcrumb) {
            return;
        }

        this.needTreemapHint = !this.options.breadcrumb.show;
    }

    isObjectEmpty(obj: any) {
        return !Object.keys(obj).length;
    }

    ngOnDestroy() {
        if (this.chart && typeof this.chart.destroy === 'function') {
            this.chart.destroy();
            this.destroy$.next(null);
        }
    }
}
