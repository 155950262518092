import { Routes } from '@angular/router';

import { JOB_GRAPH_SEGMENT } from '../data-morph-routing.constants';
import { EditJobDefinitionFormComponent } from './edit-job-definition-form/edit-job-definition-form.component';
import { ProcessorMonitoringComponent } from './processor-monitoring.component';
import { ToolGraphViewComponent } from './tool-graph-view/tool-graph-view.component';

export const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: ProcessorMonitoringComponent,
    },
    {
        path: `${JOB_GRAPH_SEGMENT}/:toolId`,
        component: ToolGraphViewComponent,
    },
    {
        path: ':id',
        component: EditJobDefinitionFormComponent,
    },
    {
        path: 'new',
        component: EditJobDefinitionFormComponent,
    },
];
