import { Deserializable, JobDefinitionDataSource } from '../../../models/job-definition';

export class DataSourceAction extends Deserializable {
    _arrays = {};
    _class = { dataSource: JobDefinitionDataSource };
    _enums = {};
    _maps = {};
    meta = {
        _arrays: this._arrays,
        _class: this._class,
        _enums: this._enums,
        _maps: this._maps,
    };

    dataSource: JobDefinitionDataSource = new JobDefinitionDataSource(null);
    variable = '';

    getType() {
        return 'DataSourceAction';
    }

    constructor(instance?: any) {
        super();
        this.fillByInstance(DataSourceAction, instance);
    }
}
