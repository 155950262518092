import { NgModule } from '@angular/core';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

import { PluginsComponent } from './plugins/plugins.component';
import { ToolLibraryComponent } from './plugins/tool-library/tool-library.component';
import { ConfiguredComponent } from './plugins/configured/configured.component';
import { ConfigureAccessPopupComponent } from './plugins/tool-library/configure-access-popup/configure-access-popup.component';

import { ProcessorMonitoringModule } from './processor-monitoring/processor-monitoring.module';
import { EditToolTemplatePopupComponent } from './plugins/tool-library/edit-tool-template-popup/edit-tool-template-popup.component';
import { EditToolTemplateAuthTypeComponent } from './plugins/tool-library/edit-tool-template-popup/edit-tool-template-auth-type/edit-tool-template-auth-type.component';
import { ProjectService, SharedComponentsModule } from '@dagility-ui/shared-components';
import { ConfigureIntegrationComponent } from './plugins/configured/configure-integration/configure-integration.component';
import { SelfTestsLogModalComponent } from './plugins/tool-library/self-tests-log-modal/self-tests-log-modal.component';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
        PluginsComponent,
        ToolLibraryComponent,
        ConfiguredComponent,
        ConfigureAccessPopupComponent,
        EditToolTemplatePopupComponent,
        EditToolTemplateAuthTypeComponent,
        ConfigureIntegrationComponent,
        SelfTestsLogModalComponent,
    ],
    imports: [SharedComponentsModule, NgbNavModule, ProcessorMonitoringModule, RouterModule],
    providers: [ProjectService],
    exports: [PluginsComponent],
})
export class DataProcessorModule {}
