import { Injectable } from '@angular/core';
import { omit, sum, isEqual } from 'lodash';

import { generateUUID, Store } from '@dagility-ui/kit';
import { DropDirection } from '@dagility-ui/shared-components';
import {
    AnyWidgetModel,
    AsyncQuery,
    ComplexWidgetLayout,
    DropdownFilter,
    WidgetDrilldown,
    WidgetFilter,
    WidgetFilterType,
    WidgetHelp,
    WidgetQuery,
    WidgetType,
} from '../models/any-widget.model';
import { WidgetAction, WidgetFilterNode, WidgetGraph, WidgetLayer } from '../models/widget.graph';
import { WidgetBuilderFacade } from '../state/widget-builder.facade';
import { isDynamicFilter } from '../services/widget-builder.util';
import { Breakpoint } from 'data-processor';
import { AsyncQueryBlock } from 'data-processor/lib/widget-library/widget-builder/models/query.block';

export type WidgetBuilderState = WidgetGraph & { breakpoints: string[] };

export const COMPLEX_LEVELS_COUNT: { [key in ComplexWidgetLayout]: [number, number] } = {
    '1-1': [2, 0],
    '1-2': [1, 2],
    '2-1': [2, 1],
    '2-2': [2, 2],
    '1-1-vertical': [1, 1],
};

const counter = {
    filter: 0,
    query: 0,
    layer: 0,
    event: 0,
    asyncQuery: 0,
};

@Injectable()
export class WidgetBuilderStore extends Store<WidgetBuilderState> {
    positionBreakpoints: Breakpoint[] = [];

    constructor(private facade: WidgetBuilderFacade) {
        super({
            root: null,
            layers: {} as any,
            queries: {} as any,
            filters: {} as any,
            actions: [] as any[],
            breakpoints: [],
        });
    }

    dirty: boolean;
    firstLoad = true;
    extensionsState: Record<string, any> = {};
    lastSavedState: WidgetGraph = null;
    originalId: String = '';

    init(state: WidgetGraph): void {
        this.setState(state);
        this.lastSavedState = state;
        if (!this.dirty) {
            this.originalId = state.root;
        }
    }

    markAsPristine(): void {
        this.dirty = false;
        this.lastSavedState = this.value;
    }

    setState(partialState: Partial<WidgetBuilderState>): void {
        if (!this.firstLoad) {
            this.dirty = true;
        }
        this.firstLoad = false;
        super.setState(partialState);
    }

    addFilterToLayer(layerId: string): void {
        const filter = this.facade.buildFilterGroup({} as WidgetFilter).value;
        filter.id = generateUUID();
        filter.placeholder = `Filter_${counter.filter++}`;

        this.addToLayer(layerId, 'filters', filter);
    }

    addQueryToLayer(layerId: string): void {
        const query = this.facade.buildQueryForm({} as WidgetQuery).value;
        query.id = generateUUID();
        query.placeholder = `Query_${counter.query++}`;

        this.addToLayer(layerId, 'queries', query);
    }

    buildLayer(): WidgetLayer {
        const layer = this.facade.buildForm({} as AnyWidgetModel, { basicChartOptionsControlsAvailable: false, layerLevel: true }).value;
        const common = layer.common;
        delete layer.common;
        layer.chartOptions.title = `Layer ${counter.layer++}`;

        return {
            ...layer,
            ...common,
            queries: [],
        };
    }

    addDrilldown(layerId: string): void {
        const drilldown = this.buildLayer();
        const data: Omit<WidgetDrilldown, 'widget'> = {
            drilldownScript: '',
            matcherScript: '',
            displayType: null,
            target: '',
            default: false,
        };

        this.setState({
            layers: {
                ...this.value.layers,
                [drilldown.id]: drilldown,
            },
            actions: [
                ...this.value.actions,
                {
                    id: generateUUID(),
                    from: layerId,
                    to: drilldown.id,
                    data,
                },
            ],
        });
    }

    addEventHandler(layerId: string) {
        const layer = this.value.layers[layerId];
        const handler = this.facade.buildEventHandlerForm({ eventId: `Event-${counter.event++}` } as any).value;

        this.setState({
            layers: {
                ...this.value.layers,
                [layerId]: {
                    ...layer,
                    handlers: [...(layer.handlers || []), handler],
                },
            },
        });
    }

    addAsyncQuery(layerId: string) {
        const layer = this.value.layers[layerId];
        const asyncQuery: AsyncQuery = this.facade.buildAsyncQueryForm({} as any).value;
        asyncQuery.placeholder = `placeholder-${counter.asyncQuery}`;

        this.setState({
            layers: {
                ...this.value.layers,
                [layerId]: {
                    ...layer,
                    asyncQueries: [...(layer.asyncQueries || []), asyncQuery],
                },
            },
        });
    }

    addToLayer(id: string, field: 'queries' | 'filters', obj: any): void {
        const { layers } = this.value;

        this.setState({
            layers: {
                ...layers,
                [id]: {
                    ...layers[id],
                    [field]: [...layers[id][field], obj.id],
                },
            },
            [field]: {
                ...this.value[field],
                [obj.id]: obj,
            },
        });
    }

    changeBlockProperty(blockId: string, collection: 'layers' | 'queries' | 'filters', fieldName: string, value: any): void {
        this.setState({
            [collection]: {
                ...this.value[collection],
                [blockId]: {
                    ...this.value[collection][blockId],
                    [fieldName]: value,
                },
            },
        });
    }

    updateEventHandler(layerId: string, index: any, data: any) {
        const layer = this.value.layers[layerId];

        this.setState({
            layers: {
                ...this.value.layers,
                [layerId]: {
                    ...layer,
                    handlers: layer.handlers.map((handler, idx) => (idx === index ? data : handler)),
                },
            },
        });
    }

    updateAsyncQuery(layerId: string, placeholder: string, data: any) {
        const layer = this.value.layers[layerId];

        this.setState({
            layers: {
                ...this.value.layers,
                [layerId]: {
                    ...layer,
                    asyncQueries: (layer.asyncQueries || []).map(query => (query.placeholder === placeholder ? data : query)),
                },
            },
        });
    }

    updateDynamicFilter(id: string, data: WidgetFilter): void {
        const filterFromStore = this.value.filters[id];
        if (data.type !== WidgetFilterType.HIDDEN && !(data as DropdownFilter).dynamic) {
            const { [filterFromStore.query]: query, ...queries } = this.value.queries;

            this.setState({
                filters: {
                    ...this.value.filters,
                    [id]: {
                        id,
                        ...omit(data, 'query'),
                    },
                },
                queries,
            });

            return;
        }

        const queryFromStore = this.value.queries[filterFromStore.query];

        this.setState({
            filters: {
                ...this.value.filters,
                [id]: {
                    id,
                    ...data,
                    query: queryFromStore.id,
                },
            },
            queries: {
                ...this.value.queries,
                [queryFromStore.id]: {
                    ...(data as DropdownFilter).query,
                    id: queryFromStore.id,
                },
            },
        });
    }

    updateFilterToDynamic(id: string, obj: any): void {
        const queryId = generateUUID();

        this.setState({
            filters: {
                ...this.value.filters,
                [id]: {
                    ...this.value.filters[id],
                    ...obj,
                    id,
                    query: queryId,
                },
            },
            queries: {
                ...this.value.queries,
                [queryId]: {
                    ...obj.query,
                    id: queryId,
                },
            },
        });
    }

    updateComplexLayer(id: string, layer: WidgetLayer): void {
        const layerFromStore = this.value.layers[id];
        const widgetsCount = sum(COMPLEX_LEVELS_COUNT[layer.chartOptions.layout] || []);

        if (layerFromStore.type !== WidgetType.COMPLEX || widgetsCount > layerFromStore.widgets.length) {
            const widgets: string[] = [];

            const layers = Array.from({ length: widgetsCount - layerFromStore.widgets.length }).reduce<Record<string, any>>(
                (acc, _, index) => {
                    const widget = this.buildLayer();
                    widget.chartOptions.title = `${layer.chartOptions.title} ${layerFromStore.widgets.length + index}`;
                    widgets.push(widget.id);
                    acc[widget.id] = widget;

                    return acc;
                },
                {}
            );

            this.setState({
                layers: {
                    ...this.value.layers,
                    ...layers,
                    [id]: {
                        ...layerFromStore,
                        ...layer,
                        widgets: [...layerFromStore.widgets, ...widgets],
                    },
                },
            });
        } else if (widgetsCount < layerFromStore.widgets.length) {
            const splitIndex = widgetsCount;
            const deletedWidgets = layerFromStore.widgets.slice(splitIndex);
            const widgets = layerFromStore.widgets.slice(0, splitIndex);

            this.setState({
                layers: {
                    ...omit(this.value.layers, deletedWidgets),
                    [id]: {
                        ...layerFromStore,
                        ...layer,
                        widgets,
                    },
                },
            });
        } else {
            this.setState({
                layers: {
                    ...this.value.layers,
                    [id]: {
                        ...layerFromStore,
                        ...layer,
                    },
                },
            });
        }
    }

    updateBlock(id: string, collection: 'layers' | 'queries' | 'filters', obj: any): void {
        if (collection === 'filters' && [WidgetFilterType.DROPDOWN, WidgetFilterType.HIDDEN].includes(obj.type) && obj.query) {
            this.updateFilterToDynamic(id, obj);

            return;
        }

        if (collection === 'layers' && [(obj as WidgetLayer).type, this.value.layers[id].type].includes(WidgetType.COMPLEX)) {
            this.updateComplexLayer(id, obj);

            return;
        }

        obj.tags = obj.tags ? obj.tags.filter(Boolean).map((value: { label: string; value: string }) => value.label) : [];

        this.setState({
            [collection]: {
                ...this.value[collection],
                [id]: {
                    ...this.value[collection][id],
                    ...obj,
                },
            },
        });
    }

    updateHelp(id: string, help: WidgetHelp) {
        this.setState({
            layers: {
                ...this.value.layers,
                [id]: {
                    ...this.value.layers[id],
                    help,
                },
            },
        });
    }

    updateDrilldown(id: string, data: any): void {
        this.setState({
            actions: this.value.actions.map(action =>
                action.id === id
                    ? {
                          ...action,
                          data,
                      }
                    : action
            ),
        });
    }

    move({
        toId,
        fromLayerId,
        movedId,
        collectionName,
        direction,
    }: {
        toId: string;
        fromLayerId: string;
        movedId: string;
        collectionName: 'queries' | 'filters';
        direction: DropDirection;
    }) {
        const toLayer = Object.values(this.value.layers).find(layer => layer[collectionName].includes(toId));
        const filteredCollection = this.value.layers[fromLayerId][collectionName].filter(id => id !== movedId);
        const toCollection = fromLayerId === toLayer.id ? filteredCollection : this.value.layers[toLayer.id][collectionName];
        const index = Math.max(toCollection.indexOf(toId) + (direction === 'bottom' ? 1 : 0), 0);
        let filters = this.value.filters;
        const filter = filters[movedId];

        if (collectionName === 'filters' && [WidgetFilterType.HIDDEN, WidgetFilterType.DROPDOWN].includes(filter.type)) {
            if (fromLayerId !== toLayer.id) {
                filters = {
                    ...filters,
                    [movedId]: {
                        ...filter,
                        dependencies: [],
                    } as any,
                };
            } else {
                const prevFilters = toCollection.slice(0, index);
                const prevFiltersMap = prevFilters.reduce<Record<string, boolean>>((acc, id) => {
                    acc[filters[id].placeholder] = true;

                    return acc;
                }, {});

                filters = {
                    ...filters,
                    [movedId]: {
                        ...filter,
                        dependencies: ((filter as any).dependencies || []).filter((placeholder: string) => !!prevFiltersMap[placeholder]),
                    } as any,
                    ...this.removeFilterFromDependencies(prevFilters, filter),
                };
            }
        }

        this.setState({
            ...this.value,
            layers: {
                ...this.value.layers,
                [fromLayerId]: {
                    ...this.value.layers[fromLayerId],
                    [collectionName]: filteredCollection,
                },
                [toLayer.id]: {
                    ...this.value.layers[toLayer.id],
                    [collectionName]: [...toCollection.slice(0, index), movedId, ...toCollection.slice(index)],
                },
            },
            filters,
        });
    }

    toggleBreakpoint(breakpoint: Breakpoint): void {
        const index = this.positionBreakpoints.findIndex(b => isEqual(b, breakpoint));

        if (index !== -1) {
            this.positionBreakpoints = this.positionBreakpoints.filter((_, i) => i !== index);
        } else {
            this.positionBreakpoints = [...this.positionBreakpoints, breakpoint];
        }

        const breakpointId = `${breakpoint.queryId}|${breakpoint.script}`;
        const breakpoints = [...this.value.breakpoints];
        const breakpointIndex = breakpoints.indexOf(breakpointId);

        if (breakpointIndex !== -1) {
            breakpoints.splice(breakpointIndex, 1);
        } else {
            breakpoints.push(breakpointId);
        }

        this.setState({
            breakpoints,
        });
    }

    delete(layerId: string, id: string, collection: 'queries' | 'filters'): void {
        let { [id]: value, ...filteredCollection } = this.value[collection];

        if (collection === 'filters') {
            filteredCollection = {
                ...filteredCollection,
                ...this.removeFilterFromDependencies(this.value.layers[layerId].filters, value as WidgetFilterNode),
            } as any;
        }

        let state: Partial<WidgetBuilderState> = {
            layers: {
                ...this.value.layers,
                [layerId]: {
                    ...this.value.layers[layerId],
                    [collection]: this.value.layers[layerId][collection].filter(elId => id !== elId),
                },
            },
            [collection]: filteredCollection,
        };

        if (collection === 'filters' && value.query) {
            const { [value.query]: _, ...filteredQueries } = this.value.queries;
            state = {
                ...state,
                queries: filteredQueries,
            };
        }

        this.setState({
            ...this.value,
            ...state,
        });
    }

    deleteGraphItems(activeBlock: any): void {
        if (activeBlock.type === 'drilldown') {
            this.deleteLayers(activeBlock.to);
        } else if (activeBlock.collection === 'layers') {
            this.deleteLayers(activeBlock.id);
        } else if (activeBlock.viewType === 'handler') {
            this.deleteEventHandler(activeBlock.layerId, activeBlock.index);
        } else if (activeBlock instanceof AsyncQueryBlock) {
            this.deleteAsyncQuery(activeBlock.layerId, activeBlock.placeholder);
        } else {
            this.delete(activeBlock.layerId, activeBlock.id, activeBlock.collection);
        }
    }

    deleteAsyncQuery(layerId: string, placeholder: string) {
        const layer = this.value.layers[layerId];

        this.setState({
            layers: {
                ...this.value.layers,
                [layerId]: {
                    ...layer,
                    asyncQueries: (layer.asyncQueries || []).filter(asyncQuery => asyncQuery.placeholder !== placeholder),
                },
            },
        });
    }

    deleteEventHandler(layerId: string, eventHandlerId: number) {
        const layer = this.value.layers[layerId];

        this.setState({
            layers: {
                ...this.value.layers,
                [layerId]: {
                    ...layer,
                    handlers: (layer.handlers || []).filter((handler: any, index: number) => index !== eventHandlerId),
                },
            },
        });
    }

    deleteLayers(root: string, deleteRoot = true): void {
        const [inputMap, outputMap] = this.state.actions.reduce<[Record<string, WidgetAction[]>, Record<string, WidgetAction[]>]>(
            ([inputAcc, outputAcc], action) => {
                if (!outputAcc[action.from]) {
                    outputAcc[action.from] = [];
                }
                if (!inputAcc[action.to]) {
                    inputAcc[action.to] = [];
                }

                inputAcc[action.to].push(action);
                outputAcc[action.from].push(action);

                return [inputAcc, outputAcc];
            },
            [{}, {}]
        );
        const deletedIds = {
            queries: new Set<string>(),
            layers: new Set<string>(),
            filters: new Set<string>(),
            actions: new Set<string>(),
        };
        let first = true;

        const doDelete = (id: string) => {
            const layer = this.value.layers[id];

            if (deleteRoot || !first) {
                (inputMap[id] || []).forEach(action => deletedIds.actions.add(action.id));
                deletedIds.layers.add(id);

                layer.filters.forEach(filterId => {
                    deletedIds.filters.add(filterId);
                    const filter = this.value.filters[filterId];

                    if (filter.query) {
                        deletedIds.queries.add(filter.query);
                    }
                });

                layer.queries.forEach(queryId => deletedIds.queries.add(queryId));

                layer.widgets.forEach(complexId => doDelete(complexId));
            }
            first = false;

            const actions = outputMap[layer.id] || [];

            actions.forEach(action => {
                deletedIds.actions.add(action.id);

                doDelete(action.to);
            });
        };

        doDelete(root);
        const queries = [...deletedIds.queries];

        this.setState({
            layers: omit(this.value.layers, ...deletedIds.layers),
            filters: omit(this.value.filters, ...deletedIds.filters),
            queries: omit(this.value.queries, queries),
            actions: this.value.actions.filter(({ id }) => !deletedIds.actions.has(id)),
            breakpoints: this.value.breakpoints.filter(breakpoint => !queries.some(id => breakpoint.includes(id))),
        });
    }

    private removeFilterFromDependencies(filters: string[], filter: WidgetFilter): WidgetBuilderState['filters'] {
        return filters.reduce<Record<string, any>>((acc, id) => {
            const currentFilter = this.value.filters[id];

            if (isDynamicFilter(currentFilter)) {
                acc[id] = {
                    ...currentFilter,
                    dependencies: (currentFilter.dependencies || []).filter((placeholder: string) => placeholder !== filter.placeholder),
                };
            }

            return acc;
        }, {});
    }
}
