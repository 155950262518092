<ng-template ngbPanelHeader let-opened="opened">
    <dp-dashboard-group-header
        *ngIf="showGroupHeader"
        class="mt-2 mb-3"
        [group]="group"
        [opened]="opened"
        [edit]="edit"
        (save)="handleSaveGroup($event)"
    ></dp-dashboard-group-header>
</ng-template>
<ng-template ngbPanelContent>
    <dp-gridster-resize *ngIf="gridsterSettings" [dragging]="dragging" class="w-100" [tabId]="tabId" [edit]="edit">
        <ng-template>
            <ngx-gridster
                dpGridsterAutoHeight
                dpGridsterDir
                [tabId]="tabId"
                [edit]="edit"
                [groupId]="group.id"
                [options]="gridsterOptions"
                [attr.data-group-id]="group.id"
                #gridster
                (reflow)="!edit && onReflow(gridster)"
                [widgets]="group.widgets"
                [dragAndDrop]="!!edit"
            >
                <ng-container *ngFor="let widget of group.widgets; trackBy: trackByWidget">
                    <ngx-gridster-item
                        *ngIf="widgets[widget]"
                        style="z-index: 2;"
                        #item
                        class="gridster-item"
                        [x]="gridsterSettings[widget].x"
                        [xSm]="gridsterSettings[widget].xSm"
                        [xMd]="gridsterSettings[widget].xMd"
                        [xLg]="gridsterSettings[widget].xLg"
                        [xXl]="gridsterSettings[widget].xXl"
                        [y]="gridsterSettings[widget].y"
                        [ySm]="gridsterSettings[widget].ySm"
                        [yMd]="gridsterSettings[widget].yMd"
                        [yLg]="gridsterSettings[widget].yLg"
                        [yXl]="gridsterSettings[widget].yXl"
                        [w]="gridsterSettings[widget].w"
                        [h]="gridsterSettings[widget].h"
                        [wSm]="gridsterSettings[widget].wSm"
                        [hSm]="gridsterSettings[widget].hSm"
                        [wMd]="gridsterSettings[widget].wMd"
                        [hMd]="gridsterSettings[widget].hMd"
                        [wLg]="gridsterSettings[widget].wLg"
                        [hLg]="gridsterSettings[widget].hLg"
                        [wXl]="gridsterSettings[widget].wXl || 6"
                        [hXl]="gridsterSettings[widget].hXl || 3"
                        [options]="widgets[widget].breakpointMinSizes[gridsterOptionsService.breakpoint$ | async]"
                        (change)="handleGridsterChange($event, widget)"
                        (start)="onStart(gridster, item, $event)"
                        (drop)="onDrop(gridster, item, widget, $event)"
                        (cancel)="onCancel(gridster, item, $event)"
                        ngxGridsterItemPrototype
                        [config]="{ helper: true }"
                    >
                        <ng-template
                            [ngTemplateOutlet]="widgetTemplate"
                            [ngTemplateOutletContext]="{ $implicit: widgets[widget] }"
                        ></ng-template>

                        <dp-gridster-item-size></dp-gridster-item-size>
                    </ngx-gridster-item>
                </ng-container>

                <dp-drag-and-drop-area *ngIf="showDragAndDropArea$ | async"></dp-drag-and-drop-area>
            </ngx-gridster>
        </ng-template>
    </dp-gridster-resize>
</ng-template>

<ng-template #widgetTemplate let-widget>
    <dp-any-widget-wrapper
        dpIlluminateExtensions
        class="h-100"
        [edit]="edit"
        [options]="widget.data"
        [separatedView]="true"
        [placeholders]="widgetsPlaceholders"
        [externalOptions]="widget | mapper: getExternalOptions"
        [eventDependencies]="store.eventDependencies"
        [widgetId]="widget.widgetId"
        [externalWidgetId]="widget.dashboardWidgetId"
        [extensionsEnabled]="extensionsEnabled"
        [attr.data-type]="widget.data.type"
        (jsonClicked)="handleExportWidget(widget)"
        (editClicked)="handleEditWidget(widget)"
        (deleteClicked)="handleDeleteWidget(widget)"
    ></dp-any-widget-wrapper>
</ng-template>
