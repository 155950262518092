<a
    class="px-2 color-brand dashboard-tab"
    [ngClass]="{ 'mr-2': !edit }"
    *ngIf="showTabHeader"
    ngbNavLink
    [class.active]="active"
    [class.cursor-move]="edit && tabsLength > 1"
    cdkDrag
    [actualContainer]="'.scroller-container'"
    >{{ tab.name }}</a
>
<ng-template ngbNavContent>
    <!--    overflow-y-auto class is used for correct behaviour gridster scroll-->
    <perfect-scrollbar *ngIf="loaded" class="pr-3 overflow-y-auto" style="height: calc(100% - 0.25rem)" dpPerfectScrollbarEventPropagation>
        <div *ngIf="edit" class="d-flex p-1">
            <button class="btn btn-link" (click)="handleAddGroup()">
                <fa-icon icon="plus" style="font-size: 1.2rem;" class="mr-2"></fa-icon>
                Add New Group
            </button>
            <button
                class="ml-auto btn btn-link"
                [disabled]="tab.groups.length <= 1"
                (click)="openReorderDialog()"
                data-test-id="reorder-groups-btn"
            >
                <fa-icon icon="bars" class="mr-2"></fa-icon>
                Reorder Groups
            </button>
        </div>
        <ngb-accordion [activeIds]="expandedGroups" [destroyOnHide]="false" (panelChange)="handleGroupToggle($event)">
            <ng-container *ngFor="let group of tab.groups">
                <dp-dashboard-group
                    *ngIf="edit || groups[group].definition.type !== groupVisibility.HIDDEN"
                    [group]="groups[group]"
                    [groupsCount]="tab.groups.length"
                    [id]="group | mapper: groupId"
                    [edit]="edit"
                    [tabId]="tab.id"
                    [widgets]="widgets$ | async"
                    [widgetsPlaceholders]="widgetsPlaceholders"
                    [extensionsEnabled]="extensionsEnabled"
                >
                </dp-dashboard-group>
            </ng-container>
        </ngb-accordion>
    </perfect-scrollbar>
</ng-template>
