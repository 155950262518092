import { Component, OnInit } from '@angular/core';
import { GridColumn, sortingDown, sortingUp } from '@dagility-ui/kit';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Sort, sortArrayByFieldAndDirection, sortGridColumn } from '@dagility-ui/shared-components/utils/sorting.utils';
import { map, switchMap } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface PublishComponentDetails {
    id: number;
    name: string;
    projectName: string;
    status?: 'Failed' | 'Success';
}

@Component({
    selector: 'app-publish-component-changes-details',
    templateUrl: './publish-component-changes-details.component.html',
    styleUrls: ['./publish-component-changes-details.component.scss'],
})
export class PublishComponentChangesDetailsComponent implements OnInit {
    retryFn: (details: PublishComponentDetails[]) => void;

    details: PublishComponentDetails[] = [];

    gridColumns: Array<GridColumn> = [
        { title: 'Component Name', field: 'name', sortingField: 'name', minWidth: '100px', width: '35%' },
        { title: 'Project', field: 'projectName', sortingField: 'projectName', width: '35%', minWidth: '100px' },
        { title: 'Status', field: 'status', sortingField: 'status', width: '30%', minWidth: '100px' },
    ];

    icons = {
        sortingUp,
        sortingDown,
    };

    details$: Observable<PublishComponentDetails[]>;

    sort$ = new BehaviorSubject({} as Sort);

    componentsToRepublish: PublishComponentDetails[] = [];

    constructor(public activeModal: NgbActiveModal) {}

    ngOnInit() {
        this.componentsToRepublish = this.details.filter(detail => detail.status === 'Failed');
        this.details$ = of(this.details).pipe(
            switchMap(details => this.sort$.pipe(map(({ field, direction }) => sortArrayByFieldAndDirection(details, field, direction))))
        );
    }

    sortClick(index: number) {
        sortGridColumn(index, this.gridColumns, this.sort$);
    }

    retryAll() {
        this.activeModal.close();
        if (typeof this.retryFn === 'function') {
            this.retryFn(this.componentsToRepublish);
        }
    }
}
