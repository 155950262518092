import { Injectable } from '@angular/core';
import { ModalService } from '@dagility-ui/kit';
import { catchError } from 'rxjs/operators';
import { StompService } from '@app/shared/services/admin/stomp.service';
import { CiCdTemplateService, TemplateSourceFilesPublishDto } from '../../../../../../api/ci-cd-template.service';
import { PublishComponentDetails } from '../publish-component-changes-details/publish-component-changes-details.component';
import { PublishComponentChangesProgressComponent } from './publish-component-changes-progress.component';

@Injectable({
    providedIn: 'root',
})
export class PublishComponentChangesService {
    constructor(
        private readonly ciCdTemplateService: CiCdTemplateService,
        private modal: ModalService,
        private stompService: StompService
    ) {}

    publish(
        templateId: string,
        componentId: string,
        publishDto: TemplateSourceFilesPublishDto,
        allComponentsToPublish: PublishComponentDetails[]
    ) {
        let componentsToProceed = publishDto.componentIds.length;

        const retryFn = (componentsToRepublish: PublishComponentDetails[]) => {
            const subPublishDto: TemplateSourceFilesPublishDto = {
                pushToRepo: true,
                commit: publishDto.commit,
                componentIds: componentsToRepublish.map(component => component.id),
            };

            this.publish(templateId, componentId, subPublishDto, componentsToRepublish);
        };
        let dialog = this.modal.open(
            PublishComponentChangesProgressComponent,
            {
                backdrop: 'static',
                backdropClass: 'publish-component-changes-backdrop',
                windowClass: 'publish-component-changes-window',
            },
            {
                allComponents: allComponentsToPublish,
                retryFn,
            }
        );

        publishDto.componentIds.forEach(compId => {
            const topic = `sourceCodeGeneration-${compId}`;
            const subscription = this.stompService.listenTopic(topic).subscribe(info => {
                if (!dialog) {
                    subscription.unsubscribe();
                }

                const infoBody = JSON.parse(info.body);
                const status = infoBody.creationPhase.status;
                switch (status) {
                    case 'SUCCESS': {
                        componentsToProceed--;
                        dialog.componentInstance.publishedComponents.push(compId);
                        subscription.unsubscribe();
                        break;
                    }
                    case 'ERROR': {
                        componentsToProceed--;
                        dialog.componentInstance.failedComponents.push(compId);
                        subscription.unsubscribe();
                        break;
                    }
                }
                if (componentsToProceed === 0) {
                    dialog.componentInstance.finished = true;
                }
            });
        });
        this.ciCdTemplateService
            .publishSourceFiles(templateId, componentId, publishDto)
            .pipe(
                catchError(err => {
                    dialog.close();
                    dialog = null;
                    return err;
                })
            )
            .subscribe();
    }
}
