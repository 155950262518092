<div *ngIf="!workflows ? loader : workflows.length > 0; else noWorkflows" class="mt-4">
    <div class="w-100 d-flex">
        <div *ngFor="let column of gridColumns; let i = index"
             [style.width]="column.width"
             class="text-nowrap align-items-center px-12px"
             style="font-size: 12px;"
        >
            <span class="secondary-text-and-icons"
                  *ngIf="i !== 1 || withProjectCol"
                  [style.margin-left]="i === 0 ? '36px' : '0'"
                  [class.blue]="column.sort"
                  [class.cursor-pointer]="column.sortingField"
                  [class.cursor-default]="!column.sortingField"
                  (click)="sortAccordion(i)"
            >
                {{ column.title }}
            </span>
            <span
                *ngIf="column.sortingField && (i !== 1 || withProjectCol)"
                class="sorting-icon ml-10px"
                [class.icon-base]="column.sort !== ''"
                [class.cursor-pointer]="column.sortingField"
                [class.cursor-default]="!column.sortingField"
                [innerHTML]="(column.sort === '' || column.sort === 'ASC' ? sortIcons.sortingDown : sortIcons.sortingUp) | safeHtml: 'html'"
                (click)="sortAccordion(i)"
            ></span>
        </div>
    </div>
    <ngb-accordion [closeOthers]="true" #accordion="ngbAccordion" class="workflows-dashboard-accordion">
        <ngb-panel *ngFor="let workflow of workflows; let i = index;" id="workflow-panel-{{i}}" title="Simple"
                   [cardClass]="accordion.isExpanded('workflow-panel-' + i ) ? 'panel-active' : ''"
        >
            <ng-template ngbPanelHeader>
                <div class="d-inline-flex align-items-center w-100" style="font-size: 12px;">
                    <div class="d-inline-flex align-items-center cursor-pointer"
                         [style.width]="gridColumns[0].width"
                         style="padding-right: 12px;"
                         (click)="accordion.toggle('workflow-panel-' + i)"
                    >
                        <button class="card-header-button">
                            <fa-icon
                                style="font-size: 18px;"
                                [icon]="accordion.isExpanded('workflow-panel-' + i ) ? 'chevron-up' : 'chevron-down'"
                                [class.color-brand]="accordion.isExpanded('workflow-panel-' + i )"
                            ></fa-icon>
                        </button>
                        <h2 class="mt-0 text-nowrap text-truncate" [ngbTooltip]="workflow.name" [container]="'body'"
                            [openDelay]="400">
                            {{ workflow.name }}
                        </h2>
                    </div>
                    <div class="d-inline-flex align-items-center px-12px"
                         [style.width]="gridColumns[1].width"
                    >
                        <a class="project-link text-truncate cursor-pointer"
                           [ngbTooltip]="workflow.projectName"
                           [container]="'body'"
                           [openDelay]="400"
                           *ngIf="withProjectCol && workflow.projectId"
                           (click)="handleProjectRedirect(workflow.projectId)"
                        >
                            {{ workflow.projectName }}
                        </a>
                    </div>
                    <div class="d-inline-flex align-items-center px-12px"
                         [style.width]="gridColumns[2].width"
                    >
                        <span class="text-truncate secondary-text-and-icons cursor-default">
                            {{ workflow.pipelinesNumber ? (workflow.pipelinesNumber + ' pipeline') : '0 pipelines' }}
                        </span>
                    </div>
                    <div class="d-inline-flex align-items-center px-12px"
                         [style.width]="gridColumns[3].width"
                    >
                        <span class="text-truncate secondary-text-and-icons cursor-default"
                              *ngIf="workflow.lastLaunchDate"
                              [ngbTooltip]="workflow.lastLaunchDate | date: 'dateTimeCustom'"
                              placement="top" container="body" [openDelay]="400"
                        >
                            {{ workflow.lastLaunchDate | date: 'lastDateTimeCustom' }}
                        </span>
                    </div>
                    <div class="d-inline-flex align-items-center col px-12px" [style.width]="gridColumns[4].width"
                         [style.max-width]="gridColumns[4].width">
                        <div
                            *ngFor="let value of workflow.executionHistory"
                            class="radial mr-1 cursor-default"
                            [ngClass]="
                                    value.status
                                        ? ['FAILED', 'REJECTED'].includes(value.status.toUpperCase())
                                            ? 'radial-red'
                                            : value.status.toUpperCase() !== 'ABORTED'
                                            ? 'radial-green'
                                            : 'radial-yellow'
                                        : 'radial-grey'
                                "
                            [ngbTooltip]="value.status === null ? null : (value.dateTime | date: 'dateTimeCustom')"
                            [container]="'body'"
                            [openDelay]="400"
                        ></div>
                    </div>
                    <div class="d-inline-flex align-items-center col px-12px" [style.width]="gridColumns[5].width"
                         [style.max-width]="gridColumns[5].width">
                        <div class="w-100">
                            <div *ngIf="workflow.status !== 'WAITING_FOR_APPROVAL' && workflow.status !== 'PENDING'"
                                 class="d-block text-capitalize mb-1 cursor-default"
                            >
                                {{
                                workflow.status
                                    ? (workflow.status | lowercase)
                                    : 'None'
                                }}
                            </div>
                            <div
                                *ngIf="
                                        workflow.status === 'RUNNING' ||
                                        workflow.status === 'BUILDING'
                                    "
                                class="d-block w-75 status-indicator rounded bg-light-gray"
                            >
                                <div
                                    [style.width.%]="workflow.progress"
                                    class="status-bar d-block {{ workflow.status | lowercase }}"
                                ></div>
                            </div>
                            <div
                                *ngIf="
                                        workflow.status !== 'RUNNING' &&
                                        workflow.status !== 'WAITING_FOR_APPROVAL' &&
                                        workflow.status !== 'BUILDING' &&
                                        workflow.status !== 'PENDING'
                                    "
                                class="d-block w-75 status-indicator rounded bg-light-gray"
                            >
                                <div
                                    [style.width.%]="100"
                                    class="status-bar d-block {{ workflow.status | lowercase }} border-radius-4px"
                                ></div>
                            </div>
                            <div
                                *ngIf="workflow.status === 'WAITING_FOR_APPROVAL'"
                                class="d-block w-100"
                            >
                                <span
                                    class="custom-badge custom-badge-background size-s warning text-white cursor-default">
                                    <fa-icon icon="facPending" class="mr-5px"></fa-icon>
                                    Waiting for approval
                                </span>
                            </div>
                            <div
                                *ngIf="workflow.status === 'PENDING'"
                                class="d-block"
                            >
                                <span
                                    class="custom-badge custom-badge-background size-s warning text-white cursor-default">
                                    <fa-icon icon="facPending" class="mr-5px"></fa-icon>
                                    Pending
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="d-inline-flex align-items-center col px-12px" [style.width]="gridColumns[6].width"
                         [style.max-width]="gridColumns[6].width">
                        <div *ngIf="workflow.status && canExecute" class="real-time-execution ml-auto">
                            <span
                                class="cursor-pointer"
                                (click)="handleOpenRealTime(workflow.projectId, workflow.id)"
                                [placement]="'top'"
                                [ngbTooltip]="'Real-time'"
                                [container]="'body'"
                                [openDelay]="400"
                            >
                                <span [innerHTML]="icons.facEditorExecution | safeHtml: 'html'"></span>
                                <span class="ml-5px blue">Real-time</span>
                            </span>
                        </div>
                    </div>
                    <div class="d-inline-flex align-items-center col px-12px" [style.width]="gridColumns[7].width"
                         [style.max-width]="gridColumns[7].width">
                        <fa-icon
                            ngbTooltip="Start"
                            placement="top"
                            container="body"
                            [openDelay]="400"
                            *ngIf="
                                    canExecute &&
                                    (!workflow.status ||
                                        ['FAILED', 'ABORTED', 'NONE', 'SUCCESS', 'REJECTED'].includes(workflow.status.toUpperCase()))
                                "
                            class="icons font-16px blue"
                            icon="facStart"
                            (click)="handleActionWorkflowRun(workflow.projectId, workflow)"
                        >
                        </fa-icon>
                        <fa-icon
                            ngbTooltip="Stop"
                            placement="top"
                            container="body"
                            [openDelay]="400"
                            *ngIf="
                                    canExecute &&
                                    workflow.status &&
                                    !['FAILED', 'ABORTED', 'NONE', 'SUCCESS', 'REJECTED'].includes(workflow.status.toUpperCase())
                                "
                            class="icons font-16px red"
                            icon="facStop"
                            (click)="handleActionWorkflowStop(workflow.projectId, workflow)"
                        >
                        </fa-icon>
                        <fa-icon
                            *ngIf="canModify"
                            ngbTooltip="Edit"
                            placement="top"
                            container="body"
                            [openDelay]="400"
                            class="icons font-16px ml-15px"
                            icon="facEdit"
                            (click)="handleActionEditWorkflow(workflow.projectId, workflow.id)"
                        >
                        </fa-icon>
                        <fa-icon
                            *ngIf="canModify"
                            ngbTooltip="Edit graph"
                            placement="top"
                            container="body"
                            [openDelay]="400"
                            class="icons font-16px ml-15px"
                            [icon]="icons.facEditor"
                            (click)="handleActionWorkflowBuilder(workflow.projectId, workflow.id)"
                        >
                        </fa-icon>
                        <fa-icon
                            ngbTooltip="Execution Log"
                            placement="top"
                            container="body"
                            [openDelay]="400"
                            class="icons font-16px ml-15px"
                            icon="facLogs"
                            (click)="handleActionOpenLogs(workflow)"
                        >
                        </fa-icon>
                    </div>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-workflows-pipelines [workflowId]="workflow.id" [projectId]="workflow.projectId" [workflowDashboard]="true"
                ></app-workflows-pipelines>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>

<ng-template #loader>
    <div class="w-100 d-inline-flex justify-content-center">
        <lib-loader></lib-loader>
    </div>
</ng-template>

<ng-template #noWorkflows>
    <div class="d-flex justify-content-center flex-column align-items-center">
        <div class="not-found-image"></div>
        <span class="not-found-text">
            <strong>There are no workflows</strong>
        </span>
    </div>
</ng-template>
