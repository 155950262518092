import { Deserializable } from './abstract-job-definition';
import { DataSchema } from './data-schema';
import { JobDefinitionDataType, PublishType } from './enums';
import { FormGroup } from '@angular/forms';
import { Required } from '../validate.decorator';
import { IsEnum, Type } from '../type.decorator';

export class JobDefinitionDataTarget extends Deserializable {
    _class = {
        schema: DataSchema,
    };
    _enums = {
        type: JobDefinitionDataType,
        publishType: PublishType,
        dataType: JobDefinitionDataType,
    };
    _maps = {};
    _arrays = {};
    _nullableFields = {
        page: 'number',
    };

    meta = {
        _arrays: this._arrays,
        _enums: this._enums,
        _class: this._class,
        _maps: this._maps,
        _nullableFields: this._nullableFields,
    };

    @Required()
    @Type(String)
    target = '';

    @Required()
    @IsEnum(PublishType)
    publishType: PublishType = null;

    @Required()
    schema: DataSchema = new DataSchema();

    @Required()
    @IsEnum(JobDefinitionDataType)
    dataType: JobDefinitionDataType = null;

    @Type(Number)
    page: number = null;

    static SchemaRequired(group: FormGroup) {
        return !!group.get('schema') ? null : { schemaIsRequired: true };
    }

    getType(): string {
        return 'JobDefinitionDataTarget';
    }

    getInfo(): string {
        return `Data Target ${this.target ? `target = "${this.target}"` : ''} ${
            this.publishType ? `publish type = "${this.publishType}"` : ''
        }`;
    }

    constructor(instanceData?: JobDefinitionDataTarget) {
        super();
        this.fillByInstance(JobDefinitionDataTarget, instanceData);
    }
}
