import { AfterViewInit, Directive, ElementRef, inject, Input } from '@angular/core';

/**
 * This directive allows to remove empty tags from html
 * @param tags list of tags to analyze
 */
@Directive({
    selector: '[remove-empty-tags]',
    standalone: true,
})
export class RemoveEmptyTagsDirective implements AfterViewInit {
    @Input() tags: string[] = ['p', 'span'];

    private readonly elementRef: ElementRef<HTMLElement> = inject(ElementRef);

    ngAfterViewInit() {
        this.removeEmptyTags(this.elementRef.nativeElement);
    }

    private removeEmptyTags(element: HTMLElement) {
        const textElements = element.querySelectorAll(this.tags.join(', '));

        for (const el of Array.from(textElements)) {
            if (['&nbsp;', ''].includes(el.innerHTML.trim())) {
                el.remove();
            }
        }
    }
}
