import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '@app/auth';

@Directive({
    selector: '[isAdmin]'
})
export class IsAdminDirective {

    private hasView = false;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private authService: AuthService
    ) {
        this.setState(this.authService.isAdmin());
    }

    private setState(cond: boolean): void {
        if (cond && !this.hasView) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.hasView = true;
        } else if (!cond && this.hasView) {
          this.viewContainer.clear();
          this.hasView = false;
        }
      }

}
