<div class="modal-header">
    <h2 class="mt-0">{{ activeStep.header }}</h2>
    <button type="button" class="close ml-auto" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<ng-template #formBody></ng-template>
<div *ngIf="activeForm" class="modal-footer">
    <button class="btn btn-secondary mr-auto" *ngIf="step !== 0" (click)="back()">Back</button>
    <button class="btn btn-secondary" (click)="modal.dismiss()">Cancel</button>
    <button class="btn btn-primary" (submitAfterAsyncValidation)="next()" [submittedControl]="activeForm">
        {{ isLastStep ? 'Save' : 'Next' }}
    </button>
</div>
