import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { IFilterItem, IInstrument, IPipelineListItem, IStage, ITemplateFilterItem } from '../models/cicd-templates.model';
import {
    IAdditionalPluginInformation,
    ICiCdTemplateDetails,
    ICiCdTemplateEditor,
    IComponentConfiguration,
    IPipelineDetails,
    IWorkflow,
    IWorkflowForMagic,
} from '../models/cicd-template-configuration.model';
import { ComponentTypeItem, IComponentShort } from '../models/component.model';
import { Pageable } from '@dagility-ui/kit';
import { IApplicationSourceConfiguration } from '../models/application-source.model';
import { SortDirection, SortedBy } from '../template-list/template-list-filter-store';
import { ENV_TOKEN } from '@app/tokens';
import { PipelineScript } from '../../project/project-edit/project-pipelines/models/project-pipeline-items';
import { TemplateDependency } from '../project-templates/project-templates.model';
import { GroupedJobResponse } from '@app/shared/components/pipeline-list/model/jobs-management.classes';
import { PublishProperties } from '@dagility-ui/shared-components';

@Injectable({
    providedIn: 'root',
})
export class CiCdTemplateService {
    private readonly ciCdApi = `${this.env.buildApiURL}/ci-cd`;
    private readonly baseUrl = `${this.env.buildApiURL}/ci-cd/templates`;
    private readonly marketplaceApiUrl = `${this.env.buildApiURL}/ci-cd/marketplace`;
    private readonly componentsPostfix = `/components`;
    private readonly pipelinesPostfix = `/pipelines`;
    private readonly workflowsPostfix = `/workflows`;
    private readonly componentTypeUrl = `${this.ciCdApi}/component-types`;

    adminUrl = `${this.env.adminURL}/api`;

    private filterItems$ = this.http.get<IFilterItem>(`${this.ciCdApi}/templates-filter-items`).pipe(shareReplay(1));
    private instruments$ = this.http.get<any>(`${this.ciCdApi}/instruments`).pipe(
        map(arr => new Map<string, IInstrument>(arr.map((i: any) => [i.code, i]))),
        shareReplay(1)
    );

    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {}

    listTemplates(searchTerm: string): Observable<CiCdTemplateListItem[]> {
        return this.http.get<CiCdTemplateListItem[]>(`${this.baseUrl}/list`, {
            params: {
                searchTerm: searchTerm,
            },
        });
    }

    getTemplatePipelineGeneratorExist(templateId: number): Observable<NumberMap<boolean>> {
        return this.http.get<NumberMap<boolean>>(`${this.baseUrl}/${templateId}/pipelines-generators`);
    }

    getTemplateWithChildrenById(templateId: number): Observable<ICiCdTemplateEditor> {
        return this.http.get<ICiCdTemplateEditor>(`${this.baseUrl}/${templateId}/with-children`);
    }

    getComponentsByProjectForPublish(templateId: string, templateComponentId: string) {
        return this.http.get<PublishItem[]>(`${this.baseUrl}/${templateId}/components/${templateComponentId}/projectList`);
    }

    publishSourceFiles(templateId: string, templateComponentId: string, publishDto: TemplateSourceFilesPublishDto) {
        return this.http.post(`${this.baseUrl}/${templateId}/components/${templateComponentId}/publishSourceFiles`, publishDto);
    }

    getComponentById(componentId: number, templateId: number): Observable<IComponentConfiguration> {
        return this.http.get<IComponentConfiguration>(`${this.baseUrl}/${templateId}${this.componentsPostfix}/${componentId}`);
    }

    getPipelineById(pipelineId: number, templateId: number): Observable<IPipelineDetails> {
        return this.http.get<IPipelineDetails>(`${this.baseUrl}/${templateId}${this.pipelinesPostfix}/${pipelineId}`);
    }

    getWorkflowsByTemplateId(templateId: number): Observable<IWorkflowForMagic[]> {
        return this.http.get<IWorkflowForMagic[]>(`${this.baseUrl}/${templateId}${this.workflowsPostfix}`);
    }

    getWorkflowById(workflowId: number, templateId: number): Observable<IWorkflow> {
        return this.http.get<IWorkflow>(`${this.baseUrl}/${templateId}${this.workflowsPostfix}/${workflowId}`);
    }

    getComponentTypeList(): Observable<ComponentTypeItem[]> {
        return this.http.get<ComponentTypeItem[]>(`${this.componentTypeUrl}`);
    }

    getProjectsByTemplate(templateId: number, sharedLibrary: boolean): Observable<GroupedJobResponse[]> {
        let httpParams = new HttpParams().append('sharedLibrary', sharedLibrary);
        return this.http.get<GroupedJobResponse[]>(`${this.baseUrl}/${templateId}/usages`, { params: httpParams });
    }

    createComponents(newComponents: IComponentConfiguration[], templateId: number): Observable<number[]> {
        return this.http.post<number[]>(`${this.baseUrl}/${templateId}${this.componentsPostfix}`, newComponents, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    cloneComponents(newComponents: IComponentConfiguration[], templateId: number): Observable<number[]> {
        return this.http.post<number[]>(`${this.baseUrl}/${templateId}${this.componentsPostfix}/clone-from-template`, newComponents, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    cloneTemplate(templateId: number, newName: string): Observable<number> {
        return this.http.post<number>(`${this.baseUrl}/${templateId}/clone`, newName);
    }

    generateTemplateName(templateId: number): Observable<string> {
        return this.http.get<string>(`${this.baseUrl}/${templateId}/clone/generate-name`, { responseType: 'text' as 'json' });
    }

    createPipeline(newPipeline: IPipelineListItem, templateId: number): Observable<number> {
        return this.http.post<number>(`${this.baseUrl}/${templateId}${this.pipelinesPostfix}`, newPipeline);
    }

    createWorkflow(newWorkflow: IWorkflow, templateId: number): Observable<number> {
        return this.http.post<number>(`${this.baseUrl}/${templateId}${this.workflowsPostfix}`, newWorkflow);
    }

    removeTemplateById(templateId: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${templateId}`);
    }

    removeComponentById(componentId: number, templateId: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${templateId}${this.componentsPostfix}/${componentId}`);
    }

    removePipelineById(pipelineId: number, templateId: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${templateId}${this.pipelinesPostfix}/${pipelineId}`);
    }

    removeWorkflowById(workflowId: number, templateId: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${templateId}${this.workflowsPostfix}/${workflowId}`);
    }

    saveTemplateDetails(templateDetails: ICiCdTemplateDetails): Observable<void> {
        return this.http.put<void>(`${this.baseUrl}/${templateDetails.id}`, templateDetails);
    }

    createTemplate(templateDetails: ICiCdTemplateDetails): Observable<number> {
        return this.http.post<number>(`${this.baseUrl}`, templateDetails);
    }

    saveComponent(component: IComponentConfiguration, templateId: number): Observable<void> {
        return this.http.put<void>(`${this.baseUrl}/${templateId}${this.componentsPostfix}/${component.id}`, component);
    }

    savePipeline(pipeline: IPipelineDetails, templateId: number): Observable<number> {
        return this.http.put<number>(`${this.baseUrl}/${templateId}${this.pipelinesPostfix}/${pipeline.id}`, pipeline, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    getPipelineScript(templateId: number, pipelineId: number): Observable<PipelineScript> {
        return this.http.get<PipelineScript>(`${this.baseUrl}/${templateId}${this.pipelinesPostfix}/${pipelineId}/script`);
    }

    savePipelineScript(script: string, pipelineId: number, templateId: number) {
        return this.http.post(`${this.baseUrl}/${templateId}${this.pipelinesPostfix}/${pipelineId}/script`, { script });
    }

    saveWorkflow(workflow: IWorkflow, templateId: number): Observable<void> {
        return this.http.put<void>(`${this.baseUrl}/${templateId}${this.workflowsPostfix}/${workflow.id}`, workflow);
    }

    duplicateComponent(sourceComponentId: number, templateId: number): Observable<number> {
        return this.http.get<number>(`${this.baseUrl}/${templateId}${this.componentsPostfix}/${sourceComponentId}/clone`);
    }

    duplicatePipeline(sourcePipelineId: number, templateId: number): Observable<number> {
        return this.http.get<number>(`${this.baseUrl}/${templateId}${this.pipelinesPostfix}/${sourcePipelineId}/clone`);
    }

    uploadImage(file: File) {
        const formData = new FormData();
        formData.append('file', file);
        return this.http.post<string>(`${this.adminUrl}/images/template-components`, formData);
    }

    filterTemplates(options: any): Observable<ITemplateFilterItem[]> {
        const params: any = {};
        const tags = options.tags || [];
        const apiUrl = options.source && options.source === 'MARKETPLACE' ? this.marketplaceApiUrl : this.baseUrl;

        if (options.isEnterprise) {
            tags.push('Enterprise Standard');
        }

        if (options.isMarketplace) {
            tags.push('Marketplace');
        }

        if (options.query) {
            params.searchTerm = options.query;
        }

        if (tags && tags.length) {
            params.tags = options.tags.toString();
        }

        if (options.stages && options.stages.length) {
            params.stages = options.stages.toString();
        }

        if (options.tools && options.tools.length) {
            params.instruments = options.tools.toString();
        }

        if (options.sort) {
            params.sortBy = options.sort;
        }

        if (options.templateId) {
            params.templateId = options.templateId;
        }

        return this.http.get<any[]>(`${apiUrl}/filter`, { params });
    }

    dataTemplates(options: any): Observable<ITemplateFilterItem[]> {
        const params: any = {};

        if (options.templateIds) {
            params.templateIds = options.templateIds;
        }

        if (options.needCheck) {
            params.needCheck = options.needCheck;
        }

        return this.http.get<any[]>(`${this.baseUrl}/data-by-ids`, { params });
    }

    getFilterItems(): Observable<IFilterItem> {
        return this.filterItems$;
    }

    getInstruments(): Observable<Map<string, IInstrument>> {
        return this.instruments$;
    }

    getComparing(templateId: number): Observable<ICiCdTemplateCompare> {
        return this.http.get<ICiCdTemplateCompare>(`${this.baseUrl}/${templateId}/comparing`);
    }

    getMarketplaceComparing(templateId: number): Observable<ICiCdTemplateCompare> {
        return this.http.get<ICiCdTemplateCompare>(`${this.marketplaceApiUrl}/${templateId}/comparing`);
    }

    importTemplateAsZip(file: File): Observable<RequiredPlugins> {
        const formData = new FormData();
        formData.append('file', file);
        return this.http.post<RequiredPlugins>(`${this.baseUrl}/import-zip`, formData);
    }

    downloadTemplateAsZip(templateId: number) {
        const url = `${this.baseUrl}/${templateId}/export-zip`;
        return this.http.get(url, { responseType: 'blob' });
    }

    createProjectByTemplate(newProject: ICreateProjectByTemplate, keycloak?: boolean): Observable<ICreateProjectByTemplate> {
        const createProjectApi = keycloak ? 'createProjectByTemplateWithPossibleKeycloak' : 'createProjectByTemplate';
        return this.http.post<ICreateProjectByTemplate>(`${this.ciCdApi}/${createProjectApi}`, newProject);
    }

    createProjectMagicByTemplate(newProject: ICreateProjectByTemplate, keycloak?: boolean): Observable<number> {
        const createProjectApi = keycloak ? 'createProjectMagicWithPossibleKeycloak' : 'createProjectMagic';
        return this.http.post<number>(`${this.ciCdApi}/${createProjectApi}`, newProject);
    }

    addComponents(project: ICreateProjectByTemplate): Observable<void> {
        return this.http.post<void>(`${this.ciCdApi}/projects/addComponents/`, project);
    }

    addComponentsWithMagic(project: ICreateProjectByTemplate, keycloak?: boolean) {
        const configURL = `${this.ciCdApi}/projects/addComponentsWithMagic/`;
        return this.http.post(configURL, project);
    }

    checkRepositoryExisting(project: ICreateProjectByTemplate): Observable<any> {
        return this.http.post<any>(`${this.ciCdApi}/repositoryExist`, project);
    }

    saveProjectAsTemplate(projectId: number): Observable<number> {
        return this.http.post<number>(`${this.ciCdApi}/saveProjectAsTemplate/${projectId}`, null);
    }

    checkExistsByPrefix(prefix: string): Observable<boolean> {
        return this.http.get<boolean>(`${this.ciCdApi}/projects/checkExistsByPrefix`, {
            params: { prefix },
        });
    }

    uploadToMarketplace(templateId: number, changelog: string, comment: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('comment', comment);
        httpParams = httpParams.set('changelog', changelog);
        return this.http.post(`${this.marketplaceApiUrl}/upload-to-marketplace/${templateId}`, {}, { params: httpParams });
    }

    getMarketplaceTemplateList(searchTerm: string, pageable: Pageable, installOnly: boolean, sortedBy: SortedBy): Observable<any> {
        let httpParams = pageable.create();
        if (searchTerm) {
            httpParams = httpParams.set('searchTerm', searchTerm);
        }
        if (installOnly) {
            httpParams = httpParams.set('installOnly', `${installOnly}`);
        }
        if (sortedBy) {
            httpParams = httpParams.set('fieldName', sortedBy.fieldName);
            const isAsc = sortedBy.direction === SortDirection.Asc;
            httpParams = httpParams.set('asc', `${isAsc}`);
        }
        return this.http.get(`${this.marketplaceApiUrl}/list`, {
            params: httpParams,
        });
    }

    downloadFromMarketplace(marketplaceGuid: string, version: number) {
        const url = `${this.marketplaceApiUrl}/download-from-marketplace/${marketplaceGuid}/${version}`;
        return this.http.get(url, { responseType: 'blob' });
    }

    getMarketplaceTemplateWithChildrenById(templateId: number, loadComponents?: boolean): Observable<ICiCdTemplateEditor> {
        let params = new HttpParams();

        if (loadComponents) {
            params = params.set('loadComponents', 'true');
        }

        return this.http.get<ICiCdTemplateEditor>(`${this.marketplaceApiUrl}/${templateId}/with-children`, { params });
    }

    getMarketplaceTemplateComponent(componentId: number, templateId: number): Observable<IComponentConfiguration> {
        return this.http.get<IComponentConfiguration>(`${this.marketplaceApiUrl}/${templateId}/components/${componentId}`);
    }

    getMarketplaceTemplatePipeline(pipelineId: number, templateId: number): Observable<IPipelineDetails> {
        return this.http.get<IPipelineDetails>(`${this.marketplaceApiUrl}/${templateId}${this.pipelinesPostfix}/${pipelineId}`);
    }

    getMarketplaceWorkflow(workflowId: number, templateId: number): Observable<IWorkflow> {
        return this.http.get<IWorkflow>(`${this.marketplaceApiUrl}/${templateId}/workflow/${workflowId}`);
    }

    getMarketplaceTemplateAppSource(appSourceId: number, templateId: number): Observable<IApplicationSourceConfiguration> {
        return this.http.get<IApplicationSourceConfiguration>(`${this.marketplaceApiUrl}/${templateId}/appsource/${appSourceId}`);
    }

    importFromMarketplace(guid: string, version: number) {
        return this.http.post(`${this.marketplaceApiUrl}/import-from-marketplace/${guid}/${version}`, {});
    }

    rateTemplate(guid: string, rate: number, version: number): Observable<void> {
        return this.http.post<void>(`${this.marketplaceApiUrl}/rate/${guid}/${version}/${rate}`, {});
    }

    getMarketplaceTemplateListByGuids(guids: any[]): Observable<Record<string, Record<string, MarketplaceTemplateItem>>> {
        return this.http.post<Record<string, Record<string, MarketplaceTemplateItem>>>(`${this.marketplaceApiUrl}/list-by-guids`, guids);
    }

    getRatingStatistics(guid: string, version: number): Observable<MarketplaceTemplateRating> {
        return this.http.get<MarketplaceTemplateRating>(`${this.marketplaceApiUrl}/rate/${guid}/${version}`);
    }

    getTemplateHistory(guid: string, version: number): Observable<MarketplaceTemplateItem[]> {
        return this.http.get<MarketplaceTemplateItem[]>(`${this.marketplaceApiUrl}/template-history/${guid}/${version}`);
    }

    updateMarketplaceTemplate(templateId: number, version: number) {
        return this.http.post(`${this.marketplaceApiUrl}/update-template-from-marketplace/${templateId}/${version}`, {});
    }

    getTemplateVersions(guid: string): Observable<Record<string, Record<string, MarketplaceTemplateItem>>> {
        return this.http.get<Record<string, Record<string, MarketplaceTemplateItem>>>(
            `${this.marketplaceApiUrl}/get-template-versions-by-guid/${guid}`
        );
    }

    getDependencies(): Observable<TemplateDependency[]> {
        return this.http.get<TemplateDependency[]>(`${this.baseUrl}/dependencies`);
    }

    getTemplateDependency(templateId: number): Observable<TemplateDependency[]> {
        return this.http.get<TemplateDependency[]>(`${this.baseUrl}/${templateId}/dependency`);
    }

    saveTemplateDependencies(templateId: number, dependencies: TemplateDependency[]): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}/${templateId}/dependency`, dependencies);
    }

    publishProperties(event: PublishProperties): Observable<void> {
        let params = new HttpParams();
        params = params.append('hierarchyLevel', event.level);
        params = params.append('overwrite', event.overwrite);
        return this.http.post<void>(`${this.baseUrl}/${event.id}/publish`, {}, { params: params });
    }
}

export interface CiCdTemplateItem {
    id: number;
    name: string;
    description: string;
    type: string;
    modifiedAt: string;
}

// used in templates list. created in accordance with json, that sends template-list service.
export interface CiCdTemplateListItem extends CiCdTemplateItem {
    tags: string[];
    type: string;
    pluginServices?: string[];
    readOnly?: boolean;
    downloadedFromMarketplace: boolean;
    uploadedToMarketplace: boolean;
    marketplace: boolean;
    metaGuid?: string;
    guid: string;
    requiredPlugins: string[];
    version?: number;
    absentPlugins: Array<IAdditionalPluginInformation>;
}

export interface MarketplaceTemplateItem extends CiCdTemplateItem {
    firstName: string;
    lastName: string;
    orgName: string;
    email: string;
    createdAt: string;
    comment: string;
    changeLog: string;
    rejectComment: string;
    version: number;
    marketplaceGuid?: string;
    downloads: number;
    rate: number;
    recommended: boolean;
    status: string;
    myRate?: number;
    approvedAt: string;
    onlyRecommended: boolean;
    templateOriginalId?: number;
    localVersion: number;
}

export type MyTemplatesListItem = CiCdTemplateListItem &
    Partial<
        Pick<
            MarketplaceTemplateItem,
            | 'changeLog'
            | 'comment'
            | 'rejectComment'
            | 'downloads'
            | 'marketplaceGuid'
            | 'rate'
            | 'status'
            | 'recommended'
            | 'templateOriginalId'
        >
    >;

export type CiCdTemplateSortType = 'RELEVANCE' | 'NEWEST' | 'OLDEST' | 'NAME';

// pipeline template model for comparing
export interface ICiCdTemplateCompare {
    originId?: number;
    id: number;
    name: string;
    marketplaceGuid?: string;
    components: ICiCdTemplateCompareComponent[];
    workflows?: IWorkflowCompare[];
    version?: number;
    tags: string[];
}

export interface IWorkflowCompare {
    pipelinesCollapsed?: boolean;
    id: number;
    name: string;
    templateId: number;
    pipelines: ICiCdTemplateComparePipelineShort[];
    bigImage: string;
    bigImagePath: string;
    smallImage: string;
    smallImagePath: string;
}

export interface ICiCdTemplateCompareComponent {
    id: number;
    name: string;
    pipelines: ICiCdTemplateComparePipeline[];
}

export interface ICiCdTemplateComparePipelineShort {
    id: number;
    name: string;
}

export interface ICiCdTemplateComparePipeline extends ICiCdTemplateComparePipelineShort {
    stages: IStage[];
    smallImagePath?: string;
    smallImage?: any;
}

export interface ICiCdTemplateSimilarFilter {
    templateId: number;
    label: string;
}

export interface ICreateProject {
    projectName: string;
    projectOwnerId: number;
    enabled: boolean;
    notes: string;
    prefix: string;
    users: number[];
}

export interface ICreateProject {
    name: string;
    prefix: string;
    ownerId: number;
    description: string;
    collaboratorsIds: any[];
    enabled: boolean;
    ownerNewFromKeycloak?: boolean;
    ownerKeycloakId?: string;
}

export interface ICreateProjectByTemplate extends ICreateProject {
    projectId?: number;
    enabled: boolean;
    scmToolId: number;
    groupUnitId: number;
    groupUnitName: string;
    components: ICreateProjectComponent[];
    projectLevelPipelineIds: number[];
    needOnCompletion: boolean;
    needProjectRepo: boolean;
    defaultBranch: string;
    cicdToolId: number;
    cicdGroupUnitId: number;
    cicdGroupUnitName: string;
    gitOpsToolId: number;
    namespaceConfig: Namespace[];
    isSearchUsersInKeycloakActive?: boolean;
}

export interface Namespace {
    category: number;
    environment: number;
    namespace: string;
    autoSync: boolean;
}

export interface ICreateProjectComponent extends IComponentShort {
    templateId: number;
    templateComponentId: number;
    uniqueId: string;
    pipelineIds: number[];
    overwriteTools: boolean;
    scmToolId: number;
    scmGroupUnitId: number;
    scmGroupUnitName: string;
    cicdToolId: number;
    cicdGroupUnitId: number;
    cicdGroupUnitName: string;
    preferredToolType?: string;
    cscanToolId: number;
    cscanUnitId: number;
    cscanUnitName: string;
}

export interface MarketplaceTemplateRating {
    rate1: number;
    rate2: number;
    rate3: number;
    rate4: number;
    rate5: number;
    downloads: number;
    rating: number;
    myRating: number;
}

export interface RequiredPlugins {
    appSourceTemplate: string[];
    projectTemplate: string[];
    requiredPlugins: string[];
}

export type TemplateListSource = 'TEMPLATES' | 'MARKETPLACE';

export interface PublishItem {
    label: string;
    value: number;
    items: PublishItem[];
}

export interface TemplateSourceFilesPublishDto {
    commit?: string;
    pushToRepo?: boolean;
    componentIds: number[];
}
