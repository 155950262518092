<div #containerHead class="ml-2 mr-1 mb-5 mt-3 trace-container overflow-auto" *ngIf="traceabilityData.statusHeader" id="traceability-grid">
    <div class="displayox d-flex">
        <div class="displayox-index d-flex justify-content-center align-items-center fixed freeze"></div>
        <div class="container-head d-flex flex-row justify-content-start align-items-center">
            <div
                class="container-child mr-5 position-relative d-flex justify-content-center align-items-center fixed freeze_vertical"
                *ngFor="let data of traceabilityData.statusHeader; let i = index"
            >
                <span [style.background]="data.color" class="d-inline-block status-indicator  mr-1 rounded-circle"></span>
                {{ data.displayName }}
            </div>
        </div>
    </div>
    <div class="d-block max-height-780px">
        <ng-container *ngFor="let content of traceabilityData.traceabilityNode; let i = index">
            <div class="displayox d-flex">
                <div
                    [class.node-is-selected]="selectednode === i"
                    [ngClass]="{
                        'displayox-line': dateDuplicateCheck(traceabilityData.traceabilityNode, i),
                        'skip-index': i < traceabilityData.traceabilityNode.length
                    }"
                    class="fixed freeze_horizontal displayox-index position-relative d-flex justify-content-center align-items-center content flex-column"
                >
                    <span class="displayox-content">{{ content.timeStamp | date: 'dd' }} </span>
                    <span class="displayox-content">{{ content.timeStamp | date: 'MMM' }}</span>
                </div>
                <div class="container-content d-flex flex-row justify-content-start align-items-center">
                    <ng-container *ngFor="let data of traceabilityData.statusHeader; let j = index">
                        <div
                            *ngIf="content.status !== data.status"
                            class="container-content-child mr-5 d-flex align-items-center justify-content-center position-relative"
                        ></div>
                        <div
                            [class.node-is-selected]="selectedId === i"
                            (click)="onClickNode(traceabilityData.traceabilityNode, i)"
                            *ngIf="content.status === data.status"
                            #containerChild
                            [style.border-left-color]="content.color"
                            class="container-content-child d-flex align-items-center position-relative link justify-content-between px-2 left-border mr-5"
                        >
                            <div class="col-6 px-0 d-flex flex-column align-content-center">
                                <span class="font-weight-600">{{ content.timeStamp | date: 'MMM dd yyyy' }}</span>
                                <span>{{ content.timeStamp | date: 'mediumTime' }}</span>
                            </div>
                            <div class="col-6 px-0 d-flex flex-column align-items-end">
                                <img
                                    alt="tool"
                                    *ngIf="content.toolIconUrl"
                                    class="max-width-20px"
                                    [src]="content?.toolIconUrl | imagePath"
                                />
                                <span [title]="content.displayLabel" class="w-100 ellipsis text-right">{{ content.displayLabel }}</span>
                            </div>
                            <ng-container *ngIf="selectedId === i">
                                <app-node-details
                                    [showNodeDetailsFlag]="selectedId === i"
                                    [nodeDetailsData]="content.traceabilityNodeAdditionalInfo"
                                ></app-node-details>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>
</div>
