<span *ngIf="!dashboardMode" class="custom-label widget-type-badge" [ngClass]="widget.system ? 'indigo' : 'turquoise'">{{
    widget.system ? 'System' : 'Custom'
}}</span>

<div class="widget-img-wrapper mr-1">
    <img *ngIf="widget.type | widgetPreview as imgSrc" class="widget-img" [src]="imgSrc" [alt]="widget.type" />
</div>
<div class="text-container d-flex overflow-hidden flex-column flex-1">
    <span class="widget-title text-truncate mb-2" [title]="widget.title">{{ widget.title }}</span>
    <span [hidden]="hideDescription" class="widget-description text-truncate mb-2" [title]="widget.description">{{
        widget.description
    }}</span>

    <div class="d-flex mr-2 pb-1 buttons-container" [class.mt-auto]="hideDescription" [class.ml-auto]="!dashboardMode">
        <ng-template
            *ngIf="isButtonShown || dashboardMode"
            [ngTemplateOutlet]="actionsTemplate"
            [ngTemplateOutletContext]="{
                $implicit: widget
            }"
        ></ng-template>
    </div>
</div>
