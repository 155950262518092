import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TraceabilityService } from './api/traceability.service';
import { Traceability } from './model/traceability.model';

@Component({
    selector: 'app-traceability-chart',
    templateUrl: './traceability-chart.component.html',
    styleUrls: ['./traceability-chart.component.scss'],
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({ transform: 'translateY(100%)' }),
                animate('500ms ease-in', style({ transform: 'translateY(0%)' })),
            ]),
            transition(':leave', [animate('500ms ease-in', style({ transform: 'translateY(100%)' }))]),
        ]),
    ],
})
export class TraceabilityChartComponent {
    @Input() issueId: string;
    selectedIssueId: string;
    @Output() clearId = new EventEmitter();
    traceabilityData: Traceability = {};
    selectedId: number = null;
    loading = false;
    constructor(private traceabilityService: TraceabilityService, public activeModal: NgbActiveModal) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.issueId.currentValue) {
            this.getData();
        }
    }

    exitConfigService() {
        this.activeModal.close();
    }

    closeTraceability() {
        this.clearId.emit(true);
    }

    getData() {
        if (!this.issueId) return;
        this.loading = true;
        this.selectedIssueId = this.issueId;
        this.traceabilityService.getTraceability(this.issueId).subscribe(obj => {
            this.traceabilityData = obj;
            this.loading = false;
            document.querySelector('.modal').scrollTo({ top: 0, behavior: 'smooth' });
        });
    }
}
