import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

/*
 *  | Format             | Description                                                          | Example Value                                              |
 *  |--------------------|----------------------------------------------------------------------|------------------------------------------------------------|
 *  | duration           | duration in minutes and seconds                                      | 5 sec, 1 min, 5 min 10 sec, 120 min 12 sec                 |
 *  | timeCustom         | 24-h time format in accordance with mock-screen: HH:mm               | 01:05, 13:59                                               |
 *  | dateCustom         | date format in in accordance with mock-screen: HH:mm                 | 13 nov 2019, 05 dec 2019                                   |
 *  | dateTimeCustom     | dateCustom + timeCustom                                              | 12 dec 2019 11:01, 05 dec 2019 14:25                       |
 *  | lastDateCustom     | as dateCustom, but last 2 days date are shown as Today and Yesterday | Today, Yesterday, 12 dec 2019                              |
 *  | lastDateTimeCustom | lastDateCustom + timeCustom                                          | Today 18:28, Yesterday 08:05, 12 dec 2019 11:01            |
 *  | all standard date pipe formats like mediumDate, fullDate, hh:yyyy etc are work as usual                                                                |
 */

@Pipe({
    name: 'date',
})
export class CustomDatePipe extends DatePipe implements PipeTransform {
    private readonly customFormats = ['duration', 'dateTimeCustom', 'dateCustom', 'timeCustom', 'lastDateTimeCustom', 'lastDateCustom'];
    private readonly customFormatsRE = new RegExp(/(duration|dateTimeCustom|dateCustom|timeCustom|lastDateTimeCustom|lastDateCustom)/g);

    dateCustom: string;
    timeCustom: string;
    lastDate: string;

    getDateCustom(value: any, timezone?: string, locale?: string): string {
        return super.transform(value, 'dd MMM y', timezone, locale).toLowerCase();
    }

    getTimeCustom(value: any, timezone?: string, locale?: string): string {
        return super.transform(value, 'HH:mm', timezone, locale) || '';
    }

    getLastDate(value: any, timezone?: string, locale?: string): string {
        const dateCustom = this.getDateCustom(value, timezone, locale);
        const today = new Date();
        const isToday = dateCustom === this.getDateCustom(today, timezone, locale);
        const isYesterday = dateCustom === this.getDateCustom(new Date().setDate(today.getDate() - 1), timezone, locale);
        return isToday ? 'Today at' : isYesterday ? 'Yesterday at' : dateCustom;
    }

    getDateStringByFormat(
        value: any,
        format: string,
        timezone: string,
        locale: string,
        dateCustom: string,
        timeCustom: string,
        lastDate: string
    ): string {
        switch (format) {
            case 'duration':
                const min = Math.trunc(value.valueOf() / (1000 * 60));
                const sec = Math.trunc((value.valueOf() % (1000 * 60)) / 1000);
                if (min === 0 && sec === 0) {
                    return `${value.valueOf()} ms`;
                }
                return `${min && min !== 0 ? `${min} min` : ''}${min && sec ? ' ' : ''}${sec ? `${sec} sec` : ''}`;

            case 'dateTimeCustom':
                return `${dateCustom}${dateCustom && timeCustom ? ` ` : ''}${timeCustom}`;

            case 'dateCustom':
                return dateCustom;

            case 'timeCustom':
                return timeCustom;

            case 'lastDateTimeCustom':
                return `${lastDate}${lastDate && timeCustom ? ` ` : ''}${timeCustom}`;

            case 'lastDateCustom':
                return lastDate;

            default:
                return super.transform(value, format, timezone, locale);
        }
    }

    getFormats(format: string): string[] {
        format = format || '';
        const result = [];
        while (format.length) {
            let customFormatIndex = format.search(this.customFormatsRE);
            if (customFormatIndex < 0) {
                customFormatIndex = format.length;
            }
            if (customFormatIndex >= 0) {
                const subString = format.slice(0, customFormatIndex);
                if (subString) {
                    result.push(subString);
                    format = format.slice(customFormatIndex);
                }
                const foundCustomFormat = this.customFormats.find(cf => format.indexOf(cf) === 0);
                if (foundCustomFormat) {
                    result.push(foundCustomFormat);
                    format = format.slice(foundCustomFormat.length);
                }
            }
        }
        return result;
    }

    transform(value: null | undefined, args?: string): null;
    transform(value: any, format?: string, timezone?: string, locale?: string): string {
        const valueDate = isDate(value)
            ? value
            : typeof value === 'number' || typeof value === 'string'
                ? new Date(value)
                : null;

        if (!(valueDate && !isNaN(valueDate.getTime()))) {
            return '';
        }

        const localeDefault = locale || 'en-US';
        const dateCustom = this.getDateCustom(valueDate, timezone, localeDefault);
        const timeCustom = this.getTimeCustom(valueDate, timezone, localeDefault);
        const lastDate = this.getLastDate(valueDate, timezone, localeDefault);

        const formats = this.getFormats(format);
        let result = '';
        formats.forEach(formatItem => {
            result += this.getDateStringByFormat(valueDate, formatItem, timezone, localeDefault, dateCustom, timeCustom, lastDate);
        });
        return result;
    }
}

function isDate(value: any): value is Date {
    return value instanceof Date && !isNaN(value.valueOf());
}
