<h3>Insights and Recommendations</h3>

<div class="row ml-0 mt-4 pb-3 mr-4 insight-container-block" *ngFor="let control of form.controls; let index = index; let first = first">
    <div class="insight-text-block">
        <span class="insight-recommendations-label font-12px">Insight</span>
        <dp-widget-builder-help-template-preview [template]="control.value.insight"></dp-widget-builder-help-template-preview>

        <lib-validation-errors [control]="control.get('insight')">
            <ng-template valError="required">{{ isRequiredText }}</ng-template>
        </lib-validation-errors>

        <div class="mt-2" *ngFor="let recommendationControl of control?.get('recommendations').controls">
            <span class="insight-recommendations-label">Recommendation</span>
            <dp-widget-builder-help-template-preview [template]="recommendationControl.value"></dp-widget-builder-help-template-preview>

            <lib-validation-errors [control]="recommendationControl">
                <ng-template valError="required">{{ isRequiredText }}</ng-template>
            </lib-validation-errors>
        </div>
    </div>
    <div class="ml-auto font-14px">
        <fa-icon *ngIf="!first" icon="facTrash" class="insight-recommendations-icons" (click)="handleDeleteInsight(index)"></fa-icon>
        <fa-icon
            icon="facEdit"
            class="ml-3 insight-recommendations-icons"
            (click)="handleEditInsight(control.value.insight, control.value.recommendations, index)"
        ></fa-icon>
    </div>
</div>

<button data-test-id="add-recommendation" class="btn btn-link mt-4 mb-1 p-0 ml-1" (click)="handleAddInsight()">
    <fa-icon class="mr-2" icon="plus"></fa-icon> Add one more insight
</button>
