<h3 class="mb-4 mt-0">Widget Size</h3>
<ng-container *ngIf="sizesWrapper" [formGroup]="sizesWrapper">
    <div class="d-flex flex-wrap" formArrayName="units">
        <div class="flex-1 mr-4">
            <h4 class="mt-0 mb-3">Minimum Size <mandatory></mandatory></h4>
            <div class="d-flex">
                <lib-dropdown
                    class="mr-3 flex-1"
                    formControlName="0"
                    label="Width"
                    placeholder="Select Size"
                    [items]="boundaries.minW | async"
                    [clearable]="false"
                ></lib-dropdown>
                <lib-dropdown
                    formControlName="1"
                    class="flex-1"
                    type="number"
                    label="Height"
                    placeholder="Select Size"
                    [items]="boundaries.minH | async"
                    [clearable]="false"
                ></lib-dropdown>
            </div>
        </div>

        <div class="flex-1">
            <h4 class="mt-0 mb-3">
                Default Size
                <mandatory></mandatory>
                <fa-icon
                    class="ml-2 font-16px"
                    icon="QuestionInCircle"
                    container="body"
                    placement="bottom"
                    ngbTooltip="This size is going to be default when user add widget to dashboard"
                ></fa-icon>
            </h4>
            <div class="d-flex">
                <lib-dropdown
                    formControlName="2"
                    class="flex-1 mr-3"
                    type="number"
                    label="Width"
                    placeholder="Select Size"
                    [items]="boundaries.defaultW | async"
                    [clearable]="false"
                ></lib-dropdown>
                <lib-dropdown
                    formControlName="3"
                    class="flex-1"
                    type="number"
                    label="Height"
                    placeholder="Select Size"
                    [items]="boundaries.defaultH | async"
                    [clearable]="false"
                ></lib-dropdown>
            </div>
        </div>
    </div>
</ng-container>
