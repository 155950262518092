<div *ngIf="label" class="header-text pb-15px">
    {{ label }}
    <span *ngIf="isRequired" class="mandatory">*</span>
</div>
<div *ngIf="label" class="row no-gutters justify-content-between mb-10px">
    <div class="col-4 label">
        Type
    </div>
    <div class="col-7 label">
        Value
    </div>
</div>
<div class="no-gutters row w-100 justify-content-between">
    <lib-dropdown
            keep-position
            closeByScroll="true"
            class="col-4 dropdown select-dropdown"
            placeholder="Select Type"
            [value]="type"
            [items]="types"
            labelKey="label"
            valueKey="id"
            appendTo="body"
            [clearable]="false"
            (change)="handleTypeChange($event)"
            id="type"
    ></lib-dropdown>
    <ng-container [ngSwitch]="type">
        <div
                class="col-7 d-inline-flex flex-column"
                [class.justify-content-center]="type == 'boolean'"
                [class.justify-content-start]="type != 'boolean'"
        >
            <trigger class="d-inline-flex" *ngSwitchCase="'boolean'" [value]="_value" (click)="handleFieldChange($event)"></trigger>
            <lib-input
                    class="w-100 select-type-input"
                    [class.invalid]="hasErrors"
                    *ngSwitchCase="'string'"
                    [value]="_value"
                    (controlValueChange)="handleFieldChange($event)" id="field-1"
            ></lib-input>
            <lib-input
                    class="w-100 select-type-input"
                    *ngSwitchCase="'number'"
                    [value]="_value"
                    [type]="'number'"
                    (controlValueChange)="handleFieldChange($event)"
                    [class.invalid]="hasErrors" id="field-2"
            ></lib-input>
            <ng-container *ngSwitchCase="'array'">
                <div class="d-inline-flex align-items-start mb-2" *ngFor="let item of _value; trackBy: trackFunction; let i = index">
                    <lib-input
                            class="col pl-0 pr-0 select-type-input"
                            [value]="item"
                            [clearable]="_value.length > 1"
                            (clear)="removeArrayItem(i)"
                            (controlValueChange)="setArrayElement(i, $event)" id="field-3"
                    ></lib-input>
                </div>
                <div class="add-text cursor-pointer d-inline-flex justify-content-start" (click)="addArrayItem()">
                    Add Value
                    <em class="ml-2 fa fa-plus font-16px"></em>
                </div>
            </ng-container>
            <lib-validation-errors [control]="control">
                <ng-template valError="required">This field is required</ng-template>
            </lib-validation-errors>
        </div>
    </ng-container>
</div>
