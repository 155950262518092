import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, inject, InjectionToken } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { ModalService } from '@dagility-ui/kit';
import { ToasterService } from '@dagility-ui/kit/toastr';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { FeatureToggleService, UserService } from '@app/services';

import { CreateOrganizationDto, OrganizationShort, OrganizationsService } from '../api/organizations.service';
import { AddNewOrganizationComponent } from '../add-new-organization/add-new-organization.component';

export const LOCATION_TOKEN = new InjectionToken<typeof location>('Location token', {
    factory: () => location,
});

@Component({
    selector: 'app-organizations-switcher',
    templateUrl: './organizations-switcher.component.html',
    styleUrls: ['./organizations-switcher.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationsSwitcherComponent {
    private readonly api = inject(OrganizationsService);
    readonly organizationsFromApi$ = this.api.getUserOrganizations().pipe(
        shareReplay(1),
        catchError(err => {
            console.log(err);

            return of([] as OrganizationShort[]);
        }),
        tap(organizations => {
            this.organizations$.next(organizations);
        })
    );
    readonly organizations$ = new BehaviorSubject<OrganizationShort[]>([]);
    readonly sortedOrganizations$ = this.organizations$.pipe(
        map(organizations => organizations.sort((a, b) => a.name.localeCompare(b.name)))
    );
    readonly user = inject(UserService).user;
    private readonly ft = inject(FeatureToggleService);
    readonly isFeatureActive = this.ft.isActiveSync('eo_52955');
    readonly isCreateEnabled = this.ft.isActiveSync('eo_52856');
    private readonly location = inject(LOCATION_TOKEN);
    private readonly modal = inject(ModalService);
    private cdr = inject(ChangeDetectorRef);
    private readonly toaster = inject(ToasterService);

    @HostBinding('hidden')
    get hidden() {
        return !this.isMultiSourceAvailable;
    }

    get isMultiSourceAvailable() {
        return this.isFeatureActive;
    }

    updateActiveOrganization({ id, current }: OrganizationShort) {
        if (current) {
            return;
        }

        this.api.setCurrentOrganization(id).subscribe(() => {
            this.location.replace('');
        });
    }

    createNewOrganization(dropdown: NgbDropdown) {
        this.modal
            .open(
                AddNewOrganizationComponent,
                {
                    centered: true,
                },
                {
                    header: 'Add New Organization',
                    saveButtonText: 'Create',
                    saveFunction: (organization: CreateOrganizationDto) => this.api.create(organization),
                }
            )
            .result.then((organization: CreateOrganizationDto) => {
                this.organizations$.next([
                    ...this.organizations$.value,
                    {
                        ...organization,
                        current: false,
                    },
                ]);
                this.showCreateOrgToast(organization);
            })
            .catch(() => {
                // empty
            })
            .finally(() => {
                dropdown.open();
                this.cdr.detectChanges();
            });
    }

    private showCreateOrgToast(createdOrg: { name: string }) {
        this.toaster.successToast({
            title: 'Success',
            content: `${createdOrg.name} has been created.`,
        });
    }
}
