import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { getColorForThresholdRelatedChart } from 'data-processor/lib/widget-library/widget-builder/services/widget-builder.util';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'dp-pi-gantt',
    templateUrl: './pi-gantt.component.html',
    styleUrls: ['./pi-gantt.component.scss'],
})
export class PiGanttComponent implements AfterViewInit {
    @Input() chartOptions: any;

    @Output() linkClicked = new EventEmitter();

    selected: string = '';
    subChart: any = {};
    subChartData: any = {};
    noData = true;

    constructor(private cdr: ChangeDetectorRef) {}

    ngAfterViewInit() {
        this.refresh();
    }

    getColor(value: number) {
        return getColorForThresholdRelatedChart(value, true, 67, 33);
    }

    handleClick(event: any) {
        if (this.selected === event.payload.name) {
            return;
        }

        this.selected = event.payload.name;
        const subChart = this.chartOptions.data.find((selectedData: any) => selectedData.name === this.selected)?.subChart;

        if (subChart) {
            this.subChart = cloneDeep(subChart);
            this.subChartData = cloneDeep(subChart.series[0].data);
        }

        this.noData = !subChart;
        this.refresh();
    }

    refresh() {
        setTimeout(() => {
            this.cdr.detectChanges();
        }, 100);
    }

    close() {
        this.selected = '';
    }
}
