type JsPdf = import('./jspdf.imports').jsPDF;

export class AutoTableGenerator {
    private tableOptions: any;

    constructor(private doc: JsPdf, startY: number = 10) {
        this.tableOptions = {
            margin: { top: startY },
            headStyles: {
                overflow: 'linebreak',
                halign: 'center',
                valign: 'middle',
                fontSize: 14,
                fillColor: [252, 252, 252],
                textColor: [0, 0, 0],
                fontStyle: 'normal',
                lineColor: [223, 225, 229],
                lineWidth: { top: 0.1, bottom: 0.1, right: 0.1 },
            },
            bodyStyles: {
                fontSize: 12,
                halign: 'center',
                lineColor: [223, 225, 229],
                lineWidth: 0.1,
                valign: 'middle',
            },
            styles: {
                minCellWidth: 47.5,
            },
            didDrawPage: (data: any) => {
                if (this.tableOptions.startY && data.pageCount > 1) {
                    data.settings.margin.top = 10;
                }
            },
        };
    }

    async generateTable(data: Array<object>) {
        (this.doc as any).autoTable({
            head: [data[0]],
            body: data.slice(1),
            ...this.tableOptions,
        });
    }
}
