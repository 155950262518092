import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SourceFolderItem, SourcesBelongType } from '../models/application-source.model';
import { ENV_TOKEN } from '@app/tokens';
import { Page, Pageable } from '@dagility-ui/kit';
import { PipelineScriptVersion } from '../../project/project-edit/project-pipelines/models/project-pipeline-items';

@Injectable({
    providedIn: 'root',
})
export class CiCdAppSourcesService {
    baseUrl = `${this.env.buildApiURL}/ci-cd/templates`;
    appSourcesBaseUrl = `${this.env.buildApiURL}/ci-cd/app-sources`;

    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {}

    getAppSourceByComponentId(componentId: number): Observable<number> {
        return this.http.get<number>(`${this.appSourcesBaseUrl}/getAppsourceId/${componentId}`);
    }

    getFolder(path: string, componentId: number | string, type: SourcesBelongType): Observable<SourceFolderItem[]> {
        const params = new HttpParams().set('path', path).set('type', type);
        return this.http.get<SourceFolderItem[]>(`${this.appSourcesBaseUrl}/folderItems/${componentId}`, { params: params });
    }

    createFolder(appSourceId: number | string, currentPath: string, folderName: string, type: SourcesBelongType) {
        const params = new HttpParams().set('type', type);
        return this.http.post<string>(`${this.appSourcesBaseUrl}/createFolder/${appSourceId}`, currentPath + folderName, {
            params: params,
        });
    }

    removeSourceItem(path: string, appSourceId: number | string, type: SourcesBelongType): Observable<string> {
        const params = new HttpParams().set('type', type);
        return this.http.post<string>(`${this.appSourcesBaseUrl}/deleteAppSourceItem/${appSourceId}`, path, {
            params: params,
        });
    }

    getFile(path: string, appSourceId: number | string, type: SourcesBelongType): Observable<any> {
        const params = new HttpParams().set('path', path).set('type', type);
        return this.http.get(`${this.appSourcesBaseUrl}/getContent/${appSourceId}`, {
            params: params,
            responseType: 'blob',
        });
    }

    saveFileContent(
        content: any,
        path: string,
        sourceItem: SourceFolderItem,
        appSourceId: number,
        type: SourcesBelongType
    ): Observable<any> {
        const params = new HttpParams().set('type', type);
        const formData = new FormData();
        const blob = new Blob([content], { type: sourceItem.contentType });

        formData.append('path', path);
        formData.append('content', blob);
        return this.http.post(`${this.appSourcesBaseUrl}/setContent/${appSourceId}`, formData, {
            responseType: 'blob',
            params: params,
        });
    }

    uploadAppSourceFile(path: string, appSourceId: number | string, file: File, type: SourcesBelongType): Observable<string> {
        const params = new HttpParams().set('type', type);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('path', path);
        return this.http.post<string>(`${this.appSourcesBaseUrl}/createFile/${appSourceId}`, formData, { params: params });
    }

    uploadAllAsZip(appSourceId: number | string, file: File, type: SourcesBelongType): Observable<number> {
        const params = new HttpParams().set('type', type);
        const formData = new FormData();
        formData.append('content', file);
        return this.http.post<number>(`${this.appSourcesBaseUrl}/uploadZip/${appSourceId}`, formData, { params: params });
    }

    getAllAsZip(appSourceId: number | string, type: SourcesBelongType): Observable<any> {
        const params = new HttpParams().set('type', type);
        return this.http.get(`${this.appSourcesBaseUrl}/downloadZip/${appSourceId}`, {
            responseType: 'blob',
            params: params,
        });
    }

    getFolderAsZip(path: string, appSourceId: number | string, type: SourcesBelongType): Observable<any> {
        const params = new HttpParams().set('path', path).set('type', type);
        return this.http.get(`${this.appSourcesBaseUrl}/downloadFolderZip/${appSourceId}`, {
            params: params,
            responseType: 'blob',
        });
    }

    getProjectComponentScriptVersions(
        id: number,
        type: SourcesBelongType,
        pageable: Pageable
    ): Observable<Page<PipelineScriptVersion>> {
        return this.http.get<Page<PipelineScriptVersion>>(
            `${this.env.buildApiURL}/ci-cd/app-sources/viewSourceLog/${id}?type=${type}`,
            {
                params: pageable.create(),
            }
        );
    }
}
