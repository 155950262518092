export enum DataFilterOperation {
    STRING = 'STRING',
    EQ = 'EQ',
    NE = 'NE',
    AND = 'AND',
    OR = 'OR',
    IN = 'IN',
    NIN = 'NIN',
    RANGE = 'RANGE',
    IS_NULL = 'IS_NULL',
    NOT_NULL = 'NOT_NULL',
    LIKE = 'LIKE',
}
