<div class="modal-header">
    <h1 class="header-text mt-0">Add New Job</h1>
    <button type="button" class="close ml-auto" aria-label="Close button" aria-describedby="modal-title" (click)="modalService.close()">
        <fa-icon [icon]="icons.facClose" class="close-modal-window-icon font-14px"></fa-icon>
    </button>
</div>

<div class="modal-body">
    <div class="nav-container">
        <nav>
            <a
                class="text-truncate"
                [ngbTooltip]="'Create on Existing'"
                [container]="'body'"
                [class.switch-active-item]="viewSelector == 1"
                (click)="changeView(1)"
            >Create on Existing</a
            >
            <a
                class="text-truncate"
                [ngbTooltip]="'Create on Existing'"
                [container]="'body'"
                [class.switch-active-item]="viewSelector == 2"
                (click)="changeView(2)"
            >Create New Empty</a
            >
            <div [ngClass]="'slider' + (viewSelector == 1 ? ' slider-people' : viewSelector == 2 ? ' slider-apps' : '')"></div>
        </nav>
    </div>
    <div class="mt-30px font-11px">{{ tool.name }}</div>
    <div class="mt-30px">
        <lib-dropdown
            (change)="handleChangeJob($event)"
            *ngIf="viewSelector === 1"
            [items]="tool.plugins"
            label="Job Definition Template"
            valueKey="id"
            id="name"
            labelKey="instanceName"
        ></lib-dropdown>
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-secondary ml-auto font-11px" (click)="modalService.dismiss()">
        Cancel
    </button>
    <button type="button" class="btn btn-primary form-button ml-25px font-11px" (click)="addJob()">
        Create
    </button>
</div>
