import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'dp-job-definition-comments',
    templateUrl: './job-definition-comments.component.html',
    styleUrls: ['./job-definition-comments.component.scss'],
})
export class JobDefinitionCommentsComponent {
    @Input() comment: string;

    constructor(private modal: NgbActiveModal) {}

    handleClose() {
        this.modal.dismiss();
    }

    handleSave() {
        this.modal.close(this.comment);
    }
}
