import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { QueryBuilderStore } from '@app/shared/components/query-builder/store/query-builder.store';
import { LockerActionsService } from '@app/shared/components/query-builder/store/locker-actions/locker-actions.service';
import { IRelation } from '@app/shared/components/query-builder/models/model-graph.model';

@Component({
    selector: 'app-qb-model-actions',
    templateUrl: './actions.component.html',
    styleUrls: ['./actions.component.scss'],
})
export class ActionsComponent {
    @Input() isEditorForm: boolean;
    @Output() panelTabClick = new EventEmitter<any>();
    @Output() openRelationEditor = new EventEmitter<IRelation>();
    @Output() closeRelationEditor = new EventEmitter<any>();

    @Input() hidden: boolean;
    sourceGraph = this.queryBuilderStore.modelGraph;

    @HostListener('document:queryBuilderChanged', ['$event'])
    updateModelGraph() {
        this.sourceGraph = this.queryBuilderStore.modelGraph;
    }

    navItems: { id: string; name: string }[] = [
        { id: 'selection', name: 'Fields' },
        { id: 'joins', name: 'Relations' },
        { id: 'where', name: 'Data Filter' },
        { id: 'groupby', name: 'Group' },
        { id: 'orderby', name: 'Sort' },
    ];

    get lockedActions(): boolean {
        return LockerActionsService.lockedStatus();
    }

    constructor(private queryBuilderStore: QueryBuilderStore) {}

    openEditorForm(relation?: IRelation) {
        this.openRelationEditor.emit(relation);
    }

    closeEditorForm() {
        this.closeRelationEditor.emit();
    }
}
