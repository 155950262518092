import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';


@Directive({
    selector: '[deepAutoFocus]',
})
export class DeepAutoFocus implements AfterViewInit {
    child: string;

    constructor(private elementRef: ElementRef) {}

    @Input() set deepAutoFocus(child: string) {
        this.child = child;
    }

    ngAfterViewInit() {
        this.elementRef.nativeElement.querySelector('input').focus();
    }
}
