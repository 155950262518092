import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'dp-work-distribution-widget',
    templateUrl: './work-distribution-widget.component.html',
    styleUrls: ['./work-distribution-widget.component.scss'],
})
export class WorkDistributionWidgetComponent implements OnInit {
    @Input() options: any;
    @Output() linkClicked = new EventEmitter();
    @Input() showLink = true;

    subData: any[];
    issueCompleted: number;
    totalIssues: number;
    subProgressHeader: string;
    legendTitle = '';
    mainChart: any[] = [];
    mainChartOptions: any;
    subChart: any[] = [];
    subChartOptions: any;
    remainingStoryPoints: number;

    private currentValue = '';

    get isMediumScreen() {
        return window.innerWidth < 1720 && window.innerWidth >= 1440;
    }

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.mainChartOptions = cloneDeep(this.options);
        this.subChartOptions = cloneDeep(this.options);
        this.mainChart = cloneDeep(this.options.series[0].data);
        this.legendTitle = `All ${this.options.workDistributionType}s`;
        this.subProgressHeader = `All ${this.options.workDistributionType}(s) Progress`;
        this.generateData();
    }

    generateSubData() {
        if (!this.subData) {
            return;
        }

        this.issueCompleted = this.subData.reduce((accumulator, currentValue) => {
            accumulator += +currentValue.completedCount;
            return accumulator;
        }, 0);

        this.totalIssues = this.subData.reduce((accumulator, currentValue) => {
            accumulator += +currentValue.count;
            return accumulator;
        }, 0);

        const subData = cloneDeep(this.subData);
        this.subChart = subData.reduce((accumulator, currentValue) => {
            if (currentValue.hasOwnProperty('issues')) {
                currentValue.issues.forEach((element: any) => {
                    const duplicateCheck: any = accumulator.find((obj: any) => obj.name === element.name);
                    if (duplicateCheck) {
                        duplicateCheck.count = duplicateCheck.count + element.count;
                    } else {
                        accumulator = [...accumulator, element];
                    }
                });
            }
            return accumulator;
        }, []);

        this.cdr.detectChanges();
    }

    onChange(value: string) {
        if (value === this.currentValue) {
            return;
        }

        this.currentValue = value;
        this.subProgressHeader = `${value === 'all' ? 'All ' + this.options.workDistributionType + '(s)' : value} Progress`;
        const subData = cloneDeep(this.options.series[0].data);
        this.subData = value === 'all' ? subData : subData.filter((obj: any) => obj.name === value);
        this.generateSubData();
    }

    handleLinkClicked() {
        const value = this.options.series[0].data.find((serie: any) => serie.name === this.subProgressHeader.replace(' Progress', ''));
        this.linkClicked.emit(value);
    }

    generateData() {
        this.subData = cloneDeep(this.options.series[0].data);
        this.generateSubData();
    }
}
