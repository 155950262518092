import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ChangeDetectionStrategy, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { InputComponent } from '@dagility-ui/kit';

@Component({
    selector: 'dp-debug-new-watch',
    templateUrl: './debug-new-watch.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugNewWatchComponent implements OnInit, AfterViewInit {
    @Input() expression: string;

    @Input() edit = false;

    @ViewChild(InputComponent, { static: true }) inputComponent: InputComponent;

    @Output() editExpression = new EventEmitter<string>();

    control = new FormControl();

    ngOnInit() {
        if (!this.inputComponent.input) {
            return;
        }
        this.control.patchValue(this.expression);
        this.inputComponent.input.nativeElement.focus();
    }

    ngAfterViewInit() {
        if (!this.inputComponent.input || !this.edit) {
            return;
        }

        this.inputComponent.input.nativeElement.select();
    }

    onBlur() {
        this.editExpression.emit(this.control.value);
    }
}
