<div class="col pl-0">
    <ng-container *ngIf="!checkLinkWithNull(issue.link); else issueWithoutLinkTmpl">
        <div class="issue">
            <a class="link-normal mr-1" [href]="issue.link" target="_blank">{{ issue.id }}</a>
            <ng-container *ngIf="issue.name"
                ><span class="primary-text">: </span>
                <span
                    class="font-weight-600 link-normal"
                    #completionStatusPopover
                    [ngbPopover]="completionStatusTooltipTmpl"
                    [placement]="['bottom', 'auto']"
                    container="body"
                    autoClose="outside"
                    popoverClass="feature-completion-tooltip"
                    >{{ issue.name }}</span
                ></ng-container
            >
        </div>
    </ng-container>

    <ng-template #issueWithoutLinkTmpl>
        <div class="issue">
            <span class="mr-1"
                >{{ issue.id }}
                <ng-container *ngIf="issue.name">
                    :
                </ng-container>
            </span>
            <span
                *ngIf="issue.name"
                #completionStatusPopover
                class="font-weight-600 link-normal"
                [ngbPopover]="completionStatusTooltipTmpl"
                [placement]="['bottom', 'auto']"
                container="body"
                autoClose="outside"
                popoverClass="feature-completion-tooltip"
                >{{ issue.name }}</span
            >
        </div>
    </ng-template>

    <ng-template #completionStatusTooltipTmpl>
        <div class="feature-completion-status-tooltip">
            <div class="p-4 d-flex align-items-center justify-content-between border-bottom">
                <h3 class="mt-0">Info {{ issue.id }}</h3>

                <button tabindex="-1" type="button" class="close" aria-label="Close" (click)="completionStatusPopover.close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="body2 p-4" *ngIf="issue.tooltip as tooltip">
                <div class="mb-3">
                    <span class="mr-1">{{ issue.id }}: </span>
                    <span class="mr-3">{{ issue.name }}</span>
                </div>

                <div class="tooltip-row">
                    <span>Created Date:</span
                    ><span>{{ tooltip.createdDate === 'NA' ? tooltip.createdDate : (tooltip.createdDate | date: 'dateCustom') }}</span>
                </div>
                <div class="tooltip-row">
                    <span>Completed Date:</span
                    ><span>{{
                        tooltip.completedDate === 'NA' ? tooltip.completedDate : (tooltip.completedDate | date: 'dateCustom')
                    }}</span>
                </div>
                <div class="tooltip-row">
                    <span>Total Items:</span><span>{{ tooltip.totalIssues }}</span>
                </div>
                <div class="tooltip-row">
                    <span>Estimated Story Points: </span><span>{{ tooltip.estimatedStoryPoints }}</span>
                </div>
                <div class="tooltip-row">
                    <span>Completed Story Points: </span><span>{{ tooltip.completedStoryPoints }}</span>
                </div>
                <div class="tooltip-row">
                    <span>Status:</span><span>{{ issue.status.label }}</span>
                </div>
                <div class="w-100 d-flex mt-4">
                    <div *ngFor="let status of tooltip.statuses" class="tooltip-status-value">
                        <div class="status-bar-value-tooltip mb-1" [style.background]="getColor(status.value)"></div>
                        <div class="d-flex">
                            <div class="mr-2">{{ status.count }} Items -</div>
                            <div class="mr-2">{{ status.value }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <div class="body2 disable">
        {{ issue.startDate === 'NA' ? issue.startDate : (issue.startDate | date: 'dateTimeCustom') }}
    </div>
</div>

<div class="author-wrapper col">
    <lib-user-pic [size]="36" [user]="issue.user"></lib-user-pic>

    <div class="author-info font-11px">
        <div class="body3 disable">Created By</div>
        <div class="body2 primary-text">{{ fullName }}</div>
        <div class="body3 disable">
            Start Date: {{ issue.startDate === 'NA' ? issue.startDate : (issue.startDate | date: 'dateCustom') }}
        </div>
    </div>
</div>

<div class="completion-wrapper col">
    <div class="d-flex justify-content-between">
        <div class="primary-text">
            Completion Status:

            <fa-icon
                class="ml-1 cursor-pointer color-brand"
                icon="facEyeOpen"
                container="body"
                #workDistributionPopover="ngbPopover"
                [ngbPopover]="workDistributionTooltipTmpl"
                [disablePopover]="isSingleWidget || !issue.workDistributionTooltip"
                [placement]="['bottom', 'auto']"
                popoverClass="feature-completion-tooltip"
                autoClose="outside"
                (click)="handleCompletionStatusClick()"
            ></fa-icon>
            <ng-template #workDistributionTooltipTmpl>
                <div *ngIf="!isSingleWidget && issue.workDistributionTooltip" class="work-distribution-tooltip">
                    <div class="mb-3 d-flex align-items-center justify-content-between border-bottom p-4">
                        <h3 class="ml-1 mt-0 title">Work Distribution by Teams</h3>

                        <button tabindex="-1" type="button" class="close" aria-label="Close" (click)="workDistributionPopover.close()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <dp-work-distribution-widget
                        class="d-block p-4"
                        style="height: 350px"
                        [options]="issue.workDistributionTooltip"
                        [showLink]="showLink"
                        (linkClicked)="workDistributionPopover.close(); linkClicked.emit($event)"
                    ></dp-work-distribution-widget>
                </div>
            </ng-template>
        </div>

        <div class="body3 secondary-text-and-icons">
            Resolved items: {{ issue.tooltip.resolvedIssues }} of {{ issue.tooltip.totalIssues }}
        </div>
        <div [style.color]="getColor(issue.status.value)">{{ issue.progress / 100 | percent: '.1' }}</div>
    </div>
    <div class="w-100 status-bar mt-1">
        <div class="status-bar-value" [style.width.%]="issue.progress" [style.background]="getColor(issue.status.value)"></div>
    </div>
</div>
