import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ExternalParameter } from '@app/shared/components/query-builder/models/model-graph-actions.model';
import { ParameterDefValueType } from '@app/shared/components/query-builder/models/model-graph.model';
import { validateFormAndDisplayErrors } from '@dagility-ui/kit';

@Component({
    selector: 'app-edit-params-modal',
    template: `
        <div class="modal-header align-items-center">
            <h2 class="mt-0">{{ parameter.uid ? 'Edit' : 'Add' }} Parameter</h2>
            <fa-icon icon="facClose" class="secondary-text-and-icons cursor-pointer" (click)="modalService.dismiss()"></fa-icon>
        </div>
        <div class="modal-body">
            <div [formGroup]="form">
                <lib-input
                    class="mb-4"
                    type="text"
                    label="Parameter Name"
                    placeholder="Enter Parameter name"
                    formControlName="name"
                    [readonly]="parameter.type === 'DRILLDOWN'"
                ></lib-input>
                <lib-dropdown
                    label="Type"
                    placeholder="Select Type"
                    formControlName="valueType"
                    [items]="types"
                    (change)="handleValueTypeChange($event)"
                ></lib-dropdown>
                <lib-input class="mt-4" label="Value" placeholder="Enter Value" formControlName="value"></lib-input>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-secondary mr-4" (click)="modalService.dismiss()">Cancel</button>
            <button class="btn btn-primary mr-0" [disabled]="form.invalid" (click)="onSubmit()">Save</button>
        </div>
    `,
})
export class EditParamsModalComponent implements OnInit {
    @Input() parameter: ExternalParameter;
    @Input() allParameterNames: string[];

    form: FormGroup;
    types = [
        { label: 'String', value: 'TEXT' },
        { label: 'Number', value: 'NUMERIC' },
    ];

    constructor(public modalService: NgbActiveModal, private fb: FormBuilder) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            name: ['', [Validators.required, this.duplicateValidator(this.allParameterNames)]],
            valueType: ['', Validators.required],
            value: ['', [Validators.required, this.numericValidator()]],
        });
        this.form.patchValue(this.parameter);
    }

    onSubmit() {
        validateFormAndDisplayErrors(this.form);
        if (this.form.valid) {
            this.parameter.name = this.form.value.name;
            this.parameter.value = this.form.value.value;
            this.modalService.close(this.parameter);
        }
    }

    changeSwitchView(type: ParameterDefValueType) {
        this.parameter.valueType = type;
    }

    handleValueTypeChange(event: any) {
        if (event) {
            this.parameter.valueType = event.value;
            validateFormAndDisplayErrors(this.form);
        }
    }

    private duplicateValidator(list: string[]): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => (list?.includes(control.value) ? { duplicate: true } : null);
    }

    private numericValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null =>
            this.parameter.valueType === 'NUMERIC' && !/^[\+\-]?\d*\.?\d+?$/.test(control.value)
                ? { pattern: { requiredPattern: 'of number' } }
                : null;
    }
}
