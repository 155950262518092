import { Deserializable, PrimitiveDataType } from '../../../models/job-definition';
import { OperandType } from './operand-type';
import { Operation } from './operation';
import { ValueType } from './value-type';

export class Operand extends Deserializable {
    _arrays = {
        value: JDValue,
    };
    _maps = {};
    _enums = {
        type: OperandType,
    };
    _class = {
        condition: Condition,
        value: JDValue,
    };
    meta = {
        _arrays: this._arrays,
        _class: this._class,
        _maps: this._maps,
        _enums: this._enums,
    };

    type: OperandType = null;
    condition: Condition = null;
    value: JDValue[] = [];

    getInfo() {
        return `Operand type = ${this.type}`;
    }

    constructor(instance?: any) {
        super();
        this.fillByInstance(Operand, instance);
    }
}

export class Condition extends Deserializable {
    _class = {
        operands: Operand,
    };
    _enums = {
        operation: Operation,
    };
    _maps = {};
    _arrays = {
        operands: Operand,
    };
    meta = {
        _arrays: this._arrays,
        _class: this._class,
        _maps: this._maps,
        _enums: this._enums,
    };

    operands: Operand[] = [];
    operation: Operation = null;

    getInfo() {
        return `Condition operation = ${this.operation}`;
    }

    constructor(instance?: any) {
        super();
        this.fillByInstance(Condition, instance);
    }
}

export class JDValue extends Deserializable {
    _arrays = {
        parameters: Operand,
    };
    _class = {
        value: PrimitiveDataType,
    };
    _maps = {};
    _enums = {
        type: ValueType,
    };
    meta = {
        _arrays: this._arrays,
        _class: this._class,
        _maps: this._maps,
        _enums: this._enums,
    };

    type: ValueType = null;
    value = '';
    parameters: Operand[] = [];

    getInfo() {
        return `Value type = ${this.type}, value = ${this.value}`;
    }

    constructor(instance?: any) {
        super();
        this.fillByInstance(JDValue, instance);
    }
}
