import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SRESquare } from 'data-processor/lib/widget-library/widget-builder/components/sre-components/sre-squares/models/sre-square.model';

@Component({
    selector: 'dp-sre-square',
    templateUrl: './sre-square.component.html',
    styleUrls: ['./sre-square.component.scss'],
    host: {
        class: 'sre-square',
    },
})
export class SreSquareComponent {
    @Input() square: SRESquare;
    @Output() squareClicked = new EventEmitter();

    statusBars = [...new Array(27).keys()];

    getSquare(square: any): SRESquare {
        return square;
    }

    squareClickedHandler(square: SRESquare) {
        if (square.drilldownTarget) {
            this.squareClicked.emit(square);
        }
    }
}
