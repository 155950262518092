import { Inject, Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subscription, of } from 'rxjs';
import { take, map, mergeMap, switchMap, catchError } from 'rxjs/operators';

import { StompService } from './stomp.service';
import { ENV_TOKEN } from '@app/tokens';

@Injectable({
    providedIn: 'root'
})
export class JenkinsJobsListener implements OnDestroy {
    private readonly baseApiUrl = `${this.env.dpApiURL}/data-processor`;
    private subscription: Subscription = Subscription.EMPTY;

    constructor(@Inject(ENV_TOKEN) private env: Env, private stompService: StompService, private httpClient: HttpClient) {}

    listenCiCdDpTaskPipelineTopic(): void {
        this.subscription = this.stompService.reconnected$
            .pipe(
                take(1),
                switchMap(() => this.stompService.listenTopic(StompService.CI_CD_DP_TASK_PIPELINE_TOPIC)),
                map(msg => {
                    const msgHeader = JSON.parse(msg.body);
                    return JSON.parse(msgHeader.json) as Job;
                }),
                mergeMap(({ toolId, fullJobName }) =>
                    this.httpClient
                        .post(
                            `${this.baseApiUrl}/force-job`,
                            {
                                jobName: 'Jenkins builds',
                                toolId,

                                params: {
                                    job: fullJobName,
                                    slug: encodeURI(fullJobName),
                                },
                            },
                            {
                                headers: new HttpHeaders({ 'skip-global-error': 'true' }),
                            }
                        )
                        .pipe(catchError(() => of()))
                )
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}

interface Job {
    fullJobName: string;
    toolId: string;
}
