import { Component, OnInit, Input } from '@angular/core';
import { zip } from 'rxjs';
import { FormControl } from '@angular/forms';
import { RecipientsEditorService } from './recipients-editor.service';
import { AuthService } from '@app/auth';

@Component({
    selector: 'app-recipients-editor',
    templateUrl: './recipients-editor.component.html',
    styleUrls: ['./recipients-editor.component.scss'],
})
export class RecipientsEditorComponent implements OnInit {
    @Input() recipientForm: any;

    appRoles: any[];
    busRoles: any[];
    wholeRoles: any[];
    recipientsArray: string[] = ['portfolios'];
    recipientTypes: { label: string; value: any }[] = [
        { label: 'Portfolios', value: 'portfolios' },
        { label: 'Applications', value: 'applications' },
        { label: 'Teams', value: 'teams' },
    ];

    teamSearch: string;
    roleSearch: string;
    userSearch: string;
    portfolioSearch: string;
    applicationSearch: string;

    usersTemporaryArray: { id: number; name: string }[] = [];
    users: { id: number; name: string }[] = [];
    portfolios: { id: number; name: string }[] = [];
    applications: { id: number; name: string }[] = [];
    teams: { id: number; name: string }[] = [];

    isAdmin: boolean;
    user: any;

    constructor(private recipientsEditorService: RecipientsEditorService, private authService: AuthService) {
        this.isAdmin = authService.isAdmin();
        this.user = authService.getAuthenticatedUser();
    }

    ngOnInit() {
        this.changeRecipients(null, true);
        this.recipientsEditorService.checkLockEditRecipientBlockByUserRole(this.recipientForm.controls);

        zip(this.recipientsEditorService.getApplicationRoles(), this.recipientsEditorService.getBusinessRoles()).subscribe(
            ([appRoles, busRoles]) => {
                this.appRoles = appRoles.map((role: any) => ({ ...role, group: 'Roles' }));
                this.busRoles = busRoles.map((role: any) => ({ ...role, group: 'Business Roles' }));
                this.wholeRoles = [...this.appRoles, ...this.busRoles];
            }
        );

    }

    getItems(recipientItem: string) {
        return recipientItem === 'portfolios' ? this.portfolios : recipientItem === 'applications' ? this.applications : this.teams;
    }

    searchUsers(search: string) {
        this.userSearch = search;
        this.loadUsers();
    }

    searchItems(recipientItem: 'portfolios' | 'applications' | 'teams', search: string) {
        if (search === '') {
            search = null;
        }
        this.portfolioSearch = this.applicationSearch = this.teamSearch = null;
        switch (recipientItem) {
            case 'portfolios':
                this.portfolioSearch = search;
                this.applicationSearch = null;
                this.teamSearch = null;
                this.loadPortfolios();
                break;
            case 'applications':
                this.portfolioSearch = null;
                this.applicationSearch = search;
                this.teamSearch = null;
                this.loadApplications();
                break;
            case 'teams':
                this.portfolioSearch = null;
                this.applicationSearch = null;
                this.teamSearch = search;
                this.loadTeams();
                break;
        }
    }

    getRecipientsData(data: any) {
        return {
            applications:
                data.applications && data.applications.map
                    ? {
                          all: data.applications.length === 0,
                          ids:
                              data.applications.length === this.applications.length
                                  ? this.applications
                                  : data.applications.map(({ id, name }: { id: number; name: string }) => ({ id, name })),
                      }
                    : null,
            portfolios:
                data.portfolios && data.portfolios.map
                    ? {
                          all: data.portfolios.length === 0,
                          ids:
                              data.portfolios.length === this.portfolios.length
                                  ? this.portfolios
                                  : data.portfolios.map(({ id, name }: { id: number; name: string }) => ({ id, name })),
                      }
                    : null,
            roles:
                data.roles && data.roles.map
                    ? {
                          all: data.roles.length === 0,
                          businessRoleIds: data.roles
                              .filter((role: any) => role.group === 'Business Roles')
                              .map(({ id, name }: { id: number; name: string }) => ({ id, name })),
                          roleIds: data.roles
                              .filter((role: any) => role.group === 'Roles')
                              .map(({ id, name }: { id: number; name: string }) => ({ id, name })),
                      }
                    : null,
            teams:
                data.teams && data.teams.map
                    ? {
                          all: data.teams.length === 0,
                          ids:
                              data.teams.length === this.teams.length
                                  ? this.teams
                                  : data.teams.map(({ id, name }: { id: number; name: string }) => ({ id, name })),
                      }
                    : null,
            users:
                data.users && data.users.map
                    ? {
                          all: false,
                          ids:
                              data.users.length === this.users.length
                                  ? this.users
                                  : data.users.map(({ id, name }: { id: number; name: string }) => ({ id, name })),
                      }
                    : null,
        };
    }

    scrollToTop() {
        const scrollContainer = document.querySelector('.ng-dropdown-panel-items');
        if (scrollContainer) {
            scrollContainer.scrollTop = 0;
        }
    }

    changeRecipientsValue(recipient: any) {
        if (recipient === 'portfolios') {
            this.recipientForm.controls['applications'].patchValue([]);
            this.recipientForm.controls['teams'].patchValue([]);
        } else if (recipient === 'applications') {
            this.recipientForm.controls['teams'].patchValue([]);
        }
        this.loadApplications();
        this.loadTeams();
    }

    changeRecipients(data: any, clearRecipientList: boolean) {
        this.recipientsArray =
            !data || data.value === 'portfolios'
                ? ['portfolios', 'applications', 'teams']
                : data.value === 'applications'
                ? ['applications', 'teams']
                : ['teams'];

        if (!data || !data.value || data.value === 'portfolios') {
            this.recipientForm.controls['portfoliosDropdown'].enable();
            this.recipientForm.controls['applicationsDropdown'].disable();
            this.recipientForm.controls['teamsDropdown'].disable();
            this.recipientForm.controls['portfoliosDropdown'].setValue(this.recipientTypes[0]);
        } else if (data.value === 'applications') {
            this.recipientForm.controls['applicationsDropdown'].enable();
            this.recipientForm.controls['teamsDropdown'].disable();
        } else if (data.value === 'teams') {
            this.recipientForm.controls['teamsDropdown'].enable();
        }

        if (clearRecipientList) {
            this.recipientForm.controls['portfolios'].patchValue([]);
            this.recipientForm.controls['applications'].patchValue([]);
            this.recipientForm.controls['teams'].patchValue([]);
        }

        this.recipientForm.controls['portfoliosDropdown'].setValue('portfolios');
        this.recipientForm.controls['applicationsDropdown'].setValue('applications');
        this.recipientForm.controls['teamsDropdown'].setValue('teams');

        this.loadPortfolios();
        this.loadApplications();
        this.loadTeams();
    }

    recipientsToFormValues(recipients: {
        portfolios: { all: boolean; ids: { id: number; name: string }[] };
        applications: { all: boolean; ids: { id: number; name: string }[] };
        teams: { all: boolean; ids: { id: number; name: string }[] };
        roles: {
            all: boolean;
            businessRoleIds: { id: number; name: string }[];
            roleIds: { id: number; name: string; group: string }[];
        };
        users: { all: boolean; ids: { id: number; name: string }[] };
    }) {
        return {
            portfolios: new FormControl(this.recipientsEditorService.toFormValues(recipients.portfolios, this.portfolios)),
            applications: new FormControl(this.recipientsEditorService.toFormValues(recipients.applications, this.applications)),
            teams: new FormControl(this.recipientsEditorService.toFormValues(recipients.teams, this.teams)),

            portfoliosDropdown: new FormControl({ value: 'portfolios', disabled: false }),
            applicationsDropdown: new FormControl({ value: 'applications', disabled: true }),
            teamsDropdown: new FormControl({ value: 'teams', disabled: true }),

            roles: new FormControl(
                !recipients.roles || recipients.roles.all
                    ? []
                    : recipients.roles.businessRoleIds
                          .map(r => ({ id: r.id, name: r.name, group: 'Business Roles' }))
                          .concat(recipients.roles.roleIds.map(r => ({ id: r.id, name: r.name, group: 'Roles' })))
            ),
            users: new FormControl((recipients.users.ids || []).map(user => ({ id: user.id, name: user.name }))),
        };
    }

    onUsersChange(users: { id: number; name: string }[]) {
        this.usersTemporaryArray = users;
    }

    onCloseUserDropdown() {
        if (this.usersTemporaryArray.length) {
            this.recipientForm.controls['users'].setValue(this.usersTemporaryArray);
        }
    }

    onRolesChange() {
        this.loadUsers();
    }

    private loadPortfolios() {
        this.recipientsEditorService.getPortfolios(this.portfolioSearch).subscribe(portfolios => {
            this.portfolios = portfolios;
            this.scrollToTop();
        });
    }

    private loadApplications() {
        this.recipientsEditorService
            .getApplications(
                {
                    portfolios: {
                        ids:
                            this.recipientForm.value.portfolios.length === 0
                                ? []
                                : this.recipientForm.value.portfolios.map(({ id, name }: { id: number; name: string }) => ({
                                      id,
                                      name,
                                  })),
                        all: this.recipientForm.value.portfolios.length === 0,
                    },
                },
                this.applicationSearch
            )
            .subscribe(applications => {
                this.applications = applications;
                this.scrollToTop();
            });
    }

    private loadTeams() {
        this.recipientsEditorService
            .getTeams(
                {
                    portfolios: {
                        ids:
                            this.recipientForm.value.portfolios.length === 0
                                ? []
                                : this.recipientForm.value.portfolios.map(({ id, name }: { id: number; name: string }) => ({
                                      id,
                                      name,
                                  })),
                        all: this.recipientForm.value.portfolios.length === 0,
                    },

                    applications: {
                        ids:
                            this.recipientForm.value.applications.length === 0
                                ? []
                                : this.recipientForm.value.applications.map(({ id, name }: { id: number; name: string }) => ({
                                      id,
                                      name,
                                  })),
                        all: this.recipientForm.value.applications.length === 0,
                    },
                },
                this.teamSearch
            )
            .subscribe(teams => {
                this.teams = teams;
                this.scrollToTop();
            });
    }

    private loadUsers() {
        const data = this.recipientForm.controls['roles'].value;
        this.recipientsEditorService
            .getUsers(
                {
                    portfolios: this.recipientForm.value.portfolios.map
                        ? {
                              ids:
                                  this.recipientForm.value.portfolios.length === 0
                                      ? []
                                      : this.recipientForm.value.portfolios.map(({ id, name }: { id: number; name: string }) => ({
                                            id,
                                            name,
                                        })),
                              all: this.recipientForm.value.portfolios.length === 0,
                          }
                        : null,

                    applications: this.recipientForm.value.applications.map
                        ? {
                              ids:
                                  this.recipientForm.value.applications.length === 0
                                      ? []
                                      : this.recipientForm.value.applications.map(({ id, name }: { id: number; name: string }) => ({
                                            id,
                                            name,
                                        })),
                              all: this.recipientForm.value.applications.length === 0,
                          }
                        : null,
                    teams: this.recipientForm.value.teams.map
                        ? {
                              ids:
                                  this.recipientForm.value.teams.length === 0
                                      ? []
                                      : this.recipientForm.value.teams.map(({ id, name }: { id: number; name: string }) => ({
                                            id,
                                            name,
                                        })),
                              all: this.recipientForm.value.teams.length === 0,
                          }
                        : null,
                    roles: {
                        all: data.length === 0,
                        roleIds: data.filter
                            ? data
                                  .filter((role: any) => role.group === 'Roles')
                                  .map(({ id, name }: { id: number; name: string }) => ({ id, name }))
                            : [],
                        businessRoleIds: data.filter
                            ? data
                                  .filter((role: any) => role.group === 'Business Roles')
                                  .map(({ id, name }: { id: number; name: string }) => ({ id, name }))
                            : [],
                    },
                },
                this.userSearch
            )
            .subscribe(users => {
                this.users = users.filter((u: any) => u.name && u.name.trim() !== '');
                this.scrollToTop();
            });
    }
}
