import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Workflow, WorkflowBuildConfiguration, WorkflowBuildConfigurationDto, WorkflowDetails } from './models/project-workflow-items';
import { EnvironmentCheck } from '@app/shared/modules/workflow-builder/workflow-approval-execution-form/workflow-approval-execution-form.component';
import { WorkflowHistory } from './project-workflow/project-workflow-execution-history/project-workflow-execution-history.component';
import { ENV_TOKEN } from '@app/tokens';

@Injectable({
    providedIn: 'root',
})
export class ProjectWorkflowsService {
    baseApi = `${this.env.buildApiURL}/ci-cd`;
    projectApi = `${this.baseApi}/projects`;

    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {}

    getWorkflowBuildParameters(projectId: number, workflowId: number): Observable<WorkflowBuildConfiguration> {
        return this.http.get<WorkflowBuildConfiguration>(`${this.projectApi}/${projectId}/workflow/${workflowId}/build-parameters`);
    }

    getAllWorkflow(projectId: number): Observable<Workflow[]> {
        return this.http.get<Workflow[]>(`${this.projectApi}/${projectId}/workflow`);
    }

    addNewWorkflow(projectId: number, workflow: Workflow): Observable<number> {
        return this.http.post<number>(`${this.projectApi}/${projectId}/workflow/new`, workflow);
    }

    copyWorkflow(projectId: number, workflowId: number): Observable<void> {
        return this.http.post<void>(`${this.projectApi}/${projectId}/workflow/${workflowId}/copy`, {});
    }

    isPipelineLinked(projectId: number, pipelineId: number): Observable<boolean> {
        const headers = new HttpHeaders({
            'skip-global-error': 'true',
        });
        return this.http.get<boolean>(`${this.projectApi}/${projectId}/workflow/${pipelineId}/pipeline`, { headers });
    }

    doesComponentHaveLinkedToWorkflowPipelines(projectId: number, componentId: number): Observable<boolean> {
        const headers = new HttpHeaders({
            'skip-global-error': 'true',
        });
        return this.http.get<boolean>(`${this.projectApi}/${projectId}/workflow/${componentId}`, { headers });
    }

    updateWorkflow(projectId: number, workflowId: number, newValue: any): Observable<void> {
        return this.http.post<void>(`${this.projectApi}/${projectId}/workflow/${workflowId}/details`, newValue);
    }

    getWorkflowDetails(projectId: number, workflowId: number): Observable<WorkflowDetails> {
        return this.http.get<WorkflowDetails>(`${this.projectApi}/${projectId}/workflow/${workflowId}/details`);
    }

    deleteWorkflow(projectId: number, workflowId: number): Observable<void> {
        return this.http.delete<void>(`${this.projectApi}/${projectId}/workflow/${workflowId}`);
    }

    runWorkflow(projectId: number, workflowId: number, configuration: WorkflowBuildConfigurationDto): Observable<string[]> {
        return this.http.post<string[]>(`${this.projectApi}/${projectId}/workflow/${workflowId}/run`, configuration);
    }

    getCanResume(projectId: number, workflowId: number): Observable<boolean> {
        return this.http.get<boolean>(`${this.projectApi}/${projectId}/workflow/${workflowId}/resume`, {});
    }

    resumeWorkflow(projectId: number, workflowId: number): Observable<string[]> {
        return this.http.post<string[]>(`${this.projectApi}/${projectId}/workflow/${workflowId}/resume`, {});
    }

    approveWorkflow(projectId: number, workflowId: number, blockId: string): Observable<void> {
        return this.http.post<void>(`${this.projectApi}/${projectId}/workflow/${workflowId}/${blockId}/approve`, {});
    }

    rejectWorkflow(projectId: number, workflowId: number, blockId: string): Observable<void> {
        return this.http.post<void>(`${this.projectApi}/${projectId}/workflow/${workflowId}/${blockId}/reject`, {});
    }

    getCanUserApprove(projectId: number, workflowId: number, approvalId: string): Observable<boolean> {
        return this.http.get<boolean>(`${this.projectApi}/${projectId}/workflow/${workflowId}/${approvalId}`);
    }

    getApprovalChecks(projectId: number, workflowId: number, approvalId: string): Observable<EnvironmentCheck[]> {
        return this.http.get<EnvironmentCheck[]>(`${this.projectApi}/${projectId}/workflow/${workflowId}/${approvalId}/checks`);
    }

    stopWorkflow(projectId: number, workflowId: number): Observable<void> {
        return this.http.post<void>(`${this.projectApi}/${projectId}/workflow/${workflowId}/stop`, {});
    }

    getWorkflowHistory(projectId: string, workflowId: string): Observable<WorkflowHistory[]> {
        return this.http.get<WorkflowHistory[]>(`${this.projectApi}/${projectId}/workflow/${workflowId}/history`);
    }

    getWorkflowVariablesByBuildNumber(projectId: number, workflowId: number, buildNumber: number): Observable<any> {
        const params = new HttpParams().append('buildNumber', buildNumber);

        return this.http.get<any>(`${this.projectApi}/${projectId}/workflow/${workflowId}/buildInfo`, { params });
    }
}
