import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QB_ICON_URLS } from '@app/shared/components/query-builder/consts/qb-icon-urls.const';
import { TreeNode } from '@app/shared/components/query-builder/qb-model/components/graph-tree/node/tree-node.model';
import { LockerActionsService } from '@app/shared/components/query-builder/store/locker-actions/locker-actions.service';

@Component({
    selector: 'app-qb-model-graph-tree',
    templateUrl: './graph-tree.component.html',
    styleUrls: ['./graph-tree.component.scss'],
})
export class GraphTreeComponent implements OnInit {
    @Input() root: TreeNode;
    @Input() search: string;
    @Output() deleteCustomModel = new EventEmitter<string>();
    showChildNodes: boolean = true;
    icons = QB_ICON_URLS;

    get rootImage(): string | null {
        return this.root && this.root.settings && this.root.settings.imageUrl ? this.root.settings.imageUrl : null;
    }

    get rootText(): string {
        return this.root && this.root.toString() + (this.root.childModelCount ? ' (' + this.root.childModelCount + ')' : '');
    }

    get isHiddenNode(): boolean {
        return !(this.root && this.root.settings && this.root.settings.hidden);
    }

    get isUserDefined(): boolean {
        return this.root && this.root.settings && this.root.settings.userDefined;
    }

    get isDraggable(): string {
        return this.root && this.root.settings && this.root.settings.draggable ? 'true' : 'false';
    }

    get hasChildNodes(): boolean {
        return this.root && this.root.next !== undefined;
    }

    get nextNodes(): TreeNode[] {
        return this.root && this.root.next;
    }

    ngOnInit(): void {
        if (this.root && this.root.settings && this.root.settings.collapsed) this.showChildNodes = false;
    }

    toggleShowingNextNodes = () => {
        this.showChildNodes = !this.showChildNodes;
    };

    isDisabledNode(node: TreeNode): boolean {
        return (node && node.settings && node.settings.disabled) || LockerActionsService.lockedStatus();
    }

    dragModelStart(root: TreeNode, event: DragEvent, modelItem: HTMLDivElement) {
        event.dataTransfer.setDragImage(modelItem, modelItem.offsetWidth / 2, modelItem.offsetHeight / 2);
        event.dataTransfer.setData('text', JSON.stringify(root.data));
    }

    matchSearchPattern(): boolean {
        return !this.search || this.search.length < 3
            ? true
            : this.root && this.root.data && this.root.data.name && this.root.data.name.indexOf(this.search) > -1;
    }

    onClick(item: string) {
        this.deleteCustomModel.emit(item);
    }

    onClickChild(item: string) {
        this.deleteCustomModel.emit(item);
    }

    dragModelStartTestMode(root: TreeNode, modelItem: HTMLDivElement) {
        if(!(window as any).QB_TEST_MODE){
            return;
        }
        modelItem.draggable = true;
        const dataTransfer = new DataTransfer();
        const toElem = document.getElementById('svgFixedContainer');

        const drop = () => {
            const event = new DragEvent('drop', {dataTransfer: dataTransfer});
            toElem.dispatchEvent(event);
        };

        modelItem.ondragstart = event => {
            event.dataTransfer.setData('text', JSON.stringify(root.data));
            drop();
        };
        const dragStart = new DragEvent('dragstart', { dataTransfer: dataTransfer });
        modelItem.dispatchEvent(dragStart);
    }
}

(window as any).QB_TEST_MODE = false;
