<div class="d-flex justify-content-between mb-10px">
    <span class="btn-link cursor-pointer" (click)="addAuthType()">+ Add auth type</span>
</div>

<ng-container formArrayName="authTypes">
    <div *ngFor="let authType of authTypes.controls; let i = index">
        <div [formGroup]="authType" class="d-flex flex-column p-2 mb-2">
            <div class="row">
                <lib-dropdown
                    class="col"
                    label="Auth Type"
                    placeholder="Select auth type"
                    formControlName="type"
                    appendTo="body"
                    [items]="authTypesItems"
                    (change)="handleChangeAuthType(i, $event)"
                    [clearable]="false"
                ></lib-dropdown>
                <fa-icon
                    icon="facTrash"
                    class="delete-auth-type-icon ml-auto"
                    (click)="handleDeleteAuthType(i)"
                    ngbTooltip="'Delete auth type'"
                    tooltipClass="project-tooltip"
                    placement="top"
                    [openDelay]="800"
                ></fa-icon>
            </div>
            <div class="mt-3">
                <ng-container *ngIf="authType.value.type === 'FORM'">
                    <lib-codemirror-editor
                        class="mb-3"
                        label="Template"
                        needRefresh="true"
                        mode="text/json-lint"
                        formControlName="value"
                    ></lib-codemirror-editor>
                    <lib-input formControlName="tokenXPath" label="Token XPath"></lib-input>
                </ng-container>
                <lib-codemirror-editor
                    *ngIf="authType.value.type === 'SCRIPT'"
                    class="mb-3"
                    label="Script"
                    needRefresh="true"
                    mode="text/groovy"
                    formControlName="value"
                ></lib-codemirror-editor>
                <lib-input *ngIf="authType.value.type === 'TOKEN'" formControlName="value" label="Token Header"></lib-input>
            </div>
        </div>
    </div>
</ng-container>
