import {ChangeDetectorRef, Component, ElementRef, HostListener, Injector, OnInit, ViewChild} from '@angular/core';
import {PerfectScrollbarConfig} from 'ngx-perfect-scrollbar';
import {ActivatedRoute, Router} from '@angular/router';
import {ComponentCanDeactivate, DropdownComponent, ModalConfirmComponent, ModalService} from '@dagility-ui/kit';
import {ModelGraphService} from '@app/shared/components/query-builder/services/model-graph.service';
import {QueryBuilderWidgetService} from '@app/shared/components/query-builder/services/query-builder-widget.service';
import {WidgetConfigRequest} from '@app/shared/components/query-builder/models/query-builder-widget.model';
import {ToasterService} from '@dagility-ui/shared-components';
import {WidgetBuilderService} from 'data-processor';
import {forkJoin, Observable} from 'rxjs';
import {clone, cloneDeep} from 'lodash';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModelService} from '@app/shared/components/query-builder/services/model.service';
import {QB_ICON_URLS} from '@app/shared/components/query-builder/consts/qb-icon-urls.const';
import {Model, ModelGraph} from '@app/shared/components/query-builder/models/model-graph.model';
import {QueryBuilderStore} from '@app/shared/components/query-builder/store/query-builder.store';
import {QueryBuilderState} from '@app/shared/components/query-builder/store/state/query-builder.state';
import {QBModelComponent} from '@app/shared/components/query-builder/qb-model/qb-model.component';
import {ModelGraphActionsService} from '@app/shared/components/query-builder/services/model-graph-actions.service';
import {LockerActionsService} from '@app/shared/components/query-builder/store/locker-actions/locker-actions.service';
import {
    ExternalParamsComponent
} from '@app/shared/components/query-builder/qb-visualization/components/external-params/external-params.component';
import {MultilevelFilterState} from '../../../pages/insights/multi-level-filter-state.service';
import {ExternalParameter, IDrilldown, WidgetDrilldown} from './models/model-graph-actions.model';

@Component({
    selector: 'app-query-builder',
    templateUrl: './query-builder.component.html',
    styleUrls: ['./query-builder.component.scss'],
    providers: [MultilevelFilterState],
})
export class QueryBuilderComponent implements OnInit, ComponentCanDeactivate {
    @ViewChild(QBModelComponent) qbModelComponent: QBModelComponent;
    perfectScrollbarConfig: PerfectScrollbarConfig = new PerfectScrollbarConfig({
        suppressScrollX: true,
        suppressScrollY: true,
    });
    drilldownBreadcrumb: any[];
    activeTab: any | string;
    isDrilldownEditor = false;
    rootModels: Model[];
    error: boolean = false;
    icons = QB_ICON_URLS;
    drilldowns: WidgetDrilldown[];
    path: { uid: string; title: string }[] = [];
    drilldownEditorData: any = undefined;
    @ViewChild(DropdownComponent) dropdown: DropdownComponent;
    spinnerItems: number[] = new Array(8);
    private editSettings: QbLoadingSettings;
    private modalCustomOpened = false;
    saveInLoad: boolean = false;
    alreadyLoaded: boolean = false;

    constructor(
        private modalService: ModalService,
        private route: ActivatedRoute,
        private router: Router,
        private modelService: ModelService,
        private modelGraphService: ModelGraphService,
        private modelGraphActionsService: ModelGraphActionsService,
        private queryBuilderWidgetService: QueryBuilderWidgetService,
        private toaster: ToasterService,
        private widgetBuilderService: WidgetBuilderService,
        private queryBuilderStore: QueryBuilderStore,
        private cdr: ChangeDetectorRef,
        private elRef: ElementRef,
        private injector: Injector
    ) {
    }

    private _debugMode: boolean = false;

    get debugMode(): boolean {
        return this._debugMode;
    }

    set debugMode(value: boolean) {
        this._debugMode = value;
    }

    get isModelPage() {
        return this.activeTab === 'Model';
    }

    get currentState(): QueryBuilderState {
        return this.queryBuilderStore.getValue();
    }

    get pageName(): string {
        return this.queryBuilderStore.widgetSettings.chartOptions.title;
    }

    get actionsLocked() {
        return LockerActionsService.lockedStatus();
    }

    get lastLockReason() {
        return LockerActionsService.lastReason;
    }

    get globalModelGraph() {
        return this.queryBuilderStore.globalModelGraph;
    }

    get modelGraph() {
        return this.queryBuilderStore.modelGraph;
    }

    get hasErrors() {
        return !!this.queryBuilderStore.validationErrors.filter(f => f.level === 'ERROR').length;
    }

    get globalModelGraphText() {
        return JSON.stringify(this.queryBuilderStore.globalModelGraph, undefined, 4);
    }

    private static sortRootModels(rootModels: Model[]) {
        const models = rootModels
            .filter(model => model.data.dbToolNameHint && model.data.schemaName)
            .sort((a, b) => {
                if (a.data.dbToolNameHint.localeCompare(b.data.dbToolNameHint) === 0) {
                    if (a.data.schemaName.localeCompare(b.data.schemaName) === 0) {
                        return a.name.localeCompare(b.name);
                    } else {
                        return a.data.schemaName.localeCompare(b.data.schemaName);
                    }
                } else {
                    return a.data.dbToolNameHint.localeCompare(b.data.dbToolNameHint);
                }
            });
        const customModels = rootModels
            .filter(model => !model.data.dbToolNameHint || !model.data.schemaName || model.userDefined)
            .sort((a, b) => a.name.localeCompare(b.name));
        return [...customModels, ...models];
    }

    exportModelGraph() {
        const a = document.createElement('a');
        const file = new Blob([this.globalModelGraphText as BlobPart], {type: 'application/json'});
        a.href = URL.createObjectURL(file);
        a.download = `${this.globalModelGraph.widgetConfig.chartOptions.title}`;
        a.click();
    }

    @HostListener('document:queryBuilderChanged', ['$event'])
    updateModelGraph() {
        this.widgetPath();
    }

    @HostListener('document:keydown.control.shift.d', ['$event'])
    onControlPClick(event: KeyboardEvent): void {
        event.preventDefault();
        if (this.globalModelGraphText && this.globalModelGraphText !== 'null' && !this.actionsLocked) {
            this.debugMode = true;
        } else {
            !this.actionsLocked &&
            this.toaster.warningToast({
                title: 'Nothing debug',
                content: 'Can`t open debug menu. Please, select a first model!',
            });
            this.actionsLocked &&
            this.toaster.warningToast({
                title: 'Please wait',
                content: 'Can`t open debug menu. Please, wait for loading process!',
            });
        }
    }

    applyModelGraph(textarea: HTMLTextAreaElement) {
        try {
            const modelGraphJSON = JSON.parse(textarea.value);
            if (typeof modelGraphJSON === 'object') {
                this.queryBuilderStore.setState({modelGraph: modelGraphJSON});
            }
            this.debugMode = false;
        } catch {
            this.toaster.errorToast({
                title: 'Parse error',
                content: 'Can`t apply current state for ModelGraph. Check the last entered data',
            });
        }
    }

    widgetPath() {
        if (this.queryBuilderStore.getValue().path.path) {
            this.path = this.queryBuilderStore.getValue().path.path.map(title => ({
                uid: this.searchDrilldownIdByTitle(title, this.queryBuilderStore.globalModelGraph),
                title,
            }));
        }
        this.path = this.path.length === 0 ? [{uid: undefined, title: this.pageName}] : this.path;
        this.cdr.detectChanges();
    }

    searchDrilldowns(drilldowns: any) {
        return drilldowns?.drillDowns;
    }

    searchDrilldownIdByTitle(title: string, drilldowns: any): string {
        const uid = this.searchDrilldowns(drilldowns)?.find((dd: any) => dd.graph.widgetConfig.chartOptions.title === title)?.uid;
        if (uid === undefined && drilldowns?.graph?.drillDowns[0]?.drilldowns) {
            this.searchDrilldownIdByTitle(title, drilldowns.graph.drillDowns[0].drilldowns);
        }
        return uid;
    }

    canDeactivate(): boolean | Observable<boolean> {
        return this.queryBuilderStore.saved || !this.queryBuilderStore.dirty;
    }

    isWithWarning() {
        const modelGraph = this.queryBuilderStore.drilldownGraph;
        const placeholders = this.queryBuilderStore.placeholders;
        const local = this.queryBuilderStore.isLocalWidget();
        if (modelGraph) {
            const modelGraphParameters = modelGraph.parameters;
            if (!modelGraphParameters.length) {
                return false;
            }
            if (
                modelGraphParameters.some(
                    param =>
                        ((param.type === 'DRILLDOWN' && local) || param.type !== 'DRILLDOWN') &&
                        !placeholders.find(p => p.uid === param.uid)
                )
            ) {
                return true;
            }
        }
        return false;
    }

    ngOnInit() {
        this.drilldowns = [];
        this.drilldownEditorData = undefined;
        this.queryBuilderStore.init();
        this.modelGraphActionsService.rootModels().subscribe(
            rootModels => {
                this.rootModels = QueryBuilderComponent.sortRootModels(rootModels);
                this.error = false;
            },
            () => (this.error = true),
            () => {
                this.tryLoadModelGraph();
            }
        );
        this.widgetPath();
        this.activeTab = 'Model';
    }

    isInit() {
        return (
            !this.queryBuilderStore.modelGraph ||
            !this.queryBuilderStore.modelGraph.models ||
            !this.queryBuilderStore.modelGraph.models.length
        );
    }

    qbReset() {
        const modalRef = this.modalService.open(ModalConfirmComponent, {
            centered: true,
            backdrop: 'static',
        });
        modalRef.componentInstance.message = {
            title: `Reset Query Builder?`,
            content: `Query Builder will be reset. Continue?`,
            warningMessage: this.saveInLoad ? `The started saving process will continue` : null,
        };
        modalRef.componentInstance.confirmOk.subscribe(() => this.ngOnInit());
    }

    addRootModel(model: Model) {
        this.rootModels.push(model);
        this.rootModels = QueryBuilderComponent.sortRootModels(this.rootModels);
        this.qbModelComponent.ngOnChanges();
    }

    deleteCustomModel(uid: string) {
        if (this.modalCustomOpened) {
            return;
        }
        this.modalCustomOpened = true;
        const modalRef = this.modalService.open(ModalConfirmComponent, {
            centered: true,
            backdrop: 'static',
        });
        modalRef.componentInstance.message = {
            title: `Delete a Custom Model?`,
            content: `'${this.rootModels.find(m => m.uid === uid)?.name}' model will be deleted. Continue?`,
        };
        modalRef.componentInstance.confirmOk.subscribe(() => {
            this.modelService.deleteModel(uid).subscribe(() => (this.rootModels = this.rootModels.filter(m => m.uid !== uid)));
            this.modalCustomOpened = false;
            this.qbModelComponent.ngOnChanges();
        });
        modalRef.componentInstance.cancelBtnClicked.subscribe(() => {
            this.modalCustomOpened = false;
        });
    }

    onNavChange(event: { nextId: string }) {
        if (this.isModelPage && !this.currentState.modelGraph) {
            this.openRestrictModal();
        } else {
            this.activeTab = event.nextId;
        }
    }

    handleSaveDrilldown({value, type}: { value: IDrilldown; type: string }) {
        const sourceGraph = this.queryBuilderStore.globalModelGraph;
        const subGraphId = this.queryBuilderStore.subGraphUid;
        const drillDownId = this.queryBuilderStore.drillDownId;

        if (type === 'ADD') {
            this.modelGraphActionsService
                .addDrilldown({
                    sourceGraph,
                    drillDownId,
                    subGraphId,
                    drilldown: {
                        default: value.default,
                        displayType: value.displayType,
                    },
                    drillDownName: value.drillDownName,
                    drillDownParamName: value.drillDownParamName,
                })
                .subscribe(result => {
                    this.queryBuilderStore.updateState(result);
                    this.cdr.detectChanges();
                    this.queryBuilderStore.setDrillDownUid(this.queryBuilderStore.modelGraph.drillDowns[0].uid);
                    this.queryBuilderStore.setLocalWidget(true);
                    this.drilldowns = this.queryBuilderStore.modelGraph.drillDowns.map(drill => ({
                        ...drill,
                        name: drill.drillDownName,
                    }));
                    this.queryBuilderStore.updatePath();
                    this.widgetPath();
                    this.updateDrilldownBreadcrumb();
                    this.activeTab = 'Model';
                    this.cdr.detectChanges();
                });
        }
        if (type === 'EDIT') {
            const parentDrilldown = this.drilldownBreadcrumb.find(dd => dd.uid === drillDownId).parentId;
            this.modelGraphActionsService
                .updateDrilldown({
                    sourceGraph,
                    drillDownId: parentDrilldown || null,
                    subGraphId,
                    drilldown: {
                        default: value.default,
                        displayType: value.displayType,
                    },
                    drillDownName: value.drillDownName,
                    drillDownParamName: value.drillDownParamName,
                    drillDownUpdateId: drillDownId,
                })
                .subscribe(result => {
                    this.queryBuilderStore.updateState(result);
                    this.drilldowns = this.queryBuilderStore.modelGraph.drillDowns.map(drill => ({
                        ...drill,
                        name: drill.drillDownName,
                    }));
                    this.queryBuilderStore.updatePath();
                    this.widgetPath();
                    this.cdr.detectChanges();
                });
        }
        this.isDrilldownEditor = false;
    }

    handleCancelDrilldownEditor() {
        this.isDrilldownEditor = false;
    }

    handleAddDrilldown() {
        this.drilldownEditorData = undefined;
        this.isDrilldownEditor = true;
    }

    handleEditDrilldown() {
        const drilldown = this.queryBuilderStore.modelGraph.drilldown;
        this.drilldownEditorData = {
            displayType: drilldown.displayType,
            default: drilldown.default,
            drillDownName: drilldown.drillDownName,
            drillDownParamName: drilldown.drillDownParamName,
        };
        this.isDrilldownEditor = true;
    }

    handleDeleteDrilldown() {
        const modalService = this.injector.get(NgbModal);
        const modalRef = modalService.open(ModalConfirmComponent, {
            centered: true,
            backdrop: 'static',
            size: 'sm',
        });
        modalRef.componentInstance.message = {
            title: 'Delete Drilldowm',
            content: 'Are you sure you want to delete drilldown?',
        };
        modalRef.componentInstance.confirmOk.subscribe(() => {
            const sourceGraph = this.queryBuilderStore.globalModelGraph;
            const subGraphId = this.queryBuilderStore.subGraphUid;
            const drillDownId = this.queryBuilderStore.drillDownId;
            const parentDrilldown = this.drilldownBreadcrumb.find(dd => dd.uid === drillDownId).parentId;
            this.modelGraphActionsService
                .deleteDrilldown({
                    sourceGraph,
                    subGraphId,
                    drillDownId: parentDrilldown || null,
                    drillDownDeleteId: drillDownId,
                })
                .subscribe(result => {
                    this.queryBuilderStore.updateState(result);
                    this.cdr.detectChanges();
                    this.queryBuilderStore.setDrillDownUid(parentDrilldown);
                    this.drilldowns = this.queryBuilderStore.modelGraph.drillDowns.map(drill => ({
                        ...drill,
                        name: drill.drillDownName,
                    }));
                    this.queryBuilderStore.updatePath();
                    this.widgetPath();
                    this.cdr.detectChanges();
                });
        });
    }

    handleSelectDrilldown(event: any) {
        this.queryBuilderStore.setDrillDownUid(event.uid);
        this.drilldowns = this.queryBuilderStore.drilldownGraph.drillDowns.map(drill => ({
            ...drill,
            name: drill.drillDownName,
        }));
        this.queryBuilderStore.updatePath();
        this.widgetPath();
        const ddDropdown = this.elRef.nativeElement.querySelector('#drilldown-dropdown');
        if (this.dropdown) {
            this.dropdown.value = undefined;
        }
        if (ddDropdown) {
            ddDropdown.blur();
        }
        this.cdr.detectChanges();
    }

    saveWidgetAndModelGraph() {
        if (this.saveInLoad) {
            return;
        }
        this.saveInLoad = true;
        const request: WidgetConfigRequest = {
            graph: this.currentState.modelGraph,
            widgetConfig: this.currentState.widgetSettings.data,
            drillDownId: null,
            widgetId: this.editSettings && this.editSettings.widgetId ? this.editSettings.widgetId : null,
        };
        if (this.editSettings && this.editSettings.modelGraphUid && this.editSettings.widgetId) {
            this.modelGraphService.updateGraph(this.currentState.modelGraph, this.editSettings.modelGraphUid).subscribe(modelGraph => {
                this.queryBuilderStore.getValue().modelGraph = modelGraph;
                this.queryBuilderWidgetService.saveWidgetDataConfig(request).subscribe(() => {
                    this.toaster.successToast({title: 'Widget changed', content: 'Widget was changed successfully'});
                }, () => this.saveInLoad = false, () => this.saveInLoad = false);

                this.queryBuilderStore.saved = true;
            });
        } else {
            this.editSettings = {
                modelGraphUid: null,
                widgetId: null,
            };
            this.modelGraphService.createGraph(request.graph).subscribe(modelGraph => {
                this.editSettings.modelGraphUid = cloneDeep<string>(modelGraph.uid);
                this.queryBuilderWidgetService.saveWidgetDataConfig(request).subscribe(resp => {
                    this.editSettings.widgetId = resp.id;
                    this.toaster.successToast({title: 'Widget saved', content: 'Widget was saved successfully'});
                }, () => this.saveInLoad = false, () => this.saveInLoad = false);

                this.queryBuilderStore.saved = true;
            });
        }
    }

    handleExternalParametersClick() {
        const modelGraph = this.queryBuilderStore.globalModelGraph;
        const drillDownId = this.queryBuilderStore.drillDownId;
        const placeholders = this.queryBuilderStore.placeholders;
        const localWidget = this.queryBuilderStore.isLocalWidget();

        const getValue = (uid: string) => {
            const parameter = placeholders.find(placeholder => placeholder.uid === uid);
            return parameter ? parameter.value : null;
        };

        this.modelGraphActionsService
            .getParameters({
                sourceGraph: modelGraph,
                drillDownId,
                local: localWidget,
            })
            .subscribe(params => {
                const externalParams: ExternalParameter[] = params.map(p => ({
                    ...p,
                    value: getValue(p.uid),
                }));
                this.modalService
                    .open(
                        ExternalParamsComponent,
                        {size: 'lg'},
                        {
                            externalParameters: externalParams,
                        }
                    )
                    .result.then((res: ExternalParameter[]) => {
                    res.forEach(newParam => {
                        const idx = placeholders.findIndex(placeholder => placeholder.uid === newParam.uid);
                        if (idx !== -1) {
                            placeholders[idx] = newParam;
                        } else {
                            placeholders.push(newParam);
                        }
                    });
                    this.queryBuilderStore.setState({
                        placeholders,
                    });
                })
                    .catch(() => {
                        // noop
                    });
            });
    }

    reload() {
        this.error = false;
        this.ngOnInit();
    }

    getWarning() {
        return {
            title: 'Discard',
            message: 'Are you sure you want to discard changes?',
        };
    }

    private updateDrilldownBreadcrumb() {
        const globalModelGraph: ModelGraph = this.queryBuilderStore.globalModelGraph;
        const currentModelGraphUid = cloneDeep<string>(this.queryBuilderStore.drillDownId);
        if (!currentModelGraphUid) {
            return;
        }
        const drilldowns: any[] = clone(globalModelGraph.drillDowns);
        for (const dd of drilldowns) {
            const ddGraph = dd.graph;
            if (ddGraph) {
                drilldowns.push(
                    ...ddGraph.drillDowns.map((drilldown: WidgetDrilldown) => ({
                        ...drilldown,
                        parentId: dd.uid,
                    }))
                );
            }
        }
        this.drilldownBreadcrumb = drilldowns;
    }

    private openRestrictModal() {
        this.modalService
            .open(
                ModalConfirmComponent,
                {centered: true, windowClass: 'import-confirm-modal'},
                {
                    showCancelButton: false,
                    confirmButtonText: 'OK',
                    message: {
                        title: 'Select a root model',
                        content: 'Please add at least one model to the dataset first.',
                    },
                }
            )
            .result.then(() => {
            this.activeTab = 'Model';
            return false;
        }).catch(() => false).finally(() => {
            this.activeTab = 'Model';
            return false;
        });
    }

    private tryLoadModelGraph() {
        if (this.alreadyLoaded) {
            return;
        }
        this.route.queryParams.subscribe(params => {
            if (!(params.modelGraphId && params.widgetId)) {
                return;
            }
            QueryBuilderStore.relationCandidatesChecking = true;
            const modelGraphId$ = this.modelGraphService.getGraph(params.modelGraphId);
            const widgetBuilderService$ = this.widgetBuilderService.getWidgetFromLibrary(params.widgetId);
            forkJoin([modelGraphId$, widgetBuilderService$]).subscribe(([modelGraph, widgetData]) => {
                if (
                    !modelGraph ||
                    !widgetData ||
                    !widgetData.data ||
                    !widgetData.data.modelGraphId ||
                    widgetData.data.modelGraphId !== modelGraph.uid
                ) {
                    this.toaster.errorToast({
                        title: 'Can`t load',
                        content: 'Can`t load Widget Settings or Model Graph',
                    });
                    return;
                }
                this.editSettings = {
                    widgetId: widgetData.id,
                    modelGraphUid: modelGraph.uid,
                };
                this.queryBuilderStore.setState({
                    modelGraph,
                    widgetSettings: widgetData,
                });
                this.drilldowns = this.queryBuilderStore.modelGraph.drillDowns;
                this.alreadyLoaded = true;
                this.cdr.detectChanges();
            });
        });
    }

    beforeLeave(): void {
        // noop
    }
}

interface QbLoadingSettings {
    widgetId: number;
    modelGraphUid: string;
}
