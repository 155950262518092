<div *ngIf="items.length > 1" ngbDropdown [placement]="placement" display="dynamic" [container]="container">
    <button ngbDropdownToggle class="btn btn-secondary dropdown-toggle">
        {{ buttonText }}
        <fa-icon icon="sorting" class="ml-10px font-16px"></fa-icon>
    </button>
    <ul class="pt-0 pb-0 dropdown-list" ngbDropdownMenu>
        <li
            class="cursor-pointer font-11px pt-2 pb-2 pl-3 pr-3"
            [ngClass]="item.type === 'title' ? 'item-title' : 'dropdown-item'"
            *ngFor="let item of items"
            [ngSwitch]="item.type"
            (click)="emitAction(item, $event)"
        >
            <div  *ngSwitchCase="'title'">
                {{item.label}}
            </div>
            <lib-radio-button
                *ngSwitchCase="'radio'"
                [name]="item.name"
                [checked]="item.value"
                [value]="item.value"
                label="{{item.label}}"
            ></lib-radio-button>
        </li>
    </ul>
</div>
