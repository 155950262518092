<ng-container formArrayName="statuses">
    <h3 class="mt-0">
        Statuses
    </h3>
    <span class="btn-link cursor-pointer my-3 d-block" (click)="addStatus()">+ Add Status</span>
    <div
        [formGroup]="statusControl"
        [class.column-controls-border]="!last"
        *ngFor="let statusControl of statuses.controls; let i = index; let last = last"
        class="d-flex flex-column mb-3"
    >
        <div class="row mb-3">
            <lib-input class="col" formControlName="value" [label]="i === 0 ? 'Value' : null"></lib-input>
            <lib-input class="col" formControlName="label" [label]="i === 0 ? 'Label' : null"></lib-input>

            <ng-container *ngIf="widgetType === type.PI_FEATURE_COMPLETION_STATUS">
                <lib-dropdown
                    class="col"
                    formControlName="statusType"
                    [label]="i === 0 ? 'Status Type' : null"
                    placeholder="Select status type"
                    [items]="statusItems"
                ></lib-dropdown>

                <lib-dropdown
                    class="col"
                    formControlName="icon"
                    [label]="i === 0 ? 'Icon' : null"
                    placeholder="Select icon"
                    [items]="iconItems"
                ></lib-dropdown>
            </ng-container>

            <ng-container *ngIf="widgetType === type.PI_ISSUE_LIFE_CYCLE">
                <lib-input class="col" formControlName="order" [label]="i === 0 ? 'Order' : null"></lib-input>
                <dp-widget-color-picker class="col" formControlName="color" [label]="i === 0 ? 'Color' : null"></dp-widget-color-picker>
            </ng-container>

            <fa-icon
                icon="facTrash"
                class="delete-column-icon"
                [style.paddingTop]="i === 0 ? '20px' : '0px'"
                aria-hidden="true"
                (click)="removeStatus(i)"
                [ngbTooltip]="'Delete status'"
                tooltipClass="project-tooltip"
                placement="top"
                [openDelay]="800"
                container="body"
            ></fa-icon>
        </div>
    </div>
</ng-container>
