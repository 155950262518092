<ng-container formArrayName="eventDependencies">
    <h3 class="mt-0">
        Event Dependencies
    </h3>
    <span class="btn-link cursor-pointer my-3 d-block add-event-dependencies-button" (click)="addEventDependency()"
        >+ Add Event Dependency</span
    >
    <div
        [formGroup]="eventDependenciesControl"
        [class.column-controls-border]="!last"
        *ngFor="let eventDependenciesControl of eventDependencies.controls; let i = index; let last = last"
        class="d-flex flex-column mb-3"
    >
        <div class="row mb-3">
            <lib-input class="col" formControlName="condition" [label]="i === 0 ? 'Condition' : null"></lib-input>

            <fa-icon
                icon="facTrash"
                class="delete-column-icon"
                [style.paddingTop]="i === 0 ? '20px' : '0px'"
                aria-hidden="true"
                (click)="removeDependency(i)"
                [ngbTooltip]="'Delete column'"
                tooltipClass="project-tooltip"
                placement="top"
                [openDelay]="800"
                container="body"
            ></fa-icon>
        </div>
    </div>
</ng-container>
