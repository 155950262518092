import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';

import { SandBox } from '../../../../widget-builder/services/widget-builder.util';

@Component({
    selector: 'dp-cell-custom',
    template: `
        <div
            [ngbTooltip]="tooltipTmpl"
            container="body"
            [innerHtml]="this.valueToDisplay | safeHtml: 'html'"
            class="text-truncate"
            style="height: 100%"
        ></div>

        <ng-template #tooltipTmpl> <div [innerHTML]="tooltip | safeHtml: 'html'"></div> </ng-template>
    `,
})
export class CellCustomComponent implements ICellRendererAngularComp {
    valueToDisplay: string;
    tooltip: string;

    agInit(params: ICellRendererParams): void {
        const regexToIsolateHTMLTag = /<(\/?iframe)>+/gm;

        if (params.value && typeof params.value === 'string') {
            params.value = params.value.replace(regexToIsolateHTMLTag, `&lt;$1&gt;`);
        }

        this.valueToDisplay = params.colDef.cellRendererParams.cellRender
            ? params.colDef.cellRendererParams.cellRender(params) || ''
            : params.value ?? '';
        if (params.colDef.cellRendererParams.cellTooltipTemplate) {
            this.tooltip = this.executeTooltipTemplateScript(params.colDef.cellRendererParams.cellTooltipTemplate)(
                params.data ? params.data[params.colDef.field] : '',
                params.colDef.cellRendererParams.placeholders,
                params.data
            );
        } else {
            this.tooltip = params.value;
        }
    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }

    executeTooltipTemplateScript(script: string) {
        const sandbox = new SandBox();
        return sandbox.buildFn(`return ( function(value, placeholders, data) {${script} })`);
    }
}
