import { cloneDeep, keys } from 'lodash';

import { JobDefinitionForm } from '../models/common';
import { Deserializable, HIDDEN_FIELDS, JobDefinition } from '../models/job-definition';
import { JobDefinitionNormalizer, HIDDEN_FIELDS_PLUGIN_TYPE_MAP } from './job-definition.normalizer';
import {
    moveToLinesJDFields,
    moveToOldJDFields,
} from 'data-processor/lib/processor-monitoring/job-definition.lines';

export abstract class AbstractDpJSONConverter {
    private readonly defaultHiddenFields = [...Deserializable.METAFIELDS];
    private readonly defaultFlattenedFields = ['setup'];
    private readonly removedFields = [...HIDDEN_FIELDS];
    private readonly isWorkflowJD: boolean;

    abstract pluginHiddenFields: string[];
    abstract flattenedFields: string[];

    private static isWorkflowJD() {
        return true;
    }

    static getConverter() {
        if (AbstractDpJSONConverter.isWorkflowJD()) {
            return SimpleWorkflowPluginJSONConverter;
        } else {
            return DpJSONConverter;
        }
    }

    static restore(jsonJD: string, jobDefinition: JobDefinition) {
        const jdFromJson = JSON.parse(jsonJD);

        HIDDEN_FIELDS.forEach(key => {
            if (key in jobDefinition) {
                jdFromJson[key] = (jobDefinition as Record<string, any>)[key];
            }
        });

        moveToOldJDFields(jdFromJson?.actions ?? []);

        return jdFromJson;
    }

    constructor(private jd: JobDefinitionForm) {
        this.isWorkflowJD = AbstractDpJSONConverter.isWorkflowJD();
    }

    convert() {
        const formJsonJD = cloneDeep(this.jd);
        let jsonJD = this.flat(formJsonJD);
        this.removeHiddenFields(jsonJD);

        const actions = jsonJD.actions;
        delete jsonJD.actions;

        jsonJD = new JobDefinitionNormalizer(this.isWorkflowJD).normalizeEmptyFields(jsonJD);

        jsonJD.actions = actions;
        moveToLinesJDFields(actions ?? []);

        return this.stringify(jsonJD);
    }

    private flat(formJsonJD: JobDefinitionForm) {
        const currentFlattenedFields = [...this.defaultFlattenedFields, ...this.flattenedFields];

        let jsonJD = { ...formJsonJD };

        for (const key of currentFlattenedFields) {
            const field = (formJsonJD as any)[key];
            delete (jsonJD as any)[key];
            jsonJD = { ...jsonJD, ...field };
        }

        return jsonJD;
    }

    private removeHiddenFields(jd: Record<string, any>) {
        const currentHiddenFields = [...this.removedFields, ...this.pluginHiddenFields];

        for (const key of currentHiddenFields) {
            if (jd[key] !== undefined) {
                delete jd[key];
            }
        }
    }

    private stringify(jd: Record<string, any>) {
        const hiddenFieldsMap: Record<string, any> = this.defaultHiddenFields.reduce((acc, key) => ({ ...acc, [key]: true }), {});
        return JSON.stringify(jd, (key, value) => (hiddenFieldsMap.hasOwnProperty(key) ? undefined : value), 2);
    }
}

class SimpleWorkflowPluginJSONConverter extends AbstractDpJSONConverter {
    flattenedFields: string[] = [];
    pluginHiddenFields: string[] = keys(HIDDEN_FIELDS_PLUGIN_TYPE_MAP.WORKFLOW_PLUGIN);
}

class DpJSONConverter extends AbstractDpJSONConverter {
    flattenedFields: string[] = ['groovy'];
    pluginHiddenFields = keys(HIDDEN_FIELDS_PLUGIN_TYPE_MAP.DATA_PLUGIN);
}
