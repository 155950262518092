import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';

@Component({
    selector: 'dp-statistic-column',
    template: `
        <lib-chart class="chart" type="simplelinechart" height="min" [options]="options" [checkHostResize]="true"></lib-chart>
    `,
    styleUrls: ['./statistic-column.component.scss'],
})
export class StatisticColumnComponent implements ICellRendererAngularComp {
    options = {};

    agInit(params: ICellRendererParams): void {
        const incomingValue = JSON.parse(params.value);
        this.options = {
            moveToLeft: false,
            series: [
                {
                    name: incomingValue.label,
                    data: incomingValue.value,
                    color: incomingValue.color,
                },
            ],
        };
    }

    refresh(params: any): boolean {
        return false;
    }
}
