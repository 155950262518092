import { Type } from '@angular/core';
import { InputComponent } from '@dagility-ui/kit';
import { MultiselectDropdownFilterComponent } from 'data-processor/lib/widget-library/dashboard/components/dashboard-filter-set/global-filters/multiselect-dropdown-filter/multiselect-dropdown-filter.component';
import { DropdownFilterComponent } from 'data-processor/lib/widget-library/dashboard/components/dashboard-filter-set/global-filters/dropdown-filter/dropdown-filter.component';
import { InlineDateFilterComponent } from 'data-processor/lib/widget-library/widget-builder/components/widget/filters/inline-date-filter/inline-date-filter.component';
import { InlineRangeFilterComponent } from 'data-processor/lib/widget-library/widget-builder/components/widget/filters/inline-range-filter/inline-range-filter.component';
import { DropdownFilter, WidgetFilter, WidgetFilterType } from '../../../../models/any-widget.model';

export function expandedFilterFactory(filter: WidgetFilter): Type<unknown> {
    switch (filter.type) {
        case WidgetFilterType.DROPDOWN:
        case WidgetFilterType.RADIO_GROUP: {
            if ((filter as DropdownFilter).multiple) {
                return MultiselectDropdownFilterComponent;
            }
            return DropdownFilterComponent;
        }

        case WidgetFilterType.INPUT: {
            return InputComponent;
        }

        case WidgetFilterType.DATE:
            return InlineDateFilterComponent;

        case WidgetFilterType.RANGE:
        case WidgetFilterType.NEW_RANGE: {
            return InlineRangeFilterComponent;
        }
    }

    return undefined;
}
