import { InjectionToken } from '@angular/core';

export const fieldNames = {
    dataSource: 'Data Source',
    targets: 'Targets',
    groovy: 'Groovy Scripts',
};

const jobDefinitionErrors = {
    collectionsRequired: 'Fields or Levels should contain at least 1 element',
    schemaIsRequired: 'A Schema is required',
    levelIsRequired: 'A Level is required',
    required: 'The section should contain at least 1 element',
    rangeRequired: "The value should contain 'from' or 'to'",
    minOneNotEmpty: 'The section should contain at least 1 non-empty element',
    keyInColumns: '"Columns" should contain a key',
    unique: '"This field should be unique"',
};

export const warningNames = {
    typingError: ({ required, received, isEnum }: any) =>
        isEnum ? `${received} not contains in enum` : `Expected ${required}, but got ${received}`,
};

export const FIELD_NAMES = new InjectionToken('FIELD_NAMES', {
    providedIn: 'root',
    factory: () => fieldNames,
});

export const WARNING_NAMES = new InjectionToken('WARNING_NAMES', {
    providedIn: 'root',
    factory: () => warningNames,
});

export const DEFINITION_ERRORS = new InjectionToken('DEFINITION_ERRORS', {
    providedIn: 'root',
    factory: () => jobDefinitionErrors,
});
