import { Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[resizeObserver]',
})
export class ResizeObserverDirective implements OnInit, OnDestroy {
    @Output() resizeObserver = new EventEmitter();

    private observer: ResizeObserver;

    @Input() resizeObserverSelectorFn: (host: Element) => Element = (host: Element) => host;

    constructor(private elRef: ElementRef, private zone: NgZone) {}

    ngOnInit() {
        this.zone.runOutsideAngular(() => {
            this.observer = new ResizeObserver(() => this.resizeObserver.emit());
            this.observer.observe(this.resizeObserverSelectorFn(this.elRef.nativeElement));
        });
    }

    ngOnDestroy() {
        if (this.observer) {
            this.observer.disconnect();
        }
    }
}
