import { Injectable } from '@angular/core';
import { QueryBuilderState } from '@app/shared/components/query-builder/store/state/query-builder.state';
import { Store } from '@dagility-ui/kit';
import { INIT_WIDGET_SETTINGS } from '@app/shared/components/query-builder/consts/widget-settings.const';
import { Subject } from 'rxjs';
import { GraphMutationResult, ModelGraphNewDrillDownResponse } from '@app/shared/components/query-builder/models/model-graph-actions.model';
import { ModelGraph } from '@app/shared/components/query-builder/models/model-graph.model';
import { GraphPath } from '@app/shared/components/query-builder/store/graph-path';

@Injectable({
    providedIn: 'root',
})
export class QueryBuilderStore extends Store<QueryBuilderState> {
    private static initState: QueryBuilderState = {
        modelGraph: null,
        validationErrors: [],
        widgetSettings: INIT_WIDGET_SETTINGS,
        conditions: [],
        path: new GraphPath(),
        placeholders: [],
    };

    private _dirty: boolean = false;
    private static _subQueryGraphId: string;
    private static localWidget: boolean = true;
    private _saved = false;
    $forcedEditorViewUpdate = new Subject();

    constructor() {
        super(QueryBuilderStore.initState);
    }

    private static _relationCandidatesChecking = false;

    static get relationCandidatesChecking(): boolean {
        return this._relationCandidatesChecking;
    }

    static set relationCandidatesChecking(value: boolean) {
        this._relationCandidatesChecking = value;
    }

    get dirty(): boolean {
        return this._dirty;
    }

    private set dirty(value: boolean) {
        this._dirty = value;
    }

    get saved() {
        return this._saved;
    }

    set saved(value: boolean) {
        this._saved = value;
    }

    private static _drillDownId: string;

    get drillDownId() {
        return QueryBuilderStore._drillDownId;
    }

    get modelGraph() {
        const modelGraphSt = this.getValue().modelGraph;
        if (!QueryBuilderStore._subQueryGraphId && !QueryBuilderStore._drillDownId) {
            return modelGraphSt;
        } else {
            return this.subGraph;
        }
    }

    get globalModelGraph() {
        return this.getValue().modelGraph;
    }

    get conditions() {
        return this.getValue().conditions;
    }

    get placeholders() {
        return this.getValue().placeholders;
    }

    get widgetSettings() {
        return this.drilldownGraph ? this.drilldownGraph.widgetConfig : this.getValue().widgetSettings.data;
    }

    get validationErrors() {
        return this.getValue().validationErrors;
    }

    get subGraphSelected() {
        return !!QueryBuilderStore._subQueryGraphId;
    }

    get drilldownSelected() {
        return !!QueryBuilderStore._drillDownId;
    }

    get drilldownGraph(): ModelGraph {
        return GraphPath.findGraph(this.getValue().modelGraph, QueryBuilderStore._drillDownId, null);
    }

    get subGraphUid() {
        return QueryBuilderStore._subQueryGraphId;
    }

    private get subGraph(): ModelGraph {
        return GraphPath.findGraph(this.getValue().modelGraph, QueryBuilderStore._drillDownId, QueryBuilderStore._subQueryGraphId);
    }

    private static fillInQbChangedTags(partialState: Partial<QueryBuilderState>): QueryBuilderChangedTags {
        let qbChangedTag: QueryBuilderChangedTags = 'NO_CHANGES';
        if (partialState.modelGraph && partialState.validationErrors && partialState.widgetSettings) {
            qbChangedTag = 'BOTH';
        } else if (partialState.modelGraph) {
            qbChangedTag = 'MODEL_GRAPH';
        } else if (partialState.validationErrors) {
            qbChangedTag = 'VALIDATION_ERRORS';
        } else if (partialState.widgetSettings) {
            qbChangedTag = 'WIDGET_SETTINGS';
        }
        return qbChangedTag;
    }

    public setSubGraphUid(subGraphUid: string) {
        QueryBuilderStore._subQueryGraphId = subGraphUid;
        document.dispatchEvent(
            new CustomEvent('queryBuilderChanged', {
                detail: { changed: 'SUBQUERY' },
            })
        );
    }

    public setDrillDownUid(drillDownId: string) {
        QueryBuilderStore._drillDownId = drillDownId;
        this.setSubGraphUid(null);
        document.dispatchEvent(
            new CustomEvent('queryBuilderChanged', {
                detail: { changed: 'SUBQUERY' },
            })
        );
    }

    public setLocalWidget(local: boolean) {
        QueryBuilderStore.localWidget = local;
    }

    public isLocalWidget() {
        return QueryBuilderStore.localWidget;
    }

    init() {
        QueryBuilderStore._subQueryGraphId = null;
        QueryBuilderStore._drillDownId = null;
        this.updatePath();
        this.setState(QueryBuilderStore.initState);
        this.dirty = false;
    }

    updateState(result: GraphMutationResult | ModelGraphNewDrillDownResponse) {
        return this.setState({
            modelGraph: (result as GraphMutationResult).graph,
            validationErrors: (result as GraphMutationResult).validationResults,
        });
    }

    updateStateSilent(result: GraphMutationResult) {
        return this.setStateSilent({
            modelGraph: result.graph,
            validationErrors: result.validationResults,
        });
    }

    setState(partialState: Partial<QueryBuilderState>) {
        this.dirty = true;
        this._saved = false;

        super.setState(partialState);
        this.updatePath();
        setTimeout(() =>
            document.dispatchEvent(
                new CustomEvent('queryBuilderChanged', {
                    detail: { changed: QueryBuilderStore.fillInQbChangedTags(partialState) },
                })
            )
        );
    }

    public updatePath() {
        this.getValue().path.updateIfNeeded(this.getValue().modelGraph, QueryBuilderStore._subQueryGraphId, QueryBuilderStore._drillDownId);
    }

    private setStateSilent(partialState: Partial<QueryBuilderState>) {
        this.dirty = true;
        super.setState(partialState);
        this.updatePath();
    }
}

export type QueryBuilderChangedTags =
    'NO_CHANGES'
    | 'MODEL_GRAPH'
    | 'VALIDATION_ERRORS'
    | 'WIDGET_SETTINGS'
    | 'BOTH'
    | 'SUBQUERY';
