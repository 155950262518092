<div class="modal-header d-flex align-items-center">
    <h1>
        Save job
    </h1>
    <button type="button" class="close ml-auto" aria-label="Close button" aria-describedby="modal-title" (click)="handleClose()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body flex-column flex-1 d-flex">
    <div class="body3 mb-2">
        Are you sure? <br />
        Leave your comment here
    </div>
    <lib-textarea class="flex-1" [(ngModel)]="comment" [resize]="false" resizeMode="none"></lib-textarea>
</div>
<div class="modal-footer d-flex">
    <button class="ml-auto btn btn-secondary" (click)="handleClose()">Cancel</button>
    <button class="btn btn-primary" (click)="handleSave()">Save</button>
</div>
