import { CardStatusValue, DropdownItem } from '@dagility-ui/kit';
import { TOOL_CATEGORY_NAMES, ToolCategoryType } from '@dagility-ui/shared-components';

/* eslint-disable @typescript-eslint/naming-convention */

export enum WidgetQueryType {
    POSTGRESQL = 'POSTGRESQL',
    CLICKHOUSE = 'CLICKHOUSE',
    JDBC = 'JDBC',
}

export enum WidgetType {
    BAR_CHART = 'stackedbarchart',
    LINE_CHART = 'linechart',
    PIE_CHART = 'piechart',
    DOUGHNUT_CHART = 'doughnutchart',
    SUNBURST_CHART = 'sunburstchart',
    NESTED_PIE_CHART = 'nestedpiechart',
    TABLE = 'table',
    STACKED_BAR_CHART = 'stacked',
    COMPLEX = 'complex',
    TREEMAP = 'treemap',
    TWO_DIMENSIONAL_TABLE = 'twodimensionaltable',
    PROGRESS = 'progress',
    BOXPLOT = 'boxplotchart',
    TILE_CHART = 'tilechart',
    LOG = 'log',
    MULTIPLE_Y_AXIS = 'multipleyaxischart',
    ACCORDION = 'accordion',
    GANTT = 'ganttChart',
    RADIAL = 'radialChart',
    PI_WORK_DISTRIBUTION = 'piWorkDistribution',
    PI_WORK_CHART = 'piWorkChart',
    PI_MULTIPLE_RADIALS = 'piMultipleRadials',
    PI_PROGRESS_MENU = 'piProgressMenu',
    TABLE_WITH_TABS = 'gridWithTabs',
    ACCORDION_WITH_TABS = 'accordionWithTabs',
    SCATTER_CHART = 'scatterchart',
    HEALTH_SCORE = 'healthscore',
    PI_GANTT = 'piGantt',
    PI_FEATURE_COMPLETION_STATUS = 'piFeatureCompletionStatus',
    PI_ISSUE_LIFE_CYCLE = 'piIssueLifecycle',
    SRE_SQUARES = 'sreSquares',
    SRE_HEXAGONS = 'sreHexagons',
    CODE_ISSUE_DETAILS = 'codeIssueDetails',
    SECURITY_POSTURE_DETAILS = 'securityPostureDetails',
    SRE_GANTT = 'sreGantt',
    FACTOR_SCORES = 'factorScores',
    CODE_QUALITY_SUMMARY = 'codeQualitySummary',
    SCORE_DOUGHNUT = 'iFitScoreDoughnut',
    METRIC_LINE = 'iFitMetricLine',
    TOP_PRIORITY_RISKS = 'iFitTopPriorityRisks',
    PORTFOLIO_HEALTH_SCORES = 'portfolioHealthScores',
    PORTFOLIO_SCORE_SUBCATEGORIES = 'portfolioScoreSubcategories',
    PORTFOLIO_STATISTICS = 'portfolioStatistics',
    PORTFOLIO_RISK_SCORES = 'portfolioRiskScores',
    COMPARE_METRICS = 'compareMetrics',
    MERGE_REQUEST_INFO = 'mergeRequestInfo',
    MULTIPLE_SERIES_TYPE = 'multipleseriestypechart',
    METRIC_TILE = 'metric-tile'
}

export enum DisplayType {
    MODAL = 'modal',
    STACK = 'stack',
}

/**
 * @deprecated
 */
export enum WidgetSize {
    '1_1' = '1_1',
    '1_2' = '1_2',
    '2_2' = '2_2',
}

export enum WidgetFilterType {
    RANGE = 'RANGE',
    DROPDOWN = 'DROPDOWN',
    INPUT = 'INPUT',
    DATE = 'DATE',
    CHECKBOX = 'CHECKBOX',
    HIDDEN = 'HIDDEN',
    NEW_RANGE = 'NEW_RANGE',
    RADIO_GROUP = 'RADIO_GROUP',
    COMPLEX_NAMED_DROPDOWN = 'COMPLEX_NAMED_DROPDOWN',
}

const WIDGET_TOOL_CATEGORY_TYPES: ReadonlyArray<ToolCategoryType | 'NONE'> = [
    'OTHER', 'PM', 'SCM', 'CISRV', 'CSCAN', 'NONE', 'ITSM', 'MONITORING', 'TEST_MANAGEMENT', 'INTEGRATION',
] as const;

export type WidgetToolCategoryType = typeof WIDGET_TOOL_CATEGORY_TYPES[number];
export type WidgetBarPosition = 'Stacked' | 'Unstacked' | 'Combined' | 'Ad';
export type WidgetOrientation = 'Horizontal' | 'Vertical';
export type XAxisType = 'category' | 'time' | 'log';
export type WidgetColorBy = 'series' | 'data';
export const TOOL_CATEGORY_TYPES: DropdownItem<string>[] = WIDGET_TOOL_CATEGORY_TYPES.map(value => ({
    value,
    label: TOOL_CATEGORY_NAMES.has(value as ToolCategoryType) ? TOOL_CATEGORY_NAMES.get(value as ToolCategoryType) : value.toUpperCase(),
})).sort((a, b) => a.label.localeCompare(b.label));

export enum ColumnFilterType {
    NUMBER = 'agNumberColumnFilter',
    TEXT = 'agTextColumnFilter',
    DATE = 'agDateColumnFilter',
}

export enum TextColumnFilterType {
    EQUALS = 'equals',
    STARTS_WITH = 'startsWith',
}

type WithLines<T, K extends string & keyof T> = T & Partial<Record<`${K}Lines`, string[]>>;

export interface AnyWidgetModel {
    id?: string;
    server?: boolean;
    complexNamedDropdown?: boolean;
    complexNamedDropdownLabel?: string;
    complexNamedDropdownDependencies?: string;
    complexNamedDropdownFilterDependency?: string;
    orderNumber?: number;
    groupIndex?: number;
    eventDependencies?: WidgetEventDependency[];
    tags?: string[];
    ftTags?: string[];
    modelGraphId?: string;
    toolCategoryType: WidgetToolCategoryType;
    type: WidgetType;
    query: WidgetQuery[];
    chartOptions: ChartOptions;
    colors?: string[];
    system?: boolean;
    filters: WidgetFilter[];
    series: WidgetSerie[];
    colorThreshold: WidgetColorThreshold[];
    accordionColumns?: WidgetAccordionColumn[];
    columns?: WidgetTableColumn[];
    drilldown?: WidgetDrilldown;
    drilldownList: WidgetDrilldown[];
    widgets: AnyWidgetModel[];
    tiles: TileWidgetSettings[];
    additionalTemplates?: WidgetAdditionalTemplate[];
    handlers?: WidgetEventHandler[];
    statuses?: WidgetStatus[];
    help?: WidgetHelp;
    asyncQueries?: AsyncQuery[];
    inputPlaceholders?: string[];
    size?: WidgetSizes;
}

export interface WidgetSizes {
    minW: number;
    minH: number;
    defaultW: number;
    defaultH: number;
}

export type WidgetAdditionalTemplate = WithLines<{
    position: 'left' | 'right' | 'bottom' | 'top';
    targetDrilldown: string;
    drilldownInput: string;
    tilesTemplate: boolean;
    template: string;
}, 'template'>;

export type WidgetTile = SingleValueTile | MultiValueTile;

export type SingleValueTile = BaseTile & TileValue;
export type MultiValueTile = BaseTile & ({ values: TileValue[] });

interface BaseTile {
    tileTitle: string;
    backgroundColor?: string;
    targetDrilldown?: string;
    template?: string;
    drilldownInput?: string;
}

export interface TileValue {
    value: unknown;
    delta?: number;
    additionalText?: string;
    deltaPostfix?: string;
    deltaInversed?: boolean;
    deltaIconInversed?: boolean;
    valueColor?: string;
    deltaSign?: number;
    postfix: string;
}

export type WidgetEventHandler = WithLines<{
    eventId: string;
    script: string;
    type: 'OUT' | 'IN';
}, 'script'>;

export type WidgetQuery = WithLines<{
    id?: string;

    parent?: string;
    query: string;
    placeholder: string;
    type: WidgetQueryType;
    script: string;
    beforeScript: string;
}, 'query' | 'script' | 'beforeScript'>;

export enum WidgetPosition {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
}

export interface AsyncQuery extends WidgetQuery {
    headers: Record<string, string>;
    requestType: string;
}

export type WidgetDrilldown = WithLines<{
    id?: string;

    drilldownScript: string;
    displayType: 'modal' | 'stack';
    widget: AnyWidgetModel;
    target: string;
    default: boolean;
    matcherScript?: string;
}, 'drilldownScript' | 'matcherScript'>;

export type ChartOptions = WithLines<{
    id?: string;
    /**
     * @deprecated
     */
    size?: WidgetSize;

    title: string;
    description: string;
    xlabel: string;
    ylabel: string;
    xAxisMinInterval: number;
    yAxisMinInterval: number;
    showArea?: boolean;
    showStacked?: boolean;
    hideBreadcrumb?: boolean;
    hideUpperLabel?: boolean;
    dataZoom?: boolean;
    xaxistype?: XAxisType;
    barsPosition?: WidgetBarPosition;
    withEmptyBars?: boolean;
    orientation: WidgetOrientation;
    cellTemplate?: string;
    layout: ComplexWidgetLayout;
    verticalHeader: string;
    horizontalHeader: string;
    gridValue: string;
    showTotal: boolean;
    showCount: boolean;
    mockData: string;
    headertemplate: string;
    nodatamessage: string;
    tooltipFormatter: string;
    gridPagination: boolean;
    serverSidePagination: boolean;
    hasResetFilter: boolean;
    scrollToBottom: boolean;
    maxValue: number;
    measure: string;
    relatedThreshold: boolean;
    higherIsBetter: boolean;
    disableBottomLine: boolean;
    mediumThreshold: number;
    criticalThreshold: number;
    hideLegend: boolean;
    minYAxisValue: number;
    maxYAxisValue: number;
    gridBottom?: string;
    piWorkChartLabel?: string;
    piCheckboxLabel?: string;
    labelFormatter?: string;
    enterableTooltip: boolean;
    fixedLastRow?: boolean;
    openFirstRow?: boolean;
    tooltipTrigger?: 'item' | 'axis' | 'none' | null;
    hideTitle?: boolean;
    isDynamicTable?: boolean;
    isBarSeriesClick?: boolean;
    colorBy?: WidgetColorBy;
    xAxisLabelFormatter?: string;
}, 'tooltipFormatter' | 'labelFormatter' | 'xAxisLabelFormatter'>;

export interface TileWidgetSettings {
    column: string;
    header: string;
    progress?: boolean;
    ratings: TileWidgetRating[];
}

export interface TileWidgetRating {
    color: string;
    rating: string;
    info: string;
}

export const FILTER_DEFAULT_SIZES: { [key in WidgetFilterType]?: number } = {
    [WidgetFilterType.DROPDOWN]: 150,
    [WidgetFilterType.INPUT]: 60,
};

export type ComplexWidgetLayout = '1-1' | '1-2' | '2-1' | '2-2' | '1-1-vertical';

export type MultipleChartType = 'bar' | 'line' | 'scatter';

export type WidgetFilter = WithLines<{
    id?: string;
    mainFilter?: boolean;
    type: WidgetFilterType;
    label: string;
    placeholder: string;
    defaultValue: string;
    width?: string;
    minWidth?: string;
    valueDependency?: string;
    dependentValue?: string[];
    placeholderText?: string;
    hideLabel?: boolean;
    position?: WidgetPosition;
    isUiFilter?: boolean;
    tooltip?: string;
    description?: string;
    onlyHideDependentOn?: boolean;
}, 'defaultValue'>;

export interface WidgetSerie {
    column: string;
    label: string;
    color?: string;
    type?: MultipleChartType;
    seriesArea?: boolean;
    markLineLabel?: string;
    markLineValue?: string;
    seriesBarStack?: string;
    options?: Record<string, any>;
    colorBy?: string;
}

export interface WidgetColorThreshold {
    from: number;
    to: number;
    includingFrom: boolean;
    includingTo: boolean;
    color: string;
    label?: string;
}

export type WidgetAccordionColumn = WithLines<{
    title: string;
    field: string;
    clickEventFunction: string;
}, 'clickEventFunction'>;

export interface WidgetEventDependency {
    condition: string;
}

export type WidgetTableColumn = WithLines<{
    title: string;
    field: string;
    progressColumn: boolean;
    statisticColumn: boolean;
    percentWidth: boolean;
    headerTooltipTemplate: string;
    cellTemplate: string;
    cellTooltipTemplate: string;
    progressTemplate: string;
    progressTooltip: string;
    columnSorting: string;
    filterComparator: string;
    colors: string[];
    progressColors: ProgressColor[];
    filtertype?: ColumnFilterType;
    columnVisible: boolean;
}, 'headerTooltipTemplate' | 'cellTemplate' | 'progressTemplate' | 'progressTooltip' | 'cellTooltipTemplate'>;

export interface ProgressColor {
    label: string;
    color: string;
}

export interface WidgetStatus {
    label: string;
    value: string;
    statusType?: CardStatusValue;
    icon?: string;
    order?: number;
    color?: string;
}

export type WidgetHelp = WithLines<{
    summary: string;
    howScoreCalculated?: string;
    howScoreCanImproved?: string;
    description: string;
    insights: WidgetHelpInsight[];
}, 'summary' | 'description' | 'howScoreCalculated' | 'howScoreCanImproved'>;

export interface WidgetHelpInsight {
    insight: string;
    recommendations: string[];
}

export interface DropdownFilter extends WidgetFilter {
    type: WidgetFilterType.DROPDOWN;

    multiple: boolean;
    dynamic: boolean;
    query?: WidgetQuery;
    items?: DropdownItem[];
    dependencies: string[];
    clearable?: boolean;
}

export interface RangeFilter extends WidgetFilter {
    label: never;
    type: WidgetFilterType.RANGE;
}

export interface InputFilter extends WidgetFilter {
    type: WidgetFilterType.INPUT;
    buttonText?: string;
}

export interface CheckboxFilter extends WidgetFilter {
    type: WidgetFilterType.CHECKBOX;
}

export interface DateFilter extends WidgetFilter {
    type: WidgetFilterType.DATE;
}

export interface HiddenFilter extends WidgetFilter {
    type: WidgetFilterType.HIDDEN;

    label: never;
    dependencies: string[];
    query: WidgetQuery;
}

export interface NewRangeFilter extends WidgetFilter {
    type: WidgetFilterType.NEW_RANGE;
    showDaysCount: boolean;
}

export interface ComplexNamedDropdownFilter extends WidgetFilter {
    type: WidgetFilterType.COMPLEX_NAMED_DROPDOWN;
    dependencies: string[];
    filterDependency: string;
    isInline: boolean;
}

type WidgetFilters =
    DropdownFilter
    | RangeFilter
    | InputFilter
    | DateFilter
    | WidgetFilter
    | HiddenFilter
    | NewRangeFilter;
type WidgetFiltersType = WidgetFilters['type'];
type WidgetFilterMap<U> = {
    [K in WidgetFiltersType]: U extends { type: K } ? U : never;
};
type WidgetFilterTypeMap = WidgetFilterMap<WidgetFilters>;
type WidgetFilterPattern<T> = {
    [K in keyof WidgetFilterTypeMap]: (filter: WidgetFilterTypeMap[K]) => T;
};

export function widgetFilterMatcher<T>(
    pattern: WidgetFilterPattern<T> & {
        _: (emptyFilter: WidgetFilter) => T;
    },
): (filter: WidgetFilters) => T {
    return filter => (pattern[filter.type] ? (pattern[filter.type] as any)(filter) : pattern['_'](filter));
}
