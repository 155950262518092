<h3>Additional templates</h3>

<div class="d-flex justify-content-between my-3">
    <span class="btn-link cursor-pointer" (click)="addTemplate()">+ Add template</span>
</div>

<ng-container formArrayName="additionalTemplates">
    <div
        class="border mb-3 p-2 d-flex flex-column"
        *ngFor="let additionalTemplate of additionalTemplatesArray.controls; index as index"
        [formGroup]="additionalTemplate"
    >
        <span class="btn-link cursor-pointer mb-1 ml-auto" (click)="removeTemplate(index)">- Remove template</span>

        <div class="row mb-3">
            <lib-dropdown class="col" label="Position" [items]="positions" formControlName="position"></lib-dropdown>
            <ng-container *ngIf="!additionalTemplate.value.tilesTemplate">
                <lib-input class="col" formControlName="targetDrilldown" label="Target Drilldown"></lib-input>
                <lib-input class="col" formControlName="drilldownInput" label="Drilldown Input"></lib-input>
            </ng-container>
        </div>
        <checkbox class="mb-2 d-block" formControlName="tilesTemplate">Tiles Template</checkbox>
        <lib-codemirror-editor label="Template" mode="text/html" formControlName="template" [needRefresh]="true"></lib-codemirror-editor>
    </div>
</ng-container>
