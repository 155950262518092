import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import ResizeObserver from 'resize-observer-polyfill';
import { SreGanttService } from '../../services/sre-gantt.service';
import { SREGanttValue } from 'data-processor/lib/widget-library/widget-builder/components/sre-components/sre-gantt/models/sre-gantt.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'dp-sre-gantt-values',
    templateUrl: './sre-gantt-values.component.html',
})
export class SreGanttValuesComponent implements OnInit, OnDestroy {
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _scaleValues: SREGanttValue[] = [];
    private resize$ = new ResizeObserver(this.onResize.bind(this));
    private destroy$ = new Subject<void>();

    set scaleValues(value: SREGanttValue[]) {
        this._scaleValues = value;
    }

    get scaleValues() {
        return this._scaleValues;
    }

    constructor(private service: SreGanttService, private elRef: ElementRef, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.subscribeToObs();
    }

    subscribeToObs() {
        this.resize$.observe(this.elRef.nativeElement);
        this.service.values$.pipe(takeUntil(this.destroy$)).subscribe(this.initScaleValues.bind(this));
    }

    onResize() {
        this.initScaleValues(this.service.values);
        this.cdr.detectChanges();
    }

    initScaleValues(values: SREGanttValue[]) {
        this.scaleValues = values.map((value: SREGanttValue) => ({
            ...value,
            from: this.scaleValue(value.from),
            to: this.scaleValue(value.to),
            unscaleValue: { from: value.from, to: value.to },
        }));
    }

    scaleValue(value: number) {
        const percentage = (value - this.service.minimum) / (this.service.maximum - this.service.minimum);
        return this.elRef.nativeElement.getBoundingClientRect().width * percentage;
    }

    ngOnDestroy() {
        this.resize$.unobserve(this.elRef.nativeElement);
        this.destroy$.next();
    }
}
