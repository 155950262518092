import { dropRight, get, isEmpty, isEqual, isMap, startCase } from 'lodash';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { KeyValue } from '@angular/common';
import 'reflect-metadata';

import { getFieldType } from './models/type.decorator';
import { getValidatorsByField, getValidatorsCollectionElement, Min } from './models/validate.decorator';
import { IssueElement } from './models/issue-list.model';
import {
    DataFilter,
    Deserializable,
    HIDDEN_FIELDS,
    JobDefinitionDataSource,
    JobDefinitionRunningType,
    PostUpdate,
    PrimitiveDataType,
} from './models/job-definition';
import { DropdownItem, FieldTypes, MetaInformation, NormalizePattern } from './models/common';
import { UpdateAction } from './job-definition-builder/edit-workflow-action/models/update.action';

export function getTypeOfField(object: any, key: string, meta: MetaInformation): string {
    const metaType = getFieldType(object, key);
    const type = (metaType && metaType.name.toLowerCase()) || typeof object[key];
    if (key in meta._enums) {
        if (key in meta._maps) {
            return 'mapenum';
        }
        return 'enum';
    } else if (['string', 'boolean', 'number'].includes(type)) {
        return type;
    } else if (Array.isArray(object[key])) {
        return 'array';
    } else if (key in meta._maps) {
        return 'map';
    } else if (typeof object[key] == 'object') {
        if (meta._nullableFields && meta._nullableFields[key]) {
            return meta._nullableFields[key];
        }

        return 'object';
    }
}

export const enumToArray = (_enum: any): DropdownItem[] => Object.keys(_enum).map(key => ({ id: _enum[key], name: _enum[key] }));

export const sortObject = (obj: any, orderFunction: Function) =>
    Object.entries(obj)
        .sort((a, b) => (orderFunction(a[1]) > orderFunction(b[1]) ? 1 : orderFunction(a[1]) < orderFunction(b[1]) ? -1 : 0))
        .reduce(
            (acc, [key, value]) => ({
                ...acc,
                [key]: value,
            }),
            {}
        );

export const sortByFieldType = (a: KeyValue<string, any>, b: KeyValue<string, any>) =>
    ['string', 'number', 'boolean', 'enum'].some(t => a.value.type === t) ? -1 : a.value.type === b.value.type ? 0 : 1;

export const getFieldTypes = (obj: any, meta: MetaInformation, hidden_fields: string[], names: { [key: string]: string }): FieldTypes =>
    Object.keys(obj)
        .filter(key => !hidden_fields.some(field => field === key))
        .reduce<any>((acc, key) => {
            const type = getTypeOfField(obj, key, meta);
            if (type) {
                acc[key] = {
                    type,
                    name: names[key] || startCase(key),
                    items: ['mapenum', 'enum'].indexOf(type) > -1 ? enumToArray(meta._enums[key]) : [],
                };
            }
            return acc;
        }, {});

export const isCollection = (obj: any) => Array.isArray(obj) || isMap(obj);

export const checkIsCollection = (obj: any, path: string[]) => !(get(obj, path, obj) || {}).meta;

export const isDeserializable = (obj: any) => obj instanceof Deserializable;

export const isPrimitive = (obj: any) =>
    obj === null ||
    typeof obj === 'boolean' ||
    typeof obj === 'number' ||
    typeof obj === 'string' ||
    typeof obj === 'symbol' || // ES6 symbol
    typeof obj === 'undefined';

export const isObject = (obj: any) => typeof obj === 'object' && obj !== null;

export const isUndefined = (obj: any) => obj === void 0;

export const createFormGroup = (
    fb: FormBuilder,
    obj: any,
    validatorObject = obj,
    parseAsObject = false,
    validatorsArray: ValidatorFn[] = [],
    noValidated = false,
    hiddenFields: string[] = [...HIDDEN_FIELDS, ...Deserializable.METAFIELDS, 'meta']
) => {
    const group = fb.group(
        Object.keys(obj)
            .filter(key => !hiddenFields.some(h => h === key))
            .reduce<any>((acc, key) => {
                const value = obj[key];
                const validators = value !== undefined ? getValidatorsByField(validatorObject, key) : [];
                if (value !== undefined || obj instanceof DataFilter) {
                    if (isPrimitive(value) || value == null) {
                        acc[key] = [value, noValidated ? [] : validators];
                    } else if (!isDeserializable(value) && !Array.isArray(value) && key !== 'setup') {
                        const elValidators = getValidatorsCollectionElement(validatorObject, key);

                        acc[key] = fb.group(
                            Object.keys(value).reduce<any>((groupAcc, k) => {
                                const v = value[k];
                                groupAcc[k] =
                                    isObject(v) && !Array.isArray(v)
                                        ? createFormGroup(fb, v, validatorObject[key], true, elValidators, false, Deserializable.METAFIELDS)
                                        : [v];

                                return groupAcc;
                            }, {}),
                            { validators }
                        );
                    } else if (Array.isArray(value)) {
                        acc[key] = fb.array(
                            value.map(value =>
                                isObject(value) && !PrimitiveDataType.isPrimitiveDataType(value)
                                    ? createFormGroup(fb, value, value, false, [], false, Deserializable.METAFIELDS)
                                    : value
                            ),
                            validators
                        );
                    } else if (isObject(value)) {
                        if (key === 'setup') {
                            acc[key] = createSetupGroup(fb, value, validatorObject);
                        } else if (key == 'groovy') {
                            acc[key] = fb.group(
                                Object.keys(value).reduce<any>((groovyGroup, scriptKey) => {
                                    groovyGroup[scriptKey] = value[scriptKey];

                                    return groovyGroup;
                                }, {})
                            );
                        } else {
                            acc[key] = createFormGroup(fb, value, validatorObject[key], true, validators, false, Deserializable.METAFIELDS);
                        }
                    } else {
                        acc[key] = [value];
                    }
                }

                return acc;
            }, {}),
        { validators: !noValidated ? validatorsArray : [] }
    );

    if (obj instanceof DataFilter && DataFilter.IsInFilter(obj) && DataFilter.ValueIsRequired(obj)) {
        const valueControl = group.get('value');

        valueControl.setValidators(Validators.required);
    }

    if (obj instanceof UpdateAction) {
        group.setValidators(UpdateAction.ValidatePostUpdate);
        UpdateAction.ValidatePostUpdate(group);
        const postUpdateControl = group.get('postUpdate');
        if (postUpdateControl) {
            postUpdateControl.updateValueAndValidity();
        }
    }

    return group;
};

export const createSetupGroup = (fb: FormBuilder, obj: any, validatorObject: any) => {
    return fb.group(
        Object.keys(obj).reduce<any>((acc, key) => {
            const validators = getValidatorsByField(validatorObject, key);

            acc[key] = [
                obj[key],
                key === 'timeout' && obj.runningType === JobDefinitionRunningType.SCHEDULE ? [...validators, Min(1)] : validators,
            ];

            return acc;
        }, {})
    );
};

export function getTypingWarnings(typingObject: any, path: string[], isFirstLevel = false) {
    let errors: any[] = [];

    Object.keys(typingObject).forEach(key => {
        const field = typingObject[key];

        if (field instanceof Deserializable) {
            errors = [...errors, ...getTypingWarnings(field, [...path, key])];
        } else if (field instanceof Array) {
            field.forEach((el, i) => {
                errors = [...errors, ...(el instanceof Deserializable ? getTypingWarnings(el, [...path, key, i.toString()]) : [])];
            });
        } else if (isObject(field)) {
            Object.keys(field).forEach(mapKey => {
                errors = [
                    ...errors,
                    ...(field[mapKey] instanceof Deserializable ? getTypingWarnings(field[mapKey], [...path, key, mapKey]) : []),
                ];
            });
        }
    });

    const objectErrors = typingObject.typingErrors;

    if (objectErrors) {
        Object.keys(objectErrors).forEach(controlKey => {
            const isSetup = isFirstLevel && controlKey in typingObject.setup;
            const localPath = isSetup ? ['setup', controlKey] : [controlKey];
            const errorPath = [...path, ...localPath];

            const field = get(typingObject, localPath);

            errors.push({
                isPrimitive: isPrimitive(field) || field === null,
                controlName: controlKey,
                errorName: 'typingError',
                errorValue: objectErrors[controlKey],
                errorPath,
            });
        });
    }

    return errors;
}

export function findAllIssuesByPath(issueList: IssueElement[], path: any, type = 'ERROR') {
    return issueList.filter(issue => issue.type === type && isEqual(dropRight(issue.errorPath), path));
}

export const checkObjectIsEmpty = (obj: any): boolean =>
    Object.keys(obj).reduce((empty, key) => {
        if (Deserializable.METAFIELDS.includes(key)) {
            return empty;
        } else if (Array.isArray(obj[key])) {
            return empty && !obj[key].length;
        } else if (isObject(obj[key])) {
            return empty && isEmpty(obj[key]);
        } else if (obj[key] == null || (typeof obj[key] === 'string' && obj[key] == '') || (typeof obj[key] === 'boolean' && !obj[key])) {
            return empty;
        } else {
            return false;
        }
    }, true);

export function findLastDataSource(path: string[], jd: any): any {
    if (path.length === 0) {
        return null;
    }

    const field = get(jd, path);

    if (field instanceof JobDefinitionDataSource || field instanceof PostUpdate) {
        return field;
    } else {
        return findLastDataSource(dropRight(path), jd);
    }
}

export const normalizeValue = (obj: any, pattern: NormalizePattern, alwaysRemovedFields = [...Deserializable.METAFIELDS, 'meta']): any =>
    Object.keys(obj)
        .filter(key => {
            if (alwaysRemovedFields.some(f => f === key) || obj[key] == null) {
                return false;
            } else {
                if (Array.isArray(obj[key])) {
                    return pattern.array(obj[key]);
                } else if (isObject(obj[key])) {
                    return pattern.object(obj[key]);
                } else {
                    return (
                        (obj instanceof DataFilter && key === 'value') ||
                        (typeof obj[key] === 'string' && !Array.isArray(obj) && !obj.meta) ||
                        (pattern as Record<string, any>)[typeof obj[key]](obj[key])
                    );
                }
            }
        })
        .reduce<any>((newObj, key) => {
            if (Array.isArray(obj[key])) {
                return {
                    ...newObj,
                    [key]: obj[key]
                        .filter((v: any) => (!isObject(v) ? (pattern as Record<string, any>)[typeof v](v) : true))
                        .map((v: any) => (isObject(v) ? normalizeValue(v, pattern) : v)),
                };
            } else if (isObject(obj[key])) {
                return {
                    ...newObj,
                    [key]: normalizeValue(obj[key], pattern),
                };
            } else {
                return {
                    ...newObj,
                    [key]: obj[key],
                };
            }
        }, {});

export const validateForm = (form: FormGroup, fields: string[]): [FormGroup | FormArray, string[]] | null => {
    if (form.invalid) {
        for (let field of fields) {
            const control = form.get(field);
            if (control.invalid) {
                return checkGroupOrArrayValid(control as any, [field]);
            }
        }
    } else {
        return null;
    }
};

const checkGroupOrArrayValid = (control: FormGroup | FormArray, path: string[]) => {
    let key: any;
    let childInvalid;

    if (control instanceof FormGroup) {
        key = Object.keys(control.controls).find(key => control.controls[key].invalid);
    } else {
        key = control.controls.findIndex(control => control.invalid);
    }

    if (![undefined, -1].includes(key)) {
        childInvalid = (control.controls as Record<string, any>)[key];
    }

    if (!!childInvalid) {
        if (childInvalid instanceof FormControl) {
            return [control, path];
        } else {
            return validate(
                childInvalid instanceof FormGroup
                    ? childInvalid.controls
                    : childInvalid.controls.reduce((acc: any, v: any, i: any) => {
                          acc[i] = v;
                          return acc;
                      }, {}),
                [...path, key] as any,
                childInvalid
            );
        }
    } else {
        return [control, path];
    }
};

const validate = (controls: { [key: string]: AbstractControl }, path: string[], parent?: AbstractControl): any => {
    const invalidKey = Object.keys(controls).find(key => controls[key].invalid);
    const invalidControl = controls[invalidKey];
    if (invalidControl instanceof FormControl) {
        return [invalidControl.parent, path];
    } else {
        if (invalidControl instanceof FormArray) {
            return checkGroupOrArrayValid(invalidControl, [...path, invalidKey]);
        } else if (invalidControl instanceof FormGroup) {
            return checkGroupOrArrayValid(invalidControl, [...path, invalidKey]);
        } else {
            return [parent, path];
        }
    }
};

export function getParamType(target: any, key: string): any {
    return Reflect.getMetadata('design:type', target, key);
}
