import { FormControl, FormGroup } from '@angular/forms';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DropdownItem } from '@dagility-ui/kit';

import { DataMorph } from 'data-processor';
import { DashboardFormExtension } from 'data-processor/tokens';

@Component({
    template: `
        <ng-container [formGroup]="form">
            <lib-dropdown
                data-test-id="visualisation-dropdown"
                class="mb-4 d-block"
                label="Tab Visualisation"
                placeholder="Select Tab Visualisation"
                formControlName="visibility"
                [items]="visibilities"
            ></lib-dropdown>
        </ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditDashboardOptionsComponent implements DashboardFormExtension {
    controlName = 'dashboardOptions';
    form: FormGroup = new FormGroup({
        visibility: new FormControl(null),
    });

    visibilities: Array<DropdownItem<DataMorph.DashboardTabVisibility>> = [
        {
            label: 'Show all',
            value: DataMorph.DashboardTabVisibility.SHOW_ALL,
        },
        {
            label: 'Show active',
            value: DataMorph.DashboardTabVisibility.SHOW_ACTIVE,
        },
        {
            label: 'Hide all',
            value: DataMorph.DashboardTabVisibility.HIDE_ALL,
        },
    ];

    setDashboard(dashboard: DataMorph.Dashboard) {
        this.form.patchValue({
            visibility: dashboard.dashboardOptions?.visibility || null,
        });
    }

    toSave(): Record<string, unknown> {
        return this.form.value;
    }
}
