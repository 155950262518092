import { Inject, Injectable } from '@angular/core';
import { ENV_TOKEN } from '@app/tokens';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class EventAlertSettingsService {
    baseUrl = `${this.env.notificationsApiURL}/event-alert-settings`;

    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {}

    getEvents() {
        return this.http.get<any>(`${this.baseUrl}/events-meta`);
    }

    getEventConditions(code: string) {
        return this.http.get<any>(`${this.baseUrl}/${code}/conditions`);
    }

    getConditionOperators(code: string, type: string) {
        return this.http.get<any>(`${this.baseUrl}/${code}/conditions/${type}/operators`);
    }

    getConditionValues(code: string, type: string, settings: any) {
        return this.http.post<any>(`${this.baseUrl}/${code}/conditions/${type}/value`, settings);
    }

    getConditionValueType(code: string, type: string) {
        return this.http.get<any>(`${this.baseUrl}/${code}/conditions/${type}/value/type`);
    }
}
