import { inject, Inject, Injectable, Injector, Optional } from '@angular/core';
import { EMPTY, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { pick } from 'lodash';

import { ModalConfirmComponent, ModalService, ModalWarningComponent } from '@dagility-ui/kit';
import { DASHBOARD_FORM_EXTENSION, DATA_MORPH_FEATURE_TOGGLE, DataMorphFeatureToggleService } from 'data-processor/tokens';
import { ToasterService } from '@dagility-ui/shared-components';

import { DataMorph } from '../../dashboard/models/dp-dashboard.model';
import { WidgetPreview } from '../../widget-library.page';
import { DpDashboardService } from '../services/dp-dashboard.service';
import { CreateTabComponent } from '../components/create-tab/create-tab.component';
import { DpEditTabComponent, EditedTabOptions } from '../components/dp-edit-tab/dp-edit-tab.component';
import { DashboardWidgetSettingsManager } from '../services/dashboard-widget-settings.manager';
import { DpCreateDashboardComponent } from '../components/create-dashboard/dp-create-dashboard.component';
import { EditDashboardOptionsComponent } from '../components/edit-dashboard-new/edit-dashboard-options.component';
import { GridsterOptionsService, WIDGET_SIZE_DELIMITER } from '../services/gridster/gridster-options.service';
import { DpDashboardStore } from './dp-dashboard.store';
import { DashboardGroupState } from './dp-dashboard.state.model';

// TODO: move middle layer behaviour from components to facade
@Injectable()
export class DpDashboardFacade {
    constructor(
        private api: DpDashboardService,
        private dashboardWidgetManager: DashboardWidgetSettingsManager,
        @Optional() private store: DpDashboardStore,
        private modal: ModalService,
        @Inject(DATA_MORPH_FEATURE_TOGGLE) private featureToggle: DataMorphFeatureToggleService,
        private toaster: ToasterService,
        private injector: Injector,
        private gridsterOptions: GridsterOptionsService
    ) {}

    editDashboard() {
        const illuminateOptions = pick(this.store.value.options, 'template', 'status', 'userId', 'module');

        return this.openEditDashboardModal({
            saveFunction: ({ options, ...dashboard }) => {
                const newOptions = {
                    ...options,
                    name: dashboard.name,
                    ...illuminateOptions,
                };

                return this.dashboardWidgetManager
                    .saveDashboard({
                        options: newOptions,
                        id: this.store.value.id,
                        dashboardId: this.store.value.dashboardId,
                    })
                    .pipe(
                        switchMap(illuminateDashboard =>
                            this.api
                                .updateDashboard({
                                    ...dashboard,
                                    ...illuminateOptions,
                                    scope: options.scope,
                                    tabs: [],
                                    options: [],
                                })
                                .pipe(
                                    tap(updatedDashboard => {
                                        if (updatedDashboard) {
                                            dashboard.name = updatedDashboard.name;
                                        }
                                    })
                                )
                        )
                    )
                    .pipe(
                        map(() => ({
                            ...dashboard,
                            ...illuminateOptions,
                            options: newOptions,
                        }))
                    );
            },
        }).pipe(
            switchMap(result => (result ? of(result) : EMPTY)),
            tap(updatedDashboard => {
                this.store.updateDashboard(updatedDashboard);
                this.toaster.successToast({
                    title: 'Success',
                    content: 'Dashboard updated successfully',
                });
            })
        );
    }

    deleteGroup({ name, id, tabId }: DashboardGroupState) {
        this.deleteEntity({
            isLast: this.isLastGroupInTab(tabId.toString()),
            entityName: 'Group',
            entityInstanceName: name,
            apiDelete: () => this.api.deleteGroup(id),
            storeDelete: () => this.store.deleteGroup(tabId, id),
        });
    }

    deleteTab(tabId: number) {
        const { activeTab, tabsMap } = this.store.value;
        const { name } = tabsMap[tabId];

        this.deleteEntity({
            isLast: this.singleTab(),
            entityName: 'Tab',
            entityInstanceName: name,
            apiDelete: () => this.api.deleteTab(tabId),
            storeDelete: () => this.store.deleteTab(tabId),
        });
    }

    deleteWidget({ id, data, dashboardWidgetId }: DataMorph.Widget) {
        this.deleteEntity({
            isLast: false,
            entityName: 'Widget',
            entityInstanceName: data.chartOptions.title,
            apiDelete: () =>
                this.api.deleteWidgetFromDashboard(dashboardWidgetId).pipe(switchMap(() => this.dashboardWidgetManager.deleteWidget(id))),
            storeDelete: () => this.store.deleteWidget(id),
        });
    }

    async editTab(tabId: number) {
        const tab = this.store.value.tabsMap[tabId];
        let editedTab: EditedTabOptions;

        try {
            editedTab = await this.openEditTabModal({
                tab,
            });
        } catch {
            return;
        }

        await this.updateTab({
            ...tab,
            ...editedTab,
        });

        this.toaster.successToast({
            title: 'Success',
            content: 'Tab updated successfully',
        });
    }

    addTab() {
        return from(
            this.modal.open(
                CreateTabComponent,
                {
                    injector: this.injector,
                },
                {}
            ).result
        ).pipe(
            catchError(() => EMPTY),
            switchMap(({ tab, group }) =>
                this.saveDashboardTab(tab).pipe(
                    switchMap(savedTab =>
                        this.api
                            .saveGroup({
                                ...group,
                                id: null,
                                dashboardId: this.store.value.dashboardId,
                                tabId: savedTab.id,
                            })
                            .pipe(
                                tap(savedGroup => {
                                    this.store.addTab(savedTab);
                                    this.store.addGroup(savedGroup);
                                })
                            )
                    )
                )
            ),
            tap(() =>
                this.toaster.successToast({
                    title: 'Success',
                    content: 'Tab created successfully',
                })
            )
        );
    }

    // TODO: fix type
    createWidget(
        dashboardId: number,
        groupId: number,
        widget: Pick<WidgetPreview, 'data' | 'id'>
    ): Observable<DataMorph.IlluminateDashboardWidget> {
        const { w, h } = this.gridsterOptions.getDefaultSizes(widget.data);

        return of({
            dashboardId,
            data: widget.data,
            options: {
                size: `${w}${WIDGET_SIZE_DELIMITER}${h}`,
            },
            groupId,
            id: widget.id,
            illuminateOptions: {},
        } as any);
    }

    openEditDashboardModal(
        params?: Partial<{ dashboard: DataMorph.Dashboard; headerText: string; saveFunction: DpCreateDashboardComponent['saveFunction'] }>
    ): Observable<DataMorph.Dashboard> {
        const dashboard = params?.dashboard ?? this.store.value;
        const headerText = params?.headerText ?? 'Dashboard Settings';
        const saveFunction = params?.saveFunction ?? undefined;

        return from(
            this.modal.open(
                DpCreateDashboardComponent,
                {
                    injector: Injector.create({
                        parent: this.injector,
                        providers: [
                            {
                                provide: DASHBOARD_FORM_EXTENSION,
                                useFactory: () =>
                                    [
                                        EditDashboardOptionsComponent,
                                        inject(DASHBOARD_FORM_EXTENSION, { skipSelf: true, optional: true }),
                                    ].filter(Boolean),
                            },
                        ],
                    }),
                },
                {
                    dashboard,
                    headerText,
                    saveFunction,
                }
            ).result
        ).pipe(catchError(() => EMPTY));
    }

    private async updateTab(tab: Omit<DataMorph.DashboardTab, 'groups'>) {
        const newTab = {
            ...tab,
            groups: this.store.getGroupsByTab(tab.id),
        };

        await this.api.updateDashboardTab(newTab).toPromise();
        this.store.updateTab(newTab);
    }

    private saveDashboardTab(tab: EditedTabOptions) {
        return this.api.saveDashboardTab({
            ...tab,
            groups: [],
            id: null,
            dashboardId: this.store.value.dashboardId,
        });
    }

    private openEditTabModal(options: Partial<DpEditTabComponent>): Promise<EditedTabOptions> {
        return this.modal.open(
            DpEditTabComponent,
            {
                injector: this.injector,
            },
            {
                ...options,
                tabVisibility: this.store.value.dashboardOptions?.visibility,
            }
        ).result;
    }

    private deleteEntity(params: {
        isLast: boolean;
        entityName: string;
        entityInstanceName: string;
        apiDelete: () => Observable<unknown>;
        storeDelete: () => void;
        featureToggleName?: string;
    }) {
        const featureToggle$ = params.featureToggleName
            ? this.featureToggle.isActive(params.featureToggleName).pipe(catchError(() => of(false)))
            : of(true);

        featureToggle$
            .pipe(
                switchMap(isActive => {
                    if (isActive && params.isLast) {
                        this.showPreventToDeleteModal();

                        return of(null);
                    }

                    return this.showConfirmToDeleteModal(`${params.entityInstanceName} ${params.entityName.toLowerCase()}`).pipe(
                        switchMap(result => (result ? params.apiDelete().pipe(tap(params.storeDelete)) : of(null)))
                    );
                })
            )
            .subscribe();
    }

    private showPreventToDeleteModal() {
        this.modal.open(
            ModalWarningComponent,
            { centered: true, windowClass: 'restrict-delete-dialog' },
            {
                message: {
                    title: 'You cannot delete the last tab or last group on a Dashboard.',
                    content:
                        'Dashboards require one tab with one group in order to add widgets;' +
                        ' however, you can rename or hide the group or tab.',
                    icon: 'warning',
                    iconFontSize: '20px',
                    iconColor: 'var(--da-warning-base)',
                },
                confirmButtonText: 'Ok',
                showCancelButton: false,
            }
        );
    }

    private showConfirmToDeleteModal(entityName: string): Observable<boolean> {
        return from(
            this.modal
                .open(
                    ModalConfirmComponent,
                    { centered: true },
                    {
                        message: {
                            title: 'Delete',
                            content: `Are you sure you want to delete ${entityName}?`,
                        },
                    }
                )
                .result.then(() => true)
                .catch(() => false)
        );
    }

    private isLastGroupInTab(tabId: string) {
        const { tabsMap } = this.store.value;

        return tabsMap[tabId as any].groups.length <= 1;
    }

    private singleTab() {
        return Object.keys(this.store.value.tabsMap).length === 1;
    }
}
