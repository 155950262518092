export enum Operation {
    AND = 'AND',
    OR = 'OR',
    NOT = 'NOT',
    EQUALS = 'EQUALS',
    NOT_EQUALS = 'NOT_EQUALS',
    GREATER = 'GREATER',
    GREATER_OR_EQUAL = 'GREATER_OR_EQUAL',
    LESS = 'LESS',
    LESS_OR_EQUAL = 'LESS_OR_EQUAL',
    IS_NULL = 'IS_NULL',
}
