<div *ngFor="let variable of debugVariables" class="variable variable--{{ variable.type }}">
    <div class="d-inline-flex variable--header align-items-center">
        <fa-icon
            *ngIf="variable.expandable"
            class="mr-2 expand-icon"
            [icon]="expandedControl.isSelected(variable.name) ? icons.faChevronDown : icons.faChevronRight"
            (click)="expandedControl.toggle(variable.name)"
        ></fa-icon>
        <dp-debug-new-watch
            class="col-4 px-0"
            *ngIf="variable.editable && ignoreHelpFields"
            edit="true"
            [expression]="variable.name"
            (editExpression)="changeExpression.emit($event)"
        ></dp-debug-new-watch>
        <span class="variable--name" *ngIf="!variable.editable" (dblclick)="!disabled && (variable.editable = true)">{{
            variable.name
        }}</span
        ><span class="mx-2">=</span>
        <span class="variable--value text-truncate" [class.invalid]="ignoreHelpFields && variable.invalid" [title]="variable.description">{{
            variable.description
        }}</span>
        <fa-icon
            *ngIf="!disabled"
            class="ml-auto cursor-pointer font-12px delete-button"
            icon="facDelete"
            ngbTooltip="Delete watch expression"
            container="body"
            (click)="delete.emit()"
        ></fa-icon>
    </div>
    <div *ngIf="variable.expandable && expandedControl.isSelected(variable.name)" class="variable--child ml-3" >
        <dp-debug-variables [variables]="variable.value"> </dp-debug-variables>
    </div>
</div>
