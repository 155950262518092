import { Observable, of } from 'rxjs';
import { InjectionToken } from '@angular/core';

export class HealthServiceStatus {
    getIsServiceAlive(entity: any): Observable<boolean> {
        return of(false);
    }
}

export function healthFactory() {
    return new HealthServiceStatus();
}

export const SERVICE_HEALTH_STATUS = new InjectionToken('Service health status', {
    factory: healthFactory,
});
