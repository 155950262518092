import { ElementRef, QueryList } from '@angular/core';
import { DatePipe } from '@angular/common';

import { DropdownItem, isDefined } from '@dagility-ui/kit';
import { ProgressItem, TileChartDataModel } from '@dagility-ui/shared-components';

import { AnyWidgetComponent } from '../../components/widget/any-widget/any-widget.component';
import { WidgetType } from '../../models/any-widget.model';
import { ExcelExportDto, WidgetBuilderService } from '../widget-builder.service';
import { AnyWidgetChartModel, isTimeSeriesChartModel } from '../query-to-options.mapper';
import {
    BOXPLOT_CATEGORIES,
    convertToGridData,
    convertToTwoDimensionalGridData,
    copyContentFromCanvas,
    getOutliersMap,
} from './widget.exporter.util';

export class ExcelExporter {
    protected IGNORED_ELEMENTS = 'widget-filter';

    constructor(
        protected readonly data: AnyWidgetChartModel,
        protected readonly title: string,
        protected readonly elementRef: ElementRef<HTMLElement>,
        protected readonly complexWidgets: QueryList<AnyWidgetComponent>,
        protected readonly widgetBuilderService: WidgetBuilderService
    ) {}

    async export(id: any, widgetType: WidgetType, filterComponents: any): Promise<Blob> {
        const exportData: ExcelExportDto[] = [];
        if (widgetType === WidgetType.PIE_CHART) {
            exportData.push(this.exportPieChart(this.data));
        } else if ([WidgetType.BAR_CHART, WidgetType.STACKED_BAR_CHART, 'horizontalstackedbarchart'].includes(widgetType)) {
            exportData.push(this.exportBarChart(this.data));
        } else if (widgetType === WidgetType.LINE_CHART) {
            exportData.push(this.exportLineChart(this.data));
        } else if (widgetType === WidgetType.DOUGHNUT_CHART) {
            exportData.push(this.exportDoughnutChart(this.data, this.elementRef));
        } else if ([WidgetType.NESTED_PIE_CHART, WidgetType.SUNBURST_CHART, WidgetType.TREEMAP].includes(widgetType)) {
            exportData.push(await this.exportNestedChart(this.data, this.elementRef));
        } else if ([WidgetType.TABLE, WidgetType.TWO_DIMENSIONAL_TABLE].includes(widgetType)) {
            exportData.push(await this.exportGridChart(this.data, widgetType));
        } else if ([WidgetType.TABLE_WITH_TABS, WidgetType.TWO_DIMENSIONAL_TABLE].includes(widgetType)) {
            exportData.push(await this.exportGridTabChart(this.data, widgetType));
        } else if (widgetType === WidgetType.COMPLEX) {
            for (const anyWidget of this.complexWidgets) {
                let exportDataObject;
                const { type } = anyWidget.chartModel.options;

                if (type === WidgetType.PIE_CHART) {
                    exportDataObject = this.exportPieChart(anyWidget.chartModel);
                } else if ([WidgetType.BAR_CHART, WidgetType.STACKED_BAR_CHART, 'horizontalstackedbarchart'].includes(type)) {
                    exportDataObject = this.exportBarChart(anyWidget.chartModel);
                } else if (type === WidgetType.LINE_CHART) {
                    exportDataObject = this.exportLineChart(anyWidget.chartModel);
                } else if (type === WidgetType.DOUGHNUT_CHART) {
                    exportDataObject = await this.exportDoughnutChart(anyWidget.chartModel, anyWidget.elementRef);
                } else if ([WidgetType.NESTED_PIE_CHART, WidgetType.SUNBURST_CHART, WidgetType.TREEMAP].includes(type)) {
                    exportDataObject = await this.exportNestedChart(anyWidget.chartModel, anyWidget.elementRef);
                } else if ([WidgetType.TABLE, WidgetType.TWO_DIMENSIONAL_TABLE].includes(type)) {
                    exportDataObject = this.exportGridChart(anyWidget.chartModel, type);
                } else if (type === WidgetType.BOXPLOT) {
                    const [data] = await new BoxplotExcel(
                        anyWidget.chartModel,
                        anyWidget.chartModel.options.title.text,
                        anyWidget.elementRef,
                        null,
                        this.widgetBuilderService
                    ).getExportData();

                    exportDataObject = data;
                } else if (type === WidgetType.TILE_CHART) {
                    const [data] = await new TileExcel(
                        anyWidget.chartModel,
                        anyWidget.chartModel.options.title.text,
                        anyWidget.elementRef,
                        null,
                        this.widgetBuilderService
                    ).getExportData();

                    exportDataObject = data;
                } else if (type === WidgetType.PROGRESS) {
                    const [data] = await new ProgressExcel(
                        anyWidget.chartModel,
                        anyWidget.chartModel.options.title.text,
                        anyWidget.elementRef,
                        null,
                        this.widgetBuilderService
                    ).getExportData();

                    exportDataObject = data;
                }

                exportData.push(exportDataObject);
            }
        }

        return await this.widgetBuilderService.getExportExcelWidget(exportData).toPromise();
    }

    exportGridChart(chartModel: AnyWidgetChartModel, widgetType: WidgetType): ExcelExportDto {
        const widgetData =
            widgetType === WidgetType.TWO_DIMENSIONAL_TABLE ? convertToTwoDimensionalGridData(chartModel) : convertToGridData(chartModel);
        const series = [];
        for (let i = 0; i < widgetData.length; i++) {
            const children = [];
            for (let j = 0; j < widgetData[i].length; j++) {
                children.push({ gridValue: widgetData[i][j] ?? '' });
            }
            series.push({ children });
        }

        return { name: this.title, seriesList: series, type: 'GRID' };
    }

    exportGridTabChart(chartModel: AnyWidgetChartModel, widgetType: WidgetType): ExcelExportDto {
        const tableData: { columnDefs: any; pinnedBottomRowData: any; rowData: any } = {
            columnDefs: [],
            pinnedBottomRowData: [],
            rowData: [],
        };
        chartModel.options.items.forEach((element: any) => {
            tableData.columnDefs = [...element.table.tableData.columnDefs];
            tableData.pinnedBottomRowData = [...tableData.pinnedBottomRowData, ...element.table.tableData.pinnedBottomRowData];
            tableData.rowData = [...tableData.rowData, ...element.table.tableData.rowData];
        });
        chartModel.options.tableData = tableData;
        console.log(chartModel, 'chartModel', tableData);
        const widgetData =
            widgetType === WidgetType.TWO_DIMENSIONAL_TABLE ? convertToTwoDimensionalGridData(chartModel) : convertToGridData(chartModel);
        const series = [];
        for (let i = 0; i < widgetData.length; i++) {
            const children = [];
            for (let j = 0; j < widgetData[i].length; j++) {
                children.push({ gridValue: widgetData[i][j] ?? '' });
            }
            series.push({ children });
        }

        return { name: this.title, seriesList: series, type: 'GRID' };
    }

    exportPieChart(chartModel: AnyWidgetChartModel): ExcelExportDto {
        const widgetData = chartModel.options.series[0].data.map((element: any) => {
            return {
                name: element.name,
                value: [element.value],
            };
        });

        return { name: this.title, seriesList: widgetData, type: 'PIE' };
    }

    exportBarChart(chartModel: AnyWidgetChartModel): ExcelExportDto {
        const widgetData = chartModel.options.series.map((element: any, i: number) => {
            return {
                name: element.name,
                value: this.getSeriesValue(element.data),
                color: chartModel.options.colors[i],
            };
        });

        return {
            name: this.title,
            seriesList: widgetData,
            categories: chartModel.options.categories,
            type: 'BAR',
            horizontal: chartModel.options.type.includes('horizontal'),
            stacked: chartModel.options.stacked,
        };
    }

    getSeriesValue(data: any[]) {
        const valueArray = [];
        for (const element of data) {
            if (typeof element === 'number') {
                valueArray.push(element);
            } else {
                valueArray.push(element?.value);
            }
        }
        return valueArray;
    }

    exportLineChart(chartModel: AnyWidgetChartModel): ExcelExportDto {
        let widgetData: any[];
        let categories: any[] = [];

        if (isTimeSeriesChartModel(chartModel)) {
            const formatter = new DatePipe('en-US');
            const dates = new Set<number>();
            const dataBySeries: Array<Record<number, number>> = [];

            chartModel.options.series.forEach((element: any, idx: number) => {
                dataBySeries.push({});

                element.data.forEach((item: any) => {
                    if (!isDefined(item)) {
                        return;
                    }

                    const [timestamp, value] = item as [number, number];

                    dates.add(timestamp);
                    dataBySeries[idx][timestamp] = value;
                });
            });
            categories = Array.from(dates).sort();

            widgetData = dataBySeries.map((data, idx) => ({
                value: categories.map(category => (isDefined(data[category]) ? data[category] : null)),
                name: chartModel.options.series[idx].name,
            }));
            categories = categories.map(category => formatter.transform(category, 'MMM-dd h:mm:ss a'));
        } else {
            widgetData = chartModel.options.series.map((element: any) => ({
                name: element.name,
                value: element.data,
            }));
            categories = chartModel.options.categories;
        }

        return { name: this.title, seriesList: widgetData, categories, type: 'LINE' };
    }

    exportDoughnutChart(chartModel: AnyWidgetChartModel, elementRef: ElementRef<HTMLElement>): ExcelExportDto {
        const widgetData = chartModel.options.series[0].data.map((element: any) => {
            return {
                name: element.name,
                value: [element.value],
                color: element.itemStyle?.color,
            };
        });

        return {
            name: this.title,
            seriesList: widgetData,
            categories: chartModel.options.categories,
            type: 'DOUGHNUT',
            total: true,
        };
    }

    async exportNestedChart(chartModel: AnyWidgetChartModel, elementRef: ElementRef<HTMLElement>): Promise<ExcelExportDto> {
        const newCanvas = copyContentFromCanvas(elementRef.nativeElement.getElementsByTagName('canvas').item(0) as HTMLCanvasElement);
        const html2canvas = (await import('html2canvas')).default;
        const canvas = await html2canvas(newCanvas, {
            ignoreElements: element => element.classList.contains(this.IGNORED_ELEMENTS),
        });
        const image = canvas.toDataURL('image/jpeg');
        this.modifyNestedData(chartModel.options.series.data);
        document.body.removeChild(newCanvas);
        return {
            name: this.title,
            seriesList: chartModel.options.series.data,
            type: 'NESTED',
            image: image,
        };
    }

    modifyNestedData(data: any) {
        for (const dataUnit of data) {
            if (dataUnit.children.length) {
                this.modifyNestedData(dataUnit.children);
            }
            dataUnit.value = [dataUnit.value];
        }
    }
}

abstract class CustomHTMLChartExcel extends ExcelExporter {
    abstract get htmlElement(): HTMLElement;

    abstract getExcelData(): Omit<ExcelExportDto, 'image' | 'name'>;

    async getExportData(): Promise<ExcelExportDto[]> {
        const html2canvas = (await import('html2canvas')).default;
        const canvas = await html2canvas(this.htmlElement, {
            ignoreElements: element => element.classList.contains(this.IGNORED_ELEMENTS),
        });
        const image = canvas.toDataURL('image/jpeg');
        const exportData = this.getExcelData();

        return [{ ...exportData, image, name: this.title }];
    }

    async export(id: any, widgetType: WidgetType, filterComponents: any): Promise<Blob> {
        return await this.widgetBuilderService.getExportExcelWidget(await this.getExportData()).toPromise();
    }
}

export class BoxplotExcel extends CustomHTMLChartExcel {
    getExcelData(): Omit<ExcelExportDto, 'image' | 'name'> {
        const points: number[][] = this.data.options.series[0].data;
        const { rawData } = this.data.options;
        const categories: string[] = this.data.options.categories;
        const outliersMap = getOutliersMap(this.data);

        return {
            type: 'BOXPLOT',
            categories: BOXPLOT_CATEGORIES,
            seriesList: categories.reduce((acc, category, index) => {
                acc.push({
                    name: category,
                    value: rawData[index],
                    children: [
                        {
                            value: [...points[index], ...(outliersMap[index] ?? [])],
                        },
                    ],
                });

                return acc;
            }, []),
        };
    }

    get htmlElement(): HTMLElement {
        return this.elementRef.nativeElement.getElementsByTagName('lib-chart').item(0) as HTMLElement;
    }
}

export class TileExcel extends CustomHTMLChartExcel {
    getExcelData(): Omit<ExcelExportDto, 'image' | 'name'> {
        const tileChartData = (this.data.options.result as TileChartDataModel).tileData;

        return {
            type: 'TILE',
            seriesMap: tileChartData.headers.reduce<ExcelExportDto['seriesMap']>((acc, header, index) => {
                const value = tileChartData.value[index];
                acc[header] = [
                    {
                        name: tileChartData.rating[index],
                    },
                ];
                if (value) {
                    acc[header][0].gridValue = value;
                }

                return acc;
            }, {}),
        };
    }

    get htmlElement(): HTMLElement {
        return this.elementRef.nativeElement.getElementsByTagName('lib-tile-chart').item(0) as HTMLElement;
    }
}

export class ProgressExcel extends CustomHTMLChartExcel {
    getExcelData(): Omit<ExcelExportDto, 'image' | 'name'> {
        const progressData = this.data.options.progress as ProgressItem[];
        const labels = this.data.options.labels as DropdownItem[];

        return {
            type: 'PROGRESS',
            additionalInfo: '',
            seriesMap: {
                progress: progressData.map(({ label, value }) => ({
                    name: label,
                    value: [value],
                })),
                labels: (labels || []).map(({ label, value }) => ({
                    name: label,
                    value: [value as any],
                })),
            },
        };
    }

    get htmlElement(): HTMLElement {
        return this.elementRef.nativeElement.getElementsByTagName('lib-progress-chart').item(0) as HTMLElement;
    }
}
