import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter, Attribute } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { faChevronDown, faChevronRight, IconDefinition } from '@fortawesome/free-solid-svg-icons';

import { CustomIcon } from '@dagility-ui/shared-components/icons';

interface DebugVariable {
    name: string;
    type: string;
    expandable: boolean;
    value: any;
    description: string;
    editable: boolean;
    invalid: boolean;
}

const TYPE_FIELD = 'type';

@Component({
    selector: 'dp-debug-variables',
    templateUrl: './debug-variables.component.html',
    styleUrls: ['./debug-variables.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugVariablesComponent {
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    @Input() set variables(_variables: Record<string, any>) {
        this.expandedControl.clear();
        const debugVariables = Object.keys(_variables).reduce<DebugVariable[]>((acc, key) => {
            if (!!this.ignoreHelpFields && ['isNew', 'expression', 'result', 'editable', 'invalid'].includes(key as any)) {
                return acc;
            }

            const type = typeof _variables[key];

            const variable: DebugVariable = {
                description: JSON.stringify(_variables[key]),
                expandable: false,
                name: key,
                type,
                value: _variables[key],
                editable: !!_variables.editable,
                invalid: !!_variables.invalid,
            };
            if (type === 'string') {
                variable.description = `"${variable.value}"`;
            } else if (type === 'object') {
                if (variable.value === null) {
                    variable.type = 'null';
                    variable.description = 'null';
                } else if (Array.isArray(variable.value)) {
                    variable.expandable = !!variable.value.length;
                    variable.description = `Array [${variable.value.length}] ${JSON.stringify(variable.value)}`;
                    variable.type = 'array';
                } else if (variable.value instanceof Date) {
                    variable.type = 'date';
                } else {
                    variable.type = 'object';
                    variable.description = `${JSON.stringify(variable.value)}`;
                    variable.expandable = true;
                }
            }

            acc.push(variable);

            return acc;
        }, []);

        this.debugVariables = !!this.sortType
            ? [...debugVariables].sort((a, b) => (a.name === TYPE_FIELD ? -1 : a.name.localeCompare(b.name)))
            : debugVariables;
    }

    @Input() disabled = true;

    @Output() changeExpression = new EventEmitter<string>();

    @Output() delete = new EventEmitter();

    icons: Record<string, IconDefinition | CustomIcon> = {
        faChevronDown,
        faChevronRight,
    };
    expandedControl = new SelectionModel(true);

    debugVariables: DebugVariable[] = [];

    constructor(@Attribute('ignoreHelpFields') public ignoreHelpFields: string, @Attribute('sort') public sortType: string) {}
}
