import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { ToasterService } from '@dagility-ui/shared-components';
import { ModalConfirmComponent, ModalService } from '@dagility-ui/kit';

import { AnyWidgetModel, WidgetType } from './widget-builder/models/any-widget.model';
import { AnyWidget, WidgetBuilderService } from './widget-builder/services/widget-builder.service';
import { WidgetLibraryStore } from './widget-library.store';
import { ADD_INPUT_PLACEHOLDERS_PROVIDER } from './widget-builder/providers/add-input-placeholders.provider';
import { WIDGET_PREVIEW_MODE } from './widget-builder/providers/widget-preview.token';

@Component({
    selector: 'dp-widget-library.flex-1.d-flex',
    templateUrl: './widget-library.page.html',
    styleUrls: ['./widget-library.page.scss'],
    providers: [
        WidgetLibraryStore,
        ADD_INPUT_PLACEHOLDERS_PROVIDER,
        {
            provide: WIDGET_PREVIEW_MODE,
            useValue: true,
        },
    ],
})
export class WidgetLibraryComponent {
    selectedWidget$: Observable<AnyWidget> = null;
    activeWidgetId: number | null = null;
    clonedId: number = null;

    constructor(
        private api: WidgetBuilderService,
        private store: WidgetLibraryStore,
        private toaster: ToasterService,
        public router: Router,
        private modalService: ModalService
    ) {
        if (this.router.getCurrentNavigation()?.extras?.state) {
            const { clonedId } = this.router.getCurrentNavigation().extras.state;

            this.clonedId = parseInt(clonedId, 10) ?? null;
        }
    }

    handleWidgetSelect(widget: WidgetPreview) {
        if (widget) {
            this.activeWidgetId = widget.id;
            this.selectedWidget$ = this.api.getWidgetFromLibrary(widget.id);
        } else {
            this.activeWidgetId = null;
            this.selectedWidget$ = null;
        }
    }

    typeGuard(obj: any): AnyWidget {
        return obj;
    }

    handleDeleteWidget(widget: WidgetPreview) {
        this.modalService
            .open(
                ModalConfirmComponent,
                {
                    centered: true,
                    backdrop: 'static',
                    windowClass: 'delete-widget-confirm-modal',
                },
                {
                    message: {
                        title: 'Delete widget?',
                        content: `Are you sure you want to delete ${widget.title}?`,
                        warningMessage: `All information associated to this widget will be permanently deleted.`,
                    },
                    closeButtonText: 'CANCEL',
                    confirmButtonText: 'PROCEED',
                }
            )
            .result.then(() => {
                this.api.deleteWidget(widget.id).subscribe(() => {
                    this.store.deleteWidget(widget.id);

                    this.toaster.successToast({
                        title: 'Success',
                        content: `You have successfully deleted ${widget.title} widget.`,
                    });

                    if (widget.id === this.activeWidgetId) {
                        this.handleWidgetSelect(null);
                    }

                    if (widget.id === this.clonedId) {
                        this.clonedId = null;
                    }
                });
            })
            .catch(() => {
                /* rejected */
            });
    }
}

export interface WidgetPreview {
    id: number;
    type: WidgetType;
    title: string;
    data?: AnyWidgetModel;
    description: string;
    system?: boolean;
}
