import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'dp-widget-builder-help-template-preview',
    templateUrl: './widget-builder-help-template-preview.component.html',
    styleUrls: ['./widget-builder-help-template-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetBuilderHelpTemplatePreviewComponent {
    @Input() set template(template: string) {
        if (template) {
            this.isShowVisible = true;
            this.templateString = template;
        } else {
            this.isShowVisible = false;
            this.templateString = '-';
        }
    }

    isShowVisible: boolean = true;
    templateString: string;
    show: boolean = true;
}
