<svg:foreignObject
    *ngIf="!(block.isStartBlock || block.isEndBlock)"
    [attr.x]="block.x"
    [attr.y]="block.y"
    [attr.width]="block.width"
    [attr.height]="block.height"
    overflow="visible"
>
    <xhtml:div
        class="block block--{{ block.type | lowercase }} d-flex align-items-center"
        [attr.data-zone]="block.isContainer && !debugMode ? 'drop' : ''"
        [attr.data-name]="block.action.name"
        [class.block--selected]="block === activeBlock"
        [class.block--debugged]="block.action.name === debuggedBlockName"
        [class.block--invalid]="block.invalid"
        (click)="activeBlockChange.emit(activeBlock && activeBlock == block ? null :block)"
    >
        <div class="block-type" *ngIf="block.isContainer">
            <span>{{ block.action.type | titlecase }}</span>
            <span class="ml-2">{{ block.action.name }}</span>
        </div>
        <ng-container *ngIf="!block.isContainer">
            <span class="block-type">{{ block.action.type | titlecase }}</span>
            <div
                [ngbTooltip]="block.action.name"
                triggers="manual"
                libTooltipWhenOverflow
                position="top"
                class="text-truncate block--name"
                >{{ block.action.name }}</div
            >
        </ng-container>
        <ng-container *ngIf="!debugMode">
            <fa-icon
                class="font-14px edit pl-1 edit--{{ block.action.type | lowercase }}"
                [class.ml-auto]="!block.isContainer"
                icon="facEdit"
                (click)="onEdit($event)"
            >
            </fa-icon>
            <div
                *ngFor="let direction of block.action.type === 'CONDITION' ? ['next', 'elseNext', 'prev'] : ['next', 'prev']"
                [attr.data-name]="block.action.name"
                [attr.data-connector]="direction"
                data-zone="drop"
                class="drop-area drop-area--{{ direction }}"
            ></div>
        </ng-container>

        <div *ngIf="block.debugging" class="breakpoint" (click)="toggleBreakpoint($event)">
            <em class="breakpoint--icon" [class.active]="block.breakpoint"></em>
        </div>
    </xhtml:div>
</svg:foreignObject>

<svg:circle
    *ngIf="block.isStartBlock"
    data-zone="drop"
    fill="#327DE2"
    stroke="none"
    [attr.r]="$any(block).radius"
    [attr.cy]="$any(block).center.y"
    [attr.cx]="$any(block).center.x"
/>

<ng-container *ngIf="block.isEndBlock">
    <svg:circle
        stroke-width="2"
        stroke="#327DE2"
        [attr.r]="$any(block).radius"
        [attr.cy]="$any(block).center.y"
        [attr.cx]="$any(block).center.x"
    ></svg:circle>
    <svg:circle
        fill="#327DE2"
        stroke="none"
        [attr.r]="$any(block).radius - 3"
        [attr.cy]="$any(block).center.y"
        [attr.cx]="$any(block).center.x"
    />
</ng-container>
