import { Component, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { ComponentCanDeactivate, Warning } from '@dagility-ui/kit';

import { MODAL_PLACEHOLDERS_PROVIDER } from './providers/modal-placeholders.provider';
import { WidgetBuilderComponent } from './widget-builder.component';
import { WIDGET_DATA_PROVIDER } from './providers/widget-data.provider';

@Component({
    selector: 'dp-widget-builder-library.d-block',
    template: `
        <dp-widget-builder (saveAndClose)="handleClose()"></dp-widget-builder>
    `,
    styles: [
        `
            :host {
                height: calc(100vh - 50px);
                width: calc(100vw - 200px);
            }

            :host-context(.lib-sidenav-content) {
                width: 100%;
            }
        `,
    ],
    providers: [MODAL_PLACEHOLDERS_PROVIDER, WIDGET_DATA_PROVIDER],
})
export class WidgetBuilderLibraryComponent implements ComponentCanDeactivate {
    @ViewChild(WidgetBuilderComponent) wb: WidgetBuilderComponent;

    fromClone = false;

    constructor(private router: Router, private activatedRoute: ActivatedRoute) {
        if (this.router.getCurrentNavigation()?.extras?.state) {
            const { fromClone } = this.router.getCurrentNavigation().extras.state;
            this.fromClone = !!fromClone;
        }
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.wb.isDirty();
    }

    getWarning(): Warning {
        return {
            title: 'Discard',
            message: 'Are you sure you want to discard changes?',
        };
    }

    handleClose() {
        const state = this.fromClone
            ? {
                  clonedId: this.activatedRoute.snapshot.paramMap.get('widgetId'),
              }
            : {};
        this.router.navigate(['../'], {
            relativeTo: this.activatedRoute,
            state,
        });
    }
}
