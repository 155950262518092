<ng-container *ngTemplateOutlet="headerTemplateRef || defaultHeaderTemplate; context: { title: title, modal: modal }"></ng-container>
<ng-template #defaultHeaderTemplate>
    <div class="modal-header p-25px">
        <ng-container *ngTemplateOutlet="titleTemplateRef || defaultTitleTemplate; context: { title: title, modal: modal }"></ng-container>

        <ng-template #defaultTitleTemplate>
            <h6 class="modal-title">{{ title }}</h6>
        </ng-template>

        <div class="pull-right">
            <fa-icon class="fa zoom" icon="{{ isExpanded ? 'facCompress' : 'facExpand' }}" (click)="isExpanded = !isExpanded"></fa-icon>
            <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</ng-template>
<div class="modal-body p-0" [style.width.px]="bodyWidth" [style.maxHeight.px]="bodyHeight">
    <div>
        <perfect-scrollbar class="log scrollbar pb-4 bottom-border-radius" [style.maxheight.px]="logHeight">
            <div class="log-content p-4 bottom-border-radius">
                <div *ngIf="logs && logs.length; else noData">
                    <div *ngFor="let log of logs; let first = first"
                         class="p-10px"
                         [ngClass]="{'log-error': log.level === 'ERROR',
                                     'log-warning': log.level === 'WARNING',
                                     'log-info': log.level === 'INFO',
                                     'mt-10px': !first}">
                        <div class="row no-gutters"><strong>Type: </strong>{{getCursiveType(log.type)}}</div>
                        <div class="row no-gutters"><strong>Level: </strong>{{log.level}}</div>
                        <div class="row no-gutters"><strong>Invoke parameters: </strong>{{log.invokeParameters}}</div>
                        <div class="row no-gutters"><strong>Parameters description: </strong>{{log.parametersDescription ? log.parametersDescription : ''}}</div>
                        <div class="row no-gutters" [style.line-break]="'anywhere'"><strong>Message: </strong>{{log.message}}</div>
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </div>
</div>

<ng-template #noData>
    <span class="ml-15px no-components-text">There are no logs</span>
</ng-template>
