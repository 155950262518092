import {Component, Input} from '@angular/core';
import { ControlContainer, FormArray, FormGroupDirective } from '@angular/forms';

import { DropdownItem, ModalService } from '@dagility-ui/kit';

import { WidgetBuilderFacade } from '../../../state/widget-builder.facade';
import { ColumnFilterType, WidgetTableColumn, WidgetType } from '../../../models/any-widget.model';
import { WidgetBuilderColumnsSettingsComponent } from '../../form/widget-builder-columns/widget-builder-columns-settings/widget-builder-columns-settings.component';
import { WidgetBuilderColumnsReorderComponent } from '../../form/widget-builder-columns/widget-builder-columns-reorder/widget-builder-columns-reorder.component';
import {DisplaySettingsConfig} from "data-processor/lib/widget-library/widget-builder/models/display-settings.config";

@Component({
    selector: 'dp-widget-builder-columns',
    templateUrl: './widget-builder-columns.component.html',
    styleUrls: ['./widget-builder-columns.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class WidgetBuilderColumnsComponent {

    @Input() block: any;

    get getDisplaySettingsConfig() {
        return this.block.displaySettingsConfig.enabled ? this.block.displaySettingsConfig : new DisplaySettingsConfig();
    }

    get columns(): FormArray {
        return this.parent.control.get('columns') as FormArray;
    }

    get widgetType(): WidgetType {
        return this.parent.control.get('common.type').value;
    }

    type = WidgetType;

    color = '';

    filterType: DropdownItem<ColumnFilterType>[] = [
        {
            label: 'Number',
            value: ColumnFilterType.NUMBER,
        },
        {
            label: 'Text',
            value: ColumnFilterType.TEXT,
        },
        {
            label: 'Date',
            value: ColumnFilterType.DATE,
        },
    ].sort((a, b) => a.label.localeCompare(b.label)) as DropdownItem<ColumnFilterType>[];

    constructor(public parent: FormGroupDirective, public facade: WidgetBuilderFacade, private modal: ModalService) {}

    ngOnInit() {
        if (this.columns.value.every((element: WidgetTableColumn) => element.columnVisible === null)) {
            const columnsLength = Math.min(8, this.columns.controls.length);
            for (let i = 0; i < columnsLength; i++) {
                this.columns
                    .at(i)
                    .get('columnVisible')
                    .patchValue(true);
            }
        }
    }

    editColumn(columnControl: any, i: number) {
        const modalRef = this.modal.open(
            WidgetBuilderColumnsSettingsComponent,
            { size: 'xl' },
            {
                columnControl: columnControl,
                columnIndex: i,
                parent: this.parent,
                facade: this.facade,
            }
        );

        modalRef.componentInstance.columnsData.subscribe((columns: any) => {
            this.columns.patchValue(columns);
        });
    }

    reorderColumns() {
        const modalRef = this.modal.open(
            WidgetBuilderColumnsReorderComponent,
            {
                centered: true,
                backdrop: 'static',
                keyboard: false,
            },
            { columns: this.columns.value }
        );
        modalRef.componentInstance.columnsData.subscribe((columnsData: any) => {
            this.columns.patchValue(columnsData);
        });
    }

    addColumn(insertAbove: boolean): void {
        const newColumn = this.facade.buildColumnGroup({} as WidgetTableColumn);
        newColumn.get('columnVisible').patchValue(true);
        insertAbove ? this.columns.insert(0, newColumn) : this.columns.push(newColumn);
    }

    removeColumn(index: number): void {
        this.columns.removeAt(index);
    }

    deleteColor(columnIndex: number, colorIndex: number): void {
        (this.columns.at(columnIndex).get('colors') as FormArray).removeAt(colorIndex);
    }

    colorPickerSelect(columnIndex: number): void {
        (this.columns.at(columnIndex).get('colors') as FormArray).push(this.facade.buildColorsControl(this.color));
    }
}
