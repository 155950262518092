import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { DropdownItem } from '@dagility-ui/kit';

import { WidgetType } from '../../../models/any-widget.model';
import { EXPORT_DATA_TYPE, WIDGET_EXPORTER, WidgetExporterFactory } from '../../../services/exporter/widget.exporter';

@Component({
    selector: 'dp-export-data-button',
    templateUrl: './export-data-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['export-data-button.component.scss']
})
export class ExportDataButtonComponent {
    @Input() set type(type: WidgetType) {
        this._type = type;

        this.initExport();
    }
    get type() {
        return this._type;
    }

    @Input() set complexWidgetTypes(widgets: any[]) {
        this._complexWidgetTypes = (widgets || []).map(widget => widget.type) as WidgetType[];
    }

    get complexWidgetTypes() {
        return this._complexWidgetTypes;
    }

    @Output() export = new EventEmitter<EXPORT_DATA_TYPE>();

    supportedFormats: DropdownItem<EXPORT_DATA_TYPE>[] = [];

    // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
    private _type: WidgetType;
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _complexWidgetTypes: WidgetType[] = [];

    constructor(@Inject(WIDGET_EXPORTER) private widgetExporterFactory: WidgetExporterFactory) {}

    initExport() {
        if (!this.complexWidgetTypes.length) {
            this.supportedFormats = Object.keys(this.widgetExporterFactory[this.type] ?? {}).map(format => ({
                label: format,
                value: format as EXPORT_DATA_TYPE,
            }));
            return;
        }

        const exportDataTypes: EXPORT_DATA_TYPE[] = ['CSV', 'PDF', 'XLSX'];
        const supportedFormats: DropdownItem<EXPORT_DATA_TYPE>[] = [];

        exportDataTypes.forEach(exportDataType => {
            if (
                this.complexWidgetTypes.some(
                    (type: WidgetType) => !this.widgetExporterFactory[type] || !this.widgetExporterFactory[type][exportDataType]
                )
            ) {
                return;
            }

            supportedFormats.push({
                label: exportDataType,
                value: exportDataType,
            });
        });

        this.supportedFormats = supportedFormats;
    }
}
