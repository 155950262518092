import { Inject, Injectable, NgZone, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { cloneDeep, dropRight, get, pickBy, startCase } from 'lodash';
import { map, take, tap, withLatestFrom, shareReplay } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, merge, Observable, of, Subscription } from 'rxjs';

import {
    checkIsCollection,
    createFormGroup,
    findAllIssuesByPath,
    getTypingWarnings,
    isUndefined,
    isPrimitive,
    isObject,
    validateForm,
} from './utils';
import { DEFINITION_ERRORS, FIELD_NAMES, WARNING_NAMES } from './field-names';
import { Deserializable, JobDefinition } from './models/job-definition';
import { IssueElement } from './models/issue-list.model';
import { JobDefinitionForm, MenuItem } from './models/common';
import { ClassName } from './models/job-definition/enums/class-name';
import { WorkflowBuilder } from './state/workflow-builder.state';
import { JDFormBuilder } from './state/job-definition-form.state';
import { AbstractDpJSONConverter } from './converters/abstract-dp.json.converter';
import { JobDefinitionNormalizer, HIDDEN_FIELDS_PLUGIN_TYPE_MAP } from './converters/job-definition.normalizer';
import { JobDefinitionState, FormMode } from './state/job-definition.state.model';
import { JobDefinitionDebug } from './state/job-definition-debug.state';
import { FORM_ERRORS, getAllErrors, Store } from '@dagility-ui/kit';
import {
    moveToLinesJDFields
} from 'data-processor/lib/processor-monitoring/job-definition.lines';

const HIDDEN_FIELDS_BY_PLUGIN_TYPE = HIDDEN_FIELDS_PLUGIN_TYPE_MAP;

const WORKFLOW_PLUGIN_KEY = 'WORKFLOW_PLUGIN';
const DATA_PLUGIN_KEY = 'DATA_PLUGIN';

@Injectable()
export class EditJobDefinitionService extends Store<JobDefinitionState> implements OnDestroy {
    workflowBuilder = new WorkflowBuilder(this);
    formBuilder = new JDFormBuilder(this);
    debugContext = new JobDefinitionDebug(this);

    activeStep$ = this.formBuilder.activeStep$;
    lastStep$ = this.formBuilder.lastStep$;
    activeField$ = this.formBuilder.activeField$.pipe(
        tap(({ menuPath }) => {
            const index = this.menu.findIndex(item => item.field === menuPath);
            if (index !== -1) {
                this.setState({ index });
            }
        })
    );
    path$ = this.select(state => state.path);
    formGroup$ = this.formBuilder.formGroup$;
    formSliceParent$ = this.formBuilder.formSliceParent$;
    jdParent$ = this.formBuilder.parent$;

    index$ = this.select(state => state.index);

    mode$ = this.select(state => state.mode);

    builder$ = this.select(state => state.builder);

    debugContext$ = this.debugContext.debugContext$;

    vm$: Observable<any> = combineLatest([
        this.index$,
        this.activeField$,
        this.lastStep$,
        this.formGroup$,
        this.formSliceParent$,
        this.path$,
        this.mode$,
        this.jdParent$,
    ]).pipe(
        map(([index, activeField, lastStep, formGroup, formSliceParent, path, mode, jdParent]) => ({
            index,
            activeField,
            lastStep,
            formGroup,
            formSliceParent,
            path,
            mode,
            jdParent,
        }))
    );

    isWorkflowJob$: Observable<boolean>;

    // used to pessimistic updates view
    needUpdating$ = new BehaviorSubject(false);
    needRevalidate$ = new BehaviorSubject(false);

    formIssues$: BehaviorSubject<IssueElement[]>;
    invalid$: Observable<boolean> = of(false);

    subscription$: Subscription;

    menu: MenuItem[] = [];
    menu$: Observable<MenuItem[]> = of([]);
    jd: JobDefinition;
    formJD: JobDefinitionForm;
    form: FormGroup;
    typeIsChecked = false;

    set json(value) {
        this.jdJson = value;
        this.isValid = this.isValidJson(value);
    }

    get json() {
        return this.jdJson;
    }

    jdJson = '';
    isValid = true;
    issueType: 'warning' | 'error' | null = null;
    isJD = true;
    isWorkflowJD = true;
    toolId: string;

    drop = this.workflowBuilder.drop;
    delete = this.workflowBuilder.delete;
    toggleBreakpoint = this.workflowBuilder.toggleBreakpoint;
    breakpoints = this.workflowBuilder.breakpoints;

    goToStep = this.formBuilder.goToStep;
    goToPath = this.formBuilder.goToPath;

    evaluate = this.debugContext.evaluate;
    removeWatch = this.debugContext.remove;
    addNewWatch = this.debugContext.addNew;
    updateDebugContainer = this.debugContext.updateDebugContainer;
    changeDebugTab = this.debugContext.changeTab;
    updateEvaluateResult = this.debugContext.updateEvaluateResult;

    constructor(
        @Inject(FIELD_NAMES) private fieldNames: any,
        @Inject(WARNING_NAMES) private warnings: any,
        @Inject(FORM_ERRORS) private errors: any,
        @Inject(DEFINITION_ERRORS) private definitionErrors: any,
        private zone: NgZone,
        public fb: FormBuilder
    ) {
        super({
            ...JDFormBuilder.getDefaultState(),
            ...WorkflowBuilder.getDefaultState(),
            ...JobDefinitionDebug.getDefaultState(),
        });
    }

    get updatingRequest() {
        return this.needUpdating$.asObservable();
    }

    get formIssues() {
        return this.formIssues$.asObservable();
    }

    goToPathAndValidateControl(issue: IssueElement) {
        const { isPrimitive, errorPath, type } = issue;
        const pathToMenu = isPrimitive ? dropRight(errorPath) : errorPath;

        const control = this.form.get(errorPath);

        const onMicrotaskEmpty = () => this.zone.onMicrotaskEmpty.asObservable().pipe(take(1), withLatestFrom(this.formIssues));

        const updateControlByIssue = (issue: IssueElement) => {
            const issueControl = this.form.get(issue.errorPath);

            if (issueControl) {
                issueControl.markAsTouched();
                issueControl.updateValueAndValidity();
            }
        };
        const validateControl = (issues: IssueElement[]) => {
            control.markAsTouched();
            control.updateValueAndValidity();

            const issuesOnPage = findAllIssuesByPath(issues, pathToMenu);

            if (issuesOnPage.length > 1) {
                issuesOnPage.forEach(issue => {
                    updateControlByIssue(issue);
                });
            }
        };

        const showErrorsInTab = () =>
            onMicrotaskEmpty().subscribe(([_, issues]) => {
                findAllIssuesByPath(issues, pathToMenu).forEach(issue => {
                    updateControlByIssue(issue);
                });
            });

        this.goToPath(pathToMenu);

        if (type === 'ERROR') {
            onMicrotaskEmpty().subscribe(([_, issues]) => {
                if (!(control instanceof FormControl)) {
                    control.markAsTouched({ onlySelf: true });
                    this.requestUpdateView();

                    showErrorsInTab();
                } else {
                    validateControl(issues);
                }
            });
        } else {
            showErrorsInTab();
        }
    }

    goToIndex(index: number) {
        const path = [this.menu[index].field];

        this.setState({ index, path });
    }

    getIssueType = (issues: IssueElement[]) => (issues.some(issue => issue.type === 'ERROR') ? 'error' : 'warning');

    init(object: any, isJD = true, resetStore = true) {
        if (this.subscription$) {
            this.subscription$.unsubscribe();
            this.subscription$ = null;
        }

        this.typeIsChecked = false;
        this.isJD = isJD;
        this.jd = object;
        this.formJD = isJD ? this.createFormObject(this.jd) : object;
        this.form = createFormGroup(this.fb, this.formJD, this.jd, true);
        this.formBuilder.init(this.form, this.formJD, this.jd);
        if (resetStore) {
            this.setState({
                index: 0,
                path: isJD ? ['setup'] : [],
                mode: 'FORM',
            });
        }
        if (isJD) {
            this.menu = this.createMenuItems(this.jd);
            this.setState({ builder: { blocks: [...object.actions] || [] } });

            this.initIsWorkflowJobObs();
            this.initMenuItems();
        }

        const issues = this.getIssuesList();

        this.formIssues$ = new BehaviorSubject(this.getIssuesList());
        this.issueType = this.getIssueType(issues);
        this.invalid$ = this.formIssues.pipe(map(issues => issues.some(({ type }) => type === 'ERROR')));

        const valueChanges$ = merge(this.form.valueChanges, this.needRevalidate$.asObservable()).subscribe(() => {
            const issues = this.getIssuesList();
            const newIssues = [...issues, ...this.formIssues$.getValue().filter(issue => issue.isActionBlock)];
            this.issueType = this.getIssueType(newIssues);
            this.formIssues$.next(newIssues);
        });

        if (this.subscription$) {
            this.subscription$.add(valueChanges$);
        } else {
            this.subscription$ = valueChanges$;
        }
    }

    setToolId(toolId: string) {
        this.toolId = toolId;
    }

    initIsWorkflowJobObs() {
        this.isWorkflowJob$ = of(ClassName.WORKFLOW_PLUGIN).pipe(
            map(className => className === ClassName.WORKFLOW_PLUGIN),
            shareReplay(1)
        );
    }

    initMenuItems() {
        this.menu$ = this.isWorkflowJob$.pipe(
            map(isWorkflow => [isWorkflow, isWorkflow ? WORKFLOW_PLUGIN_KEY : DATA_PLUGIN_KEY]),
            tap(([isWorkflow, _]: [boolean, string]) => {
                this.isWorkflowJD = isWorkflow;

                setTimeout(() => {
                    this.toggleValidators(Object.keys(HIDDEN_FIELDS_BY_PLUGIN_TYPE.WORKFLOW_PLUGIN), !isWorkflow);
                    this.typeIsChecked = true;
                });
            }),
            map(([_, className]: [boolean, string]) => {
                const hiddenFields = (HIDDEN_FIELDS_BY_PLUGIN_TYPE as Record<string, any>)[className];

                return this.createMenuItems(this.jd).filter(item => !hiddenFields[item.field]);
            }),
            tap(menu => (this.menu = menu)),
            tap(() => {
                if (!this.isWorkflowJD) {
                    this.setState({ builder: { blocks: [] } });
                }
            })
        );
    }

    createMenuItems(jd: JobDefinition): MenuItem[] {
        return [
            {
                text: 'Setup',
                field: 'setup',
            },
            {
                text: 'Variables',
                field: 'variables',
            },
            ...Object.keys(pickBy(jd, value => value !== null && typeof value === 'object'))
                .filter(key => ![...Deserializable.METAFIELDS, 'meta', 'setup', 'actions', 'variables'].includes(key))
                .map(key => ({ text: this.fieldNames[key] || startCase(key), field: key }))
                .sort((a, b) => a.text.localeCompare(b.text)),
            {
                text: 'Groovy Scripts',
                field: 'groovy',
            },
            {
                text: 'Actions',
                field: 'actions',
            },
            {
                text: 'JSON',
                field: 'json',
            },
        ];
    }

    createFormObject(jd: JobDefinition): JobDefinitionForm {
        const groovy = {};
        const setup = {};

        Object.keys(jd).forEach(key => {
            const field = (jd as Record<string, any>)[key];

            if (isPrimitive(field)) {
                if (!JobDefinition.GROOVY_FIELDS.includes(key)) {
                    (setup as Record<string, any>)[key] = field;
                } else {
                    (groovy as Record<string, any>)[key] = field;
                }
            }
        });

        return {
            setup,
            groovy,
            ...pickBy(jd, (prop: any) => !isUndefined(prop) && isObject(prop)),
        } as any;
    }

    getMetaInformation(jd: JobDefinition, path: string[]) {
        const field = get(jd, path);

        if (!!field && !checkIsCollection(jd, path)) {
            return field.meta;
        } else {
            const pathToParent = dropRight(path);
            const metaObj = get(jd, pathToParent, jd);
            return metaObj.meta;
        }
    }

    modeChange(mode: FormMode) {
        if (mode === 'JSON') {
            this.setState({ mode });

            const converterConstructor = AbstractDpJSONConverter.getConverter();
            const converter = new converterConstructor({ ...this.formJD, actions: this.value.builder.blocks });
            this.json = converter.convert();
        } else if (mode === 'FORM' && this.isValid) {
            this.setState({ mode });

            this.init(new JobDefinition(AbstractDpJSONConverter.restore(this.json, this.jd)), true, false);
        }
    }

    isValidJson(str: string) {
        try {
            const obj = JSON.parse(str);
            return typeof obj === 'object';
        } catch (e) {
            return false;
        }
    }

    toggleValidators(fields: string[], enable: boolean) {
        for (const field of fields) {
            const control = this.form.get(field);

            if (!control) {
                continue;
            }

            if (enable) {
                control.enable();
            } else {
                control.disable();
            }
        }
    }

    toSave() {
        const validStatus = validateForm(
            this.form,
            this.menu.map(item => item.field)
        );
        if (!validStatus) {
            const jdToSave = this.buildJDToSave();
            const warnings = this.formIssues$.getValue().filter(issue => issue.type === 'WARNING');

            if (warnings.length) {
                if (confirm(`You have ${warnings.length} warning${warnings.length !== 1 ? 's' : ''}. Save?`)) {
                    return jdToSave;
                } else {
                    return null;
                }
            } else {
                return jdToSave;
            }
        } else {
            const [control, path] = validStatus;
            this.goToPath(path);
            control.markAsDirty();
            (control instanceof FormGroup ? Object.values(control.controls) : control.controls).forEach(c => {
                c.markAsDirty();
                c.markAsTouched();
            });
        }
    }

    buildJDToSave() {
        let jdToSave = cloneDeep(this.formJD);

        if (this.isJD) {
            const normalizer = new JobDefinitionNormalizer(this.isWorkflowJD);
            jdToSave = normalizer.normalize(jdToSave, cloneDeep(this.getValue().builder.blocks));
            moveToLinesJDFields(jdToSave.actions ?? []);
        } else {
            jdToSave = JobDefinitionNormalizer.removeEmptyFields(jdToSave);
        }

        return jdToSave as any;
    }

    findInvalidBlock(): IssueElement | null {
        return this.formIssues$.getValue().find(({ isActionBlock, type }) => isActionBlock && type === 'ERROR');
    }

    requestUpdateView() {
        this.needUpdating$.next(!this.needUpdating$.getValue());
    }

    getIssuesList(form = this.form, warningObject = this.formJD): IssueElement[] {
        const errors = getAllErrors(form, []).map(error => ({
            ...error,
            isPrimitive: error.control instanceof FormControl,
            message:
                this.definitionErrors[error.errorName] && !(error.control instanceof FormControl && error.errorName === 'required')
                    ? this.definitionErrors[error.errorName]
                    : this.errors[error.errorName]
                    ? this.errors[error.errorName](error.errorValue)
                    : 'Error',
            control: undefined,
            type: 'ERROR',
        }));

        const warnings = warningObject
            ? getTypingWarnings(warningObject, [], true).map(warning => ({
                  ...warning,
                  message:
                      this.warnings && this.warnings[warning.errorName]
                          ? this.warnings[warning.errorName](warning.errorValue)
                          : 'Typing error',
                  type: 'WARNING',
              }))
            : [];

        return [...errors, ...warnings];
    }

    ngOnDestroy() {
        if (this.subscription$) {
            this.subscription$.unsubscribe();
        }
    }
}
