import { Component, Inject } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { noop, Observable, of } from 'rxjs';
import { DropdownItem, liftToObsWithStatus } from '@dagility-ui/kit';

import { WidgetDebuggerState } from '../../../../services/widget.debugger';
import { FilterSetDirective } from '../../../../directives/filter-set.directive';
import { BaseWidgetFilter } from '../../../../components/widget/filters/base-widget-filter.component';
import { DropdownFilter, FILTER_DEFAULT_SIZES } from '../../../../models/any-widget.model';
import { getWidgetWidth } from '../../../../services/widget-builder.util';

@Component({
    selector: 'dp-radio-button-group-filter',
    template: `
        <ng-container *ngIf="items$ | obsWithStatus | async as itemsObs">
            <span class="label label-top">
                {{ filter.label }}
            </span>
            <div *ngIf="itemsObs.value; else loaderTmpl" class="filter-row" [style.max-width.px]="filter.width | mapper: getWidth">
                <ng-container *ngFor="let item of itemsObs.value; let i = index">
                    <lib-radio-button
                        id="radio-button-{{ i }}"
                        [attr.data-test-id]="item.value"
                        class="custom-radio-button col"
                        [name]="item.value"
                        [checked]="isItemChecked(item.value)"
                        [label]="item.label"
                        (click)="onItemChecked(item.value)"
                    >
                    </lib-radio-button>
                </ng-container>
            </div>
            <ng-template #loaderTmpl>
                <lib-loader class="d-block mt-3"></lib-loader>
            </ng-template>
        </ng-container>
    `,
    styleUrls: ['./radio-button-group-filter.component.scss'],
})
export class RadioButtonGroupFilterComponent extends BaseWidgetFilter {
    nameByIdMap: Record<string, DropdownItem> = {};

    getWidth = getWidgetWidth(FILTER_DEFAULT_SIZES.DROPDOWN * 4);

    getItemsObs = () => (obs: Observable<DropdownItem[]>) => {
        return liftToObsWithStatus(
            obs.pipe(
                catchError(() => {
                    return of([] as DropdownItem[]);
                }),
                tap((data: any) => {
                    if (data && data.type !== 'start') {
                        this.nameByIdMap = data.reduce((acc: Record<string, any>, item: DropdownItem, idx: number) => {
                            acc[item.value] = item;

                            return acc;
                        }, {});
                    }
                })
            )
        );
    };

    // eslint-disable-next-line @typescript-eslint/member-ordering
    items$ = this.debuggerState
        ? this.debuggerState.dataSources[this.filter.placeholder].pipe(this.getItemsObs())
        : this.filterSet.filtersDataMap?.[this.filter.placeholder]?.pipe(this.getItemsObs());

    // eslint-disable-next-line @typescript-eslint/member-ordering
    onChange: any = noop;

    constructor(
        @Inject(BaseWidgetFilter.FILTER_TOKEN) public filter: DropdownFilter,
        private debuggerState: WidgetDebuggerState,
        private filterSet: FilterSetDirective
    ) {
        super(filter);
    }

    onItemChecked(value: string): void {
        this.formControl.patchValue(value);
    }

    isItemChecked(value: string): boolean {
        return this.formControl.value === value;
    }

    getFilterText(): { title: string; value: any } {
        return {
            title: this.filter.label,
            value: this.nameByIdMap ? this.nameByIdMap[this.formControl.value].label : null,
        };
    }
}
