<div class="fields-grid-group">
    <div class="header d-flex align-items-center justify-content-between" [ngClass]="{ 'header-drag-enable': !readonly }">
        <trigger *ngIf="showToggle" [value]="!fieldsHidden" [followValue]="true" (click)="handleToggle()"></trigger>
        <div class="title mr-auto" [class.disabled]="readonly">
            {{ title }}
            <ng-container *ngIf="fieldConfigContextHelpTemplate">
                <ng-template
                    *ngTemplateOutlet="fieldConfigContextHelpTemplate; context: { contextHelpId: 'field-configuration-properties' }"
                >
                </ng-template>
            </ng-container>
        </div>
        <div
            *ngIf="!readonly && addSystemProperties"
            [style.display]="'none !important'"
            class="d-flex d-inline-flex add-new-control align-items-center"
            (click)="handleAddSystemProperty()"
        >
            <fa-icon icon="plus" class="fa mr-5px"></fa-icon>
            Add Global Parameter
        </div>

        <ng-container *ngIf="!readonly">
            <div
                *ngIf="!uploadFn"
                [ngClass]="{ 'mr-10px': !(canPublishChanges && idToPublish) }"
                data-test-id="add-new-btn"
                class="d-flex d-inline-flex add-new-control align-items-center"
                (click)="addField()"
            >
                <fa-icon icon="plus" class="fa mr-5px"></fa-icon>
                Add New Variable
            </div>

            <div
                *ngIf="uploadFn"
                ngbDropdown
                [container]="'body'"
                [ngClass]="{ 'mr-10px': !(canPublishChanges && idToPublish) }"
                (openChange)="isAddDropdownOpened = $event"
            >
                <button
                    class="btn btn-link text-secondary default-size px-1 add-dropdown"
                    [class.add-dropdown--active]="isAddDropdownOpened"
                    ngbDropdownToggle
                >
                    <fa-icon icon="plus" class="fa-icon fa mr-5px"></fa-icon> Add
                    <fa-icon class="fa-icon chevron-icon ml-2" [icon]="isAddDropdownOpened ? 'chevron-up' : 'chevron-down'"></fa-icon>
                </button>

                <div ngbDropdownMenu (mousedown)="modalWindowOpened.emit(true)">
                    <div class="dropdown-item body1 cursor-pointer" (click)="showUploadDialog()">
                        Upload Config File
                    </div>
                    <div data-test-id="add-new-btn" class="dropdown-item body1 cursor-pointer" (click)="addField()">
                        Add New Variable
                    </div>
                </div>
            </div>
        </ng-container>

        <button
            *ngIf="canPublishChanges && idToPublish"
            data-test-id="publish-changes"
            [ngClass]="{ 'mr-10px': !readonly }"
            class="btn btn-primary ml-30px"
            (click)="publishChanges()"
        >
            Publish
        </button>
    </div>
    <ng-container *ngIf="!fieldsHidden">
        <lib-data-grid
            [dataSource]="editFields"
            [columns]="gridColumns"
            [sticky]="gridBodyHeight && gridBodyHeight.length"
            class="app-data-grid"
            data-test-id="fields-grid"
            [ngClass]="{ 'app-data-grid-drag-enable': !readonly }"
            [allowDraggingRows]="!readonly"
            (rowItemDropped)="handleReorder($event)"
        >
            <ng-template headerTemplate>
                <tr>
                    <ng-container *ngFor="let c of gridColumns">
                        <th
                            *ngIf="!c.hidden"
                            [ngClass]="{ 'sort-field': c.sortingField }"
                            [style.width]="c.width"
                            [style.min-width]="c.minWidth"
                            scope="col"
                            class="over-me"
                        >
                            <span class="column-header">
                                {{ c.title }}
                            </span>
                        </th>
                    </ng-container>
                </tr>
            </ng-template>

            <ng-template rowTemplate let-field="item" let-fields="items">
                <ng-container *ngIf="allowedTypes.indexOf(field.type) >= 0">
                    <td
                        class="cursor-default over-me"
                        [ngStyle]="{ width: gridColumns[0].width, 'min-width': gridColumns[0].minWidth, 'max-width': 0 }"
                    >
                        <span
                            ngbTooltip="{{ field.name }}"
                            placement="top-left"
                            tooltipClass="fields-grid-icon-tooltip"
                            [openDelay]="800"
                            container="body"
                        >
                            {{ field.name }}
                        </span>
                    </td>
                    <td
                        class="cursor-default over-me"
                        [ngStyle]="{ width: gridColumns[1].width, 'min-width': gridColumns[1].minWidth, 'max-width': 0 }"
                    >
                        <span
                            ngbTooltip="{{ field.type }}"
                            placement="top-left"
                            tooltipClass="fields-grid-icon-tooltip"
                            [openDelay]="800"
                            container="body"
                        >
                            {{ field.type }}
                        </span>
                    </td>
                    <td
                        class="cursor-default over-me"
                        [ngStyle]="{ width: gridColumns[2].width, 'min-width': gridColumns[2].minWidth, 'max-width': 0 }"
                    >
                        <span
                            ngbTooltip="{{ field.defaultValue }}"
                            placement="top-left"
                            tooltipClass="fields-grid-icon-tooltip"
                            [openDelay]="800"
                            container="body"
                        >
                            {{ field.defaultValue }}
                        </span>
                    </td>
                    <td *ngIf="!gridColumns[3].hidden" [ngStyle]="{ width: gridColumns[3].width, 'min-width': gridColumns[3].minWidth }">
                        <fa-icon [icon]="field.embedded ? 'checkmark' : 'line'"></fa-icon>
                    </td>
                    <td [ngStyle]="{ width: gridColumns[4].width, 'min-width': gridColumns[4].minWidth }">
                        <fa-icon [icon]="field.mandatory ? 'checkmark' : 'line'"></fa-icon>
                    </td>
                    <td [ngStyle]="{ width: gridColumns[5].width, 'min-width': gridColumns[5].minWidth }">
                        <fa-icon [icon]="field.array ? 'checkmark' : 'line'"></fa-icon>
                    </td>
                    <td [ngStyle]="{ width: gridColumns[6].width, 'min-width': gridColumns[6].minWidth }">
                        <fa-icon [icon]="field.runtime ? 'checkmark' : 'line'"></fa-icon>
                    </td>
                    <td class="over-me" [ngStyle]="{ width: gridColumns[7].width, 'min-width': gridColumns[7].minWidth, 'max-width': 0 }">
                        <div
                            class="d-flex d-inline-flex over-me w-100"
                            [ngbTooltip]="tipOptionNames"
                            placement="left-bottom"
                            tooltipClass="fields-grid-options-tooltip"
                            [openDelay]="800"
                            container="body"
                        >
                            <div *ngFor="let option of field.options" class="option-item mr-10px">
                                {{ option.label }}
                            </div>
                            <fa-icon icon="line" *ngIf="!field.options?.length"></fa-icon>
                        </div>

                        <ng-template #tipOptionNames>
                            <div *ngFor="let option of field.options">
                                {{ option.label }}
                            </div>
                        </ng-template>
                    </td>
                    <td [ngStyle]="{ width: gridColumns[8].width, 'min-width': gridColumns[8].minWidth }">
                        <div class="d-flex align-items-center justify-content-center">
                            <div
                                *ngIf="!field.readonly && (!lockEmbedded || (lockEmbedded && !field.embedded))"
                                class="fa font-14px icons mr-15px"
                                data-test-id="edit-field-btn"
                                (click)="editField(field)"
                            >
                                <fa-icon
                                    icon="{{ readonly ? 'facEyeOpen' : 'facEdit' }}"
                                    [ngbTooltip]="readonly ? 'View' : 'Edit'"
                                    placement="top"
                                    tooltipClass="fields-grid-icon-tooltip"
                                    [openDelay]="800"
                                    container="body"
                                ></fa-icon>
                            </div>
                            <div
                                *ngIf="!readonly && !field.readonly && (!lockEmbedded || (lockEmbedded && !field.embedded))"
                                class="fa font-14px icons mr-15px"
                                data-test-id="remove-field-btn"
                                (click)="removeField(field)"
                            >
                                <fa-icon
                                    icon="facTrash"
                                    class="fa font-14px icons"
                                    ngbTooltip="Remove"
                                    placement="top"
                                    tooltipClass="fields-grid-icon-tooltip"
                                    [openDelay]="800"
                                    container="body"
                                ></fa-icon>
                            </div>
                        </div>
                        <svg
                            *ngIf="!readonly"
                            class="row-drag-handle"
                            width="16"
                            height="16"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M4.5 6.5C4.77614 6.5 5 6.27614 5 6C5 5.72386 4.77614 5.5 4.5 5.5C4.22386 5.5 4 5.72386 4 6C4 6.27614 4.22386 6.5 4.5 6.5Z"
                                fill="#BDBCBC"
                            />
                            <path
                                d="M4.5 3C4.77614 3 5 2.77614 5 2.5C5 2.22386 4.77614 2 4.5 2C4.22386 2 4 2.22386 4 2.5C4 2.77614 4.22386 3 4.5 3Z"
                                fill="#BDBCBC"
                            />
                            <path
                                d="M4.5 10C4.77614 10 5 9.77614 5 9.5C5 9.22386 4.77614 9 4.5 9C4.22386 9 4 9.22386 4 9.5C4 9.77614 4.22386 10 4.5 10Z"
                                fill="#BDBCBC"
                            />
                            <path
                                d="M7.5 6.5C7.77614 6.5 8 6.27614 8 6C8 5.72386 7.77614 5.5 7.5 5.5C7.22386 5.5 7 5.72386 7 6C7 6.27614 7.22386 6.5 7.5 6.5Z"
                                fill="#BDBCBC"
                            />
                            <path
                                d="M7.5 3C7.77614 3 8 2.77614 8 2.5C8 2.22386 7.77614 2 7.5 2C7.22386 2 7 2.22386 7 2.5C7 2.77614 7.22386 3 7.5 3Z"
                                fill="#BDBCBC"
                            />
                            <path
                                d="M7.5 10C7.77614 10 8 9.77614 8 9.5C8 9.22386 7.77614 9 7.5 9C7.22386 9 7 9.22386 7 9.5C7 9.77614 7.22386 10 7.5 10Z"
                                fill="#BDBCBC"
                            />
                            <path
                                d="M4.5 6.5C4.77614 6.5 5 6.27614 5 6C5 5.72386 4.77614 5.5 4.5 5.5C4.22386 5.5 4 5.72386 4 6C4 6.27614 4.22386 6.5 4.5 6.5Z"
                                stroke="#BDBCBC"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M4.5 3C4.77614 3 5 2.77614 5 2.5C5 2.22386 4.77614 2 4.5 2C4.22386 2 4 2.22386 4 2.5C4 2.77614 4.22386 3 4.5 3Z"
                                stroke="#BDBCBC"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M4.5 10C4.77614 10 5 9.77614 5 9.5C5 9.22386 4.77614 9 4.5 9C4.22386 9 4 9.22386 4 9.5C4 9.77614 4.22386 10 4.5 10Z"
                                stroke="#BDBCBC"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M7.5 6.5C7.77614 6.5 8 6.27614 8 6C8 5.72386 7.77614 5.5 7.5 5.5C7.22386 5.5 7 5.72386 7 6C7 6.27614 7.22386 6.5 7.5 6.5Z"
                                stroke="#BDBCBC"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M7.5 3C7.77614 3 8 2.77614 8 2.5C8 2.22386 7.77614 2 7.5 2C7.22386 2 7 2.22386 7 2.5C7 2.77614 7.22386 3 7.5 3Z"
                                stroke="#BDBCBC"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M7.5 10C7.77614 10 8 9.77614 8 9.5C8 9.22386 7.77614 9 7.5 9C7.22386 9 7 9.22386 7 9.5C7 9.77614 7.22386 10 7.5 10Z"
                                stroke="#BDBCBC"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </td>
                </ng-container>
            </ng-template>
        </lib-data-grid>
    </ng-container>
</div>
