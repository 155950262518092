import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup, FormGroupDirective } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';
import { DropdownItem } from '@dagility-ui/kit';
import { ColumnFilterType, WidgetType } from 'data-processor';

import { WidgetBuilderFacade } from '../../../../../widget-builder/state/widget-builder.facade';

@Component({
    selector: 'dp-widget-builder-columns-settings',
    templateUrl: './widget-builder-columns-settings.component.html',
    styleUrls: ['./widget-builder-columns-settings.component.scss'],
})
export class WidgetBuilderColumnsSettingsComponent implements OnInit {
    @Input() columnControl: FormGroup;
    @Input() columnIndex: number;
    @Input() parent: FormGroupDirective;
    @Input() facade: WidgetBuilderFacade;

    @Output() columnsData: EventEmitter<any> = new EventEmitter();

    filterType: DropdownItem<ColumnFilterType>[] = [
        {
            label: 'Number',
            value: ColumnFilterType.NUMBER,
        },
        {
            label: 'Text',
            value: ColumnFilterType.TEXT,
        },
        {
            label: 'Date',
            value: ColumnFilterType.DATE,
        },
    ].sort((a, b) => a.label.localeCompare(b.label)) as DropdownItem<ColumnFilterType>[];

    get widgetType(): WidgetType {
        return this.parent.control.get('common.type').value;
    }

    get columns(): FormArray {
        return this.parent.control.get('columns') as FormArray;
    }

    type = WidgetType;
    color: string = '';
    columnsState: FormArray;
    columnName: string;

    constructor(private activeModal: NgbActiveModal) {}

    ngOnInit(): void {
        this.columnName = this.columnControl.get('title').value;
        this.columnsState = cloneDeep(this.columns.value);
    }

    handleSave() {
        this.activeModal.dismiss();
    }

    handleCancel() {
        this.columnsData.emit(this.columnsState);
        this.activeModal.dismiss();
    }

    removeProgressColor(columnIndex: number, colorIndex: number): void {
        (this.columns.at(columnIndex).get('progressColors') as FormArray).removeAt(colorIndex);
    }

    colorPickerSelect(columnIndex: number): void {
        (this.columns.at(columnIndex).get('progressColors') as FormArray).push(this.facade.buildProgressColorsGroup({}));
    }
}
