import { Inject, Injectable, NgZone, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, Observable, of } from 'rxjs';
import { catchError, map, publishReplay, refCount, switchMap } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

import { DropdownItem, EnvironmentModel, generateUUID, loadFile, readFileToObject } from '@dagility-ui/kit';
import {
    DATA_MORPH_AUTH,
    DATA_MORPH_FEATURE,
    DATA_MORPH_FEATURE_TOGGLE,
    DataMorphAuthService,
    DataMorphFeatureToggleService,
} from 'data-processor/tokens';

import {
    AnyWidgetModel,
    ChartOptions,
    WidgetFilter,
    WidgetQuery,
    WidgetQueryType,
    WidgetSize,
    WidgetToolCategoryType,
} from '../models/any-widget.model';
import { BasePlaceholders } from './widget-builder.util';

interface CacheItem {
    expired: number;
    item$: Observable<Record<string, any>>;
}

const TTL = 10 * 1_000; // 10 sec

@Injectable({
    providedIn: 'root',
})
export class WidgetBuilderService implements OnDestroy {
    private readonly dpUrl = `${this.environment.dpApiURL}/data-processor`;
    private readonly baseUrl = `${this.dpUrl}/widgets`;
    private readonly insightUrl = `${this.environment.insightApiURL}/constructor`;
    private readonly cache = new Map<string, CacheItem>();
    private clearCacheInterval: number;

    constructor(
        @Inject('environment') private environment: EnvironmentModel,
        private http: HttpClient,
        private zone: NgZone,
        @Inject(DATA_MORPH_FEATURE_TOGGLE) private featureToggleService: DataMorphFeatureToggleService,
        @Inject(DATA_MORPH_AUTH) private authService: DataMorphAuthService
    ) {
        this.checkClearCache();
    }

    static convertToDashboardWidget(widget: AnyWidgetModel, id: number): AnyWidget {
        return {
            id,
            dashboardId: null,
            data: widget,
        } as AnyWidget;
    }

    private static serializeRequestParams(params: Record<string, any>) {
        return JSON.stringify(params);
    }

    private static isValid(item?: CacheItem) {
        return item && item.expired > new Date().getTime();
    }

    executeQuery(dataType: string, toolId: string, query: string, params: Record<string, any>, allowCache = true): Observable<any> {
        const httpParams: Record<string, any> = {
            dataType,
        };

        if (toolId) {
            httpParams.toolId = toolId;
        }

        const request = this.http.post<any>(
            `${this.dpUrl}/sql`,
            {
                params,
                query,
            } as QueryObject,
            {
                params: httpParams,
            }
        );

        if (!allowCache) {
            return request;
        }

        const key = WidgetBuilderService.serializeRequestParams({
            dataType,
            toolId,
            query,
            params,
        });

        let cacheItem = this.cache.get(key);

        if (!WidgetBuilderService.isValid(cacheItem)) {
            cacheItem = {
                item$: request.pipe(publishReplay(1), refCount(), map(cloneDeep)),
                expired: new Date().getTime(),
            };

            this.cache.set(key, cacheItem);
        }

        cacheItem.expired += TTL;

        return cacheItem.item$;
    }

    createWidget(widget: AnyWidgetModel): Observable<AnyWidget> {
        return this.http.put<AnyWidget>(`${this.baseUrl}/`, WidgetBuilderService.convertToDashboardWidget(widget, null));
    }

    updateWidget(widget: AnyWidgetModel, widgetId: number): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}/`, WidgetBuilderService.convertToDashboardWidget(widget, widgetId));
    }

    deleteWidget(widgetId: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${widgetId}`);
    }

    getWidgetFromLibrary(widgetId: number): Observable<AnyWidget> {
        return this.http.get<AnyWidget>(`${this.baseUrl}/${widgetId}`);
    }

    getAllWidgets(): Observable<LightAnyWidget[]> {
        const widgets$ = this.http.get<LightAnyWidget[]>(`${this.baseUrl}/`);

        return this.featureToggleService.isActive(DATA_MORPH_FEATURE.TAGS_VISIBILITY).pipe(
            switchMap(active => {
                if (!active || this.authService.isAdmin()) {
                    return widgets$;
                }

                return widgets$.pipe(
                    switchMap(widgets => {
                        const features = new Set<string>();
                        const widgetsMap = new Map<LightAnyWidget, LightAnyWidget>();
                        const widgetsWithFeaturesMap = new Map<LightAnyWidget, LightAnyWidget>();

                        for (const widget of widgets) {
                            widgetsMap.set(widget, widget);

                            if (widget.data.ftTags?.length) {
                                widgetsWithFeaturesMap.set(widget, widget);
                            }

                            for (const ftTag of widget.data.ftTags ?? []) {
                                features.add(ftTag);
                            }
                        }

                        const featuresArray = Array.from(features);

                        return features.size === 0
                            ? of(widgets)
                            : this.featureToggleService.areFeaturesActive(featuresArray).pipe(
                                  catchError(() =>
                                      of(
                                          Array.from<boolean>({ length: featuresArray.length }).fill(false)
                                      )
                                  ),
                                  map(featuresStatus => {
                                      if (featuresStatus.length !== 0 && !featuresStatus.every(Boolean)) {
                                          const featuresMap: ReadonlyMap<string, boolean> = new Map<string, boolean>(
                                              featuresArray.map((feature, idx) => [feature, featuresStatus[idx]])
                                          );

                                          for (const widget of widgetsWithFeaturesMap.keys()) {
                                              const { data } = widget;

                                              if (!data || !data.ftTags || data.ftTags.length === 0) {
                                                  continue;
                                              }

                                              if (data.ftTags.some(feature => !featuresMap.get(feature))) {
                                                  widgetsMap.delete(widget);
                                              }
                                          }
                                      }

                                      return Array.from(widgetsMap.keys());
                                  })
                              );
                    })
                );
            })
        );
    }

    cloneWidgetInLibrary(widgetId: number): Observable<AnyWidget> {
        return this.http.get<AnyWidget>(`${this.baseUrl}/${widgetId}/clone`);
    }

    getExportExcelWidget(widgetData: ExcelExportDto[]): Observable<Blob> {
        return this.http.post(`${this.insightUrl}/getExportWidget`, widgetData, { responseType: 'blob' });
    }

    calculateWidget(parameters: ExecutionParametersApi) {
        const data = cloneDeep(parameters.widget.data);

        return this.http.post<ExecutionResult>(`${this.dpUrl}/calculate-widget`, {
            ...parameters,
            widget: {
                ...parameters.widget,
                data,
            },
        });
    }

    calculateWidgetById(id: number, parameters: ExecutionParametersApi) {
        return this.http.post<ExecutionResult>(`${this.dpUrl}/calculate-widget/${id}`, parameters);
    }

    runSelfTestByWidgetId(id: number, parameters: ExecutionParametersApi) {
        return this.calculateWidgetById(id, parameters);
    }

    calculateDynamicDashboard(parameters: ExecutionParametersApi) {
        return this.http.post<ExecutionResult>(`${this.dpUrl}/calculate-dashboard/dynamic`, parameters);
    }

    calculateDashboard(parameters: ExecutionParametersApi) {
        return this.http.post<ExecutionResult>(`${this.dpUrl}/calculate-dashboard`, parameters);
    }

    calculateDashboardById(id: number, parameters: ExecutionParametersApi) {
        return this.http.post<ExecutionResult>(`${this.dpUrl}/calculate-dashboard/${id}`, parameters);
    }

    importWidget() {
        return from(loadFile('application/JSON')).pipe(
            switchMap(fileList => readFileToObject(fileList.item(0))),
            map((widget: AnyWidgetModel) => {
                widget.id = generateUUID();
                this.adjustWidgetSize(widget);

                return widget;
            })
        );
    }

    getAllWidgetQueries(): Observable<WidgetQueryDto[]> {
        return this.http.get<WidgetQueryDto[]>(`${this.dpUrl}/widget-queries`);
    }

    getWidgetQuery(name: string): Observable<WidgetQueryDto> {
        return this.http.get<WidgetQueryDto>(`${this.dpUrl}/widget-queries/${name}`);
    }

    ngOnDestroy() {
        window.clearInterval(this.clearCacheInterval);
    }

    adjustWidgetSize(widget: AnyWidgetModel) {}

    private checkClearCache() {
        this.zone.runOutsideAngular(() => {
            this.clearCacheInterval = window.setInterval(() => {
                for (const [key, { expired }] of this.cache) {
                    if (expired < new Date().getTime()) {
                        this.cache.delete(key);
                    }
                }
            }, 2 * TTL);
        });
    }
}

function adjustDefaultSize(dimension: 'W' | 'H', widget: AnyWidgetModel) {}

export interface ExcelExportDto {
    name: string;
    seriesList?: ExcelSeries[];
    type: string;
    sheetName?: string;
    categories?: string[];
    stacked?: boolean;
    horizontal?: boolean;
    image?: string;
    total?: boolean;
    seriesMap?: Record<string, ExcelSeries[]>;
    additionalInfo?: string;
}

interface ExcelSeries {
    name?: string;
    value?: number[];
    children?: ExcelSeries[];
    gridValue?: any;
}

export interface AnyWidget {
    id: number;
    dashboardId: number;
    uuid: string;
    groupId: number;

    data: AnyWidgetModel;

    options?: DashboardWidgetOptions;
}

export interface DashboardWidgetOptions {
    toolCategoryType?: WidgetToolCategoryType;
    index: number;
    size: WidgetSize;
}

interface QueryObject {
    query: string;
    params: Record<string, any>;
}

export interface DropdownOptionItem<T = string> extends DropdownItem<T> {
    preferred?: boolean;
    wcFilterOrder?: number;
}

export type Placeholders = Record<string | symbol, any> & {
    [BasePlaceholders.OPTIONS]?: Record<string, DropdownOptionItem<any>[]>;
    [BasePlaceholders.RESULT]?: any;
};

export type ExecutionParametersApi = Omit<ExecutionParameters, 'widget'> & { widget: AnyWidget };

export interface ExecutionParameters {
    widget: AnyWidgetModel;
    placeholders: Placeholders;
    filters?: WidgetFilter[];
    results: Placeholders[];
    range: {
        drilldowns: number[][];
        from: Breakpoint | null;
        to: Breakpoint | null;
    };
}

export enum WidgetScript {
    BEFORE = 0,
    SQL = 1,
    AFTER = 2,
}

export interface Breakpoint {
    widgetId?: string;
    queryId?: string;

    level: number;
    placeholder: string;
    script: WidgetScript;
}

export interface ExecutionResult {
    placeholders: Placeholders;
    results: Placeholders[];
    errors: string[];
}

export interface LightAnyWidget {
    id: AnyWidget['id'];
    data: Pick<AnyWidgetModel, 'ftTags' | 'type' | 'system'> & {
        chartOptions: Pick<ChartOptions, 'description' | 'title'>;
    };
}

export interface WidgetQueryDto {
    id: number;
    name: string;
    data: WidgetQuery;
}
