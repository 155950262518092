import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
@Component({
    selector: 'app-tour-menu',
    templateUrl: './tour-menu.component.html',
    styleUrls: ['./tour-menu.component.scss'],
})
export class TourMenuComponent implements OnInit {
    @Input() stepData: any;
    @Input() steps: any;
    @Input() tourTitle: string;
    @Input() tourId: string;
    @Input() that: any;

    currentStep: number;

    constructor(public activeModal: NgbActiveModal) {}

    ngOnInit(): void {
        this.steps.forEach((value: any, i: number) => {
            value['keyId'] = i;
            if (this.stepData && this.stepData.stepId === value.stepId) {
                this.currentStep = i;
            }
        });
        // temporarily added.. will be removed once implemented actual popover position
        if (['multi-level-filter', 'create-organization', 'create-policy'].includes(this.stepData.anchorId)) {
            setTimeout(() => {
                const element = document.getElementsByClassName('modal-dialog') as HTMLCollectionOf<HTMLElement>;
                element.item(0).classList.add('left-modal');
            }, 500);
        }
    }

    switchToStep(stepId: number | string) {
        this.activeModal.close();
        this.that.startAt(stepId);
    }
}
