import { Component, Output, EventEmitter, Input } from '@angular/core';

import { WorkflowAction, WorkflowActionType } from '../../models/actions';

@Component({
    selector: 'dp-job-definition-actionsbox',
    templateUrl: './job-definition-actionsbox.component.html',
    styleUrls: ['./job-definition-actionsbox.component.scss'],
    host: {
        '[class.border-right-0]': 'collapsed',
        '[class.p-3]': '!collapsed',
    },
})
export class JobDefinitionActionsboxComponent {
    @Input() collapsed: boolean;
    @Input() dragDisabled: boolean;

    @Output() dropped = new EventEmitter();
    @Output() collapseClick = new EventEmitter();

    actions: WorkflowAction[] = ([
        'CALCULATION',
        'CHANNEL',
        'CONDITION',
        'DATASOURCE',
        'FORK',
        'GROOVY',
        'LOOP',
        'PUBLISH',
        'REFERENCE',
        'TRANSFORMATION',
        'UPDATE',
        'QUERY',
    ] as WorkflowActionType[])
        .map(type => ({
            name: `${type.charAt(0).toUpperCase()}${type.slice(1).toLowerCase()}`,
            next: null,
            type,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));

    onDrop(event: any) {
        this.dropped.emit(event);
    }
}
