import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Organization, ToolList } from '@app/models';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ENV_TOKEN } from '@app/tokens';
import { FeatureToggleService } from '@app/services';

@Injectable({
    providedIn: 'root',
})
export class OrganizationService {
    appSourcesBaseUrl = `${this.env.adminApiURL}/organizations`;
    imageuploadpath = `${this.env.adminApiURL}/images`;
    public readonly organizationLogo = new Subject<any>();
    public sidemenuSwitch = new Subject<any>();

    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient, private featureToggleService: FeatureToggleService) {}

    getAllOrganizations(canListAllOrganizations: boolean): Observable<Organization[]> {
        if (canListAllOrganizations) {
            return this.http.get<Organization[]>(`${this.appSourcesBaseUrl}`);
        } else {
            return this.http.get<Organization[]>(`${this.appSourcesBaseUrl}/organizationsBasedOnUser`);
        }
    }
    getAdGroupList(): Observable<any> {
        return this.http.get<any>(`${this.appSourcesBaseUrl}/keycloakAdGroups`);
    }
    validateAdGroup(adGroup: string): Observable<any> {
        return this.http.get<any>(`${this.appSourcesBaseUrl}/validateAdGroup?adGroup=${adGroup}`);
    }
    getUsersList(orgId: number, policyId: number): Observable<Record<string, any>> {
        return this.http.get<any>(`${this.appSourcesBaseUrl}/${orgId}/policyAssignment/${policyId}`);
    }

    saveUsersList(orgId: number, policyId: number, userList: any): Observable<any> {
        return this.http.post<any>(`${this.appSourcesBaseUrl}/${orgId}/policyAssignment/${policyId}`, userList);
    }

    uploadAvatar(file: File, extraArgs: string = ''): Observable<string> {
        const formData = new FormData();
        formData.append('file', file);
        if (extraArgs) {
            formData.append('extraArgs', extraArgs);
        }
        return this.http.post<string>(`${this.imageuploadpath}`, formData).pipe(map((result: any) => result.imagePath));
    }

    deleteAvatar(): Observable<any> {
        return this.http.delete<string>(`${this.imageuploadpath}/logo`);
    }

    getAvatar(): Observable<any> {
        return this.http.get<any>(`${this.imageuploadpath}/logo`).pipe(map((result: any) => result.imagePath));
    }

    OrganizationSwich(): Observable<any> {
        return this.featureToggleService.isActive('Organization', true);
    }

    UpdateOrganization(data: Organization): Observable<Organization> {
        return this.http.put<Organization>(`${this.appSourcesBaseUrl}/${data.id}`, data);
    }

    CreateOrganization(data: any): Observable<any> {
        return this.http.post<any>(`${this.appSourcesBaseUrl}`, data);
    }

    getResourcesData(resourceArgument: any = {}): Observable<ToolList[]> {
        return this.http.get<ToolList[]>(`${this.appSourcesBaseUrl}/policyMapping`, { params: resourceArgument });
    }

    createPolicy(data: Record<string, any>, id: number) {
        return this.http.post<any>(`${this.appSourcesBaseUrl}/${id}/policies`, data);
    }

    getPolicyDatabyId(orgId: number, policyID: number): Observable<Record<string, any>> {
        return this.http.get<any>(`${this.appSourcesBaseUrl}/${orgId}/policies/${policyID}`);
    }

    updatePolicy(data: Record<string, any>, id: number) {
        return this.http.put<any>(`${this.appSourcesBaseUrl}/${id}/policies/${data.id}`, data);
    }

    getPreviwPolicyData(data: any): Observable<any> {
        return this.http.post<any>(`${this.appSourcesBaseUrl}/previewPolicyData`, data);
    }

    getPolicyListData(orgId: number): Observable<Record<string, any>> {
        return this.http.get<any>(`${this.appSourcesBaseUrl}/${orgId}/policies`);
    }

    deletePolicy(orgId: number, policyId: number): Observable<Record<string, any>> {
        return this.http.delete<any>(`${this.appSourcesBaseUrl}/${orgId}/policies/${policyId}`);
    }
}
export interface users {
    userId: number;
    name: string;
    policyAssigned: boolean;
}
