import { Component } from '@angular/core';
import { SreGanttService } from '../services/sre-gantt.service';

@Component({
    selector: 'dp-sre-gantt-tree',
    templateUrl: './sre-gantt-tree.component.html',
    styleUrls: ['./sre-gantt-tree.component.scss'],
})
export class SreGanttTreeComponent {
    constructor(public service: SreGanttService) {}
}
