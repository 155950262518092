import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { HealthService } from '@app/shared/services/admin/health-service';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ConveyorHealthGuard implements CanActivate {
    constructor(private healthService: HealthService) {}

    canActivate(): Observable<boolean> {
        return this.healthService.getIsServiceAlive('conveyor').pipe(filter((res: boolean) => res !== null));
    }
}
