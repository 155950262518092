<div class="d-flex d-inline-flex flex-wrap">
    <div class="cursor-default my-2px"
        [ngClass]="{'mr-30px': isMyScope, 'mr-10px': !isMyScope}">
        {{title}}
    </div>
    <div class="d-flex d-inline-flex flex-wrap">
        <div *ngFor="let item of legendItemsArray" class="d-flex d-inline-flex my-2px"
             [ngClass]="{'mr-30px': isMyScope, 'mr-15px': !isMyScope}">
            <div [class.relationship-legend-follower]=" item.relationshipType == relationshipType.FOLLOWER "
                [class.relationship-legend-owner]=" item.relationshipType == relationshipType.OWNER "
                [class.relationship-legend-contributor]=" item.relationshipType == relationshipType.CONTRIBUTOR "
                [class.relationship-legend-none]=" item.relationshipType == relationshipType.NONE "
                class="d-inline-flex align-items-center">
                <div *ngIf="!item.icon || ['line', 'square'].indexOf(item.icon) > -1"
                    class="relationship-legend-item d-inline-flex align-items-center">
                    <div [class.square]="!item.icon || item.icon == relationshipLegendDefaultIcon.square"
                        [class.line]="item.icon == relationshipLegendDefaultIcon.line">
                    </div>
                </div>
                <fa-icon *ngIf="item.icon && ['line', 'square'].indexOf(item.icon) < 0 " [icon]="item.icon"
                    class="relationship-legend-icon"></fa-icon>
            </div>
            <div class="relationship-legend-text overme">
                {{item.label}}
            </div>
        </div>
    </div>
</div>
