import { Injectable } from '@angular/core';
import { Store } from '@dagility-ui/kit';
import { omit, cloneDeep, debounce } from 'lodash';
import { Subject } from 'rxjs';

import { DataMorph } from '../models/dp-dashboard.model';
import { GridsterOptionsService } from '../services/gridster/gridster-options.service';
import { MIN_WIDGET_SIZE, WidgetWithMinSizes } from '../services/gridster/angular2gridster.const';
import { DashboardGroupState, DpDashboardState } from './dp-dashboard.state.model';

@Injectable()
export class DpDashboardStore extends Store<DpDashboardState> {
    defaultValue: DpDashboardState;
    loadedTabs: Record<number, boolean> = {};
    optionsChanged$ = new Subject<void>();
    eventDependencies: Record<string, Subject<any>> = {};
    previousTab: number[] = [];
    ignoreReorder = false;

    private backupedGridsterState: Record<string, any> = null;

    constructor(private gridsterOptions: GridsterOptionsService) {
        super(null);
    }

    init = ({ dashboard, widgets }: { dashboard: DataMorph.Dashboard; widgets: DataMorph.Widget[] }, edit: boolean, startTab: string) => {
        this.loadedTabs = {};
        const { widgetsMap, widgetGroups } = (widgets || []).reduce<
            Pick<DpDashboardState, 'widgetsMap'> & { widgetGroups: Record<number, number[]> }
        >(
            (acc, widget) => {
                if (widget.data) {
                    (widget as WidgetWithMinSizes).breakpointMinSizes = this.gridsterOptions.fillMinSizes(widget.data);
                }
                acc.widgetsMap[widget.id] = widget;
                if (!acc.widgetGroups[widget.groupId]) {
                    acc.widgetGroups[widget.groupId] = [];
                }
                acc.widgetGroups[widget.groupId].push(widget.id);

                return acc;
            },
            {
                widgetsMap: {},
                widgetGroups: {},
            }
        );

        const { tabsMap, groupsMap, tabs, expandedGroups } = (dashboard.tabs || []).reduce<
            Pick<DpDashboardState, 'tabsMap' | 'tabs' | 'groupsMap' | 'expandedGroups'>
        >(
            (acc, tab) => {
                const groups = tab.groups ?? [];

                acc.tabs.push(tab.id);
                acc.expandedGroups[tab.id] = [];
                acc.tabsMap[tab.id] = {
                    ...tab,
                    groups: groups.map(({ id }) => id),
                };

                groups.forEach(group => {
                    acc.expandedGroups[tab.id].push(`g-${group.id}`);

                    acc.groupsMap[group.id] = {
                        ...group,
                        widgets: this.sortWidgetIds(widgetGroups[group.id] || [], widgetsMap),
                    };
                });

                return acc;
            },
            {
                tabsMap: {},
                groupsMap: {},
                expandedGroups: {},
                tabs: [],
            }
        );
        const sortedTabs = this.sortTabIds(tabs, tabsMap);
        const activeTab = this.getStartTabId(startTab, tabsMap, sortedTabs);
        this.loadedTabs[activeTab] = true;
        const options = dashboard.options ?? {};

        this.setState({
            ...dashboard,
            options: {
                ...options,
                gridsterSettings: this.getGridsterSettings(groupsMap, widgetsMap, options.gridsterSettings ?? {}),
            },
            tabs: sortedTabs,
            groupsMap,
            tabsMap,
            widgetsMap,
            activeTab,
            edit: !!edit,
            expandedGroups,
        });
    };

    getStartTabId = (name: string, tabsMap: any, sortedTabs: any[]) => {
        if (name && tabsMap) {
            for (const key of Object.keys(tabsMap)) {
                if (tabsMap[key] && tabsMap[key].name === name) {
                    return +key;
                }
            }

            return null;
        } else {
            return sortedTabs[0] ?? null;
        }
    };

    getWidgetGridsterSettings(widget: DataMorph.IlluminateDashboardWidget, widgetSettings: any) {
        return (
            widgetSettings ?? {
                w: MIN_WIDGET_SIZE,
                h: MIN_WIDGET_SIZE,
                x: 0,
                y: 0,
            }
        );
    }

    getGridsterSettings(
        groupsMap: Record<string, DashboardGroupState>,
        widgetsMap: Record<number, DataMorph.Widget>,
        gridsterSettings: Record<string, any>
    ) {
        return Object.keys(groupsMap).reduce<Record<string, any>>((acc, groupId: string) => {
            if (!acc[groupId]) {
                acc[groupId] = {};
            }
            const group = groupsMap[groupId];
            const groupSettings = gridsterSettings[groupId] || {};

            group.widgets.forEach(widgetId => {
                const widget = widgetsMap[widgetId];
                const widgetSettings = groupSettings[widgetId];

                acc[groupId][widgetId] = this.getWidgetGridsterSettings(widget as any, widgetSettings);
            });

            return acc;
        }, {});
    }

    updateDashboard(dashboard: DataMorph.Dashboard) {
        const { tabs } = this.value;

        this.setState({
            ...dashboard,
            tabs,
        });
    }

    changeTab(activeTab: number) {
        this.previousTab.push(this.value.activeTab);
        this.loadedTabs[activeTab] = true;

        this.setState({
            activeTab,
        });
    }

    backToPreviousTab() {
        this.setState({
            activeTab: this.previousTab[this.previousTab.length - 1],
        });

        this.previousTab.pop();
    }

    setGroupState(tabId: number, panelId: string, state: boolean) {
        const ids = this.value.expandedGroups[tabId] ?? [];

        this.setState({
            expandedGroups: {
                ...this.value.expandedGroups,
                [tabId]: state ? [...ids, panelId] : ids.filter(id => id !== panelId),
            },
        });
    }

    setGroup(groupId: number, state: DashboardGroupState, tabId: number) {
        state.name = removeExtraSpaces(state.name);

        const { [groupId]: _, ...rest } = this.value.groupsMap;
        const groupsMap = {
            ...rest,
            [groupId]: state,
        };
        const tab = this.value.tabsMap[tabId];

        this.setState({
            groupsMap,
            tabsMap: {
                ...this.value.tabsMap,
                [tabId]: {
                    ...tab,
                    groups: this.sortGroupIds(tab.groups || [], groupsMap),
                },
            },
        });
    }

    setEdit(edit: boolean) {
        if (edit) {
            this.defaultValue = { ...this.value };
        }

        if (
            !edit &&
            this.value.dashboardOptions?.visibility === DataMorph.DashboardTabVisibility.SHOW_ACTIVE &&
            this.value.tabs.length &&
            this.value.tabs.indexOf(this.value.activeTab) !== 0
        ) {
            this.setState({
                edit,
                activeTab: this.value.tabs[0],
            });

            return;
        }

        this.setState({ edit });
    }

    addGroup(group: DataMorph.DashboardGroup) {
        group.name = group.name.replace(/\s+/g, ' ');

        const { [group.tabId]: tab } = this.value.tabsMap;
        const { [group.tabId]: expandedMap } = this.value.expandedGroups;
        const groupsMap: DpDashboardState['groupsMap'] = {
            ...this.value.groupsMap,
            [group.id]: {
                ...group,
                widgets: [],
            },
        };
        this.value.options.gridsterSettings[group.id] = {};

        this.setState({
            tabsMap: {
                ...this.value.tabsMap,
                [group.tabId]: {
                    ...tab,
                    groups: this.sortGroupIds([group.id, ...tab.groups], groupsMap),
                },
            },
            groupsMap,
            expandedGroups: {
                ...this.value.expandedGroups,
                [group.tabId]: [...(expandedMap || []), `g-${group.id}`],
            },
        });
    }

    deleteGroup(tabId: number, groupId: number) {
        const { [groupId]: deletedGroup, ...rest } = this.value.groupsMap;
        const { widgets } = deletedGroup;
        const tab = this.value.tabsMap[tabId];

        this.setState({
            tabsMap: {
                ...this.value.tabsMap,
                [tabId]: {
                    ...tab,
                    groups: tab.groups.filter(id => id !== groupId),
                },
            },
            groupsMap: rest,
            widgetsMap: omit(this.value.widgetsMap, widgets),
        });
    }

    addTab(tab: DataMorph.DashboardTab) {
        tab.name = removeExtraSpaces(tab.name);

        const { tabs, tabsMap } = this.value;
        const newTabsMap: DpDashboardState['tabsMap'] = {
            ...tabsMap,
            [tab.id]: { ...tab, groups: [] },
        };

        this.loadedTabs[tab.id] = true;
        this.setState({
            tabsMap: newTabsMap,
            tabs: this.sortTabIds([...tabs, tab.id], newTabsMap),
            activeTab: tab.id,
        });
    }

    updateTab(tab: DataMorph.DashboardTab) {
        tab.name = removeExtraSpaces(tab.name);

        const { [tab.id]: tabFromStore } = this.value.tabsMap;
        const tabsMap = {
            ...this.value.tabsMap,
            [tab.id]: {
                ...tab,
                groups: tabFromStore.groups,
            },
        };

        this.setState({
            tabsMap,
            tabs: this.sortTabIds(this.value.tabs, tabsMap),
        });
    }

    deleteTab(id: number) {
        const { tabs, groupsMap, widgetsMap, activeTab } = this.value;
        const { [id]: tab, ...tabsMap } = this.value.tabsMap;
        const { groups } = tab;
        const widgets = groups.reduce((acc, group) => [...acc, ...groupsMap[group].widgets], []);
        const filteredTabs = tabs.filter(tabId => tabId !== id);

        this.setState({
            activeTab: activeTab === id ? filteredTabs?.[0] : activeTab,
            tabs: filteredTabs,
            tabsMap,
            groupsMap: omit(groupsMap, groups),
            widgetsMap: omit(widgetsMap, widgets),
        });
    }

    getGroupsByTab(id: number): DataMorph.DashboardGroup[] {
        const tab = this.value.tabsMap[id];

        if (!tab) {
            throw new Error(`Tab with id ${id} doesnt exist`);
        }

        const { groupsMap } = this.value;

        return tab.groups.map(groupId => {
            const { widgets, ...group } = groupsMap[groupId];

            return group;
        });
    }

    deleteWidget(id: number) {
        const { [id]: widget, ...widgetsMap } = this.value.widgetsMap;
        const group = this.value.groupsMap[widget.groupId];

        delete this.value.options.gridsterSettings[group.id][widget.id];

        this.setState({
            widgetsMap,
            groupsMap: {
                ...this.value.groupsMap,
                [group.id]: {
                    ...group,
                    widgets: group.widgets.filter(widgetId => id !== widgetId),
                },
            },
        });
    }

    addWidget(widget: DataMorph.IlluminateDashboardWidget, gridsterOptions = {}) {
        const { [widget.groupId]: group } = this.value.groupsMap;
        const { widgets } = group;
        const widgetsMap: DpDashboardState['widgetsMap'] = {
            ...this.value.widgetsMap,
            [widget.id]: widget,
        };

        this.value.options.gridsterSettings[group.id][widget.id] = this.getWidgetGridsterSettings(widget, gridsterOptions);

        this.setState({
            groupsMap: {
                ...this.value.groupsMap,
                [widget.groupId]: {
                    ...group,
                    widgets: this.sortWidgetIds([...widgets, widget.id], widgetsMap),
                },
            },
            widgetsMap,
        });
    }

    changeGroupVisibility(groupName: string, state: DataMorph.DashboardGroupVisibility) {
        const group = Object.values(this.value.groupsMap).find(({ name }) => name === groupName);
        if (!group) {
            console.error(`Cannot find group with name ${groupName}`);
            return;
        }
        this.setStateForGroupVisibility(group, state);
    }

    changeGroupVisibilityByKey(groupName: string, groupKey: number, state: DataMorph.DashboardGroupVisibility) {
        const group = Object.values(this.value.groupsMap).find(({ name, key }) => name === groupName && key === groupKey);
        if (!group) {
            console.error(`Cannot find group with name ${groupName} and key ${groupKey}`);
            return;
        }
        this.setStateForGroupVisibility(group, state);
    }

    backupGridsterState = debounce(() => {
        this.backupedGridsterState = cloneDeep(this.value.options.gridsterSettings);
    });

    restoreGridsterState() {
        Object.assign(this.value.options.gridsterSettings, this.backupedGridsterState);

        this.resetBackupState();
    }

    resetBackupState() {
        this.backupedGridsterState = null;
    }

    readonly isGroupEmptySelector = (groupId: number) => (state: DpDashboardState) => !state.groupsMap[groupId]?.widgets?.length;

    isGroupEmpty(groupId: number) {
        return this.select(this.isGroupEmptySelector(groupId));
    }

    isGroupEmptySync(groupId: number) {
        return this.selectSync(this.isGroupEmptySelector(groupId));
    }

    getGroupNamesByTab(tabId: number) {
        return new Set(this.getGroupsByTab(tabId).map(group => group.name));
    }

    getTabNames() {
        return new Set(this.value.tabs.map(tabId => this.value.tabsMap[tabId].name));
    }

    private setStateForGroupVisibility(group: DataMorph.DashboardGroup & { widgets: number[] }, state: DataMorph.DashboardGroupVisibility) {
        this.setState({
            groupsMap: {
                ...this.value.groupsMap,
                [group.id]: {
                    ...group,
                    definition: {
                        ...group.definition,
                        type: state,
                    },
                },
            },
        });
    }

    private sortTabIds(tabs: number[], tabsMap: DpDashboardState['tabsMap']) {
        return [...tabs].sort((a, b) => (tabsMap[a].options?.tabOrder || 0) - (tabsMap[b].options?.tabOrder || 0));
    }

    private sortWidgetIds(widgets: number[], widgetsMap: DpDashboardState['widgetsMap']) {
        return [...widgets].sort((a, b) => (widgetsMap[a].options?.index || 0) - (widgetsMap[b].options?.index || 0));
    }

    private sortGroupIds(groups: number[], groupsMap: DpDashboardState['groupsMap']) {
        return [...groups].sort((a, b) => groupsMap[a].groupOrder - groupsMap[b].groupOrder);
    }
}

export function removeExtraSpaces(str: string) {
    return str.trim().replace(/\s+/g, ' ');
}
