import { Injectable } from '@angular/core';
import { IModuleParamSubstitution } from '@app/core/services/module-param-substitution';
import { ModuleConfig } from '@app/core/services/modules-api.service';
import { map } from 'rxjs/operators';
import { KeepNIConfigService } from '@app/core/keep-ni/keep-ni-config.service';

@Injectable({
    providedIn: 'root',
})
export class KeepNiEnvSubstitution extends IModuleParamSubstitution {
    constructor(private keepNIConfigService: KeepNIConfigService) {
        super();
    }

    process(mod: ModuleConfig): void {
        mod.appUrl$ = this.keepNIConfigService.loadEnvironment().pipe(
            map(c => mod.appUrl.replace('{keep-ni-env-id}', c.envId))
        );
    }
}
