<div id="gridwrapper" class="wrapper h-100 pb-10px">
    <nav
        ngbNav
        [activeId]="activeTab"
        #nav="ngbNav"
        (activeIdChange)="tabStatus[$event] = true; activeTab = $event"
        libScroller
        class="nav nav-tabs justify-content-start middle"
        #widgetsContent
        [destroyOnHide]="false"
    >
        <ng-container *ngFor="let nav of items; index as index" [ngbNavItem]="nav.tabName">
            <a ngbNavLink>{{ nav.tabName }}
                <span *ngIf="nav.tabCount" class="custom-badge size-s custom-badge-background text-white ml-5px m-0">{{nav.tabCount}}</span>
            </a>
            <ng-template ngbNavContent>
                <dp-grid-widget-wrapper
                    *ngIf="tabStatus[nav.tabName]"
                    class="d-block w-100 h-100"
                    [paginationState]="true"
                    [chartModel]="{
                        options: {
                          tableId: nav.tabId,
                          tableData: nav.table.tableData
                        }
                    }"
                    [showResetButton]="nav.table.hasResetFilter"
                    (cellClicked)="handleDrilldownEvent($event, index)">

                </dp-grid-widget-wrapper>
            </ng-template>

        </ng-container>
    </nav>
    <div [ngbNavOutlet]="nav"></div>
</div>
