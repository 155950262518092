import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges, OnDestroy, TemplateRef } from '@angular/core';
import { ControlContainer, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { merge, Subscription } from 'rxjs';
import { filter, first, map, pairwise, startWith, withLatestFrom } from 'rxjs/operators';
import { faChevronDown, faChevronRight, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { dropRight, get, startCase } from 'lodash';

import { EditJobDefinitionService } from '../edit-job-definition.service';
import { createFormGroup, findLastDataSource, getFieldTypes, isCollection, isDeserializable, sortByFieldType, isPrimitive } from '../utils';
import { DEFINITION_ERRORS, FIELD_NAMES, WARNING_NAMES } from '../field-names';
import { AbstractJobDefinitionTemplate } from '../abstract-job-definition-template';
import {
    DataFilter,
    DataFilterOperation,
    Deserializable,
    HIDDEN_FIELDS,
    JobDefinition,
    JobDefinitionDataSource,
    JobDefinitionDataType,
    PostUpdate,
    PrimitiveDataType,
} from '../models/job-definition';
import { getValidatorsByField } from '../models/validate.decorator';
import { FieldTypes } from '../models/common';
import { CustomIcon } from '@dagility-ui/shared-components/icons';

@Component({
    selector: 'dp-edit-job-definition-template',
    templateUrl: './edit-job-definition-template.component.html',
    styleUrls: ['./edit-job-definition-template.component.scss', '../templates.scss'],
    host: {
        class: 'd-flex flex-column',
        '[class.template-container]': '!isFirstLevel',
        '[class.p-4]': '!isFirstLevel',
        '[class.pl-0]': 'isFirstLevel',
    },
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditJobDefinitionTemplateComponent extends AbstractJobDefinitionTemplate implements OnChanges, OnDestroy {
    @Input() isFirstLevel = false;
    @Input() updatingRequest: boolean;
    @Input() key: string;

    icons: Record<string, IconDefinition | CustomIcon> = {
        chevronRight: faChevronRight,
        chevronDown: faChevronDown,
    };

    fieldTypes: FieldTypes;

    contextHelpAnchors: Record<string, any> = {};

    removeKeysArray: string[] = [];
    addKeys: string[] = [];
    notDeletedFields: any = [];

    isDeserializable = isDeserializable;
    isFilter = false;
    isPrimitiveDataType = PrimitiveDataType.isPrimitiveDataType;
    sortByFieldType = sortByFieldType;

    customErrors = {
        fieldRequired: ({ field }: any) => `${field} is required`,
    };

    private subscription: Subscription;

    constructor(
        @Inject(FIELD_NAMES) private fieldNames: Record<string, any>,
        @Inject(WARNING_NAMES) public warnings: Record<string, any>,
        @Inject(DEFINITION_ERRORS) public definitionErrors: Record<string, any>,
        public expandedControl: EditJobDefinitionService,
        @Inject('templateProvider') public templateProvider: Record<string, TemplateRef<any>>
    ) {
        super();
    }

    ngOnChanges() {
        this.isFilter = this.definition instanceof DataFilter;

        this.checkDataSourceTypeDisabled();

        this.fieldTypes = getFieldTypes(
            this.definition,
            this.isFilter ? this.definition.meta : this.meta,
            [...(this.definition instanceof JobDefinition ? HIDDEN_FIELDS : []), ...Deserializable.METAFIELDS],
            this.fieldNames
        );

        const contextKey = typeof this.key === 'string' ? this.key.toLowerCase() : (this.key as number).toString().toLowerCase();

        this.contextHelpAnchors = {
            From: `${contextKey.toLowerCase()}-from`,
            Type: `${contextKey.toLowerCase()}-type`,
            Required: `${contextKey.toLowerCase()}-required`,
            'Source Field': `source-field`,
            'Target Field': `target-field`,
            'Data Type': `data-type`,
            'Publish Type': `publish-type`,
            Target: `job-target`,
            Keys: `${contextKey.toLowerCase()}-keys`,
            Columns: `${contextKey.toLowerCase()}-columns`,
        };

        this.removeKeysArray = this.generateRemoveKeys();

        this.addKeys = this.generateAddKeys();

        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this.subscription = merge(
            ...Object.keys(this.fieldTypes)
                .filter(key => ['string', 'boolean', 'number', 'enum'].includes(this.fieldTypes[key].type))
                .map(key => this.formSlice.get(key).valueChanges.pipe(map(value => ({ key, value }))))
        ).subscribe(({ key, value }) => {
            this.definition[key] = value;
            if (value && this.definition.typingErrors[key]) {
                delete this.definition.typingErrors[key];
            }
        });

        if (this.isFilter) {
            if (this.fieldTypes.operation) {
                this.expandedControl.path$.pipe(first()).subscribe(path => {
                    const ds = findLastDataSource(path, this.expandedControl.formJD);
                    let type = null;

                    if (!!ds) {
                        type = ds instanceof JobDefinitionDataSource ? ds.type : ds.dataType;
                    }

                    if (!ds || type !== JobDefinitionDataType.ELASTICSEARCH) {
                        this.fieldTypes.operation = {
                            ...this.fieldTypes.operation,
                            ...{
                                items: (this.fieldTypes.operation.items || []).filter(item => item.id != DataFilterOperation.STRING),
                            },
                        };
                    }
                });
            }
            const operationChanges$ = this.formSlice
                .get('operation')
                .valueChanges.pipe(
                    startWith(this.definition.operation),
                    pairwise(),
                    filter(([oldOperation, newOperation]) => oldOperation !== newOperation),
                    map(([_, operation]) => operation),
                    withLatestFrom(this.expandedControl.path$)
                )
                .subscribe(([operation, path]: [DataFilterOperation, string[]]) => {
                    const parent = get(this.expandedControl.formJD, dropRight(path), this.expandedControl.formJD);
                    const formParent = this.expandedControl.form.get(dropRight(path));
                    this.definition.operation = operation;
                    const dataFilter = DataFilter.Of({ operation });
                    parent[this.key] = dataFilter;
                    this.definition = dataFilter;
                    const group = createFormGroup(this.expandedControl.fb, dataFilter, dataFilter, true);
                    (formParent as FormGroup).setControl(this.key, group);
                    this.formSlice = group as any;
                    this.expandedControl.requestUpdateView();
                    setTimeout(() => this.formSlice.updateValueAndValidity());
                });
            if (this.subscription) {
                this.subscription.add(operationChanges$);
            } else {
                this.subscription = operationChanges$;
            }
        }
    }

    generateAddKeys() {
        const instance = this.definition.create();

        return Object.getOwnPropertyNames(instance).reduce(
            (acc, key) =>
                key in this.definition._class &&
                (instance[key] == null || !isPrimitive(instance[key])) &&
                !isCollection(instance[key]) &&
                !this.definition[key] &&
                !this.removeKeysArray.includes(key)
                    ? [...acc, { name: this.fieldNames[key] || startCase(key), key }]
                    : acc,
            []
        );
    }

    generateRemoveKeys() {
        return Object.keys(this.definition).reduce(
            (acc, key) =>
                isDeserializable(this.definition[key]) && !getValidatorsByField(this.definition, key).includes(Validators.required)
                    ? [...acc, key]
                    : acc,
            []
        );
    }

    checkDataSourceTypeDisabled() {
        const isDataSource = this.definition instanceof JobDefinitionDataSource;
        if (isDataSource || this.definition instanceof PostUpdate) {
            const needDisable = this.definition.hasStringFilter();
            const typeControl = this.formSlice.get(isDataSource ? 'type' : 'dataType');

            if (typeControl) {
                if (needDisable && typeControl.enabled) {
                    typeControl.disable();
                } else if (!needDisable && typeControl.disabled) {
                    typeControl.enable({ onlySelf: true });
                }
            }
        }
    }

    handleFieldChangesOutput(value: any, key: string) {
        if (!(value instanceof Event)) {
            this.definition[key] = value;
        }
    }

    removeField(key: string) {
        this.definition[key] = undefined;
        this.formSlice.removeControl(key);
        this.formSlice.updateValueAndValidity();

        if (this.isFilter && key === 'dataSource') {
            const valueControl = this.formSlice.get('value');
            valueControl.setValidators(Validators.required);
            setTimeout(() => {
                valueControl.updateValueAndValidity({ emitEvent: false });
                this.expandedControl.needRevalidate$.next(null);
            });
        }

        this.expandedControl.requestUpdateView();
    }

    handleDeleteWarningMessage(key: string) {
        delete this.definition.typingErrors[key];
        this.expandedControl.form.updateValueAndValidity();
    }

    addField(key: string) {
        const value = Reflect.construct((this.isFilter ? this.definition.meta : this.meta)['_class'][key], []);
        const group = createFormGroup(this.expandedControl.fb, value, value, true, getValidatorsByField(this.definition, key));

        if (this.formSlice.get(key)) {
            this.formSlice.setControl(key, group);
        } else {
            this.formSlice.addControl(key, group);
        }
        this.definition[key] = value;

        if (this.isFilter && key === 'dataSource') {
            const valueControl = this.formSlice.get('value');
            valueControl.setValidators(null);
            setTimeout(() => {
                valueControl.updateValueAndValidity({ emitEvent: false });
                this.expandedControl.needRevalidate$.next(null);
            });
        }

        this.formSlice.updateValueAndValidity();

        this.expandedControl.requestUpdateView();
    }

    toggleTrigger(key: string) {
        this.definition[key] = !this.definition[key];
    }

    handleNumericChange({ target: { value } }: any, key: string) {
        this.definition[key] = value === '' ? null : +value;
        this.formSlice.get(key).setValue(this.definition[key], { emitEvent: false });
    }

    goToStep(field: string) {
        this.expandedControl.goToStep([field]);
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
