import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {PerfectScrollbarComponent} from 'ngx-perfect-scrollbar';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {Details, LogTypeString, testType} from './self-test-widgets-constants.component';

const BODY_HEIGHT = 555;
const BODY_WIDTH = 958;
const BODY_PADDING = 100;
const LOG_PADDING = 55;

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'app-self-tests-log',
    templateUrl: './self-tests-log-modal.component.html',
    styleUrls: ['./self-tests-log-modal.component.scss'],
})
export class SelfTestsLogModalComponent implements OnInit {
    @ViewChild(PerfectScrollbarComponent) scrollbar: PerfectScrollbarComponent;

    title = 'Log';

    logs: Details[];
    headerTemplateRef: TemplateRef<any>;
    titleTemplateRef: TemplateRef<any>;

    sortBy = ['ERROR', 'WARNING', 'INFO'];

    isExpanded: boolean;

    constructor(public modal: NgbActiveModal) {}

    ngOnInit(): void {
        this.logs.sort((a, b) => this.sortBy.indexOf(a.level) - this.sortBy.indexOf(b.level));
    }

    get bodyWidth() {
        return this.isExpanded ? document.body.clientWidth - BODY_PADDING : BODY_WIDTH;
    }

    get bodyHeight() {
        return this.isExpanded ? document.body.clientHeight - BODY_PADDING : BODY_HEIGHT;
    }

    get logHeight() {
        return this.bodyHeight - LOG_PADDING;
    }

    getCursiveType(str: LogTypeString) {
        return testType[str];
    }
}
