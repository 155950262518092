import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, HostBinding } from '@angular/core';

import { getColorForThresholdRelatedChart } from 'data-processor/lib/widget-library/widget-builder/services/widget-builder.util';

interface Radial {
    value: string;
    title: string;
    link?: string;
    additionalInfo?: [{ label: string; value: string }];
    subLabel?: string;
}

@Component({
    selector: 'dp-pi-radial',
    templateUrl: './pi-radial.component.html',
    styleUrls: ['./pi-radial.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PiRadialComponent {
    @Input() radial: Radial;
    @Input() additionalInfo: any[];
    @HostBinding('attr.size') @Input() size: 'md' | 'lg' | 'sm' = 'md';

    @Output() linkClicked = new EventEmitter();

    get value() {
        return +this.radial.value;
    }

    get thresholdColor() {
        return getColorForThresholdRelatedChart(this.value, true, 67, 33);
    }
}
