import { Component, Inject } from '@angular/core';
import { ENV_TOKEN } from '@app/tokens';
import { ModulesApiService } from '@app/core/services/modules-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
@Component({
    selector: 'app-mcm',
    templateUrl: './mcm.component.html',
})
export class McmComponent {
    // url$ = this.modulesApiService.getModuleUrl(this.env.mcmUrl);
    url$ = this.route.queryParams.pipe(

        map(params => {

            return this.env.mcmUrl;

        })

    );
    constructor(@Inject(ENV_TOKEN) private env: Env, public modulesApiService: ModulesApiService,private router: Router, private route: ActivatedRoute,) {}
}
