import { Component } from '@angular/core';
import { ControlContainer, FormArray, FormGroupDirective } from '@angular/forms';
import { TitleCasePipe } from '@angular/common';
import { DropdownItem } from '@dagility-ui/kit';

import { WidgetBuilderFacade } from '../../../state/widget-builder.facade';

@Component({
    selector: 'dp-widget-builder-templates',
    templateUrl: './widget-builder-templates.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class WidgetBuilderTemplatesComponent {
    private titleCase = new TitleCasePipe();

    get additionalTemplatesArray() {
        return this.parent.control.get('additionalTemplates') as FormArray;
    }

    positions: DropdownItem[] = ['top', 'bottom', 'left', 'right'].map(position => ({
        label: this.titleCase.transform(position),
        value: position,
    }));

    constructor(private parent: FormGroupDirective, private facade: WidgetBuilderFacade) {}

    addTemplate() {
        this.additionalTemplatesArray.push(this.facade.buildAdditionalTemplateForm({} as any));
    }

    removeTemplate(index: number) {
        this.additionalTemplatesArray.removeAt(index);
    }
}
