import { inject, Inject, Injectable, InjectFlags, InjectionToken, Optional } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, retry, shareReplay } from 'rxjs/operators';

import { AuthenticatedUser, DefaultUser, IFullNameUser, ShortUser, UserStatus, UserVariables } from '@app/models';
import { Page, Pageable } from '@dagility-ui/kit';
import { APPLICATION_NAME, ENV_TOKEN } from '@app/tokens';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    public defaultPage: string;

    public readonly userChanged = new Subject<DefaultUser>();
    private _user: DefaultUser;

    private readonly adminApi = this.env.adminApiURL;
    private readonly notificationsApi = this.env.notificationsApiURL;

    public readonly defaultTheme$: Subject<string> = new Subject();

    public readonly roles$ = this.httpClient.get<UserRoleInfo[]>(`${this.adminApi}/user-roles`).pipe(retry(3), shareReplay(1));

    constructor(
        @Inject(ENV_TOKEN) private env: Env,
        private httpClient: HttpClient,
        @Optional() @Inject(DEFAULT_COMMUNICATION_CHANNELS) private defaultCommunicationChannels: CommunicationChannel[]
    ) {
        this.defaultCommunicationChannels = this.defaultCommunicationChannels ?? [];
    }

    public get user(): DefaultUser {
        return this._user;
    }

    public set user(user: DefaultUser) {
        this._user = user;
        this.userChanged.next(user);
    }

    public onUpdateUserFields(): void {
        if (!(this._user && this._user.id)) {
            return;
        }
        this.getUser(this._user.id).subscribe(user => {
            this.user = user;
        });
    }

    getCurrentUser(reloadRoles = false): Observable<AuthenticatedUser> {
        if (reloadRoles) {
            return this.httpClient.get<AuthenticatedUser>(`${this.adminApi}/current-user?reloadRoles=true`);
        } else {
            return this.httpClient.get<AuthenticatedUser>(`${this.adminApi}/current-user`);
        }
    }

    getUsers(): Observable<DefaultUser[]> {
        return this.httpClient.get<DefaultUser[]>(`${this.adminApi}/users`);
    }

    getOrganizationDetails(userId: string): Observable<DefaultUser[]> {
        return this.httpClient.get<DefaultUser[]>(`${this.adminApi}/users/${userId}/organizationInfo`);
    }

    getUser(userId: string): Observable<DefaultUser> {
        return this.httpClient.get<DefaultUser>(`${this.adminApi}/users/${userId}`);
    }

    getUsersForManager(userId: string, text: string): Observable<Page<ShortUser>> {
        const params = new HttpParams().set('text', text);
        return this.httpClient.get<Page<ShortUser>>(`${this.adminApi}/users/${userId}/get-managers`, { params: params });
    }

    getUserCommunicationChannels() {
        return this.httpClient.get<any>(`${this.notificationsApi}/settings/current-user/communication-channels`);
    }

    updateUser(user: DefaultUser): Observable<void> {
        return this.httpClient.put<void>(`${this.adminApi}/users/${user.id}`, user);
    }

    updateUserProfile(user: DefaultUser): Observable<void> {
        return this.httpClient.put<void>(`${this.adminApi}/current-user`, user);
    }

    updateUserChannels(user: DefaultUser): Observable<void> {
        return this.httpClient.put<void>(
            `${this.notificationsApi}/settings/current-user/communication-channels`,
            this.getTransportListFromSelectedChannels(user.communicationChannels)
        );
    }

    setUserStatus(userId: string, isActive: boolean): Observable<void> {
        const action = isActive ? 'deactivate' : 'activate';
        return this.httpClient.put<void>(`${this.adminApi}/users/${userId}/${action}`, {});
    }

    findUsers(
        term: string,
        roles: string[],
        statuses: UserStatus[],
        pageable: Pageable,
        canListUsersAcrossOrganizations: boolean = false,
        scope: boolean = false
    ): Observable<Page<DefaultUser>> {
        let httpParams = pageable.create();
        if (term) {
            httpParams = httpParams.set('term', term);
        }
        if (roles && roles.length) {
            httpParams = httpParams.set('roles', roles.join(','));
        }
        if (statuses && statuses.length) {
            httpParams = httpParams.set('statuses', statuses.join(','));
        }
        httpParams = httpParams.set('scope', String(scope));

        if (canListUsersAcrossOrganizations) {
            return this.httpClient.get<Page<DefaultUser>>(`${this.adminApi}/usersAcrossOrganizations`, { params: httpParams });
        } else {
            return this.httpClient.get<Page<DefaultUser>>(`${this.adminApi}/users`, { params: httpParams });
        }
    }

    findActiveUsers(text: string = ''): Observable<IFullNameUser[]> {
        return this.findUsers(text, [], ['ACTIVE'], Pageable.pageAndSort('ASC', ['last_name', 'first_name'], 0, 50), false).pipe(
            map(result => {
                const itemsArray = result.content;
                return itemsArray.map((item: IFullNameUser) => {
                    item.fullName = ((item.firstName || '') + ' ' + (item.lastName || '')).trim();
                    return item;
                });
            })
        );
    }

    getRoles(): Observable<UserRoleInfo[]> {
        return this.roles$;
    }

    uploadAvatar(userId: string, file: File): Observable<string> {
        const formData = new FormData();
        formData.append('file', file);
        return this.httpClient.post<string>(`${this.adminApi}/current-user/image`, formData);
    }

    deleteAvatar(userId: string): Observable<void> {
        return this.httpClient.delete<void>(`${this.adminApi}/current-user/image`);
    }

    getCurrentRights(): Observable<any> {
        return this.httpClient.get<any>(`${this.adminApi}/security/current-rights`);
    }

    updateUsersRoles(list: IUserRolesDto[]): Observable<void> {
        return this.httpClient.put<void>(`${this.adminApi}/update-users-roles`, list);
    }

    updateUserVariables(variables: UserVariables): Observable<void> {
        this.defaultTheme$.next(variables.defaultTheme);
        return this.httpClient.put<void>(`${this.adminApi}/current-user/variables`, variables);
    }

    getBusinessRoles(): Observable<any> {
        return this.httpClient.get<any>(`${this.adminApi}/list-business-roles`);
    }

    private getTransportListFromSelectedChannels(selectedChannels: CommunicationChannel[]) {
        const selectedTransports: string[] = [];
        selectedChannels.forEach(channel => {
            selectedTransports.push(channel.transport);
        });

        this.defaultCommunicationChannels
            .filter(value => value.defaultValues && value.defaultValues.notSelectable && value.defaultValues.selectedDefault)
            .forEach(channel => {
                selectedTransports.push(channel.transport);
            });

        return [...new Set(selectedTransports)];
    }

    getSelectedChannelsFromTransportList(selectedTransports: string[]) {
        const selectedChannels: CommunicationChannel[] = [];

        selectedChannels.push(
            ...this.defaultCommunicationChannels.filter(
                value => value.defaultValues && value.defaultValues.notSelectable && value.defaultValues.selectedDefault
            )
        );

        selectedTransports.forEach(transport => {
            selectedChannels.push(this.defaultCommunicationChannels.filter(channel => channel.transport === transport)[0]);
        });
        return [...new Set(selectedChannels)];
    }
}

export const DEFAULT_COMMUNICATION_CHANNELS = new InjectionToken<CommunicationChannel[]>('Provide default communication channels', {
    factory: () => [
        {
            transport: 'teams',
            name: 'Microsoft Teams',
            icon: '/assets/images/icons/teams.svg',
        },
        {
            transport: 'slack',
            name: 'Slack',
            icon: '/assets/images/icons/slack.svg',
        },
        {
            transport: 'email',
            name: 'Outlook',
            icon: '/assets/images/icons/outlook.svg',
        },
        {
            transport: 'web',
            name: inject(APPLICATION_NAME, InjectFlags.Optional) ?? '',
            icon: '/assets/images/icons/UST.svg',
            darkModeInvertIconColor: true,
            defaultValues: {
                notSelectable: true,
                selectedDefault: true,
            },
        },
    ],
});

export type ITransportCommunicationChannel = 'teams' | 'slack' | 'email' | 'web';

export interface CommunicationChannel {
    transport: ITransportCommunicationChannel;
    name: string;
    icon: any;
    darkModeInvertIconColor?: boolean;
    defaultValues?: CommunicationChannelDefaultValues;
}

export interface CommunicationChannelDefaultValues {
    notSelectable?: boolean;
    selectedDefault?: boolean;
}

export interface UserRoleInfo {
    readonly name: string;
    readonly description: string;
    persistent: boolean;
    readonly dynamic?: boolean;
}

export interface IUserRolesDto {
    id: string;
    roles: string[];
}
