<div (click)="pmState.selectionModel.toggle(tool.toolId)" class="header d-flex align-items-center ml-0 mr-0">
    <fa-icon
        class="toggle-icon"
        [icon]="pmState.selectionModel.isSelected(tool.toolId) ? icons.faChevronDown : icons.faChevronRight"
    ></fa-icon>

    <div class="row m-0 flex-1 flex-nowrap">
        <span class="col-xl-5 col-lg-3 p-0 text-truncate" [class.font-weight-bold]="pmState.selectionModel.isSelected(tool.toolId)">{{
            tool.name
        }}</span>

        <span class="col-3 p-0 set-name text-truncate">{{ tool.jobSet?.name }}</span>

        <span class="col-1 p-0" style="font-weight: 600">{{ tool.plugins.length }} job{{ tool.plugins.length == 1 ? '' : 's' }} </span>

        <span class="col-xl-3 col-lg-5 p-0 pr-4 d-inline-flex">
            <fa-icon
                icon="tree"
                (click)="openGraph(tool, $event)"
                class="fa job-set-icons"
                [openDelay]="800"
                ngbTooltip="Tree"
                placement="top"
                container="body"
            ></fa-icon>
            <fa-icon
                icon="swap"
                (click)="handleSwap($event)"
                class="fa job-set-icons"
                [openDelay]="800"
                ngbTooltip="Swap instance"
                placement="top"
                container="body"
            ></fa-icon>
            <fa-icon
                *ngIf="tool.plugins.length !== 0"
                icon="export"
                (click)="handleExport($event)"
                class="fa job-set-icons"
                [openDelay]="800"
                ngbTooltip="Export job set"
                placement="top"
                container="body"
            ></fa-icon>
            <fa-icon
                icon="facDownload"
                (click)="handleImport($event)"
                class="fa job-set-icons pb-3px"
                [openDelay]="800"
                ngbTooltip="Import job set"
                placement="top"
                container="body"
            ></fa-icon>
            <fa-icon
                icon="createNew"
                (click)="handleSaveNewSet($event)"
                class="fa job-set-icons"
                [openDelay]="800"
                ngbTooltip="Create"
                placement="top"
                container="body"
            ></fa-icon>
            <fa-icon
                *ngIf="tool.plugins.length !== 0"
                icon="duplicate"
                (click)="handleDuplicate($event, tool)"
                class="fa job-set-icons"
                [openDelay]="800"
                ngbTooltip="Duplicate"
                placement="top"
                container="body"
            ></fa-icon>
            <fa-icon
                *ngIf="!!tool.jobSet && !tool.jobSet.default"
                icon="facTrash"
                (click)="handleDeleteJobSet($event)"
                class="fa job-set-icons"
                [openDelay]="800"
                ngbTooltip="Delete job set"
                placement="top"
                container="body"
            ></fa-icon>

            <fa-icon
                *ngIf="!!tool.jobSet"
                (click)="handleAddJob($event, tool)"
                class="fa add-button cursor-pointer ml-auto"
                ngbTooltip="Add new job"
                placement="top"
                container="body"
                icon="plus"
            ></fa-icon>

            <fa-icon
                *ngIf="!!tool.jobSet"
                icon="facDownload"
                (click)="handleImportJD($event)"
                class="fa import-job--icon ml-3 font-14px"
                [openDelay]="800"
                ngbTooltip="Import job"
                placement="top"
                container="body"
            ></fa-icon>
        </span>
    </div>
</div>
<lib-data-grid
    *ngIf="pmState.selectionModel.isSelected(tool.toolId)"
    class="d-block mr-4 app-data-grid"
    [bodyHeight]="'300px'"
    [dataSource]="tool.plugins"
    [pageable]="pageable"
    [columns]="pluginGridColumns"
    [sticky]="true"
    wrapToPerfectScrollbar="tbody"
    (sort)="handleSortChange(tool, $event)"
    [trackByFn]="trackByFn"
>
    <ng-template rowTemplate let-plugin="item">
        <ng-container *ngIf="itemGuard(plugin) as plugin">
            <td style="width: 40%" class="text-truncate table-cells">{{ plugin.instanceName }}</td>
            <td style="width: 15%" class="table-cells">
                {{ plugin.runningType | titlecase }}
            </td>
            <td style="width: 15%" class="table-cells">
                <span [ngClass]="plugin.runningStatusBinary == 'INACTIVE' ? 'inactive' : 'active'">
                    {{ plugin.runningStatusBinary | titlecase }}
                </span>
            </td>
            <td style="width: 20%" class="text-truncate table-cells">
                <dp-monitoring-state
                    *ngIf="plugin.runningStatusBinary != 'INACTIVE'"
                    (forceRun)="handleForceRun(plugin)"
                    [runningStatus]="plugin.runningStatus"
                >
                </dp-monitoring-state>
            </td>
            <td class="d-flex justify-content-around table-cells table-buttons">
                <fa-icon
                    [icon]="plugin.runningStatus === 'INACTIVE' ? 'facStart' : 'facStop'"
                    (click)="changePluginState(plugin, plugin.runningStatus)"
                    class="cursor-pointer font-14px position-relative"
                    [ngClass]="plugin.runningStatus === 'INACTIVE' ? 'start-icon' : 'stop-icon'"
                    aria-hidden="true"
                    tooltipClass="dp-tooltip"
                    [ngbTooltip]="plugin.runningStatus === 'INACTIVE' ? 'Start' : 'Stop'"
                    placement="top"
                    container="body"
                ></fa-icon>
                <fa-icon
                    class="cursor-pointer icons"
                    tooltipClass="dp-tooltip"
                    ngbTooltip="Edit Job Definition"
                    placement="top"
                    container="body"
                    icon="facEdit"
                    aria-hidden="true"
                    (click)="openJd(plugin)"
                ></fa-icon>
                <fa-icon
                    class="cursor-pointer icons"
                    tooltipClass="dp-tooltip"
                    ngbTooltip="View Logs"
                    placement="top"
                    container="body"
                    icon="facLogs"
                    aria-hidden="true"
                    (click)="handleViewPluginLogs(plugin)"
                ></fa-icon>
                <fa-icon
                    *ngIf="!!tool.jobSet"
                    class="cursor-pointer icons"
                    tooltipClass="dp-tooltip"
                    ngbTooltip="Delete Job Definition"
                    placement="top"
                    container="body"
                    icon="facTrash"
                    (click)="handleDeleteJD(plugin)"
                ></fa-icon>
                <fa-icon
                    class="cursor-pointer icons"
                    tooltipClass="dp-tooltip"
                    ngbTooltip="Export Job Definition"
                    placement="top"
                    icon="export"
                    container="body"
                    (click)="handleExportJD(plugin)"
                ></fa-icon>
            </td>
        </ng-container>
    </ng-template>
</lib-data-grid>
