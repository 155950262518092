<div
    *ngIf="!widget.server"
    class="d-flex flex-wrap align-items-center widget-filters mb-1"
    [dpFilterSet]="widget.filters"
    [formGroup]="debuggerState.filtersGroupMap[widget.id]"
    [updateDisabled]="true"
>
    <ng-container *ngFor="let filter of widget.filters" [dpChartFilter]="filter" (ngModelChange)="handleFilterChange(filter)" [widgetDebugger]="debuggerState">
    </ng-container>
</div>
