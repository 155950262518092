import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { isDefined } from '@dagility-ui/kit';

import { BaseWidgetFilter } from '../../../../../widget-builder/components/widget/filters/base-widget-filter.component';
import { DropdownOptionItem } from '../../../../../widget-builder/services/widget-builder.service';
import { WidgetDebuggerState } from '../../../../../widget-builder/services/widget.debugger';

class SelectionModel<T> {
    readonly changed = new Subject<void>();
    emitEvent = true;

    get selectedItems() {
        return Array.from(this.selected);
    }

    private readonly selected = new Set<T>();

    setSelected(items: T[]) {
        try {
            this.emitEvent = false;

            this.clear();
            this.select(items);
        } finally {
            this.emitEvent = true;
        }
    }

    select(items: T[]) {
        items.forEach(item => this.selected.add(item));
        this.emitChange();
    }

    clear() {
        this.selected.clear();
        this.emitChange();
    }

    toggleAll(items: T[]) {
        for (const item of items) {
            this.toggle(item);
        }
    }

    toggle(item: T) {
        if (this.selected.has(item)) {
            this.selected.delete(item);
        } else {
            this.selected.add(item);
        }

        this.emitChange();
    }

    isSelected(item: T) {
        return this.selected.has(item);
    }

    isEmpty() {
        return this.selected.size === 0;
    }

    size() {
        return this.selected.size;
    }

    emitChange() {
        if (this.emitEvent) {
            this.changed.next();
        }
    }
}

@Component({
    selector: 'dp-multiselect-dropdown-filter.d-block.widget-filter',
    templateUrl: './multiselect-dropdown-filter.component.html',
    styleUrls: ['./multiselect-dropdown-filter.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiselectDropdownFilterComponent extends BaseWidgetFilter {
    selectionModel = new SelectionModel<any>();

    padding = '';

    items$: Observable<DropdownOptionItem[]> = inject(WidgetDebuggerState).dataSources[this.filter.placeholder].pipe(
        tap(items => {
            this.items = items ?? [];
        })
    );

    disabled = false;

    private items: DropdownOptionItem[] = [];
    private cdr = inject(ChangeDetectorRef);

    getFilterText(): { title: string; value: any } {
        return { title: '', value: undefined };
    }

    get marginRight() {
        return '0';
    }

    writeValue(values: unknown) {
        let valuesArray: unknown[];

        if (!isDefined(values)) {
            valuesArray = [];
        } else if (Array.isArray(values)) {
            valuesArray = values;
        } else {
            valuesArray = [values];
        }

        this.selectionModel.setSelected(valuesArray);
    }

    selectAll(items: DropdownOptionItem[]) {
        this.selectionModel.select(items.map(({ value }) => value));
    }

    registerOnChange(fn: (_: any) => void) {
        this.subscription.unsubscribe();

        this.subscription = this.selectionModel.changed.pipe(debounceTime(0)).subscribe(() => {
            fn(this.getInternalValue());
        });
    }

    invertSelection(items: DropdownOptionItem[]) {
        this.selectionModel.toggleAll(items.map(item => item.value));
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;

        this.cdr.detectChanges();
    }

    clear() {
        this.selectionModel.clear();
    }

    private getInternalValue() {
        const cachedIndexes = new Map();

        const setCacheIndex = (a: any) => {
            if (!cachedIndexes.has(a)) {
                const indexA = this.items.findIndex(item => item.value === a);
                cachedIndexes.set(a, this.items[indexA]?.wcFilterOrder ?? indexA);
            }
        };

        return this.selectionModel.selectedItems.sort((a, b) => {
            setCacheIndex(a);
            setCacheIndex(b);

            return cachedIndexes.get(a) - cachedIndexes.get(b);
        });
    }
}
