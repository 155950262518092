import { Component } from '@angular/core';
import { SreGanttService } from '../../services/sre-gantt.service';
import { SREGanttTable } from '../../../sre-gantt/models/sre-gantt.model';

@Component({
    selector: 'dp-sre-gantt-table',
    templateUrl: './sre-gantt-table.component.html',
    styleUrls: ['./sre-gantt-table.component.scss'],
})
export class SreGanttTableComponent {
    constructor(public service: SreGanttService) {}

    isTableData(data: any): SREGanttTable {
        return data;
    }
}
