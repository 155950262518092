import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { SVGBlock } from '@dagility-ui/shared-components';

import { LayerBlock } from '../../../models/query.block';
import { WidgetAction } from '../../../models/widget.graph';
import { WidgetEventHandler, WidgetType } from '../../../models/any-widget.model';
import { getDrilldownConverter } from '../../../services/widget.drilldown';
import { WidgetScript } from '../../../services/widget-query.executor';

@Component({
    selector: 'dp-widget-flow-block',
    templateUrl: './widget-flow-block.component.html',
    styleUrls: ['./widget-flow-block.component.scss'],
    host: {
        '[class.d-none]': 'block && block.viewType === "cell"',
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetFlowBlockComponent<T extends SVGBlock> {
    @Input() block: T;

    @Input() debugPosition: {
        queryId: string;
        scriptIndex: number;
    } = null;

    @Input() showDrilldown = true;

    @Input() readonly: boolean;

    @Input() activeBlockId: string;

    @Output() editBlock = new EventEmitter<T>();

    @Output() addFilter = new EventEmitter<string>();

    @Output() addQuery = new EventEmitter<string>();

    @Output() addAsyncQuery = new EventEmitter<string>();

    @Output() addDrilldown = new EventEmitter<void>();

    @Output() addEventHandler = new EventEmitter<void>();

    @Output() editDrilldown = new EventEmitter<WidgetAction>();

    @Output() editEventHandler = new EventEmitter<WidgetEventHandler>();

    @Output() toggleBreakpoint = new EventEmitter();

    hasDrilldown = (type: WidgetType) => !!getDrilldownConverter(type);

    handleToggleBreakpoint(position: WidgetScript): void {
        this.toggleBreakpoint.emit({ block: this.block, position });
    }

    isLayerBlock(block: any): LayerBlock {
        return block as LayerBlock;
    }
}
