import {Observable} from 'rxjs';
import {InjectionToken} from '@angular/core';

export class ModelGraphService {
    cloneGraph(modelGraphId: string): Observable<any>{
       return new Observable(subscriber => {subscriber.error("No providers for QueryBuilderService");});
    }
}

export function notificationHealthFactory() {
    return new ModelGraphService();
}

export const QUERY_BUILDER_MODEL_GRAPH_SERVICE = new InjectionToken('Query builder service', {
    factory: notificationHealthFactory,
});
