<nav
    vocButtonDisabled
    ngbNav
    [destroyOnHide]="false"
    [(activeId)]="activeTab"
    (navChange)="onNavChange($event)"
    #qbNav="ngbNav"
    class="nav nav-tabs justify-content-start main-content-header main-content-header-shadow pb-0"
>
    <ng-container *ngTemplateOutlet="topmenu"></ng-container>
    <ng-container *ngIf="!actionsLocked; else preloader"></ng-container>
    <ng-container *ngIf="!debugMode; else debugModeTemplate"></ng-container>
    <ng-container ngbNavItem="Model">
        <a ngbNavLink style="font-size: 14px;">Model</a>
        <ng-template ngbNavContent>
            <div *ngIf="!error; else errorTemplate">
                <app-widget-model
                    [rootModels]="rootModels"
                    (addRootModel)="addRootModel($event)"
                    (deleteCustomModel)="deleteCustomModel($event)"
                    *ngIf="rootModels"
                >
                </app-widget-model>
            </div>
        </ng-template>
    </ng-container>
    <ng-container ngbNavItem="Data">
        <a ngbNavLink style="font-size: 14px;">Data Preview</a>
        <ng-template ngbNavContent>
            <app-query-builder-data (backButton)="onNavChange($event)" *ngIf="currentState.modelGraph && activeTab === 'Data'">
            </app-query-builder-data>
        </ng-template>
    </ng-container>
    <ng-container ngbNavItem="Filters">
        <a ngbNavLink style="font-size: 14px;">UI Filters</a>
        <ng-template ngbNavContent>
            <app-query-builder-filters
                (backButton)="onNavChange($event)"
                [rootModels]="rootModels"
                *ngIf="currentState.modelGraph && activeTab === 'Filters'"
            >
            </app-query-builder-filters>
        </ng-template>
    </ng-container>
    <ng-container ngbNavItem="Visualization">
        <a ngbNavLink style="font-size: 14px;">Presentation</a>
        <ng-template ngbNavContent>
            <app-query-builder-visualization
                (backButton)="onNavChange($event)"
                *ngIf="currentState.modelGraph && activeTab === 'Visualization'"
            >
            </app-query-builder-visualization>
        </ng-template>
    </ng-container>
</nav>
<div class="pt-0" [ngbNavOutlet]="qbNav"></div>
<app-qb-model-drilldown-editor
    *ngIf="isDrilldownEditor"
    (save)="handleSaveDrilldown($event)"
    (cancel)="handleCancelDrilldownEditor()"
    class="drilldown-editor"
    [data]="drilldownEditorData"
></app-qb-model-drilldown-editor>

<ng-template #errorTemplate>
    <perfect-scrollbar>
        <div style="width: 100%; height: 85vh; display: flex; align-items: center; justify-content: center; flex-direction: column;">
            <img src="{{ icons.error }}" alt="Error" />
            <h2>Something went wrong</h2>
            <br />
            <button class="btn btn-primary size-s" (click)="reload()">
                <fa-icon class="font-16px mr-5px" icon="rotationRight"></fa-icon>
                Reload
            </button>
        </div>
    </perfect-scrollbar>
</ng-template>

<ng-template #debugModeTemplate>
    <div class="preloader-cover">
        <div class="debugger-place">
            <div
                style="width: 100%; height: 60px; border-bottom: 1px solid #e9e9e9;"
                class="d-flex mt-10px px-25px justify-content-between align-items-center"
            >
                <h1>Debug Menu</h1>
                <span style="cursor: pointer; " (click)="debugMode = false">✕</span>
            </div>
            <h3 class="w-100 pt-10px pl-10px">ModelGraph JSON</h3>
            <textarea
                #modelGraphData
                class="col pt-10px"
                style="width: 100%; outline: none; border: 0;"
                [value]="globalModelGraphText"
            ></textarea>
            <div
                style="width: 100%; height: 60px; border-top: 1px solid #e9e9e9; column-gap: 8px"
                class="d-flex mt-10px px-25px justify-content-end align-items-center"
            >
                <button class="btn btn-secondary" (click)="debugMode = false">Cancel</button>
                <button class="btn btn-secondary" (click)="exportModelGraph()">Export</button>
                <button class="btn btn-primary" (click)="applyModelGraph(modelGraphData)">Apply</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #preloader>
    <div class="preloader-cover">
        <div class="preloader-place">
            <div class="lds-spinner mr-10px">
                <div *ngFor="let i of spinnerItems"></div>
            </div>
            <h2>Please Wait</h2>
            <p class="mt-8px disable">Loading: {{ lastLockReason }}</p>
        </div>
    </div>
</ng-template>

<ng-template #topmenu>
    <div class="w-100">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a [routerLink]="'../..'">Settings</a></li>
                <li class="breadcrumb-item"><a [routerLink]="'../widget-library'">Widget Library</a></li>
                <li class="breadcrumb-item active" aria-current="page">Query Builder</li>
            </ol>
        </nav>
        <div style="height: 40px" class="d-flex justify-content-between align-items-center w-100">
            <div class="d-flex align-items-center title-container">
                <h1 class="text-truncate d-flex" style="margin-right: 12px" *ngFor="let p of path; first as isFirst">
                    <span *ngIf="!isFirst" class="mr-2"> > </span>
                    <div
                        class="cursor-pointer text-truncate"
                        [ngbTooltip]="p.title"
                        [container]="'body'"
                        (click)="handleSelectDrilldown({ uid: p.uid })"
                    >
                        {{ p.title }}
                    </div>
                </h1>
                <fa-icon
                    style="cursor: pointer"
                    class="font-16px mr-2"
                    *ngIf="!(path.length < 2)"
                    (click)="handleEditDrilldown()"
                    icon="facEdit"
                ></fa-icon>
                <fa-icon
                    style="cursor: pointer"
                    class="font-16px mr-2"
                    *ngIf="!(path.length < 2)"
                    (click)="handleDeleteDrilldown()"
                    icon="facTrash"
                ></fa-icon>
                <lib-dropdown
                    *ngIf="drilldowns?.length"
                    style="margin-right: 12px"
                    (change)="handleSelectDrilldown($event)"
                    valueKey="uid"
                    labelKey="drillDownName"
                    id="drilldown-dropdown"
                    [placeholder]="'Select Drilldown'"
                    [items]="drilldowns"
                ></lib-dropdown>
                <div class="bounder-button" style="height: 16px;"></div>
                <button
                    [disabled]="!this.currentState.modelGraph"
                    class="btn btn-link min-width-fit-content"
                    style="margin: 0;"
                    (click)="handleAddDrilldown()"
                >
                    <fa-icon [icon]="'plus'" class="mr-8px"></fa-icon>
                    Add drilldown
                </button>
            </div>
            <div style="display: flex; flex-direction: row;">
                <button
                    class="btn btn-secondary mr-3 min-width-fit-content"
                    [disabled]="isInit()"
                    (click)="handleExternalParametersClick()"
                >
                    <fa-icon icon="facFilters" class="mr-10px"></fa-icon>
                    External Parameters
                    <fa-icon icon="facExclamationCircle" class="ml-2 error-text" *ngIf="isWithWarning()"></fa-icon>
                </button>
                <app-qb-validation></app-qb-validation>
                <div class="bounder-button mx-25px"></div>
                <button
                    [ngbTooltip]="'Reset'"
                    [openDelay]="1000"
                    class="btn btn-secondary"
                    [class.reload-button-img]="globalModelGraph"
                    [class.reload-button-img-disable]="!globalModelGraph"
                    style="width: 32px; padding: 0;"
                    [disabled]="!globalModelGraph"
                    (click)="qbReset()"
                >
                    <app-icon-remoter
                        *ngIf="globalModelGraph"
                        [iconName]="'RESET'"
                        [width]="15"
                        [height]="14"
                        [color]="'BRAND'"
                    ></app-icon-remoter>
                    <app-icon-remoter
                        *ngIf="!globalModelGraph"
                        [iconName]="'RESET'"
                        [width]="15"
                        [height]="14"
                        [color]="'DISABLED'"
                    ></app-icon-remoter>
                </button>
                <button
                    class="btn btn-primary ml-15px"
                    style="min-width: 60px; max-width: 60px"
                    [disabled]="!globalModelGraph || hasErrors || saveInLoad"
                    (click)="saveWidgetAndModelGraph()"
                >
                    <ng-container *ngIf="!saveInLoad; else loading">Save</ng-container>
                    <ng-template #loading>
                        <lib-loader></lib-loader>
                    </ng-template>
                </button>
            </div>
        </div>
    </div>
</ng-template>
