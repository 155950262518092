import { ICiCdTemplateEditor, IComponentConfiguration, IPipelineDetails } from '../../models/cicd-template-configuration.model';
import { CI_CD_MODULE_SEGMENT, PIPELINE_BUILDER_SEGMENT, TEMPLATE_EDITOR_SEGMENT } from '../../../../app-routing.constants';
import { MARKETPLACE_TAB } from '../../template-list/constants/template-list-constants';
import { DropdownItem } from '@dagility-ui/kit';

export const PIPELINE_BUILDER_ROUTE = `./${CI_CD_MODULE_SEGMENT}/${PIPELINE_BUILDER_SEGMENT}`;

export const MARKETPLACE_PIPELINE_BUILDER_ROUTE = `./${CI_CD_MODULE_SEGMENT}/${MARKETPLACE_TAB}/${PIPELINE_BUILDER_SEGMENT}`;

export const TEMPLATES_LIST_ROUTE = `./${CI_CD_MODULE_SEGMENT}/templates`;

export const TEMPLATES_EDITOR_ROUTE = `/${TEMPLATES_LIST_ROUTE}/${TEMPLATE_EDITOR_SEGMENT}`;

export const COMPONENT_IMAGE_MAX_FILE_SIZE = 200_000;

export const TEMPLATE_TYPES_ITEMS: DropdownItem[] = [
    { label: 'Standard Tech Stack', value: 'STANDARD' },
    { label: 'Package Software', value: 'PACKAGE' },
    { label: 'Generic components', value: 'GENERIC' },
    { label: 'Custom template', value: 'CUSTOM' },
];

export const DEFAULT_TEMPLATE: Partial<ICiCdTemplateEditor> = {
    id: null,
    components: [],
    pipelines: [],
    workflows: [],
    description: '',
    pluginServices: [],
    tags: [],
    fields: [],
    name: '',
    type: 'CUSTOM',
    readOnly: false,
};

export const MOCK_TEMPLATE: Partial<ICiCdTemplateEditor> = {
    id: null,
    components: [],
    pipelines: [],
    workflows: [],
    description: 'Mocked template for testing Save as template functionality',
    pluginServices: [],
    fields: [],
    name: 'Mocked template',
    type: 'CUSTOM',
    readOnly: false,
};

export const DEFAULT_COMPONENT: IComponentConfiguration = {
    id: null,
    name: '',
    templateId: null,
    imagePath: '',
    code: '',
    description: '',
    fields: [],
    componentType: null,
    defaultAppSource: null,
    readOnly: false,
    appSourcesIds: [],
    pluginServices: [],
};

export const DEFAULT_PIPELINE: IPipelineDetails = {
    id: null,
    name: '',
    description: '',
    componentId: null,
    bigImagePath: null,
    bigImage: null,
    smallImage: null,
    smallImagePath: null,
    templateId: null,
    fields: [],
    preferredToolType: null,
    preferredToolId: null,
    readOnly: false,
    script: '',
};

export const DEFAULT_TEMPLATE_ITEM_MAP: Record<string, any> = {
    components: DEFAULT_COMPONENT,
    pipelines: DEFAULT_PIPELINE,
};
