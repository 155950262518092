import { Component } from '@angular/core';
import { PerfectScrollbarConfig } from 'ngx-perfect-scrollbar';

@Component({
    selector: 'dp-sre-gantt-wrapper',
    templateUrl: './sre-gantt-wrapper.component.html',
    styleUrls: ['./sre-gantt-wrapper.component.scss'],
})
export class SreGanttWrapperComponent {
    perfectScrollbarConfig: PerfectScrollbarConfig = new PerfectScrollbarConfig({
        suppressScrollX: false,
        suppressScrollY: true,
        useBothWheelAxes: false,
    });
}
