import { Provider } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { pick } from 'lodash';

import { AnyWidgetModel } from 'data-processor';

import { WIDGET_DATA_TOKEN, WidgetBuilderData } from '../../providers/widget-data.provider';
import { DashboardWidgetSettingsManager } from '../../../dashboard/services/dashboard-widget-settings.manager';
import { createFakeWidget } from '../../services/widget-builder.util';

export const GLOBAL_FILTER_FIELDS: Array<keyof AnyWidgetModel> = [
    'handlers',
    'server',
    'complexNamedDropdown',
    'complexNamedDropdownLabel',
    'complexNamedDropdownDependencies',
    'complexNamedDropdownFilterDependency',
    'filters',
];

export function fakeWidgetFactory({ params }: ActivatedRoute, api: DashboardWidgetSettingsManager): WidgetBuilderData {
    return params.pipe(
        switchMap(({ dashboardId }) => api.getDashboard(dashboardId, true)),
        map(({ name, dashboardOptions }) => ({
            id: 1,
            dashboardId: null,
            uuid: null,
            groupId: null,
            data: createFakeWidget({
                name,
                ...pick(dashboardOptions ?? {}, GLOBAL_FILTER_FIELDS),
            } as any),
        }))
    );
}

export const FAKE_WIDGET_DATA_PROVIDER: Provider = {
    provide: WIDGET_DATA_TOKEN,
    deps: [ActivatedRoute, DashboardWidgetSettingsManager],
    useFactory: fakeWidgetFactory,
};
