<ng-container *ngIf="!noData; else noDataTmpl">
    <div class="container mt-1 ml-1">
        <div class="row align-items-center">
            <h3 class="mt-0">{{ label }}</h3>
        </div>
    </div>

    <dp-work-distribution-chart [chartOptions]="options" [chartData]="chartData" [height]="'100%'"> </dp-work-distribution-chart>
</ng-container>

<ng-template #noDataTmpl>
    <h1 class="d-flex align-items-center justify-content-center h-100 mt-0 secondary-text-and-icons">No Data</h1>
</ng-template>
