<perfect-scrollbar>
    <div class="font-12px">
        <ng-container *ngIf="data.overview as overview">
            <div class="font-weight-600 py-10px">{{ overview.title }}</div>
            <div *ngIf="getColumns(overview.itemsPerColumn, overview.items) as columns" class="pl-15px row">
                <div *ngFor="let column of columns" class="col-3">
                    <ng-container *ngFor="let cell of getPartition(overview, column)">
                        <ng-container *ngTemplateOutlet="cellTemplate; context: { cell: cell }"></ng-container>
                    </ng-container>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="data.summary as summary">
            <div class="font-weight-600 pt-10px">{{summary.title}}</div>
            <div class="pt-10px pb-2">{{summary.description}}</div>
            <dp-widget-tiles
                class="custom-tiles"
                [position]="'top'"
                [placeholders]="summary.tilePlaceholders"
                (drilldown)="handleDrilldown($event)"
            ></dp-widget-tiles>
        </ng-container>

        <ng-container *ngIf="data.list as securityList">
            <div class="font-weight-600 pt-10px">{{securityList.title}}</div>
            <perfect-scrollbar *ngIf="dataList$ | async as items">
                <ng-container *ngFor="let item of items">
                    <ng-container *ngTemplateOutlet="listItemTemplate; context: { item: item }"></ng-container>
                </ng-container>
            </perfect-scrollbar>
        </ng-container>
    </div>
</perfect-scrollbar>

<ng-template #cellTemplate let-cell="cell">
    <div class="row pb-10px">
        <div class="flex-1 text-secondary">{{ cell.label }}</div>
        <div
            class="flex-1 text-ellipsis p-0"
            ngbTooltip="{{ cell.value }}"
            placement="top-left"
            container="body"
            [openDelay]="400"
        >
            <ng-container
                *ngTemplateOutlet="
                    cell.badge ? badgeValueTemplate : defaultValueTemplate;
                    context: cell.badge ? { color: cell.badgeColor, value: cell.value } : { cell: cell }
                "
            ></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #defaultValueTemplate let-cell="cell">
    <span class="data-text">{{ cell.value }}</span>
</ng-template>

<ng-template #badgeValueTemplate let-color="color" let-value="value">
  <span
      [style.background-color]="color"
      [style.border]="'1px solid ' + color"
      class="custom-badge size-s custom-badge-background text-white"
  >
      {{ value }}
  </span>
</ng-template>

<ng-template #listItemTemplate let-item="item">
    <div class="py-2 px-1 security-item cursor-pointer" (click)="rowClick(item)">
        <div class="row no-gutters align-items-baseline">
            <fa-icon class="font-13px text-secondary mr-5px" icon="clock"></fa-icon>
            <div class="text-secondary">{{item.date}}</div>
            <div class="round-color round round-8 ml-5px mr-5px"></div>
            <div class="text-ellipsis"
                 [style.max-width.px]="350"
                 ngbTooltip="{{ item.name }}"
                 placement="top-left"
                 container="body"
                 tooltipClass="custom-tooltip"
                 [openDelay]="400"
            >{{item.name}}</div>
            <div class="round-color round round-8 ml-5px mr-5px"></div>
            <div>
                <ng-container
                    *ngTemplateOutlet="badgeValueTemplate;
                    context: { color: item.statusColor, value: item.status }"></ng-container>
            </div>
        </div>
        <div class="row no-gutters">
            <div class="mr-5px"
                 [ngClass]="item.idLink ? 'cursor-pointer color-brand' : ''"
                 (click)="openLink(item.idLink); $event.stopPropagation()">
                {{item.id}}
            </div>
            <div class="col text-ellipsis"
                 ngbTooltip="{{ item.description }}"
                 placement="top-left"
                 container="body"
                 tooltipClass="custom-tooltip"
                 [openDelay]="400"
            >{{item.description}}</div>
        </div>
    </div>
</ng-template>
