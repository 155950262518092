export const QB_ICON_URLS = {
    key: 'assets/images/icons/default.svg',
    field: 'assets/images/icons/grid-2-horizontal.svg',
    filter: 'assets/images/icons/filter.svg',
    xAxis: 'assets/images/icons/right.svg',
    yAxis: 'assets/images/icons/up.svg',
    table: 'assets/images/icons/table.svg',
    settings: 'assets/images/icons/settings.svg',
    trash: 'assets/images/icons/delete.svg',
    collapse: 'assets/images/icons/right.svg',
    relations: 'assets/images/icons/relations.svg',
    downChevron: 'assets/images/icons/downChevron.svg',
    danger: 'assets/images/icons/danger.svg',
    backArrow: 'assets/images/back-arrow.svg',
    error: 'assets/images/error.svg',
    eye: 'assets/images/icons/eye.svg',
    user: 'assets/images/icons/icon_profile.svg',
};
