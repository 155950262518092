<div *ngIf="loading">
    <div class="modal-body" style="text-align: center; height: 200px;">
        <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="lds-spinner"><div *ngFor="let i of spinnerItems"></div></div>
        <div>Checking for existing repository...</div>
    </div>
</div>

<div *ngIf="!loading">
    <div class="modal-header">
        <h4 class="modal-title" *ngIf="message.title" id="modal-title">{{ message.title }}</h4>
        <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p *ngIf="message.content">{{ message?.content }}</p>
        <perfect-scrollbar style="max-height: 150px;">
            <div *ngFor="let url of listOfUrls" class="my-5px font-11px">
                <div
                    class="label-font mr-5px text-ellipsis"
                    tooltipClass="custom-tooltip"
                    ngbTooltip="{{url.label}}"
                    container="body"
                    style="max-width: 120px;"
                    [openDelay]="800"
                >
                    {{url.label}}:
                </div>
                <div
                    class="text-ellipsis link-text cursor-pointer"
                    tooltipClass="custom-tooltip"
                    ngbTooltip="{{url.value}}"
                    container="body"
                    [openDelay]="800"
                    (click)="openLink(url.value)"
                >
                    {{url.value}}
                </div>
            </div>
        </perfect-scrollbar>
    </div>
    <div class="modal-footer">
        <button *ngIf="showCancelButton" type="button" class="btn btn-secondary" (click)="cancel()">{{ closeButtonText }}</button>
        <button *ngIf="showConfirmButton" type="button" ngbAutofocus class="btn btn-primary" (click)="confirm()">
            {{ confirmButtonText }}
        </button>
    </div>
</div>
