import { Component, EventEmitter, Output, TemplateRef, Type, ViewChild } from '@angular/core';

import { TourStepOption } from './interactive-tour.service';

@Component({
    selector: 'app-tour-dialog',
    template: `
        <ng-template [ngTemplateOutlet]="tmpl" [ngTemplateOutletContext]="{ step: this }"></ng-template>

        <ng-template #tmpl let-progress="progress" let-step="step">
            <div *ngIf="progress" class="progress-bar" [style.--progress]="progress"></div>
            <div class="d-flex justify-content-between border-bottom header-container pt-1 pb-2 px-2">
                <span class="tour-heading">{{ step.title }}</span>
            </div>
            <div class="mt-2 tour-step-content font-12px px-1" *ngIf="isString(step.content)" [innerHTML]="step.content"></div>
            <ng-template *ngIf="!isString(step.content)" [ngComponentOutlet]="step.content"></ng-template>
            <div class="d-flex" *ngIf="step.back || step.next">
                <div class="ml-auto">
                    <button *ngIf="step.back" class="btn" (click)="step.back()">Back</button>
                    <button *ngIf="step.next" class="btn btn-primary" (click)="step.next()">Next</button>
                </div>
            </div>
        </ng-template>
    `,
    host: {
        class: 'p-4',
    },
    styles: [
        `
            .element-wrapper {
                position: fixed !important;
                max-width: 480px;
            }

            .tour-element--show {
                position: relative;
                z-index: 1059;
            }

            .header-container {
                margin: 0 -12px;
            }

            .header-container .tour-heading {
                font-size: 14px;
                font-weight: 600;
                color: #000000;
            }

            .tour-step-content img {
                max-width: 100%;
                width: 100%;
                height: auto;
            }

            .tour-step-content a {
                color: #317ce2 !important;
            }
        `,
    ],
})
export class TourDialogComponent implements TourStepOption {
    @ViewChild('tmpl', { read: TemplateRef, static: true }) template: TemplateRef<any>;

    @Output() nextClicked = new EventEmitter();

    isString(content: any): boolean {
        return typeof content === 'string';
    }

    next(): void {
        this.nextClicked.emit();
    }
}
