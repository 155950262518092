import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-template-publish-changes',
    templateUrl: './template-publish-changes.component.html',
    styleUrls: ['./template-publish-changes.component.scss'],
})
export class TemplatePublishChangesComponent {
    @Output() cancelBtnClicked: EventEmitter<any> = new EventEmitter();
    @Output() confirmOk: EventEmitter<any> = new EventEmitter();

    @Input() radioButtons: RadioButtons[];
    @Input() title = 'Publish the changes'
    @Input() closeButtonText = 'Cancel';
    @Input() confirmButtonText = 'Publish';

    constructor(public modal: NgbActiveModal) {}

    confirm() {
        this.confirmOk.emit(this.radioButtons.find(x => x.checked).value);
        this.modal.close();
    }

    cancel() {
        this.cancelBtnClicked.emit();
        this.modal.dismiss('cancel click');
    }

    handleChange(index: number) {
        this.radioButtons.forEach((x, i) => x.checked = (i === index))
    }
}

export interface RadioButtons {
    value: any;
    label: string;
    checked?: boolean;
}
