<div class="d-flex flex-column actions-panel">
    <div
        class="p-1 mt-2 cursor-pointer"
        ngbTooltip="Evaluate expression"
        [class.evaluate--disabled]="disabled$ | async"
        container="body"
        (click)="evaluate()"
    >
        <fa-icon class="start-evaluate font-14px" icon="facStart"></fa-icon>
    </div>
</div>
<lib-codemirror-editor class="col-7 pl-0 pr-1" mode="text/x-groovy" [(ngModel)]="store.debugContext.evaluateScript"></lib-codemirror-editor>
<div class="d-flex flex-1 p-3 evaluate-result--container overflow-hidden">
    <ng-container *ngIf="!loading; else loadingTmpl">
        <perfect-scrollbar>
            <dp-debug-variables *ngIf="!evaluateResult.isError" [variables]="evaluateResult.result" sort="true"></dp-debug-variables>
            <span *ngIf="evaluateResult.isError" class="error-message">
                {{ evaluateResult.result }}
            </span>
        </perfect-scrollbar>
    </ng-container>
</div>

<ng-template #loadingTmpl>
    <lib-spinner class="m-auto"></lib-spinner>
</ng-template>
