import { Component, Inject, OnInit } from '@angular/core';
import { merge, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { AbstractJobDefinitionTemplate } from '../abstract-job-definition-template';
import { EditJobDefinitionService } from '../edit-job-definition.service';
import { WARNING_NAMES } from '../field-names';
import { JDValue } from '../job-definition-builder/edit-workflow-action/models/operand';
import { enumToArray } from '../../edit-job-definition-form/utils';
import { ValueType } from '../job-definition-builder/edit-workflow-action/models/value-type';

@Component({
    selector: 'dp-edit-jd-value',
    templateUrl: './edit-jd-value.component.html',
    styleUrls: ['../templates.scss'],
})
export class EditJdValueComponent extends AbstractJobDefinitionTemplate<JDValue> implements OnInit {
    subscription = Subscription.EMPTY;
    valueType = ValueType;
    types = enumToArray(ValueType);

    constructor(public store: EditJobDefinitionService, @Inject(WARNING_NAMES) public warnings: Record<string, any>) {
        super();
    }

    ngOnInit() {
        this.subscription = merge(
            ...(['value', 'type'] as const).map(key =>
                this.formSlice.get(key).valueChanges.pipe(
                    map(value => ({
                        key,
                        value,
                    }))
                )
            )
        ).subscribe(({ key, value }) => {
            this.definition[key] = value;
        });
    }

    handleTypeChange(event: any) {
        if (event instanceof Event) {
            return;
        }

        this.definition.value = event;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
