import { Deserializable, PostUpdate } from '../../../models/job-definition';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { mapRequired } from '../../../models/validate.decorator';

export class UpdateAction extends Deserializable {
    _arrays = {};
    _class = { postUpdate: PostUpdate };
    _enums = {
        operationType: OperationType,
    };
    _maps = {};
    meta = {
        _arrays: this._arrays,
        _class: this._class,
        _enums: this._enums,
        _maps: this._maps,
    };

    postUpdate: PostUpdate = new PostUpdate();
    operationType: OperationType = null;

    static ValidatePostUpdate(control: AbstractControl): ValidationErrors | null {
        const postUpdateControl = control.get('postUpdate');
        if (postUpdateControl) {
            const valuesControl = postUpdateControl.get('values');

            if (!valuesControl) {
                return null;
            }

            if ((control.value as UpdateAction).operationType !== OperationType.DELETE) {
                valuesControl.setValidators(mapRequired);
            } else {
                valuesControl.clearValidators();
            }

            valuesControl.updateValueAndValidity({ onlySelf: true });
        }

        return null;
    }

    getType() {
        return 'UpdateAction';
    }

    getInfo() {
        return `Update postUpdate = ${this.postUpdate.getInfo()}, operationType = ${this.operationType}`;
    }

    constructor(instance?: any) {
        super();
        this.fillByInstance(UpdateAction, instance);
    }
}

enum OperationType {
    INSERT = 'INSERT',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
}
