import { ChangeDetectionStrategy, Component, Inject, Input, NgZone, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, share, shareReplay, tap } from 'rxjs/operators';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';

import { DATA_MORPH_FEATURE, DATA_MORPH_FEATURE_TOGGLE, DataMorphFeatureToggleService } from 'data-processor/tokens';
import { WidgetPreview } from 'data-processor/lib/widget-library/widget-library.page';
import { WidgetBuilderService } from 'data-processor/lib/widget-library/widget-builder/services/widget-builder.service';

import { DpDashboardStore } from '../../state/dp-dashboard.store';
import { GridsterEvent, GridsterUtils } from '../../services/gridster/gridster-resize.util';
import { CanAddWidgetToDashboardService } from '../../services/can-add-widget-to-dashboard.service';
import { GridsterAutoScroll } from './gridster-autoscroll';
import { AddWidgetFlowManager } from './add-widget-flow.manager';
import { DashboardWidgetListFacade } from './dashboard-widget-list.facade';

@Component({
    selector: 'dp-dashboard-widget-list',
    templateUrl: './dashboard-widget-list.component.html',
    styleUrls: ['./dashboard-widget-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [AddWidgetFlowManager, CanAddWidgetToDashboardService, DashboardWidgetListFacade],
})
export class DashboardWidgetListComponent {
    @Input() importAllowed = true;

    @ViewChild(PerfectScrollbarDirective) perfectScrollbar: PerfectScrollbarDirective;

    data$: Observable<WidgetPreview[]> = this.api.getAllWidgets().pipe(
        map(widgets =>
            widgets.map(({ id, data }) => ({
                id,
                type: data.type,
                title: data.chartOptions.title,
                description: data.chartOptions.description,
            }))
        ),
        shareReplay()
    );

    search$ = new BehaviorSubject('');

    widgets$ = combineLatest([this.data$, this.search$]).pipe(
        tap(() => {
            this.perfectScrollbar?.scrollToTop();
        }),
        map(([widgets, search]) => widgets.filter((w) => w.title?.toUpperCase().includes(search.toUpperCase())))
    );
    hideDescription$ = this.featureToggleService.isActive(DATA_MORPH_FEATURE.HIDE_DESCRIPTION).pipe(share());
    dragged = false;

    private autoScroll: GridsterAutoScroll;

    constructor(
        public store: DpDashboardStore,
        private api: WidgetBuilderService,
        private zone: NgZone,
        @Inject(DATA_MORPH_FEATURE_TOGGLE) private featureToggleService: DataMorphFeatureToggleService,
        private dashboardWidgetFacade: DashboardWidgetListFacade
    ) {}

    handleImportWidget() {
        this.dashboardWidgetFacade.importWidget().subscribe();
    }

    start(event: GridsterEvent) {
        GridsterUtils.patchApplySizeFn(event);
        this.dragged = true;
        this.store.backupGridsterState();
    }

    // @tech: need to find more generic way for overflow-toggle/autoscroll/dragged
    over(event: GridsterEvent) {
        const size = event.item.calculateSize(event.gridster);
        this.autoScroll?.destroy();
        this.autoScroll = new GridsterAutoScroll(event, this.zone);
        document.body.classList.add('overflow-y-hidden');
        GridsterUtils.setGridElementSize(event.item, `${size.width}px`, `${size.height}px`);
        event.item.itemPrototype.$element.classList.add('dragged');
    }

    out(event: GridsterEvent) {
        this.autoScroll?.destroy();
        GridsterUtils.setGridElementSize(event.item, '', '');
        event.item.itemPrototype.$element.classList.remove('dragged');
    }

    cancel() {
        this.autoScroll?.destroy();
        this.dragged = false;
        document.body.classList.remove('overflow-y-hidden');
    }

    drop(e: GridsterEvent, widget: WidgetPreview) {
        this.dragged = false;
        const groupId = GridsterUtils.getGroupId(e);
        this.autoScroll?.destroy();
        document.body.classList.remove('overflow-y-hidden');
        this.store.ignoreReorder = true;

        this.dashboardWidgetFacade.addWidget(widget.id, groupId, e).subscribe();
    }
}
