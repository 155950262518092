import { Pipe, PipeTransform } from '@angular/core';
import { WidgetType } from '../models/any-widget.model';

const WIDGETS_PREVIEW: Record<WidgetType, string> = {
    [WidgetType.BAR_CHART]: 'barchart.svg',
    [WidgetType.LINE_CHART]: 'linechart.svg',
    [WidgetType.TABLE]: 'Table.svg',
    [WidgetType.DOUGHNUT_CHART]: 'piechart.svg',
    [WidgetType.PIE_CHART]: 'piechart.svg',
    [WidgetType.STACKED_BAR_CHART]: 'barchart.svg',
    [WidgetType.TWO_DIMENSIONAL_TABLE]: 'Table.svg',
    [WidgetType.NESTED_PIE_CHART]: 'piechart.svg',
    [WidgetType.SUNBURST_CHART]: 'piechart.svg',
    [WidgetType.TREEMAP]: `Table.svg`,
    [WidgetType.PROGRESS]: `progresschart.svg`,
    [WidgetType.COMPLEX]: 'tabchart.svg',
    [WidgetType.BOXPLOT]: 'boxplotchart.svg',
    [WidgetType.TILE_CHART]: 'boxplotchart.svg',
    [WidgetType.LOG]: 'boxplotchart.svg',
    [WidgetType.MULTIPLE_Y_AXIS]: 'linechart.svg',
    [WidgetType.ACCORDION]: `Table.svg`,
    [WidgetType.GANTT]: `Table.svg`,
    [WidgetType.RADIAL]: `piechart.svg`,
    [WidgetType.PI_WORK_DISTRIBUTION]: `piechart.svg`,
    [WidgetType.PI_WORK_CHART]: `piechart.svg`,
    [WidgetType.PI_MULTIPLE_RADIALS]: `piechart.svg`,
    [WidgetType.PI_PROGRESS_MENU]: `piechart.svg`,
    [WidgetType.TABLE_WITH_TABS]: `Table.svg`,
    [WidgetType.ACCORDION_WITH_TABS]: `Table.svg`,
    [WidgetType.SCATTER_CHART]: `linechart.svg`,
    [WidgetType.HEALTH_SCORE]: `boxplotchart.svg`,
    [WidgetType.PI_GANTT]: 'Table.svg',
    [WidgetType.PI_FEATURE_COMPLETION_STATUS]: 'Table.svg',
    [WidgetType.PI_ISSUE_LIFE_CYCLE]: 'Table.svg',
    [WidgetType.SRE_SQUARES]: 'Table.svg',
    [WidgetType.SRE_HEXAGONS]: 'Table.svg',
    [WidgetType.CODE_ISSUE_DETAILS]: 'boxplotchart.svg',
    [WidgetType.SECURITY_POSTURE_DETAILS]: 'boxplotchart.svg',
    [WidgetType.SRE_GANTT]: 'Table.svg',
    [WidgetType.FACTOR_SCORES]: 'Table.svg',
    [WidgetType.CODE_QUALITY_SUMMARY]: 'Table.svg',
    [WidgetType.SCORE_DOUGHNUT]: 'piechart.svg',
    [WidgetType.METRIC_LINE]: `linechart.svg`,
    [WidgetType.TOP_PRIORITY_RISKS]: `Table.svg`,
    [WidgetType.PORTFOLIO_HEALTH_SCORES]: 'Table.svg',
    [WidgetType.PORTFOLIO_SCORE_SUBCATEGORIES]: 'Table.svg',
    [WidgetType.PORTFOLIO_STATISTICS]: 'Table.svg',
    [WidgetType.PORTFOLIO_RISK_SCORES]: 'Table.svg',
    [WidgetType.COMPARE_METRICS]: 'Table.svg',
    [WidgetType.MERGE_REQUEST_INFO]: 'Table.svg',
    [WidgetType.MULTIPLE_SERIES_TYPE]: 'linechart.svg',
    [WidgetType.METRIC_TILE]: 'boxplotchart.svg',
};

@Pipe({
    name: 'widgetPreview',
})
export class WidgetPreviewPipe implements PipeTransform {
    transform(type: WidgetType, full = true): string {
        if (!type) {
            return null;
        }
        const path = WIDGETS_PREVIEW[type] ?? WIDGETS_PREVIEW[WidgetType.COMPLEX];

        return full ? `/assets/images/charts/${path}` : `/${path}`;
    }
}
