import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormArray, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { DropdownItem } from '@dagility-ui/kit';

import {
    ChartOptions,
    ComplexWidgetLayout,
    TOOL_CATEGORY_TYPES,
    WidgetBarPosition,
    WidgetOrientation,
    WidgetType,
    XAxisType,
} from '../../../models/any-widget.model';
import { WidgetBuilderFacade } from '../../../state/widget-builder.facade';
import {
    hasAdditionalTemplates,
    hasHeader,
    hasHideLegendControl,
    hasThreshold,
    hasTooltipFormatter,
    isBarChartWidget,
    isPiWorkChart,
    isWidgetWithColors,
    isWidgetWithSeries,
} from '../../../services/widget-builder.util';
import { WidgetBuilderTagsService } from 'data-processor/lib/widget-library/widget-builder/services/widget-builder-tags.service';
import { DATA_MORPH_FEATURE_TOGGLE, DataMorphFeatureToggleService } from 'data-processor/tokens';
import { GLOBAL_FILTERS_EDITOR } from 'data-processor/lib/widget-library/widget-builder/providers/global-filters-editor.token';
import { DisplaySettingsConfig } from 'data-processor/lib/widget-library/widget-builder/models/display-settings.config';

@Component({
    selector: 'dp-widget-builder-chart-options',
    templateUrl: './widget-builder-chart-options.component.html',
    styleUrls: ['./widget-builder-chart-options.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class WidgetBuilderChartOptionsComponent implements OnInit {
    @Input() widgetType: WidgetType;

    @Input() basicChartOptionControlsAvailable: boolean = true;

    @Input() block: any;

    @Input() hideDescription: boolean;

    @Input() isRoot = false;

    @Input() displaySettingsConfig: DisplaySettingsConfig;

    @Output() typeChange = new EventEmitter<DropdownItem<WidgetType> | null>();

    get colors(): FormArray {
        return this.widgetFormGroup.get('colors') as FormArray;
    }

    get tagsControl(): FormControl {
        return this.widgetFormGroup.get('tags') as FormControl;
    }

    get inputPlaceholdersControl(): FormControl {
        return this.widgetFormGroup.get('inputPlaceholders') as FormControl;
    }

    get pagination(): FormControl {
        return this.widgetFormGroup.get('chartOptions.gridPagination') as FormControl;
    }

    get showTotal(): FormControl {
        return this.widgetFormGroup.get('chartOptions.showTotal') as FormControl;
    }

    get showCount(): FormControl {
        return this.widgetFormGroup.get('chartOptions.showCount') as FormControl;
    }

    get widgetFormGroup() {
        return this.parent.control;
    }

    get chartOptionsControl() {
        return this.widgetFormGroup.get('chartOptions') as FormGroup;
    }

    type = WidgetType;

    tags: DropdownItem[] = [];

    defaultTags: string[];

    color = '';

    inputPlaceholdersItems: DropdownItem[] = [];

    layouts: DropdownItem<ComplexWidgetLayout>[] = [
        {
            label: '1-1',
            value: '1-1',
        },
        {
            label: '1-2',
            value: '1-2',
        },
        {
            label: '2-1',
            value: '2-1',
        },
        {
            label: '2-2',
            value: '2-2',
        },
        {
            label: '1-1 vertical',
            value: '1-1-vertical',
        },
    ];

    barsPositions: DropdownItem<WidgetBarPosition>[] = [
        {
            label: 'Stacked',
            value: 'Stacked',
        },
        {
            label: 'Unstacked',
            value: 'Unstacked',
        },
        {
            label: 'Combined',
            value: 'Combined',
        },
        {
            label: 'Ad',
            value: 'Ad',
        },
    ].sort((a, b) => a.label.localeCompare(b.label)) as DropdownItem<WidgetBarPosition>[];

    orientation: DropdownItem<WidgetOrientation>[] = [
        {
            label: 'Horizontal',
            value: 'Horizontal',
        },
        {
            label: 'Vertical',
            value: 'Vertical',
        },
    ].sort((a, b) => a.label.localeCompare(b.label)) as DropdownItem<WidgetOrientation>[];

    xAxisType: DropdownItem<XAxisType>[] = [
        {
            label: 'Category',
            value: 'category',
        },
        {
            label: 'Time',
            value: 'time',
        },
        {
            label: 'Log',
            value: 'log',
        },
    ].sort((a, b) => a.label.localeCompare(b.label)) as DropdownItem<XAxisType>[];

    tooltipTriggers: Array<DropdownItem<ChartOptions['tooltipTrigger']>> = [
        {
            label: 'Item',
            value: 'item',
        },
        { label: 'Axis', value: 'axis' },
        { label: 'None', value: 'none' },
    ];

    TOOL_CATEGORY_TYPES = TOOL_CATEGORY_TYPES;

    colorByOptions: Array<DropdownItem<ChartOptions['colorBy']>> = [
        { label: 'Series', value: 'series' },
        { label: 'Data', value: 'data' },
    ];

    widgetTypes: DropdownItem<WidgetType>[] = [
        {
            value: WidgetType.BAR_CHART,
            label: 'Bar Chart',
        },
        {
            value: WidgetType.LINE_CHART,
            label: 'Line Chart',
        },
        {
            value: WidgetType.PIE_CHART,
            label: 'Pie Chart',
        },
        {
            value: WidgetType.DOUGHNUT_CHART,
            label: 'Doughnut Chart',
        },
        {
            value: WidgetType.SUNBURST_CHART,
            label: 'Sunburst Chart',
        },
        {
            value: WidgetType.NESTED_PIE_CHART,
            label: 'Nested Pie Chart',
        },
        {
            value: WidgetType.TABLE,
            label: 'Grid',
        },
        {
            value: WidgetType.TWO_DIMENSIONAL_TABLE,
            label: 'Two Dimensional Grid',
        },
        {
            value: WidgetType.STACKED_BAR_CHART,
            label: 'Stacked Bar Chart',
        },
        {
            value: WidgetType.COMPLEX,
            label: 'Complex Widget',
        },
        {
            value: WidgetType.TREEMAP,
            label: 'Tree map',
        },
        {
            value: WidgetType.PROGRESS,
            label: 'Progress Widget',
        },
        {
            value: WidgetType.BOXPLOT,
            label: 'Boxplot',
        },
        {
            value: WidgetType.TILE_CHART,
            label: 'Tile Chart',
        },
        {
            value: WidgetType.LOG,
            label: 'Log Chart',
        },
        {
            value: WidgetType.MULTIPLE_Y_AXIS,
            label: 'Multiple Y Axis Chart',
        },
        {
            value: WidgetType.ACCORDION,
            label: 'Accordion',
        },
        {
            value: WidgetType.GANTT,
            label: 'Gantt',
        },
        {
            value: WidgetType.RADIAL,
            label: 'Radial',
        },
        {
            value: WidgetType.PI_WORK_DISTRIBUTION,
            label: 'PI Work Distribution',
        },
        {
            value: WidgetType.PI_WORK_CHART,
            label: 'PI Work Chart',
        },
        {
            value: WidgetType.PI_MULTIPLE_RADIALS,
            label: 'PI Multiple Radials',
        },
        {
            value: WidgetType.PI_PROGRESS_MENU,
            label: 'PI Progress Menu',
        },
        {
            value: WidgetType.PI_ISSUE_LIFE_CYCLE,
            label: 'PI Issue Life Cycle',
        },
        {
            value: WidgetType.TABLE_WITH_TABS,
            label: 'Grid With Tabs',
        },
        {
            value: WidgetType.HEALTH_SCORE,
            label: 'Health Score',
        },
        {
            value: WidgetType.ACCORDION_WITH_TABS,
            label: 'Accordion With Tabs',
        },
        {
            value: WidgetType.SCATTER_CHART,
            label: 'Scatter Chart',
        },
        {
            value: WidgetType.PI_GANTT,
            label: 'PI Gantt',
        },
        {
            value: WidgetType.PI_FEATURE_COMPLETION_STATUS,
            label: 'PI Feature Completion Status',
        },
        {
            value: WidgetType.SRE_SQUARES,
            label: 'SRE Squares',
        },
        {
            value: WidgetType.SRE_HEXAGONS,
            label: 'SRE Hexagons',
        },
        {
            value: WidgetType.CODE_ISSUE_DETAILS,
            label: 'Code Issue Details',
        },
        {
            value: WidgetType.SECURITY_POSTURE_DETAILS,
            label: 'Security Posture Details',
        },
        {
            value: WidgetType.SRE_GANTT,
            label: 'SRE Gantt',
        },
        {
            value: WidgetType.FACTOR_SCORES,
            label: 'Factor Scores',
        },
        {
            value: WidgetType.CODE_QUALITY_SUMMARY,
            label: 'Code Quality',
        },
        {
            value: WidgetType.SCORE_DOUGHNUT,
            label: 'Score Doughnut',
        },
        {
            value: WidgetType.METRIC_LINE,
            label: 'Metric Line',
        },
        {
            value: WidgetType.TOP_PRIORITY_RISKS,
            label: 'Top Priority Risks',
        },
        {
            value: WidgetType.PORTFOLIO_HEALTH_SCORES,
            label: 'Portfolio Health Scores',
        },
        {
            value: WidgetType.PORTFOLIO_SCORE_SUBCATEGORIES,
            label: 'Portfolio Score Subcategories',
        },
        {
            value: WidgetType.PORTFOLIO_STATISTICS,
            label: 'Portfolio Statistics',
        },
        {
            value: WidgetType.PORTFOLIO_RISK_SCORES,
            label: 'Portfolio Risk Scores',
        },
        {
            value: WidgetType.COMPARE_METRICS,
            label: 'Compare Metrics',
        },
        {
            value: WidgetType.MERGE_REQUEST_INFO,
            label: 'Merge request info',
        },
        {
            value: WidgetType.MULTIPLE_SERIES_TYPE,
            label: 'Multiple Series Type',
        },
        {
            value: WidgetType.METRIC_TILE,
            label: 'Metric Tile',
        },
    ].sort((a, b) => a.label.localeCompare(b.label));

    isWidgetWithSeries = isWidgetWithSeries;
    isWidgetWithColors = isWidgetWithColors;
    isBarChartWidget = isBarChartWidget;
    isPiWorkChart = isPiWorkChart;
    hasTooltipFormatter = hasTooltipFormatter;
    hasHeader = hasHeader;
    hasAdditionalTemplates = hasAdditionalTemplates;
    hasThreshold = hasThreshold;
    hasHideLegendControl = hasHideLegendControl;

    constructor(
        public facade: WidgetBuilderFacade,
        public parent: FormGroupDirective,
        private tagsService: WidgetBuilderTagsService,
        @Inject(GLOBAL_FILTERS_EDITOR) public globalFiltersMode: boolean,
        @Inject(DATA_MORPH_FEATURE_TOGGLE) private featureToggleService: DataMorphFeatureToggleService
    ) {}

    ngOnInit() {
        this.tagsService.getTags().subscribe(value => {
            this.defaultTags = value;
            this.tags = value.map(item => ({ value: item, label: item }));
        });

        this.inputPlaceholdersControl.patchValue(
            this.inputPlaceholdersControl.value.map((value: string) => ({ label: value, value: value }))
        );
        this.inputPlaceholdersItems = this.inputPlaceholdersControl.value;
    }

    showTotalCountToggle(e: any, type: any) {
        if (type === 'total') {
            this.showCount.patchValue(false);
        }
        if (type === 'count') {
            this.showTotal.patchValue(false);
        }
    }

    deleteColor(index: number): void {
        this.colors.removeAt(index);
    }

    colorPickerSelect(): void {
        this.colors.push(this.facade.buildColorsControl(this.color));
    }

    searchTags(searchStr: string) {
        this.tags = this.defaultTags
            .filter(tag => tag.toLowerCase().includes(searchStr.toLowerCase()))
            .map(item => ({ value: item, label: item }));
    }

    addTagFn = (value: string) => {
        this.tags = [...this.tags, { value: value, label: value }];
        this.defaultTags = this.tags.map(tag => tag.value);
    };

    addInputPlaceholdersFn(value: string) {
        return {
            label: value,
            value,
        };
    }

    handleInputPlaceholdersChange(data: string[]) {
        this.inputPlaceholdersControl.patchValue(data);
        this.inputPlaceholdersItems = data.map((value: string) => ({ label: value, value: value }));
    }
}
