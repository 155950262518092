<ng-container>
    <button class="btn btn-link p-0 mr-2 ml-1 group-header" [ngbPanelToggle]="panel" [class.group-header--opened]="opened">
        <fa-icon class="font-16px" [icon]="icon"></fa-icon>
    </button>
    <span [style.font-size.px]="fontSize$ | async" class="group-header" [class.group-header--opened]="opened">
        {{ groupName$ | async }}
    </span>
    <div
        class="custom-badge widgets-count ml-3 px-10px"
        [style.border-color]="group.definition.headerColor || '#000'"
        [style.color]="group.definition.headerColor || '#000'"
    >
        {{ group.widgets.length }}
    </div>
    <div *ngIf="!edit else editOptions" class="border-top flex-1 mx-3 group-divider"></div>
    <ng-template #editOptions>
        <ng-container *ngIf="groupActions$ | async as groupActionsData">
            <lib-treelike-menu
                *ngIf="groupActionsData.length"
                btnClasses="btn-secondary ml-2 edit-button-new"
                [openIconStyles]="{
                    color: 'var(--da-brand-base)'
                }"
                [treeItems]="groupActionsData"
                [closeOnClick]="true"
            ></lib-treelike-menu>
        </ng-container>
    </ng-template>
</ng-container>
