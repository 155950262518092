import { CiCdProperty, CiCdFieldType } from '../fields-grid/model/fields.model';
import { DropdownItem } from '@dagility-ui/kit';
import { isObject } from 'lodash';

export type FieldTypeMap<T> = { [key in CiCdFieldType]: T };
export type FieldTypeFunctionsMap = FieldTypeMap<Function>;

const mapDropdownItemValue = (map: Function) => (item: any) => map(isDropdownItem(item) ? item.value : item);

const mapPropertyValue = (mapFn: any, property: CiCdProperty) =>
    property.array ? (property.value || []).map(mapDropdownItemValue(mapFn)) : mapDropdownItemValue(mapFn)(property.value);

export const getOrDefault = <T>(predicate: (a: T) => boolean) => (defaultValue: T) => (item: T) => (predicate(item) ? item : defaultValue);

export const identity = (id: any) => id;

export function normalizeProperties(properties: CiCdProperty[]) {
    const typeMapFn: FieldTypeFunctionsMap = {
        BOOLEAN: (bool: any) => (bool === undefined ? null : bool),
        STRING: identity,
        STRING_MULTILINE: identity,
        CREDENTIAL: identity,
        NUMBER: Number,
        GROOVY: identity,
    };

    return properties.map(property => ({
        ...property,
        value: mapPropertyValue(typeMapFn[property.type], property),
    }));
}

export function isDropdownItem(item: any): item is DropdownItem {
    return isObject(item) as any && item.value && item.label;
}
