import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { GridColumn, validateFormAndDisplayErrors } from '@dagility-ui/kit';
import { ProcessorMonitoringService } from '../../processor-monitoring.service';

@Component({
    selector: 'dp-sql-executor-tab',
    templateUrl: './sql-executor-tab.component.html',
    styleUrls: ['./sql-executor-tab.component.scss'],
})
export class SqlExecutorTabComponent {
    @Input() sources$: Observable<any>;

    @Input() form: FormGroup;

    dataSource$: Observable<{
        columns: GridColumn[];
        data: Array<any[]>;
    }> | null;

    constructor(private api: ProcessorMonitoringService) {}

    handleExecute() {
        validateFormAndDisplayErrors(this.form);

        if (!this.form.valid) {
            return;
        }
        const value = this.form.value;

        const isJDBC = !['POSTGRESQL', 'CLICKHOUSE'].includes(value.source);
        this.dataSource$ = this.api.executeQuery(isJDBC ? 'JDBC' : value.source, isJDBC ? value.source : null, value.query, {}).pipe(
            catchError(() => of([])),
            map(data => ({
                columns: data.length ? this.getColumns(data) : [],
                data,
            }))
        );
    }

    getColumns(data: any[]): GridColumn[] {
        return Array.from(
            data.reduce<Set<string>>((set, row) => {
                Object.keys(row).forEach(key => {
                    set.add(key);
                });

                return set;
            }, new Set())
        ).map(title => ({ title }));
    }
}
