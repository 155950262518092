import {Component, EventEmitter, Input, Output} from '@angular/core';
import {interval, Subscription} from 'rxjs';

@Component({
    selector: 'app-count-down',
    templateUrl: './count-down.component.html',
})
export class CountDownComponent {
    @Input() dateUntil: string;
    @Output() finished = new EventEmitter();

    milliSecondsInASecond = 1000;
    hoursInADay = 24;
    minutesInAnHour = 60;
    secondsInAMinute = 60;

    subscription: Subscription;
    timer: Countdown;

    ngOnInit() {
        this.timer = this.allocateTimeUnits();
        if (this.timer.daysLeft < 0 || this.timer.hoursLeft < 0 || this.timer.minutesLeft < 0 || +this.timer.secondsLeft < 0) {
            this.finished.emit();
        }
        this.subscription = interval(1000).subscribe(() => {
            this.timer = this.allocateTimeUnits();
        });
    }

    private allocateTimeUnits() {
        const timeDifference = new Date(this.dateUntil).getTime() - new Date().getTime();
        const secondsLeft = ('0' + Math.floor((timeDifference / this.milliSecondsInASecond) % this.secondsInAMinute).toString()).slice(-2);
        const minutesLeft = Math.floor((timeDifference / (this.milliSecondsInASecond * this.minutesInAnHour)) % this.secondsInAMinute);
        const hoursLeft = Math.floor(
            (timeDifference / (this.milliSecondsInASecond * this.minutesInAnHour * this.secondsInAMinute)) % this.hoursInADay
        );
        const daysLeft = Math.floor(
            timeDifference / (this.milliSecondsInASecond * this.minutesInAnHour * this.secondsInAMinute * this.hoursInADay)
        );
        if (!daysLeft && !hoursLeft && !minutesLeft && !+secondsLeft)  {
            this.finished.emit();
        }
        return { secondsLeft, minutesLeft, hoursLeft, daysLeft };
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}

export interface Countdown {
    secondsLeft: string;
    minutesLeft: number;
    hoursLeft: number;
    daysLeft: number;
}
