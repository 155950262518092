import {
    Component,
    EventEmitter,
    inject,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormControl, FormGroup, NgForm, NgModel, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import { ArrayByFieldDuplication, DropdownItem, FORM_ERRORS, validateFormAndDisplayErrors } from '@dagility-ui/kit';
import {
    ToasterService,
    TOOL_CATEGORY_NAMES,
    ToolAuthentication,
    ToolAuthenticationType,
    ToolCategoryType,
    ToolCreateResponse,
    ToolService,
    ValidationPattern,
} from '@dagility-ui/shared-components';

const JDBC_CATEGORY_TYPE = 'JDBC';
const DUPLICATE_HEADER_ERROR = 'duplicateHeader';
const POSTFIX_PROXY = '_PROXY';

enum AuthenticationType {
    JDBC = 'JDBC',
    FORM = 'FORM',
    BASIC = 'BASIC',
    SCRIPT = 'SCRIPT',
    TOKEN = 'TOKEN',
}

enum HTTPMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
}

const BASE_PROXY_URL = 'http://dagility-git-proxy';

@Component({
    selector: 'dp-configure-access-popup',
    templateUrl: './configure-access-popup.component.html',
    styleUrls: ['./configure-access-popup.component.scss'],
    providers: [
        {
            provide: FORM_ERRORS,
            useFactory: () => ({
                ...inject(FORM_ERRORS, { skipSelf: true }),
                [DUPLICATE_HEADER_ERROR]: () => `Header should be unique`,
            }),
        },
    ],
})
export class ConfigureAccessPopupComponent implements OnInit, OnDestroy {
    @Input() plugin: any;
    @Input() editForm = false;
    @Input() name: any;
    @Input() imagePath = 'assets/images/icons/bitbucket.png';

    @Output() updateStatus: EventEmitter<any> = new EventEmitter();
    @Output() runToolWizard: EventEmitter<any> = new EventEmitter();

    @ViewChild('form') formValues: NgForm;
    @ViewChild('username', { static: true }) username: NgModel;
    @ViewChild('p', { static: true }) p: NgModel;
    @ViewChild('token', { static: true }) token: NgModel;
    @ViewChild('authTemplate', { static: true }) authTemplate: NgModel;

    buttonText = 'Save and Close';
    test: any;
    passwordShown = false;
    pluginName = '';

    icons = {
        faTimes: faTimes,
        faEye: faEye,
        faEyeSlash: faEyeSlash,
        faCheck: faCheck,
    };

    readonly defaultCredentials = {
        username: '',
        password: '',
        tokenHeader: '',
        token: '',
        authTemplate: '',
    };

    changedCredentials = {
        username: '',
        password: '',
        tokenHeader: '',
        token: '',
        authTemplate: '',
    };
    defaultVerificationPath = '';

    readonly urlPatterns: Record<string, RegExp | string> = {
        [JDBC_CATEGORY_TYPE]: /jdbc:\w+:\/\/.+\:\d+(\/\w+)?/,
    };

    readonly defaultUrlPattern = 'https?://.+';
    readonly defaultUrlValidator = Validators.pattern(this.defaultUrlPattern);

    selectedUrlPattern: string | RegExp = this.defaultUrlPattern;

    customPatterns: Record<string, ValidationPattern[]> = {
        URL: [
            {
                pattern: '.*[^/]',
                errorMsg: 'No trailing slash allowed!',
                errorKey: 'lastSlashPattern',
            },
        ],
    };

    isJDBCTool = false;
    previousAuthType: ToolAuthenticationType | null = null;
    credentialsVerified = false;
    credentialsRequired = true;
    notVerified = false;
    private formSuccessfulSent = false;

    authTypes: Record<string, ToolAuthentication> = {};
    authTypesDropdownItems: DropdownItem[] = [];
    readonly defaultValueField: Partial<{ [key in ToolAuthenticationType]: string }> = {
        FORM: 'authTemplate',
        TOKEN: 'tokenHeader',
        SCRIPT: 'authTemplate',
    };

    readonly httpMethods = [HTTPMethod.PUT, HTTPMethod.GET, HTTPMethod.POST]
        .sort((a, b) => a.localeCompare(b))
        .map(item => ({
            label: item,
            value: item,
        }));

    readonly authExtensionForm = new FormGroup(
        {
            method: new FormControl(HTTPMethod.GET),
            headers: new FormArray([]),
            proxyEnabled: new FormControl(false),
            proxy: new FormControl(''),
        },
        [headerNameUniqueValidator as ValidatorFn]
    );

    isAuthExtensionsFormClosed = true;
    destroyed$ = new Subject<void>();
    isProxyRequired$ = this.authExtensionForm.get('proxy').statusChanges.pipe(
        map(() => this.authExtensionForm.get('proxy').hasValidator(Validators.required)),
        takeUntil(this.destroyed$)
    );

    get headersControl() {
        return this.authExtensionForm.get('headers') as FormArray;
    }

    constructor(
        public activeModal: NgbActiveModal,
        private spinnerService: SpinnerVisibilityService,
        private pluginService: ToolService,
        private toaster: ToasterService,
        private renderer: Renderer2,
        @Inject('templateProvider') public templateProvider: Record<string, TemplateRef<any>>
    ) {}

    ngOnInit() {
        this.listenCheckboxState();
        this.pluginName = this.plugin ? (this.plugin.name ? this.plugin.name : this.pluginName) : this.pluginName;
        if (this.editForm) {
            this.credentialsVerified = this.plugin.verified;
            this.credentialsRequired = !this.plugin.verified;
        }

        if (this.plugin.authTypes && this.plugin.authTypes.length) {
            this.authTypes = this.plugin.authTypes.reduce((acc: Record<string, any>, authType: ToolAuthentication) => {
                acc[authType.type] = authType;

                return acc;
            }, {});

            this.authTypesDropdownItems = this.plugin.authTypes.map(
                (authType: ToolAuthentication) =>
                    ({
                        label: authType.type,
                        value: authType.type,
                    } as DropdownItem)
            );

            if (this.authTypesDropdownItems.length && !this.editForm) {
                this.plugin.authType = this.authTypesDropdownItems[0].value;
            }
        }

        if (this.plugin) {
            this.buildAuthExtensionForm(this.plugin);
            if (!this.credentialsRequired) {
                this.authExtensionForm.disable();
            }
            this.isJDBCTool = this.plugin.pluginCategories[0] === JDBC_CATEGORY_TYPE;
            this.setUrlPattern(this.plugin.authType);
            this.defaultVerificationPath = this.plugin.verificationPath;
            this.setAuth(this.plugin.authType);
        }

        if (!this.editForm) {
            this.pluginService.generateToolSettings(this.plugin.pluginType, this.plugin.name).subscribe((response: any) => {
                if (response.status === 200) {
                    this.plugin.toolId = response.result.toolId;
                    this.plugin.name = response.result.toolName;
                }
            });
        }
    }

    getContextHelpSuffix(): string {
        return this.plugin && this.plugin.pluginType ? `-${normalizeToolType(this.plugin.pluginType).toLowerCase()}` : '';
    }

    getMultiToolCategoriesText(type: ToolCategoryType) {
        return TOOL_CATEGORY_NAMES.get(type);
    }

    setAuth(authType: ToolAuthenticationType | null) {
        if (!authType) {
            return;
        }

        const selectedAuth = this.authTypes[authType];
        const field = (this.defaultValueField as Record<string, any>)[authType];

        this.changedCredentials = field
            ? {
                  ...this.defaultCredentials,
                  [field]: selectedAuth.value,
              }
            : { ...this.defaultCredentials };

        if (authType === AuthenticationType.FORM) {
            this.changedCredentials.tokenHeader = (selectedAuth as Record<string, any>)['tokenXPath'];
        }

        if (authType === AuthenticationType.TOKEN && selectedAuth.value && selectedAuth.value.includes(':')) {
            const tokenComps = selectedAuth.value.split(':');
            this.changedCredentials.tokenHeader = tokenComps[0].trim();
            this.changedCredentials.token = tokenComps[1].trim();
        }

        if (this.plugin) {
            this.setUrlPattern(authType);
        }

        this.previousAuthType = authType;
    }

    setUrlPattern(authType: ToolAuthenticationType) {
        const urlPattern = this.urlPatterns[this.plugin.pluginCategories[0]];

        if (!!urlPattern) {
            this.selectedUrlPattern = urlPattern;

            return;
        }

        if (authType === AuthenticationType.JDBC) {
            this.selectedUrlPattern = this.urlPatterns[JDBC_CATEGORY_TYPE];

            return;
        }

        this.selectedUrlPattern = this.defaultUrlPattern;
    }

    handleAuthTypeChange(e: any) {
        if (e) {
            this.setAuth(e.value);
        } else {
            this.changedCredentials = { ...this.defaultCredentials };
        }
        this.showCredentialFields();

        this.notVerified = false;
    }

    userPassCheckStatusAndSetFocus(e: any) {
        this.setFocus(e);
        this.renderer.removeAttribute(e.target, 'readonly');
    }

    verifyAccess() {
        const basicFields = ['username', 'p'];

        const controlNamesByAuthType: { [key in ToolAuthenticationType]: string[] } = {
            BASIC: basicFields,
            TOKEN: ['tokenHeader', 'token'],
            FORM: ['authTemplate'],
            SCRIPT: ['script'],
            JDBC: basicFields,
        };

        const controls = this.formValues.form.controls;

        controls.authType.markAsTouched();

        if (!this.validateAuthExtensionForm()) {
            return;
        }

        if (!controls.authType.errors) {
            const controlNames = ['URL', ...controlNamesByAuthType[this.plugin.authType as ToolAuthenticationType]];

            controlNames.forEach(name => controls[name].markAsTouched());

            if (controlNames.every(name => !controls[name].errors)) {
                this.plugin.changedCredentials = true;

                this.plugin = {
                    ...this.plugin,
                    ...this.changedCredentials,
                    authExt: this.prepareAuthExtension(),
                };

                this.pluginService.verifyAccess(this.plugin).subscribe(
                    (result: any) => {
                        if (!result) {
                            this.notVerified = false;
                            this.credentialsVerified = true;
                        } else {
                            this.toaster.errorToast(
                                {
                                    title: `Authentication Failed`,
                                    content: result.result,
                                },
                                5000
                            );

                            this.notVerified = true;
                            this.credentialsVerified = false;
                        }
                    },
                    () => {
                        this.formSuccessfulSent = false;
                    }
                );
            }
        }
    }

    needToVerify() {
        return (
            !this.credentialsVerified &&
            this.plugin.authType &&
            (([AuthenticationType.BASIC, AuthenticationType.JDBC].includes(this.plugin.authType) && this.changedCredentials.password) ||
                ([AuthenticationType.FORM, AuthenticationType.SCRIPT].includes(this.plugin.authType) &&
                    this.changedCredentials.authTemplate) ||
                (this.plugin.authType === AuthenticationType.TOKEN && this.changedCredentials.tokenHeader && this.changedCredentials.token))
        );
    }

    onSubmit() {}

    submitFrom() {
        if (this.formSuccessfulSent) {
            if (this.editForm) {
                this.closeByUpdate();
            } else {
                this.closeByCreate();
            }
        } else {
            this.sendForm(true);
        }
    }

    validateAndSendForm() {
        const controls = this.formValues.form.controls;
        for (const key in controls) {
            controls[key].markAsTouched();
        }

        if (!this.validateAuthExtensionForm()) {
            return;
        }

        if (this.formValues.form.valid && (this.credentialsVerified || this.plugin.verified)) {
            this.sendForm(false);
        }
    }

    sendForm(closeForm: boolean = false) {
        this.plugin.verified = this.credentialsVerified;
        if (this.editForm) {
            this.spinnerService.show();
            this.pluginService.editTool(this.plugin, this.plugin.toolId).subscribe(
                result => {
                    this.spinnerService.hide();

                    if (result.status === 200) {
                        this.toaster.successToast({
                            title: 'Success',
                            content: result.result,
                        });

                        if (closeForm) {
                            this.closeByUpdate();
                        } else {
                            this.formSuccessfulSent = true;
                        }
                    } else {
                        this.formSuccessfulSent = false;

                        const error = result.errors[0];
                        this.toaster.errorToast(
                            {
                                title: 'Error',
                                content: `${error.errorDescription}!`,
                            },
                            5000
                        );
                    }
                },
                () => {
                    this.formSuccessfulSent = false;
                    this.toaster.errorToast({
                        title: 'Error',
                        content: '',
                    });
                }
            );
        } else {
            this.spinnerService.show();
            this.pluginService.createTool(this.plugin).subscribe(result => {
                this.spinnerService.hide();

                if (result.status !== 200) {
                    this.formSuccessfulSent = false;

                    const error = result.errors[0];
                    this.toaster.errorToast(
                        {
                            title: 'Error',
                            content: `${error.errorDescription}!`,
                        },
                        5000
                    );
                } else {
                    this.toaster.successToast({
                        title: 'Success',
                        content: 'Tool created successfully',
                    });

                    if (closeForm) {
                        this.closeByCreate();
                    } else {
                        this.formSuccessfulSent = true;
                    }

                    if (this.pluginName.match(/\.*Azure\.*/gi) && result.result) {
                        const pmTool: ToolCreateResponse = result.result;
                        if (pmTool.pluginCategories.indexOf('PM') === -1) {
                            return;
                        }
                        this.runToolWizard.emit(pmTool.toolId);
                    }
                }
            });
        }
    }

    getPluginCategoriesAsString() {
        return this.plugin.pluginCategories.map((x: ToolCategoryType) => TOOL_CATEGORY_NAMES.get(x)).join(', ');
    }

    closeByCreate() {
        this.activeModal.close('created');
    }

    closeByUpdate() {
        this.updateStatus.emit('success');
        this.activeModal.close('updated');
    }

    onCredentialsInputChange() {
        const companyOrServerName = this.editForm || !this.plugin.baseUrl ? null : this.getCompanyNameFromBaseUrl(this.plugin.baseUrl);
        this.plugin.name = this.pluginName + (companyOrServerName ? ' ' + companyOrServerName : '');
    }

    getCompanyNameFromBaseUrl(baseUrl: any) {
        if (this.pluginName.match(/\.*Azure\.*/gi)) {
            if (baseUrl.match(/\w+:\/\/dev.azure.com\/(\w+)(\.+|\W+|\w+)*/gi)) {
                return baseUrl.replace(/\w+:\/\/dev.azure.com\/(\w+)(\.+|\W+|\w+)*/gi, '$1');
            } else if (baseUrl.match(/\w+:\/\/(\w+).visualstudio.com(\w+|\W+|\.+)*/gi)) {
                return baseUrl.replace(/\w+:\/\/(\w+).visualstudio.com(\w+|\W+|\.+)*/gi, '$1');
            } else if (baseUrl.match(/\w+:\/\/\w+(.\w+)*\/tfs\/(\w+)(\W+|\w+|\.+)*/gi)) {
                return baseUrl.replace(/\w+:\/\/\w+(.\w+)*\/tfs\/(\w+)(\W+|\w+|\.+)*/gi, '$2');
            }
        } else if (this.pluginName.match(/\.*atlassian|jira\.*/gi) && baseUrl.match(/\w+:\/\/(\w+).atlassian.net(\w+|\W+|\.+)*/gi)) {
            return baseUrl.replace(/\w+:\/\/(\w+).atlassian.net(\w+|\W+|\.+)*/gi, '$1');
        } else if (this.pluginName.match(/\.*jenkins\.*/gi) && baseUrl.match(/\w+:\/\/jenkins.(\w+)(.\w+)*(\W+|\.+)*/gi)) {
            return baseUrl.replace(/\w+:\/\/jenkins.(\w+)(.\w+)*(\w+|\W+|\.+)*/gi, '$1');
        } else if (this.pluginName.match(/\.*bitbucket\.*/gi) && baseUrl.match(/\w+:\/\/bitbucket.(\w+)(.\w+)*(\W+|\.+)*/gi)) {
            return baseUrl.replace(/\w+:\/\/bitbucket.(\w+)(.\w+)*(\w+|\W+|\.+)*/gi, '$1');
        } else if (this.pluginName.match(/\.*gitlab\.*/gi) && baseUrl.match(/\w+:\/\/gitlab.(\w+)(.\w+)*(\W+|\.+)*/gi)) {
            return baseUrl.replace(/\w+:\/\/gitlab.(\w+)(.\w+)*(\w+|\W+|\.+)*/gi, '$1');
        }
        return null;
    }

    showCredentialFields(passParams = false) {
        this.plugin.verified = false;
        this.credentialsVerified = false;
        this.credentialsRequired = true;
        this.authExtensionForm.enable();
        this.checkProxyControlStatus(!!this.authExtensionForm.get('proxyEnabled').value);

        if (passParams && this.plugin.authType) {
            this.setAuth(this.plugin.authType);
        }
    }

    showPassword() {
        this.passwordShown = !this.passwordShown;
    }

    setReadonlyAndResetFocus(e: any) {
        this.resetFocus(e);
        this.renderer.setAttribute(e.target, 'readonly', 'true');
    }

    resetSubCollectionNameAndResetFocus(e: any) {
        this.resetFocus(e);
        if (this.plugin.subCollection === '') {
            this.formValues.form.controls.subCollection.reset();
        }
    }

    resetSentFlag() {
        this.formSuccessfulSent = false;
    }

    setFocus(e: any) {
        const parentEl = e.target.parentElement;
        this.renderer.addClass(parentEl, 'popup-form-control-focused');
    }

    resetFocus(e: any) {
        const parentEl = e.target.parentElement;
        this.renderer.removeClass(parentEl, 'popup-form-control-focused');
    }

    handleRemoveHeader(index: number) {
        this.headersControl.removeAt(index);
    }

    handleAddNewHeader() {
        if (!(this.headersControl.value as DropdownItem[]).some(item => !item.label)) {
            this.headersControl.push(this.buildHeaderFormGroup());
        }
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }

    private buildHeaderFormGroup(header: string = '', value: string = '') {
        return new FormGroup({
            label: new FormControl(header, Validators.required),
            value: new FormControl(value),
        });
    }

    private prepareAuthExtension() {
        const { method, headers, proxy, proxyEnabled } = this.authExtensionForm.value;

        const formValue: Record<string, any> = {
            method,
            headers: ((headers ?? []) as DropdownItem[]).reduce((acc, item) => {
                acc[item.label] = item.value;

                return acc;
            }, {} as Record<string, string>),
        };

        if (proxy && proxyEnabled) {
            formValue.proxy = proxy;
        }

        return formValue;
    }

    private buildAuthExtensionForm(plugin: any) {
        this.authExtensionForm.get('method').patchValue(plugin?.authExt?.method ?? HTTPMethod.GET);
        const proxy = plugin?.authExt?.proxy;

        this.authExtensionForm.get('proxyEnabled').patchValue(!!proxy);
        this.authExtensionForm.get('proxy').patchValue(proxy || '');

        for (const [header, value] of Object.entries(plugin?.authExt?.headers ?? {})) {
            this.headersControl.push(this.buildHeaderFormGroup(header, value as string));
        }
    }

    private validateAuthExtensionForm() {
        validateFormAndDisplayErrors(this.authExtensionForm);

        if (this.authExtensionForm.invalid) {
            this.isAuthExtensionsFormClosed = false;
        }

        return !this.authExtensionForm.invalid;
    }

    private listenCheckboxState() {
        const proxyEnabledControl = this.authExtensionForm.get('proxyEnabled');
        proxyEnabledControl.valueChanges
            .pipe(startWith(proxyEnabledControl.value as boolean), takeUntil(this.destroyed$))
            .subscribe((value: boolean) => {
                this.checkProxyControlStatus(value);
            });
    }

    private checkProxyControlStatus(value: boolean) {
        const proxyControl = this.authExtensionForm.get('proxy');
        const proxyControlValidators = [Validators.required, this.defaultUrlValidator];

        if (value) {
            proxyControl.enable();
            proxyControl.addValidators(proxyControlValidators);

            if (!this.editForm) {
                proxyControl.patchValue(BASE_PROXY_URL);
            }
        } else {
            proxyControl.disable();
            proxyControl.removeValidators(proxyControlValidators);
            proxyControl.patchValue('');
        }

        proxyControl.updateValueAndValidity();
    }
}

function headerNameUniqueValidator(formGroup: FormGroup) {
    ArrayByFieldDuplication(formGroup, 'headers', 'header', DUPLICATE_HEADER_ERROR);
}

function normalizeToolType(value: string) {
    return value?.endsWith(POSTFIX_PROXY) ? value.substring(0, value.lastIndexOf(POSTFIX_PROXY)) : value;
}
