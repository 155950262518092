import { Component } from '@angular/core';
import { ControlContainer, FormArray, FormGroupDirective } from '@angular/forms';
import { WidgetAccordionColumn } from '../../../models/any-widget.model';
import { WidgetBuilderFacade } from '../../../state/widget-builder.facade';

@Component({
    selector: 'dp-widget-builder-accordion-columns',
    templateUrl: './widget-builder-accordion-columns.component.html',
    styleUrls: ['./widget-builder-accordion-columns.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class WidgetBuilderAccordionColumnsComponent {
    get accordionColumns(): FormArray {
        return this.parent.control.get('accordionColumns') as FormArray;
    }

    constructor(public parent: FormGroupDirective, public facade: WidgetBuilderFacade) {}

    addColumn(): void {
        this.accordionColumns.push(this.facade.buildAccordionColumnGroup({} as WidgetAccordionColumn));
    }

    removeColumn(index: number): void {
        this.accordionColumns.removeAt(index);
    }
}
