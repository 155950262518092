import { Component, EventEmitter, Input, Output, inject, HostBinding, ElementRef } from '@angular/core';

interface Radial {
    title: string;
    value: string;
    additionalInfo?: [{ label: string; value: string }];
}
export interface MultipleRadialsOptions {
    radials: Radial[];
}

@Component({
    selector: 'dp-multiple-radials',
    templateUrl: './multiple-radials.component.html',
    styleUrls: ['./multiple-radials.component.scss'],
})
export class MultipleRadialsComponent {
    @Input() options: MultipleRadialsOptions;
    @Input() hasDrilldown: boolean;

    @Output() radialClicked = new EventEmitter<Radial>();
    @Output() linkClicked = new EventEmitter();

    size: 'lg' | 'md' | 'sm' = null;

    constructor() {
        this.handleResize(inject(ElementRef).nativeElement);
    }

    handleResize({ offsetHeight }: HTMLElement) {
        if (offsetHeight > 250) {
            this.size = 'lg';
        } else if (offsetHeight > 150) {
            this.size = 'md';
        } else {
            this.size = 'sm';
        }
    }

    handleDrilldownEvent(radial: Radial) {
        this.radialClicked.emit(radial);
    }
}
