import {Injectable, Optional} from '@angular/core';

import {AnyWidgetModel, ExecutionResult, Placeholders, WidgetType} from 'data-processor';

import {DashboardMessageBus} from './message-bus';
import {AnyWidgetStore} from '../../components/widget/any-widget/any-widget.store';
import {WidgetLogger, WidgetScriptExecutor} from '../widget-script.executor';
import {parseError} from '../widget-builder.util';
import {BehaviorSubject} from 'rxjs';
import {cloneDeep} from 'lodash';

export const WIDGET_EVENTS_MAP: Partial<{ [key in WidgetType]: string[] }> = {
    complex: ['Link Clicked'],
    piWorkDistribution: ['Link Clicked'],
    accordionWithTabs: ['Link Clicked'],
    piMultipleRadials: ['Link Clicked'],
    piGantt: ['Link Clicked'],
    piFeatureCompletionStatus: ['Link Clicked'],
    factorScores: ['Link Clicked'],
};

@Injectable()
export class WidgetEventManager {
    constructor(
        @Optional() private readonly messageBus: DashboardMessageBus,
        private store: AnyWidgetStore,
        private widgetScriptExecutor: WidgetScriptExecutor,
        private logger: WidgetLogger
    ) {}

    process(event: WidgetEvent, placeholders: Placeholders, widget: AnyWidgetModel, chart?: any) {
        if (!this.messageBus) {
            return;
        }

        const handler = this.findHandler(event, widget);
        const newPlaceholders = cloneDeep(placeholders);

        if (!handler) {
            return;
        }

        this.buildEventId(event);

        try {
            handler(this.messageBus, newPlaceholders, event, document, chart);

            if (!this.messageBus.eventDependencies[event.id]) {
                this.messageBus.eventDependencies[event.id] = new BehaviorSubject({ placeholders: newPlaceholders });
                return;
            }

            this.messageBus.eventDependencies[event.id].next({ placeholders: newPlaceholders });
        } catch (e) {
            this.logger.log(parseError(e));
        }
    }

    private findHandler(event: WidgetEvent, widget: AnyWidgetModel): Function {
        const eventHandler = (widget.handlers || []).find(handler => handler.type === 'OUT' && handler.eventId === event.id);

        if (!eventHandler) {
            return null;
        }

        return this.buildScript(eventHandler.script);
    }

    private buildScript(script: string): Function {
        return this.widgetScriptExecutor.buildFn(`return (function(messageBus, placeholders, event, document) {${script}})`);
    }

    private buildEventId(event: WidgetEvent) {
        event.id = `${this.store.value.widget.chartOptions.title}.${event.id}`;
    }
}

export const WIDGET_CLICKED_EVENT_ID = 'widget_clicked';

export abstract class WidgetEvent {
    readonly timestamp = Date.now();
    id: string;

    abstract value: any;
}

export class WidgetFormEvent extends WidgetEvent {
    constructor(public readonly value: any, placeholder: string) {
        super();

        this.id = `${placeholder}.change`;
    }
}

export class WidgetDrilldownEvent extends WidgetEvent {
    constructor(public readonly value: any, placeholder: string) {
        super();

        this.id = placeholder === null ? 'drilldown' : `${placeholder}.drilldown`;
    }
}

export class WidgetDomEvent extends WidgetEvent {
    constructor(public readonly value: any, placeholder: string) {
        super();

        this.id = `dom.${placeholder}`;
    }
}

export class WidgetLoadedEvent extends WidgetEvent {
    id = 'loaded';

    constructor(public readonly value: ExecutionResult) {
        super();
    }
}

export class WidgetLinkEvent extends WidgetEvent {
    id = 'Link Clicked';

    constructor(public readonly value: ExecutionResult) {
        super();
    }
}

export interface EChartEvent {
    componentType: string;
    seriesType: string;
    seriesIndex: number;
    seriesName: string;
    name: string;
    dataIndex: number;
    data: Record<string, any>;
    dataType: string;
    value: number | any[];
    color: string;
    info: Record<string, any>;
}
