import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropdownItem, validateFormAndDisplayErrors } from '@dagility-ui/kit';
import { QueryBuilderStore } from '@app/shared/components/query-builder/store/query-builder.store';
import { DisplayType } from 'data-processor';
import { IDrilldown } from '@app/shared/components/query-builder/models/model-graph-actions.model';
import { ModelGraphActionsService } from '@app/shared/components/query-builder/services/model-graph-actions.service';

@Component({
    selector: 'app-qb-model-drilldown-editor',
    templateUrl: './drilldown-editor.component.html',
    styleUrls: ['./drilldown-editor.component.scss'],
})
export class DrilldownEditorComponent implements OnInit {
    canDelete = false;
    form: FormGroup;
    @Input() queryBlockReplacement: TemplateRef<any>;
    @Input() data: IDrilldown;

    @Output() openedQueryBlock: EventEmitter<boolean> = new EventEmitter();
    @Output() cancel = new EventEmitter();
    @Output() save = new EventEmitter();
    @Output() delete = new EventEmitter();
    @Output() preview = new EventEmitter();

    placeholders: DropdownItem[] = [];

    seenTabs: Record<string, any> = {};

    displayType: DropdownItem<DisplayType>[] = [
        {
            value: DisplayType.MODAL,
            label: 'Modal',
        },
        {
            value: DisplayType.STACK,
            label: 'Stack',
        },
    ];

    constructor(public store: QueryBuilderStore, private fb: FormBuilder, private modelGraphActionsService: ModelGraphActionsService) {}

    buildDrilldownForm({ displayType, default: isDefault, drillDownName, drillDownParamName }: IDrilldown): FormGroup {
        return this.fb.group({
            displayType: [displayType, Validators.required],
            default: [!!isDefault],
            drillDownName: [drillDownName],
            drillDownParamName: [drillDownParamName],
        });
    }

    handleSave(): void {
        if (this.form.valid) {
            this.save.emit({ value: this.form.value, type: this.data === undefined ? 'ADD' : 'EDIT' });
        } else {
            validateFormAndDisplayErrors(this.form);
        }
    }

    handleDelete(): void {
        this.delete.emit();
    }

    handleCancel() {
        this.cancel.emit();
    }

    ngOnInit() {
        this.modelGraphActionsService
            .defaultDrilldown({ sourceGraph: this.store.globalModelGraph, drillDownId: this.store.drillDownId })
            .subscribe(result => {
                this.form = this.buildDrilldownForm(
                    this.data === undefined
                        ? {
                              displayType: 'stack',
                              default: false,
                              drillDownName: result.defaultName,
                              drillDownParamName: result.defaultParameter,
                          }
                        : this.data
                );
            });
    }
}
