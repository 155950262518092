import { Component, Input } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';

import { Tool } from '../../models/processor-monitoring/plugin.model';

@Component({
    selector: 'dp-processors',
    templateUrl: './processors.component.html',
    styleUrls: ['./processors.component.scss'],
})
export class ProcessorsComponent {
    @Input() tools: Tool[] = [];
    toolsMap: Record<string, any> = {};

    processorTreeControl = new FlatTreeControl<Tool>(
        _ => 1,
        _ => false
    );

    trackByFn(index: number, item: Tool) {
        return item.toolId;
    }

    ngOnChanges() {
        this.toolsMap = this.tools.reduce((map: Record<string, any>, tool) => {
            map[tool.toolId] = tool;
            return map;
        }, {});
    }
}
