import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy } from '@angular/core';
import { GridsterComponent } from 'angular2gridster';
import { concat, merge, of, Subject } from 'rxjs';
import { delay, mapTo, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'dp-drag-and-drop-area',
    templateUrl: './drag-and-drop-area.component.html',
    styleUrls: ['./drag-and-drop-area.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DragAndDropAreaComponent implements OnDestroy {
    private destroy$ = new Subject<void>();

    constructor(private gridster: GridsterComponent, { nativeElement }: ElementRef<HTMLElement>) {
        merge(
            this.gridster.prototypeEnter.pipe(
                mapTo(true),
            ),
            merge(this.gridster.prototypeOut, this.gridster.prototypeDrop).pipe(mapTo(false))
        )
            .pipe(takeUntil(this.destroy$))
            .subscribe(isDragOver => {
                nativeElement.classList.toggle('drag--over', isDragOver);
            });

        this.gridster.prototypeDrop
            .pipe(
                switchMap(() => concat(of(true), of(false).pipe(delay(150)))),
                takeUntil(this.destroy$)
            )
            .subscribe(isDropped => {
                nativeElement.classList.toggle('drag--dropped', isDropped);
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
    }
}
