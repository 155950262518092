import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ControlContainer, FormArray, FormGroup, FormGroupDirective } from '@angular/forms';

import { WidgetBuilderFacade } from '../../../state/widget-builder.facade';

@Component({
    selector: 'dp-widget-builder-tile-form',
    templateUrl: './widget-builder-tile-form.component.html',
    styleUrls: ['./widget-builder-tile-form.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetBuilderTileFormComponent {
    get tiles(): FormArray {
        return this.parent.control.get('tiles') as FormArray;
    }

    constructor(private parent: FormGroupDirective, private facade: WidgetBuilderFacade) {}

    addTile() {
        this.tiles.push(this.facade.buildTileItemForm({} as any));
    }

    removeTile(index: number) {
        this.tiles.removeAt(index);
    }

    addRating(tileControl: FormGroup) {
        (tileControl.get('ratings') as FormArray).push(this.facade.buildTileWidgetRatingForm({}));
    }

    removeRating(index: number, tileControl: FormGroup) {
        (tileControl.get('ratings') as FormArray).removeAt(index);
    }
}
