import { animate, state, style, transition, trigger } from '@angular/animations';

export const easeInOutBackBezier = 'ease-in-out';

export const animations = {
    slideInOut: trigger('slideInOut', [
        transition(':enter', [
            style({ transform: 'translateX(100%)' }),
            animate(`250ms ${easeInOutBackBezier}`, style({ transform: 'translateX(0)' })),
        ]),
        transition(':leave', [animate(`250ms ${easeInOutBackBezier}`, style({ transform: 'translateX(100%)' }))]),
    ]),
    filtersPanelOpen: trigger('filtersPanelOpen', [
        state('false', style({ 'margin-right': '0' })),
        state('true', style({ 'margin-right': '{{distance}}' }), { params: { distance: 0 } }),
        transition('false <=> true', [animate(`250ms ${easeInOutBackBezier}`)]),
    ]),
};
