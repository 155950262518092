<ng-container *ngIf="layout$ | async as layout">
    <div class="d-flex flex-1 flex-column px-0" style="min-width: 0;">
        <dp-job-definition-builder-view
            class="flex-1"
            [class.publish]="publish"
            [debugMode]="debug"
            [debuggedBlockName]="debuggedBlockName"
            [layout]="layout"
            (dropped)="onDrop($event)"
            (delete)="onDelete($event)"
            (breakpointToggle)="onBreakpointToggle($event)"
            (editAction)="onEditAction($event)"
        ></dp-job-definition-builder-view>
        <lib-stream-wrapper
            *ngIf="facade.debugContainer$"
            [dataStream$]="facade.debugContainer$"
            errorCssClass="w-100"
            class="d-flex debug-container border-left-0 border-right-0"
            [class.expanded]="!!debugContext.activeTab || loading"
        >
            <ng-template contentTemplate>
                <dp-job-definition-builder-debug [debugContext]="debugContext" class="flex-1"></dp-job-definition-builder-debug>
            </ng-template>
        </lib-stream-wrapper>
    </div>
    <dp-job-definition-actionsbox
        class="actions-box--panel"
        [collapsed]="panelCollapsed"
        [dragDisabled]="debug"
        [class.actions-box--panel--expanded]="!panelCollapsed"
        (dropped)="onDrop($event)"
    ></dp-job-definition-actionsbox>
</ng-container>
