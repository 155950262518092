<div
    class="any-widget-filter-item px-3"
    [class.any-widget-filter-item--expanded]="isExpanded"
    [class.d-none]="(hasOptions$ | async) === false"
    [class.non-clickable]="filter.type === widgetFilterType.CHECKBOX"
    data-test-id="filter-label"
    (click)="toggle()"
>
    <fa-icon [icon]="isExpanded ? 'chevron-left' : 'chevron-right'" class="arrow-icon color-brand mr-2"></fa-icon>
    <span class="h3 m-0 any-widget-filter-name mr-2">{{ filter.label || filter.type | titlecase }}</span>
    <dp-any-widget-filter-item-value
        *ngIf="filter.type !== widgetFilterType.CHECKBOX"
        class="any-widget-filter-value color-brand body2 flex-1"
        [value]="value$ | async"
    ></dp-any-widget-filter-item-value>
    <trigger class="ml-auto" *ngIf="filter.type === widgetFilterType.CHECKBOX" [formControl]="form.get(filter.placeholder)"></trigger>
</div>
<div *ngIf="isExpanded" [formGroup]="form" class="expanded-filter">
    <ng-container [dpChartFilter]="filter" [filterComponentFactory]="expandedFilterFactory"></ng-container>
</div>
