import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CiCdTemplateService, PublishItem, TemplateSourceFilesPublishDto } from '../../../../../api/ci-cd-template.service';
import { PublishComponentChangesService } from './publish-component-changes-progress/publish-component-changes.service';

@Component({
    selector: 'app-publish-component-changes-dialog',
    templateUrl: './publish-component-changes-dialog.component.html',
    styleUrls: ['./publish-component-changes-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublishComponentChangesDialogComponent implements OnInit, OnDestroy {
    publishItems$: Observable<PublishItem[]>;

    selection = new SelectionModel(true);

    componentIds: number[] = [];

    selectedProjects = new Set();

    selectedComponentsPerProject: Record<number, string[]> = {};

    step: 'selection' | 'commit' = 'selection';

    templateId: string;

    componentId: string;

    items = [
        {
            label: 'PACE Projects',
            value: false,
        },
        {
            label: 'SCM Repo',
            value: true,
        },
    ];

    form = new FormGroup({
        pushToRepo: new FormControl(false, Validators.required),
        commit: new FormControl(''),
    });

    publishing$ = new BehaviorSubject(false);

    private componentToProjectMap = new Map();

    private componentIdToComponentName = new Map();

    private readonly destroyed$ = new Subject();

    constructor(
        public activeModal: NgbActiveModal,
        private toastr: ToastrService,
        private ciCdTemplateService: CiCdTemplateService,
        private publishComponentChangesService: PublishComponentChangesService
    ) {}

    ngOnInit() {
        this.publishItems$ = this.ciCdTemplateService.getComponentsByProjectForPublish(this.templateId, this.componentId).pipe(
            tap(publishItems => {
                this.componentIds = publishItems.reduce((acc, cur) => [...acc, ...cur.items.map(item => item.value)], []);

                publishItems.forEach(project => {
                    project.items.forEach(component => {
                        this.componentToProjectMap.set(component.value, project);
                        this.componentIdToComponentName.set(component.value, component.label);
                    });
                });
            })
        );
        this.selection.changed
            .pipe(
                tap(value => {
                    this.selectedProjects.clear();
                    this.selectedComponentsPerProject = {};
                    value.source.selected.forEach((componentId: number) => {
                        const project = this.componentToProjectMap.get(componentId);
                        this.selectedProjects.add(project);
                        this.selectedComponentsPerProject[project.value] = [
                            ...(this.selectedComponentsPerProject[project.value] || []),
                            this.componentIdToComponentName.get(componentId),
                        ];
                    });
                }),
                takeUntil(this.destroyed$)
            )
            .subscribe();
        this.form
            .get('pushToRepo')
            .valueChanges.pipe(
                tap(value => {
                    const commitCtrl = this.form.get('commit');
                    if (value) {
                        commitCtrl.addValidators(Validators.required);
                    } else {
                        commitCtrl.removeValidators(Validators.required);
                    }
                    commitCtrl.updateValueAndValidity();
                }),
                takeUntil(this.destroyed$)
            )
            .subscribe();
    }

    toggleAll(checked: boolean) {
        if (checked) {
            this.selection.select(...this.componentIds);
        } else {
            this.selection.clear();
        }
    }

    toggleProjectSelection(project: PublishItem, checked: boolean) {
        const projectComponentIds = project.items.map(item => item.value);
        if (checked) {
            this.selection.select(...projectComponentIds);
        } else {
            this.selection.deselect(...projectComponentIds);
        }
    }

    getCountOfProjectComponentsSelected(project: PublishItem) {
        return project.items.filter(item => this.selection.isSelected(item.value)).length;
    }

    publish() {
        this.publishing$.next(false);

        const publishDto: TemplateSourceFilesPublishDto = {
            pushToRepo: this.form.value.pushToRepo,
            commit: this.form.value.pushToRepo ? this.form.value.commit : null,
            componentIds: this.selection.selected as number[],
        };
        if (!publishDto.pushToRepo) {
            this.publishing$.next(true);
            this.ciCdTemplateService
                .publishSourceFiles(this.templateId, this.componentId, publishDto)
                .pipe(
                    tap(() => {
                        this.toastr.success('Published the changes to projects', 'Success');
                    }),
                    finalize(() => {
                        this.publishing$.next(false);
                        this.activeModal.close();
                    })
                )
                .subscribe();
            return;
        }

        this.activeModal.close();
        const allComponentsToPublish = publishDto.componentIds.map(componentId => ({
            id: componentId,
            name: this.componentIdToComponentName.get(componentId),
            projectName: this.componentToProjectMap.get(componentId).label,
        }));
        this.publishComponentChangesService.publish(this.templateId, this.componentId, publishDto, allComponentsToPublish);
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }
}
