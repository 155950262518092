import {MultiLevelFilterRelationshipType} from "../filters/multi-level-filter-model";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core"

export type RelationshipLegendIconType = 'line' | 'square' | IconDefinition;

export const relationshipLegendDefaultIcon: {
    square: RelationshipLegendIconType,
    line: RelationshipLegendIconType
} = {
    square: "square",
    line: "line"
};

export interface RelationshipLegendItem {
    label: string;
    relationshipType: MultiLevelFilterRelationshipType;
    icon?: RelationshipLegendIconType;
}
