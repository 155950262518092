import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    inject,
    Inject,
    Injector,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, map, startWith, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IfInViewportService, ModalService, ResizeObserverService } from '@dagility-ui/kit';
import { DomPortal, Portal } from '@angular/cdk/portal';
import { LogModalComponent } from '@dagility-ui/shared-components';
import {
    DATA_MORPH_FEATURE,
    DATA_MORPH_FEATURE_TOGGLE,
    DataMorphFeatureToggleService,
    SERVICE_HEALTH_STATUS,
    HealthServiceStatus,
    DATA_MORPH_AUTH,
} from 'data-processor/tokens';
import { IlluminateDashboardWidgetExtension } from 'data-processor/lib/widget-library/dashboard/services/dashboard-widget-settings.manager';

import {
    LOAD_EXTENSIONS_FN,
    LoadExtensionsFn,
    WIDGET_EXTENSIONS_INNER,
    WidgetExtensionInner,
    WidgetExtensionInnerViewContext,
} from 'data-processor/lib/extensions';
import { TreeLikeMenuGroup, TreelikeMenuItem } from '@dagility-ui/kit/modules/forms/controls/treelike-menu/treelike-menu.component';
import { anyWidgetProviders } from '../../../providers/any-widget.providers';
import { AnyWidgetStore } from '../any-widget/any-widget.store';
import { AnyWidgetModel, WidgetType } from '../../../models/any-widget.model';
import { WidgetDebuggerState } from '../../../services/widget.debugger';
import { AnyWidgetComponent } from '../../../components/widget/any-widget/any-widget.component';
import { DEFAULT_PLACEHOLDERS, DefaultPlaceholders } from '../../../providers/default-placeholders.provider';
import { EXPORT_DATA_TYPE, WidgetExporterFactory, WIDGET_EXPORTER } from '../../../services/exporter/widget.exporter';
import { WidgetLogger } from '../../../services/widget-script.executor';
import { WidgetWorkflow, WidgetWorkflowContainer } from '../../../services/widget.flow';
import { ExecutionResult } from '../../../services/widget-builder.service';
import { WidgetDOMEventsService } from '../../../services/message-bus/widget-dom-events.service';
import { moveLineFieldsToOldFields } from '../../../services/widget.lines';

@Component({
    selector: 'dp-any-widget-wrapper',
    templateUrl: './any-widget-wrapper.component.html',
    styleUrls: ['./any-widget-wrapper.component.scss'],
    providers: [
        ...anyWidgetProviders,
        {
            provide: WidgetWorkflowContainer,
            useExisting: AnyWidgetWrapperComponent,
        },
        {
            provide: 'EXPORTED_DATA_OPTIONS',
            useValue: {
                filter: (items: unknown[]) => items,
            },
        },
        ResizeObserverService,
    ],
})
export class AnyWidgetWrapperComponent extends WidgetWorkflowContainer implements OnInit, OnChanges, OnDestroy {
    @Input() options: AnyWidgetModel;
    @Input() placeholders: Record<string, any> = {};
    @Input() actionsTemplate: TemplateRef<any>;
    @Input() externalOptions: Record<string, any> = {};
    @Input() @HostBinding('class.separated') separatedView = false;

    @Input() set mock(value: boolean) {
        this.store.mock = value;
    }

    @Input() widgetId: number;
    @Input() drilldownLevel: number[][] = [];
    @Input() externalWidgetId: number;
    @Input() extensionsEnabled = false;
    @Input() eventDependencies: Record<string, Subject<any>>;
    @Input() externalState: ExecutionResult;
    @Input() showHelp = false;
    @Input() previewMode = false;
    @Input() showingOnlyFiltersBlock: boolean;
    @Input() set edit(value: boolean) {
        this._edit = value;
        this.editMode$.next(value);
    }
    get edit() {
        return this._edit;
    }
    private _edit = false;
    editMode$ = new BehaviorSubject(this._edit);
    @Output() storeAfterLoad = new EventEmitter();
    @Output() jsonClicked = new EventEmitter();
    @Output() deleteClicked = new EventEmitter();
    @Output() editClicked = new EventEmitter();

    private notificationServiceActive: boolean = false;
    @ViewChild('widget') dpAnyWidget: AnyWidgetComponent;
    @ViewChild('widgetTitle', { static: false }) widgetTitle: ElementRef;

    @ViewChild('filterTemplate', { static: true }) filterTemplate: TemplateRef<any>;

    filtersPortal: Portal<any>;

    get title$(): Observable<string> {
        return this.store.title$;
    }

    get drilldown$(): Observable<void> {
        return this.store.drilldown$.asObservable();
    }

    get description$(): Observable<string> {
        return this.store.description$;
    }

    get extensions() {
        if (this.notificationServiceActive) return this._extensions.filter(ex => ex.view);
        else return this._extensions.filter(ex => ex.extensionId !== 'THRESHOLD_SETTINGS');
    }

    vm$ = this.store.asObservable().pipe(
        tap(() => {
            setTimeout(() => this.cdr.detectChanges());
        })
    );

    @HostBinding('class.widget-modal')
    modal = false;

    triggerAll = false;
    destroyed$ = new Subject<void>();
    activeExtension: WidgetExtensionInner;
    extensionsMap: Record<string, IlluminateDashboardWidgetExtension> = {};
    closeButton = this.modalService.hasOpenModals();
    showContextHelp = true;
    vocEnabled$: Observable<boolean> = this.featureToggle.isActive('VoiceOfCustomer');
    hideDescription$: Observable<boolean> = this.featureToggle.isActive(DATA_MORPH_FEATURE.HIDE_DESCRIPTION).pipe(startWith(false));
    widgetMenuItems: TreeLikeMenuGroup[];

    isSmallWidget: boolean;
    isSmallWidgetWithoutFilter: boolean;
    treeMenuItems$: Observable<TreeLikeMenuGroup[]> = getMenuItems(this);

    constructor(
        public store: AnyWidgetStore,
        public logger: WidgetLogger,
        private cdr: ChangeDetectorRef,
        public ngZone: NgZone,
        @Optional() public debuggerState: WidgetDebuggerState,
        private modalService: ModalService,
        public workflow: WidgetWorkflow,
        private ifInViewPort: IfInViewportService,
        private elementRef: ElementRef<HTMLElement>,
        @Inject(DEFAULT_PLACEHOLDERS) private defaultPlaceholders: DefaultPlaceholders,
        public activeModal: NgbActiveModal,
        // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
        @Inject(WIDGET_EXTENSIONS_INNER) private _extensions: WidgetExtensionInner[],
        @Inject(DATA_MORPH_FEATURE_TOGGLE) public featureToggle: DataMorphFeatureToggleService,
        @Inject(SERVICE_HEALTH_STATUS) private notificationHealthService: HealthServiceStatus,
        @Inject('EXPORTED_DATA_OPTIONS') private exportedDataOptions: { filter: (items: unknown[]) => unknown[] },
        @Inject(LOAD_EXTENSIONS_FN) private loadExtensionsFn: LoadExtensionsFn,
        private widgetDomEvents: WidgetDOMEventsService,
        public elRef: ElementRef
    ) {
        super();
    }

    ngOnInit(): void {
        this.closeButton = this.modalService.hasOpenModals();
        this.store.debuggerState = this.debuggerState;
        moveLineFieldsToOldFields(this.options);

        if (this.options.server) {
            this.workflow.id = this.widgetId;
            this.workflow.setDrilldownLevel(this.drilldownLevel);
            this.store.debuggerState = this.debuggerState || new WidgetDebuggerState();
            this.store.workflow = this.workflow;
            this.workflow.widgetDebugger = this.store.debuggerState;
            this.workflow.externalState = this.externalState;

            this.store.workflow.breakpoints = new Set(this.breakpoints);
            this.store.workflow
                .init(this.options, this.externalOptions, {}, this.eventDependencies)
                .pipe(takeUntil(this.destroyed$))
                .subscribe();

            if (this.storeAfterLoad.observers.length) {
                this.store.workflow.loaded$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
                    this.storeAfterLoad.emit(this.store);
                });
            }

            if (!this.previewMode) {
                this.widgetDomEvents
                    .observe()
                    .drilldown$.pipe(takeUntil(this.destroyed$))
                    .subscribe((e: Record<string, any>) => {
                        this.handleDrilldown(e);
                    });
            }
        } else {
            this.workflow = null;
        }

        this.store.externalOptions = this.externalOptions;
        this.store.initRoot(this.options, this.placeholders);

        this.modal = !!this.placeholders;

        if (this.loadExtensionsFn) {
            this.loadExtensionsFn(this.externalWidgetId)
                .pipe(withLatestFrom(this.vocEnabled$), takeUntil(this.destroyed$))
                .subscribe(([extensions, voc]) => {
                    const initialState: any = {};
                    if (voc) {
                        initialState['VOC_SETTINGS'] = {};
                    }
                    initialState['THRESHOLD_SETTINGS'] = {};
                    this.extensionsMap = (extensions || []).reduce<Record<string, any>>((acc, ex) => {
                        acc[ex.extension.id] = ex;

                        return acc;
                    }, initialState);
                });
        }
        this.notificationHealthService.getIsServiceAlive('notification').subscribe(st => {
            this.notificationServiceActive = st;
        });
    }

    extensionContext(extension: WidgetExtensionInner): WidgetExtensionInnerViewContext {
        return {
            widgetId: this.widgetId,
            dashboardWidgetId: this.externalWidgetId,
            extension: this.extensionsMap[extension.extensionId],
            options: this.options,
            onClickOutside: () => {
                this.activeExtension = null;
                this.store.extensionOpened = false;
                this.elementRef.nativeElement.classList.remove('extension-opened');
                this.cdr.detectChanges();
            },
        };
    }

    doExport(format: EXPORT_DATA_TYPE) {
        this.dpAnyWidget.handleExportData(format, false, this.widgetTitle, this.exportedDataOptions.filter);
    }

    openModal(inputs: Record<string, any>, defaultPlaceholders = this.defaultPlaceholders) {
        this.modalService.open(
            AnyWidgetWrapperComponent,
            {
                centered: true,
                size: 'xl',
                windowClass: 'any-widget-modal',
                backdrop: 'static',
                injector: Injector.create({
                    providers: [
                        {
                            provide: DEFAULT_PLACEHOLDERS,
                            useValue: defaultPlaceholders,
                        },
                    ],
                }),
            },
            inputs
        );
    }

    handleDrilldown(event: any) {
        this.ngZone.run(() => {
            this.store.workflow.drilldown(AnyWidgetWrapperComponent, event);
        });
    }

    initWidgetLoading(format: string, pdf: any = null, newExport: boolean = false) {
        return this.dpAnyWidget.handleInitLoading(format as any, pdf, newExport);
    }

    isRoot(): boolean {
        return this.store.isRoot();
    }

    back(): void {
        if (this.store.workflow) {
            this.store.workflow.back();

            return;
        }

        if (this.debuggerState) {
            this.debuggerState.drilldown$.next({ event: null, id: this.options.id, type: 'back' });
        }

        this.store.back();
    }

    handleShowErrorLogs(item: TreelikeMenuItem, event: MouseEvent): void {
        event.stopPropagation();
        this.modalService.open(
            LogModalComponent,
            {
                centered: true,
                backdrop: 'static',
                size: 'lg',
                windowClass: 'log-modal',
            },
            {
                logs$: this.logger.getLogs().pipe(
                    map(logs => ({
                        fullLog: logs.join('\r\n'),
                    }))
                ),
                title: 'Error Log',
            }
        );
    }

    handleOpenContextHelp(item: TreelikeMenuItem, event: MouseEvent) {
        event.stopPropagation();
        if (this.modalService.hasOpenModals()) {
            this.showHelp = !this.showHelp;
        } else if (this.options.server) {
            this.store.workflow.preview(AnyWidgetWrapperComponent);
        } else {
            this.openModal(
                {
                    externalOptions: this.store.externalOptions,
                    options: this.store.value.widget,
                    separatedView: true,
                    showHelp: true,
                    previewMode: true,
                },
                () => of(this.dpAnyWidget.ph)
            );
        }
    }

    parseLoadingStr(str: string) {
        return str.includes('{') ? 'Loading' : str;
    }

    handleOpenExtension(extension: WidgetExtensionInner) {
        this.store.extensionOpened = true;
        this.elementRef.nativeElement.classList.add('extension-opened');
        this.activeExtension = extension;
    }

    reload() {
        this.workflow.reload(this.options);
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }

    forceReload() {
        this.store.workflow.updatePlaceholdersAndReload([]);
    }

    initFiltersPortal($event: HTMLElement) {
        if (!this.filtersPortal) {
            this.filtersPortal = new DomPortal($event);
            this.cdr.detectChanges();
        }
    }
}

export function getMenuItems(component: AnyWidgetWrapperComponent): Observable<TreeLikeMenuGroup[]> {
    const anyWidgetStore = inject(AnyWidgetStore);
    const widgetExporter = inject(WIDGET_EXPORTER);
    const serviceHealthStatus = inject(SERVICE_HEALTH_STATUS);
    const auth = inject(DATA_MORPH_AUTH);
    const resizeObserverService = inject(ResizeObserverService);

    return combineLatest([
        resizeObserverService.observe$(component.elRef, 100).pipe(
            map(() => ({
                isSmallWidget: anyWidgetStore.state.widget?.filters?.length && component.elRef.nativeElement.clientWidth <= 400,
                isSmallWidgetWithoutFilter: component.elRef.nativeElement.clientWidth <= 400,
            })),
            distinctUntilChanged(),
            tap(({ isSmallWidget, isSmallWidgetWithoutFilter }) => {
                component.ngZone.run(() => {
                    component.isSmallWidgetWithoutFilter = isSmallWidgetWithoutFilter;
                    component.isSmallWidget = isSmallWidget;
                    if (component.workflow) {
                        component.workflow.isSmallWidget = isSmallWidget;
                        return isSmallWidget;
                    }
                    return isSmallWidget;
                });
            })
        ),
        component.logger.empty$,
        serviceHealthStatus.getIsServiceAlive('notification'),
        component.editMode$,
        anyWidgetStore.pipe(map(store => store.widget.help)),
        anyWidgetStore.pipe(map(store => getSupportedExportFormats(store.widget.widgets, widgetExporter, store.widget.type))),
    ]).pipe(
        map(([filter, emptyErrorLog, alert, editMode, help, exportDataTypes]) => {
            let treeItems: TreeLikeMenuGroup[] = [];

            treeItems = [
                {
                    label: '',
                    items: [
                        {
                            label: 'Filter',
                            icon: 'facFilter',
                            actionHandler: (item, event) => {
                                event.stopPropagation();
                                component.dpAnyWidget?.filterWrapperComp?.openFilters();
                            },
                            hidden: !filter.isSmallWidget,
                        },
                        {
                            label: 'Error Log',
                            icon: 'facLogs',
                            actionHandler: (item, event) => {
                                component.handleShowErrorLogs(item, event);
                            },
                            hidden: emptyErrorLog,
                        },
                        {
                            label: 'Set Alerts',
                            icon: 'customNotification',
                            actionHandler: () => {
                                component.handleOpenExtension(
                                    component.extensions.filter(extension => extension.extensionId === 'THRESHOLD_SETTINGS').pop()
                                );
                            },
                            hidden: !alert || component.closeButton,
                        },
                    ],
                },
                {
                    label: 'Manage Widget',
                    items: [
                        {
                            label: 'Export',
                            children: [
                                {
                                    label: 'Export',
                                    items: [
                                        {
                                            label: 'JSON',
                                            actionHandler: () => {
                                                component.jsonClicked.emit();
                                            },
                                            hidden: component.closeButton,
                                        },
                                        {
                                            label: 'PDF',
                                            actionHandler: () => {
                                                component.doExport('PDF');
                                            },
                                            hidden: !exportDataTypes.includes('PDF'),
                                        },
                                        {
                                            label: 'CSV',
                                            actionHandler: () => {
                                                component.doExport('CSV');
                                            },
                                            hidden: !exportDataTypes.includes('CSV'),
                                        },
                                        {
                                            label: 'XLSX',
                                            actionHandler: () => {
                                                component.doExport('XLSX');
                                            },
                                            hidden: !exportDataTypes.includes('XLSX'),
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            label: 'Edit',
                            icon: 'facEdit',
                            actionHandler: () => {
                                component.editClicked.emit();
                            },
                            hidden: component.options.system || !auth.isAdmin() || !editMode,
                        },
                        {
                            label: 'Delete',
                            icon: 'facTrash',
                            actionHandler: () => {
                                component.deleteClicked.emit();
                            },
                            hidden: !editMode,
                        },
                    ],
                },
                {
                    label: '',
                    items: [
                        {
                            label: 'Help',
                            icon: 'QuestionInCircle',
                            actionHandler: (item, event) => {
                                component.handleOpenContextHelp(item, event);
                            },
                            hidden: !help,
                        },
                    ],
                },
            ];

            treeItems.map(option => {
                if (option.items.some(item => item.label === 'Export')) {
                    const exportItem = option.items.find(item => item.label === 'Export');
                    const visibleChildItems = exportItem.children[0].items.filter(subItem => !subItem.hidden);

                    if (visibleChildItems.length > 0) {
                        exportItem.children[0].items = visibleChildItems;
                        option.label = 'Manage Widget';
                    } else {
                        option.items = option.items.filter(item => item.label !== 'Export');
                        option.label = '';
                    }
                }
                return option;
            });

            return [...treeItems].filter(group => group.items.filter(item => !item.hidden).length > 0);
        })
    );
}

export function getSupportedExportFormats(
    complexWidgetTypes: AnyWidgetModel[],
    widgetExporterFactory: WidgetExporterFactory,
    type: WidgetType
): EXPORT_DATA_TYPE[] {
    const complexWidgeTypetList = (complexWidgetTypes || []).map(widget => widget.type) as WidgetType[];

    if (!complexWidgeTypetList?.length) {
        return Object.keys(widgetExporterFactory[type] ?? {}) as EXPORT_DATA_TYPE[];
    }

    const exportDataTypes: EXPORT_DATA_TYPE[] = ['CSV', 'PDF', 'XLSX'];
    const supportedFormats: EXPORT_DATA_TYPE[] = [];

    exportDataTypes.forEach(exportDataType => {
        if (
            complexWidgeTypetList.some((type: WidgetType) => !widgetExporterFactory[type] || !widgetExporterFactory[type][exportDataType])
        ) {
            return;
        }

        supportedFormats.push(exportDataType);
    });

    return supportedFormats;
}
