<div class="modal-header align-items-center border-0">
    <h4 class="modal-title">Column {{ columnName }}</h4>
    <button class="close" (click)="handleCancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" [formGroup]="columnControl">
    <perfect-scrollbar>
        <div class="edit-columns-form pr-4">
            <lib-dropdown
                formControlName="filtertype"
                label="Column Filter Type"
                placeholder="Select column filter type"
                [items]="filterType"
            ></lib-dropdown>

            <div *ngIf="type.ACCORDION !== widgetType" class="d-flex align-items-center my-3">
                <checkbox formControlName="progressColumn">Progress Column </checkbox>
                <fa-icon
                    class="ml-2 font-16px"
                    icon="QuestionInCircle"
                    container="body"
                    placement="bottom"
                    tooltipClass="help-tooltip"
                    [ngbTooltip]="progressColumnHelp"
                ></fa-icon>
                <checkbox class="pl-3" formControlName="statisticColumn">Statistic Column </checkbox>
                <checkbox class="ml-3" formControlName="percentWidth">Percent Width </checkbox>
                <checkbox class="ml-3" formControlName="columnVisible">Column Visible</checkbox>
            </div>

            <ng-container *libLet="columns.at(columnIndex).value.progressColumn as isProgressColumn">
                <lib-codemirror-editor
                    class="my-3"
                    label="Header Tooltip Template"
                    [needRefresh]="true"
                    mode="text/html"
                    formControlName="headerTooltipTemplate"
                ></lib-codemirror-editor>
                <lib-codemirror-editor
                    *ngIf="!isProgressColumn"
                    class="mb-3"
                    label="Cell Template"
                    [needRefresh]="true"
                    mode="text/javascript"
                    formControlName="cellTemplate"
                ></lib-codemirror-editor>
                <lib-codemirror-editor
                    *ngIf="!isProgressColumn"
                    class="mb-3"
                    label="Cell Tooltip Template"
                    [needRefresh]="true"
                    mode="text/javascript"
                    formControlName="cellTooltipTemplate"
                ></lib-codemirror-editor>

                <lib-codemirror-editor
                    *ngIf="isProgressColumn"
                    class="mb-3"
                    label="Progress Template"
                    [needRefresh]="true"
                    mode="text/javascript"
                    formControlName="progressTemplate"
                ></lib-codemirror-editor>
                <lib-codemirror-editor
                    *ngIf="isProgressColumn"
                    class="mb-3"
                    label="Progress Tooltip"
                    [needRefresh]="true"
                    mode="text/javascript"
                    formControlName="progressTooltip"
                ></lib-codemirror-editor>
                <lib-codemirror-editor
                    class="mb-3"
                    label="Column Sorting"
                    [needRefresh]="true"
                    mode="text/javascript"
                    formControlName="columnSorting"
                ></lib-codemirror-editor>
                <lib-codemirror-editor
                    class="mb-3"
                    label="Filter Comparator"
                    [needRefresh]="true"
                    mode="text/javascript"
                    formControlName="filterComparator"
                ></lib-codemirror-editor>
                <ng-container *ngIf="isProgressColumn">
                    <h3 class="mt-0">Colors</h3>
                    <div
                        class="row mt-3"
                        *ngFor="let progressColor of $any(columns.at(columnIndex).get('progressColors')).controls; let j = index"
                        [formGroup]="progressColor"
                    >
                        <lib-input class="col-4" label="Label" formControlName="label"></lib-input>
                        <dp-widget-color-picker label="Color" formControlName="color"></dp-widget-color-picker>
                        <button
                            class="btn btn-link default-size delete-progress-color-button mt-4 ml-3"
                            [style.padding]="'0 8px !important'"
                            (click)="removeProgressColor(columnIndex, j)"
                            container="body"
                        >
                            <fa-icon
                                icon="facTrash"
                                class="delete-column-icon"
                                aria-hidden="true"
                                [ngbTooltip]="'Delete Color'"
                                tooltipClass="project-tooltip"
                                placement="top"
                                [openDelay]="800"
                                container="body"
                            ></fa-icon>
                        </button>
                    </div>
                    <button class="btn btn-primary align-self-start my-4" (click)="colorPickerSelect(columnIndex)">
                        Add
                    </button>
                </ng-container>
            </ng-container>
        </div>
    </perfect-scrollbar>
</div>
<div class="modal-footer border-0">
    <button class="btn btn-secondary mr-4" (click)="handleCancel()">Cancel</button>
    <button class="btn btn-primary" (click)="handleSave()">Save</button>
</div>

<ng-template #progressColumnHelp>
    <div>
        There are 3 options to configure colors of progress column element. Here they are sorted by priority:<br /><br />
        1. Define "progressColors" placeholder. It should be an array of objects with two fields &#123;label: string, color: string}: -
        label - is a key, that corresponds to one of the expected values for a progress column; - color - html color id for the scpecified
        label. It is supposed to be used in case of dynamically predefined colors (e.g. colors come from DB). <br />
        2. Define label-color pairs in the Colors UI section. <br />
        3. Automatic label-color association will be applied if a placeholder or control is not defined for a particular label. The color
        will be chosen randomly. <br /><br />
        Note: "label" field must be defined for the object (column data placeholder), which is an item of iteration within the progress
        column.
    </div>
</ng-template>
