import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {DashboardWidgetSettingsManager} from '../../../widget-library/dashboard/services/dashboard-widget-settings.manager';

@Injectable()
export class WidgetBuilderTagsService {
    constructor(private dashboardWidgetManager: DashboardWidgetSettingsManager) {
    }

    getTags(): Observable<string[]> {
        return this.dashboardWidgetManager.getWidgetTags();
    }
}
