<div #templateContent class="font-12px h-100">
    <div class="data-text font-weight-600 pb-10px">Overview</div>
    <div class="row">
        <ng-container *ngIf="data.customdataKeys">
            <ng-container *ngFor="let key of data.customdataKeys">
                <ng-container *ngTemplateOutlet="customTemplate; context: { item: key }"></ng-container>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="!data.customdataKeys">
            <ng-container *ngFor="let key of dataKeys">
                <ng-container *ngTemplateOutlet="customTemplate; context: { item: key }"></ng-container>
            </ng-container>
        </ng-container>
    </div>

    <lib-nav class="custom-nav"
             [activeId]="activeTab"
             (navChange)="changeActiveTab($event)">
        <ng-template
            *ngIf="data['code']"
            navItem
            [navItemName]="'Code Snippet'"
            [navItemId]="'tab-Code-Snippet'"
        >
            <ng-container *ngTemplateOutlet="tabContent; context: { template: codeSnippet }"></ng-container>
            <ng-template #codeSnippet>
                <div class="code">{{ data['code'] }}</div>
            </ng-template>
        </ng-template>

        <ng-template
            navItem
            [navItemName]="data.customdataKeys ? 'Description' : 'Remediation'"
            [navItemId]="data.customdataKeys ? 'tab-Description' : 'tab-Remediation'"
        >
            <ng-container *ngTemplateOutlet="tabContent; context: { template: remediation }"></ng-container>
            <ng-template #remediation>
                <div class="remediation">
                    <span [innerHtml]="cleanRemediation(data['remediation']) | safeHtml: 'html'"></span>
                    <span *ngIf="data['hrefLink'] && data['hrefText']">
                        <a [href]="data['hrefLink']" target="_blank">{{ data['hrefText'] }}</a>
                    </span>
                </div>
            </ng-template>
        </ng-template>

        <ng-template
            *ngIf="data.recommendedAction"
            navItem
            [navItemName]="'Recommended Action'"
            [navItemId]="'tab-Recommended-Action'"
        >
            <ng-container *ngTemplateOutlet="tabContent; context: { template: recommended }"></ng-container>
            <ng-template #recommended>
                <div class="remediation data-text">
                    <span>{{ data['recommendedAction'] }}</span>
                    <span *ngIf="data['hrefLink'] && data['hrefText']">
                        <a [href]="data['hrefLink']" target="_blank">{{ data['hrefText'] }}</a>
                    </span>
                </div>
            </ng-template>
        </ng-template>

        <ng-container *ngIf="!data.customdataKeys">
            <ng-template
                *ngIf="defects$ | async as defects"
                navItem
                [navItemName]="'Defect Details'"
                [navItemId]="'tab-Defect-Details'"
            >
                <ng-container *ngTemplateOutlet="tabContent; context: { template: defectList }"></ng-container>
                <ng-template #defectList>
                    <lib-data-grid
                        class="data-grid"
                        [dataSource]="defects"
                        [columns]="defectColumns"
                        [sticky]="true"
                        [usePerfectScrollbar]="true"
                        [bodyHeight]="gridHeight"
                    >
                        <ng-template headerTemplate>
                            <tr class="table-border">
                                <th
                                    *ngFor="let c of defectColumns; let i = index"
                                    [ngStyle]="{ width: c.width }"
                                    (click)="sortClick(i)"
                                    class="over-me"
                                >
                                    <div
                                        [class.blue]="c.sort"
                                        [class.cursor-pointer]="c.sortingField"
                                        [class.cursor-default]="!c.sortingField"
                                        class="text-truncate body2 d-inline-flex align-items-center column-header"
                                        [ngbTooltip]="c.title"
                                        container="body"
                                    >
                                        {{ c.title }}
                                        <span *ngIf="c.sortingField"
                                              [innerHTML]="(c.sort !== 'ASC' ? icons.sortingUp : icons.sortingDown) | safeHtml: 'html'"
                                              class="fa sort-icon ml-5px"
                                        >
                                        </span>
                                    </div>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template rowTemplate let-item="item">
                            <td [ngStyle]="{ width: defectColumns[0].width }">
                                <span class="over-me cursor-pointer"
                                      [ngClass]="{'link' : item['pm_key'] && item['pm_key'] != '--'}"
                                      ngbTooltip="{{ item['pm_key'] }}"
                                      placement="top-left"
                                      container="body"
                                      [openDelay]="400"
                                      (click)="openLink(item['pm_link'])"
                                >
                                    {{ item['pm_key'] || '--' }}
                                </span>
                            </td>
                            <td [ngStyle]="{ width: defectColumns[1].width }"
                                class="cursor-default">
                                <div class="over-me"
                                     ngbTooltip="{{ item['state'] }}"
                                     placement="top-left"
                                     container="body"
                                     [openDelay]="400"
                                >
                                    {{ item['state'] }}
                                </div>
                            </td>
                            <td [ngStyle]="{ width: defectColumns[2].width }"
                                class="cursor-default">
                                <span class="over-me"
                                      ngbTooltip="{{ item['component'] }}"
                                      placement="top-left"
                                      container="body"
                                      [openDelay]="400"
                                >
                                    {{ item['component'] }}
                                </span>
                            </td>
                            <td [ngStyle]="{ width: defectColumns[3].width }"
                                class="cursor-default">
                                <div class="over-me"
                                     ngbTooltip="{{ item['source_url'] }}"
                                     placement="top-left"
                                     container="body"
                                     [openDelay]="400"
                                >
                                    {{ item['source_url'] }}
                                </div>
                            </td>
                            <td [ngStyle]="{ width: defectColumns[4].width }"
                                class="cursor-default">
                                <div class="over-me"
                                     ngbTooltip="{{ item['found_by'] }}"
                                     placement="top-left"
                                     container="body"
                                     [openDelay]="400"
                                >
                                    {{ item['found_by'] }}
                                </div>
                            </td>
                            <td [ngStyle]="{ width: defectColumns[5].width }"
                                class="cursor-default">
                                <div class="over-me"
                                     ngbTooltip="{{ item['assignee'] }}"
                                     placement="top-left"
                                     container="body"
                                     [openDelay]="400"
                                >
                                    {{ item['assignee'] }}
                                </div>
                            </td>
                        </ng-template>
                    </lib-data-grid>
                </ng-template>
            </ng-template>
        </ng-container>
    </lib-nav>
</div>

<ng-template #tabContent let-template="template">
    <perfect-scrollbar [style.max-height]="scrollbarHeight">
        <ng-container *ngTemplateOutlet="template"></ng-container>
    </perfect-scrollbar>
</ng-template>

<ng-template #customTemplate let-item="item">
    <div class="col-3 row pb-10px">
        <div class="col-3 pr-0 label-text text-secondary">{{ item.label }}</div>
        <div
            class="col-9 text-ellipsis"
            ngbTooltip="{{ data[item.value] }}"
            placement="top-left"
            container="body"
            [openDelay]="400"
        >
            <ng-container
                *ngTemplateOutlet="item.value === 'severity' ? severityValueTemplate :
                (item.prefix && item.isLink ? linkTemplate : defaultValueTemplate);
                context: { item: item, key: item.value }"
            ></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #severityValueTemplate let-key="key">
  <span
      [ngClass]="{
          'badge-critical': data[key] === 'Critical',
          'badge-high': data[key] === 'High',
          'badge-medium': data[key] === 'Medium',
          'badge-low': data[key] === 'Low',
          'badge-info': data[key] === 'Info'
      }"
      class="custom-badge size-s custom-badge-background text-white"
  >
      {{ data[key] }}
  </span>
</ng-template>

<ng-template #linkTemplate let-item="item">
    <span
        [ngClass]="{'link' : data[item.prefix + '_link']}"
        class="data-text" (click)="openLink(data[item.prefix + '_link'])"
    >
        <ng-container
            *ngTemplateOutlet="defaultValueWithoutSpanTemplate; context: { key: item.prefix + '_key', item: item }"></ng-container>
    </span>
</ng-template>

<ng-template #defaultValueTemplate let-key="key" let-item="item" let-data="data">
    <span class="data-text">
         <ng-container
             *ngTemplateOutlet="defaultValueWithoutSpanTemplate; context: { key: key, item: item }"></ng-container>
    </span>
</ng-template>

<ng-template #defaultValueWithoutSpanTemplate let-key="key" let-item="item">
    {{ data[key] || '--' }}
    <fa-icon *ngIf="item.openTab && data[item.showIf]" icon="facEyeOpen" class="ml-10px link"
             ngbTooltip="{{ 'View details' }}"
             placement="right"
             container="body"
             (click)="openTab(item.openTab); $event.stopPropagation()"
    ></fa-icon>
</ng-template>
