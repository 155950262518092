<div *ngIf="!hasErrors; else errorTemplate" class="wrapper-max-width-with-collapsed-sidenav ps-container">
    <div class="m-4 p-4 content-wrapper" *ngIf="!loadingFilters || !loadingPage; else loadingTemplate">
        <div class="mb-15px w-100">
            <div class="control-container">
                <div class="buttons">
                    <button class="btn btn-link log-button" *ngIf="!loadingPage && errors?.length > 0"
                            (click)="handleShowErrorLogs()">
                        <fa-icon class="font-16px" icon="facLogs"></fa-icon>
                    </button>
                    <button class="btn btn-link" (click)="reset()">
                        <fa-icon class="font-16px mr-5px" icon="rotationRight"></fa-icon>
                        Refresh
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="!loadingFilters; else loadingTemplate">
            <dp-any-widget-wrapper
                *ngIf="filters?.length"
                class="position-relative m-auto fixed-size widget shadow-none border-0"
                style="font-size: 12px;"
                [options]="options"
                [separatedView]="true"
                [showingOnlyFiltersBlock]="true"
                (storeAfterLoad)="updatePlaceholders($event)"
            >
            </dp-any-widget-wrapper>
        </div>
        <div *ngIf="!loadingPage; else loadingTemplate" class="table"
             [ngStyle]="{border: errors?.length > 0 ? 'none' : 'cssvar(dropdown-border)'}"
        >
            <lib-data-grid
                *ngIf="errors?.length === 0"
                [dataSource]="page.content"
                [columns]="gridColumns"
                [pagination]="pagination"
                [pageable]="pageable"
                [sticky]="true"
[adjustHorizontalScroll]="true"
                [bodyHeight]="gridHeight"
                [usePerfectScrollbar]="true"
                (pageChange)="onChangePage($event)"
                (sort)="onSort($event)"
            >
                <ng-template headerTemplate>
                    <tr>
                        <th
                            *ngFor="let c of gridColumns"
                            [id]="c.field"
                            [ngClass]="{ 'sort-field': c.sortingField }"
                            [style.width]="c.width"
                            (click)="changeSort(c.sortingField)"
                        >
                        <span class="column-header">
                            <div (mouseover)="setIsHovered(true, c, $event)"
                                 (mouseout)="setIsHovered(false, c, $event)"
                            >
                                {{ c.title }}
                                <div class="secondary-header">{{ c.field }}</div>
                            </div>
                            <span
                                *ngIf="c.sortingField"
                                [class.sort-off-icon]="!pageable || !pageable.sorted(c.sortingField)"
                                class="sorting-button"
                                [innerHTML]="(pageable && pageable.sorted(c.sortingField) !== 'ASC' ? sortIcons.sortingDown : sortIcons.sortingUp) | safeHtml: 'html'"
                            >
                            </span>
                        </span>
                        </th>
                    </tr>
                </ng-template>

                <ng-template rowTemplate let-content="item">
                    <td
                        *ngFor="let column of gridColumns"
                        [style.width]="column.width"
                    >
                        {{ (content[column.field]?.length && content[column.field]?.length > 100) ? ( (content[column.field] | slice:0:100)+'..') : content[column.field] }}
                    </td>
                </ng-template>
            </lib-data-grid>
            <div class="error-message-box info2" *ngIf="errors?.length > 0">
                Failed to retrieve data
            </div>
        </div>
    </div>
</div>

<ng-template #errorTemplate>
    <div
        style="width: 100%; height: 80vh; display: flex; align-items: center; justify-content: center; flex-direction: column;">
        <app-icon-remoter [iconName]="'GLOBAL-ERROR'"></app-icon-remoter>
        <h2>Data Set configuration is invalid</h2>
        <br/>
        <div class="body3 color-font-4">
            Please switch to Model tab and check the list of Issues to fix the configuration
        </div>
        <br/>
        <button class="btn btn-primary" (click)="backButton.emit({ nextId: 'Model' })">
            Back to Model tab
        </button>
    </div>
</ng-template>

<ng-template #loadingTemplate>
    <div *ngIf="!hasErrors; else errorTemplate"
         class="mb-15px p-4 m-4 no-gutters w-100 d-flex justify-content-center align-items-center"
    >
        <lib-loader></lib-loader>
    </div>
</ng-template>

<div class="hint-box" id="hintBoxRef" [class.invisible]="!isHovered" [style.top.px]="hintBox.top"
     [style.left.px]="hintBox.left"
>
    <span>{{ hintData }}</span>
</div>
