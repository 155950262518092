import { Component, Inject } from '@angular/core';
import { ENV_TOKEN } from '@app/tokens';
import { ModulesApiService } from '@app/core/services/modules-api.service';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-xpresso',
    templateUrl: './xpresso.component.html',
})
export class XpressoComponent {
    xpressoUrl = this.env.xpressoUrl;
    url$ = this.route.queryParams.pipe(
        map(params => {
            return this.xpressoUrl;
        })
    );
    constructor(@Inject(ENV_TOKEN) private env: Env, public modulesApiService: ModulesApiService, private router: Router, private route: ActivatedRoute) {}
}
