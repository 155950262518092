import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';

const BODY_HEIGHT = 555;
const BODY_WIDTH = 800;
const BODY_PADDING = 200;
const FILE_PADDING = 55;

@Component({
    selector: 'app-generated-script-modal',
    templateUrl: './generated-script-modal.component.html',
    styleUrls: ['./generated-script-modal.component.scss'],
})
export class GeneratedScriptModalComponent {
    title = 'Generated script';

    jenkinsFile$: Observable<any>;
    jenkinsFilename: string;

    refresh$ = new Subject();

    codeMirror: boolean= false;
    isExpanded: boolean;

    expand() {
        this.isExpanded = !this.isExpanded;
        this.refresh$.next();
    }

    get bodyWidth() {
        return this.isExpanded ? document.body.clientWidth - BODY_PADDING : BODY_WIDTH;
    }

    get bodyHeight() {
        return this.isExpanded ? document.body.clientHeight - BODY_PADDING : BODY_HEIGHT;
    }

    get jenkinsFileHeight() {
        return this.bodyHeight - FILE_PADDING;
    }

    constructor(public modal: NgbActiveModal) {}
}
