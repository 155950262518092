import { Component, Input } from '@angular/core';
import { SourceFolderItem, SourcesBelongType } from '../../../../../../models/application-source.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CiCdAppSourcesService } from '../../../../../../api/ci-cd-app-sources.service';
import { CompletionSourceItem } from '@dagility-ui/shared-components';

@Component({
    selector: 'app-file-editor',
    templateUrl: './file-edit.component.html',
    styleUrls: ['./file-edit.component.scss'],
    host: {
        class: 'flex-1 overflow-y-auto font-12px position-relative',
    },
})
export class FileEditComponent {
    @Input() set content(el: any) {
        this.contentValue = el;
    }

    @Input() sourceItem: SourceFolderItem;
    @Input() path: string;
    @Input() appSourceId: number;
    @Input() readOnly: boolean = true;
    @Input() autoCompletionDictionary: CompletionSourceItem[] = [];
    @Input() sourceType: SourcesBelongType;

    contentValue = '';

    saving = false;
    wasSaved = true;

    constructor(private apiService: CiCdAppSourcesService, private activeModal: NgbActiveModal, private toaster: ToastrService) {}

    editorValueChange(value: string) {
        this.content = value;
        this.wasSaved = false;
    }

    showConfirm() {
        return confirm('File has been modified. Your changes will be lost if you leave the page.');
    }

    saveFile() {
        this.saving = true;
        this.apiService
            .saveFileContent(
                this.contentValue,
                this.path + `/${this.sourceItem.name}`,
                this.sourceItem,
                this.appSourceId,
                this.sourceType
            )
            .subscribe(
                () => {
                    this.toaster.success('File has been saved successfully');
                    this.wasSaved = true;
                    this.saving = false;
                    this.activeModal.close(true);
                },
                error => {
                    this.saving = false;
                    this.toaster.error(error['message'], 'Error while saving file');
                }
            );
    }

    cancel() {
        this.activeModal.dismiss();
    }
}
