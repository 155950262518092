<ng-container formArrayName="accordionColumns">
    <h3 class="mt-0">
        Accordion Columns
    </h3>
    <span class="btn-link cursor-pointer my-3 d-block" (click)="addColumn()">+ Add Accordion column</span>
    <div
        [formGroup]="accordionColumnControl"
        [class.column-controls-border]="!last"
        *ngFor="let accordionColumnControl of accordionColumns.controls; let i = index; let last = last"
        class="d-flex flex-column mb-3"
    >
        <div class="row mb-3">
            <lib-input class="col" formControlName="title" [label]="i === 0 ? 'Title' : null"></lib-input>
            <lib-input class="col" formControlName="field" [label]="i === 0 ? 'Field' : null"></lib-input>

            <fa-icon
                icon="facTrash"
                class="delete-column-icon"
                [style.paddingTop]="i === 0 ? '20px' : '0px'"
                aria-hidden="true"
                (click)="removeColumn(i)"
                [ngbTooltip]="'Delete column'"
                tooltipClass="project-tooltip"
                placement="top"
                [openDelay]="800"
                container="body"
            ></fa-icon>
        </div>

        <lib-codemirror-editor
            class="mb-3"
            label="Click Event Function"
            [needRefresh]="true"
            mode="text/javascript"
            formControlName="clickEventFunction"
        ></lib-codemirror-editor>
    </div>
</ng-container>
