<ng-container formArrayName="columns">
    <div class="d-flex">
        <h3 class="mt-0">
            Columns
        </h3>
        <div class="d-flex ml-auto" *ngIf="!getDisplaySettingsConfig.enabled">
            <span class="btn-link cursor-pointer my-3 d-block" (click)="addColumn(true)">+ Add column</span>
            <span class="btn-link cursor-pointer my-3 d-block ml-5" (click)="reorderColumns()"
                ><fa-icon icon="bars"></fa-icon> Reorder columns</span
            >
        </div>
    </div>
    <div
        [formGroup]="columnControl"
        *ngFor="let columnControl of columns.controls; let i = index; let last = last"
        class="d-flex flex-column mb-3"
    >
        <div class="row mb-3">
            <lib-input class="col-4" formControlName="title" [label]="i === 0 ? 'Title' : null"></lib-input>
            <lib-input [setDisable]="getDisplaySettingsConfig.enabled" class="col-4" formControlName="field" [label]="i === 0 ? 'Field' : null"></lib-input>

            <button
                class="btn btn-secondary col-3"
                [style.fontSize]="'0.8vw !important'"
                [style.marginTop]="i === 0 ? '1.5rem' : '0px'"
                (click)="editColumn(columnControl, i)"
            >
                <fa-icon icon="facEdit" class="mr-2"></fa-icon> Edit Additional Settings
            </button>

            <button *ngIf="!getDisplaySettingsConfig.enabled"
                class="btn btn-link default-size delete-column-button ml-3"
                [style.marginTop]="i === 0 ? '25px' : '0px'"
                [style.padding]="'0 8px !important'"
                (click)="removeColumn(i)"
                container="body"
            >
                <fa-icon
                    icon="facTrash"
                    class="delete-column-icon"
                    aria-hidden="true"
                    [ngbTooltip]="'Delete column'"
                    tooltipClass="project-tooltip"
                    placement="top"
                    [openDelay]="800"
                    container="body"
                ></fa-icon>
            </button>
        </div>
    </div>
    <div class="mt-2">
        <span class="btn-link cursor-pointer d-block add-column-button" (click)="addColumn(false)" *ngIf="!getDisplaySettingsConfig.enabled">+ Add column</span>
    </div>
</ng-container>
