<div *ngIf="!createTab" class="modal-header border-0">
    <h2 class="modal-title mt-0">{{ headerText }} Tab</h2>
    <button class="close" (click)="handleCancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" [formGroup]="form">
    <lib-input
        class="mb-3"
        entityNameControl
        label="Tab Name"
        formControlName="name"
        [customErrors]="{ unique: 'Tab name already exists' }"
    ></lib-input>
    <ng-container formGroupName="options">
        <!-- <lib-input class="mb-3" label="Index" type="number" formControlName="tabOrder"></lib-input> -->
        <checkbox data-test-id="reset-event-dependencies" *ngIf="tabVisibility === 'SHOW_ACTIVE'" formControlName="resetEventDependencies"
            >Reset Event Dependencies</checkbox
        >
    </ng-container>
</div>
<div *ngIf="!createTab" class="modal-footer border-0">
    <button class="btn btn-secondary mr-4" (click)="handleCancel()">Cancel</button>
    <button data-test-id="save-button" class="btn btn-primary" (submitAfterAsyncValidation)="handleSave()" [submittedControl]="form">
        Save
    </button>
</div>
