<div class="modal-header">
    <h2 id="modal-title" class="mt-0">
        <span>{{ title }}</span>
    </h2>
    <button
        type="button"
        class="close"
        aria-label="Close button"
        aria-describedby="modal-title"
        (click)="modal.dismiss('Cross click')"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div *ngFor="let radio of radioButtons; let i = index"
         [ngClass]="{'mt-3' : i !== 0}"
    >
        <lib-radio-button
            attr.data-test-id="{{'option-' + i}}"
            [checked]="radio.checked"
            [label]="radio.label"
            [value]="radio.value"
            (click)="handleChange(i)"
        ></lib-radio-button>
    </div>
</div>

<div class="modal-footer">
    <button
        data-test-id="cancel-button"
        type="button"
        class="btn btn-secondary"
        (click)="cancel()"
    >
        {{ closeButtonText }}
    </button>
    <button
        data-test-id="confirm-button"
        type="button"
        class="btn btn-primary ml-3"
        (click)="confirm()"
    >
        {{ confirmButtonText }}
    </button>
</div>
