<div [class]="'banner ' + color + (filled ? ' filled' : '')" *ngIf="data">
    <div [class]="'banner-title ' + (filled ? '' : color)">
        <fa-icon icon="facExclamationCircle"></fa-icon>
        <div class="pl-10px">{{ data.category }}</div>
    </div>
    <span class="banner-content">{{ data.message }}</span>

    <span class="banner-right">
        <span class="banner-time">
            {{ timeMessage }}
            <img src="assets/images/icons/icon_alarm.svg" alt="" />
        </span>
        <button [class]="'btn btn-' + color" (click)="onClose()">{{ data.acceptanceType }}</button>
        <span class="banner-close cursor-pointer" (click)="onClose()">&times;</span>
    </span>
</div>
