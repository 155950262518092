<perfect-scrollbar class="modal-container" [config]="perfectScrollbarConfig" [style.maxWidth.vw]="95">
    <div [style.maxWidth.px]="960">
        <div class="modal-header">
            <div class="d-block">
                <h2 class="modal-title mt-0">Execution Logs {{ pipelineName }}</h2>
                <span *ngIf="buildNumber">
                    Job Id: <strong>{{ buildNumber }}</strong>
                </span>
                <span *ngIf="time" class="ml-16px">
                    Execution Date:
                    <strong>{{ time | date: 'lastDateTimeCustom' }}</strong>
                </span>
            </div>
            <button type="button" class="close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body p-0" [style.maxWidth.px]="960" [style.maxHeight.px]="555">
            <nav ngbNav #nav="ngbNav" class="nav nav-tabs justify-content-start">
                <ng-container ngbNavItem="logs">
                    <a ngbNavLink class="ml-25px" [attr.data-test-id]="'logs'">Logs</a>
                    <ng-template ngbNavContent>
                        <lib-log
                            *ngIf="logs$"
                            [logs$]="logs$"
                            [contentTemplateRef]="contentTemplateRef"
                            [title]="title"
                            [request$]="request$"
                            [height]="400"
                            [fileName]="fileName"
                            [searchable]="true"
                        ></lib-log>
                    </ng-template>
                </ng-container>
                <ng-container *ngIf="dataSource | async as data" ngbNavItem="runtime-variables">
                    <a ngbNavLink [attr.data-test-id]="'runtime-variables'">Runtime Variables</a>
                    <ng-template ngbNavContent>
                        <lib-data-grid
                            *ngIf="data.length; else noData"
                            [dataSource]="data"
                            [columns]="gridColumns"
                            [sticky]="true"
                            [usePerfectScrollbar]="true"
                            [bodyHeight]="'300px'"
                            class="p-4"
                        >
                            <ng-template headerTemplate>
                                <tr>
                                    <th
                                        *ngFor="let c of gridColumns; let i = index"
                                        [ngStyle]="{ width: c.width }"
                                        (click)="sortClick(i)"
                                        class="over-me"
                                    >
                                        <div
                                            [class.blue]="c.sort"
                                            [class.cursor-pointer]="c.sortingField"
                                            [class.cursor-default]="!c.sortingField"
                                            class="text-truncate body2 d-inline-flex align-items-center column-header"
                                            [ngbTooltip]="c.title"
                                            container="body"
                                        >
                                            {{ c.title }}
                                            <span
                                                *ngIf="c.sortingField"
                                                [innerHTML]="(c.sort !== 'ASC' ? icons.sortingUp : icons.sortingDown) | safeHtml: 'html'"
                                                class="fa sort-icon ml-5px"
                                            >
                                            </span>
                                        </div>
                                    </th>
                                </tr>
                            </ng-template>

                            <ng-template rowTemplate let-sourceItem="item" let-i="index">
                                <td [ngStyle]="{ width: gridColumns[0].width }" class="cursor-default over-me">
                                    <div class="d-inline-flex align-items-center" attr.data-test-id="{{ 'variable-name-' + i }}">
                                        {{ sourceItem.variableName }}
                                    </div>
                                </td>
                                <td
                                    [ngStyle]="{ width: gridColumns[1].width }"
                                    class="cursor-default over-me"
                                    attr.data-test-id="{{ 'variable-value-' + i }}"
                                >
                                    <div [innerText]="sourceItem.value || '—'"></div>
                                </td>
                            </ng-template>
                        </lib-data-grid>
                    </ng-template>
                </ng-container>
            </nav>
            <div [ngbNavOutlet]="nav"></div>
        </div>
    </div>
</perfect-scrollbar>

<ng-template #noData>
    <div class="info1 text-center p-5">
        There are no Runtime Variables.
    </div>
</ng-template>
