<form *ngIf="recipientForm" [formGroup]="recipientForm">
    <ng-container *ngIf="isAdmin; else defaultRecipientTmp">
        <div id="recipientList">
            <div *ngIf="recipientForm.errors?.recipientListInvalid" class="multi-tool-warning mb-15px row no-gutters">
                <div class="multi-tool-warning-title body5 color-font-4">
                    <fa-icon icon="facExclamationCircle"></fa-icon>
                </div>
                <div class="multi-tool-warning-content body4">Please fill in at least one field in the Recipients section</div>
            </div>
        </div>
        <div class="recipients-container">
            <ng-container *ngFor="let recipientItem of recipientsArray; let i = index">
                <lib-dropdown
                    (change)="changeRecipients($event, true)"
                    [items]="recipientTypes"
                    [formControlName]="recipientItem + 'Dropdown'"
                    valueKey="value"
                    labelKey="label"
                    label="{{ i === 0 ? 'Recipient type' : '' }}"
                ></lib-dropdown>
                <lib-dropdown
                    (change)="changeRecipientsValue(recipientItem)"
                    class="mb-16px"
                    style="width: 100%"
                    [items]="getItems(recipientItem)"
                    [formControlName]="recipientItem"
                    [placeholder]="'Search'"
                    valueKey="id"
                    labelKey="name"
                    label="{{ i === 0 ? 'Recipients' : '' }}"
                    [searchable]="true"
                    [clearable]="true"
                    [multiple]="true"
                    (filter)="searchItems(recipientItem, $event)"
                ></lib-dropdown>
            </ng-container>
            <lib-dropdown
                [items]="wholeRoles"
                [formControlName]="'roles'"
                valueKey="id"
                labelKey="name"
                label="Roles"
                [searchable]="true"
                [clearable]="true"
                [multiple]="true"
                (change)="onRolesChange()"
                groupBy="group"
            ></lib-dropdown>
            <lib-dropdown
                class="mb-16px"
                style="width: 100%"
                [items]="users"
                [formControlName]="'users'"
                [placeholder]="'Search'"
                valueKey="id"
                labelKey="name"
                label="Users"
                [searchable]="true"
                [clearable]="true"
                [multiple]="true"
                (filter)="searchUsers($event)"
                (change)="onUsersChange($event)"
                (close)="onCloseUserDropdown()"
            ></lib-dropdown>
        </div>
    </ng-container>
    <ng-template #defaultRecipientTmp>
        <div class="multi-tool-warning mb-15px row no-gutters">
            <div class="multi-tool-warning-title body5 color-font-4">
                <fa-icon icon="facExclamationCircle"></fa-icon>
            </div>
            <div class="multi-tool-warning-content body4">Alerts will be sent to: {{ user.name }} </div>
        </div>
    </ng-template>
</form>
