import { Deserializable, PrimitiveDataType } from './abstract-job-definition';
import { IsEnum, Type } from '../type.decorator';
import { Required } from '../validate.decorator';

export enum TransformationType {
    NOT_NULL = 'NOT_NULL',
    DISTINCT = 'DISTINCT',
    SUBSTRING = 'SUBSTRING',
    TO_LOWER_CASE = 'TO_LOWER_CASE',
    REPLACE = 'REPLACE',
    COPY = 'COPY',
    DEFAULT = 'DEFAULT',
    TO_STRING = 'TO_STRING',
    CONCAT = 'CONCAT'
}

export class DataTransformation extends Deserializable {
    _arrays = {
        fields: 'string',
    };
    _class = {};
    _enums = {
        transformationType: TransformationType,
    };
    _maps = {
        parameters: PrimitiveDataType,
    };
    meta = {
        _arrays: this._arrays,
        _enums: this._enums,
        _class: this._class,
        _maps: this._maps,
    };

    @Required()
    @IsEnum(TransformationType)
    transformationType: TransformationType = null;

    @Type(String)
    fields: string[] = [];

    @Type(PrimitiveDataType)
    parameters: Map<string, PrimitiveDataType> = {} as any;

    getType() {
        return 'DataTransformation';
    }

    getInfo() {
        return `Data Transformation ${this.transformationType ? `transformation type = "${this.transformationType}"` : ''}`;
    }

    constructor(instanceData?: DataTransformation) {
        super();
        this.fillByInstance(DataTransformation, instanceData);
    }
}
