<ng-content></ng-content>
<span class="position-relative context-help-container" *ngIf="contextData || data as contextData" (click)="preventClickBubbling($event)">
    &nbsp;<fa-icon
        data-test-id="context-help-icon"
        class="align-baseline cursor-pointer fa font-14px"
        [class.color-brand]="defaultColor"
        icon="QuestionInCircle"
        aria-hidden="true"
        (click)="showContextHelp()"
    ></fa-icon>

    <div
        *ngIf="showHelp"
        data-test-id="context-help-content-container"
        [style.max-width.px]="contextHelpWidth"
        [style.min-width.px]="contextHelpWidth"
        class="context-help-wrap d-flex flex-column rounded position-absolute"
        (document:click)="onDocumentClick($event.target)"
        (window:keydown.Escape)="onEscapeKey()"
    >
        <ng-template [ngTemplateOutlet]="contextHelpTmpl" [ngTemplateOutletContext]="{ close: dismiss, popup: true }"></ng-template>
    </div>
    <ng-template #contextHelpTmpl let-close="close" let-popup="popup">
        <div class="d-flex justify-content-between border-bottom py-20px px-4">
            <h2 class="mt-0">{{ contextData?.title }}</h2>
            <button type="button" class="close ml-auto" aria-label="Close button" aria-describedby="modal-title" (click)="close()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div
            data-test-id="context-help-text"
            [class.font-12px]="popup"
            class="font-14px pb-0 context-help-text position-relative my-4 px-4"
            perfectScrollbar
        >
            <div remove-empty-tags [innerHTML]="contextData?.content | safeHtml: 'html'" (click)="handleContentClick($event)"></div>
        </div>
    </ng-template>
</span>
