import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { filter, finalize, groupBy, map, mergeMap, toArray } from 'rxjs/operators';
import { from } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerVisibilityService } from 'ng-http-loader';

import { EnvironmentModel, ModalConfirmComponent } from '@dagility-ui/kit';

import { EditToolTemplatePopupComponent } from 'data-processor/lib/plugins/tool-library/edit-tool-template-popup/edit-tool-template-popup.component';
import {
    sortObjectArrayLikeMapValuesOrder,
    ToasterService,
    TOOL_CATEGORY_NAMES,
    ToolTemplate,
    ToolTemplateService,
} from '@dagility-ui/shared-components';

@Component({
    selector: 'dp-tool-library',
    templateUrl: './tool-library.component.html',
    styleUrls: ['./tool-library.component.scss'],
})
export class ToolLibraryComponent implements OnInit {
    @Input() isInIframe = false;

    @Output()
    openConfigured = new EventEmitter();

    private toolTemplates: ToolTemplate[];
    text = '';

    groups: ToolTemplateGroup[] = [];
    dellBuild = this.environment.component;

    constructor(
        private toolTemplateService: ToolTemplateService,
        private modalService: NgbModal,
        private spinnerService: SpinnerVisibilityService,
        private toster: ToasterService,
        @Inject('environment') private environment: EnvironmentModel
    ) {}

    ngOnInit() {
        this.getData();
    }

    handleAddToolTemplate() {
        const editModalRef = this.modalService.open(EditToolTemplatePopupComponent, {
            centered: true,
            windowClass: 'widget-modal',
            backdrop: 'static',
            keyboard: false,
        });
        editModalRef.componentInstance.toolTemplateSaved.subscribe(() => {
            this.getData();
        });
    }

    getData() {
        this.spinnerService.show();
        this.toolTemplateService
            .findAll()
            .pipe(finalize(() => this.spinnerService.hide()))
            .subscribe(result => {
                this.toolTemplates = result;
                this.checkForMultiTools();
                this.transform();
            });
    }

    configureAccess(plugin: any) {
        const modalRef = this.modalService.open(ModalConfirmComponent, {
            windowClass: 'tool-modal',
            centered: true,
            backdrop: 'static',
        });
        modalRef.componentInstance.message = { title: 'Tool actions' };
        modalRef.componentInstance.confirmButtonText = 'Edit tool template';
        modalRef.componentInstance.closeButtonText = 'Delete tool template';
        if (plugin.hasTools) {
            modalRef.componentInstance.cancelBtnDisable = true;
            modalRef.componentInstance.cancelBtnTooltip = 'Tool template has linked tools';
        }

        modalRef.componentInstance.confirmOk.subscribe(() => {
            const editModalRef = this.modalService.open(EditToolTemplatePopupComponent, {
                centered: true,
                windowClass: 'widget-modal',
                backdrop: 'static',
                keyboard: false,
            });
            editModalRef.componentInstance.template = plugin;
            editModalRef.componentInstance.toolTemplateSaved.subscribe(() => {
                this.getData();
            });
        });

        modalRef.componentInstance.cancelBtnClicked.subscribe(() => {
            const deleteModalRef = this.modalService.open(ModalConfirmComponent, {
                centered: true,
                backdrop: 'static',
            });
            deleteModalRef.componentInstance.message = {
                title: `Delete`,
                content: 'Are you sure you want to delete tool template?',
            };
            deleteModalRef.componentInstance.confirmOk.subscribe(() => {
                this.toolTemplateService.deleteToolTemplateById(plugin.id).subscribe(
                    () => {
                        this.toster.successToast({
                            title: 'Success',
                            content: 'Tool Template was deleted',
                        });
                        this.getData();
                    },
                    result => {
                        this.toster.errorToast({
                            title: 'Error',
                            content: `${result.message}`,
                        });
                    }
                );
            });
        });
    }

    searchData($event: string) {
        this.text = $event.toLowerCase().trim();
        this.transform();
    }

    private transform() {
        const predicate = (t: ToolTemplate) =>
            !this.text ||
            t.name.toLowerCase().indexOf(this.text) >= 0 ||
            TOOL_CATEGORY_NAMES.get(t.groupCategory)
                .toLowerCase()
                .indexOf(this.text) >= 0;

        from(this.toolTemplates)
            .pipe(
                filter(predicate),
                groupBy(t => TOOL_CATEGORY_NAMES.get(t.groupCategory)),
                mergeMap(group => group.pipe(toArray())),
                map(x => <ToolTemplateGroup>{ category: TOOL_CATEGORY_NAMES.get(x[0].groupCategory), templates: x }),
                toArray()
            )
            .subscribe(result => {
                this.groups = sortObjectArrayLikeMapValuesOrder(result, 'category', TOOL_CATEGORY_NAMES);
            });
    }

    checkForMultiTools() {
        const multiTools: ToolTemplate[] = [];
        this.toolTemplates.forEach(x => {
            x.groupCategory = x.pluginCategories[0];
            if (x.pluginCategories.length > 1) {
                for (let i = 1; i < x.pluginCategories.length; i++) {
                    multiTools.push({ ...x, groupCategory: x.pluginCategories[i] });
                }
            }
        });
        this.toolTemplates.push(...multiTools);
    }
}

interface ToolTemplateGroup {
    category: string;
    templates: ToolTemplate[];
}
