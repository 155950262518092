export class DisplaySettingsConfig {
    enabled = false;
    dataSeries: DataSeriesConfig = {
        showColumn: true,
        showColorPicker: true,
        showLabel: true,
    };
    showBasicChartOptions: boolean = true;
    showChartType = true;
    showSizeChanger: boolean = false;
}

export interface DataSeriesConfig {
    showColumn: boolean;
    showLabel: boolean;
    showColorPicker: boolean;
}
