<div class="modal-header align-items-center">
    <h2 class="mt-0">External Parameters</h2>
    <fa-icon icon="facClose" class="secondary-text-and-icons cursor-pointer" (click)="activeModal.dismiss()"></fa-icon>
</div>
<div class="modal-body">
    <div class="font-14px error-base" *ngIf="showWarning">
        <fa-icon icon="facExclamationCircle" class="mr-2"></fa-icon>
        <span>Please fill in placeholders for the correct data display</span>
    </div>
    <button class="btn btn-link size-s ml-auto mb-3" tabindex="-1" (click)="handleAddClick()">
        <fa-icon class="mr-5px" icon="plus"> </fa-icon>Add Parameter
    </button>
    <lib-data-grid [columns]="columns" [dataSource]="params">
        <ng-template rowTemplate let-param="item" let-index="index">
            <td [style.width]="columns[0].width">
                {{ param.name }}
            </td>
            <td [style.width]="columns[1].width">
                {{ param.value }}
            </td>
            <td [style.width]="columns[2].width">
                {{ param.valueType === 'TEXT' ? 'String' : 'Number' }}
            </td>
            <td [style.width]="columns[3].width">
                <div class="d-flex disable" [style.fontSize.px]="12">
                    <fa-icon
                        icon="facEdit"
                        class="cursor-pointer mr-3"
                        ngbTooltip="Edit"
                        openDelay="500"
                        (click)="handleEditClick(param)"
                    ></fa-icon>
                    <fa-icon
                        *ngIf="param.type !== 'DRILLDOWN'"
                        icon="facTrash"
                        class="cursor-pointer"
                        ngbTooltip="Delete"
                        openDelay="500"
                        (click)="handleDeleteClick(param.uid)"
                    ></fa-icon>
                </div>
            </td>
        </ng-template>
    </lib-data-grid>
</div>
<div class="modal-footer">
    <button class="btn btn-secondary mr-4" (click)="activeModal.dismiss()">Cancel</button>
    <button class="btn btn-primary mr-0" (click)="handleApplyClick()">Apply</button>
</div>
