import { Component, Input } from '@angular/core';
import { debounce } from 'lodash';

import { WidgetDebuggerState } from '../../../services/widget.debugger';
import { AnyWidgetModel, WidgetFilter } from '../../../models/any-widget.model';
import { PlaceholdersProvider } from '../../../directives/filter-set.directive';
import { ClientWidgetScriptExecutor, ConsoleLogger, WidgetLogger, WidgetScriptExecutor } from '../../../services/widget-script.executor';

@Component({
    selector: 'dp-debug-global-filters',
    templateUrl: './debug-global-filters.component.html',
    providers: [
        {
            provide: PlaceholdersProvider,
            useValue: new (class extends PlaceholdersProvider {
                phf = {};
                filterValuesMap = {};
            })(),
        },
        {
            provide: WidgetScriptExecutor,
            useClass: ClientWidgetScriptExecutor,
        },
        {
            provide: WidgetLogger,
            useClass: ConsoleLogger,
        },
    ],
})
export class DebugGlobalFiltersComponent {
    @Input() widget: AnyWidgetModel;

    handleFilterChange = debounce((filter: WidgetFilter) => {
        this.debuggerState.filterChanged$.next([this.widget.id, filter.placeholder]);
    }, 300);

    constructor(public debuggerState: WidgetDebuggerState) { }
}
