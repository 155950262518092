import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, forkJoin, from, of } from 'rxjs';
import { cloneDeep } from 'lodash';

import { EventAlertSettingsService } from '../services/event-alert-settings.service';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-event-conditions',
    templateUrl: './event-conditions.component.html',
})
export class EventConditionsComponent implements OnInit, OnDestroy {
    @Input() form: FormGroup;
    @Input() pipelineId: number;

    logicOperators: { label: string; value: any }[] = [
        { value: 'AND', label: 'And' },
        { value: 'OR', label: 'Or' },
    ];

    events: any[] = [];
    eventConditions: any[] = [];
    operators: Record<string, any> = {};
    values: Record<string, any> = {};
    addConditionControl = new FormControl();
    loading: boolean;

    updateControls$ = new BehaviorSubject<any>(null);

    constructor(private service: EventAlertSettingsService, private fb: FormBuilder, private cdr: ChangeDetectorRef) {}

    get conditions() {
        return this.form.controls['eventConditions'] as FormArray;
    }

    ngOnInit(): void {
        if (this.pipelineId) {
            this.loading = true;
        }
        this.form.setControl(
            'eventConditions',
            this.fb.array([
                this.fb.group({
                    event: new FormControl(null, Validators.required),
                    condition: new FormControl(null, Validators.required),
                    operator: new FormControl(null, Validators.required),
                    value: new FormControl(null, Validators.required),
                }),
            ])
        );
        this.service.getEvents().subscribe(events => {
            this.events = events;
            if (this.pipelineId) {
                this.setPredefinedCondition();
            }
        });
        this.updateControls$
            .pipe(
                switchMap(condition => {
                    if (!condition) {
                        return of(null);
                    }
                    const conditionForm = this.form.controls['eventConditions'] as any;
                    const eventCode = conditionForm.controls[0].controls['event'].value;
                    return forkJoin([
                        this.service.getConditionOperators(eventCode, condition.condition.type),
                        this.service.getConditionValues(eventCode, condition.condition.type, this.getEventConditions()),
                        this.service.getConditionValueType(eventCode, condition.condition.type),
                    ]).pipe(
                        map(([operators, values, valueType]) => {
                            return {
                                index: condition.index,
                                operators: operators,
                                values: values,
                                valueType: valueType,
                            };
                        }),
                        catchError(() => of(null)),
                    );
                })
            )
            .subscribe(value => {
                if (value) {
                    this.operators[value.index] = value.operators;
                    this.values[value.index] = {
                        values: value.values,
                        valueType: value.valueType,
                    };
                    this.clearOperatorAndValueControls(value.index);
                }
            });
    }

    onEventChange(event: any) {
        if (!event) {
            return;
        }
        this.service.getEventConditions(event.code).subscribe(conditions => {
            this.eventConditions = conditions;
        });
    }

    onConditionChange(condition: any, index: number) {
        this.operators[index] = [];
        this.values[index] = null;
        this.clearOperatorAndValueControls(index);

        if(condition) {
            this.updateControls$.next({ condition, index });
        }
    }

    onOperatorChange(operator: any, index: number) {
        if (!operator) {
            return;
        }

        const conditionForm = this.form.controls['eventConditions'] as any;
        const value = conditionForm.controls[index].controls['value'].value;

        if (operator.multiple && value && !Array.isArray(value)) {
            conditionForm.controls[index].controls['value'].patchValue([value]);
            conditionForm.controls[index].controls['value'].updateValueAndValidity();
            this.cdr.detectChanges();
        }
        if (!operator.multiple && value && Array.isArray(value)) {
            conditionForm.controls[index].controls['value'].patchValue(null);
            conditionForm.controls[index].controls['value'].updateValueAndValidity();
        }
    }

    getEventConditions() {
        const eventConditions = cloneDeep(this.form.controls['eventConditions'].value);
        eventConditions.forEach((condition: any) => (condition.value = this.getValue(condition.value)));
        return eventConditions;
    }

    eventConditionsToFormValues(conditions: any[]) {
        this.loading = true;
        this.service.getEventConditions(conditions[0].event).subscribe(conditions => {
            this.eventConditions = conditions;
        });

        const eventConditionsArray: FormArray = this.fb.array([]);

        from(conditions).pipe(concatMap((condition, index) =>
            forkJoin([
                this.service.getConditionOperators(conditions[0].event, condition.condition),
                this.service.getConditionValues(conditions[0].event, condition.condition, conditions),
                this.service.getConditionValueType(conditions[0].event, condition.condition),
            ]).pipe(map(([operators, values, valueType]) => {
                return {
                    index: index,
                    operators: operators,
                    values: values,
                    valueType: valueType,
                };
            }))
        )).subscribe(value => {
            this.operators[value.index] = value.operators;
            this.values[value.index] = {
                values: value.values,
                valueType: value.valueType,
            }

            eventConditionsArray.insert(
                value.index,
                this.fb.group({
                    event: value.index === 0 ? new FormControl(conditions[value.index].event, Validators.required) : null,
                    logicOperator: value.index === 0 ? null : new FormControl(conditions[value.index].logicOperator, Validators.required),
                    condition: new FormControl(conditions[value.index].condition, Validators.required),
                    operator: new FormControl(conditions[value.index].operator, Validators.required),
                    value: new FormControl(
                        this.operators[value.index].find((op: any) => op.code === conditions[value.index].operator)?.multiple
                            ? conditions[value.index].value
                            : conditions[value.index].value[0],
                        Validators.required
                    ),
                })
            );

            if (value.index === conditions.length - 1) {
                this.loading = false;
            }
        });

        return {
            eventConditions: eventConditionsArray,
        };
    }

    addCondition(conditionType: any) {
        this.conditions.push(
            this.fb.group({
                logicOperator: new FormControl(conditionType.value, Validators.required),
                condition: new FormControl(null, Validators.required),
                operator: new FormControl(null, Validators.required),
                value: new FormControl(null, Validators.required),
            })
        );
        this.addConditionControl.patchValue(null);
    }

    deleteCondition(index: number) {
        this.conditions.removeAt(index);
    }

    isMultiple(index: number) {
        const conditionForm = this.form.controls['eventConditions'] as any;
        const operatorCode = conditionForm.controls[index].controls['operator'].value;
        if (operatorCode) {
            const operator = this.operators[index].find((op: any) => op.code === operatorCode);
            return operator?.multiple;
        }
        return false;
    }

    getValue(value: any) {
        if (Array.isArray(value)) {
            return value.map(v => v.value);
        }
        return [value];
    }

    private setPredefinedCondition() {
        const eventCode = 'FAILURE';
        const conditionType = 'PIPELINE';
        const predefinedCondition: any = {
            event: eventCode,
            condition: conditionType,
            operator: 'EQ',
            value: [this.pipelineId.toString()],
        };

        forkJoin([
            this.service.getEventConditions(eventCode),
            this.service.getConditionOperators(eventCode, conditionType),
            this.service.getConditionValues(eventCode, conditionType, [predefinedCondition]),
            this.service.getConditionValueType(eventCode, conditionType),
        ]).subscribe(([conditions, operators, values, valueType]) => {
            this.eventConditions = conditions;
            this.operators[0] = operators;
            this.values[0] = {
                values,
                valueType,
            };
            predefinedCondition.value = this.pipelineId.toString();
            this.form.controls['eventConditions'].patchValue([predefinedCondition]);
            this.loading = false;
        });
    }

    private clearOperatorAndValueControls(index: number) {
        const conditionForm = this.form.controls['eventConditions'] as any;
        conditionForm.controls[index].controls['operator'].patchValue(null);
        conditionForm.controls[index].controls['value'].patchValue(null);
        conditionForm.controls[index].controls['operator'].updateValueAndValidity();
        this.cdr.detectChanges();
        conditionForm.controls[index].controls['value'].updateValueAndValidity();
    }

    ngOnDestroy() {
        this.form.removeControl('eventConditions');
    }
}
