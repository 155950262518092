import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from '../../../../../services/Toaster/toaster.service';

@Component({
    selector: 'app-upload-variables-dialog',
    templateUrl: './upload-variables-dialog.component.html',
    styleUrls: ['./upload-variables-dialog.component.scss'],
})
export class UploadVariablesDialogComponent {
    uploadFn: (file: File) => Promise<any>;

    uploading = false;
    constructor(public activeModal: NgbActiveModal, private toastr: ToasterService) {}

    doUpload() {
        const input = document.createElement('input');

        input.accept = '.yml,.yaml';
        input.type = 'file';
        input.onchange = async () => {
            if (!input.files?.length) {
                return;
            }

            const file = input.files[0];
            // 1048576 = 1mb
            if (file.size > 500 * 1048576) {
                this.toastr.errorToast({
                    title: 'Warning',
                    content: `Can't upload ${file.name} because it size is larger than 500mb`,
                });
                return;
            }

            this.uploading = true;
            try {
                await this.uploadFn(file);
                this.activeModal.close();
                this.toastr.successToast({
                    title: 'Success',
                    content: `Variables have been successfully uploaded`,
                });
            } catch (e) {
            } finally {
                this.uploading = false;
            }
        };
        input.click();
    }
}
