import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { GridColumn } from '@dagility-ui/kit';
import { QueryBuilderStore } from '@app/shared/components/query-builder/store/query-builder.store';
import { ModelGraphActionsService } from '@app/shared/components/query-builder/services/model-graph-actions.service';
import { DataSetField } from '@app/shared/components/query-builder/models/model-graph.model';

interface GroupByItem {
    field: string;
    uid: string;
    modelUid: string;
    selected: boolean;
}

@Component({
    selector: 'app-qb-model-group-by-table',
    templateUrl: './group-by-table.component.html',
    styleUrls: ['./group-by-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupByTableComponent implements OnInit {
    gridData: GroupByItem[] = [];

    gridDataSelected: GroupByItem[] = [];

    gridColumns: GridColumn[] = [{ title: 'field' }];
    modelNames: { [key: string]: string } = {};

    constructor(
        private queryBuilderStore: QueryBuilderStore,
        private modelGraphActionsService: ModelGraphActionsService,
        private cdr: ChangeDetectorRef
    ) {}

    @HostListener('document:queryBuilderChanged', ['$event'])
    gridDataFromFields = (): any => {
        const modelGraph = this.queryBuilderStore.modelGraph;

        this.modelNames = modelGraph?.models.reduce((prev: { [key: string]: string }, current) => {
            prev[current.uid] = current.name;
            return prev;
        }, {});

        this.modelGraphActionsService.expressionFields().subscribe(fields => {
            const mapFields = (field: DataSetField) => ({
                field: field.dsFieldName,
                modelUid: field.srcModelUid,
                uid: field.uid,
                selected: false,
            });
            this.gridDataSelected = modelGraph?.dataSetFields?.fields.filter(field => field.groupBy).map(mapFields);

            this.gridData = fields
                .filter(
                    field =>
                        !this.gridDataSelected.find(
                            selected => selected.field === field.dsFieldName && selected.modelUid === field.srcModelUid
                        )
                )
                .map(mapFields);
            this.cdr.detectChanges();
        });
    };

    ngOnInit() {
        this.gridDataFromFields();
    }

    getFieldRefs(items: GroupByItem[]) {
        return items.map(item =>
            item.modelUid
                ? {
                      srcModelUid: item.modelUid,
                      fieldName: item.field,
                  }
                : { dsFieldUid: item.uid }
        );
    }

    handleRemove(item: GroupByItem) {
        const sourceGraph = this.queryBuilderStore.globalModelGraph;
        this.modelGraphActionsService
            .removeGroupByField({ fieldRefs: this.getFieldRefs([item]), sourceGraph })
            .subscribe(result => this.queryBuilderStore.updateState(result));
    }

    handleAdd(item: GroupByItem) {
        const sourceGraph = this.queryBuilderStore.globalModelGraph;
        this.modelGraphActionsService
            .addGroupByField({ fieldRefs: this.getFieldRefs([item]), sourceGraph })
            .subscribe(result => this.queryBuilderStore.updateState(result));
    }

    onSelectRow(item: GroupByItem) {
        item.selected = !item.selected;
    }

    handlePushSelected(gridFrom: GroupByItem[]) {
        const fieldRefs = this.getFieldRefs(gridFrom.filter(item => item.selected));

        const sourceGraph = this.queryBuilderStore.globalModelGraph;
        if (gridFrom === this.gridData) {
            this.modelGraphActionsService
                .addGroupByField({ fieldRefs, sourceGraph })
                .subscribe(result => this.queryBuilderStore.updateState(result));
        } else {
            this.modelGraphActionsService
                .removeGroupByField({ fieldRefs, sourceGraph })
                .subscribe(result => this.queryBuilderStore.updateState(result));
        }
    }

    hasSelectedFields(gridData: GroupByItem[]) {
        return gridData.some(field => field.selected);
    }

    handleCancelSelection(gridData: GroupByItem[]) {
        gridData.forEach(field => (field.selected = false));
    }
}
