<div class="modal-header d-flex flex-column">
    <div class="d-flex w-100 align-items-center">
        <h1 class="mt-0">{{ action.name }}</h1>
        <button
            type="button"
            class="close ml-auto p-0 m-0"
            aria-label="Close button"
            aria-describedby="modal-title"
            (click)="handleCloseClick()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <dp-job-definition-issue-button
        *ngIf="store.formIssues$ | async as formIssues"
        class="mt-2 ml-auto"
        [formIssues]="formIssues"
        [issueType]="store.issueType"
        (issueSelected)="store.goToPathAndValidateControl($event)"
    ></dp-job-definition-issue-button>
</div>
<ng-container *ngIf="store.vm$ | async as vm">
    <form *ngIf="!!store.form && !!vm.formGroup" class="modal-body d-flex flex-column flex-1 overflow-hidden" [formGroup]="store.form">
        <div class="d-flex flex-column px-1" style="width: 98%;" *ngIf="vm.path.length === 0">
            <lib-input
                class="mb-20px"
                label="Name"
                id="workflow"
                formControlName="name"
                [customErrors]="{
                    unique: 'This field should be unique'
                }"
            ></lib-input>
            <lib-dropdown
                [class.mb-20px]="isCondition"
                label="Next"
                id="next"
                [items]="nextBlockNames"
                formControlName="next"
            ></lib-dropdown>
            <lib-dropdown
                *ngIf="isCondition"
                label="Else next"
                id="else-next"
                [items]="elseNextBlockNames"
                formControlName="elseNext"
            ></lib-dropdown>
        </div>
        <lib-codemirror-editor
            *ngIf="isGroovy"
            class="flex-1 mt-25px codemirror-editor"
            label="Script"
            mode="text/x-groovy"
            formControlName="script"
        ></lib-codemirror-editor>
        <ng-container *ngIf="!isGroovy">
            <ng-container *ngIf="!vm.activeField.isCollection">
                <ng-container [ngSwitch]="true">
                    <dp-edit-jd-value
                        *ngSwitchCase="vm.activeField.field | mapper: isJDValue"
                        class="flex-1 overflow-hidden d-flex flex-column"
                        [definition]="vm.activeField.field"
                        [meta]="vm.activeField.meta"
                        [formSlice]="vm.formGroup"
                    ></dp-edit-jd-value>

                    <dp-edit-operand-action
                        *ngSwitchCase="vm.activeField.field | mapper: isOperand"
                        class="flex-1 overflow-hidden d-flex flex-column"
                        [definition]="vm.activeField.field"
                        [meta]="vm.activeField.meta"
                        [formSlice]="vm.formGroup"
                    ></dp-edit-operand-action>

                    <dp-edit-query-action
                        *ngSwitchCase="vm.activeField.field | mapper: isQuery"
                        class="flex-1 overflow-hidden d-flex flex-column"
                        [definition]="vm.activeField.field"
                    ></dp-edit-query-action>

                    <dp-edit-job-definition-template
                        *ngSwitchDefault
                        class="flex-1 overflow-hidden"
                        [definition]="vm.activeField.field"
                        [meta]="vm.activeField.meta"
                        isFirstLevel="true"
                        [formSlice]="vm.formGroup"
                        [key]="vm.lastStep"
                        [updatingRequest]="store.updatingRequest | async"
                    ></dp-edit-job-definition-template>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="vm.activeField.isCollection">
                <ng-container *ngIf="vm.path.length > 1" [formGroup]="vm.formSliceParent">
                    <dp-edit-job-definition-iterative-template
                        class="col overflow-hidden pr-0"
                        [definition]="vm.activeField.field"
                        [meta]="vm.activeField.meta"
                        [key]="vm.lastStep"
                        [formSlice]="vm.formGroup"
                        [isFirstLevel]="true"
                        [parent]="vm.jdParent"
                    ></dp-edit-job-definition-iterative-template>
                </ng-container>
                <dp-edit-job-definition-iterative-template
                    class="col overflow-hidden pr-0"
                    *ngIf="vm.path.length == 1"
                    [definition]="vm.activeField.field"
                    [meta]="vm.activeField.meta"
                    [key]="vm.lastStep"
                    [formSlice]="vm.formGroup"
                    [isFirstLevel]="true"
                    [parent]="vm.jdParent"
                ></dp-edit-job-definition-iterative-template>
            </ng-container>
        </ng-container>
    </form>
</ng-container>
<div class="modal-footer mt-auto d-flex">
    <button class="btn  btn-secondary" (click)="handleDelete()">Delete action</button>
    <button class="btn  btn-secondary ml-auto mr-4" (click)="handleCloseClick()">Close</button>
    <button class="btn  btn-primary" (click)="handleSave()">Save</button>
</div>
