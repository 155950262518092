<ng-container formArrayName="tiles">
    <h3 class="mt-0">Tiles</h3>

    <span class="btn-link cursor-pointer my-3 d-block" (click)="addTile()">+ Add tile</span>

    <div class="mb-3 border-bottom" *ngFor="let tileControl of tiles.controls; let i = index; let last = last" [formGroupName]="i">
        <div class="row align-items-end mb-3">
            <lib-input class="col-10" label="Column" formControlName="column"></lib-input>
            <checkbox class="mr-3 mb-1 ml-3 mt-2" formControlName="progress">Rating</checkbox>

            <fa-icon
                icon="facTrash"
                class="delete-icon mb-1 mr-3 ml-auto"
                [ngbTooltip]="'Delete Tile'"
                placement="top"
                [openDelay]="800"
                container="body"
                (click)="removeTile(i)"
            ></fa-icon>
        </div>
        <lib-input class="mb-3" label="Header" formControlName="header"></lib-input>

        <h2 class="mt-0">Ratings</h2>
        <span class="btn-link cursor-pointer my-3 d-block" (click)="addRating($any(tileControl))">+ Add Rating</span>
        <ng-container formArrayName="ratings">
            <div
                class="row align-items-start mb-3"
                *ngFor="let ratingControl of tileControl['controls'].ratings.controls; let first = first; let j = index"
                [formGroup]="ratingControl"
            >
                <lib-input
                    class="col-2"
                    formControlName="rating"
                    [label]="first ? 'Rating' : null"
                    [customErrors]="{ duplicateRating: 'Rating should be unique' }"
                ></lib-input>
                <lib-input class="col-6" formControlName="info" [label]="first ? 'Tooltip Info' : null"></lib-input>
                <dp-widget-color-picker class="col-3" formControlName="color" [label]="first ? 'Color' : null"></dp-widget-color-picker>

                <fa-icon
                    icon="facTrash"
                    [class.mt-3]="first"
                    class="mr-3 ml-auto delete-icon"
                    [ngbTooltip]="'Delete Rating'"
                    placement="top"
                    [openDelay]="800"
                    container="body"
                    (click)="removeRating(j, $any(tileControl))"
                ></fa-icon>
            </div>
        </ng-container>
    </div>
</ng-container>
