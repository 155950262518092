import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
//#region external modules
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ColorPickerModule } from 'ngx-color-picker';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
//#region shared app modules
import { SharedComponentsModule } from '@dagility-ui/shared-components';
import { CronEditorModule } from '@app/shared/modules/cron-editor/cron-editor.module';

//#region components
import { OrganizationLogoModule } from './components/organization-logo/organization-logo.component';
import { CheckboxGroupComponent } from './components/checkbox-group/checkbox-group.component';
import { InfoMessageComponent } from './components/info-message/info-message.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { TreeViewItemComponent } from './components/tree-view-list/tree-view-item/tree-view-item.component';
import { TreeViewListComponent } from './components/tree-view-list/tree-view-list.component';
import { UserEditFormComponent } from './components/user-edit-form/user-edit-form.component';
import { RelationshipLegendModule } from './components/relationship-legend/relationship-legend.component';
import { DummyComponent } from './services/admin/first-login.guard';
import { TraceabilityChartComponent } from './components/traceability-chart/traceability-chart.component';

//#region directives
import { IfFeatureEnabledDirective } from './directives/if-feature-enabled.directive';
import { IfHasRightDirective } from './directives/if-has-right.directive';
import { IsAdminDirective } from './directives/is-admin.directive';
import { ResizeObserverDirective } from '@app/shared/directives/resize-observer.directive';

//#region pipes
import { NumberOfProjectsPipe } from '@app/shared/pipes/number-of-projects.pipe';
import { FileSizePipe } from '@app/shared/pipes/file-size.pipe';
import { dateFormatPipe } from './pipes/date-formate.pipe';
import { ElapsedTimePipe } from './pipes/elapsed-time.pipe';

import { ModalLoadingComponent } from '@app/shared/components/modal-loading/modal-loading.component';

import { WorkflowsPipelinesComponent } from './components/workflows-pipelines/workflows-pipelines.component';
import { ApplicationScoreModule } from '@app/shared/modules/application-scores/application-score.module';
import { WorkflowsLogsComponent } from './components/workflows-logs/workflows-logs.component';
import { NestedSplitButtonComponent } from './components/nested-split-button/nested-split-button.component';
import { WorkflowDashboardPipelineListComponent } from '@app/shared/components/workflow-pipeline-list/workflow-pipeline-list.component';
import { ChartNodeComponent } from './components/traceability-chart/chart-node/chart-node.component';
import { NodeDetailsComponent } from './components/traceability-chart/node-details/node-details.component';
import { PublishModalComponent } from './components/publish-modal/publish-modal.component';
import { UpdateTemplateModalComponent } from './components/update-template-modal/update-template-modal.component';
import { LogDeployedApplicationComponent } from '@app/shared/components/log-deployed-applications/log-deployed-application.component';
import { NoDataComponent } from './components/no-data/no-data.component';
import { WorkflowsDashboardComponent } from './components/workflows-dashboard/workflows-dashboard.component';
import { PortfolioScoresModule } from './modules/portfolio-scores/portfolio-scores.module';
import { FileEditComponent } from '../pages/ci-cd/template-editor/template-editor-sub-menu/component-edit/component-app-sources/files-grid/file-edit/file-edit.component';
import { FilesGridComponent } from '../pages/ci-cd/template-editor/template-editor-sub-menu/component-edit/component-app-sources/files-grid/files-grid.component';
import { PublishComponentChangesDialogComponent } from '../pages/ci-cd/template-editor/template-editor-sub-menu/component-edit/component-app-sources/publish-component-changes-dialog/publish-component-changes-dialog.component';
import { PublishComponentChangesProgressComponent } from '../pages/ci-cd/template-editor/template-editor-sub-menu/component-edit/component-app-sources/publish-component-changes-dialog/publish-component-changes-progress/publish-component-changes-progress.component';
import { PublishComponentChangesDetailsComponent } from '../pages/ci-cd/template-editor/template-editor-sub-menu/component-edit/component-app-sources/publish-component-changes-dialog/publish-component-changes-details/publish-component-changes-details.component';
import { FilesGridPathComponent } from '../pages/ci-cd/template-editor/template-editor-sub-menu/component-edit/component-app-sources/files-grid/files-grid-path/files-grid-path.component';
import { NewFolderModalComponent } from '../pages/ci-cd/template-editor/template-editor-sub-menu/component-edit/component-app-sources/files-grid/new-folder-modal/new-folder-modal.component';
import { TagsComponent } from '../pages/project/project-create/template-selection/shared/tags/tags.component';
import { GeneratedScriptModalComponent } from './components/generated-script-modal/generated-script-modal.component';
import { GeneratedScriptComponent } from './components/generated-script-modal/generated-script/generated-script.component';
import { ProjectFilterButtonComponent } from '@app/shared/components/project-filter-button/project-filter-button.component';
import { ProgressiveLogComponent } from './components/progressive-log/progressive-log.component';
import { RepositoryExistingModalComponent } from '@app/shared/components/repository-existing-modal/repository-existing-modal.component';
import { ActionPropertyEditorModule } from '@app/shared/components/action-property-editor/action-property-editor.module';
import { CommitMessageModalComponent } from '@app/shared/components/commit-message-modal/commit-message-modal.component';
import { CKEditorModule } from 'ngx-ckeditor';
import { CountDownComponent } from './components/count-down/count-down.component';
import { NgbNavStatusDirective } from '@app/shared/directives/ngb-nav-status.directive';
import { VocFormModule } from './modules/voc-form/voc-form.module';
import { QueryBuilderComponent } from './components/query-builder/query-builder.component';
import { WidgetLibraryModule } from 'data-processor';
import { QueryBuilderLibraryModule } from '@app/shared/components/query-builder/query-builder-library.module';
import { AlertListModalModule } from '../pages/insights/dp-extensions/alert-list-modal/alert-list-modal.component';
import { PipelineListModule } from '@app/shared/components/pipeline-list/pipeline-list.module';
import { CustomDashboardTooltipComponent } from './components/custom-dashboard-tooltip/custom-dashboard-tooltip.component';
import { DeepAutoFocus } from '@app/shared/directives/deep-auto-focus.directive';

const modules = [
    ApplicationScoreModule,
    ColorPickerModule,
    RouterModule,
    PerfectScrollbarModule,
    DragDropModule,
    SharedComponentsModule,
    RelationshipLegendModule,
    PortfolioScoresModule,
    CronEditorModule,
    ActionPropertyEditorModule,
    CKEditorModule,
    OrganizationLogoModule,
    VocFormModule,
    WidgetLibraryModule,
    QueryBuilderLibraryModule,
    AlertListModalModule,
    PipelineListModule,
];
const components = [
    TreeViewListComponent,
    ConfirmationDialogComponent,
    CheckboxGroupComponent,
    UserEditFormComponent,
    InfoMessageComponent,
    DummyComponent,
    WorkflowsPipelinesComponent,
    WorkflowDashboardPipelineListComponent,
    WorkflowsDashboardComponent,
    LogDeployedApplicationComponent,
    ModalLoadingComponent,
    TraceabilityChartComponent,
    NoDataComponent,
    FileEditComponent,
    FilesGridComponent,
    PublishComponentChangesDialogComponent,
    PublishComponentChangesProgressComponent,
    PublishComponentChangesDetailsComponent,
    FilesGridPathComponent,
    NewFolderModalComponent,
    TagsComponent,
    GeneratedScriptComponent,
    GeneratedScriptModalComponent,
    ProjectFilterButtonComponent,
    RepositoryExistingModalComponent,
    ProgressiveLogComponent,
    CommitMessageModalComponent,
    CountDownComponent,
    CustomDashboardTooltipComponent,
    NestedSplitButtonComponent,
    QueryBuilderComponent,
];
const pipes = [NumberOfProjectsPipe, FileSizePipe, dateFormatPipe, ElapsedTimePipe];
const directives = [
    IfHasRightDirective,
    IsAdminDirective,
    IfHasRightDirective,
    IfFeatureEnabledDirective,
    DeepAutoFocus,
    NgbNavStatusDirective,
    ResizeObserverDirective,
];

@NgModule({
    declarations: [
        ...components,
        ...pipes,
        ...directives,
        TreeViewItemComponent,
        WorkflowsLogsComponent,
        ChartNodeComponent,
        NodeDetailsComponent,
        PublishModalComponent,
        UpdateTemplateModalComponent,
        ProgressiveLogComponent,
        CustomDashboardTooltipComponent,
    ],
    imports: [...modules],
    exports: [...modules, ...components, ...pipes, ...directives],
})
export class SharedModule {}
