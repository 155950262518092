export type TemplateEditorItemType = 'details' | 'components' | 'appSources' | 'category' | 'pipelines' | 'workflows';

export interface IMenuItemTag {
    id: number;
    caption: string;
    additionalInfo: string;
}

export class MenuItem {
    id?: string;
    label: string;
    itemType: TemplateEditorItemType;
    items?: MenuItem[];
    data?: any;
    routerLink: string;
    parent?: MenuItem;
    isLoaded?: boolean;
    isCategory?: boolean;
    loading: boolean;
    show: boolean;
    tag: IMenuItemTag;
    isNew: boolean;
    readOnly?: boolean;
    tabs?: any;

    constructor(
        id?: string,
        label: string = '',
        itemType: TemplateEditorItemType = null,
        data: any = null,
        routerLink: string = '',
        items: MenuItem[] = null,
        parent: MenuItem = null,
        isLoaded: boolean = false,
        isCategory: boolean = false,
        show: boolean = true,
        tag?: IMenuItemTag,
        isNew: boolean = false,
        readOnly?: boolean
    ) {
        this.id = id;
        this.label = label;
        this.itemType = itemType;
        this.data = data;
        this.routerLink = routerLink;
        this.items = items;
        this.parent = parent;
        this.isLoaded = isLoaded;
        this.isCategory = isCategory;
        this.loading = false;
        this.show = show;
        this.tag = tag ? tag : { id: null, caption: '', additionalInfo: '' };
        this.isNew = isNew;
        this.readOnly = readOnly;
    }
}

export interface TemplateEditorFilter {
    label: string;
    tagId: number;
    tagCaption: string;
    tagAdditionalInfo: string;
}
