import { GridColumn } from '@dagility-ui/kit';
import { CiCdFieldDescription, CiCdFieldType } from '../../../model/fields.model';

export const FIELD_TYPE_ITEMS: CiCdFieldType[] = ['STRING', 'NUMBER', 'BOOLEAN', 'GROOVY'];

export const fieldGridColumns: GridColumn[] = [
    { title: 'Name', field: 'name', width: '13%', minWidth: '13%' },
    { title: 'Type', field: 'type', width: '10%', minWidth: '10%' },
    { title: 'Default Value', field: 'defaultValue', minWidth: '10%' },
    { title: 'Embedded', field: 'embedded', width: '10%', minWidth: '10%', hidden: true },
    { title: 'Required', field: 'mandatory', width: '10%', minWidth: '10%' },
    { title: 'Is Array', field: 'array', width: '10%', minWidth: '10%' },
    { title: 'Runtime', field: 'runtime', width: '10%', minWidth: '10%' },
    { title: 'Picklist Items', field: 'options', minWidth: '10%' },
    { title: '', width: '5%', minWidth: '5%' },
];

export const optionsGridColumns: GridColumn[] = [
    { title: 'Label', field: 'label', sortingField: 'label', sortAsc: true, width: '45%' },
    { title: 'Value', field: 'value', sortingField: 'value', sortAsc: true, width: '45%' },
    { title: '', width: '10%' },
];

export const DEFAULT_FIELD_DESCRIPTOR: CiCdFieldDescription = {
    name: '',
    label: '',
    type: 'STRING',
    defaultValue: '',
    description: '',
    embedded: false,
    mandatory: false,
    array: false,
    runtime: false,
    options: [],
};
