import { NgModule } from '@angular/core';

import { SharedComponentsModule } from '@dagility-ui/shared-components';

import { DebugVariablesComponent } from './debug-variables/debug-variables.component';
import { DebugNewWatchComponent } from './debug-variables/debug-new-watch/debug-new-watch.component';

@NgModule({
    declarations: [DebugVariablesComponent, DebugNewWatchComponent],
    imports: [SharedComponentsModule],
    exports: [DebugVariablesComponent, DebugNewWatchComponent],
})
export class DebugVariablesModule {}
