import { Directive, inject, Input, Provider } from '@angular/core';

import { DpGlobalFiltersService } from '../../dashboard/services/global-filters.service';
import { WidgetEventHandler, WidgetFilter } from '../models/any-widget.model';
import { WidgetWorkflow } from '../services/widget.flow';
import { GlobalFiltersWorkflow } from '../services/global-filters.flow';
import { ClientWidgetScriptExecutor, ConsoleLogger, WidgetLogger, WidgetScriptExecutor } from '../services/widget-script.executor';
import { WidgetDebuggerState } from '../services/widget.debugger';
import { addTimeZonePlaceholderFactory } from '../providers/timezone-placeholders.provider';
import { DEFAULT_PLACEHOLDERS } from '../providers/default-placeholders.provider';
import { createFakeWidget } from '../services/widget-builder.util';

export const GLOBAL_FILTER_PROVIDERS: Provider[] = [
    {
        provide: WidgetWorkflow,
        useClass: GlobalFiltersWorkflow,
    },
    {
        provide: WidgetLogger,
        useClass: ConsoleLogger,
    },
    { provide: WidgetScriptExecutor, useClass: ClientWidgetScriptExecutor },
    { provide: DEFAULT_PLACEHOLDERS, useFactory: addTimeZonePlaceholderFactory },
    {
        provide: WidgetDebuggerState,
        useFactory: () => inject(WidgetDebuggerState, { skipSelf: true, optional: true }) ?? new WidgetDebuggerState(),
    },
];

@Directive()
export class DashboardFilterSetController {
    @Input() filters: WidgetFilter[];
    @Input() dashboardId: number;
    @Input() dynamicDashboard: boolean;
    @Input() handlers: WidgetEventHandler[];
    @Input() edit = false;

    workflow = inject(WidgetWorkflow) as GlobalFiltersWorkflow;
    protected debuggerState = inject(WidgetDebuggerState);
    protected store = inject(DpGlobalFiltersService, { optional: true });

    protected initServerFilters() {
        this.workflow.id = this.dashboardId;
        this.workflow.dynamicDashboard = this.dynamicDashboard;
        this.workflow.widgetDebugger = this.debuggerState;

        return this.workflow.init(
            createFakeWidget({
                filters: this.filters,
                name: '',
                handlers: this.handlers || [],
                server: true,
                complexNamedDropdown: null,
                complexNamedDropdownDependencies: null,
                complexNamedDropdownFilterDependency: null,
                complexNamedDropdownLabel: null,
            }),
            {},
            this.store?.defaultFiltersValues ?? {}
        );
    }
}
