import { Component, Input, OnInit, Inject, Injector } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { faChevronDown, faChevronRight, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { noop } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import {
    EnvironmentModel,
    GridColumn,
    loadFile,
    ModalConfirmComponent,
    Pageable,
    readFileToObject,
    toJSONBlob,
    writeContents,
} from '@dagility-ui/kit';
import { ToasterService } from '@dagility-ui/shared-components';
import { CustomIcon } from '@dagility-ui/shared-components/icons';

import { Plugin, Tool, RunningStatus } from 'data-processor';

import { PluginUtils, ProcessorMonitoringState } from '../../processor-monitoring-state.service';
import { ViewJobDefinitionLogFormComponent } from '../../view-job-definition-log-form/view-job-definition-log-form.component';
import { CreateNewJobDefinitionFormComponent } from '../create-new-job-definition-form/create-new-job-definition-form.component';
import { JobSetConfirmFormComponent } from '../job-set-confirm-form/job-set-confirm-form.component';
import { JobSetFormComponent } from '../job-set-form/job-set-form.component';
import { ProcessorMonitoringService } from '../../processor-monitoring.service';
import { JobSet } from '../../../models/processor-monitoring/job-set.model';
import { JobDefinitionStartFormComponent } from '../job-definition-start-form/job-definition-start-form.component';
import { DATA_MORPH_SEGMENT, JOB_SETS_SEGMENT } from '../../../data-morph-routing.constants';
import { removeOldJDFields } from 'data-processor/lib/processor-monitoring/job-definition.lines';
import { prettifyObject } from 'data-processor/lib/common';

@Component({
    selector: 'dp-tool-tree-node',
    templateUrl: './tool-tree-node.component.html',
    styleUrls: ['./tool-tree-node.component.scss'],
})
export class ToolTreeNodeComponent implements OnInit {
    @Input() tool: Tool;
    @Input() isExpanded: boolean;

    icons: Record<string, IconDefinition | CustomIcon> = {
        faChevronDown,
        faChevronRight,
    };

    pageable: Pageable;

    pluginGridColumns: GridColumn[] = [
        {
            title: "Job's Name",
            field: 'instanceName',
            sortingField: 'instanceName',
            width: '39%',
        },
        {
            title: 'Running Type',
            field: 'runningType',
            sortingField: 'runningType',
            width: '15%',
        },
        {
            title: 'Status',
            field: 'runningStatusBinary',
            sortingField: 'runningStatusBinary',
            width: '14%',
        },
        {
            title: 'State',
            field: 'runningStatus',
            sortingField: 'runningStatus',
            width: '15%',
        },
        {
            title: 'Actions',
            width: '15%',
        },
    ];

    baseUrl = !!(this.environment as any).component ? `../${DATA_MORPH_SEGMENT}/${JOB_SETS_SEGMENT}/` : './';

    constructor(
        public pmState: ProcessorMonitoringState,
        private modalService: NgbModal,
        private toaster: ToasterService,
        private router: Router,
        private route: ActivatedRoute,
        private pmService: ProcessorMonitoringService,
        @Inject('environment') private environment: EnvironmentModel
    ) {}

    ngOnInit() {
        this.pageable = new Pageable(0, 0, [{ direction: 'ASC', property: 'instanceName' }]);
    }

    ngOnChanges() {
        this.handleSortAfterChange();
    }

    handleExport(event: Event) {
        if (!!this.tool.jobSet) {
            event.preventDefault();
            event.stopImmediatePropagation();

            const fileName = `${this.tool.jobSet.name}.json`;

            this.pmService
                .exportJobSet(this.tool.jobSetId)
                .pipe(
                    map(jobSet => {
                        jobSet.jobDefinitions = (jobSet.jobDefinitions || []).sort((a, b) => a.instanceName.localeCompare(b.instanceName));
                        jobSet.jobDefinitions = (jobSet.jobDefinitions || []).map(jd => {
                            removeOldJDFields(jd?.actions ?? []);
                            jd.id = null;
                            jd.jobSetId = null;

                            return prettifyObject(jd, false);
                        });

                        return jobSet;
                    }),
                    map(toJSONBlob)
                )
                .subscribe(blob => {
                    writeContents(blob, fileName);
                });
        }
    }

    handleImport(event: Event) {
        event.preventDefault();
        event.stopImmediatePropagation();

        let jobSetFile: File;
        const readJobSetName = () =>
            new Promise((resolve, reject) => {
                const modalRef = this.modalService.open(JobSetConfirmFormComponent, {
                    centered: true,
                    backdrop: 'static',
                    windowClass: '',
                    size: 'lg',
                });
                modalRef.componentInstance.action = 'import';
                modalRef.componentInstance.title = 'Import job set';
                modalRef.componentInstance.setName = jobSetFile.name.includes('.')
                    ? jobSetFile.name.substr(0, jobSetFile.name.lastIndexOf('.'))
                    : jobSetFile.name;
                modalRef.result.then(name => resolve([name, jobSetFile])).catch(reject);
            });

        loadFile('application/JSON')
            .then(fileList => fileList.item(0))
            .then(file => {
                jobSetFile = file;

                return readFileToObject(file);
            })
            .then(
                jobSetObj =>
                    new Promise((resolve, reject) => {
                        if (jobSetObj.pluginType !== this.tool.pluginType) {
                            reject();
                        }

                        resolve(jobSetFile);
                    })
            )
            .then(readJobSetName)
            .then(([name, file]) => this.pmService.importJobSet(name, file).toPromise())
            .then(() =>
                this.toaster.successToast({
                    title: 'Success',
                    content: 'Job Set imported successfully',
                })
            )
            .catch(() => {
                this.toaster.warningToast({
                    title: 'Job Set was not imported',
                    content: 'Plugin types are not compatible',
                });
            });
    }

    handleSwap(event: Event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        const modalRef = this.modalService.open(JobSetFormComponent, {
            centered: true,
            backdrop: 'static',
            windowClass: '',
            size: 'lg',
        });
        modalRef.componentInstance.action = 'swap';
        modalRef.componentInstance.title = 'Swap instance';
        modalRef.componentInstance.toolType = this.tool.pluginType;
        modalRef.componentInstance.selectedSetId = !!this.tool.jobSet ? this.tool.jobSet.id : null;

        let selectedJobSet: JobSet;

        modalRef.result
            .then((jobSet?: JobSet) => {
                selectedJobSet = jobSet;

                return this.pmService.changeActiveJobSet(!!jobSet ? jobSet.id : 0, this.tool.id).toPromise();
            })
            .then(() => {
                this.pmState.updateToolJobSet(this.tool.id, selectedJobSet);
                this.pmState.needFullUpdate();
            })
            .catch(noop);
    }

    handleSaveNewSet(event: Event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        const modalRef = this.modalService.open(JobSetConfirmFormComponent, {
            centered: true,
            backdrop: 'static',
            windowClass: '',
            size: 'lg',
        });
        modalRef.componentInstance.action = 'newSet';
        modalRef.componentInstance.title = 'New job set';
        modalRef.result
            .then(name => this.pmService.createJobSet(name, this.tool.pluginType).toPromise())
            .then(() =>
                this.toaster.successToast({
                    title: 'Success',
                    content: '',
                })
            )
            .catch(noop);
    }

    handleDuplicate(event: Event, tool: Tool) {
        event.preventDefault();
        event.stopImmediatePropagation();
        const modalRef = this.modalService.open(JobSetConfirmFormComponent, {
            centered: true,
            backdrop: 'static',
            windowClass: '',
            size: 'lg',
        });
        modalRef.componentInstance.action = 'duplicate';
        modalRef.componentInstance.message = 'Instance job set ' + tool.jobSet.name;
        modalRef.componentInstance.title = 'Duplicate job set';
        modalRef.result
            .then(name => this.pmService.createFromExisting(tool.jobSet.id, name).toPromise())
            .then(() => this.toaster.successToast({ title: 'Success', content: '' }))
            .catch(noop);
    }

    handleSortAfterChange() {
        if (this.pageable && this.pageable.orders.length) {
            this.tool.plugins = PluginUtils.sortPlugins(this.tool.plugins, [...this.pageable.orders]);
        }
    }

    handleSortChange(tool: Tool, sortedField: string) {
        this.pageable.sortOneField(sortedField);
        tool.plugins = PluginUtils.sortPlugins(tool.plugins, [...this.pageable.orders]);
    }

    handleAddJob(event: Event, tool: Tool) {
        event.preventDefault();
        event.stopImmediatePropagation();
        const modalRef = this.modalService.open(CreateNewJobDefinitionFormComponent, {
            centered: true,
            backdrop: 'static',
            windowClass: '',
            size: 'lg',
        });
        modalRef.componentInstance.tool = tool;
    }

    openJd(plugin: Plugin) {
        this.router.navigate([`${this.baseUrl}${plugin.id}`], {
            relativeTo: this.route,
            state: {
                readonly: !this.tool.jobSet,
                toolId: plugin.toolId,
                defaultJobSet: this.tool.jobSet.default,
            },
        });
    }

    openGraph(tool: Tool, event: Event) {
        event.preventDefault();
        event.stopImmediatePropagation();

        this.router.navigate([this.baseUrl, 'graph', tool.toolId], {
            relativeTo: this.route,
        });
    }

    handleViewPluginLogs(plugin: Plugin) {
        const modalRef = this.modalService.open(ViewJobDefinitionLogFormComponent, {
            centered: true,
            backdrop: 'static',
            windowClass: 'widget-modal',
        });
        modalRef.componentInstance.plugin = plugin;
    }

    changePluginState(plugin: Plugin, pluginStatus: RunningStatus) {
        const modalRef = this.modalService.open(ModalConfirmComponent, {
            centered: true,
            backdrop: 'static',
        });

        if (pluginStatus === 'INACTIVE') {
            modalRef.componentInstance.confirmButtonText = 'Start';
            modalRef.componentInstance.message = {
                title: `Start job`,
                content: `Are you sure you want to start '${plugin.instanceName}'?`,
            };
            modalRef.result
                .then(() => this.pmService.startJobDefinition(plugin.id).toPromise())
                .catch(() => {
                    /* rejected */
                });
        } else {
            modalRef.componentInstance.message = {
                title: `Stop Job`,
                content: `Are you sure you want to stop '${plugin.instanceName}'?`,
            };
            modalRef.componentInstance.confirmButtonText = 'Stop';
            modalRef.componentInstance.additionalButtonText = 'Stop immediately';
            modalRef.componentInstance.showAdditionalButton = true;

            modalRef.componentInstance.confirmOk.pipe(switchMap(() => this.pmService.stopJobDefinition(plugin.id))).subscribe(() => {
                /* rejected */
            });
            modalRef.componentInstance.additionalBtnClicked
                .pipe(switchMap(() => this.pmService.killJobDefinition(plugin.id)))
                .subscribe(noop);
        }
    }

    handleDeleteJD(plugin: Plugin) {
        const modalRef = this.modalService.open(ModalConfirmComponent, {
            centered: true,
            backdrop: 'static',
        });

        modalRef.componentInstance.confirmButtonText = 'Delete';
        modalRef.componentInstance.message = {
            title: `Delete job`,
            content: `Are you sure you want to delete '${plugin.instanceName}'?`,
        };
        modalRef.componentInstance.confirmOk.subscribe((result: any) => {
            if (result) {
                this.pmService.deleteJobDefinition(plugin.id).subscribe(() => {
                    this.toaster.successToast({ title: 'Success', content: '' });
                    this.pmState.needFullUpdate();
                });
            }
        });
    }

    handleExportJD(plugin: Plugin) {
        const fileName = `${plugin.instanceName}.json`;

        this.pmService
            .getJobDefinition(plugin.id.toString())
            .pipe(
                map(jd => ({
                    ...jd,
                    id: null,
                    jobSetId: null,
                })),
                map(jd => {
                    removeOldJDFields(jd?.actions || []);

                    return prettifyObject(jd, false);
                }),
                map(toJSONBlob)
            )
            .subscribe(blob => {
                writeContents(blob, fileName); // file extension
            });
    }

    handleImportJD(event: Event) {
        event.preventDefault();
        event.stopImmediatePropagation();

        const setInstanceNameAndSetId = (jd: any) =>
            new Promise<any>((resolve, reject) => {
                const modalRef = this.modalService.open(JobSetConfirmFormComponent, {
                    centered: true,
                    backdrop: 'static',
                    windowClass: '',
                    size: 'lg',
                });
                modalRef.componentInstance.action = 'newJob';
                modalRef.componentInstance.title = 'Import job';
                modalRef.componentInstance.isJD = true;
                modalRef.componentInstance.action = 'import';

                modalRef.result
                    .then(name => {
                        jd.instanceName = name;
                        jd.jobSetId = this.tool.jobSetId;

                        resolve(jd);
                    })
                    .catch(noop);
            });

        loadFile('application/JSON')
            .then(fileList => readFileToObject(fileList[0]))
            .then(setInstanceNameAndSetId)
            .then(jd => this.pmService.createJobDefinition(jd).toPromise())
            .then(() => this.pmState.needFullUpdate())
            .catch(e => {
                if (e) {
                    this.toaster.errorToast({ title: 'Error', content: typeof e === 'string' ? e : '' });
                }
            });
    }

    handleDeleteJobSet(event: Event) {
        if (this.tool.jobSetId != null) {
            event.preventDefault();
            event.stopImmediatePropagation();

            const modalRef = this.modalService.open(ModalConfirmComponent, {
                centered: true,
                backdrop: 'static',
            });

            modalRef.componentInstance.confirmButtonText = 'Delete';
            modalRef.componentInstance.message = {
                title: `Delete job set`,
                content: `Are you sure you want to delete ${this.tool.jobSet.name}?`,
            };
            modalRef.result
                .then(() => this.pmService.deleteJobSet(this.tool.jobSet.id).toPromise())
                .then(() => {
                    this.toaster.successToast({ title: 'Success', content: '' });
                    this.pmState.deleteJobSet(this.tool.jobSet.id);
                    this.pmState.needFullUpdate();
                })
                .catch(noop);
        }
    }

    handleForceRun(plugin: Plugin) {
        const modalRef = this.modalService.open(JobDefinitionStartFormComponent, {
            centered: true,
            backdrop: 'static',
            windowClass: '',
            size: 'lg',
            injector: Injector.create({
                providers: [
                    {
                        provide: ProcessorMonitoringState,
                        useValue: this.pmState,
                    },
                ],
            }),
        });
        modalRef.componentInstance.job = plugin;
    }

    trackByFn(index: number, item: Plugin) {
        return item.id;
    }

    itemGuard(item: any): Plugin {
        return item;
    }
}
