<ng-container *ngIf="isHiddenNode && root">
    <ng-container *ngIf="!hasChildNodes; else noDraggable">
        <ng-container [ngSwitch]="isDisabledNode(root)">
            <ng-container *ngSwitchCase="false">
                <div
                    [ngbTooltip]="root.data.description"
                    [openDelay]="800"
                    #modelItem
                    *ngIf="matchSearchPattern()"
                    (click)="dragModelStartTestMode(root, modelItem)"
                    (dragstart)="dragModelStart(root, $event, modelItem)"
                    draggable="true"
                    class="cursor-pointer d-flex align-items-center justify-content-between mb-8px"
                >
                    <div style="width: calc(100% - 10px)" class="d-flex align-items-center justify-content-start">
                        <app-icon-remoter [width]="12" height="12" [color]="'WHITE'" [iconName]="'TABLE'" *ngIf="!hasChildNodes && !isUserDefined"></app-icon-remoter>
                        <app-icon-remoter [width]="12" height="12" [color]="'WHITE'" [iconName]="'USER'" *ngIf="!hasChildNodes && isUserDefined"></app-icon-remoter>
                        <div style="overflow: hidden; text-overflow: ellipsis; white-space: pre; font-size: 12px" class="ml-5px">
                            {{ rootText }}
                        </div>
                    </div>
                    <div style="width: 10px; height: 10px; margin-top: -8px;">
                        <app-icon-remoter (click)="deleteCustomModel.emit(root.data.uid)" *ngIf="isUserDefined" style="cursor: pointer;" [color]="'WHITE'" [width]="12" [height]="12" [iconName]="'DELETE'"></app-icon-remoter>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="true">
                <div
                    *ngIf="matchSearchPattern()"
                    draggable="false"
                    class="cursor-default d-flex align-items-center justify-content-between mb-8px"
                >
                    <div style="width: calc(100% - 10px)" class="d-flex align-items-center justify-content-start">
                        <app-icon-remoter [width]="12" height="12" [color]="'WHITE'" [iconName]="'TABLE'" *ngIf="!hasChildNodes && !isUserDefined"></app-icon-remoter>
                        <app-icon-remoter [width]="12" height="12" [color]="'WHITE'" [iconName]="'USER'" *ngIf="!hasChildNodes && isUserDefined"></app-icon-remoter>
                        <div style="overflow: hidden; text-overflow: ellipsis; white-space: pre; font-size: 12px" class="ml-5px">
                            {{ rootText }}
                        </div>
                    </div>
                    <div style="width: 10px; height: 10px; margin-top: -8px;">
                        <app-icon-remoter (click)="onClick(root.data.uid)" *ngIf="isUserDefined" style="cursor: pointer;" [color]="'WHITE'" [width]="12" [height]="12" [iconName]="'DELETE'"></app-icon-remoter>
                    </div>
                </div>
            </ng-container>
        </ng-container>

    </ng-container>
    <div style="margin-left: 20px; margin-right: 10px;" *ngIf="showChildNodes">
        <app-qb-model-graph-tree *ngFor="let rootNode of nextNodes" (deleteCustomModel)="onClickChild($event)" [search]="search" [root]="rootNode"></app-qb-model-graph-tree>
    </div>
</ng-container>
<ng-template #noDraggable>
    <div (click)="toggleShowingNextNodes()"
         class="cursor-pointer d-flex align-items-center justify-content-start mb-7px"
    >
        <ng-container *ngIf="showChildNodes; else collapseStatusImg">
            <div class="mr-8px">
                <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 0.5L4 3.5L7 0.5" class="svg-brand-color" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
        </ng-container>
        <img *ngIf="rootImage" src="{{ rootImage }}" alt=""/>
        <div class="ml-5px">{{ rootText }}</div>
    </div>
</ng-template>

<ng-template #collapseStatusImg>
    <div class="mr-8px" style="margin-top: -0px; transform: rotate(-90deg)">
        <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 0.5L4 3.5L7 0.5" class="svg-brand-color" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </div>
</ng-template>
