import { Component, Input } from '@angular/core';

@Component({
    selector: ':svg:g[jdLabel]',
    templateUrl: './job-definition-channel-label.component.html',
    styleUrls: ['./job-definition-channel-label.component.scss'],
})
export class JobDefinitionChannelLabelComponent {
    @Input() label: any;
}
