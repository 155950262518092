import { Component, EventEmitter, Input, Output } from '@angular/core';

import { WorkflowActionBlock } from '../../workflow-action.block';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: ':svg:g[actionBlock]',
    templateUrl: './jd-action-block.component.html',
    styleUrls: ['./jd-action-block.component.scss'],
})
export class JdActionBlockComponent {
    @Input() block: WorkflowActionBlock;
    @Input() activeBlock: any;
    @Input() debuggedBlockName: string;
    @Input() debugMode: boolean;

    @Output() activeBlockChange = new EventEmitter();
    @Output() breakpointToggle = new EventEmitter();
    @Output() editAction = new EventEmitter();
    @Output() dropped = new EventEmitter();

    onDrop(event: any) {
        this.dropped.emit({ ...event, toBlock: this.block });
    }

    toggleBreakpoint(event: MouseEvent) {
        event.stopImmediatePropagation();
        event.preventDefault();

        this.block.breakpoint = !this.block.breakpoint;

        this.breakpointToggle.emit();
    }

    onEdit(event: MouseEvent) {
        event.stopPropagation();
        event.preventDefault();

        this.editAction.emit(this.block);
    }
}
