import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { GridColumn, sortingDown, sortingUp } from '@dagility-ui/kit';

import { PropertyService } from '../api/property.service';
import { CiCdFieldDescription, CiCdProperty, HierarchyLevel } from '../../../model/fields.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Sort, sortArrayByFieldAndDirection, sortGridColumn } from '../../../../../utils/sorting.utils';

export interface PropertyWSelected extends CiCdProperty {
    selected?: boolean;
}

@Component({
    selector: 'lib-add-system-property',
    templateUrl: './add-system-property.component.html',
    styleUrls: ['./add-system-property.component.scss'],
})
export class AddSystemPropertyComponent implements OnInit {
    @Input() editProperties: CiCdFieldDescription[];
    @Input() hierarchyLevel: HierarchyLevel;

    selectedItems: PropertyWSelected[] = [];

    items$: Observable<PropertyWSelected[]>;

    gridColumns: GridColumn[] = [
        { title: '', width: '6%' },
        { title: 'Key Name', field: 'name', sortingField: 'name', width: '22%' },
        { title: 'Label', field: 'label', sortingField: 'label', width: '22%' },
        { title: 'Description', field: 'description', width: '50%' },
    ];

    sort$ = new BehaviorSubject({ field: 'name', direction: 'ASC' } as Sort);

    icons = {
        sortingUp,
        sortingDown,
    };

    constructor(private activeModal: NgbActiveModal, private propertyService: PropertyService) {}

    ngOnInit(): void {
        this.getItems();
    }

    getItems() {
        this.items$ = this.propertyService.getPredefinedProperties(this.hierarchyLevel).pipe(
            map(result => {
                return result
                    .filter(it => !this.isItemWithThisNameExist(it.name))
                    .map(item =>
                        Object.assign({}, item, {
                            selected: false,
                            defaultValue: item.defaultValue ? item.defaultValue : '',
                            value: item.value ? item.value : '',
                        })
                    );
            }),
            switchMap(this.sortProperties.bind(this))
        );
    }

    sortProperties(sources: PropertyWSelected[]) {
        return this.sort$.pipe(map(({ field, direction }) => sortArrayByFieldAndDirection(sources, field, direction)));
    }

    handleSort(index: number) {
        sortGridColumn(index, this.gridColumns, this.sort$);
    }

    isAnySelected(): boolean {
        return this.selectedItems && this.selectedItems.length > 0;
    }

    isItemWithThisNameExist(value: string): boolean {
        return this.editProperties ? this.editProperties.findIndex(it => it.name === value) !== -1 : false;
    }

    onSelectItem(value: boolean, item: PropertyWSelected) {
        item.selected = value;
        if (value) {
            this.selectedItems.push(item);
        } else {
            this.selectedItems = this.selectedItems.filter(p => p !== item);
        }
    }

    handleAdd() {
        this.activeModal.close({ addedProperties: this.selectedItems });
    }

    cancel() {
        this.activeModal.close();
    }
}
