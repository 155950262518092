import { Deserializable, TargetReference } from '../../../models/job-definition';

export class ReferenceAction extends Deserializable {
    _arrays = {};
    _class = { targetReference: TargetReference };
    _enums = {};
    _maps = {};
    meta = {
        _arrays: this._arrays,
        _class: this._class,
        _enums: this._enums,
        _maps: this._maps,
    };

    targetReference: TargetReference = new TargetReference();
    variable = '';

    getType() {
        return 'ReferenceAction';
    }

    constructor(instance?: any) {
        super();
        this.fillByInstance(ReferenceAction, instance);
    }
}
