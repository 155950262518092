<div ngbDropdown [autoClose]="'outside'" [placement]="placement" display="dynamic" [container]="container">
    <button ngbDropdownToggle href="javascript:void(0)" [disabled]="disabled === true || !nestedMenuItems || !nestedMenuItems.length" class="btn "
            [ngClass]="{'btn-primary': isPrimary && !isLink, 'btn-secondary': !isPrimary && !isLink, 'btn-link py-0': isLink}">
        {{ buttonText }}
    </button>
    <ul class="pt-0 pb-0 dropdown-menu" aria-labelledby="navbarDropdownMenuLink" ngbDropdownMenu>
        <ng-container *ngFor="let item of items">
            <ng-template [ngTemplateOutlet]="nodeTemplateRef" [ngTemplateOutletContext]="{ node: item }"></ng-template>
        </ng-container>
    </ul>
</div>

<ng-template #nodeTemplateRef let-node="node">
    <li #dd="ngbDropdown"
        class="dropdown-submenu"
        ngbDropdown
        [autoClose]="'outside'"
        [placement]="placement">
        <a *ngIf="!node.children" class="cursor-pointer dropdown-item font-11px pt-2 pb-2 pl-3 pr-3" (click)="actionClick(node)">
            {{ node.label }}
        </a>
        <a *ngIf="node.children && node.children.length > 0" class="cursor-pointer dropdown-item font-11px pt-2 pb-2 pl-3 pr-3 align-items-center" ngbDropdownToggle>
            <div class="col pl-0 pr-0 node-label text-truncate">{{ node.label }}</div>
            <ng-container
                *ngTemplateOutlet="defaultItem; context: { item: node.children }">
            </ng-container>
        </a>
        <ul *ngIf="node.children && node.children.length > 0" class="pt-0 pb-0 dropdown-menu submenu" aria-labelledby="navbarDropdownMenuLink" ngbDropdownMenu>
            <ng-container *ngFor="let item of node.children">
                <ng-template [ngTemplateOutlet]="nodeTemplateRef"
                             [ngTemplateOutletContext]="{ node: item }">
                </ng-template>
            </ng-container>
        </ul>
    </li>
</ng-template>

<ng-template #defaultItem let-item="item">
    {{ item.label }}
</ng-template>
