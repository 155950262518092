<div class="d-flex flex-column">
    <div class="p-1 mt-2 cursor-pointer width-fit-content ml-auto mr-4 mt-3" (click)="addWatch.emit()" ngbTooltip="Add new watch expression" container="body">
        <fa-icon class="font-14px add-button" icon="plus"></fa-icon>
    </div>
</div>
<div class="flex-1 overflow-auto p-3">
    <perfect-scrollbar>
        <ng-container *ngFor="let watch of watches; let i = index">
            <dp-debug-new-watch
                *ngIf="watch.isNew"
                class="py-1 pr-2"
                [expression]="watch.expression"
                (editExpression)="onBlur(i, $event)"
            ></dp-debug-new-watch>
            <dp-debug-variables
                *ngIf="!watch.isNew"
                class="d-block pr-2"
                ignoreHelpFields="true"
                [variables]="watch"
                [disabled]="false"
                (delete)="removeWatch.emit(i)"
                (changeExpression)="evaluateWatch.emit({ index: i, expression: $event })"
            ></dp-debug-variables>
        </ng-container>
    </perfect-scrollbar>
</div>
