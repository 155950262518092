<ng-container *ngIf="items$ | async as items">
    <dp-items-with-more [items]="items" [visibleItemsCount]="items.length">
        <ng-template let-item>
            <lib-radio-button
                class="d-flex align-items-center filter-item"
                [class.checked]="formControl.value === item.value"
                [style.pointer-events]="formControl.disabled ? 'none' : undefined"
                [name]="filter.placeholder"
                [value]="item.value"
                [checked]="formControl.value === item.value"
                [disabled]="formControl.disabled"
                (click)="!formControl.disabled && formControl.setValue(item.value)"
            >
                <span class="text-truncate" libTooltipWhenOverflow [ngbTooltip]="item.label" triggers="manual" container="body">
                    {{ item.label }}
                </span>
                <dp-preferred-item-icon [item]="item"></dp-preferred-item-icon>
            </lib-radio-button>
        </ng-template>
    </dp-items-with-more>
</ng-container>
