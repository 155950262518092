import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { QueryBuilderStore } from '@app/shared/components/query-builder/store/query-builder.store';
import { ModelGraphActionsService } from '@app/shared/components/query-builder/services/model-graph-actions.service';
import { QBConditionGroup } from '@app/shared/components/query-builder/models/model-graph-actions.model';
import { ExpressionBuilderService, ExpressionHelperEvent } from '../expression-builder/expression-builder.service';
import { ExpressionBuilderComponent } from '../expression-builder/expression-builder.component';

@Component({
    selector: 'app-qb-model-where-table',
    templateUrl: './where-table.component.html',
    styleUrls: ['./where-table.component.scss'],
})
export class WhereTableComponent implements OnInit {
    @ViewChild('svgContainer') svgContainer: ElementRef;
    @ViewChild(ExpressionBuilderComponent) expressionBuilderComponent: ExpressionBuilderComponent;
    recursionIndex: 1;
    addExpression = false;
    addExpressionIndex: number;
    isOperatorActions = false;
    isOperatorActionsIndex: number;
    isOperatorActionsType: string;
    isViewEditor = false;
    expressionCursor: number = 0;
    isSwitchFunctionEditor: boolean;
    expressionBuilderPosition: { left: number; top: number } = { left: 0, top: 0 };
    operatorActionsPosition: { left: number; top: number } = { left: 0, top: 0 };
    expressionInputElement?: HTMLInputElement;
    editCondition = false;
    editConditionIndex: number;
    prevEditValue: string;
    pathIndexes: number[];

    globalCounter = 0;
    newExpression: string;
    conditions: QBConditionGroup[] = [];

    constructor(
        private queryBuilderStore: QueryBuilderStore,
        private modelGraphActionsService: ModelGraphActionsService,
        private cdr: ChangeDetectorRef,
        private elRef: ElementRef,
        private expressionBuilderService: ExpressionBuilderService
    ) {}

    ngOnInit() {
        const coniditonsCopy = this.queryBuilderStore.getValue().conditions;
        if (coniditonsCopy) {
            this.conditions = coniditonsCopy;
        }
        if (this.conditions.length !== 0) {
            this.globalCounter = this.conditions[this.conditions.length - 1].id + 1;
        }
    }

    @HostListener('document:queryBuilderChanged', ['$event'])
    public gridDataFromFields(event?: CustomEvent) {
        this.conditions = this.conditionsFromModelGraph;
        const modelGraph = this.queryBuilderStore.modelGraph;
        if (modelGraph) {
            this.expressionBuilderService.updateGraphData(modelGraph);
        }
        this.cdr.detectChanges();
    }

    ngAfterViewInit() {
        this.gridDataFromFields();
    }

    get conditionsFromModelGraph() {
        this.globalCounter = 0;
        const conditions: QBConditionGroup[] = [];
        const gotoLevelTree = (condition: QBConditionGroup, level: number) => {
            const cond = cloneDeep(condition);
            const conditionsCopy = cloneDeep(cond.conditions);
            cond.id = this.globalCounter;
            this.globalCounter++;
            cond.parentId = level === -1 ? undefined : level;
            cond.expression = condition.expressions.map((e: any) => e.expression);
            delete cond.conditions;
            delete cond.expressions;
            conditions.push(cond);
            for (const c of conditionsCopy) {
                gotoLevelTree(c, level + 1);
            }
        };
        const filter = this.queryBuilderStore.modelGraph.dataSetFields.filter;
        if (filter && filter.condition) {
            gotoLevelTree(filter.condition, -1);
        }
        return filter ? conditions : [];
    }

    @HostListener('document:keydown', ['$event'])
    closeActions(event: KeyboardEvent) {
        if (event.code === 'Escape') {
            this.isOperatorActions = false;
            this.addExpression = false;
            this.isViewEditor = false;
            this.newExpression = '';
        }
    }

    setWhere() {
        this.modelGraphActionsService
            .setWhere({ filter: this.conditions, sourceGraph: this.queryBuilderStore.globalModelGraph })
            .subscribe(result => this.queryBuilderStore.updateState(result));
    }

    sortConditions(conditions: QBConditionGroup[]): QBConditionGroup[] {
        return conditions.sort((x, y) => {
            const n = x.id - y.id;
            if (n !== 0) {
                return n;
            }

            return x.parentId - y.parentId;
        });
    }

    handleAdd({
        type,
        expression,
        operator,
        parentId,
    }: {
        type: 'operator' | 'expression';
        expression?: string;
        operator?: 'AND' | 'OR';
        parentId: number;
    }) {
        if (type === 'operator') {
            const condition = this.conditions.find(item => item.id === parentId);
            let newConditions = this.conditions.filter(item => item.id !== parentId);

            newConditions = [
                ...newConditions,
                {
                    id: condition.id,
                    groupOp: condition.groupOp,
                    parentId: condition.parentId,
                    expression: [...condition.expression, expression],
                },
            ];
            if (parentId === undefined) {
                newConditions.push({ id: 0, parentId: undefined });
            } //dummy condition for layout purposes
            this.conditions = this.sortConditions(newConditions);
            this.queryBuilderStore.setState({ conditions: this.conditions });
            this.setWhere();
            this.newExpression = '';
        } else {
            this.conditions = this.sortConditions([
                ...this.conditions,
                {
                    id: this.globalCounter,
                    parentId,
                    groupOp: operator,
                    expression: [],
                },
            ]);
            this.globalCounter++;
            this.queryBuilderStore.setState({ conditions: this.conditions });
            this.setWhere();
        }
        this.cdr.detectChanges();
    }

    handleEnableAddExpression(recursionIndex: number, fromActions?: boolean) {
        this.addExpression = true;
        this.addExpressionIndex = recursionIndex;

        this.isViewEditor = true;
        this.updateExpressionBuilderPosition();
        this.expressionBuilderComponent.focus(this.newExpression.length);
        if (fromActions) {
            this.isOperatorActions = false;
        }
    }

    updateExpressionBuilderPosition(updateExpression = true) {
        this.cdr.detectChanges();
        const expressionBuilderRef: Element = this.elRef.nativeElement.querySelector('#expressionBuilderRef');
        this.expressionInputElement = this.elRef.nativeElement.querySelector('#expressionInput');
        const expressionInputTemplateElement = this.elRef.nativeElement.querySelector('#expressionInputTemplate');
        const element = this.editCondition ? expressionInputTemplateElement : this.expressionInputElement;
        if (element) {
            setTimeout(() => {
                const editorRect = expressionBuilderRef.getBoundingClientRect();
                const inputRect = element.getBoundingClientRect();
                this.expressionBuilderPosition.left = inputRect.x - editorRect.width + editorRect.width;
                this.expressionBuilderPosition.top = inputRect.y - editorRect.height + inputRect.height;
            }, 10);
            if (updateExpression) {
                this.newExpression = element.value;
            }
        }
    }

    onOpenFunctionEditor() {
        this.isSwitchFunctionEditor = !this.isSwitchFunctionEditor;
        this.updateExpressionBuilderPosition();
    }

    updateExpression(expression: string): string {
        return this.expressionBuilderService.updateExpressionView(expression || '');
    }

    openOperatorActions(recursionIndex: number, index: number, type?: string) {
        let operator;
        if (type === 'root') {
            operator = document.getElementById('rootCondition').getBoundingClientRect();
        } else {
            operator = document.getElementsByClassName('operator')[index].getBoundingClientRect();
        }
        this.operatorActionsPosition.top = operator.top - 180;
        this.operatorActionsPosition.left = operator.left - 150;
        this.isOperatorActions = true;
        if (type) {
            this.isOperatorActionsType = type;
        }
        this.isOperatorActionsIndex = recursionIndex;
    }

    handleNewOperator(index: number, operator: 'AND' | 'OR') {
        this.handleAdd({ type: 'expression', parentId: index, operator });
        this.isOperatorActions = false;
    }

    setOperator(index: number, type: 'AND' | 'OR') {
        const condition = this.conditions.find(item => item.id === index);
        let newConditions = this.conditions.filter(item => item.id !== index);
        newConditions = [
            ...newConditions,
            {
                id: condition.id,
                groupOp: type,
                parentId: condition.parentId,
                expression: condition.expression,
            },
        ];
        this.conditions = this.sortConditions(newConditions);
        this.queryBuilderStore.setState({ conditions: this.conditions });
        this.setWhere();
        this.isOperatorActions = false;
    }

    saveExpression(index: number) {
        this.handleAdd({ type: 'operator', parentId: index, expression: this.newExpression });
        this.addExpression = false;
        this.addExpressionIndex = undefined;
        this.newExpression = undefined;
        this.isViewEditor = false;
    }

    editExpression(value: string) {
        const x = this.conditions.find(cond => cond.expression.includes(this.prevEditValue));
        x.expression = [...x.expression.filter(y => y !== this.prevEditValue), value];
        this.addExpression = false;
        this.addExpressionIndex = undefined;
        this.newExpression = undefined;
        this.isViewEditor = false;
        this.editCondition = false;
        this.setWhere();
    }

    handleEdit(id: number, expessionIndex: number, value: string) {
        this.editCondition = true;
        this.addExpressionIndex = expessionIndex;
        this.isViewEditor = true;
        this.isOperatorActionsIndex = id;
        this.editConditionIndex = id;
        this.prevEditValue = value;
        this.cdr.detectChanges();
        this.expressionInputElement = this.elRef.nativeElement.querySelector('#expressionInputTemplate');
        if (this.expressionInputElement) {
            this.expressionInputElement.value = this.expressionBuilderService.updateExpressionView(value);
            this.onExpressionInput('');
            this.updateExpressionBuilderPosition(false);
        } else {
            this.updateExpressionBuilderPosition();
        }
        this.expressionBuilderComponent.focus(this.expressionCursor);
    }

    handleDelete(index: number, type: string, expression?: string) {
        if (type === 'operator') {
            let copyArray: QBConditionGroup[];
            const cascadeDelete = (array: QBConditionGroup[], id: number) => {
                copyArray = array;
                if (copyArray.findIndex(x => x.parentId === id) !== -1) {
                    copyArray = cascadeDelete(copyArray, array.find(x => x.parentId === id).id);
                }
                copyArray = copyArray.filter(item => item.id !== id && item.parentId !== id);
                return copyArray;
            };
            const conditions = cascadeDelete(this.conditions, index);
            this.conditions = this.sortConditions(conditions);
            this.globalCounter = index;
            this.queryBuilderStore.setState({ conditions: this.conditions });
            this.setWhere();
        } else {
            const condition = this.conditions.find(item => item.id === index);
            const remainingConditions = this.conditions.filter(item => item.id !== index);

            this.conditions = this.sortConditions([
                ...remainingConditions,
                {
                    id: condition.id,
                    parentId: condition.parentId,
                    groupOp: condition.groupOp,
                    expression: condition.expression.filter(item => item !== expression),
                },
            ]);
            this.queryBuilderStore.setState({ conditions: this.conditions });
            this.setWhere();
        }
        this.isOperatorActions = false;
        this.cdr.detectChanges();
    }

    onExpressionInput(event: any) {
        this.expressionInputElement = this.elRef.nativeElement.querySelector('#expressionInput');
        if (this.elRef.nativeElement.querySelector('#expressionInputTemplate')) {
            this.expressionInputElement = this.elRef.nativeElement.querySelector('#expressionInputTemplate');
            if (this.prevEditValue && this.prevEditValue[0] === '{') {
                this.isSwitchFunctionEditor = true;
                this.newExpression = this.prevEditValue;
                this.expressionBuilderComponent.setExpressionData(this.prevEditValue);
            } else {
                this.isSwitchFunctionEditor = false;
                this.newExpression = this.prevEditValue;
                this.expressionBuilderComponent.focus(this.expressionCursor);
            }
        }
        if (this.expressionInputElement) {
            this.expressionCursor = this.expressionInputElement.selectionStart;
        }
        if (event?.target) {
            this.newExpression = event.target.value;
        }
    }

    onExpressionChanged(event: ExpressionHelperEvent) {
        if (event.type === 'expression-complete') {
            if (this.editCondition) {
                this.editExpression(this.newExpression);
            } else {
                this.saveExpression(this.addExpressionIndex);
            }
            return;
        }
        if (event.type === 'expression-canceled') {
            this.editCondition = false;
            this.addExpression = false;
            this.addExpressionIndex = undefined;
            this.newExpression = undefined;
            this.isViewEditor = false;
            if (this.expressionInputElement) {
                this.expressionInputElement.value = '';
            }
            return;
        }
        if (event.type === 'expression-changed') {
            this.newExpression = event.expression;
            if (this.expressionInputElement) {
                this.expressionInputElement.value = event.expressionView;
            }
            this.cdr.detectChanges();
            return;
        }
        this.expressionInputElement = this.elRef.nativeElement.querySelector('#expressionInput');

        if (this.elRef.nativeElement.querySelector('#expressionInputTemplate')) {
            this.expressionInputElement = this.elRef.nativeElement.querySelector('#expressionInputTemplate');
        }

        if (event.expression) {
            this.newExpression = event.expression;
        }
        this.cdr.detectChanges();
        if (this.expressionInputElement) {
            this.expressionInputElement.value = this.newExpression;
            if (event.selectionStart) {
                this.expressionInputElement.setSelectionRange(event.selectionStart, event.selectionEnd);
            }
        }
    }

    getPath(expressionIndex: number, conditionIndex: number, isOp = false) {
        const fromElem = document.getElementsByClassName('path-start');
        const toElem = document.getElementsByClassName(`expression-${conditionIndex}`);
        let svgRelation: any;
        svgRelation = {
            from: fromElem[conditionIndex],
            to: toElem[expressionIndex],
        };
        if (isOp) {
            const condition = this.conditions.find(({ id }) => id === conditionIndex);
            if (condition === undefined) {
                svgRelation = {
                    from: fromElem[0],
                    to: undefined,
                };
            } else {
                svgRelation = {
                    from: fromElem[condition.parentId],
                    to: fromElem[condition.id],
                };
            }
        }
        let x1 = 0;
        let y1 = 0;
        let x2 = 0;
        let y2 = 0;
        let xOffset = 0;
        let yOffset = 0;
        if (svgRelation.from && svgRelation.to && this.svgContainer) {
            x1 =
                svgRelation.from.getBoundingClientRect().left -
                this.svgContainer.nativeElement.getBoundingClientRect().left -
                xOffset +
                svgRelation.from.getBoundingClientRect().width / 2;
            y1 =
                svgRelation.from.getBoundingClientRect().top -
                this.svgContainer.nativeElement.getBoundingClientRect().top +
                svgRelation.from.offsetHeight / 2 +
                svgRelation.from.getBoundingClientRect().height / 2;
            x2 =
                svgRelation.to.getBoundingClientRect().left - this.svgContainer.nativeElement.getBoundingClientRect().left + (isOp ? 0 : 9);
            y2 =
                svgRelation.to.getBoundingClientRect().top -
                this.svgContainer.nativeElement.getBoundingClientRect().top +
                svgRelation.to.offsetHeight / 2;
            xOffset = x2 - x1 >= 12 ? 6 : x2 - x1 <= -12 ? -6 : (x2 - x1) / 2;
            yOffset = y2 - y1 >= 12 ? 6 : y2 - y1 <= -12 ? -6 : (y2 - y1) / 2;
        }
        return `M ${x1} ${y1} L ${x1} ${y2 - yOffset} Q ${x1} ${y2} ${x1 + xOffset} ${y2} L ${x2} ${y2}`;
    }
}
