import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Inject, Injectable } from '@angular/core';

import { ICreateProject } from '../../../pages/ci-cd/api/ci-cd-template.service';
import { ProjectGeneralSettingsDTO } from 'src/app/pages/project/project-edit/project-settings/models/project-general-settings-dto';
import { CiCdProperty, IToolsChoosingResult, ProjectItem, ToolCategoryType, ToolModel } from '@dagility-ui/shared-components';
import { ENV_TOKEN } from '@app/tokens';

@Injectable({
    providedIn: 'root',
})
export class ProjectSettingsService {
    private readonly BASE_PATH = `${this.env.adminApiURL}/v2/projects`;
    private readonly CI_CD_BASE_PATH = `${this.env.buildApiURL}/ci-cd`;
    private readonly TOOL_BASE_PATH = `${this.env.adminApiURL}/v2/tools`;
    private readonly TAGS_BASE_PATH = `${this.env.adminApiURL}`;

    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {}

    getProjectGeneralSettingsResponse(projectId: number): Observable<HttpResponse<any>> {
        return this.http.get(`${this.BASE_PATH}/${projectId}/settings`, { observe: 'response' });
    }

    getProjectGeneralSettings(projectId: number): Observable<any> {
        return this.http.get(`${this.BASE_PATH}/${projectId}/settings`);
    }

    getProjectProperties(projectId: number): Observable<CiCdProperty[]> {
        return this.http.get<CiCdProperty[]>(`${this.BASE_PATH}/${projectId}/properties`);
    }

    saveProjectProperties(projectId: number, properties: CiCdProperty[]) {
        return this.http.put(`${this.BASE_PATH}/${projectId}/properties`, properties);
    }

    getUnusedTools(projectId: number, type: ToolCategoryType) {
        return this.http.get<ToolShort[]>(`${this.BASE_PATH}/${projectId}/tools_with_image/${type}`);
    }

    getTags(): Observable<any> {
        return this.http.get<any>(`${this.TAGS_BASE_PATH}/ci-cd/tags`);
    }

    saveGeneralSettings(projectId: number, project: ProjectGeneralSettingsDTO): Observable<any> {
        return this.http.put(`${this.BASE_PATH}/${projectId}/settings`, project);
    }

    getToolUnitsByCategoryType(projectId: number, categoryType: ToolCategoryType) {
        return this.http.get<ToolAndUnits[]>(`${this.BASE_PATH}/${projectId}/${categoryType}`);
    }

    updateToolUnits(projectId: number, categoryType: ToolCategoryType, toolUnits: { [toolId: number]: UnitShort[] }) {
        return this.http.put(`${this.BASE_PATH}/${projectId}/${categoryType}`, toolUnits);
    }

    createProject(project: ICreateProject) {
        return this.http.post(this.BASE_PATH, project);
    }

    getProjectUnitByCategory(projectId: number, categoryType: ToolCategoryType): Observable<IToolsChoosingResult> {
        const methodsMap: Partial<CategoryTypeMap<Observable<IToolsChoosingResult>>> = {
            SCM: this.http.get<IToolsChoosingResult>(`${this.BASE_PATH}/${projectId}/project-level-repository`),
            CISRV: of(null),
            CSCAN: of(null),
            OTHER: of(null),
            PM: of(null),
            ARTIFACTORY: of(null),
            NOTIFICATION: of(null),
            ITSM: of(null),
            MONITORING: of(null),
            TEST_MANAGEMENT: of(null),
        };

        return methodsMap[categoryType];
    }

    createProjectRepository(projectId: number) {
        return this.http.post(`${this.CI_CD_BASE_PATH}/projects/${projectId}/generate-repository`, null);
    }

    getToolComponents(toolId: number, categoryType: ToolCategoryType) {
        return this.http.get<ProjectItem[]>(`${this.TOOL_BASE_PATH}/components/${toolId}/${categoryType.toUpperCase()}`);
    }

    getToolsByCategory(categoryType: ToolCategoryType) {
        return this.http.get<ToolModel[]>(`${this.TOOL_BASE_PATH}/byCategoryType`, {
            params: { categoryType: categoryType.toUpperCase() },
        });
    }

    getProjectTools(projectId: string) {
        return this.http.get<any>(`${this.BASE_PATH}/${projectId}/tools`);
    }
}

export interface ToolShort {
    toolId: string;
    name: string;
    imageUrl: string;
}

export interface ToolAndUnits {
    id: number;
    toolName: string;

    toolImagePath: string;
    baseUrl: string;

    managed: AppUnit[];
    linked: AppUnit[];
}

export interface AppUnit {
    unitId: number;
    unitName: string;
    projectId: number;
    projectName: string;
    componentId: number;
}

export interface UnitShort {
    unitGroupId: number;
    unitGroupName: string;
    unitId: number;
    unitName: string;
}

export type CategoryTypeMap<T = any> = {
    [key in ToolCategoryType]: T;
};
