import { Component, Input } from '@angular/core';

import { PortfolioScore } from '../../portfolio-health-scores/models/portfolio-scores.model';

@Component({
  selector: 'dp-score-subcategory',
  templateUrl: './score-subcategory.component.html',
  styleUrls: ['./score-subcategory.component.scss']
})
export class ScoreSubcategoryComponent {
    @Input() data: PortfolioScore;

    isLoad = true;

    ngOnInit() {
        this.isLoad = false;
    }
}
