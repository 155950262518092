import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CompletionSourceItem, HierarchyLevel } from '@dagility-ui/shared-components';
import { ENV_TOKEN } from '@app/tokens';

@Injectable({
    providedIn: 'root',
})
export class AutoCompletionService {
    baseUrl = `${this.env.buildApiURL}/ci-cd/completion`;

    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {}

    getCodeCompletionProperties(id: string, hierarchyLevel: HierarchyLevel, belongToProject: boolean): Observable<CompletionSourceItem[]> {
        let params = new HttpParams();
        params = params.append('belongToProject', String(belongToProject));
        params = params.append('hierarchyLevel', hierarchyLevel);
        return this.http.get<CompletionSourceItem[]>(`${this.baseUrl}/${id}`, { params });
    }
}
