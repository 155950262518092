import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { isEmpty } from 'lodash';

import { WidgetToolCategoryType } from 'data-processor';
import { AppUnits, DashboardService } from '@app/shared/services/admin/dasboard.service';
import { isDefined, Store } from '@dagility-ui/kit';

export enum MultiLevelFilterPlaceholders {
    PROJECT_ID = 'projectId',
    TEAM_ID = 'teamId',
    PORTFOLIO_ID = 'portfolioId',
}

export interface MultilevelFilterValue {
    [MultiLevelFilterPlaceholders.PROJECT_ID]: number[] | null;
    [MultiLevelFilterPlaceholders.TEAM_ID]: number[] | null;
    [MultiLevelFilterPlaceholders.PORTFOLIO_ID]: number[] | null;
    portfolio_id?: number;
    portfolioName?: string;
    dashboardScope?: string;
    project_id_name?: Record<string, any>;
    isLastAppliedMlf: boolean;
    isManualChanged: boolean;
}

interface FlatAppUnits {
    project_id: number[];
    projectId: string[];
    unit_id: number[];
    unitId: string[];
    team_id?: number[];
    teamId?: string[];
    portfolioId?: number;
}

type UnitsMap = Partial<{ [key in WidgetToolCategoryType | 'ALL']: Observable<FlatAppUnits> }>;

@Injectable()
export class MultilevelFilterState extends Store<MultilevelFilterValue> {
    unitsMap$: UnitsMap;
    loaded$ = new ReplaySubject(1);
    disabled = false;

    constructor(private api: DashboardService) {
        super({
            projectId: [],
            teamId: [],
            portfolioId: [],
            portfolio_id: null,
            isLastAppliedMlf: false,
            isManualChanged: false,
        });
    }

    setFilter(value: Partial<MultilevelFilterValue>) {
        this.setState({
            ...value,
        });

        this.updateUnitsMap();

        this.loaded$.next();
    }

    private updateUnitsMap() {
        const ids = this.value.projectId ?? this.value.portfolioId;
        const notIsEmpty = (arr: unknown[]) => isDefined(arr) && !isEmpty(arr);
        const teamIds$ = notIsEmpty(this.value.teamId)
            ? this.api.getTeamsByIds(this.value.teamId).pipe(
                  catchError(() => of([] as string[])),
                  map(teamId => ({
                      // eslint-disable-next-line @typescript-eslint/naming-convention
                      team_id: this.value.teamId,
                      teamId,
                  })),
                  shareReplay(1)
              )
            : of({});

        this.unitsMap$ = ([
            'CISRV',
            'CSCAN',
            'PM',
            'SCM',
            'TEST_MANAGEMENT',
            'INTEGRATION',
            'ITSM',
            'MONITORING',
            null,
        ] as WidgetToolCategoryType[]).reduce<UnitsMap>((acc, type) => {
            (acc as any)[type === null ? 'ALL' : type] = forkJoin([
                notIsEmpty(ids)
                    ? this.api.getProjectsByIds(type, ids).pipe(
                          catchError(() => of([])),
                          map(flatAppUnits)
                      )
                    : of(empty()),
                teamIds$,
            ]).pipe(
                map(([appUnits, teams]) => ({
                    ...appUnits,
                    ...teams,
                    portfolioId: this.value.portfolio_id,
                    portfolioName: this.value.portfolioName,
                    dashboardScope: this.value.dashboardScope,
                    project_id_name: this.value.project_id_name,
                })),
                shareReplay(1)
            );

            return acc;
        }, {} as UnitsMap);
    }
}

export function flatAppUnits(appUnits: AppUnits[]): FlatAppUnits {
    return appUnits.reduce((acc, { projectId, project_id, units }) => {
        if (projectId) {
            acc.projectId.push(projectId);
        }
        acc.project_id.push(project_id);
        units.forEach(({ unitId, unit_id }) => {
            acc.unitId.push(unitId);
            acc.unit_id.push(unit_id);
        });

        return acc;
    }, empty());
}

export function empty(): FlatAppUnits {
    return {
        project_id: [],
        projectId: [],
        unit_id: [],
        unitId: [],
    };
}
