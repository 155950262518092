import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentModel } from '@dagility-ui/kit';

@Injectable({
    providedIn: 'root',
})

export class TourLibApiService {
    private baseApiUrl = `${this.environment.adminApiURL}`;
    constructor(private http: HttpClient, @Inject('environment') private environment: EnvironmentModel) {}

    skipTour(params: any): Observable<void> {
        return this.http.post<void>(`${this.baseApiUrl}/saveSkippedTour`, params);
    }
}
