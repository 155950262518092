import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthorityPermissions, RbacAction, RbacObjectId, RbacStaticType, RbacType } from '@dagility-ui/kit';
import { shareReplay } from 'rxjs/operators';
import { ENV_TOKEN } from '@app/tokens';

@Injectable({
    providedIn: 'root'
})
export class RightsService {
    private readonly securityApi = this.env.adminApiURL + '/security';

    private currentRights$: Observable<ISecuredObjectPermissions[]>;

    constructor(@Inject(ENV_TOKEN) private env: Env, private httpClient: HttpClient) {
        this.refreshCurrentUserRights();
    }

    getObjectTypes(): Observable<ISecuredObject[]> {
        return this.httpClient.get<ISecuredObject[]>(`${this.securityApi}/permissible-objects`);
    }

    getObjectTypeRights(type: string, objId: string): Observable<any> {
        return this.httpClient.get<IObjectPermissions>(`${this.securityApi}/permissions`, {
            params: { objectUri: type + ':' + objId },
        });
    }

    getCurrentUserRights(): Observable<ISecuredObjectPermissions[]> {
        return this.currentRights$;
    }

    refreshCurrentUserRights() {
        this.currentRights$ = this.httpClient.get<ISecuredObjectPermissions[]>(`${this.securityApi}/user-rights`).pipe(shareReplay(1));
    }

    getCurrentUserPermissions(type: RbacType, objectLocator: string): Observable<AuthorityPermissions> {
        const url = `${this.securityApi}/user-permissions`;
        return this.httpClient.get<AuthorityPermissions>(url, { params: { objectUri: type + ':' + objectLocator } });
    }

    calculateAuthority(data: ICalculateAuthorityDto): Observable<IObjectAuthority> {
        return this.httpClient.post<IObjectAuthority>(`${this.securityApi}/calculate-authority`, data);
    }

    updatePermissions(data: IUpdatePermissionsDto): Observable<void> {
        return this.httpClient.post<void>(`${this.securityApi}/update-permissions`, data);
    }
}

export interface ISecuredObject {
    type: RbacStaticType;
    objId: RbacObjectId;
    name?: string;
    description?: string;
}

export interface PermissionsData {
    permissionsResponseGroupList: IPermissionsResponseGroupList[];
}

export interface IPermissionsResponseGroupList {
    name: string;
    objectUri: string;
    content: IObjectPermissions;
}

export interface IObjectPermissions {
    availablePermissions: IPermissionLabel[];
    authorities: IObjectAuthorities;
}

export interface IPermissionLabel {
    code: string;
    title: string;
    description?: string;
}

export interface IObjectAuthorities {
    [key: string]: IObjectAuthority;
}

export interface IObjectAuthority {
    label: string;
    permissions?: AuthorityPermissions;
}

export interface ISecuredObjectPermissions {
    type: RbacType;
    objId: string;
    permissions: AuthorityPermissions;
}

export interface ICalculateAuthorityDto {
    objectUri: string;
    authority: string;
    existingPermissions: RbacAction[];
    permission: RbacAction;
    enable: boolean;
}

export interface IUpdatePermissionsDto {
    objectUri: string;
    updateAuthorities: IUpdateAuthorities;
}

export interface IUpdateAuthorities {
    [key: string]: RbacAction[];
}
