import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { share, shareReplay } from 'rxjs/operators';
import { Release } from '../../../pages/insights/Widgets/radial-widget/radial-widget.component';
import { ENV_TOKEN } from '@app/tokens';

@Injectable({
    providedIn: 'root',
})
export class InsightService {
    private insightDashaboardBasePath = `${this.env.insightApiURL}/insights/`;
    private serviceUrl = `${this.env.serviceUrl}`;
    private dashboardBasePath = `${this.env.insightApiURL}/insights/demoDashboards/`;
    private applicationHealthPath = `${this.env.insightApiURL}/reports/scores/composites`;
    private applicationScores = `${this.env.insightApiURL}/reports/composites`;
    dragDropId = new BehaviorSubject<any>([]);
    dropId: any = ['drop1', 'drop2', 'drop3', 'drop4', 'drop5', 'drop6', 'drop7', 'drop8', 'drop9', 'drop10'];
    filterPOSTParams: any = {};
    filterStream: any = {};
    groupObject: any = {};

    piSummaryIsActive$: Observable<boolean>;

    windowRefresh = new BehaviorSubject<boolean>(false);
    widgetDrgandDrop = new BehaviorSubject<boolean>(false);

    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {}

    getDashboardData(menuId: string, filterName: string): Observable<any> {
        return this.http.get(this.dashboardBasePath + `${menuId}` + '/' + `${filterName}` + '.properties');
    }

    getVSMDashboardData(menuId: string): Observable<any> {
        return this.http.get(this.dashboardBasePath + `${menuId}` + '/' + `${menuId}` + '.properties');
    }

    getChartData(url: string): Observable<any> {
        return this.http.get(`${this.serviceUrl}${url}`);
    }

    getChartOriginalData(dataUrl: string, params = {}): Observable<any> {
        return this.http.get(`${this.serviceUrl}${dataUrl}`, { params });
    }

    getChartOriginalDataPortfolio(dataUrl: string, params: any): Observable<any> {
        return this.http.post(`${this.serviceUrl}${dataUrl}`, params);
    }

    getDashboardCategory() {
        return this.http.get(this.insightDashaboardBasePath + 'reportsNameByCategory');
    }

    getAllWidgets(categoryName: string) {
        return this.http.get(this.insightDashaboardBasePath + 'reportsByCategory/' + `${categoryName}`);
    }

    getWidgetById(id: any) {
        return this.http.get(this.insightDashaboardBasePath + 'report/' + `${id}`);
    }

    getAllReports(data: any): Observable<any> {
        return this.http.get(this.insightDashaboardBasePath + 'reports/searchByName', { params: data });
    }

    saveDashBoard(dashboardData: any) {
        return this.http.post(this.insightDashaboardBasePath + 'insightdashboard', dashboardData);
    }

    getAllDashBoard() {
        return this.http.get(this.insightDashaboardBasePath + 'dashboards');
    }

    getSideMenu(skipError?: boolean) {
        const headers = skipError ? { 'skip-global-error': 'true' } : {};

        return this.http.get<{ result: NamedFilter[]; status: number }>(this.insightDashaboardBasePath + 'dashboardsNames', {
            headers,
        });
    }

    deleteWidget(id: any): Observable<any> {
        return this.http.delete(this.insightDashaboardBasePath + '/report' + id);
    }

    // getFilterData(url: string): Observable<any> {
    //     return this.http.get(`${this.serviceUrl}${url}`).pipe(shareReplay(1));
    // }

    // update getFilterData to avoid duplicate API calls
    getFilterData(url: string): Observable<any> {
        if (this.filterStream.hasOwnProperty(this.createFilterJsonDataKey(url)) && this.filterStream[this.createFilterJsonDataKey(url)]) {
            return this.filterStream[this.createFilterJsonDataKey(url)];
        } else {
            this.filterStream[this.createFilterJsonDataKey(url)] = this.http.get(`${this.serviceUrl}${url}`).pipe(share());
            return this.filterStream[this.createFilterJsonDataKey(url)];
        }
    }

    getPIDashboard(): Observable<any> {
        if (!this.piSummaryIsActive$) {
            this.piSummaryIsActive$ = this.http.get<any>(`${this.env.adminApiURL}/feature-on-off/status/PI Summary`).pipe(shareReplay(1));
        }

        return this.piSummaryIsActive$;
    }

    createFilterJsonDataKey(filterUrl: any) {
        return `${filterUrl
            .substr(0, filterUrl.lastIndexOf('/'))
            .substr(filterUrl.substr(0, filterUrl.lastIndexOf('/')).lastIndexOf('/') + 1)}-${filterUrl.substr(
            filterUrl.lastIndexOf('/') + 1
        )}`;
    }

    // update getPOSTFilterData to avoid duplicate API calls
    getPOSTFilterData(url: string, params: any): Observable<any> {
        const urlKey = this.createFilterJsonDataKey(url);
        if (
            this.filterStream.hasOwnProperty(urlKey) &&
            this.filterStream[urlKey] &&
            this.filterPOSTParams.hasOwnProperty(urlKey) &&
            JSON.stringify(this.filterPOSTParams[urlKey]) === JSON.stringify(params)
        ) {
            return this.filterStream[urlKey];
        } else {
            this.filterPOSTParams[urlKey] = params;
            this.filterStream[urlKey] = this.http.post(`${this.serviceUrl}${url}`, params).pipe(share());
            return this.filterStream[urlKey];
        }
    }

    setGroupContainerList(groupObject: any) {
        this.groupObject = groupObject;
    }

    getGroupContainer(): any[] {
        if (this.groupObject != null) {
            return this.groupObject;
        }
    }

    getToggleStatus(): Observable<any> {
        return this.http.get(this.insightDashaboardBasePath + 'is-toggle/ui_elements/help');
    }

    updateWidgetVisibility(widgetData: any) {
        return this.http.post(this.insightDashaboardBasePath + 'widgetVisibility', widgetData);
    }

    getSavedDashboard() {
        return this.http.get(this.insightDashaboardBasePath + 'lastUsedDashboard');
    }

    getApplicationHealth(projectId: string, dashboardId: string, numberOfSprints?: string) {
        if (!numberOfSprints) {
            numberOfSprints = '5';
        }
        const httpParams: HttpParams = new HttpParams()
            .append('projectId', projectId)
            .append('dashboardId', dashboardId)
            .append('numberOfSprints', numberOfSprints);
        return this.http.get(this.applicationHealthPath, { params: httpParams });
    }

    getApplicationHealthSettings(dashboardId: string) {
        const httpParams: HttpParams = new HttpParams().append('dashboardId', dashboardId);
        return this.http.get(`${this.applicationHealthPath}/settings`, { params: httpParams });
    }

    updateApplicationHealthSettings(settings: any[]) {
        return this.http.put(`${this.applicationHealthPath}/settings`, settings);
    }

    getBuildExecutionRecords(url: any, params: any): Observable<any> {
        return this.http.get(`${this.serviceUrl}${url}`, { params: params });
    }

    getPOSTBuildExecutionRecords(url: string, params: any): Observable<any> {
        return this.http.post(`${this.serviceUrl}${url}`, params);
    }

    // getHTMLContent(htmlLink: any) {
    //     return this.http.get(htmlLink);
    // }

    getSizeBy() {
        return this.http.get(`${this.applicationScores}/sizeByFilter`);
    }

    getIssueTypeData(params: any) {
        return this.http.post(`${this.applicationScores}/getIssueType`, params);
    }

    getPriorityTypeData(params: any) {
        return this.http.post(`${this.applicationScores}/getPriorityType`, params);
    }

    getFeatureCompletionReleases(projectId: string): Observable<Release[]> {
        const httpParams: HttpParams = new HttpParams().append('projectId', projectId);
        return this.http.get<Release[]>(`${this.env.insightApiURL}/reports/feature-completion-releases`, { params: httpParams });
    }
}

export interface NamedFilter {
    categoryName: string;
    subCategories: NamedData[];
}

interface NamedData {
    objId: string;
    name: string;
    sequenceNo: number;
    visibility: boolean;
    editable: boolean;
}
