import {Injectable} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Injectable()
export class EditToolTemplateFacade {
    form: FormGroup;

    constructor(private fb: FormBuilder) {}

    buildForm(template: any) {
        this.form = this.fb.group({
            name: [template.name || '', Validators.required],
            description: [template.description || ''],
            componentCollection: [template.componentCollection || ''],
            defaultSchedulerTime: [template.defaultSchedulerTime || null],
            imageUrl: [template.imageUrl || ''],
            pluginCategories: [template.pluginCategories || [], Validators.required],
            pluginType: [template.pluginType || '', Validators.required],
            sequence: [template.sequence || null, Validators.required],
            subCollection: [template.subCollection || ''],
            verificationPath: [template.verificationPath || '', Validators.required],
            authTypes: this.buildAuthTypeForm(template.authTypes || ([] as any)),
        });
    }

    buildAuthTypeForm(authTypes: any) {
        return this.fb.array(authTypes.map(this.buildAuthTypeGroup.bind(this)));
    }

    buildAuthTypeGroup(authType: any) {
        return this.fb.group({
            type: [authType.type || '', Validators.required],
            ...this.getAuthControlsByAuthType(authType).controls,
        });
    }

    getAuthControlsByAuthType(authType: any) {
        switch (authType.type) {
            case 'FORM': {
                return this.fb.group({
                    value: authType.value || '',
                    tokenXPath: authType.tokenXPath || '',
                });
            }
            case 'TOKEN': {
                return this.fb.group({
                    value: authType.value || '',
                });
            }
            case 'SCRIPT': {
                return this.fb.group({
                    value: authType.value || '',
                });
            }
            default: {
                return this.fb.group({});
            }
        }
    }
}
