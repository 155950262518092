<ng-container *ngIf="collapsed; else nonCollapsedTemplate">
    <div class="d-inline-flex w-100 justify-content-center border-top p-2 resize-panel" [style.minHeight.px]="collapsedHeight">
        <div class="btn collapse-toggle" ngbTooltip="Expand" [openDelay]="800" (click)="onCollapsed(false)">
            <fa-icon icon="chevron-up"></fa-icon>
        </div>
    </div>
</ng-container>

<ng-template #nonCollapsedTemplate>
    <div *ngIf="logs$ | obsWithStatus | async as logsVm" class="h-100 resize-panel" [style.minHeight.px]="50" #defaultParentItem>
        <div class="row no-gutters w-100 justify-content-center border-top resize" libResize
             (resizedEvent)="resizedEmit.emit()"
             defaultHeight="{{ height }}"
             [parentItem]="resizeItem ? resizeItem : defaultParentItem">
            <div class="collapse-toggle" ngbTooltip="Collapse" [openDelay]="800" (mousedown)="onMouseDown()" (mouseup)="onMouseUp()">
                <fa-icon icon="chevron-down"></fa-icon>
            </div>
        </div>

        <perfect-scrollbar *ngIf="text; else loadingTemplate" class="scrollbar pt-2 pb-3 px-2" [config]="perfectScrollBarConfig">
            <div class="log-content p-2" [style.maxHeight.px]="height">
                {{ text }}
            </div>
        </perfect-scrollbar>
    </div>
</ng-template>


<ng-template #loadingTemplate>
    <div class="d-inline-flex justify-content-center align-items-center w-100 h-100 pb-4 mt-2">
        <lib-spinner></lib-spinner>
    </div>
</ng-template>
