import { Provider, SkipSelf } from '@angular/core';
import { FORM_ERRORS } from '@dagility-ui/kit';

export function formErrorsFactory(formErrors: Record<string, any>): Record<string, (error: any) => string> {
    return {
        ...formErrors,
        maxWordsCount: ({ requiredLength, actualLength }) => `Must be max ${requiredLength} words long`,
    };
}

export const HELP_FORM_ERRORS_PROVIDER: Provider = {
    provide: FORM_ERRORS,
    useFactory: formErrorsFactory,
    deps: [[new SkipSelf(), FORM_ERRORS]],
};
