import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, Output, TrackByFunction } from '@angular/core';
import { CardStatus, CardStatusValue } from '@dagility-ui/kit';

interface Issue {
    issue: any;
    tabbedGrid: any;
    withoutParent: {
        navItems: any;
    };
}

@Component({
    selector: 'dp-feature-completion-status',
    templateUrl: './feature-completion-status.component.html',
    styleUrls: ['feature-completion-status.component.scss'],
})
export class FeatureCompletionStatusComponent {
    @Input() options: {
        items: Issue[];
        statusMapper: Record<string, CardStatusValue>;
        isSingleWidget?: boolean;
        showLink: boolean;
    };
    @Input() set infiniteScrollChartData(data: Record<string, any> | null) {
        this.isLoading = false;

        if (!data) {
            return;
        }

        if (data?.options.items) {
            this.options.items = [...this.options.items, ...data.options.items];
        }
    }

    @Output() linkClicked = new EventEmitter();
    @Output() scrollEnded = new EventEmitter();
    @Output() handleDrilldown = new EventEmitter<unknown>();

    isLoading = false;

    trackByIssue: TrackByFunction<Issue> = (_, { issue }) => issue.id;

    private limit = 10;
    private offset = 0;

    constructor(private cdr: ChangeDetectorRef, private zone: NgZone) {}

    getCardStatus(value: any): CardStatus {
        return value;
    }

    getLinkClickedValue(value: any, type: string) {
        return {
            ...value,
            type,
        };
    }

    handleScrollEnd() {
        if (this.isLoading) {
            return;
        }

        this.zone.run(() => {
            this.isLoading = true;
            this.scrollEnded.emit({ offset: ++this.offset, limit: this.limit });
        });
    }
}
