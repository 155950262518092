import { ChangeDetectionStrategy, Component, Input, Output, Inject, EventEmitter, ViewChild, ElementRef, OnInit } from '@angular/core';
import { NgbAccordion, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';

import { WidgetHelp } from 'data-processor';

import { EnvironmentModel } from '@dagility-ui/kit';

import { WidgetHelpSupportService } from '../../../services/widget-help-support.service';

interface ContextHelpPanel {
    header: string;
    field: Exclude<keyof WidgetHelp, 'insights'>;
}

type TestIdMap = Partial<Record<ContextHelpPanel['field'], string>>;

@Component({
    selector: 'dp-widget-context-help',
    templateUrl: './widget-context-help.component.html',
    styleUrls: ['./widget-context-help.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'px-0',
    },
})
export class WidgetContextHelpComponent implements OnInit {
    @Input() help: WidgetHelp;

    @Output() close = new EventEmitter();

    @ViewChild(NgbAccordion, { read: ElementRef }) accordionElement: ElementRef<HTMLElement>;

    openedPanel: HTMLElement;
    showInsights = false;

    readonly panels: ContextHelpPanel[] = [
        {
            field: 'description',
            header: 'How is this widget useful?',
        },
        {
            field: 'howScoreCalculated',
            header: 'How is this Score Calculated?',
        },
        {
            field: 'howScoreCanImproved',
            header: 'How can this Score be Improved?',
        },
    ];

    @Input() testIdMap: TestIdMap = {};

    constructor(
        @Inject('environment') public env: EnvironmentModel,
        public helpSupportService: WidgetHelpSupportService,
    ) {}

    ngOnInit() {
        for (const insight of this.help.insights) {
            if (insight.insight !== '' || insight.recommendations.some((element: any) => element !== '')) {
                this.showInsights = true;
            }
        }
    }

    handleAccordionPanelChange(event: NgbPanelChangeEvent) {
        const PANEL_OPENED_CLASS = 'opened-panel';

        if (this.openedPanel) {
            this.openedPanel.classList.remove(PANEL_OPENED_CLASS);
            this.openedPanel = null;
        }

        if (event.panelId) {
            this.openedPanel = this.accordionElement.nativeElement.querySelector(`[id='${event.panelId}-header']`);
            this.openedPanel.classList.toggle(PANEL_OPENED_CLASS, event.nextState);
        }
    }

    handleContentClick(event: any) {
        //const serviceUrl = !this.env.production ? this.env.serviceUrl : event.target.host;
        if (event.target.tagName === 'A') {
            const hash = event.target.hash;
            if (hash !== '') {
                const key = decodeURIComponent(hash.slice(1));
                event.preventDefault();
                const filteredData = this.helpSupportService.getFilteredData(key);
                if (filteredData && filteredData.length) {
                    window.open(`#/help-support/${filteredData[0].id}`, '_blank');
                }
            }
        }
    }
}
