import { Inject, Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { UserVariablesHelper } from '@app/models';
import { AuthService, KeycloakService } from '@app/auth';
import { FEATURE_TOGGLE_SYNC_KEYS, FeatureToggleService, UserService } from '@app/services';
import { ENV_TOKEN } from '@app/tokens';
import { configureScopeOfInterestPropName, PlatformSettingsService } from '@app/shared/services/admin/platform-settings.service';
import { DEFAULT_THEME } from 'src/app/pages/user/user.themes';
import { UserDefaultRoutesService } from '@app/shared/services/user/user-default-routes.service';

/**
 * It performs the task at application startup
 */
@Injectable({
    providedIn: 'root',
})
export class Starter {
    constructor(
        @Inject(ENV_TOKEN) private env: Env,
        private keycloakService: KeycloakService,
        private authService: AuthService,
        private userService: UserService,
        private platformSettings: PlatformSettingsService,
        private featureToggleService: FeatureToggleService,
        private userDefaultRoutesService: UserDefaultRoutesService,
        @Inject(FEATURE_TOGGLE_SYNC_KEYS) private featureToggleKeys: string[]
    ) {}

    async start(): Promise<any> {
        await this.keycloakService.login();
        return forkJoin([
            this.authService.init(),
            this.platformSettings.getAllSettings(),
            this.featureToggleService.preFetchFeatures(this.featureToggleKeys),
            this.featureToggleService.preFetchOnOffFeatures()
        ])
            .pipe(
                switchMap(async ([userManagementEnabled, platformSettings]) => {
                    // const scopeEnabled = states[1];

                    const configureScopeOfInterest = platformSettings[configureScopeOfInterestPropName];

                    const user = this.authService.getAuthenticatedUser();
                    const userDefaultRoute = await this.userDefaultRoutesService
                        .getDefaultRoute({ settings: platformSettings, checkUserRoute: true, checkPlatformRoute: true })
                        .toPromise();

                    document.documentElement.dataset.theme = user.variables.defaultTheme || DEFAULT_THEME;

                    const defaultPage = this.env.calibrate?.enabled
                        ? '/calibrate'
                        : userDefaultRoute
                        ? '/' + userDefaultRoute.route
                        : '/project';

                    if (userManagementEnabled) {
                        if (UserVariablesHelper.needToSaveProfile(user.variables)) {
                            this.userService.defaultPage = '/user/profile';
                        } else if (
                            configureScopeOfInterest &&
                            UserVariablesHelper.needToSaveScope(user.variables) &&
                            !this.env.calibrate?.enabled
                        ) {
                            this.userService.defaultPage = '/user/scope';
                        } else if (defaultPage) {
                            this.userService.defaultPage = defaultPage;
                        }
                    } else if (defaultPage) {
                        this.userService.defaultPage = defaultPage;
                    }

                    return true;
                })
            )
            .toPromise();
    }
}
