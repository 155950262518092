<div #hexagonContainer class="w-100 hexagon-container"></div>
<ng-container *ngIf="externalScoring else defaultScoring">
    <div class="hexagon-legend-ext" (mouseover)="tooltip = false">
        <div *ngFor="let bound of externalBounds" class="mr-2 d-inline-flex align-items-center justify-content-center">
            <svg class="mr-2" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="4" cy="4" r="4" [attr.fill]="bound.color"/>
            </svg>
            <span>{{ bound.label }}</span>
        </div>
    </div>
</ng-container>
<ng-template #defaultScoring>
    <div class="hexagon-legend" (mouseover)="tooltip = false">
        <div class="mb-1 good">Good</div>
        <div class="mb-1 average">Average</div>
        <div class="bad">Bad</div>
    </div>
</ng-template>
<div *ngIf="tooltip" #hexagonTooltip class="hexagon-tooltip" (mouseout)="tooltip = false"></div>
