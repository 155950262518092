import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';

export const DEFAULT_DURATION = 250;

export const animations = {
    collapse: trigger('collapse', [
        state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
        state('true', style({ height: '0', visibility: 'hidden' })),
        transition('false => true', animate(`${DEFAULT_DURATION}ms ease-in-out`)),
        transition('true => false', animate(`${DEFAULT_DURATION}ms ease-in-out`)),
    ]),
};
