import { CellEvent } from '@ag-grid-community/core';
import { isEmpty } from 'lodash';

import { AnyWidgetModel, WidgetDrilldown, WidgetType } from '../models/any-widget.model';
import { SandBox } from '../services/widget-builder.util';

export interface DrilldownEvent {
    target: string;
    payload: any;
    event?: any;
}

interface ChartEvent {
    payload: {
        barName: string;
        barId: string;
        name?: string;
        value?: any;
        event?: any;
        data?: any;
        dataIndex?: number;
    };
}

export function getDrilldownConverter(widgetType: WidgetType): (e: unknown) => DrilldownEvent {
    switch (widgetType) {
        case WidgetType.TABLE:
            return (e: CellEvent) => ({
                target: e.column?.getColId() || e.rowIndex.toString(),
                payload: e.data,
            });
        case WidgetType.TILE_CHART:
            return (e: any) => ({
                target: e.tileName,
                payload: e,
            });
        case WidgetType.SECURITY_POSTURE_DETAILS:
        case WidgetType.SRE_SQUARES:
            return (e: any) => ({
                target: e.drilldownTarget,
                payload: e,
            });
        case WidgetType.FACTOR_SCORES:
            return (e: any) => ({
                target: e,
                payload: e,
                event: e,
            });
        case WidgetType.TWO_DIMENSIONAL_TABLE:
            return (e: { data: string }) => ({
                target: null,
                payload: e.data,
            });
        case WidgetType.LINE_CHART:
            return (e: ChartEvent) => ({
                target: e.payload.barName,
                payload: {
                    label: e.payload.name,
                    series: e.payload.barName,
                    value: e.payload.value,
                    data: e.payload.data,
                },
            });
        case WidgetType.BAR_CHART:
        case WidgetType.BOXPLOT:
        case WidgetType.STACKED_BAR_CHART:
        case WidgetType.PIE_CHART:
        case WidgetType.DOUGHNUT_CHART:
            return (e: ChartEvent) => ({
                target: e.payload.barName,
                payload: e.payload.barName,
                event: e.payload.event || null,
            });
        case WidgetType.PROGRESS:
            return (e: string) => ({
                target: e,
                payload: e,
            });
        case WidgetType.ACCORDION:
            return (e: any) => ({
                target: e.colId,
                payload: e.data,
            });
        case WidgetType.PI_MULTIPLE_RADIALS:
            return (e: any) => ({
                target: e.title,
                payload: {
                    title: e.title,
                    value: e.value,
                },
            });
        case WidgetType.PI_PROGRESS_MENU:
        case WidgetType.SRE_HEXAGONS:
            return (e: any) => ({
                target: e.title,
                payload: e,
            });
        case WidgetType.TREEMAP:
            return (e: any) => ({
                target: e.payload.mapName,
                payload: e,
            });
        case WidgetType.SRE_GANTT:
            return (e: any) => ({
                target: e?.title,
                payload: e,
            });
        case WidgetType.TABLE_WITH_TABS:
            return (e: CellEvent & { tabIndex: number }) => ({
                target: `${e.column.getId()}-${e.tabIndex}`,
                payload: {
                    ...e.data,
                    tabIndex: e.tabIndex,
                },
            });
        case WidgetType.MULTIPLE_SERIES_TYPE:
            return (e: ChartEvent) => ({
                target: e.payload.barName,
                payload: {
                    label: e.payload.name,
                    data: e.payload.data,
                    dataIndex: e.payload.dataIndex,
                    series: e.payload.barName,
                    value: e.payload.value,
                },
            });
        case WidgetType.COMPLEX:
        case WidgetType.PI_FEATURE_COMPLETION_STATUS:
        case WidgetType.METRIC_TILE:
            return (e: any) => e;
        default:
            return null;
    }
}

export function findMatchedDrilldown(
    { drilldown, drilldownList }: AnyWidgetModel,
    event: DrilldownEvent,
    placeholders: Record<string, any>
): WidgetDrilldown | null {
    const sandBox = new SandBox();

    if (drilldown && isEmpty(drilldownList)) {
        return drilldown;
    }

    if (drilldownList.length === 1 && !drilldownList[0].target && !drilldownList[0].matcherScript) {
        return drilldownList[0];
    }

    let defaultDrilldown: WidgetDrilldown = null;

    for (const d of drilldownList) {
        if (matchDrilldown(sandBox, placeholders, event, d)) {
            return d;
        }

        if (d.target === event.target) {
            return d;
        }

        const drilldownTargetExp = new RegExp(`^${d.target}$`);

        if (event.target.match(drilldownTargetExp)) {
            return d;
        }

        if (d.default) {
            defaultDrilldown = d;
        }
    }

    return defaultDrilldown;
}

function matchDrilldown(
    sandbox: SandBox,
    placeholders: Record<string, unknown>,
    event: DrilldownEvent,
    { matcherScript }: WidgetDrilldown
) {
    if (!matcherScript) {
        return false;
    }

    try {
        const isMatchFn: Function = sandbox.buildFn(`return (function(event, placeholders){${matcherScript}})`);

        return !!isMatchFn(event, placeholders);
    } catch (e) {
        console.error(e);

        return false;
    }
}

interface DrilldownHistoryItem {
    widget: AnyWidgetModel;
    placeholders: Record<string, any>;
}

export class WidgetDrilldownHistory {
    private history: DrilldownHistoryItem[] = [];

    empty() {
        return !this.history.length;
    }

    reset() {
        this.history = [];
    }

    push(el: DrilldownHistoryItem) {
        this.history.push(el);
    }

    pop() {
        return this.history.pop();
    }
}
