import { Component, ElementRef, EventEmitter, inject, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DropdownItem, ModalService, ModalWarningComponent } from '@dagility-ui/kit';
import { BehaviorSubject, noop, of, Subject, timer } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { clone } from 'lodash';

import { ToasterService } from '@dagility-ui/kit/toastr';

import { DashboardFilterSetController, GLOBAL_FILTER_PROVIDERS } from '../../../widget-builder/directives/dashboard-filter-set.controller';
import { WidgetFilter, WidgetFilterType } from '../../..//widget-builder/models/any-widget.model';
import { PERSIST_APPLIED_FILTERS_TOKEN } from '../../services/persist-applied-filters.token';
import { DashboardExternalFilterDirective } from '../../directives/dashboard-external-filter.directive';
import { DpDashboardState } from '../../state/dp-dashboard.state.model';
import { DpDashboardComponent } from '../dp-dashboard/dp-dashboard.component';
import { DataMorph } from '../../models/dp-dashboard.model';
import {
    FiltersDirtyStateToken,
    GLOBAL_FILTERS_LOADING,
    GLOBAL_FILTERS_LOADING_PROVIDER,
    VISIBLE_FILTERS,
    VISIBLE_FILTERS_PROVIDER,
    WIDGET_FILTER_VISIBILITY_DEFAULT,
    withPanelWidth,
} from './dashboard-filter-set.tokens';
import { FILTERS_DIRTY_STATE_PROVIDER } from './filters-dirty-state';
import { animations } from './animations';

@Component({
    selector: 'dp-dashboard-filter-set[new]',
    templateUrl: './dashboard-filter-set.component.html',
    styleUrls: ['./dashboard-filter-set.component.scss'],
    providers: [
        GLOBAL_FILTER_PROVIDERS,
        VISIBLE_FILTERS_PROVIDER,
        GLOBAL_FILTERS_LOADING_PROVIDER,
        FILTERS_DIRTY_STATE_PROVIDER,
        withPanelWidth(350),
    ],
    animations: [animations.filtersPanelOpen, animations.slideInOut],
})
export class DashboardFilterSetComponent extends DashboardFilterSetController implements OnInit, OnDestroy {
    @Input() externalFilter: DashboardExternalFilterDirective;
    @Input() dashboard: DpDashboardState;
    @Input() event: EventEmitter<unknown>;

    @Output() filterValueChanged = new EventEmitter();
    @Output() filtersRecalculated = new EventEmitter();

    @ViewChild('funnelButton', { read: ElementRef, static: false })
    funnelButtonElement: ElementRef<HTMLElement>;

    widgetFilterVisibility = WIDGET_FILTER_VISIBILITY_DEFAULT;
    private dashboardComponent = inject(DpDashboardComponent, { optional: true });
    private destroy$ = new Subject<void>();
    private zone = inject(NgZone);
    private toaster = inject(ToasterService);
    private persistAppliedFiltersFn = inject(PERSIST_APPLIED_FILTERS_TOKEN, { optional: true }) ?? of;
    private filtersDirtyState = inject(FiltersDirtyStateToken);
    private filters$ = inject(VISIBLE_FILTERS);
    private modal = inject(ModalService);

    isSomeFilterIsNotAvailable$ = this.filters$.pipe(
        map((visibleFilters: WidgetFilter[]) =>
            visibleFilters.some(
                filter =>
                    filter.type === WidgetFilterType.DROPDOWN &&
                    !(this.debuggerState.dataSources[filter.placeholder] as BehaviorSubject<DropdownItem[]>)?.value?.length
            )
        )
    );

    expanded = false;
    loading$ = inject(GLOBAL_FILTERS_LOADING);
    visibleFilters$ = inject(VISIBLE_FILTERS);
    panelWidth = `350px`;

    ngOnInit() {
        if (this.dashboardComponent) {
            this.workflow.setDashboard(createDashboardStub(this.dashboardComponent, this.zone));
        }

        if (this.workflow.placeholders?.widgetFilterVisibility) {
            this.widgetFilterVisibility = this.workflow.placeholders.widgetFilterVisibility;
        }

        this.initServerFilters()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                if (this.store && !this.workflow.updateFromFilters) {
                    this.propagateFilterPlaceholders();
                }
            });
    }

    getWidgetFilterVisibility(value: string) {
        this.widgetFilterVisibility = value;
        this.workflow.placeholders.widgetFilterVisibility = this.widgetFilterVisibility;
    }

    propagateFilterPlaceholders(placeholders: Record<string, unknown> = this.workflow.placeholders) {
        if (!this.store) {
            return;
        }

        this.filtersDirtyState.updateAppliedFilters(placeholders);
        this.store.globalFilters$.next(placeholders);
    }

    expand() {
        this.funnelButtonElement.nativeElement.blur();

        if (this.expanded) {
            this.collapse();

            return;
        }

        this.expanded = true;
    }

    collapse() {
        if (this.modal.hasOpenModals()) {
            return;
        }

        this.expanded = false;
    }

    async apply() {
        await this.applyFilter();
        this.toaster.successToast({
            title: 'Success',
            content: 'Dashboard filters have been applied',
        });
    }

    async save() {
        const placeholders = await this.saveWithoutPreferred();
        placeholders.widgetFilterVisibility = this.widgetFilterVisibility;
        await this.applyFilter();

        timer(0)
            .pipe(switchMap(() => this.persistAppliedFiltersFn(this.dashboard.id ?? this.dashboard.dashboardId, placeholders)))
            .subscribe(() => {
                this.toaster.successToast({
                    title: 'Success',
                    content: 'Dashboard filters have been saved',
                });
                this.filtersDirtyState.updateLastSavedFilters(this.workflow.placeholders);
            });
    }

    resetToDefault() {
        this.collapse();
        this.workflow.reset();
        this.workflow.updateFromFilters = false;
    }

    resetToLastSaved() {
        this.collapse();
        this.event.emit({
            id: 'RESET_TO_LAST_SAVED',
            dashboardId: this.dashboard.id,
        });
    }

    async applyFilter() {
        this.propagateFilterPlaceholders(this.workflow.placeholders);
        this.collapse();
        this.workflow.updateFromFilters = false;
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    private async saveWithoutPreferred() {
        const { placeholders, preferredFilters, preferredItems, selectedPreferredValues } = this.workflow.getPlaceholdersForPersisting();

        if (preferredFilters.length) {
            const nonSavedPreferredFilter = preferredFilters.find(filter => selectedPreferredValues[filter.placeholder]?.length);

            if (nonSavedPreferredFilter && nonSavedPreferredFilter.label === 'Release') {
                const activeReleases = (preferredItems[nonSavedPreferredFilter.placeholder] ?? []).map(({ label }) => label).join(',');
                try {
                    await this.modal.open(
                        ModalWarningComponent,
                        {
                            centered: true,
                            windowClass: 'preferred-filter-popup',
                        },
                        {
                            message: {
                                content:
                                    `The release version number ${activeReleases} ` +
                                    'is dynamically updated whenever a new release becomes active.',
                                image: 'info',
                                description: '',
                                imageFontSize: '40px',
                                imageColor: 'var(--da-brand-base)',
                            },
                            showCancelButton: false,
                            confirmButtonText: 'OK',
                        }
                    ).result;
                } catch {}
            }
        }

        return placeholders;
    }
}

export function createDashboardStub(dashboard: DpDashboardComponent, zone: NgZone) {
    function selectTab(name: string) {
        const tabs = Object.values(dashboard.store.value.tabsMap);
        const nextTab = tabs.find(tab => tab.name === name);

        if (nextTab) {
            dashboard.handleTabChange({
                preventDefault: noop,
                nextId: nextTab.id,
                activeId: null,
            });
        }
    }

    function changeDashboard(name: string, placeholders: any) {
        zone.run(() => {
            dashboard.dashboardWidgetManager.getDashboardIdByName(name).subscribe(({ id }) => {
                if (placeholders.states?.length === 0) {
                    placeholders.states = getPreviousStates();
                }

                dashboard.changeDashboard([`../${id}`], { placeholders });
            });
        });
    }

    function changeGroupVisibility(groupName: string, state: DataMorph.DashboardGroupVisibility) {
        zone.run(() => {
            dashboard.store.changeGroupVisibility(groupName, state);
        });
    }

    function changeGroupVisibilityByKey(groupName: string, groupKey: number, state: DataMorph.DashboardGroupVisibility) {
        zone.run(() => {
            dashboard.store.changeGroupVisibilityByKey(groupName, groupKey, state);
        });
    }

    function emit(event: any) {
        zone.run(() => {
            setPreviousState({ state: event['state'] });

            dashboard.event.emit(event);
        });
    }

    function setPreviousState(state: any) {
        if (state && state.state) {
            dashboard.setPreviousState(state);
        }
    }

    function getPreviousStates(): any[] {
        return dashboard.previousMlfState ? clone(dashboard.previousMlfState) : [];
    }

    function getActiveTab() {
        const state = dashboard.store.value;

        return state.tabsMap[state.activeTab].name;
    }

    return {
        selectTab,
        changeDashboard,
        changeGroupVisibility,
        changeGroupVisibilityByKey,
        emit,
        getActiveTab,
    };
}
