export interface MultiLevelFilterItemInterface {
    id: string;
    previous: MultiLevelFilterItemInterface;
    name: string;
    favorite: boolean;
    selected: boolean;
    loading: boolean;
    items: MultiLevelFilterItem[];
}

export enum MultiLevelFilterViewMode {
    PORTFOLIO = 'PORTFOLIO',
    APPLICATION = 'APPLICATION',
    TEAM = 'TEAM',
}

export enum MultiLevelFilterDataMode {
    SYSTEM = 'SYSTEM',
    CUSTOM = 'CUSTOM',
}

export enum MultiLevelFilterItemType {
    PORTFOLIO = 'PORTFOLIO',
    MANAGER = 'MANAGER',
    APPLICATION = 'APPLICATION',
    TEAM = 'TEAM',
    NONE = 'NONE',
}

export enum MultiLevelFilterRelationshipType {
    FOLLOWER = 'FOLLOWER',
    CONTRIBUTOR = 'CONTRIBUTOR',
    OWNER = 'OWNER',
    SUBORDINATE = 'SUBORDINATE',
    NONE = 'NONE',
}

export enum MultiLevelFilterHeader {
    PORTFOLIO_WITH_MANAGER = 'Manager / Portfolio',
    PORTFOLIO = 'Portfolio',
    TEAM = 'Team',
    APPLICATION = 'Application',
    DEFAULT = 'Default',
}

export function getMultilevelFilterHeadersHierarchy(scope: MultiLevelFilterViewMode) {
    const hierarchy = {
        [MultiLevelFilterViewMode.TEAM]: [
            MultiLevelFilterHeader.PORTFOLIO_WITH_MANAGER,
            MultiLevelFilterHeader.APPLICATION,
            MultiLevelFilterHeader.TEAM,
        ],
        [MultiLevelFilterViewMode.APPLICATION]: [MultiLevelFilterHeader.PORTFOLIO_WITH_MANAGER, MultiLevelFilterHeader.APPLICATION],
        [MultiLevelFilterViewMode.PORTFOLIO]: [MultiLevelFilterHeader.PORTFOLIO],
    };

    return hierarchy[scope] ?? [MultiLevelFilterHeader.DEFAULT];
}

export class MultiLevelFilterItem implements MultiLevelFilterItemInterface {
    favorite: boolean;
    id: string;
    name: string;
    previous: MultiLevelFilterItem;
    selected: boolean;
    expanded: boolean;
    loading: boolean;
    hidden: boolean;
    items: MultiLevelFilterItem[];
    itemType: MultiLevelFilterItemType;
    relationshipType: MultiLevelFilterRelationshipType;

    constructor() {
        this.selected = false;
        this.loading = false;
        this.hidden = false;
        this.itemType = MultiLevelFilterItemType.NONE;
        this.relationshipType = MultiLevelFilterRelationshipType.NONE;
    }

    get hasChildPortfolio(): boolean {
        return hasChildPortfolio(this);
    }

    get childPortfolioItems(): MultiLevelFilterItem[] {
        return this.items != null ? this.items.filter(i => i.itemType === MultiLevelFilterItemType.PORTFOLIO) : null;
    }
}

export function hasChildPortfolio(filter: MultiLevelFilterItem) {
    return filter.items != null && filter.items.find(i => i.itemType === MultiLevelFilterItemType.PORTFOLIO) != null;
}
