<span *ngIf="showNodeDetailsFlag && nodeDetailsData.infoDetailsMap">
    <div class="node-details-wrap bg-white p-2 d-flex flex-column rounded border position-absolute">
        <div class="d-flex justify-content-between pt-5px pr-2">
            <span class="font-9px font-weight-bold">{{ title }}</span>
            <button type="button" class="close ml-auto" aria-label="Close button" aria-describedby="modal-title" (click)="dismiss()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="w-100 font-9px font-weight-600 pt-3 pr-3 pl-3 pb-0 node-details-text">
            <table class="w-100" aria-label="">
                <tr class="row" *ngFor="let nodeData of nodeDetailsData.infoDetailsMap | keyvalue">
                    <td style="width: 35%">{{ nodeData.key }}</td>
                    <td style="width: 10%">:</td>
                    <td style="width: 55%">{{ nodeData.value }}</td>
                </tr>
            </table>
        </div>
    </div>
</span>
