import { Component, Input } from '@angular/core';

@Component({
    selector: 'dp-top-priority-risks',
    templateUrl: './top-priority-risks.component.html',
    styleUrls: ['./top-priority-risks.component.scss'],
})
export class TopPriorityRisksComponent {
    @Input() data: any;
}
