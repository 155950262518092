import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { ENV_TOKEN } from '@app/tokens';
import { catchError, publishReplay, refCount } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class CheWorkspaceService {
    eclipseCheWorkspace = `${this.env.buildApiURL}/ci-cd/workspace`;

    private url$: Observable<string>;

    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {}

    getWorkspaceUrl(): Observable<string> {
        if (!this.url$) {
            const headers = new HttpHeaders({
                'skip-global-error': 'true',
            });
            this.url$ = this.http
                .get(`${this.eclipseCheWorkspace}/url`, {
                    headers,
                    responseType: 'text',
                })
                .pipe(
                    catchError(() => of(null)),
                    publishReplay(1), // this tells Rx to cache the latest emitted
                    refCount()
                );
        }

        return this.url$;
    }
}
