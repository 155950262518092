import { BehaviorSubject, combineLatest, Observable } from 'rxjs';

import { WatchVariable, JobDefinitionDebugState, DebugTabs, EvaluateResult } from './job-definition.state.model';
import { map } from 'rxjs/operators';
import { Store } from '@dagility-ui/kit';

export interface EvaluateModel {
    debugId: string;
    script: string;
}

export type DebugContext = Pick<
    JobDefinitionDebugState['debug'],
    'activeTab' | 'watches' | 'logs' | 'evaluateResult' | 'context' | 'loading'
>;

export class JobDefinitionDebug {
    collapsed$ = this.state.select(state => state.debug.actionsPanelCollapsed);
    loading$ = this.state.select(state => state.debug.loading);
    debug$ = this.state.select(state => state.debug.debug);
    debuggedBlockName$ = this.state.select(state => state.debug.debuggedBlockName);
    publish$ = this.state.select(state => state.debug.publish);

    activeTab$ = this.state.select(state => state.debug.activeTab);
    watches$ = this.state.select(state => state.debug.watches);
    logs$ = this.state.select(state => state.debug.logs);
    evaluateResult$ = this.state.select(state => state.debug.evaluateResult);
    context$ = this.state.select(state => state.debug.context);
    debugContext$: Observable<DebugContext> = combineLatest([
        this.activeTab$,
        this.watches$,
        this.logs$,
        this.evaluateResult$,
        this.context$,
        this.loading$,
    ]).pipe(
        map(([activeTab, watches, logs, evaluateResult, context, loading]) => ({
            activeTab,
            watches,
            logs,
            evaluateResult,
            context,
            loading,
        }))
    );
    debugVm$ = combineLatest([
        this.loading$,
        this.collapsed$,
        this.debug$,
        this.debuggedBlockName$,
        this.publish$,
        this.debugContext$,
    ]).pipe(
        map(([loading, collapsed, debug, debuggedBlockName, publish, debugContext]) => ({
            loading,
            collapsed,
            debug,
            debuggedBlockName,
            publish,
            debugContext,
        }))
    );

    evaluateScript = '';

    get debugId() {
        return this.debugId$.value;
    }
    set debugId(id: string | null) {
        this.debugId$.next(id);
    }
    debugId$ = new BehaviorSubject<string | null>(null);

    static getDefaultState(): JobDefinitionDebugState {
        return {
            debug: {
                activeTab: 'variables',
                context: {},
                logs: [],
                watches: [],
                evaluateResult: {
                    isError: false,
                    result: {},
                },
                debuggedBlockName: null,
                debug: false,
                loading: false,
                publish: false,
                actionsPanelCollapsed: true,
            },
        };
    }

    constructor(private state: Store<JobDefinitionDebugState>) {}

    setState(partialState: Partial<JobDefinitionDebugState['debug']>) {
        this.state.setState({
            debug: {
                ...this.state.value.debug,
                ...partialState,
            },
        });
    }

    get value() {
        return this.state.value.debug;
    }

    private evalVariable(ctx: Record<string, any>, expression: string): WatchVariable {
        try {
            const result = new Function(`with(this) { return ${expression}; }`).bind(ctx)();

            return {
                expression,
                result,
                [expression]: result,
            };
        } catch (e) {
            return {
                expression,
                invalid: true,
                [expression]: '<not available>',
            };
        }
    }

    getEvaluateModel = () =>
        ({
            script: this.evaluateScript,
            debugId: this.debugId,
        } as EvaluateModel);

    evaluate = (index: number, expression: string) => {
        const { watches, context } = this.value;

        this.setState({
            watches: watches.map((w, i) => (i === index ? this.evalVariable(context, expression) : w)),
        });
    };

    remove = (index: number) =>
        this.setState({
            watches: this.value.watches.filter((_, i) => index !== i),
        });

    addNew = () =>
        this.setState({
            watches: [{ editable: true, isNew: true, expression: '' }, ...this.value.watches],
        });

    updateDebugContainer = (context: Record<string, any>, logs: string[]) =>
        this.setState({
            logs,
            context,
            watches: this.value.watches.map(watch => (watch.editable ? watch : this.evalVariable(context, watch.expression))),
        });

    updateEvaluateResult = (evaluateResult: EvaluateResult) =>
        this.setState({
            evaluateResult,
        });

    changeTab = (activeTab: DebugTabs) => this.setState({ activeTab });

    setFetch = (loading: boolean) => this.setState({ loading });

    actionsPanelToggle = () =>
        this.setState({
            actionsPanelCollapsed: !this.value.actionsPanelCollapsed,
        });

    resetDebug() {
        this.debugId = null;
    }
}
