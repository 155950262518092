import { DropdownOptionItem } from './widget-builder.service';

export class PreferredFiltersContainer extends Map<string, Set<unknown>> {
    process(placeholder: string, options: DropdownOptionItem<unknown>[]) {
        this.set(placeholder, new Set());

        if (!Array.isArray(options)) {
            return;
        }

        options.forEach(item => {
            if (item.preferred) this.get(placeholder).add(item.value);
        });
    }
}
