import { GridListItem, IGridsterOptions } from 'angular2gridster';
import { DataMorph } from 'data-processor';
import { capitalize } from 'lodash';

export const GRIDSTER_BREAKPOINTS = ['sm', 'md', 'lg', 'xl'] as const;
export type GridsterBreakpoint = typeof GRIDSTER_BREAKPOINTS[number];
export type GridsterMinSizes = Record<GridsterBreakpoint, GridsterWidgetOptions>;
export type WidgetWithMinSizes = DataMorph.Widget & { breakpointMinSizes?: GridsterMinSizes };
export type GridsterWidgetOptions = Partial<{
    minWidth: number;
    minHeight: number;
    maxWidth: number;
    maxHeight: number;
}>;
export type GridsterProperty = keyof Pick<GridListItem, 'w' | 'h' | 'x' | 'y'>;
export type GridsterResponsiveOptions = Partial<Record<`${GridsterProperty}${Capitalize<GridsterBreakpoint | ''>}`, number>>;

export const LANES_BY_BREAKPOINTS: Record<GridsterBreakpoint, number> = {
    sm: 4,
    md: 6,
    lg: 8,
    xl: 12,
};

export const GRIDSTER_OPTIONS: IGridsterOptions = {
    lanes: LANES_BY_BREAKPOINTS.sm,
    direction: 'vertical',
    floating: true,
    dragAndDrop: false,
    resizable: false,
    shrink: true,
    useCSSTransforms: true,
    responsiveSizes: true,
    responsiveOptions: [
        {
            breakpoint: 'sm',
            lanes: LANES_BY_BREAKPOINTS.sm,
        },
        {
            breakpoint: 'md',
            minWidth: 768,
            lanes: LANES_BY_BREAKPOINTS.md,
        },
        {
            breakpoint: 'lg',
            lanes: LANES_BY_BREAKPOINTS.lg,
        },
        {
            breakpoint: 'xl',
            minWidth: 1440,
            lanes: LANES_BY_BREAKPOINTS.xl,
        },
    ],
};

export const MIN_WIDGET_SIZE: number = 2;

export const DEFAULT_WIDTH_PROPORTION = 6 / 12;
export const DEFAULT_HEIGHT_PROPORTION = 5 / 12;
export const MIN_WIDTH_PROPORTION = 6 / 12;
export const MIN_HEIGHT_PROPORTION = 4 / 12;


export function buildResponsiveOption(breakpoint: GridsterBreakpoint, property: GridsterProperty): keyof GridsterResponsiveOptions {
    return `${property}${capitalize(breakpoint)}` as any;
}
