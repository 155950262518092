import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    inject,
    Input,
    QueryList,
    TrackByFunction,
    ViewChild,
    ViewChildren,
    Injector,
    Directive,
} from '@angular/core';
import { NgbNav, NgbNavContent, NgbNavItem, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { concat, defer, zip } from 'rxjs';
import { defaultIfEmpty } from 'rxjs/operators';
import { ModalService } from '@dagility-ui/kit';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { DATA_MORPH_FEATURE_TOGGLE } from 'data-processor/tokens';
import { DpDashboardGroupReorderDialogComponent } from 'data-processor/lib/widget-library/dashboard/components/dp-dashboard-group-reorder-dialog/dp-dashboard-group-reorder-dialog.component';
import { ToasterService } from '@dagility-ui/shared-components';
import { DashboardTabState, DpDashboardState } from '../../state/dp-dashboard.state.model';
import { DpDashboardStore } from '../../state/dp-dashboard.store';
import { DP_DASHBOARD_DEFAULT_PLACEHOLDERS_PROVIDER } from '../../services/default-placeholders.provider';
import { DpEditGroupComponent } from '../dp-edit-group/dp-edit-group.component';
import { DpDashboardGroupComponent } from '../dp-dashboard-group/dp-dashboard-group.component';
import { DataMorph } from '../../models/dp-dashboard.model';
import { DpDashboardService } from '../../services/dp-dashboard.service';

@Component({
    selector: 'dp-dashboard-tab',
    templateUrl: './dp-dashboard-tab.component.html',
    styleUrls: ['./dp-dashboard-tab.component.scss'],
    providers: [
        {
            provide: NgbNavItem,
            useExisting: DpDashboardTabComponent,
        },
        DP_DASHBOARD_DEFAULT_PLACEHOLDERS_PROVIDER,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DpDashboardTabComponent extends NgbNavItem {
    @Input() tab: DashboardTabState;

    @Input() dashboardId: string;

    @Input() activeId: number;

    @Input() expandedGroups: number[] = [];

    @Input() edit: boolean;

    @Input() loaded = false;

    @Input() groups: DpDashboardState['groupsMap'];

    @Input() widgetsPlaceholders: any;

    @Input() visibility: DataMorph.DashboardTabVisibility;

    @Input() extensionsEnabled = false;

    @Input() tabsLength: number;

    @ViewChild(NgbNavContent, { static: true }) contentTpl: NgbNavContent;
    @ViewChildren(DpDashboardGroupComponent) dpDashboardGroups: QueryList<any>;

    groupVisibility: typeof DataMorph.DashboardGroupVisibility = DataMorph.DashboardGroupVisibility;

    get active() {
        return this.activeId === this._id;
    }

    get showTabHeader() {
        return (
            this.edit ||
            !this.visibility ||
            this.visibility === DataMorph.DashboardTabVisibility.SHOW_ALL ||
            (DataMorph.DashboardTabVisibility.SHOW_ACTIVE === this.visibility && this.active)
        );
    }

    widgets$ = this.store.select(state => state.widgetsMap);

    constructor(
        private nav: NgbNav,
        elementRef: ElementRef,
        public store: DpDashboardStore,
        public cdr: ChangeDetectorRef,
        private toaster: ToasterService,
        private modalService: ModalService,
        private dpDashboardService: DpDashboardService,
        private injector: Injector
    ) {
        super(nav, elementRef);
    }

    ngAfterContentChecked() {
        // overridden ngbnavitem method
    }

    trackByGroup: TrackByFunction<number> = (_: number, group: number) => group;

    groupId = (group: number) => `g-${group}`;

    handleGroupToggle({ panelId, nextState }: NgbPanelChangeEvent) {
        this.store.setGroupState(this.tab.id, panelId, nextState);
    }

    public handleExportTab(format: string, pdf: any = null, newExport: boolean = false) {
        this.dpDashboardGroups.forEach(group => {
            group.cdr.detectChanges();
        });

        if (newExport) {
            return concat(...this.dpDashboardGroups.map(group => defer(() => group.handleExportGroups(format, pdf, newExport)))).pipe(
                defaultIfEmpty([])
            );
        }

        return zip(
            ...this.dpDashboardGroups.map(group => {
                return group.handleExportGroups(format, pdf, newExport);
            })
        ).pipe(defaultIfEmpty([]));
    }

    openReorderDialog() {
        this.modalService
            .open(
                DpDashboardGroupReorderDialogComponent,
                {
                    windowClass: 'dashboard-groups-reorder-dialog',
                },
                {
                    groups: this.tab.groups.map(id => this.groups[id]),
                    dashboardId: this.dashboardId,
                    tabId: this.tab.id,
                }
            )
            .result.then(groupIds => {
                if (groupIds) {
                    this.tab.groups = groupIds;
                    this.tab.groups.forEach((id, i) => {
                        this.groups[id].groupOrder = i;
                    });
                    this.cdr.detectChanges();
                }
            });
    }

    async handleAddGroup() {
        const { activeTab, dashboardId } = this.store.value;

        try {
            const editedGroup = await this.modalService.open(DpEditGroupComponent, { injector: this.injector }, { modal: true }).result;
            const group = await this.dpDashboardService
                .saveGroup({
                    ...editedGroup,
                    id: null,
                    dashboardId,
                    tabId: activeTab,
                })
                .toPromise();

            this.toaster.successToast({
                title: 'Success',
                content: 'Group created successfully',
            });
            this.store.addGroup(group);
        } catch {
            // noop
        }
    }
}

@Directive({
    selector: '[cdkDrag][actualContainer]',
})
export class CdkDropListActualContainerDirective {
    @Input('actualContainer') actualContainer: string;

    constructor(cdkDrag: CdkDrag, private elRef: ElementRef) {
        cdkDrag._dragRef.beforeStarted.subscribe(() => {
            const cdkDropList = cdkDrag.dropContainer;

            if (this.actualContainer) {
                const element = this.elRef.nativeElement.closest(this.actualContainer) as HTMLElement;
                cdkDropList._dropListRef.element = element;
                cdkDropList.element = new ElementRef<HTMLElement>(element);
            }
        });
    }
}
