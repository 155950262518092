<div class="d-flex align-items-center disable">
    <fa-icon class="font-16px" icon="clock"></fa-icon>
    <span class="mx-2">{{ commit.time }}</span>
    <dp-divider type="circle"></dp-divider>
    <span class="mx-2 primary-text">{{ commit.author }}</span>
    <dp-divider type="circle"></dp-divider>
    <button
        class="btn btn-primary ml-2 sda-badge default-size"
        ngbTooltip="Click to Copy"
        [copyToClipboard]="commit.sha"
        (copied)="handleCopied()"
    >
        {{ commit.sha | slice: 0:8 }} <fa-icon class="ml-2" icon="facClone"></fa-icon>
    </button>
</div>
<div class="commit-message mt-1">
    {{ commit.message }}
</div>
