import { Component, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { filter as filterObs } from 'rxjs/operators';

import { NewRangeFilter } from '../../../../models/any-widget.model';
import { BaseWidgetFilter } from '../base-widget-filter.component';

interface NewDateRangeValue {
    startDate: Date | null;
    endDate: Date | null;
}

const ONE_DAY_IN_MS = 1000 * 3600 * 24;

@Component({
    selector: 'dp-new-range-filter',
    template: `
        <lib-datepicker
            class="size-s"
            mode="range"
            [formControl]="formControl"
            [label]="filter.hideLabel ? '' : filter.label"
            [placeholder]="filter.placeholderText || 'Select Date'"
        >
        </lib-datepicker>
        <div *ngIf="filter.showDaysCount" class="days-count mt-1">{{ daysCount }}</div>
    `,
    styleUrls: ['new-range-filter.component.scss'],
})
export class NewRangeFilterComponent extends BaseWidgetFilter {
    get formValue(): NewDateRangeValue {
        return this.formControl.value;
    }

    get daysCount() {
        const { startDate, endDate } = this.formValue || {};

        if (!endDate) {
            return 'Choose End Date';
        }

        const difference = Math.round((new Date(endDate).getTime() - new Date(startDate).getTime()) / ONE_DAY_IN_MS);

        return `Selected Days Count: ${difference + 1}`;
    }

    constructor(@Inject(BaseWidgetFilter.FILTER_TOKEN) public filter: NewRangeFilter) {
        super(filter);
    }

    getFilterText(): { title: string; value: string } {
        return { title: this.filter.label, value: this.formValue.toString() };
    }

    registerOnChange(fn: (_: NewDateRangeValue) => void): void {
        this.subscription.unsubscribe();

        this.subscription = (this.formControl.valueChanges as Observable<NewDateRangeValue>)
            .pipe(filterObs(({ startDate, endDate }) => !!startDate === !!endDate))
            .subscribe(value => {
                fn(value);
            });
    }
}
