<div class="modal-header">
    <h6 class="modal-title font-14px">{{ title }}</h6>
    <div class="pull-right">
        <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>
<div class="modal-body" [formGroup]="form">
    <div class="row no-gutters mb-30px">
        {{ subTitle }}
    </div>
    <div class="row no-gutters">
        <lib-textarea class="col pl-0"
                      formControlName="changelog"
                      id="changelog"
                      [resize]="false"
                      [label]="'Changelog'">
        </lib-textarea>
    </div>
    <div class="row no-gutters mt-30px">
        <lib-textarea class="col pl-0"
                      formControlName="comment"
                      id="comment"
                      [resize]="false"
                      [label]="'Comments for Reviewer'">
        </lib-textarea>
    </div>
</div>
<div class="modal-footer">
    <div class="d-inline-flex justify-content-end align-items-center">
        <button class="btn btn-secondary font-11px" (click)="handleCancel()">Cancel</button>
        <button
            class="btn btn-primary font-11px ml-20px"
            (click)="handleSend()"
        >
            Send
        </button>
    </div>
</div>
