<div
    *ngIf="service.getValueWithUnitName(value.unscaleValue.to - value.unscaleValue.from) as valueWithUnitName"
    class="position-absolute value"
    [class.value-active]="isSelected"
    [style.left.px]="value.from"
    [style.width.px]="value.to - value.from"
    [style.background-color]="value.color ? value.color : ''"
>
    {{ value.name }} [{{ valueWithUnitName.value | number: '1.0-3' }}{{ valueWithUnitName.unitName }}]
</div>
