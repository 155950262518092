export const CI_CD_MODULE_SEGMENT = 'conveyor';
export const APPLICATIONS_SOURCES_SEGMENT = 'application-sources';
export const TEMPLATE_EDITOR_SEGMENT = 'edit-template';
export const PIPELINE_BUILDER_SEGMENT = 'pipeline-builder';
export const WORKFLOW_BUILDER_SEGMENT = 'workflow-builder';
export const PROJECT_MODULE_SEGMENT = 'project';
export const INSIGHT_MODULE_SEGMENT = 'illuminate';
export const ENVIRONMENT_MODULE_SEGMENT = 'habitat';
export const CALIBRATE_MODULE_SEGMENT = 'calibrate';
export const XPRESSO_MODULE_SEGMENT = 'xpresso';
export const OBSERVATORY_MODULE_SEGMENT = 'observatory';
export const WORKSPACES_MODULE_SEGMENT = 'workspaces';
export const DATA_MORPH_MODULE_SEGMENT = 'data-morph';
export const KEEPNI_MODULE_SEGMENT = 'keepni';
export const NOSKRIPT_MODULE_SEGMENT = 'noskript';
export const MCM_MODULE_SEGMENT = 'mcm';
