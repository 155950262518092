import { Component } from '@angular/core';
import { ControlContainer, FormArray, FormGroupDirective } from '@angular/forms';
import { WidgetBuilderFacade } from 'data-processor/lib/widget-library/widget-builder/state/widget-builder.facade';
import { WidgetEventDependency } from 'data-processor';

@Component({
    selector: 'dp-widget-builder-event-dependencies',
    templateUrl: './widget-builder-event-dependencies.component.html',
    styleUrls: ['./widget-builder-event-dependencies.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class WidgetBuilderEventDependenciesComponent {
    get eventDependencies(): FormArray {
        return this.parent.control.get('eventDependencies') as FormArray;
    }

    constructor(public parent: FormGroupDirective, public facade: WidgetBuilderFacade) {}

    addEventDependency(): void {
        this.eventDependencies.push(this.facade.buildEventDependencyGroup({} as WidgetEventDependency));
    }

    removeDependency(index: number): void {
        this.eventDependencies.removeAt(index);
    }
}
