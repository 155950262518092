<ng-container [ngSwitch]="block.viewType">
    <ng-container *ngSwitchCase="'layer'">
        <ng-container *ngIf="isLayerBlock(block) as layerBlock">
            <div class="layer-header d-flex overflow-hidden">
                <span class="text-truncate">
                    {{ layerBlock.name }}
                </span>

                <fa-icon *ngIf="!readonly" class="ml-auto mr-1" icon="cog" (click)="!readonly && editBlock.emit(block)"></fa-icon>
            </div>

            <div class="drilldown-container">
                <ng-container *ngIf="layerBlock.messageBus">
                    <span class="block-header position-relative">
                        Message Bus
                    </span>
                    <div class="drilldown-block" *ngFor="let handler of $any(block).handlers" [class.active]="activeBlockId === handler.id">
                        <span class="text-truncate">
                            {{ handler.eventId }}
                        </span>
                        <fa-icon
                            *ngIf="!readonly"
                            icon="facEdit"
                            class="ml-auto"
                            (click)="!readonly && editEventHandler.emit(handler)"
                        ></fa-icon>
                    </div>
                    <div [class.not-allowed]="readonly" class="add-area position-relative" (click)="!readonly && addEventHandler.emit()">
                        <fa-icon class="mr-1" icon="plus"></fa-icon>
                        Add Event Handler
                    </div>
                </ng-container>
                <ng-container *ngIf="showDrilldown && layerBlock.data.type | mapper: hasDrilldown">
                    <span class="block-header position-relative">Drilldowns</span>
                    <div
                        *ngFor="let drilldown of $any(block).actions; let i = index"
                        class="drilldown-block"
                        [class.active]="activeBlockId === drilldown.id"
                    >
                        <span class="text-truncate">
                            {{ drilldown.name }}
                        </span>
                        <fa-icon
                            *ngIf="!readonly"
                            icon="facEdit"
                            class="ml-auto"
                            (click)="!readonly && editDrilldown.emit(drilldown)"
                        ></fa-icon>
                    </div>
                    <div [class.not-allowed]="readonly" class="add-area position-relative" (click)="!readonly && addDrilldown.emit()">
                        <fa-icon class="mr-1" icon="plus"></fa-icon>
                        Add Drilldown
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'filters'">
        <span class="block-header">Filters</span>
        <div class="add-area" [class.not-allowed]="readonly" (click)="!readonly && addFilter.emit($any(block).layer.id)">
            <fa-icon class="mr-1" icon="plus"></fa-icon>
            Add Filter
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'queries'">
        <span class="block-header">Queries</span>
        <div class="add-area" [class.not-allowed]="readonly" (click)="!readonly && addQuery.emit($any(block).layer.id)">
            <fa-icon class="mr-1" icon="plus"></fa-icon>
            Add Query
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'async-queries'">
        <span class="block-header">Asynchronous Queries</span>
        <div class="add-area" [class.not-allowed]="readonly" (click)="!readonly && addAsyncQuery.emit($any(block).layer.id)">
            <fa-icon class="mr-1" icon="plus"></fa-icon>
            Add Async Query
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'query'">
        <ng-container *ngIf="!!$any(block).name" [ngTemplateOutlet]="headerTemplate"></ng-container>
        <div
            *ngFor="let script of ['Before', 'DataSource', 'After']; let position = index"
            class="script-block"
            [class.active]="debugPosition && debugPosition.queryId === block.id && debugPosition.scriptIndex === position"
        >
            {{ script }} Script <mandatory class="ml-1" *ngIf="script === 'DataSource'"></mandatory>
            <em
                *ngIf="block.debuggable"
                class="ml-auto breakpoint--icon"
                [class.active]="$any(block).debuggingScripts.includes(position)"
                (click)="handleToggleBreakpoint(position)"
            ></em>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'filter'">
        <ng-template [ngTemplateOutlet]="headerTemplate"></ng-template>
        <div style="height: 28px" class="script-block p-2 d-flex align-items-center">
            {{ $any(block).type | titlecase }}
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'filter-dynamic'">
        <ng-template [ngTemplateOutlet]="headerTemplate"></ng-template>
    </ng-container>
</ng-container>

<ng-template #headerTemplate>
    <span class="query-header" [class.position-relative]="block.viewType === 'query'">
        <span class="text-truncate">{{ $any(block).name }}</span>
        <fa-icon *ngIf="!readonly" class="ml-auto cursor-pointer" icon="facEdit" (click)="editBlock.emit(block)"></fa-icon>
    </span>
</ng-template>
