import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { removeOldField, prettifyObject } from 'data-processor/lib/common';
import { removeOldFields, transformFilters } from 'data-processor/lib/widget-library/widget-builder/services/widget.lines';
import { EnvironmentModel } from '@dagility-ui/kit';

import { DataMorph } from '../models/dp-dashboard.model';
import { AnyWidgetModel, WidgetFilter } from '../../widget-builder/models/any-widget.model';

@Injectable()
export class DpDashboardService {
    private readonly insightUrl = `${this.env.insightApiURL}`;

    constructor(@Inject('environment') private env: EnvironmentModel, private http: HttpClient) {}

    createDashboard(dashboard: DataMorph.Dashboard): Observable<DataMorph.Dashboard> {
        return this.http.put<DataMorph.Dashboard>(`${this.insightUrl}/dashboard/saveDashboard`, dashboard);
    }

    updateDashboard(dashboard: DataMorph.Dashboard): Observable<Omit<DataMorph.Dashboard, 'tabs'>> {
        return this.http.post<DataMorph.Dashboard>(`${this.insightUrl}/dashboard/updateDashboard`, dashboard);
    }

    getDpDashboard(id: string): Observable<DataMorph.Dashboard> {
        return this.http.get<DataMorph.Dashboard>(`${this.insightUrl}/dashboard/getDashboardById/${id}`);
    }

    getDashboards(): Observable<DataMorph.Dashboard[]> {
        return this.http.get<DataMorph.Dashboard[]>(`${this.insightUrl}/dashboard/getDashboards`);
    }

    saveWidgetOnDashboard(widget: DataMorph.DashboardWidget) {
        return this.http.put<DataMorph.DashboardWidget>(`${this.insightUrl}/constructor/saveWidgetOnDashboard`, widget);
    }

    updateWidgetOnDashboard(widget: DataMorph.DashboardWidget) {
        return this.http.post<DataMorph.DashboardWidget>(`${this.insightUrl}/constructor/updateWidgetOnDashboard`, widget);
    }

    getWidgets(dashboardId: string): Observable<DataMorph.Widget[]> {
        return this.http.get<DataMorph.Widget[]>(`${this.insightUrl}/constructor/getWidgetsByDashboard/${dashboardId}`);
    }

    getDpWidget(widgetId: number, dashboardId: number) {
        return this.http.get<DataMorph.Widget>(`${this.insightUrl}/constructor/getWidgetByDashboard/${dashboardId}/${widgetId}`);
    }

    getWidget(id: number): Observable<DataMorph.Widget> {
        return this.http.get<DataMorph.Widget>(`${this.insightUrl}/getFullWidgetById/`);
    }

    getDashboardWidget(dashboardWidgetId: number): Observable<DataMorph.DashboardWidget> {
        return this.http.get<DataMorph.DashboardWidget>(`${this.insightUrl}/constructor/getDashboardWidget/${dashboardWidgetId}`);
    }

    deleteWidgetFromDashboard(dashboardWidgetId: number): Observable<void> {
        return this.http.delete<void>(`${this.insightUrl}/constructor/deleteDashboardWidget/${dashboardWidgetId}`);
    }

    getGroupsByDashboardId(id: number): Observable<DataMorph.DashboardGroup[]> {
        return this.http.get<DataMorph.DashboardGroup[]>(`${this.insightUrl}/dashboard/getGroupsByDashboardId/${id}`);
    }

    saveDashboardTab(tab: DataMorph.DashboardTab): Observable<DataMorph.DashboardTab> {
        return this.http.put<DataMorph.DashboardTab>(`${this.insightUrl}/dashboard/saveDashboardTab`, tab);
    }

    updateDashboardTab(tab: DataMorph.DashboardTab): Observable<DataMorph.DashboardTab> {
        return this.http.post<DataMorph.DashboardTab>(`${this.insightUrl}/dashboard/updateDashboardTab`, tab);
    }

    updateTabsOrder(dashboardId: DataMorph.Dashboard['dashboardId'], tabIds: number[]) {
        return this.http.post<number[]>(`${this.insightUrl}/dashboard/${dashboardId}/updateTabsOrder`, tabIds);
    }

    addTab(tab: DataMorph.DashboardTab): Observable<DataMorph.DashboardTab> {
        return this.http.put<DataMorph.DashboardTab>(`${this.insightUrl}/dashboard/saveDashboardTab`, tab);
    }

    deleteTab(id: DataMorph.DashboardTab['id']): Observable<void> {
        return this.http.delete<void>(`${this.insightUrl}/dashboard/deleteDashboardTabById/${id}`);
    }

    saveGroup(group: DataMorph.DashboardGroup): Observable<DataMorph.DashboardGroup> {
        return this.http.put<DataMorph.DashboardGroup>(`${this.insightUrl}/dashboard/saveGroup`, group);
    }

    updateGroup(group: DataMorph.DashboardGroup): Observable<DataMorph.DashboardGroup> {
        return this.http.post<DataMorph.DashboardGroup>(`${this.insightUrl}/dashboard/updateGroup`, group);
    }

    updateGroupsOrder(dashboardId: string, tabId: string, groups: string[]): Observable<DataMorph.DashboardGroup> {
        return this.http.post<DataMorph.DashboardGroup>(`${this.insightUrl}/dashboard/${dashboardId}/tab/${tabId}/updateGroupsOrder`, groups);
    }

    deleteGroup(id: DataMorph.DashboardGroup['id']): Observable<void> {
        return this.http.delete<void>(`${this.insightUrl}/dashboard/deleteGroupById/${id}`);
    }

    updateDashboardFilters(dashboard: DataMorph.Dashboard): Observable<void> {
        return this.http.post<void>(`${this.insightUrl}/dashboard/updateDashboardFilters`, dashboard);
    }

    updateDashboardOptions(dashboard: DataMorph.Dashboard): Observable<void> {
        return this.http.post<void>(`${this.insightUrl}/dashboard/updateDashboardOptions`, dashboard);
    }

    exportDashboard(id: string): Observable<any> {
        return this.http.get<DashboardInfo>(`${this.insightUrl}/dashboard/getExportDashboard/${id}`).pipe(
            map((data: any) => (prettifyObject(data) as unknown) as DashboardInfo),
            map(data => {
                transformFilters(data.options?.filters ?? ([] as any), removeOldField);

                for (const tab of data.tabs ?? []) {
                    for (const group of tab.groups ?? []) {
                        for (const widget of group.widgets ?? []) {
                            removeOldFields(widget.widgetImport);
                        }
                    }
                }

                return data;
            })
        );
    }

    importDashboard(dashboard: DataMorph.Dashboard): Observable<number> {
        return this.http.post<number>(`${this.insightUrl}/dashboard/importDashboard`, dashboard);
    }

    exportDashboardToPPTX(dashboard: any) {
        return this.http.post<void>(`${this.insightUrl}/dashboard/getExportDashboardPpt`, dashboard, { responseType: 'blob' } as any);
    }

    createDefaultTabAndGroup(dashboardId: number) {
        return this.saveDashboardTab({
            id: null,
            name: 'Default Tab',
            groups: [],
            dashboardId,
            options: {
                tabOrder: 0,
            },
        }).pipe(
            switchMap(tab =>
                this.saveGroup({
                    dashboardId,
                    tabId: tab.id,
                    name: 'Default Group',

                    groupOrder: 0,
                    id: null,
                    definition: {
                        type: DataMorph.DashboardGroupVisibility.DEFAULT,
                        headerColor: '#3C4043',
                        headerFontSize: 12,
                    },
                })
            )
        );
    }
}

export interface DashboardInfo {
    name: string;
    dashboardUuid: string;
    scope: string;
    template: boolean;
    dynamic: boolean;
    status: boolean;
    options: { filters: WidgetFilter };
    changeWidgets: boolean;
    hideMLF: boolean;
    hideGlobalFilter: boolean;
    sequence: number;
    tabs: Array<
        DataMorph.DashboardTab & { groups: Array<DataMorph.DashboardGroup & { widgets: Array<{ widgetImport: AnyWidgetModel }> }> }
    >;
}
