<ng-container *libLet="isSmallView$ | async as isSmallView">
    <div class="d-flex flex-1 overflow-hidden my-3">
        <lib-gauge-chart
            class="mx-5"
            [class.mx-5]="!isSmallView"
            [class.mx-2]="isSmallView"
            [startAngle]="-150"
            [endAngle]="150"
            [maximum]="model.maxValue"
            [value]="model.value"
            [gaugeColor]="model.thresholdColor"
        ></lib-gauge-chart>

        <div class="score-container d-flex justify-content-center flex-column">
            <div *ngIf="model.delta != 0" class="delta-{{ model.delta > 0 ? 'up' : 'down' }}">
                <fa-icon icon="triangle{{ model.delta > 0 ? 'Up' : 'Down' }}"></fa-icon>
                {{ model.delta > 0 ? '+' : '' }} {{ model.delta | number: '1.0-1' }} <span>{{ model.measure }}</span>
            </div>
            <div class="current-score">
                Score <span class="current-score-value" [class.current-score-value-sm]="isSmallView">{{ model.value | number: '1.1-1' }}</span
                >/{{ model.maxValue }}
            </div>
            <div [class.mt-3]="!isSmallView" [class.mt-1]="isSmallView">{{ model.risk }} Risk</div>
        </div>
    </div>
    <div class="note d-flex">
        <div class="px-4 py-3 note-header" [class.py-3]="!isSmallView" [class.py-2]="isSmallView">Note</div>
        <div
            class="mx-4 my-3 overflow-hidden note-description"
            [class.my-3]="!isSmallView"
            [class.my-2]="isSmallView"
            [ngbTooltip]="model.note"
            triggers="manual"
            #tooltipRef="ngbTooltip"
            #noteRef
            (mouseenter)="noteRef.clientHeight < noteRef.scrollHeight && tooltipRef.open()"
            (mouseleave)="tooltipRef.close()"
        >
            {{ model.note }}
        </div>
    </div>
</ng-container>
