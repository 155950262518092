import { Inject, Injectable } from '@angular/core';
import { ENV_TOKEN } from '@app/tokens';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TeamsNotificationsService {
    private readonly BASE_URL = `${this.env.notificationsApiURL}/teams-notifications`;

    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {}

    getTeamsAppLink() {
        return this.http.get(`${this.BASE_URL}/teams-app-link`, { responseType: 'text' });
    }

    confirmTeamsAccount(referenceId: string) {
        return this.http.post(`${this.BASE_URL}/save-teams-reference/${referenceId}`, null);
    }

    getCredentials(): Observable<TeamsBotCredentials> {
        return this.http.get<TeamsBotCredentials>(`${this.BASE_URL}/credentials`);
    }

    saveCredentials(teamsBotCredentials: TeamsBotCredentials): Observable<void> {
        return this.http.post<void>(`${this.BASE_URL}/credentials`, teamsBotCredentials);
    }

    enableTeamsSettings(enable: boolean): Observable<void> {
        return this.http.post<void>(`${this.BASE_URL}/credentials/enable`, { enable });
    }
}

export interface TeamsBotCredentials {
    appId: string;
    appLink: string;
    secret: string;
    enabled: boolean;
}
