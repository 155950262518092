import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { MergeRequestStatus } from '../models/merge-request-status';

@Component({
    selector: 'dp-merge-request-status',
    template: '{{statusText[status]}}',
    styleUrls: ['./merge-request-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'custom-badge text-white',
    },
})
export class MergeRequestStatusComponent {
    @HostBinding('attr.merge-status') @Input() status: MergeRequestStatus;

    readonly statusText: Record<MergeRequestStatus, string> = {
        [MergeRequestStatus.MERGES_WITH_UPDATES]: 'Merged - With Updates',
        [MergeRequestStatus.MERGED_NO_UPDATES]: 'Merged - No Updates',
        [MergeRequestStatus.OPEN]: 'Open',
        [MergeRequestStatus.ABANDONED]: 'Abandoned',
    };
}
