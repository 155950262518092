import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

import { CronEditorComponent } from './cron-editor/cron-editor.component';
import { CronTimePickerComponent } from './cron-time-picker/cron-time-picker.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LibFormsModule } from '@dagility-ui/kit';


@NgModule({
    declarations: [CronEditorComponent, CronTimePickerComponent],
    imports: [
        CommonModule,
        NgbNavModule,
        LibFormsModule,
        ReactiveFormsModule,
    ],
    exports: [
        CronEditorComponent,
    ],
})
export class CronEditorModule { }
