import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ENV_TOKEN } from '@app/tokens';

@Injectable({
    providedIn: 'root',
})
export class FilesAttacherService {
    private baseUrl: string = `${this.env.notificationsApiURL}`;
    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {}

    uploadAttachmentFiles(files: File[], path: string) {
        const formData = new FormData();
        files.forEach(file => formData.append('file', file));
        return this.http.post<any>(`${this.baseUrl}/${path}`, formData, {
            reportProgress: true,
            observe: 'events',
        });
    }
}
