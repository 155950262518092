<div
    ngbDropdown
    placement="bottom-right"
    container="body"
    autoClose="true"
>
           <span
               ngbDropdownToggle
               [innerHTML]="selected.icon | safeHtml: 'html'"
               class="model-sorting-button btn-link cursor-pointer"
               [class.sort-off-icon]="!selected.dir">
           </span>
    <ul ngbDropdownMenu class="py-5px" [style.minWidth]="120">
        <li
            *ngFor="let item of sortTypeItems"
            class="cursor-pointer font-12px py-5px px-15px dropdown-list-item"
            [class.selected-item]="item.dir === selected.dir"
            (click)="onItemClicked(item)"
            ngbDropdownItem
        >
            <div>
                <span [innerHTML]="item.icon | safeHtml: 'html'" class="pr-10px dropdown-icon"></span>
                {{ item.label }}
            </div>
            <fa-icon *ngIf="item.dir === selected.dir" icon="simpleCheck" class="color-brand"></fa-icon>
        </li>
    </ul>
</div>
