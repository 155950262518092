import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { faPlayCircle, IconDefinition } from '@fortawesome/free-regular-svg-icons';

import { RunningStatus } from 'data-processor';

@Component({
    selector: 'dp-monitoring-state',
    templateUrl: './monitoring-state.component.html',
    styleUrls: ['./monitoring-state.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonitoringStateComponent {
    @Input() runningStatus: RunningStatus;
    @Output() forceRun = new EventEmitter();

    get icon(): IconDefinition | null {
        if (this.runningStatus === 'RUNNING') {
            return faPlayCircle;
        } else {
            return null;
        }
    }

    get hasLoader() {
        return ['STOPPING', 'STARTING'].includes(this.runningStatus);
    }

    get message() {
        switch (this.runningStatus) {
            case 'RUNNING':
                return 'Idle';
            case 'STOPPING':
                return 'Stopping in progress';
            case 'PROCESSING':
                return 'Processing in progress';
            case 'STARTING':
                return 'Starting in progress';
        }
    }
}
