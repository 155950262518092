import { Component, Inject, Input, OnInit } from '@angular/core';
import { share } from 'rxjs/operators';

import { DropdownItem } from '@dagility-ui/kit';
import { DATA_MORPH_FEATURE, DATA_MORPH_FEATURE_TOGGLE, DataMorphFeatureToggleService } from 'data-processor/tokens';

import { WidgetEventHandler, WidgetFilterType, WidgetType } from '../../../models/any-widget.model';
import { BlockForm } from '../widget-builder-block-form/widget-builder-block-form.component';
import { WidgetBuilderFacade } from '../../../state/widget-builder.facade';
import { WidgetBuilderStore } from '../../../services/widget-builder.store';
import { GLOBAL_FILTERS_EDITOR } from '../../../providers/global-filters-editor.token';
import { WIDGET_CLICKED_EVENT_ID, WIDGET_EVENTS_MAP } from '../../../services/message-bus/widget-event.manager';
import { getDrilldownConverter } from '../../../services/widget.drilldown';

@Component({
    selector: 'dp-widget-builder-event-handler',
    templateUrl: './widget-builder-event-handler.component.html',
    providers: [{ provide: BlockForm, useExisting: WidgetBuilderEventHandlerComponent }],
})
export class WidgetBuilderEventHandlerComponent extends BlockForm implements OnInit {
    @Input() set data(eventHandler: WidgetEventHandler) {
        this.eventSources = [];

        this.form = this.facade.buildEventHandlerForm(eventHandler);
        this.initEventSources((eventHandler as any).layerId);
    }

    get eventId() {
        return this.form.get('type').value;
    }

    eventType: DropdownItem<WidgetEventHandler['type']>[] = [
        {
            value: 'IN',
            label: 'Consumer',
        },
    ];

    eventSources: DropdownItem[] = [];

    isLinkClickEventActive$ = this.featureToggle.isActive(DATA_MORPH_FEATURE.LINK_CLICK).pipe(share());

    constructor(
        private facade: WidgetBuilderFacade,
        private state: WidgetBuilderStore,
        @Inject(DATA_MORPH_FEATURE_TOGGLE) private featureToggle: DataMorphFeatureToggleService,
        @Inject(GLOBAL_FILTERS_EDITOR) private globalFiltersMode: boolean
    ) {
        super();
    }

    ngOnInit() {
        if (!this.globalFiltersMode) {
            this.eventType.push({
                value: 'OUT',
                label: 'Producer',
            });
        }
    }

    initEventSources(layerId: string) {
        const layer = this.state.value.layers[layerId];
        this.eventSources = layer.filters
            .map(id => this.state.value.filters[id])
            .filter(filter => filter.type !== WidgetFilterType.HIDDEN)
            .map(({ placeholder }) => ({
                label: placeholder,
                value: `${placeholder}.change`,
            }))
            .concat(
                (WIDGET_EVENTS_MAP[layer.type] || []).map(event => ({
                    label: event,
                    value: event,
                }))
            )
            .concat(
                layer.type === WidgetType.COMPLEX
                    ? layer.widgets.reduce((acc, subLayerId, idx) => {
                          const subLayer = this.state.value.layers[subLayerId];
                          if (getDrilldownConverter(subLayer.type)) {
                              acc.push({
                                  label: `Widget ${idx} drilldown`,
                                  value: `${idx}.drilldown`,
                              });
                          }
                          return acc;
                      }, [])
                    : [
                          {
                              label: 'Drilldown',
                              value: 'drilldown',
                          },
                      ]
            )
            .concat([
                {
                    value: 'loaded',
                    label: `${this.globalFiltersMode ? 'Dashboard filters' : 'Widget'} loaded`,
                },
            ]);

        if (!this.globalFiltersMode) {
            this.eventSources.push({
                label: 'Widget clicked',
                value: WIDGET_CLICKED_EVENT_ID,
            });

            if (this.eventId) {
                const eventId = this.form.get('eventId').value;

                if (!this.eventSources.find(event => event.value === eventId)) {
                    this.eventSources.push({
                        label: eventId,
                        value: eventId,
                    });
                }
            }
        }
    }
}
