import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalService } from '@dagility-ui/kit';
import { AnnouncementSettingsModalComponent } from './announcement-settings-modal/announcement-settings-modal.component';

interface ImportanceType {
    code: string;
    description: string;
}

interface CategoryType {
    code: string;
    description: string;
}

interface FileData {
    id: string;
    contentType?: string;
    name?: string;
    size?: number;
}

export interface AnnouncementData {
    message: string;
    importance: string;
    category: string;
    hideBanner: string;
    acceptanceType: string;
    recipients: {
        applications: string[];
        portfolios: string[];
        teams: string[];
        role: string;
        users: string[];
    };
    attachments: FileData[];
    timeSetting: {
        dateFrom: Date;
        dateEnd?: Date;
        includeTime?: boolean;
        recurringEvent?: {
            repeatsCount: number;
            repeatsPeriod: number;
            repeatDaysOfWeek?: number[];
            repeatDayOfMonth?: string;
            repeat?: Date;
            endDate?: Date;
            neverEnds: boolean;
            occurenceLimit?: number;
        };
    };
}

@Injectable()
export class AnnouncementService {
    message$: Subject<AnnouncementData> = new Subject<AnnouncementData>();

    weekDays: string[] = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];
    categoryTypes: CategoryType[] = [
        { code: 'Update', description: 'Update' },
        { code: 'Release', description: 'Release' },
    ];
    importanceTypes: ImportanceType[] = [
        { code: 'HIGH', description: 'High' },
        { code: 'LOW', description: 'Low' },
    ];
    hideBannerTypes: { label: string; value: any }[] = [
        { label: 'Never', value: 'Never' },
        { label: 'Close button', value: 'Close' },
        { label: 'Recipient accepts', value: 'Accepts' },
    ];
    acceptanceTypes: { label: string; value: any }[] = [
        { label: 'OK', value: 'OK' },
        { label: 'Apply', value: 'Apply' },
        { label: 'Accept', value: 'Accept' },
        { label: 'Sign up', value: 'Sign up' },
    ];

    repeatTypes: { label: string; value: any }[] = ['Days', 'Weeks', 'Months', 'Years'].map(value => ({
        label: value,
        value,
    }));

    specificDayTypes: { label: string; value: any }[] = [
        'Month on day 28',
        'Monthly on the fourth Tuesday',
        'Monthly on the last Tuesday',
    ].map(value => ({
        label: value,
        value,
    }));

    remindTypesFrom: { label: string; value: any }[] = [
        'On day of event (9:00 AM)',
        '1 day before (9:00 AM)',
        '2 day before (9:00 AM)',
        '1 week before (9:00 AM)',
    ].map(value => ({ label: value, value }));
    remindTypesTo: { label: string; value: any }[] = [
        'At the end of event',
        '5 minutes before',
        '10 minutes before',
        '15 minutes before',
        '30 minutes before',
        '1 hour before',
        '2 hour before',
        '1 day before (9:00 AM)',
        '2 days before (9:00 AM)',
    ].map(value => ({ label: value, value }));

    constructor(private modalService: ModalService) {}

    getPreviewAnnouncementData(): AnnouncementData {
        return {
            message: 'test message',
            hideBanner: this.hideBannerTypes[0].value,
            importance: this.importanceTypes[0].code,
            category: this.categoryTypes[0].code,
            acceptanceType: this.acceptanceTypes[0].value,
            recipients: {
                teams: [],
                users: [],
                applications: [],
                portfolios: [],
                role: 'Admin',
            },
            attachments: [],
            timeSetting: {
                dateFrom: new Date(),
            },
        };
    }

    getAnnouncement() {
        this.message$.next(this.getPreviewAnnouncementData());
    }

    createAlert() {
        const modalRef = this.modalService.open(
            AnnouncementSettingsModalComponent,
            { centered: true, backdrop: 'static', size: 'lg' }
            // { widgetTags: this.context.options.tags || [] }
        );
        console.log('Ref', modalRef);
    }
}
