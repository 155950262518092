<div *ngIf="!isLoad; else loadingTmpl"
     class="score-category grade-{{ data.grade }}"
>
    <div class="row no-gutters">
        <span class="text-truncate score-title-text"
              [ngbTooltip]="data.name"
              openDelay="800"
              [container]="'body'"
        >{{ data.name }}</span>
    </div>

    <ng-container *ngIf="data.score || data.score === 0">
        <div class="row no-gutters mt-1">
            <div class="col-8">
                <span class="score-value-text">{{ data.score === 'N/A' ? 'N/A' : data.score | number: '1.1'}}</span>
                <span class="score-title-text ml-4">{{ data.score === 'N/A' ? '' : '/' + data.outOf }}</span>
            </div>
            <div class="col-4">
                <div class="score-value-text text-center">{{ data.grade.toLocaleUpperCase() }}</div>
            </div>
        </div>
    </ng-container>
</div>
<ng-template #loadingTmpl>
    <lib-spinner class="spinner"></lib-spinner>
</ng-template>
