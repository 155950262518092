<div
    *ngIf="filtersManager.toggleAllState$ | async as toggleAllState"
    class="toggle-button filter-item px-4 d-flex align-items-center"
    (click)="filtersManager.toggleAll(toggleAllState)"
>
    <fa-icon
        class="color-brand font-16px d-inline-flex pr-2"
        [icon]="$any(toggleAllState === 'expand' ? 'plusSquare' : 'minusSquare')"
    ></fa-icon>
    <span class="body2">{{ toggleAllState === 'expand' ? 'Expand All' : 'Collapse All' }}</span>
</div>
<perfect-scrollbar class="position-relative mt-2">
    <ng-container *ngFor="let filter of filtersManager.filters$ | async; last as last">
        <ng-container *dpFilterDataSource="filter; isLoading as isLoading">
            <fieldset
                data-test-id="global-filter"
                *ngIf="{ expanded: filtersManager.isExpanded(filter), isDynamicFilter: filter | mapper: isDynamicFilter } as vm"
                [disabled]="isLoading"
                [name]="filter.placeholder"
                [class.mb-2]="!last"
            >
                <div
                    *ngIf="vm.isDynamicFilter"
                    class="px-4 filter-header filter-item"
                    [class.expanded]="vm.expanded"
                    [class.color-brand]="vm.expanded"
                    (click)="filtersManager.toggle(filter)"
                >
                    <fa-icon icon="chevron-right" class="d-inline-flex font-16px"></fa-icon>
                    <lib-waiting-loader *ngIf="isLoading" data-test-id="filter-loader" style="width: 16px"></lib-waiting-loader>
                    <legend class="h3 color-title text-truncate my-0 filter-header--label w-auto" [class.disable]="isLoading">
                        {{ filter.label }}
                    </legend>
                </div>
                <dp-global-filter
                    [filter]="filter"
                    [editMode]="vm.expanded"
                    [filterDisabled]="isLoading"
                    (previewClick)="!isLoading && filtersManager.toggle(filter)"
                ></dp-global-filter>
            </fieldset>
        </ng-container>
    </ng-container>
    <ng-container>
        <div class="static-filter">
            <div class="px-4 filter-header filter-item mt-2">
                <legend class="h3 color-title text-truncate my-0 filter-header--label w-auto">
                    Widget Filter Visibility
                    <span context-help-id="filter-visibility" [context-help-width]="panelWidth" [context-help-padding]="0"></span>
                </legend>
            </div>
            <div class="mt-2 px-4">
                <div *ngFor="let selector of widgetFilterVisibilityOptions; let i = index">
                    <div data-test-id="visibility-filter" class="mt-2 mb-20px">
                        <lib-radio-button
                            class="size-m"
                            [checked]="selector.checked"
                            [label]="selector.label"
                            [value]="selector.name"
                            [attr.data-test-id]="selector.name"
                            (click)="handleChange(i)"
                        ></lib-radio-button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</perfect-scrollbar>
