import { negate, isEmpty } from 'lodash';

import { normalizeValue } from '../utils';

export const HIDDEN_FIELDS_PLUGIN_TYPE_MAP = {
    WORKFLOW_PLUGIN: {
        dataSource: true,
        parameters: true,
        references: true,
        targets: true,
        transformations: true,
        updates: true,
        groovy: true,
        beforeScript: true,
        beforeIterationScript: true,
        afterIterationScript: true,
        afterScript: true,
    },
    DATA_PLUGIN: {
        actions: true,
    },
};

const pattern = {
    array: (a: any) => !!a.length,
    number: () => true,
    object: negate(isEmpty),
    string: negate(isEmpty),
    boolean: () => true,
};

export class JobDefinitionNormalizer {
    static removeEmptyFields(object: any) {
        return normalizeValue(normalizeValue(object, pattern), pattern);
    }

    constructor(private isWorkflowJD: boolean) {}

    normalize(jobDefinition: any, actions: any[] = []) {
        const setup = jobDefinition.setup;
        const groovy = jobDefinition.groovy;

        delete jobDefinition.setup;
        delete jobDefinition.groovy;

        jobDefinition = { ...jobDefinition, ...setup, ...groovy };
        jobDefinition = this.normalizeByPluginType(normalizeValue(normalizeValue(jobDefinition, pattern), pattern));

        if (this.isWorkflowJD) {
            jobDefinition.actions = actions;
        } else if (!!jobDefinition.actions) {
            delete jobDefinition.actions;
        }

        return jobDefinition;
    }

    normalizeEmptyFields(jobDefinition: any) {
        return this.normalizeByPluginType(JobDefinitionNormalizer.removeEmptyFields(jobDefinition));
    }

    private normalizeByPluginType(obj: any) {
        const key = this.isWorkflowJD ? 'WORKFLOW_PLUGIN' : 'DATA_PLUGIN';
        const removedFields = (HIDDEN_FIELDS_PLUGIN_TYPE_MAP as Record<string, any>)[key];

        Object.keys(obj).forEach(field => {
            if ((removedFields as Record<string, any>)[field]) {
                delete obj[field];
            }
        });

        return obj;
    }
}
