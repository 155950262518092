import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

interface Point {
    x: number;
    y: number;
}

function describePath(center: Point, radius: number, startAngle: number, endAngle: number): string {
    const start = polarToCartesian(center, radius, endAngle);
    const end = polarToCartesian(center, radius, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    return ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y, 'L', center.x, center.y].join(' ');
}

function polarToCartesian(center: Point, radius: number, angle: number): Point {
    const angleInRadians = ((angle - 90) * Math.PI) / 180;

    return {
        x: center.x + radius * Math.cos(angleInRadians),
        y: center.y + radius * Math.sin(angleInRadians),
    };
}

@Component({
    selector: 'lib-gauge-chart',
    templateUrl: './gauge-chart.component.html',
    styleUrls: ['./gauge-chart.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GaugeChartComponent {
    @Input() maximum: number = 100;
    @Input() value: number = 0;
    @Input() radius: number = 80;
    @Input() startAngle: number = 0;
    @Input() endAngle: number = 90;
    @Input() gaugeColor = 'blue';
    @Input() baseColor = '#E9E9E9';

    CENTER: Point = { x: 100, y: 100 };
    RADIUS: number = 100;
    height: number = 2 * this.RADIUS;
    valueAngle: number = 0;
    gaugePath: string = '';
    whitePath: string = '';

    ngOnInit() {
        this.valueAngle = this.calculateValueAngle();
        this.gaugePath = this.calculateGaugePath();
        this.whitePath = describePath(this.CENTER, this.RADIUS, this.endAngle, this.startAngle);
    }

    private calculateValueAngle(): number {
        this.value = Math.max(this.value, 0);
        this.value = Math.min(this.value, this.maximum);

        const totalValue = this.maximum - 0 || 1;

        return (Math.min(this.value, this.maximum) * (this.endAngle - this.startAngle)) / totalValue + this.startAngle;
    }

    private calculateGaugePath(): string {
        return describePath(this.CENTER, this.RADIUS, this.startAngle, this.valueAngle);
    }
}
