import { Component, ContentChild, ElementRef, Input, NgZone, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Subject, merge, Observable } from 'rxjs';
import { filter, map, mapTo, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { GridsterComponent, GridsterService, IGridsterOptions } from 'angular2gridster';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';

import { ResizeObserverService } from '@dagility-ui/kit';

import { DpDashboardStore } from '../../state/dp-dashboard.store';
import { GridsterReflowService } from 'data-processor/lib/widget-library/dashboard/components/dp-dashboard-group/gridster-reflow.service';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare let MutationObserver: any;

@Component({
    selector: 'dp-gridster-resize',
    template: `
        <ng-container *ngIf="tabActive$ | async">
            <ng-template [ngTemplateOutlet]="template"></ng-template>
        </ng-container>
    `,
    providers: [ResizeObserverService],
})
export class GridsterResizeComponent implements OnInit, OnDestroy {
    @Input() tabId: number;

    @Input() edit = false;

    @Input() dragging = false;

    @ContentChild(GridsterComponent)
    set gridsterComponent(value: GridsterComponent) {
        this._gridsterComponent = value;
        this.gridsterReflow.init(value);
        if (!value) {
            return;
        }
        this.gridsterComponent.gridster.gridList.fixItemsPositions = (options: IGridsterOptions) => {
            if (this.store.ignoreReorder) {
                return;
            }
            // items with x, y that fits gird with size of options.lanes
            const validItems = this.gridsterComponent.gridster.items
                .filter(item => item.itemComponent)
                .filter(item => this.gridsterComponent.gridster.gridList['isItemValidForGrid'](item, options));
            // items that x, y must be generated
            const invalidItems = this.gridsterComponent.gridster.items
                .filter(item => item.itemComponent)
                .filter(item => !this.gridsterComponent.gridster.gridList['isItemValidForGrid'](item, options));
            const service = new GridsterService();
            service._items = [];
            service.options = options;
            service.initGridList();
            const gridList = service.gridList;
            // put items with defined positions to the grid
            gridList.items = validItems.map(item => {
                return item.copyForBreakpoint(options.breakpoint);
            });
            gridList.generateGrid();
            let lastPosition = { x: 0, y: 0 };
            invalidItems.forEach(item => {
                // TODO: check if this change does not broke anything
                // const itemCopy = item.copy();
                const itemCopy = item.copyForBreakpoint(options.breakpoint);
                const position = gridList.findPositionForItem(itemCopy, lastPosition);
                lastPosition = { x: position[0], y: position[1] + itemCopy.w };
                gridList.items.push(itemCopy);
                gridList['setItemPosition'](itemCopy, position);
                gridList['markItemPositionToGrid'](itemCopy);
            });
            gridList.pullItemsToLeft();
            gridList.pushCollidingItems();
            this.gridsterComponent.gridster.items.forEach(itm => {
                const cachedItem = gridList.items.filter((cachedItm: any) => {
                    return cachedItm.$element === itm.$element;
                })[0];
                if (cachedItem) {
                    itm.setValueX(cachedItem.x, options.breakpoint);
                    itm.setValueY(cachedItem.y, options.breakpoint);
                    itm.setValueW(cachedItem.w, options.breakpoint);
                    itm.setValueH(cachedItem.h, options.breakpoint);
                    itm.autoSize = cachedItem.autoSize;
                }
            });
        };
    }

    get gridsterComponent() {
        return this._gridsterComponent;
    }

    @ContentChild(TemplateRef) template: TemplateRef<ElementRef>;

    private ngbNavPane: HTMLElement;
    private destroyed$ = new Subject<void>();

    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _gridsterComponent: GridsterComponent;

    tabActive$: Observable<boolean>;

    constructor(
        private store: DpDashboardStore,
        private zone: NgZone,
        private ngbNav: NgbNav,
        private resizeObserverService: ResizeObserverService,
        private elementRef: ElementRef<HTMLElement>,
        private gridsterReflow: GridsterReflowService
    ) {}

    ngOnInit() {
        this.ngbNavPane = this.elementRef.nativeElement.closest('[ngbnavpane]');
        this.tabActive$ = new Observable<boolean>(subscriber => {
            const mutationObserver = new MutationObserver(() => {
                if (this.ngbNavPane.classList.contains('active')) {
                    subscriber.next();
                }
            });
            mutationObserver.observe(this.ngbNavPane, {
                attributes: true,
                attributeFilter: ['class'],
                childList: false,
                subtree: false,
            });

            return () => {
                mutationObserver.disconnect();
            };
        }).pipe(mapTo(true), startWith(this.ngbNavPane.classList.contains('active')), filter<boolean>(Boolean), take(1));

        merge(this.resizeObserverService.observe$(this.elementRef).pipe(map(() => this.store.value.activeTab)), this.ngbNav.shown)
            .pipe(
                filter(tabId => tabId === this.tabId),
                switchMap(() => this.zone.onStable.pipe(take(1))),
                takeUntil(this.destroyed$)
            )
            .subscribe(() => {
                if (!this.dragging) {
                    this.zone.run(() => {
                        this.gridsterComponent?.reload();
                    });
                }
            });
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }
}
