import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { validateFormAndDisplayErrors } from '@dagility-ui/kit';

export interface SaveGraphModalResult {
    valid: boolean;
    name?: string;
    description?: string;
}

@Component({
    selector: 'app-save-graph-modal',
    templateUrl: './save-graph-modal.component.html',
    styleUrls: ['./save-graph-modal.component.scss'],
})
export class SaveGraphModalComponent implements OnInit {
    @Input() names: string[];
    saveForm: FormGroup;
    name: string;
    description: string;

    constructor(public modal: NgbActiveModal, private fb: FormBuilder) {}

    ngOnInit(): void {
        this.saveForm = this.fb.group({
            name: [this.name, [Validators.required, this.duplicateValidator(this.names)]],
            description: [this.description, Validators.required],
        });
    }

    save() {
        validateFormAndDisplayErrors(this.saveForm);
        if (this.saveForm.valid) {
            this.modal.close({ valid: true, name: this.name, description: this.description });
        }
    }

    private duplicateValidator(list: string[]): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => (list?.includes(control.value) ? { duplicate: true } : null);
    }
}
