<div [ngClass]="{ 'd-flex': horizontalOrientation }">
    <div
        *ngFor="let tile of this.placeholders.tiles"
        class="tile-block mb-2 pt-2 px-3 background-color-{{ tile.backgroundColor }}"
        [ngClass]="{
            'ml-3': horizontalOrientation,
            'cursor-pointer': tile.targetDrilldown,
            'tile-border': tile.backgroundColor === 'white',
            'hover-disable': !tile.targetDrilldown,
            'hover-active-disable': !tile.targetDrilldown
        }"
        [ngStyle]="{ background: tile.backgroundColor }"
        (click)="handleTileClicked(tile)"
    >
        <div class="d-flex">
            <div
                class="tile-title mr-3 text-truncate text-color-{{ tile.textColor }}"
                [ngStyle]="{ color: tile.textColor }"
                [ngbTooltip]="tile.tileTitle"
                container="body"
                triggers="manual"
                libTooltipWhenOverflow
            >
                {{ tile.tileTitle }}
            </div>
            <fa-icon
                *ngIf="tile.targetDrilldown"
                icon="barChart"
                class="ml-auto tile-drilldown-icon text-color-{{ tile.textColor }}"
                [ngClass]="{ 'icon-color-brand': tile.backgroundColor === 'gray' || tile.backgroundColor === 'white' }"
            ></fa-icon>
        </div>
        <div class="d-flex tile-body">
            <div class="d-flex align-items-baseline tile-value-container text-truncate">
                <span class="tile-value text-truncate" [ngStyle]="{ color: tile.valueColor }">{{ tile.value }}</span>
                <span *ngIf="tile.postfix" class="body1 ml-1 text-truncate">{{ tile.postfix }}</span>
            </div>
            <div class="d-flex mx-4 mt-2 additional-container text-truncate">
                <div
                    *ngIf="tile.delta"
                    [ngStyle]="{
                        color:
                            deltaSign(tile.delta) < 0
                                ? !tile.deltaInversed
                                    ? 'var(--da-error-base)'
                                    : 'var(--da-success-base)'
                                : !tile.deltaInversed
                                ? 'var(--da-success-base)'
                                : 'var(--da-error-base)'
                    }"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 16 16" fill="none">
                        <path
                            [attr.d]="
                                deltaSign(tile.delta) < 0
                                    ? !tile.deltaIconInversed
                                        ? 'M13 0.833496L7 10.1668L1 0.833496L13 0.833496Z'
                                        : 'M2 12.6667L8 3.33335L14 12.6667L2 12.6667Z'
                                    : !tile.deltaIconInversed
                                    ? 'M2 12.6667L8 3.33335L14 12.6667L2 12.6667Z'
                                    : 'M13 0.833496L7 10.1668L1 0.833496L13 0.833496Z'
                            "
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <span class="ml-2 metric-text">{{ tile.delta > 0 ? '+' : '' }}{{ tile.delta }}</span>
                    <span *ngIf="tile.deltaPostfix" class="ml-1 metric-text">{{ tile.deltaPostfix }}</span>
                </div>
                <div class="tile-additional-text mt-auto pb-2 text-truncate">
                    {{ tile.additionalText }}
                </div>
            </div>
        </div>
        <div class="d-flex full-details-badge">
            <div class="mt-1">Full details</div>
            <fa-icon class="ml-auto arrow-drilldown-icon" icon="facArrowRightRegular"></fa-icon>
        </div>
    </div>
</div>
