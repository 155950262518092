import { Directive, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { forkJoin, merge, Observable, of, Subject, Subscription } from 'rxjs';
import {
    defaultIfEmpty,
    delay,
    filter as filterObs,
    map,
    publishReplay,
    refCount,
    switchMap,
    switchMapTo,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { intersectionBy, isEmpty } from 'lodash';

import { ExecutionContext, getQueryExecutor } from '../services/widget-query.executor';
import { DropdownFilter, HiddenFilter, WidgetFilter, WidgetFilterType } from '../models/any-widget.model';
import { WidgetBuilderService } from '../services/widget-builder.service';
import {
    BasePlaceholders,
    buildFilterDependenciesMap,
    getFiltersForm,
    isDynamicFilter,
    resetGridPagination,
} from '../services/widget-builder.util';
import { WidgetLogger, WidgetScriptExecutor } from '../services/widget-script.executor';

export class PlaceholdersProvider {
    phf: Record<string, any>;
    filterValuesMap?: Record<string, any>;
}

@Directive({
    selector: '[dpFilterSet] [formGroup]',
})
export class FilterSetDirective implements OnDestroy, OnChanges {
    @Input('dpFilterSet') filters: WidgetFilter[];

    @Input() set formGroup(group: FormGroup) {
        this._formGroup = group;
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _formGroup: FormGroup;

    get formGroup() {
        return this._formGroup;
    }

    @Input() updateDisabled: boolean;
    @Input() intersect$: Observable<boolean> = of(true);
    @Input() widgetId: string;
    @Input() defaultPlaceholders$: Observable<Record<string, any>> = of({});
    @Input() parentFilter = false;
    @Input() reset = false;
    @Input() appliedFilters: Record<string, any> = {};
    @Input() applyDynamicFilters = false;
    @Input() previewMode = false;

    @Output() loaded = new EventEmitter();
    @Output() filterValueChanged = new EventEmitter();

    loading = false;
    filtersDataMap: Record<string, Subject<any>> = {};
    loadingFiltersMap$: Record<string, Observable<any>> = {};
    resetGlobalFilters = false;

    private filterDependenciesMap: Record<string, string[]> = {};
    private destroyed$ = new Subject<void>();
    private subscription: Subscription = Subscription.EMPTY;
    private walkFiltersSubscription = Subscription.EMPTY;

    constructor(
        private api: WidgetBuilderService,
        private provider: PlaceholdersProvider,
        private scriptExecutor: WidgetScriptExecutor,
        private logger: WidgetLogger
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.resetGlobalFilters) {
            this.resetGlobalFilters = changes.resetGlobalFilters as any;
        }
        if (this.updateDisabled) {
            return;
        }

        if (changes.formGroup && changes.formGroup.currentValue) {
            this.observeFilterChanges();
        }

        if (changes.filters && this.formGroup) {
            this.filterDependenciesMap = buildFilterDependenciesMap(this.filters);
            if (!this.filters) {
                this.filters = [];
            }
            this.filtersDataMap = this.filters.reduce<Record<string, Subject<any>>>((acc, filter) => {
                acc[filter.placeholder] = new Subject();

                return acc;
            }, {});

            if (this.reset) {
                this.loadFilters(null);
            }
        }
    }

    observeFilterChanges(): void {
        this.subscription.unsubscribe();

        this.subscription = merge(
            ...Object.entries(this.formGroup.controls).map(([key, control]) =>
                control.valueChanges.pipe(
                    map(value => ({
                        value,
                        key,
                    }))
                )
            )
        )
            .pipe(
                filterObs(() => !this.updateDisabled && !this.loading),
                delay(100),
                takeUntil(this.destroyed$)
            )
            .subscribe(({ value, key }) => {
                if (this.parentFilter) {
                    this.logger.clear();
                }

                if (this.previewMode) {
                    this.provider.phf[key] = value;
                }

                this.filterValueChanged.emit({ key, value });

                this.loadFilters(key);
            });
    }

    loadFilters(root: string | null): void {
        this.loading = true;
        this.loadingFiltersMap$ = {};

        if (
            ![BasePlaceholders.FILTER_PLACEHOLDER, BasePlaceholders.SORT_PLACEHOLDER, BasePlaceholders.PAGEABLE_PLACEHOLDER].includes(
                root as any
            )
        ) {
            resetGridPagination(this.formGroup);
        }

        const filtersMap: Record<string, WidgetFilter> = this.filters.reduce<Record<string, WidgetFilter>>((acc, filter) => {
            acc[filter.placeholder] = filter;

            return acc;
        }, {});
        const independentFilters: DropdownFilter[] = this.filters.filter(
            f => isDynamicFilter(f) && isEmpty(f.dependencies)
        ) as DropdownFilter[];

        const visit = (defaultPlaceholders: Record<string, any>, filter: DropdownFilter, skipRoot: boolean = false): Observable<any> => {
            return (skipRoot || (filter.dependencies || []).includes(root)
                ? of([])
                : forkJoin(
                      (filter.dependencies || [])
                          .filter(placeholder => isDynamicFilter(filtersMap[placeholder] || ({} as any)))
                          .map(placeholder => this.buildQueryExecutor(defaultPlaceholders, filtersMap[placeholder] as DropdownFilter))
                  )
            ).pipe(
                defaultIfEmpty([]),
                switchMap(() => {
                    const dependents = this.filterDependenciesMap[filter.placeholder];
                    if (dependents?.length) {
                        return forkJoin(
                            dependents
                                .filter(placeholder => isDynamicFilter(filtersMap[placeholder] || ({} as any)))
                                .map(placeholder => visit(defaultPlaceholders, filtersMap[placeholder] as DropdownFilter))
                        ).pipe(defaultIfEmpty([]));
                    } else if (!skipRoot) {
                        return this.buildQueryExecutor(defaultPlaceholders, filter);
                    } else {
                        return of(null);
                    }
                })
            );
        };

        if (this.reset && this.applyDynamicFilters) {
            this.walkFiltersSubscription.unsubscribe();
        }
        const staticFilters = this.filters.filter(f => !isDynamicFilter(f));
        this.walkFiltersSubscription = this.intersect$
            .pipe(
                switchMapTo(this.defaultPlaceholders$),
                tap(defaultPlaceholders => {
                    if (this.reset) {
                        this.loadingFiltersMap$ = {};
                    }

                    Object.assign(this.provider.phf, defaultPlaceholders);

                    if (!root) {
                        this.resetStaticFilters();
                    }
                }),
                switchMap(defaultPlaceholders =>
                    root
                        ? visit(defaultPlaceholders, (filtersMap[root] as any) ?? {}, true)
                        : forkJoin(
                              (independentFilters.length
                                  ? independentFilters
                                  : this.filters.length === staticFilters.length
                                  ? []
                                  : staticFilters
                              ).map(filter => visit(defaultPlaceholders, filter as any))
                          ).pipe(defaultIfEmpty(null))
                ),
                delay(0),
                takeUntil(this.destroyed$)
            )
            .subscribe(
                () => {
                    this.loading = false;
                    this.loaded.emit();
                },
                () => {
                    this.loading = false;
                    Object.values(this.filtersDataMap).forEach(subject => {
                        subject.next([]);
                    });

                    this.loaded.emit(true);
                }
            );
    }

    buildQueryExecutor = (defaultPlaceholders: Record<string, any>, filter: DropdownFilter | HiddenFilter) => {
        if (!isDynamicFilter(filter)) {
            return of(null);
        }

        this.filtersDataMap[filter.placeholder].next({ type: 'start' });

        this.loadingFiltersMap$[filter.placeholder] ??= (filter.query
            ? new (getQueryExecutor({} as any))({
                  api: this.api,
                  queries: [filter.query],
                  filterPlaceholder: filter.placeholder,
                  logger: this.logger,
                  scriptExecutor: this.scriptExecutor,
              }).execute$(
                  new ExecutionContext({
                      defaultPlaceholders,
                      filters: {
                          ...this.provider.phf,
                          ...this.formGroup.value,
                      },
                      placeholders: this.provider.phf,
                  })
              )
            : of((filter as DropdownFilter).items)
        ).pipe(
            switchMap((data: any) => {
                let defaultValue: any;

                if (this.previewMode) {
                    defaultValue = this.provider.phf?.[filter.placeholder];
                }

                if (!this.resetGlobalFilters && !defaultValue) {
                    if (this.provider.filterValuesMap) {
                        const filtersMap = this.provider.filterValuesMap[this.widgetId];
                        defaultValue = filtersMap?.[filter.placeholder];
                        delete filtersMap?.[filter.placeholder];
                    }

                    if (this.applyDynamicFilters) {
                        defaultValue = this.appliedFilters[filter.placeholder];
                    }
                }

                if (filter.defaultValue && defaultValue === undefined) {
                    try {
                        defaultValue = this.scriptExecutor.evaluateFilterDefaultValue(data, filter, {
                            ...this.provider.phf,
                            ...this.formGroup.value,
                        });
                    } catch (e) {}
                } else if (!filter.defaultValue && this.resetGlobalFilters) {
                    if (filter.type === WidgetFilterType.DROPDOWN && filter.multiple) {
                        defaultValue = [];
                    } else if (filter.type === WidgetFilterType.DROPDOWN && !filter.multiple) {
                        defaultValue = null;
                    }
                }

                if (defaultValue !== undefined) {
                    const dropdownItems = (Array.isArray(defaultValue) ? defaultValue : [defaultValue]).map(value => ({ value }));
                    if (Array.isArray(defaultValue)) {
                        defaultValue = (intersectionBy(dropdownItems, data, 'value') || []).map(item => item.value);
                    } else {
                        defaultValue = intersectionBy(dropdownItems, data, 'value')?.[0]?.value ?? null;
                    }
                }

                if (defaultValue !== undefined) {
                    this.formGroup.get(filter.placeholder).patchValue(defaultValue, { emitEvent: false });
                }

                this.filtersDataMap[filter.placeholder].next(data);

                return of(data);
            }),
            publishReplay(1),
            refCount()
        );

        return this.loadingFiltersMap$[filter.placeholder];
    };

    resetStaticFilters() {
        const defaultValues = getFiltersForm({
            filters: this.filters,
            defaultValuesMap: this.resetGlobalFilters
                ? null
                : this.reset
                ? this.appliedFilters
                : this.provider.filterValuesMap?.[this.widgetId] ?? {},
            scriptExecutor: this.scriptExecutor,
            placeholders: this.provider.phf,
            useDefaultFromPlaceholders: this.previewMode
        }).value;
        this.filters
            .filter(filter => !isDynamicFilter(filter))
            .forEach(filter => {
                (this.formGroup.get(filter.placeholder) as FormControl).patchValue(defaultValues[filter.placeholder], { emitEvent: false });
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }
}
