import { inject, Injectable, OnDestroy, Provider } from '@angular/core';
import { merge, of, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, map, mapTo, skip, startWith, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { cloneDeep, isEqual } from 'lodash';

import { isDefined, observeProperty } from '@dagility-ui/kit';
import { Placeholders, WidgetFilter } from 'data-processor';
import { SAVED_FILTERS_PLACEHOLDER } from 'data-processor/tokens';

import { WidgetWorkflow } from '../../../widget-builder/services/widget.flow';
import { GlobalFiltersWorkflow } from '../../../widget-builder/services/global-filters.flow';
import { FiltersDirtyStateToken, VISIBLE_FILTERS } from './dashboard-filter-set.tokens';

@Injectable()
class FiltersDirtyState extends FiltersDirtyStateToken implements OnDestroy {
    private save$ = new ReplaySubject<void>(1);
    private flow = inject(WidgetWorkflow) as GlobalFiltersWorkflow;
    private placeholders$ = merge(this.flow.loaded$, this.save$, of(null)).pipe(map(() => this.flow.placeholders));
    private appliedFilters: Record<string, unknown> = {};
    private appliedFilters$ = observeProperty(this, 'appliedFilters' as any);
    private filterValues$ = this.flow.loaded$.pipe(
        take(1),
        switchMap(() =>
            this.flow.filters.valueChanges.pipe(
                startWith(null as {}),
                map(() => this.flow.filters.value),
                catchError(err => {
                    console.error(err);
                    return throwError(err);
                })
            )
        )
    );
    private destroy$ = new Subject<void>();

    constructor() {
        super();

        const visibleFilters$ = inject(VISIBLE_FILTERS);

        merge(
            this.appliedFilters$.pipe(mapTo(false)),
            this.filterValues$.pipe(
                withLatestFrom(visibleFilters$),
                map(([current, visibleFilters]) => !this.compareFilterPlaceholders(visibleFilters, current, this.appliedFilters))
            )
        )
            .pipe(takeUntil(this.destroy$))
            .subscribe(isEnabled => this.applyEnabled$.next(isEnabled));

        merge(
            this.save$.pipe(mapTo(false)),
            merge(
                this.appliedFilters$.pipe(
                    skip(1),
                    map(() => this.flow.filters.value)
                ),
                this.filterValues$
            ).pipe(
                withLatestFrom(visibleFilters$),
                map(([current, visibleFilters]) => {
                    if (!this.flow.placeholders[SAVED_FILTERS_PLACEHOLDER]) {
                        return true;
                    }

                    return !this.compareFilterPlaceholders(visibleFilters, current, this.flow.placeholders[SAVED_FILTERS_PLACEHOLDER]);
                })
            )
        )
            .pipe(takeUntil(this.destroy$))
            .subscribe(isEnabled => this.saveEnabled$.next(isEnabled));
    }

    saveEnabled$ = new ReplaySubject<boolean>(1);
    resetToLastSavedEnabled$ = this.placeholders$.pipe(
        withLatestFrom(inject(VISIBLE_FILTERS)),
        map(([placeholders, filters]) => {
            if (!placeholders[SAVED_FILTERS_PLACEHOLDER]) {
                return false;
            }

            return !this.compareFilterPlaceholders(filters, placeholders, placeholders[SAVED_FILTERS_PLACEHOLDER]);
        })
    );

    applyEnabled$ = new ReplaySubject<boolean>(1);
    resetToDefaultEnabled$ = of(true);

    updateLastSavedFilters(placeholders: Placeholders): void {
        this.flow.placeholders[SAVED_FILTERS_PLACEHOLDER] = cloneDeep(placeholders);
        this.save$.next();
    }

    updateAppliedFilters(): void {
        this.appliedFilters = this.flow.filters.value;
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    private compareFilterPlaceholders(filters: WidgetFilter[], currentPlaceholders: Placeholders, storedPlaceholders: Placeholders) {
        if (
            currentPlaceholders.widgetFilterVisibility !== undefined &&
            currentPlaceholders.widgetFilterVisibility !== storedPlaceholders.widgetFilterVisibility
        ) {
            return false;
        }

        for (const { placeholder } of filters) {
            if (
                !isDefined(storedPlaceholders[placeholder]) &&
                currentPlaceholders[placeholder] &&
                this.flow.preferredFilters.get(placeholder)?.has(currentPlaceholders[placeholder])
            ) {
                continue;
            }

            if (!isDefined(currentPlaceholders[placeholder]) && !isDefined(storedPlaceholders[placeholder])) {
                continue;
            }

            if (!isEqual(currentPlaceholders[placeholder], storedPlaceholders[placeholder])) {
                return false;
            }
        }

        return true;
    }
}

export const FILTERS_DIRTY_STATE_PROVIDER: Provider = {
    provide: FiltersDirtyStateToken,
    useClass: FiltersDirtyState,
};
