import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { QB_ICON_URLS } from '@app/shared/components/query-builder/consts/qb-icon-urls.const';
import { ModelGraphActionsService } from '@app/shared/components/query-builder/services/model-graph-actions.service';
import { ModelFilterCandidate, ModelRelationCandidate } from '@app/shared/components/query-builder/models/model-graph-actions.model';
import { QueryBuilderStore } from '@app/shared/components/query-builder/store/query-builder.store';
import { Model } from '@app/shared/components/query-builder/models/model-graph.model';

@Component({
    selector: 'app-qb-model-add-items',
    templateUrl: './add-items.component.html',
    styleUrls: ['./add-items.component.scss'],
})
export class AddItemsComponent implements OnChanges {
    @Output() menuOpened: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() itemSelected: EventEmitter<void> = new EventEmitter<void>();
    @Output() update: EventEmitter<void> = new EventEmitter<void>();

    @Input() modelUid: string;
    @Input() candidateType: CandidateType;
    @Input() rootModels: Model[];

    // only for ngOnChanges
    @Input() updatingRelations: boolean;

    icons = QB_ICON_URLS;

    items: ModelRelationCandidate[] | ModelFilterCandidate[] = null;

    opened: boolean = false;

    loadingStatus: LoadingStatus = 'READY';

    constructor(private modelGraphActionsService: ModelGraphActionsService, private queryBuilderStore: QueryBuilderStore) {}

    ngOnChanges(): void {
        this.opened = false;
        this.update.emit();
    }

    loadItems() {
        if (this.loadingStatus === 'APPLYING' || this.loadingStatus === 'LOADING' || this.opened) {
            this.opened = false;
            this.menuOpened.emit(false);
            return;
        }
        this.items = null;
        this.loadingStatus = 'LOADING';
        const modelGraph = this.queryBuilderStore.modelGraph;
        switch (this.candidateType) {
            case 'RELATION':
                this.modelGraphActionsService.relationCandidates(this.modelUid, modelGraph).subscribe(
                    candidates => {
                        this.items = candidates.filter(
                            m =>
                                !this.queryBuilderStore.modelGraph.references.find(
                                    r => r.referencedModelUid === m.parentModelUid && r.parentModelUid === m.referencedModelUid
                                )
                        );
                        const itemNames = this.items.filter(i => i.joinType === 'INNER').map(i => i.modelName);
                        this.items.forEach(item => {
                            if (itemNames.filter(n => n === item.modelName).length > 1) {
                                item.modelName = `${item.modelName} [${
                                    this.rootModels.find(m => m.uid === item.referencedModelUid).data.dbToolNameHint
                                }]`;
                            }
                        });
                        if (this.items.length > 0) {
                            this.itemSelected.emit();
                            this.menuOpened.emit(true);
                            this.opened = true;
                        }
                    },
                    () => (this.loadingStatus = 'ERROR'),
                    () => (this.loadingStatus = 'READY')
                );
                break;
        }
    }

    selectItem(item: ModelRelationCandidate | ModelFilterCandidate) {
        this.loadingStatus = 'APPLYING';
        this.opened = false;
        this.menuOpened.emit(false);
        const modelGraph = this.queryBuilderStore.globalModelGraph;
        switch (this.candidateType) {
            case 'RELATION':
                {
                    this.modelGraphActionsService
                        .addReference({
                            sourceGraph: modelGraph,
                            relation: item as ModelRelationCandidate,
                        })
                        .subscribe(
                            result => {
                                this.queryBuilderStore.updateState(result);
                            },
                            () => (this.loadingStatus = 'ERROR'),
                            () => (this.loadingStatus = 'READY')
                        );
                }
                break;
        }
    }

    joinType(item: ModelRelationCandidate | ModelFilterCandidate): string {
        return this.candidateType === 'RELATION' && 'joinDescription' in item ? item.joinDescription.concat(' ') : '';
    }

    candidateName(item: ModelRelationCandidate | ModelFilterCandidate): string {
        if (this.candidateType === 'RELATION' && 'modelName' in item) {
            return item.modelName;
        } else if ('name' in item && item.name) {
            return item.name;
        } else if ('description' in item) {
            return item.description;
        } else {
            return '';
        }
    }
}

export type CandidateType = 'RELATION' | 'FILTER';

export type LoadingStatus = 'READY' | 'LOADING' | 'ERROR' | 'APPLYING';
