import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SandBox } from 'data-processor/lib/widget-library/widget-builder/services/widget-builder.util';
import { CellCustomComponent } from 'data-processor/lib/widget-library/widget-builder/components/custom-components/cell-custom/cell-custom.component';

@Component({
    selector: 'dp-compare-metrics-widget',
    templateUrl: './compare-metrics-widget.component.html',
    styleUrls: ['./compare-metrics-widget.component.scss'],
})
export class CompareMetricsWidgetComponent implements OnInit {
    @Input() placeholders: Record<string, any>;

    @Output() reload = new EventEmitter();

    @Output() cellClicked = new EventEmitter();

    get groupedByScore() {
        return this.activeMod === 'groupedByScore';
    }

    get autoClusters() {
        return this.activeMod === 'autoClusters';
    }

    get applicationsCount() {
        return this.chartModel?.options?.tableData?.rowData?.length || 0;
    }

    form: FormGroup;

    activeMod: 'autoClusters' | 'groupedByScore';
    groupingInterval: { from: number; to: number };

    chartModel: any;

    hexagonData = {
        invertColors: true,
        bounds: {},
        values: [] as any[],
    };

    constructor(private fb: FormBuilder) {}

    ngOnInit(): void {
        this.initChartModel();
        const modal = document.body.querySelector('ngb-modal-window.any-widget-modal');
        if (modal) {
            modal.classList.add('compare-metrics-widget');
        }

        this.initForm();
        this.createBounds();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.placeholders.currentValue.bounds) {
            this.createBounds();
        }
    }

    initChartModel() {
        this.chartModel = {
            options: {
                hasResetFilter: false,
                tableData: {
                    columnDefs: [
                        {
                            headerName: 'Applications',
                            field: 'applications',
                        },
                        {
                            headerName: 'Application Owner',
                            field: 'applicationOwner',
                        },
                        {
                            cellRendererFramework: CellCustomComponent,
                            headerName: 'Actions',
                            field: 'actions',
                            cellRendererParams: { cellRender: this.cellRenderer('actions') },
                        },
                    ] as any[],
                    rowData: [] as any[],
                },
            },
        };
    }

    initForm() {
        this.activeMod = this.placeholders?.activeMod || 'groupedByScore';
        this.groupingInterval = this.placeholders?.groupingInterval || { from: 0, to: 5 };
        this.chartModel.options.tableData.rowData = this.placeholders?.gridData || [];

        this.form = this.fb.group({
            metric: [this.placeholders?.selectedMetric || null],
            application: [this.placeholders?.selectedApplication || null],
        });

        this.form.valueChanges.subscribe(value => {
            if (value) {
                this.placeholders.selectedMetric = value.metric;
                this.placeholders.selectedApplication = value.application;
                this.reload.emit([
                    {
                        placeholder: 'selectedMetric',
                        value: this.placeholders.selectedMetric,
                    },
                    {
                        placeholder: 'selectedApplication',
                        value: this.placeholders.selectedApplication,
                    },
                    {
                        placeholder: 'selectedCriteriaId',
                        value: null,
                    },
                ]);
            }
        });
    }

    onCheckboxChanged(checkboxMod: 'autoClusters' | 'groupedByScore') {
        this.activeMod = checkboxMod;
        let changes = [];
        changes.push({
            placeholder: 'activeMod',
            value: this.activeMod,
        });
        if (this.activeMod === 'groupedByScore') {
            changes.push({
                placeholder: 'selectedCriteriaId',
                value: null,
            });
        }
        this.reload.emit(changes);
    }

    onIntervalChange(event: any) {
        this.reload.emit([
            {
                placeholder: 'groupingInterval',
                value: { from: event.from, to: event.to },
            },
        ]);
    }

    handleHexagonEvent(event: any) {
        this.reload.emit([
            {
                placeholder: 'selectedCriteriaId',
                value: { min: event.min, max: event.max },
            },
        ]);
    }

    handleCellClicked(event: any) {
        this.cellClicked.emit({
            target: 'actions',
            payload: event.data,
        });
    }

    createBounds() {
        this.hexagonData.bounds = this.placeholders.bounds;
        this.hexagonData.values = this.placeholders.hexagonData || [];
    }

    cellRenderer(field: string) {
        let item = (this.placeholders?.customCellTemplates || []).find((x: any) => x.field === field);
        if (item) {
            const sandbox = new SandBox();
            return sandbox.buildFn(`return ( function(params) {${item.cellTemplate} })`);
        } else {
            return null;
        }
    }
}
