export function showError(e: any) {
    console.error(e);
    // hide loader
    const loader = document.getElementById('loader');
    loader?.parentNode.removeChild(loader);

    document.body.insertAdjacentHTML(
        'beforeend',
        `
        <div id='error-page' class="row bootstrap-error position-relative">
            <div class="col not-found"></div>
            <div class="col d-flex flex-wrap justify-content-center align-content-center" style="flex-direction:column;">
                <div class="title2">Oops! An Unexpected Error Occurred!</div>
                <p class="info1 mt-16px">
                    This was unintentional. Please try again later or report the problem.
                </p>
                <div class="d-flex mt-25px">
                    <div class="btn btn-primary" id="refresh-button">
                        Refresh
                    </div>
                    <div class="btn btn-secondary" id="signout-button">
                        Sign out
                    </div>
                </div>
            </div>
        </div>
        `
    );
    const refreshButton = document.getElementById('refresh-button');
    const signOutButton = document.getElementById('signout-button');
    refreshButton.onclick = () => utils.reload();
    signOutButton.onclick = () => window.logout();
}

export const utils = {
    reload: () => location.reload(),
};
