<perfect-scrollbar class="scrollbar">
    <div class="sre-squares"
         [ngClass]="
            squares.length % 4 === 1 ? 'sre-squares-1' :
            squares.length % 4 === 2 ? 'sre-squares-2' :
            squares.length % 4 === 3 ? 'sre-squares-3' : ''">
        <dp-sre-square *ngFor="let square of squares" [square]="square"
                       (squareClicked)="squareClickedHandler($event)"></dp-sre-square>
    </div>
</perfect-scrollbar>
