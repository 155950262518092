import { Directive, Input, NgZone, OnDestroy, Renderer2 } from '@angular/core';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';

@Directive({
    selector: '[dpDisableScroll]',
})
export class DisableScrollDirective implements OnDestroy {
    @Input('dpDisableScroll') set disabled(value: boolean) {
        this._disabled = value;

        if (value && this.ps) {
            this.updateScrollPosition();
        }
    }

    get disabled(): boolean {
        return this._disabled;
    }

    private y: number = 0;
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _disabled: boolean;
    private unlistenFn = () => {
        /* intentional empty */
    };

    constructor(private ps: PerfectScrollbarDirective, private zone: NgZone, private renderer2: Renderer2) {}

    ngAfterViewInit() {
        if (this.disabled) {
            this.updateScrollPosition();
        }

        this.zone.runOutsideAngular(() => {
            this.unlistenFn = this.renderer2.listen(this.ps.elementRef.nativeElement as HTMLElement, 'ps-scroll-y', () => {
                if (this.disabled) {
                    this.ps.scrollTo(this.y);
                }
            });
        });
    }

    private updateScrollPosition() {
        this.y = this.ps.geometry().y;
    }

    ngOnDestroy() {
        this.unlistenFn();
    }
}
