<div class="modal-header">
    <h2 class="row no-gutters mt-0">
        Add Global Parameter
    </h2>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
            (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body" *ngIf="items$ | async as items">
    <div style="min-width: 800px">
        <lib-data-grid
            class="data-grid"
            bodyHeight="310px"
            [sticky]="true"
            [dataSource]="items"
            [columns]="gridColumns"
            [usePerfectScrollbar]="true"
        >
            <ng-template headerTemplate>
                <th
                    *ngFor="let c of gridColumns; let i = index"
                    class="border-top-0 border-bottom-0"
                    [style.width]="c.width"
                    scope="col"
                >
                    <div
                        [class.blue]="c.sort"
                        [class.cursor-pointer]="c.sortingField"
                        class="text-truncate body2 d-inline-flex align-items-center column-header"
                        (click)="handleSort(i)"
                    >
                        {{ c.title }}
                        <span *ngIf="c.sortingField"
                              [innerHTML]="(c.sort !== 'ASC' ? icons.sortingUp : icons.sortingDown) | safeHtml: 'html'"
                              class="fa sort-icon ml-5px"
                        ></span>
                    </div>
                </th>
            </ng-template>
            <ng-template rowTemplate let-data="item">
                <td [style.width]="gridColumns[0].width">
                    <checkbox [value]="data.selected" (change)="onSelectItem($event, data)"></checkbox>
                </td>
                <td [style.width]="gridColumns[1].width">
                    {{ data.name }}
                </td>
                <td [style.width]="gridColumns[2].width">
                    {{ data.label }}
                </td>
                <td [style.width]="gridColumns[3].width">
                    {{ data.description }}
                </td>
            </ng-template>
        </lib-data-grid>
    </div>

    <ng-container *ngIf="!items || items.length === 0">
        <div class="row no-gutters w-100 justify-content-center">
            <div class="col-3 not-found-text">No items found</div>
        </div>
    </ng-container>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-secondary mr-25px ml-auto" (click)="cancel()">
        Cancel
    </button>
    <button type="button" class="btn btn-primary" [disabled]="!isAnySelected()" (click)="handleAdd()">
        Add
    </button>
</div>
