import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { WorkflowAction, ConditionWorkflowAction } from '../models/actions';
import { matcher } from './action.matcher';
import {
    ConditionWorkflowActionBlock,
    WorkflowActionBlock,
    ForkWorkflowActionBlock,
    LoopWorkflowActionBlock,
    StartBlock,
    EndBlock,
} from './workflow-action.block';
import { WorkflowActionLine } from './workflow-actions.line';
import { calculateMaxBranchLength, deepestChild } from './utils';

// @dynamic
@Injectable()
export class WorkflowActionsConverter {
    static readonly X_GAP = 30;
    static readonly LEVEL_GAP = 50;
    static readonly BLOCK_MARGIN = 40;
    order = 0;

    constructor(private fb: FormBuilder) {}

    workflowActionBlockFactory = matcher({
        CONDITION: action => new ConditionWorkflowActionBlock(action),
        FORK: action => new ForkWorkflowActionBlock(action),
        LOOP: action => new LoopWorkflowActionBlock(action),
        _: action => new WorkflowActionBlock(action),
    });

    blocks = new Map<string, WorkflowActionBlock>();
    lines = new Map<string, WorkflowActionLine>();
    countInLine = 8;
    actionsNamesMap: Record<string, any> = {};

    private breakpoints: Set<string>;
    private actions: WorkflowAction[] = [];
    private interlevelLines: WorkflowActionLine[] = [];
    private first: WorkflowAction;
    private linesFromElseBranch: WorkflowActionLine[] = [];
    private currentLevel = 0;
    private linesToThisBranch: { from: string; to: string }[] = [];

    static generateParentsMap = (actions: WorkflowAction[]) =>
        new Map(
            Object.entries(
                actions.reduce<Record<string, WorkflowAction[]>>((acc, action) => {
                    if (!acc[action.next]) {
                        acc[action.next] = [];
                    }
                    acc[action.next].push(action);

                    return acc;
                }, {})
            )
        );

    init(actions: WorkflowAction[], breakpoints: Set<string>, countInLine = 8) {
        this.actions = actions;
        this.breakpoints = breakpoints;
        this.countInLine = countInLine;
        this.collectActionsNames(actions);

        if (this.actions.length) {
            this.first = actions[0];
        }
    }

    convert(
        actions: WorkflowAction[] = this.actions,
        parentBlock?: WorkflowActionBlock,
        prevPos = { x: 0, y: 0 },
        startFrom?: { x: number; y: number },
        countInLine = this.countInLine
    ) {
        const elseBranches: {
            elseNext: string;
            parent: WorkflowActionBlock;
            prevPos: { x: number; y: number };
            countInLine: number;
        }[] = [];

        if (!actions.length) {
            return;
        }

        const actionsMap = new Map(actions.map(action => [action.name, action]));

        let parentsMap = WorkflowActionsConverter.generateParentsMap(actions);

        const nextBlocksMap: Record<string, any> = {};
        const firstInElseBranchMap: Record<string, any> = {};

        let head = actions[0];
        const branchFirst = actions[0];
        let i = 0;
        let indexInLine = 0;
        let elseBranchesDrawn = false;

        const drawElseBranches = () => {
            for (let j = elseBranches.length - 1; j >= 0; j--) {
                const { elseNext, parent, prevPos, countInLine } = elseBranches[j];
                const actions = this.generateActionsBranch(elseNext, actionsMap, parentsMap, {}, true, parent.getMainBranch(this.actions));

                this.convert(actions, parent, prevPos, null, countInLine);

                this.lines.set(`${parent.name}${elseNext}`, new WorkflowActionLine(parent, this.blocks.get(elseNext)));

                if (!actions.length) {
                    return;
                }

                const lastInElseBranch = this.blocks.get(actions[actions.length - 1].name);
                if (lastInElseBranch && lastInElseBranch.next) {
                    this.linesToThisBranch.push({ from: lastInElseBranch.name, to: lastInElseBranch.next });
                }
            }
        };

        this.currentLevel++;

        do {
            i++;
            const block = this.workflowActionBlockFactory(head);
            block.initForm(this.fb);

            block.order = this.order++;
            block.level = this.currentLevel;

            block.breakpoint = this.breakpoints.has(head.name);

            if (parentBlock && (parentBlock.isContainerBlock() || parentBlock.isConditionBlock())) {
                block.parent = parentBlock;
            }

            if (i == 1) {
                const containerActions = block.getContainerActions() || this.actions;

                for (let action of containerActions) {
                    if (action.next) {
                        nextBlocksMap[action.next] = action.name;
                    }

                    if ((action as any).elseNext) {
                        firstInElseBranchMap[(action as any).elseNext] = true;
                    }
                }

                parentsMap = WorkflowActionsConverter.generateParentsMap(containerActions);
            }

            this.blocks.set(head.name, block);
            indexInLine++;

            const parentName = this.findLastParentName(parentsMap, block.name);
            const parent = parentName ? this.blocks.get(parentName) : parentBlock;

            const isFirst = parent && parent.isFirstBlock(head);

            if (startFrom && i === 1) {
                block.x = startFrom.x;
                block.y = startFrom.y;
            } else {
                if (parentBlock && parentBlock.isContainerBlock()) {
                    if (parentBlock.isForkBlock()) {
                        const forkBranchIndex = parentBlock.getIndexForkBranch(head);

                        block.y = isFirst && forkBranchIndex !== 0 ? parentBlock.end.y + WorkflowActionsConverter.BLOCK_MARGIN : parent.y;
                    } else if (parentBlock.isLoopBlock()) {
                        block.y = isFirst ? parentBlock.y : parent.y;

                        if (block.isLoopBlock() && block.y === parentBlock.y) {
                            block.y += 10;
                        }
                    }
                }

                if (parentBlock && parentBlock.isConditionBlock()) {
                    // else branch
                    if (i === 1) {
                        const deepestBlock = this.findDeepestElseBlock(parentBlock);
                        block.y = deepestBlock.end.y + WorkflowActionsConverter.BLOCK_MARGIN + deepestBlock.marginBottom;
                    } else {
                        block.y = parent.y;
                    }
                }

                if (!!parent) {
                    block.x = (isFirst ? parent.x : parent.end.x) + WorkflowActionsConverter.X_GAP;
                    if (startFrom) {
                        block.y = startFrom.y;
                    }
                }
            }

            block.end.x = block.x + block.width;
            block.end.y = block.y + block.height;

            if (block.isContainerBlock()) {
                const maxInLine = countInLine - indexInLine + 1;
                const count = block.getMaxChildrenCount();
                block.countInLine = maxInLine;

                if (count > 0) {
                    indexInLine += Math.min(count, maxInLine) - 1;
                }

                if (block.isForkBlock()) {
                    const { actions } = block.getCastedAction();

                    actions.forEach(nestedActions => this.convert(nestedActions, block, block.end, undefined, maxInLine));

                    block.width = block.end.x - block.x + WorkflowActionsConverter.X_GAP;
                    block.height = block.end.y - block.y;

                    block.end.x = block.x + block.width;
                    block.end.y = block.y + block.height;
                } else if (block.isLoopBlock()) {
                    const { actions } = block.getCastedAction();

                    this.convert(actions, block, block.end, undefined, maxInLine);

                    block.width = block.end.x - block.x + WorkflowActionsConverter.X_GAP;
                    block.height = block.end.y - block.y;

                    block.end.x = block.x + block.width;
                    block.end.y = block.y + block.height;
                }

                block.end.y += 5 * Math.max(deepestChild(block.action) - 1, 0);
            } else if (block.isConditionBlock()) {
                const { elseNext } = block.getCastedAction();
                const elseInThisBranch =
                    elseNext &&
                    ((nextBlocksMap[elseNext] && block.getMainBranch(this.actions).has(elseNext)) ||
                        // loop to first block
                        [branchFirst.name, this.first.name].includes(elseNext) ||
                        // loop to first else branch block
                        (this.blocks.has(elseNext) && firstInElseBranchMap[elseNext]));

                if (elseNext && !elseInThisBranch) {
                    if (actions) {
                        const count = countInLine - i;
                        const firstContainerParent = parentBlock
                            ? parentBlock.isContainerBlock()
                                ? parentBlock
                                : parentBlock.findContainerParent()
                            : undefined;
                        elseBranches.push({
                            elseNext,
                            parent: block,
                            countInLine: count <= 0 ? this.countInLine : count,
                            prevPos: firstContainerParent ? firstContainerParent.end : undefined,
                        });
                    }
                } else if (elseNext) {
                    this.linesToThisBranch.push({
                        from: block.name,
                        to: elseNext,
                    });
                }
            }

            if (!!parent && parent !== parentBlock && !(startFrom && i === 1)) {
                this.lines.set(`${parent.action.name}${head.name}`, new WorkflowActionLine(parent, block));
            }

            prevPos.x = Math.max(prevPos.x, block.end.x);
            prevPos.y = Math.max(prevPos.y, block.end.y);

            if ((countInLine > 0 && indexInLine === countInLine) || this.needLineBreak(indexInLine, countInLine, actionsMap, block)) {
                const parentIsCondition =
                    parentBlock && parentBlock.isConditionBlock() && parentBlock.getCastedAction().elseNext === actions[0].name;
                const branch = this.generateActionsBranch(
                    head.name,
                    actionsMap,
                    parentsMap,
                    {},
                    parentIsCondition,
                    block.getMainBranch(this.actions)
                ).splice(1); // first block is already drawn

                drawElseBranches();
                elseBranchesDrawn = true;

                if (branch.length) {
                    const containerParent = block.findContainerParent();
                    const deepestBlock = this.findDeepestElseBlock(this.blocks.get(actions[0].name), block.name, false);

                    const startFrom = {
                        x: !!containerParent ? containerParent.x + WorkflowActionsConverter.X_GAP : 0,
                        y: !!containerParent
                            ? containerParent.end.y + WorkflowActionsConverter.LEVEL_GAP
                            : deepestBlock.end.y + WorkflowActionsConverter.LEVEL_GAP + deepestBlock.marginBottom,
                    };

                    this.convert(
                        branch,
                        block.isConditionBlock() ? block : parentBlock,
                        prevPos,
                        startFrom,
                        !!containerParent ? containerParent.countInLine : this.countInLine
                    );

                    this.interlevelLines.push(new WorkflowActionLine(block, this.blocks.get(block.next), true));
                } else if (this.blocks.has(head.next)) {
                    this.linesToThisBranch.push({
                        from: head.name,
                        to: head.next,
                    });
                }

                break;
            } else {
                // check loop
                if (this.blocks.has(head.next)) {
                    this.linesToThisBranch.push({
                        from: head.name,
                        to: head.next,
                    });

                    break;
                }

                head = actionsMap.get(head.next);
            }
        } while (!!head);

        if (!elseBranchesDrawn) {
            drawElseBranches();
        }
    }

    generateLines() {
        for (let j = this.linesToThisBranch.length - 1; j >= 0; j--) {
            const { from, to } = this.linesToThisBranch[j];
            const fromBlock = this.blocks.get(from);
            const toBlock = this.blocks.get(to);
            if (fromBlock && toBlock) {
                const line = new WorkflowActionLine(fromBlock, toBlock, false, false, true);

                this.lines.set(`${from}-${to}`, line);
            }
        }

        const lines: WorkflowActionLine[] = Array.from(this.lines.values())
            .concat(this.interlevelLines)
            .map(line => {
                line.from = this.blocks.get(line.from.name);
                line.to = this.blocks.get(line.to.name);
                line.generate();

                return line;
            });

        if (this.linesFromElseBranch.length) {
            const sortedLines = [...this.linesFromElseBranch].sort((a, b) => b.from.end.y - a.from.end.y);
            const padding = 30;
            let prevY = 0;
            let first = true;
            for (const line of sortedLines) {
                if (line.isLoopLine) {
                    prevY = line.generateLoopLinePath(first ? padding : prevY - line.from.end.y + padding);
                    first = false;
                } else {
                    line.generateLineFromElseBranch();
                }

                lines.push(line);
            }
        }

        return lines;
    }

    generateBlocks(): WorkflowActionBlock[] {
        const startBlock = new StartBlock();
        const endBlock = new EndBlock();

        if (this.actions.length) {
            const firstAction = this.actions[0];
            const firstBlock = this.blocks.get(firstAction.name);

            let lastXBlock: WorkflowActionBlock;
            let lastYBlock: WorkflowActionBlock;

            for (const block of this.blocks.values()) {
                lastXBlock = lastXBlock && lastXBlock.end.x > block.end.x ? lastXBlock : block;
                lastYBlock = lastYBlock && lastYBlock.end.y > block.end.y ? lastYBlock : block;
            }

            startBlock.x = firstBlock.x - WorkflowActionsConverter.X_GAP - startBlock.width;
            startBlock.y = firstBlock.y;
            startBlock.end = {
                x: startBlock.x + startBlock.width,
                y: startBlock.y + startBlock.height,
            };

            this.lines.set('start', new WorkflowActionLine(startBlock, firstBlock));

            endBlock.x = lastXBlock.end.x + WorkflowActionsConverter.X_GAP;
            endBlock.y = lastYBlock.y;
            endBlock.end = {
                x: endBlock.x + endBlock.width,
                y: endBlock.y + endBlock.height,
            };

            this.actions.forEach(action => {
                const block = this.blocks.get(action.name);
                if (block) {
                    if (!action.next) {
                        this.lines.set(`${action.name}-end`, new WorkflowActionLine(block, endBlock));
                    }
                    if (action.type === 'CONDITION' && !(action as ConditionWorkflowAction).elseNext) {
                        this.lines.set(`${action.name}-else-end`, new WorkflowActionLine(block, endBlock, undefined, true));
                    }
                }
            });
        } else {
            startBlock.end = {
                x: startBlock.x + startBlock.width,
                y: startBlock.y + startBlock.height,
            };

            endBlock.x = startBlock.end.x + WorkflowActionsConverter.X_GAP;
            endBlock.end = {
                x: endBlock.x + endBlock.width,
                y: endBlock.y + endBlock.height,
            };
            this.lines.set('start-end', new WorkflowActionLine(startBlock, endBlock));
        }

        this.blocks.set(startBlock.name, startBlock);
        this.blocks.set(endBlock.name, endBlock);

        const blocks: WorkflowActionBlock[] = [];
        this.blocks.forEach(block => blocks.push(block));

        return blocks;
    }

    getBlockNamesMap(): Record<string, boolean> {
        return [...this.blocks.keys()].reduce<Record<string, any>>((acc, blockName) => {
            acc[blockName] = true;

            return acc;
        }, {});
    }

    reset() {
        this.order = 0;
        this.lines.clear();
        this.blocks.clear();
        this.linesFromElseBranch = [];
        this.interlevelLines = [];
        this.linesToThisBranch = [];
        this.currentLevel = 0;
    }

    private needLineBreak(index: number, countInLine: number, actionsMap: Map<string, WorkflowAction>, currentBlock: WorkflowActionBlock) {
        const maxInLine = countInLine - index - 1;

        if (currentBlock.next) {
            const nextAction = actionsMap.get(currentBlock.next);
            if (nextAction) {
                if (['LOOP', 'FORK'].includes(nextAction.type)) {
                    const length = calculateMaxBranchLength(nextAction);

                    return maxInLine < 2 && length >= 2;
                } else {
                    // break if condition block last
                    return countInLine === index + 1 && nextAction.type === 'CONDITION';
                }
            }
        }

        return false;
    }

    private findLastParentName(parentsMap: Map<string, WorkflowAction[]>, name: string) {
        const parents = (parentsMap.get(name) || []).map(({ name }) => this.blocks.get(name)).filter(Boolean);

        return parents.length ? parents.reduce((a, b) => (a.order > b.order ? a : b)).name : null;
    }

    private generateActionsBranch(
        head: string,
        actionsMap: Map<string, WorkflowAction>,
        parents: Map<string, WorkflowAction[]>,
        seen: Record<string, any> = {},
        isElseBranch = false,
        mainBranchMap?: Map<string, WorkflowAction>
    ) {
        const actions = [];
        let elseActions: any[] = [];
        let action: WorkflowAction = actionsMap.get(head);
        let next = head;

        if (!action) {
            return [];
        }

        do {
            actions.push(action);
            seen[action.name] = true;
            const elseNext = (action as any).elseNext;
            if (elseNext && !seen[elseNext] && !mainBranchMap.has(elseNext)) {
                elseActions = [...elseActions, ...this.generateActionsBranch(elseNext, actionsMap, parents, seen, true, mainBranchMap)];
            }
            next = action.next;
            action = actionsMap.get(next);
        } while (
            action &&
            !!next &&
            !this.blocks.has(next) &&
            !seen[action.name] &&
            // else branch merges into main branch
            (!isElseBranch || (mainBranchMap && !mainBranchMap.has(action.name)) || (!action.next && !(action as any).elseNext))
        );

        return [...actions, ...elseActions];
    }

    private generateBlocksBranch(head: string, lastBlockName?: string, seen = {}): WorkflowActionBlock[] {
        const blocks = [];
        let block = this.blocks.get(head);

        while (!!block) {
            blocks.push(block);

            const nextBlock = this.blocks.get(block.next);
            // loop
            if ((nextBlock && nextBlock.order < block.order) || (lastBlockName && block.name === lastBlockName)) {
                break;
            }

            block = nextBlock;
        }

        return blocks;
    }

    private findDeepestElseBlock(
        block: WorkflowActionBlock,
        endBlock?: string,
        removeFirst = true,
        seen: Record<string, any> = {}
    ): WorkflowActionBlock {
        const branch = this.generateBlocksBranch(block.name, endBlock).splice(removeFirst ? 1 : 0);

        seen[block.name] = true;

        if (!branch.length) {
            return block;
        }

        let branchDeepestBlock: WorkflowActionBlock;

        for (const currentBlock of branch) {
            seen[currentBlock.name] = true;
            if (currentBlock.isConditionBlock() && currentBlock.getCastedAction().elseNext) {
                const elseNextBlock = this.blocks.get(currentBlock.getCastedAction().elseNext);
                if (elseNextBlock && !seen[elseNextBlock.name]) {
                    branchDeepestBlock = this.findDeepestElseBlock(elseNextBlock, undefined, undefined, seen);

                    break;
                }
            }
        }

        if (branchDeepestBlock) {
            branch.push(branchDeepestBlock);
        }

        return branch.reduce((max, b) => (b.end.y > max.end.y ? b : max));
    }

    private collectActionsNames(actions: WorkflowAction[]) {
        (actions || []).forEach(action => {
            this.actionsNamesMap[action.name] = true;

            if (action.type === 'LOOP') {
                this.collectActionsNames((action as any).actions);
            }
            if (action.type === 'FORK') {
                (action as any).actions.forEach((forkBranch: any[]) => {
                    this.collectActionsNames(forkBranch || []);
                });
            }
        });
    }
}
