<ng-container *ngIf="!funnelIconEnabled && !isSmallWidget; else funnelIconTmpl">
    <div
        *ngIf="!editDashboard && !options.server"
        class="d-flex flex-wrap align-items-center widget-filters"
        [class.mb-3]="filters | mapper: hasVisibleFilter"
        [dpFilterSet]="filters"
        [defaultPlaceholders$]="defaultPlaceholders$"
        [parentFilter]="!hasParent"
        [intersect$]="intersect$"
        [formGroup]="filtersGroup"
        [updateDisabled]="!!debuggerState"
        [widgetId]="options.id"
        [previewMode]="previewMode"
        (loaded)="filtersLoaded.emit($event)"
    >
        <ng-container *ngFor="let filter of filters" [dpChartFilter]="filter" (ngModelChange)="handleFilterChange(filter)"></ng-container>
    </div>

    <div
        *ngIf="!isComplexPart && options.server && debuggerState.filters$ | async as filtersGroup"
        [ngStyle]="{
            display: options.type === type.METRIC_TILE ? 'none' : 'flex'
        }"
        class="flex-wrap align-items-center widget-filters"
        [class.filters-margin]="filters | mapper: hasVisibleFilter"
        [formGroup]="filtersGroup"
        data-test-id="expanded-filters"
    >
        <ng-container *ngFor="let filter of filters" [dpChartFilter]="filter" (ngModelChange)="handleFilterChange(filter)"></ng-container>

        <lib-loader class="async-loader mt-auto" *ngIf="store.workflow?.asyncQueriesLoader$ | async"></lib-loader>
    </div>
</ng-container>

<ng-template #funnelIconTmpl>
    <ng-container *ngIf="{ loading: loading$ | async, isDefaultValueDirty: isDefaultValueDirty$ | async } as vm">
        <div
            *ngIf="filters?.length && (filtersGroup$ | async) as formGroup"
            ngbDropdown
            container="body"
            class="btn btn-link border-0 p-0"
            [class.filter-in-use]="vm.isDefaultValueDirty"
            [class.d-none]="!(filters | mapper: hasVisibleFilter)"
            autoClose="outside"
            [open]="dropdownOpened"
            data-test-id="funnel-icon-wrapper"
            (openChange)="dropdownToggled($event, formGroup)"
        >
            <button
                data-test-id="funnel-icon-btn"
                class="btn p-0 border-0"
                type="button"
                ngbDropdownToggle
                [btnWithLoader]="vm.loading"
                [disabled]="vm.loading"
            >
                <ng-template>
                    <lib-waiting-loader class="waiting-loader"></lib-waiting-loader>
                </ng-template>
                <fa-icon *ngIf="!vm.loading" icon="facFilter" class="d-inline-block font-14px"></fa-icon>
            </button>
            <div
                ngbDropdownMenu
                aria-labelledby="dropdown-container"
                [style.minWidth.px]="300"
                [style.maxWidth]="maxWidth"
                [style.maxHeight.px]="maxHeight"
                class="flex-column"
            >
                <div class="filters-list d-flex flex-column flex-1 overflow-hidden">
                    <perfect-scrollbar class="d-flex flex-column">
                        <ng-container
                            *ngIf="!editDashboard && !options.server"
                            [dpFilterSet]="filters"
                            [defaultPlaceholders$]="defaultPlaceholders$"
                            [parentFilter]="!hasParent"
                            [intersect$]="intersect$"
                            [formGroup]="formGroup"
                            [updateDisabled]="!!debuggerState"
                            [widgetId]="options.id"
                            [previewMode]="previewMode"
                            (loaded)="filtersLoaded.emit($event)"
                        ></ng-container>
                        <dp-any-widget-filter-item
                            *ngFor="let filter of filters"
                            [class.d-none]="(expandedFilter && expandedFilter !== filter) || filter.type === widgetFilterType.HIDDEN"
                            [filter]="filter"
                            [form]="activeForm"
                            [expandedFilter]="expandedFilter"
                            (expandedFilterChange)="updateExpandedFilter($event, formGroup)"
                            (controlUpdated)="updateOriginControl(filter, formGroup)"
                        ></dp-any-widget-filter-item>
                    </perfect-scrollbar>
                </div>

                <div class="filters-actions d-flex justify-content-between border-top p-3">
                    <button
                        class="btn btn-secondary flex-1 text-nowrap"
                        type="button"
                        (click)="reset()"
                        [disabled]="!vm.isDefaultValueDirty"
                        data-test-id="reset-btn"
                    >
                        Reset to Default
                    </button>
                    <button
                        class="btn btn-primary flex-1 ml-3 text-nowrap"
                        type="button"
                        (click)="apply(formGroup)"
                        [disabled]="!(isDirty$ | async)"
                        data-test-id="apply-btn"
                    >
                        Apply
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
</ng-template>
