import { Route } from '@angular/router';

import {
    CI_CD_MODULE_SEGMENT,
    ENVIRONMENT_MODULE_SEGMENT,
    INSIGHT_MODULE_SEGMENT,
    OBSERVATORY_MODULE_SEGMENT, PROJECT_MODULE_SEGMENT,
} from './app-routing.constants';
import { ConveyorHealthGuard } from '@app/shared/guards/conveyor-health.guard';
import { EnvironmentsGuard } from '@app/shared/guards/environments.guard';

export interface RouteWithStatus extends Route {
    loaded?: boolean;
}

export const DYNAMIC_ROUTES: RouteWithStatus[] = [
    {
        path: PROJECT_MODULE_SEGMENT,
        loadChildren: () => import('./pages/project/project.module').then(m => m.ProjectModule),
        data: { breadcrumb: 'Project' },
    },
    {
        path: INSIGHT_MODULE_SEGMENT,
        loadChildren: () => import('./pages/insights/insights-lazy.module').then(m => m.InsightsLazyModule),
        data: { preload: true },
    },
    {
        path: OBSERVATORY_MODULE_SEGMENT,
        loadChildren: () => import('./pages/monitoring/monitoring.module').then(m => m.MonitoringModule)
    },
    {   path: CI_CD_MODULE_SEGMENT,
        canActivate: [ConveyorHealthGuard],
        loadChildren: () => import('./pages/ci-cd/ci-cd.module').then(m => m.CiCdModule)
    },
    {
        path: ENVIRONMENT_MODULE_SEGMENT,
        canActivate: [EnvironmentsGuard],
        loadChildren: () => import('./pages/environment/environment.module').then(m => m.EnvironmentModule),
    },
];
