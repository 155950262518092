import { Inject, Injectable } from '@angular/core';
import { catchError, map, startWith, switchMap, tap } from 'rxjs/operators';
import { combineLatest, defer, Observable, of } from 'rxjs';
import { faCubes } from '@fortawesome/free-solid-svg-icons';
import { faCompass } from '@fortawesome/free-regular-svg-icons';

import { RbacAction, HeaderItem, RbacStaticType, sortObjectArrayByKey } from '@dagility-ui/kit';
import {
    facCiCd,
    facEnvironments,
    facInsights,
    facKeepNI,
    facMonitoring,
    facProjects,
    filters,
} from '@dagility-ui/shared-components/icons';

import { HealthService } from '@app/shared/services/admin/health-service';
import { CheWorkspaceService } from '@app/shared/services/admin/che-workspace.service';
import { AuthService } from '@app/auth';
import { ModulesResolver } from '@app/core/services/modules.resolver';
import { ModuleConfig } from '@app/core/services/modules-api.service';
import {
    CALIBRATE_MODULE_SEGMENT,
    MCM_MODULE_SEGMENT,
    NOSKRIPT_MODULE_SEGMENT,
    XPRESSO_MODULE_SEGMENT,
} from '../../../app-routing.constants';
import { ENV_TOKEN } from '@app/tokens';

@Injectable({
    providedIn: 'root',
})
export class HeaderMenuService {
    icons = {
        facProjects: facProjects,
        facInsights: facInsights,
        facCiCd: facCiCd,
        facEnvironments: facEnvironments,
        facMonitoring: facMonitoring,
        facSettings: filters,
        facKeepNI,
    };

    staticMenuItems$ = of<HeaderItem[]>([
        ...insertIfCondition(this.env.calibrate?.enabled, {
            label: 'Calibrate',
            routerLink: CALIBRATE_MODULE_SEGMENT,
            icon: faCompass,
            module: 'calibrate',
            active$: this.checkCalibrateAvailability(),
            position: 899,
        }),
        ...insertIfCondition(this.env.darwin?.enabled, {
            label: 'Xpresso',
            routerLink: `/${XPRESSO_MODULE_SEGMENT}`,
            icon: faCubes,
            module: 'xpresso',
            active$: this.env.darwin?.enabled ? this.checkXpressoAvailability() : of(false),
            position: 1000,
        }),
        ...insertIfCondition(this.env.darwin?.enabled, {
            label: 'Noskript',
            routerLink: `/${NOSKRIPT_MODULE_SEGMENT}`,
            icon: facInsights,
            module: 'noskript',
            active$: this.env.darwin?.enabled ? this.checkNoskriptAvailability() : of(false),
            position: 1000,
        }),
        ...insertIfCondition(this.env.darwin?.enabled, {
            label: 'MCM',
            routerLink: `/${MCM_MODULE_SEGMENT}`,
            icon: facInsights,
            module: 'mcm',
            active$: this.env.darwin?.enabled ? this.checkMCMAvailability() : of(false),
            position: 1000,
        }),
        {
            label: 'Settings',
            routerLink: '/admin',
            image: this.icons.facSettings,
            module: 'settings',
            active$: of(this.authorizationService.isAdmin() || this.authorizationService.isSubscriptionAdmin()),
            position: 900,
        },
    ]);

    dynamicMenuItems$: Observable<HeaderItem[]> = this.modulesResolver.modules$.pipe(
        startWith([]),
        map((modules: ModuleConfig[]) =>
            modules.map(m => ({
                label: m.displayName,
                routerLink: `/${m.contextPath}`,
                module: m.contextPath,
                active$: m.isActive$,
                image: m.image,
                position: m.position,
            }))
        )
    );

    menuItems$: Observable<HeaderItem[]> = !this.env.calibrate?.enabled
        ? combineLatest([this.staticMenuItems$, this.dynamicMenuItems$]).pipe(
              map(([staticItems, dynamicItems]) => [...sortObjectArrayByKey(dynamicItems, 'position', true), ...staticItems]),
              tap(items => (this.moduleList = items))
          )
        : this.staticMenuItems$.pipe(tap(items => (this.moduleList = items)));

    moduleList: HeaderItem[];

    constructor(
        @Inject(ENV_TOKEN) private env: Env,
        private modulesResolver: ModulesResolver,
        private authorizationService: AuthService,
        private cheWorkspaceService: CheWorkspaceService,
        private healthService: HealthService
    ) {}

    hasRightForObject(action: RbacAction, objectLocator: string): Observable<boolean> {
        return this.authorizationService.hasPermission(RbacStaticType.OBJECT, objectLocator, action);
    }

    checkWorkspacesAvailability(): Observable<boolean> {
        return this.healthService.getIsServiceAlive('conveyor').pipe(
            switchMap(value =>
                value
                    ? this.cheWorkspaceService.getWorkspaceUrl().pipe(
                          map(url => !!url)
                      )
                    : of(false)
            ),
            catchError(() => {
                return of(false);
            })
        );
    }

    checkConveyorAvailability(): Observable<boolean> {
        return this.healthService.getIsServiceAlive('conveyor').pipe(
            map(alive => {
                return alive;
            }),
            catchError(() => {
                return of(false);
            })
        );
    }

    checkTrackingAvailability(): Observable<boolean> {
        return defer(() => {
            return of(this.authorizationService.isAdmin() && !!this.env.webConsoleApiUrl);
        });
    }

    checkMonitoringAvailability(): Observable<boolean> {
        return combineLatest([this.hasRightForObject('read', 'Observatory'), this.healthService.getIsServiceAlive('monitoring')]).pipe(
            map(([hasRights, isAlive]) => {
                return hasRights && isAlive;
            }),
            catchError(() => {
                return of(false);
            })
        );
    }

    checkCalibrateAvailability(): Observable<boolean> {
        return combineLatest([
            of(this.authorizationService.isAdmin() || this.authorizationService.isSubscriptionAdmin()),
            this.healthService.getIsServiceAlive('calibrate'),
        ]).pipe(
            map(([hasRights, isAlive]) => {
                return hasRights && isAlive;
            }),
            catchError(() => {
                return of(false);
            })
        );
    }

    checkXpressoAvailability(): Observable<boolean> {
        return combineLatest([this.hasRightForObject('view', 'Xpresso')]).pipe(
            map(([hasRights]) => {
                return hasRights;
            }),
            catchError(() => {
                return of(false);
            })
        );
    }

    checkNoskriptAvailability(): Observable<boolean> {
        return combineLatest([this.hasRightForObject('view', 'Noskript')]).pipe(
            map(([hasRights]) => {
                return hasRights;
            }),
            catchError(() => {
                return of(false);
            })
        );
    }
    checkMCMAvailability(): Observable<boolean> {
        return combineLatest([this.hasRightForObject('view', 'MCM')]).pipe(
            map(([hasRights]) => {
                return hasRights;
            }),
            catchError(() => {
                return of(false);
            })
        );
    }
}

function insertIfCondition<T>(condition: boolean, insertion: T): T[] {
    return condition ? [insertion] : [];
}
