import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormArray, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { catchError, map, share, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { range } from 'lodash';

import { DropdownItem, isDefined, validateFormAndDisplayErrors } from '@dagility-ui/kit';

import { MIN_WIDGET_SIZE } from 'data-processor/lib/widget-library/dashboard/services/gridster/angular2gridster.const';
import { GridsterOptionsService } from 'data-processor/lib/widget-library/dashboard/services/gridster/gridster-options.service';

type UnitDropdownItems = Array<DropdownItem<number>>;
const WIDGET_SIZE_KEYS = ['minW', 'minH', 'defaultW', 'defaultH'];

@Component({
    selector: 'dp-widget-builder-size-form',
    templateUrl: './widget-builder-size-form.component.html',
    styleUrls: ['./widget-builder-size-form.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'py-4 border-top border-bottom mt-2',
    },
})
export class WidgetBuilderSizeFormComponent implements OnInit, OnDestroy {
    sizesWrapper: FormGroup;
    units: FormArray;
    sizes: FormGroup;
    destroyed$ = new Subject<void>();
    boundaries: Record<string, Observable<UnitDropdownItems>> = {};
    mappers: ProportionToUnitMapper[] = [];

    constructor(private gridsterOptions: GridsterOptionsService, public formGroup: FormGroupDirective) {}

    /*@tech: need to dynamic update units on resizing*/
    ngOnInit() {
        this.sizes = this.formGroup.form.get('size') as FormGroup;
        this.sizesWrapper = new FormGroup({
            units: new FormArray(
                WIDGET_SIZE_KEYS.map(
                    prop => new FormControl(getUnitValue(this.sizes.get(prop) as FormControl, this.gridsterOptions), [Validators.required])
                )
            ),
        });
        this.units = this.sizesWrapper.get('units') as FormArray;
        this.mappers = WIDGET_SIZE_KEYS.map(
            (prop, idx) =>
                new ProportionToUnitMapper(this.sizes.get(prop) as FormControl, this.units.at(idx) as FormControl, this.gridsterOptions)
        );
        this.units.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.updateProportions();
        });
        const maxLanes$ = this.gridsterOptions.maxLanes$.pipe(take(1));
        const minWControl = this.units.at(0);
        const minHControl = this.units.at(1);
        this.boundaries.minW = unitsRange(of(MIN_WIDGET_SIZE), maxLanes$);
        this.boundaries.minH = unitsRange(of(MIN_WIDGET_SIZE), maxLanes$);
        this.boundaries.defaultW = unitsRange(valueChanges(minWControl), maxLanes$).pipe(boundControlValue(this.units.at(2)));
        this.boundaries.defaultH = unitsRange(valueChanges(minHControl), maxLanes$).pipe(boundControlValue(this.units.at(3)));
        this.patchOnTouched();
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }

    // https://github.com/angular/angular/issues/17736
    patchOnTouched() {
        const markAsTouched = this.sizes.markAsTouched;

        this.sizes.markAsTouched = (...args: any) => {
            validateFormAndDisplayErrors(this.units);
            markAsTouched.bind(this.sizes)(...args);
        };
    }

    private updateProportions() {
        this.mappers.forEach(mapper => mapper.updateProportionControl());
    }

    private updateUnits() {
        this.mappers.forEach(mapper => mapper.updateUnitControl());
    }
}

function unitsRange(from$: Observable<number>, to$: Observable<number>): Observable<UnitDropdownItems> {
    return combineLatest([from$, to$]).pipe(
        map(([from, to]) => {
            if (from > to) {
                throw Error();
            }

            return range(Math.max(from, MIN_WIDGET_SIZE), to + 1).map(unit => ({
                label: `${unit} units`,
                value: unit,
            }));
        }),
        catchError(() => of([] as UnitDropdownItems)),
        share()
    );
}

function valueChanges(control: AbstractControl) {
    return control.valueChanges.pipe(startWith(control.value as number));
}

function boundControlValue(control: AbstractControl) {
    return function(source: Observable<UnitDropdownItems>) {
        return source.pipe(
            tap(units => {
                if (units.length) {
                    const [firstUnit] = units;

                    if (isDefined(control.value) && firstUnit.value > control.value) {
                        control.patchValue(firstUnit.value);
                    }
                } else {
                    control.patchValue(null);
                }
            })
        );
    };
}

class ProportionToUnitMapper {
    constructor(
        private proportionControl: FormControl,
        private unitControl: FormControl,
        private gridsterOptions: GridsterOptionsService
    ) {}

    updateProportionControl() {
        this.proportionControl.patchValue(Number(this.unitControl.value) / this.gridsterOptions.getMaxLanes());
    }

    updateUnitControl() {
        this.unitControl.patchValue(getUnitValue(this.proportionControl, this.gridsterOptions), {
            emitEvent: false,
        });
    }
}

function getUnitValue(proportionControl: FormControl, gridsterOptions: GridsterOptionsService) {
    return proportionControl.value
        ? gridsterOptions.coerceMinSize(gridsterOptions.convertProportionToUnit(proportionControl.value, gridsterOptions.getMaxLanes()))
        : null;
}
