import { Component, Inject, Input, OnChanges } from '@angular/core';
import { startCase } from 'lodash';

import { EditJobDefinitionService } from '../edit-job-definition.service';
import { FIELD_NAMES } from '../field-names';

@Component({
    selector: 'dp-edit-job-definition-breadcrumb',
    templateUrl: './edit-job-definition-breadcrumb.component.html',
    styleUrls: ['./edit-job-definition-breadcrumb.component.scss'],
})
export class EditJobDefinitionBreadcrumbComponent implements OnChanges {
    @Input() path: string[] = [];

    pathWithName: any[] = [];

    constructor(@Inject(FIELD_NAMES) private fieldNames: Record<string, any>, public jdStore: EditJobDefinitionService) {}

    ngOnChanges() {
        this.pathWithName = this.path.map(p => ({
            field: p,
            name: this.fieldNames[p] || startCase(p),
        }));
    }

    goToStep(index: number) {
        const newPath = index === 0 ? [this.path[0]] : this.path.slice(0, index + 1);

        this.jdStore.goToPath(newPath);
    }

    goToRoot() {
        this.jdStore.setState({ path: [] });
    }
}
