<div class="card common-popup plugin-config-content popup-max-width-none h-100 d-flex flex-column">
    <div class="modal-header d-flex flex-column">
        <div class="pt-10px d-inline-flex w-100 align-items-center pb-4">
            <h6 class="font-14px header-text mb-0">Logs for {{ plugin.instanceName }}</h6>
            <button
                type="button"
                class="close ml-auto"
                aria-label="Close button"
                aria-describedby="modal-title"
                (click)="modalService.close()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="row no-gutters align-items-end w-100">
            <span></span>
            <form [formGroup]="formGroup">
                <lib-datepicker
                    class="mr-25px mt-5px"
                    [dateWithTime]="true"
                    (ngModelChange)="setStartDate($event)"
                    [placeholder]="'Start date/time'"
                    formControlName="dateTime"
                    name="dateTime"
                >
                </lib-datepicker>
            </form>

            <lib-dropdown
                class="mr-25px col-3 logs-type"
                (change)="handleLogTypeChange($event)"
                placeholder="All logs type"
                [items]="logTypes"
                labelKey="value"
                valueKey="value"
                ngSelectItemsFilter="true"
                id="type"
            ></lib-dropdown>

            <lib-search
                class="search-bar col-5 mt-15px"
                [placeholder]="'Search by Log Message'"
                (searchText)="searchData($event)"
            ></lib-search>
        </div>
    </div>

    <div class="list-log-view mt-10px">
        <ng-container *ngIf="this.loaded; else loadingTmpl">
            <ng-container *ngIf="currentLogs.length !== 0; else notFoundTmpl">
                <div
                    class="log-list card-body py-0 px-4 popup-form-control padding-body flex-1 d-flex flex-column overflow-y-auto"
                >
                    <div *ngIf="!isPreviousLogLoaded" class="d-flex justify-content-center align-items-center">
                        <lib-spinner></lib-spinner>
                    </div>
                    <lib-data-grid
                        class="d-block"
                        [wheelUsing]="true"
                        [wheelUpCallback]="loadPreviousLogs"
                        [wheeledDownCallback]="loadNextLogs"
                        [checkLog]="isPreviousLogLoaded"
                        [bodyHeight]="'300px'"
                        [dataSource]="this.currentLogs"
                        [columns]="logGridColumns"
                        [sticky]="true"
                    >
                        <ng-template headerTemplate>
                            <tr>
                                <th class="table-header-cell" *ngFor="let c of logGridColumns" [style.width]="c.width">
                                    {{ c.title }}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template rowTemplate let-log="item">
                            <td style="width: 22%" class="text-truncate">
                                <span [ngClass]="markErrorLog(log, 'time')" [ngbTooltip]="log.timestamp | date: 'OOOO'" placement="top">
                                    {{ log.timestamp | date: 'dateTimeCustom' }}
                                </span>
                            </td>
                            <td style="width: 8%" [ngClass]="markErrorLog(log, 'type')">{{ log.type }}</td>
                            <td style="width: 70%" [ngClass]="markErrorLog(log, 'message')">{{ log.message }}</td>
                        </ng-template>
                    </lib-data-grid>
                    <div *ngIf="!isNextLogLoaded" class="d-flex justify-content-center align-items-center"><lib-spinner></lib-spinner></div>
                </div>
            </ng-container>
            <ng-template #notFoundTmpl>
                <div class="logs-not-found-wrapper d-flex justify-content-center flex-column align-items-center px-2">
                    <div class="logs-not-found-image"></div>
                    <span class="not-found-text">
                        Looks like job doesn't have any logs yet
                    </span>
                </div>
            </ng-template>
        </ng-container>
        <ng-template #loadingTmpl>
            <div class="spinner-wrapper d-flex justify-content-center align-items-center">
                <lib-spinner></lib-spinner>
            </div>
        </ng-template>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-primary form-button ml-auto font-11px" (click)="exportToFile()">
            Export logs to file
        </button>
        <button type="button" class="btn btn-secondary ml-25px font-11px" (click)="modalService.close()">
            Cancel
        </button>
    </div>
</div>
