import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { AnyWidgetModel } from '../../../widget-builder/models/any-widget.model';

import { WidgetEvent } from './widget-event.manager';

@Injectable()
export class DashboardMessageBus extends BehaviorSubject<WidgetEvent[]> {
    eventDependencies: Record<string, Subject<any>> = {};
    tabChanged$ = new Subject<void>();

    constructor() {
        super([]);
    }

    emit(event: any) {
        this.next([...this.value, event]);
    }

    on() {
        return this.pipe();
    }

    reset() {
        this.next([]);
    }

    eventReload() {
        this.next(null);
    }
}

export function findEventHandler(event: WidgetEvent, widget: AnyWidgetModel) {
    return (widget.handlers || []).find(handler => handler.type === 'IN' && handler.eventId === event.id);
}
