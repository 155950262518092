import {Component, Input, NgZone, OnInit, ViewChild} from '@angular/core';
import { NgbActiveModal, NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ProcessorMonitoringService } from '../processor-monitoring.service';
import { facArrowDown, facArrowUp } from '../processor-monitoring-icons';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { DataGridComponent, GridColumn, ScrollDirective, writeContents } from '@dagility-ui/kit';
import { CustomIcon } from '@dagility-ui/shared-components/icons';

@Component({
    selector: 'dp-view-job-definition-log-form',
    templateUrl: './view-job-definition-log-form.component.html',
    styleUrls: ['./view-job-definition-log-form.component.scss'],
    providers: [ProcessorMonitoringService],
})
export class ViewJobDefinitionLogFormComponent implements OnInit {
    @Input() scrollCallback: Function;
    @Input() bodyHeight: string;
    @Input() plugin: any;

    @ViewChild(DataGridComponent) dataGrid: DataGridComponent;
    @ViewChild(ScrollDirective) scrollDirective: ScrollDirective;

    currentLogs: any;
    loaded: boolean;
    todayNgb: NgbDate;
    today: Date = null;
    isPreviousButtonDisabled = false;
    isNextLogLoaded = true;
    isPreviousLogLoaded = true;
    filterText: string;
    filterType: string;
    instanceName: string;

    dateTime: Date = null;
    updateFilter$: Subject<void> = new Subject();
    formGroup: FormGroup;

    icons: Record<string, IconDefinition | CustomIcon> = {
        facArrowUp: facArrowUp,
        facArrowDown: facArrowDown,
    };

    logGridColumns: GridColumn[] = [
        {
            title: 'Timestamp',
            field: 'timestamp',
            sortingField: 'timestamp',
            width: '22%',
        },
        {
            title: 'Type',
            field: 'timestamp',
            sortingField: 'timestamp',
            width: '8%',
        },
        {
            title: 'Log Message',
            field: 'message',
            sortingField: 'message',
            width: '70%',
        },
    ];

    logTypes = [{ value: 'Any' }, { value: 'INFO' }, { value: 'ERROR' }];

    constructor(
        public modalService: NgbActiveModal,
        private pmService: ProcessorMonitoringService,
        private calendar: NgbCalendar,
        private zone: NgZone,
        private dispatcher: ScrollDispatcher,
        private toaster: ToastrService
    ) {}

    ngOnInit() {
        this.instanceName = this.plugin.instanceName.replace(/{toolId}/g, this.plugin.toolId);
        this.getLogs(this.instanceName);

        this.formGroup = new FormGroup(
            {
                dateTime: new FormControl(''),
            },
            { updateOn: 'change' }
        );

        this.todayNgb = this.calendar.getToday();
        this.today = new Date(this.todayNgb.year, this.todayNgb.month, this.todayNgb.day);

        this.updateFilter$
            .pipe(
                switchMap(() => {
                    return this.pmService.getJobDefinitionsLogs(
                        this.instanceName,
                        this.plugin.toolId,
                        new Date(this.dateTime).getTime(),
                        undefined,
                        this.filterType === 'Any' ? undefined : this.filterType,
                        this.filterText
                    );
                })
            )
            .subscribe(response => {
                this.modifyCurrentLogs(response);
            });
    }

    getLogs(instanceName: string, from?: number, to?: number) {
        this.pmService.getJobDefinitionsLogs(instanceName, this.plugin.toolId, from, to).subscribe(response => {
            this.modifyCurrentLogs(response);
        });
    }

    searchData(filter: any): void {
        const searchText = filter;
        this.filterText = searchText;
        this.updateFilter$.next();
    }

    handleLogTypeChange(event: any) {
        if (event) {
            this.filterType = event.value;
            this.updateFilter$.next();
        }
    }

    modifyCurrentLogs(logsResponse: any) {
        const receivedLogs = Object.keys(logsResponse).map(function(key) {
            return logsResponse[key];
        });
        this.currentLogs = receivedLogs;
        this.loaded = true;
        setTimeout(() => {
            if (this.dataGrid) {
                const scrollElement = this.dataGrid.scroll.element;
                if (scrollElement) {
                    scrollElement.nativeElement.scrollTo(0, scrollElement.nativeElement.scrollHeight);
                    this.dataGrid.scroll.isOnBottom = true;
                }
            }
        });
    }

    setStartDate(startDate: Date) {
        if (startDate > this.today || !this.formGroup.valid) {
            this.toaster.error(`You entered invalid date`, `Date Validation Error`);
        } else {
            this.dateTime = startDate;
            this.getLogsByDate();
        }
    }

    getLogsByDate() {
        this.updateFilter$.next();
    }

    exportToFile() {
        let logsToFileArray = 'Time                         ' + 'Type    ' + 'Message';
        for (const log of this.currentLogs) {
            let logText;
            if (log.message.includes('\n')) {
                logText = log.message.replace(/\n/gi, '\n                                     ');
            } else {
                logText = log.message;
            }
            logsToFileArray =
                logsToFileArray +
                '\n' +
                new DatePipe('en-US').transform(log.timestamp, 'yyyy-MM-dd, h:mm:ss.SSS a') +
                '   ' +
                log.type +
                '   ' +
                '"' +
                logText +
                '"';
        }
        writeContents(new Blob([logsToFileArray], { type: 'text/csv' }), 'logs.txt');
    }

    loadPreviousLogs = () => {
        if (this.isPreviousLogLoaded) {
            this.isPreviousLogLoaded = false;
            if (this.currentLogs.length !== 0) {
                this.pmService
                    .getJobDefinitionsLogs(
                        this.instanceName,
                        this.plugin.toolId,
                        undefined,
                        this.currentLogs[0].timestamp - 1,
                        this.filterType === 'Any' ? undefined : this.filterType,
                        this.filterText
                    )
                    .subscribe(response => {
                        this.subscribingForLoadingPreviousLogs(response);
                    });
            } else if (this.dateTime != null) {
                this.pmService
                    .getJobDefinitionsLogs(
                        this.instanceName,
                        this.plugin.toolId,
                        undefined,
                        new Date(this.dateTime).getTime() - 1,
                        this.filterType === 'Any' ? undefined : this.filterType,
                        this.filterText
                    )
                    .subscribe(response => {
                        this.subscribingForLoadingPreviousLogs(response);
                    });
            }
        }
    };

    subscribingForLoadingPreviousLogs(response: any) {
        const receivedLogs = Object.keys(response).map(function(key) {
            return response[key];
        });
        if (receivedLogs.length < 100 || receivedLogs.length === 0) {
            this.isPreviousButtonDisabled = true;
        }
        this.currentLogs = [...receivedLogs, ...this.currentLogs];
        this.isPreviousLogLoaded = true;
    }

    loadNextLogs = () => {
        if (this.isNextLogLoaded) {
            this.isNextLogLoaded = false;
            if (this.currentLogs.length !== 0) {
                this.pmService
                    .getJobDefinitionsLogs(
                        this.instanceName,
                        this.plugin.toolId,
                        this.currentLogs[this.currentLogs.length - 1].timestamp + 1,
                        undefined,
                        this.filterType === 'Any' ? undefined : this.filterType,
                        this.filterText
                    )
                    .subscribe((response: any) => {
                        if (response.length !== 0) {
                            this.dataGrid.scroll.isOnBottom = false;
                        } else {
                            this.dataGrid.scroll.isOnBottom = true;
                        }
                        const receivedLogs = Object.keys(response).map(function(key) {
                            return response[key];
                        });
                        this.currentLogs = [...this.currentLogs, ...receivedLogs];
                        this.isNextLogLoaded = true;
                    });
            }
        }
    };

    markErrorLog(logMessage: any, column: string) {
        if (column === 'type' && logMessage.type === 'ERROR') {
            return 'error-log-type';
        } else if (logMessage.type === 'ERROR' && (column === 'time' || 'message')) {
            return 'error-log';
        }
    }
}

export const DateTimeValidator = (fc: FormControl) => {
    const dateNow = new Date();
    const date = new Date(fc.value);
    const isValid = dateNow > date;
    return isValid
        ? null
        : {
              isValid: {
                  valid: false,
              },
          };
};
