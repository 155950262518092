import { Observable } from 'rxjs';
import { RbacAction, RbacObjectId, RbacStaticType, RbacType } from '@dagility-ui/kit';

/**
 * This class describes required permissions of actions of a specific object.
 * It is used with ifHasRight and PermissionGuard.
 * It can also contain a prepared Observable that to execute right on the object
 */
export class RequiredRights {
    get prepared$(): Observable<boolean> {
        return this._prepared$;
    }

    isPrepared(): boolean {
        return !!this._prepared$;
    }

    prepare(value: Observable<boolean>) {
        this._prepared$ = value;
        this._permissions = null;
        this.current = null;
    }

    get permissions(): RequiredRightsItem[][] {
        return this._permissions;
    }

    /**
     * the first dimension is 'or'-bound set of required rights, the second is 'and'-bound required rights
     */
    private _permissions: RequiredRightsItem[][];

    private current: RequiredRightsItem[];

    /**
     * prepared observable that can be executed multiple times
     */
    private _prepared$: Observable<boolean>;

    /**
     * Construct the object.
     *
     * @param role      role to check
     * @param allRoles  array of roles
     * @param anyRole   array of roles
     */
    constructor() {
        this._permissions = [];
        this.current = [];
        this._permissions.push(this.current);
    }

    or(): RequiredRights {
        this.current = [];
        this._permissions.push(this.current);
        return this;
    }

    /**
     * check that the user has specified role
     * @param role authority name, not null
     */
    static hasRole(role: string): RequiredRights {
        const result = new RequiredRights();
        return result.hasRole(role);
    }

    hasRole(role: string): RequiredRights {
        this.current.push({ kind: RequiredRightsKind.ROLE, role: role });
        return this;
    }

    /**
     * check that the user has all specified roles
     * @param roles authorities names, not null
     */
    static hasAllRoles(roles: string[]): RequiredRights {
        const result = new RequiredRights();
        return result.hasAllRoles(roles);
    }

    hasAllRoles(roles: string[]): RequiredRights {
        this.current.push({ kind: RequiredRightsKind.ALL_ROLES, roles: roles });
        return this;
    }

    /**
     * check that the user has any of specified roles
     * @param roles authorities names, not null
     */
    static hasAnyRole(roles: string[]): RequiredRights {
        const result = new RequiredRights();
        return result.hasAllRoles(roles);
    }

    hasAnyRole(roles: string[]): RequiredRights {
        this.current.push({ kind: RequiredRightsKind.ANY_ROLE, roles: roles });
        return this;
    }

    /**
     * check that the user has ADMIN role
     */
    static isAdmin(): RequiredRights {
        const result = new RequiredRights();
        return result.isAdmin();
    }

    isAdmin(): RequiredRights {
        return this.hasRole('ROLE_ADMIN');
    }

    /**
     * check that the user has SUBSCRIPTION ADMIN  or Admin role
     * this function needs to be removed once user Heirarchy is defined
     */
    static isSubscriptionAdminOrAdmin(): RequiredRights {
        const result = new RequiredRights();
        return result.isSubscriptionAdminOrAdmin();
    }

    isSubscriptionAdminOrAdmin(): RequiredRights {
        return this.hasAnyRole(['ROLE_SUBSCRIPTION_ADMIN', 'ROLE_ADMIN']);
    }

    /**
     * check that the user has permission on specified object
     * @param objId object id [not null]. Combination type + objId identifiers ACL in database.
     * @param action checked action name of the object. If it is an array, it means that all of the specified actions
     *               must have permissions.
     */
    static object(objId: RbacObjectId, action: RbacAction | RbacAction[]): RequiredRights {
        const result = new RequiredRights();
        result.object(objId, action);
        return result;
    }

    object(objId: RbacObjectId, action: RbacAction | RbacAction[]): RequiredRights {
        this.current.push({
            kind: RequiredRightsKind.PERMISSION,
            type: RbacStaticType.OBJECT,
            objId: objId,
            action: action,
        });
        return this;
    }

    /**
     * check that the user has permission on specified object
     * @param type type of checked permission. Usually it is a OBJECT or 'java.class.Name' for UI application
     * @param objId object id [not null]. Combination type + objId identifiers ACL in database.
     * @param action checked action name of the object. If it is an array, it means that all of the specified actions
     *               must have permissions.
     */
    static permission(type: RbacStaticType, objId: RbacObjectId, action: RbacAction | RbacAction[]): RequiredRights {
        const result = new RequiredRights();
        result.permission(type, objId, action);
        return result;
    }

    permission(type: RbacStaticType, objId: RbacObjectId, action: RbacAction | RbacAction[]): RequiredRights {
        this.current.push({
            kind: RequiredRightsKind.PERMISSION,
            type: type,
            objId: objId,
            action: action,
        });
        return this;
    }
}

export enum RequiredRightsKind {
    PERMISSION,
    ROLE,
    ALL_ROLES,
    ANY_ROLE,
}

export interface RequiredRightsItem {
    kind: RequiredRightsKind;
    type?: RbacType;
    objId?: string;
    action?: RbacAction | RbacAction[];
    role?: string;
    roles?: string[];
}
