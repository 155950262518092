<div class="card">
    <header class="d-flex d-inline-flex w-100 justify-content-between align-items-center">
        <div class="d-flex flex-wrap align-items-center">
            <div class="d-inline-flex justify-content-start mr-30px my-6px  ">
                <a class="fa fa-file-o font-14px icons-file-tree" aria-label="file"></a>
                <div class="title ml-10px">
                    {{ sourceItem.name }}
                </div>
            </div>
            <div class="d-inline-flex justify-content-start mr-auto">
                <div class="additional">
                    <span class="caption mr-5px">Size:</span> <span class="additional">{{ sourceItem.size | fileSize: 2 }}</span>
                </div>
                <div class="additional ml-15px">
                    <span class="caption mr-5px">Last modified:</span>
                    <span class="additional">{{ sourceItem.lastModifiedDate | date: 'dateTimeCustom' }}</span>
                </div>
            </div>
        </div>

        <button type="button" class="close position-absolute" aria-label="Close button" aria-describedby="modal-title" (click)="cancel()">
            <span aria-hidden="true">&times;</span>
        </button>
    </header>
    <perfect-scrollbar class="scrollbar wrapper-max-height-100vh-250">
        <lib-codemirror-editor
            [theme]="'idea'"
            [mode]="sourceItem.contentType"
            [readonly]="readOnly"
            [propertiesCompletion]="autoCompletionDictionary"
            [lineWrapping]="true"
            [bracketSyntax]="false"
            [needRefresh]="true"
            [(ngModel)]="contentValue"
            (ngModelChange)="editorValueChange($event)"
        >
        </lib-codemirror-editor>
    </perfect-scrollbar>
    <div class="modal-footer d-inline-flex justify-content-end align-items-center w-100">
        <div *ngIf="saving" class="loader"></div>
        <button class="btn btn-secondary ml-25px" (click)="cancel()">Cancel</button>
        <button *ngIf="!readOnly" [disabled]="wasSaved" class="btn btn-primary ml-25px" (click)="saveFile()">Save</button>
    </div>
</div>
