import { Store } from '@dagility-ui/kit';

import { WorkflowAction, ConditionWorkflowAction, LoopAction } from '../models/actions';
import { LoopWorkflowActionBlock, WorkflowActionBlock, ContainerBlock } from '../job-definition-builder/workflow-action.block';
import { flatContainerActions, generateWorkflowAction } from '../job-definition-builder/utils';
import { WorkflowBuilderState } from './job-definition.state.model';

export class WorkflowBuilder {
    breakpoints = new Set<string>();

    static getDefaultState(): WorkflowBuilderState {
        return {
            builder: {
                blocks: [],
            },
        };
    }

    constructor(private state: Store<WorkflowBuilderState>) {}

    getValue() {
        return this.state.getValue();
    }

    setState(partialState: Partial<WorkflowBuilderState>) {
        const nextState = { ...this.state.getValue(), ...partialState };

        this.state.next(nextState);
    }

    drop = (
        dropped: WorkflowAction | WorkflowActionBlock,
        to: WorkflowActionBlock,
        connector: 'next' | 'prev' | 'elseNext',
        namesMap: Record<string, boolean>
    ) => {
        const { blocks } = this.getValue().builder;
        const mappedAction = generateWorkflowAction(dropped);

        if (namesMap[mappedAction.name]) {
            mappedAction.name = `${mappedAction.type.charAt(0).toUpperCase()}${mappedAction.type.slice(1).toLowerCase()} ${Math.random()
                .toString()
                .substr(2, 3)}`;
        }

        if (blocks.length) {
            if (!to) {
                mappedAction.next = blocks[0].name;
                blocks.unshift(mappedAction);
            } else if (!connector && to.isContainerBlock()) {
                to.push(mappedAction);
            } else {
                const parent = to.findContainerParent();

                const parentActions = parent
                    ? parent.isForkBlock()
                        ? parent.getCastedAction().actions[parent.getIndexForkBranch(to.action)]
                        : (parent as LoopWorkflowActionBlock).getCastedAction().actions
                    : blocks;

                if (connector === 'elseNext' && to.isConditionBlock()) {
                    mappedAction.next = to.getCastedAction().elseNext;
                    to.getCastedAction().elseNext = mappedAction.name;
                } else if (connector === 'next') {
                    if (to.hasNext()) {
                        mappedAction.next = to.next;
                    }

                    to.next = mappedAction.name;
                } else if (connector === 'prev') {
                    this.addPrevious(to.action, mappedAction, parentActions);
                }

                const parentIndex = parentActions.findIndex(a => a.name === to.name);

                if (connector === 'prev' && parentIndex === 0) {
                    parentActions.unshift(mappedAction);
                } else {
                    if (to.isConditionBlock() && connector === 'elseNext') {
                        parentActions.push(mappedAction);
                    } else {
                        parentActions.splice(parentIndex + (connector === 'next' ? 1 : 0), 0, mappedAction);
                    }
                }
            }
        } else {
            blocks.push(mappedAction);
        }

        this.setState({ builder: { blocks: [...blocks] } });
    };

    private addPrevious(to: WorkflowAction, addedAction: WorkflowAction, container: WorkflowAction[]) {
        container.forEach(containerAction => {
            if (containerAction.next === to.name) {
                containerAction.next = addedAction.name;
            }
            if ((containerAction as ConditionWorkflowAction).elseNext === to.name) {
                (containerAction as ConditionWorkflowAction).elseNext = addedAction.name;
            }
        });

        addedAction.next = to.name;
    }

    delete = (block: WorkflowActionBlock) => {
        const { blocks } = this.getValue().builder;

        const removeFromBranch = (blocks: WorkflowAction[]) =>
            blocks.forEach(b => {
                if (b.next === block.name) {
                    b.next = block.next;
                }

                if ((b as any).elseNext === block.name) {
                    (b as any).elseNext = block.next;
                }
            });

        this.breakpoints.delete(block.name);
        this.removeBreakpoints(flatContainerActions(block.action));

        if (!block.hasParent()) {
            // is main branch
            removeFromBranch(blocks);
        } else {
            if (!block.parent.isConditionBlock()) {
                // is container block (fork / loop)
                (block.parent as ContainerBlock).delete(block);
            } else {
                const action = block.parent.getCastedAction();
                const parentContainer = block.findContainerParent();
                const parentActions = parentContainer
                    ? parentContainer.isForkBlock()
                        ? parentContainer.getCastedAction().actions[parentContainer.getIndexForkBranch(block.action)]
                        : (parentContainer as LoopWorkflowActionBlock).getCastedAction().actions
                    : blocks;

                if (action.elseNext === block.name) {
                    action.elseNext = block.next;
                } else {
                    removeFromBranch(parentActions);
                }

                const removedBlockIndex = parentActions.findIndex(a => a.name === block.name);
                parentActions.splice(removedBlockIndex, 1);
            }
        }

        this.setState({ builder: { blocks: blocks.filter(b => b.name !== block.name) } });
    };

    changeBlock(oldName: string, block: WorkflowActionBlock, action: WorkflowAction) {
        Object.assign(block.action, action);

        const { blocks } = this.getValue().builder;

        if (oldName !== action.name) {
            const changeName = (name: string, a: WorkflowAction, newName: string) => {
                if (a.next === name) {
                    a.next = newName;
                }
                if ((a as ConditionWorkflowAction).elseNext === name) {
                    (a as ConditionWorkflowAction).elseNext = newName;
                }
            };

            const container = block.findContainerParent();
            let containerBlocks = blocks;
            if (container) {
                if (container.isForkBlock()) {
                    containerBlocks = container.getCastedAction().actions[
                        container.getIndexForkBranch({ name: action.name, next: null, type: null })
                    ];
                } else {
                    containerBlocks = (container.getCastedAction() as LoopAction).actions;
                }
            }
            containerBlocks.forEach(a => changeName(oldName, a, action.name));
        }

        this.setState({ builder: { blocks: [...blocks] } });
    }

    toggleBreakpoint = (name: string) => {
        if (this.breakpoints.has(name)) {
            this.breakpoints.delete(name);
        } else {
            this.breakpoints.add(name);
        }
    };

    removeBreakpoints(actions: WorkflowAction[]) {
        actions.forEach(({ name }) => this.breakpoints.delete(name));
    }
}
