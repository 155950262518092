<lib-toastr
    [toastrType]="toastPackage.toastType"
    [title]="title"
    [message]="message"
    [htmlBodyContent]="options.enableHtml"
    [closeButton]="options.closeButton"
    [onCloseFunction]="onClose"
>
    <ng-template bodyTemplate>
        <ng-container
            *ngIf="bodyTemplate"
            [ngTemplateOutlet]="bodyTemplate"
        >
        </ng-container>
    </ng-template>
</lib-toastr>
