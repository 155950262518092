import {Pipe, PipeTransform} from '@angular/core';

@Pipe ({
    name: 'numberOfProjects'
})
export class NumberOfProjectsPipe implements PipeTransform{
    transform(value: number): string {
        return value < 10 ? '0' + value : value > 99 ? '99+' : value.toString();
    }
}
