<div *ngIf="currentSourceFolder$ | async as sources" [ngClass]="{ adaptive: headerStyle }" class="files-grid-group h-100 p-1">
    <div class="row no-gutters align-items-center justify-content-end mb-2">
        <div *ngIf="sources && currentPath" class="path-row col">
            <app-files-grid-path [currentPath]="currentPath" (selectPath)="handleSelectPath($event)"></app-files-grid-path>
        </div>
        <button
            *ngIf="sourceType === sourcesBelongType.PROJECT_COMPONENT"
            class="btn btn-secondary mr-2"
            (click)="openShowChangeLogDialog()"
            data-test-id="show-change-log-btn"
        >
            Show Change Log
        </button>
        <button
            *ngIf="sourceType === sourcesBelongType.TEMPLATE_COMPONENT || enablePublish"
            class="btn btn-primary mr-2"
            data-test-id="publish-changes-btn"
            (click)="openPublishChangesDialog()"
        >
            Publish Changes
        </button>
        <lib-treelike-menu
            [treeItems]="actionItems"
            [openIconStyles]="{
                color: 'var(--da-brand-base)'
            }"
            btnClasses="border-0 btn-secondary"
            [closeOnClick]="true"
            [closeOnScroll]="true"
            data-test-id="files-grid-treelike-menu"
        >
        </lib-treelike-menu>
    </div>

    <lib-data-grid
        [dataSource]="sources"
        [sticky]="true"
        [usePerfectScrollbar]="true"
        [columns]="gridColumns"
        [bodyHeight]="gridHeight"
        (rowDoubleClick)="handleRowDoubleClick($event)"
        class="data-grid"
    >
        <ng-template headerTemplate>
            <tr class="table-border">
                <th
                    *ngFor="let c of gridColumns; let i = index"
                    [ngStyle]="{ width: c.width, 'min-width': c.minWidth }"
                    id="c"
                    (click)="sortClick(i)"
                    class="over-me"
                >
                    <div
                        [class.blue]="c.sort"
                        [class.cursor-pointer]="c.sortingField"
                        [class.cursor-default]="!c.sortingField"
                        class="text-truncate body2 d-inline-flex align-items-center column-header"
                        [ngbTooltip]="c.title"
                        container="body"
                    >
                        {{ c.title }}
                        <span
                            *ngIf="c.sortingField"
                            [innerHTML]="(c.sort !== 'ASC' ? icons.sortingUp : icons.sortingDown) | safeHtml: 'html'"
                            class="fa sort-icon ml-5px"
                        >
                        </span>
                    </div>
                </th>
            </tr>
        </ng-template>

        <ng-template rowTemplate let-sourceItem="item">
            <td [ngStyle]="{ width: gridColumns[0].width, 'min-width': gridColumns[0].minWidth }" class="cursor-default over-me">
                <div class="d-inline-flex align-items-center">
                    <a *ngIf="sourceItem.isFolder" class="fa fa-folder-o font-14px color-brand mr-10px" aria-label="folder"></a>
                    <a *ngIf="!sourceItem.isFolder" class="fa fa-file-o font-14px color-brand mr-10px" aria-label="file"></a>
                    {{ sourceItem.name }}
                </div>
            </td>
            <td [ngStyle]="{ width: gridColumns[1].width, 'min-width': gridColumns[1].minWidth }" class="cursor-default over-me">
                <div *ngIf="!sourceItem.isFolder">
                    {{ sourceItem.size | fileSize: 2 }}
                </div>
                <div *ngIf="sourceItem.isFolder && sourceItem.size">
                    {{ sourceItem.size + ' object' + (sourceItem.size > 1 ? 's' : '') }}
                </div>
            </td>
            <td [ngStyle]="{ width: gridColumns[2].width, 'min-width': gridColumns[2].minWidth }" class="cursor-default over-me">
                {{ sourceItem.lastModifiedDate | date: 'dateTimeCustom' }}
            </td>
            <td [ngStyle]="{ width: gridColumns[3].width, 'min-width': gridColumns[3].minWidth }">
                <div class="row w-100 justify-content-end">
                    <div class="d-inline-flex justify-content-start">
                        <fa-icon
                            *ngIf="canModify && !sourceItem.isFolder && !editorService.readonly"
                            class="fa font-14px icons"
                            ngbTooltip="Edit"
                            placement="top"
                            tooltipClass="files-gird-icon-tooltip"
                            container="body"
                            icon="facEdit"
                            [openDelay]="800"
                            (click)="editSource(sourceItem)"
                        ></fa-icon>
                        <fa-icon
                            *ngIf="(!canModify || editorService.readonly) && !sourceItem.isFolder"
                            class="fa font-14px icons"
                            ngbTooltip="View"
                            placement="top"
                            tooltipClass="files-gird-icon-tooltip"
                            container="body"
                            icon="facEyeOpen"
                            [openDelay]="800"
                            (click)="editSource(sourceItem)"
                        ></fa-icon>
                    </div>
                    <div *ngIf="canModify && !editorService.readonly" class="ml-20px d-inline-flex justify-content-start">
                        <fa-icon
                            class="fa font-14px icons"
                            ngbTooltip="Remove"
                            placement="top"
                            tooltipClass="files-gird-icon-tooltip"
                            container="body"
                            icon="facTrash"
                            [openDelay]="800"
                            (click)="removeSource(sourceItem)"
                        ></fa-icon>
                    </div>
                    <div class="ml-20px d-inline-flex justify-content-start">
                        <fa-icon
                            class="fa font-14px mr-10px icons"
                            ngbTooltip="Download"
                            placement="top"
                            tooltipClass="files-gird-icon-tooltip"
                            container="body"
                            icon="facDownload"
                            [openDelay]="800"
                            (click)="download(sourceItem)"
                        ></fa-icon>
                    </div>
                </div>
            </td>
        </ng-template>
    </lib-data-grid>
</div>

<ng-template #widgetTemplate let-widgetData="widgetData">
    <div class="d-flex flex-column align-items-center w-100 p-20px">
        <div *ngIf="projectComponentsFacade.actionCompleted === false">
            <div class="mb-7px">{{ widgetData.label }}</div>
            <span
                *ngIf="widgetData.progress !== 100"
                class="d-block w-100 status-indicator rounded bg-light-gray"
                title="{{ widgetData.progress }}%"
            >
                <span [style.width.%]="widgetData.progress" class="status-bar d-block building"></span>
            </span>
            <span
                *ngIf="widgetData.progress === 100"
                class="d-block w-100 status-indicator rounded bg-light-gray"
                title="{{ widgetData.progress }}%"
            >
                <span [style.width.%]="100" class="status-bar d-block"></span>
            </span>
        </div>
        <div
            (click)="showPublishSourceCodeLogs(projectComponentsFacade.log$, this.projectComponentsFacade.sourceCodeGenerationWidget)"
            *ngIf="projectComponentsFacade.actionCompleted === true"
            class="d-flex"
        >
            <div class="color-brand cursor-pointer mr-15px">Show last execution log</div>
            <fa-icon class="cursor-pointer" icon="facExternalLink"></fa-icon>
        </div>
    </div>
</ng-template>

<ng-template #titleTemplate let-title="title">
    <div class="d-inline-flex align-items-center h-100">
        <h6 class="modal-title">{{ title }}</h6>
        <span class="ml-30px secondary-text-and-icons">Started at :</span
        >{{ projectComponentsFacade.processStartedTime | date: 'dateTimeCustom' }}
    </div>
</ng-template>

<ng-template #logContentTemplate let-logsVm="logsVm">
    <ng-container *ngFor="let item of logsVm.value">
        <div class="d-flex flex-inline w-100">
            <span style="font-weight: 600; margin-right: 20px">{{ item.moment | date: 'HH:mm:ss' }}</span>
            <span style="font-weight: 300; margin-right: 20px; white-space: nowrap;">{{ item.duration | date: 'm:ss:SSS' }}</span>
            <div class="flex-1">{{ item.text }}</div>
        </div>
    </ng-container>
</ng-template>
