import { Component, ElementRef, ViewChild, OnInit, Renderer2, OnDestroy, Inject } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HealthService } from '@app/shared/services/admin/health-service';
import { NavigationEnd, Router } from '@angular/router';
import { ENV_TOKEN } from '@app/tokens';

@Component({
    selector: 'app-calibrate',
    templateUrl: './calibrate.component.html',
    styleUrls: ['./calibrate.component.scss'],
})
export class CalibrateComponent implements OnInit, OnDestroy {
    @ViewChild('iframe', { static: false }) iframe: ElementRef;
    public loaded: boolean;
    public url: string;
    public showCalibrateError: boolean;
    public onMessageEventListner: Function;
    public onReplaceUrl: boolean;
    public currentUrl = window.location.href;

    private stylesheet = document.styleSheets?.length ? document.styleSheets[0] : null;
    private headerLayoverClassName = 'header-layover';
    public navigationSubscription: Subscription;
    private headerTag: HTMLCollectionOf<Element>;

    constructor(@Inject(ENV_TOKEN) private env: Env, private healthService: HealthService, private renderer: Renderer2, private router: Router) {
        this.navigationSubscription = this.router.events.subscribe((route: any) => {
            if (route instanceof NavigationEnd) {
                if (route.url.includes('/calibrate/product-tour') && this.iframe) {
                    this.iframe.nativeElement.contentWindow.postMessage({ type: 'showProductTour'}, '*');
                }
            }
        });
        this.onMessageEventListner = this.renderer.listen(window, 'message', event => {
            if (event.data && event.data.type === 'originInfoRequest') {
                if (this.iframe && this.iframe.nativeElement) {
                    this.iframe.nativeElement.contentWindow.postMessage({ type: 'originInfoResponse', url: window.location.origin }, '*');
                }
            }
            if (event.data && event.data.type === 'replaceBrowserUrl') {
                if (this.iframe && this.iframe.nativeElement && this.currentUrl.indexOf('#/calibrate') > -1) {
                    this.router.navigateByUrl('/calibrate');
                }
            }
            if (event.data && event.data.type === 'productTourStarted') {
                this.headerTag = document.getElementsByTagName('app-header');
                this.headerTag[0].classList.add(this.createCustomClass(this.headerLayoverClassName, 51));
            }
            if (event.data && event.data.type === 'productTourStopped') {
                this.router.navigate(['/product-tour']);
                this.headerTag[0].classList.remove(this.headerLayoverClassName);
            }
        });
    }

    ngOnInit(): void {
        this.checkCalibrateAvailability().subscribe(val => {
            if (val !== null) {
                if (val) {
                    this.showCalibrateError = false;
                    let substring = '';
                    if (this.currentUrl && this.currentUrl.length > 0 && this.currentUrl.indexOf('#/calibrate') > -1) {
                        substring = this.currentUrl.substring(this.currentUrl.indexOf('#/calibrate'), this.currentUrl.length);
                        substring = substring.replace('#/', '');
                    }
                    let urlToRender = '';
                    if (substring && substring !== '') {
                        urlToRender = this.env.calibrate?.url + substring;
                    } else {
                        urlToRender = this.env.calibrate?.url;
                    }
                    this.url = urlToRender;
                } else {
                    this.showCalibrateError = true;
                }
            }
        });
    }
    checkCalibrateAvailability(): Observable<boolean> {
        return this.healthService.getIsServiceAlive('calibrate').pipe(
            map(alive => {
                return alive;
            }),
            catchError(() => {
                return of(false);
            })
        );
    }

    private createCustomClass(className: string, height: number): string {
        const rules: CSSRuleList =
            this.stylesheet?.cssRules?.length > 0 || this.stylesheet?.rules?.length === 0
                ? this.stylesheet?.cssRules : this.stylesheet?.rules;
        const rule: CSSStyleRule =
            Array.from(rules)
                .find(r => r instanceof CSSStyleRule && r.selectorText?.toLowerCase() === '.' + className?.toLowerCase()) as CSSStyleRule;

        if (!rule) {
            this.stylesheet?.
                insertRule(`.${className}::before { content: ''; height: ${height}px; width: 100%; top: 0;position: fixed; background: rgba(0, 0, 0, 0.5); left: 0; z-index: 1039}`, 0);
        }
        return className;
    }

    ngOnDestroy(): void {
        if (this.onMessageEventListner) {
            this.onMessageEventListner();
        }
        if (this.navigationSubscription) {
            this.navigationSubscription.unsubscribe();
        }
    }
}

