import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { QueryBuilderStore } from '@app/shared/components/query-builder/store/query-builder.store';
import { ModelGraphRemoveModelCommand } from '@app/shared/components/query-builder/models/model-graph-actions.model';
import { ModelGraphActionsService } from '@app/shared/components/query-builder/services/model-graph-actions.service';
import { DropdownItem } from '@dagility-ui/kit';
import { IRelation, Model } from '@app/shared/components/query-builder/models/model-graph.model';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

const joinTypes: Record<string, string> = {
    INNER: 'Inner Join',
    LEFT: 'Left Join',
    RIGHT: 'Right Join',
    FULL: 'Full Outer Join',
    CROSS: 'Cross Join',
};

@Component({
    selector: 'app-qb-model-joins-table',
    templateUrl: './joins-table.component.html',
    styleUrls: ['./joins-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JoinsTableComponent implements OnInit {
    @ViewChild('svgContainer') svgContainer: ElementRef;
    @Input() isEditorForm: boolean;
    @Output() openRelationEditor = new EventEmitter<IRelation>();
    @Output() closeRelationEditor = new EventEmitter<any>();

    editorPosition: { left: number; top: number } = { left: 0, top: 0 };
    joinTypeItems: DropdownItem[] = Object.keys(joinTypes).map(key => ({ value: key, label: key }));
    references: IRelation[];

    @ViewChild('scrollBarRef') scrollBarRef: PerfectScrollbarComponent;

    constructor(
        private elRef: ElementRef,
        private queryBuilderStore: QueryBuilderStore,
        private cdr: ChangeDetectorRef,
        private modelGraphActionsService: ModelGraphActionsService
    ) {}

    ngOnInit() {
        this.getReferences();
    }

    ngOnDestroy() {
        this.closeEditorForm();
    }

    openEditorForm(relation?: IRelation) {
        this.openRelationEditor.emit(relation);
    }

    closeEditorForm() {
        this.closeRelationEditor.emit();
    }

    isRelAddable(): boolean {
        return this.queryBuilderStore.modelGraph.models.length > 0;
    }

    @HostListener('document:queryBuilderChanged', ['$event'])
    getReferences = () => {
        this.closeEditorForm();
        const modelGraph = this.queryBuilderStore.modelGraph;
        if (modelGraph) {
            this.references = modelGraph.references?.map(reference => ({
                leftModelField: reference.joinCondition.leftModelField,
                rightModelField: reference.joinCondition.rightModelField,
                leftModel: modelGraph.models.find(model => model.uid === reference.parentModelUid),
                rightModel: modelGraph.models.find(model => model.uid === reference.referencedModelUid),
                joinType: reference.joinType,
            }));
            this.cdr.detectChanges();
        }
    };

    getJoinType = (joinType: string): string => joinTypes[joinType];

    detachModel = (model: Model) => {
        const modelGraph = this.queryBuilderStore.modelGraph;
        const deleteData: ModelGraphRemoveModelCommand = {
            referencedModelUid: modelGraph.models.find(m => m.uid === model.uid).uid,
            sourceGraph: this.queryBuilderStore.globalModelGraph,
        };
        this.modelGraphActionsService.removeReference(deleteData).subscribe(mutationResult => {
            this.queryBuilderStore.updateState(mutationResult);
            this.queryBuilderStore.$forcedEditorViewUpdate.next('');
        });
    };

    getPath(index: any) {
        const lineHeight = 16;
        const relationBlockHeight = 64;
        const x1 = 1;
        const x2 = 20;
        const x3 = 30;
        const y1 = 0;
        const y2 = lineHeight + lineHeight / 2 + index * relationBlockHeight;
        const y3 = y2 + relationBlockHeight / 2;
        const offset = 6;

        const pathToJoinFields = `M ${x2} ${y2 + 5} L ${x2} ${y3 - offset} Q ${x2} ${y3} ${x2 + offset} ${y3} L ${x3} ${y3}`;

        return (
            (this.references.length > 1 && index !== this.references.length - 1
                ? `M ${x1} ${y2} L ${x2 - 5} ${y2}`
                : `M ${x1} ${y1}  L ${x1} ${y2 - offset} Q ${x1} ${y2} ${x1 + offset} ${y2} L ${x2 - 5} ${y2}`) + pathToJoinFields
        );
    }
}
