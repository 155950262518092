import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DropdownOptionItem } from '../../../../../widget-builder/services/widget-builder.service';

@Component({
    selector: 'dp-preferred-item-icon',
    templateUrl: './preferred-item-icon.component.html',
    styleUrls: ['./preferred-item-icon.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'd-flex',
    },
})
export class PreferredItemIconComponent {
    @Input() item: DropdownOptionItem;
}
