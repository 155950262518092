import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiKitModule } from '@dagility-ui/kit';

import { WidgetLibraryModule } from '../../../../widget-library.module';
import { ItemsWithMoreComponent, ExpandButtonTemplateDirective } from '../items-with-more/items-with-more.component';
import { GlobalFilterComponent } from './global-filter/global-filter.component';
import { FilterValuePreviewComponent } from './filter-value-preview/filter-value-preview.component';
import { DropdownFilterComponent } from './dropdown-filter/dropdown-filter.component';
import { PreferredItemIconComponent } from './preferred-item-icon/preferred-item-icon.component';
import { MultiselectDropdownFilterComponent } from './multiselect-dropdown-filter/multiselect-dropdown-filter.component';
import { TriggerFilterComponent } from './trigger-filter/trigger-filter.component';

@NgModule({
    declarations: [
        GlobalFilterComponent,
        FilterValuePreviewComponent,
        DropdownFilterComponent,
        PreferredItemIconComponent,
        MultiselectDropdownFilterComponent,
    ],
    exports: [GlobalFilterComponent],
    imports: [
        CommonModule,
        UiKitModule,
        WidgetLibraryModule,
        ItemsWithMoreComponent,
        ExpandButtonTemplateDirective,
        TriggerFilterComponent,
    ],
})
export class GlobalFiltersModule {}
