import { ModelGraphActionsService } from '@app/shared/components/query-builder/services/model-graph-actions.service';
import { QueryBuilderStore } from '@app/shared/components/query-builder/store/query-builder.store';
import { IRelation, Model } from '@app/shared/components/query-builder/models/model-graph.model';
import { TreeNode } from '@app/shared/components/query-builder/qb-model/components/graph-tree/node/tree-node.model';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate, Warning } from '@dagility-ui/kit';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-widget-model',
    templateUrl: './qb-model.component.html',
    styleUrls: ['./qb-model.component.scss'],
    host: {
        class: 'widget-builder',
    },
})
export class QBModelComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    actionsMenuVisible = true;
    modelTreeVisible: boolean = true;

    hideTable() {
        this.actionsMenuVisible = !this.actionsMenuVisible;
    }

    get modelGraph() {
        return this.queryBuilderStore.modelGraph;
    }

    compatibleOnlyMode: boolean;

    isEditorForm: boolean;
    relation: IRelation;

    rootTreeNode: TreeNode;
    searchTreeStr: string;

    @Input() rootModels: Model[];
    treeModels: TreeNode[];
    @Output() addRootModel = new EventEmitter<Model>();
    @Output() deleteCustomModel = new EventEmitter<string>();

    constructor(private modelGraphActionsService: ModelGraphActionsService, private queryBuilderStore: QueryBuilderStore) {}

    ngOnInit(): void {
        this.compatibleOnlyMode = true;
        this.closeEditorForm();
        this.getTreeNodes();
    }

    ngOnChanges(): void {
        this.closeEditorForm();
        this.getTreeNodes();
    }

    openEditorForm(relation: IRelation) {
        this.relation = relation;
        this.isEditorForm = true;
    }

    closeEditorForm() {
        this.isEditorForm = false;
        this.relation = null;
    }

    private getTreeNodes() {
        const modelUids: string[] = this.queryBuilderStore.modelGraph?.models?.map(m => m.uid);
        this.treeModels = [];

        this.rootModels.forEach(model => {
            this.treeModels.push({
                data: model,
                toString(): string {
                    return this.data.name;
                },
                next: undefined,
                settings: {
                    userDefined: model.userDefined,
                    draggable: !modelUids?.includes(model.uid) || true,
                    disabled: modelUids?.includes(model.uid) || false,
                    type: 'MODEL',
                },
            });
        });
        this.buildTreeNode();
    }

    private buildTreeNode() {
        this.rootTreeNode = {
            data: 'Tools',
            toString(): string {
                return this.data;
            },
            next: [],
        };
        this.treeModels
            .filter(
                (model, i, models) =>
                    models
                        .map(m =>
                            m.data.userDefined || (!m.data.data.dbToolNameHint && !m.data.userDefined) ? undefined : m.data.data.dbToolId
                        )
                        .indexOf(
                            model.data.userDefined || (!model.data.data.dbToolNameHint && !model.data.userDefined)
                                ? undefined
                                : model.data.data.dbToolId
                        ) === i
            )
            .forEach(tool => {
                if (tool.data.userDefined || (!tool.data.data.dbToolNameHint && !tool.data.userDefined)) {
                    this.rootTreeNode.next.push({
                        data: 'Custom',
                        toString(): string {
                            return this.data;
                        },
                        next: [],
                        settings: {
                            type: 'ROOT',
                        },
                    });
                    this.treeModels
                        .filter(model => !model.data.data.dbToolId || model.data.userDefined)
                        .forEach(model => this.rootTreeNode.next.find(node => node.data === 'Custom').next.push(model));
                } else {
                    this.rootTreeNode.next.push({
                        data: tool.data.data.dbToolNameHint,
                        toString(): string {
                            return this.data;
                        },
                        next: [],
                        settings: {
                            collapsed: false,
                            type: 'TOOL',
                        },
                    });
                    this.treeModels
                        .filter(model => !model.data.userDefined && model.data.data.dbToolId === tool.data.data.dbToolId)
                        .filter((model, i, arr) => arr.map(m => m.data.data.schemaName).indexOf(model.data.data.schemaName) === i)
                        .map(model => model.data.data.schemaName)
                        .forEach(schema => {
                            this.rootTreeNode.next
                                .find(node => node.data === tool.data.data.dbToolNameHint)
                                .next.push({
                                    data: schema,
                                    toString(): string {
                                        return this.data;
                                    },
                                    next: [],
                                    settings: {
                                        collapsed: true,
                                        type: 'SCHEMA',
                                    },
                                });
                            this.treeModels
                                .filter(
                                    model =>
                                        !model.data.userDefined &&
                                        model.data.data.dbToolId === tool.data.data.dbToolId &&
                                        model.data.data.schemaName === schema
                                )
                                .forEach(model => {
                                    const toolNode = this.rootTreeNode.next.find(node => node.data === tool.data.data.dbToolNameHint);
                                    const schemaNode = toolNode.next.find(node => node.data === schema);
                                    schemaNode.next.push(model);
                                    toolNode.childModelCount = (toolNode.childModelCount || 0) + 1;
                                    schemaNode.childModelCount = (schemaNode.childModelCount || 0) + 1;
                                });
                        });
                }
            });
    }

    searchByTreeView(str: string) {
        this.searchTreeStr = str;
    }

    canDeactivate(): boolean | Observable<boolean> {
        return true;
    }

    getWarning(): Warning {
        return undefined;
    }
}
