import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { UiKitModule } from '@dagility-ui/kit';

import { BaseWidgetFilter } from '../../../../../widget-builder/components/widget/filters/base-widget-filter.component';

@Component({
    standalone: true,
    imports: [CommonModule, UiKitModule, ReactiveFormsModule, NgbTooltipModule],
    template: `
        <label class="filter-item d-flex" for="global-filter-{{ filter.placeholder }}" (click)="formControl.patchValue(!formControl.value)">
            <span [ngbTooltip]="filter.label" libTooltipWhenOverflow triggers="manual" class="h3 my-0 text-truncate">{{
                filter.label
            }}</span>

            <trigger
                id="global-filter-{{ filter.placeholder }}"
                class="ml-auto"
                [formControl]="formControl"
                (click)="$event.preventDefault(); $event.stopImmediatePropagation()"
            ></trigger>
        </label>
    `,
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'pt-0 mr-0',
    },
})
export class TriggerFilterComponent extends BaseWidgetFilter {}
