<dp-edit-job-definition-breadcrumb class="mb-25px d-block" [path]="expandedControl.path$ | async"> </dp-edit-job-definition-breadcrumb>
<div class="items-container overflow-auto" [formGroup]="formSlice">
    <perfect-scrollbar>
        <div *ngFor="let field of fieldTypes | keyvalue" class="item-container mb-20px px-1">
            <div *ngIf="field.value.type == 'string'" class="row no-gutters d-inline-flex flex-nowrap w-100">
                <lib-input
                        [label]="field.value.name"
                        id="name"
                        [formControlName]="field.key"
                        class="w-100"
                        [warningMessage]="
                        expandedControl.formJD.typingErrors[field.key]
                            ? warnings.typingError(expandedControl.formJD.typingErrors[field.key])
                            : null
                    "
                        [setDisable]="field.key === 'instanceName' && !newJob"
                >
                    <ng-container *ngIf="contextHelpAnchors[field.value.name] && templateProvider?.contextHelpTemplate">
                        <ng-template *ngTemplateOutlet="templateProvider?.contextHelpTemplate; context: { contextHelpId: contextHelpAnchors[field.value.name] }"></ng-template>
                    </ng-container>
                </lib-input>
            </div>
            <div *ngIf="field.value.type == 'number'" class="row no-gutters d-inline-flex flex-nowrap w-100">
                <lib-input
                        class="w-100"
                        [label]="field.value.name"
                        id="number"
                        [formControlName]="field.key"
                        [type]="'number'"
                        (change)="handleNumericChange($event, field.key)"
                        [warningMessage]="
                        expandedControl.formJD.typingErrors[field.key]
                            ? warnings.typingError(expandedControl.formJD.typingErrors[field.key])
                            : null
                    "
                >
                    <ng-container *ngIf="contextHelpAnchors[field.value.name] && templateProvider?.contextHelpTemplate">
                        <ng-template *ngTemplateOutlet="templateProvider?.contextHelpTemplate; context: { contextHelpId: contextHelpAnchors[field.value.name] }"></ng-template>
                    </ng-container>
                </lib-input>
            </div>
            <div *ngIf="field.value.type == 'enum'" class="row no-gutters d-inline-flex flex-nowrap w-100">
                <lib-dropdown
                        class="dropdown w-100"
                        [label]="field.value.name"
                        [items]="field.value.items"
                        placeholder="Select"
                        valueKey="name"
                        labelKey="name"
                        id="name"
                        [formControlName]="field.key"
                        [warningMessage]="
                        expandedControl.formJD.typingErrors[field.key]
                            ? warnings.typingError(expandedControl.formJD.typingErrors[field.key])
                            : null
                    "
                >
                    <ng-container *ngIf="contextHelpAnchors[field.value.name] && templateProvider?.contextHelpTemplate">
                        <ng-template *ngTemplateOutlet="templateProvider?.contextHelpTemplate; context: { contextHelpId: contextHelpAnchors[field.value.name] }"></ng-template>
                    </ng-container>
                </lib-dropdown>
            </div>
            <div *ngIf="field.value.type == 'boolean'" class="row no-gutters d-inline-flex flex-nowrap w-100">
                <trigger
                        [formControlName]="field.key"
                        class="col-10"
                        [warningMessage]="
                        expandedControl.formJD.typingErrors[field.key]
                            ? warnings.typingError(expandedControl.formJD.typingErrors[field.key])
                            : null
                    "
                >{{ field.value.name }}</trigger
                >
            </div>
        </div>
    </perfect-scrollbar>
</div>
