<div class="modal-main-container editor">
    <div class="modal-header justify-content-between align-items-center">
        <h2 class="m-0">Relation editor</h2>
        <span style="cursor: pointer" (click)="closeForm()">✕</span>
    </div>
    <form class="modal-body" [formGroup]="editorForm">
        <div class="editor-model">
            <h3 class="m-0">Parent model selection</h3>
            <div class="editor-block">
                <lib-dropdown
                    class="mr-20px"
                    [width]="400"
                    [clearable]="false"
                    [items]="parentModelItems"
                    [setDisable]="isEditorMode"
                    valueKey="uid"
                    labelKey="name"
                    label="Parent Model"
                    placeholder="Select Parent Model"
                    formControlName="pm"
                    (change)="onChangeForm($event, 'leftModel')"
                >
                </lib-dropdown>
                <lib-dropdown
                    [width]="400"
                    [(ngModel)]="selectedParentField"
                    [clearable]="false"
                    [items]="parentFieldItems"
                    [setDisable]="!selectedParentModel"
                    label="Parent Model field"
                    placeholder="Select Parent Model field"
                    formControlName="pmf"
                    (change)="onChangeForm($event, 'leftModelField')"
                >
                </lib-dropdown>
            </div>
        </div>
        <div class="editor-model">
            <h3>Relation type</h3>
            <div class="editor-block">
                <lib-radio-button
                    *ngFor="let j of joinTypeItems"
                    class="size-s mr-20px"
                    name="joinTypeSelector"
                    [label]="getJoinType(j.label)"
                    [value]="j.value"
                    [checked]="j.value === selectedJoinType"
                    (click)="selectedJoinType = j.value"
                >
                </lib-radio-button>
            </div>
        </div>
        <div class="editor-model">
            <h3>Child model selection</h3>
            <div class="editor-block">
                <lib-dropdown
                    class="mr-20px"
                    [width]="400"
                    [multiple]="true"
                    [(ngModel)]="selectedTools"
                    [items]="toolItems"
                    [setDisable]="isEditorMode"
                    placeholder="Select JDBC Tools filter"
                    label="JDBC Tools filter"
                    [clearable]="true"
                    formControlName="tools"
                    (change)="filterChildModelsList('TOOL')"
                >
                </lib-dropdown>
                <lib-dropdown
                    [width]="400"
                    [multiple]="true"
                    [(ngModel)]="selectedSchemas"
                    [items]="schemaItems"
                    [setDisable]="isEditorMode || (selectedTools?.length === 1 && selectedTools[0].value === undefined || selectedTools?.length === 0)"
                    placeholder="Select Schemas filter"
                    label="Schemas filter"
                    [clearable]="true"
                    formControlName="schemas"
                    (change)="filterChildModelsList('SCHEMA')"
                >
                </lib-dropdown>
            </div>
            <div class="editor-block">
                <lib-dropdown
                    class="mr-20px"
                    [width]="400"
                    [clearable]="false"
                    [items]="childModelItems"
                    [labelTemplateRef]="childModelLabelOptionTmp"
                    [optionTemplateRef]="childModelLabelOptionTmp"
                    [setDisable]="isEditorMode"
                    valueKey="uid"
                    labelKey="name"
                    label="Child Model"
                    placeholder="Select Child Model"
                    formControlName="cm"
                    (change)="onChangeForm($event, 'rightModel')"
                >
                    <ng-template #childModelLabelOptionTmp let-item="item" let-labelKey="labelKey">
                        <span>
                            {{ item[labelKey] }}
                        </span>
                    </ng-template>
                </lib-dropdown>
                <lib-dropdown
                    [width]="400"
                    [(ngModel)]="selectedChildField"
                    [clearable]="false"
                    [items]="childFieldItems"
                    [setDisable]="!selectedChildModel"
                    label="Child Model field"
                    placeholder="Select Child Model field"
                    formControlName="cmf"
                    (change)="onChangeForm($event, 'rightModelField')"
                >
                </lib-dropdown>
            </div>
        </div>
    </form>
    <div class="modal-footer justify-content-end align-items-center">
        <button type="button" class="btn btn-secondary mr-20px" (click)="closeForm()">
            Cancel
        </button>
        <button type="button" class="btn btn-primary" (click)="handleSave()">
            Save
        </button>
    </div>
</div>
