import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, FormArray, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { Subject } from 'rxjs';
import { share, take, takeUntil } from 'rxjs/operators';

import { DATA_MORPH_FEATURE, DATA_MORPH_FEATURE_TOGGLE, DataMorphFeatureToggleService } from 'data-processor/tokens';
import { DropdownItem } from '@dagility-ui/kit';
import { WidgetBuilderTagsService } from 'data-processor/lib/widget-library/widget-builder/services/widget-builder-tags.service';

import { WidgetBuilderStore } from '../../../services/widget-builder.store';
import { WidgetBuilderFacade } from '../../../state/widget-builder.facade';
import { AnyWidgetModel, DisplayType, WidgetType } from '../../../models/any-widget.model';
import { BlockForm } from '../widget-builder-block-form/widget-builder-block-form.component';
import { hasAdditionalTemplates, isWidgetWithColors, isWidgetWithSeries } from '../../../services/widget-builder.util';
import { GLOBAL_FILTERS_EDITOR } from '../../../providers/global-filters-editor.token';
import { DisplaySettingsConfig } from 'data-processor/lib/widget-library/widget-builder/models/display-settings.config';

@Component({
    selector: 'dp-widget-builder-form',
    templateUrl: './widget-builder-form.component.html',
    styleUrls: ['./widget-builder-form.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
    providers: [{ provide: BlockForm, useExisting: WidgetBuilderFormComponent }, WidgetBuilderTagsService],
})
export class WidgetBuilderFormComponent extends BlockForm implements OnInit, OnDestroy {
    @Input() set data(block: any) {
        this.block = block;
        this.isRoot = block.data.id === this.store.value.root;
        this.basicChartOptionControlsAvailable =
            this.checkHasParent(block.id) || this.isRoot || this.getDisplaySettingsConfig.showBasicChartOptions;
        this.form = this.facade.buildForm(block.data, {
            basicChartOptionsControlsAvailable: !this.basicChartOptionControlsAvailable,
            isRoot: this.isRoot,
        });
        this.showComplexNamedDropdown = this.form.get('complexNamedDropdown').value;

        this.hideDescription$.pipe(take(1), takeUntil(this.destroyed$)).subscribe(hideDescription => {
            if (hideDescription) {
                (this.form.get('chartOptions.description') as FormControl).disable();
            }
        });
    }

    block: any;

    type = WidgetType;

    isRoot = false;

    basicChartOptionControlsAvailable = true;

    showComplexNamedDropdown = false;

    destroyed$ = new Subject<void>();

    ftTags: DropdownItem[] = [];

    ftTagsActive = false;

    hideComplexNamedDropdownFilterDependency = true;

    get getDisplaySettingsConfig() {
        return this.block.displaySettingsConfig.enabled ? this.block.displaySettingsConfig : new DisplaySettingsConfig();
    }

    get widgetType(): WidgetType {
        return this.form.get('common').value.type;
    }

    get ftTagsControl(): FormControl {
        return this.form.get('ftTags') as FormControl;
    }

    displayType: DropdownItem<DisplayType>[] = [
        {
            value: DisplayType.MODAL,
            label: 'Modal',
        },
        {
            value: DisplayType.STACK,
            label: 'Stack',
        },
    ];

    hideDescription$ = this.featureToggle.isActive(DATA_MORPH_FEATURE.HIDE_DESCRIPTION).pipe(share());

    constructor(
        public facade: WidgetBuilderFacade,
        private store: WidgetBuilderStore,
        @Inject(DATA_MORPH_FEATURE_TOGGLE) private featureToggle: DataMorphFeatureToggleService,
        @Inject(GLOBAL_FILTERS_EDITOR) public globalFiltersMode: boolean
    ) {
        super();
        this.featureToggle.isActive(DATA_MORPH_FEATURE.TAGS_VISIBILITY).subscribe(visibility => (this.ftTagsActive = visibility));
    }

    ngOnInit(): void {
        this.ftTagsControl.patchValue(this.ftTagsControl.value.map((value: string) => ({ label: value, value: value })));
        this.ftTags = this.ftTagsControl.value;
    }

    checkHasParent(blockId: string): boolean {
        return this.store.value.actions.some(({ to }) => to === blockId);
    }

    addFtTagFn = (value: string) => {
        return {
            label: value,
            value,
        };
    };

    handleFtTagsChange(data: string[]) {
        this.ftTagsControl.patchValue(data);
        this.ftTags = data.map((value: string) => ({ label: value, value: value }));
    }

    handleTypeChange(item: DropdownItem<WidgetType> | null): void {
        this.hideDescription$.pipe(takeUntil(this.destroyed$)).subscribe(hideDescription => {
            this.form.setControl('chartOptions', this.facade.buildChartOptionsForm(this.form.value.chartOptions, item ? item.value : null));

            if (hideDescription) {
                this.form.get('chartOptions.description').disable();
            }

            if (!this.basicChartOptionControlsAvailable) {
                const chartOptions = this.form.get('chartOptions') as FormGroup;
                setTimeout(() => {
                    chartOptions.removeControl('title');
                    chartOptions.removeControl('description');
                });
            }

            if (!item || !isWidgetWithSeries(item.value)) {
                (this.form.get('series') as FormArray).clear();
            } else {
                this.form.setControl('series', this.facade.buildWidgetSeriesGroup(this.form.get('series').value || [], item.value));
            }

            if (!item || WidgetType.ACCORDION_WITH_TABS !== item.value) {
                (this.form.get('accordionColumns') as FormArray).clear();
            }

            if (!item || WidgetType.TABLE !== item.value) {
                (this.form.get('columns') as FormArray).clear();
            }

            if (!item || !isWidgetWithColors(item.value)) {
                (this.form.get('colors') as FormArray).clear();
            }

            if (!item || WidgetType.TILE_CHART !== item.value) {
                (this.form.get('tiles') as FormArray).clear();
            }

            if (!hasAdditionalTemplates(item.value)) {
                (this.form.get('additionalTemplates' as keyof AnyWidgetModel) as FormArray).clear();
            }
        });
    }

    handleComplexNamedDropdownCheckbox(value: boolean) {
        this.showComplexNamedDropdown = value;

        if (!value) {
            this.form.get('complexNamedDropdownLabel').reset('');
            this.form.get('complexNamedDropdownDependencies').reset('');
            this.form.get('complexNamedDropdownFilterDependency').reset('');
        }
    }

    toSave(): any {
        const { common, size, ...rest } = this.featureToggle.isActiveSync('query_library') ? this.form.getRawValue() : this.form.value;
        const inputPlaceholders = (this.form.value.inputPlaceholders ?? []).map((item: any) =>
            typeof item === 'string' ? item : item.value
        );

        const ftTags = (this.form.value.ftTags ?? []).map((item: any) => (typeof item === 'string' ? item : item.value));

        return {
            ...rest,
            ...common,
            inputPlaceholders,
            ftTags,
            size: this.globalFiltersMode ? null : size,
        };
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }
}
