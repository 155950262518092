<div class="d-flex flex-wrap px-1 align-items-end">
    <h2 class="mr-15px">Widget Library</h2>
    <button
        id="new-widget-button"
        class="ml-auto btn btn-link mr-3 size-s"
        routerLink="new"
        [state]="{ fromLink: router.url }"
        ngbTooltip="Add widget"
        container="body"
    >
        <fa-icon icon="plus"></fa-icon>
    </button>
    <button id="import-button" class="btn btn-link size-s" (click)="handleImport()" ngbTooltip="Import widget" container="body">
        <fa-icon icon="facDownload"></fa-icon>
    </button>
</div>

<lib-stream-wrapper class="flex-1 d-flex flex-column overflow-hidden px-1" [dataStream$]="data$">
    <ng-template contentTemplate>
        <lib-search class="py-4 d-block border-bottom" (searchText)="filter.patchValue($event)"></lib-search>
        <cdk-virtual-scroll-viewport class="widget-list" perfectScrollbar class="mt-3" style="width: 100%; height: 100%" itemSize="134">
            <dp-widget-list-item
                *cdkVirtualFor="let widget of widgets$ | async"
                class="mb-3"
                [class.selected-widget]="widget.id === activeWidgetId"
                [class.cloned-widget]="widget.id === clonedId"
                [widget]="widget"
                [hideDescription]="hideDescription$ | async"
                (selected)="widgetSelect.emit(widget)"
            >
                <ng-template let-widget>
                    <button
                        *ngIf="!widget.system"
                        class="btn btn-link"
                        [routerLink]="widget.id"
                        [state]="{ fromLink: router.url }"
                        ngbTooltip="Edit widget"
                        container="body"
                        (click)="$event.preventDefault(); $event.stopPropagation()"
                    >
                        <fa-icon icon="facEdit"></fa-icon>
                    </button>
                    <button
                        class="btn btn-link"
                        ngbTooltip="Clone widget"
                        container="body"
                        (click)="$event.preventDefault(); $event.stopPropagation(); handleCloneWidget(widget.id)"
                    >
                        <fa-icon icon="facClone"></fa-icon>
                    </button>
                    <button
                        *ngIf="!widget.system"
                        class="btn btn-link"
                        ngbTooltip="Delete widget"
                        container="body"
                        (click)="$event.preventDefault(); $event.stopPropagation(); deleteWidget.emit(widget)"
                    >
                        <fa-icon icon="facTrash"></fa-icon>
                    </button>
                </ng-template>
            </dp-widget-list-item>
        </cdk-virtual-scroll-viewport>
    </ng-template>
</lib-stream-wrapper>
