import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { catchError, distinctUntilChanged, filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { combineLatest, of, pipe, Subject, throwError } from 'rxjs';

import { ResizeObserverService } from '@dagility-ui/kit';

import { DpDashboardStore } from '../../state/dp-dashboard.store';
import { DashboardGroupState, DashboardTabState } from '../../state/dp-dashboard.state.model';
import { PerfectScrollbarEventPropagationDirective } from '../../directives/perfect-scrollbar-event-propagation.directive';

function logError() {
    return pipe(
        catchError(e => {
            console.error(e);

            return throwError(e);
        })
    );
}

@Directive({
    selector: '[dpGridsterAutoHeight]',
    providers: [ResizeObserverService],
})
export class GridsterHeightDirective implements OnInit, OnDestroy {
    @Input() tabId: number;
    @Input() groupId: number;

    private destroyed$ = new Subject<void>();

    constructor(
        private store: DpDashboardStore,
        private resizeObserver: ResizeObserverService,
        private elementRef: ElementRef<HTMLElement>,
        private container: PerfectScrollbarEventPropagationDirective
    ) {}

    ngOnInit() {
        combineLatest([
            this.groupIsNotEmpty().pipe(tap(isNotEmpty => this.elementRef.nativeElement.classList.toggle('gridster--empty', !isNotEmpty))),
            this.isLastGroupOnTab(this.tabId),
            this.store.select(state => state.edit),
        ])
            .pipe(
                switchMap(([groupIsNotEmpty, isLastGroupOnTab, editMode]) => {
                    if (groupIsNotEmpty || !editMode) {
                        return of('unset');
                    }

                    if (isLastGroupOnTab) {
                        return this.observeContainerHeight();
                    }

                    return of('154px');
                }),
                distinctUntilChanged(),
                takeUntil(this.destroyed$)
            )
            .subscribe(height => {
                this.elementRef.nativeElement.style.minHeight = height;
            });
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }

    // TODO: duplicated groupIsEmpty
    private groupIsNotEmpty() {
        return this.store
            .select(state => state.groupsMap[this.groupId])
            .pipe(
                startWith(this.store.selectSync(state => state.groupsMap[this.groupId])),
                filter<DashboardGroupState>(Boolean),
                map(group => !!group.widgets?.length),
                logError(),
                distinctUntilChanged()
            );
    }

    private observeContainerHeight() {
        const card = this.elementRef.nativeElement.closest<HTMLElement>('.card');
        const cardHeader = card.querySelector<HTMLElement>('.card-header');

        return combineLatest([this.observeHeight(this.container.elementRef.nativeElement), this.observeHeight(cardHeader)]).pipe(
            map(([containerHeight, headerHeight]) => containerHeight - headerHeight - 3),
            filter(sign => sign > 0),
            map(height => `calc(${height}px - 0.25rem)`)
        );
    }

    private isLastGroupOnTab(tabId: number) {
        return this.store
            .select(state => state.tabsMap)
            .pipe(
                startWith(this.store.selectSync(state => state.tabsMap)),
                map(tabsMap => tabsMap[tabId]),
                filter<DashboardTabState>(Boolean),
                map(tab => tab.groups.length <= 1),
                logError(),
                distinctUntilChanged()
            );
    }

    private observeHeight(element: HTMLElement) {
        return this.resizeObserver.observe$(new ElementRef(element), 0).pipe(
            startWith(null as {}),
            map(() => element.offsetHeight)
        );
    }
}
