import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
    ComparePipelineScripts,
    ComponentWithPipelines,
    PipelineAdditionalInformation,
    PipelineDetails,
    PipelineScript,
    PipelineScriptVersion,
    PipelineScriptWithComment,
    ProjectComponent,
    TemplateGuidAndName,
} from './models/project-pipeline-items';
import { ExtendJob, Job, JobResponse, UnitItem } from '@app/shared/components/pipeline-list/model/jobs-management.classes';
import { PipelineBuildTrigger } from './project-pipeline/project-pipeline-settings/project-pipeline-build-triggers/project-pipeline-build-triggers.component';
import { isDefined, Pageable, toHttpParams } from '@dagility-ui/kit';
import { CiCdProperty } from '@dagility-ui/shared-components';
import { ENV_TOKEN } from '@app/tokens';
import { Page } from '@dagility-ui/kit/models/pagination/paging.models';

@Injectable()
export class ProjectPipelinesService {
    baseUrl = this.env.buildApiURL + '/ci-cd/projects/';
    adminUrl = this.env.adminApiURL + '/ci-cd/projects/';
    appSourcesBaseUrl = `${this.env.buildApiURL}/jobs-management`;

    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {}

    getProjectPipelines(projectId: number): Observable<ComponentWithPipelines[]> {
        return this.http.get<ComponentWithPipelines[]>(`${this.adminUrl}${projectId}/pipelines`);
    }

    getProjectPipelineAdditionalInformation(projectId: number): Observable<NumberMap<PipelineAdditionalInformation>> {
        return this.http.get<NumberMap<PipelineAdditionalInformation>>(`${this.baseUrl}${projectId}/pipelines`);
    }

    getComponents(projectId: number): Observable<ProjectComponent[]> {
        return this.http.get<ProjectComponent[]>(this.env.adminApiURL + '/v2/projects/' + projectId + '/components');
    }

    getTags(): Observable<any> {
        return this.http.get<any>(this.env.adminApiURL + '/ci-cd/tags');
    }

    getPipelineTemplateInfo(projectId: number, appLevel: boolean, preferredToolType: string): Observable<TemplateGuidAndName[]> {
        let params = new HttpParams().set('toolType', preferredToolType).set('appLevel', appLevel);

        return this.http.get<TemplateGuidAndName[]>(`${this.adminUrl}${projectId}/pipeline/templates`, { params });
    }

    getPipelineDetails(projectId: number, pipelineId: number): Observable<PipelineDetails> {
        return this.http.get<PipelineDetails>(`${this.adminUrl}${projectId}/pipelines/${pipelineId}/details`);
    }

    generatePipelineWebHookToken(projectId: number, pipelineId: number): Observable<string> {
        return this.http.get<string>(`${this.adminUrl}${projectId}/pipelines/${pipelineId}/generateWebHookToken`, {
            responseType: 'text' as 'json',
        });
    }

    getPipelineScript(projectId: number, pipelineId: number): Observable<PipelineScript> {
        return this.http.get<PipelineScript>(`${this.env.buildApiURL}/ci-cd/${projectId}/pipeline-script/${pipelineId}`);
    }

    getPipelineScriptVersions(projectId: number, pipelineId: number, pageable: Pageable): Observable<Page<PipelineScriptVersion>> {
        return this.http.get<Page<PipelineScriptVersion>>(
            `${this.env.buildApiURL}/ci-cd/${projectId}/pipeline-script/${pipelineId}/versions`,
            {
                params: pageable.create(),
            }
        );
    }

    comparePipelineScripts(scripts: ComparePipelineScripts) {
        return this.http.post<PipelineScript>(`${this.env.buildApiURL}/ci-cd/compare-pipeline-scripts`, scripts);
    }

    usePipelineScriptVersion(projectId: number, pipelineId: number, version: number) {
        return this.http.put(`${this.env.buildApiURL}/ci-cd/${projectId}/pipeline-script/${pipelineId}/version/${version}`, {});
    }

    getPipelineScriptVersion(projectId: number, pipelineId: number, version: string): Observable<string> {
        return this.http.get<string>(`${this.env.buildApiURL}/ci-cd/${projectId}/pipeline-script/${pipelineId}/version/${version}`);
    }

    deletePipelineScriptVersion(projectId: number, pipelineId: number, version: number): Observable<void> {
        return this.http.delete<void>(`${this.env.buildApiURL}/ci-cd/${projectId}/pipeline-script/${pipelineId}/version/${version}`);
    }

    isPipelineScriptSyncedWithScm(projectId: number, pipelineId: number): Observable<boolean> {
        return this.http.get<boolean>(`${this.env.buildApiURL}/ci-cd/${projectId}/pipeline-script/${pipelineId}/syncWithScm`);
    }

    savePipelineScript(projectId: number, pipelineId: number, script: PipelineScriptWithComment): Observable<PipelineScript> {
        return this.http.post<PipelineScript>(`${this.env.buildApiURL}/ci-cd/${projectId}/pipeline-script/${pipelineId}`, script);
    }

    syncAllPipelineScript(pipelineIds: number[]): Observable<void> {
        return this.http.post<void>(`${this.env.buildApiURL}/ci-cd/pipeline-script/sync`, pipelineIds);
    }

    syncPipelineScript(projectId: number, pipelineId: number): Observable<PipelineScript> {
        return this.http.get<PipelineScript>(`${this.env.buildApiURL}/ci-cd/${projectId}/pipeline-script/${pipelineId}/sync`);
    }

    getPipelineDependencies(projectId: number, pipelineId: number): Observable<string[]> {
        return this.http.get<string[]>(`${this.env.buildApiURL}/ci-cd/${projectId}/${pipelineId}/dependencies`);
    }

    addNewPipeline(projectId: number, pipelineDetails: PipelineDetails): Observable<number> {
        return this.http.post<number>(`${this.adminUrl}${projectId}/pipelines/new`, pipelineDetails);
    }

    deletePipeline(projectId: number, pipelineId: number): Observable<void> {
        return this.http.delete<void>(`${this.adminUrl}${projectId}/pipelines/${pipelineId}`);
    }

    savePipelineDetails(
        projectId: number,
        pipelineId: number,
        pipelineDetails: Partial<PipelineDetails>,
        retain?: boolean
    ): Observable<void> {
        const retainPart = isDefined(retain) ? `?retain=${retain}` : '';
        return this.http.post<void>(`${this.adminUrl}${projectId}/pipelines/${pipelineId}/details${retainPart}`, pipelineDetails);
    }

    getPipelineProperties(projectId: number, pipelineId: number): Observable<CiCdProperty[]> {
        return this.http.get<CiCdProperty[]>(`${this.adminUrl}${projectId}/pipelines/${pipelineId}/properties`);
    }

    copyPipeline(projectId: number, pipelineId: number): Observable<void> {
        return this.http.post<void>(`${this.adminUrl}${projectId}/pipelines/${pipelineId}/copy`, {});
    }

    updatePipelineProperties(projectId: number, pipelineId: number, properties: CiCdProperty[]): Observable<void> {
        return this.http.put<void>(`${this.adminUrl}${projectId}/pipelines/${pipelineId}/properties`, properties);
    }

    getRuntimeProperties(projectId: number, pipelineId: number): Observable<CiCdProperty[]> {
        return this.http.get<CiCdProperty[]>(`${this.adminUrl}${projectId}/pipelines/${pipelineId}/runtime-properties`);
    }

    startPipeline(job: Job, params: any): Observable<void> {
        const request = {
            job: job,
            params: params,
        };
        return this.http.post<void>(`${this.appSourcesBaseUrl}/run-job`, request);
    }

    stopPipeline(job: ExtendJob): Observable<void> {
        return this.http.get<void>(`${this.appSourcesBaseUrl}/stop-job`, {
            params: toHttpParams(job),
        });
    }

    getUpstreamJobs(projectId: number, pipelineId: number): Observable<UnitItem[]> {
        return this.http.get<UnitItem[]>(`${this.adminUrl}${projectId}/pipelines/${pipelineId}/upstream-jobs`);
    }

    setBuildTriggers(projectId: number, pipelineId: number, buildTriggers: PipelineBuildTrigger[]): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}${projectId}/pipelines/${pipelineId}/build-triggers`, buildTriggers);
    }

    getBuildTriggers(projectId: number, pipelineId: number): Observable<PipelineBuildTrigger[]> {
        return this.http.get<PipelineBuildTrigger[]>(`${this.baseUrl}${projectId}/pipelines/${pipelineId}/build-triggers`);
    }

    getBranches(projectId: number, pipelineId: number, search: string): Observable<string[]> {
        let params = new HttpParams();
        if (search) {
            params = params.set('search', search);
        }

        return this.http.get<string[]>(`${this.baseUrl}${projectId}/pipelines/${pipelineId}/branches`, {
            params,
        });
    }

    getJobsByProject(projectId: number): Observable<JobResponse[]> {
        return this.http.get<JobResponse[]>(`${this.appSourcesBaseUrl}/get-jobs-by-project/${projectId}`);
    }

    getJobsByProjectComponent(projectId: number, component: number): Observable<JobResponse[]> {
        return this.http.get<JobResponse[]>(`${this.appSourcesBaseUrl}/get-jobs-by-project/${projectId}/component/${component}`);
    }
}
