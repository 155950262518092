<dp-edit-job-definition-breadcrumb [path]="store.path$ | async"></dp-edit-job-definition-breadcrumb>

<perfect-scrollbar class="flex-1 mt-3" [formGroup]="control">
    <lib-dropdown
        *ngIf="tools$ | obsWithStatus | async as tools"
        style="width: 98%"
        class="px-1 d-block mb-3"
        formControlName="from"
        label="Data Source"
        [loading]="tools.loading"
        [items]="tools.value"
    ></lib-dropdown>
    <lib-input style="width: 98%" class="px-1 d-block mb-3" formControlName="variable" label="Variable"></lib-input>
    <lib-codemirror-editor
        class="flex-1 mt-25px codemirror-editor"
        label="Script"
        mode="text/x-groovy"
        formControlName="query"
    ></lib-codemirror-editor>
</perfect-scrollbar>
