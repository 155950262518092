import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { Placeholders } from './widget-builder.service';

@Injectable()
export class WidgetDebuggerState {
    dataSources: Record<string, Observable<any>> = {};
    filtersGroupMap: Record<string, FormGroup> = {};
    filterChanged$ = new Subject<string[]>();
    drilldown$ = new Subject();
    placeholders$: BehaviorSubject<any>;
    filters$: BehaviorSubject<FormGroup> = new BehaviorSubject<FormGroup>(null);
    placeholdersMap: Record<string, BehaviorSubject<Placeholders>> = {};
    filterVisibleState: Record<string, BehaviorSubject<boolean>> = {};

    setFilterVisibleState(placeholder: string, state: boolean) {
        if (!this.filterVisibleState[placeholder]) {
            this.filterVisibleState[placeholder] = new BehaviorSubject(state);
        } else {
            this.filterVisibleState[placeholder].next(state);
        }
    }
}
