<div class="range-slider">
    <span class="info min" #minOut>{{ minValue }}</span>
    <span class="info max" #maxOut>{{ maxValue }}</span>
    <input *ngIf="!list" class="original" type="range"
           name="{{ name }}" min="{{ min }}" max="{{ max }}" step="{{ step }}" [(ngModel)]="minValue"
           (click)="$event.stopPropagation(); submit()" (input)="$event.stopPropagation(); setLow()">
    <input *ngIf="!list" #ghost class="ghost" type="range"
           name="{{ name }}" min="{{ min }}" max="{{ max }}" step="{{ step }}" [(ngModel)]="maxValue"
           (click)="$event.stopPropagation(); submit()" (input)="$event.stopPropagation(); setHigh()">

    <input *ngIf="list" class="original" type="range"
           name="{{ name }}" min="{{ min }}" max="{{ max }}" step="{{ step }}" [(ngModel)]="minValue"
           [attr.list]="datalistName"
           (click)="$event.stopPropagation(); submit()" (input)="$event.stopPropagation(); setLow()">
    <input *ngIf="list" #ghost class="ghost" type="range"
           name="{{ name }}" min="{{ min }}" max="{{ max }}" step="{{ step }}" [(ngModel)]="maxValue"
           [attr.list]="datalistName"
           (click)="$event.stopPropagation(); submit()" (input)="$event.stopPropagation(); setHigh()">
    <datalist *ngIf="list" [id]="datalistName">
        <option *ngFor="let value of list" value="{{ value }}" [label]="value"></option>
    </datalist>
</div>
<ng-content></ng-content>
