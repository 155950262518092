import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    ElementRef,
    Input,
    NgZone,
    OnDestroy,
    EventEmitter,
    Output,
    OnInit,
    HostBinding,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PerfectScrollbarConfig } from 'ngx-perfect-scrollbar';

import { ChartComponent } from '@dagility-ui/shared-components';

import { getWidgetDOMEvents } from '../../../services/message-bus/widget-dom-events.service';
import { AnyWidgetStore } from '../../widget/any-widget/any-widget.store';
import { WidgetEvent, WidgetEventManager } from '../../../services/message-bus/widget-event.manager';
import { WidgetAdditionalTemplate, WidgetTile } from '../../../models/any-widget.model';
import { DrilldownEvent } from '../../../services/widget.drilldown';

function getTooltipElement(chartInstance: any) {
    // eslint-disable-next-line no-underscore-dangle
    const tooltipModel = chartInstance.getModel()._componentsMap?.data?.tooltip?.[0];

    if (!tooltipModel) {
        return null;
    }

    const tooltipView = chartInstance.getViewOfComponentModel(tooltipModel);

    if (!tooltipView) {
        return null;
    }

    // eslint-disable-next-line no-underscore-dangle
    return tooltipView._tooltipContent.el;
}

function convertWidgetDomEventToDrilldownEvent(event: WidgetEvent): DrilldownEvent {
    return {
        target: event.id,
        payload: event.value,
    };
}

@Component({
    selector: 'dp-widget-template-wrapper',
    templateUrl: './widget-template-wrapper.component.html',
    styleUrls: ['./widget-template-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetTemplateWrapperComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() placeholders: Record<string, any>;

    @Input() templates: WidgetAdditionalTemplate[] = [];

    @Input() isComplexPart = false;

    @Output() drilldown = new EventEmitter<DrilldownEvent>();

    @ContentChild(ChartComponent) chartComponent: ChartComponent;

    subscription: Subscription = Subscription.EMPTY;
    initializedSubscription: Subscription = Subscription.EMPTY;
    allTemplates: Record<string, WidgetAdditionalTemplate[]> = {};
    @HostBinding('style.grid-template-rows') gridTemplateRows = '';
    @HostBinding('style.grid-template-columns') gridTemplateColumns = '';

    horizontalScrollbarConfig: PerfectScrollbarConfig = new PerfectScrollbarConfig({
        suppressScrollX: true,
        suppressScrollY: false,
        useBothWheelAxes: false,
    });

    verticalScrollbarConfig: PerfectScrollbarConfig = new PerfectScrollbarConfig({
        suppressScrollX: false,
        suppressScrollY: true,
        useBothWheelAxes: false,
    });

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef<HTMLElement>,
        private store: AnyWidgetStore,
        private manager: WidgetEventManager
    ) {}

    ngOnInit() {
        this.createAllTemplates();
        this.buildGridSizes();
    }

    templateClickedHandler(value: WidgetTile) {
        const drilldownInput = {
            template: value.template,
            targetDrilldown: value.targetDrilldown
                ? value.targetDrilldown.search(/{/) === -1 && value.targetDrilldown.search(/}/) === -1
                    ? value.targetDrilldown
                    : this.placeholders[value.targetDrilldown.replace(/{/, '').replace(/}/, '')]
                : value.targetDrilldown,
            drilldownInput: value.drilldownInput
                ? value.drilldownInput.search(/{/) === -1 && value.drilldownInput.search(/}/) === -1
                    ? value.drilldownInput
                    : this.placeholders[value.drilldownInput.replace(/{/, '').replace(/}/, '')]
                : value.drilldownInput,
        };

        if (drilldownInput.targetDrilldown) {
            this.drilldown.emit({
                target: drilldownInput.targetDrilldown,
                payload: drilldownInput,
            });
        }
    }

    createAllTemplates() {
        this.templates.forEach((element: WidgetAdditionalTemplate) => {
            if (!this.allTemplates[element.position]) {
                this.allTemplates[element.position] = [];
            }

            this.allTemplates[element.position].push(element);
        });
    }

    tooltipHandler = () => {
        this.subscription.unsubscribe();

        const tooltipElement = getTooltipElement(this.chartComponent.chartInstance);

        if (!tooltipElement) {
            return;
        }

        const widgetElement: HTMLElement = this.elementRef.nativeElement.closest('[data-widget_id]');

        if (!widgetElement) {
            return;
        }

        const widgetId = widgetElement.dataset['widget_id'];

        this.subscription = getWidgetDOMEvents({
            zone: this.zone,
            element: tooltipElement,
            getPlaceholdersFn: () => this.store.workflow.widgetDebugger.placeholdersMap[widgetId].value,
        })
            .pipe(debounceTime(300))
            .subscribe(({ event, placeholders }: { event: any; placeholders: Record<string, any> }) => {
                if (event.drilldown) {
                    this.zone.run(() => {
                        this.drilldown.emit(convertWidgetDomEventToDrilldownEvent(event));
                    });
                } else {
                    this.manager.process(event, placeholders, this.store.workflow.widget$.value);
                }
            });
    };

    ngAfterViewInit() {
        if (!this.store.workflow || !this.chartComponent) {
            return;
        }

        this.initializedSubscription = this.chartComponent.initialized.subscribe(() => {
            this.chartComponent.chartInstance.on('showTip', this.tooltipHandler);
        });
    }

    ngOnDestroy() {
        if (this.chartComponent?.chartInstance) {
            this.chartComponent.chartInstance.off('showTip', this.tooltipHandler);
        }
        this.subscription.unsubscribe();
        this.initializedSubscription.unsubscribe();
    }

    private buildGridSizes() {
        const columns = ['fit-content(40%)', 'fit-content(40%)'];
        const rows = ['fit-content(20%)', 'fit-content(20%)'];
        this.buildGridDimension(rows, ['top', 'bottom'], 'auto');
        this.buildGridDimension(columns, ['left', 'right'], 'min(400px, 50%)');

        this.gridTemplateRows = `${rows[0]} 1fr ${rows[1]}`;
        this.gridTemplateColumns = `${columns[0]} 1fr ${columns[1]}`;
    }

    private buildGridDimension(sizes: string[], positions: string[], correctedSize: string) {
        for (let i = 0; i < positions.length; i++) {
            const position = positions[i];
            if ((this.allTemplates[position] ?? []).some(template => template.tilesTemplate)) {
                sizes[i] = correctedSize;
            }
        }
    }
}
