<div class="tab-section">
    <div class="header">
        <span>Fields</span>
        <button *ngIf="hasSelectedFields(gridData)" class="btn btn-link size-s" (click)="handleCancelSelection(gridData)">
            <fa-icon class="mr-5px" icon="facClose" style="font-size: 6px"></fa-icon>
            Cancel selection
        </button>
    </div>
    <ng-container *ngIf="gridData.length else listPlaceholderTmpl">
        <perfect-scrollbar [style.maxHeight.px]="220" >
            <ul class="data-list">
                <li *ngFor="let data of gridData" [class.selected]="data.selected" (click)="onSelectRow(data)">
                    <span>{{ data.displayedName }}</span>
                </li>
            </ul>
        </perfect-scrollbar>
    </ng-container>
</div>
<div class="buttons-container">
    <div class="arrow-button" [class.disabled]="!hasSelectedFields(gridData)" (click)="handlePushSelected(gridData)">
        <fa-icon icon="chevron-right"></fa-icon>
    </div>
    <div class="arrow-button" [class.disabled]="!hasSelectedFields(gridDataSelected)" (click)="handlePushSelected(gridDataSelected)">
        <fa-icon icon="chevron-left"></fa-icon>
    </div>
</div>

<div class="tab-section">
    <div class="header">
        <span>Sort by</span>
        <button *ngIf="hasSelectedFields(gridDataSelected)" class="btn btn-link size-s mr-30px" (click)="handleCancelSelection(gridDataSelected)">
            <fa-icon class="mr-5px" icon="facClose" style="font-size: 6px"></fa-icon>
            Cancel selection
        </button>
    </div>
    <ng-container *ngIf="gridDataSelected.length else listSortPlaceholderTmpl" >
        <perfect-scrollbar [style.maxHeight.px]="220">
            <ul class="data-list" cdkDropList [cdkDropListData]="gridDataSelected" (cdkDropListDropped)="handleDropListDropped($event)">
                <li *ngFor="let data of gridDataSelected" [class.selected]="data.selected" cdkDrag [cdkDragData]="data" cdkDragLockAxis="y" (click)="onSelectRow(data)">
                    <div class="data-list-item">
                        <span class="user-select-none">{{ data.displayedName }}</span>
                        <div class="d-inline-flex align-items-center cursor-pointer">
                            <app-sort-type-dropdown
                                (click)="$event.stopPropagation()"
                                [style.marginTop.px]="-1"
                                [withSortOff]="false"
                                [selectedDirection]="data.dir"
                                (itemClicked)="updateSort(data, $event)"
                            ></app-sort-type-dropdown>
                            <fa-icon icon="facTrash" class="ml-5px cursor-pointer" (click)="$event.stopPropagation(); handleRemove(data)"></fa-icon>
                        </div>
                    </div>
                    <svg
                        class="drag-handle-icon"
                        cdkDragHandle
                        width="14"
                        height="14"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M4.5 6.5C4.77614 6.5 5 6.27614 5 6C5 5.72386 4.77614 5.5 4.5 5.5C4.22386 5.5 4 5.72386 4 6C4 6.27614 4.22386 6.5 4.5 6.5Z"
                            fill="#BDBCBC"
                        />
                        <path
                            d="M4.5 3C4.77614 3 5 2.77614 5 2.5C5 2.22386 4.77614 2 4.5 2C4.22386 2 4 2.22386 4 2.5C4 2.77614 4.22386 3 4.5 3Z"
                            fill="#BDBCBC"
                        />
                        <path
                            d="M4.5 10C4.77614 10 5 9.77614 5 9.5C5 9.22386 4.77614 9 4.5 9C4.22386 9 4 9.22386 4 9.5C4 9.77614 4.22386 10 4.5 10Z"
                            fill="#BDBCBC"
                        />
                        <path
                            d="M7.5 6.5C7.77614 6.5 8 6.27614 8 6C8 5.72386 7.77614 5.5 7.5 5.5C7.22386 5.5 7 5.72386 7 6C7 6.27614 7.22386 6.5 7.5 6.5Z"
                            fill="#BDBCBC"
                        />
                        <path
                            d="M7.5 3C7.77614 3 8 2.77614 8 2.5C8 2.22386 7.77614 2 7.5 2C7.22386 2 7 2.22386 7 2.5C7 2.77614 7.22386 3 7.5 3Z"
                            fill="#BDBCBC"
                        />
                        <path
                            d="M7.5 10C7.77614 10 8 9.77614 8 9.5C8 9.22386 7.77614 9 7.5 9C7.22386 9 7 9.22386 7 9.5C7 9.77614 7.22386 10 7.5 10Z"
                            fill="#BDBCBC"
                        />
                        <path
                            d="M4.5 6.5C4.77614 6.5 5 6.27614 5 6C5 5.72386 4.77614 5.5 4.5 5.5C4.22386 5.5 4 5.72386 4 6C4 6.27614 4.22386 6.5 4.5 6.5Z"
                            stroke="#BDBCBC"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M4.5 3C4.77614 3 5 2.77614 5 2.5C5 2.22386 4.77614 2 4.5 2C4.22386 2 4 2.22386 4 2.5C4 2.77614 4.22386 3 4.5 3Z"
                            stroke="#BDBCBC"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M4.5 10C4.77614 10 5 9.77614 5 9.5C5 9.22386 4.77614 9 4.5 9C4.22386 9 4 9.22386 4 9.5C4 9.77614 4.22386 10 4.5 10Z"
                            stroke="#BDBCBC"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M7.5 6.5C7.77614 6.5 8 6.27614 8 6C8 5.72386 7.77614 5.5 7.5 5.5C7.22386 5.5 7 5.72386 7 6C7 6.27614 7.22386 6.5 7.5 6.5Z"
                            stroke="#BDBCBC"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M7.5 3C7.77614 3 8 2.77614 8 2.5C8 2.22386 7.77614 2 7.5 2C7.22386 2 7 2.22386 7 2.5C7 2.77614 7.22386 3 7.5 3Z"
                            stroke="#BDBCBC"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M7.5 10C7.77614 10 8 9.77614 8 9.5C8 9.22386 7.77614 9 7.5 9C7.22386 9 7 9.22386 7 9.5C7 9.77614 7.22386 10 7.5 10Z"
                            stroke="#BDBCBC"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </li>
            </ul>
        </perfect-scrollbar>
    </ng-container>

</div>

<ng-template #listSortPlaceholderTmpl>
    <div class="list-placeholder">
        <h3>No fields selected</h3>
        <span class="mt-8px disable">Add fields here to sort by</span>
    </div>
</ng-template>

<ng-template #listPlaceholderTmpl>
    <div class="list-placeholder">
        <h3>No fields</h3>
        <span class="mt-8px disable">All fields are used for sorting</span>
    </div>
</ng-template>
