import { Component, TemplateRef, ViewChild } from '@angular/core';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from '@dagility-ui/shared-components';

const BODY_HEIGHT = 555;
const BODY_WIDTH = 800;
const BODY_PADDING = 100;
const LOG_PADDING = 55;

@Component({
    selector: 'app-log-deployed-application',
    templateUrl: './log-deployed-application.component.html',
    styleUrls: ['./log-deployed-application.component.scss'],
})
export class LogDeployedApplicationComponent {
    @ViewChild(PerfectScrollbarComponent) scrollbar: PerfectScrollbarComponent;

    title = 'Log';

    logs: string;
    headerTemplateRef: TemplateRef<any>;
    titleTemplateRef: TemplateRef<any>;

    isExpanded: boolean;

    get bodyWidth() {
        return this.isExpanded ? document.body.clientWidth - BODY_PADDING : BODY_WIDTH;
    }

    get bodyHeight() {
        return this.isExpanded ? document.body.clientHeight - BODY_PADDING : BODY_HEIGHT;
    }

    get logHeight() {
        return this.bodyHeight - LOG_PADDING;
    }

    constructor(public modal: NgbActiveModal, private toaster: ToasterService) {}

    copyToClipboard(): void {
        const log = this.scrollbar.directiveRef.elementRef.nativeElement.innerText;
        const selBox = document.createElement('textarea');
        selBox.style.position = 'absolute';
        selBox.style.left = '-9999px';
        selBox.value = log;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.toaster.successToast({ title: 'Success', content: 'Copied to clipboard' });
    }
}
