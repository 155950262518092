import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
import { DropdownItem, lightOrDark } from '@dagility-ui/kit';
import { sum } from 'lodash';

@Component({
    selector: 'lib-progress-chart',
    templateUrl: './progress-chart.component.html',
    styleUrls: ['./progress-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressChartComponent {
    @Input() set data(data: ProgressChartData) {
        this._data = data;
        this._data.progress = data.progress.filter(progress => parseInt(progress.value as any, 10) !== 0);
        this.isEmpty = this.data.progress.every(({ value }) => !parseInt(value as any, 10));
        this.sum = sum(this.data.progress.map(({ value }) => parseInt(value as any, 10)));
    }

    @Output() barClicked = new EventEmitter<string>();

    get data() {
        return this._data;
    }

    isEmpty = false;
    sum = 0;
    lightOrDark = lightOrDark;

    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _data: ProgressChartData;
}

export interface ProgressChartData {
    progress: ProgressItem[];
    labels: DropdownItem[];
    header: string;
    noDataMessage: string;
    clickable?: boolean;
    measure?: string;
}

export interface ProgressItem {
    color: string;
    value: number;
    label: string;
    column: string;
}
