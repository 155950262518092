import { Deserializable, JobDefinitionDataTarget, DataSchema } from '../../../models/job-definition';
import { Validate } from '../../../models/validate.decorator';

export class PublishAction extends Deserializable {
    _arrays = {};
    _class = { dataTarget: JobDefinitionDataTarget };
    _enums = {};
    _maps = {};
    meta = {
        _arrays: this._arrays,
        _class: this._class,
        _enums: this._enums,
        _maps: this._maps,
    };

    @Validate([DataSchema.KeysInColumns, DataSchema.ColumnsNotEmpty])
    dataTarget: JobDefinitionDataTarget = new JobDefinitionDataTarget();
    variable = '';

    getType() {
        return 'PublishAction';
    }

    constructor(instance?: any) {
        super();
        this.fillByInstance(PublishAction, instance);
    }
}
