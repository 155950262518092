import { ComponentRef, Directive, ElementRef, NgZone, OnDestroy, Optional } from '@angular/core';
import { fromEvent, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { NgbDropdown, NgbDropdownMenu, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { NgbPopoverWindow } from '@ng-bootstrap/ng-bootstrap/popover/popover';

import { ZoneFreeOperator } from '@dagility-ui/kit';

function hasOpenedDropdownInto(element: HTMLElement) {
    return !!element.querySelector('ng-select.ng-select-opened');
}

function eventFromDropdownPanel(event: MouseEvent) {
    const [root] = event.composedPath();

    return root instanceof Element && root.closest('ng-dropdown-panel');
}

function eventFromDatepickerPanel(event: MouseEvent) {
    const [root] = event.composedPath();

    return root instanceof Element && root.closest('lib-datepicker-range-dialog,ngb-datepicker');
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[ngbPopover][autoClosePopup],[ngbDropdown][autoCloseDropdown]',
})
export class AutoClosePopupDirective implements OnDestroy {
    private destroyed$ = new Subject<void>();

    private get shown$(): Observable<any> {
        if (this.popover) {
            return this.popover.shown.asObservable();
        }

        if (this.dropdown) {
            return this.dropdown.openChange.pipe(filter(Boolean));
        }
    }

    private get hidden$(): Observable<any> {
        if (this.popover) {
            return this.popover.hidden.asObservable();
        }

        return this.dropdown.openChange.pipe(filter(open => !open));
    }

    private get nativeElement(): HTMLElement {
        if (this.popover) {
            return (this.popover['_windowRef'] as ComponentRef<NgbPopoverWindow>).location.nativeElement;
        }

        return (this.dropdown['_menu'] as NgbDropdownMenu).nativeElement;
    }

    constructor(
        @Optional() private popover: NgbPopover | null,
        @Optional() private dropdown: NgbDropdown,
        zone: NgZone,
        host: ElementRef<HTMLElement>
    ) {
        this.shown$
            .pipe(
                map(() => this.nativeElement),
                switchMap(element =>
                    fromEvent<MouseEvent>(document, 'mousedown').pipe(
                        map(event => ({ element, event })),
                        takeUntil(this.hidden$)
                    )
                ),
                map(({ element, event }) => {
                    const path = event.composedPath();

                    if (
                        // click on panel
                        path.includes(element) ||
                        // click on popover button
                        path.includes(host.nativeElement) ||
                        // click on datepicker backdrop
                        (path[0] as Element).matches('div#popover-backdrop')
                    ) {
                        return false;
                    }

                    if (eventFromDropdownPanel(event) && hasOpenedDropdownInto(element)) {
                        return false;
                    }

                    return !eventFromDatepickerPanel(event);
                }),
                filter(Boolean),
                takeUntil(this.destroyed$)
            )
            .lift(new ZoneFreeOperator<boolean>(zone))
            .subscribe(() => {
                zone.run(() => this.close());
            });
    }

    private close() {
        if (this.popover) {
            this.popover.close();
        } else {
            this.dropdown.close();
        }
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }
}
