<div *ngIf="!filesDragging">
    <div class="d-inline-flex justify-content-start align-items-center">
        <button class="btn btn-link" (click)="selectFiles()" [disabled]="getUnloadedFiles() > 0">
            <svg class="mr-8px" width="18" height="18" viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M21.44 11.05L12.25 20.24C11.1242 21.3659 9.5972 21.9984 8.00502 21.9984C6.41283 21.9984 4.88586 21.3659 3.76002 20.24C2.63417 19.1142 2.00168 17.5872 2.00168 15.995C2.00168 14.4029 2.63417 12.8759 3.76002 11.75L12.95 2.56004C13.7006 1.80948 14.7186 1.38782 15.78 1.38782C16.8415 1.38782 17.8595 1.80948 18.61 2.56004C19.3606 3.3106 19.7822 4.32859 19.7822 5.39004C19.7822 6.4515 19.3606 7.46948 18.61 8.22004L9.41002 17.41C9.03473 17.7853 8.52574 17.9962 7.99502 17.9962C7.46429 17.9962 6.9553 17.7853 6.58002 17.41C6.20473 17.0348 5.9939 16.5258 5.9939 15.995C5.9939 15.4643 6.20473 14.9553 6.58002 14.58L15.07 6.10004"
                    stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Attach file
        </button>
        <span class="color-font-4 ml-30px">
                System supports {{getAllowedFileTypes()}} files only.
                <br>
                Max size of each file is {{getMaxFileSize()}} MB
            </span>
    </div>
    <div class="mt-20px" *ngIf="getUnloadedFiles() > 0">
        <span class="color-font-4 mr-30px ml-10px font-12px">{{filesUploadingProgress}} %</span>
        <span class="color-brand font-12px" *ngIf="filesUploadingProgress < 100">{{getUnloadedFiles()}}
            file{{getUnloadedFiles() > 1 ? 's' : ''}} is uploading</span>
        <span class="color-brand font-12px" *ngIf="filesUploadingProgress === 100">Preparing {{getUnloadedFiles()}}
            file{{getUnloadedFiles() > 1 ? 's' : ''}}. Please, wait...</span>
    </div>
    <div *ngIf="this.files && this.files.length > 0 && this.files[0].id !== null">
        <div class="attached-file-header" *ngIf="files && files.length > 0"
             (click)="this.attachedMenuOpened = !this.attachedMenuOpened">
            <span *ngIf="files && files.length > 0">{{files.length}} files attached</span>
            <span class="attached-file-header-elem color-font-4 mr-5px"
                  *ngIf="files && files.length > 0">{{getSumFileSize()}}
                MB</span>
            <fa-icon *ngIf="!attachedMenuOpened" icon="chevron-down" class="size-m"></fa-icon>
            <fa-icon *ngIf="attachedMenuOpened" icon="chevron-up" class="size-m"></fa-icon>
        </div>
        <div *ngIf="this.attachedMenuOpened"
             class="attached-file">
            <div *ngFor="let file of files">
                <div *ngIf="file.id !== null" class="d-inline-flex justify-content-start align-items-center mt-5px">
                    <fa-icon icon="facDownload" class="attached-file-header-icon color-brand"></fa-icon>
                    <span class="attached-file-header-elem">{{file.file ? file.file.name : file.name}}</span>
                    <span class="attached-file-header-elem color-font-4 mr-5px">{{getFileSize(file)}} MB</span>
                    <fa-icon (click)="deleteFile(file)" icon="facTrash"
                             class="attached-file-header-icon color-brand cursor-pointer"></fa-icon>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="filesDragging"
     style="display: flex; flex-direction: column; align-items: center; justify-content: center; border: 2px dashed #317CE2;padding: 80px 0 80px 0; border-radius: 4px;">
    <span class="color-brand font-18px">Drag & Drop</span>
</div>
