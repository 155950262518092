import { Component, Input } from '@angular/core';
import { PerfectScrollbarConfig } from 'ngx-perfect-scrollbar';

import { CodeQualitySummary, CodeQualitySummaryItemType } from './model/code-quality-summary.model';


@Component({
    selector: 'dp-code-quality-summary',
    templateUrl: './code-quality-summary.component.html',
    styleUrls: ['./code-quality-summary.component.scss'],
})
export class CodeQualitySummaryComponent {
    @Input() item: CodeQualitySummary;

    psConfig: PerfectScrollbarConfig = new PerfectScrollbarConfig(
        {
            suppressScrollX: false,
            suppressScrollY: true,
            useBothWheelAxes: false
        }
    );

    getStatisticsList(statisticsMap: Record<CodeQualitySummaryItemType, { label: string, value: any }>): { label: string, value: any }[] {
        return statisticsMap ? Object.entries(statisticsMap).map(x => x[1]) : [];
    }
}
