<perfect-scrollbar class="scrollbar">
    <div class="summary-wrapper">
        <div class="col-4 h-100 p-0">
            <dp-code-coverage [item]="item.coverage"></dp-code-coverage>
        </div>

        <div class="col w-100 h-100 pr-0">
            <perfect-scrollbar class="ps" [config]="psConfig">
                <div class="col quality-items">
                    <ng-container *ngIf="item.summary">
                        <div class="summary-squares">
                            <dp-code-quality-summary-item *ngFor="let item of item.summary" [item]="item"></dp-code-quality-summary-item>
                        </div>
                    </ng-container>

                    <div class="row no-gutters">
                        <div class="statistics-text w-100">
                            <div *ngFor="let stat of getStatisticsList(item.newIssuesNumbers)"
                                 class="row no-gutters"
                            >
                                <div class="col font-12px">
                                    {{ stat.label }}
                                </div>
                                <div class="col statistics-value">
                                    {{ stat.value }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="item.duplication">
                        <dp-factor-scores [data]="item.duplication" [type]="'percent'"></dp-factor-scores>
                    </ng-container>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
</perfect-scrollbar>
