import { EventEmitter } from '@angular/core';
import { Component, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-interval-chooser',
    templateUrl: './interval-chooser.component.html',
    styleUrls: ['./interval-chooser.component.scss'],
})
export class IntervalChooserComponent {
    @Input() set value(val: { from: number, to: number }) {
        if (!this.form) {
            const initVal = val ? val : { from: 0, to: 0 };
            this.initForm(initVal);
        }
    }

    @Output() intervalChanged = new EventEmitter();

    form: FormGroup;

    get from() {
        return (this.form.get('from') || {}) as FormControl;
    }

    get to() {
        return (this.form.get('to') || {}) as FormControl;
    }

    constructor(private fb: FormBuilder) {}

    initForm(value: { from: number, to: number }) {
        this.form = this.fb.group({
            from: [value.from, [Validators.min(0), Validators.max(5)]],
            to: [value.to, [Validators.min(0), Validators.max(5)]],
        });

        this.form.valueChanges
            .pipe(distinctUntilChanged((p, q) => p.from === q.from && p.to === q.to))
            .subscribe((value) => {
                if (value) {
                    this.intervalChanged.emit({ from: +value.from, to: +value.to });
                }
            });
    }

    onToValueChange(toValue: any) {
        this.to.patchValue(toValue);
        this.form.updateValueAndValidity();
    }

    onFromValueChange(fromValue: any) {
        this.from.patchValue(fromValue);
        this.form.updateValueAndValidity();
    }
}
