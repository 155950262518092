import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
    readonly units = ['bytes', 'KB', 'MB', 'GB'];

    transform(value: number, mantissaDigitsCount: number = 1): string {
        let result = '';
        let unitIndex = 0;
        let maxUnitIndex = this.units.length;

        if (value >= 0) {
            while (value / 1024 > 0.1 && unitIndex < maxUnitIndex) {
                value = value / 1024;
                unitIndex++;
            }
            mantissaDigitsCount = value % 1 ? mantissaDigitsCount : 0;
            result = value.toFixed(mantissaDigitsCount) + ' ' + this.units[unitIndex];
        }
        return result;
    }
}
