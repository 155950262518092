<div class="graph-container">
    <div class="graph-header-container main-content-header main-content-header-shadow">
        <h6 class="my-1 d-flex align-items-center cursor-pointer" (click)="back()">
            <fa-icon icon="facArrowLeftRegular" class="fa color-font-4"></fa-icon>
            <span class="heading1 ml-2"> Job definitions tree for {{ toolName }} </span>
        </h6>

        <div class="ml-auto actions-group d-flex align-items-center mr-2">
            <div class="pl-10px pr-2" (click)="view.zoom.zoom(1)">+</div>
            <div class="px-2 border-x" (click)="view.zoom.reset()">100%</div>
            <div class="pl-2 pr-10px" (click)="view.zoom.zoom(-1)">-</div>
        </div>
    </div>
    <dp-tool-graph-view-layout #view [lines]="lines" [blocks]="blocks" [channels]="channels"> </dp-tool-graph-view-layout>
</div>
