<div class="modal-header d-flex">
    <h2 class="mt-0">{{ header }}</h2>
    <button type="button" class="close ml-auto" (click)="handleCancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<perfect-scrollbar
    *ngIf="ckEditorLoaded$ | async; else loaderTemplate"
    class="modal-body overflow-hidden d-flex flex-column align-items-start pr-3"
>
    <ng-container *ngIf="!insightMode; else insightsTemplate">
        <ng-template [ngTemplateOutlet]="maxCharsTemplate" [ngTemplateOutletContext]="{ $implicit: maxCharsMap.get(form) }"></ng-template>
        <ck-editor
            data-test-id="simple"
            intoScrollContainer
            [config]="editorOptions"
            [formControl]="form"
            (ready)="onEditorReady()"
        ></ck-editor>
    </ng-container>
    <ng-template #insightsTemplate>
        <ng-container [formGroup]="form">
            <div class="mb-4">
                <div class="body2 mb-2">
                    Insight
                    <ng-template
                        [ngTemplateOutlet]="maxCharsTemplate"
                        [ngTemplateOutletContext]="{ $implicit: maxCharsMap.get(form.get('insight')) }"
                    ></ng-template>
                </div>
                <ck-editor
                    data-test-id="insight"
                    class="d-block"
                    intoScrollContainer
                    [config]="editorOptions"
                    formControlName="insight"
                ></ck-editor>
            </div>

            <ng-container formArrayName="recommendations">
                <div
                    [class.mb-4]="!last"
                    *ngFor="let recommendationControl of recommendationsArray.controls; index as index; first as first; last as last"
                >
                    <div data-test-id="recommendation-header" class="body d-flex align-items-center mb-2">
                        Recommendation
                        <div class="d-flex align-items-center ml-auto">
                            <button *ngIf="!first" class="btn btn-link mr-2" (click)="handleRemoveRecommendation(index)">
                                <fa-icon class="font-16px" icon="facTrash"></fa-icon>
                            </button>
                            <ng-template
                                [ngTemplateOutlet]="maxCharsTemplate"
                                [ngTemplateOutletContext]="{ $implicit: maxCharsMap.get(recommendationControl) }"
                            ></ng-template>
                        </div>
                    </div>
                    <ck-editor
                        data-test-id="recommendation"
                        class="d-block"
                        intoScrollContainer
                        [config]="editorOptions"
                        [formControlName]="$any(index)"
                    ></ck-editor>
                </div>
            </ng-container>

            <button data-test-id="add-recommendation" class="btn btn-link mt-4 ml-1 mb-1" (click)="handleAddRecommendation()">
                <fa-icon class="mr-2" icon="plus"></fa-icon> Add one more recommendation
            </button>
        </ng-container>
    </ng-template>
</perfect-scrollbar>

<div class="modal-footer">
    <button class="btn btn-secondary mr-3" (click)="handleCancel()">Cancel</button>
    <button [disabled]="saveEnabled$ | async" class="btn btn-primary" (click)="handleSave()">Ok</button>
</div>

<ng-template #loaderTemplate>
    <div class="d-flex justify-content-center my-4">
        <lib-spinner></lib-spinner>
    </div>
</ng-template>

<ng-template #maxCharsTemplate let-maxChars>
    <div
        class="counter-container"
        *ngIf="isMaxCharsControlGuard(maxChars) as maxCharsTyped"
        [class.color-red]="!(maxCharsTyped.valid$ | async)"
    >
        {{ maxCharsTyped.currentCount$ | async }} / {{ maxCharsTyped.maxCount }} characters
    </div>
</ng-template>
