import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    SimpleChanges
} from "@angular/core";
import { PerfectScrollbarConfig } from "ngx-perfect-scrollbar";
import { DomSanitizer } from "@angular/platform-browser";
import { DashboardMessageBus } from "data-processor/lib/widget-library/widget-builder/services/message-bus/message-bus";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import * as regularIcons from "@fortawesome/free-regular-svg-icons";
import { cloneDeep } from "lodash";

export interface FlowItem {
    action: string;
    type: string;
    created: string;
    date?: string;
    logTime: string;
    status: string;
    isVisible: boolean;
    toolId: string;
    field: string;
    from: string;
    to: string;
    buildStatus: string;
    data: any[];
    collapsed: boolean;
    stepsCollapsed: boolean;
}

interface SelectedSprint {
    id: number;
    created: string;
    from: string;
    to: string;
    startDate: string;
    endDate: string;
    type: string;
    field: string;
    logTime: string;
}

const SMALL_COL_WIDTH = 130;
const MEDIUM_COL_WIDTH = 150;

@Component({
    selector: 'dp-issue-life-cycle',
    templateUrl: './issue-life-cycle.component.html',
    styleUrls: ['./issue-life-cycle.component.scss'],
})
export class IssueLifeCycleComponent implements OnInit, OnDestroy {
    @Input() options: any;

    horizontalScrollbarConfig: PerfectScrollbarConfig = new PerfectScrollbarConfig({
        suppressScrollX: false,
        suppressScrollY: true,
        useBothWheelAxes: false,
    });

    icons = {
        faPlusSquare: regularIcons.faPlusSquare,
        faMinusSquare: regularIcons.faMinusSquare,
    };

    lastState: any;
    lastColor: string;
    statusValues: string[] = [];
    selectedFlowItem: number = null;
    selectedSprint: SelectedSprint = null;
    selectedGroupStep: any = null;
    stepTypeMap: Record<string, any> = {
        'CI/CD': 'Build',
        PM: 'Status Changed',
        SCM: 'Commit',
        LAST_7_BUILDS_STATUSES: 'Deployment',
    };
    flow: FlowItem[] = [];
    defaultFlow: FlowItem[] = [];
    toolImages: Record<string, any> = {};
    unconfiguredStatuses: string[] = [];

    tooltip = false;

    readonly smallColWidth = SMALL_COL_WIDTH;
    readonly mediumColWidth = MEDIUM_COL_WIDTH;

    private dateMap = new Map();
    private prevStepGroup = new Map();
    private destroy$ = new Subject<void>();

    constructor(
        private sanitizer: DomSanitizer,
        @Optional() protected messageBus: DashboardMessageBus,
        private cdr: ChangeDetectorRef,
        private elRef: ElementRef
    ) {}

    ngOnInit() {
        if (this.messageBus) {
            this.messageBus.tabChanged$.pipe(takeUntil(this.destroy$)).subscribe(() => (this.options = {}));
        }

        this.init();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.options?.previousValue) {
            this.init();
        }
    }

    init() {
        this.flow = [];
        this.defaultFlow = [];
        this.unconfiguredStatuses = [];
        this.statusValues = [];

        this.toolImages = {};

        this.dateMap = new Map();
        this.prevStepGroup = new Map();

        this.selectedFlowItem = null;
        this.selectedSprint = null;
        this.selectedGroupStep = null;

        const statusSteps = (this.options.steps || []).filter((s: any) => s.type === 'PM' && s.field === 'status');
        this.lastState = statusSteps.length ? statusSteps[statusSteps.length - 1] : null;

        this.lastColor = this.options.statuses.find((status: any) => status.value === this.lastState.to)?.color || '';

        this.toolImages = (this.options.toolImages || []).reduce((map: any, tool: any) => {
            map[tool.tool_id] = this.sanitizer.bypassSecurityTrustResourceUrl(tool.image_url);
            return map;
        }, {});
        this.initSteps();

        const realStatuses = this.flow.map(item => item.status).filter((item, i, array) => array.indexOf(item) === i);
        const statusValues = this.options.statuses.map((status: any) => status.value);
        realStatuses.forEach(status => {
            if (!statusValues.includes(status)) {
                this.unconfiguredStatuses.push(status);
            }
        });

        if (this.unconfiguredStatuses.length) {
            return;
        }

        if (this.flow.length) {
            this.initGroupSteps();
        }

        this.defaultFlow = cloneDeep(this.flow);

        this.options.statuses = this.options.statuses
            .filter((status: any) => realStatuses.includes(status.value))
            .sort((a: any, b: any) => a.order - b.order);

        this.statusValues = this.options.statuses.map((status: any) => status.value);
    }

    @HostListener('window:resize')
    onResize() {
        setTimeout(() => this.cdr.detectChanges(), 200);
    }

    initSteps() {
        let status = this.options.statuses[0]?.value;
        (this.options.steps || []).forEach((step: any) => {
            if (step.type === 'PM') {
                if (step.field === 'status') {
                    status = step.to;
                }

                if (!step.isVisible) {
                    return;
                }
            }

            const date = new Date(step.created);
            const mapKey = `${date.getDay()}-${date.getMonth()}-${date.getFullYear()}`;

            const prevIndex = this.prevStepGroup.get(`${step.type}-${step.toolId}-${status}-${new Date(step.created).getDate()}`);

            if (prevIndex && step.field !== 'Sprint' && step.field !== 'status') {
                this.flow[prevIndex] = {
                    ...this.flow[prevIndex],
                    data: [...this.flow[prevIndex]?.data, { ...step, buildStatus: step.status }],
                };
            } else {
                this.flow.push({
                    ...step,
                    action: step.type === 'PM' ? (step.from ? step.toLabel || 'Status Changed' : 'Created') : this.stepTypeMap[step.type],
                    created: this.dateMap.has(mapKey) ? null : step.created,
                    status,
                    date: step.created,
                    buildStatus: step.status,
                    data: [{ ...step, buildStatus: step.status }],
                    collapsed: false,
                });
                this.prevStepGroup.set(`${step.type}-${step.toolId}-${status}-${new Date(step.created).getDate()}`, this.flow.length - 1);
            }

            this.dateMap.set(mapKey, true);
        });
    }

    initGroupSteps() {
        const transformDate = (date: Date) => `${date.getDay()}-${date.getMonth()}-${date.getFullYear()}`;
        let currentDay = transformDate(new Date(this.flow[0].date));
        let currentStatus = this.flow[0].status;

        const newFlow: FlowItem[] = [];
        let tmpGroupSteps: any = [];

        const transformGroupStep = (tmpGroupSteps: any, date: string) => ({
            type: 'Group',
            status: currentStatus,
            steps: tmpGroupSteps,
            collapsed: false,
            stepsCollapsed: true,
            created: date,
        });

        this.flow.forEach(item => {
            const itemDate = transformDate(new Date(item.date));
            if (currentDay === itemDate && item.field !== 'Sprint') {
                if (currentStatus === item.status) {
                    tmpGroupSteps.push(item);
                } else {
                    if (tmpGroupSteps.length) {
                        newFlow.push(
                            tmpGroupSteps.length === 1 ? tmpGroupSteps[0] : transformGroupStep(tmpGroupSteps, tmpGroupSteps[0].created)
                        );
                        tmpGroupSteps = [];
                    }
                    currentStatus = item.status;
                    tmpGroupSteps.push(item);
                }
                return;
            }

            if (item.field === 'Sprint') {
                if (tmpGroupSteps.length) {
                    newFlow.push(
                        tmpGroupSteps.length === 1 ? tmpGroupSteps[0] : transformGroupStep(tmpGroupSteps, tmpGroupSteps[0].created)
                    );
                    tmpGroupSteps = [];
                }

                newFlow.push(item);
                return;
            }

            if (currentDay !== itemDate) {
                if (tmpGroupSteps.length) {
                    newFlow.push(
                        tmpGroupSteps.length === 1 ? tmpGroupSteps[0] : transformGroupStep(tmpGroupSteps, tmpGroupSteps[0].created)
                    );
                    tmpGroupSteps = [];
                }
                currentDay = itemDate;
                currentStatus = item.status;

                tmpGroupSteps.push(item);
            }
        });

        if (tmpGroupSteps.length) {
            newFlow.push(tmpGroupSteps.length === 1 ? tmpGroupSteps[0] : transformGroupStep(tmpGroupSteps, tmpGroupSteps[0].created));
        }

        this.flow = newFlow;
    }

    getColWidth(col: HTMLElement) {
        return Math.round(col.getBoundingClientRect().width);
    }

    getLinkPosition(colWidth: number, from: number, to: number, isGroup: boolean) {
        const flowBlockWidth = colWidth > this.mediumColWidth ? '130px' : colWidth > this.smallColWidth || isGroup ? '70px' : '30px';
        const beforeBorder = '7px';
        const columnsCount = Math.abs(from - to);
        return `calc(${columnsCount * colWidth}px - ${flowBlockWidth} / 2 + ${columnsCount} * ${beforeBorder})`;
    }

    closeSidePanel() {
        this.selectedFlowItem = null;
        this.selectedSprint = null;
        this.selectedGroupStep = null;
    }

    onFlowItemClick(index: number) {
        if (this.flow[index].field === 'Sprint' || this.flow[index].type === 'Group') {
            return;
        }

        this.selectedFlowItem = index;
        this.selectedSprint = null;
    }

    onSprintClick(index: number) {
        const item = this.flow[index] as any;
        this.selectedSprint = {
            id: index,
            created: item.date,
            startDate: item.startDate,
            endDate: item.endDate,
            from: item.from,
            to: item.to,
            field: 'sprint',
            type: 'PM',
            logTime: item.logTime,
        };
        this.selectedFlowItem = null;
    }

    onStepGroupClick(item: FlowItem) {
        this.selectedGroupStep = item;
        this.selectedFlowItem = null;
        this.selectedSprint = null;
    }

    showTooltip() {
        this.tooltip = true;

        setTimeout(() => {
            const tooltipEl = this.elRef.nativeElement.getElementsByClassName('issue-life-cycle-tooltip').item(0);

            if (!tooltipEl) {
                return;
            }

            const additionalInfoEl = this.elRef.nativeElement.getElementsByClassName('additional-info').item(0);
            const { left, top, width } = additionalInfoEl.getBoundingClientRect();
            const topOffset = 12;
            const triangleWidth = 8;

            tooltipEl.parentNode.removeChild(tooltipEl);
            tooltipEl.style.left = `${left + width / 2 + triangleWidth}px`;
            tooltipEl.style.top = `${top - topOffset}px`;

            document.body.append(tooltipEl);
        });
    }

    hideTooltip({ toElement }: any) {
        if (toElement?.classList.contains('issue-life-cycle-tooltip') || toElement?.classList.contains('additional-info')) {
            return;
        }

        const tooltipEl = document.getElementsByClassName('issue-life-cycle-tooltip').item(0);
        if (tooltipEl.contains(toElement)) {
            return;
        }

        const additionalInfoEl = this.elRef.nativeElement.getElementsByClassName('additional-info').item(0);
        if (additionalInfoEl.contains(toElement)) {
            return;
        }

        this.tooltip = false;
        this.cdr.detectChanges();
    }

    handleExpandingIcon(item: FlowItem) {
        item.collapsed = !item.collapsed;
        const index = this.flow.findIndex(flowItem => flowItem === item);

        const tail = this.defaultFlow.slice(index + 1);
        tail.forEach(item => (item.collapsed = false));

        this.flow = item.collapsed ? this.flow.slice(0, index + 1) : [...this.flow, ...tail];
    }

    handleStepsExpandingIcon(item: FlowItem) {
        item.stepsCollapsed = !item.stepsCollapsed;
    }

    checkLinkWithNull(link: string) {
        return !link || link.includes('null');
    }

    ngOnDestroy() {
        this.destroy$.next();
    }
}
