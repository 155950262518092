<ng-container *ngIf="queryForm">
    <ng-container [formGroup]="queryForm" *ngIf="isQueryLibraryEnabled">
        <lib-dropdown
            *ngIf="facade.queries$ | obsWithStatus | async as vm"
            data-test-id="parent-control"
            class="d-block mb-3"
            formControlName="parent"
            label="Template"
            labelKey="name"
            placeholder="Select parent query"
            valueKey="name"
            [items]="vm.value"
            [loading]="vm.loading"
            (change)="clearScriptFields()"
        >
            <span context-help-id="template-query"></span>
        </lib-dropdown>
    </ng-container>

    <ng-container *ngIf="hasParent; else defaultTemplate">
        <ng-container *ngIf="queryForm | mapper: getQueryFromLibrary | obsWithStatus | async | mapper: buildPreviewForm as vm">
            <ng-container *ngIf="!vm.loading && vm.value; else loader">
                <ng-template
                    [ngTemplateOutlet]="queryControls"
                    [ngTemplateOutletContext]="{ $implicit: vm.value, readonly: true }"
                ></ng-template>
            </ng-container>
            <ng-template #loader>
                <lib-spinner class="d-flex justify-content-center p-4"></lib-spinner>
            </ng-template>
        </ng-container>
    </ng-container>
    <ng-template #defaultTemplate>
        <ng-template [ngTemplateOutlet]="queryControls" [ngTemplateOutletContext]="{ $implicit: queryForm, readonly: false }"></ng-template>
    </ng-template>
</ng-container>

<ng-template #queryControls let-formGroup let-readonly="readonly">
    <ng-container [formGroup]="formGroup">
        <div class="row mb-3">
            <lib-input *ngIf="!filterQuery" class="col" label="Placeholder" [formControl]="placeholderControl"></lib-input>
            <lib-dropdown
                *ngIf="facade.dataSources$ | obsWithStatus | async as obs"
                [setDisable]="readonly"
                data-test-id="data-source"
                class="col"
                label="Data Source"
                placeholder="Select data source"
                formControlName="type"
                [loading]="obs.loading"
                [items]="obs.value"
            ></lib-dropdown>
        </div>
        <lib-codemirror-editor
            class="mb-3"
            data-test-id="before-script"
            label="Before script"
            [readonly]="readonly"
            [needRefresh]="true"
            [mode]="scriptType"
            formControlName="beforeScript"
        ></lib-codemirror-editor>
        <lib-codemirror-editor
            class="mb-3"
            data-test-id="data-source-script"
            label="DataSource Script"
            [readonly]="readonly"
            [needRefresh]="true"
            mode="text/x-sql"
            formControlName="query"
        ></lib-codemirror-editor>
        <lib-codemirror-editor
            class="mb-3"
            data-test-id="after-script"
            label="After script"
            [readonly]="readonly"
            [needRefresh]="true"
            [mode]="scriptType"
            formControlName="script"
        ></lib-codemirror-editor>
    </ng-container>
</ng-template>

<ng-template #commonPart>
    <p>You can use {{ language }} code to process the data that will come from the SQL script:</p>
    <ul>
        <li><span class="font-weight-bold">Placeholder</span>: Enter a required placeholder name to store the SQL script query results.</li>
        <li><span class="font-weight-bold">Before Script</span>: Write the {{ language }} code to execute before the SQL script.</li>
        <li><span class="font-weight-bold">SQL Script</span>: Write a script to create the widget data.</li>
        <li><span class="font-weight-bold">After Script</span>: Write the {{ language }} code to process the data after the SQL script.</li>
    </ul>
</ng-template>

<ng-template [helpForTemplateQuery]="[type.BAR_CHART, type.LINE_CHART, type.PIE_CHART, type.DOUGHNUT_CHART]">
    <ng-template [ngTemplateOutlet]="commonPart"></ng-template>
    For Line, Bar, Pie and Doughnut charts you should have in your result set ‘label’ and ‘value’ columns.<br />
    For example:<br />
    select repoId as label, sum(total) as total, sum(additions) - sum(deletions) as value'
</ng-template>

<ng-template [helpForTemplateQuery]="[type.TABLE]">
    <ng-template [ngTemplateOutlet]="commonPart"></ng-template>
    For Grid chart you can write query in format "select * from table", when table columns are set in Columns section in Layer settings.
</ng-template>

<ng-template [helpForTemplateQuery]="[type.TWO_DIMENSIONAL_TABLE]">
    <ng-template [ngTemplateOutlet]="commonPart"></ng-template>
    For Two Dimensional Grid chart you should to write correct sql query and set fields "Vertical Header" "Column Header"<br />
    and "Grid Value" in Layer settings.
</ng-template>

<ng-template [helpForTemplateQuery]="[type.METRIC_TILE]">
    <ng-template [ngTemplateOutlet]="commonPart"></ng-template>

    <p><span class="font-weight-bold">Tip:</span> To write the correct SQL script, follow the contract on column names.</p>
    <p>For a Metric Tile chart, you should have the following in your result set:</p>
    <ol>
        <li>
            <code>tile</code> - object with the following fields:
            <ul>
                <li><code>value</code> – used for the value of the tile</li>
                <li><code>additionalText</code> – used when some additional text is needed</li>
                <li><code>postfix</code> – used for tile value units</li>
                <li><code>delta</code> - value of delta unit</li>
                <li><code>deltaInversed</code> - flag setting inverse color of delta arrow</li>
                <li><code>deltaIconInversed</code> - flag setting inverse direction of delta arrow</li>
            </ul>
        </li>
        <li><code>details</code> - array of label and value pairs</li>
        <li>
            <p class="mb-0">You can add custom tooltip for each details item and per the tile. Add a tooltip field to do this.</p>
            <code>tooltip</code> - object with the following fields:
            <ul>
                <li><code>title: string</code></li>
                <li><code>items</code> - array of objects with the following fields:</li>
                <ul>
                    <li><code>label: string;</code></li>
                    <li><code>value: string | number;</code></li>
                    <li><code>color</code> - valid html color;</li>
                </ul>
            </ul>
        </li>
    </ol>
</ng-template>

<ng-template #defaultHelp>
    <ng-template [ngTemplateOutlet]="commonPart"></ng-template>
    For Sunburst, Stacked Bar Chart, Tree map and Nested Pie it must have label1, label2, …, labelN, value columns.<br />
    For example: <br />
    select buildState as label1, environment as label2, COUNT(*) AS value
</ng-template>
