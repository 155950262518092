import { Component, Input } from '@angular/core';
import { QueryBuilderStore } from '@app/shared/components/query-builder/store/query-builder.store';
import { Model } from '@app/shared/components/query-builder/models/model-graph.model';
import { QB_ICON_URLS } from '@app/shared/components/query-builder/consts/qb-icon-urls.const';

@Component({
    selector: 'app-qb-visualization-filter-item',
    templateUrl: './filter-item.component.html',
    styleUrls: ['./filter-item.component.scss'],
})
export class FilterItemComponent {
    @Input() name: string;
    @Input() iconUrl: string;

    icons = QB_ICON_URLS;

    get attachedFilters(): Model[] {
        const filtersAttached = this.queryBuilderStore.modelGraph.filtersAttached;
        if (!filtersAttached) {
            return [];
        }
        return this.queryBuilderStore.modelGraph.filters.filter(x => filtersAttached.map(y => y.filterModelUid).includes(x.uid));
    }

    constructor(private queryBuilderStore: QueryBuilderStore) {}
}
