import {
    ApplicationRef, Attribute, ChangeDetectorRef, ComponentFactoryResolver,
    Directive,
    ElementRef,
    Host,
    HostBinding, Inject,
    Injector,
    Input, NgZone,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewContainerRef,
} from '@angular/core';
import { NgbPopover, NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import withinviewport from 'withinviewport';
import { DOCUMENT } from '@angular/common';

import { StepOption, TourService, TourStepService } from './tour.service';


// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[tourAnchor]' })
export class TourAnchorPopoverDirective extends NgbPopover {
    constructor(private elRef: ElementRef<HTMLElement>,
        renderer: Renderer2,
        injector: Injector,
        viewContainerRef: ViewContainerRef,
        resolver: ComponentFactoryResolver,
        config: NgbPopoverConfig,
        ngZone: NgZone,
        @Inject(DOCUMENT) private document: any,
        cdRef: ChangeDetectorRef,
        applicationRef: ApplicationRef,
        @Attribute('hostQuerySelector') public hostQuerySelector: string) {

        super(elRef, renderer, injector, resolver, viewContainerRef, config, ngZone, document, cdRef, applicationRef);

    }

    open(context?: any) {
        if (this.hostQuerySelector) {
            const hostEl = this.elRef.nativeElement.querySelector<HTMLElement>(this.hostQuerySelector);
            if (hostEl) {
                (this as any)._elementRef = new ElementRef(hostEl);
            }
        }

        super.open(context);
    }
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[tourAnchor]',
})
export class TourAnchorDirective implements OnInit, OnDestroy {
    @Input() tourAnchor: string;

    @HostBinding('class.touranchor--is-active')
    isActive: boolean;

    private destroyed$ = new Subject<void>();

    private helperLayer: HTMLDivElement;

    constructor(
        private tourService: TourService,
        private tourStepTemplate: TourStepService,
        private elRef: ElementRef<HTMLElement>,
        @Host() private popoverDirective: TourAnchorPopoverDirective
    ) {
        this.popoverDirective.triggers = 'manual';
        this.popoverDirective.autoClose = false;
        this.popoverDirective.popoverClass = 'tour-popover';
    }

    ngOnInit(): void {
        this.tourService.register(this.tourAnchor, this);
    }

    showTourStep(step: StepOption): void {
        this.isActive = true;
        this.popoverDirective.ngbPopover = this.tourStepTemplate.template;
        this.popoverDirective.container = 'body';
        this.popoverDirective.placement =
            step.placement ||
            'auto'
                .replace('before', 'left')
                .replace('after', 'right')
                .replace('below', 'bottom')
                .replace('above', 'top');
        step.prevBtnTitle = step.prevBtnTitle || 'Previous';
        step.nextBtnTitle = step.nextBtnTitle || 'Next';
        step.endBtnTitle = step.endBtnTitle || 'End Tour';
        step.pauseBtnTitle = step.pauseBtnTitle || 'Pause';
        this.popoverDirective.popoverClass = 'tour-element-wrapper';

        this.popoverDirective.open({ step });

        if (this.popoverDirective.hostQuerySelector) {
            const host = this.elRef.nativeElement.querySelector<HTMLElement>(this.popoverDirective.hostQuerySelector);
            if (host) {
                this.elRef = new ElementRef(host);
            }
        }

        const el = this.elRef.nativeElement;
        this.addHelperLayer();

        if (step.preventScrolling) {
            return;
        }

        if (!withinviewport(el, { sides: 'bottom' })) {
            this.popoverDirective.placement = 'top';
            this.popoverDirective.open({ step });
            el.scrollIntoView(true);
        } else if (!withinviewport(el, { sides: 'left top right' })) {
            el.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
        }
    }

    hideTourStep(): void {
        this.isActive = false;
        this.popoverDirective.close();
        this.removeHelperLayer();
    }

    private addHelperLayer() {
        const el = this.elRef.nativeElement;
        el.classList.add('tour-element--show');
        const { top, left, width, height } = el.getBoundingClientRect();
        this.helperLayer = document.createElement('div');
        this.helperLayer.style.cssText = `position: absolute; top: ${top + window.pageYOffset}px; left:${left +
            window.pageXOffset}px; width: ${width}px; height: ${height}px; z-index: 999; background: rgba(255,255,255,.9);`;
        document.body.appendChild(this.helperLayer);
    }

    private removeHelperLayer() {
        if (this.helperLayer) {
            this.elRef.nativeElement.classList.remove('tour-element--show');
            this.helperLayer.parentNode.removeChild(this.helperLayer);
            this.helperLayer = null;
        }
    }

    ngOnDestroy(): void {
        this.tourService.unregister(this.tourAnchor);
        this.destroyed$.next();
        this.removeHelperLayer();
    }
}
