import { APP_BOOTSTRAP_LISTENER, ErrorHandler, Inject, NgModule, NgZone } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { UiKitModule } from '@dagility-ui/kit';

import { ProcessorMonitoringConfig } from 'data-processor';
import { HEADER_ICONS } from '@dagility-ui/shared-components/icons';
import { TourModule } from '@dagility-ui/shared-components/modules/tour/tour.module';
import { HelpSupportModule } from '@dagility-ui/shared-components/utils/help-support/help-support.module';
import { IframeWrapperModule } from '@app/shared/components/iframe-wrapper/iframe-wrapper.module';
import { PopupService } from '@dagility-ui/shared-components/services/popup-service/popup-service';
import { AuthInterceptor } from '@app/core/http-interceptors/auth-interceptor';
import { DateAdapter } from '@dagility-ui/shared-components/services/date-adapter';
import { DATA_MORPH_AUTH, DATA_MORPH_FEATURE_TOGGLE, SERVICE_HEALTH_STATUS } from 'data-processor/tokens';
import { FEATURE_TOGGLE_SYNC_KEYS, FeatureToggleService } from '@app/services';
import { APPLICATION_NAME, ENV_TOKEN } from '@app/tokens';
import { VocFormModule } from '@app/shared/modules/voc-form/voc-form.module';
import { JenkinsJobsListener } from '@app/shared/services/admin/jenkins-jobs.listener';
import { HealthService } from '@app/shared/services/admin/health-service';
import { AuthService } from '@app/auth';
import { XpressoComponent } from './pages/xpresso/xpresso.component';
import { CalibrateComponent } from './pages/calibrate/calibrate.component';
import { NoskriptComponent } from './pages/noskript/noskript.component';
import { McmComponent } from './pages/mcm/mcm.component';
import { initSentry, SentryErrorHandler } from './sentry';
import { WorkspaceComponent } from './pages/workspace/workspace.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { ServerErrorComponent } from './pages/server-error/server-error.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { AnnouncementModule } from './shared/modules/announcement/announcement.module';
import { ResolutionChecker } from '@app/core/services/resolution-checker';
import { DEFAULT_THEME } from './pages/user/user.themes';

const processorMonitoringConfig: ProcessorMonitoringConfig = {
    dataProcessorStateTopic: 'DPStateTopic',
};

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
};

@NgModule({
    declarations: [
        AppComponent,
        ServerErrorComponent,
        AccessDeniedComponent,
        NotFoundComponent,
        WorkspaceComponent,
        CalibrateComponent,
        XpressoComponent,
        NoskriptComponent,
        McmComponent,
    ],
    imports: [
        AnnouncementModule,
        BrowserModule,
        BrowserAnimationsModule,
        CoreModule,
        NgHttpLoaderModule.forRoot(),
        ToastrModule.forRoot(),
        AppRoutingModule,
        TourModule,
        UiKitModule,
        IframeWrapperModule,
        HelpSupportModule.forRoot(AuthInterceptor),
        VocFormModule,
    ],
    providers: [
        {
            provide: ToastrService,
            useClass: PopupService,
        },
        {
            provide: ErrorHandler,
            useClass: SentryErrorHandler,
        },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
        {
            provide: APP_BOOTSTRAP_LISTENER,
            multi: true,
            useFactory: (env: Env) => () => initSentry(env),
            deps: [ENV_TOKEN],
        },
        { provide: 'templateProvider', useValue: {} },
        { provide: 'processorMonitoringConfig', useValue: processorMonitoringConfig },
        { provide: DATA_MORPH_FEATURE_TOGGLE, useExisting: FeatureToggleService },
        { provide: DATA_MORPH_AUTH, useExisting: AuthService },
        { provide: SERVICE_HEALTH_STATUS, useExisting: HealthService },
        { provide: 'featureToggleService', useExisting: FeatureToggleService },
        DateAdapter,
        {
            provide: APPLICATION_NAME,
            useValue: 'UST PACE',
        },
        {
            provide: FEATURE_TOGGLE_SYNC_KEYS,
            useValue: ['query_library', 'eo_49306', 'eo_48475', 'eo_51403', 'eo_52316', 'eo_52852', 'eo_52955', 'eo_52856', 'eo_54340'],
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(
        iconLibrary: FaIconLibrary,
        private router: Router,
        private jenkinsListener: JenkinsJobsListener,
        private resolutionChecker: ResolutionChecker,
        @Inject(ENV_TOKEN) private env: Env,
        private ngZone: NgZone,
        private dateAdapter: DateAdapter
    ) {
        document.documentElement.dataset.theme = DEFAULT_THEME;
        window.setTimezone = this.dateAdapter.setTimezone;
        window.getTimezone = this.dateAdapter.getTimezone;
        iconLibrary.addIcons(...HEADER_ICONS);
        this.handleLoadingForLazyLoadedModules();
        this.jenkinsListener.listenCiCdDpTaskPipelineTopic();
        this.establishIframeCommunicationChannel();
    }

    private handleLoadingForLazyLoadedModules() {
        this.router.events.subscribe(event => {
            if (event instanceof RouteConfigLoadStart) {
                document.body.classList.add('lazy-loading');
            } else if (event instanceof RouteConfigLoadEnd) {
                setTimeout(() => {
                    document.body.classList.remove('lazy-loading');
                }, 300);
            }
        });
    }

    private establishIframeCommunicationChannel() {
        const origins = this.env.onMessageOrigin.split(',');
        this.ngZone.runOutsideAngular(() => {
            // sonar doesn't recognize condition for origin taken from env variable so we have to disable sonar here. DO NOT move any comment
            // NOSONAR
            window.addEventListener('message', e => { // NOSONAR
                // NOSONAR
                if (!origins.includes(e.origin) && this.env.onMessageOrigin !== '*') {
                    return;
                }
                if (e.data && e.data.type === 'navigate') {
                    this.ngZone.run(() => this.router.navigateByUrl(e.data.destination, e.data.state ? { state: e.data.state } : null));
                }
            });
        });
    }
}
