import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter, shareReplay } from 'rxjs/operators';

@Injectable()
export class DpGlobalFiltersService {
    defaultFiltersValues: Record<string, any> = {};
    phf: Record<string, any> = {};
    globalFilters$ = new BehaviorSubject<Record<string, any>>(null);
    filters$: Observable<Record<string, any>> = this.globalFilters$.pipe(filter(Boolean), shareReplay(1));
}
