import { Component, Inject, Input, OnInit } from '@angular/core';
import { LogTemplateComponent } from 'data-processor/lib/widget-library/widget-builder/components/widget/log-template/log-template.component';
import { HttpClient } from '@angular/common/http';
import { EnvironmentModel, ModalService } from '@dagility-ui/kit';

@Component({
    selector: 'dp-status-changed-details',
    templateUrl: './status-changed-details.component.html',
    styleUrls: ['./status-changed-details.component.scss'],
})
export class IssueLifeCycleStatusChangedDetailsComponent implements OnInit {
    @Input() actions: any;
    @Input() statuses: any[];
    @Input() toolImages: Record<string, any> = {};
    statusMap = new Map();

    constructor(@Inject('environment') private env: EnvironmentModel, private modalService: ModalService, private http: HttpClient) {}

    ngOnInit() {
        (this.statuses || []).forEach(status => {
            this.statusMap.set(status.value.toLowerCase(), status);
        });
    }

    viewLogs(build: string, logLink: string) {
        this.modalService.open(LogTemplateComponent, {
            centered: true,
            windowClass: '',
            size: 'lg',
        }, {
            logs$: this.http.get(`${this.env.serviceUrl}${logLink}`),
            header: `<h2 class="ml-25px mb-7px">Build #${build} Logs</h2>`
        });
    }
}
