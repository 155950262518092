import { Component, Input } from '@angular/core';

@Component({
    selector: 'dp-radial-template',
    templateUrl: './radial-template.component.html',
    styleUrls: ['./radial-template.component.scss'],
})
export class RadialTemplateComponent {
    @Input() options: any;
}
