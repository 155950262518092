<fa-icon
    *ngIf="item.preferred"
    data-test-id="preferred-icon"
    [ngbTooltip]="preferredItemTooltip"
    tooltipClass="preferred-item-tooltip"
    class="ml-2 color-brand body1"
    container="body"
    icon="facInfoCircle"
></fa-icon>

<ng-template #preferredItemTooltip>
    <div class="font-weight-normal">
        This value is updated automatically
    </div>
</ng-template>
