import { NgControl, Validators } from '@angular/forms';
import { ChangeDetectorRef, Directive, inject, InjectionToken, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { debounceValidator, InputComponent, noLeadingTrailingSpaces, notEmptyString } from '@dagility-ui/kit';

export const ENTITY_NAME = new InjectionToken<string>('');

const NAME_BOUNDS = {
    min: 1,
    max: 50,
};

const DEBOUNCE_TIME = 500;

@Directive({
    selector: 'lib-input[formControlName][entityNameControl]',
    standalone: true,
})
export class EntityNameDirective implements OnInit {
    private control = inject(NgControl, { self: true });
    private cdr = inject(ChangeDetectorRef);
    private entityName = inject(ENTITY_NAME);
    private input = inject(InputComponent);

    ngOnInit() {
        const { control } = this.control;

        control.addValidators([Validators.required, notEmptyString(), Validators.maxLength(NAME_BOUNDS.max)]);
        control.addAsyncValidators([
            control =>
                debounceValidator(control => {
                    const trailingSpacesValidator = noLeadingTrailingSpaces(`${this.entityName} name`);

                    return of(trailingSpacesValidator(control)).pipe(tap(() => this.cdr.markForCheck()));
                }, DEBOUNCE_TIME)(control),
        ]);

        control.updateValueAndValidity();

        this.input.maxlength = NAME_BOUNDS.max.toString();
        this.input.placeholder = `Must be between ${NAME_BOUNDS.min} - ${NAME_BOUNDS.max} characters`;
        this.cdr.markForCheck();
    }
}
