import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

import { DisplayType } from '../../../models/any-widget.model';
import { WidgetBuilderFacade } from '../../../state/widget-builder.facade';
import { BlockForm } from '../widget-builder-block-form/widget-builder-block-form.component';
import { DropdownItem } from '@dagility-ui/kit';

@Component({
    selector: 'dp-widget-builder-drilldown',
    templateUrl: './widget-builder-drilldown.component.html',
    providers: [{ provide: BlockForm, useExisting: WidgetBuilderDrilldownComponent }],
})
export class WidgetBuilderDrilldownComponent extends BlockForm {
    @Input() set data(drilldown: any) {
        this.form = this.facade.buildDrilldownForm(drilldown);
    }

    @ViewChild('help') help: TemplateRef<unknown>;

    displayType: DropdownItem<DisplayType>[] = [
        {
            value: DisplayType.MODAL,
            label: 'Modal',
        },
        {
            value: DisplayType.STACK,
            label: 'Stack',
        },
    ];

    constructor(private facade: WidgetBuilderFacade) {
        super();
    }

    handleDefaultChange(isDefault: boolean) {
        if (!isDefault) {
            return;
        }

        this.form.get('target').setValue('');
        this.form.get('matcherScript').setValue('');
    }

    getHelpTemplate = (layer: any): TemplateRef<unknown> => {
        return this.help;
    };
}
