<div class="modal-header">
    <h2 class="modal-title mt-0">Details</h2>
    <button type="button" class="close" data-test-id="cross-tick-btn" aria-describedby="modal-title" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body d-flex flex-column overflow-auto">
    <perfect-scrollbar class="pr-3 scroll-container">
        <p class="info-box p-2">
            <fa-icon icon="facExclamationCircle"></fa-icon>
            The following table shows the status and details of published repository changes.
        </p>
        <lib-data-grid
            [dataSource]="details$ | async"
            [sticky]="true"
            [usePerfectScrollbar]="true"
            [columns]="gridColumns"
            class="data-grid"
        >
            <ng-template headerTemplate>
                <tr class="table-border">
                    <th
                        *ngFor="let c of gridColumns; let i = index"
                        [ngStyle]="{ width: c.width, 'min-width': c.minWidth }"
                        id="c"
                        (click)="sortClick(i)"
                        class="over-me"
                    >
                        <div
                            [class.blue]="c.sort"
                            [class.cursor-pointer]="c.sortingField"
                            [class.cursor-default]="!c.sortingField"
                            class="text-truncate body2 d-inline-flex align-items-center column-header"
                            [ngbTooltip]="c.title"
                            container="body"
                        >
                            {{ c.title }}
                            <span
                                *ngIf="c.sortingField"
                                [innerHTML]="(c.sort !== 'ASC' ? icons.sortingUp : icons.sortingDown) | safeHtml: 'html'"
                                class="fa sort-icon ml-5px"
                            >
                            </span>
                        </div>
                    </th>
                </tr>
            </ng-template>

            <ng-template rowTemplate let-sourceItem="item">
                <td *ngFor="let column of gridColumns" [ngStyle]="{ width: column.width, 'min-width': column.minWidth }">
                    <span class="field-{{ column.field }}-{{ sourceItem.status }}">{{ sourceItem[column.field] }}</span>
                </td>
            </ng-template>
        </lib-data-grid>
    </perfect-scrollbar>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary mr-3" data-test-id="cancel-btn" (click)="activeModal.dismiss()">Cancel</button>
    <button type="button" class="btn btn-primary" *ngIf="componentsToRepublish.length" (click)="retryAll()">
        <fa-icon icon="rotationLeft" class="mr-2"></fa-icon>
        Retry All
    </button>
</div>
