<ng-container *ngIf="form" [formGroup]="form">
    <ng-container *ngIf="!form.value.default">
        <lib-codemirror-editor
            class="d-block mb-3"
            label="Matcher Script"
            needRefresh="true"
            mode="text/javascript"
            formControlName="matcherScript"
        ></lib-codemirror-editor>

        <lib-input class="d-block mb-3" label="Target" placeholder="Select target" formControlName="target"></lib-input>
    </ng-container>
    <checkbox class="d-block mb-3" (change)="handleDefaultChange($event)" formControlName="default">Default drilldown</checkbox>
    <lib-dropdown
        class="d-block mb-3"
        label="Display Type"
        placeholder="Select widget type"
        formControlName="displayType"
        [items]="displayType"
    ></lib-dropdown>
    <lib-codemirror-editor
        label="Drilldown Script"
        needRefresh="true"
        mode="text/javascript"
        formControlName="drilldownScript"
    ></lib-codemirror-editor>
</ng-container>

<ng-template #help>
    <span
        >A drilldown to run is picked up from the provided set of drilldowns trying them one-by-one from left to right by the following
        logic:
    </span>
    <ol>
        <li>
            If a Matcher script is provided: drilldown that returns true is picked up. Script parameters are: <i>(event, placeholders)</i>
        </li>
        <li>Otherwise a drilldown is picked up based on the "Target" field. It is compared with the clicked bar or column name</li>
        <li>In case none of presented drill-down match the default drilldown is used</li>
    </ol>
</ng-template>
