import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';

@Component({
    selector: 'app-icon-remoter',
    templateUrl: './icon-remoter.component.html',
    styleUrls: ['./icon-remoter.component.scss'],
})
export class IconRemoterComponent implements OnInit {
    @Input() width: number = 10;
    @Input() height: number = 10;
    @Input() angleRotate: number = 0;
    @Input() iconName: AllowedIcons;
    @Output() click = new EventEmitter<any>();
    classColor: string;
    widthStr: string;
    heightStr: string;
    transformRotateStr: string;
    @Input() private color: 'BRAND' | 'ERROR' | 'WARNING' | 'WHITE' | 'SECONDARY-TEXT-ICONS' | 'DISABLED' = 'BRAND';
    @ViewChild(TemplateRef, {static: true}) private iconHandle: HTMLElement;

    ngOnInit(): void {
        switch (this.color) {
            case 'ERROR':
                this.classColor = 'error-base-color';
                break;
            case 'WARNING':
                this.classColor = 'warning-base-color';
                break;
            case 'BRAND':
                this.classColor = 'brand-base-color';
                break;
            case 'WHITE':
                this.classColor = 'white-color';
                break;
            case 'SECONDARY-TEXT-ICONS':
                this.classColor = 'secondary-text-and-icons-color';
                break;
            case 'DISABLED':
                this.classColor = 'disabled-color';
        }
        this.widthStr = `${this.width}px`;
        this.heightStr = `${this.height}px`;
        this.transformRotateStr = `rotate(${this.angleRotate}deg)`;
    }
}

export type AllowedIcons =
    | 'LABEL'
    | 'CATEGORY'
    | 'NAME'
    | 'VALUE'
    | 'KEY'
    | 'COLUMNS'
    | 'TIME'
    | 'START-DATE'
    | 'END-DATE'
    | 'ERROR'
    | 'TABLE'
    | 'CHEVRON'
    | 'DRAGDROP'
    | 'Y-AXIS'
    | 'X-AXIS'
    | 'RELATIONS'
    | 'FIELD'
    | 'EDIT'
    | 'CLOSE'
    | 'RESET'
    | 'DELETE'
    | 'FILTER'
    | 'USER'
    | 'GLOBAL-ERROR';
