import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpHeaders } from '@angular/common/http';
import { BasicHttpClient } from '@dagility-ui/shared-components/utils/help-support/basic-http-client';

const headers = new HttpHeaders({
    'skip-global-error': 'true',
});
const options = { headers };
@Injectable({
    providedIn: 'root',
})
export class ContextHelpService extends BasicHttpClient {
    helpUrl = `/jsonapi/node/contextual_help?include=field_page_key.vid`;

    formatContextHelpData(response: any) {
        const formattedData: any[] = [];
        response.data.forEach((element: any) => {
            const elementData: Record<string, any> = {};
            elementData['title'] = element.attributes.title;
            if (element.attributes.field_anchor_id) {
                elementData['anchorId'] = element.attributes.field_anchor_id;
            }
            if (element.attributes.field_content) {
                elementData['content'] = element.attributes.field_content.processed;
            }
            formattedData.push(elementData);
        });

        return formattedData;
    }

    getContextHelpData(): Observable<any[]> {
        return this.http.get<any[]>(`${this.API_URL}${this.helpUrl}`, options);
    }

    getContextHelpPaginatedData(url: any) {
        return this.http.get<any[]>(url, options);
    }
}
