import { SourceFolderItem } from '../models/application-source.model';
import { sortObjectArrayByKey } from '@dagility-ui/kit';

export const APP_SOURCE_MAX_FILE_SIZE = 50_000_000;
export const APP_SOURCE_MAX_PROJECT_ZIP_FILE_SIZE = 500_000_000;

export const fileNameRE = new RegExp(
    `^jenkinsfile$|\\.(txt|xml|html|xhtml|java|ts|js|json|scss|css|sass|sql|ini|iml|yml|feature|properties|bat|kt|groovy|cs|csproj|sln|cpp|cmd|md|config|gitignore)$`
);
export const textTypeRE = new RegExp(`^text\/.+$`);
export const applicationTypeRE = new RegExp(`^application\/(xml|html|json|javascript|x\-sh)$`);

export function sortFolderFilesByKey(
    folderItems: SourceFolderItem[],
    filesSortingField: string,
    filesSortAsc: boolean
): SourceFolderItem[] {
    let currentSourceFolder = [];
    if (folderItems) {
        filesSortingField = filesSortingField ? filesSortingField : 'name';
        const folders = folderItems.filter(el => el.isFolder);
        const files = folderItems.filter(el => !el.isFolder);
        sortObjectArrayByKey(folders, filesSortingField, filesSortAsc);
        sortObjectArrayByKey(files, filesSortingField, filesSortAsc);
        currentSourceFolder = [];
        currentSourceFolder.push(...folders);
        currentSourceFolder.push(...files);
    }
    return currentSourceFolder;
}

export function checkFileFormat(source: SourceFolderItem): boolean {
    let contentType = (source.contentType || '').toLowerCase();
    let fileName = (source.name || '').toLowerCase();
    return (
        fileNameRE.test(fileName) ||
        textTypeRE.test(contentType) ||
        applicationTypeRE.test(contentType)
    );
}

export function getZipName(name: string): string {
    return name
        .replace(/,/g, '-')
        .replace(/\./g, '-')
        .replace(/\s+/g, '-')
        .toLowerCase();
}
