import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Model } from '@app/shared/components/query-builder/models/model-graph.model';
import { Observable } from 'rxjs';
import { ENV_TOKEN } from '@app/tokens';

@Injectable({
    providedIn: 'root',
})
export class ModelService {
    private readonly baseUrl = `${this.env.querybuilderApiURL}/qb/model`;

    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {}

    getModels(): Observable<Model[]> {
        return this.http.get<Model[]>(`${this.baseUrl}`);
    }

    getModel(id: string): Observable<Model[]> {
        return this.http.get<Model[]>(`${this.baseUrl}/${id}`);
    }

    createModel(model: Model): Observable<Model[]> {
        return this.http.post<Model[]>(`${this.baseUrl}`, model);
    }

    updateModel(model: Model, id: string): Observable<Model> {
        return this.http.put<Model>(`${this.baseUrl}/${id}`, model);
    }

    deleteModel(id: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${id}`);
    }

    syncModels(toolId: string, schemaName: string): Observable<void> {
        const params = new HttpParams().set('tool', toolId).set('schema', schemaName);
        return this.http.post<void>(`${this.baseUrl}/sync`, {}, { params });
    }
}
