import { Component, ContentChild, Input, Output, EventEmitter, HostListener, ViewChild, ElementRef, TrackByFunction } from '@angular/core';
import { coerceNumberProperty } from '@angular/cdk/coercion';

import { SvgBlockDirective } from '../../directives/svg-block.directive';
import { SvgEdgeDirective } from '../../directives/svg-edge.directive';
import { SvgZoomDirective } from '../../../../directives/svg-zoom.directive';
import { SVGEdge } from '../../models';
import { SVGBlock } from '../../models';
import { DropEvent } from '../../models';

@Component({
    selector: 'lib-svg-builder',
    templateUrl: './svg-builder.component.html',
    styleUrls: ['./svg-builder.component.scss'],
    host: {
        class: 'svg-builder'
    }
})
export class SvgBuilderComponent<E extends SVGEdge, B extends SVGBlock> {
    @Input() blocks: B[];

    @Input() edges: E[];

    @Input() readonly: boolean;

    @Input() set zoomShiftX(shift: string | number) {
        this._zoomShiftX = coerceNumberProperty(shift, 0);
    }
    get zoomShiftX() {
        return this._zoomShiftX;
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
    private _zoomShiftX = 0;

    @Input() set zoomShiftY(shift: string | number) {
        this._zoomShiftY = coerceNumberProperty(shift, 0);
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _zoomShiftY = 0;
    get zoomShiftY() {
        return this._zoomShiftY;
    }

    @Input() zoomViewMode = false;

    @Input() zoomDisabled = false;

    @Input() activeBlock: B;

    @Input() activeEdgeId: string;

    @Output() dropped = new EventEmitter<DropEvent<B>>();

    @Output() deleteBlock = new EventEmitter<B>();

    @Output() activeBlockChange = new EventEmitter<B | null>();

    @Output() activeEdgeChange = new EventEmitter<E | null>();

    @ContentChild(SvgBlockDirective, { static: true }) blockTemplate: SvgBlockDirective<B>;

    @ContentChild(SvgEdgeDirective, { static: true }) edgeTemplate: SvgEdgeDirective<E> | null;

    @ViewChild(SvgZoomDirective, { static: true }) zoom: SvgZoomDirective;

    @ViewChild('svg', { static: true }) svg: ElementRef<SVGSVGElement>;

    @HostListener('document:keyup', ['$event'])
    handleKeyPress(event: KeyboardEvent) {
        if (this.activeBlock && event.key === 'Delete') {
            this.deleteBlock.emit(this.activeBlock);
        }
    }

    trackByBlock: TrackByFunction<B> = (i: number, block: B) => {
        if (this.blockTemplate.trackBy) {
            return this.blockTemplate.trackBy(i, block);
        }

        return block;
    };

    trackByEdge: TrackByFunction<E> = (i: number, edge: E) => {
        if (this.edgeTemplate && this.edgeTemplate.trackBy) {
            return this.edgeTemplate.trackBy(i, edge);
        }

        return edge;
    };

    handleBlockClick(block: any, event: Event) {
        if (!this.readonly) {
            this.activeBlockChange.emit(this.activeBlock && block.id === this.activeBlock.id ? null : block);
            event.stopPropagation();
        }
    }
}
