import { Injectable, TemplateRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, merge, Observable, Subject } from 'rxjs';

import { DropdownItem, RbacAction, RbacStaticType, sortObjectArrayByKey } from '@dagility-ui/kit';
import {
    CodeScanTool,
    ComponentCodeScanTool,
    ComponentDetails,
    ComponentScmTool,
    ProjectComponent,
    ScmTool,
} from './models/project-component-items';
import { finalize, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { ProjectComponentsService } from './project-components.service';
import { StompService } from '@app/shared/services/admin/stomp.service';
import {
    ProgressBackgroundWidgetService,
    ProgressWidgetHandler,
} from '../shared/progress-background-widget/progress-background-widget.service';
import { ILogItem } from '../project-magic-in-progress/do-the-magic-form/do-the-magic.model';
import { ProjectRightsService } from '../../project-rights.service';
import { CiCdPluginService } from '../../../ci-cd/api/ci-cd-plugin.service';
import { AutoCompletionService } from '../../../ci-cd/api/auto-completion.service';
import { CompletionSourceItem, ToasterService } from '@dagility-ui/shared-components';
import { AuthService } from '@app/auth';
import { HealthService } from '@app/shared/services/admin/health-service';

@Injectable()
export class ProjectComponentsFacade {
    activeComponent: ProjectComponent;

    form: FormGroup;
    addComponentForm: FormGroup;

    componentDetails$: Observable<ComponentDetails>;
    componentCodeScan$: Observable<Array<CodeScanTool>>;
    componentSCM$: Observable<Array<ScmTool>>;

    updateComponent$ = new BehaviorSubject(false);
    updateComponentCodeScan$ = new BehaviorSubject(false);
    updateComponentSCM$ = new BehaviorSubject(false);

    components: Array<ProjectComponent> = [];
    components$ = new BehaviorSubject<ProjectComponent[]>([]);
    codeScanTools: Array<ComponentCodeScanTool> = [];
    scmTools: Array<ComponentScmTool> = [];
    pluginServices: DropdownItem[] = [];

    projectId: number;
    defaultValue: any;
    addNewButtonDisabled = false;
    topic: string;

    sourceCodeGenerationWidget: ProgressWidgetHandler;
    processStartedTime: Date = null;
    log$: BehaviorSubject<ILogItem[]> = new BehaviorSubject([]);
    actionCompleted = false;
    isConveyorAlive: boolean = false;

    autoCompletionId: string;
    autoCompletion: CompletionSourceItem[] = [];
    autoCompletionEnabled: boolean = false;

    canModifyPipeline: boolean;

    private destroyed$ = new Subject();

    private initTabs$ = new Subject();

    constructor(
        private fb: FormBuilder,
        private toaster: ToasterService,
        private componentsService: ProjectComponentsService,
        private autoCompletionService: AutoCompletionService,
        private stompService: StompService,
        private widgetService: ProgressBackgroundWidgetService,
        private sanitizer: DomSanitizer,
        private pluginService: CiCdPluginService,
        public projectRights: ProjectRightsService,
        private authService: AuthService,
        private healthService: HealthService
    ) {
        this.healthService.getIsServiceAlive('conveyor').subscribe(value => {
            this.isConveyorAlive = value;
            if (value) {
                this.checkPermissionsForAutoComplete();
            }
        });
        this.hasRightForPipeline('modify').subscribe(value => (this.canModifyPipeline = value));
    }

    private checkPermissionsForAutoComplete() {
        this.authService
            .hasPermission(RbacStaticType.OBJECT, 'ProjectTemplate', 'modify')
            .subscribe(value => (this.autoCompletionEnabled = value));
    }

    initForm(formObject: any): void {
        this.defaultValue = formObject;
        this.setForm(formObject);
    }

    hasRightForPipeline(action: RbacAction): Observable<boolean> {
        return this.authService.hasPermission(RbacStaticType.OBJECT, 'Pipeline', action);
    }

    setForm(formObject: any): void {
        this.form = this.fb.group({
            details: this.fb.group({
                name: [{ value: formObject.name, disabled: !this.projectRights.canModify }, [Validators.required]],
                code: [
                    { value: formObject.code, disabled: !this.projectRights.canModify },
                    [Validators.required, Validators.pattern(`^[a-z0-9]([-a-z0-9]*[a-z0-9])?$`)],
                ],
                description: [
                    {
                        value: formObject.description ? formObject.description : '',
                        disabled: !this.projectRights.canModify,
                    },
                ],
                pluginServices: [{ value: formObject.pluginServices, disabled: !this.projectRights.canModify }],
            }),
            properties: [{ value: [], disabled: !this.projectRights.canModify }],
            codeScan: this.fb.array([]),
            scm: [
                {
                    value: {
                        toolId: formObject.toolId || null,
                        unitGroupId: formObject.unitGroupId || null,
                        unitGroupName: formObject.unitGroupName || null,
                        unitId: formObject.selectedUnitId || null,
                        unitName: formObject.selectedUnitName || null,
                    },
                    disabled: !this.projectRights.canModify,
                },
            ],
            codeTemplate: this.fb.group({
                id: [{ value: formObject.id, disabled: !this.projectRights.canModify }],
                name: [{ value: formObject.name, disabled: !this.projectRights.canModify }],
                description: [{ value: formObject.description, disabled: !this.projectRights.canModify }],
            }),
        });
    }

    initAddComponentForm(details: ComponentDetails): void {
        if (this.pluginServices.length === 0) {
            this.getPluginServices();
        }

        this.addComponentForm = this.fb.group({
            details: this.fb.group({
                name: [details.name, [Validators.required]],
                code: [details.code, [Validators.required, Validators.pattern(`^[a-z0-9]([-a-z0-9]*[a-z0-9])?$`)]],
                description: [details.description],
                pluginServices: [details.pluginServices],
            }),
        });
    }

    reset(tabName: string): void {
        this.form.get(tabName).patchValue(this.defaultValue);
        this.form.markAsPristine();
    }

    getComponentTabs(componentId$: Observable<number>) {
        this.initTabs$.next();
        this.componentDetails$ = combineLatest([this.updateComponent$.asObservable(), componentId$]).pipe(
            switchMap(([_, componentId]) =>
                this.componentsService
                    .getComponentDetails(this.projectId, componentId)
                    .pipe(finalize(() => (this.activeComponent.loading = false)))
            ),
            shareReplay(1)
        );

        this.getPluginServices();

        this.componentCodeScan$ = combineLatest([this.updateComponentCodeScan$.asObservable(), componentId$]).pipe(
            switchMap(([_, componentId]) => this.componentsService.getComponentCodeScan(this.projectId, componentId)),
            shareReplay(1)
        );

        this.componentSCM$ = combineLatest([this.updateComponentSCM$.asObservable(), componentId$]).pipe(
            switchMap(([_, componentId]) => this.componentsService.getComponentSCM(this.projectId, componentId)),
            shareReplay(1)
        );

        this.componentCodeScan$.pipe(takeUntil(merge(this.destroyed$, this.initTabs$))).subscribe((componentCodeScan: CodeScanTool[]) => {
            if (componentCodeScan) {
                this.codeScanTools = componentCodeScan.map(toolItem => {
                    return {
                        baseUrl: toolItem.baseUrl,
                        toolId: toolItem.toolId,
                        toolImageUrl: toolItem.toolImageUrl ? this.sanitizer.bypassSecurityTrustResourceUrl(toolItem.toolImageUrl) : '',
                        toolName: toolItem.toolName,
                        selectedUnitId: toolItem.unitId,
                        selectedUnitName: toolItem.unitName,
                        units: toolItem.units
                            ? toolItem.units
                                  .map(unitItem => {
                                      return {
                                          dpProjectId: unitItem.dpProjectId,
                                          projectName: unitItem.projectName,
                                          unitId: unitItem.unitId,
                                          unitName: unitItem.unitName,
                                          toolId: toolItem.toolId,
                                          projectWithUnitName: unitItem.projectName
                                              ? unitItem.projectName + ' / ' + unitItem.unitName
                                              : unitItem.unitName,
                                      };
                                  })
                                  .sort((a, b) => a.projectWithUnitName.localeCompare(b.projectWithUnitName))
                            : [],
                    };
                });
            }
        });

        this.componentSCM$.pipe(takeUntil(merge(this.destroyed$, this.initTabs$))).subscribe((response: ScmTool[]) => {
            this.scmTools = response.map(toolItem => {
                return {
                    baseUrl: toolItem.baseUrl,
                    isEnabled: toolItem.isEnabled,
                    toolId: toolItem.toolId,
                    toolImageUrl: toolItem.toolImageUrl,
                    toolName: toolItem.toolName,
                    unitGroupId: toolItem.unitGroupId,
                    unitGroupName: toolItem.unitGroupName,
                    selectedUnitId: toolItem.unitId,
                    selectedUnitName: toolItem.unitName,
                    units: (toolItem.units || []).map(unitItem => {
                        return {
                            unitId: unitItem.unitId,
                            unitName: unitItem.unitName,
                            dpProjectId: unitItem.dpProjectId,
                            projectName: unitItem.projectName,
                        };
                    }),
                };
            });
        });
    }

    getPluginServices() {
        if (this.isConveyorAlive) {
            this.pluginService.getPluginServices('com.ustri.digital.edgeops.buildapi.plugins.SourceProcessorProvider').subscribe(data => {
                this.pluginServices = [];
                if (data && data.length > 0) {
                    data.forEach(d =>
                        this.pluginServices.push({
                            label: d.split(':')[1],
                            value: d,
                        })
                    );
                }
            });
        } else {
            this.pluginServices = [];
        }
    }

    stompSubscribing() {
        this.actionCompleted = false;
        this.topic = `sourceCodeGeneration-${this.activeComponent.id}`;
        const subscription = this.stompService.listenTopic(this.topic).subscribe(info => {
            const widget = this.sourceCodeGenerationWidget;
            const infoBody = JSON.parse(info.body);
            widget.setDataByKey(infoBody.creationPhase.progress, 'progress');
            if (infoBody.creationPhase.status === 'SUCCESS') {
                if (subscription) {
                    subscription.unsubscribe();
                }
                this.addLogItems(infoBody.creationPhase.logs);
            }
        });
    }

    initSourceCodeGenerationWidget(widgetTemplate: TemplateRef<any>) {
        if (this.sourceCodeGenerationWidget) {
            this.widgetService.removeWidget(this.sourceCodeGenerationWidget);
        }
        this.sourceCodeGenerationWidget = this.widgetService.createWidget(
            {
                label: `Publishing Source Code`,
                progress: 0,
            },
            widgetTemplate
        );
    }

    private addLogItems(items: ILogItem[]) {
        items = items || [];
        sortObjectArrayByKey(items, 'moment');
        if (!this.processStartedTime && items[0]) {
            this.processStartedTime = items[0].moment;
        }

        const formattedItems = items.map(item => {
            return { ...item, duration: item.moment.valueOf() - this.processStartedTime.valueOf() };
        });
        this.log$.value.push(...formattedItems);
        this.log$.next(this.log$.value);
        this.actionCompleted = true;
    }

    showSaveCompletedToast(): void {
        this.toaster.successToast({
            title: 'Success',
            content: '',
        });
    }

    showCantSaveToast(title: string, content = 'Something went wrong while saving the data'): void {
        this.toaster.errorToast({
            title: title,
            content,
        });
    }

    onUniqueIdChange() {
        const detailsForm = this.form.get('details');
        const codeControl = detailsForm.get('code');

        if (this.components.find(c => c.code === codeControl.value && c.code !== this.activeComponent.code)) {
            codeControl.setErrors({ customText: 'Component code is not unique' });
        }
    }

    getAutoCompletionProperties(id: string, refresh?: boolean) {
        if (this.autoCompletionEnabled && (this.autoCompletionId !== id || refresh)) {
            this.autoCompletionService.getCodeCompletionProperties(id, 'COMPONENT', true).subscribe(response => {
                this.autoCompletion = response;
                this.autoCompletionId = id;
            });
        }
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }
}
