<svg [attr.viewBox]="'0 0 ' + size + ' ' + size" class="h-100 w-100">
    <circle class="progress__meter" [attr.cx]="center" [attr.cy]="center" [attr.r]="radius" [style.stroke-width]="strokeWidth"></circle>
    <circle
        *ngIf="value"
        class="progress__value"
        [attr.cx]="center"
        [attr.cy]="center"
        [attr.r]="radius"
        [style.stroke-width]="strokeWidth"
        [style.strokeDasharray]="progressPathArray"
        [style.rotate.deg]="rotateAngle"
    ></circle>
    <circle
        class="progress__meter"
        [attr.r]="innerCircleRadius"
        [attr.cx]="center"
        [attr.cy]="center"
        [style.stroke-width]="innerCircleStrokeWidth"
    ></circle>
</svg>
