import { Inject, Injectable, Optional } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { DATA_MORPH_AUTH, DataMorphAuthService } from 'data-processor/tokens';

import { AnyWidgetModel } from '../models/any-widget.model';
import { DashboardWidgetSettingsManager } from '../../dashboard/services/dashboard-widget-settings.manager';
import { WidgetBuilderService } from './widget-builder.service';

@Injectable()
export class EditSystemWidgetGuard implements CanActivate {
    constructor(
        @Optional() private manager: DashboardWidgetSettingsManager,
        private api: WidgetBuilderService,
        @Inject(DATA_MORPH_AUTH) private auth: DataMorphAuthService
    ) {}

    /* @duplicate {canEditWidget} **/
    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const isLibrary = route.data.mode === 'library';

        let query$: Observable<{ data: AnyWidgetModel }>;

        if (!isLibrary) {
            query$ = this.manager.getWidget(parseInt(route.paramMap.get('dashboardWidgetId'), 10));
        } else {
            const widgetId = route.paramMap.get('widgetId');

            if (widgetId === 'new') {
                return of(true);
            }

            query$ = this.api.getWidgetFromLibrary(parseInt(widgetId, 10));
        }

        return query$.pipe(
            map(widget => this.auth.isAdmin() && !!widget && !widget.data.system),
            catchError(() => of(false))
        );
    }
}
