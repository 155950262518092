import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { FilesAttacherModule } from '@app/shared/components/files-attacher/files-attacher.module';
import { RecipientsEditorModule } from '../recipients-editor/recipients-editor.module';
import { AnnouncementService } from './announcement.service';
import { AnnouncementBannerComponent } from './announcement-banner/announcement-banner.component';
import { AnnouncementSettingsModalComponent } from './announcement-settings-modal/announcement-settings-modal.component';

@NgModule({
    imports: [CommonModule, SharedModule, FilesAttacherModule, RecipientsEditorModule],
    declarations: [AnnouncementBannerComponent, AnnouncementSettingsModalComponent],
    exports: [AnnouncementBannerComponent, AnnouncementSettingsModalComponent],
    providers: [AnnouncementService],
})
export class AnnouncementModule {}
