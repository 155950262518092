import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { finalize, map, catchError } from 'rxjs/operators';
import { negate } from 'lodash';

import { EditJobDefinitionService } from '../../../edit-job-definition.service';
import { ProcessorMonitoringService } from '../../../../../processor-monitoring/processor-monitoring.service';
import { EvaluateResult } from '../../../state/job-definition.state.model';

@Component({
    selector: 'dp-debug-evaluate',
    templateUrl: './debug-evaluate.component.html',
    styleUrls: ['./debug-evaluate.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'd-flex',
    },
})
export class DebugEvaluateComponent {
    @Input() evaluateResult: EvaluateResult = {
        isError: false,
        result: '',
    };

    loading$ = new BehaviorSubject<boolean>(false);

    canEvaluate = () => this.store.debugContext.debugId && !this.loading$.value;

    // eslint-disable-next-line @typescript-eslint/member-ordering
    disabled$ = combineLatest([this.store.debugContext.debugId$, this.loading$]).pipe(map(negate(this.canEvaluate)));

    get loading() {
        return this.loading$.value;
    }

    constructor(public store: EditJobDefinitionService, private api: ProcessorMonitoringService) {}

    evaluate() {
        if (!this.canEvaluate()) {
            return;
        }

        this.loading$.next(true);
        this.api
            .evaluateScript(this.store.debugContext.getEvaluateModel())
            .pipe(
                map(result => ({
                    isError: false,
                    result,
                })),
                catchError(({ status, error = {} }: HttpErrorResponse) =>
                    of(status === 500 ? { isError: true, result: error.message } : { isError: true, result: '' })
                ),
                finalize(() => this.loading$.next(false))
            )
            .subscribe(this.store.updateEvaluateResult);
    }
}
