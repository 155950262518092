import { SortDirection } from '@dagility-ui/kit';

export const projectFilter: SplitButtonItem[] = [
    { label: 'Sort By Name', name: '', action: 'NONE', type: 'title', value: null},
    { label: 'A-Z', name: 'radio-1', action: 'PROJECT_NAME', type: 'radio', value: true},
    { label: 'Z-A', name: 'radio-1', action: 'PROJECT_NAME', type: 'radio', value: false },
    { label: 'Sort By Status', name: '', action: 'NONE', type: 'title', value: null },
    { label: 'Enabled Projects First', name: 'radio-2', action: 'ENABLED', type: 'radio', value: true },
    { label: 'Disabled Projects First', name: 'radio-2', action: 'ENABLED', type: 'radio', value: false },
    { label: 'Sort By Time', name: '', action: 'NONE', type: 'title', value: null },
    { label: 'None', name: 'radio-3', action: 'NONE', type: 'radio', value: true },
    { label: 'Creation Date', name: 'radio-3', action: 'CREATED_DATE', type: 'radio', value: false },
    { label: 'Recently Accessed', name: 'radio-3', action: 'LAST_ACCESS_DATE', type: 'radio', value: false},
];

export interface SplitButtonItem {
    label: string;
    name: string;
    action: ColumnName;
    type: string;
    value: boolean;
}

export interface ProjectSort {
    name: ColumnName;
    direction: SortDirection;
}

export type ColumnName = 'PROJECT_NAME' | 'FAVOURITE' | 'ENABLED' | 'LAST_ACCESS_DATE' | 'CREATED_DATE' | 'NONE';

export const defaultProjectSort: ProjectSort[] = [
    { name: 'FAVOURITE', direction: 'ASC' },
    { name: 'ENABLED', direction: 'DESC' },
    { name: 'PROJECT_NAME', direction: 'ASC' },
];
