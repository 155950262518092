import {Injectable, NgZone} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

import {AuthService} from '@app/auth';
import {DagilityToastrExpireComponent} from '../../../../projects/shared-components/components/dagility-toastr/dagility-toastr-expire.component';

@Injectable({
    providedIn: 'root',
})
export class SessionExpirationTracker {
    private sessionTime = window.testSessionTime || 60 * 60 * 1000; // 60 min;
    private showMessageDelta = window.testShowMessageDelta || 60 * 1000; // 1 min;
    private expiredTime: number;
    private checkInterval: any;
    private popupActive = false;

    constructor(private zone: NgZone, private toastr: ToastrService, private authService: AuthService) {}

    track() {
        this.zone.runOutsideAngular(() => {
            this.renewInterval();
            document.addEventListener('mousemove', this.mouseMoveHandler);
        });
    }

    unTrack() {
        this.stopInterval();
        document.removeEventListener('mousemove', this.mouseMoveHandler);
    }

    private mouseMoveHandler = () => {
        if (!this.popupActive) {
            this.stopInterval();
            this.renewInterval();
        }
    }

    private renewInterval(): void {
        this.expiredTime = this.sessionTime + new Date().getTime();
        this.popupActive = false;
        this.startCheckLoop();
    }

    private startCheckLoop() {
        this.checkInterval = setInterval(() => {
            if (this.notifySessionExpired(this.showMessageDelta)) {
                this.stopInterval();
            }
        }, 10000);
    }

    private stopInterval(): void {
        clearInterval(this.checkInterval);
    }

    private notifySessionExpired(notifyDelta: number): boolean {
        const expiredTime = this.expiredTime - new Date().getTime();
        const showMessage = expiredTime <= notifyDelta;

        if (showMessage) {
            this.zone.run(() => {
                this.showSessionExpiredToastr();
            });
        }
        return showMessage;
    }

    private showSessionExpiredToastr() {
        const toastrInstance = this.toastr.warning('Please close this message to renew the session.', 'Attention!', {
            timeOut: this.showMessageDelta,
            toastComponent: DagilityToastrExpireComponent,
            progressBar: true,
        });
        this.popupActive = true;
        toastrInstance.toastRef.componentInstance.closeIntervalEvent.subscribe((closeFn: Function) => {
            this.authService.logout();
            closeFn();
        });
        toastrInstance.toastRef.componentInstance.closeEvent.subscribe((closeFn: Function) => {
            this.zone.runOutsideAngular(() => this.renewInterval());
            closeFn();
        });
    }
}
