import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

import { BaseFormControl } from '@dagility-ui/kit';
import { CompletionSourceItem, definePropertiesCompletion } from '../../directives/properties-completion';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'lib-codemirror-editor',
    templateUrl: './codemirror-editor.component.html',
    host: {
        class: 'd-block font-12px',
    },
    styleUrls: ['./codemirror-editor.component.scss'],
})
export class CodeMirrorEditorComponent extends BaseFormControl<string, HTMLTextAreaElement> implements AfterViewInit, OnChanges {
    @Input() mode: string;
    @Input() theme = 'default';
    @Input() needRefresh: boolean;
    @Input() propertiesCompletion: CompletionSourceItem[] = [];
    @Input('readonly') disabled: boolean;
    @Input() lineWrapping: boolean = false;
    @Input() refresh$ = new Subject();
    @Input() bracketSyntax = true;

    @ViewChild('ref', { static: true }) ref: ElementRef;

    @HostBinding('attr.readonly')
    get readonlyClass() {
        return this.disabled ? true : null;
    }

    private codeMirrorEditor: any;
    private changeFromEditor = true;
    private firstRefresh = true;

    @HostBinding('class.d-none') loading = true;

    ngAfterViewInit() {
        this.value = this.value || '';

        if (!this.ref) {
            return;
        }

        this.zone.runOutsideAngular(async () => {
            setTimeout(() => (this.loading = false));
            const { CodeMirror } = await import('./codemirror.dependencies');
            definePropertiesCompletion(CodeMirror, this.propertiesCompletion, this.bracketSyntax);
            this.codeMirrorEditor = CodeMirror.fromTextArea(this.ref.nativeElement, {
                theme: this.theme,
                mode: this.mode,
                lineNumbers: true,
                lineWrapping: this.lineWrapping,
                lint: true,
                gutters: ['CodeMirror-lint-markers'],
                extraKeys: {
                    'Ctrl-Space': 'autocomplete',
                },
                readOnly: this.disabled,
            });

            this.codeMirrorEditor.setValue(this.value);

            if (this.needRefresh && this.firstRefresh) {
                setTimeout(() => {
                    this.codeMirrorEditor.refresh();
                    this.firstRefresh = false;
                });
            }

            this.codeMirrorEditor.on('change', () => {
                this.zone.run(() => this.editorValueChanged());
            });

            this.codeMirrorEditor.on('keyup', function(cm: any, event: any) {
                if (!cm.state.completionActive && event.key === '.') {
                    CodeMirror.commands.autocomplete(cm, null, { completeSingle: false });
                }
            });

            this.refresh$.pipe(takeUntil(this.destroy$)).subscribe(() => setTimeout(() => this.codeMirrorEditor.refresh()));
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.disabled) {
            this.codeMirrorEditor?.setOption('readOnly', this.disabled);
        }
    }

    writeValue(value: string) {
        if (!value && !this.needRefresh) {
            return;
        }

        this.value = value;

        if (this.codeMirrorEditor) {
            this.changeFromEditor = false;
            this.codeMirrorEditor.setValue(this.value);
            this.changeFromEditor = true;
        }
    }

    private editorValueChanged() {
        if (this.changeFromEditor) {
            this.value = this.codeMirrorEditor.getValue();
            this.onChange(this.value);
        }
    }
}
