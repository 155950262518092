<div class="header">
    <lib-nav (navItemClick)="panelTabClick.emit()">
        <ng-template
            *ngFor="let item of navItems"
            navItem
            navItemName="{{ item.name }}"
            navItemId="{{ item.id }}"
            style="padding: 0 !important;"
        >
            <ng-container *ngIf="!hidden">
                <div style="height: max-content; z-index: 1000;" *ngIf="sourceGraph; else noRootModel" [ngSwitch]="item.id">
                    <app-qb-model-selection-table *ngSwitchCase="'selection'"></app-qb-model-selection-table>
                    <app-qb-model-where-table *ngSwitchCase="'where'"></app-qb-model-where-table>
                    <app-qb-model-joins-table *ngSwitchCase="'joins'"
                                              [isEditorForm]="isEditorForm"
                                              (openRelationEditor)="openEditorForm($event)"
                                              (closeRelationEditor)="closeEditorForm()"
                    >
                    </app-qb-model-joins-table>
                    <app-qb-model-group-by-table *ngSwitchCase="'groupby'"></app-qb-model-group-by-table>
                    <app-qb-model-order-by-table *ngSwitchCase="'orderby'"></app-qb-model-order-by-table>
                    <div *ngSwitchDefault>Tab Content</div>
                </div>
            </ng-container>
        </ng-template>
    </lib-nav>
</div>

<ng-template #noRootModel>
    <div style="width: 100%; display: flex; align-items: center; justify-content: center;">
        <h3>Please, add root model</h3>
    </div>
</ng-template>
