import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CellEvent } from '@ag-grid-community/core';

import { ResizeObserverService } from '@dagility-ui/kit';

@Component({
    selector: 'dp-accordion-wrapper',
    templateUrl: './accordion-wrapper.component.html',
    styleUrls: ['./accordion-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ResizeObserverService],
    host: {
        class: 'h-100 dp-accordion-wrapper',
    },
})
export class AccordionWrapperComponent {
    @Input() options: {
        headerTemplate: string;
        items: Array<{ header: string; table: any }>;
    } = {
        headerTemplate: null,
        items: [],
    };

    @Input() placeholders: Record<string, any> = {};

    @Output() cellClicked = new EventEmitter();

    tableHeight$: Observable<string> = this.resizeObserverService.observe$(this.elementRef).pipe(
        startWith(true),
        map(() => {
            return `${this.elementRef.nativeElement.offsetHeight / 2}px !important`;
        })
    );

    constructor(private readonly elementRef: ElementRef<HTMLElement>, private readonly resizeObserverService: ResizeObserverService) {}

    handleDrilldownEvent(event: CellEvent, tableIndex: number) {
        this.cellClicked.emit({
            colId: event.column.getColId(),
            data: {
                ...event.data,
                tableIndex,
            },
        });
    }
}
