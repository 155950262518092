import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { WidgetTile } from 'data-processor';

@Component({
    selector: 'dp-security-posture-details',
    templateUrl: './security-posture-details-template.component.html',
    styleUrls: ['./security-posture-details-template.component.scss'],
})
export class SecurityPostureDetailsTemplateComponent {
    @Input() data: SecurityPosture;

    @Output() itemClick = new EventEmitter();

    filter$ = new BehaviorSubject(null as string);

    dataList$: Observable<SecurityListItem[]>;

    ngOnInit() {
        this.filter$.next((this.data?.summary?.tilePlaceholders?.tiles || []).find((x: any) => x.value > 0)?.tileTitle || null);
        this.dataList$ = of(this.data?.list?.items || []).pipe(switchMap(this.filterItems.bind(this)));
    }

    getColumns(itemsPerColumn: number, items: SecurityOverviewItem[]) {
        let count = !itemsPerColumn || !items ? 0 : Math.ceil(items.length / itemsPerColumn);
        return Array.from(Array(count > 4 ? 4 : count).keys());
    }

    getPartition(overview: SecurityOverview, column: number) {
        return overview.items.slice(column * overview.itemsPerColumn, overview.itemsPerColumn * (column + 1));
    }

    handleDrilldown(e: WidgetTile) {
        this.filter$.next(this.filter$.value === e.tileTitle ? null : e.tileTitle);
    }

    filterItems(items: SecurityListItem[]) {
        return this.filter$.pipe(map(tile => (tile === null ? items : items.filter(x => x.status === tile))));
    }

    openLink(url: string) {
        if (url) {
            window.open(url, '_blank');
        }
    }

    rowClick(item: SecurityListItem) {
        this.itemClick.next(item);
    }
}

export interface SecurityPosture {
    overview: SecurityOverview;
    summary: SecuritySummary;
    list: SecurityList;
}

export interface SecurityOverview {
    title: string;
    itemsPerColumn: number;
    items: SecurityOverviewItem[];
}

export interface SecurityOverviewItem {
    value: string;
    label: string;
    badge?: boolean;
    badgeColor?: string;
}

export interface SecuritySummary {
    title: string;
    description: string;

    tilePlaceholders: Record<string, any>;
}

export interface SecurityList {
    title: string;
    items: SecurityListItem[];
}

export interface SecurityListItem {
    id?: string;
    idLink?: string;
    date: string;
    name: string;
    status?: string;
    statusColor?: string;
    description: string;
}
