<div #containerRef perfectScrollbar class="items-container position-relative px-3" (libResizeObserver)="handleResize(containerRef)">
    <dp-pi-radial
        *ngFor="let radial of options.radials"
        class="radial-chart"
        [radial]="radial"
        [size]="size"
        [class.cursor-pointer]="hasDrilldown"
        (click)="handleDrilldownEvent(radial)"
        (linkClicked)="linkClicked.emit($event)"
    ></dp-pi-radial>
</div>
