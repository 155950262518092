<ng-container *ngIf="service.tableData$ | async as data">
    <ng-container *ngIf="isTableData(data) as tableData">
        <ng-container *ngIf="tableData.rowData?.length; else noData">
            <lib-ag-grid class="d-block w-100 mt-4 table" gridHeight="100%" [rowBuffer]="1000" [tableData]="tableData"></lib-ag-grid>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #noData>
    <div class="h-100 d-flex align-items-center justify-content-center no-data-text secondary-text-and-icons">No Data</div>
</ng-template>
