import { Deserializable, PrimitiveDataType } from './abstract-job-definition';
import { JobDefinitionRunningType } from './enums';
import { Required, CollectionElementValidator, Validate, NotEmpty } from '../validate.decorator';
import { IsString, IsEnum, Type, IsInt, IsBoolean } from '../type.decorator';
import { JobDefinitionDataSource, PostUpdate } from './data-source';
import { JobDefinitionDataTarget } from './data-target';
import { DataTransformation } from './data-transformation';
import { TargetReference } from './target-reference';
import { Variable } from './variable';
import { WorkflowAction } from '../actions';
import { DataSchema } from './data-schema';

export class JobDefinition extends Deserializable {
    constructor(instanceData?: JobDefinition) {
        super();

        this.fillByInstance(JobDefinition, instanceData);
    }

    static GROOVY_FIELDS = ['beforeScript', 'beforeIterationScript', 'afterIterationScript', 'afterScript'];

    _class = {
        dataSource: JobDefinitionDataSource,
    };
    _enums = {
        runningType: JobDefinitionRunningType,
    };
    _arrays = {
        targets: JobDefinitionDataTarget,
        transformations: DataTransformation,
        updates: PostUpdate,
        references: TargetReference,
        variables: Variable,
    };
    _maps = {
        parameters: PrimitiveDataType,
    };
    _nullableFields = {
        timeout: 'number',
    };
    meta = {
        _arrays: this._arrays,
        _enums: this._enums,
        _class: this._class,
        _maps: this._maps,
        _nullableFields: this._nullableFields,
    };

    @Required()
    @IsString()
    instanceName: string = '';

    @Required()
    @IsEnum(JobDefinitionRunningType)
    runningType?: JobDefinitionRunningType = null;

    @CollectionElementValidator([JobDefinitionDataTarget.SchemaRequired, DataSchema.KeysInColumns, DataSchema.ColumnsNotEmpty])
    @Type(JobDefinitionDataTarget)
    targets: JobDefinitionDataTarget[] = [];

    dataSource: JobDefinitionDataSource = new JobDefinitionDataSource(null, false);

    @Validate([NotEmpty(PrimitiveDataType.IsEmpty)])
    @Type(PrimitiveDataType)
    parameters: Map<string, PrimitiveDataType> = {} as any;

    @IsString()
    incomingChannel: string = '';

    @IsInt()
    id: number = null;

    @IsString()
    toolId: string = '';

    @IsInt()
    jobSetId: number = null;

    @IsString()
    version: string = '';

    @IsString()
    beforeScript = '';

    @IsString()
    beforeIterationScript = '';

    @IsString()
    afterIterationScript = '';

    @IsString()
    afterScript = '';

    @Type(Number)
    @IsInt()
    timeout: number = 1;

    @IsBoolean()
    active: boolean = false;

    @Type(PostUpdate)
    updates: PostUpdate[] = [];

    @Type(DataTransformation)
    transformations: DataTransformation[] = [];

    @Type(TargetReference)
    references: TargetReference[] = [];

    @Type(Variable)
    variables: Variable[] = [];

    actions: WorkflowAction[] = [];

    getType() {
        return 'JobDefinition';
    }
}
