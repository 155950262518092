import { inject, Provider } from '@angular/core';
import { DEFAULT_PLACEHOLDERS, DefaultPlaceholders } from 'data-processor';
import { addTimeZonePlaceholderFactory } from 'data-processor/lib/widget-library/widget-builder/providers/timezone-placeholders.provider';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DateAdapter } from '@dagility-ui/shared-components';
import { QueryBuilderStore } from '@app/shared/components/query-builder/store/query-builder.store';

export function externalParametersFactory(): DefaultPlaceholders {
    const defaultPlaceholders$ = inject(DEFAULT_PLACEHOLDERS, { skipSelf: true }) || (() => of({}));
    const dateAdapter = inject(DateAdapter);
    const qbStore = inject(QueryBuilderStore);
    return widget =>
        addTimeZonePlaceholderFactory(
            defaultPlaceholders$,
            dateAdapter
        )(widget).pipe(
            switchMap(defaultPlaceholders => {
                const externalParameters = qbStore.placeholders;
                if (!externalParameters || !externalParameters.length) {
                    return of(defaultPlaceholders);
                }
                const parameters = externalParameters.filter(parameter => defaultPlaceholders[parameter.name] === undefined);
                if (!parameters.length) {
                    return of(defaultPlaceholders);
                }
                const parametersObj = Object.assign({}, ...parameters.map(param => ({ [param.name]: param.value })));
                return of({ ...defaultPlaceholders, ...parametersObj });
            })
        );
}

export const EXTERNAL_PARAMETERS_PROVIDER: Provider = {
    provide: DEFAULT_PLACEHOLDERS,
    useFactory: externalParametersFactory,
};
