import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { QB_ICON_URLS } from '@app/shared/components/query-builder/consts/qb-icon-urls.const';
import { QueryBuilderStore } from '@app/shared/components/query-builder/store/query-builder.store';
import { DropdownItem, GridColumn, validateFormAndDisplayErrors } from '@dagility-ui/kit';
import { Model, ModelDataField, WCFilter } from '@app/shared/components/query-builder/models/model-graph.model';
import { ModelGraphActionsService } from '@app/shared/components/query-builder/services/model-graph-actions.service';
import { DropdownFilter, WidgetFilter, WidgetFilterType } from 'data-processor';
import { WidgetBuilderStore } from 'data-processor/lib/widget-library/widget-builder/services/widget-builder.store';
import { WidgetBuilderFacade } from 'data-processor/lib/widget-library/widget-builder/state/widget-builder.facade';
import { FilterBlock } from 'data-processor/lib/widget-library/widget-builder/models/query.block';
import { WidgetLayer } from 'data-processor/lib/widget-library/widget-builder/models/widget.graph';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FiltersFormConfig } from 'data-processor/lib/widget-library/widget-builder/models/filters-form.config';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { cloneDeep } from 'lodash';

const filterTypes: Record<string, string> = {
    DROPDOWN: 'Dropdown',
    INPUT: 'Input',
    RANGE: 'Range',
    DATE: 'Date',
    CHECKBOX: 'Checkbox',
    NEW_RANGE: 'New Range',
    HIDDEN: 'Hidden',
    RADIO_GROUP: 'Radio Group',
};

@Component({
    selector: 'app-query-builder-filters',
    templateUrl: './qb-filters.component.html',
    styleUrls: ['./qb-filters.component.scss'],
    providers: [WidgetBuilderStore, WidgetBuilderFacade],
})
export class QbFiltersComponent implements OnInit {
    @Input() rootModels: Model[];
    @Output() backButton: EventEmitter<any> = new EventEmitter<any>();

    filters: WCFilter[];
    filtersGrid: Filter[];
    filterColumns: GridColumn[] = [
        { title: 'Placeholder', field: 'placeholder', width: '25%' },
        { title: 'Label', field: 'label', width: '25%' },
        { title: 'Type', field: 'type', width: '45%' },
        { title: 'Action', field: 'action', width: '5%' },
    ];

    selectedFilter: WCFilter;

    block: FilterBlock;
    mainWidgetLayer: WidgetLayer;

    icons = QB_ICON_URLS;

    loading: boolean = true;
    editor: boolean = false;

    modelForm: FormGroup;
    modelItems: Model[];
    selectedModel: string;
    labelFieldItems: ModelDataField[];
    selectedLabelField: string;
    valueFieldItems: ModelDataField[];
    selectedValueField: string;
    toolItems: DropdownItem[];
    schemaItems: DropdownItem[];
    selectedTools: DropdownItem[];
    selectedSchemas: DropdownItem[];
    toolsAndSchemas: { tool?: { value: string; label: string }; schemas?: { value: string; label: string }[] }[];

    filtersFormConfig: FiltersFormConfig;
    @ViewChild('queryReplacement') queryReplacement: TemplateRef<any>;
    @ViewChild('checkDefaultTemplate') checkDefaultTemplate: TemplateRef<any>;
    checkDefaultValue: boolean = false;
    @ViewChild('dateDefaultTemplate') dateDefaultTemplate: TemplateRef<any>;
    dateDefaultValue: any = null;
    @ViewChild('dateRangeDefaultTemplate') dateRangeDefaultTemplate: TemplateRef<any>;
    dateRangeDefaultValue: { minimum?: any; maximum?: any } = { minimum: null, maximum: null };
    @ViewChild('dateNewRangeDefaultTemplate') dateNewRangeDefaultTemplate: TemplateRef<any>;
    dateNewRangeDefaultValue: { startDate: any; endDate: any } = { startDate: null, endDate: null };
    @ViewChild('inputDefaultTemplate') inputDefaultTemplate: TemplateRef<any>;
    inputDefaultValue: string = null;
    @ViewChild('ddrgDefaultTemplate') ddrgDefaultTemplate: TemplateRef<any>;
    ddrgDefaultValueItems: DropdownItem[] = [];
    ddrgDefaultValue: any = null;
    ddrgIsMultiple: boolean = false;

    get hasErrors(): boolean {
        return this.queryBuilderStore.validationErrors.filter(error => error.level === 'ERROR').length > 0;
    }

    constructor(
        private queryBuilderStore: QueryBuilderStore,
        private modelGraphActionsService: ModelGraphActionsService,
        private cdr: ChangeDetectorRef,
        private wbStore: WidgetBuilderStore,
        private fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.rootModels = QbFiltersComponent.setTrueModelName(this.rootModels);
        this.prepareToolsAndSchemas();
        this.updateData();
    }

    ngAfterContentInit(): void {
        this.filtersFormConfig = {
            hiddenFilterTypes: [WidgetFilterType.HIDDEN],
            defaultValueReplacementMap: new Map<WidgetFilterType, TemplateRef<any>>([
                [WidgetFilterType.CHECKBOX, this.checkDefaultTemplate],
                [WidgetFilterType.DATE, this.dateDefaultTemplate],
                [WidgetFilterType.RANGE, this.dateRangeDefaultTemplate],
                [WidgetFilterType.NEW_RANGE, this.dateNewRangeDefaultTemplate],
                [WidgetFilterType.INPUT, this.inputDefaultTemplate],
                [WidgetFilterType.DROPDOWN, this.ddrgDefaultTemplate],
                [WidgetFilterType.RADIO_GROUP, this.ddrgDefaultTemplate],
            ]),
            queryReplacement: this.queryReplacement,
        };
    }

    @HostListener('document:queryBuilderChanged', ['$event'])
    updateData = (): any => {
        this.loading = true;
        this.editor = false;
        this.selectedFilter = null;

        this.filters = this.queryBuilderStore.modelGraph.wcFilters ? this.queryBuilderStore.modelGraph.wcFilters : [];
        this.filters.forEach(f => (f.filter.id = f.uid));
        this.filtersGrid = this.filters
            ? this.filters.map(filter => ({
                  uid: filter.uid,
                  filter: filter,
                  selected: false,
              }))
            : [];

        this.mainWidgetLayer = {
            id: this.queryBuilderStore.modelGraph.uid,
            type: null,
            toolCategoryType: null,
            series: [],
            chartOptions: null,
            tiles: [],
            colorThreshold: [],
            widgets: [],
            queries: [],
            filters: [],
        };
        this.wbStore.init({
            root: this.queryBuilderStore.modelGraph.uid,
            layers: { [this.mainWidgetLayer.id]: this.mainWidgetLayer },
            queries: {},
            filters: {},
            actions: [],
        });
        this.filtersGrid.forEach(f => this.wbStore.addToLayer(this.mainWidgetLayer.id, 'filters', f.filter.filter));
        this.initQueryBlock();

        this.loading = false;
        this.cdr.detectChanges();
    };

    openEditor() {
        this.block = new FilterBlock(this.selectedFilter?.filter);
        this.block.layerId = this.queryBuilderStore.modelGraph.uid;
        this.block.id = this.selectedFilter?.uid;
        if (this.selectedFilter?.filterModel) {
            this.initToolItems();
            this.selectedTools = this.toolItems.filter(i =>
                !this.selectedFilter.filterModel.userDefined ? i.value === this.selectedFilter.filterModel.data.dbToolId : !i.value
            );
            this.initSchemaItems(this.selectedTools);
            this.selectedSchemas = this.schemaItems.filter(i => i.value.split(':')[1] === this.selectedFilter.filterModel.data.schemaName);
            this.initModelItems();
            this.selectedModel = this.selectedFilter.filterModel.uid;
            this.labelFieldItems = this.selectedFilter.filterModel.data.fields;
            this.selectedLabelField = this.selectedFilter.labelDataField;
            this.valueFieldItems = this.labelFieldItems;
            this.selectedValueField = this.selectedFilter.valueDataField;
        } else {
            this.initQueryBlock();
        }
        this.switchFormGroup(this.selectedFilter?.filter);
        this.editor = true;
    }

    closeEditor() {
        if (!this.selectedFilter?.uid) {
            this.wbStore.delete(this.mainWidgetLayer.id, undefined, 'filters');
        }
        this.block = null;
        this.selectedFilter = null;

        this.modelItems = [];
        this.selectedModel = null;
        this.labelFieldItems = [];
        this.selectedLabelField = null;
        this.valueFieldItems = [];
        this.selectedValueField = null;
        this.toolItems = [];
        this.schemaItems = [];
        this.selectedTools = [];
        this.selectedSchemas = [];

        this.filtersGrid.forEach(f => {
            f.selected = false;
        });
        this.editor = false;
    }

    switchFormGroup(filter: WidgetFilter) {
        this.modelForm = this.fb.group(
            [WidgetFilterType.DROPDOWN, WidgetFilterType.RADIO_GROUP].includes(filter?.type) && (<DropdownFilter>filter)?.dynamic
                ? {
                      model: [this.selectedModel, Validators.required],
                      label: [this.selectedLabelField, Validators.required],
                      value: [this.selectedValueField, Validators.required],
                      tools: [this.selectedTools],
                      schemas: [this.selectedSchemas],
                  }
                : {
                      model: [this.selectedModel],
                      label: [this.selectedLabelField],
                      value: [this.selectedValueField],
                      tools: [this.selectedTools],
                      schemas: [this.selectedSchemas],
                  }
        );
        this.initDefaultValues(filter);
    }

    private initDefaultValues(filter: WidgetFilter) {
        switch (filter?.type) {
            case WidgetFilterType.CHECKBOX:
                this.checkDefaultValue = filter.defaultValue && filter.defaultValue === '1';
                break;
            case WidgetFilterType.DATE:
                this.dateDefaultValue = new Date(filter.defaultValue?.slice(1, filter.defaultValue?.length - 1));
                break;
            case WidgetFilterType.RANGE:
                let rangeArgs = filter.defaultValue?.split('"');
                this.dateRangeDefaultValue = {
                    minimum: rangeArgs?.indexOf('minimum') >= 0 ? rangeArgs[rangeArgs.indexOf('minimum') + 2] : null,
                    maximum: rangeArgs?.indexOf('maximum') >= 0 ? rangeArgs[rangeArgs.indexOf('maximum') + 2] : null,
                };
                break;
            case WidgetFilterType.NEW_RANGE:
                let newRangeArgs = filter.defaultValue?.split('"');
                this.dateNewRangeDefaultValue = {
                    startDate: newRangeArgs?.indexOf('startDate') >= 0 ? newRangeArgs[newRangeArgs.indexOf('startDate') + 2] : null,
                    endDate: newRangeArgs?.indexOf('endDate') >= 0 ? newRangeArgs[newRangeArgs.indexOf('endDate') + 2] : null,
                };
                break;
            case WidgetFilterType.INPUT:
                this.inputDefaultValue = filter.defaultValue?.slice(1, filter.defaultValue?.length - 1);
                break;
            case WidgetFilterType.DROPDOWN:
                this.ddrgIsMultiple = (<DropdownFilter>filter).multiple;
                this.ddrgDefaultValueItems = (<DropdownFilter>filter).items;
                if (this.ddrgIsMultiple) {
                    this.ddrgDefaultValue = this.ddrgDefaultValueItems
                        ?.filter(i => filter.defaultValue && <Array<any>>JSON.parse(filter.defaultValue)?.includes(i.value))
                        ?.map(i => i.value);
                } else {
                    this.ddrgDefaultValue = this.ddrgDefaultValueItems?.find(
                        i => i.value && i.value === filter.defaultValue?.slice(1, filter.defaultValue?.length - 1)
                    )?.value;
                }
                break;
            case WidgetFilterType.RADIO_GROUP:
                this.ddrgIsMultiple = false;
                this.ddrgDefaultValueItems = (<DropdownFilter>filter).items;
                this.ddrgDefaultValue = this.ddrgDefaultValueItems?.find(
                    i => i.value && i.value === filter.defaultValue?.slice(1, filter.defaultValue?.length - 1)
                )?.value;
                break;
            default:
                this.checkDefaultValue = false;
                this.dateDefaultValue = null;
                this.dateRangeDefaultValue = { minimum: null, maximum: null };
                this.dateNewRangeDefaultValue = { startDate: null, endDate: null };
                this.inputDefaultValue = null;
                this.ddrgIsMultiple = false;
                this.ddrgDefaultValueItems = [];
                this.ddrgDefaultValue = null;
                break;
        }
    }

    private updateFilterDefaultValues(filter: WidgetFilter): WidgetFilter {
        switch (filter.type) {
            case WidgetFilterType.CHECKBOX:
                filter.defaultValue = this.checkDefaultValue ? '1' : '0';
                return filter;
            case WidgetFilterType.DATE:
                filter.defaultValue = this.dateDefaultValue ? `"${this.dateDefaultValue.slice(0, 10)}"` : null;
                return filter;
            case WidgetFilterType.RANGE:
                if (!this.dateRangeDefaultValue.minimum && !this.dateRangeDefaultValue.maximum) {
                    filter.defaultValue = null;
                    return filter;
                }
                filter.defaultValue = 'def map = new HashMap<String, String>();\n';
                filter.defaultValue +=
                    this.dateRangeDefaultValue.minimum?.slice(0, 10).length > 0
                        ? `map.put("minimum", "${this.dateRangeDefaultValue.minimum?.slice(0, 10)}");\n`
                        : '';
                filter.defaultValue +=
                    this.dateRangeDefaultValue.maximum?.slice(0, 10).length > 0
                        ? `map.put("maximum", "${this.dateRangeDefaultValue.maximum?.slice(0, 10)}");\n`
                        : '';
                filter.defaultValue += 'return map;';
                return filter;
            case WidgetFilterType.NEW_RANGE:
                if (this.dateNewRangeDefaultValue.startDate && this.dateNewRangeDefaultValue.endDate) {
                    filter.defaultValue = `def map = new HashMap<String, String>();\nmap.put("startDate", "${this.dateNewRangeDefaultValue.startDate.toISOString()}");\nmap.put("endDate", "${this.dateNewRangeDefaultValue.endDate.toISOString()}");\nreturn map;`;
                } else {
                    filter.defaultValue = null;
                }
                return filter;
            case WidgetFilterType.INPUT:
                filter.defaultValue = this.inputDefaultValue ? `"${this.inputDefaultValue}"` : null;
                return filter;
            case WidgetFilterType.DROPDOWN:
                if ((<DropdownFilter>filter).dynamic) {
                    return filter;
                }
                if (this.ddrgIsMultiple) {
                    let dvArray = (<Array<any>>this.ddrgDefaultValue)?.map(dv => (dv?.value ? dv.value : dv));
                    filter.defaultValue = JSON.stringify(dvArray);
                    filter.defaultValue = dvArray?.length > 0 ? filter.defaultValue : null;
                } else {
                    filter.defaultValue = this.ddrgDefaultValue ? `"${this.ddrgDefaultValue}"` : null;
                }
                return filter;
            case WidgetFilterType.RADIO_GROUP:
                if ((<DropdownFilter>filter).dynamic) {
                    return filter;
                }
                if (this.ddrgDefaultValueItems?.length > 0) {
                    filter.defaultValue = this.ddrgDefaultValue
                        ? `"${this.ddrgDefaultValue}"`
                        : `"${this.ddrgDefaultValueItems[0]?.value}"`;
                } else {
                    filter.defaultValue = this.ddrgDefaultValue ? `"${this.ddrgDefaultValue}"` : null;
                }
                return filter;
            default:
                return filter;
        }
    }

    handleAdd() {
        this.selectedFilter = {
            uid: null,
            filter: {
                placeholder: 'Filter_' + this.filters.length,
                type: null,
                defaultValue: null,
                label: null,
            },
            filterModel: null,
            labelDataField: null,
            valueDataField: null,
        };
        this.wbStore.addToLayer(this.mainWidgetLayer.id, 'filters', this.selectedFilter.filter);
        this.openEditor();
    }

    addFilter(data: WidgetFilter) {
        if (!this.selectedFilter) {
            return;
        }
        validateFormAndDisplayErrors(this.modelForm);
        if (this.modelForm.valid) {
            const sourceGraph = this.queryBuilderStore.modelGraph;
            const filter = this.updateFilterDefaultValues(data);
            const modelUid = this.selectedModel ? this.selectedModel : null;
            const labelDataField = this.selectedLabelField ? this.selectedLabelField : null;
            const valueDataField = this.selectedValueField ? this.selectedValueField : null;
            this.modelGraphActionsService
                .addWCFilter({
                    sourceGraph,
                    filter,
                    modelUid,
                    labelDataField,
                    valueDataField,
                })
                .subscribe(result => this.queryBuilderStore.updateState(result));
        }
    }

    updateFilter(data: WidgetFilter) {
        if (!this.selectedFilter) {
            return;
        }
        validateFormAndDisplayErrors(this.modelForm);
        if (
            !([WidgetFilterType.DROPDOWN, WidgetFilterType.RADIO_GROUP].includes(data.type) && (<DropdownFilter>data).dynamic) ||
            this.modelForm.valid
        ) {
            const sourceGraph = this.queryBuilderStore.modelGraph;
            const filterUid = this.selectedFilter.uid;
            const filter = this.updateFilterDefaultValues(data);
            const modelUid =
                this.selectedModel &&
                [WidgetFilterType.DROPDOWN, WidgetFilterType.RADIO_GROUP].includes(filter.type) &&
                (<DropdownFilter>filter).dynamic
                    ? this.selectedModel
                    : null;
            const labelDataField =
                this.selectedLabelField &&
                [WidgetFilterType.DROPDOWN, WidgetFilterType.RADIO_GROUP].includes(filter.type) &&
                (<DropdownFilter>filter).dynamic
                    ? this.selectedLabelField
                    : null;
            const valueDataField =
                this.selectedValueField &&
                [WidgetFilterType.DROPDOWN, WidgetFilterType.RADIO_GROUP].includes(filter.type) &&
                (<DropdownFilter>filter).dynamic
                    ? this.selectedValueField
                    : null;
            this.modelGraphActionsService
                .updateWCFilter({
                    sourceGraph,
                    filterUid,
                    filter,
                    modelUid,
                    labelDataField,
                    valueDataField,
                })
                .subscribe(result => this.queryBuilderStore.updateState(result));
        }
    }

    deleteFilter() {
        if (!this.selectedFilter || !this.selectedFilter?.uid) {
            this.closeEditor();
            return;
        }
        const sourceGraph = this.queryBuilderStore.modelGraph;
        const filterUid = this.selectedFilter.uid;
        this.modelGraphActionsService
            .deleteWCFilter({
                sourceGraph,
                filterUid,
            })
            .subscribe(result => this.queryBuilderStore.updateState(result));
    }

    handleTableDrop(event: CdkDragDrop<Filter[]>) {
        moveItemInArray(this.filtersGrid, event.previousIndex, event.currentIndex);
        const sourceGraph = this.queryBuilderStore.globalModelGraph;
        const filterUid = event.item.data.uid;
        const ord = event.currentIndex;
        this.modelGraphActionsService.reorderWCFilters({ sourceGraph, filterUid, ord }).subscribe(result => {
            this.queryBuilderStore.updateState(result);
        });
    }

    onChangeModel(event: any) {
        this.selectedModel = event.uid;
        this.labelFieldItems = this.rootModels.find(m => m.uid === event.uid)?.data.fields;
        this.selectedLabelField = null;
        this.valueFieldItems = this.labelFieldItems;
        this.selectedValueField = null;
    }

    onChangeLabelField(event: any) {
        this.selectedLabelField = event.dataField;
    }

    onChangeValueField(event: any) {
        this.selectedValueField = event.dataField;
    }

    filterModelList(param: 'TOOL' | 'SCHEMA') {
        this.selectedTools = this.toolsAndSchemas
            .filter(tns => this.selectedTools.map(t => (t instanceof Object ? t.value : t)).includes(tns.tool.value))
            .map(tns => tns.tool);
        this.selectedSchemas = this.toolsAndSchemas
            .filter(tns => this.selectedTools.map(t => t.value).includes(tns.tool.value))
            .map(tns => tns.schemas)
            .reduce((a, b) => a.concat(b), [])
            .filter(schema => this.selectedSchemas.map(s => (s instanceof Object ? s.value : s)).includes(schema.value));

        if (param === 'TOOL') {
            if (this.selectedTools.length > 0) {
                this.initSchemaItems(this.selectedTools);
                if (this.selectedSchemas?.length > 0) {
                    this.selectedSchemas = this.selectedSchemas.filter(s => this.schemaItems.map(t => t.value).includes(s.value));
                }
            } else {
                this.schemaItems = [];
                this.selectedSchemas = [];
            }
        }
        this.initModelItems();
    }

    private prepareToolsAndSchemas() {
        this.toolsAndSchemas = this.rootModels
            .filter(
                (model, i, models) =>
                    models
                        .map(m => (m.userDefined || (!m.data.dbToolNameHint && !m.userDefined) ? undefined : m.data.dbToolId))
                        .indexOf(
                            model.userDefined || (!model.data.dbToolNameHint && !model.userDefined) ? undefined : model.data.dbToolId
                        ) === i
            )
            .map(m => ({
                tool:
                    m.userDefined || (!m.data.dbToolNameHint && !m.userDefined)
                        ? {
                              value: undefined,
                              label: 'Custom',
                          }
                        : {
                              value: m.data.dbToolId,
                              label: m.data.dbToolNameHint,
                          },
            }));
        this.toolsAndSchemas.forEach(
            tns =>
                (tns.schemas = this.rootModels
                    .filter(m =>
                        tns.tool.value ? !m.userDefined && m.data.dbToolId === tns.tool.value : !m.data.dbToolId || m.userDefined
                    )
                    .map(m => ({
                        value: `${tns.tool.value}:${tns.tool.value ? m.data.schemaName : undefined}`,
                        label: m.data.schemaName,
                    }))
                    .filter((schema, i, a) => a.map(s => s.value).indexOf(schema.value) === i))
        );
        const allSchemas = this.toolsAndSchemas.map(tns => tns.schemas).reduce((a, b) => a.concat(b));
        this.toolsAndSchemas.forEach(tns =>
            tns.schemas
                .filter(schema => allSchemas.filter(s => s.value.split(':')[1] === schema.value.split(':')[1]).length > 1)
                .forEach(schema => (schema.label = `${schema.label} [${tns.tool.label}]`))
        );
    }

    private initQueryBlock() {
        this.initToolItems();
        this.initSchemaItems();
        this.initModelItems();
    }

    private initToolItems() {
        this.toolItems = this.toolsAndSchemas.map(tns => tns.tool);
        this.selectedTools = [];
    }

    private initSchemaItems(selectedTools?: DropdownItem<string>[]) {
        this.schemaItems = this.toolsAndSchemas
            .filter(tns => (selectedTools?.length ? selectedTools.map(t => t.value).includes(tns.tool.value) : true))
            .map(tns => tns.schemas)
            .reduce((a, b) => a.concat(b))
            .filter(schema => schema.value.split(':')[0] !== 'undefined');
        if (!selectedTools) {
            this.selectedSchemas = [];
        }
    }

    private initModelItems() {
        if (
            (this.selectedTools?.length === 0 && this.selectedSchemas?.length === 0) ||
            (this.selectedTools?.length === this.toolItems.length && this.selectedSchemas?.length === 0)
        ) {
            this.modelItems = this.rootModels;
        } else {
            this.modelItems = this.rootModels
                .filter(model => this.selectedTools?.map(t => t.value).includes(model.userDefined ? undefined : model.data.dbToolId))
                .filter(model =>
                    !model.userDefined && model.data.dbToolId && this.selectedSchemas?.length > 0
                        ? this.selectedSchemas?.find(
                              s =>
                                  s.value.split(':')[0] === (model.userDefined ? 'undefined' : model.data.dbToolId + '') &&
                                  s.value.split(':')[1] === model.data.schemaName + ''
                          )
                        : true
                );
        }
        this.initModelFields();
    }

    private initModelFields() {
        if (!this.selectedModel && !this.modelItems.map(i => i.uid).includes(this.selectedModel?.valueOf())) {
            this.selectedModel = null;
            this.labelFieldItems = [];
            this.selectedLabelField = null;
            this.valueFieldItems = [];
            this.selectedValueField = null;
        } else {
            this.selectedModel = this.modelItems.map(i => i.uid).includes(this.selectedModel) ? this.selectedModel : null;
            if (!this.selectedModel) {
                this.labelFieldItems = [];
                this.selectedLabelField = null;
                this.valueFieldItems = [];
                this.selectedValueField = null;
            }
        }
    }

    private static setTrueModelName(models: Model[]): Model[] {
        return models.map(m => {
            let newModel = cloneDeep(m);
            newModel.name =
                newModel.data.dbToolId && !newModel.userDefined
                    ? `[${newModel.data.dbToolNameHint}] ${newModel.data.schemaName}: ${newModel.name}`
                    : newModel.name;
            return newModel;
        });
    }

    getFilterType = (filterType: string): string => filterTypes[filterType];

    handleFilterEdit(filter: WCFilter) {
        this.selectedFilter = filter;
        this.openEditor();
    }

    handleFilterDelete(filter: WCFilter) {
        this.selectedFilter = filter;
        this.deleteFilter();
    }
}

interface Filter {
    uid: string;
    filter: WCFilter;
    selected: boolean;
}
