import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PortalModule } from '@angular/cdk/portal';
import { NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ColorPickerModule } from 'ngx-color-picker';

import { CKEditorModule } from 'ngx-ckeditor';
import { SharedComponentsModule } from '@dagility-ui/shared-components';

import { UiKitModule } from '@dagility-ui/kit';
import { DebugVariablesModule } from '../processor-monitoring/debug-variables/debug-variables.module';
import { WidgetLibraryComponent } from './widget-library.page';
import { WidgetListComponent } from './widget-list/widget-list.component';
import { WidgetListItemComponent } from './widget-list-item/widget-list-item.component';
import { WidgetBuilderComponent } from './widget-builder/widget-builder.component';
import {
    HelpForTemplateQueryDirective,
    WidgetBuilderQueryComponent,
} from './widget-builder/components/form/widget-builder-query/widget-builder-query.component';
import { WidgetBuilderChartOptionsComponent } from './widget-builder/components/form/widget-builder-chart-options/widget-builder-chart-options.component';
import { WidgetBuilderFiltersComponent } from './widget-builder/components/form/widget-builder-filters/widget-builder-filters.component';
import { AnyWidgetComponent } from './widget-builder/components/widget/any-widget/any-widget.component';
import { AnyWidgetFilterWrapperComponent } from './widget-builder/components/widget/any-widget-filter-wrapper/any-widget-filter-wrapper.component';
import { AnyWidgetFilterItemComponent } from './widget-builder/components/widget/any-widget-filter-wrapper/any-widget-filter-item/any-widget-filter-item.component';
import { WidgetBuilderChartSeriesComponent } from './widget-builder/components/form/widget-builder-chart-series/widget-builder-chart-series.component';
import { WidgetBuilderColumnsComponent } from './widget-builder/components/form/widget-builder-columns/widget-builder-columns.component';
import { AnyWidgetWrapperComponent } from './widget-builder/components/widget/any-widget-wrapper/any-widget-wrapper.component';
import { WidgetBuilderFormComponent } from './widget-builder/components/form/widget-builder-form/widget-builder-form.component';
import { WidgetColorPickerComponent } from './widget-builder/components/form/widget-builder-chart-series/widget-color-picker/widget-color-picker.component';
import { TwoDimensionalGridComponent } from './widget-builder/components/widget/two-dimensional-grid/two-dimensional-grid.component';
import { WidgetBuilderFlowComponent } from './widget-builder/components/form/widget-builder-flow/widget-builder-flow.component';
import { WidgetFlowBlockComponent } from './widget-builder/components/form/widget-flow-block/widget-flow-block.component';
import { WidgetBuilderBlockFormComponent } from './widget-builder/components/form/widget-builder-block-form/widget-builder-block-form.component';
import { WidgetBuilderDrilldownComponent } from './widget-builder/components/form/widget-builder-drilldown/widget-builder-drilldown.component';
import { CustomTooltipComponent } from './widget-builder/components/widget/any-widget/custom-tooltip.component';
import { ChartFilterDirective } from './widget-builder/directives/chart-filter.directive';
import { FilterSetDirective } from './widget-builder/directives/filter-set.directive';
import { WidgetPreviewPipe } from './widget-builder/services/widget-preview.pipe';
import { CheckboxFilterComponent } from './widget-builder/components/widget/filters/checkbox-filter/checkbox-filter.component';
import { DateFilterComponent } from './widget-builder/components/widget/filters/date-filter/date-filter.component';
import { InlineDateFilterComponent } from './widget-builder/components/widget/filters/inline-date-filter/inline-date-filter.component';
import { RangeFilterComponent } from './widget-builder/components/widget/filters/range-filter/range-filter.component';
import { InlineRangeFilterComponent } from './widget-builder/components/widget/filters/inline-range-filter/inline-range-filter.component';
import { DropdownFilterComponent } from './widget-builder/components/widget/filters/dropdown-filter/dropdown-filter.component';
import { InputFilterComponent } from './widget-builder/components/widget/filters/input-filter/input-filter.component';
import { NewPlaceholdersComponent } from './widget-builder/components/new-placeholders/new-placeholders.component';
import { WidgetBuilderLibraryComponent } from './widget-builder/widget-builder-library.component';
import { ExportDataButtonComponent } from './widget-builder/components/widget/export-data-button/export-data-button.component';
import { ColumnWithContextMenuComponent } from './widget-builder/components/widget/any-widget/column-context-menu/column-with-context-menu';
import { WidgetBuilderTileFormComponent } from './widget-builder/components/form/widget-builder-tile-form/widget-builder-tile-form.component';
import { DebugGlobalFiltersComponent } from './widget-builder/components/form/debug-global-filters/debug-global-filters.component';
import { DashboardFilterSetSettingsComponent } from './widget-builder/components/dashboard-filter-set-settings/dashboard-filter-set-settings.component';
import { DashboardFilterSetComponent } from './widget-builder/components/dashboard-filter-set/dashboard-filter-set.component';
import { ProgressColumnComponent } from 'data-processor/lib/widget-library/widget-builder/components/custom-components/progress-column.component';
import { LogTemplateComponent } from './widget-builder/components/widget/log-template/log-template.component';
import { GridWidgetWrapperComponent } from './widget-builder/components/grid-widget-wrapper/grid-widget-wrapper.component';
import { TileTemplateComponent } from './widget-builder/components/widget/tile-template/tile-template.component';
import { AccordionWrapperComponent } from './widget-builder/components/custom-components/accordion-wrapper/accordion-wrapper.component';
import { RadialTemplateComponent } from './widget-builder/components/widget/radial-template/radial-template.component';
import { WidgetBuilderEventHandlerComponent } from './widget-builder/components/form/widget-builder-event-handler/widget-builder-event-handler.component';
import { WidgetTemplateWrapperComponent } from './widget-builder/components/custom-components/widget-template-wrapper/widget-template-wrapper.component';
import { WidgetTemplateInterpolationPipe } from './widget-builder/services/widget-template.interpolation';
import { WidgetBuilderTemplatesComponent } from './widget-builder/components/form/widget-builder-templates/widget-builder-templates.component';
import { WorkDistributionWidgetComponent } from './widget-builder/components/pi-components/work-distribution-widget/work-distribution-widget.component';
import { WorkDistributionChartComponent } from './widget-builder/components/pi-components/work-distribution-widget/work-distribution-chart/work-distribution-chart.component';
import { MultipleRadialsComponent } from './widget-builder/components/pi-components/multiple-radials/multiple-radials.component';
import { PiRadialComponent } from './widget-builder/components/pi-components/multiple-radials/pi-radial/pi-radial.component';
import { ProgressMenuComponent } from './widget-builder/components/pi-components/progress-menu/progress-menu.component';
import { GridWithTabsComponent } from './widget-builder/components/pi-components/grid-with-tabs/grid-with-tabs.component';
import { WorkProgressWidgetComponent } from './widget-builder/components/pi-components/work-progress-widget/work-progress-widget.component';
import { HealthScoreTemplateComponent } from './widget-builder/components/widget/health-score-template/health-score-template.component';
import { PiGanttComponent } from './widget-builder/components/pi-components/pi-gantt/pi-gantt.component';
import { AccordionWithTabsComponent } from './widget-builder/components/pi-components/accordion-with-tabs/accordion-with-tabs.component';
import { WidgetBuilderAccordionColumnsComponent } from './widget-builder/components/form/widget-builder-accordion-columns/widget-builder-accordion-columns.component';
import { FeatureCompletionStatusComponent } from './widget-builder/components/pi-components/feature-completion-status/feature-completion-status.component';
import { FeatureCompletionStatusIssueComponent } from './widget-builder/components/pi-components/feature-completion-status/feature-completion-status-issue/feature-completion-status-issue.component';
import { WidgetBuilderStatusesComponent } from './widget-builder/components/form/widget-builder-statuses/widget-builder-statuses.component';
import { NewRangeFilterComponent } from 'data-processor/lib/widget-library/widget-builder/components/widget/filters/new-range-filter/new-range-filter.component';
import { IssueLifeCycleComponent } from './widget-builder/components/pi-components/issue-life-cycle/issue-life-cycle.component';
import { ExtensionsModule } from 'data-processor/lib/extensions/extensions.module';
import { WidgetBuilderEventDependenciesComponent } from './widget-builder/components/form/widget-builder-event-dependencies/widget-builder-event-dependencies.component';
import { ComplexNamedDropdownFilterComponent } from './widget-builder/components/widget/filters/complex-named-dropdown-filter/complex-named-dropdown-filter.component';
import { StatisticColumnComponent } from './widget-builder/components/custom-components/statistic-column/statistic-column.component';
import { SreSquaresComponent } from './widget-builder/components/sre-components/sre-squares/sre-squares.component';
import { SreSquareComponent } from './widget-builder/components/sre-components/sre-squares/sre-square/sre-square.component';
import { RadioButtonGroupFilterComponent } from './widget-builder/components/widget/filters/radio-button-group-filter/radio-button-group-filter.component';
import { SreHexagonsComponent } from './widget-builder/components/sre-components/sre-hexagons/sre-hexagons.component';
import { IssueLifeCycleStatusChangedDetailsComponent } from 'data-processor/lib/widget-library/widget-builder/components/pi-components/issue-life-cycle/status-changed-details/status-changed-details.component';
import { CodeIssueDetailsTemplateComponent } from 'data-processor/lib/widget-library/widget-builder/components/widget/code-issue-details-template/code-issue-details-template.component';
import { SreGanttComponent } from './widget-builder/components/sre-components/sre-gantt/sre-gantt.component';
import { SreGanttSideMenuComponent } from './widget-builder/components/sre-components/sre-gantt/sre-gantt-side-menu/sre-gantt-side-menu.component';
import { SreGanttWrapperComponent } from './widget-builder/components/sre-components/sre-gantt/sre-gantt-wrapper/sre-gantt-wrapper.component';
import { SreGanttColumnsComponent } from './widget-builder/components/sre-components/sre-gantt/sre-gantt-wrapper/sre-gantt-columns/sre-gantt-columns.component';
import { SreGanttColumnComponent } from './widget-builder/components/sre-components/sre-gantt/sre-gantt-wrapper/sre-gantt-columns/sre-gantt-column/sre-gantt-column.component';
import { SreGanttValuesComponent } from 'data-processor/lib/widget-library/widget-builder/components/sre-components/sre-gantt/sre-gantt-wrapper/sre-gantt-values/sre-gantt-values.component';
import { SreGanttValueComponent } from 'data-processor/lib/widget-library/widget-builder/components/sre-components/sre-gantt/sre-gantt-wrapper/sre-gantt-values/sre-gantt-value/sre-gantt-value.component';
import { SreGanttTreeComponent } from './widget-builder/components/sre-components/sre-gantt/sre-gantt-tree/sre-gantt-tree.component';
import { SreGanttTableComponent } from './widget-builder/components/sre-components/sre-gantt/sre-gantt-side-menu/sre-gantt-table/sre-gantt-table.component';
import { FactorScoresComponent } from 'data-processor/lib/widget-library/widget-builder/components/factor-scores/factor-scores.component';
import { WidgetBuilderHelpFormComponent } from './widget-builder/components/form/widget-builder-help-form/widget-builder-help-form.component';
import { WidgetBuilderHelpFormBlockComponent } from './widget-builder/components/form/widget-builder-help-form/widget-builder-help-form-block/widget-builder-help-form-block.component';
import { WidgetBuilderHelpTemplateComponent } from './widget-builder/components/form/widget-builder-help-form/widget-builder-help-template/widget-builder-help-template.component';
import { WidgetBuilderHelpFormInsightComponent } from './widget-builder/components/form/widget-builder-help-form/widget-builder-help-form-insight/widget-builder-help-form-insight.component';
import { WidgetBuilderHelpTemplatePreviewComponent } from './widget-builder/components/form/widget-builder-help-form/widget-builder-help-template-preview/widget-builder-help-template-preview.component';
import { WidgetContextHelpComponent } from './widget-builder/components/widget/widget-context-help/widget-context-help.component';
import { CodeQualitySummaryComponent } from './widget-builder/components/code-quality-summary/code-quality-summary.component';
import { CodeCoverageComponent } from './widget-builder/components/code-quality-summary/code-coverage/code-coverage.component';
import { CodeQualitySummaryItemComponent } from './widget-builder/components/code-quality-summary/code-quality-summary-item/code-quality-summary-item.component';
import { ScoreDoughnutComponent } from './widget-builder/components/score-doughnut/score-doughnut.component';
import { MetricLineTemplateComponent } from './widget-builder/components/widget/metric-line-template/metric-line-template.component';
import { WidgetBuilderColorThresholdsComponent } from './widget-builder/components/form/widget-builder-color-thresholds/widget-builder-color-thresholds.component';
import { WidgetBuilderAsyncQueryComponent } from './widget-builder/components/form/widget-builder-async-query/widget-builder-async-query.component';
import { PortfolioHealthScoresComponent } from './widget-builder/components/portfolio-health-scores/portfolio-health-scores.component';
import { PortfolioScoreSquareComponent } from 'data-processor/lib/widget-library/widget-builder/components/portfolio-health-scores/portfolio-score-square/portfolio-score-square.component';
import { PortfolioScoreSubcategoriesComponent } from './widget-builder/components/portfolio-score-subcategories/portfolio-score-subcategories.component';
import { ScoreSubcategoryComponent } from './widget-builder/components/portfolio-score-subcategories/score-subcategory/score-subcategory.component';
import { PortfolioStatisticsWidgetComponent } from './widget-builder/components/portfolio-statistics-widget/portfolio-statistics-widget.component';
import { TopPriorityRisksComponent } from 'data-processor/lib/widget-library/widget-builder/components/top-priority-risks/top-priority-risks.component';
import { WidgetBuilderColumnsSettingsComponent } from './widget-builder/components/form/widget-builder-columns/widget-builder-columns-settings/widget-builder-columns-settings.component';
import { WidgetBuilderColumnsReorderComponent } from './widget-builder/components/form/widget-builder-columns/widget-builder-columns-reorder/widget-builder-columns-reorder.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CkeditorPreventScrollDirective } from 'data-processor/lib/widget-library/widget-builder/components/form/widget-builder-help-form/widget-builder-help-template/ckeditor-prevent-scroll.directive';
import { CellCustomComponent } from './widget-builder/components/custom-components/cell-custom/cell-custom.component';
import { RisksHeaderComponent } from './widget-builder/components/risks-header/risks-header.component';
import { RiskCountSquareComponent } from './widget-builder/components/risks-header/risk-count-square/risk-count-square.component';
import { CompareMetricsWidgetComponent } from './widget-builder/components/compare-metrics-widget/compare-metrics-widget.component';
import { IntervalChooserComponent } from 'data-processor/lib/widget-library/widget-builder/components/compare-metrics-widget/interval-chooser/interval-chooser.component';
import { RangeSliderComponent } from './widget-builder/components/custom-components/range-slider/range-slider.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { WidgetBuilderSizeFormComponent } from './widget-builder/components/form/widget-builder-size-form/widget-builder-size-form.component';
import { WidgetScrollContainerComponent } from './widget-builder/components/custom-components/widget-scroll-container/widget-scroll-container.component';
import { WidgetTilesComponent } from './widget-builder/components/custom-components/widget-tiles/widget-tiles.component';
import { MergeRequestInfoModule } from 'data-processor/lib/widget-library/widget-builder/components/merge-request-info/merge-request-info.module';
import { SecurityPostureDetailsTemplateComponent } from 'data-processor/lib/widget-library/widget-builder/components/widget/security-posture-details-template/security-posture-details-template.component';
import { WidgetTilesNewComponent } from './widget-builder/components/custom-components/widget-tiles-new/widget-tiles-new.component';
import { WidgetTileComponent } from './widget-builder/components/custom-components/widget-tile/widget-tile.component';
import { FullTextWidth, MetricTileComponent } from './widget-builder/components/metric-tile/metric-tile.component';
import { ManageWidgetButtonComponent } from './widget-builder/components/widget/manage-widget-button/manage-widget-button.component';
import { AnyWidgetFilterItemValueComponent } from 'data-processor/lib/widget-library/widget-builder/components/widget/any-widget-filter-wrapper/any-widget-filter-item-value/any-widget-filter-item-value.component';

const components = [
    WidgetListComponent,
    WidgetLibraryComponent,
    WidgetListItemComponent,
    WidgetBuilderComponent,
    WidgetBuilderQueryComponent,
    WidgetBuilderChartOptionsComponent,
    WidgetBuilderFiltersComponent,
    AnyWidgetComponent,
    AnyWidgetFilterWrapperComponent,
    AnyWidgetFilterItemComponent,
    AnyWidgetFilterItemValueComponent,
    WidgetBuilderChartSeriesComponent,
    WidgetBuilderColumnsComponent,
    AnyWidgetWrapperComponent,
    WidgetBuilderFormComponent,
    WidgetColorPickerComponent,
    TwoDimensionalGridComponent,
    WidgetBuilderFlowComponent,
    WidgetFlowBlockComponent,
    WidgetBuilderBlockFormComponent,
    WidgetBuilderDrilldownComponent,
    InputFilterComponent,
    DropdownFilterComponent,
    RangeFilterComponent,
    InlineRangeFilterComponent,
    DateFilterComponent,
    InlineDateFilterComponent,
    CheckboxFilterComponent,
    NewRangeFilterComponent,
    CustomTooltipComponent,
    WidgetBuilderLibraryComponent,
    ColumnWithContextMenuComponent,
    ProgressColumnComponent,
    NewPlaceholdersComponent,
    ExportDataButtonComponent,
    WidgetBuilderTileFormComponent,
    DebugGlobalFiltersComponent,
    DashboardFilterSetSettingsComponent,
    DashboardFilterSetComponent,
    LogTemplateComponent,
    GridWidgetWrapperComponent,
    AccordionWrapperComponent,
    TileTemplateComponent,
    RadialTemplateComponent,
    WidgetBuilderTemplatesComponent,
    WidgetTemplateWrapperComponent,
    WorkDistributionWidgetComponent,
    WorkDistributionChartComponent,
    MultipleRadialsComponent,
    PiRadialComponent,
    ProgressMenuComponent,
    GridWithTabsComponent,
    WorkProgressWidgetComponent,
    PiGanttComponent,
    AccordionWithTabsComponent,
    WidgetBuilderEventHandlerComponent,
    WidgetBuilderAccordionColumnsComponent,
    HealthScoreTemplateComponent,
    FeatureCompletionStatusComponent,
    FeatureCompletionStatusIssueComponent,
    WidgetBuilderStatusesComponent,
    IssueLifeCycleComponent,
    IssueLifeCycleStatusChangedDetailsComponent,
    WidgetBuilderEventDependenciesComponent,
    ComplexNamedDropdownFilterComponent,
    RadioButtonGroupFilterComponent,
    SreSquaresComponent,
    FactorScoresComponent,
    CodeIssueDetailsTemplateComponent,
    SecurityPostureDetailsTemplateComponent,
    SreSquareComponent,
    SreHexagonsComponent,
    StatisticColumnComponent,
    SreGanttComponent,
    SreGanttSideMenuComponent,
    SreGanttWrapperComponent,
    SreGanttColumnsComponent,
    SreGanttColumnComponent,
    SreGanttValueComponent,
    SreGanttValuesComponent,
    SreGanttTreeComponent,
    SreGanttTableComponent,
    WidgetBuilderHelpFormComponent,
    WidgetBuilderHelpFormBlockComponent,
    WidgetBuilderHelpTemplateComponent,
    WidgetBuilderHelpFormInsightComponent,
    WidgetBuilderHelpTemplatePreviewComponent,
    WidgetContextHelpComponent,
    CodeQualitySummaryComponent,
    CodeCoverageComponent,
    CodeQualitySummaryItemComponent,
    ScoreDoughnutComponent,
    TopPriorityRisksComponent,
    WidgetBuilderColorThresholdsComponent,
    PortfolioHealthScoresComponent,
    PortfolioScoreSquareComponent,
    PortfolioScoreSubcategoriesComponent,
    ScoreSubcategoryComponent,
    PortfolioStatisticsWidgetComponent,
    MetricLineTemplateComponent,
    WidgetBuilderAsyncQueryComponent,
    CellCustomComponent,
    RisksHeaderComponent,
    RiskCountSquareComponent,
    CompareMetricsWidgetComponent,
    IntervalChooserComponent,
    RangeSliderComponent,
    WidgetBuilderSizeFormComponent,
    WidgetScrollContainerComponent,
    WidgetTilesComponent,
    MetricTileComponent,
    HelpForTemplateQueryDirective,
    FullTextWidth,
    ManageWidgetButtonComponent
];
const directives = [ChartFilterDirective, FilterSetDirective, CkeditorPreventScrollDirective];
const pipes = [WidgetPreviewPipe, WidgetTemplateInterpolationPipe];

@NgModule({
    declarations: [
        components,
        directives,
        pipes,
        WidgetBuilderColumnsSettingsComponent,
        WidgetBuilderColumnsReorderComponent,
        WidgetTilesNewComponent,
        WidgetTileComponent,
    ],
    imports: [
        CommonModule,
        ColorPickerModule,
        RouterModule,
        DebugVariablesModule,
        NgbTooltipModule,
        UiKitModule,
        ExtensionsModule,
        CKEditorModule,
        SharedComponentsModule,
        DragDropModule,
        ScrollingModule,
        MergeRequestInfoModule,
        NgbPopoverModule,
        PortalModule
    ],
    exports: [
        AnyWidgetComponent,
        AnyWidgetWrapperComponent,
        WidgetBuilderComponent,
        WidgetLibraryComponent,
        WidgetBuilderLibraryComponent,
        WidgetListItemComponent,
        ExportDataButtonComponent,
        ManageWidgetButtonComponent,
        ...components,
        ...directives,
        WidgetTileComponent,
    ],
    providers: [],
})
export class WidgetLibraryModule {}
