import { Component, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagePathPipeModule } from '@dagility-ui/shared-components/pipes/image-path.pipe';

@Component({
    selector: 'app-organization-logo',
    templateUrl: './organization-logo.component.html',
})
export class OrganizationLogoComponent {
    @Input() enabled: boolean = true;
    @Input() circle: boolean = true;
    @Input() size: number = 24;
    @Input() Organization: any;

    get initials(): string {
        const Organization = this.Organization;
        if (!Organization) {
            return '';
        }
        if (Organization.name) {
            return Organization.name.substr(0, 10);
        }
    }

    getbackgrountColor(): string {
        if (!this.Organization) {
            return 'white';
        }
        if (this.Organization.imageBackgroundColor) {
            return this.Organization.imageBackgroundColor;
        }
        return 'white';
    }

    get imagePath(): string {
        if (!this.Organization) {
            return null;
        }
        if (this.Organization.imagePath) {
            return this.Organization.imagePath;
        }
        return null;
    }
}

@NgModule({
    imports: [CommonModule, ImagePathPipeModule],
    declarations: [OrganizationLogoComponent],
    exports: [OrganizationLogoComponent]
})
export class OrganizationLogoModule {}
