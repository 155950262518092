<header class="row no-gutters mb-25px w-100 d-inline-flex">
    <dp-edit-job-definition-breadcrumb [path]="expandedControl.path$ | async"></dp-edit-job-definition-breadcrumb>
    <div class="ml-auto justify-content-end col-3 d-inline-flex">
        <div *ngIf="addKeys && addKeys.length" class="dropdown mr-10px" ngbDropdown placement="bottom-right" display="dynamic">
            <a class="dropdown-button cursor-pointer add-text" ngbDropdownToggle>
                <fa-icon class="mr-5px" icon="plus"></fa-icon>
            </a>
            <div class="dropdown-menu dropdown-menu-right font-10px" aria-labelledby="navbarDropdown" ngbDropdownMenu>
                <a *ngFor="let field of addKeys" class="dropdown-item cursor-pointer" (click)="addField(field.key)">
                    {{ field.name }}
                </a>
            </div>
        </div>
    </div>
</header>
<div class="job-definition-template overflow-auto items-container" [formGroup]="formSlice" [class.head-container]="isFirstLevel">
    <perfect-scrollbar>
        <div *ngFor="let field of fieldTypes | keyvalue: sortByFieldType" class="item-container mb-20px px-1">
            <ng-template
                *ngTemplateOutlet="
                    definition._class && isPrimitiveDataType((definition?._class)[field?.key])
                        ? selectTemplate
                        : isDeserializable(definition[field.key])
                        ? objectTemplate
                        : ['map', 'array', 'mapenum'].indexOf(field.value.type) > -1
                        ? iterativeTemplate
                        : primitiveTemplate;
                    context: { field: field, control: formSlice.get(field.key) }
                "
            ></ng-template>
        </div>
    </perfect-scrollbar>
</div>

<lib-validation-errors [control]="formSlice">
    <ng-template valError="collectionsRequired">{{ definitionErrors.collectionsRequired }}</ng-template>
    <ng-template valError="schemaIsRequired">{{ definitionErrors.schemaIsRequired }}</ng-template>
    <ng-template valError="levelIsRequired">{{ definitionErrors.levelIsRequired }}</ng-template>
</lib-validation-errors>

<ng-template #selectTemplate let-field="field" let-control="control">
    <div class="row no-gutters d-inline-flex flex-nowrap w-100">
        <div class="col select-container">
            <dp-configure-job-definition-select-type
                [label]="field.value.name"
                [formControl]="control"
                [showError]="false"
                (change)="handleFieldChangesOutput($event, field.key)"
            >
            </dp-configure-job-definition-select-type>
            <lib-app-warning-message
                [message]="definition.typingErrors[field.key] ? warnings.typingError(definition.typingErrors[field.key]) : null"
            >
            </lib-app-warning-message>
        </div>
    </div>
</ng-template>

<ng-template #objectTemplate let-field="field">
    <div class="no-gutters template-container p-4 w-100">
        <div class="col-2 d-inline-flex justify-content-start align-items-center pb-20px">
            <div class="header-text">{{ field.value.name }}</div>
        </div>
        <div class="row no-gutters">
            <div class="col info-container text-truncate">
                {{ definition[field.key].getInfo() }}
            </div>
            <a (click)="goToStep(field.key)" class="d-flex align-items-center font-weight-bold cursor-pointer ml-auto mr-4 proceed-text">
                Proceed
            </a>
            <fa-icon
                *ngIf="removeKeysArray.indexOf(field.key) > -1"
                class="cursor-pointer font-16px"
                icon="facTrash"
                (click)="removeField(field.key)"
            ></fa-icon>
        </div>
    </div>
</ng-template>

<ng-template #iterativeTemplate let-field="field" let-control="control">
    <ng-container [formGroup]="formSlice">
        <dp-edit-job-definition-iterative-template
            [definition]="definition[field.key]"
            [meta]="definition.meta"
            [key]="field.key"
            [formSlice]="control"
        ></dp-edit-job-definition-iterative-template>
    </ng-container>
</ng-template>

<ng-template #primitiveTemplate let-field="field" let-control="control">
    <div *ngIf="field.value.type == 'enum'" class="row no-gutters d-inline-flex flex-nowrap w-100">
        <lib-dropdown
            class="dropdown"
            [label]="field.value.name"
            [items]="field.value.items"
            placeholder="Select"
            valueKey="name"
            labelKey="name"
            id="name"
            [formControl]="control"
            class="w-100"
            [warningMessage]="definition.typingErrors[field.key] ? warnings.typingError(definition.typingErrors[field.key]) : null"
            [ngbTooltip]="formSlice.get(field.key).disabled ? 'Disabled while there is a string filter' : null"
            placement="bottom-left"
            tooltipClass="json-info-tooltip"
            container="body"
        >
            <ng-container *ngIf="contextHelpAnchors[field.value.name] && templateProvider?.contextHelpTemplate">
                <ng-template
                    *ngTemplateOutlet="
                        templateProvider?.contextHelpTemplate;
                        context: { contextHelpId: contextHelpAnchors[field.value.name] }
                    "
                ></ng-template>
            </ng-container>
        </lib-dropdown>
    </div>
    <div *ngIf="field.value.type == 'string'" class="row no-gutters d-inline-flex flex-nowrap w-100">
        <lib-input
            [label]="field.value.name"
            [formControl]="control"
            class="w-100"
            id="name"
            [warningMessage]="definition.typingErrors[field.key] ? warnings.typingError(definition.typingErrors[field.key]) : null"
        >
            <ng-container *ngIf="contextHelpAnchors[field.value.name] && templateProvider?.contextHelpTemplate">
                <ng-template
                    *ngTemplateOutlet="
                        templateProvider?.contextHelpTemplate;
                        context: { contextHelpId: contextHelpAnchors[field.value.name] }
                    "
                ></ng-template>
            </ng-container>
        </lib-input>
    </div>
    <div *ngIf="field.value.type == 'number'" class="row no-gutters d-inline-flex flex-nowrap w-100">
        <lib-input
            [label]="field.value.name"
            [formControl]="control"
            id="number"
            (change)="handleNumericChange($event, field.key)"
            [type]="'number'"
            class="w-100"
            [warningMessage]="definition.typingErrors[field.key] ? warnings.typingError(definition.typingErrors[field.key]) : null"
        >
            <ng-container *ngIf="contextHelpAnchors[field.value.name] && templateProvider?.contextHelpTemplate">
                <ng-template
                    *ngTemplateOutlet="
                        templateProvider?.contextHelpTemplate;
                        context: { contextHelpId: contextHelpAnchors[field.value.name] }
                    "
                ></ng-template>
            </ng-container>
        </lib-input>
    </div>
    <div *ngIf="field.value.type == 'boolean'" class="row no-gutters d-inline-flex flex-nowrap w-100">
        <div class="col-10 d-inline-flex justify-content-start">
            <trigger
                [formControl]="control"
                [warningMessage]="definition.typingErrors[field.key] ? warnings.typingError(definition.typingErrors[field.key]) : null"
            >
                {{ field.value.name }}
                <ng-container *ngIf="contextHelpAnchors[field.value.name] && templateProvider?.contextHelpTemplate">
                    <ng-template
                        *ngTemplateOutlet="
                            templateProvider?.contextHelpTemplate;
                            context: { contextHelpId: contextHelpAnchors[field.value.name] }
                        "
                    ></ng-template>
                </ng-container>
            </trigger>
        </div>
    </div>
</ng-template>

<ng-template #noData>
    <div class="row no-gutters cursor-default px-4 py-1">
        No Data
    </div>
</ng-template>

<ng-template #noKeys>
    <div class="row no-gutters cursor-default px-4 py-1">
        No Available Keys
    </div>
</ng-template>
