import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlContainer, FormArray, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { takeUntil, mergeAll } from 'rxjs/operators';

import { FiltersFormConfig } from 'data-processor/lib/widget-library/widget-builder/models/filters-form.config';
import { DropdownItem } from '@dagility-ui/kit';

import { WidgetFilter, WidgetFilterType, WidgetPosition } from '../../../models/any-widget.model';
import { WidgetBuilderFacade } from '../../../state/widget-builder.facade';
import { GLOBAL_FILTERS_EDITOR } from '../../../providers/global-filters-editor.token';
import { BlockForm } from '../widget-builder-block-form/widget-builder-block-form.component';
import { isDynamicWidthFilter } from '../../../services/widget-builder.util';

@Component({
    selector: 'dp-widget-builder-filters',
    templateUrl: './widget-builder-filters.component.html',
    styleUrls: ['./widget-builder-filters.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
    providers: [{ provide: BlockForm, useExisting: WidgetBuilderFiltersComponent }],
})
export class WidgetBuilderFiltersComponent extends BlockForm implements OnInit, OnDestroy {
    @Input() set data(filter: WidgetFilter) {
        this.form = this.facade.buildFilterGroup(filter);
    }
    @Input() filtersFormConfig: FiltersFormConfig;
    @Input() placeholders: DropdownItem[] = [];

    @Output() recalculatePlaceholders = new EventEmitter();
    @Output() formOnChange: EventEmitter<WidgetFilter> = new EventEmitter();

    get dropdownItems(): AbstractControl[] {
        return (this.form.get('items') as FormArray).controls;
    }

    get isUiFilterControl(): FormControl {
        return this.form.get('isUiFilter') as FormControl;
    }

    types: DropdownItem<WidgetFilterType>[] = [
        {
            value: WidgetFilterType.DROPDOWN,
            label: 'Dropdown',
        },
        {
            value: WidgetFilterType.INPUT,
            label: 'Input',
        },
        {
            value: WidgetFilterType.RANGE,
            label: 'Range',
        },
        {
            value: WidgetFilterType.DATE,
            label: 'Date',
        },
        {
            value: WidgetFilterType.CHECKBOX,
            label: 'Checkbox',
        },
        {
            value: WidgetFilterType.NEW_RANGE,
            label: 'New Range',
        },
        {
            value: WidgetFilterType.HIDDEN,
            label: 'Hidden',
        },
        {
            value: WidgetFilterType.RADIO_GROUP,
            label: 'Radio Group',
        },
    ].sort((a, b) => a.label.localeCompare(b.label));

    dependentValueItems: DropdownItem[] = [];

    type = WidgetFilterType;

    positionItems: DropdownItem<WidgetPosition>[] = [
        {
            value: WidgetPosition.LEFT,
            label: 'Left',
        },
        {
            value: WidgetPosition.RIGHT,
            label: 'Right',
        },
    ];

    isDynamicWidthFilter = isDynamicWidthFilter;

    private destroyed$ = new Subject<void>();

    constructor(public facade: WidgetBuilderFacade, @Inject(GLOBAL_FILTERS_EDITOR) public globalFiltersMode: boolean) {
        super();
    }

    changeForm(): void {
        this.formOnChange.emit(this.form.value);
    }

    ngOnInit() {
        this.dependentValueItems = (this.form.get('dependentValue').value ?? []).map((value: string) => ({ label: value, value }));
        if (this.filtersFormConfig?.hiddenFilterTypes) {
            this.types = this.types.filter(t => !this.filtersFormConfig.hiddenFilterTypes.includes(t.value));
        }
        this.listenIsUiFilter();
        this.updateDependentValueValidators();
    }

    handleFilterTypeChange(item: DropdownItem<WidgetFilterType> | null): void {
        this.form = this.facade.buildFilterGroup(item ? this.form.value : ({} as any));

        if ([WidgetFilterType.DROPDOWN, WidgetFilterType.HIDDEN].includes(item?.value)) {
            this.recalculatePlaceholders.emit();
        }
        this.changeForm();
    }

    addFilterItem(): void {
        (this.form.get('items') as FormArray).push(this.facade.buildDropdownItemForm({} as DropdownItem));
    }

    removeOption(optionIndex: number): void {
        (this.form.get('items') as FormArray).removeAt(optionIndex);
        this.changeForm();
    }

    handleDynamicChange(dynamic: boolean, filterGroup: FormGroup): void {
        if (dynamic) {
            filterGroup.removeControl('items');
            filterGroup.addControl('query', this.facade.buildQueryForm({} as any, { fromFilter: true }));
        } else {
            filterGroup.removeControl('query');
            filterGroup.addControl('items', new FormArray([]));
        }
        this.changeForm();
    }

    handleFilterDependentChange(filterControl: FormGroup, data: DropdownItem[] | string[]): void {
        filterControl.get('dependencies').patchValue(data.map(item => (typeof item === 'string' ? item : item.value)));
        this.changeForm();
    }

    handleFilterValueDependentChange(data: string[]) {
        this.form.get('dependentValue').patchValue(data);
        this.dependentValueItems = data.map((value: string) => ({ label: value, value }));
        this.changeForm();
    }

    addTagFn(value: string) {
        return {
            label: value,
            value,
        };
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }

    private listenIsUiFilter() {
        this.isUiFilterControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
            if (!value) {
                this.form.get('tooltip').patchValue('');
            }
        });
    }

    private updateDependentValueValidators() {
        of(this.form.get('type').valueChanges, this.form.get('valueDependency').valueChanges)
            .pipe(mergeAll(), takeUntil(this.destroyed$))
            .subscribe(() => {
                this.form.get('dependentValue').updateValueAndValidity();
            });
    }
}
