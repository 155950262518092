<perfect-scrollbar>
    <h4 class="mt-0">Overview</h4>
    <div [style.--col-count]="colCount$ | async" class="body2 merge-info">
        <div>
            <span class="name">Merge/Pull Request ID</span>
            <a class="text-wrap" [attr.href]="data.merge.value" target="_blank">{{ data.merge.label }}</a>
        </div>
        <div>
            <span class="name">Status</span>
            <dp-merge-request-status [status]="$any(data.status)"></dp-merge-request-status>
        </div>
        <div>
            <span class="name">Title</span>
            <span>{{ data.title }}</span>
        </div>
        <div>
            <span class="name">Requested by</span>
            <span>{{ data.requestedBy }}</span>
        </div>
        <div>
            <span class="name">Requested Time</span>
            <span>{{ data.requestedTime }}</span>
        </div>
        <div>
            <span class="name">Merged Time</span>
            <span>{{ data.mergedTime }}</span>
        </div>
        <div>
            <span class="name">Merged by</span>
            <span>{{ data.mergedBy }}</span>
        </div>
        <div>
            <span class="name">Time of Merge</span>
            <span>{{ data.mergedTime }}</span>
        </div>
        <div>
            <span class="name">Source Branch</span>
            <span>{{ data.sourceBranch }}</span>
        </div>
        <div>
            <span class="name">Target Branch</span>
            <span>{{ data.targetBranch }}</span>
        </div>
        <div *ngIf="data.items?.length" class="tickets-container">
            <span class="name pt-1"
                >Item IDs <ng-container *ngTemplateOutlet="countBadge; context: { $implicit: data.items.length, ml: 1 }"></ng-container
            ></span>
            <div class="d-flex flex-wrap p-1 tickets-links">
                <a
                    libTag
                    *ngFor="let ticket of data.items"
                    [href]="ticket.value"
                    target="_blank"
                    [tag]="ticket.label"
                    [ngbTooltip]="ticket.label"
                    libTooltipWhenOverflow
                    [textElementSelectorFn]="itemTextSelector"
                    triggers="manual"
                    (click)="handleBlur($event)"
                ></a>
            </div>
        </div>
    </div>

    <ng-container *ngIf="data.description">
        <h4>Description</h4>
        <span class="body2" [innerHTML]="data.description | safeHtml: 'html'"></span>
    </ng-container>

    <h4>
        Approvers <ng-container *ngTemplateOutlet="countBadge; context: { $implicit: data.approvers?.length || 0, ml: 2 }"></ng-container>
    </h4>
    <dp-merge-request-approvers
        *ngIf="data.approvers?.length; else notApprovedTemplate"
        [approvers]="data.approvers"
    ></dp-merge-request-approvers>

    <ng-template #notApprovedTemplate>
        <span class="body1 disable">
            Not approved yet
        </span>
    </ng-template>

    <ng-container *ngIf="data.initialCommits?.length">
        <h4 class="my-3">Initial commits</h4>

        <dp-merge-request-commit
            *ngFor="let commit of data.initialCommits; last as last"
            [class.mb-3]="!last"
            [commit]="commit"
        ></dp-merge-request-commit>
    </ng-container>

    <ng-container *ngIf="data.updatesCommits?.length">
        <h4 class="my-3">Updates</h4>

        <dp-merge-request-commit
            *ngFor="let commit of data.updatesCommits; last as last"
            [class.mb-3]="!last"
            [commit]="commit"
        ></dp-merge-request-commit>
    </ng-container>
</perfect-scrollbar>

<ng-template #countBadge let-count let-ml="ml">
    <span class="custom-badge custom-badge-background text-white count-badge ml-{{ ml }}">{{ count }}</span>
</ng-template>
