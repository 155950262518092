import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { SidenavStorageService, UiKitModule } from '@dagility-ui/kit';

import { OrganizationLogoModule } from '@app/shared/components/organization-logo/organization-logo.component';
import { SidenavUserStorageService } from '@app/core/services/sidenav-user-storage.service';
import { UserPicModule } from '@dagility-ui/shared-components/components/user-pic/user-pic.component';
import { TourModule } from '@dagility-ui/shared-components/modules/tour/tour.module';

import { OrganizationsBentoMenuModule } from '../pages/admin/organizations/organizations-switcher/organizations-bento-menu.module';
import { HeaderComponent } from './components/header/header.component';
import { RouterStateHistory } from './services/router-state-history.service';
import { Starter } from './services/starter';
import { HTTP_INTERCEPTOR_PROVIDERS } from './http-interceptors';

@NgModule({
    imports: [
        HttpClientModule,
        HttpClientXsrfModule.withOptions({
            cookieName: 'XSRF-TOKEN',
            headerName: 'X-XSRF-TOKEN',
        }),
        RouterModule,
        OrganizationLogoModule,
        UserPicModule,
        UiKitModule,
        TourModule,
        OrganizationsBentoMenuModule,
    ],
    declarations: [HeaderComponent],
    exports: [HeaderComponent],

    providers: [
        RouterStateHistory,
        ...HTTP_INTERCEPTOR_PROVIDERS,
        {
            provide: APP_INITIALIZER,
            useFactory: (srv: Starter) => () => srv.start(),
            deps: [Starter],
            multi: true,
        },
        {
            provide: SidenavStorageService,
            useClass: SidenavUserStorageService,
        },
    ],
})
export class CoreModule {
    // Inject RouterStateHistory to make it eager...
    constructor(private routerStateHistory: RouterStateHistory) {}
}
