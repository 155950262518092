import { FormGroup, ValidatorFn } from '@angular/forms';

export class RecipientListValidator {
    static validateRecipientList(): ValidatorFn {
        return (formGroup: FormGroup) => {
            const portfoliosControl = formGroup.get('portfolios');
            const applicationsControl = formGroup.get('applications');
            const teamsControl = formGroup.get('teams');
            const rolesControl = formGroup.get('roles');
            const usersControl = formGroup.get('users');

            if (!portfoliosControl || !applicationsControl || !teamsControl || !usersControl || !rolesControl) {
                return null;
            }
            if (
                portfoliosControl.value.length === 0 &&
                applicationsControl.value.length === 0 &&
                teamsControl.value.length === 0 &&
                (!Array.isArray(usersControl.value) || (usersControl.value && usersControl.value.length === 0)) &&
                (!Array.isArray(rolesControl.value) || (usersControl.value && rolesControl.value.length === 0))
            ) {
                return { recipientListInvalid: true };
            } else {
                return null;
            }
        };
    }
}
