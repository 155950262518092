import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Directive, ElementRef, EventEmitter, HostBinding, Inject, OnDestroy, Optional, inject } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter as filterObs, map, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { isEqual } from 'lodash';

import { isDefined } from '@dagility-ui/kit';
import { WidgetFilter } from '../../../models/any-widget.model';

import { getWidgetWidth } from '../../../services/widget-builder.util';
import { WidgetDebuggerState } from '../../../services/widget.debugger';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseWidgetFilter implements ControlValueAccessor, OnDestroy {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    static FILTER_TOKEN = 'filter';

    @HostBinding('class') padding = 'pt-1 widget-filter';
    @HostBinding('hidden') hidden = false;
    @HostBinding('attr.data-main-filter') mainFilter = false;
    @HostBinding('class.popup-filter') popup = false;

    @HostBinding('style.margin-right') get marginRight(): string {
        if (this.popup) {
            return '0';
        }

        return this.filter.position === 'RIGHT' ? '0.25rem' : '1rem';
    }

    @HostBinding('style.margin-left') get marginLeft() {
        return this.filter.position === 'RIGHT' ? 'auto' : '';
    }

    @HostBinding('style.margin-top') get marginTop() {
        return this.filter.hideLabel ? 'auto' : '';
    }

    @HostBinding('style.--wc-filter-min-width') get minWidthVar() {
        const width = getWidgetWidth(null as undefined)(this.filter.minWidth);

        return isDefined(width) ? `${width}px` : width;
    }

    formControl = new FormControl();
    subscription = Subscription.EMPTY;
    filtersReset: EventEmitter<any>;

    protected destroyed$ = new Subject<void>();
    protected reload$ = new Subject<void>();

    constructor(@Optional() @Inject(BaseWidgetFilter.FILTER_TOKEN) public filter: WidgetFilter) {
        this.mainFilter = !!filter?.mainFilter;

        this.listenVisibleState();
    }

    static transformDate(date: Parameters<DatePipe['transform']>[0]) {
        const datePipe = new DatePipe('en-US');

        return datePipe.transform(date, 'yyyy-MM-dd');
    }

    static filterValueChanged<F, T, S>(mapFn: (value: F, index: number) => T, startWithFn: () => S) {
        return function(source: Observable<F>) {
            return source.pipe(
                map(mapFn),
                startWith(startWithFn() as unknown),
                pairwise(),
                filterObs(([prev, curr]) => !isEqual(prev, curr)),
                map(items => items[1])
            );
        };
    }

    getFilterText(): { title: string; value: any } {
        return null;
    }

    registerOnTouched(): void {
        // empty impl
    }

    registerOnChange(fn: (_: any) => void): void {
        this.subscription.unsubscribe();
        this.subscription = this.formControl.valueChanges.subscribe(v => fn(v));
    }

    writeValue(obj: unknown): void {
        this.formControl.patchValue(obj, { emitEvent: false });
    }

    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.formControl.disable({ emitEvent: false });
        } else {
            this.formControl.enable({ emitEvent: false });
        }
    }

    reload(): void {
        this.reload$.next();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.destroyed$.next();
    }

    private listenVisibleState() {
        const dataSource = inject(WidgetDebuggerState);
        if (!this.filter || !dataSource) {
            return;
        }

        const cdr = inject(ChangeDetectorRef);

        dataSource.filterVisibleState?.[this.filter.placeholder]?.pipe(takeUntil(this.destroyed$)).subscribe(isVisible => {
            this.hidden = !isVisible;
            cdr.markForCheck();
        });
    }
}
