import { Component, EventEmitter, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { takeUntil, tap } from 'rxjs/operators';

import { ToasterService } from '../../../services/Toaster/toaster.service';
import { writeContents } from '@dagility-ui/kit';

const logSizeGap = 15728640;

@Component({
    selector: 'lib-log',
    templateUrl: './log.component.html',
    styleUrls: ['./log.component.scss'],
})
export class LogComponent implements OnInit, OnDestroy {
    @ViewChild(PerfectScrollbarComponent) scrollbar: PerfectScrollbarComponent;
    @Input() height: number;
    @Input() logs$: Observable<any>;
    @Input() contentTemplateRef: TemplateRef<any>;
    @Input() title: string;
    @Input() scrollToBottom = true;
    @Input() fileName: string;
    @Input() request$: Observable<any>;
    @Input() searchable: boolean = false;

    searchQuery: string = '';
    maxLogSize = logSizeGap;

    protected destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private toaster: ToasterService) {}

    ngOnInit(): void {
        this.logs$ = this.logs$.pipe(
            tap(() => {
                setTimeout(() => {
                    if (this.scrollToBottom) {
                        this.scrollbar.directiveRef.scrollToBottom(0);
                    }
                });
            })
        );
    }

    sizeof(val: any): number {
        let bytes = 0;

        function sizeOf(v: any) {
            if (v !== null && v !== undefined) {
                switch (typeof v) {
                    case 'number':
                        bytes += 8;
                        break;
                    case 'string':
                        bytes += v.length * 2;
                        break;
                    case 'boolean':
                        bytes += 4;
                        break;
                    case 'object':
                        const objClass = Object.prototype.toString.call(v).slice(8, -1);
                        if (objClass === 'Object' || objClass === 'Array') {
                            for (const key in v) {
                                if (!v.hasOwnProperty(key)) {
                                    continue;
                                }
                                sizeOf(v[key]);
                            }
                        } else {
                            bytes += v.toString().length * 2;
                        }
                        break;
                }
            }
            return bytes;
        }

        return sizeOf(val);
    }

    download(): void {
        const fileName = this.fileName + `_${Date.now()}.log`;
        this.request$.pipe(takeUntil(this.destroy$)).subscribe(blob => {
            writeContents(blob, fileName); // file extension
        });
    }

    copyToClipboard(): void {
        const data = this.scrollbar.directiveRef.elementRef.nativeElement.innerText;
        navigator.clipboard.writeText(data).then(
            () => {
                this.toaster.successToast({ title: 'Success', content: 'Copied to clipboard' });
            },
            () => {
                this.toaster.errorToast({ title: 'Failure', content: 'Something went wrong' });
            }
        );
    }

    ngOnDestroy(): void {
        this.destroy$.emit();
        this.destroy$.complete();
    }
}
