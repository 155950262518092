import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, of, throwError as observableThrowError } from 'rxjs';
import { catchError, delay, switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private toaster: ToastrService,
        @Inject('environment') private environment: any,
        @Inject('featureToggleService') @Optional() private featureToggleService: {  isActive(name: string): Observable<boolean> }
    ) {}

    $timeObs = of(null);
    toastActive = false;
    extraDataCollection: any[] = [];

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.headers.has('skip-global-error')) {
            req.headers.delete('skip-global-error');
            return next.handle(req);
        }

        return next.handle(req).pipe(
            catchError((err: HttpErrorResponse) =>
                (this.featureToggleService ? this.featureToggleService.isActive('hide_error_toaster') : of(false)).pipe(
                    switchMap((hideErrorToaster: boolean) => {
                        if (!hideErrorToaster) {
                            this.parseError(err);
                        }

                        return observableThrowError(err);
                    })
                )
            )
        );
    }

    private parseError(err: HttpErrorResponse): void {
        if (err.message) {
            this.handleSpringError(err).then(() => {});
        } else if (err.error.error) {
            this.handleBaseHttpServiceError(err);
        } else {
            this.toaster.error('Handling unknown error format');
        }
    }

    private async handleSpringError(err: HttpErrorResponse): Promise<void> {
        let error = err.error;
        if (error instanceof Blob) {
            error = JSON.parse(new TextDecoder().decode(await (error as Blob).arrayBuffer()));
        }
        const extraData = [
            {
                timestamp: error.timestamp,
                serviceName: error.serviceName,
                error: typeof error.error === 'object' ? JSON.stringify(error.error) : error.error,
                httpCode: error.httpCode,
                stackTrace: error.stackTrace,
                pageUrl: window.location.href,
                requestUrl: err.url,
                clientName: this.environment.envCustomer,
                details: { traceId: error.traceId },
            },
        ];
        let message;
        let title;
        const isError = true;
        let hideReport = false;
        switch (err.status) {
            case 401:
                title = 'Unauthorized';
                message =
                    'User authentication is required before accessing this service. Please verify credentials.';
                break;
            case 403:
                title = 'Permission Denied';
                message =
                    'Permission is required to access this service. Please check permission settings.';
                break;
            case 400:
                title = 'Bad Request';
                message =
                    'There was a problem with the request. We are working on resolving this issue quickly. Please try again later.';
                if (error.detailedMessage) {
                    hideReport = true;
                }
                break;
            default:
                title = 'Error';
                message =
                    'An unexpected error occurred. We are working on resolving this issue quickly. Please try again later.';
                break;
        }

        // With this we find all previous error toasts and remove them.
        // Default library doesn't support comparing only title to prevent duplicates.
        const removeErrorToasts = () => {
            this.toaster.toasts
                .filter(item => item.message && JSON.parse(item.message).isError)
                .forEach(toast => this.toaster.remove(toast.toastId));
        };
        const clearErrorToasts = () => {
            this.toaster.toasts
                .filter(item => item.message && JSON.parse(item.message).isError)
                .forEach(toast => this.toaster.clear(toast.toastId));
        };

        // We wait for 30 seconds and then forcefully clear all error toasts.
        if (!this.toastActive) {
            this.toastActive = true;
            this.$timeObs.pipe(delay(30000)).subscribe(() => {
                this.extraDataCollection = [];
                this.toastActive = false;
                clearErrorToasts();
            });
        }

        this.extraDataCollection = [...extraData, ...this.extraDataCollection];

        removeErrorToasts();

        this.toaster.error(
            JSON.stringify({
                extraData: this.extraDataCollection,
                message: error.detailedMessage || message,
                hideReport,
                isError,
            }),
            title,
            { timeOut: 30000, disableTimeOut: 'extendedTimeOut', onActivateTick: true }
        );
    }

    private handleBaseHttpServiceError(errorResp: HttpErrorResponse): void {
        const err = errorResp.error;
        this.toaster.error(`${err.status},  Message: ${err.error}`, `${err.message}`);
    }
}
