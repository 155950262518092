import { Injectable, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize, tap } from 'rxjs/operators';
import { Observable, noop } from 'rxjs';

import { SvgZoomDirective } from '@dagility-ui/shared-components';
import { generateUUID } from '@dagility-ui/kit';
import { ProcessorMonitoringService } from '../../processor-monitoring.service';
import { EditJobDefinitionService } from '../edit-job-definition.service';
import { DebugContainer } from '../models/debug-container';
import { JobDefinitionBuilderComponent } from '../job-definition-builder/job-definition-builder.component';
import { StartDebugVariablesComponent } from '../job-definition-builder/start-debug-variables/start-debug-variables.component';

@Injectable()
export class JobDefinitionFacade implements OnDestroy {
    builderView: JobDefinitionBuilderComponent;

    debugContainer$: Observable<DebugContainer>;

    lastDebugVariableValues: Record<string, unknown> = {};

    get zoomControl(): SvgZoomDirective | null {
        return this.builderView && this.builderView.view && this.builderView.view.zoom;
    }

    constructor(private api: ProcessorMonitoringService, private store: EditJobDefinitionService, private modalService: NgbModal) {}

    startDebug(publish?: boolean) {
        const jobDefinition = this.store.buildJDToSave();
        jobDefinition.toolId = this.store.toolId;

        let promise = Promise.resolve({});

        if (!!jobDefinition.variables && !!jobDefinition.variables.length) {
            const modalRef = this.modalService.open(StartDebugVariablesComponent, {
                centered: true,
            });
            modalRef.componentInstance.variables = (jobDefinition.variables ?? []).map((variable: any) => ({
                ...variable,
                value: this.lastDebugVariableValues[variable.name] ?? undefined,
            }));
            promise = modalRef.result;
        }

        promise
            .then(variables => {
                const debugId = generateUUID();
                this.store.debugContext.debugId = debugId;
                this.store.debugContext.setState({
                    debug: true,
                    loading: true,
                    publish,
                });
                this.lastDebugVariableValues = variables;

                this.debugContainer$ = this.api
                    .startDebug(
                        debugId,
                        {
                            left: jobDefinition,
                            middle: [...this.store.breakpoints],
                            right: variables,
                        },
                        !!publish
                    )
                    .pipe(
                        tap(container => this.handleDebugContainer(container)),
                        finalize(() =>
                            this.store.debugContext.setState({
                                loading: false,
                                activeTab: this.store.debugContext.value.activeTab || 'variables',
                            })
                        )
                    );
            })
            .catch(noop);
    }

    resumeDebug() {
        this.store.debugContext.setFetch(true);

        this.debugContainer$ = this.api.resumeDebug(this.store.debugContext.debugId, [...this.store.breakpoints]).pipe(
            tap(container => this.handleDebugContainer(container)),
            finalize(() => this.store.debugContext.setFetch(false))
        );
    }

    cancelDebug() {
        if (!this.store.debugContext.debugId) {
            return;
        }

        this.store.debugContext.setState({
            debug: false,
            debuggedBlockName: null,
            publish: false,
        });

        this.api.cancelDebug(this.store.debugContext.debugId).subscribe(
            () => this.store.debugContext.resetDebug(),
            () => this.store.debugContext.resetDebug()
        );
    }

    zoom(shift: number) {
        if (!this.zoomControl) {
            return;
        }

        this.zoomControl.zoom(shift);
    }

    onTabChange({ previous, current }: { previous: string; current: string }) {
        if (current === 'json') {
            this.store.modeChange('JSON');
        } else if (previous === 'json') {
            this.store.modeChange('FORM');
        }

        if (this.store.menu.find(({ field }) => field === current)) {
            this.store.goToPath([current]);
        }
    }

    private handleDebugContainer(container: DebugContainer) {
        if (!container || !container.position || container.exception) {
            this.store.debugContext.setState({
                debug: false,
                debuggedBlockName: null,
                publish: false,
            });
        } else if (container) {
            this.store.debugContext.setState({
                debuggedBlockName: container.position,
            });
        }

        if (container) {
            this.store.updateDebugContainer(container.variables, container.logs);
        }
    }

    ngOnDestroy() {
        this.cancelDebug();
    }
}
