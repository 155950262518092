import {
    ChangeDetectorRef,
    Component,
    Directive,
    EventEmitter,
    inject,
    Inject,
    Input,
    OnDestroy,
    Output,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { DropdownItem, FormDirtyDirective, NavComponent, validateFormAndDisplayErrors } from '@dagility-ui/kit';

import {
    ComponentOutletDirective,
    WIDGET_EXTENSIONS_INNER,
    WidgetExtensionInner,
    WidgetExtensionInnerFormComponent,
    WidgetExtensionInnerFormContext,
} from '../../../../../extensions';
import { WidgetBuilderStore } from '../../../services/widget-builder.store';
import { WidgetFilter, WidgetHelp, WidgetType } from '../../../models/any-widget.model';
import { AsyncQueryBlock, FilterBlock, LayerBlock, QueryBlock } from '../../../models/query.block';
import { WidgetBuilderFacade } from '../../../state/widget-builder.facade';
import { GLOBAL_FILTERS_EDITOR } from '../../../providers/global-filters-editor.token';
import { FiltersFormConfig } from 'data-processor/lib/widget-library/widget-builder/models/filters-form.config';
import { DATA_MORPH_FEATURE_TOGGLE } from 'data-processor/tokens';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BlockForm {
    @ViewChild(FormDirtyDirective) formDirtyDirective: FormDirtyDirective;
    protected ft = inject(DATA_MORPH_FEATURE_TOGGLE);

    help: TemplateRef<unknown>;

    form: FormGroup;

    getHelpTemplate = (layer: LayerBlock | QueryBlock | FilterBlock | any): TemplateRef<unknown> => {
        return null;
    }

    toSave() {
        return this.ft.isActiveSync('query_library') ? this.form.getRawValue() : this.form.value;
    }
}

@Component({
    selector: 'dp-widget-builder-block-form',
    templateUrl: './widget-builder-block-form.component.html',
    styleUrls: ['./widget-builder-block-form.component.scss'],
    host: {
        '[class.p-4]': '!showTabs',
    },
})
export class WidgetBuilderBlockFormComponent implements OnDestroy {
    headers = {
        filter: 'Filter',
        'filter-dynamic': 'Filter',
        query: 'Query',
        layer: 'General',
        handler: 'Event Handler',
    };
    canDelete = false;

    @Input() filtersFormConfig: FiltersFormConfig;
    @Output() filtersFormOnChange: EventEmitter<WidgetFilter> = new EventEmitter();
    @Input() set block(model: LayerBlock | QueryBlock | FilterBlock | any) {
        this._block = model;
        this.canDelete = model.viewType !== 'layer' || this.checkHasParent(model.id);
        this.activeTab = null;

        if (['filter', 'filter-dynamic'].includes(model.viewType)) {
            this.recalculatePlaceholders();
        }
    }

    @Input() extensionsEnabled = false;

    @Output() close = new EventEmitter();

    @Output() save = new EventEmitter();

    @Output() delete = new EventEmitter();

    @Output() preview = new EventEmitter();

    @ViewChild(BlockForm) formComponent: BlockForm;

    @ViewChild(NavComponent) set nav(navComponent: NavComponent) {
        if (navComponent) {
            navComponent.tabSet.animation = false;
        }
    }

    @ViewChildren(ComponentOutletDirective) extensionsComponents: QueryList<
        ComponentOutletDirective<WidgetExtensionInnerFormComponent, WidgetExtensionInnerFormContext>
    >;

    type = WidgetType;

    showContextHelp = true;

    activeTab: string | null = null;

    get block(): any {
        return this._block;
    }

    get extensions() {
        return (this._extensions || []).filter(ex => ex.form);
    }

    getContext(extension: WidgetExtensionInner): WidgetExtensionInnerFormContext {
        return {
            widgetId: this.facade.anyWidgetId,
            dashboardWidgetId: this.facade.externalWidgetId,
            state:
                this.store.extensionsState[extension.extensionId] ||
                (this.facade.extensions ?? []).find(
                    (illuminateExtension: any) => illuminateExtension.extension.id === extension.extensionId
                ),
            onSave: state => {
                this.store.extensionsState[extension.extensionId] = state;

                this.handleCancel();
            },
            extensionId: extension.extensionId,
            onCancel: this.handleCancel.bind(this),
        };
    }

    get header(): string {
        return this.globalFiltersMode ? 'Dashboard Filters Settings' : this.showTabs ? 'Settings' : this.widgetTabHeader;
    }

    get widgetTabHeader(): string {
        return `${(this.headers as any)[this.block.viewType] || 'Drilldown'}`;
    }

    get showTabs(): boolean {
        return !this.globalFiltersMode && this.block.viewType === 'layer';
    }

    get activeTabForm(): FormGroup {
        return this.formComponent?.form;
    }

    _block: any;

    placeholders: DropdownItem[] = [];

    seenTabs: Record<string, any> = {};

    recalculatePlaceholders() {
        const index = this.store.value.layers[this.block.layerId].filters.indexOf(this.block.id);

        this.placeholders = this.store.value.layers[this.block.layerId].filters.slice(0, index).map(id => {
            const { placeholder } = this.store.value.filters[id];

            return {
                value: placeholder,
                label: placeholder,
            };
        });
    }

    constructor(
        public store: WidgetBuilderStore,
        private facade: WidgetBuilderFacade,
        private cdr: ChangeDetectorRef,
        // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
        @Inject(WIDGET_EXTENSIONS_INNER) private _extensions: WidgetExtensionInner[],
        @Inject(GLOBAL_FILTERS_EDITOR) private globalFiltersMode: boolean
    ) {}

    changeFiltersForm(filter: WidgetFilter) {
        this.filtersFormOnChange.emit(filter);
    }

    handleSaveHelp(help: WidgetHelp) {
        this.store.updateHelp(this.block.id, help);

        this.handleCancel();
    }

    handlePreview(help: WidgetHelp) {
        this.store.updateHelp(this.block.id, help);

        this.preview.emit();
    }

    handleSave(): void {
        if (this.filtersFormConfig?.queryReplacement) {
            this.formComponent.form.removeControl('query');
        }
        if (this.formComponent.form.valid) {
            this.save.emit(this.formComponent.toSave());
            if (this.filtersFormConfig?.queryReplacement) {
                this.formComponent.form.addControl('query', this.facade.buildQueryForm({} as any, { fromFilter: true }));
            }
        } else {
            validateFormAndDisplayErrors(this.formComponent.form);
        }
    }

    handleDelete(): void {
        this.delete.emit();
    }

    checkHasParent(blockId: string): boolean {
        return this.store.value.actions.some(({ to }) => to === blockId);
    }

    saveExtensions() {
        this.facade.extensionsComponent = this.extensionsComponents.map(({ instance }) => instance as any);
    }

    isAsyncQuery(block: any) {
        return block instanceof AsyncQueryBlock;
    }

    handleTabChange(event: NgbNavChangeEvent) {
        setTimeout(() => {
            this.activeTab = event.nextId;
            this.seenTabs[event.nextId] = true;
            window.dispatchEvent(new Event('resize'));
        });
    }

    handleCancel() {
        this.close.emit();
    }

    ngOnDestroy() {
        this.saveExtensions();

        this.facade.dirty$.next(false);
    }
}
