import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    EventEmitter,
    Inject,
    Input,
    Output,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Warning } from '@dagility-ui/kit';
import { Observable, of } from 'rxjs';
import { map, pluck, switchMap, tap } from 'rxjs/operators';

import { DATA_MORPH_AUTH, DataMorphAuthService } from 'data-processor/tokens';

import { DataMorph } from '../../models/dp-dashboard.model';
import { DashboardWidgetSettingsManager } from '../../services/dashboard-widget-settings.manager';
import { DpGlobalFiltersService } from '../../services/global-filters.service';
import { DashboardWidgetSettingsComponent } from '../dashboard-widget-settings/dashboard-widget-settings.component';
import { DashboardMessageBus } from '../../../widget-builder/services/message-bus/message-bus';
import { WIDGET_BUILDER_TAB, WidgetBuilderTab } from '../../../widget-builder/providers/widget-builder-tab.token';

@Component({
    selector: 'dp-widget-builder-dashboard',
    templateUrl: './widget-builder-dashboard.component.html',
    styleUrls: ['./widget-builder-dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DpGlobalFiltersService, DashboardMessageBus],
})
export class WidgetBuilderDashboardComponent {
    @Input() canEditLibrary = true;

    @Input() extensionsEnabled = false;

    @Output() quit = new EventEmitter<string>();

    @ContentChild(TemplateRef) filters: TemplateRef<any>;
    @ViewChild(DashboardWidgetSettingsComponent) dpDashboardWidgetSettings: DashboardWidgetSettingsComponent;
    @ViewChildren(WIDGET_BUILDER_TAB) tabs: QueryList<WidgetBuilderTab>;

    dashboardId: number;
    widgetId: number;
    options: Record<string, any> = {};
    widget: DataMorph.IlluminateDashboardWidget;
    previousUrl: string = null;

    dashboardWidgetId$ = this.route.params.pipe(pluck('dashboardWidgetId'));
    activeTab$ = of('widget');
    extensions$ = this.dashboardWidgetId$.pipe(
        switchMap(id => this.manager.getExtensions(id)),
        map(extensions => extensions || [])
    );

    data$: Observable<DataMorph.IlluminateDashboardWidget> = this.dashboardWidgetId$.pipe(
        switchMap(dashboardWidgetId => this.manager.getWidget(dashboardWidgetId)),
        tap(widget => {
            const { options, illuminateOptions } = widget;
            this.widget = widget;

            this.options = {
                ...options,
                ...illuminateOptions,
            };
        })
    );

    filters$ = this.dashboardWidgetId$.pipe(
        switchMap(id => this.manager.getDashboard(id)),
        switchMap(dashboard =>
            this.manager.getLastAppliedFilters(dashboard.id ?? dashboard.dashboardId).pipe(
                tap(filters => {
                    this.globalFilters.defaultFiltersValues = filters;
                }),
                map(() => dashboard)
            )
        ),
        map(dashboard => {
            if (dashboard.dashboardOptions) {
                return {
                    server: dashboard.dashboardOptions.server,
                    dashboardId: dashboard.dashboardId,
                    filters: dashboard.dashboardOptions.filters ?? [],
                    dashboard,
                };
            }

            return {
                server: false,
                dashboardId: undefined,
                filters: dashboard.filter || [],
                dashboard,
            };
        })
    );

    getWarning(): Warning {
        return {
            title: 'Discard',
            message: 'Are you sure you want to discard changes?',
        };
    }

    constructor(
        private manager: DashboardWidgetSettingsManager,
        private route: ActivatedRoute,
        private router: Router,
        private globalFilters: DpGlobalFiltersService,
        @Inject(DATA_MORPH_AUTH) public auth: DataMorphAuthService
    ) {
        if (this.router.getCurrentNavigation()?.extras?.state) {
            const { fromLink } = this.router.getCurrentNavigation().extras.state;

            this.previousUrl = fromLink ?? null;
        }
    }

    isFormsDirty() {
        return this.tabs.toArray().some(tab => !tab.canDeactivate());
    }

    handleQuit(closeEditMode?: boolean) {
        if (this.previousUrl) {
            this.router.navigate([this.previousUrl], closeEditMode ? {} : { state: { editDashboard: true } });

            return;
        }

        this.quit.emit(this.previousUrl);
    }

    handleClose(event: any) {
        this.handleQuit(event);
    }
}
