<form [formGroup]="formControl" [attr.id]="userId">
    <div class="row">
        <div class="col-md-6">
            <lib-input label="First name" placeholder="Your First Name" formControlName="firstName" maxlength="100"></lib-input>
        </div>
        <div class="col-md-6">
            <lib-input label="Last name" placeholder="Your Last Name" formControlName="lastName" maxlength="100"></lib-input>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <lib-input
                label="Email"
                placeholder="Your Email"
                formControlName="email"
                maxlength="100"
                [setDisable]="profileUnsaved ? false : emailExist"
            ></lib-input>
        </div>
        <div class="col-md-6" *ngIf="!isCalibrate">
            <lib-input label="Job Title" placeholder="Your Position" formControlName="position" maxlength="100"></lib-input>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6" *ngIf="!isCalibrate">
            <lib-dropdown
                placeholder="Select Manager"
                id="manager"
                label="Manager"
                class="select-manager"
                formControlName="managerId"
                labelKey="fullName"
                valueKey="id"
                [searchable]="true"
                [items]="managers$ | async"
                [loading]="managersLoading"
                (filter)="getManagers($event)"
            >
            </lib-dropdown>
        </div>
        <div class="col-md-6">
            <label>Roles</label>
            <checkbox-group
                [isInline]="true"
                [blueCheck]="true"
                [items]="rolesCheckboxes$ | async"
                formControlName="roles"
            ></checkbox-group>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <lib-dropdown
                placeholder="Select Business Role"
                id="role"
                label="Business Role"
                formControlName="businessRoleId"
                labelKey="name"
                valueKey="id"
                [searchable]="true"
                [items]="businessRoles"
                (filter)="searchBusinessRoles($event)"
            >
                <span context-help-id="user-business-role"></span>
            </lib-dropdown>
        </div>
        <div class="col-md-6" *ngIf="notificationServiceActive">
            <lib-dropdown
                placeholder="Select Communication channel(-s)"
                id="communication_channel"
                label="My preferred communication channel"
                formControlName="communicationChannels"
                labelKey="name"
                valueKey="transport"
                [items]="defaultCommunicationChannels"
                [multiple]="true"
                [labelTemplateRef]="communicationChannelLabel"
                [optionTemplateRef]="communicationChannelOption"
                [clearable]="false"
            >
                <ng-template #communicationChannelOption let-item="item">
                    <div (click)="communicationChannelsHandler($event, item)">
                        <div
                            style="width: 100%; height: 30px; display: flex; align-items: center; justify-content: start;"
                            class="d-inline-flex"
                        >
                            <checkbox
                                style="margin: 0 5px 0 5px !important;"
                                [value]="isSelectedChannel(item)"
                                [disabled]="item.defaultValues && item.defaultValues.notSelectable"
                            ></checkbox>
                            <img
                                style="width: 18px; height: 18px; background-size: 100%; margin: 0 5px 0 0;"
                                [class.invert-dark-mode]="item.darkModeInvertIconColor"
                                src="{{ item.icon }}"
                                alt=""
                            />
                            <span style="font-size: 14px">{{ item.name }}</span>
                        </div>
                    </div>
                </ng-template>
                <ng-template #communicationChannelLabel let-item="item">
                    <div class="d-inline-flex" style="height: 18px;">
                        <img style="width: 18px; height: 18px; background-size: 100%; margin: 0 5px 0 0;" src="{{ item.icon }}" alt="" />
                        <span style="font-size: 14px">{{ item.name }}</span>
                    </div>
                </ng-template>
            </lib-dropdown>
        </div>
    </div>
</form>
