import {
    ChangeDetectorRef,
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    Injector,
    Input,
    OnChanges,
    SimpleChanges,
    ViewContainerRef,
} from '@angular/core';

import { ComponentOutletComponent, ComponentOutletContent } from '../../models/component';

@Directive({
    selector: '[dpComponentOutlet]',
})
export class ComponentOutletDirective<T extends object, C extends object> implements OnChanges {
    static COMPONENT_OUTLET_CONTEXT = 'COMPONENT_OUTLET_CONTEXT';

    @Input('dpComponentOutlet') outlet: ComponentOutletContent<T>;
    @Input('dpComponentOutletContext') context: C;

    componentRef: ComponentRef<unknown>;

    get instance() {
        return this.componentRef?.instance;
    }

    constructor(
        private readonly viewContainerRef: ViewContainerRef,
        private factoryResolver: ComponentFactoryResolver,
        private injector: Injector
    ) {}

    ngOnChanges({ outlet }: SimpleChanges) {
        if (this.componentRef) {
            this.componentRef.injector.get(ChangeDetectorRef).markForCheck();
        }

        if (!outlet) {
            return;
        }

        this.viewContainerRef.clear();

        if (isComponent(this.outlet)) {
            const factory = this.factoryResolver.resolveComponentFactory(this.outlet.component);

            const injector = Injector.create({
                parent: this.injector,
                providers: [
                    {
                        provide: ComponentOutletDirective.COMPONENT_OUTLET_CONTEXT,
                        useValue: this.context,
                    },
                ],
            });

            this.componentRef = this.viewContainerRef.createComponent(factory, 0, injector);
        }
    }
}

function isComponent<T extends object>(content: ComponentOutletContent<T>): content is ComponentOutletComponent<T> {
    return content instanceof ComponentOutletComponent;
}
