import { Deserializable } from '../../../models/job-definition';
import { Condition } from './operand';

export class ConditionAction extends Deserializable {
    _arrays = {};
    _enums = {};
    _class = {
        condition: Condition,
    };
    _maps = {};
    meta = {
        _arrays: this._arrays,
        _enums: this._enums,
        _class: this._class,
        _maps: this._maps,
    };

    condition: Condition = null;

    constructor(instance?: any) {
        super();
        this.fillByInstance(ConditionAction, instance);
    }
}
