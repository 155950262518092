<div class="d-flex flex-column flex-1" [style.width]="!selected ? '100%' : 'calc(100% - 300px)'">
    <div class="width-fit-content ml-auto">
        <div class="d-flex flex-wrap">
            <div *ngFor="let legend of chartOptions.legends" class="min-width-fit-content mr-2 d-flex align-items-center">
                <div class="square mr-1" [style.background]="legend.color"></div>
                {{ legend.value }}
            </div>
        </div>
    </div>
    <lib-chart [type]="'ganttChart'" class="chart" [options]="chartOptions" [checkHostResize]="true" (event)="handleClick($event)">
    </lib-chart>
</div>
<div *ngIf="selected" class="slider px-3 position-absolute right-0" [class.slider-active]="selected">
    <ng-container *ngIf="!noData; else noDataTmpl">
        <div class="slider-header">
            <div class="d-flex justify-content-between">
                <div class="headers">
                    <h3 class="color-brand mb-2">{{ selected }}</h3>
                    <h4 class="font-weight-bold">Items Details</h4>
                </div>

                <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="row mt-3">
                <div class="col">Items Closed</div>
                <div class="col text-right">
                    {{ subChart.issuesCompleted }}
                    <span [style.color]="getColor((subChart.issuesCompleted / subChart.totalIssues) * 100)">{{
                        subChart.issuesCompleted / subChart.totalIssues | percent
                    }}</span>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col">Total Items</div>
                <div class="col text-right">{{ subChart.totalIssues }}</div>
            </div>

            <div *ngIf="subChart.totalIssues != 0" class="row cursor-pointer link-normal mt-4 ml-2" (click)="linkClicked.emit(selected)">
                <fa-icon icon="facArrowRightRegular" class="fa mr-1"></fa-icon>
                View Release Progress
            </div>
        </div>

        <div class="slider-footer">
            <h4 class="font-weight-600">Items by Status</h4>
            <dp-work-distribution-chart
                class="sub-chart"
                [chartOptions]="subChart"
                [chartData]="subChartData"
                [width]="'200px'"
                [height]="'200px'"
            >
            </dp-work-distribution-chart>
        </div>

        <div class="position-absolute ml-1" style="bottom: 0">
            <span class="d-block font-12px secondary-text-and-icons">* Epics are not included here</span>
        </div>
    </ng-container>

    <ng-template #noDataTmpl>
        <button
            type="button"
            class="close d-block"
            [style.marginTop.px]="18"
            aria-label="Close button"
            aria-describedby="modal-title"
            (click)="close()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="d-flex justify-content-center" [style.marginTop.px]="80">
            <span class="title3 disable">
                There are no data yet
            </span>
        </div>
    </ng-template>
</div>
