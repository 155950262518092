<div
    class="wrapper d-flex flex-row wrapper-{{ data.type }}">
    <ng-container *ngIf="data.type !== 'debt'; else debtTemplate">
        <div class="col-3 p-0 mr-4">
            <div class="row-no-gutters label-text justify-content-start">
                <ng-container
                    [ngTemplateOutlet]="nameTemplate"
                    [ngTemplateOutletContext]="{ $implicit: data }"
                ></ng-container>
            </div>

            <div class="row no-gutters label-text">
                <span
                    class="capital-text-value"
                    [ngbTooltip]="data.description || ''"
                    container="body"
                    openDelay="600"
                >
                    {{ data.label | thousandsFormat }}
                </span>
            </div>
            <ng-container *ngIf="data.type === 'vulnerability'">
                <div class="d-inline-flex label-text">
                    <span class="lower-case-text-value"
                      [style.white-space]="'nowrap !important'"
                    >out of 100
                    </span>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <div class="d-inline-flex flex-1 align-items-center mt-4">
        <ng-container
            [ngTemplateOutlet]="data.chart ? chart : emptyTemplate"
            [ngTemplateOutletContext]="{ $implicit: data }"
        ></ng-container>
    </div>
</div>

<div *ngIf="data.statusBarValue" class="status-bar">
    <div
        *ngFor="let i of statusBars"
        class="status-bar-value"
        [class.status-bar-failed]="i <= 3 && i <= data.statusBarValue"
        [class.status-bar-warning]="i > 3 && i <= 7 && i <= data.statusBarValue"
        [class.status-bar-success]="i > 7 && i <= 11 && i <= data.statusBarValue"
        [class.status-bar-none]="i > data.statusBarValue && i <= 12"
    ></div>
</div>

<ng-template #nameTemplate>
    <div *ngIf="data.title"
         class="body5 font-weight-600 label-text"
         [style.white-space]="'nowrap !important'"
    >
        {{ data.title }}
        <span context-help-id="{{ data.contextHelpLink }}"></span>
    </div>
</ng-template>

<ng-template #debtTemplate>
    <div class="col-3 align-items-center label-text p-0 mr-4">
        <div class="row-no-gutters label-text justify-content-start">
            <ng-container
                [ngTemplateOutlet]="nameTemplate"
                [ngTemplateOutletContext]="{ $implicit: data }"
            ></ng-container>
        </div>
        <div class="row-no-gutters label-text justify-content-start">
            <span class="capital-text-value">{{ data.label['days'] }}</span><span class="lower-case-text-value">d</span>
            <span class="capital-text-value">{{ data.label['hours'] }}</span><span class="lower-case-text-value">h</span>
        </div>
    </div>
</ng-template>

<ng-template #chart let-item>
    <ng-container *ngIf="item as data">
        <lib-chart
                   [type]="data.chart.type"
                   [options]="data.chart.options"
                   [height]="'min'">
        </lib-chart>
    </ng-container>
</ng-template>

<ng-template #emptyTemplate></ng-template>
