import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ENV_TOKEN } from '@app/tokens';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-noskript',
    templateUrl: './noskript.component.html',
})
export class NoskriptComponent {
    noSkriptUrl = this.env.noSkriptUrl;

    url$ = this.route.queryParams.pipe(
        map(params => {
            if (params.projectId && params.appId && params.pipelineId) {
                return `${this.noSkriptUrl}?appId=${params.appId}&projectId=${params.projectId}&projectName=${params.projectName}&appName=${params.appName}&pipelineId=${params.pipelineId}`;
            }
            return this.noSkriptUrl;
        })
    );

    constructor(private router: Router, private route: ActivatedRoute, @Inject(ENV_TOKEN) private env: Env) {}
}
