import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-alert-card',
    templateUrl: './alert-card.component.html',
    styleUrls: ['./alert-card.component.scss'],
})
export class AlertCardComponent {
    @Input() alert: any;
    @Input() importance: string;
    @Input() frequency: string;

    showAll: boolean = false;

    getUsers(users: { ids: { name: string }[] }) {
        if (users === null || !users.ids.length) {
            return 'All';
        }
        return this.showAll
            ? users.ids.map(user => user.name).join(', ')
            : users.ids
                .map(user => user.name)
                .slice(0, 6)
                .join(', ');
    }

    getTeams(teams: { ids: { name: string }[] }) {
        return teams === null || teams.ids === null || teams.ids.length === 0 ? 'All' : teams.ids.map(team => team.name).join(', ');
    }
}
