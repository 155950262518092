import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@app/auth';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';
import { AuthorityPermissions, RbacAction, RbacStaticType } from '@dagility-ui/kit';

@Injectable({
    providedIn: 'root'
})
export class ProjectRightsService implements OnDestroy {
    readonly READ: RbacAction = 'read';
    readonly CREATE: RbacAction = 'modify';
    readonly MODIFY: RbacAction = 'modify';
    readonly DELETE: RbacAction = 'delete';
    readonly EXPORT: RbacAction = 'export';
    readonly CHANGE_STATUS: RbacAction = 'changeStatus';

    private rights: AuthorityPermissions;
    private rights$: Observable<AuthorityPermissions>;
    public initialized = false;
    private destroy$ = new Subject();
    isOrganizationOn$ = new BehaviorSubject(false);
    isProjectAccessible$ = new BehaviorSubject(false);

    get canRead() {
        return this.rights[this.READ] || false;
    }

    get canCreate() {
        return this.rights[this.CREATE] || false;
    }

    get canModify() {
        return this.rights[this.MODIFY] || false;
    }

    get canDelete() {
        return this.rights[this.DELETE] || false;
    }

    get canChangeStatus() {
        return this.rights[this.CHANGE_STATUS] || false;
    }

    get canExport() {
        return this.rights[this.EXPORT] || false;
    }

    get getProjectRights() {
        return this.rights;
    }

    get getProjectRightsObs() {
        return this.rights$;
    }

    constructor(private authService: AuthService) {
        this.initService();
    }

    initService(): Observable<AuthorityPermissions> {
        if (!this.rights$) {
            this.rights$ = this.authService.getRights(RbacStaticType.OBJECT, 'Project').pipe(takeUntil(this.destroy$), shareReplay(1));
            this.rights$.pipe(takeUntil(this.destroy$)).subscribe(rights => {
                this.rights = rights;
                this.initialized = true;
            });
            return this.rights$;
        } else {
            return this.rights$;
        }
    }

    hasRight(action: RbacAction): Observable<boolean> {
        return this.authService.hasPermission(RbacStaticType.OBJECT, 'Project', action);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }
}
