import { Injectable, Injector, Optional } from '@angular/core';
import { take } from 'rxjs/operators';
import { ModalService } from '@dagility-ui/kit';

import { DpEditGroupComponent } from '../dp-edit-group/dp-edit-group.component';
import { DpDashboardStore } from '../../state/dp-dashboard.store';
import { DashboardGroupState } from '../../state/dp-dashboard.state.model';
import {
    DashboardWidgetSettingsFormExtension,
    DashboardWidgetSettingsComponent,
} from '../dashboard-widget-settings/dashboard-widget-settings.component';
import { DataMorph } from '../../models/dp-dashboard.model';
import { DpDashboardService } from '../../services/dp-dashboard.service';

@Injectable()
export class AddWidgetFlowManager {
    constructor(
        private modal: ModalService,
        @Optional() private widgetFormExtension: DashboardWidgetSettingsFormExtension,
        private store: DpDashboardStore,
        private api: DpDashboardService,
        private injector: Injector
    ) {}

    async add(widget: any) {
        let options: Record<string, any> = {
            groupId: widget.groupId,
        };
        let illuminateOptions: Record<string, any> = {};
        let flowState = 'ADD_WIDGET';
        let groupState: Record<string, any>;

        // eslint-disable-next-line no-constant-condition
        while (true) {
            if (flowState === 'ADD_WIDGET') {
                const { action, state } = await this.modal
                    .open(
                        DashboardWidgetSettingsComponent,
                        {
                            injector: this.injector,
                        },
                        {
                            settings: {
                                dashboardId: this.store.value.dashboardId,
                                data: widget.data,
                                options,
                                groupId: options?.groupId || null,
                                id: widget.id,
                                illuminateOptions,
                            } as DataMorph.IlluminateDashboardWidget,
                            dashboard: this.store.value,
                            widgetImport: true,
                            formExtensionType: this.widgetFormExtension,
                        }
                    )
                    .componentInstance.result$.pipe(take(1))
                    .toPromise();

                flowState = action;
                illuminateOptions = state.illuminateOptions;
                options = state.options;
            } else if (flowState === 'ADD_NEW_GROUP') {
                try {
                    groupState = await this.addNewGroup();
                    groupState.id = await this.saveGroup(groupState);
                    options.groupId = groupState.id;
                } catch {
                    groupState = null;
                }
                flowState = 'ADD_WIDGET';
            } else if (flowState === 'SAVE') {
                return {
                    options: {
                        ...options,
                        groupId: options.groupId,
                    },
                    illuminateOptions,
                };
            } else if (flowState === 'CLOSE') {
                return null;
            }

            this.modal.dismissAll();
        }
    }

    async addNewGroup() {
        const groupState: DashboardGroupState = {
            id: null,
            tabId: null,
            widgets: [],
            name: 'New Group',
            dashboardId: this.store.value.dashboardId,
            groupOrder: 0,
            definition: {
                type: null,
                headerColor: null,
                headerFontSize: null,
            },
        };

        return await this.modal.open(
            DpEditGroupComponent,
            {injector: this.injector},
            {
                group: groupState,
                modal: true,
            }
        ).result;
    }

    async saveGroup(groupState: any) {
        const { activeTab, dashboardId } = this.store.value;

        try {
            const group = await this.api
                .saveGroup({
                    ...groupState,
                    id: null,
                    dashboardId,
                    tabId: activeTab,
                })
                .toPromise();

            this.store.addGroup(group);

            return group.id;
        } catch (e) {
            console.error(e);

            return null;
        }
    }
}
