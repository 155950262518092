<div *ngIf="supportedFormats.length" ngbDropdown [container]="'body'">
    <button class="btn btn-link text-secondary default-size px-1" ngbDropdownToggle ngbTooltip="Export data" container="body">
        <fa-icon class="font-14px" icon="exportWidgetData"></fa-icon>
    </button>

    <div ngbDropdownMenu>
        <div *ngFor="let exportType of supportedFormats" class="cursor-pointer font-11px" ngbDropdownItem (click)="export.emit(exportType.value)">
            {{ exportType.label }}
        </div>
    </div>
</div>
