import { WorkflowActionType, WorkflowAction } from '../models/actions';
import { matcher, TransformActionTo } from './action.matcher';
import { CalculationAction } from './edit-workflow-action/models/calculation.action';
import { ChannelAction } from './edit-workflow-action/models/channel.action';
import { ConditionAction } from './edit-workflow-action/models/condition.action';
import { DataSourceAction } from './edit-workflow-action/models/datasource.action';
import { ForkAction } from './edit-workflow-action/models/fork.action';
import { LoopAction } from './edit-workflow-action/models/loop.action';
import { PublishAction } from './edit-workflow-action/models/publish.action';
import { ReferenceAction } from './edit-workflow-action/models/reference.action';
import { TransformationAction } from './edit-workflow-action/models/transformation.action';
import { UpdateAction } from './edit-workflow-action/models/update.action';
import { GroovyAction } from './edit-workflow-action/models/groovy.action';
import { QueryAction } from './edit-workflow-action/models/query.action';

const counter: { [K in WorkflowActionType]: number } = {
    DATASOURCE: 0,
    CONDITION: 0,
    GROOVY: 0,
    LOOP: 0,
    PUBLISH: 0,
    TRANSFORMATION: 0,
    UPDATE: 0,
    CHANNEL: 0,
    FORK: 0,
    CALCULATION: 0,
    REFERENCE: 0,
    QUERY: 0,
};

const PACKAGE_NAME = 'com.ust.processor.action';
const getId = (name: string) => `${PACKAGE_NAME}.${name}`;

const fields: Record<string, any> = {
    LOOP: () => ({
        ...{
            actions: new Array(),
            id: getId('LoopAction'),
        },
    }),
    FORK: () => ({
        ...{
            actions: new Array(),
            id: getId('ForkAction'),
        },
    }),
    UPDATE: () => ({
        id: getId('UpdateAction'),
    }),
    TRANSFORMATION: () => ({
        id: getId('TransformationAction'),
    }),
    REFERENCE: () => ({
        id: getId('ReferenceAction'),
    }),
    PUBLISH: () => ({
        id: getId('PublishAction'),
    }),
    GROOVY: () => ({
        id: getId('GroovyAction'),
        script: '',
    }),
    DATASOURCE: () => ({
        id: getId('DataSourceAction'),
    }),
    CONDITION: () => ({
        id: getId('ConditionAction'),
    }),
    CHANNEL: () => ({
        id: getId('ChannelAction'),
    }),
    CALCULATION: () => ({
        id: getId('CalculationAction'),
    }),
    QUERY: () =>
        ({
            id: getId('QueryAction'),
            query: '',
            from: null,
            variable: '',
        } as any),
};

export function generateWorkflowAction(action: WorkflowAction) {
    return {
        ...action,
        name: `${action.type.charAt(0).toUpperCase()}${action.type.slice(1).toLowerCase()} ${counter[action.type]++}`,
        ...(fields[action.type] ? fields[action.type]() : {}),
    };
}

export const calculateMaxBranchLength: any = matcher({
    FORK: action => Math.max(...action.actions.map(actions => actions.reduce((acc, a) => acc + calculateMaxBranchLength(a), 0))),
    LOOP: action => action.actions.reduce((acc, a) => acc + calculateMaxBranchLength(a), 0),
    _: () => 1,
});

export const actionsFactory = {
    CALCULATION: CalculationAction,
    CHANNEL: ChannelAction,
    CONDITION: ConditionAction,
    DATASOURCE: DataSourceAction,
    FORK: ForkAction,
    LOOP: LoopAction,
    GROOVY: GroovyAction,
    PUBLISH: PublishAction,
    REFERENCE: ReferenceAction,
    TRANSFORMATION: TransformationAction,
    UPDATE: UpdateAction,
    QUERY: QueryAction,
};

export const flatContainerActions: any = matcher({
    FORK: action =>
        action.actions.reduce((acc, actions) => acc.concat(actions.reduce((acc, a) => acc.concat(flatContainerActions(a)), [])), []),
    LOOP: action => action.actions.reduce((acc, a) => acc.concat(flatContainerActions(a)), []),
    _: action => [action],
});

export const deepestChild: TransformActionTo<number> = matcher({
    FORK: ({ actions }) => 1 + actions.reduce((acc, forkActions) => acc + Math.max(...forkActions.map(deepestChild)), 0),
    LOOP: ({ actions }) => 1 + (!!actions.length ? Math.max(...actions.map(deepestChild)) : 0),
    _: () => 0,
});

export function generateMainActionsBranch(actions: WorkflowAction[]) {
    const actionsMap = actions.reduce<Record<string, WorkflowAction>>((acc, action) => {
        acc[action.name] = action;

        return acc;
    }, {});

    const branch = new Map();

    if (actions.length) {
        let action = actionsMap[actions[0].name];

        while (!!action && !branch.has(action.name)) {
            branch.set(action.name, action);
            action = actionsMap[action.next];
        }
    }

    return branch;
}
