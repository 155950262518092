import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { cloneDeep, differenceWith, isEmpty } from 'lodash';
import pick from 'lodash/pick';

import { APPLIED_FILTERS_PLACEHOLDER, RELOAD_SAVED_FILTERS, SAVED_FILTERS_PLACEHOLDER } from 'data-processor/tokens';
import { isDefined } from '@dagility-ui/kit';

import { AnyWidgetModel, WidgetFilter, WidgetFilterType } from '../models/any-widget.model';
import { DataMorph } from '../../dashboard/models/dp-dashboard.model';
import {
    DropdownOptionItem,
    ExecutionParametersApi,
    ExecutionResult,
    Placeholders,
    WidgetScript,
} from '../services/widget-builder.service';
import { WidgetEvent } from './message-bus/widget-event.manager';
import { findEventHandler } from './message-bus/message-bus';
import { isDynamicFilter, parseError } from './widget-builder.util';
import { FilterValueChange, WidgetWorkflow } from './widget.flow';
import { PreferredFiltersContainer } from './preferred-filters.container';
import { GLOBAL_FILTERS_EDITOR } from '../providers/global-filters-editor.token';

@Injectable()
export class GlobalFiltersWorkflow extends WidgetWorkflow {
    updateFromFilters = false;
    preferredFilters = new PreferredFiltersContainer();

    private dashboardStub: any;
    private isSlideInOutPanelActive = !inject(GLOBAL_FILTERS_EDITOR, { optional: true });

    private filtersInited = false;

    get isFunnelIconEnabled() {
        return false;
    }

    getDefaultPlaceholders() {
        if (this.isSlideInOutPanelActive) {
            this.initFilters(this.widget$.value);
        }

        return this.defaultPlaceholdersFn$(null).pipe(this.filterLoadingState(), this.reloadSavedFilters(), this.mergePlaceholders());
    }

    buildCalculateWidgetParams(): ExecutionParametersApi {
        return {
            ...this.position,
            widget: null,
            filters: this.debug ? this.position.widget.filters || [] : [],
        };
    }

    calculate(): Observable<ExecutionResult> {
        const params = this.buildCalculateWidgetParams();

        if (isEmpty(this.widget$.value.filters) || this.position.range.from?.level === 1) {
            return of({
                errors: [],
                results: [],
                placeholders: cloneDeep(params.placeholders),
            });
        }

        return this.dynamicDashboard
            ? this.api.calculateDynamicDashboard(params)
            : this.debug
            ? this.api.calculateDashboard(params)
            : this.api.calculateDashboardById(this.id, params);
    }

    getPlaceholdersFromCurrentLevel(state: ExecutionResult) {
        return state.placeholders;
    }

    findNearestBreakpoint() {
        if (!this.breakpoints.size) {
            return;
        }

        this.position.widget.id = [...this.breakpoints][0].widgetId;

        super.findNearestBreakpoint();
    }

    setDashboard(dashboardStub: any) {
        this.dashboardStub = dashboardStub;
    }

    drilldown() {}

    getPlaceholdersForPersisting(): {
        placeholders: Placeholders;
        selectedPreferredValues: Record<string, DropdownOptionItem[]>;
        preferredFilters: WidgetFilter[];
        preferredItems: Record<string, DropdownOptionItem[]>;
    } {
        const placeholders = cloneDeep(this.placeholders);

        const preferredFilters: WidgetFilter[] = [];
        const selectedPreferredValues: Record<string, DropdownOptionItem[]> = {};
        const preferredItems: Record<string, DropdownOptionItem[]> = {};

        for (const filter of this.widget$.value.filters) {
            if (!isDynamicFilter(filter) || filter.type === WidgetFilterType.HIDDEN) {
                continue;
            }

            selectedPreferredValues[filter.placeholder] = [];
            preferredItems[filter.placeholder] = [];
            const filterValue = placeholders[filter.placeholder];
            const { preferredFilterOptions, filterOptionsMap } = (placeholders._options[filter.placeholder] ?? []).reduce(
                (acc, item) => {
                    if (!isDefined(item.value)) {
                        return acc;
                    }

                    if (item.preferred) {
                        acc.preferredFilterOptions.push(item.value);
                        preferredItems[filter.placeholder].push(item);
                    }

                    acc.filterOptionsMap[item.value.toString()] = item;

                    return acc;
                },
                { preferredFilterOptions: [], filterOptionsMap: {} as Record<string, DropdownOptionItem<any>> }
            );
            const preferredFilterOptionsSet = new Set(preferredFilterOptions);

            if (Array.isArray(filterValue)) {
                const newFilterValue: DropdownOptionItem[] = [];

                filterValue.forEach(item => {
                    if (!preferredFilterOptionsSet.has(item)) {
                        newFilterValue.push(item);
                    } else {
                        selectedPreferredValues[filter.placeholder].push(filterOptionsMap[item]);
                    }
                });

                if (newFilterValue.length !== filterValue.length) {
                    preferredFilters.push(filter);
                    placeholders[filter.placeholder] = newFilterValue;
                }
            } else if (preferredFilterOptionsSet.has(filterValue)) {
                delete placeholders[filter.placeholder];
                preferredFilters.push(filter);
                selectedPreferredValues[filter.placeholder].push(filterOptionsMap[filterValue]);
            }

            selectedPreferredValues[filter.placeholder] = this.getNonAppliedFilterValues(
                filter,
                selectedPreferredValues[filter.placeholder] ?? []
            );
        }

        placeholders._options = {};

        return { placeholders, preferredFilters, selectedPreferredValues, preferredItems };
    }

    initLoading() {
        super.initLoading();

        if (!this.isCascadingFiltersEnabled || !this.isSlideInOutPanelActive) {
            return;
        }

        for (const filterPlaceholder of this.reloadedFilters) {
            this.filters.get(filterPlaceholder).disable({ emitEvent: false });
        }
    }

    private getNonAppliedFilterValues(filter: WidgetFilter, preferredValues: DropdownOptionItem[]) {
        let appliedFilterValue = this.placeholders[SAVED_FILTERS_PLACEHOLDER]?.[filter.placeholder];

        if (!isDefined(appliedFilterValue)) {
            return preferredValues;
        }
        appliedFilterValue = Array.isArray(appliedFilterValue) ? appliedFilterValue : [appliedFilterValue];

        return differenceWith(preferredValues, appliedFilterValue, (a, b) => a.value === b);
    }

    protected processEvents(events: WidgetEvent[]) {
        events.forEach(event => {
            const handler = findEventHandler(event, this.widget$.value);
            this.updateLastReceivedTime(event);

            if (!handler) {
                return;
            }

            try {
                this.scriptExecutor.buildFn(`return (function(event, dashboard, messageBus, DashboardGroupVisibility){${handler.script}})`)(
                    event,
                    this.dashboardStub,
                    this.messageBus,
                    DataMorph.DashboardGroupVisibility
                );
            } catch (e) {
                this.logger.log(parseError(e));
            }
        });
    }

    protected executeAsyncScripts() {
        // empty implementation for global filters
    }

    protected initFilters(widget: AnyWidgetModel) {
        if (this.filtersInited && this.isSlideInOutPanelActive) {
            return;
        }

        super.initFilters(widget);
        this.cdr.detectChanges();

        this.filtersInited = true;
    }

    protected checkWidgetIsValid(): boolean {
        return true;
    }

    // we don't reset state global filter, because there is not drilldown
    protected resetWidget() {
        // empty
    }

    protected clearColumnsState() {
        // empty
    }

    protected setFromToFirstWidgetQuery() {
        this.position.range.from = {
            level: 1,
            script: WidgetScript.BEFORE,
            placeholder: '',
        };
    }

    protected updateFilterValue(event: FilterValueChange | null): Placeholders {
        this.updateFromFilters = Boolean(event);

        return super.updateFilterValue(event);
    }

    protected clearDependentFilters(event: FilterValueChange): Placeholders {
        if (this.placeholders[RELOAD_SAVED_FILTERS]) {
            this.placeholders[RELOAD_SAVED_FILTERS] = false;

            return this.placeholders;
        }

        return super.clearDependentFilters(event);
    }

    protected updatePlaceholders(state: ExecutionResult) {
        state.placeholders = Object.assign(
            state.placeholders,
            pick(this.placeholders, APPLIED_FILTERS_PLACEHOLDER, SAVED_FILTERS_PLACEHOLDER)
        );

        super.updatePlaceholders(state);
    }

    protected needReloadFilterDataSource(filter: WidgetFilter) {
        return !this.isCascadingFiltersEnabled || this.reloadedFilters.has(filter.placeholder) || this.firstLoad || this.debug;
    }

    protected afterCalculateCall() {
        for (const { placeholder } of this.widget$.value.filters || []) {
            const filterControl = this.filters.get(placeholder);

            filterControl.enable({ emitEvent: false });
        }
    }

    private filterLoadingState = () =>
        filter((placeholders: Placeholders) => {
            if (!this.isSlideInOutPanelActive) {
                return true;
            }

            if (placeholders?.type === 'LOADING') {
                this.startLoading$.next();

                if (this.isCascadingFiltersEnabled) {
                    this.reloadAllFilters();
                    this.initLoading();
                }

                return false;
            }

            return true;
        });

    private reloadSavedFilters = () =>
        switchMap((placeholders: Placeholders) => {
            const savedFilters$: Observable<Placeholders> = of(this.isSlideInOutPanelActive ? {} : this.initialPlaceholders);

            return savedFilters$.pipe(map(savedFilters => [savedFilters, placeholders]));
        });

    private mergePlaceholders() {
        return map(([savedFilters, placeholders]: [Placeholders, Placeholders]) => ({
            ...placeholders,
            ...savedFilters,
        }));
    }
}
