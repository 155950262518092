<lib-stream-wrapper class="d-flex flex-1 flex-column overflow-hidden" [dataStream$]="jd$">
    <ng-template contentTemplate>
        <ng-container
            *ngIf="{
                vm: editJDState.debugContext.debugVm$ | async,
                actionsIsOpen: editJDState.formBuilder.actionsIsOpen$ | async
            } as debugState"
        >
            <div class="row align-items-center no-gutters px-4 py-xl-4 py-2">
                <div class="my-1 d-flex align-items-center cursor-pointer" (click)="back()">
                    <fa-icon icon="facArrowLeftRegular" class="fa font-16px back-icon"></fa-icon>
                    <h1 class="ml-2 mt-0">{{ jdName }}</h1>
                </div>
                <div class="ml-auto d-flex align-items-center" *ngIf="editJDState.mode$ | async as mode">
                    <dp-job-definition-issue-button
                        *ngIf="editJDState.jd && editJDState.typeIsChecked && editJDState.formIssues | async as formIssues"
                        [formIssues]="formIssues"
                        [issueType]="editJDState.issueType"
                        [class.ml-auto]="editJDMode"
                        [class.ml-3]="!editJDMode"
                        [class.d-none]="!formIssues.length || mode == 'JSON'"
                        (issueSelected)="handleIssueSelected($event)"
                    ></dp-job-definition-issue-button>

                    <ng-container *ngIf="debugState.actionsIsOpen">
                        <lib-zoom-control class="mx-3" *ngIf="facade.zoomControl" [zoom]="facade.zoomControl"></lib-zoom-control>

                        <button
                            *ngIf="!debugState.vm.debug"
                            class="btn  btn-primary"
                            [disabled]="debugState.vm.loading || editJDState.invalid$ | async"
                            (click)="facade.startDebug()"
                        >
                            Debug
                        </button>

                        <ng-container *ngIf="debugState.vm.debug">
                            <button class="btn  btn-primary mr-2" [disabled]="debugState.vm.loading" (click)="facade.resumeDebug()">
                                Resume
                            </button>
                            <button class="btn  btn-secondary" [disabled]="debugState.vm.loading" (click)="facade.cancelDebug()">
                                Stop
                            </button>
                        </ng-container>
                    </ng-container>

                    <button
                        *ngIf="!readonly"
                        class="btn  btn-primary ml-4"
                        (click)="handleSaveClick()"
                        [disabled]="saving || mode === 'JSON' || debugState.vm.debug"
                    >
                        Save
                    </button>
                </div>
            </div>
            <div *ngIf="!!editJDState.jd" class="d-flex flex-1 flex-column mh-0">
                <ng-container *ngIf="editJDState.vm$ | async as vm">
                    <div class="d-flex align-items-center" [class.mb-4]="!debugState.actionsIsOpen">
                        <dp-edit-job-definition-side-menu
                            class="flex-1"
                            [disabled]="debugState.vm.debug || !editJDState.isValid"
                            [menuItems]="editJDState.menu$ | async"
                            (tabChange)="facade.onTabChange($event)"
                        >
                        </dp-edit-job-definition-side-menu>
                        <fa-icon
                            *ngIf="debugState.actionsIsOpen"
                            class="expand-collapse cursor-pointer mr-4"
                            [class.expand-collapse--expanded]="!debugState.vm.collapsed"
                            icon="open"
                            (click)="editJDState.debugContext.actionsPanelToggle()"
                        ></fa-icon>
                    </div>
                    <ng-container *ngIf="editJDState.activeField$ | async as activeField">
                        <form
                            [formGroup]="editJDState.form"
                            class="overflow-hidden d-flex flex-column flex-1"
                            [ngClass]="activeField.menuPath !== 'actions' ? ['px-4', 'pb-4'] : []"
                        >
                            <dp-edit-job-definition-setup
                                class="col pr-0 overflow-hidden"
                                *ngIf="vm.index == 0"
                                [definition]="activeField.field"
                                [newJob]="this.creatingNewJd"
                                [formSlice]="vm.formGroup"
                                [meta]="activeField.meta"
                            ></dp-edit-job-definition-setup>
                            <dp-job-definition-builder
                                class="col px-0"
                                [class.d-none]="activeField.menuPath !== 'actions'"
                                [panelCollapsed]="debugState.vm.collapsed"
                                [debug]="debugState.vm.debug"
                                [debugContext]="debugState.vm.debugContext"
                                [debuggedBlockName]="debugState.vm.debuggedBlockName"
                                [loading]="debugState.vm.loading"
                                [publish]="debugState.vm.publish"
                                (forceRun)="facade.startDebug(true)"
                            ></dp-job-definition-builder>
                            <lib-codemirror-editor
                                *ngIf="activeField.menuPath === 'json'"
                                class="col mh-0 px-0"
                                mode="application/json"
                                [(ngModel)]="editJDState.json"
                                [ngModelOptions]="{ standalone: true }"
                            ></lib-codemirror-editor>
                            <ng-container *ngIf="vm.index > 0">
                                <dp-edit-job-definition-groovy
                                    *ngIf="activeField.menuPath == 'groovy'"
                                    class="col pr-0 overflow-hidden px-0"
                                    [formSlice]="vm.formGroup"
                                    [scripts]="activeField.field"
                                ></dp-edit-job-definition-groovy>
                                <ng-template [ngIf]="!['groovy', 'actions', 'json'].includes(activeField.menuPath)">
                                    <dp-edit-job-definition-template
                                        class="col overflow-hidden pr-0"
                                        *ngIf="!activeField.isCollection"
                                        [definition]="activeField.field"
                                        [meta]="activeField.meta"
                                        [isFirstLevel]="true"
                                        [formSlice]="vm.formGroup"
                                        [key]="vm.lastStep"
                                        [updatingRequest]="editJDState.updatingRequest | async"
                                    ></dp-edit-job-definition-template>
                                    <ng-container *ngIf="vm.activeField.isCollection">
                                        <ng-container *ngIf="vm.path.length > 1" [formGroup]="vm.formSliceParent">
                                            <dp-edit-job-definition-iterative-template
                                                class="col overflow-hidden pr-0"
                                                *ngIf="activeField.isCollection"
                                                [definition]="activeField.field"
                                                [meta]="activeField.meta"
                                                [key]="vm.lastStep"
                                                [formSlice]="vm.formGroup"
                                                [isFirstLevel]="true"
                                                [parent]="vm.jdParent"
                                            ></dp-edit-job-definition-iterative-template>
                                        </ng-container>
                                        <dp-edit-job-definition-iterative-template
                                            class="col overflow-hidden pr-0"
                                            *ngIf="vm.path.length == 1"
                                            [definition]="activeField.field"
                                            [meta]="activeField.meta"
                                            [key]="vm.lastStep"
                                            [formSlice]="vm.formGroup"
                                            [isFirstLevel]="true"
                                            [parent]="vm.jdParent"
                                        ></dp-edit-job-definition-iterative-template>
                                    </ng-container>
                                </ng-template>
                            </ng-container>
                        </form>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
    </ng-template>
</lib-stream-wrapper>
