import { Inject, Pipe, PipeTransform } from '@angular/core';
import { startCase } from 'lodash';

import { FIELD_NAMES } from '../field-names';

@Pipe({
    name: 'normalizePath',
})
export class NormalizePathPipe implements PipeTransform {
    constructor(@Inject(FIELD_NAMES) private fieldNames: any) {}

    transform(path: string[]): string {
        return path.reduce((acc, field, index) => `${acc} ${index === 0 ? '' : ' > '} ${this.fieldNames[field] || startCase(field)}`, '');
    }
}
