<perfect-scrollbar class="score-body">
    <div
        *ngFor="let row of data; index as i"
        [class.score-row-border-error]="row.value <= 3"
        [class.score-row-border-warning]="row.value > 3 && row.value <= 6"
        [class.score-row-border-success]="row.value > 6"
        class="d-flex score-row cursor-pointer"
        (click)="rowClickedHandler(row)"
    >
        <div class="d-flex flex-column justify-content-center col-10"
            [ngClass]="{ 'score-width': type === 'percent' }"
        >
            <div class="body3">{{ row.title ? row.title : '' }}</div>
            <div class="body1 secondary-text-and-icons" [style.max-width.px]="280">Factor {{ i + 1 }} · {{ row.description ? row.description : '' }}</div>
            <div class="status-bar">
                <div
                    *ngFor="let i of statusBars"
                    class="status-bar-value"
                    [class.status-bar-failed]="i < 6 && i < calculateStatusBars(row.value)"
                    [class.status-bar-warning]="i >= 6 && i < 12 && i < calculateStatusBars(row.value)"
                    [class.status-bar-success]="i >= 12 && i < 18 && i < calculateStatusBars(row.value)"
                    [class.status-bar-none]="i > calculateStatusBars(row.value) && i < 18"
                ></div>
            </div>
        </div>
        <div
            class="score-value-wrapper d-flex justify-content-center col {{ type }}-content"
            [class.score-value-error]="row.value <= 3"
            [class.score-value-warning]="row.value > 3 && row.value <= 6"
            [class.score-value-success]="row.value > 6"
        >
            <div class="d-flex score-value-content">
                <ng-container *ngIf="type === 'out-of-10'; else percentTemplate">
                    <div class="score-value">{{ row.value }}</div>
                    <div class="secondary-text-and-icons">/10</div>
                </ng-container>
                <ng-template #percentTemplate>
                    <div class="score-value">{{ row.value?(row.value === 100?row.value:row.value.toFixed(2)):0.00 }}%</div>
                </ng-template>
            </div>
        </div>
    </div>
</perfect-scrollbar>
