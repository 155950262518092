import { Directive, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { debounce, noop } from 'lodash';

import { isInDom } from './chart-libraries/echarts/echarts.chart';

import { adjustMargins } from './chart-legend/chart-legend.component';

declare let ResizeObserver: any;

@Directive({
    // eslint-disable-next-line
    selector: '[eChart]',
})
export class EChartDirective implements OnInit, OnChanges, OnDestroy {
    @Input('eChart') option: any;

    @Input() checkHostResize: boolean = false;

    @Input() disableMarginsAdjustment: boolean = false;

    @Output() initialized = new EventEmitter();

    instance: any;
    timer: any;
    mouseMoveUnlisten = noop;

    private destroyed$ = new Subject<void>();

    private resizeObserver: any;

    constructor(private elRef: ElementRef, private zone: NgZone, private renderer: Renderer2) {}

    ngOnChanges() {
        this.zone.runOutsideAngular(() => {
            if (this.instance) {
                this.instance.setOption(this.option);
            }
        });
    }

    ngOnInit() {
        this.zone.runOutsideAngular(() => {
            Promise.resolve().then(() => {
                this._init();
            });
        });
    }

    private async _init() {
        const { init } = await import('./chart-libraries/echarts/echarts.imports');
        this.instance = init(this.elRef.nativeElement);
        if (!this.disableMarginsAdjustment) {
            adjustMargins(this.instance);
        }

        this.instance.setOption(this.option);
        if (!this.checkHostResize) {
            fromEvent(window, 'resize')
                .pipe(debounceTime(0), takeUntil(this.destroyed$))
                .subscribe(() => {
                    if (isInDom(this.elRef.nativeElement)) {
                        this.instance.resize();
                    }
                });
        }

        this.mouseMoveUnlisten = this.renderer.listen(this.elRef.nativeElement, 'mousemove', () => {
            if (this.option.noHoverOnBar) {
                this.instance.dispatchAction({
                    type: 'downplay',
                });
            }
        });

        if (this.checkHostResize) {
            const resize = () => {
                requestAnimationFrame(() => {
                    if (isInDom(this.elRef.nativeElement)) {
                        this.instance?.resize();
                    }
                });
            };

            this.resizeObserver = new ResizeObserver(debounce(resize, 100));

            this.resizeObserver.observe(this.elRef.nativeElement);
        }

        this.initialized.emit(this.instance);
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.mouseMoveUnlisten();

        if (this.instance) {
            this.instance.dispose();
            this.instance = null;
        }

        if (this.resizeObserver) {
            this.resizeObserver.unobserve(this.elRef.nativeElement);
            this.resizeObserver = null;
        }
    }
}
