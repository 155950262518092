import { Component, ContentChild, ElementRef, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { SplitButtonItem } from '@dagility-ui/shared-components';

@Component({
    selector: 'app-project-filter-button',
    templateUrl: './project-filter-button.component.html',
    styleUrls: ['./project-filter-button.component.scss'],
})
export class ProjectFilterButtonComponent<T extends SplitButtonItem> {
    @Input() placement = 'bottom-left';
    @Input() buttonText: string;
    @Input() items: T[];
    @Input() container: 'body' | null = null;

    @Output() itemClick = new EventEmitter<T>();

    @ContentChild(TemplateRef, { static: true }) itemTemplate: TemplateRef<ElementRef>;

    emitAction(item: T, event: any) {
        if (item.type !== 'title' && event.detail !== 0 && !item.value) {
            this.refreshItems(item);
            this.itemClick.emit(item);
        }
    }

    refreshItems(item: T) {
        this.items.forEach(x => {
            if (x.name === item.name) {
                x.value = x.label === item.label;
            }
        });
    }
}
