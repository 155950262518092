import { Component, EventEmitter, Inject, Injector, Output } from '@angular/core';
import { ControlContainer, FormArray, FormGroup, FormGroupDirective } from '@angular/forms';
import { noop } from 'rxjs';
import { FORM_ERRORS, ModalService } from '@dagility-ui/kit';

import { WidgetHelpInsight } from 'data-processor';

import { WidgetBuilderFacade } from '../../../../../widget-builder/state/widget-builder.facade';
import { WidgetBuilderHelpTemplateComponent } from '../widget-builder-help-template/widget-builder-help-template.component';

@Component({
    selector: 'dp-widget-builder-help-form-insight',
    templateUrl: './widget-builder-help-form-insight.component.html',
    styleUrls: ['./widget-builder-help-form-insight.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'd-block px-4 py-3',
    },
})
export class WidgetBuilderHelpFormInsightComponent {
    @Output() modalClosed = new EventEmitter<void>();

    get form(): FormArray {
        return this.parent.control.get('insights') as FormArray;
    }

    isRequiredText: string;

    constructor(
        private parent: FormGroupDirective,
        private modal: ModalService,
        private facade: WidgetBuilderFacade,
        private injector: Injector,
        @Inject(FORM_ERRORS) formErrors: Record<string, () => string>
    ) {
        this.isRequiredText = formErrors.required();
    }

    handleEditInsight(insight: string, recommendations: string[], arrayIndex: number) {
        this.handleOpenEditModal(insight, recommendations)
            .result.then(
                (result: WidgetHelpInsight) => {
                    const insightFormGroup = this.form.at(arrayIndex) as FormGroup;
                    const newInsightFormGroup = this.facade.buildHelpInsight(result);
                    insightFormGroup.get('insight').patchValue(result.insight);
                    insightFormGroup.setControl('recommendations', newInsightFormGroup.get('recommendations'));
                    this.modalClosed.emit();
                },
                () => {
                    this.modalClosed.emit();
                }
            )
            .catch(noop);
    }

    handleAddInsight() {
        this.handleOpenEditModal('', [''])
            .result.then(
                (result: WidgetHelpInsight) => {
                    this.form.push(this.facade.buildHelpInsight(result));
                    this.modalClosed.emit();
                },
                () => {
                    this.modalClosed.emit();
                }
            )
            .catch(noop);
    }

    handleDeleteInsight(index: number) {
        this.form.removeAt(index);
    }

    handleOpenEditModal(insight: string, recommendations: string[]) {
        return this.modal.open(
            WidgetBuilderHelpTemplateComponent,
            { centered: true, backdrop: 'static', size: 'lg', windowClass: 'help-template', injector: this.injector },
            {
                header: 'Add Insight & Recommendations',
                data: {
                    insight,
                    recommendations,
                },
            }
        );
    }
}
