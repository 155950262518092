import { Directive } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
    selector: '[vocButtonDisabled]',
})
export class VocFormDirective {
    constructor(private router: Router) {
        setInterval(() => {
            const form = document.getElementById('voc-form');
            if (form) {
                form.style.display = router.url.includes('/query-builder') ? 'none' : 'block';
            }
        }, 100);
    }
}
