import { Directive, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { exhaustMap, take, takeUntil } from 'rxjs/operators';
import { fromEvent, Observable, of, Subject } from 'rxjs';
import { validateFormAndDisplayErrors } from '@dagility-ui/kit';

@Directive({
    selector: '[submitAfterAsyncValidation]',
    standalone: true,
})
export class SubmitAfterAsyncValidationDirective implements OnInit, OnDestroy {
    @Input() submittedControl: FormGroup;

    @Input() checkDirty = false;

    @Output() submitAfterAsyncValidation = new EventEmitter<void>();

    get control() {
        return this.submittedControl;
    }

    get submitEvent(): Observable<unknown> {
        return fromEvent(this.elementRef.nativeElement, 'click');
    }

    @HostBinding('attr.disabled') get disabled() {
        const disableByDirty = this.checkDirty && !this.control.dirty;

        if (this.control.pending) {
            if (disableByDirty) {
                return true;
            }

            for (const control of Object.values(this.control.controls)) {
                if (control.status === 'INVALID') {
                    return true;
                }
            }

            return null;
        }

        return this.control.invalid || disableByDirty || null;
    }

    private destroy$ = new Subject<void>();

    constructor(private elementRef: ElementRef<HTMLElement>) {}

    ngOnInit() {
        this.submitEvent
            .pipe(
                exhaustMap(() => (this.control.pending ? this.control.statusChanges.pipe(take(1)) : of(null))),
                takeUntil(this.destroy$)
            )
            .subscribe(() => {
                if (this.control.valid) {
                    this.submitAfterAsyncValidation.emit();
                } else {
                    validateFormAndDisplayErrors(this.control as any);
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
    }
}
