import { FormGroup } from '@angular/forms';
import { map, shareReplay } from 'rxjs/operators';
import { dropRight, get, last, head } from 'lodash';

import { Store } from '@dagility-ui/kit';

import { checkIsCollection } from '../utils';
import { JobDefinitionFormState } from './job-definition.state.model';

export class JDFormBuilder<T = any, Y = any> {
    path$ = this.store.select(state => state.path).pipe(shareReplay(1));
    activeStep$ = this.path$.pipe(map(head)).pipe(shareReplay(1));
    lastStep$ = this.path$.pipe(
        map(last),
        map(key => key || '')
    );
    activeField$ = this.path$.pipe(
        map(path => {
            const menuPath = path[0] || '';

            return {
                menuPath,
                field: get(this.object, path, this.object),
                meta: this.getMetaInformation(this.metaObject, path),
                isCollection: checkIsCollection(this.object, path),
            };
        })
    );
    formGroup$ = this.path$.pipe(map(path => this.form.get(path) || this.form));
    parentPath$ = this.path$.pipe(
        map(path => dropRight(path)),
        shareReplay(1)
    );
    formSliceParent$ = this.parentPath$.pipe(map(path => this.form.get(path) || this.form));
    parent$ = this.parentPath$.pipe(map(path => get(this.object, path, this.object)));
    actionsIsOpen$ = this.activeStep$.pipe(map(step => step === 'actions'));

    form: FormGroup;
    object: T;
    metaObject: Y;

    static getDefaultState(): JobDefinitionFormState {
        return {
            path: ['setup'],
            index: 0,
            mode: 'FORM',
        };
    }

    constructor(private store: Store<JobDefinitionFormState>) {}

    init(form: FormGroup, object: T, metaObject: Y) {
        this.form = form;
        this.object = object;
        this.metaObject = metaObject;
    }

    goToStep = (path: string[], index?: number, isMenu = false) => {
        const { path: activePath } = this.store.getValue();

        if (isMenu && path.length === 1 && activePath[0] === path[0]) {
            return;
        }

        this.store.setState({
            path: isMenu ? path : [...this.store.state.path, ...path],
            index: index == null ? this.store.state.index : index,
        });
    };

    goToPath = (path: string[]) => this.store.setState({ path });

    private getMetaInformation(metaObject: Y, path: string[]) {
        const field = get(metaObject, path, metaObject);

        if (!!field && !checkIsCollection(metaObject, path)) {
            return field.meta;
        } else {
            const pathToParent = dropRight(path);
            return get(metaObject, pathToParent, metaObject).meta;
        }
    }
}
