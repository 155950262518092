import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, Subject, Subscription, throwError } from 'rxjs';
import { catchError, filter, takeUntil } from 'rxjs/operators';

import { ProcessorMonitoringState } from './processor-monitoring-state.service';
import { ProcessorMonitoringService } from './processor-monitoring.service';
import { DataProcessorStateListener } from './dp-state.listener';
import { SqlExecutorComponent } from './sql-executor/sql-executor.component';
import { ToolService } from '@dagility-ui/shared-components';
import { ModalService } from '@dagility-ui/kit';
import { PerfectScrollbarConfig } from 'ngx-perfect-scrollbar';
import { DATA_MORPH_FEATURE, DATA_MORPH_FEATURE_TOGGLE, DataMorphFeatureToggleService } from 'data-processor/tokens';

@Component({
    selector: 'dp-processor-monitoring',
    templateUrl: './processor-monitoring.component.html',
    styleUrls: ['./processor-monitoring.component.scss'],
    providers: [ProcessorMonitoringState, DataProcessorStateListener],
})
export class ProcessorMonitoringComponent implements OnInit, OnDestroy {
    @Input() isInIframe = false;

    private subscription: Subscription;
    private destroy$ = new Subject<void>();

    perfectScrollBarConfig: PerfectScrollbarConfig = new PerfectScrollbarConfig({
        suppressScrollX: false,
    });

    constructor(
        public pmState: ProcessorMonitoringState,
        private api: ProcessorMonitoringService,
        private toolService: ToolService,
        private stateListener: DataProcessorStateListener,
        private modalService: ModalService,
        @Inject(DATA_MORPH_FEATURE_TOGGLE) private featureToggleService: DataMorphFeatureToggleService
    ) {}

    ngOnInit() {
        combineLatest([
            this.api.getJobDefinitionState().pipe(
                catchError(err => {
                    if ([503, 504].includes(err.status)) {
                        this.pmState.isServiceAvailable = false;

                        return throwError(err);
                    }
                })
            ),
            this.toolService.getAllTools() as Observable<any>,
            this.api.getJobSets(),
            this.featureToggleService.isActive(DATA_MORPH_FEATURE.MONITORING_TOOLS),
        ])
            .pipe(takeUntil(this.destroy$))
            .subscribe(([dataProcessorState, toolResponse, jobSets]) => {
                this.pmState.init(dataProcessorState, toolResponse.result.content, jobSets);
                this.subscribeOnUpdates();
            });
    }

    subscribeOnUpdates() {
        this.unsubscribeFromUpdates();

        this.subscription = this.stateListener
            .listen(this.pmState.stateParams)
            .pipe(filter(({ isError }) => !isError))
            .subscribe(({ full, tools, state }) => {
                this.pmState.update(full, state, tools);
            });
    }

    unsubscribeFromUpdates() {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }

    handleSearch(query: string) {
        this.pmState.filter(query);

        if (query.toLowerCase() === 'sql') {
            setTimeout(() => this.pmState.filter(''));

            this.modalService.open(SqlExecutorComponent, {
                centered: true,
                backdrop: 'static',
                size: 'xl',
            });
        }
    }

    ngOnDestroy() {
        this.unsubscribeFromUpdates();
        this.destroy$.next();
    }
}
