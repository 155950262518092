import { Component, Input } from '@angular/core';

import { CodeQualitySummaryItem } from '../model/code-quality-summary.model';

@Component({
    selector: 'dp-code-quality-summary-item',
    templateUrl: './code-quality-summary-item.component.html',
    styleUrls: ['./code-quality-summary-item.component.scss'],
})
export class CodeQualitySummaryItemComponent {
    @Input() set item(value: CodeQualitySummaryItem) {
        this.data = value;
    }

    data: CodeQualitySummaryItem;

    statusBars = [...new Array(12).keys()];
}
