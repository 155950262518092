import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AgGridComponent } from '@dagility-ui/shared-components';
import { isDefined, Pager, Pagination } from '@dagility-ui/kit';
import { CellClickedEvent, ColDef, IGetRowsParams, RowClassRules, RowClickedEvent } from '@ag-grid-community/core';
import { FormControl, FormGroup } from '@angular/forms';
import { cloneDeep, isEqual } from 'lodash';

import { BasePlaceholders } from '../../services/widget-builder.util';
import { AnyWidgetStore } from 'data-processor/lib/widget-library/widget-builder/components/widget/any-widget/any-widget.store';

import { WidgetDOMEventAttribute } from '../../services/message-bus/widget-dom-events.service';

function filterDrilldownEvent(event: CellClickedEvent | RowClickedEvent) {
    if (!event.event) {
        return true;
    }

    return !(event.event.target instanceof HTMLElement && event.event.target.hasAttribute(WidgetDOMEventAttribute.EVENT_SKIP));
}

@Component({
    selector: 'dp-grid-widget-wrapper',
    templateUrl: './grid-widget-wrapper.component.html',
    styleUrls: ['./grid-widget-wrapper.component.scss'],
})
export class GridWidgetWrapperComponent implements OnInit, OnDestroy {
    @Input() chartModel: any;
    @Input() paginationState: boolean;
    @Input() serverSidePagination: boolean;
    @Input() filtersGroup: FormGroup;
    @Input() placeholders: Record<string, any> = {};
    @Input() serverSideWidget: boolean;
    @Input() showResetButton = false;

    @Output() cellClicked = new EventEmitter();
    @Output() stateChanged = new EventEmitter();

    @ViewChild('agGrid') agGridComponent: AgGridComponent;

    paginationPageSize: number = 10;
    pagination: Pagination;
    initialized = false;

    hasFewRows = false;

    private currentPage = 0;
    readonly rowClassRules: RowClassRules = {
        'row-disabled': params => !!params.data['class-disabled'],
    };

    ngOnInit() {
        if (this.serverSidePagination) {
            this.paginationPageSize = undefined;
        }
        const rowsLen = this.chartModel.options.tableData?.rowData?.length;
        this.hasFewRows = rowsLen && rowsLen < 5;
    }

    get form() {
        return this.serverSideWidget ? this.store.debuggerState.filters$.value : this.filtersGroup;
    }

    get gridApi() {
        return this.agGridComponent.gridApi;
    }

    private get filters(): FormControl {
        return this.form.get(BasePlaceholders.FILTER_PLACEHOLDER) as FormControl;
    }

    private get pageable(): FormControl {
        return this.form.get(BasePlaceholders.PAGEABLE_PLACEHOLDER) as FormControl;
    }

    private get sort(): FormControl {
        return this.form.get(BasePlaceholders.SORT_PLACEHOLDER) as FormControl;
    }

    constructor(private store: AnyWidgetStore, private elRef: ElementRef) {}

    private listenSort = () => {
        if (!isEqual(this.gridApi.getSortModel(), this.sort.value)) {
            const sortModel = this.gridApi.getSortModel();
            this.sort.patchValue(sortModel);
            this.resetPagination();
            this.stateChanged.emit({});
        }
    };

    private listenFilter = () => {
        if (!isEqual(this.gridApi.getFilterModel(), this.filters.value)) {
            if (!this.chartModel.options.hasResetFilter) {
                this.filters.patchValue(this.gridApi.getFilterModel());
            }
            this.resetPagination();
            this.stateChanged.emit({});
        }
    };

    handleDrilldownEvent($event: CellClickedEvent | RowClickedEvent, cellClick: boolean) {
        if (!filterDrilldownEvent($event)) {
            return;
        }

        if ((cellClick && this.placeholders.selectedRowId !== undefined) || (!cellClick && !isDefined(this.placeholders.selectedRowId))) {
            return;
        }

        this.cellClicked.emit($event);
    }

    onPaginationChanged() {
        if (!(this.agGridComponent.gridApi && this.agGridComponent.gridApi.paginationGetCurrentPage() !== undefined)) {
            return;
        }

        if (this.serverSidePagination) {
            const totalElements: number = this.placeholders[BasePlaceholders.GRID_ELEMENTS_COUNT] ?? this.gridApi.getDisplayedRowCount();
            const size = this.pageable.value.limit ?? 25;
            this.pagination = Pagination.of({
                number: this.pageable.value.offset ?? 0,
                totalPages: Math.trunc(totalElements / size),
                totalElements: totalElements,
                size,
            } as any);
        } else {
            this.pagination = Pagination.of({
                number: this.currentPage,
                totalPages: this.gridApi.paginationGetTotalPages(),
                totalElements: this.gridApi.getDisplayedRowCount(),
                size: this.gridApi.paginationGetPageSize(),
            } as any);
        }
    }

    getHiddenColumnsLength(columnDefs: any[]) {
        return columnDefs?.map((i: any) => i.hide).filter(i => !!i).length;
    }

    onGridReady() {
        if (isDefined(this.placeholders.selectedRowId)) {
            this.gridApi.forEachNode(node => {
                if (node.data.id === this.placeholders.selectedRowId) {
                    node.setSelected(true);
                }
            });
        }

        if (!this.serverSidePagination || this.initialized) {
            return;
        }

        const storedGridColumnDefs = this.store.gridColumnDefsBeforePagination[this.chartModel.options.tableId];

        /* TODO: columns are flickering due to this code
           need to move columns initialization to query-to-options mapper
         */
        if (
            storedGridColumnDefs?.length &&
            needRestoreColumns(storedGridColumnDefs, this.agGridComponent.gridApi.getColumnDefs() as ColDef[])
        ) {
            this.agGridComponent.gridApi.setColumnDefs(
                storedGridColumnDefs.map((column: ColDef) => {
                    column.hide = !!column.hide;

                    return column;
                })
            );
        }

        const that = this;
        this.gridApi.setDatasource({
            getRows(params: IGetRowsParams) {
                params.successCallback(that.chartModel.options.tableData.rowData);
            },
        });
        this.gridApi.setSortModel(this.sort.value || []);
        this.gridApi.setFilterModel(this.filters.value);

        Promise.resolve().then(() => {
            this.gridApi.addEventListener('filterChanged', this.listenFilter);
            this.gridApi.addEventListener('sortChanged', this.listenSort);
            this.gridApi.addEventListener('gridColumnsChanged', this.storeColumnDefs);
        });
        this.initialized = true;
    }

    private resetPagination() {
        this.pageable.patchValue(
            {
                ...this.pageable.value,
                offset: 0,
            },
            { emitEvent: false }
        );
    }

    handleChangePaginationState(pager: Pager) {
        this.currentPage = pager.currentPage - 1;

        if (this.serverSidePagination) {
            this.pageable.patchValue({
                limit: pager.pageSize,
                offset: pager.currentPage - 1,
            });
            this.stateChanged.emit({});
        } else {
            this.agGridComponent.gridApi.paginationGoToPage(pager.currentPage - 1);
            this.paginationPageSize = pager.pageSize;
            this.agGridComponent.gridApi.paginationSetPageSize(pager.pageSize);
            this.agGridComponent.perfectScrollbarY?.update();
        }
    }

    private storeColumnDefs = () => {
        this.store.gridColumnDefsBeforePagination[this.chartModel.options.tableId] = cloneDeep(this.gridApi?.getColumnDefs()) || [];
    };

    ngOnDestroy() {
        this.gridApi?.removeEventListener('filterChanged', this.listenFilter);
        this.gridApi?.removeEventListener('sortChanged', this.listenSort);
        this.gridApi?.removeEventListener('gridColumnsChanged', this.storeColumnDefs);
    }
}

function needRestoreColumns(storedColumns: ColDef[], currentColumns: ColDef[]) {
    for (let idx = 0; idx < storedColumns.length; idx++) {
        const columnA = storedColumns[idx];
        const columnB = currentColumns[idx];

        if (!!columnA.hide !== !!columnB.hide) {
            return true;
        }
    }

    return false;
}
