import { Component, Renderer2, OnInit, Input, ElementRef, OnChanges } from '@angular/core';
import { TraceabilityService } from '../api/traceability.service';

export interface Help {
    anchorId: string;
    title: string;
    content: string;
}

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'app-node-details',
    templateUrl: './node-details.component.html',
    styleUrls: ['./node-details.component.scss'],
    host: { class: 'position-relative' },
})
export class NodeDetailsComponent implements OnInit, OnChanges {
    @Input() showNodeDetailsFlag = false;
    @Input() nodeDetailsData: any;

    title = '';
    content = '';

    // @HostListener('window:keydown.Escape')
    // onEscapeKey(): void {
    //     this.showNodeDetailsFlag = false;
    // }

    // @HostListener('document:click', ['$event.target'])
    // public onClick(targetElement: any) {
    //     const clickedInside = this.el.nativeElement.contains(targetElement);
    //     if (!clickedInside) {
    //         this.showNodeDetailsFlag = false;
    //     }
    // }

    constructor(private renderer: Renderer2, private el: ElementRef, private traceabilityService: TraceabilityService) {}

    ngOnInit() {
        this.title = this.nodeDetailsData.infoHeader;
        if (this.showNodeDetailsFlag) {
            this.showNodeDetails();
        }
    }

    ngOnChanges() {
        if (this.showNodeDetailsFlag) {
            this.showNodeDetails();
        }
    }

    showNodeDetails() {
        this.renderer.addClass(this.el.nativeElement, 'context-help-trigger');

        setTimeout(() => {
            if (window.outerWidth - (this.el.nativeElement.offsetLeft + this.el.nativeElement.offsetWidth) < 800) {
                const contextHelpEl = document.querySelector('.node-details-wrap');
                contextHelpEl.classList.add('positioned-right');
            }
        }, 50);
    }

    dismiss(): void {
        this.showNodeDetailsFlag = false;
        setTimeout(() => {
            this.traceabilityService.closeNode.next(true);
        }, 20);
    }
}
