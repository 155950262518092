<lib-data-grid
    class="table"
    *ngIf="(jobsList || []).length"
    [dataSource]="jobsList"
    [columns]="gridColumns"
    [pageable]="pageable"
    [sticky]="true"
    [usePerfectScrollbar]="true"
    [bodyHeight]="'calc(85vh - 225px)'"
>
    <ng-template headerTemplate>
        <tr class="d-inline-flex align-items-center w-100">
            <th
                *ngFor="let c of columns"
                [id]="c.field"
                [ngClass]="{ 'sort-field': c.sortingField }"
                class="d-inline-flex align-items-center"
                [style.width]="c.width"
                [style.min-width]="c.minWidth"
                (click)="changeSort(c.sortingField)"
            >
                <span class="column-header">
                    <div [class.blue]="pageable && pageable.sorted(c.sortingField)"
                         [class.cursor-pointer]="c.sortingField" [class.cursor-default]="!c.sortingField"
                    >
                        {{ c.title }}
                    </div>
                    <span
                        *ngIf="c.sortingField"
                        [class.sort-off-icon]="!pageable || !pageable.sorted(c.sortingField)"
                        class="sorting-button"
                        [innerHTML]="(pageable && (pageable.sorted(c.sortingField) === 'ASC' || pageable.sorted(c.sortingField) === null) ? sortIcons.sortingDown : sortIcons.sortingUp) | safeHtml: 'html'"
                    >
                    </span>
                </span>
            </th>
        </tr>
    </ng-template>
    <ng-template rowTemplate let-job="item">
        <tr *ngIf="job?.pipelineName" class="w-100 cards-and-blocks">
            <td
                [style.width]="gridColumns[0].width"
                [style.min-width]="gridColumns[0].minWidth"
                class="text-truncate"
                [ngClass]="{ 'color-brand cursor-pointer': job.pipelineId !== 0  }"
                [class.cursor-default]="job.pipelineId === 0"
            >
                <a class="text-truncate"
                   [ngbTooltip]="job.pipelineName"
                   [container]="'body'"
                   [openDelay]="400"
                   [ngClass]="{ 'color-brand cursor-pointer': job.pipelineId !== 0  }"
                   [class.cursor-default]="job.pipelineId === 0"
                   (click)="handleActionEditPipeline(projectId, job.pipelineId)"
                >
                    {{ job.pipelineName }}
                </a>
            </td>
            <td
                [style.width]="gridColumns[1].width"
                [style.min-width]="gridColumns[1].minWidth"
                class="cursor-default text-truncate"
            >
                {{ job.buildId }}
            </td>
            <td [style.width]="gridColumns[2].width" [style.min-width]="gridColumns[2].minWidth">
                <span [ngbTooltip]="job.lastExecutedTime | date: 'dateTimeCustom'" placement="top"
                      container="body" [openDelay]="400" class="cursor-default text-truncate">
                    {{ job.lastExecutedTime | date: 'lastDateTimeCustom' }}
                </span>
            </td>
            <td [style.width]="gridColumns[3].width" [style.min-width]="gridColumns[3].minWidth">
                <span
                    *ngIf="job?.last5Statuses?.length || job.lastStatus === 'BUILDING'"
                    [ngbTooltip]="job.lastDuration | date: 'duration':'+0000'"
                    [container]="'body'"
                    placement="top"
                    [openDelay]="400"
                    class="cursor-default"
                >
                    {{ job.lastDuration | date: 'duration' }}
                </span>
            </td>
            <td [style.width]="gridColumns[4].width" [style.min-width]="gridColumns[4].minWidth" class="last-status">
                <span
                    class="rounded-circle mr-1 d-inline-block {{ status === null ? 'null' : status | lowercase }}"
                    [ngbTooltip]="status"
                    [container]="'body'"
                    [openDelay]="400"
                    *ngFor="let status of (job.last5Statuses || [])"
                ></span>
            </td>
            <td [style.width]="gridColumns[5].width" [style.min-width]="gridColumns[5].minWidth">
                <div class="w-100">
                    <div *ngIf="job.lastStatus !== 'WAITING_FOR_APPROVAL' && job.lastStatus !== 'PENDING'"
                         class="d-block text-capitalize mb-1 cursor-default">
                        {{
                        job.lastStatus
                            ? (job.lastStatus | lowercase)
                            : 'None'
                        }}
                    </div>
                    <div
                        *ngIf="
                                  job.lastStatus === 'RUNNING' ||
                                  job.lastStatus === 'BUILDING'
                              "
                        class="d-block w-75 status-indicator rounded bg-light-gray"
                    >
                        <div
                            [style.width.%]="job.progressPercent"
                            class="status-bar d-block {{ job.lastStatus | lowercase }}"
                        ></div>
                    </div>
                    <div
                        *ngIf="
                                  job.lastStatus !== 'RUNNING' &&
                                  job.lastStatus !== 'WAITING_FOR_APPROVAL' &&
                                  job.lastStatus !== 'PENDING' &&
                                  job.lastStatus !== 'BUILDING'
                              "
                        class="d-block w-75 status-indicator rounded bg-light-gray"
                    >
                        <div
                            [style.width.%]="100"
                            class="status-bar d-block {{ job.lastStatus | lowercase }} border-radius-4px"
                        ></div>
                    </div>
                    <div *ngIf="job.lastStatus === 'WAITING_FOR_APPROVAL'"
                         class="d-block mb-1 cursor-default text-truncate">
                        <fa-icon icon="facPending" class="mr-5px"></fa-icon>
                        <span [ngbTooltip]="'Waiting for approval'"
                              [container]="'body'"
                              [openDelay]="400"
                        >
                            Waiting for approval
                        </span>
                    </div>
                    <div *ngIf="job.lastStatus === 'WAITING_FOR_APPROVAL'"
                         class="d-block w-75 status-indicator rounded bg-light-gray">
                        <div
                            [style.width.%]="job.progressPercent"
                            class="status-bar d-block {{ job.lastStatus | lowercase }}"
                        ></div>
                    </div>
                    <div *ngIf="job.lastStatus === 'PENDING'" class="d-block mb-1 cursor-default text-truncate">
                        <fa-icon icon="facPending" class="mr-5px"></fa-icon>
                        <span [ngbTooltip]="'Pending'"
                              [container]="'body'"
                              [openDelay]="400"
                        >
                            Pending
                        </span>
                    </div>
                    <div *ngIf="job.lastStatus === 'PENDING'"
                         class="d-block w-75 status-indicator rounded bg-light-gray">
                        <div
                            [style.width.%]="job.progressPercent"
                            class="status-bar d-block {{ job.lastStatus | lowercase }}"
                        ></div>
                    </div>
                </div>
            </td>
            <td [style.width]="gridColumns[6].width" [style.min-width]="gridColumns[6].minWidth"></td>
            <td [style.width]="gridColumns[7].width" [style.min-width]="gridColumns[7].minWidth">
                <fa-icon
                    ngbTooltip="Start"
                    placement="top"
                    container="body"
                    [openDelay]="400"
                    *ngIf="
                              canExecute &&
                              (!job.lastStatus ||
                                  ['FAILED', 'ABORTED', 'NONE', 'SUCCESS', 'REJECTED', 'FAILURE'].includes(job.lastStatus.toUpperCase()))
                          "
                    class="icons font-16px blue"
                    icon="facStart"
                    (click)="handlePipelineExecution(job)"
                >
                </fa-icon>
                <fa-icon
                    ngbTooltip="Stop"
                    placement="top"
                    container="body"
                    [openDelay]="400"
                    *ngIf="
                              canExecute &&
                              job.lastStatus &&
                              !['FAILED', 'ABORTED', 'NONE', 'SUCCESS', 'REJECTED', 'FAILURE'].includes(job.lastStatus.toUpperCase())
                          "
                    class="icons font-16px red"
                    icon="facStop"
                    (click)="handlePipelineExecution(job)"
                >
                </fa-icon>
                <fa-icon
                    *ngIf="canModify"
                    ngbTooltip="Edit"
                    placement="top"
                    container="body"
                    [openDelay]="400"
                    class="icons font-16px ml-15px"
                    icon="facEdit"
                    (click)="handleActionEditPipeline(projectId, job.pipelineId)"
                >
                </fa-icon>
                <fa-icon
                    ngbTooltip="Execution Log"
                    placement="top"
                    container="body"
                    [openDelay]="400"
                    class="icons font-16px ml-15px"
                    icon="facLogs"
                    (click)="openExecutionLog(job)"
                >
                </fa-icon>
            </td>
        </tr>
    </ng-template>
</lib-data-grid>
