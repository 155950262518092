import { Component, Input } from '@angular/core';

@Component({
  selector: 'mandatory',
  templateUrl: './mandatory.component.html',
  styleUrls: ['./mandatory.component.scss']
})
export class MandatoryComponent {
  @Input()
  tooltip: string;
}
