import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { WorkflowsLogsService } from '@app/shared/components/workflows-logs/services/workflows-logs.service';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { defaultIfEmpty, map, switchMap, takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { JobsManagementService } from '@app/shared/components/pipeline-list/api/jobs-management.service';
import { FormControl, FormGroup } from '@angular/forms';
import { DataGridComponent, GridColumn, sortingDown, sortingUp, writeContents } from '@dagility-ui/kit';
import { PerfectScrollbarConfig } from 'ngx-perfect-scrollbar';
import { ProjectWorkflowsService } from 'src/app/pages/project/project-edit/project-workflows/project-workflows.service';
import { Sort, sortArrayByFieldAndDirection, sortGridColumn } from '@dagility-ui/shared-components/utils/sorting.utils';
import { DropdownFilterItemModel } from '../../../models/filters/dropdown-filter-item.model';

const HEIGHT = 680;
const WIDTH = 980;
const PADDING = 65;

@Component({
    selector: 'app-workflows-logs',
    templateUrl: './workflows-logs.component.html',
    styleUrls: ['./workflows-logs.component.scss'],
})
export class WorkflowsLogsComponent implements OnInit, OnDestroy {
    logGridColumns: GridColumn[] = [
        {
            title: 'Date',
            field: 'moment',
            sortingField: 'moment',
            width: '22%',
        },
        {
            title: 'Level',
            field: 'level',
            sortingField: 'level',
            width: '8%',
        },
        {
            title: 'Log Text',
            field: 'text',
            sortingField: 'text',
            width: '70%',
        },
    ];

    buildId: string;
    workflowId: string;
    projectId: number;
    lastExecutedTime: number;

    loaded = false;
    currentLogs: any[];
    filteredLogs: any[];
    destroyed$ = new Subject();
    executionLogs$: Observable<any>;
    selectedItem: number;
    isExpanded: boolean;
    isExecutionLogsExpanded: boolean;
    searchTerm = '';
    filterTypes: any[] = [];
    formGroup: FormGroup;
    defaultDateString: '';
    objectTypes: DropdownFilterItemModel[] = [];
    runtimeVariables$: Observable<Pipeline[]>;

    perfectScrollbarConfig: PerfectScrollbarConfig = new PerfectScrollbarConfig({
        suppressScrollX: false,
        useBothWheelAxes: false,
    });

    tabValue = 'logs';

    get title() {
        return this.buildId ? `Logs for Execution ID ${this.buildId}` : 'Logs';
    }

    get width() {
        return this.isExpanded && document.body.clientWidth > 1300 ? document.body.clientWidth - PADDING - 100 : WIDTH;
    }

    get height() {
        return this.isExpanded ? document.body.clientHeight - PADDING - 50 : HEIGHT;
    }

    get gridHeight() {
        return `${this.executionLogs$ ? this.height - 500 : this.height - 220}px`;
    }

    get executionLogHeight() {
        return this.height - (this.isExecutionLogsExpanded ? 260 : parseInt(this.gridHeight) + 320);
    }

    @ViewChild(DataGridComponent) dataGrid: DataGridComponent;

    gridColumns: Array<GridColumn & { sort?: string }> = [
        {
            title: 'Variable Name',
            field: 'variableName',
            sortingField: 'variableName',
            width: '25%',
            sort: '',
        },
        {
            title: 'Value',
            field: 'value',
            width: '75%',
        },
    ];
    icons = {
        sortingUp,
        sortingDown,
    };
    sort$ = new BehaviorSubject({} as Sort);

    constructor(
        public modal: NgbActiveModal,
        private workflowsLogsService: WorkflowsLogsService,
        private projectWorkflowsService: ProjectWorkflowsService,
        private jobsManagementService: JobsManagementService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.workflowsLogsService
            .getLogs(this.workflowId, this.buildId)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(logs => {
                this.initTypes(logs);
                this.currentLogs = logs;
                this.loaded = true;
                this.applyFilters();
            });

        this.formGroup = new FormGroup({
            dateStart: new FormControl(null),
            dateEnd: new FormControl(null),
        });

        this.runtimeVariables$ = this.projectWorkflowsService
            .getWorkflowVariablesByBuildNumber(+this.projectId, +this.workflowId, +this.buildId)
            .pipe(
                map(response => this.initVariables(response)),
                switchMap(this.sortVariables.bind(this))
            );
    }

    sortClick(index: number) {
        sortGridColumn(index, this.gridColumns, this.sort$);
    }

    sortVariables(pipeline: Pipeline[]) {
        return this.sort$.pipe(
            map(({ field, direction }) => {
                pipeline.map(runtimeProperty => {
                    runtimeProperty.variables = sortArrayByFieldAndDirection(runtimeProperty.variables, field, direction);
                });
                return pipeline;
            })
        );
    }

    markErrorLog(logMessage: any, column: string) {
        if (column === 'level' && logMessage.level.toUpperCase() === 'ERROR') {
            return 'error-log-type';
        } else if (column === 'level' && logMessage.level.toUpperCase() === 'INFO') {
            return 'info-log';
        }
    }

    exportToFile() {
        this.getPipelineLogs().subscribe(pipelineLogs => {
            let logsToFileArray = 'Date                         ' + 'Level    ' + 'Log Text';
            for (let i = 0, j = 0; i < this.currentLogs.length; i++) {
                let logText;
                if (this.currentLogs[i].text.includes('\n')) {
                    logText = this.currentLogs[i].text.replace(/\n/gi, '\n                                     ');
                } else {
                    logText = this.currentLogs[i].text;
                }
                if (this.isPipelineLogLink(logText)) {
                    logText = logText + '\n' + pipelineLogs[j];
                    j++;
                }
                logsToFileArray =
                    logsToFileArray +
                    '\n' +
                    new DatePipe('en-US').transform(this.currentLogs[i].moment, 'yyyy-MM-dd, h:mm:ss.SSS a') +
                    '   ' +
                    this.currentLogs[i].level +
                    '   ' +
                    '"' +
                    logText +
                    '"';
            }
            writeContents(new Blob([logsToFileArray], { type: 'text/csv' }), 'logs.txt');
        });
    }

    getPipelineLogs() {
        return forkJoin(
            this.currentLogs
                .filter(log => this.isPipelineLogLink(log.text))
                .map(log => this.jobsManagementService.getFullLog(this.getLogParams(log.text)).pipe(map((l: any) => l.fullLog)))
        ).pipe(defaultIfEmpty([]));
    }

    getLogParams(logText: string) {
        return JSON.parse(logText.slice(logText.indexOf('->') + 3, logText.length));
    }

    isPipelineLogLink(s: string): boolean {
        const index = s.indexOf('->');
        return index >= 0 && Object.keys(this.getLogParams(s)).length > 0;
    }

    parseLogText(s: string): string {
        const endIndex = s.indexOf('->');
        if (endIndex >= 0) {
            return s.slice(0, endIndex);
        }
        return s;
    }

    openExecutionLog(s: string, index: number) {
        this.executionLogs$ = null;
        this.cdr.detectChanges();

        if (this.isPipelineLogLink(s) && this.selectedItem !== index) {
            const obj = this.getLogParams(s);
            this.selectedItem = index;
            this.executionLogs$ = this.jobsManagementService.getFullLog({
                toolId: obj.toolId,
                name: obj.name,
                project: obj.project,
                buildNumber: obj.buildNumber,
            });

            setTimeout(() => {
                this.scrollToSelectedItem();
            });
        } else {
            this.selectedItem = null;
        }
    }

    scrollToSelectedItem() {
        const scrollElement = this.dataGrid.scroll.element.nativeElement;
        const offset = this.selectedItem * 43;
        const listHeight = scrollElement.clientHeight - 43;
        const position = offset < listHeight ? listHeight - offset : offset - listHeight;

        if (scrollElement.scrollTop < position) {
            scrollElement.scrollTo(0, position);
        }
    }

    applyFilters() {
        const dateStart = new Date(this.formGroup.get('dateStart').value).valueOf();
        const dateEnd = new Date(this.formGroup.get('dateEnd').value).valueOf();

        this.filteredLogs = this.currentLogs.filter(log => {
            const matchSearch = this.searchTerm.length ? log.text.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 : true;
            const matchDateStart = dateStart ? log.moment > dateStart : true;
            const matchDateEnd = dateEnd ? log.moment < dateEnd : true;
            const matchType = this.filterTypes.length ? this.filterTypes.indexOf(log.level) > -1 : true;
            return matchSearch && matchDateStart && matchDateEnd && matchType;
        });
    }

    resetFilters() {
        this.formGroup.reset();
        this.defaultDateString = '';
        this.filterTypes = [];
        this.searchTerm = '';
        this.applyFilters();
    }

    initTypes(logs: Array<any> = []) {
        logs.forEach(log => {
            if (!this.objectTypes.find(type => type.value === log.level)) {
                this.objectTypes.push({ label: log.level, value: log.level });
            }
        });
    }

    tabChange(event: NgbNavChangeEvent) {
        this.tabValue = event.nextId;
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }

    private initVariables(response: any): Pipeline[] {
        const runtimeParameters: Pipeline[] = [];
        if (!response || !response.runtimePropertiesByStepId) {
            return runtimeParameters;
        }

        for (const stepId in response.pipelineByStepId) {
            if (response.pipelineByStepId.hasOwnProperty(stepId)) {
                const pipeline = response.pipelineByStepId[stepId];
                const runtimeProperties = response.runtimePropertiesByStepId[stepId];

                const variables: Variable[] = [];
                for (const key in runtimeProperties) {
                    if (runtimeProperties.hasOwnProperty(key)) {
                        const variableObj = {
                            variableName: key,
                            value: runtimeProperties[key],
                        };
                        variables.push(variableObj);
                    }
                }

                runtimeParameters.push({
                    pipelineName: pipeline.name,
                    variables,
                });
            }
        }

        return runtimeParameters.filter(parameter => parameter.variables.length);
    }
}

interface Pipeline {
    pipelineName: string;
    variables: Variable[];
}

interface Variable {
    variableName: string;
    value: any;
}
