export type DropDirection = 'left' | 'right' | 'top' | 'bottom' | 'inside';

export abstract class SVGBlock {
    abstract id: any;

    width = 140;
    height = 28;

    x = 0;
    y = 0;

    end: { x: number; y: number } = {
        x: 0,
        y: 0,
    };

    draggable = false;
    dropZone = false;

    viewType: 'box' | 'plain' | string = 'plain';
    directions: DropDirection[] = [];

    get error(): string {
        return '';
    }
}
