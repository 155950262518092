import { ToasterService } from '@dagility-ui/shared-components';
import { cloneDeep } from 'lodash';
import { toJSONBlob, writeContents } from '@dagility-ui/kit';
import { removeOldFields } from 'data-processor/lib/widget-library/widget-builder/services/widget.lines';
import { prettifyObject } from 'data-processor/lib/common';

import { AnyWidgetModel } from './models/any-widget.model';

export function exportAnyWidget(widgetData: AnyWidgetModel, toastr?: ToasterService, name?: string) {
  const widget = cloneDeep(widgetData);
  removeOldFields(widget);

  if (!name) {
    name = `${widgetData.chartOptions.title} widget`;
  }

  writeContents(toJSONBlob(prettifyObject(widget as any)), `${name}.json`);

  if (toastr) {
    toastr.successToast({
      title: 'Success',
      content: 'Widget was successfully exported',
    });
  }
}
