import { isEmpty } from 'lodash';
import { FormGroup, ValidationErrors } from '@angular/forms';

import { Deserializable, PrimitiveDataType } from './abstract-job-definition';
import { DataFilterOperation, JobDefinitionDataType } from './enums';
import { IsBoolean, IsEnum, IsInt, IsString, Type } from '../type.decorator';
import { MapRequired, mapRequired, NotEmpty, Required, Validate } from '../validate.decorator';
import { JoinType } from './enums/join-type';

export class DataSourceJoin extends Deserializable {
    // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
    _class = { dataSource: JobDefinitionDataSource };
    // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
    _enums = {
        joinType: JoinType,
    };
    // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
    _arrays = {};
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    _maps = {
        conditions: 'string',
    };
    meta = {
        _arrays: this._arrays,
        _enums: this._enums,
        _class: this._class,
        _maps: this._maps,
    };

    @Type(String)
    conditions: Map<string, string> = {} as any;

    @Required()
    dataSource = new JobDefinitionDataSource(null, false);

    @IsEnum(JoinType)
    joinType: JoinType = null;

    getType() {
        return 'DataSourceJoinAggregation';
    }

    getInfo() {
        return `Data Source Join`;
    }

    constructor(instanceData?: DataSourceJoin, nextLevel: boolean = false) {
        super();

        if (nextLevel) {
            this.dataSource = undefined;
        }
        if (instanceData) {
            this.fillByInstance(DataSourceJoin, instanceData);
        }
    }
}

export class JobDefinitionDataSource extends Deserializable {
    _class = {
        level: DataSourceJsonLevel,
        filter: DataFilter,
        paging: DataSourcePaging,
        aggregation: DataSourceAggregation,
    };
    _enums = {
        type: JobDefinitionDataType,
    };
    _arrays = {
        joins: DataSourceJoin,
        sort: 'string',
        details: JobDefinitionDataSource,
    };
    _maps = {
        constants: PrimitiveDataType,
        parameters: PrimitiveDataType,
    };
    _nullableFields = {
        limit: 'number',
    };
    meta = {
        _arrays: this._arrays,
        _enums: this._enums,
        _class: this._class,
        _maps: this._maps,
        _nullableFields: this._nullableFields,
    };

    @Required()
    @IsEnum(JobDefinitionDataType)
    type: JobDefinitionDataType = null;

    @Required()
    @IsString()
    from: string = '';

    level: DataSourceJsonLevel = undefined;

    @Type(JobDefinitionDataSource)
    details: JobDefinitionDataSource[] = [];

    @Type(PrimitiveDataType)
    constants: Map<string, PrimitiveDataType> = {} as any;

    @Type(PrimitiveDataType)
    parameters: Map<string, PrimitiveDataType> = {} as any;

    @Type(DataSourceJoin)
    joins: DataSourceJoin[] = [];

    @Type(Number)
    limit: number = null;

    @IsBoolean()
    distinct = false;

    @Type(String)
    sort: string[] = [];

    filter: DataFilter = undefined;
    paging: DataSourcePaging = undefined;
    aggregation: DataSourceAggregation = undefined;

    static LevelIsRequired(group: FormGroup) {
        return !!group.get('level') ? null : { levelIsRequired: true };
    }

    getType(): string {
        return 'JobDefinitionDataSource';
    }

    getInfo(): string {
        return `Data Source ${this.type ? `type = "${this.type}"` : ''} ${this.from ? `from = "${this.from}"` : ''}`;
    }

    hasStringFilter(): boolean {
        if (this.filter) {
            return DataFilterString.HasStringFilter()(this.filter);
        } else {
            return false;
        }
    }

    removeStringFilters() {
        if (this.filter && this.type !== JobDefinitionDataType.ELASTICSEARCH) {
            this.filter = DataFilterString.RemoveStringFilters()(this.filter);
        }
    }

    constructor(instanceData?: JobDefinitionDataSource, nextLevel: boolean = false) {
        super();
        this.fillByInstance(JobDefinitionDataSource, instanceData);
        if (nextLevel) {
            this.details = undefined;
            this.joins = undefined;
        }
        if (instanceData) {
            this.removeStringFilters();
        }
    }
}

enum DataSourceNextPageCheck {
    VALUE = 'VALUE',
    REVERSED_VALUE = 'REVERSED_VALUE',
    TOTAL_COMPARISON = 'TOTAL_COMPARISON',
    MAX_RESULT_COMPARISON = 'MAX_RESULT_COMPARISON',
    PAGE_RESULT_COMPARISON = 'PAGE_RESULT_COMPARISON',
}

class DataSourcePaging extends Deserializable {
    _class = {};
    _enums = {
        check: DataSourceNextPageCheck,
    };
    _arrays = {
        path: 'string',
    };
    _maps = {};
    meta = {
        _arrays: this._arrays,
        _enums: this._enums,
        _class: this._class,
        _maps: this._maps,
    };

    @Required()
    @IsInt()
    page = 0;

    @Required()
    @IsInt()
    size = 0;

    @Required()
    @IsInt()
    increment = 0;

    @Required()
    @IsString()
    sizeParameter = '';

    @Required()
    @IsString()
    pageParameter = '';

    @Required()
    @IsEnum(DataSourceNextPageCheck)
    check: DataSourceNextPageCheck = null;

    @Type(String)
    path: string[] = [];

    getType() {
        return 'DataSourcePaging';
    }

    getInfo() {
        return `Paging ${this.page ? `page = "${this.page}"` : ''} ${this.size ? `size = "${this.size}"` : ''}`;
    }

    constructor(instanceData?: DataSourcePaging) {
        super();
        this.fillByInstance(DataSourcePaging, instanceData);
    }
}

class DataSourceJsonLevel extends Deserializable {
    _class = {};
    _enums = {};
    _maps = {
        levels: DataSourceJsonLevel,
        fields: 'string',
    };
    _arrays = {};
    _nameMapping = {
        fields: {
            key: 'From',
            value: 'To',
        },
        levels: {
            key: 'From',
            value: '',
        },
    };
    meta = {
        _arrays: this._arrays,
        _enums: this._enums,
        _class: this._class,
        _maps: this._maps,
        _nameMapping: this._nameMapping,
    };

    @Type(String)
    fields: Map<string, string> = {} as any;

    @Type(DataSourceJsonLevel)
    levels: Map<string, DataSourceJsonLevel> = {} as any;

    static CollectionsRequired(group: FormGroup): ValidationErrors | null {
        const fieldsGroup = group.get('fields') as FormGroup;
        const levelsGroup = group.get('levels') as FormGroup;

        if (!!mapRequired(fieldsGroup) && !!mapRequired(levelsGroup)) {
            return { collectionsRequired: true };
        }

        return null;
    }

    getType() {
        return 'DataSourceJsonLevel';
    }

    getInfo() {
        return `Json Level`;
    }

    constructor(instanceData?: DataSourceJsonLevel) {
        super();
        this.fillByInstance(DataSourceJsonLevel, instanceData);
    }
}

export enum DataSourceAggregationType {
    MAX = 'MAX',
    MIN = 'MIN',
    SUM = 'SUM',
    COUNT = 'COUNT',
    AVG = 'AVG'
}

export class DataSourceAggregation extends Deserializable {
    _class = {};
    _enums = {
        aggregations: DataSourceAggregationType,
    };
    _arrays = {
        fields: 'string',
    };
    _maps = {
        aggregations: DataSourceAggregationType,
    };
    meta = {
        _arrays: this._arrays,
        _enums: this._enums,
        _class: this._class,
        _maps: this._maps,
    };

    @Type(String)
    fields: string[] = [];

    @MapRequired()
    @Validate([NotEmpty(isEmpty)])
    @Type(DataSourceAggregationType, { enum: true })
    aggregations: Map<string, DataSourceAggregationType> = {} as any;

    getType() {
        return 'DataSourceAggregation';
    }

    getInfo() {
        return `Aggregation`;
    }

    constructor(instanceData?: DataSourceAggregation) {
        super();
        this.fillByInstance(DataSourceAggregation, instanceData);
    }
}

// @dynamic
export class DataFilter extends Deserializable {
    @Required()
    @IsEnum(DataFilterOperation)
    operation: DataFilterOperation = null;

    _maps = {};
    _arrays = {};
    _class = {};
    _enums = { operation: DataFilterOperation };
    meta = {
        _arrays: this._arrays,
        _enums: this._enums,
        _class: this._class,
        _maps: this._maps,
    };

    static Match = <Y, T extends DataFilter>(
        pattern: Partial<{ [K in DataFilterOperation]: (filter?: T) => Y }> & { DEFAULT: (filter?: T) => Y }
    ) => (filter: any): Y =>
        filter && filter.operation != null && (pattern as Record<string, any>)[filter.operation]
            ? (pattern as Record<string, any>)[filter.operation](filter)
            : pattern.DEFAULT(filter);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    static Of = DataFilter.Match({
        AND: filter => new DataFilterAnd(filter),
        OR: filter => new DataFilterOr(filter),
        EQ: filter => new DataFilterEq(filter),
        IN: filter => new DataFilterIn(filter),
        NE: filter => new DataFilterNe(filter),
        STRING: filter => new DataFilterString(filter),
        NIN: filter => new DataFilterNin(filter),
        RANGE: filter => new DataFilterRange(filter),
        IS_NULL: filter => new DataFilterIsNull(filter),
        NOT_NULL: filter => new DataFilterNotNull(filter),
        LIKE: filter => new DataFilterLike(filter),
        DEFAULT: () => new DataFilter(),
    });

    // eslint-disable-next-line @typescript-eslint/member-ordering
    static IsInFilter = DataFilter.Match({
        IN: () => true,
        NIN: () => true,
        DEFAULT: () => false,
    });

    // eslint-disable-next-line @typescript-eslint/member-ordering
    static ValueIsRequired = DataFilter.Match({
        IN: filter => (filter as DataFilterIn).dataSource == null,
        NIN: filter => (filter as DataFilterNin).dataSource == null,
        DEFAULT: () => false,
    });

    getInfo() {
        return `Data Filter ${this.operation ? `operation = "${this.operation}"` : ''}`;
    }

    getType() {
        return 'DataFilter';
    }

    constructor(instanceData?: DataFilter) {
        super();
        if (!!instanceData) {
            return DataFilter.Of(instanceData);
        }
    }
}

export class DataFilterIsNull extends DataFilter {
    _arrays = {};
    _class = {};
    _maps = {};
    _enums = {
        operation: DataFilterOperation,
    };
    meta = {
        _arrays: this._arrays,
        _enums: this._enums,
        _class: this._class,
        _maps: this._maps,
    };

    @Required()
    @IsEnum(DataFilterOperation)
    operation = DataFilterOperation.IS_NULL;

    @Required()
    @IsString()
    field = '';

    constructor(instanceData?: any) {
        super();
        this.fillByInstance(DataFilterIsNull, instanceData);
    }
}

export class DataFilterNotNull extends DataFilter {
    _arrays = {};
    _class = {};
    _maps = {};
    _enums = {
        operation: DataFilterOperation,
    };
    meta = {
        _arrays: this._arrays,
        _enums: this._enums,
        _class: this._class,
        _maps: this._maps,
    };

    @Required()
    @IsEnum(DataFilterOperation)
    operation = DataFilterOperation.NOT_NULL;

    @Required()
    @IsString()
    field = '';

    constructor(instanceData?: any) {
        super();
        this.fillByInstance(DataFilterNotNull, instanceData);
    }
}

export class DataFilterAnd extends DataFilter {
    _arrays = { filters: DataFilter };
    _maps = {};
    _class = {};
    _enums = { operation: DataFilterOperation };
    meta = {
        _arrays: this._arrays,
        _enums: this._enums,
        _class: this._class,
        _maps: this._maps,
    };

    @Required()
    @IsEnum(DataFilterOperation)
    operation = DataFilterOperation.AND;

    @Required()
    @Type(DataFilter)
    filters: DataFilter[] = [];

    constructor(instanceData?: any) {
        super();
        this.fillByInstance(DataFilterAnd, instanceData);
    }
}

export class DataFilterOr extends DataFilterAnd {
    _maps = {};
    _class = {};
    _enums = { operation: DataFilterOperation };
    meta = {
        _arrays: this._arrays,
        _enums: this._enums,
        _class: this._class,
        _maps: this._maps,
    };

    @Required()
    @IsEnum(DataFilterOperation)
    operation = DataFilterOperation.OR;

    constructor(instanceData?: any) {
        super();
        this.fillByInstance(DataFilterOr, instanceData);
    }
}

export class DataFilterIn extends DataFilter {
    _class = {
        value: PrimitiveDataType,
        dataSource: JobDefinitionDataSource,
    };
    _maps = {};
    _enums = { operation: DataFilterOperation };
    _arrays = {};
    meta = {
        _arrays: this._arrays,
        _enums: this._enums,
        _class: this._class,
        _maps: this._maps,
    };

    @Required()
    @IsEnum(DataFilterOperation)
    operation = DataFilterOperation.IN;

    @Type(PrimitiveDataType)
    value = '';

    @Type(PrimitiveDataType)
    field = '';

    dataSource: JobDefinitionDataSource = undefined;

    constructor(instanceData?: any) {
        super();
        this.fillByInstance(DataFilterIn, instanceData);
    }
}

export class DataFilterNin extends DataFilterIn {
    meta = {
        _arrays: this._arrays,
        _enums: this._enums,
        _class: this._class,
        _maps: this._maps,
    };

    @Required()
    @IsEnum(DataFilterOperation)
    operation = DataFilterOperation.NIN;

    @Required()
    @IsString()
    field = '';

    constructor(instanceData?: any) {
        super();
        this.fillByInstance(DataFilterNin, instanceData);
    }
}

export class DataFilterEq extends DataFilter {
    _class = {
        value: PrimitiveDataType,
    };
    _arrays = {};
    _enums = { operation: DataFilterOperation };
    _maps = {};
    meta = {
        _arrays: this._arrays,
        _enums: this._enums,
        _class: this._class,
        _maps: this._maps,
    };

    @Required()
    @IsEnum(DataFilterOperation)
    operation = DataFilterOperation.EQ;

    @Required()
    @IsString()
    field = '';

    @Type(PrimitiveDataType)
    value = '';

    constructor(instanceData?: any) {
        super();
        this.fillByInstance(DataFilterEq, instanceData);
    }
}

export class DataFilterLike extends DataFilter {
    _class = {
        value: PrimitiveDataType,
    };
    _arrays = {};
    _enums = { operation: DataFilterOperation };
    _maps = {};
    meta = {
        _arrays: this._arrays,
        _enums: this._enums,
        _class: this._class,
        _maps: this._maps,
    };

    @Required()
    @IsEnum(DataFilterOperation)
    operation = DataFilterOperation.LIKE;

    @Required()
    @IsString()
    field = '';

    @Type(PrimitiveDataType)
    value = '';

    constructor(instanceData?: any) {
        super();
        this.fillByInstance(DataFilterLike, instanceData);
    }
}

export class DataFilterNe extends DataFilterEq {
    meta = {
        _arrays: this._arrays,
        _enums: this._enums,
        _class: this._class,
        _maps: this._maps,
    };

    @Required()
    @IsEnum(DataFilterOperation)
    operation = DataFilterOperation.NE;

    constructor(instanceData?: any) {
        super();
        this.fillByInstance(DataFilterNe, instanceData);
    }
}

export class DataFilterRange extends DataFilter {
    _maps = {
        value: PrimitiveDataType,
    };
    _enums = { operation: DataFilterOperation };
    _arrays = {};
    _class = {};
    meta = {
        _arrays: this._arrays,
        _enums: this._enums,
        _class: this._class,
        _maps: this._maps,
    };

    @Required()
    @IsEnum(DataFilterOperation)
    operation = DataFilterOperation.RANGE;

    @Required()
    @IsString()
    field = '';

    @Validate([DataFilterRange.RangeMapValidator])
    value: Map<string, PrimitiveDataType> = {} as any;

    static RangeMapValidator(control: FormGroup) {
        const from = control.get('from');
        const to = control.get('to');

        if (!from && !to) {
            return { rangeRequired: true };
        } else {
            return null;
        }
    }

    constructor(instanceData?: any) {
        super();
        this.fillByInstance(DataFilterRange, instanceData);
    }
}

export class DataFilterString extends DataFilter {
    _class = {};
    _arrays = {};
    _enums = { operation: DataFilterOperation };
    _maps = {};
    meta = {
        _arrays: this._arrays,
        _enums: this._enums,
        _class: this._class,
        _maps: this._maps,
    };

    @Required()
    @IsEnum(DataFilterOperation)
    operation = DataFilterOperation.STRING;

    @Type(PrimitiveDataType)
    value = '';

    static HasStringFilter() {
        const matchFunction: any = DataFilter.Match({
            STRING: () => true,
            OR: filter => (filter as DataFilterOr).filters.some(matchFunction),
            AND: filter => (filter as DataFilterAnd).filters.some(matchFunction),
            DEFAULT: () => false,
        });

        return matchFunction;
    }

    static RemoveStringFilters() {
        const matchFunction = DataFilter.Match({
            STRING: () => undefined,
            OR: filter => {
                (<DataFilterOr>filter).filters = (filter as DataFilterOr).filters.filter(matchFunction);
                return filter;
            },
            AND: filter => {
                (<DataFilterAnd>filter).filters = (filter as DataFilterAnd).filters.filter(matchFunction);
                return filter;
            },
            DEFAULT: filter => filter,
        });

        return matchFunction;
    }

    constructor(instanceData?: any) {
        super();
        this.fillByInstance(DataFilterString, instanceData);
    }
}

export class PostUpdate extends Deserializable {
    _class = {
        filter: DataFilter,
    };
    _arrays = {};
    _enums = {
        dataType: JobDefinitionDataType,
    };
    _maps = {
        values: PrimitiveDataType,
    };
    meta = {
        _arrays: this._arrays,
        _enums: this._enums,
        _class: this._class,
        _maps: this._maps,
    };

    @Required()
    @IsEnum(JobDefinitionDataType)
    dataType: JobDefinitionDataType = null;

    @Required()
    @IsString()
    target: string = '';

    @MapRequired()
    @Validate([NotEmpty(PrimitiveDataType.IsEmpty)])
    @Type(PrimitiveDataType)
    values: Map<string, PrimitiveDataType> = {} as any;

    filter: DataFilter = undefined;

    getType() {
        return 'PostUpdate';
    }

    getInfo() {
        return `Post Update ${this.dataType ? `data type = "${this.dataType}"` : ''} ${this.target ? `target = "${this.target}"` : ''}`;
    }

    hasStringFilter(): boolean {
        return this.filter ? DataFilterString.HasStringFilter()(this.filter) : false;
    }

    removeStringFilters() {
        if (this.filter && this.dataType !== JobDefinitionDataType.ELASTICSEARCH) {
            this.filter = DataFilterString.RemoveStringFilters()(this.filter);
        }
    }

    constructor(instanceData?: any) {
        super();

        this.fillByInstance(PostUpdate, instanceData);
        if (instanceData) {
            this.removeStringFilters();
        }
    }
}
