import { Component, EventEmitter, Input, Output } from '@angular/core';

import { PortfolioScoreHealth, PortfolioScoreTab } from './models/portfolio-scores.model';

interface PortfolioHealthScoresData {
    scoreHealth: PortfolioScoreHealth;
    categories: PortfolioScoreHealth[];
    selectedCategory?: string;
}

@Component({
    selector: 'dp-portfolio-health-scores',
    templateUrl: './portfolio-health-scores.component.html',
    styleUrls: ['./portfolio-health-scores.component.scss'],
})
export class PortfolioHealthScoresComponent {
    @Input() set data(value: PortfolioHealthScoresData) {
        this.handleFirstLoading(value);
        this.items = value;
    }

    activeTab: PortfolioScoreTab;
    items: PortfolioHealthScoresData;

    @Output() categoryClicked = new EventEmitter();

    ngOnChanges(changes: any) {
        let selectedTab = changes.data?.currentValue?.selectedCategory;
        if (selectedTab) {
            this.activeTab = selectedTab;
        }
    }

    handleFirstLoading(value: PortfolioHealthScoresData) {
        if (!this.items) {
            if (value && value.categories && value.categories[0]) {
                const firstCategory = value.categories[0];
                this.activeTab = firstCategory.type as PortfolioScoreTab;
                this.categoryClicked.emit({ type: firstCategory.type, pk: firstCategory.pk });
            } else {
                this.categoryClicked.emit({});
            }
        }
    }

    onClick(category: PortfolioScoreHealth) {
        this.activeTab = category.type as PortfolioScoreTab;
        this.categoryClicked.emit({ type: category.type, pk: category.pk });
    }
}
