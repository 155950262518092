import { generateUUID } from '@dagility-ui/kit';

import { AnyWidgetModel, DropdownFilter, WidgetDrilldown, WidgetFilter, WidgetQuery } from './any-widget.model';
import { isDynamicFilter } from '../services/widget-builder.util';

type ID = string;

export interface WidgetGraph {
    root: ID | null;
    layers: { [id in ID]: WidgetLayer };
    queries: { [id in ID]: WidgetQuery };
    filters: { [id in ID]: WidgetFilterNode };
    actions: WidgetAction[];
}

export interface WidgetAction<T = any> {
    id: string;
    from: ID;
    to: ID;
    data: T;
    name?: string;
}

export type WidgetLayer = Omit<AnyWidgetModel, 'widgets' | 'drilldown' | 'query' | 'filters' | 'drilldownList'> & {
    widgets: ID[];
    queries: ID[];
    filters: ID[];
};

export type WidgetFilterNode = Omit<WidgetFilter, 'query'> & {
    query?: ID;
};

export function normalizeWidgetModel(widget: AnyWidgetModel): WidgetGraph {
    widget.id = widget.id || generateUUID();

    const graph = {
        root: widget.id,
        layers: {},
        queries: {},
        filters: {},
        actions: [] as WidgetAction[],
    };

    convertAnyWidgetToGraph(widget, graph);

    return graph;
}

function convertAnyWidgetToGraph(widget: AnyWidgetModel, graph: WidgetGraph): WidgetLayer {
    const { query, drilldown, drilldownList, ...widgetData } = widget;
    const normalizedWidget = {
        ...widgetData,
        id: widget.id || generateUUID(),
        filters: (widget.filters || []).map(filter => {
            const id = generateUUID();
            const filterQuery = (filter as DropdownFilter).query;
            const queryId = generateUUID();
            graph.filters[id] = Object.assign(
                {},
                filter,
                { id },
                filterQuery ? { query: queryId } : {},
                isDynamicFilter(filter)
                    ? {
                          dependencies: (filter as DropdownFilter).dependencies || [],
                      }
                    : {}
            );

            if (filterQuery) {
                graph.queries[graph.filters[id].query] = { ...filterQuery, id: queryId };
            }

            return id;
        }),
        widgets: (widget.widgets || []).map(widget => {
            const complexWidget = convertAnyWidgetToGraph(widget, graph);

            return complexWidget.id;
        }),
        queries: (widget.query || []).map(query => {
            const id = generateUUID();
            graph.queries[id] = { ...query, id } as WidgetQuery;

            return id;
        }),
    };

    const drilldowns = drilldownList && drilldownList.length ? drilldownList : !!drilldown ? [drilldown] : [];
    drilldowns.forEach(d => {
        const drilldownWidget = convertAnyWidgetToGraph(d.widget, graph);
        const { widget, ...data } = d;

        graph.actions.push({
            id: generateUUID(),
            from: normalizedWidget.id,
            to: drilldownWidget.id,
            data,
        });
    });

    graph.layers[normalizedWidget.id] = normalizedWidget;

    return normalizedWidget;
}

export function convertGraphToAnyWidget(layerId: string, graph: WidgetGraph, removeId = true): AnyWidgetModel {
    const actionsMap = graph.actions.reduce<Record<string, WidgetAction[]>>((acc, action) => {
        if (!acc[action.from]) {
            acc[action.from] = [];
        }
        acc[action.from].push(action);

        return acc;
    }, {});
    const { queries, ...layer } = graph.layers[layerId];
    const actions = actionsMap[layerId] as WidgetAction<WidgetDrilldown>[];
    const drilldownList: WidgetDrilldown[] = (actions || []).map(({ to, data: { id, widget, ...drilldownData } }) => ({
        ...drilldownData,
        widget: convertGraphToAnyWidget(to, graph, removeId),
    }));

    return {
        ...layer,
        chartOptions: { ...layer.chartOptions, id: null },
        filters: getWidgetFilters(layer.filters || [], graph, removeId),
        widgets: getWidgetsForComplex(layer.widgets || [], graph, removeId),
        query: getWidgetQueries(queries || [], graph, removeId),
        drilldownList,
    };
}

function getWidgetFilters(filterIds: ID[], graph: WidgetGraph, removeId = true): WidgetFilter[] {
    const filters = [];
    for (const filterId of filterIds) {
        const filter = graph.filters[filterId];
        let query: any = null;

        if (!!filter.query) {
            query = getWidgetQueries([filter.query], graph, removeId)[0];
        }

        filters.push({ ...filter, id: removeId ? null : filterId, query } as any);
    }
    return filters;
}

function getWidgetQueries(queryIds: ID[], graph: WidgetGraph, removeId = true): WidgetQuery[] {
    const queries = [];
    for (const queryId of queryIds) {
        const query = { ...graph.queries[queryId] };
        if (removeId) {
            delete query.id;
        }

        queries.push(query);
    }

    return queries;
}

function getWidgetsForComplex(widgetsIds: ID[], graph: WidgetGraph, removeId = true): AnyWidgetModel[] {
    const widgets = [];
    for (const widgetId of widgetsIds) {
        widgets.push(convertGraphToAnyWidget(widgetId, graph, removeId));
    }

    return widgets;
}
