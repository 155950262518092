/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Input, OnDestroy, OnInit, Optional, TemplateRef, ViewChild } from '@angular/core';
import { SourceFolderItem, SourcesBelongType } from '../../../../../models/application-source.model';
import { FileEditComponent } from './file-edit/file-edit.component';
import { ToastrService } from 'ngx-toastr';
import { CiCdAppSourcesService } from '../../../../../api/ci-cd-app-sources.service';
import { NewFolderModalComponent } from './new-folder-modal/new-folder-modal.component';
import { BehaviorSubject, forkJoin, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { FileSizePipe } from '@app/shared/pipes/file-size.pipe';
import { AuthService } from '@app/auth';
import { TemplateEditorService } from '../../../../services/template-editor.service';
import { AutoCompletionService } from '../../../../../api/auto-completion.service';
import { ProjectComponentsFacade } from '../../../../../../project/project-edit/project-components/project-components.facade';
import {
    GridColumn,
    ModalConfirmComponent,
    ModalService,
    RbacAction,
    RbacStaticType,
    sortingDown,
    sortingUp,
    writeContents,
} from '@dagility-ui/kit';
import { CompletionSourceItem, LogModalComponent } from '@dagility-ui/shared-components';
import {
    APP_SOURCE_MAX_FILE_SIZE,
    APP_SOURCE_MAX_PROJECT_ZIP_FILE_SIZE,
    checkFileFormat,
    sortFolderFilesByKey,
} from '../../../../../template-list-common/files-grid.utilities';
import { Sort, sortGridColumn } from '@dagility-ui/shared-components/utils/sorting.utils';
import { TreeLikeMenuGroup } from '@dagility-ui/kit/modules/forms/controls/treelike-menu/treelike-menu.component';
import { PublishComponentChangesDialogComponent } from '../publish-component-changes-dialog/publish-component-changes-dialog.component';
import { ILogItem } from 'src/app/pages/project/project-edit/project-magic-in-progress/do-the-magic-form/do-the-magic.model';
import { CommitMessageModalComponent } from '@app/shared/components/commit-message-modal/commit-message-modal.component';
import { ProjectStore } from 'src/app/pages/project/project-edit/project-edit.store';
import { ProjectComponentsService } from 'src/app/pages/project/project-edit/project-components/project-components.service';
import { ProjectPipelineScriptVersionsComponent } from 'src/app/pages/project/project-edit/project-pipelines/project-pipeline/project-pipeline-script/project-pipeline-script-versions/project-pipeline-script-versions.component';
import { VersionHistoryType } from 'src/app/pages/project/project-edit/project-pipelines/models/project-pipeline-items';
import { ProjectComponent } from 'src/app/pages/project/project-edit/project-components/models/project-component-items';

@Component({
    selector: 'app-files-grid',
    templateUrl: './files-grid.component.html',
    styleUrls: ['./files-grid.component.scss'],
})
export class FilesGridComponent implements OnInit, OnDestroy {
    @Input() sourceType: SourcesBelongType = SourcesBelongType.TEMPLATE_COMPONENT;
    @Input() sourceId: number = null;
    @Input() gridHeight: string = document.documentElement.clientHeight - 520 + 'px';
    @Input() enablePublish: boolean;

    @ViewChild('widgetTemplate') widgetTemplate: TemplateRef<any>;
    @ViewChild('logContentTemplate', { static: true }) logContentTemplate: TemplateRef<any>;
    @ViewChild('titleTemplate', { static: true }) titleTemplate: TemplateRef<any>;

    sourcesBelongType = SourcesBelongType;

    autoCompletion: CompletionSourceItem[] = [];
    canModify: boolean;
    headerStyle: boolean;

    sort$ = new BehaviorSubject({} as Sort);
    currentSourceFolder$: Observable<SourceFolderItem[]>;

    currentPath = '';

    icons = {
        sortingUp,
        sortingDown,
    };

    gridColumns: Array<GridColumn & { sort?: string }> = [
        { title: 'Name', field: 'name', sortingField: 'name', minWidth: '100px', width: '25%', sort: '' },
        { title: 'Size', field: 'size', sortingField: 'size', width: '15%', minWidth: '100px', sort: '' },
        {
            title: 'Last Modified Date',
            field: 'lastModifiedDate',
            sortingField: 'lastModifiedDate',
            width: '25%',
            minWidth: '100px',
            sort: '',
        },
        { title: '', width: '35%', minWidth: '100px', sort: '' },
    ];

    private destroy$ = new Subject();

    actionItems: TreeLikeMenuGroup[] = [];

    constructor(
        private apiService: CiCdAppSourcesService,
        private autoCompletionService: AutoCompletionService,
        private modalService: ModalService,
        private toaster: ToastrService,
        private route: ActivatedRoute,
        private fileSizePipe: FileSizePipe,
        private authService: AuthService,
        public editorService: TemplateEditorService,
        private componentsService: ProjectComponentsService,
        @Optional() private projectComponentsFacade: ProjectComponentsFacade,
        @Optional() private projectStore: ProjectStore,
    ) {
        this.hasRight('modify').subscribe(value => (this.canModify = value));
    }

    hasRight(action: RbacAction): Observable<boolean> {
        return this.authService.hasPermission(RbacStaticType.OBJECT, 'AppSource', action);
    }

    ngOnInit(): void {
        this.currentPath = '';
        if (this.sourceId === null) {
            this.sourceId = +this.editorService.selectedSubMenuItem.id;
        } else {
            this.headerStyle = true;
        }
        this.getFolder(this.currentPath);
        this.checkAutoCompletion();
        this.initActionItems();
    }

    getFolder(path: string = '', reloadComponents?: boolean) {
        this.currentSourceFolder$ = this.apiService.getFolder(path + '/', this.sourceId, this.sourceType).pipe(
            tap(() => (this.currentPath = path)),
            switchMap(this.sortSources.bind(this)),
        );
        if (reloadComponents) {
            this.componentsService
                .getComponents(this.projectComponentsFacade.projectId)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response: ProjectComponent[]) => {
                    const activeComponentId = this.projectComponentsFacade.activeComponent?.id;
                    this.projectComponentsFacade.activeComponent = response.filter(component => component.id === activeComponentId).pop();
                    this.projectComponentsFacade.components$.next(response);
                });
        }
    }

    getFileContent(sourceItem: SourceFolderItem, path: string = '') {
        if (checkFileFormat(sourceItem)) {
            this.openFile(path, sourceItem);
        } else {
            const modalRef = this.modalService.open(ModalConfirmComponent, {
                centered: true,
                backdrop: 'static',
            });
            modalRef.componentInstance.message = {
                title: `Unexpected file format`,
                content: `The file looks like a non-text file. Do you still want to edit it?`,
            };
            modalRef.componentInstance.confirmOk.subscribe(() => this.openFile(path, sourceItem));
        }
    }

    private initActionItems() {
        this.actionItems = [
            {
                label: '',
                items: [
                    {
                        label: 'New Folder',
                        icon: 'facOpenFolderO',
                        actionHandler: () => this.createFolder(),
                        hidden: !this.canModify || this.editorService.readonly,
                    },
                    {
                        label: 'Upload file',
                        icon: 'plus',
                        actionHandler: () => this.upload(),
                        hidden: !this.canModify || this.editorService.readonly,
                    },
                    {
                        label: 'Upload from ZIP',
                        icon: 'plus',
                        actionHandler: () => this.upload(true),
                        hidden: !this.canModify || this.editorService.readonly,
                    },
                    {
                        label: 'Download as ZIP',
                        icon: 'facDownload',
                        actionHandler: () => this.download(null),
                    },
                ],
            },
        ];
    }

    private openFile(path: string, sourceItem: SourceFolderItem) {
        const reader: FileReader = new FileReader();

        this.apiService.getFile(path, this.sourceId, this.sourceType).subscribe(data => {
            reader.onloadend = () => {
                const modalRef = this.modalService.open(
                    FileEditComponent,
                    {
                        centered: true,
                        backdrop: 'static',
                        windowClass: 'file-edit-popup',
                        beforeDismiss: () => {
                            const { wasSaved, showConfirm } = modalRef.componentInstance;
                            if (!wasSaved) {
                                const confirmRes = showConfirm();
                                if (confirmRes) {
                                    modalRef.close(false);
                                }
                            } else {
                                modalRef.close(false);
                            }
                            return false;
                        },
                    },
                    {
                        contentValue: reader.result,
                        sourceItem: sourceItem,
                        path: this.currentPath,
                        appSourceId: this.sourceId,
                        readOnly: !this.canModify || this.editorService.readonly,
                        autoCompletionDictionary: this.autoCompletion,
                        sourceType: this.sourceType,
                    },
                );

                modalRef.result.then(
                    (result: any) => {
                        if (result) {
                            this.getFolder(this.currentPath, true);
                        }
                    },
                    () => {
                        /* rejected */
                    },
                );
            };
            reader.readAsText(data);
        });
    }

    editSource(sourceItem: SourceFolderItem) {
        this.getFileContent(sourceItem, this.currentPath + `/${sourceItem.name}`);
    }

    removeSource(sourceItem: SourceFolderItem) {
        let messagePrefix = 'File';
        let pathPostfix = '';
        if (sourceItem.isFolder) {
            messagePrefix = 'Folder';
            pathPostfix = '/';
        }

        this.confirmToDoAction(
            true,
            () => {
                this.apiService
                    .removeSourceItem(this.currentPath + `/${sourceItem.name}${pathPostfix}`, this.sourceId, this.sourceType)
                    .subscribe(
                        () => {
                            this.toaster.success(`${messagePrefix} ${sourceItem.name} is removed successfully`);
                            this.getFolder(this.currentPath, true);
                        },
                        error => {
                            this.toaster.error(`Error: ${error['message']}`, `${messagePrefix} ${sourceItem.name} is not removed.`);
                        },
                    );
            },
            `Remove ${messagePrefix}?`,
            `${messagePrefix} \"${sourceItem.name}\" will be removed`,
        );
    }

    upload(allAsZip: boolean = false): void {
        const input = document.createElement('input');
        input.type = 'file';
        input.multiple = !allAsZip;
        if (allAsZip) {
            input.accept = 'application/zip';
        }
        input.onchange = () => {
            const fileList = input.files;
            const uploadsList: any[] = [];
            for (let i = 0; i < fileList.length; i++) {
                const file: File = input.files[i];
                const MAX_FILE_SIZE = allAsZip ? APP_SOURCE_MAX_FILE_SIZE : APP_SOURCE_MAX_PROJECT_ZIP_FILE_SIZE;
                const fileSizeString = this.fileSizePipe.transform(MAX_FILE_SIZE);
                if (file.size && file.size > MAX_FILE_SIZE) {
                    this.toaster.error(`You have selected a file greater then ${fileSizeString} to upload.`);
                    continue;
                }
                const upload = !allAsZip
                    ? this.apiService.uploadAppSourceFile(this.currentPath + '/', this.sourceId, file, this.sourceType)
                    : this.apiService.uploadAllAsZip(this.sourceId, file, this.sourceType);
                uploadsList.push(upload);
            }
            forkJoin(uploadsList).subscribe((response: any) => {
                if (response[0] !== null) {
                    this.sourceId = response[0];
                }
                this.currentPath = allAsZip ? '' : this.currentPath;
                this.getFolder(this.currentPath, true);
            });
        };

        const title = 'Do you want continue?';
        const message = 'The uploaded archived files will be extracted as project. Existing files will be overwritten';
        this.confirmToDoAction(
            allAsZip,
            () => {
                input.click();
            },
            title,
            message,
        );
    }

    download(sourceItem: SourceFolderItem = null) {
        let path = '';
        const zipName = (
            (this.editorService.selectedSubMenuItem$.getValue() && this.editorService.selectedSubMenuItem$.getValue().data.name) ||
            this.projectComponentsFacade.activeComponent.name
        )
            .replace(/,/g, '-')
            .replace(/\./g, '-')
            .replace(/\s+/g, '-')
            .toLowerCase();
        let fileName = `${zipName}.zip`;
        if (!sourceItem) {
            this.apiService.getAllAsZip(this.sourceId, this.sourceType).subscribe(blob => {
                writeContents(blob, fileName); // file extension
            });
        } else if (sourceItem.isFolder) {
            path = this.currentPath + `/${sourceItem.name}`;
            fileName = `${sourceItem.name}.zip`;

            this.apiService.getFolderAsZip(path, this.sourceId, this.sourceType).subscribe(blob => {
                writeContents(blob, fileName); // file extension
            });
        } else {
            path = this.currentPath + `/${sourceItem.name}`;
            fileName = sourceItem.name;

            this.apiService.getFile(path, this.sourceId, this.sourceType).subscribe(blob => {
                writeContents(blob, fileName); // file extension
            });
        }
    }

    handleRowDoubleClick(value: SourceFolderItem) {
        if (value.isFolder) {
            this.currentPath = this.currentPath.concat(`/${value.name}`);
            this.getFolder(this.currentPath);
        } else {
            this.editSource(value);
        }
    }

    handleSelectPath(e: any) {
        this.currentPath = e;
        this.getFolder(this.currentPath);
    }

    createFolder() {
        const modalRef = this.modalService.open(
            NewFolderModalComponent,
            {
                centered: true,
                backdrop: 'static',
                size: 'lg',
            },
            {},
        );
        modalRef.result.then(
            (newFolderName: any) => {
                this.apiService.createFolder(this.sourceId, this.currentPath + '/', newFolderName, this.sourceType).subscribe(() => {
                    this.getFolder(this.currentPath, true);
                });
            },
            () => {
                /* rejected */
            },
        );
    }

    confirmToDoAction(
        requestCondition: boolean = true,
        callback: () => void = () => {
            /* empty */
        },
        title: string = 'Discard',
        message: string = 'Are you sure you want to discard changes?',
    ) {
        if (requestCondition) {
            this.modalService
                .open(
                    ModalConfirmComponent,
                    {
                        centered: true,
                        backdrop: 'static',
                    },
                    {
                        message: {
                            title: title,
                            content: message,
                        },
                    },
                )
                .result.then(
                () => {
                    callback();
                },
                () => {
                    /* rejected */
                },
            );
        } else {
            callback();
        }
    }

    private checkAutoCompletion() {
        switch (this.sourceType) {
            case SourcesBelongType.PROJECT_COMPONENT:
                if (
                    this.projectComponentsFacade.autoCompletion.length &&
                    this.projectComponentsFacade.autoCompletionId === this.sourceId.toString()
                ) {
                    this.autoCompletion = this.projectComponentsFacade.autoCompletion;
                } else {
                    this.refreshAutoCompletionProperties();
                    this.projectComponentsFacade.autoCompletionId = this.sourceId.toString();
                }
                break;
            case SourcesBelongType.TEMPLATE_COMPONENT:
                if (
                    this.editorService.hierarchyLevel === 'COMPONENT' &&
                    this.editorService.autoCompletionId === this.editorService.selectedSubMenuItem.id &&
                    this.editorService.autoCompletion.length
                ) {
                    this.autoCompletion = this.editorService.autoCompletion;
                } else {
                    this.refreshAutoCompletionProperties();
                    this.editorService.autoCompletionId = this.editorService.selectedSubMenuItem.id;
                    this.editorService.hierarchyLevel = 'COMPONENT';
                }
                break;
        }
    }

    private refreshAutoCompletionProperties() {
        if (this.canModify) {
            this.autoCompletionService
                .getCodeCompletionProperties(
                    this.sourceId ? this.sourceId.toString() : this.editorService.selectedSubMenuItem.id,
                    'COMPONENT',
                    this.sourceType === SourcesBelongType.PROJECT_COMPONENT,
                )
                .subscribe((response: CompletionSourceItem[]) => {
                    if (response) {
                        this.autoCompletion = response;
                        if (this.sourceId) {
                            if (this.projectComponentsFacade) {
                                this.projectComponentsFacade.autoCompletion = response;
                            }
                        } else {
                            this.editorService.autoCompletion = response;
                        }
                    }
                });
        }
    }

    sortSources(sources: SourceFolderItem[]) {
        return this.sort$.pipe(map(({
                                        field,
                                        direction,
                                    }) => sortFolderFilesByKey(sources, field, direction === 'ASC')));
    }

    sortClick(index: number) {
        sortGridColumn(index, this.gridColumns, this.sort$);
    }

    openPublishChangesDialog() {
        if (this.sourceType === SourcesBelongType.TEMPLATE_COMPONENT) {
            const componentId = this.route.parent?.snapshot?.params?.id;
            const templateId = this.route.parent?.parent?.parent?.snapshot?.params?.id;
            if (!componentId || !templateId) {
                return;
            }

            this.modalService.open(
                PublishComponentChangesDialogComponent,
                {
                    windowClass: 'publish-component-changes-dialog',
                },
                {
                    templateId,
                    componentId,
                },
            );
        }
        if (this.sourceType === SourcesBelongType.PROJECT_COMPONENT) {
            this.warningModal();
        }
    }

    openShowChangeLogDialog() {
        this.modalService.open(
            ProjectPipelineScriptVersionsComponent,
            {
                centered: true,
                backdrop: 'static',
                windowClass: 'side-panel-versions-modal',
            },
            {
                componentId: this.sourceId,
                modalType: VersionHistoryType.COMPONENT,
            },
        );
    }

    private warningModal() {
        this.modalService
            .open(
                ModalConfirmComponent,
                {
                    centered: true,
                    backdrop: 'static',
                },
                {
                    message: {
                        title: 'Warning',
                        content:
                            'Are you sure you want to publish changes in the SCM repository? \n Files will be updated but not deleted.',
                    },
                },
            )
            .componentInstance.confirmOk.subscribe(() => this.showCommitMessageModal());
    }

    private showCommitMessageModal() {
        this.modalService
            .open(
                CommitMessageModalComponent,
                {
                    centered: true,
                    backdrop: 'static',
                },
                {
                    message: {
                        title: 'Publish to Repository',
                        content: 'Commit message',
                        description: `${this.projectStore.projectPrefix.toUpperCase()}-00: Published by PACE Conveyor`,
                    },
                },
            )
            .componentInstance.confirmOk.subscribe((commit: string) => {
            this.projectComponentsFacade.initSourceCodeGenerationWidget(this.widgetTemplate);
            this.projectComponentsFacade.stompSubscribing();
            this.publishAppSources('repository', commit);
        });
    }

    private publishAppSources(action: string, commit: string) {
        this.componentsService
            .generateSourceCodeTemplate(this.projectComponentsFacade.activeComponent.id, action, commit)
            .pipe(
                catchError(err => {
                    this.projectComponentsFacade.sourceCodeGenerationWidget.destroy();
                    return throwError(err);
                }),
                switchMap(() => this.componentsService.getComponents(this.projectComponentsFacade.projectId)),
                takeUntil(this.destroy$),
            )
            .subscribe((response: ProjectComponent[]) => {
                const activeComponentId = this.projectComponentsFacade.activeComponent?.id;
                this.projectComponentsFacade.activeComponent = response.filter(component => component.id === activeComponentId).pop();
                this.projectComponentsFacade.components$.next(response);
                this.projectComponentsFacade.activeComponent.dataSourceModifiedDate = Date.now();
                this.projectComponentsFacade.showSaveCompletedToast();
            });
    }

    showPublishSourceCodeLogs(log$: BehaviorSubject<ILogItem[]>, widget: any) {
        this.modalService
            .open(
                LogModalComponent,
                {
                    centered: true,
                    backdrop: 'static',

                    size: 'lg',
                    windowClass: 'log-modal',
                },
                {
                    title: 'Publishing Source Code Logs ',
                    titleTemplateRef: this.titleTemplate,
                    contentTemplateRef: this.logContentTemplate,
                    logs$: log$.asObservable(),
                },
            )
            .result.then(
            () => {
            },
            () => {
                widget.destroy();
            },
        );
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }
}
