import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CKEditorModalComponent } from './ckeditor-modal.component';
import { SharedComponentsModule } from '@dagility-ui/shared-components';
import { CKEditorModule } from 'ngx-ckeditor';

@NgModule({
    imports: [CommonModule, SharedComponentsModule, CKEditorModule],
    declarations: [CKEditorModalComponent],
    exports: [CKEditorModalComponent],
})
export class CKEditorModalModule {}
