<lib-ag-grid
    #agGrid
    class="d-block"
    [style.height]="hasFewRows ? 'auto !important' : (pagination && paginationState ? 'calc(100% - 75px) !important' : '100% !important')"
    gridHeight="100%"
    [calculateMaxHeight]="false"
    [rowBuffer]="1000"
    [paginationPageSize]="paginationPageSize"
    [pagination]="paginationState"
    [suppressPaginationPanel]="true"
    [lazyLoad]="chartModel.options.hasResetFilter"
    [showResetButton]="showResetButton"
    [autoSizeColumns]="true"
    (paginationChanged)="onPaginationChanged()"
    [tableData]="chartModel.options.tableData"
    [rowClassRules]="$any(rowClassRules)"
    (rowClicked)="handleDrilldownEvent($event, false)"
    (cellClicked)="handleDrilldownEvent($event, true)"
    (gridReady)="onGridReady(); onPaginationChanged()"
></lib-ag-grid>
<lib-pagination
    *ngIf="pagination && paginationState"
    class="mt-5"
    [ngClass]="pagination.totalElements !== 0 ? 'd-block' : 'd-none'"
    [metaData]="pagination"
    placement="top"
    (pagerdata)="handleChangePaginationState($event)"
></lib-pagination>
