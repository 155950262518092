<div class="card common-popup plugin-config-content p-0">
    <div class="card-header text-body font-14px padding-header d-flex align-items-center">
        <div class="pt-2">
            <img *ngIf="plugin?.imageUrl" [src]="plugin.imageUrl | imagePath" [alt]="pluginName" class="mr-10px plugin-thumb" />
            Configure Access to {{ pluginName }} Instance
        </div>
        <button
            type="button"
            class="close"
            aria-label="Close button"
            aria-describedby="modal-title"
            (click)="activeModal.close('Cross click')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form
        name="form"
        #form="ngForm"
        inttouranchor="someForm"
        [tourAnchorContext]="form"
        novalidate
        autocomplete="off"
        (ngSubmit)="form.form.valid && submitFrom()"
        (change)="resetSentFlag()"
    >
        <div class="card-body common-popup-form popup-form-control padding-body">
            <div class="row">
                <div class="col">
                    <div class="row mb-7px popup-form-control-control-title">
                        <div class="col popup-form-control-title">
                            Base URL
                            <span
                                class="invalid-color"
                                [context-help-id]="'tool-base-url' + getContextHelpSuffix()"
                                default-context-help-id="tool-base-url"
                            >
                                *</span
                            >
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <lib-input
                                id="URL"
                                class="popup-form-control-text"
                                [style.margin-top.px]="3"
                                [class.is-invalid]="URL.touched && URL.invalid"
                                type="text"
                                #URL="ngModel"
                                name="URL"
                                required
                                [pattern]="selectedUrlPattern"
                                [libCustomPatterns]="customPatterns.URL"
                                maxlength="250"
                                aria-label="Base URL"
                                [(ngModel)]="plugin.baseUrl"
                                (ngModelChange)="onCredentialsInputChange()"
                            >
                            </lib-input>
                            <div class="invalid-color feedback-placeholder mt-10px">
                                <ng-container *ngIf="URL.touched && URL.invalid && URL.errors">
                                    <div *ngIf="URL.errors.required">
                                        URL is required
                                    </div>
                                    <div *ngIf="URL.errors.pattern">
                                        Must be a valid URL
                                    </div>
                                    <div *ngIf="!URL.errors.pattern && URL.errors.lastSlashPattern">
                                        {{ URL.errors.lastSlashPattern.errorMsg }}
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col configure-access">
                    <lib-dropdown
                        label="Authentication Type"
                        [items]="authTypesDropdownItems"
                        [(ngModel)]="plugin.authType"
                        [required]="credentialsRequired"
                        [clearable]="false"
                        [class.invalid-border]="(authType.touched && authType.invalid) || notVerified"
                        [class.valid-border]="credentialsVerified && credentialsRequired"
                        name="authType"
                        #authType="ngModel"
                        (change)="handleAuthTypeChange($event)"
                        id="type"
                    >
                        <span
                            [context-help-id]="'tool-authentication-type' + getContextHelpSuffix()"
                            default-context-help-id="tool-authentication-type"
                        ></span>
                    </lib-dropdown>
                    <div class="invalid-color feedback-placeholder">
                        <div *ngIf="authType.touched && authType.invalid && authType.errors?.required">
                            Authentication Type is required
                        </div>
                    </div>
                </div>
            </div>
            <!--credentials-->
            <div [formGroup]="authExtensionForm" class="proxy-field-container mb-4 col-6 px-0 pr-3">
                <checkbox class="size-s" formControlName="proxyEnabled"
                    >Proxy URL <span *ngIf="isProxyRequired$ | async" class="invalid-color">*</span></checkbox
                >
                <lib-input class="mt-7px w-100" maxlength="250" formControlName="proxy"></lib-input>
            </div>
            <ng-container *ngIf="!!plugin.authType">
                <div class="row" *ngIf="['BASIC', 'JDBC'].includes(plugin.authType)">
                    <div class="col">
                        <div class="row mb-7px">
                            <div class="col popup-form-control-title">
                                User
                                <span
                                    class="invalid-color"
                                    [context-help-id]="'tool-user' + getContextHelpSuffix()"
                                    default-context-help-id="tool-user"
                                >
                                </span>
                            </div>
                        </div>
                        <div class="row form-group mb-0">
                            <div class="col">
                                <lib-input
                                    id="User"
                                    class="popup-form-control-text"
                                    [ngClass]="{
                                        'muted-control': !credentialsRequired,
                                        'is-valid': credentialsVerified && credentialsRequired,
                                        'is-invalid': (username.touched && username.invalid) || notVerified
                                    }"
                                    type="text"
                                    name="username"
                                    #username="ngModel"
                                    maxlength="250"
                                    readonly
                                    aria-label="User"
                                    [disabled]="!credentialsRequired"
                                    [(ngModel)]="changedCredentials.username"
                                    (change)="onCredentialsInputChange()"
                                >
                                </lib-input>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="row mb-7px">
                            <div class="col popup-form-control-title">
                                PAT/Password
                                <span
                                    class="invalid-color"
                                    [context-help-id]="'tool-password' + getContextHelpSuffix()"
                                    default-context-help-id="tool-password"
                                >
                                    *</span
                                >
                            </div>
                        </div>
                        <div class="row form-group mb-0">
                            <div class="col">
                                <lib-input
                                    class="popup-form-control-text"
                                    type="password"
                                    [passwordPreview]="true"
                                    [ngClass]="{
                                        'muted-control': !credentialsRequired,
                                        'is-invalid': (p.touched && p.invalid) || notVerified,
                                        'is-valid': credentialsVerified && credentialsRequired
                                    }"
                                    id="Password"
                                    name="p"
                                    #p="ngModel"
                                    [required]="credentialsRequired"
                                    [disabled]="!credentialsRequired"
                                    [(ngModel)]="changedCredentials.password"
                                    readonly
                                    aria-label="Password"
                                    (change)="onCredentialsInputChange()"
                                >
                                </lib-input>
                                <div class="invalid-color feedback-placeholder mt-10px">
                                    <div *ngIf="p.touched && p.invalid && p.errors?.required">
                                        Password is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="plugin.authType == 'FORM'">
                    <div class="form-group mb-0">
                        <div class="row mb-7px">
                            <div class="col">
                                Template
                                <span
                                    class="invalid-color"
                                    [context-help-id]="'tool-template' + getContextHelpSuffix()"
                                    default-context-help-id="tool-template"
                                >
                                    *</span
                                >
                            </div>
                        </div>
                        <div class="row">
                            <div class="col" [style.height.px]="88">
                                <lib-textarea
                                    id="Template"
                                    [ngClass]="{
                                        'is-invalid': (template.touched && template.invalid) || notVerified,
                                        'is-valid': credentialsVerified && credentialsRequired,
                                        'muted-control': !credentialsRequired
                                    }"
                                    name="authTemplate"
                                    [disabled]="!credentialsRequired"
                                    [required]="credentialsRequired"
                                    [resize]="true"
                                    aria-label="Template"
                                    maxlength="1000"
                                    #template="ngModel"
                                    rows="3"
                                    [(ngModel)]="changedCredentials.authTemplate"
                                    (change)="onCredentialsInputChange()"
                                >
                                </lib-textarea>
                                <div
                                    *ngIf="template.touched && template.invalid && template.errors.required"
                                    class="invalid-color feedback-placeholder mt-0"
                                >
                                    <div>
                                        Template is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="plugin.authType == 'SCRIPT'">
                    <div class="form-group mb-0">
                        <div class="row mb-7px">
                            <div class="col">
                                Script
                                <span
                                    class="invalid-color"
                                    [context-help-id]="'tool-script' + getContextHelpSuffix()"
                                    default-context-help-id="tool-script"
                                >
                                    *</span
                                >
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <lib-textarea
                                    id="Script"
                                    [ngClass]="{
                                        'is-invalid': (script.touched && script.invalid) || notVerified,
                                        'is-valid': credentialsVerified && credentialsRequired,
                                        'muted-control': !credentialsRequired
                                    }"
                                    name="script"
                                    class="textarea-script"
                                    #script="ngModel"
                                    aria-label="Script"
                                    [resize]="false"
                                    [resizeMode]="'vertical'"
                                    [disabled]="!credentialsRequired"
                                    [required]="credentialsRequired"
                                    [(ngModel)]="changedCredentials.authTemplate"
                                    (change)="onCredentialsInputChange()"
                                >
                                </lib-textarea>
                                <div
                                    *ngIf="script.touched && script.invalid && script.errors.required"
                                    class="invalid-color feedback-placeholder mt-10px"
                                >
                                    <div>
                                        Script is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="plugin.authType == 'TOKEN'">
                    <div class="col">
                        <div class="row mb-7px">
                            <div class="col popup-form-control-title">
                                Token Header
                                <span
                                    class="invalid-color"
                                    [context-help-id]="'tool-token-header' + getContextHelpSuffix()"
                                    default-context-help-id="tool-token-header"
                                >
                                    *</span
                                >
                            </div>
                        </div>
                        <div class="row form-group mb-0">
                            <div class="col">
                                <lib-input
                                    id="Token-header"
                                    class="popup-form-control-text"
                                    [ngClass]="{
                                        'is-invalid': (tokenHeaderVar.touched && tokenHeaderVar.invalid) || notVerified,
                                        'is-valid': credentialsVerified && credentialsRequired,
                                        'muted-control': !credentialsRequired
                                    }"
                                    type="text"
                                    name="tokenHeader"
                                    #tokenHeaderVar="ngModel"
                                    [disabled]="!credentialsRequired"
                                    [required]="credentialsRequired"
                                    aria-label="Enter Token"
                                    [(ngModel)]="changedCredentials.tokenHeader"
                                    (change)="onCredentialsInputChange()"
                                >
                                </lib-input>
                                <div class="invalid-color feedback-placeholder mt-7px">
                                    <div *ngIf="tokenHeaderVar.touched && tokenHeaderVar.invalid && tokenHeaderVar.errors?.required">
                                        Token Header is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="row mb-7px">
                            <div class="col popup-form-control-title">
                                Token Value
                                <span
                                    class="invalid-color"
                                    [context-help-id]="'tool-token-value' + getContextHelpSuffix()"
                                    default-context-help-id="tool-token-value"
                                >
                                    *</span
                                >
                            </div>
                        </div>
                        <div class="row form-group mb-0">
                            <div class="col">
                                <lib-input
                                    id="Token-value"
                                    class="popup-form-control-text"
                                    [ngClass]="{
                                        'is-invalid': (token.touched && token.invalid) || notVerified,
                                        'is-valid': credentialsVerified && credentialsRequired,
                                        'muted-control': !credentialsRequired
                                    }"
                                    type="text"
                                    aria-label="Token Value"
                                    readonly
                                    name="token"
                                    #token="ngModel"
                                    [required]="credentialsRequired"
                                    [disabled]="!credentialsRequired"
                                    [(ngModel)]="changedCredentials.token"
                                    (change)="onCredentialsInputChange()"
                                >
                                </lib-input>
                                <div class="invalid-color feedback-placeholder mt-10px">
                                    <div *ngIf="token.touched && token.invalid && token.errors?.required">
                                        Token is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mb-16px" [class.d-none]="!plugin.verified_flag">
                    <div class="col-12">
                        <div class="row popup-form-secret-name no-gutters">Vault Secret Name: {{ plugin.toolId ? plugin.toolId : '' }}</div>
                    </div>
                </div>
            </ng-container>

            <div class="row no-gutters align-items-center" [ngClass]="{ 'mt--10px': plugin.authType == 'FORM' }">
                <div *ngIf="!isJDBCTool" class="col-10 verification-path pt-10px pr-2">
                    <div class="row no-gutters mb-7px popup-form-control-control-title">
                        <div class="col">
                            API Test {{ plugin.authType === 'JDBC' ? 'Default Schema' : 'Verification Path' }}
                            <span
                                class="invalid-color"
                                [context-help-id]="'tool-api-test-verification-path' + getContextHelpSuffix()"
                                default-context-help-id="tool-api-test-verification-path"
                            >
                                *</span
                            >
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <lib-input
                                id="Verification-path"
                                class="popup-form-control-text"
                                [ngClass]="{ 'is-invalid': verificationPath.touched && verificationPath.invalid }"
                                type="text"
                                name="verificationPath"
                                maxlength="250"
                                #verificationPath="ngModel"
                                [pattern]="plugin.authType === 'JDBC' ? '' : '/.+'"
                                [attr.aria-label]="'Enter ' + plugin.authType === 'JDBC' ? 'default schema' : 'API verification path'"
                                required
                                [(ngModel)]="plugin.verificationPath"
                                (change)="onCredentialsInputChange()"
                            >
                            </lib-input>
                            <div class="invalid-color feedback-placeholder mt-10px">
                                <div *ngIf="verificationPath.touched && verificationPath.invalid && verificationPath.errors?.pattern">
                                    Must be a valid {{ plugin.authType === 'JDBC' ? 'schema' : 'path' }}
                                </div>
                                <div *ngIf="verificationPath.touched && verificationPath.invalid && verificationPath.errors?.required">
                                    {{ plugin.authType === 'JDBC' ? 'Default schema' : 'Verification path' }} is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-2 verification-buttons" [ngClass]="{ 'mt-30px': needToVerify() }">
                    <div class="row no-gutters flex-end flex-column">
                        <div class="col">
                            <!-- remove attribute 'disabled' when it needs to be enabled -->
                            <button
                                type="button"
                                (click)="verifyAccess()"
                                [class.d-none]="!credentialsRequired"
                                class="btn w-100"
                                [ngClass]="needToVerify() ? 'btn-primary' : 'btn-secondary'"
                            >
                                Verify
                            </button>
                            <!-- when edit mode on remove 'd-none'-->
                            <button
                                type="button"
                                (click)="showCredentialFields(true)"
                                [class.d-none]="credentialsRequired"
                                class="btn w-100"
                                [ngClass]="needToVerify() ? 'btn-primary' : 'btn-secondary'"
                            >
                                Change Credentials
                            </button>
                            <!-- end -->
                            <div *ngIf="needToVerify()" class="row no-gutters invalid-color feedback-placeholder mt-10px flex-end">
                                <div>
                                    Credentials should be verified
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex mb-4 align-items-center">
                <button type="button" class="btn btn-link" (click)="collapse.toggle()">
                    {{ isAuthExtensionsFormClosed ? 'Show' : 'Hide' }} additional authentication settings
                    <fa-icon class="ml-2" icon="chevron-{{ isAuthExtensionsFormClosed ? 'down' : 'up' }}"></fa-icon>
                </button>
                <div class="flex-1 border-bottom"></div>
            </div>
            <div [formGroup]="authExtensionForm" #collapse="ngbCollapse" [(ngbCollapse)]="isAuthExtensionsFormClosed">
                <lib-dropdown
                    label="HTTP method"
                    placeholder="Select method"
                    formControlName="method"
                    [clearable]="false"
                    [items]="httpMethods"
                ></lib-dropdown>

                <div class="my-4" formArrayName="headers">
                    <div *ngIf="headersControl.length" class="d-flex align-items-center justify-content-between mb-4">
                        <h3 class="mt-0">Headers</h3>
                        <ng-container *ngTemplateOutlet="addHeaderButton; context: { buttonSize: 'size-s' }"></ng-container>
                    </div>
                    <div
                        class="row mb-3 align-items-start"
                        *ngFor="let headerControl of headersControl.controls; let i = index; let first = first; let last = last"
                        [formGroup]="$any(headerControl)"
                    >
                        <lib-input
                            style="height: unset"
                            [label]="first ? 'Header' : null"
                            formControlName="label"
                            class="col-5"
                        ></lib-input>
                        <div class="col d-flex">
                            <lib-input
                                style="height: unset"
                                [label]="first ? 'Header Value' : null"
                                formControlName="value"
                                class="flex-1"
                            ></lib-input>
                            <button
                                [disabled]="authExtensionForm.disabled"
                                type="button"
                                class="btn btn-icon ml-3 mt-auto"
                                (click)="handleRemoveHeader(i)"
                            >
                                <fa-icon icon="facTrash"></fa-icon>
                            </button>
                        </div>
                    </div>
                    <ng-container *ngTemplateOutlet="addHeaderButton"></ng-container>

                    <div class="border-bottom mt-4"></div>
                </div>

                <ng-template #addHeaderButton let-buttonSize="buttonSize">
                    <button
                        type="button"
                        class="btn btn-link"
                        [ngClass]="buttonSize"
                        [disabled]="authExtensionForm.disabled"
                        (click)="handleAddNewHeader()"
                    >
                        <fa-icon class="mr-2" icon="plus"></fa-icon>
                        Add header
                    </button>
                </ng-template>
            </div>

            <div class="row">
                <div class="col">
                    <div class="row mb-7px">
                        <div class="col popup-form-control-title">
                            Display Name<span class="invalid-color" context-help-id="tool-display-name"> *</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <lib-input
                                id="Display-name"
                                class="popup-form-control-text"
                                [ngClass]="{ 'is-invalid': name.touched && name.invalid }"
                                type="text"
                                name="name"
                                #name="ngModel"
                                pattern="[a-zA-Z0-9_ ]+"
                                maxlength="50"
                                aria-label="Enter Display Name"
                                required
                                [(ngModel)]="plugin.name"
                            >
                            </lib-input>
                            <div class="invalid-color feedback-placeholder mt-10px">
                                <div *ngIf="name.touched && name.invalid && name.errors?.required">
                                    Name is required
                                </div>
                                <div *ngIf="name.touched && name.invalid && name.errors?.pattern">
                                    Name must be valid. Allowed characters are '0-9', 'a-z', 'A-Z', '_', space.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group mb-0">
                        <div class="row mb-7px">
                            <div class="col popup-form-control-title">External Tool Id<span context-help-id="tool-external-id"></span></div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <lib-input
                                    id="Ext-tool-id"
                                    class="popup-form-control-text"
                                    [ngClass]="{ 'muted-control': editForm }"
                                    type="text"
                                    name="toolId"
                                    #toolId="ngModel"
                                    [readonly]="editForm"
                                    maxlength="50"
                                    aria-label="External Tool Id"
                                    [(ngModel)]="plugin.toolId"
                                >
                                </lib-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="form-group mb-0">
                        <div class="row mb-7px">
                            <div class="col" context-help-id="tool-description">Description</div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <lib-textarea
                                    id="Description"
                                    class="block-highlight"
                                    [ngClass]="{ 'is-invalid': description.touched && description.invalid }"
                                    name="description"
                                    #description="ngModel"
                                    maxlength="1000"
                                    aria-label="Description"
                                    [resize]="false"
                                    [(ngModel)]="plugin.description"
                                >
                                </lib-textarea>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-10px">
                        <div class="col">
                            <div class="form-group d-none">
                                <div class="row mb-7px">
                                    <div class="col popup-form-control-title">
                                        Component Collection<span class="invalid-color"> *</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <lib-input
                                            id="Component Collection"
                                            class="popup-form-control-text"
                                            [ngClass]="{ 'is-invalid': componentCollection.touched && componentCollection.invalid }"
                                            name="componentCollection"
                                            type="text"
                                            #componentCollection="ngModel"
                                            aria-label="Component Collection"
                                            maxlength="50"
                                            [(ngModel)]="plugin.componentCollection"
                                        >
                                        </lib-input>
                                        <div class="invalid-color feedback-placeholder mt-10px">
                                            <div
                                                *ngIf="
                                                    componentCollection.touched &&
                                                    componentCollection.invalid &&
                                                    componentCollection.errors.required
                                                "
                                            >
                                                Component collection is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group d-none">
                                <div class="row mb-7px">
                                    <div class="col popup-form-control-title">Sub Collection</div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <lib-input
                                            id="Sub Collection"
                                            class="popup-form-control-text"
                                            [ngClass]="{ 'is-invalid': subCollection.touched && subCollection.invalid }"
                                            type="text"
                                            name="subCollection"
                                            #subCollection="ngModel"
                                            maxlength="50"
                                            aria-label="Sub Collection"
                                            [(ngModel)]="plugin.subCollection"
                                        >
                                        </lib-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="!editForm && plugin.pluginCategories.length > 1" class="multi-tool-warning mb-15px row no-gutters">
                <div class="multi-tool-warning-title body5">Multi-Tool:</div>
                <div class="multi-tool-warning-content body4">
                    This integration is a multi-tool configuration and will create tools with the same settings and credentials in all
                    categories: {{ '\n' + getPluginCategoriesAsString() }}
                </div>
            </div>
        </div>

        <div class="modal-footer">
            <button type="button" class="btn btn-secondary mr-25px" (click)="activeModal.close('Cross click')">
                Cancel
            </button>
            <button *ngIf="editForm" type="button" class="btn btn-primary mr-25px" (click)="validateAndSendForm()">
                Save
            </button>
            <button
                type="submit"
                class="btn btn-primary mr-15px"
                [disabled]="!form.form.valid || !authExtensionForm.valid || !credentialsVerified"
            >
                {{ buttonText }}
            </button>
        </div>
    </form>
</div>
