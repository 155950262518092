import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { CARD_STATUS_COLORS, CardStatus, CardStatusValue } from '@dagility-ui/kit';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

interface WorkDistributionSerie {
    name: string;
    completedCount: number;
    count: number;
    tooltipDate: string[];
    issues: { name: string; count: number }[];
}

interface WorkDistributionTooltip {
    workDistributionType: string;
    remainingIssues: number;
    remainingStoryPoints: number;
    series: WorkDistributionSerie[];
}

interface TooltipStatus {
    count: number;
    value: string;
}

interface Tooltip {
    createdDate: Date | number;
    resolvedDate: Date | number;
    completedDate: Date | number;
    remainingDays: number;
    resolvedIssues: number;
    totalIssues: number;
    estimatedStoryPoints: number;
    completedStoryPoints: number;
    statuses: TooltipStatus[];
}

interface User {
    firstName: string;
    lastName: string;
    imagePath?: string;
    imageId?: string;
}

export interface Issue {
    id: string;
    link: string;
    name: string;
    user: User;
    startDate: any;
    endDate: Date | number;
    progress: number;
    remainingDays: number;
    totalDays: number;
    status: CardStatus;
    tooltip: Tooltip;
    workDistributionTooltip: WorkDistributionTooltip;
}

@Component({
    selector: 'dp-feature-completion-status-issue',
    templateUrl: './feature-completion-status-issue.component.html',
    styleUrls: ['./feature-completion-status-issue.component.scss'],
    host: {
        class: 'feature-completion-status-issue',
    },
})
export class FeatureCompletionStatusIssueComponent implements OnInit, OnDestroy {
    @Input() issue: Issue;
    @Input() statusMapper: Record<string, CardStatusValue>;
    @Input() isSingleWidget: boolean = false;
    @Input() showLink = true;

    @Output() linkClicked = new EventEmitter();
    @Output() handleDrilldown = new EventEmitter();

    tooltip = false;
    workDistributionTooltip = false;
    @ViewChild('completionStatusPopover', { read: NgbPopover }) completionStatusPopover: NgbPopover;
    @ViewChildren(NgbPopover) popovers: QueryList<NgbPopover>;

    private destroy$ = new Subject<void>();

    constructor(private perfectScrollbar: PerfectScrollbarComponent) {}

    get fullName() {
        return `${this.issue.user.firstName} ${this.issue.user.lastName}`;
    }

    get abs() {
        return Math.abs;
    }

    ngOnInit() {
        this.perfectScrollbar.psScrollY.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.closeAllTooltips();
        });
    }

    getColor(value: string) {
        return CARD_STATUS_COLORS[this.statusMapper[value.toLowerCase()]];
    }

    handleCompletionStatusClick() {
        if (this.isSingleWidget) {
            this.handleDrilldown.emit({ payload: this.issue, target: 'completion-status' });
        }
    }

    checkLinkWithNull(link: string) {
        return !link || link.includes('null');
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    private closeAllTooltips() {
        this.popovers.forEach(it => it.close());
    }
}
