export const defaultEnvironment = {
    production: false,
    helpSupportParams: {
        url: 'https://www.dagility.com',
        version: 3,
    },
};

export const getDefaultRuntimeEnv = (envName: string) => {
    return {
        keycloakParams: {
            enable: true,
            url: 'https://keycloak.ustpace.com/auth',
            realm: 'Edgeops-Dev',
            clientId: 'Edgeops-ui',
            'ssl-required': 'external',
            'public-client': true,
        },
        env: {
            product: 'PACE',
            customer: null as string,
            name: envName,
        },
        darwin: {
            enabled: false,
        },
        onMessageOrigin: '*',
        argoCdUrl: '/argocd/',
    };
};

export function buildApiUrls(apiUrl: string): GeneratedEnv {
    return {
        adminURL: `${apiUrl}/admin`,
        adminApiURL: `${apiUrl}/admin/api`,
        updateApiURL: `${apiUrl}/update/api`,
        habitatApiURL: `${apiUrl}/habitat/api`,
        stompApiUrl: changeSchemeToWs(`${apiUrl}/admin/stomp`),
        buildApiURL: `${apiUrl}/pipeline/api`,
        insightApiURL: `${apiUrl}/insight/api`,
        monitorApiURL: `${apiUrl}/monitoring/api`,
        webConsoleApiUrl: `${apiUrl}/keepni/api`,
        notificationApiURL: `${apiUrl}/notification/api`,
        notificationsApiURL: `${apiUrl}/notification/api`,
        dpApiURL: `${apiUrl}/dp/api`,
        querybuilderApiURL: `${apiUrl}/querybuilder/api`,
    };
}

function changeSchemeToWs(url: string) {
    url = url.trim();
    let n = url.indexOf('://');
    let scheme = url.substring(0, n).toLowerCase();
    if (scheme === 'http') {
        return 'ws' + url.substring(n);
    } else if (scheme === 'https') {
        return 'wss' + url.substring(n);
    } else {
        console.warn('Unsupported scheme ' + url);
        return url;
    }
}
