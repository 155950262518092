<div class="compare-metrics-wrapper" [formGroup]="form">
    <div class="col-6 pl-0">
        <div class="row no-gutters">
            <span class="label-text">Select the source to be compared with other application</span>
        </div>

        <div class="row justify-content-between no-gutters mt-4">
            <lib-dropdown
                [minWidth]="210"
                [maxWidth]="380"
                formControlName="metric"
                class="col lib-dropdown mr-4 mt-1"
                valueKey="id"
                labelKey="name"
                [placeholder]="'Select Metric'"
                label="Select Metric"
                [labelPosition]="'top'"
                [items]="placeholders?.metrics || []"
            ></lib-dropdown>
            <lib-dropdown
                [minWidth]="210"
                [maxWidth]="380"
                formControlName="application"
                class="col lib-dropdown mt-1"
                valueKey="id"
                labelKey="name"
                [placeholder]="'Select Application'"
                label="Application"
                [labelPosition]="'top'"
                [items]="placeholders?.applications || []"
            ></lib-dropdown>
        </div>

        <div class="row justify-content-between no-gutters mt-4">
            <div class="col col-width mr-4 mt-1">
                <div class="row no-gutters cursor-pointer">
                    <lib-radio-button
                        class="size-s"
                        [checked]="groupedByScore"
                        [label]="'Show Applications grouped by score'"
                        (click)="onCheckboxChanged('groupedByScore')"
                    ></lib-radio-button>
                </div>
                <div class="row no-gutters mt-2">
                    <div class="secondary-text-and-icons">When a score range is selected, the applications will be grouped on the
selected KPI’s Scores
                    </div>
                </div>
            </div>

            <div class="col col-width mt-1">
                <div class="row no-gutters cursor-pointer">
                    <lib-radio-button
                        class="size-s"
                        [checked]="autoClusters"
                        [label]="'Auto clusters'"
                        (click)="onCheckboxChanged('autoClusters')"
                    ></lib-radio-button>
                </div>
                <div class="row no-gutters mt-2">
                    <div class="secondary-text-and-icons">The PACE algorithm, will create intelligent groupings against the source
to compare
                    </div>
                </div>
            </div>
        </div>

        <div class="row no-gutters mt-4">
            <ng-container *ngIf="activeMod === 'groupedByScore'">
                <app-interval-chooser [value]="groupingInterval" (intervalChanged)="onIntervalChange($event)"></app-interval-chooser>
            </ng-container>
            <ng-container *ngIf="activeMod === 'autoClusters' && hexagonData.values?.length">
                <div class="d-flex" [style.width.px]="820" [style.height.px]="416">
                    <dp-sre-hexagons
                        class="w-100"
                        [hexagons]="hexagonData.values"
                        externalScoring="true"
                        [externalBounds]="hexagonData.bounds"
                        [invertColors]="hexagonData.invertColors"
                        (hexagonClicked)="handleHexagonEvent($event)"
                    ></dp-sre-hexagons>
                </div>
            </ng-container>
        </div>

    </div>

    <div class="col-6 pr-0">
        <div class="row no-gutters">
            <span><b>Found:</b>{{ applicationsCount }} Applications</span>
        </div>
        <lib-ag-grid
            class="d-block mt-2"
            gridHeight="634px"
            [tableData]="chartModel?.options?.tableData"
            (cellClicked)="handleCellClicked($event)"
        ></lib-ag-grid>
    </div>
</div>
