<div
    data-test-id="organization-button"
    ngbDropdown
    #ref="ngbDropdown"
    *ngIf="isMultiSourceAvailable && organizationsFromApi$ | async"
    container="body"
    display="dynamic"
    [placement]="'bottom-left'"
    [autoClose]="'autoclose'"
>
    <button class="btn btn-link px-2" ngbDropdownToggle [class.pseudoactive]="ref.isOpen()">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M7 3H3V7H7V3Z" fill="currentColor" />
            <path d="M14 3H10V7H14V3Z" fill="currentColor" />
            <path d="M21 3H17V7H21V3Z" fill="currentColor" />
            <path d="M14 10H10V14H14V10Z" fill="currentColor" />
            <path d="M21 10H17V14H21V10Z" fill="currentColor" />
            <path d="M14 17H10V21H14V17Z" fill="currentColor" />
            <path d="M21 17H17V21H21V17Z" fill="currentColor" />
            <path d="M7 10H3V14H7V10Z" fill="currentColor" />
            <path d="M7 17H3V21H7V17Z" fill="currentColor" />
        </svg>
    </button>
    <div class="mt-2" ngbDropdownMenu>
        <div class="organizations-panel-wrapper gap-2 body1 py-2">
            <div data-test-id="user-info" class="d-flex align-items-center dropdown-item gap-2 user-info py-2">
                <lib-user-pic [user]="user" [size]="32"></lib-user-pic>
                <div class="min-width-0">
                    <h3 class="mt-0">My Organizations</h3>
                    <div class="text-truncate secondary-text-and-icons">{{ user.firstName }} {{ user.lastName }}</div>
                </div>
            </div>
            <div class="divider border-bottom"></div>
            <div>
                <div
                    *ngFor="let organization of sortedOrganizations$ | async"
                    class="dropdown-item py-2 cursor-pointer d-flex align-items-center gap-2 primary-text"
                    [class.active-organization]="organization.current"
                    data-test-id="organization-item"
                    (click)="ref.close(); updateActiveOrganization(organization)"
                >
                    <lib-user-pic [user]="organization" [size]="32"></lib-user-pic>
                    <div data-test-id="organization-name" class="text-truncate">{{ organization.name }}</div>
                    <fa-icon
                        data-test-id="organization-status"
                        *ngIf="organization.current"
                        class="ml-auto font-16px"
                        icon="simpleCheck"
                    ></fa-icon>
                </div>
            </div>
            <ng-container *ngIf="isCreateEnabled">
                <ng-container *isAdmin>
                    <div class="divider border-bottom"></div>
                    <div data-test-id="add-new-organization" class="dropdown-item cursor-pointer" (click)="createNewOrganization(ref)">
                        <fa-icon class="mr-2 color-brand" icon="plus"></fa-icon>
                        New Organization
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
