<ng-container *ngIf="attachedFilters.length > 0">
    <div class="model">
        <div class="model-header">
            <img [src]="iconUrl" alt=""/>
            <span>{{ name }}</span>
        </div>
        <div class="container">
            <div class="item">
                <div class="fields">
                    <ng-container *ngFor="let filter of attachedFilters">
                        <div class="field">
                            <div class="data">
                                <span>{{ filter.name }}</span>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-container>
