import { Component, Output, EventEmitter } from '@angular/core';
import { ControlContainer, FormArray, FormBuilder, FormGroupDirective } from '@angular/forms';

import { ToolAuthenticationType } from '@dagility-ui/shared-components';
import { DropdownItem } from '@dagility-ui/kit';

import { EditToolTemplateFacade } from '../edit-tool-template.facade';

@Component({
    selector: 'dp-edit-tool-template-auth-type',
    templateUrl: './edit-tool-template-auth-type.component.html',
    styleUrls: ['./edit-tool-template-auth-type.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class EditToolTemplateAuthTypeComponent {
    @Output() authTypeAdded = new EventEmitter<void>();

    get formGroup() {
        return this.parent.control;
    }

    get authTypes() {
        return this.formGroup.get('authTypes') as FormArray;
    }

    authTypesItems: DropdownItem<ToolAuthenticationType>[] = [
        {
            value: 'BASIC',
            label: 'BASIC',
        },
        {
            value: 'FORM',
            label: 'FORM',
        },
        {
            value: 'TOKEN',
            label: 'TOKEN',
        },
        {
            value: 'JDBC',
            label: 'JDBC',
        },
        {
            value: 'SCRIPT',
            label: 'SCRIPT',
        },
    ];
    constructor(private fb: FormBuilder, private parent: FormGroupDirective, public facade: EditToolTemplateFacade) {}

    addAuthType() {
        this.authTypes.push(this.facade.buildAuthTypeGroup({}));

        this.authTypeAdded.emit();
    }

    handleChangeAuthType(index: number, item: DropdownItem<ToolAuthenticationType> | null) {
        if (!this.authTypes.controls[index]) {
            return;
        }

        const value = this.authTypes.at(index).value;
        this.authTypes.removeAt(index);
        this.authTypes.insert(index, this.facade.buildAuthTypeGroup(item ? value : ({} as any)));
    }

    handleDeleteAuthType(index: number) {
        this.authTypes.removeAt(index);
    }
}
