import { DEFAULT_PLACEHOLDERS, DefaultPlaceholders } from 'data-processor';
import { inject } from '@angular/core';
import { map, skip, startWith, switchMap, take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

import { DateAdapter } from '@dagility-ui/shared-components';

export function addTimeZonePlaceholderFactory(
    externalDefaultPlaceholders?: DefaultPlaceholders,
    externalDateAdapter?: DateAdapter
): DefaultPlaceholders {
    const defaultPlaceholders: DefaultPlaceholders =
        externalDefaultPlaceholders ??
        inject(DEFAULT_PLACEHOLDERS, {
            skipSelf: true,
        });
    const dateAdapter = externalDateAdapter ?? inject(DateAdapter, { optional: true });

    if (!dateAdapter) {
        return defaultPlaceholders;
    } else {
        return widget => {
            const defaultPlaceholders$ = defaultPlaceholders(widget);

            return defaultPlaceholders$.pipe(
                take(1),
                switchMap(placeholders =>
                    combineLatest([defaultPlaceholders$, dateAdapter.timezoneChanges$.pipe(startWith(null as unknown))]).pipe(
                        skip(1),
                        startWith([placeholders])
                    )
                ),
                map(([placeholders]) => ({
                    ...placeholders,
                    ...dateAdapter.getPlaceholders(),
                }))
            );
        };
    }
}
