<perfect-scrollbar class="mt-3 scrollbar">
    <ng-container *ngIf="!collapsed">
        <div
            class="action-box text-truncate"
            *ngFor="let action of actions"
            [class.action-box--disabled]="dragDisabled"
            [libDrag]="action"
            [libDragDisabled]="dragDisabled"
            (dropped)="onDrop($event)"
        >
            {{ action.name }}
        </div>
    </ng-container>
</perfect-scrollbar>
