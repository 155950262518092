import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { orderBy } from 'lodash';
import { SelectionModel } from '@angular/cdk/collections';

import { SortOrder, Store } from '@dagility-ui/kit';
import { ToolGroup, ToolModel } from '@dagility-ui/shared-components';
import { DataProcessorState } from '../models/processor-monitoring/data-processor-state.model';
import { ProcessorMonitoringService } from './processor-monitoring.service';
import { Plugin, Tool } from '../models/processor-monitoring/plugin.model';
import { JobSet } from '../models/processor-monitoring/job-set.model';
import { DataProcessorStateParams } from './dp-state.listener';
import { normalizeJobSets, getToolJobSets, normalizeJobDefinitionsState } from './state.normalizer';

export interface PMState {
    plugins: Plugin[];
    tools: Tool[];
    filter: string;
    filteredTools: Tool[];
}

const DEFAULT_STATE: Readonly<PMState> = {
    plugins: [],
    tools: [],
    filter: '',
    filteredTools: [],
};

@Injectable()
export class ProcessorMonitoringState extends Store<PMState> {
    filter$ = this.select(state => state.filter);
    tools$ = this.select(state => state.tools);
    filteredTools$ = this.select(state => state.filteredTools).pipe(map(tools => [...tools].sort(PluginUtils.sortTools)));
    plugins$ = this.select(state => state.plugins);

    state$: Observable<PMState> = combineLatest([this.filter$, this.tools$, this.filteredTools$, this.plugins$]).pipe(
        map(([filter, tools, filteredTools, plugins]) => ({
            filter,
            tools,
            filteredTools,
            plugins,
        })),
    );

    selectionModel = new SelectionModel<any>(true);

    public isServiceAvailable = true;

    stateParams: DataProcessorStateParams = {
        full: true,
    };

    jobSetsMap: Record<string, JobSet>;

    lastFullJDMap: { [toolIdAndInstanceName: string]: boolean } = {};
    lastUpdateTime: number;

    constructor(private api: ProcessorMonitoringService) {
        super(DEFAULT_STATE);
    }

    init({ jobDefinitions, updated }: DataProcessorState, toolGroups: ToolGroup[], jobSets: JobSet[]) {
        this.lastUpdateTime = updated;

        this.stateParams.full = false;

        const jdWithRunningStatus = this.addRunningStatusBinaryField(jobDefinitions || []);

        this.jobSetsMap = normalizeJobSets(jobSets);

        const flatTools = toolGroups.reduce((acc, v: any) => [...acc, ...v.plugins], []);
        const pluginsMap = this.reduceJobDefinitions(jdWithRunningStatus);
        this.lastFullJDMap = this.reduceToFullMap(jdWithRunningStatus);

        const tools = flatTools.map((tool: any) => ({
            jobSetId: tool.jobSetId,
            name: tool.name,
            toolId: tool.toolId,
            plugins: pluginsMap[tool.toolId] || [],
            jobSet: this.jobSetsMap[tool.jobSetId],
            pluginType: tool.pluginType,
            id: tool.id,
        }));

        const filteredTools = PluginUtils.filterTools(tools, this.state.filter);

        this.setState({ tools, filteredTools, plugins: jobDefinitions });
    }

    async update(prevFull: boolean, { jobDefinitions = [], updated }: DataProcessorState, currentTools: ToolModel[]) {
        const reset = prevFull;

        if (!prevFull && updated !== this.lastUpdateTime) {
            this.needFullUpdate();

            return;
        }

        if (prevFull) {
            this.stateParams.full = false;
            this.lastUpdateTime = updated;
        }

        const { plugins, tools, filter } = this.getValue();
        let updatedPluginList: Plugin[] = [];
        const pluginsFromStomp = this.addRunningStatusBinaryField(jobDefinitions);
        updatedPluginList = [...pluginsFromStomp];

        const jobDefinitionsMap: Record<string, any[]> = {};

        const addToToolPluginsMap = (dict: Record<string, Plugin[]>, plugin: Plugin) => {
            if (dict[plugin.toolId]) {
                dict[plugin.toolId].push(plugin);
            } else {
                dict[plugin.toolId] = [plugin];
            }
        };

        if (!reset) {
            const jobDefinitionsFromStompMap: Record<string, Plugin> = normalizeJobDefinitionsState(jobDefinitions);
            const currentJobDefinitionsMap: Record<string, Plugin> = normalizeJobDefinitionsState(plugins);

            const ids = new Set(Object.keys(jobDefinitionsFromStompMap).concat(Object.keys(currentJobDefinitionsMap)));

            for (const id of ids) {
                let jobDefinition = jobDefinitionsFromStompMap[id];

                if (!this.lastFullJDMap[id]) {
                    continue;
                }

                if (!jobDefinition) {
                    jobDefinition = currentJobDefinitionsMap[id];
                    jobDefinition.runningStatus = 'INACTIVE';
                    jobDefinition.runningStatusBinary = 'INACTIVE';

                    updatedPluginList.push(jobDefinition);
                }

                addToToolPluginsMap(jobDefinitionsMap, jobDefinition);
            }
        } else {
            this.lastFullJDMap = {};

            for (const plugin of updatedPluginList) {
                addToToolPluginsMap(jobDefinitionsMap, plugin);

                this.lastFullJDMap[`${plugin.toolId}|${plugin.instanceName}`] = true;
            }

            const toolJobSetMap = getToolJobSets(currentTools);

            for (const tool of tools) {
                const newJobSetId = toolJobSetMap[tool.toolId];

                if (tool.jobSetId === newJobSetId) {
                    continue;
                }

                const jobSet = this.jobSetsMap[newJobSetId];
                if (!jobSet) {
                    try {
                        const jobSets = await this.api.getJobSets().toPromise();
                        this.jobSetsMap = normalizeJobSets(jobSets);
                    } catch (e) {
                    }
                }

                tool.jobSetId = newJobSetId || null;
                tool.jobSet = this.jobSetsMap[newJobSetId] || null;
            }
        }

        const updatedTools = tools.map(tool => ({
            ...tool,
            plugins: jobDefinitionsMap[tool.toolId] || [],
        }));

        const filteredTools = PluginUtils.filterTools(updatedTools, filter);

        this.setState({ tools: updatedTools, filteredTools, plugins: updatedPluginList });
    }

    public forceRun(jobName: string, params: any, toolId: string) {
        this.api.forceRun(jobName, params, toolId).subscribe();
    }

    updateToolJobSet(id: number, jobSet?: JobSet) {
        const { tools, filter } = this.getValue();
        const updatedTools = tools.map(tool =>
            tool.id === id
                ? {
                    ...tool,
                    jobSetId: !!jobSet ? jobSet.id : null,
                    jobSet: jobSet || null,
                }
                : tool,
        );

        this.setState({ tools: updatedTools, filteredTools: PluginUtils.filterTools(updatedTools, filter) });
    }

    deleteJobSet(id: number) {
        const { tools, filter } = this.getValue();
        const updatedTools = tools.map(tool =>
            tool.jobSetId === id
                ? {
                    ...tool,
                    jobSetId: null,
                    jobSet: null,
                }
                : tool,
        );

        this.setState({ tools: updatedTools, filteredTools: PluginUtils.filterTools(updatedTools, filter) });
    }

    reduceJobDefinitions(jobDefinitions: any) {
        return jobDefinitions.reduce((acc: any, v: any) => {
            if (acc[v.toolId]) {
                acc[v.toolId] = [...acc[v.toolId], v];
            } else {
                acc[v.toolId] = [v];
            }
            return acc;
        }, {});
    }

    reduceToFullMap(plugins: Plugin[]) {
        return plugins.reduce<Record<string, boolean>>((acc, plugin) => {
            acc[`${plugin.toolId}|${plugin.instanceName}`] = true;

            return acc;
        }, {});
    }

    addRunningStatus(plugin: Plugin) {
        plugin.runningStatusBinary = plugin.runningStatus === 'INACTIVE' ? 'INACTIVE' : 'ACTIVE';
    }

    addRunningStatusBinaryField(plugins: Plugin[]) {
        for (const plugin of plugins) {
            this.addRunningStatus(plugin);
        }

        return plugins;
    }

    filter(filter: string) {
        const { tools } = this.getValue();
        const filteredTools = PluginUtils.filterTools(tools, filter);

        this.setState({ filter, filteredTools });
    }

    needFullUpdate() {
        this.stateParams.full = true;
    }
}

// @dynamic
export class PluginUtils {
    static sortPlugins(plugins: Plugin[], orders: SortOrder[]) {
        return orderBy(
            plugins,
            orders.map(o => o.property),
            orders.map(o => o.direction.toLowerCase()) as any,
        );
    }

    static sortTools(a: Tool, b: Tool) {
        return a.name.localeCompare(b.name);
    }

    static filterTools(tools: Tool[], pattern: string) {
        const searchText = pattern.toLowerCase();

        if (!!searchText) {
            const filteredTools = [];

            for (const tool of tools) {
                const plugins = (tool.plugins || []).filter(plugin => plugin.instanceName.toLowerCase().includes(searchText));

                if (plugins.length) {
                    filteredTools.push({
                        ...tool,
                        plugins,
                    });
                }
            }
            return filteredTools;
        } else {
            return tools;
        }
    }
}
