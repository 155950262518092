import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-iframe-wrapper',
    templateUrl: './iframe-wrapper.component.html',
    styleUrls: ['./iframe-wrapper.component.scss']
})
export class IframeWrapperComponent {
    @Input() url: string;

    loaded = false;

    enableFullscreen(element: any) {
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullScreen) {
            element.webkitRequestFullScreen((<any>Element).ALLOW_KEYBOARD_INPUT);
        }
    }
}
