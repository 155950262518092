import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { JobDefinition } from './edit-job-definition-form/models/job-definition';
import { DebugContainer } from './edit-job-definition-form/models/debug-container';
import { JobSet } from '../models/processor-monitoring/job-set.model';
import { DataProcessorState } from '../models/processor-monitoring/data-processor-state.model';
import { EvaluateModel } from './edit-job-definition-form/state/job-definition-debug.state';
import { ToolType } from '@dagility-ui/shared-components';
import { EnvironmentModel } from '@dagility-ui/kit';

@Injectable()
export class ProcessorMonitoringService {
    private readonly baseUrl = `${this.environment.dpApiURL}/data-processor`;

    constructor(private http: HttpClient, @Inject('environment') private environment: EnvironmentModel) {}

    getJobDefinitionsLogs(instanceName: string, toolId: string, from?: number, to?: number, type?: string, search?: string) {
        let params = new HttpParams().append('instanceName', instanceName);
        params = params.append('toolId', toolId);

        if (from) {
            params = params.append('from', from.toString());
        } else if (to) {
            params = params.append('to', to.toString());
        }
        if (type) {
            params = params.append('type', type);
        }
        if (search) {
            params = params.append('search', search);
        }
        return this.http.get(`${this.baseUrl}/logs`, { params });
    }

    getJobDefinitionState() {
        return this.http.get<DataProcessorState>(`${this.baseUrl}/state`);
    }

    startJobDefinition(id: number) {
        return this.http.get<void>(`${this.baseUrl}/start-job/${id}`);
    }

    stopJobDefinition(id: number) {
        return this.http.get<void>(`${this.baseUrl}/stop-job/${id}`);
    }

    killJobDefinition(id: number) {
        return this.http.get<void>(`${this.baseUrl}/kill-job/${id}`);
    }

    public forceRun(jobName: string, params: any, toolId: string) {
        const configURL = this.baseUrl + '/force-job';
        return this.http.post(configURL, { jobName, toolId, params });
    }

    startDebug(key: string, body: { left: JobDefinition; middle: string[]; right: Record<string, any> }, publish = false) {
        return this.http.post<DebugContainer>(`${this.baseUrl}/start-debug/${key}?publish=${publish}`, body);
    }

    resumeDebug(key: string, breakpoints: string[]) {
        return this.http.post<DebugContainer>(`${this.baseUrl}/resume-debug/${key}`, breakpoints);
    }

    cancelDebug(key: string) {
        return this.http.post<DebugContainer>(`${this.baseUrl}/cancel-debug/${key}`, {});
    }

    getJobSets(toolType?: ToolType) {
        let params = new HttpParams();
        if (!!toolType) {
            params = params.append('toolType', toolType);
        }

        return this.http.get<JobSet[]>(`${this.baseUrl}/job-sets`, { params });
    }

    importJobSet(name: string, file: File) {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('file', file);

        return this.http.post<JobSet>(`${this.baseUrl}/job-sets/import`, formData);
    }

    exportJobSet(id: number) {
        return this.http.get<JobSet>(`${this.baseUrl}/job-sets/${id}`);
    }

    createJobSet(name: string, toolType: ToolType) {
        const params = new HttpParams().append('name', name).append('toolType', toolType);

        return this.http.put<JobSet>(`${this.baseUrl}/job-sets`, params);
    }

    changeActiveJobSet(id: number, toolId: number) {
        const params = new HttpParams().append('toolId', toolId.toString());

        return this.http.post(`${this.baseUrl}/job-sets/${id}/assign`, params);
    }

    deleteJobSet(id: number) {
        return this.http.delete<void>(`${this.baseUrl}/job-sets/${id}`);
    }

    createFromExisting(id: number, name: string) {
        const params = new HttpParams().append('name', name);

        return this.http.post<JobSet>(`${this.baseUrl}/job-sets/${id}/copy`, params);
    }

    getJobDefinition(id: string) {
        return this.http.get<any>(`${this.baseUrl}/job-definitions/${id}`);
    }

    updateJobDefinition(data: any) {
        return this.http.post<any>(`${this.baseUrl}/job-definitions`, data);
    }

    createJobDefinition(data: any) {
        return this.http.put<any>(`${this.baseUrl}/job-definitions`, data);
    }

    deleteJobDefinition(id: number) {
        return this.http.delete<void>(`${this.baseUrl}/job-definitions/${id}`);
    }

    evaluateScript({ debugId, script }: EvaluateModel) {
        return this.http.post<Record<string, any>>(`${this.baseUrl}/evaluate/${debugId}`, {
            script,
        });
    }

    executeQuery(dataType: string, toolId: string, query: string, params: Record<string, any>) {
        let httpParams = new HttpParams({
            fromObject: {
                dataType,
            },
        });

        if (toolId) {
            httpParams = httpParams.append('toolId', toolId);
        }

        return this.http.post<any[]>(
            `${this.baseUrl}/sql`,
            { query, params },
            {
                params: httpParams,
            }
        );
    }
}
