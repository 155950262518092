import { ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective, Validators } from '@angular/forms';
import { noop } from 'rxjs';

import { ModalService } from '@dagility-ui/kit';

import { WidgetBuilderHelpTemplateComponent } from '../widget-builder-help-template/widget-builder-help-template.component';

@Component({
    selector: 'dp-widget-builder-help-form-block',
    templateUrl: './widget-builder-help-form-block.component.html',
    styleUrls: ['./widget-builder-help-form-block.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'px-4 py-3 border-bottom d-block',
    },
})
export class WidgetBuilderHelpFormBlockComponent implements OnInit {
    @Input() sectionControlName: string;

    @Input() label: string;

    @Output() modalClosed = new EventEmitter<boolean>();

    get form() {
        return this.parent.control.get(this.sectionControlName) as FormControl;
    }

    mandatory = false;

    constructor(
        private parent: FormGroupDirective,
        private modal: ModalService,
        private injector: Injector,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.mandatory = this.form.hasValidator(Validators.required);
    }

    handleOpenEditModal() {
        this.modal
            .open(
                WidgetBuilderHelpTemplateComponent,
                { centered: true, backdrop: 'static', size: 'lg', windowClass: 'help-template', injector: this.injector },
                {
                    header: this.label,
                    data: { type: this.sectionControlName, value: this.form.value ?? '', mandatory: this.mandatory },
                }
            )
            .result.then(
                result => {
                    this.form.patchValue(result);
                    this.cdr.detectChanges();
                    this.modalClosed.emit(true);
                },
                () => {
                    this.modalClosed.emit();
                }
            )
            .catch(noop);
    }
}
