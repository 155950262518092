import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmModal} from '@dagility-ui/kit';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
    selector: 'app-commit-message-modal',
    templateUrl: './commit-message-modal.component.html',
    styleUrls: ['./commit-message-modal.component.scss']
})
export class CommitMessageModalComponent implements OnInit {
    @Output() confirmOk: EventEmitter<string> = new EventEmitter();
    @Output() cancelBtnClicked: EventEmitter<any> = new EventEmitter();

    message: ConfirmModal;

    form: FormGroup;

    confirmButtonText = 'Publish';
    closeButtonText = 'Cancel';

    showConfirmButton = true;
    showCancelButton = true;

    constructor(public modal: NgbActiveModal) {}

    ngOnInit() {
        this.form = new FormGroup({ commit: new FormControl(this.message.description) });
    }

    confirm() {
        this.confirmOk.emit(this.form.get('commit').value.replace(/\n/g, ' '));
        this.modal.close();
    }

    cancel() {
        this.cancelBtnClicked.emit();
        this.modal.dismiss('cancel click');
    }

    close() {
        this.cancelBtnClicked.emit();
        this.modal.dismiss('Cross click')
    }
}

