import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    Output,
    ViewChild,
} from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { identity } from 'lodash';

import { SandBox } from '../../../services/widget-builder.util';

import { TwoDimensionalGridModel } from '../../../services/query-to-options.mapper';

const HOVER_CLASS = 'hovered';

@Component({
    selector: 'dp-two-dimensional-grid',
    templateUrl: './two-dimensional-grid.component.html',
    styleUrls: ['./two-dimensional-grid.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwoDimensionalGridComponent implements AfterViewInit, OnDestroy {
    @Input() set tableData(value: TwoDimensionalGridModel) {
        this._tableData = value;

        if (this._tableData.cellTemplate) {
            const sandbox = new SandBox();
            this.cellRenderer =  sandbox.buildFn(`return function(params) { ${this._tableData.cellTemplate}}`);
        } else {
            this.cellRenderer = identity;
        }
    }

    get tableData() {
        return this._tableData;
    }

    @Output() cellClick = new EventEmitter();

    @ViewChild('table', { static: true, read: ElementRef }) table: ElementRef<HTMLTableElement>;

    private _tableData: TwoDimensionalGridModel;

    cellRenderer: Function;

    private destroyed$ = new Subject<void>();

    private hovered: Array<HTMLElement> = [];

    constructor(private zone: NgZone) {}

    ngAfterViewInit(): void {
        this.zone.runOutsideAngular(() => {
            this.addMouseOverListener();
            this.addMouseOutListener();
        });
    }

    handleClick(data: any, $event: MouseEvent): void {
        if (!data) {
            return;
        }

        this.cellClick.emit({
            type: 'cellClicked',
            data,
        });
    }

    addMouseOverListener(): void {
        fromEvent(this.table.nativeElement, 'mouseenter', { capture: true })
            .pipe(takeUntil(this.destroyed$))
            .subscribe((e: any) => {
                if (e.target.tagName.toLowerCase() === 'td') {
                    const index = e.target.cellIndex;
                    const rowIndex = e.target.parentElement.rowIndex;
                    if (this.hovered) {
                        this.hovered.forEach(cell => {
                            cell.classList.remove(HOVER_CLASS);
                        });
                    }

                    this.hovered = Array.from(this.table.nativeElement.rows).map(row => {
                        let i = index;
                        let cell = null;
                        while (!cell && i >= 0) {
                            cell = row.cells[i];
                            i -= 1;
                        }
                        return cell;
                    });

                    this.hovered.push(this.table.nativeElement.tHead.rows[0].cells[index]);

                    Array.from(this.table.nativeElement.rows[rowIndex].cells).forEach(cell => {
                        this.hovered.push(cell);
                    });

                    this.hovered.forEach(cell => {
                        cell.classList.add(HOVER_CLASS);
                    });
                }
            });
    }

    addMouseOutListener(): void {
        fromEvent(this.table.nativeElement, 'mouseleave', { capture: true })
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                if (this.hovered) {
                    this.hovered.forEach(cell => {
                        cell.classList.remove(HOVER_CLASS);
                    });

                    this.hovered = null;
                }
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }
}
