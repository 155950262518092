import { interval, Observable, Subscription } from 'rxjs';
import { GridsterItemPrototypeDirective, GridsterPrototypeService } from 'angular2gridster';
import { debounce } from 'lodash';
import { NgZone } from '@angular/core';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import { GridsterEvent } from '../../services/gridster/gridster-resize.util';

export class GridsterAutoScroll {
    private static SPEED = 10;
    private static BOUNDARY_GAP = 54;

    private readonly drag$: Observable<{ item: GridsterItemPrototypeDirective; event: any }>;
    private readonly dragStopped$: Observable<any>;
    private readonly perfectScrollbar: HTMLElement;
    private subscription: Subscription = Subscription.EMPTY;
    private updateGridsterElementData = debounce(() => {
        this.zone.run(() => {
            this.event.gridster.gridsterComponent.updateGridsterElementData();
        });
    });

    constructor(private readonly event: GridsterEvent, private zone: NgZone) {
        const prototypeService = this.event.item.itemPrototype['gridsterPrototype'] as GridsterPrototypeService;
        this.drag$ = prototypeService['dragSubject'].asObservable();
        this.dragStopped$ = prototypeService['dragStopSubject'].asObservable();
        this.perfectScrollbar = event.gridster.gridsterComponent.$element.closest('.ps');

        this.zone.runOutsideAngular(() => {
            this.listen();
        });
    }

    destroy() {
        this.subscription.unsubscribe();
    }

    private listen() {
        this.subscription = this.drag$
            .pipe(
                switchMap(e => interval(0).pipe(map(() => e))),
                takeUntil(this.dragStopped$)
            )
            .subscribe((e: { event: any }) => {
                const { event } = e;

                if (event.pageY - this.getOffset(this.perfectScrollbar) < GridsterAutoScroll.BOUNDARY_GAP) {
                    this.startAutoScrolling(this.perfectScrollbar, -GridsterAutoScroll.SPEED, 'scrollTop');
                } else if (
                    this.getOffset(this.perfectScrollbar) + this.perfectScrollbar.getBoundingClientRect().height - event.pageY <
                    GridsterAutoScroll.BOUNDARY_GAP
                ) {
                    this.startAutoScrolling(this.perfectScrollbar, GridsterAutoScroll.SPEED, 'scrollTop');
                }
            });
    }

    private startAutoScrolling(node: any, amount: number, direction: keyof HTMLElement) {
        node[direction] += amount * 0.25;

        this.updateGridsterElementData();
    }

    private getOffset(el: HTMLElement): number {
        const rect = el.getBoundingClientRect();

        return rect.top + this.getScroll('scrollTop', 'pageYOffset');
    }

    private getScroll(scrollProp: string, offsetProp: string) {
        if (typeof (window as any)[offsetProp] !== 'undefined') {
            return (window as any)[offsetProp];
        }
        if (document.documentElement.clientHeight) {
            return (document.documentElement as any)[scrollProp];
        }
        return (document.body as any)[scrollProp];
    }
}
