import { Component, Input } from '@angular/core';

@Component({
    selector: 'dp-health-score-template',
    templateUrl: './health-score-template.component.html',
    styleUrls: ['./health-score-template.component.scss'],
    host: {class: 'app-score-widget'}
})
export class HealthScoreTemplateComponent {
    @Input() options: any;

    get barItems(): number[] {
        return new Array(10).fill(null).map((item, i) => i);
    }

    getBarItemValue(i: number): number {
        return this.options.points - i > 1 ? 100 : Math.max(0, (this.options.points - i) * 100);
    }
}
