import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { UiKitModule } from '@dagility-ui/kit';
import { UserPicModule } from '@dagility-ui/shared-components';
import { SharedModule } from '@app/shared/shared.module';

import { AddNewOrganizationComponent } from '../add-new-organization/add-new-organization.component';
import { OrganizationsSwitcherComponent } from './organizations-switcher.component';

@NgModule({
    declarations: [OrganizationsSwitcherComponent, AddNewOrganizationComponent],
    exports: [OrganizationsSwitcherComponent],
    imports: [CommonModule, UiKitModule, UserPicModule, ReactiveFormsModule, SharedModule],
})
export class OrganizationsBentoMenuModule {}
