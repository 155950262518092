export function convertToBoolean(input: string): boolean | undefined {
    try {
        return JSON.parse(input);
    } catch (e) {
        return undefined;
    }
}

export function moveArrayElement(arr: any[], fromIndex: number, toIndex: number) {
    const movedElements = arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, movedElements[0]);
}
