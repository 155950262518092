import { Pageable, Store } from '@dagility-ui/kit';

export class TemplateListFilterStore extends Store<TemplateListFilter> {
    constructor() {
        super({
            text: '',
            pageable: Pageable.page(0, 10),
            installOnly: false,
            sortedBy: {
                fieldName: 'name',
                direction: SortDirection.Asc,
            },
        });
    }
}

export enum SortDirection {
    Asc = 'ASC',
    Desc = 'DESC'
}

export type TemplateListTab = 'favorites' | 'my-templates' | 'marketplace' | 'compare';

interface TemplateListFilter {
    installOnly: boolean;
    text: string;
    pageable: Pageable;
    sortedBy: SortedBy;
}

export interface SortedBy {
    fieldName: string;
    direction: SortDirection;
}
