<div class="card common-popup">
    <div class="card-header color-brand">
        Reorder Columns
    </div>
    <div class="card-body p-10px">
        <div class="d-flex pb-20px align-items-center">
            <div class="pl-10px primary-text">
                Hold the Column to drag
            </div>
        </div>
        <div *ngIf="!columns; then showmessage; else showlist"></div>
        <ng-template #showmessage>
            <span class="ml-40px"><span class="text-danger">No Columns are available</span> for reordering</span>
        </ng-template>
        <ng-template #showlist>
            <div class="">
                <perfect-scrollbar class="ml-10px display-list">
                    <ul class="drag-list pr-15px" cdkDropList [cdkDropListData]="columns" (cdkDropListDropped)="dropDashboard($event)">
                        <li *ngFor="let item of columns; index as i" [cdkDragData]="item" cdkDrag>
                            <div *cdkDragPlaceholder>
                                <div class="drag-details">
                                    <strong class="font-12px d-block ml-2">{{ item.title }}</strong>
                                </div>
                                <div class="drag-handle ml-auto">
                                    <fa-icon icon="bars" class="font-14px icon"></fa-icon>
                                </div>
                            </div>
                            <div class="drag-details">
                                <strong class="font-12px d-block ml-2">{{ item.title }}</strong>
                            </div>
                            <div class="drag-handle ml-auto">
                                <fa-icon icon="bars" class="font-14px icon"></fa-icon>
                            </div>
                        </li>
                    </ul>
                </perfect-scrollbar>
            </div>
        </ng-template>
        <div class="row">
            <div class="col pt-15px pb-15px pr-25px pl-25px">
                <button [disabled]="!columns" class="btn btn-primary  ml-20px pull-right" type="button" (click)="applyChanges()">
                    Apply
                </button>
                <button class="btn btn-secondary  compress-vertical-padding min-width-80px pull-right" type="button" (click)="dismiss()">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>
