import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modal-loading',
    templateUrl: './modal-loading.component.html',
    styleUrls: ['./modal-loading.component.scss'],
})
export class ModalLoadingComponent {
    height: number = 200;

    isLoading: boolean = true;
    isError: boolean = false;

    textConfig: ModalTextConfig = {
        loadingText: '',
        titleText: '',
        successText: '',
        errorText: '',
        errorType: '',
    };

    constructor(public activeModal: NgbActiveModal) {}
}

export interface ModalTextConfig {
    loadingText: string;
    titleText: string;
    successText?: string;
    errorText?: string;
    errorType?: string;
}
