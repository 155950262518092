import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-custom-dashboard-tooltip',
    template: `
        <h3 class="mt-0 tooltip-header primary-text">{{ dashboardItem.dashboardName }}</h3>
        <span class="body2 font-weight-normal">{{ dashboardItem.description }}</span>
    `,
})
export class CustomDashboardTooltipComponent {
    @Input() dashboardItem: any;
}
