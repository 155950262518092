import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { SafeHtmlPipe } from '@dagility-ui/kit';
import { WidgetScriptExecutor } from '../services/widget-script.executor';

export class WidgetTemplateInterpolationManager {
    constructor(public readonly placeholders: Record<string, any>) {}

    interpolate(template: string) {
        if (Object.keys(this.placeholders).length === 0 || !template) {
            return template;
        }

        return template.replace(/({([^}]+)})/g, i => {
            const key = i.replace(/{/, '').replace(/}/, '');

            if (this.placeholders[key] === undefined) {
                return i;
            }

            return this.placeholders[key];
        });
    }
}

function buildAdditionalParams(params: Record<string, any>) {
    if (!params || !Object.keys(params).length) {
        return ['', []];
    }

    const keys = Object.keys(params);
    const values = Object.values(params);

    return [`,${keys.join(',')}`, values];
}

@Pipe({ name: 'dpWidgetTemplate' })
export class WidgetTemplateInterpolationPipe implements PipeTransform {
    readonly safeHtml = new SafeHtmlPipe(this.sanitizer);

    constructor(private readonly sanitizer: DomSanitizer, private scriptExecutor: WidgetScriptExecutor) {}

    transform(value: string, placeholders: Record<string, any>, additionalParams?: Record<string, any>): SafeHtml {
        let html: string;
        const isScript = value.includes('return ');

        if (isScript) {
            const [keys, values] = buildAdditionalParams(additionalParams);

            html = this.scriptExecutor.buildFn(`return (function(placeholders${keys}){${value}})`)(placeholders, ...values);
        } else {
            html = new WidgetTemplateInterpolationManager(placeholders).interpolate(value);
        }

        return this.safeHtml.transform(html, 'html');
    }
}
