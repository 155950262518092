<ng-template [ngIf]="filters" [ngTemplateOutlet]="filters"> </ng-template>
<lib-stream-wrapper class="d-flex flex-column flex-1 overflow-hidden" [dataStream$]="data$">
    <ng-template contentTemplate let-vm="data">
        <ng-container *ngIf="filters$ | async as filtersVm">
            <div class="main-content-header main-content-header-shadow pb-0 px-0 mb-3">
                <div class="d-flex px-4 header-height">
                    <div class="widget-header d-flex align-items-center cursor-pointer" (click)="handleQuit()">
                        <fa-icon icon="facArrowLeftRegular" class="fa font-16px back-icon"></fa-icon>
                        <h3 class="ml-2 mt-0 mb-0 font-weight-bold">{{ widget.data.chartOptions.title }}</h3>
                    </div>

                    <dp-dashboard-filter-set
                        class="ml-4"
                        [filters]="filtersVm.filters"
                        [server]="filtersVm.server"
                        [dashboardId]="filtersVm.dashboardId"
                    ></dp-dashboard-filter-set>
                </div>
                <div class="blue-tab w-100">
                    <ul
                        class="nav nav-tabs justify-content-start"
                        ngbNav
                        #nav="ngbNav"
                        [destroyOnHide]="false"
                        [activeId]="activeTab$ | async"
                    >
                        <li *ngIf="auth.isAdmin()" ngbNavItem="widget">
                            <a ngbNavLink>Widget Settings</a>

                            <dp-widget-builder
                                class="d-flex"
                                *ngbNavContent
                                dpProvideDefaultPlaceholders
                                [showBackLink]="false"
                                [externalOptions]="options"
                                [externalWidgetId]="dashboardWidgetId$ | async"
                                [extensions]="extensions$ | async"
                                [extensionsEnabled]="extensionsEnabled"
                                (saveAndClose)="handleClose($event)"
                            ></dp-widget-builder>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="flex-1 d-flex pt-0 background" [ngbNavOutlet]="nav"></div>
        </ng-container>
    </ng-template>
</lib-stream-wrapper>
