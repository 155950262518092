import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { animate, AUTO_STYLE, style, transition, trigger } from '@angular/animations';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

import { DropdownItem, isDefined, observeProperty } from '@dagility-ui/kit';
import { DropdownFilter, WidgetFilter, WidgetFilterType } from 'data-processor';
import { DATA_MORPH_FEATURE_TOGGLE } from 'data-processor/tokens';

import { WidgetDebuggerState } from '../../../../../widget-builder/services/widget.debugger';
import { WidgetWorkflow } from '../../../../../widget-builder/services/widget.flow';
import { globalFilterFactory } from '../global-filter.factory';

@Component({
    selector: 'dp-global-filter',
    templateUrl: './global-filter.component.html',
    styleUrls: ['./global-filter.component.css'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({ opacity: '0', height: 0 }),
                animate('0.25s ease-in-out', style({ opacity: '1', height: AUTO_STYLE })),
            ]),
            transition(':leave', [style({ height: 0 })]),
        ]),
    ],
})
export class GlobalFilterComponent implements OnInit, OnDestroy {
    @Input() filter: WidgetFilter;
    @Input() filterDisabled = false;
    @Input() editMode: boolean;

    @Output() previewClick = new EventEmitter();

    sortedDebuggerState: Partial<WidgetDebuggerState> = {};
    filtersForm = inject(WidgetWorkflow).filters;
    sortedItems$ = new BehaviorSubject<DropdownItem[]>([]);
    selectedValues$ = new BehaviorSubject<DropdownItem[]>([]);
    isEmptySource = false;
    destroy$ = new Subject();
    isStaticFilter = false;

    private debuggerState = inject(WidgetDebuggerState);
    private ft = inject(DATA_MORPH_FEATURE_TOGGLE);

    globalFilterFactory = globalFilterFactory;

    ngOnInit() {
        this.sortedDebuggerState = {
            ...this.debuggerState,
            dataSources: {
                [this.filter.placeholder]: this.sortedItems$.asObservable(),
            },
        };
        const dataSource = this.debuggerState.dataSources[this.filter.placeholder];

        if (!dataSource) {
            this.isStaticFilter = true;

            return;
        }

        const triggers: Array<Observable<unknown>> = [observeProperty(this, 'editMode'), dataSource];
        merge(...triggers)
            .pipe(withLatestFrom(dataSource), takeUntil(this.destroy$))
            .subscribe(([, items]) => this.updateSortedDataSource(items));
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    private updateSortedDataSource(items: DropdownItem[]) {
        const filterControl = this.filtersForm.get(this.filter.placeholder);

        this.isEmptySource = !items || !items?.length;
        if (!items) {
            return;
        }
        const selectedValue = filterControl.value;
        let wrappedValue = selectedValue;
        if (!Array.isArray(selectedValue) && isDefined(selectedValue)) {
            wrappedValue = [selectedValue];
        }
        wrappedValue = new Set(wrappedValue);
        let selectedValues: DropdownItem[] = [];
        const unselectedValues: DropdownItem[] = [];

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            (item as any).wcFilterOrder = i;

            if (wrappedValue.has(item.value)) {
                selectedValues.push(item);
            } else {
                unselectedValues.push(item);
            }
        }

        this.sortedItems$.next(selectedValues.concat(unselectedValues));

        if (this.filter.type === WidgetFilterType.DROPDOWN && (this.filter as DropdownFilter).multiple) {
            if (selectedValues.length === this.sortedItems$.value.length && selectedValues.length > 0) {
                selectedValues = [
                    {
                        value: null,
                        label: 'All',
                    },
                ];
            } else if (selectedValues.length === 0 && !this.isEmptySource) {
                selectedValues = [
                    {
                        value: null,
                        label: 'None',
                    },
                ];
            }
        }

        this.selectedValues$.next(selectedValues);
    }
}
