import { inject, Provider } from '@angular/core';

import { DP_DASHBOARD_TOKEN } from 'data-processor/tokens';
import { DashboardWidgetSettingsManager } from 'data-processor';
import { ActivatedRoute } from '@angular/router';
import { delay, distinctUntilChanged, pluck, switchMap } from 'rxjs/operators';

export const DP_DASHBOARD_DEFAULT_PROVIDER: Provider = {
    provide: DP_DASHBOARD_TOKEN,
    useFactory: () => {
        const externalDpDashboard$ = inject(DP_DASHBOARD_TOKEN, {
            skipSelf: true,
            optional: true,
        });

        if (externalDpDashboard$) {
            return externalDpDashboard$;
        }

        const api = inject(DashboardWidgetSettingsManager);
        const route = inject(ActivatedRoute);

        return route.params.pipe(
            delay(100),
            pluck('id'),
            distinctUntilChanged<number>(),
            switchMap(id => api.getDashboard(id.toString(), true))
        );
    },
};
