export const THEMES = [
    {
        value: 'light-ust',
        label: 'Light UST',
    },
    {
        value: 'dark-ust',
        label: 'Dark UST',
    },
];
export const DEFAULT_THEME = 'light-ust';
