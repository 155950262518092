<div class="modal-header">
    <h4 class="modal-title">{{ title }} <span *ngIf="id && dashboard" class="color-brand"> {{ dashboard }} </span></h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>
        <strong>
            {{ message }} <span *ngIf="dashboard" class="color-brand"> {{ dashboard }} dashboard </span>?
        </strong>
    </p>
    <p *ngIf="warningMessage">
        {{ warningMessage }}
        <span *ngIf="note" class="text-danger">{{ note }}</span>
    </p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="dismiss()">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="accept()">Ok</button>
</div>
