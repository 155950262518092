import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {validateFormAndDisplayErrors} from '@dagility-ui/kit';

@Component({
    selector: 'app-new-folder-modal',
    templateUrl: './new-folder-modal.component.html',
    styleUrls: ['./new-folder-modal.component.scss'],
})
export class NewFolderModalComponent implements OnInit {
    formGroup: FormGroup;

    private folderName: string;
    constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {}

    ngOnInit() {
        this.formGroup = this.fb.group({
            folderName: [this.folderName, [Validators.required, Validators.pattern(`^(?!\\.)[a-zA-Z0-9\-\_\.]+$`)]],
        });
    }

    submit() {
        validateFormAndDisplayErrors(this.formGroup);
        if (this.formGroup.valid) {
            this.folderName = this.formGroup.get('folderName').value;
            this.formGroup.markAsPristine();
            this.activeModal.close(this.folderName);
        }
    }

    cancel() {
        this.activeModal.dismiss();
    }

    enterKeyHandler(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.submit();
        }
    }
}
