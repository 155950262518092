import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'dp-widget-builder-columns-reorder',
    templateUrl: './widget-builder-columns-reorder.component.html',
    styleUrls: ['./widget-builder-columns-reorder.component.scss'],
})
export class WidgetBuilderColumnsReorderComponent {
    @Input() public columns: any;

    @Output() columnsData: EventEmitter<any> = new EventEmitter();

    constructor(private activeModal: NgbActiveModal) {}

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }

    dropDashboard(event: CdkDragDrop<string[]>) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }

    applyChanges() {
        if (this.columns) {
            this.columns.forEach((element: any, index: number) => {
                element.sequenceNo = index + 1;
            });
            this.columnsData.emit(this.columns);
        }
        this.activeModal.close('created');
    }

    dismiss() {
        this.activeModal.close();
    }
}
