<div class="d-flex align-items-center min-width-fit-content wrapper flex-wrap"
     [class.mb-10px]="!last"
     [class.extra-row]="last"
     *ngFor="let row of data.items; let last = last"
>
    <ng-container *ngFor="let item of row; let lastItem = last">
        <div class="item d-flex flex-1" [class.top]="item.isTop">
            <div class="item-value">{{item.value}}</div>
            <div class="item-label ml-10px">
                <span class="d-flex item-label-unit" *ngIf="last">Top Priority Risks</span>
                <span class="d-flex" [class.mt-8px]="!last">{{item.label}}</span>
            </div>
        </div>
        <div *ngIf="!lastItem" class="item-splitter"></div>
    </ng-container>
</div>
