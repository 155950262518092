<ng-container *ngIf="pmState.state$ | async as state">
    <div class="dp-header d-flex main-content-header main-content-header-shadow pb-15px" [class.align-items-center]="isInIframe">
        <div>
            <nav *ngIf="!isInIframe" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a [routerLink]="'../../../../'">Settings</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Integrations: ETL Subsystem</li>
                </ol>
            </nav>

            <h1>ETL Subsystem</h1>
        </div>

            <div *ngIf="isInIframe" class="control-container mt-20px">
                <lib-search
                    *ngIf="this.pmState.isServiceAvailable"
                    [value]="state.filter"
                    class="search"
                    (searchText)="handleSearch($event)"
                ></lib-search>
            </div>
        </div>
        <perfect-scrollbar class="pt-25px pr-15px dp-container" [config]="perfectScrollBarConfig">
            <div *ngIf="!isInIframe" class="control-container w-100">
                <lib-search
                    *ngIf="this.pmState.isServiceAvailable"
                    [value]="state.filter"
                    class="search ml-auto mb-3"
                    (searchText)="handleSearch($event)"
                ></lib-search>
            </div>
            <ng-container *ngIf="state.tools && this.pmState.isServiceAvailable; else errorTmpl">
                <div class="pl-5 wrapper">
                    <dp-processors [tools]="state.filteredTools"></dp-processors>
                </div>
            </ng-container>
            <ng-template #errorTmpl>
                <div class="d-flex justify-content-center flex-column align-items-center">
                    <div class="service-not-available-image"></div>
                    <span class="error-text">
                        Service is not available
                    </span>
                </div>
            </ng-template>
        </perfect-scrollbar>
</ng-container>
