import { Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, throwError } from 'rxjs';
import { catchError, map, share, startWith, switchMap, tap } from 'rxjs/operators';

import { AnyWidget, WidgetBuilderService } from '../widget-builder/services/widget-builder.service';
import { WidgetPreview } from '../widget-library.page';
import { WidgetLibraryStore } from '../widget-library.store';
import { ToasterService } from '@dagility-ui/shared-components';
import { DATA_MORPH_FEATURE, DATA_MORPH_FEATURE_TOGGLE, DataMorphFeatureToggleService } from 'data-processor/tokens';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { ModelGraphService, QUERY_BUILDER_MODEL_GRAPH_SERVICE } from 'data-processor/lib/tokens/querybuilder.token';

@Component({
    selector: 'dp-widget-list',
    templateUrl: './widget-list.component.html',
    styleUrls: ['./widget-list.component.scss'],
    host: { class: 'widget-list' },
})
export class WidgetListComponent {
    @Input() activeWidgetId: number;
    @Input() clonedId: number;

    @Output() widgetSelect = new EventEmitter<WidgetPreview>();
    @Output() deleteWidget = new EventEmitter<WidgetPreview>();

    filter: FormControl = new FormControl('');
    reload$ = new BehaviorSubject(null);
    data$ = this.reload$.pipe(
        switchMap(() => this.api.getAllWidgets()),
        map(widgets =>
            widgets.map(
                ({ id, data }) =>
                    ({
                        id,
                        description: data.chartOptions.description ?? '',
                        title: data.chartOptions.title ?? '',
                        type: data.type,
                        system: data.system,
                    } as WidgetPreview)
            )
        ),
        map(widgets => {
            if (!this.clonedId) {
                return widgets;
            }

            const clonedIndex = widgets.findIndex(({ id }) => id === this.clonedId);

            if (clonedIndex === -1) {
                return widgets;
            }

            const [clonedWidget] = widgets.splice(clonedIndex, 1);

            widgets.unshift(clonedWidget);

            return widgets;
        }),
        tap(widgets =>
            this.store.setState({
                widgets,
            })
        ),
        catchError(e => {
            console.error(e);

            return throwError(e);
        })
    );
    filter$: Observable<string> = this.filter.valueChanges.pipe(startWith(this.filter.value as {}));

    widgets$ = combineLatest([this.store.asObservable(), this.filter$]).pipe(
        tap(() => {
            this.perfectScrollbar?.scrollToTop();
        }),
        map(([{ widgets }, filter]) => widgets.filter(w => w.title?.toUpperCase().includes(filter.toUpperCase())))
    );

    hideDescription$: Observable<boolean> = this.featureToggleService
        .isActive(DATA_MORPH_FEATURE.HIDE_DESCRIPTION)
        .pipe(startWith(false), share());

    @ViewChild(PerfectScrollbarDirective) perfectScrollbar: PerfectScrollbarDirective;

    constructor(
        private api: WidgetBuilderService,
        private store: WidgetLibraryStore,
        private toaster: ToasterService,
        public router: Router,
        private route: ActivatedRoute,
        @Inject(DATA_MORPH_FEATURE_TOGGLE) private featureToggleService: DataMorphFeatureToggleService,
        @Inject(QUERY_BUILDER_MODEL_GRAPH_SERVICE) private modelGraphService: ModelGraphService
    ) {}

    handleImport() {
        this.api
            .importWidget()
            .pipe(switchMap(importedWidget => this.api.createWidget(importedWidget)))
            .subscribe(() => {
                this.handleImportSuccess();
            });
    }

    handleImportSuccess() {
        this.toaster.successToast({
            content: 'Imported successfully',
            title: 'Success',
        });

        this.reload$.next(null);
    }

    handleCloneWidget(widgetId: number) {
        const showMessage = (anyWidget: AnyWidget) => {
            this.toaster.successToast({
                content: `You Have Successfully cloned ${anyWidget.data.chartOptions.title} widget. You can find this widget under custom widgets.`,
                title: 'Success',
            });
            this.router.navigate([anyWidget.id], {
                state: { fromLink: this.router.url, fromClone: true },
                relativeTo: this.route,
            });
        };

        this.api.cloneWidgetInLibrary(widgetId).subscribe(anyWidget => {
            if (!anyWidget.data.modelGraphId) {
                showMessage(anyWidget);
                return;
            }
            this.modelGraphService.cloneGraph(anyWidget.data.modelGraphId).subscribe(
                st => {
                    anyWidget.data.modelGraphId = st.uid;
                    this.api.updateWidget(anyWidget.data, anyWidget.id).subscribe(() => {
                        showMessage(anyWidget);
                    });
                },
                () => {
                    showMessage(anyWidget);
                }
            );
        });
    }
}
