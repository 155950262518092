import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import {NgbNav} from '@ng-bootstrap/ng-bootstrap';
import {ToolService} from '@dagility-ui/shared-components';
import {EnvironmentModel} from '@dagility-ui/kit';
import {PerfectScrollbarConfig} from 'ngx-perfect-scrollbar';

@Component({
    selector: 'dp-plugins',
    templateUrl: './plugins.component.html',
    styleUrls: ['./plugins.component.scss'],
})
export class PluginsComponent implements OnInit {
    @Input() showProjects = true;
    @Input() showExternalCredentials = true;

    @Output() runToolWizard: EventEmitter<string> = new EventEmitter();

    @ViewChildren('parentList') parentList: QueryList<any>;
    @ViewChildren('childList') childList: QueryList<any>;
    pluginDetailsToggle = true;
    pluginUsageToggle = false;
    pluginInfo: any;
    pluginDetails: any;
    tempPlugins: any;
    dataReady = false;
    isInIframe = false;
    dpTab = (this.environment as any).component;
    @ViewChild(NgbNav, { static: true }) tabset: NgbNav;

    perfectScrollBarConfig: PerfectScrollbarConfig = new PerfectScrollbarConfig({
        suppressScrollX: false,
        suppressScrollY: true,
    });

    constructor(private pluginService: ToolService, @Inject('environment') private environment: EnvironmentModel) {
    }

    ngOnInit() {
        this.pluginDetailsToggle = true;
        this.pluginUsageToggle = false;
        this.isInIframe = this.inIframe();
    }

    onPluginSelect(pluginInfo: any) {
        this.pluginInfo = pluginInfo;
    }

    onPluginDetailsSelect() {
        this.pluginDetailsToggle = true;
        this.pluginUsageToggle = false;
    }

    onPluginUsageSelect() {
        this.pluginUsageToggle = true;
        this.pluginDetailsToggle = false;
    }

    onselectedChange(data: any) {
        this.pluginService.getToolDetails(data['text']).subscribe((result: any) => {
            this.pluginDetails = result['result'];
        });
    }

    pluginDetailsJson() {
        this.pluginService.getAllTools().subscribe(result => {
            this.processPlugins(result);
        });
    }

    processPluginResult(modalRef: any) {
        modalRef.result
            .then((result: any) => {
                if (result === 'success') {
                    this.dataReady = false;
                    this.tempPlugins = {};
                    this.pluginDetailsJson();
                }
            })
            .catch();
    }

    processPlugins(result: any) {
        this.tempPlugins = result['result'];
        this.tempPlugins.forEach((element: any) => {
            element['text'] = element['category'];
            element['value'] = element['category'];
            element['collapsed'] = true;
            element['imgUrl'] = null;
            element['children'] = [];
            for (let i = 0; i < element['plugins'].length; i++) {
                const pluginValue = element['plugins'];
                const children: Record<string, any> = {};
                children['text'] = pluginValue[i];
                children['value'] = pluginValue[i];
                children['collapsed'] = true;
                children['imgUrl'] = null;
                element['children'].push(children);
            }
        });
        this.dataReady = true;
    }

    openTab(tabName: string) {
        this.tabset.select(tabName);
    }

    inIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }
}
