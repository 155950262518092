<ng-template #tourStep let-step="step">
    <div class="d-flex justify-content-between border-bottom header-container pt-1 pb-2 px-3">
        <span *ngIf="step?.title" class="tour-heading">{{ step?.title }}</span>
    </div>
    <div class="mt-2 tour-step-content font-12px px-1" [innerHTML]="step?.content" (click)="handleContentClick($event)"></div>
    <div class="tour-step-navigation d-flex justify-content-between px-1">
        <div>
            <span class="step-num">{{ tourService.steps.indexOf(tourService.currentStep) + 1 }}/{{ tourService.steps.length }}</span>
            <button class="btn btn-secondary d-inline-block mx-2 px-2" (click)="skipTour()" *ngIf="tourService.hasNext(step)">
                Skip Tour
            </button>
        </div>
        <div class="pb-1">
            <button *ngIf="tourService.hasPrev(step)" class="btn btn-primary mx-2 px-2 d-inline-block" (click)="tourService.prev()">
                {{ step?.prevBtnTitle }}
            </button>
            <button *ngIf="tourService.hasNext(step)" class="btn btn-primary d-inline-block" (click)="tourService.next()">
                {{ step?.nextBtnTitle }}
            </button>
            <button class="btn btn-primary px-2 mx-2 d-inline-block" (click)="endTour()" *ngIf="!tourService.hasNext(step)">
                {{ step?.endBtnTitle }}
            </button>
        </div>
    </div>
</ng-template>
