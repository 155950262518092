import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, InjectionToken, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { notEmptyString, showServerValidationErrors, validateFormAndDisplayErrors } from '@dagility-ui/kit';

import { CreateOrganizationDto } from '../api/organizations.service';

export const ORG_FORM_HELPER = new InjectionToken('Form Helper', {
    factory: () => ({ validateFormAndDisplayErrors, showServerValidationErrors }),
});

@Component({
    selector: 'app-add-new-organization',
    templateUrl: './add-new-organization.component.html',
    styleUrls: ['./add-new-organization.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddNewOrganizationComponent implements OnInit {
    @Input() header: string = '';
    @Input() saveButtonText: string;
    @Input() saveFunction: (organization: Omit<CreateOrganizationDto, 'id'>) => Observable<CreateOrganizationDto> = of;
    @Input() organization: CreateOrganizationDto = null;

    maxLength: Record<string, number> = {
        description: 250,
        name: 50,
    };
    form = inject(FormBuilder).nonNullable.group({
        name: ['', [notEmptyString(), Validators.required, Validators.maxLength(this.maxLength.name)]],
        description: ['', [Validators.maxLength(this.maxLength.description)]],
    });
    private activeModal = inject(NgbActiveModal);
    private formHelper = inject(ORG_FORM_HELPER);
    private cdr = inject(ChangeDetectorRef);

    ngOnInit() {
        this.form.patchValue(this.organization ?? {});
    }

    toSave() {
        return this.form.value as Required<this['form']['value']>;
    }

    save() {
        if (this.form.pending) {
            return;
        }

        if (!this.form.valid) {
            this.formHelper.validateFormAndDisplayErrors(this.form);

            return;
        }

        this.form.markAsPending();

        this.saveFunction(this.toSave())
            .pipe(
                finalize(() => {
                    this.form.markAsPristine();
                })
            )
            .subscribe({
                next: data => this.activeModal.close(data),
                error: err => this.handleError(err),
            });
    }

    close() {
        if (this.form.pending) {
            return;
        }

        this.activeModal.dismiss();
    }

    private handleError(err: Error) {
        if (err instanceof HttpErrorResponse && err?.error.errors && Array.isArray(err.error.errors)) {
            this.form.get('name').markAsDirty();
            this.formHelper.showServerValidationErrors(this.form, err);
            this.cdr.detectChanges();
        } else {
            console.error(err);
        }
    }
}
