import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { WidgetTile } from 'data-processor';

@Component({
    selector: 'dp-widget-tiles:not([new])',
    templateUrl: './widget-tiles.component.html',
    styleUrls: ['./widget-tiles.component.scss'],
})
export class WidgetTilesComponent implements OnInit {
    @Input() placeholders: Record<string, any>;
    @Input() position: string;

    @Output() drilldown = new EventEmitter<WidgetTile>();

    tiles: WidgetTile[];
    horizontalOrientation: boolean = false;

    ngOnInit(): void {
        this.horizontalOrientation = this.position === 'top' || this.position === 'bottom';
    }

    handleTileClicked(tile: WidgetTile) {
        this.drilldown.emit(tile);
    }

    deltaSign(tileDelta: any) {
        return parseFloat(tileDelta);
    }
}
