import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { faExclamationCircle, faExclamationTriangle, IconDefinition } from '@fortawesome/free-solid-svg-icons';

import { IssueElement } from '../models/issue-list.model';
import { CustomIcon } from '@dagility-ui/shared-components/icons';

@Component({
    selector: 'dp-edit-job-definition-issue-list',
    templateUrl: './edit-job-definition-issue-list.component.html',
    styleUrls: ['./edit-job-definition-issue-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditJobDefinitionIssueListComponent {
    @Input() issuesList: IssueElement[] = [];

    @Output() issueSelected = new EventEmitter();

    icons: Record<string, IconDefinition | CustomIcon> = {
        WARNING: faExclamationTriangle,
        ERROR: faExclamationCircle,
    };
}
