import { AfterViewInit, Directive, ElementRef, Input, NgModule, OnDestroy, Renderer2 } from '@angular/core';

import PerfectScrollbar from 'perfect-scrollbar';

@Directive({
    selector: '[wrapToPerfectScrollbar]',
})
export class WrapToPerfectScrollbarDirective implements AfterViewInit, OnDestroy {
    @Input('wrapToPerfectScrollbar') selector: string;
    @Input('wrapToPerfectScrollbarOptions') options: PerfectScrollbar.Options = undefined;

    private ps: PerfectScrollbar;

    constructor(private elementRef: ElementRef<HTMLElement>, private renderer: Renderer2) {}

    ngAfterViewInit() {
        const scrollableContainer = this.elementRef.nativeElement.querySelector(this.selector);

        if (!scrollableContainer) {
            console.warn(`Can't find element by ${this.selector}`);

            return;
        }

        this.ps = new PerfectScrollbar(scrollableContainer, this.options);
        this.renderer.setStyle(scrollableContainer, 'position', 'relative');
        this.ps.update();
    }

    ngOnDestroy() {
        this.ps?.destroy();
    }
}

@NgModule({
    declarations: [WrapToPerfectScrollbarDirective],
    exports: [WrapToPerfectScrollbarDirective],
})
export class WrapToPerfectScrollbarDirectiveModule {}
