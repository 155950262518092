<ng-container *ngIf="actions.type === 'PM'">
    <div *ngFor="let action of actions.data; let last = last" class="mb-20px" [class.border-bottom]="!last">
        <h4 class="font-weight-bold font-12px">Status Changed Details</h4>
        <div class="row mt-15px">
            <div class="col-8 user">
                <lib-user-pic class="d-inline-block float-left" [size]="30" [user]="{ name: action.author }"></lib-user-pic>
                <div class="ml-10px d-inline-block">
                    <div class="user-name">{{ action.author }}</div>
                    <div>made changes</div>
                </div>
            </div>
            <div class="col-4 font-10px secondary-text-and-icons font-italic time">
                <span class="pull-right">{{ action.created | date: 'MM/dd/yyyy' }} at {{ action.logTime }}</span>
            </div>
        </div>
        <div class="row mt-20px" *ngIf="action.fromLabel">
            <div class="col-4 secondary-text-and-icons">Previous Status</div>
            <div class="col-8">
                <span
                    class="custom-badge custom-badge-background text-white float-right"
                    [style.backgroundColor]="statusMap.get(action.from.toLowerCase())?.color"
                >
                    {{ action.fromLabel }}
                </span>
            </div>
        </div>
        <div class="row mt-15px mb-15px pb-2">
            <div class="col-4 secondary-text-and-icons">New Status</div>
            <div class="col-8">
                <span
                    class="custom-badge custom-badge-background text-white float-right"
                    [style.backgroundColor]="statusMap.get(action.to.toLowerCase())?.color"
                >
                    {{ action.toLabel }}
                </span>
            </div>
        </div>
        <ng-container *ngIf="action.comments && action.comments.length">
            <h4 class="font-weight-bold font-12px">Changes Made in Item</h4>
            <ng-container *ngFor="let comment of action.comments; let last = last">
                <ng-container *ngIf="comment.field !== 'RemoteIssueLink' && 'comment'.indexOf(comment.field.toLowerCase()) < 0; else commentTmpl">
                    <div class="row mt-15px">
                        <div class="col-8 user">
                            <lib-user-pic class="d-inline-block float-left" [size]="30" [user]="{ name: comment.userId }"></lib-user-pic>
                            <div class="ml-10px d-inline-block">
                                <div class="user-name">{{ comment.userId }}</div>
                                <div>changed {{ comment.field | lowercase }}</div>
                            </div>
                        </div>
                        <div class="col-4 font-10px secondary-text-and-icons font-italic time">
                            <span class="pull-right">{{ comment.created | date: 'MM/dd/yyyy' }} at {{ comment.logTime }}</span>
                        </div>
                    </div>
                    <div class="row mt-20px" *ngIf="comment.changeFrom">
                        <div class="col-4 secondary-text-and-icons">Previous Value</div>
                        <div class="col-8 pull-right">
                            <span #value class="pull-right changed-value">{{ comment.changeFrom }}</span>
                            <span
                                *ngIf="value.scrollHeight > value.clientHeight || value.classList.contains('expanded')"
                                class="pull-right mt-5px cursor-pointer color-brand"
                                (click)="value.classList.toggle('expanded')"
                            >
                                Show {{ value.scrollHeight > value.clientHeight ? 'more' : 'less' }}
                            </span>
                        </div>
                    </div>
                    <div class="row mt-15px mb-15px pb-2" [class.border-bottom]="!last">
                        <div class="col-4 secondary-text-and-icons">New Value</div>
                        <div class="col-8 pull-right">
                            <span #value class="pull-right changed-value">{{ comment.changeTo }}</span>
                            <span
                                *ngIf="value.scrollHeight > value.clientHeight || value.classList.contains('expanded')"
                                class="pull-right mt-5px cursor-pointer color-brand"
                                (click)="value.classList.toggle('expanded')"
                            >
                                Show {{ value.scrollHeight > value.clientHeight ? 'more' : 'less' }}
                            </span>
                        </div>
                    </div>
                </ng-container>

                <ng-template #commentTmpl>
                    <div class="row mt-15px">
                        <div class="col-8 user">
                            <lib-user-pic class="d-inline-block float-left" [size]="30" [user]="{ name: comment.userId }"></lib-user-pic>
                            <div class="ml-10px d-inline-block">
                                <div class="user-name">{{ comment.userId }}</div>
                                <div>added a comment</div>
                            </div>
                        </div>
                        <div class="col-4 font-10px secondary-text-and-icons font-italic time">
                            <span class="pull-right">{{ comment.created | date: 'MM/dd/yyyy' }} at {{ comment.logTime }}</span>
                        </div>
                    </div>
                    <div class="row mt-15px ml-5px mb-15px pb-2 comment-row">{{ comment.changeTo }}</div>
                </ng-template>
            </ng-container>
        </ng-container>
    </div>
</ng-container>
<ng-container *ngIf="actions.type === 'SCM'">
    <h4 class="font-weight-bold font-12px">Commit Details</h4>
    <ng-container *ngFor="let action of actions.data; let last = last">
        <div class="row mt-15px">
            <div class="col-8 user">
                <lib-user-pic class="d-inline-block float-left" [size]="30" [user]="{ name: action.author }"></lib-user-pic>
                <div class="ml-10px d-inline-block">
                    <div class="user-name">{{ action.author }}</div>
                    <div>made a commit on</div>
                </div>
            </div>
            <div class="col-4 font-10px secondary-text-and-icons font-italic time">
                {{ action.created | date: 'MM/dd/yyyy' }} at {{ action.logTime }}
            </div>
        </div>
        <div class="row mt-20px">
            <div class="col-8 secondary-text-and-icons">Lines of Code Committed</div>
            <div class="col-4">{{ action.additions }}</div>
        </div>
        <div class="row mt-15px mb-15px">
            <div class="col-8 secondary-text-and-icons">Lines of Code Deleted</div>
            <div class="col-4">{{ action.deletions }}</div>
        </div>
        <div class="row mt-15px mb-15px">
            <div class="col-8 secondary-text-and-icons">Repo</div>
            <div class="col-4">{{ action.repo }}</div>
        </div>
        <div class="row mt-15px mb-15px pb-2" [class.border-bottom]="!last">
            <div class="col-8 secondary-text-and-icons">Branch</div>
            <div class="col-4">{{ action.branch }}</div>
        </div>
    </ng-container>
</ng-container>
<ng-container *ngIf="actions.type === 'CI/CD'">
    <h4 class="font-weight-bold font-12px">Build Details</h4>
    <div *ngFor="let action of actions.data; let last = last" [class.border-bottom]="!last" class="pb-2">
        <div class="row mt-20px">
            <div class="col-7 secondary-text-and-icons">Build Triggered on</div>
            <div class="col-5">{{ action.created | date: 'MM/dd/yyyy' }} at {{ action.logTime }}</div>
        </div>
        <div class="row mt-15px">
            <div class="col-7 secondary-text-and-icons">Build Agent</div>
            <div class="col-5">
                <span class="build-agent">
                    <img *ngIf="toolImages[action.toolId] as image" class="tool-icon ml-5px mr-5px mt-5px float-left" [src]="image" />
                    {{ action.toolId }}
                </span>
            </div>
        </div>
        <div class="row mt-15px">
            <div class="col-7 secondary-text-and-icons">Environment</div>
            <div class="col-5">{{ action.environment }}</div>
        </div>
        <div class="row mt-15px">
            <div class="col-7 secondary-text-and-icons">Component</div>
            <div class="col-5">{{ action.component }}</div>
        </div>
        <div class="row mt-15px">
            <div class="col-7 secondary-text-and-icons">Duration</div>
            <div class="col-5">{{ action.duration }}</div>
        </div>
        <div class="row mt-15px" *ngIf="action.deployedIssues?.length">
            <div class="col-7 secondary-text-and-icons">Deployed Items</div>
            <div class="col-5">
                <span class="d-block" *ngFor="let issue of action.deployedIssues">{{ issue }}</span>
            </div>
        </div>
        <div *ngIf="action.buildStatus?.toLowerCase() as status" class="row mt-15px mb-15px">
            <div class="col-7 secondary-text-and-icons">Build Status</div>
            <div class="col-5">
                <span class="status-{{ status }}">{{ action.buildStatus }}</span>
                (Build id: #{{ action.buildNumber }})
                <span
                    *ngIf="action.logLink"
                    class="cursor-pointer d-block color-brand"
                    (click)="viewLogs(action.buildNumber, action.logLink)"
                >
                    {{ status === 'failed' ? 'Analyze Build Failure' : 'View Logs' }}
                </span>
            </div>
        </div>
        <div *ngFor="let report of action.sonar || [] | keyvalue">
            <h4 class="font-weight-bold font-12px mt-10px cursor-pointer" (click)="report.value.show = !report.value.show">
                Sonar Report (<span class="color-brand mr-5px ml-5px">{{ report.key }}</span
                >)
                <fa-icon [icon]="report.value.show ? 'chevron-down' : 'chevron-right'" class="pull-right font-16px"></fa-icon>
            </h4>
            <ng-container *ngIf="report.value.show">
                <ng-container *ngFor="let metric of report.value; let even = even; let odd = odd; let last = last">
                    <div
                        class="col-6 my-10px p-15px d-inline-block sonar-metric"
                        [class.col-12]="even && last"
                        [class.even]="even"
                        [class.odd]="odd"
                        [class.last]="last"
                    >
                        <div class="font-13px">{{ metric.actualValue || 0 | number: '1.0-1' }}</div>
                        {{ metric.metricKey | titlecase }}
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="actions.type === 'PM' && actions.field?.toLowerCase() === 'sprint'">
    <h4 class="font-weight-bold font-12px">Sprint Changed</h4>
    <div class="row mt-15px">
        <div class="col-8">
            <span class="sprint-change-icon color-brand font-14px mr-5px d-inline-block"><fa-icon icon="facSitemapO"></fa-icon></span>
            Sprint changed on
        </div>
        <div class="col-4 font-10px secondary-text-and-icons font-italic time">
            <span class="pull-right">{{ actions.created | date: 'MM/dd/yyyy' }} at {{ actions.logTime }}</span>
        </div>
    </div>
    <div class="row mt-20px">
        <div class="col-4 secondary-text-and-icons">Previous Sprint</div>
        <div class="col-8">
            <span class="pull-right">{{ actions.from }}</span>
        </div>
    </div>
    <div class="row mt-15px">
        <div class="col-4 secondary-text-and-icons">Current Sprint</div>
        <div class="col-8">
            <span class="pull-right">{{ actions.to }}</span>
        </div>
    </div>
    <div class="row mt-15px">
        <div class="col-4 secondary-text-and-icons">Sprint Start Date</div>
        <div class="col-8">
            <span *ngIf="actions.startDate?.toLowerCase() !== 'unknown'; else unknownDateTmpl" class="pull-right"
                >{{ actions.startDate | date: 'MM/dd/yyyy' }} at {{ actions.startDate | date: 'h:mm a' }}</span
            >
        </div>
    </div>
    <div class="row mt-15px">
        <div class="col-4 secondary-text-and-icons">Sprint End Date</div>
        <div class="col-8">
            <span *ngIf="actions.endDate?.toLowerCase() !== 'unknown'; else unknownDateTmpl" class="pull-right"
                >{{ actions.endDate | date: 'MM/dd/yyyy' }} at {{ actions.endDate | date: 'h:mm a' }}</span
            >
        </div>
    </div>

    <ng-template #unknownDateTmpl>
        <span class="pull-right">Unknown</span>
    </ng-template>
</ng-container>

<ng-container *ngIf="actions.type === 'LAST_7_BUILDS_STATUSES'">
    <h4 class="font-weight-bold font-12px">Deployment Details (Last 7 Days)</h4>
    <ng-container *ngFor="let status of actions.statuses; let last = last">
        <div class="row mt-20px">
            <div class="col-7 secondary-text-and-icons">Environment</div>
            <div class="col-5">{{ status.environment }}</div>
        </div>
        <div class="row mt-15px">
            <div class="col-7 secondary-text-and-icons">Successful</div>
            <div class="col-5">{{ status.successfulPercentage | number: '1.0-2' }}%</div>
        </div>
        <div class="row mt-15px pb-15px" [class.border-bottom]="!last">
            <div class="col-7 secondary-text-and-icons">Failed</div>
            <div class="col-5">{{ status.failedPercentage | number: '1.0-2' }}%</div>
        </div>
    </ng-container>
</ng-container>
