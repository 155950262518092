import { Injectable, Injector, NgModuleRef } from '@angular/core';
import { PreloadingStrategy, Route, RouteConfigLoadEnd, Router, RouterPreloader } from '@angular/router';
import { EMPTY, Observable, Subject } from 'rxjs';
import { finalize, mergeMap } from 'rxjs/operators';
import { ModulesResolver } from '@app/core/services/modules.resolver';

interface TriggerOptions {
    routePath: string;
    resolve: (moduleRef: NgModuleRef<any>) => void;
}

@Injectable({ providedIn: 'root' })
export class OnDemandPreloadStrategy implements PreloadingStrategy {
    private preloadingTrigger$ = new Subject<TriggerOptions>();

    private loadedRoutes: Record<string, Route> = {};

    constructor(private router: Router, private modulesResolver: ModulesResolver, private injector: Injector) {
        router.events.subscribe((
            event => {
                if (event instanceof RouteConfigLoadEnd) {
                    this.loadedRoutes[event.route.path] = event.route;
                }
            }));
    }

    loadModule(routePath: string): Promise<NgModuleRef<any>> {
        if (this.loadedRoutes[routePath]) {
            return Promise.resolve(this.getNgModuleRef(this.loadedRoutes[routePath] as any));
        }

        return new Promise(async resolve => {
            const routerPreloader =  this.injector.get(RouterPreloader);
            this.modulesResolver.resolveModule(routePath).subscribe(resolved => {
                if (resolved) {
                    routerPreloader.preload().subscribe();
                }
                this.preloadingTrigger$.next({ routePath, resolve });
            });
        });
    }

    preload(route: Route & { _loadedInjector: any }, load: () => Observable<any>): Observable<any> {
        return this.preloadingTrigger$.pipe(
            mergeMap(({ routePath, resolve }) => {
                const shouldPreload = this.shouldPreload(route, routePath);
                if (shouldPreload && route['_loadedInjector']) {
                    // already loaded
                    resolve(this.getNgModuleRef(route));

                    return EMPTY;
                }

                return shouldPreload
                    ? load().pipe(
                          finalize(() => {
                              resolve(this.getNgModuleRef(route));
                          })
                      )
                    : EMPTY;
            })
        );
    }

    private getNgModuleRef(route: any) {
        return route && route['_loadedInjector'].get(NgModuleRef);
    }

    private shouldPreload(route: Route, path: string) {
        return route.data && route.data['preload'] && route.path.includes(path);
    }
}
