import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, ElementRef,
    Inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    QueryList,
    SimpleChanges,
    TrackByFunction,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbAccordion, NgbPanel, NgbPanelContent, NgbPanelHeader } from '@ng-bootstrap/ng-bootstrap';
import { concat, defer, fromEvent, of, Subject, zip } from 'rxjs';
import { defaultIfEmpty, shareReplay, startWith, takeUntil } from 'rxjs/operators';
import { GridListItem, GridsterComponent, GridsterItemComponent, IGridsterOptions } from 'angular2gridster';

import { ToasterService } from '@dagility-ui/shared-components';
import { ModalService } from '@dagility-ui/kit';
import {
    DATA_MORPH_AUTH,
    DATA_MORPH_FEATURE_TOGGLE,
    DataMorphAuthService,
    DataMorphFeatureToggleService,
} from 'data-processor/tokens';

import { AnyWidgetWrapperComponent } from '../../../widget-builder/components/widget/any-widget-wrapper/any-widget-wrapper.component';
import { DataMorph } from '../../models/dp-dashboard.model';
import { DashboardWidgetSettingsManager } from '../../services/dashboard-widget-settings.manager';
import { DpDashboardService } from '../../services/dp-dashboard.service';
import { DashboardGroupState, DpDashboardState } from '../../state/dp-dashboard.state.model';
import { DpDashboardStore } from '../../state/dp-dashboard.store';
import { DpDashboardFacade } from '../../state/dp-dashboard.facade';
import { exportAnyWidget } from '../../../widget-builder/widget-builder.utils';
import { GridsterResizeComponent } from './gridster-resize.component';
import { GridsterReflowService } from './gridster-reflow.service';
import { GridsterOptionsService } from '../../services/gridster/gridster-options.service';
import {
    buildResponsiveOption,
    GRIDSTER_OPTIONS,
    GridsterBreakpoint,
    GridsterProperty,
} from '../../services/gridster/angular2gridster.const';

@Component({
    selector: 'dp-dashboard-group',
    templateUrl: './dp-dashboard-group.component.html',
    styleUrls: ['./dp-dashboard-group.component.scss'],
    providers: [
        {
            provide: NgbPanel,
            useExisting: DpDashboardGroupComponent,
        },
        GridsterReflowService,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DpDashboardGroupComponent extends NgbPanel implements OnInit, OnChanges, OnDestroy {
    @Input() group: DashboardGroupState;

    @Input() tabId: number;

    @Input() edit: boolean;

    @Input() widgets: DpDashboardState['widgetsMap'];

    @Input() widgetsPlaceholders: any;

    @Input() groupsCount: number;

    @Input() extensionsEnabled = false;

    @ViewChild(NgbPanelHeader) headerTpl: NgbPanelHeader;

    @ViewChild(NgbPanelContent) contentTpl: NgbPanelContent;

    @ViewChild(GridsterComponent) gridsterComponent: GridsterComponent;

    @ViewChild('gridster', { read: ElementRef }) gridsterEl: ElementRef;

    @ViewChild(GridsterResizeComponent) gridsterResize: GridsterResizeComponent;

    @ViewChildren(AnyWidgetWrapperComponent) dpWidgets: QueryList<any>;

    cardClass = 'dashboard-group';

    gridsterOptions: IGridsterOptions = GRIDSTER_OPTIONS;

    /* @duplicate {canEditWidget} **/
    isAdmin$ = of(this.auth.isAdmin()).pipe(shareReplay(1));

    showDragAndDropArea$ = this.store.select(state => state.edit).pipe(startWith(this.store.selectSync(state => state.edit)));

    private destroyGridsterMouseDown$ = new Subject<void>();

    get showGroupHeader() {
        if (this.edit) {
            return true;
        }

        if (this.group.definition.type === DataMorph.DashboardGroupVisibility.HIDE_HEADER) {
            return false;
        }

        return this.groupsCount > 1 || this.group.definition.type !== DataMorph.DashboardGroupVisibility.SINGLE;
    }

    getExternalOptions = (widget: DataMorph.IlluminateDashboardWidget) => ({
        ...widget.options,
        ...widget.illuminateOptions,
    });

    get gridsterSettings() {
        return this.store.value.options.gridsterSettings[this.group.id];
    }

    constructor(
        public store: DpDashboardStore,
        private modal: ModalService,
        private api: DpDashboardService,
        public dashboardWidgetManager: DashboardWidgetSettingsManager,
        private router: Router,
        private route: ActivatedRoute,
        private toaster: ToasterService,
        public cdr: ChangeDetectorRef,
        private accordion: NgbAccordion,
        private zone: NgZone,
        private gridsterReflowService: GridsterReflowService,
        private facade: DpDashboardFacade,
        private elRef: ElementRef,
        public gridsterOptionsService: GridsterOptionsService,
        @Inject(DATA_MORPH_FEATURE_TOGGLE) private featureToggle: DataMorphFeatureToggleService,
        @Inject(DATA_MORPH_AUTH) public auth: DataMorphAuthService
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.edit) {
            this.gridsterOptions = {
                ...this.gridsterOptions,
                resizable: true,
            };
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes.group?.isFirstChange()) {
            this.gridsterResize?.gridsterComponent.reload();
        }
    }

    ngAfterContentChecked() {
        // overridden ngbpanel method
    }

    trackByWidget: TrackByFunction<number> = (_: number, widget: number) => widget;

    handleSaveGroup(group: DashboardGroupState) {
        if ([DataMorph.DashboardGroupVisibility.SINGLE, DataMorph.DashboardGroupVisibility.HIDE_HEADER].includes(group.definition.type)) {
            this.accordion.expand(this.id);
        }

        this.api.updateGroup(group).subscribe(() => {
            this.store.setGroup(group.id, group, this.tabId);
        });
    }

    handleDeleteWidget(widget: DataMorph.Widget) {
        this.facade.deleteWidget(widget);
    }

    handleEditWidget(widget: DataMorph.Widget) {
        this.router.navigate(['../widget-builder', widget.dashboardWidgetId], {
            relativeTo: this.route,
            state: {
                fromLink: this.router.url,
            },
        });
    }

    handleExportWidget({ dashboardWidgetId }: DataMorph.Widget) {
        this.dashboardWidgetManager.getWidget(dashboardWidgetId).subscribe(widget => {
            exportAnyWidget(widget.data, this.toaster);
        });
    }

    handleExportGroups(format: string, pdf: any = null, newExport: boolean) {
        if (newExport) {
            const orders = new Map([
                ['Application Events', 1],
                ['Delayed Response Count', 2],
                ['Server CPU rate', 3],
                ['Error Logs', 4],
            ]);

            return concat(
                ...this.dpWidgets
                    .toArray()
                    .sort((a, b) => {
                        const titleA = a.options?.chartOptions?.title;
                        const titleB = b.options?.chartOptions?.title;
                        const orderA = orders.get(titleA);
                        const orderB = orders.get(titleB);

                        if (orderA && orderB) {
                            return orderA - orderB;
                        }

                        if (titleA && titleB) {
                            return titleA.localeCompare(titleB);
                        }

                        return 0;
                    })
                    .map(widget => defer(() => widget.initWidgetLoading(format, pdf, newExport)))
            ).pipe(defaultIfEmpty([]));
        }

        return zip(
            ...this.dpWidgets.map(anyWidgetWrapper => {
                return anyWidgetWrapper.initWidgetLoading(format, pdf, newExport);
            })
        ).pipe(defaultIfEmpty([]));
    }

    handleGridsterChange(
        event: { changes: GridsterProperty[]; item: GridListItem; isInit: boolean; breakpoint: GridsterBreakpoint | null; isNew: boolean },
        widgetId: number
    ) {
        if (event.isInit || event instanceof Event || this.store.ignoreReorder) {
            return;
        }

        const { widgetsMap, options } = this.store.value;

        const gridsterSettings = options.gridsterSettings[this.group.id][widgetId];

        if (this.gridsterOptionsService.isChangeForCurrentBreakpoint(event.breakpoint)) {
            this.gridsterOptionsService.fillWidgetSizes({
                changes: event.changes,
                size: event.item,
                options: gridsterSettings,
                minSizes: widgetsMap[widgetId].breakpointMinSizes,
            });
        }

        for (const change of event.changes) {
            if (['w', 'h'].includes(change)) {
                continue;
            }

            gridsterSettings[event.breakpoint ? buildResponsiveOption(event.breakpoint, change) : change] = event.item[change];
        }

        this.store.optionsChanged$.next();
    }

    onReflow(gridsterComp: GridsterComponent) {
        this.gridsterReflowService.reflow(gridsterComp);
    }

    ngOnDestroy() {
        this.destroyGridsterMouseDown$.next();
    }

    dragging = false;
    dragInfo: any = null;

    onStart(gridster: GridsterComponent, item: GridsterItemComponent, event: any) {
        if (item.isResizing) {
            return;
        }
        this.dragging = true;
        const dragIndex = gridster.gridster.items.findIndex(x => x.itemComponent === item);
        const dragItem = gridster.gridster.items[dragIndex];
        gridster.gridster.items.splice(dragIndex, 1);
        item.$element.classList.add('gridster-item--dragging');
        this.dragInfo = {
            dragIndex,
            dragItem,
        };

        const canvases = item.$element.querySelectorAll('canvas');
        const clonedCanvases = event.item.$element.querySelectorAll('canvas');
        Array.from(clonedCanvases).forEach((canvas: HTMLCanvasElement, i) => {
            const context = canvas.getContext('2d');
            context.drawImage(canvases[i], 0, 0);
        });
    }

    onDrop(gridster: GridsterComponent, item: GridsterItemComponent, widget: any, event: any) {
        this.dragging = false;
        const breakpoint = gridster.gridster.options.breakpoint;

        const groupId = event.gridster.gridsterComponent.$element.dataset.groupId;
        const gridsterSettings = this.store.value.options.gridsterSettings[groupId];
        if (!gridsterSettings[widget]) {
            gridsterSettings[widget] = this.gridsterSettings[widget];
            delete this.gridsterSettings[widget];
        }

        gridsterSettings[widget][GridListItem.W_PROPERTY_MAP[breakpoint] || 'w'] = event.item.w;
        gridsterSettings[widget][GridListItem.H_PROPERTY_MAP[breakpoint] || 'h'] = event.item.h;
        gridsterSettings[widget][GridListItem.X_PROPERTY_MAP[breakpoint] || 'x'] = (item as any)[
            GridListItem.X_PROPERTY_MAP[breakpoint] || 'x'
        ] = event.item.x;
        gridsterSettings[widget][GridListItem.Y_PROPERTY_MAP[breakpoint] || 'y'] = (item as any)[
            GridListItem.Y_PROPERTY_MAP[breakpoint] || 'y'
        ] = event.item.y;

        for (const rwdProp of ['wSm', 'hSm', 'wMd', 'hMd', 'wLg', 'hLg', 'wXl', 'hXl']) {
            if (event.item.itemPrototype.hasOwnProperty(rwdProp)) {
                gridsterSettings[widget][rwdProp] = event.item.itemPrototype[rwdProp];
            }
        }

        if (gridster.gridster === event.gridster) {
            // drop to the same grid
            const dragItem = this.dragInfo.dragItem;
            setTimeout(() => {
                gridster.gridster.items.splice(this.dragInfo.dragIndex, 0, dragItem);
                item.$element.classList.remove('gridster-item--dragging');
            });
        } else {
            const fromWidgets = (gridster.gridster as any)['widgets'] as any[];
            const removingIndex = fromWidgets.findIndex(w => w === widget);
            fromWidgets.splice(removingIndex, 1);
            event.gridster.widgets.push(widget);
            this.api
                .updateWidgetOnDashboard({
                    ...this.store.value.widgetsMap[widget],
                    groupId: +groupId,
                })
                .subscribe();
        }

        item.$element.classList.add('no-transition');
        setTimeout(() => {
            item.$element.classList.remove('no-transition');
        }, 100);
        this.store.optionsChanged$.next();
    }

    onCancel(gridster: GridsterComponent, item: GridsterItemComponent, event: any) {
        if (gridster.gridster !== event.gridster) {
            return;
        }
        this.dragging = false;

        gridster.gridster.items.splice(this.dragInfo.dragIndex, 0, this.dragInfo.dragItem);
        gridster.gridster.gridList.pullItemsToLeft();
        gridster.gridster.render();
        item.$element.classList.remove('gridster-item--dragging');
    }
}
