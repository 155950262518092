import { Injectable, Injector, TemplateRef } from '@angular/core';
import { NavigationStart, Router, UrlSegment } from '@angular/router';
import { NgbModal, NgbModalRef, Placement } from '@ng-bootstrap/ng-bootstrap';
import { Tour, TourStep } from './tour';
import { TourDialogComponent } from './tour-dialog.component';
import { InteractiveTourAnchorDirective } from './interactive-tour-anchor-directive';
import { filter, first } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteractiveTourService {
    private activeTour: Tour;

    private currentStep: string;

    private modalRef: NgbModalRef;

    private anchors: { [anchor: string]: InteractiveTourAnchorDirective } = {};

    private template: TemplateRef<any>;

    private anchorRegister$ = new Subject();

    private anchorUnregister$ = new Subject();

    private overlay: HTMLDivElement;

    constructor(private modalService: NgbModal, private inj: Injector, private router: Router) {}

    startTour(tour: Tour): void {
        this.activeTour = tour;
        this.goToStepOf(tour.initialStep);

        this.modalRef = this.modalService.open(TourDialogComponent, { ariaLabelledBy: 'modal-basic-title', injector: this.inj });

        const activeStep = tour.steps[tour.initialStep];
        const { componentInstance } = this.modalRef;
        componentInstance.title = activeStep.title;
        componentInstance.content = activeStep.content;
        componentInstance.nextClicked.pipe(first()).subscribe(() => this.next());

        this.template = componentInstance.template;
    }

    next(): void {
        if (this.modalRef) {
            this.modalRef.dismiss();
            this.modalRef = null;
        }

        const step = this.activeTour.steps[this.currentStep || this.activeTour.initialStep];
        const nextStep = typeof step.next === 'function' ? step.next(this.anchors[step.anchor].context) : step.next;
        if (!nextStep) {
            this.end();
            return;
        }
        this.goToStepOf(nextStep);
    }

    prev(): void {
        const prevStep = this.activeTour.steps[this.currentStep].prev;
        if (prevStep === this.activeTour.initialStep) {
            this.startTour(this.activeTour);
            return;
        }

        this.goToStepOf(prevStep);
    }

    end(): void {
        this.hideStep(this.activeTour.steps[this.currentStep]);
        this.modalRef = this.modalService.open(TourDialogComponent, { ariaLabelledBy: 'modal-basic-title', injector: this.inj });
        const { componentInstance } = this.modalRef;
        componentInstance.content = 'The end';
        componentInstance.next = null;
    }

    register(anchorId: string, anchor: InteractiveTourAnchorDirective): void {
        if (!anchorId) {
            return;
        }
        if (this.anchors[anchorId]) {
            throw new Error('anchorId ' + anchorId + ' already registered!');
        }
        this.anchors[anchorId] = anchor;
        this.anchorRegister$.next(anchorId);
    }

    unregister(anchorId: string): void {
        if (!anchorId) {
            return;
        }
        delete this.anchors[anchorId];
        this.anchorUnregister$.next(anchorId);
        this.anchorRegister$.next(this.anchors);
    }

    private showStep(step: TourStep): void {
        const doShow = () => {
            if (step.selector && typeof step.selector === 'function') {
                this.anchors[step.anchor].el = step.selector(this.anchors[step.anchor].hostEl);
            }

            this.anchors[step.anchor].showTourStep(
                {
                    title: step.title,
                    content: step.content as string,
                    back: !step.hideActions && this.prev.bind(this),
                    next: !step.hideActions && this.next.bind(this),
                },
                this.template
            );
            if (typeof step.initFn === 'function') {
                step.initFn(this.anchors[step.anchor].context)
                    .pipe(first())
                    .subscribe(nextStep => {
                        this.goToStepOf(nextStep);
                    });
            }
        };

        if (step.anchor && this.anchors[step.anchor]) {
            doShow();
        } else {
            this.anchorRegister$
                .pipe(
                    filter(anchorId => anchorId === step.anchor),
                    first()
                )
                .subscribe(() => {
                    setTimeout(() => {
                        doShow();
                    }, 250);
                });
        }
    }

    private hideStep(step: TourStep): void {
        if (!step) {
            return;
        }
        const anchor = this.anchors[step.anchor];
        if (!anchor) {
            return;
        }
        anchor.hideTourStep();
    }

    private goToStepOf(step: string): void {
        const anchorPromise = this.anchorRegister$.pipe(first()).toPromise();
        let navigatePromise: Promise<boolean> = <Promise<boolean>>anchorPromise;
        if (this.activeTour.steps[step].route && typeof this.activeTour.steps[step].route === 'string') {
            navigatePromise = this.router.navigateByUrl(this.activeTour.steps[step].route).then(() => <Promise<boolean>>anchorPromise);
        } else {
            this.setCurrentStep(step);
        }
        navigatePromise.then((navigated: any) => {
            console.log('navigated', step, this.activeTour.steps[step], navigated);
            if (navigated !== false) {
                if (navigated.hasOwnProperty(this.activeTour.steps[step].anchor)) {
                    setTimeout(() => this.setCurrentStep(step), 100);
                } else {
                    if (navigated.hasOwnProperty(this.activeTour.steps[step].anchor)) {
                        this.setCurrentStep(step);
                    } else {
                        setTimeout(() => this.setCurrentStep(step), 2000);
                    }
                }
            }
        });
    }

    private setCurrentStep(step: string): void {
        const currentStep = this.currentStep;
        this.hideStep(this.activeTour.steps[currentStep]);
        this.currentStep = step;

        if (!this.activeTour.steps[step].prev) {
            this.activeTour.steps[step].prev = currentStep;
        }

        if (step !== this.activeTour.initialStep) {
            this.showStep(this.activeTour.steps[step]);
            this.router.events
            .pipe(
                filter(event => event instanceof NavigationStart),
                first()
            )
            .subscribe(() => this.tryToHideStep());
        }
    }

    private tryToHideStep() {
        if (this.currentStep && this.currentStep.hasOwnProperty('route')) {
            this.hideStep(this.activeTour.steps[this.currentStep]);
        }
    }
}

export interface TourStepOption {
    stepId?: string;
    anchorId?: string;
    title?: string;
    content?: string;
    hasAccess?: boolean;
    route?: string;
    nextStep?: number | string;
    prevStep?: number | string;
    preventScrolling?: boolean;
    prevBtnTitle?: string;
    nextBtnTitle?: string;
    endBtnTitle?: string;
    pauseBtnTitle?: string;
    placement?:
        | Placement
        | 'after'
        | 'after-top'
        | 'after-bottom'
        | 'top-after'
        | 'top-before'
        | 'bottom-after'
        | 'bottom-before'
        | 'before'
        | 'before-top'
        | 'before-bottom'
        | 'below'
        | 'above';
    back?: () => void;
    next?: () => void;
}
