import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { GroupByTableComponent } from '@app/shared/components/query-builder/qb-model/components/actions/components/group-by-table/group-by-table.component';
import { NgModule } from '@angular/core';
import { ActionsComponent } from '@app/shared/components/query-builder/qb-model/components/actions/actions.component';
import { DebugVariablesModule, ExtensionsModule, WidgetLibraryModule } from 'data-processor';
import { FilterItemComponent } from '@app/shared/components/query-builder/qb-visualization/components/mapping-container/filter-item/filter-item.component';
import { EditorComponent } from '@app/shared/components/query-builder/qb-model/components/editor/editor.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { JoinsTableComponent } from '@app/shared/components/query-builder/qb-model/components/actions/components/join-table/joins-table.component';
import { SelectionTableComponent } from '@app/shared/components/query-builder/qb-model/components/actions/components/selection-table/selection-table.component';
import { AddItemsComponent } from '@app/shared/components/query-builder/qb-model/components/editor/add-items/add-items.component';
import { WidgetViewerComponent } from '@app/shared/components/query-builder/qb-visualization/components/widget-viewer/widget-viewer.component';
import { FieldItemComponent } from '@app/shared/components/query-builder/qb-visualization/components/mapping-container/field-item/field-item.component';
import { DatasetItemsComponent } from '@app/shared/components/query-builder/qb-visualization/components/dataset-items/dataset-items.component';
import { QbValidationComponent } from '@app/shared/components/query-builder/qb-validation/qb-validation.component';
import { QBModelComponent } from '@app/shared/components/query-builder/qb-model/qb-model.component';
import { CKEditorModule } from 'ngx-ckeditor';
import { QbDataComponent } from '@app/shared/components/query-builder/qb-data/qb-data.component';
import { MappingContainerComponent } from '@app/shared/components/query-builder/qb-visualization/components/mapping-container/mapping-container.component';
import { SortTypeDropdownComponent } from '@app/shared/components/query-builder/qb-model/components/sort-type-dropdown/sort-type-dropdown.component';
import { OrderByTableComponent } from '@app/shared/components/query-builder/qb-model/components/actions/components/order-by-table/order-by-table.component';
import { WhereTableComponent } from '@app/shared/components/query-builder/qb-model/components/actions/components/where-table/where-table.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ExpressionBuilderModule } from '@app/shared/components/query-builder/qb-model/components/actions/components/expression-builder/expression-builder.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UiKitModule } from '@dagility-ui/kit';
import { SharedComponentsModule } from '@dagility-ui/shared-components';
import { VocFormDirective } from '@app/shared/components/query-builder/directives/voc-form.directive';
import { DrilldownEditorComponent } from '@app/shared/components/query-builder/qb-model/components/editor/drilldown-editor/drilldown-editor.component';
import { QBVisualizationComponent } from './qb-visualization/qb-visualization.component';
import { GraphTreeComponent } from './qb-model/components/graph-tree/graph-tree.component';
import { RelationEditorComponent } from './qb-model/components/actions/components/join-table/relation-editor/relation-editor.component';
import { QbFiltersComponent } from './qb-filters/qb-filters.component';
import { SaveGraphModalComponent } from './qb-model/components/editor/save-graph-modal/save-graph-modal.component';
import { ExternalParamsComponent } from './qb-visualization/components/external-params/external-params.component';
import { EditParamsModalComponent } from './qb-visualization/components/external-params/edit-params-modal/edit-params-modal.component';
import { IconRemoterComponent } from './consts/icon-remoter/icon-remoter.component';
import {GridsterOptionsService} from "data-processor/lib/widget-library/dashboard/services/gridster/gridster-options.service";
import {QUERY_BUILDER_MODEL_GRAPH_SERVICE} from "data-processor/tokens";
import {ModelGraphService} from "@app/shared/components/query-builder/services/model-graph.service";

@NgModule({
    declarations: [
        QBModelComponent,
        FilterItemComponent,
        WidgetViewerComponent,
        QBVisualizationComponent,
        GraphTreeComponent,
        EditorComponent,
        ActionsComponent,
        AddItemsComponent,
        DatasetItemsComponent,
        MappingContainerComponent,
        FieldItemComponent,
        SelectionTableComponent,
        QbDataComponent,
        GroupByTableComponent,
        SortTypeDropdownComponent,
        JoinsTableComponent,
        OrderByTableComponent,
        WhereTableComponent,
        QbValidationComponent,
        VocFormDirective,
        RelationEditorComponent,
        QbFiltersComponent,
        DrilldownEditorComponent,
        SaveGraphModalComponent,
        ExternalParamsComponent,
        EditParamsModalComponent,
        IconRemoterComponent,
    ],
    imports: [
        CommonModule,
        ColorPickerModule,
        RouterModule,
        DebugVariablesModule,
        NgbTooltipModule,
        UiKitModule,
        ExtensionsModule,
        CKEditorModule,
        SharedComponentsModule,
        ExpressionBuilderModule,
        WidgetLibraryModule,
        DragDropModule,
    ],
    exports: [
        QBModelComponent,
        QbDataComponent,
        QBVisualizationComponent,
        QbValidationComponent,
        VocFormDirective,
        QbFiltersComponent,
        DrilldownEditorComponent,
        IconRemoterComponent,
    ],
    providers: [
        GridsterOptionsService,
        {provide: QUERY_BUILDER_MODEL_GRAPH_SERVICE, useExisting: ModelGraphService}
    ]
})
export class QueryBuilderLibraryModule {}
