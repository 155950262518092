import { inject, Type } from '@angular/core';

import { DATA_MORPH_FEATURE_TOGGLE } from 'data-processor/tokens';
import { DropdownFilter, WidgetFilter, WidgetFilterType } from 'data-processor';

import { DropdownFilterComponent } from './dropdown-filter/dropdown-filter.component';
import { MultiselectDropdownFilterComponent } from './multiselect-dropdown-filter/multiselect-dropdown-filter.component';
import { TriggerFilterComponent } from './trigger-filter/trigger-filter.component';

export function globalFilterFactory(filter: WidgetFilter): Type<unknown> {
    const ft = inject(DATA_MORPH_FEATURE_TOGGLE);

    if (filter.type === WidgetFilterType.DROPDOWN) {
        if ((filter as DropdownFilter).multiple) {
            return MultiselectDropdownFilterComponent;
        }

        return DropdownFilterComponent;
    }

    if (filter.type === WidgetFilterType.CHECKBOX) {
        return TriggerFilterComponent;
    }

    return undefined;
}
