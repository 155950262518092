<div class="modal-header">
    <h2 class="modal-title mt-0">{{ step === 'selection' ? 'Select Projects and Components' : 'Publish Source File Changes' }}</h2>
    <button type="button" class="close" data-test-id="cross-tick-btn" aria-describedby="modal-title" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body d-flex flex-column overflow-auto">
    <div [hidden]="step === 'commit'">
        <perfect-scrollbar class="pr-3 scroll-container wrapper-max-height-100vh-310">
            <lib-stream-wrapper [dataStream$]="publishItems$">
                <p class="info-box p-2">
                    <fa-icon icon="facExclamationCircle"></fa-icon>
                    The source file changes will be published to the selected projects and components that were created from this template.
                </p>
                <ng-template contentTemplate let-publishItems="data">
                    <div class="mb-2" *libLet="selection.selected.length === componentIds.length as allSelected">
                        <div class="right-col" *libLet="!allSelected && !!selection.selected.length as undefinedState">
                            <checkbox
                                [undefinedState]="undefinedState"
                                [value]="undefinedState ? undefined : allSelected"
                                (change)="toggleAll($event)"
                                >Select All</checkbox
                            >
                        </div>
                    </div>
                    <ngb-accordion class="custom-accordion" #accordion="ngbAccordion">
                        <ngb-panel *ngFor="let project of publishItems" [id]="'panel_' + project.value">
                            <ng-template ngbPanelHeader>
                                <div class="d-flex align-items-center">
                                    <button [ngbPanelToggle] class="header-button btn btn-link"></button>
                                    <div class="col cursor-pointer" (click)="accordion.toggle('panel_' + project.value)">
                                        <div class="h3 mb-0">{{ project.label }}</div>
                                        <div class="body2 secondary-text-and-icons">
                                            {{ project.items?.length || 0 }} Component{{ project.items?.length === 1 ? '' : 's' }}
                                        </div>
                                    </div>
                                    <ng-container *libLet="getCountOfProjectComponentsSelected(project) as selectedComponentsCount">
                                        <div
                                            class="right-col"
                                            *libLet="selectedComponentsCount === project.items.length as allProjectComponentsSelected"
                                        >
                                            <checkbox
                                                *libLet="!allProjectComponentsSelected && !!selectedComponentsCount as undefinedState"
                                                [undefinedState]="undefinedState"
                                                [value]="undefinedState ? undefined : allProjectComponentsSelected"
                                                (change)="toggleProjectSelection(project, $event)"
                                                >Select Project</checkbox
                                            >
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <checkbox
                                    *ngFor="let component of project.items"
                                    class="component-item"
                                    [value]="selection.isSelected(component.value)"
                                    (change)="selection.toggle(component.value)"
                                >
                                    <span class="font-10px font-weight-bold">{{ component.label }}</span></checkbox
                                >
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                </ng-template>
            </lib-stream-wrapper>
        </perfect-scrollbar>
    </div>
    <ng-container *ngIf="step === 'commit'" [formGroup]="form">
        <div class="h3 mb-2">Source file changes will be published to the components of the following projects:</div>
        <perfect-scrollbar>
            <div *ngFor="let project of selectedProjects" class="body1 mb-2 project-label">
                {{ project.label }}
                <ng-container *ngFor="let componentName of selectedComponentsPerProject[project.value]">
                    <li class="ml-2 secondary-text-and-icons">
                        {{ componentName }}
                    </li>
                </ng-container>
            </div>
        </perfect-scrollbar>
        <lib-dropdown class="d-block mt-4" [label]="'Publish Changes to'" formControlName="pushToRepo" [items]="items"> </lib-dropdown>
        <lib-textarea *ngIf="form.value.pushToRepo" [label]="'Commit Message'" formControlName="commit" class="d-block my-2"></lib-textarea>

        <div class="info-box mt-4 p-2 d-flex align-items-center">
            <fa-icon icon="facExclamationCircle"></fa-icon>
            <div class="ml-2">
                <ng-container *ngIf="!form.value.pushToRepo">
                    Choosing this option will publish changes made in the template and make them available on all selected project
                    components; an additional step is required to publish the source code changes to the repositories triggered on the
                    project. To do this, navigate to Projects, then Components, followed by Source Files, and click on Publish.
                </ng-container>
                <ng-container *ngIf="form.value.pushToRepo">
                    The process of publishing source file changes requires time. You can start publishing the updates and continue on with
                    other activities while you wait.
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>
<div class="modal-footer">
    <ng-container *ngIf="step === 'selection'">
        <div *ngIf="selection.hasValue()" class="info1 mr-2">
            <span class="secondary-text-and-icons">Selected: </span>{{ selectedProjects.size }} Project{{
                selectedProjects.size === 1 ? '' : 's'
            }}, {{ selection.selected.length }} Component{{ selection.selected.length === 1 ? '' : 's' }}
        </div>
        <button type="button" class="btn btn-secondary mr-3" data-test-id="cancel-btn" (click)="activeModal.dismiss()">Cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="selection.isEmpty()" (click)="step = 'commit'">
            Next
        </button>
    </ng-container>
    <ng-container *ngIf="step === 'commit'">
        <button type="button" class="btn btn-secondary mr-3" (click)="step = 'selection'">Back</button>
        <ng-container *ngIf="{ publishing: publishing$ | async } as vm">
            <button
                type="button"
                class="btn btn-primary"
                [btnWithLoader]="vm.publishing"
                [disabled]="vm.publishing || !form.valid"
                (click)="publish()"
            >
                Publish
            </button>
        </ng-container>
    </ng-container>
</div>
