<div *ngFor="let item of allTemplates | keyvalue" [style.grid-area]="item.key">
    <perfect-scrollbar
        *ngIf="!item.value[0].tilesTemplate; else scrollContainer"
        class="p-scroll"
        [config]="item.key === 'top' || item.key === 'bottom' ? horizontalScrollbarConfig : verticalScrollbarConfig"
        [ngClass]="{
            'horizontal-scrollbar': item.key === 'top' || item.key === 'bottom',
            'vertical-scrollbar': item.key === 'right' || item.key === 'left'
        }"
    >
        <span
            *ngFor="let subItem of item.value; index as i"
            [ngClass]="{
                'cursor-pointer': subItem.targetDrilldown,
                'd-inline-block': (item.key === 'top' || item.key === 'bottom') && item.value.length > 1,
                'ml-2': (item.key === 'top' || item.key === 'bottom') && i !== 0,
                'mt-2': (item.key === 'right' || item.key === 'left') && i !== 0
            }"
            (click)="templateClickedHandler(subItem)"
            [innerHTML]="subItem.template | dpWidgetTemplate: placeholders"
        ></span>
    </perfect-scrollbar>
    <ng-template #scrollContainer>
        <dp-widget-scroll-container
            [placeholders]="placeholders"
            [position]="item.value[0].position"
            (drilldown)="templateClickedHandler($event)"
        ></dp-widget-scroll-container>
    </ng-template>
</div>

<div
    class="widget"
    [ngClass]="{ 'mt-4': isComplexPart && placeholders?.tiles?.length, 'mt-3': !isComplexPart && placeholders?.tiles?.length }"
>
    <ng-content></ng-content>
</div>
