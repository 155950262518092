import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedComponentsModule } from '@dagility-ui/shared-components';
import { UiKitModule } from '@dagility-ui/kit';

import { MergeRequestCommitComponent } from './merge-request-commit/merge-request-commit.component';
import { MergeRequestInfoComponent } from './merge-request-info.component';
import { MergeRequestApproversComponent } from './merge-request-approver/merge-request-approvers.component';
import { DividerComponent } from './divider/divider.component';
import { MergeRequestStatusComponent } from './merge-request-status/merge-request-status.component';

@NgModule({
    declarations: [MergeRequestCommitComponent, MergeRequestInfoComponent, MergeRequestApproversComponent, DividerComponent, MergeRequestStatusComponent],
    exports: [MergeRequestInfoComponent],
    imports: [CommonModule, SharedComponentsModule, UiKitModule],
})
export class MergeRequestInfoModule {}
