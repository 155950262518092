import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ProcessorMonitoringState } from '../../processor-monitoring-state.service';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { ProcessorMonitoringService } from '../../processor-monitoring.service';
import { CustomIcon, facClose } from '@dagility-ui/shared-components/icons';
import { validateFormAndDisplayErrors } from '@dagility-ui/kit';

@Component({
    selector: 'dp-job-definition-start-form',
    templateUrl: './job-definition-start-form.component.html',
    styleUrls: ['./job-definition-start-form.component.scss'],
})
export class JobDefinitionStartFormComponent implements OnInit {
    job: any;
    form: FormGroup;
    params: any;
    paramsLoaded = true;

    icons: Record<string, IconDefinition | CustomIcon> = {
        facClose: facClose,
    };

    constructor(
        public modalService: NgbActiveModal,
        private fb: FormBuilder,
        private api: ProcessorMonitoringService,
        public pmState: ProcessorMonitoringState,
        @Inject('templateProvider') public templateProvider: Record<string, TemplateRef<any>>
    ) {}

    ngOnInit() {
        this.paramsLoaded = false;
        this.initForm();
        this.api.getJobDefinition(this.job.id.toString()).subscribe(resp => {
            if (resp.variables) {
                this.params = resp.variables;
                this.form.get('configuration').setValue(this.params);
            }
            this.paramsLoaded = true;
        });
    }

    startJob() {
        if (this.form.get('configuration').valid) {
            let paramsToStart = {};
            if (this.params) {
                for (const config of this.form.get('configuration').value) {
                    const param = {
                        [config.name]: config.value,
                    };
                    paramsToStart = Object.assign(paramsToStart, param);
                }
            }
            this.pmState.forceRun(this.job.instanceName, paramsToStart, this.job.toolId);
            this.modalService.close();
        } else {
            validateFormAndDisplayErrors(this.form);
        }
    }

    initForm(): void {
        this.form = this.fb.group({
            configuration: [[]],
        });
    }
}
