import { ElementRef, InjectionToken, QueryList, Type } from '@angular/core';

import { WidgetType } from '../../models/any-widget.model';
import {
    AccordionCSV,
    BarLineCSV,
    BoxplotCSV,
    ComplexCSV,
    GridCSV,
    GridTabCSV,
    MultipleYAxisCSV,
    NestedCSV,
    PieDoughnutCSV,
    ProgressCSV,
    TileCSV,
    TwoDimensionalGridCSV,
} from './widget.csv';
import { CustomHTMLChartPDF, PDFExporter } from './widget.pdf';
import { BoxplotExcel, ExcelExporter, ProgressExcel, TileExcel } from './widget.excel';
import { AnyWidgetChartModel } from 'data-processor/lib/widget-library/widget-builder/services/query-to-options.mapper';
import { AnyWidgetComponent } from 'data-processor/lib/widget-library/widget-builder/components/widget/any-widget/any-widget.component';

export type EXPORT_DATA_TYPE = 'XLSX' | 'CSV' | 'PDF';

export interface WidgetDataExporter {
    add?: (
        chartModel: AnyWidgetChartModel,
        title: string,
        elementRef: ElementRef<HTMLElement>,
        filterComponents: any,
        complexWidgets: QueryList<AnyWidgetComponent>
    ) => void;
    export(id: any, widgetType: WidgetType, filterComponents: any, dashboardExport?: string): Promise<any>;
}

export type WidgetExporterFactory = Partial<{ [key in WidgetType]: Partial<{ [type in EXPORT_DATA_TYPE]: Type<WidgetDataExporter> }> }>;

export const widgetExporterFactory: WidgetExporterFactory = {
    [WidgetType.TABLE]: {
        CSV: GridCSV,
        XLSX: ExcelExporter,
    },
    [WidgetType.TWO_DIMENSIONAL_TABLE]: {
        CSV: TwoDimensionalGridCSV,
        XLSX: ExcelExporter,
        PDF: PDFExporter,
    },
    [WidgetType.STACKED_BAR_CHART]: {
        CSV: BarLineCSV,
        XLSX: ExcelExporter,
        PDF: PDFExporter,
    },
    [WidgetType.BAR_CHART]: {
        CSV: BarLineCSV,
        XLSX: ExcelExporter,
        PDF: PDFExporter,
    },
    [WidgetType.DOUGHNUT_CHART]: {
        CSV: PieDoughnutCSV,
        XLSX: ExcelExporter,
        PDF: PDFExporter,
    },
    [WidgetType.PIE_CHART]: {
        CSV: PieDoughnutCSV,
        XLSX: ExcelExporter,
        PDF: PDFExporter,
    },
    [WidgetType.LINE_CHART]: {
        CSV: BarLineCSV,
        XLSX: ExcelExporter,
        PDF: PDFExporter,
    },
    [WidgetType.SCATTER_CHART]: {
        CSV: BarLineCSV,
        XLSX: ExcelExporter,
        PDF: PDFExporter,
    },
    [WidgetType.NESTED_PIE_CHART]: {
        CSV: NestedCSV,
        XLSX: ExcelExporter,
        PDF: PDFExporter,
    },
    [WidgetType.SUNBURST_CHART]: {
        CSV: NestedCSV,
        XLSX: ExcelExporter,
        PDF: PDFExporter,
    },
    [WidgetType.TREEMAP]: {
        CSV: NestedCSV,
        XLSX: ExcelExporter,
        PDF: PDFExporter,
    },
    [WidgetType.COMPLEX]: {
        CSV: ComplexCSV,
        XLSX: ExcelExporter,
        PDF: PDFExporter,
    },
    [WidgetType.BOXPLOT]: {
        CSV: BoxplotCSV,
        PDF: PDFExporter,
        XLSX: BoxplotExcel,
    },
    [WidgetType.TILE_CHART]: {
        CSV: TileCSV,
        PDF: CustomHTMLChartPDF,
        XLSX: TileExcel,
    },
    [WidgetType.PROGRESS]: {
        CSV: ProgressCSV,
        PDF: CustomHTMLChartPDF,
        XLSX: ProgressExcel,
    },
    [WidgetType.TABLE_WITH_TABS]: {
        CSV: GridTabCSV,
        XLSX: ExcelExporter,
    },
    [WidgetType.METRIC_TILE]: {
        PDF: PDFExporter
    },
    [WidgetType.ACCORDION]: {
        CSV: AccordionCSV,
    },
    [WidgetType.MULTIPLE_Y_AXIS]: {
        CSV: MultipleYAxisCSV,
    },
};

export const WIDGET_EXPORTER = new InjectionToken<WidgetExporterFactory>('WidgetExporter', {
    providedIn: 'root',
    factory: () => widgetExporterFactory,
});
