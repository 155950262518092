import { NgSelectComponent } from '@ng-select/ng-select';

import { FieldTypeFunctionsMap } from './utils';

export const addTagTypeMap: FieldTypeFunctionsMap = {
    BOOLEAN: addTagBoolean,
    NUMBER: addTagNumber,
    STRING: addToDropdownIfNotExist,
    GROOVY: addToDropdownIfNotExist,
    CREDENTIAL: addToDropdownIfNotExist,
    STRING_MULTILINE: addToDropdownIfNotExist
};

export const checkTypingFunctions: FieldTypeFunctionsMap = {
    BOOLEAN: (item: string) => /^(true|false)$/.test(item),
    NUMBER: (item: string) => !Number.isNaN(Number(item)),
    STRING: () => true,
    STRING_MULTILINE: () => true,
    CREDENTIAL: () => true,
    GROOVY: () => true
};

function addTagNumber(this: NgSelectComponent, value: string) {
    if (!checkTypingFunctions.NUMBER(value)) {
        return null;
    } else {
        return addToDropdownIfNotExist.call(this, value);
    }
}

function addTagBoolean(this: NgSelectComponent, value: string) {
    if (!checkTypingFunctions.BOOLEAN(value)) {
        return null;
    } else {
        return addToDropdownIfNotExist.call(this, value);
    }
}

function addToDropdownIfNotExist(this: NgSelectComponent, value: string) {
    let result: any = {};
    result[this.bindValue] = value;
    result[this.bindLabel] = value;

    const item = this.items.find(el => el[this.bindValue] === result[this.bindValue]);
    result = !item ? result : null;
    return result;
}
