import { Component, ElementRef, ViewChild } from '@angular/core';
import { SliderItem } from '@dagility-ui/kit';
import { SreGanttService } from '../services/sre-gantt.service';
import { SreGanttTableComponent } from './sre-gantt-table/sre-gantt-table.component';

const SLIDER_ITEMS = [
    {
        title: 'Tags',
    },
    {
        title: 'Release',
    },
];

@Component({
    selector: 'dp-sre-gantt-side-menu',
    templateUrl: './sre-gantt-side-menu.component.html',
    styleUrls: ['./sre-gantt-side-menu.component.scss'],
})
export class SreGanttSideMenuComponent {
    @ViewChild(SreGanttTableComponent, { read: ElementRef }) tableEl: ElementRef;

    readonly sliderItems: SliderItem[] = SLIDER_ITEMS;

    constructor(public service: SreGanttService) {}

    handleSelectedTab(value: number) {
        this.tableEl.nativeElement.style.animation = 'none';
        setTimeout(() => (this.tableEl.nativeElement.style.animation = ''));
        this.service.changeTab(value);
    }
}
