<div class="modal-main-container" libOutsideClick>
    <div class="w-100 content-container">
        <div class="modal-header border-bottom-0">
            <div class="d-block">
                <h2 class="modal-title mt-0" id="modal-basic-title">Compare Versions</h2>
            </div>

            <button tabindex="-1" type="button" class="close" aria-label="Close" (click)="handleCloseClick()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <ng-container *ngIf="scripts$ | obsWithStatus | async as scripts">
            <ng-container *ngIf="!scripts.loading; else loadingTemplate">
                <div class="row no-gutters">
                    <div class="col-6">
                        <h3 class="mt-0 pl-25px pt-25px">{{leftPanelTitle}}</h3>
                    </div>
                    <div class="col-6">
                        <h3 class="mt-0 pt-25px" [style.padding-left.px]="45">{{rightPanelTitle}}</h3>
                    </div>
                </div>

                <div class="mt-5px mx-25px mb-35px">
                    <codemirror-merge-editor
                        [original]="scripts.value.pipelineContent || ''"
                        mode="text/x-groovy"
                        [readonly]="true"
                        [modified]="scripts.value.comparedPipelineContent || ''"
                    >
                    </codemirror-merge-editor>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>

<ng-template #loadingTemplate>
    <div class="d-inline-flex justify-content-center align-items-center w-100" style="height: 100px!important;">
        <lib-spinner></lib-spinner>
    </div>
</ng-template>
