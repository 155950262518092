import { CustomIcon } from '@dagility-ui/shared-components/icons';

export const facStop: CustomIcon
    = {
    prefix: 'fac',
    iconName: 'facStop',
    icon: [
        14, // width
        14, // height
        [], // ligatures
        '', // unicode (if relevant)
        'M8 2C7.21207 2 6.43185 2.15519 5.7039 2.45672C4.97595 2.75825 4.31451 3.20021 3.75736 3.75736C2.63214 4.88258 2 6.4087 2 8C2 9.5913 2.63214 11.1174 3.75736 12.2426C4.31451 12.7998 4.97595 13.2417 5.7039 13.5433C6.43185 13.8448 7.21207 14 8 14C9.5913 14 11.1174 13.3679 12.2426 12.2426C13.3679 11.1174 14 9.5913 14 8C14 7.21207 13.8448 6.43185 13.5433 5.7039C13.2417 4.97595 12.7998 4.31451 12.2426 3.75736C11.6855 3.20021 11.0241 2.75825 10.2961 2.45672C9.56815 2.15519 8.78793 2 8 2ZM5.32122 1.53284C6.17049 1.18106 7.08075 1 8 1C8.91925 1 9.8295 1.18106 10.6788 1.53284C11.5281 1.88463 12.2997 2.40024 12.9497 3.05025C13.5998 3.70026 14.1154 4.47194 14.4672 5.32122C14.8189 6.17049 15 7.08075 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15C7.08075 15 6.17049 14.8189 5.32122 14.4672C4.47194 14.1154 3.70026 13.5998 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C3.70026 2.40024 4.47194 1.88463 5.32122 1.53284Z M5.99997 6V10H9.99997V6', // svg path data
    ],
};

export const facStart: CustomIcon = {
    prefix: 'fac',
    iconName: 'facStop',
    icon: [
        14, // width
        14, // height
        [], // ligatures
        '', // unicode (if relevant)
        'M8 2C7.21207 2 6.43185 2.15519 5.7039 2.45672C4.97595 2.75825 4.31451 3.20021 3.75736 3.75736C2.63214 4.88258 2 6.4087 2 8C2 9.5913 2.63214 11.1174 3.75736 12.2426C4.31451 12.7998 4.97595 13.2417 5.7039 13.5433C6.43185 13.8448 7.21207 14 8 14C9.5913 14 11.1174 13.3679 12.2426 12.2426C13.3679 11.1174 14 9.5913 14 8C14 7.21207 13.8448 6.43185 13.5433 5.7039C13.2417 4.97595 12.7998 4.31451 12.2426 3.75736C11.6855 3.20021 11.0241 2.75825 10.2961 2.45672C9.56815 2.15519 8.78793 2 8 2ZM5.32122 1.53284C6.17049 1.18106 7.08075 1 8 1C8.91925 1 9.8295 1.18106 10.6788 1.53284C11.5281 1.88463 12.2997 2.40024 12.9497 3.05025C13.5998 3.70026 14.1154 4.47194 14.4672 5.32122C14.8189 6.17049 15 7.08075 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15C7.08075 15 6.17049 14.8189 5.32122 14.4672C4.47194 14.1154 3.70026 13.5998 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C3.70026 2.40024 4.47194 1.88463 5.32122 1.53284Z M6.5 11L10.5 8L6.5 5V11Z', // svg path data
    ],
};


export const facLogs: CustomIcon = {
    prefix: 'fac',
    iconName: 'facLogs',
    icon: [
        14, // width
        14, // height
        [], // ligatures
        '', // unicode (if relevant)
        'M1.00004 0.999916H12L12.0681 0.999711C12.3494 0.998435 12.5974 0.997311 12.8 1.19992C13.0026 1.40252 13.0015 1.65057 13.0002 1.93186L13 1.99992V3.99992H12V1.99992H1.00004V12.9999H12V10.9999H13V12.9999L13.0002 13.068C13.0015 13.3493 13.0026 13.5973 12.8 13.7999C12.5974 14.0025 12.3494 14.0014 12.0681 14.0001L12 13.9999H1.00004L0.931987 14.0001C0.650694 14.0014 0.402645 14.0025 0.20004 13.7999C-0.00256509 13.5973 -0.00144037 13.3493 -0.000164911 13.068L3.98611e-05 12.9999V2.01134L-0.000165301 1.94315C-0.00144562 1.66039 -0.0026132 1.40253 0.200002 1.19992C0.402607 0.997311 0.650689 0.998435 0.931987 0.999711L1.00004 0.999916ZM2.00004 8.99992H5V7.99992H2.00004V8.99992ZM2.00004 6.99992H5V5.99992H2.00004V6.99992ZM2.00004 10.9999H5.00004V9.99992H2.00004V10.9999ZM2 3.99992H5V4.99992H2V3.99992ZM10 8.99992V10.9999L14 7.49992L10 3.99992V5.99992H6V8.99992H10ZM11 6.20368L12.4814 7.49992L11 8.79615V7.99992H7V6.99992H11V6.20368Z', // svg path data
    ],
};

export const facKey: CustomIcon = {
    prefix: 'fac',
    iconName: 'facKey',
    icon: [
        14, // width
        14, // height
        [], // ligatures
        '', // unicode (if relevant)
        'M4.56641 2.46331L2.37721 4.70444C2.22039 4.86498 2.22039 5.12526 2.37721 5.2858L2.53905 5.45148C2.69587 5.61202 2.95012 5.61202 3.10694 5.45148L5.29615 3.21035C5.45297 3.04982 5.45297 2.78953 5.29615 2.629L5.1343 2.46331C4.97748 2.30277 4.72323 2.30277 4.56641 2.46331Z M8.72341 5.92941L8.97639 5.67042C9.35386 5.28607 9.56676 4.76398 9.56826 4.21896C9.56977 3.67393 9.35976 3.15062 8.98442 2.76408L6.86018 0.601795C6.48367 0.216466 5.97307 0 5.44068 0C4.90828 0 4.39769 0.216466 4.02117 0.601795L0.587853 4.11654C0.211451 4.50198 0 5.02469 0 5.56971C0 6.11473 0.211451 6.63744 0.587853 7.02288L2.70005 9.18517C3.07656 9.5705 3.58716 9.78696 4.11955 9.78696C4.65195 9.78696 5.16254 9.5705 5.53906 9.18517L5.69968 9.02073L6.57508 9.90867H7.95644V11.1419H9.43818V11.812L10.5907 13H13V10.3074L8.72341 5.92941ZM12.1969 12.1778H10.936L10.2252 11.4543V10.3033H8.7475V9.07007H6.89632L5.69165 7.83682L4.96885 8.60554C4.74297 8.83649 4.43678 8.96621 4.11754 8.96621C3.79831 8.96621 3.49212 8.83649 3.26624 8.60554L1.15405 6.4227C0.928454 6.19147 0.801739 5.87802 0.801739 5.55121C0.801739 5.2244 0.928454 4.91095 1.15405 4.67972L4.59138 1.16087C4.81726 0.929919 5.12345 0.800199 5.44269 0.800199C5.76192 0.800199 6.06811 0.929919 6.29399 1.16087L8.40618 3.32315C8.63178 3.55439 8.75849 3.86783 8.75849 4.19464C8.75849 4.52145 8.63178 4.8349 8.40618 5.06614L7.60307 5.8883L12.1969 10.6486V12.1778Z', // svg path data
    ],
};

export const facEdit: CustomIcon = {
    prefix: 'fac',
    iconName: 'facEdit',
    icon: [
        14, // width
        14, // height
        [], // ligatures
        '', // unicode (if relevant)
        'M12.6584 8.63577C12.4698 8.63577 12.3168 8.79715 12.3168 8.99628V12.1973C12.3162 12.7944 11.8579 13.2783 11.2921 13.2788H1.70788C1.14214 13.2783 0.683817 12.7944 0.68315 12.1973V2.80258C0.683817 2.20562 1.14214 1.72174 1.70788 1.72103H4.74069C4.92936 1.72103 5.08226 1.55965 5.08226 1.36052C5.08226 1.16153 4.92936 1 4.74069 1H1.70788C0.765075 1.00113 0.00106742 1.8075 0 2.80258V12.1974C0.00106742 13.1925 0.765075 13.9989 1.70788 14H11.2921C12.2349 13.9989 12.9989 13.1925 13 12.1974V8.99628C13 8.79715 12.8471 8.63577 12.6584 8.63577Z M13.1513 0.4493C12.5522 -0.149767 11.5809 -0.149767 10.9818 0.4493L4.89973 6.53079C4.85805 6.57246 4.82796 6.62413 4.81224 6.68085L4.01244 9.56807C3.97955 9.68644 4.01297 9.81321 4.0998 9.90016C4.18676 9.98697 4.31353 10.0204 4.43192 9.98764L7.31939 9.18777C7.37612 9.17206 7.42779 9.14197 7.46947 9.10029L13.5514 3.01867C14.1495 2.41921 14.1495 1.44877 13.5514 0.8493L13.1513 0.4493ZM5.64254 6.75236L10.6202 1.775L12.2255 3.38019L7.24774 8.35755L5.64254 6.75236ZM5.32188 7.39576L6.60441 8.67832L4.83035 9.1698L5.32188 7.39576ZM13.0693 2.53665L12.7077 2.89817L11.1023 1.29284L11.464 0.931324C11.7967 0.598568 12.3363 0.598568 12.6691 0.931324L13.0693 1.33132C13.4015 1.66448 13.4015 2.20363 13.0693 2.53665Z', // svg path data
    ],
};

export const facArrowUp: CustomIcon = {
    prefix: 'fac',
    iconName: 'facArrowUp',
    icon: [
        10, // width
        10, // height
        [], // ligatures
        '', // unicode (if relevant)
        'M0 4.5L0.7 5.2L4 2L4 8H5L5 2L8.3 5.2L9 4.5L4.50051 0L0 4.5Z'
    ],
};

export const facArrowDown: CustomIcon = {
    prefix: 'fac',
    iconName: 'facArrowDown',
    icon: [
        10, // width
        10, // height
        [], // ligatures
        '', // unicode (if relevant)
        'M9 3.5L8.3 2.8L5 6L5 0H4L4 6L0.7 2.8L0 3.5L4.49949 8L9 3.5Z'
    ],
};

export const facExport: CustomIcon = {
    prefix: 'fac',
    iconName: 'facExport',
    icon: [
        14, // width
        14, // height
        [], // ligatures
        '', // unicode (if relevant)
        'M2.7501 9.34998C2.97101 9.34998 3.1501 9.52906 3.1501 9.74998V12.0833C3.1501 12.2866 3.23087 12.4816 3.37465 12.6254C3.51843 12.7692 3.71343 12.85 3.91676 12.85H12.0834C12.2868 12.85 12.4818 12.7692 12.6255 12.6254C12.7693 12.4816 12.8501 12.2866 12.8501 12.0833V9.74998C12.8501 9.52906 13.0292 9.34998 13.2501 9.34998C13.471 9.34998 13.6501 9.52906 13.6501 9.74998V12.0833C13.6501 12.4988 13.485 12.8973 13.1912 13.1911C12.8974 13.4849 12.4989 13.65 12.0834 13.65H3.91676C3.50126 13.65 3.10277 13.4849 2.80896 13.1911C2.51516 12.8973 2.3501 12.4988 2.3501 12.0833V9.74998C2.3501 9.52906 2.52918 9.34998 2.7501 9.34998Z M11.1998 5.94966C11.0436 6.10587 10.7903 6.10587 10.6341 5.94966L8.00029 3.31583L5.36647 5.94966C5.21026 6.10587 4.95699 6.10587 4.80078 5.94966C4.64457 5.79345 4.64457 5.54018 4.80078 5.38397L7.71745 2.4673C7.79246 2.39229 7.8942 2.35015 8.00029 2.35015C8.10638 2.35015 8.20812 2.39229 8.28313 2.4673L11.1998 5.38397C11.356 5.54018 11.356 5.79345 11.1998 5.94966Z M8.00029 10.1501C7.77937 10.1501 7.60029 9.97106 7.60029 9.75015L7.60029 2.75015C7.60029 2.52923 7.77938 2.35015 8.00029 2.35015C8.2212 2.35015 8.40029 2.52923 8.40029 2.75015L8.40029 9.75015C8.40029 9.97106 8.2212 10.1501 8.00029 10.1501Z'
    ],
};

export const facSwap: CustomIcon = {
    prefix: 'fac',
    iconName: 'facSwap',
    icon: [
        14, // width
        14, // height
        [], // ligatures
        '', // unicode (if relevant)
        'M10.0506 8.19974C9.89438 8.04353 9.89438 7.79026 10.0506 7.63405L12.6844 5.00023L10.0506 2.36641C9.89438 2.2102 9.89438 1.95693 10.0506 1.80072C10.2068 1.64451 10.4601 1.64451 10.6163 1.80072L13.5329 4.71739C13.608 4.7924 13.6501 4.89414 13.6501 5.00023C13.6501 5.10632 13.608 5.20806 13.5329 5.28307L10.6163 8.19974C10.4601 8.35595 10.2068 8.35595 10.0506 8.19974Z M3.8501 5.00023C3.8501 4.77931 4.02918 4.60023 4.2501 4.60023L13.2501 4.60023C13.471 4.60023 13.6501 4.77932 13.6501 5.00023C13.6501 5.22114 13.471 5.40023 13.2501 5.40023L4.2501 5.40023C4.02918 5.40023 3.8501 5.22114 3.8501 5.00023Z M5.94953 7.80075C6.10574 7.95696 6.10574 8.21023 5.94953 8.36644L3.31571 11.0003L5.94953 13.6341C6.10574 13.7903 6.10574 14.0436 5.94953 14.1998C5.79332 14.356 5.54006 14.356 5.38385 14.1998L2.46718 11.2831C2.39217 11.2081 2.35003 11.1063 2.35003 11.0003C2.35003 10.8942 2.39217 10.7924 2.46718 10.7174L5.38385 7.80075C5.54006 7.64454 5.79332 7.64454 5.94953 7.80075Z M12.15 11.0003C12.15 11.2212 11.9709 11.4003 11.75 11.4003L2.75002 11.4003C2.52911 11.4003 2.35003 11.2212 2.35003 11.0003C2.35003 10.7793 2.52911 10.6003 2.75002 10.6003L11.75 10.6003C11.9709 10.6003 12.15 10.7793 12.15 11.0003Z'
    ],
};

export const facNewSet: CustomIcon = {
    prefix: 'fac',
    iconName: 'facNewSet',
    icon: [
        14, // width
        14, // height
        [], // ligatures
        '', // unicode (if relevant)
        'M12.0335 8.4502C12.3097 8.4502 12.5335 8.67405 12.5335 8.9502V15.1169C12.5335 15.393 12.3097 15.6169 12.0335 15.6169C11.7574 15.6169 11.5335 15.393 11.5335 15.1169V8.9502C11.5335 8.67405 11.7574 8.4502 12.0335 8.4502Z M8.4502 12.0335C8.4502 11.7574 8.67405 11.5335 8.9502 11.5335H15.1169C15.393 11.5335 15.6169 11.7574 15.6169 12.0335C15.6169 12.3097 15.393 12.5335 15.1169 12.5335H8.9502C8.67405 12.5335 8.4502 12.3097 8.4502 12.0335Z M1.63235 2.64329C1.7508 2.52371 1.91144 2.45652 2.07895 2.45652H4.5886L5.55324 3.91747C5.64109 4.05052 5.78899 4.13043 5.94737 4.13043H10.9211C11.0886 4.13043 11.2492 4.19762 11.3676 4.31721C11.4861 4.4368 11.5526 4.59899 11.5526 4.76812V6.5H12.5V4.76812C12.5 4.34531 12.3336 3.93982 12.0375 3.64084C11.7414 3.34187 11.3398 3.17391 10.9211 3.17391H6.20088L5.23623 1.71297C5.14838 1.57992 5.00048 1.5 4.8421 1.5H2.07895C1.66018 1.5 1.25857 1.66796 0.962463 1.96693C0.666353 2.2659 0.5 2.67139 0.5 3.0942V10.9058C0.5 11.3286 0.666353 11.7341 0.962463 12.0331C1.25857 12.332 1.66018 12.5 2.07895 12.5H6.5V11.5435H2.07895C1.91144 11.5435 1.7508 11.4763 1.63235 11.3567C1.51391 11.2371 1.44737 11.0749 1.44737 10.9058V3.0942C1.44737 2.92508 1.51391 2.76288 1.63235 2.64329Z'
    ],
};

export const facImport: CustomIcon = {
    prefix: 'fac',
    iconName: 'facImport',
    icon: [
        14, // width
        14, // height
        [], // ligatures
        '', // unicode (if relevant)
        'M0.750098 7.34998C0.971012 7.34998 1.1501 7.52906 1.1501 7.74998V10.0833C1.1501 10.2866 1.23087 10.4816 1.37465 10.6254C1.51843 10.7692 1.71343 10.85 1.91676 10.85H10.0834C10.2868 10.85 10.4818 10.7692 10.6255 10.6254C10.7693 10.4816 10.8501 10.2866 10.8501 10.0833V7.74998C10.8501 7.52906 11.0292 7.34998 11.2501 7.34998C11.471 7.34998 11.6501 7.52906 11.6501 7.74998V10.0833C11.6501 10.4988 11.485 10.8973 11.1912 11.1911C10.8974 11.4849 10.4989 11.65 10.0834 11.65H1.91676C1.50126 11.65 1.10277 11.4849 0.808964 11.1911C0.515157 10.8973 0.350098 10.4988 0.350098 10.0833V7.74998C0.350098 7.52906 0.529184 7.34998 0.750098 7.34998Z M2.80059 4.55047C2.9568 4.39426 3.21006 4.39426 3.36627 4.55047L6.0001 7.18429L8.63392 4.55047C8.79013 4.39426 9.0434 4.39426 9.19961 4.55047C9.35582 4.70668 9.35582 4.95994 9.19961 5.11615L6.28294 8.03282C6.20793 8.10783 6.10618 8.14998 6.0001 8.14998C5.89401 8.14998 5.79227 8.10783 5.71725 8.03282L2.80059 5.11615C2.64438 4.95994 2.64438 4.70668 2.80059 4.55047Z M6.0001 0.349976C6.22101 0.349976 6.4001 0.529062 6.4001 0.749976V7.74998C6.4001 7.97089 6.22101 8.14998 6.0001 8.14998C5.77918 8.14998 5.6001 7.97089 5.6001 7.74998V0.749976C5.6001 0.529062 5.77918 0.349976 6.0001 0.349976Z'
    ],
};
