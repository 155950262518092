<ng-container *ngIf="viewData">
    <div
        class="risk-item card-body"
        [ngClass]="{ 'portfolio-risk-scores': isPortfolioScore }"
    >
        <div class="row no-gutters">
             <span class="score-title-text" [style.color]="!isPortfolioScore ? viewData.color : 'white'">
                 {{ viewData.name + (!isPortfolioScore ? ' Risks' : '') }}
             </span>
        </div>

        <div class="d-inline-flex mt-1 w-100">
            <div class="col-6 pl-0">
                <ng-container *ngIf="isPortfolioScore">
                    <div class="d-inline-flex mt-2 w-100">
                        <div class="col pl-0 pr-0">
                            <span class="score-title-count" [style.color]="'white'">{{ $any(viewData).score }}</span>
                            <span class="score-title-text ml-2" [style.color]="'white'">/5</span>
                        </div>
                    </div>
                    <div class="row no-gutters mt-1 w-100">
                        <svg width="14" height="18" viewBox="0 0 20 16" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path [attr.d]="$any(viewData).delta >= 0 ? 'M1 15L10 1L19 15L1 15Z' : 'M19 1L10 15L1 0.999999L19 1Z'"
                                  stroke="white"
                                  stroke-linecap="round"
                                  stroke-linejoin="round" />
                        </svg>
                        <span class="score-title-text ml-1" [style.color]="'white'">{{ $any(viewData).delta + ' pt'}}</span>
                    </div>
                </ng-container>
                <ng-container *ngIf="!isPortfolioScore">
                    <div class="row no-gutters mt-2 w-100" [style.color]="!isPortfolioScore ? viewData.color : 'white'">
                        <span class="score-title-count">{{ $any(viewData).count }}</span>
                    </div>
                </ng-container>
            </div>

            <ng-container *ngIf="viewData">
                <div class="col-6 chart-wrapper"
                     [style.background-color]="transformColor(viewData.color)">
                    <lib-chart
                        class="lib-chart"
                        [type]="'basiclinechart'"
                        [options]="viewData.chart.options"
                        [height]="'min'"
                    >
                    </lib-chart>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>
