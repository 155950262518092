<div #dd="ngbDropdown" ngbDropdown container="body">
    <button ngbDropdownToggle class="btn btn-link">
        <span class="mr-5px">{{ buttonText }}</span>
        <fa-icon *ngIf="isOpen(); else closedIconTmpl" class="fa font-14px" icon="chevron-up"></fa-icon>
        <ng-template #closedIconTmpl>
            <fa-icon class="fa font-14px" icon="chevron-down"></fa-icon>
        </ng-template>
    </button>

    <div ngbDropdownMenu>
        <ng-container *ngIf="items; else noData">
            <div class="option-row" *ngFor="let item of items" (click)="selected$.next(item);handleConfigureIntegration(item)">
                <img [src]="item.imageUrl | imagePath" alt="" class="logo-icon"/>
                <div class="label">
                    {{ item.name }}
                </div>
            </div>
            <div *ngIf="false" class="option-row">
                <button class="btn btn-link">
                    <fa-icon class="fa mr-5px" icon="plus"></fa-icon>
                    <span>Request New Supported Tool</span>
                </button>
            </div>
        </ng-container>
    </div>
</div>

<ng-template #noData>
    <div class="d-flex justify-content-center flex-column align-items-center">
        <div class="not-found-image"></div>
        <span class="not-found-text">
            <strong>There are no tools yet</strong>
        </span>
    </div>
</ng-template>

