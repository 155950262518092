<div class="modal-header settings-header">
    <ng-container>
        <h2 class="modal-title settings-title">{{ title }}</h2>
    </ng-container>

    <div class="pull-right">
        <button type="button" class="close" aria-describedby="modal-title" (click)="onClose()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>
<form *ngIf="announcementForm" [formGroup]="announcementForm">
    <div class="modal-body">
        <div class="d-flex">
            <lib-dropdown
                class="mr-4 mb-16px"
                [items]="importanceTypes"
                [formControlName]="'importance'"
                valueKey="code"
                labelKey="description"
                label="Importance"
                [searchable]="true"
                [clearable]="false"
                [optionTemplateRef]="optionTemplateRef"
                [labelTemplateRef]="optionTemplateRef"
            >
                <ng-template #optionTemplateRef let-item="item">
                    <div class="d-inline-flex font-14px justify-content-between align-items-center">
                        <fa-icon *ngIf="item.code === 'HIGH'" icon="facfilledExclamatory" style="color:#C62828"></fa-icon>
                        <span class="ml-10px color-gray-3">{{ item.description }}</span>
                    </div>
                </ng-template>
            </lib-dropdown>
            <lib-dropdown
                class="mr-4 mb-16px"
                style="width: 100%"
                [items]="categoryTypes"
                [formControlName]="'category'"
                valueKey="code"
                labelKey="description"
                [placeholder]="'Select or input'"
                label="Category"
                [searchable]="true"
                [clearable]="false"
            ></lib-dropdown>
            <lib-dropdown
                class="mr-4 mb-16px"
                [items]="hideBannerTypes"
                [formControlName]="'hideBanner'"
                valueKey="value"
                labelKey="label"
                [placeholder]="'Select or input'"
                label="Hide banner"
                [clearable]="false"
            ></lib-dropdown>
            <lib-dropdown
                class="mb-16px"
                [items]="acceptanceTypes"
                [formControlName]="'acceptanceType'"
                valueKey="value"
                labelKey="label"
                [placeholder]="'Select or input'"
                label="Acceptance button"
                [clearable]="false"
            ></lib-dropdown>
        </div>

        <div class="editor">
            <ng-container *ngIf="ckEditorLoaded$ | async; else loaderTemplate">
                <ck-editor (change)="onChangeEditor($event)" #editor name="bodyEditor" [config]="editorOptions"></ck-editor>
            </ng-container>
            <ng-template #loaderTemplate>
                <div class="d-flex justify-content-center my-4">
                    <lib-spinner></lib-spinner>
                </div>
            </ng-template>
        </div>
        <div class="mt-20px mb-20px">
            <app-files-attacher path="alert-attachments"></app-files-attacher>
        </div>

        <div class="modal-block">
            <h3 class="modal-title settings-title">Preview</h3>
            <app-announcement-banner [preview]="true" [data]="previewData"></app-announcement-banner>
        </div>
    </div>

    <div class="modal-body">
        <div class="modal-block">
            <h3 class="modal-title settings-title">Recipients</h3>
        </div>
        <app-recipients-editor [recipientsForm]="announcementForm"></app-recipients-editor>
    </div>

    <div class="modal-body">
        <div class="modal-block">
            <h3 class="modal-title settings-title">Time settings</h3>
            <div class="d-flex">
                <trigger class="mt-8px" formControlName="endDateTrigger">End date</trigger>
                <trigger class="mt-8px" formControlName="includeTimeTrigger">Include Time</trigger>
                <trigger class="mt-8px" formControlName="recurringEventTrigger">Recurring Event</trigger>
            </div>
            <div class="mt-30px d-flex">
                <lib-datepicker
                    style="width: 70%"
                    class="mr-3 size-m"
                    label="From"
                    [inputDatetimeFormat]="getDateFormat()"
                    [dateWithTime]="getTriggerValue('includeTimeTrigger')"
                    (ngModelChange)="setDateFrom($event)"
                    formControlName="dateFrom"
                    [clearable]="false"
                    name="dateFrom"
                >
                </lib-datepicker>
                <lib-dropdown
                    [items]="remindRecipientTypesFrom"
                    formControlName="remindFrom"
                    valueKey="value"
                    labelKey="label"
                    label="Remind Recipients"
                    [searchable]="false"
                    [clearable]="false"
                    [multiple]="false"
                ></lib-dropdown>
            </div>
            <div class="mt-30px d-flex" *ngIf="getTriggerValue('endDateTrigger')">
                <lib-datepicker
                    style="width: 70%"
                    class="mr-3 size-m"
                    label="To"
                    [inputDatetimeFormat]="getDateFormat()"
                    [dateWithTime]="getTriggerValue('includeTimeTrigger')"
                    (ngModelChange)="setDateTo($event)"
                    formControlName="dateTo"
                    [clearable]="false"
                    name="dateTo"
                >
                </lib-datepicker>
                <lib-dropdown
                    [items]="remindRecipientTypesTo"
                    formControlName="remindTo"
                    valueKey="value"
                    labelKey="label"
                    label="Remind Recipients"
                    [searchable]="false"
                    [clearable]="false"
                    [multiple]="false"
                ></lib-dropdown>
            </div>

            <div class="mt-30px d-flex" *ngIf="getTriggerValue('recurringEventTrigger')">
                <lib-input class="size-m" type="number" label="Repeats Every" formControlName="repeatsCount"></lib-input>
                <lib-dropdown
                    class="ml-10px"
                    [items]="repeatsPeriodTypes"
                    formControlName="repeatsPeriod"
                    valueKey="value"
                    labelKey="label"
                    label="&nbsp;"
                    [searchable]="false"
                    [clearable]="false"
                    [multiple]="false"
                ></lib-dropdown>

                <div [ngSwitch]="getTriggerValue('repeatsPeriod')" class="ml-20px">
                    <ng-container *ngSwitchCase="'Weeks'">
                        <span>Repeats on</span>
                        <div class="d-flex">
                            <checkbox class="mr-20px mt-15px" *ngFor="let day of weekDays">{{ day }}</checkbox>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Months'">
                        <lib-dropdown
                            [items]="specificDayTypes"
                            formControlName="repeatDayOfMonth"
                            valueKey="value"
                            labelKey="label"
                            label="Specific day"
                            [searchable]="false"
                            [clearable]="false"
                            [multiple]="false"
                        ></lib-dropdown>
                    </ng-container>
                </div>
            </div>

            <div class="mt-30px" *ngIf="getTriggerValue('recurringEventTrigger')">
                <span>Ends</span>
                <div class="d-flex mt-10px">
                    <lib-radio-button
                        class="mb-20px mt-5px"
                        [id]="'never'"
                        [name]="'never'"
                        [checked]="getTriggerValue('endType') === 'never'"
                        [label]="'Never'"
                        (click)="onSelectEndType($event)"
                    ></lib-radio-button>
                    <lib-radio-button
                        class="mb-20px ml-20px mt-5px"
                        [id]="'on'"
                        [name]="'on'"
                        [checked]="getTriggerValue('endType') === 'on'"
                        [label]="'On'"
                        (click)="onSelectEndType($event)"
                    ></lib-radio-button>
                    <lib-datepicker
                        class="ml-1 size-m"
                        [inputDatetimeFormat]="'yyyy-MM-dd'"
                        [dateWithTime]="false"
                        formControlName="endDate"
                        [clearable]="false"
                        name="endDate"
                        [disabled]="getTriggerValue('endType') !== 'on'"
                    >
                    </lib-datepicker>
                    <lib-radio-button
                        class="mb-20px ml-20px mt-5px"
                        [id]="'after'"
                        [name]="'after'"
                        [checked]="getTriggerValue('endType') === 'after'"
                        [label]="'After'"
                        (click)="onSelectEndType($event)"
                    ></lib-radio-button>
                    <lib-input
                        class="size-m ml-1 "
                        type="number"
                        formControlName="occurenceLimit"
                        [setDisable]="getTriggerValue('endType') !== 'after'"
                    ></lib-input>

                    <span class="mt-5px ml-1">occurrence</span>
                </div>
            </div>
        </div>
    </div>
</form>
<div class="modal-footer">
    <div class="d-inline-flex justify-content-end align-items-center">
        <lib-loader *ngIf="false" [size]="'15px'"></lib-loader>
        <button class="btn btn-secondary font-11px ml-20px" (click)="onCancel()">Cancel</button>
        <button class="btn btn-primary font-11px ml-20px" (click)="onSave()">
            OK
        </button>
    </div>
</div>
