import { Component, Input, OnInit } from '@angular/core';
import { GridColumn, ModalService } from '@dagility-ui/kit';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ExternalParameter, Parameter } from '@app/shared/components/query-builder/models/model-graph-actions.model';
import { ModelGraphActionsService } from '@app/shared/components/query-builder/services/model-graph-actions.service';
import { EditParamsModalComponent } from '@app/shared/components/query-builder/qb-visualization/components/external-params/edit-params-modal/edit-params-modal.component';
import { QueryBuilderStore } from '@app/shared/components/query-builder/store/query-builder.store';

@Component({
    selector: 'app-external-params',
    templateUrl: './external-params.component.html',
    styleUrls: ['./external-params.component.scss'],
})
export class ExternalParamsComponent implements OnInit {
    @Input() externalParameters: ExternalParameter[];

    columns: GridColumn[] = [
        { field: 'parameter', title: 'Parameter Name', width: '30%' },
        { field: 'value', title: 'Value', width: '30%' },
        { field: 'type', title: 'Type', width: '30%' },
        { field: 'actions', title: 'Actions', width: '10%' },
    ];

    params: ExternalParameter[];
    showWarning: boolean = false;

    constructor(
        public activeModal: NgbActiveModal,
        private modalService: ModalService,
        private modelGraphActionsService: ModelGraphActionsService,
        private queryBuilderStore: QueryBuilderStore
    ) {}

    ngOnInit(): void {
        this.params = this.externalParameters;
        this.params.forEach(param => {
            if (!param.valueType) param.valueType = 'TEXT';
        });
        this.hasEmptyPlaceholders();
    }

    handleAddClick() {
        this.modalService
            .open(
                EditParamsModalComponent,
                { size: 'lg' },
                {
                    parameter: {
                        name: '',
                        value: '',
                        valueType: 'TEXT',
                    } as ExternalParameter,
                    allParameterNames: this.params.map(p => p.name),
                }
            )
            .result.then(param => {
                this.addParameter(param);
            })
            .catch(() => {
                // noop
            });
    }

    handleDeleteClick(paramId: string) {
        const sourceGraph = this.queryBuilderStore.globalModelGraph;
        const drillDownId = this.queryBuilderStore.drillDownId;
        this.modelGraphActionsService.deleteParameter({ parameterUid: paramId, sourceGraph, drillDownId }).subscribe(result => {
            this.queryBuilderStore.setState({
                modelGraph: result.graph,
            });
            this.params = this.params.filter(p => p.uid !== paramId);
        });
        this.hasEmptyPlaceholders();
    }

    handleEditClick(param: ExternalParameter) {
        this.modalService
            .open(
                EditParamsModalComponent,
                { size: 'lg' },
                {
                    parameter: param,
                    allParameterNames: this.params.filter(p => p.uid !== param.uid).map(p => p.name),
                }
            )
            .result.then(param => {
                this.updateParameter(param);
            })
            .catch(() => {
                // noop
            });
    }

    handleApplyClick() {
        const notEmptyParams = this.params.filter(param => param.value);
        this.activeModal.close(notEmptyParams);
    }

    private addParameter(param: ExternalParameter) {
        const sourceGraph = this.queryBuilderStore.globalModelGraph;
        const drillDownId = this.queryBuilderStore.drillDownId;
        const parameter: Parameter = {
            name: param.name,
            valueType: param.valueType,
        };
        this.modelGraphActionsService.createParameter({ parameter, sourceGraph, drillDownId }).subscribe(result => {
            this.queryBuilderStore.setState({
                modelGraph: result.graph,
            });

            const newParam = this.queryBuilderStore.drilldownGraph.parameters.find(p => p.name === param.name);

            this.params.push({
                uid: newParam.uid,
                name: newParam.name,
                valueType: newParam.valueType,
                type: newParam.type,
                value: newParam.valueType === 'NUMERIC' ? Number(param.value) : param.value,
            } as ExternalParameter);
        });
    }

    private updateParameter(param: ExternalParameter) {
        const sourceGraph = this.queryBuilderStore.globalModelGraph;
        const drillDownId = this.queryBuilderStore.drillDownId;
        const parameter: Parameter = {
            uid: param.uid,
            name: param.name,
            valueType: param.valueType,
            type: param.type,
        };
        this.modelGraphActionsService.updateParameter({ sourceGraph, drillDownId, parameter }).subscribe(result => {
            this.queryBuilderStore.updateState(result);
            const idx = this.params.findIndex(p => p.uid === param.uid);
            param.value = param.valueType === 'NUMERIC' ? Number(param.value) : param.value;
            this.params[idx] = param;
        });
        this.hasEmptyPlaceholders();
    }

    private hasEmptyPlaceholders() {
        this.showWarning = this.params.some(param => !param.value);
    }
}
