import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { isNil, omitBy } from 'lodash';

import { environment, runtimeEnv } from '@app/env';
import { ENV_TOKEN } from '@app/tokens';
import { AppModule } from './app/app.module';
import { buildApiUrls } from './environments/env.common';
import { showError } from './bootstrap-error-page';

declare let isSystemSupported: boolean;

if (environment.production) {
    enableProdMode();
}

function loadEnvironment(): Promise<Env> {
    return fetch(`/assets/config/env.json?${+Date.now()}`)
        .then(res => res.json())
        .then(config => {
            const mergedRuntimeEnv: RuntimeEnv = Object.assign(runtimeEnv, omitBy(config, isNil) as any);
            Object.assign(mergedRuntimeEnv, buildApiUrls(mergedRuntimeEnv.serviceUrl));
            // override some generated props
            const props: Array<keyof RuntimeEnv> = ['webConsoleApiUrl'];
            props.forEach(prop => {
                if (prop in config) {
                    mergedRuntimeEnv[prop] = config[prop];
                }
            });
            return { ...environment, ...mergedRuntimeEnv };
        });
}

(async () => {
    if (!isSystemSupported) {
        return;
    }
    const env = await loadEnvironment();

    await platformBrowserDynamic([
        { provide: 'environment', useValue: env },
        { provide: ENV_TOKEN, useValue: env },
    ])
        .bootstrapModule(AppModule)
        .then(module => {
            // @ts-ignore
            window['ngModuleInjector'] = module.injector;
        })
        .catch(showError);
})();
