<div class="modal-header d-flex align-items-center">
    <h1>
        The following parameters are required for the job debug.
    </h1>
    <button type="button" class="close ml-auto" aria-label="Close button" aria-describedby="modal-title"
            (click)="handleCloseClick()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form class="modal-body flex-column" [formGroup]="formGroup">
    <lib-properties-editor
        class="properties-editor-form"
        [fieldsEditAllowed]="false"
        [showError]="false"
        [fieldConfigContextHelpTemplate]="templateProvider.contextHelpTemplate"
        [fieldOptionsContextHelpTemplate]="templateProvider.contextHelpTemplate"
        formControlName="variables">
    </lib-properties-editor>
</form>
<div class="modal-footer d-flex">
    <button class="btn btn-secondary" (click)="handleCloseClick()">Close</button>
    <button class="btn btn-primary ml-auto" (click)="handleSave()">Debug</button>
</div>
