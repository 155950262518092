<lib-stream-wrapper [dataStream$]="data$" errorCssClass="w-100" class="d-flex overflow-hidden w-100 background-color">
    <ng-template contentTemplate>
        <ng-container
            *ngIf="{
                dashboard: vm$ | async,
                breadcrumbHeader: breadcrumbHeader$ | async,
                editMode: editMode$ | async
            } as vm"
        >
            <div
                *libLet="!vm.dashboard.edit && vm.dashboard.dashboardOptions?.visibility === tabVisibility.HIDE_ALL as tabsHidden"
                class="d-flex flex-column w-100 min-width-0"
            >
                <div
                    class="main-content-header main-content-header-shadow pb-0 mb-3"
                    [style.paddingTop]="
                        !vm.breadcrumbHeader && vm.dashboard.edit && vm.dashboard.activeTab !== null && !vm.dashboard.options?.hideMLF
                            ? '50px'
                            : '24px'
                    "
                    [class.pb-3]="tabsHidden"
                    keepni-path-type="MODULE,DASHBOARD"
                    [attr.keepni-path-name]="'Illuminate,' + vm.dashboard.name"
                >
                    <div class="d-flex align-items-start" [class.flex-column]="vm.breadcrumbHeader">
                        <div class="d-flex justify-content-end flex-shrink-0 mr-auto w-100">
                            <div class="d-flex align-items-center" [style.height]="vm.breadcrumbHeader ? '30px' : null">
                                <ng-container *ngIf="vm.breadcrumbHeader">
                                    <ng-container *ngTemplateOutlet="arrowTemplate"></ng-container>
                                </ng-container>
                                <lib-breadcrumb [mode]="vm.breadcrumbHeader ? 'arrow' : 'bootstrap'">
                                    <ng-template libBreadcrumb> Illuminate </ng-template>
                                    <ng-container
                                        *ngIf="
                                            vm.dashboard.dashboardOptions?.visibility !== tabVisibility.SHOW_ACTIVE;
                                            else breadcrumbWithTabs
                                        "
                                    >
                                        <ng-template libBreadcrumb active="true">
                                            {{ vm.dashboard.name }}
                                        </ng-template>
                                    </ng-container>
                                    <ng-template #breadcrumbWithTabs>
                                        <ng-template libBreadcrumb (breadcrumbClick)="handleBreadCrumbLink()">
                                            {{ vm.dashboard.name }}
                                        </ng-template>
                                        <ng-template libBreadcrumb active="true">
                                            {{ vm.dashboard.tabsMap[vm.dashboard.activeTab].name }}
                                        </ng-template>
                                    </ng-template>
                                </lib-breadcrumb>
                            </div>
                            <h1 *ngIf="!vm.breadcrumbHeader" class="pt-2 dashboard-name">
                                <ng-container *ngTemplateOutlet="arrowTemplate"></ng-container>
                                {{ vm.dashboard.name + (portfolioName ? ' — ' + portfolioName : '') }}
                            </h1>

                            <dp-dashboard-filter-set
                                class="ml-auto"
                                [ngClass]="{ 'mr-3': vm.dashboard.edit }"
                                new
                                [externalFilter]="externalFilter"
                                [dashboard]="vm.dashboard"
                                [filters]="
                                    (!!vm.dashboard.dashboardOptions ? vm.dashboard.dashboardOptions.filters : vm.dashboard.filter) || []
                                "
                                [edit]="vm.editMode"
                                [handlers]="vm.dashboard.dashboardOptions?.handlers || []"
                                [dashboardId]="vm.dashboard.dashboardId"
                                [dynamicDashboard]="!!vm.dashboard.uuid"
                                [event]="event"
                                (filtersRecalculated)="handleSubmitAllChangedFilters($event)"
                            >
                                <ng-container *ngIf="dashboardActions$ | async as dashboardActions">
                                    <lib-treelike-menu
                                        *ngIf="dashboardActions.length"
                                        btnClasses="btn-secondary"
                                        [openIconStyles]="{
                                            color: 'var(--da-brand-base)'
                                        }"
                                        [treeItems]="dashboardActions"
                                        [closeOnClick]="true"
                                    ></lib-treelike-menu>
                                </ng-container>
                            </dp-dashboard-filter-set>
                            <span *ngIf="vm.dashboard.edit" class="splitter"></span>
                            <button class="btn btn-primary ml-3" *ngIf="vm.dashboard.edit" (click)="handleEditDashboard(false)">
                                Close
                            </button>
                        </div>
                    </div>
                    <div class="d-flex">
                        <nav
                            class="nav nav-tabs justify-content-start mt-3"
                            [class.pt-4]="!vm.dashboard.tabs?.length"
                            [hidden]="tabsHidden"
                            ngbNav
                            #nav="ngbNav"
                            libScroller
                            [destroyOnHide]="false"
                            [activeId]="vm.dashboard.activeTab"
                            (navChange)="handleTabChange($event)"
                            (cdkDropListDropped)="reOrderTabs($event, vm.dashboard.dashboardId, vm.dashboard.tabsMap)"
                            cdkDropList
                            cdkDropListOrientation="horizontal"
                            [cdkDropListData]="vm.dashboard.tabs"
                            [cdkDropListDisabled]="
                                !vm.dashboard.edit || vm.dashboard.tabs.length <= 1 || (vm.dashboard.options.template && !isAdmin)
                            "
                        >
                            <ng-container *ngFor="let tab of vm.dashboard.tabs; trackBy: trackByTab">
                                <dp-dashboard-tab
                                    [class.cursor-move]="vm.dashboard.edit && vm.dashboard.tabs.length > 1"
                                    [tabsLength]="vm.dashboard.tabs.length"
                                    [tab]="vm.dashboard.tabsMap[tab]"
                                    [dashboardId]="vm.dashboard.dashboardId"
                                    [ngbNavItem]="tab"
                                    [visibility]="vm.dashboard.dashboardOptions?.visibility"
                                    [activeId]="vm.dashboard.activeTab"
                                    [loaded]="store.loadedTabs[tab]"
                                    [expandedGroups]="vm.dashboard.expandedGroups[tab] || []"
                                    [edit]="vm.dashboard.edit"
                                    [groups]="vm.dashboard.groupsMap"
                                    [widgetsPlaceholders]="widgetsPlaceholders"
                                    [extensionsEnabled]="extensionsEnabled"
                                ></dp-dashboard-tab>
                                <ng-container *ngIf="tabActions$ | async as tabActions">
                                    <lib-treelike-menu
                                        *ngIf="vm.dashboard.edit"
                                        btnClasses="border-0 btn-secondary mr-1 mt-1"
                                        [openIconStyles]="{
                                            color: 'var(--da-brand-base)'
                                        }"
                                        [closeOnClick]="true"
                                        [treeItems]="tabActions"
                                        (click)="getTab(tab)"
                                    ></lib-treelike-menu>
                                </ng-container>
                            </ng-container>
                        </nav>
                        <a *ngIf="vm.dashboard.edit" class="cursor-pointer add-tab" (click)="handleAddTab()">
                            <fa-icon class="fa mr-5px" icon="plus"></fa-icon>
                        </a>
                    </div>
                </div>
                <div class="d-flex flex-column w-100 h-100 overflow-hidden px-4">
                    <div class="flex-1 overflow-hidden d-flex" [ngbNavOutlet]="nav"></div>
                </div>
            </div>

            <dp-dashboard-widget-list
                *ngIf="vm.editMode"
                id="side-menu-dashboard"
                class="side-menu-dashboard border-left px-4 pt-4"
                [importAllowed]="importAllowed"
            ></dp-dashboard-widget-list>

            <ng-template #arrowTemplate>
                <ng-container
                    *ngIf="
                        vm.dashboard.dashboardOptions?.visibility === tabVisibility.SHOW_ACTIVE &&
                            vm.dashboard.tabsMap[vm.dashboard.activeTab].options.tabOrder !== 0;
                        else anotherArrow
                    "
                >
                    <fa-icon
                        icon="facArrowLeftRegular"
                        class="fa font-16px back-icon cursor-pointer"
                        [class.mr-2]="vm.breadcrumbHeader"
                        (click)="handleBackToPreviousTab()"
                    ></fa-icon>
                </ng-container>
                <ng-template #anotherArrow>
                    <ng-container *ngIf="previousMlfState && previousMlfState.length">
                        <fa-icon
                            icon="facArrowLeftRegular"
                            class="fa font-16px back-icon cursor-pointer"
                            [class.mr-2]="vm.breadcrumbHeader"
                            (click)="handleBackToPreviousState()"
                        ></fa-icon>
                    </ng-container>
                </ng-template>
            </ng-template>
        </ng-container>
    </ng-template>
</lib-stream-wrapper>
