import { Component, Input, EventEmitter, Output, ChangeDetectorRef, OnInit, HostListener, ElementRef, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { QueryBuilderStore } from '@app/shared/components/query-builder/store/query-builder.store';
import { ModelGraphActionsService } from '@app/shared/components/query-builder/services/model-graph-actions.service';
import { DataSetField } from '@app/shared/components/query-builder/models/model-graph.model';
import { debounceTime } from 'rxjs/operators';
import {
    ExpressionTemplate as ExpressionTemplateDto,
    ExpressionParts,
} from '@app/shared/components/query-builder/models/model-graph-actions.model';
import { Subscription } from 'rxjs';
import {
    GridItem,
    FunctionItem,
    ExpressionNodeType,
    ExpressionNode,
    ExpressionTemplate,
    ExpressionBuilderService,
} from '../expression-builder.service';

interface ContextOption {
    label: string;
    action: string;
}

@Component({
    selector: 'app-qb-model-expression-helper-modal',
    templateUrl: './expression-helper-modal.component.html',
    styleUrls: ['./expression-helper-modal.component.scss'],
})
export class ExpressionHelperModalComponent implements OnInit, OnDestroy {
    expressionCursor: number = 0;
    expressionString: string = '';
    showTemplates: boolean = true;

    allFields: DataSetField[] = [];
    allFunction: FunctionItem[] = [];
    allOperators: FunctionItem[] = [];
    allTemplates: ExpressionTemplate[] = [];
    allParams: string[] = [];

    contextMenuTargetParent: ExpressionNode | null = null;
    contextMenuTarget: ExpressionNode | null = null;
    gridData: GridItem[] = [];
    selectedItem: GridItem;
    filterControl = new FormControl();
    filterPlaceholder: string = '<constant>';
    filterControlElement?: HTMLInputElement;
    contextMenuPosition: { left: number; top: number } = { left: 0, top: 0 };

    @Input() active: boolean = false;
    @Input() set cursor(value: number) {
        this.expressionCursor = value;
    }
    @Input() set expression(value: string) {
        if (!value || value[0] !== '{') {
            this.expressionString = this.expressionBuilderService.convertDataToJson({ ...this.defaultData });
            this.selectedNode = this.expressionTree.arguments[0] = { ...this.defaultData };
            this.selectedNode.parentNode = this.expressionTree;
            this.selectedNode.selected = true;
            this.contextMenuTarget = null;
            this.filterControl.setValue('', { emitEvent: false });
        } else {
            this.expressionString = value;
        }
    }
    @Output() expressionChanged = new EventEmitter();

    contextMenuOptions: ContextOption[] = [];
    types = ExpressionNodeType;
    typesArray: string[];
    selectedNode: ExpressionNode;

    typeIcons: { [key: number]: string };
    typeDefenitions: { [key: number]: string };
    defaultData: ExpressionNode = {
        parentNode: null,
        type: ExpressionNodeType.const,
        value: '<constant>',
    };
    expressionTree: ExpressionNode = {
        type: ExpressionNodeType.func,
        value: '',
        parentNode: null,
        arguments: [{ ...this.defaultData }],
    };

    expressionPartsSub: Subscription;
    constructor(
        private elRef: ElementRef,
        private queryBuilderStore: QueryBuilderStore,
        private modelGraphActionsService: ModelGraphActionsService,
        private cdr: ChangeDetectorRef,
        private expressionBuilderService: ExpressionBuilderService
    ) {
        const keys = Object.keys(this.types);
        this.expressionTree.arguments[0].parentNode = this.expressionTree;
        this.typesArray = keys.slice(keys.length / 2).slice(0, -1);

        this.typeIcons = this.expressionBuilderService.typeIcons;
        this.typeDefenitions = this.expressionBuilderService.typeDefenitions;

        this.expressionPartsSub = this.expressionBuilderService.expressionParts$.subscribe((data: ExpressionParts) => {
            this.allOperators = this.expressionBuilderService.mapFunctions(data.operators, true);
            this.allFunction = this.expressionBuilderService.mapFunctions(data.functions);
            this.allFields = data.fields.map(field => ({ ...field, selected: false })).filter(field => !field.calculated);
            this.allParams = data.parameters;
            this.allTemplates = this.expressionBuilderService.mapTemplates(data.templates)
            this.updateGridData();
        });
    }

    ngOnInit(): void {
        this.filterControl.valueChanges.pipe(debounceTime(100)).subscribe(() => {
            if (this.selectedNode.type === ExpressionNodeType.const) {
                this.selectedNode.value = this.filterControl.value;
            }
            this.updateGridData();
            this.emitChanges();
        });
        this.focusFilterControl();
    }

    ngOnDestroy(): void {
        if (this.expressionPartsSub) {
            this.expressionPartsSub.unsubscribe();
        }
    }

    setData(data: string) {
        this.filterControl.setValue('', { emitEvent: false });
        this.selectedNode = this.expressionTree.arguments[0] = this.expressionBuilderService.parseData(data);
        this.selectedNode.parentNode = this.expressionTree;
        this.selectedNode.selected = true;
        this.cdr.detectChanges();
    }

    emitChanges() {
        this.expressionString = this.expressionBuilderService.convertDataToJson(this.expressionTree.arguments[0]);
        this.expressionChanged.emit({
            type: 'expression-changed',
            expression: this.expressionString,
            expressionView: this.expressionBuilderService.convertNodeToFunctionView(this.expressionTree.arguments[0]),
        });
    }

    focusFilterControl() {
        setTimeout(() => {
            this.filterControlElement = this.elRef.nativeElement.querySelector('#filterControlRef').getElementsByTagName('input')[0];
            if (this.filterControlElement) {
                this.filterControlElement.focus();
                this.filterControlElement.setSelectionRange(0, String(this.selectedNode.value).length);
            }
        }, 50);
    }

    updateGridData() {
        let templates: GridItem[] = this.showTemplates
            ? this.allTemplates.map(t => ({
                  label: t.text,
                  isTemplate: true,
                  data: t,
              }))
            : [];
        if (!this.selectedNode) {
            return;
        }
        switch (this.selectedNode.type) {
            case ExpressionNodeType.const:
                this.gridData = [];
                break;
            case ExpressionNodeType.field:
                this.gridData = this.allFields.map(item => ({
                    data: item,
                    selected: false,
                    label: `${this.expressionBuilderService.modelNames[item.srcModelUid]}.${item.dsFieldName}`,
                }));
                break;
            case ExpressionNodeType.operator:
            case ExpressionNodeType.func:
                this.gridData = (this.selectedNode.type === ExpressionNodeType.operator ? this.allOperators : this.allFunction).map(
                    item => ({
                        data: item,
                        selected: false,
                        label: `${item.name}`,
                        paramsList: ` (${item.argTypes.join(',')})`,
                        description: ` - ${item.description}`,
                    })
                );
                break;
            case ExpressionNodeType.param:
                this.gridData = this.allParams.map(param => ({
                    data: param,
                    selected: false,
                    label: param,
                }));
                break;
        }
        this.gridData = this.filterControl.value
            ? this.gridData.filter(item => item.label.includes(this.filterControl.value))
            : this.gridData;
        templates = this.filterControl.value ? templates.filter(item => item.label.includes(this.filterControl.value)) : templates;
        templates = templates.slice(0, Math.min(3, templates.length));
        this.gridData = templates.concat(this.gridData);
        this.cdr.detectChanges();
    }

    @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        if (!this.active) {
            return;
        }
        switch (event.key) {
            case 'Escape':
                this.expressionChanged.emit({ type: 'expression-canceled' });
                break;
            case 'Enter':
                {
                    const prevType = this.selectedNode.type;
                    this.onSelectGridData(this.selectedItem);
                    if ([ExpressionNodeType.func, ExpressionNodeType.operator].includes(prevType)) {
                        return;
                    }
                    if ([ExpressionNodeType.const, ExpressionNodeType.param, ExpressionNodeType.field].includes(this.selectedNode.type)) {
                        if (this.selectedNode.parentNode) {
                            const parentArguments = this.selectedNode.parentNode.arguments;
                            let nextIndex = parentArguments.indexOf(this.selectedNode);
                            nextIndex = nextIndex + 1 >= parentArguments.length ? 0 : nextIndex + 1;
                            if (nextIndex === 0) {
                                const nextParent = this.selectedNode.parentNode.parentNode;
                                if (nextParent) {
                                    let nextParentIndex = nextParent.arguments.indexOf(this.selectedNode.parentNode);
                                    nextParentIndex = nextParentIndex + 1 >= nextParent.arguments.length ? 0 : nextParentIndex + 1;
                                    this.onSelectExpressionNode(null, nextParent.arguments[nextParentIndex]);
                                } else {
                                    this.onSelectExpressionNode(null, parentArguments[nextIndex]);
                                }
                            } else {
                                this.onSelectExpressionNode(null, parentArguments[nextIndex]);
                            }
                        }
                    }
                }
                break;
            case 'ArrowDown':
            case 'ArrowUp':
                {
                    const grid: any[] = this.gridData;
                    const elementRef = this.elRef.nativeElement.querySelector('#dataGridHelperRef');
                    const selectedItem = this.expressionBuilderService.selectItem(event.key, grid, elementRef, this.selectedItem);
                    this.selectedItem = selectedItem;
                }
                event.preventDefault();
                break;
            case 'ArrowLeft':
            case 'ArrowRight':
                {
                    const offset = event.key === 'ArrowLeft' ? -1 : 1;
                    const index = Object.keys(ExpressionNodeType).indexOf(this.selectedNode.type.toString());
                    const typesCount = this.typesArray.length;
                    let nextIndex = index + offset;
                    this.selectedItem = null;
                    nextIndex = nextIndex < 0 ? typesCount - 1 : nextIndex >= typesCount ? 0 : nextIndex;
                    this.onSwitchExpressionType(ExpressionNodeType[this.typesArray[nextIndex] as keyof typeof ExpressionNodeType]);
                }
                break;
            default:
                break;
        }
    }

    onContextOption(item: ContextOption) {
        const removeArgument = (child: ExpressionNode): number => {
            const index = child.parentNode.arguments.indexOf(child);
            if (index >= 0) {
                child.parentNode.arguments.splice(index, 1);
            }
            return index;
        };

        switch (item.action) {
            case 'save-template-local':
            case 'save-template-global':
                {
                    const isGlobal = item.action === 'save-template-global';
                    const template: ExpressionTemplateDto = {
                        global: isGlobal,
                        text: this.expressionBuilderService.convertNodeToFunctionView(this.contextMenuTarget),
                        content: this.expressionBuilderService.convertDataToJson(this.contextMenuTarget),
                    };
                    const sub = this.modelGraphActionsService.saveExpressionTemplates(template).subscribe(() => {
                        sub.unsubscribe();
                        this.expressionBuilderService.getExpressionParts();
                    });
                }
                break;
            case 'wrap-as-function':
            case 'wrap-as-operator':
                {
                    const isOperator = item.action === 'wrap-as-operator';
                    const lastParent = this.contextMenuTarget.parentNode;
                    const newArgument: ExpressionNode = {
                        type: isOperator ? ExpressionNodeType.operator : ExpressionNodeType.func,
                        value: '',
                        arguments: [this.contextMenuTarget],
                        operator: isOperator,
                        parentNode: lastParent,
                        selected: false,
                    };
                    const index = removeArgument(this.contextMenuTarget);
                    this.contextMenuTarget.parentNode = newArgument;
                    this.contextMenuTargetParent.arguments.splice(index, 0, newArgument);
                    this.onSelectExpressionNode(null, newArgument);
                    this.selectedNode.value = this.filterPlaceholder = `<${isOperator ? 'operator' : 'function'}>`;
                }
                break;
            case 'remove-argument':
                removeArgument(this.contextMenuTarget);
                break;
            case 'add-argument':
                {
                    const argument: ExpressionNode = {
                        type: ExpressionNodeType.const,
                        value: '',
                        parentNode: this.contextMenuTarget,
                        arguments: [],
                        operator: false,
                        selected: false,
                    };
                    if (this.selectedNode) {
                        this.selectedNode.selected = false;
                    }
                    this.selectedNode = argument;
                    argument.selected = true;
                    this.contextMenuTarget.arguments.push(argument);
                    this.onSwitchExpressionType(ExpressionNodeType.const);
                }
                break;
        }
        this.contextMenuTargetParent = this.contextMenuTarget = null;
    }

    onContextMenuExpressionNode(event: any, node: ExpressionNode, parent: ExpressionNode) {
        if (event.stopPropagation) {
            event.preventDefault();
            event.stopPropagation();
            const treeBlockRef = this.elRef.nativeElement.querySelector('#treeBlockRef');
            const contextMenuButton = this.elRef.nativeElement.querySelector('#contextMenuButtonRef');
            const treeBlockRefRect = treeBlockRef.getBoundingClientRect();
            const contextMenuButtonRect = contextMenuButton.getBoundingClientRect();
            this.contextMenuTarget = node;
            this.contextMenuTargetParent = parent;
            this.contextMenuPosition.left = contextMenuButtonRect.x;
            this.contextMenuPosition.top = contextMenuButtonRect.y + 18 - treeBlockRefRect.y;
            this.contextMenuOptions = [
                { label: 'Wrap as function', action: 'wrap-as-function' },
                { label: 'Wrap as operator', action: 'wrap-as-operator' },
                { label: 'Save local template', action: 'save-template-local' },
                { label: 'Save global template', action: 'save-template-global' },
            ];

            if ([ExpressionNodeType.func, ExpressionNodeType.operator].includes(node.type)) {
                this.contextMenuOptions.splice(0, 0, { label: 'Add argument', action: 'add-argument' });
            }

            if (this.expressionTree.arguments[0] !== node) {
                this.contextMenuOptions.splice(0, 0, { label: 'Remove argument', action: 'remove-argument' });
            }
        }
    }

    onSelectExpressionNode(event: any, node: ExpressionNode) {
        this.contextMenuTarget = null;
        if (event) {
            event.stopPropagation();
        }

        if (!node || node === this.selectedNode) {
            return;
        }
        if (this.selectedNode) {
            this.selectedNode.selected = false;
        }
        this.selectedNode = node;
        node.selected = true;

        this.filterPlaceholder = `<${this.typeDefenitions[this.selectedNode.type].toLocaleLowerCase()}>`;
        if (node.type === ExpressionNodeType.const) {
            this.filterControl.setValue('', { emitEvent: false });
        } else {
            this.filterControl.setValue('', { emitEvent: false });
        }
        this.updateGridData();
        this.focusFilterControl();
    }

    onSwitchExpressionType(type: ExpressionNodeType) {
        this.contextMenuTarget = null;
        this.selectedNode.type = type;
        this.selectedNode.value = '';
        this.filterControl.setValue('', { emitEvent: false });
        this.selectedNode.value = this.filterPlaceholder = `<${this.typeDefenitions[type].toLocaleLowerCase()}>`;
        this.selectedNode.arguments = [];
        this.updateGridData();
        this.emitChanges();
        this.focusFilterControl();
    }

    onSelectGridData(item?: GridItem) {
        this.contextMenuTarget = null;
        this.selectedItem = item;
        if (item?.isTemplate) {
            this.selectedNode.type = (item.data as ExpressionTemplate).node.type;
        }
        this.filterPlaceholder = `<${this.typeDefenitions[this.selectedNode.type].toLocaleLowerCase()}>`;
        switch (this.selectedNode.type) {
            case ExpressionNodeType.const:
            case ExpressionNodeType.field:
                if (item) {
                    this.selectedNode.value = item.label;
                }
                break;
            case ExpressionNodeType.operator:
            case ExpressionNodeType.func:
                if (item) {
                    if (item.isTemplate) {
                        const temp = item.data as ExpressionTemplate;
                        const tempNode = this.expressionBuilderService.parseData(temp.content);
                        this.selectedNode.value = tempNode.value;
                        this.selectedNode.operator = tempNode.operator;
                        this.selectedNode.arguments = tempNode.arguments;
                        tempNode.arguments.forEach(args => {
                            args.parentNode = this.selectedNode;
                        });
                    } else {
                        const func = item.data as FunctionItem;
                        if (func.argTypes) {
                            this.selectedNode.value = func.name;
                            this.selectedNode.operator = this.selectedNode.type === ExpressionNodeType.operator;
                            const newArguments = func.argTypes.map(arg => ({
                                type: ExpressionNodeType.const,
                                parentNode: this.selectedNode,
                                value: arg,
                            }));

                            if (newArguments.length > this.selectedNode.arguments.length) {
                                newArguments.splice(0, this.selectedNode.arguments.length);
                                this.selectedNode.arguments = this.selectedNode.arguments.concat(newArguments);
                            }
                        }
                    }
                }
                this.onSelectExpressionNode(null, this.selectedNode.arguments[0]);
                this.selectedItem = null;
                break;
            case ExpressionNodeType.param:
                if (item) {
                    this.selectedNode.value = item.data as string;
                }
                break;
        }
        this.filterControl.setValue('', { emitEvent: false });
        this.emitChanges();
    }

    onClearFunctionFilter() {
        this.filterControl.setValue('');
    }

    onRemoveTemplate(event: any, templateItem: GridItem) {
        event.stopPropagation();
        const sub = this.modelGraphActionsService.deleteExpressionTemplates((templateItem.data as ExpressionTemplate).uid).subscribe(() => {
            sub.unsubscribe();
            this.expressionBuilderService.getExpressionParts();
        });
    }

    onTemplateMode() {
        this.showTemplates = !this.showTemplates;
        this.updateGridData();
    }
}
