import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {
    FactorScoreModel,
    FactorScoreValueType, FactorScoreValueTypes,
} from 'data-processor/lib/widget-library/widget-builder/components/factor-scores/models/factor-score.model';

@Component({
    selector: 'dp-factor-scores',
    templateUrl: './factor-scores.component.html',
    styleUrls: ['./factor-scores.component.scss'],
})
export class FactorScoresComponent implements OnInit {
    @Input() data: FactorScoreModel[];
    @Input() type: FactorScoreValueType = FactorScoreValueTypes.OUT_OF_TEN;

    @Output() rowClicked = new EventEmitter();

    statusBars = [...new Array(18).keys()];

    calculateStatusBars(value: number): number {
        return Math.ceil(this.statusBars.length * value / 10);
    }

    rowClickedHandler(value: any) {
        this.rowClicked.emit(value);
    }

    constructor(private elementRef: ElementRef) {}

    ngOnInit(): void {
        const wrapper = this.elementRef.nativeElement.closest('dp-any-widget');
        if (!wrapper) {
            return;
        }
        wrapper.classList.add('wrapper-without-padding');
    }
}
