import { Directive, Injector, Input, OnDestroy, TemplateRef, ViewContainerRef, inject, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, pluck, scan, shareReplay } from 'rxjs/operators';

import { WidgetFilter } from 'data-processor';

import { DATA_MORPH_FEATURE_TOGGLE } from 'data-processor/tokens';
import { WidgetDebuggerState } from '../../../../widget-builder/services/widget.debugger';

@Directive({
    selector: '[dpFilterDataSource]',
    standalone: true,
})
export class FilterDataSourceWithState implements OnInit, OnDestroy {
    @Input('dpFilterDataSource') filter: WidgetFilter;
    readonly dataSource = inject(WidgetDebuggerState);

    private subscription = Subscription.EMPTY;
    private ft = inject(DATA_MORPH_FEATURE_TOGGLE);

    constructor(private vcr: ViewContainerRef, private templateRef: TemplateRef<unknown>, private injector: Injector) {}

    ngOnInit() {
        const ctx = {
            isLoading: false,
            items: [] as unknown,
        };

        let injector = this.injector;

        let filterDataSource = this.dataSource.dataSources[this.filter.placeholder];

        if (filterDataSource) {
            filterDataSource = filterDataSource.pipe(
                scan((acc, value) => {
                    acc.isLoading = value?.type === 'start';

                    if (!acc.isLoading) {
                        acc.items = value;
                    }

                    return acc;
                }, ctx),
                shareReplay(1)
            );

            this.subscription = filterDataSource.subscribe();
        }

        injector = Injector.create({
            providers: [
                {
                    provide: WidgetDebuggerState,
                    useValue: {
                        ...this.dataSource,
                        dataSources: { [this.filter.placeholder]: filterDataSource?.pipe(pluck('items'), distinctUntilChanged()) },
                    },
                },
            ],
            parent: this.injector,
        });

        this.vcr.createEmbeddedView(this.templateRef, ctx, {
            injector,
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
