<div *ngIf="isMap" class="d-flex flex-column overflow-hidden pl-0 pr-0">
    <section *ngIf="jdState.path$ | async as path" class="row no-gutters mb-25px w-100 d-inline-flex align-items-center">
        <dp-edit-job-definition-breadcrumb *ngIf="isFirstLevel" [path]="path"></dp-edit-job-definition-breadcrumb>
        <div class="col no-gutters header-text">
            {{ !isFirstLevel ? headerName : '' }}
        </div>
        <a
            [class.disabled]="!canAddMapItem"
            class="d-inline-flex justify-content-between cursor-pointer align-items-center add-text"
            (click)="addMapItem()"
        >
            <fa-icon icon="plus" class="mr-2"></fa-icon>
            Add {{ key }}
        </a>
    </section>
    <div *ngIf="((formSlice.controls | keyvalue) || []).length">
        <div class="row mb-10px" *ngIf="!isNotTypingMap">
            <div class="col-4 label">{{ nameMapping.key !== undefined ? nameMapping.key : 'Name' }}</div>
            <div class="col-8 label">{{ nameMapping.value !== undefined ? nameMapping.value : 'Value' }}</div>
        </div>

        <div class="row mb-10px" *ngIf="isNotTypingMap">
            <div class="col-4 label">{{ nameMapping.key !== undefined ? nameMapping.key : 'Name' }}</div>
            <div class="col-3 label">Type</div>
            <div class="pl-0 col label">{{ nameMapping.value !== undefined ? nameMapping.value : 'Value' }}</div>
        </div>
    </div>

    <div [formGroupName]="key" [class.maps-container]="isFirstLevel" [class.head-container]="isFirstLevel">
        <perfect-scrollbar>
            <div class="overflow-y-auto p-1">
                <div
                    *ngFor="
                        let recipient of formSlice.controls | keyvalue: sortByKey;
                        trackBy: trackMapFunction;
                        let i = index;
                        let last = last
                    "
                    class="row mb-30px align-items-start mx-0"
                    [class.mb-30px]="!last"
                >
                    <div class="col-4 pl-0">
                        <lib-input [value]="recipient.key" id="recipient" (change)="handleMapKeyChange(recipient.key, $event)"></lib-input>
                    </div>
                    <div class="col-7 align-items-start">
                        <ng-container *ngIf="fieldValueType != 'object'">
                            <lib-input
                                *ngIf="!isEnumMap"
                                [formControlName]="recipient.key"
                                (change)="handleMapValueChange(recipient.key, $event)"
                                id="recipient-2"
                            ></lib-input>
                            <lib-dropdown
                                keep-position
                                closeByScroll="true"
                                id="name"
                                class="dropdown"
                                [items]="items"
                                *ngIf="isEnumMap"
                                placeholder="Select"
                                valueKey="name"
                                labelKey="name"
                                appendTo="body"
                                [formControlName]="recipient.key"
                                (change)="handleMapDropdownValueChange(recipient.key, $event)"
                            >
                            </lib-dropdown>
                        </ng-container>
                        <dp-configure-job-definition-select-type
                            *ngIf="fieldValueType == 'object' && isNotTypingMap"
                            class="align-items-start"
                            [formControlName]="recipient.key"
                            (change)="setMapValueChange(recipient.key, $event)"
                        ></dp-configure-job-definition-select-type>
                        <div
                            *ngIf="fieldValueType == 'object' && !isNotTypingMap"
                            class="row pt-2 no-gutters d-inline-flex flex-nowrap nb w-100"
                        >
                            <div class="col-7">
                                {{ definition[recipient.key].getInfo() }}
                            </div>
                            <a
                                class="col d-inline-flex justify-content-end font-weight-bold cursor-pointer proceed-text align-items-center"
                                (click)="goToElement(recipient.key)"
                                >Proceed</a
                            >
                        </div>
                    </div>
                    <div class="col d-inline-flex justify-content-start">
                        <fa-icon icon="facTrash" class="cursor-pointer font-16px" (click)="removeMapItem(recipient.key)"></fa-icon>
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </div>
</div>

<div *ngIf="!isMap" class="d-flex flex-column overflow-hidden pr-0 pl-0" [formArrayName]="key">
    <section *ngIf="jdState.path$ | async as path" class="row no-gutters mb-20px w-100 d-inline-flex">
        <dp-edit-job-definition-breadcrumb *ngIf="isFirstLevel" [path]="path"></dp-edit-job-definition-breadcrumb>
        <div class="col no-gutters header-text">
            {{ !isFirstLevel ? headerName : '' }}
        </div>
        <a class="d-inline-flex justify-content-between cursor-pointer align-items-center add-text" (click)="addArrayItem()">
            <fa-icon icon="plus" class="mr-2"></fa-icon>
            Add {{ key }}
        </a>
    </section>
    <div [class.items-container]="isFirstLevel">
        <perfect-scrollbar>
            <div *ngFor="let item of formSlice.controls; let i = index; let last = last" class=" item-container row no-gutters">
                <div class="overflow-y-auto w-100">
                    <div *ngIf="fieldValueType == 'object'" class="row no-gutters d-inline-flex flex-nowrap w-100 py-1" [class.mb-10px]="!last">
                        <div class="col-md-1 d-inline-flex justify-content-start">{{ i }}</div>
                        <div class="col info-container text-truncate">
                            {{ definition[i]?.getInfo() }}
                        </div>
                        <a
                            class="ml-auto mr-4 font-weight-bold cursor-pointer proceed-text d-flex align-items-center"
                            (click)="goToElement(i)"
                        >
                            Proceed
                        </a>
                        <fa-icon icon="facTrash" class="cursor-pointer font-16px" (click)="removeArrayItem(i)"></fa-icon>
                    </div>
                    <div
                        *ngIf="fieldValueType == 'string'"
                        class="row no-gutters d-inline-flex flex-nowrap w-100 align-items-start py-1"
                        [class.mb-25px]="!last"
                    >
                        <div class="col-md-1 d-inline-flex justify-content-start mt-2">
                            <div class="label">{{ i }}</div>
                        </div>
                        <div class="col-10 d-inline-flex">
                            <lib-input
                                class="w-100"
                                [customErrors]="definitionErrors"
                                [formControlName]="i"
                                (change)="setArrayElement(i, $event)"
                                id="definition"
                            >
                            </lib-input>
                        </div>

                        <fa-icon icon="facTrash" class="mt-2 ml-auto cursor-pointer font-16px" (click)="removeArrayItem(i)"></fa-icon>
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </div>
</div>

<lib-app-warning-message
    class="cursor-pointer"
    (click)="handleDeleteWarningMessage(key)"
    [message]="(parent?.typingErrors)[key] ? warnings?.typingError((parent?.typingErrors)[key]) : null"
></lib-app-warning-message>

<lib-validation-errors [control]="formSlice">
    <ng-template valError="required">Must contain at least 1 element</ng-template>
    <ng-template valError="rangeRequired"> {{ definitionErrors.rangeRequired }} </ng-template>
    <ng-template valError="minOneNotEmpty"> {{ definitionErrors.minOneNotEmpty }} </ng-template>
</lib-validation-errors>
