<div *ngIf="items; else loadingTmpl" class="portfolio-scores-health">
    <dp-portfolio-score-square
        [data]="items.scoreHealth"
    >
    </dp-portfolio-score-square>
    <div class="vertical-line"></div>
    <dp-portfolio-score-square
        *ngFor="let category of items.categories"
        class="mr-4"
        [class.active]="activeTab === category.type"
        [data]="category"
        (click)="onClick(category)"
    >
    </dp-portfolio-score-square>
</div>
<ng-template #loadingTmpl>
    <lib-spinner class="spinner"></lib-spinner>
</ng-template>

