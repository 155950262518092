import { AnyWidgetModel, WidgetEventHandler, WidgetFilter } from '../../widget-builder/models/any-widget.model';

export namespace DataMorph {
    export type Dashboard = {
        id: number;
        dashboardId: number;
        uuid: string;
        options: any;
        tabs: DashboardTab[];
        header: string;
        name: string;
        description: string;
        dynamic: boolean;
        filter: WidgetFilter[];
        dashboardOptions: DashboardOptions;
    } & IlluminateDashboardOptions;

    export interface DashboardOptions {
        server: boolean;
        complexNamedDropdown?: boolean;
        complexNamedDropdownLabel?: string;
        complexNamedDropdownDependencies?: string;
        complexNamedDropdownFilterDependency?: string;
        visibility?: DashboardTabVisibility;
        filters: WidgetFilter[];
        handlers: WidgetEventHandler[];
    }

    export enum DashboardTabVisibility {
        SHOW_ALL = 'SHOW_ALL',
        SHOW_ACTIVE = 'SHOW_ACTIVE',
        HIDE_ALL = 'HIDE_ALL',
    }

    export enum DashboardGroupVisibility {
        DEFAULT = 'default',
        SINGLE = 'single',
        HIDE_HEADER = 'hide_header',
        HIDDEN = 'hidden',
    }

    export interface IlluminateDashboardOptions {
        status: boolean;
        scope: string;
        locked: boolean;
        template: boolean;
        userId: number;
        module: string;
        category: string;
    }

    export interface DashboardTab {
        id: number;
        name: string;
        dashboardId: number;
        options: Record<string, any>;
        groups: DashboardGroup[];
    }

    export interface DashboardGroup {
        id: number;
        dashboardId: number;
        tabId: number;
        groupOrder: number;
        name: string;
        definition: DashboardGroupDefinition;
        key?: number;
    }

    export interface DashboardGroupDefinition {
        headerColor: string;
        headerFontSize: number;
        type?: DashboardGroupVisibility;
    }

    export interface Widget<T = Record<string, any>> {
        id: number;
        uuid: string;
        dashboardWidgetId: number;
        dashboardId: number;
        groupId: number;
        widgetId: number;
        data: AnyWidgetModel;
        options: T;
    }

    export interface DashboardWidget<DashboardWidgetOptions = Record<string, any>> {
        dashboardId: number;
        uuid: string;
        dashboardWidgetId: number;
        widgetId: number;
        groupId: number;
        options: DashboardWidgetOptions;
        data: AnyWidgetModel;
    }

    export interface IlluminateDashboardWidget<IlluminateOptions = Record<string, any>> {
        id: number;
        uuid: string;
        illuminateDashboardWidgetId?: number;
        dashboardWidgetId: number;
        dashboardId: number;
        widgetId: number;
        illuminateOptions: IlluminateOptions;
        options: Record<string, any>;
        groupId: number;
        data: AnyWidgetModel;
    }
}
