import { Directive, HostListener, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[ngbNav]',
    exportAs: 'ngbNavStatus'
})
export class NgbNavStatusDirective implements OnInit {
    tabStatus: Record<string, boolean> = {};

    @Input() activeId: string;

    @HostListener('activeIdChange', ['$event'])
    onChange(id: string) {
        this.tabStatus[id] = true;
    }

    ngOnInit() {
        if (this.activeId) {
            this.tabStatus[this.activeId] = true;
        }
    }

    isTabInitialized(tabId: string) {
        return !!this.tabStatus[tabId];
    }
}
