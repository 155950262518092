<div class="card">
    <div class="modal-header">
        <h2 class="mt-0">{{ editMode ? (readonly ? 'View ' : 'Edit ') : 'Add New ' }}Variable</h2>
        <button type="button" class="close position-absolute" aria-label="Close button" aria-describedby="modal-title"
                (click)="cancel()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div [formGroup]="fieldConfiguration" class="modal-body h-100">
        <div class="col-md col-md-12 h-100 d-flex flex-column">
            <div class="row pb-15px">
                <div class="col pl-0">
                    <lib-input
                        formControlName="name"
                        label="Name"
                        id="name"
                        [customErrors]="{
                            pattern: 'only numbers, letters a-z, A-Z and symbols \'_\', inconsistent \'.\' between are allowed',
                            unique: 'Field Name must be unique and not begin as or contain a variable with dots'
                         }"
                    >
                        <ng-container *ngIf="fieldOptionsContextHelpTemplate">
                            <ng-template
                                *ngTemplateOutlet="fieldOptionsContextHelpTemplate; context: { contextHelpId: 'field-configurations-name' }"
                            ></ng-template>
                        </ng-container>
                    </lib-input>
                </div>
            </div>
            <div
                class="row"
                [ngClass]="
                    (typeControl.dirty && typeControl.errors) || (defaultValueControl.dirty && defaultValueControl.errors)
                        ? 'pb-7px'
                        : isSimpleType ? 'pb-20px' : 'pb-15px'
                "
            >
                <div class="col pl-0"
                     [ngClass]="(typeControl.dirty && typeControl.errors) ? 'height-with-error': ''"
                    [class.pr-0]="!isSimpleType"
                >
                    <lib-dropdown
                        class="font-11px"
                        label="Type"
                        style="min-width: 115px"
                        formControlName="type"
                        [items]="fieldTypeItems"
                        [searchable]="false"
                        [multiple]="false"
                        id="type"
                        [customErrors]="{ wrongType: 'Type doesn\'t match the form fields' }"
                        (valueChange)="onTypeChanged($event)"
                    >
                        <ng-container *ngIf="fieldOptionsContextHelpTemplate">
                            <ng-template
                                *ngTemplateOutlet="fieldOptionsContextHelpTemplate; context: { contextHelpId: 'field-configurations-type' }"
                            ></ng-template>
                        </ng-container>
                    </lib-dropdown>
                </div>

                <ng-container *ngIf="isSimpleType">
                    <div class="col pr-0">
                        <lib-input
                            formControlName="defaultValue"
                            label="Default value"
                            [propertiesCompletion]="propertiesCompletion"
                            id="default"
                            [customErrors]="{
                            wrongType: 'Default value is of the incorrect type',
                            defaultOption: 'Default value is not in the options list'
                        }"
                        >
                            <ng-container *ngIf="fieldOptionsContextHelpTemplate">
                                <ng-template
                                    *ngTemplateOutlet="
                                    fieldOptionsContextHelpTemplate;
                                    context: { contextHelpId: 'field-configurations-default-value' }
                                "
                                ></ng-template>
                            </ng-container>
                        </lib-input>
                    </div>
                </ng-container>
            </div>

            <ng-container *ngIf="isMultiLineTextAreaType">
                <div class="row pb-20px">
                    <lib-textarea
                        label="Default value"
                        id="defaultMulti"
                        aria-label="Default value"
                        formControlName="defaultValue"
                        [propertiesCompletion]="propertiesCompletion"
                        [resize]="false"
                        class="col px-0 w-100"
                        [customErrors]="{
                        wrongType: 'Default value is of the incorrect type',
                        defaultOption: 'Default value is not in the options list'
                    }"
                    >
                    </lib-textarea>
                    <ng-container *ngIf="fieldOptionsContextHelpTemplate">
                        <div [style.marginLeft.px]="-554">
                            <ng-template
                                *ngTemplateOutlet="
                                fieldOptionsContextHelpTemplate;
                                context: { contextHelpId: 'field-configurations-default-value' }
                            "
                            ></ng-template>
                        </div>
                    </ng-container>
                </div>
            </ng-container>

            <div class="row d-flex flex-column align-items-end justify-content-start">
                <h4 class="mt-0 mr-auto mb-3">
                    Options
                </h4>

                <checkbox class="col mb-3 mr-2 px-0" id="mandatory" formControlName="mandatory">
                    Required
                    <ng-container *ngIf="fieldOptionsContextHelpTemplate">
                        <ng-template
                            *ngTemplateOutlet="
                                fieldOptionsContextHelpTemplate;
                                context: { contextHelpId: 'field-configurations-mandatory' }
                            "
                        ></ng-template>
                    </ng-container>
                </checkbox>
                <checkbox *ngIf="isSimpleType" id="array" class="col mb-3 mr-2 px-0" formControlName="array">
                    Is Array
                    <ng-container *ngIf="fieldOptionsContextHelpTemplate">
                        <ng-template
                            *ngTemplateOutlet="fieldOptionsContextHelpTemplate; context: { contextHelpId: 'field-configurations-array' }"
                        ></ng-template>
                    </ng-container>
                </checkbox>
                <checkbox class="col mb-3 mr-2 px-0" id="runtime" formControlName="runtime">
                    Runtime
                    <ng-container *ngIf="fieldOptionsContextHelpTemplate">
                        <ng-template
                            *ngTemplateOutlet="fieldOptionsContextHelpTemplate; context: { contextHelpId: 'field-configurations-runtime' }"
                        ></ng-template>
                    </ng-container>
                </checkbox>
            </div>

            <div class="row pb-15px" *ngIf="isTextAreaType">
                <lib-codemirror-editor
                    class="col px-0 codemirror-container"
                    formControlName="defaultValue"
                    [propertiesCompletion]="propertiesCompletion"
                    label="Default value"
                    [mode]="'text/x-groovy'"
                ></lib-codemirror-editor>
            </div>

            <div class="row pb-15px">
                <lib-textarea
                    label="Description"
                    id="description"
                    aria-label="Description"
                    formControlName="description"
                    [resize]="false"
                    class="col px-0 w-100"
                >
                </lib-textarea>
                <ng-container *ngIf="fieldOptionsContextHelpTemplate">
                    <div [style.marginLeft.px]="-565">
                        <ng-template
                            *ngTemplateOutlet="
                                fieldOptionsContextHelpTemplate;
                                context: { contextHelpId: 'field-configurations-description' }
                            "
                        ></ng-template>
                    </div>
                </ng-container>
            </div>

            <div class="row flex-1" *ngIf="isSimpleType">
                <perfect-scrollbar class="col options-grid-group w-100 pb-2 px-0" [config]="perfectScrollbarConfig">
                    <div class="d-flex justify-content-end align-items-baseline mt-1">
                        <h4 class="mr-auto mt-0">
                            Picklist Items
                            <ng-container *ngIf="fieldOptionsContextHelpTemplate">
                                <ng-template
                                    *ngTemplateOutlet="
                                        fieldOptionsContextHelpTemplate;
                                        context: { contextHelpId: 'field-configurations-options' }
                                    "
                                ></ng-template>
                            </ng-container>
                        </h4>
                        <ng-container *ngIf="!readonly">
                            <button *ngIf="optionsControls?.controls?.length" type="button" class="btn btn-link size-s mr-3" (click)="deleteAllOptions()">
                                <fa-icon class="font-12px mr-5px" icon="facTrash"></fa-icon>
                                Delete All
                            </button>
                            <button type="button" class="btn btn-link size-s mr-1" (click)="addOption()">
                                <fa-icon class="font-12px mr-5px" icon="plus"></fa-icon>
                                Add New
                            </button>
                        </ng-container>
                    </div>

                    <table class="table data-grid sticky mt-10px mb-0 w-100">
                        <thead class="w-100">
                        <tr class="w-100">
                            <th
                                *ngFor="let c of optionsGridColumns"
                                [style.width]="c.width"
                                class="body2"
                                scope="col"
                            >
                                {{ c.title }}
                            </th>
                        </tr>
                        </thead>
                        <perfect-scrollbar
                            *ngIf="optionsControls?.controls?.length; else noOptionsData"
                            #optionsScrollRef
                            class="scrollable-body w-100"
                            [style.max-height]="'250px'"
                        >
                            <tbody formArrayName="options" class="w-100">
                            <tr
                                *ngFor="let optionControl of optionsControls.controls; let i = index"
                                [formGroupName]="i"
                                class="w-100"
                            >
                                <td>
                                    <lib-input
                                        class="font-12px"
                                        formControlName="label"
                                        [customErrors]="{ duplicateLabel: 'Label should be unique' }"
                                        id="field-1"
                                    >
                                    </lib-input>
                                </td>
                                <td>
                                    <lib-input
                                        class="font-12px"
                                        formControlName="value"
                                        id="field-2"
                                        [customErrors]="{
                                                wrongType: 'Option value is of the incorrect type',
                                                duplicateValue: 'Value should be unique'
                                            }"
                                    >
                                    </lib-input>
                                </td>
                                <td [style.width]="'10%'">
                                    <div class="d-inline-flex align-items-center border-0">
                                        <div
                                            *ngIf="!readonly"
                                            class="fa font-14px icons mt-8px"
                                            (click)="removeOption(i, optionsControls)"
                                        >
                                            <fa-icon
                                                icon="facTrash"
                                                ngbTooltip="Remove"
                                                placement="top"
                                                tooltipClass="new-field-icon-tooltip"
                                                container="body"
                                                [openDelay]="800"
                                            ></fa-icon>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </perfect-scrollbar>
                    </table>
                </perfect-scrollbar>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-secondary" type="button" (click)="cancel()">
            Cancel
        </button>
        <button class="btn btn-primary ml-25px" type="submit" *ngIf="!readonly" (click)="submit()">
            Save
        </button>
    </div>
</div>

<ng-template #noOptionsData>
    <div class="d-flex align-items-center justify-content-center mt-20px">
        <h1 class="mt-0">No Data</h1>
    </div>
    <div class="d-flex align-items-center justify-content-center mt-15px">
        <button class="btn btn-primary" (click)="addOption()">
            <fa-icon icon="plus" class="fa mr-5px"></fa-icon>
            Add New
        </button>
    </div>
</ng-template>
