import { Component, EventEmitter, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { DropdownItem, loadFile, validateFormAndDisplayErrors } from '@dagility-ui/kit';
import { EditToolTemplateFacade } from './edit-tool-template.facade';
import { removeContextHelp, ToasterService, ToolCategoryType, ToolTemplateService } from '@dagility-ui/shared-components';

@Component({
    selector: 'dp-edit-tool-template-popup',
    templateUrl: './edit-tool-template-popup.component.html',
    styleUrls: ['./edit-tool-template-popup.component.scss'],
    providers: [EditToolTemplateFacade],
})
export class EditToolTemplatePopupComponent implements OnInit, OnDestroy {
    @Output() toolTemplateSaved = new EventEmitter();

    @ViewChild(PerfectScrollbarComponent) scrollbar: PerfectScrollbarComponent;

    template: any = {};

    pluginCategoryTypes: DropdownItem<ToolCategoryType>[] = ([
        'CISRV',
        'SCM',
        'CSCAN',
        'PM',
        'OTHER',
        'ARTIFACTORY',
        'MONITORING',
        'NOTIFICATION',
        'ITSM',
        'TEST_MANAGEMENT',
    ] as const).map(label => ({label, value: label}));

    private destroyed$ = new Subject<void>();

    constructor(
        public activeModal: NgbActiveModal,
        private toolTemplateService: ToolTemplateService,
        private toster: ToasterService,
        private zone: NgZone,
        public facade: EditToolTemplateFacade
    ) {}

    ngOnInit() {
        this.facade.buildForm(this.template);
    }

    handleChooseImage() {
        loadFile('image/png,image/svg+xml,image/jpg,image/jpeg')
            .then(fileList => fileList.item(0))
            .then(
                importedImage =>
                    new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(importedImage);
                        reader.onload = () => resolve(reader.result);
                        reader.onerror = error => reject(error);
                    })
            )
            .then(base64Image => {
                this.facade.form.get('imageUrl').setValue(base64Image);
            })
            .catch(() => {/* rejected */});
    }

    handleDeleteImage() {
        this.facade.form.get('imageUrl').setValue('');
    }

    handleSaveToolTemplate() {
        if (!this.facade.form.valid) {
            validateFormAndDisplayErrors(this.facade.form);
            return;
        }

        this.facade.form.get('defaultSchedulerTime').setValue(+this.facade.form.get('defaultSchedulerTime').value);
        this.facade.form.get('sequence').setValue(+this.facade.form.get('sequence').value);
        let formValueToSave = this.facade.form.value;
        formValueToSave.pluginCategories = (formValueToSave.pluginCategories || []).map((item: DropdownItem | string) =>
            typeof item === 'string' ? { value: item } : item
        );
        formValueToSave.pluginCategories = formValueToSave.pluginCategories.map((x: { value: any }) => x.value);
        if (this.template.id) {
            formValueToSave = { id: this.template.id, ...formValueToSave };
        }
        this.toolTemplateService.updateToolTemplate(formValueToSave).subscribe(
            () => {
                this.toster.successToast({
                    title: 'Success',
                    content: 'Tool Template was saved',
                });
                this.toolTemplateSaved.emit();
                this.activeModal.dismiss();
            },
            result => {
                this.toster.errorToast({
                    title: 'Error',
                    content: `${result.message}`,
                });
            }
        );
    }

    handleAddAuthType() {
        this.zone.onMicrotaskEmpty.pipe(take(1), takeUntil(this.destroyed$)).subscribe(() => {
            this.scrollbar.directiveRef.scrollToBottom(0);
        });
    }

    onCrossClick() {
        removeContextHelp();
        this.activeModal.dismiss('Cross click');
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }
}
