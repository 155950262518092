import { inject, Injectable } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';

import { WidgetFilter } from '../../../widget-builder/models/any-widget.model';
import { VISIBLE_FILTERS } from './dashboard-filter-set.tokens';

@Injectable()
export class DashboardFiltersManager {
    private expandedModel = new SelectionModel<string>(true, [], true);

    filters$ = inject(VISIBLE_FILTERS);
    toggleAllState$: Observable<'expand' | 'collapse'> = combineLatest([
        this.filters$,
        this.expandedModel.changed.pipe(startWith(null as {})),
    ]).pipe(
        map(([filters]) => {
            if (filters.length === this.expandedModel.selected.length) {
                return 'collapse';
            }

            return 'expand';
        })
    );

    toggle({ placeholder }: WidgetFilter) {
        this.expandedModel.toggle(placeholder);
    }

    isExpanded({ placeholder }: WidgetFilter) {
        return this.expandedModel.isSelected(placeholder);
    }

    toggleAll(fromState: 'expand' | 'collapse') {
        if (fromState === 'collapse') {
            this.expandedModel.clear();
        } else {
            this.filters$.pipe(take(1)).subscribe(filters => {
                this.expandedModel.select(...filters.map(({ placeholder }) => placeholder));
            });
        }
    }
}
