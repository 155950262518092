import { Component, HostListener, ChangeDetectorRef, Input, Output, EventEmitter, ElementRef, OnDestroy } from '@angular/core';
import { QueryBuilderStore } from '@app/shared/components/query-builder/store/query-builder.store';
import { DataSetField, Model } from '@app/shared/components/query-builder/models/model-graph.model';
import { ExpressionMode } from '@app/shared/components/query-builder/models/model-graph-actions.model';
import { Subscription } from 'rxjs';
import { ExpressionBuilderService, FunctionItem, GridItem, ExpressionNodeType, ExpressionTemplate } from '../expression-builder.service';

interface DataSetFieldItem extends DataSetField {
    selected: boolean;
}

@Component({
    selector: 'app-qb-model-expression-helper',
    templateUrl: './expression-helper.component.html',
    styleUrls: ['./expression-helper.component.scss'],
})
export class ExpressionHelperComponent implements OnDestroy {
    expressionCursor: number = 0;
    searchString: string;
    expressionString: string;
    expressionFields: DataSetField[] = [];
    dataFields: DataSetFieldItem[] = [];
    dataParameters: string[] = [];
    allOperators: FunctionItem[] = [];
    allFunction: FunctionItem[] = [];
    dataOperators: FunctionItem[] = [];
    dataFunctions: FunctionItem[] = [];
    dataGrid: GridItem[] = [];
    types = ExpressionNodeType;
    selectedTable: Model;
    allTemplates: ExpressionTemplate[] = [];
    selectedItem: any = null;
    typeIcons: { [key: number]: string };
    expressionPartsSub: Subscription;
    suggestion: string;
    isControlDown: boolean;
    lastKey: string;
    isGroovyMode: boolean = false;
    @Input() selection: { start: number; end: number };
    @Input() active: boolean = false;
    @Input() set cursor(value: number) {
        this.expressionCursor = value;
        this.searchString = this.getNextString(this.expressionCursor);
        this.clearSelection();
        this.updateGridData();
    }

    @Input() set expression(value: string) {
        this.expressionString = value;
        this.searchString = this.getNextString(this.expressionCursor);
        this.clearSelection();
        this.updateGridData();
    }

    @Output() expressionChanged = new EventEmitter();

    constructor(
        private elRef: ElementRef,
        private queryBuilderStore: QueryBuilderStore,
        private cdr: ChangeDetectorRef,
        private expressionBuilderService: ExpressionBuilderService
    ) {
        this.typeIcons = this.expressionBuilderService.typeIcons;
        this.expressionPartsSub = this.expressionBuilderService.expressionParts$.subscribe(data => {
            this.allFunction = this.expressionBuilderService.mapFunctions(data.functions);
            this.allOperators = this.expressionBuilderService.mapFunctions(data.operators, true);
            this.dataParameters = data.parameters.map(param => `:${param}`);
            this.expressionFields = data.fields;
            this.isGroovyMode = data.mode === ExpressionMode.GROOVY;
            this.allTemplates = this.expressionBuilderService.mapTemplates(data.templates)
            this.updateGridData();
        });
    }

    clearSelection() {
        if (this.selectedItem) {
            this.selectedItem.selected = false;
        }
        this.selectedItem = null;
    }

    ngOnDestroy(): void {
        if (this.expressionPartsSub) {
            this.expressionPartsSub.unsubscribe();
        }
    }

    @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) {
        if (!this.active) {
            return;
        }
        switch (event.key) {
            case 'Control':
                this.isControlDown = false;
                break;
        }
    }
    @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        if (!this.active) {
            return;
        }
        const elementRef = this.elRef.nativeElement.querySelector('#dataFunctionsRef');
        let selectedItem = this.selectedItem;
        this.lastKey = event.key;
        switch (event.key) {
            case 'Control':
                this.isControlDown = true;
                break;
            case 'Escape':
                this.expressionChanged.emit({ type: 'expression-canceled' });
                break;
            case 'End':
                this.clearSelection();
                break;
            case 'Enter':
                if (selectedItem) {
                    this.onSelectItem(selectedItem);
                    this.suggestion = '';
                } else {
                    this.expressionChanged.emit({ type: 'expression-complete' });
                }
                this.clearSelection();
                break;
            case 'ArrowRight':
            case 'ArrowLeft':
                {
                    this.clearSelection();
                    selectedItem = this.selectedItem;
                    const grid: GridItem[] = this.dataGrid;
                    this.selectedItem = this.expressionBuilderService.selectItem('ArrowDown', grid, elementRef, selectedItem);
                }
                break;
            case 'ArrowDown':
            case 'ArrowUp':
                {
                    const grid: GridItem[] = this.dataGrid;
                    this.selectedItem = this.expressionBuilderService.selectItem(event.key, grid, elementRef, selectedItem);
                }
                break;
            default:
                break;
        }
    }

    updateGridData() {
        this.updateFields();
        this.updateFunctions();
        const filter = this.searchString;
        const templates: GridItem[] = this.allTemplates
            .map(t => ({
                label: t.text,
                isTemplate: true,
                data: t,
                type: ExpressionNodeType.template,
            }))
            .filter(item => item.label.includes(filter));
        const fields: GridItem[] = this.dataFields.map(field => ({
            data: field,
            selected: false,
            label: field.dsFieldName,
            type: ExpressionNodeType.field,
        }));
        const parameters: GridItem[] = this.dataParameters.map(param => ({
            data: param,
            selected: false,
            label: param,
            type: ExpressionNodeType.param,
        }));
        const functions: GridItem[] = this.dataFunctions.map(func => ({
            data: func,
            selected: false,
            label: func.name,
            type: ExpressionNodeType.func,
        }));

        if (this.isGroovyMode) {
            this.dataGrid = fields.concat(parameters);
        } else {
            this.dataGrid = templates.concat(functions.concat(fields)).concat(parameters);
        }
        if (['Backspace', 'Delete'].includes(this.lastKey) || this.isControlDown) {
            this.suggestion = '';
            this.expressionChanged.emit({
                type: 'expression-changed',
                expression: this.expressionString,
                expressionView: this.expressionString,
            });
            return;
        }
        if (this.dataGrid.length) {
            this.clearSelection();
            this.selectedItem = this.dataGrid[0];
            switch (this.selectedItem.type) {
                case ExpressionNodeType.template:
                    {
                        const item = this.selectedItem.data as ExpressionTemplate;
                        this.suggestion = item.text;
                    }
                    break;
                case ExpressionNodeType.operator:
                    {
                        const item = this.selectedItem.data as FunctionItem;
                        this.suggestion = item.name;
                    }
                    break;
                case ExpressionNodeType.func:
                    {
                        const item = this.selectedItem.data as FunctionItem;
                        this.suggestion = `${item.name}(${item.argTypes.map(arg => '$' + arg).join(',')})`;
                    }
                    break;
                case ExpressionNodeType.param:
                    {
                        this.suggestion = `${this.selectedItem.data}`;
                    }
                    break;
                case ExpressionNodeType.field:
                    {
                        const item = this.selectedItem.data as DataSetField;
                        this.suggestion = `${this.expressionBuilderService.modelNames[item.srcModelUid]}.${item.dsFieldName}`;
                    }
                    break;
            }
            this.selectedItem.selected = true;
        }
    }

    updateFunctions() {
        const filter = this.searchString;
        this.dataFunctions = this.allFunction
            .filter(item => item.name.includes(filter))
            .reverse()
            .sort((a, b) => (filter.indexOf(a.name) < filter.indexOf(b.name) ? 1 : -1));
        this.dataOperators = this.allOperators
            .filter(item => item.name.includes(filter))
            .reverse()
            .sort((a, b) => (filter.indexOf(a.name) < filter.indexOf(b.name) ? 1 : -1));
    }

    updateFields() {
        const modelGraph = this.queryBuilderStore.modelGraph;
        const filter = this.searchString;
        if (modelGraph) {
            this.dataFields = this.expressionFields
                .map(field => ({ ...field, selected: false }))
                .filter(field => !field.calculated)
                .filter(field => (this.selectedTable ? field.srcModelUid === this.selectedTable.uid : true));

            if (filter) {
                const hasDot = filter.includes('.');
                const modelFilter = filter.split('.')[0];
                const fieldFilter = hasDot ? filter.split('.')[1] : filter;
                const filteredByModel = this.dataFields.filter(field =>
                    this.expressionBuilderService.modelNames[field.srcModelUid]?.includes(modelFilter)
                );
                const filteredByField = (hasDot ? filteredByModel : this.dataFields).filter(field =>
                    field.dsFieldName.includes(fieldFilter)
                );
                if (hasDot) {
                    filteredByModel.splice(0, filteredByModel.length);
                }
                this.dataFields = filteredByModel.length ? Array.from(new Set(filteredByModel.concat(filteredByField))) : filteredByField;
            }
        }
    }

    fieldName(field: DataSetFieldItem) {
        return `${this.expressionBuilderService.modelNames[field.srcModelUid]}.${field.dsFieldName}`;
    }

    getNextString(cursor: number, searchLeft: boolean = true): string {
        const str = this.expressionString || '';
        const dividers: string[] = ['(', ')', ',', ' ', '*', '+', '-', '/', '<', '>', '='];
        let nextString = searchLeft ? str.substr(0, cursor) : str.substr(cursor, str.length);

        for (const divider of dividers) {
            if (searchLeft) {
                const index = nextString.lastIndexOf(divider);
                if (index >= 0) {
                    nextString = nextString.substr(index + 1, nextString.length);
                }
            } else {
                const index = nextString.indexOf(divider);
                if (index >= 0) {
                    nextString = nextString.substr(0, index);
                }
            }
        }

        return ['not', 'and', 'or', 'in', 'like', '/', '+', '*', '>', '<', '-'].includes(nextString) ? '' : nextString;
    }

    injectString(str: string) {
        const start = this.selection.start;
        const prevStr = this.getNextString(start, true);
        const expressionString = this.expressionString || '';
        this.expressionString =
            expressionString.substr(0, start - prevStr.length) +
            str +
            expressionString.substr(this.selection.end, expressionString.length - this.selection.end);
        const argIndex = this.expressionString.indexOf('$');
        let selectionStart;
        let selectionEnd;
        if (argIndex >= 0) {
            selectionStart = argIndex;
            const nextArg = this.getNextString(argIndex, false);
            selectionEnd = this.expressionString.indexOf(nextArg) + nextArg.length;
        }

        this.expressionCursor = selectionStart;

        this.updateGridData();
        return { selectionStart, selectionEnd };
    }

    onSelectItem(data: GridItem) {
        if (data.isTemplate) {
            const injectedTemplate = data.label;
            const { selectionStart, selectionEnd } = this.injectString(injectedTemplate);
            this.expressionChanged.emit({ type: 'select-field', expression: this.expressionString, selectionStart, selectionEnd });
            return;
        }
        switch (data.type) {
            case ExpressionNodeType.param:
                {
                    const item = data.data as string;
                    const { selectionStart, selectionEnd } = this.injectString(item);
                    this.expressionChanged.emit({ type: 'select-field', expression: this.expressionString, selectionStart, selectionEnd });
                }
                break;
            case ExpressionNodeType.field:
                {
                    const item = data.data as DataSetField;
                    const injectedField = `${this.expressionBuilderService.modelNames[item.srcModelUid]}.${item.dsFieldName}`;
                    const { selectionStart, selectionEnd } = this.injectString(injectedField);
                    this.expressionChanged.emit({ type: 'select-field', expression: this.expressionString, selectionStart, selectionEnd });
                }
                break;
            case ExpressionNodeType.operator:
            case ExpressionNodeType.func:
                {
                    const isOperator = data.type === ExpressionNodeType.operator;
                    const item = data.data as FunctionItem;
                    const injectedFunction = isOperator ? item.name : `${item.name}(${item.argTypes.map(arg => '$' + arg).join(',')})`;
                    const { selectionStart, selectionEnd } = this.injectString(injectedFunction);
                    this.expressionChanged.emit({
                        type: 'select-function',
                        expression: this.expressionString,
                        selectionStart,
                        selectionEnd,
                    });
                }
                break;
        }
    }
}
