import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { ModelGraphActionsService } from '@app/shared/components/query-builder/services/model-graph-actions.service';
import { QueryBuilderStore } from '@app/shared/components/query-builder/store/query-builder.store';
import { GridColumn, ModalService, Page, Pageable, Pager, Pagination, sortingDown, sortingUp } from '@dagility-ui/kit';
import { Observable } from 'rxjs';
import { LogModalComponent } from '@dagility-ui/shared-components';
import { QB_ICON_URLS } from '@app/shared/components/query-builder/consts/qb-icon-urls.const';
import { QbDataConverter } from '@app/shared/components/query-builder/qb-data/qb-data.converter';
import { QueryBuilderWidgetService } from '@app/shared/components/query-builder/services/query-builder-widget.service';
import { WidgetConfigRequest } from '@app/shared/components/query-builder/models/query-builder-widget.model';
import { AnyWidgetStore } from 'data-processor/lib/widget-library/widget-builder/components/widget/any-widget/any-widget.store';
import { anyWidgetProviders } from 'data-processor/lib/widget-library/widget-builder/providers/any-widget.providers';
import { WidgetWorkflowContainer } from 'data-processor/lib/widget-library/widget-builder/services/widget.flow';
import { AnyWidgetModel, AnyWidgetWrapperComponent, WidgetFilter } from 'data-processor';

const PAGE_SIZE = 10;

@Component({
    selector: 'app-query-builder-data',
    templateUrl: './qb-data.component.html',
    styleUrls: ['./qb-data.component.scss'],
    providers: [
        ...anyWidgetProviders,
        {
            provide: WidgetWorkflowContainer,
            useExisting: AnyWidgetWrapperComponent,
        },
    ],
})
export class QbDataComponent implements OnInit {
    isHovered = false;
    hintData: string;
    hintBox = { top: 0, left: 0 };
    loadingFilters: boolean = true;
    loadingPage: boolean = true;
    page: Page<Map<string, object>>;
    pagination: Pagination;
    pageable: Pageable;

    gridColumns: ExtendedGridColumn[];
    gridHeight: string;

    errors: string[];

    icons = QB_ICON_URLS;
    sortIcons = { sortingUp, sortingDown };

    options: AnyWidgetModel;
    filters: WidgetFilter[];
    filterPlaceholders: any;

    @Output() backButton: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private modelGraphActionsService: ModelGraphActionsService,
        private queryBuilderWidgetService: QueryBuilderWidgetService,
        private queryBuilderStore: QueryBuilderStore,
        private modalService: ModalService,
        private elRef: ElementRef,
        public store: AnyWidgetStore
    ) {}

    get hasErrors(): boolean {
        return this.queryBuilderStore.validationErrors.filter(error => error.level === 'ERROR').length > 0;
    }

    ngOnInit() {
        this.reset();
    }

    updatePlaceholders(state: AnyWidgetStore) {
        const filterPlaceholders = state?.workflow?.filters?.value;
        this.filterPlaceholders = {};
        Object.keys(filterPlaceholders)
            .filter(k => k !== 'complexNamedDropdown')
            .forEach(k => (this.filterPlaceholders[k] = filterPlaceholders[k]));
        this.getPage();
    }

    reset() {
        this.loadingFilters = true;
        this.loadingPage = true;
        this.page = null;
        this.pageable = Pageable.page(0, PAGE_SIZE);
        this.pageable.orders = [];
        this.options = null;
        this.filters = [];
        this.getAllData();
        this.updateGridHeight();
    }

    private getAllData() {
        this.loadingFilters = true;
        const widgetConfigRequest: WidgetConfigRequest = {
            graph: this.queryBuilderStore.globalModelGraph,
            widgetConfig: this.queryBuilderStore.drilldownGraph.widgetConfig,
            drillDownId: this.queryBuilderStore.drillDownId,
            widgetId: null,
        };
        this.queryBuilderWidgetService.getWidgetDataConfigNew(widgetConfigRequest).subscribe(
            result => {
                this.queryBuilderStore.updateStateSilent(result);
                this.options = result.graph.widgetConfig;
                this.filters = this.options.filters;
                this.loadingFilters = false;
                if (!this.filters?.length) {
                    this.getPage();
                }
            },
            () => (this.loadingFilters = false)
        );
    }

    changeSort(fieldName: string): void {
        if (!fieldName) {
            return;
        }
        this.onSort(fieldName);
    }

    setIsHovered(bool: boolean, item: any, event: any) {
        this.isHovered = bool;
        this.hintData = this.getModelNameById(item.modelId) + '.' + item.field;
        const { x, y, width: eventWidth } = event.target.getBoundingClientRect();
        const { height } = this.elRef.nativeElement.querySelector('#hintBoxRef').getBoundingClientRect();
        this.hintBox = { top: y - height, left: x - 200 + eventWidth };
    }

    handleShowErrorLogs(): void {
        this.modalService.open(
            LogModalComponent,
            {
                centered: true,
                backdrop: 'static',
                size: 'lg',
                windowClass: 'log-modal',
            },
            {
                title: 'Error Log',
                logs$: new Observable<{ fullLog: string }>(observer => {
                    observer.next({ fullLog: this.errors.join('\r\n') });
                    observer.complete();
                }),
            }
        );
    }

    onChangePage(data: Pager): void {
        this.pageable.update(data);
        this.getPage();
    }

    onSort(fieldName: string): void {
        if (!fieldName) {
            return;
        }
        this.pageable.addSortField(fieldName);
        this.getPage();
    }

    getModelNameById(id: string) {
        return this.queryBuilderStore.modelGraph?.models.find(model => model.uid === id)?.name;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.updateGridHeight(event.target.innerHeight);
    }

    private getPage() {
        const graph = this.queryBuilderStore.modelGraph;
        if (!graph) {
            return;
        }
        const placeholders = this.queryBuilderStore.placeholders;
        const placeholdersMap: Map<string, any> = new Map<string, any>();
        placeholders.forEach(placeholder => {
            placeholdersMap.set(placeholder.name, placeholder.value);
        });
        const placeholdersObject = Object.assign(
            {},
            ...Array.from(placeholdersMap.entries()).map(([k, v]) => ({ [k]: v })),
            this.filterPlaceholders
        );
        this.loadingPage = true;
        const previewRequest = {
            body: {
                graph,
                placeholders: placeholdersObject,
            },
            params: {
                page: this.pageable.page,
                size: this.pageable.size,
                sort: this.pageable.orders.map(o => `${this.getFieldUidByName(o.property)},${o.direction.toLowerCase()}`),
            },
        };
        this.modelGraphActionsService.dataPreview(previewRequest).subscribe(
            response => {
                this.page = QbDataConverter.convertDateInPage(this.queryBuilderStore.modelGraph, response.result);
                this.pagination = Pagination.of(response.result);
                this.initGridColumns(response.result);
                this.errors = response.errors;
                this.loadingPage = false;
            },
            () => (this.loadingPage = false)
        );
    }

    private initGridColumns(page: Page<Map<string, object>>) {
        const graph = this.queryBuilderStore.modelGraph;
        if (!graph) {
            return;
        }
        const gridColumns: ExtendedGridColumn[] = [];
        if (page.content.length === 0) {
            graph.dataSetFields.fields
                .filter(field => field.selected)
                .forEach(field => {
                    gridColumns.push({
                        modelId: field.srcModelUid,
                        title: field.dsFieldName,
                        field: field.dsDataField,
                        rights: ['read'],
                        width: 'auto',
                    });
                });
        } else {
            page.content
                .map(c => Object.keys(c))
                .reduce((a, b) => a.concat(b))
                .filter((c, i, arr) => arr.indexOf(c) === i)
                .forEach(key =>
                    graph.dataSetFields.fields
                        .filter(f => f.selected && f.dsDataField === key)
                        .filter((f, i, arr) => arr.map(ds => ds.dsDataField).indexOf(f.dsDataField) === i)
                        .forEach(f =>
                            gridColumns.push({
                                modelId: f.srcModelUid,
                                title: f.dsFieldName,
                                field: f.dsDataField,
                                sortingField: f.dsDataField,
                                width: 'auto',
                            })
                        )
                );
        }
        this.gridColumns = gridColumns;
    }

    private getFieldUidByName(fieldName: string): string {
        return this.queryBuilderStore.modelGraph?.dataSetFields?.fields
            .filter(f => f.selected && f.dsDataField === fieldName)
            .map(f => f.uid)
            .pop();
    }

    private updateGridHeight(height?: number): void {
        const gridHeight = height || window.innerHeight;
        this.gridHeight = `${gridHeight - 500}px`;
    }
}

export interface ExtendedGridColumn extends GridColumn {
    modelId: string;
}
