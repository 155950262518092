import { Component, Input, ViewChild } from '@angular/core';

import {TreeNode} from "../tool-graph-view.component";
import {
    ToolGraphChannel,
    ToolGraphLines
} from "../tool-graph-view-model";
import { SvgZoomDirective } from '@dagility-ui/shared-components';

@Component({
    selector: 'dp-tool-graph-view-layout',
    templateUrl: './tool-graph-view-layout.component.html',
    styleUrls: ['./tool-graph-view-layout.component.scss'],
})
export class ToolGraphViewLayoutComponent {
    @Input() lines: ToolGraphLines[];
    @Input() blocks: TreeNode[];
    @Input() channels: ToolGraphChannel[];

    @ViewChild('zoom', { static: false }) zoom: SvgZoomDirective;
}
