import { Provider, SkipSelf } from '@angular/core';
import { map, switchMap, take } from 'rxjs/operators';

import { DefaultPlaceholders, DEFAULT_PLACEHOLDERS } from '../../widget-builder/providers/default-placeholders.provider';
import { DpGlobalFiltersService } from './global-filters.service';

export function defaultPlaceholderFactory(
    defaultPlaceholders: DefaultPlaceholders<any>,
    store: DpGlobalFiltersService
): DefaultPlaceholders<any> {
    return widget =>
        store.filters$.pipe(
            switchMap(filters =>
                defaultPlaceholders(widget).pipe(
                    map(placeholders => ({
                        ...filters,
                        ...placeholders,
                    })),
                    take(1)
                )
            )
        );
}

export const DP_DASHBOARD_DEFAULT_PLACEHOLDERS_PROVIDER: Provider = {
    provide: DEFAULT_PLACEHOLDERS,
    deps: [[new SkipSelf(), DEFAULT_PLACEHOLDERS], DpGlobalFiltersService],
    useFactory: defaultPlaceholderFactory,
};
