<div class="modal-main-container model-saver">
    <div class="modal-header justify-content-between align-items-center">
        <h2 class="m-0">Save graph as Model</h2>
        <span style="cursor: pointer" (click)="modal.close({ valid: false })">✕</span>
    </div>
    <form class="modal-body" [formGroup]="saveForm">
        <div class="model-saver-field-block">
            <lib-input #nameInput
                formControlName="name"
                label="Model name"
                placeholder="Type a model name"
                [(ngModel)]="name"
                required
            >
            </lib-input>
            <lib-input
                class="mt-15px"
                #descInput
                formControlName="description"
                label="Model description"
                placeholder="Type a model description"
                [(ngModel)]="description"
                required
            >
            </lib-input>
        </div>
    </form>
    <div class="modal-footer justify-content-end align-items-center">
        <button type="button" class="btn btn-secondary mr-20px" (click)="modal.close({ valid: false })">
            Cancel
        </button>
        <button [disabled]="nameInput.input.nativeElement.value.length === 0 || descInput.input.nativeElement.value.length === 0" type="button" class="btn btn-primary" (click)="save()">
            Save
        </button>
    </div>
</div>
