import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    inject,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { combineLatest, fromEvent, Subject, Subscription, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SplitButtonItem } from '@dagility-ui/kit';

import { FILTER_PANEL_WIDTH, FiltersDirtyStateToken, WIDGET_FILTER_VISIBILITY_DEFAULT } from '../dashboard-filter-set.tokens';
import { GlobalFiltersWorkflow } from '../../../../widget-builder/services/global-filters.flow';
import { WidgetWorkflow } from '../../../../widget-builder/services/widget.flow';

interface ResetAction extends SplitButtonItem {
    action: () => void;
}

@Component({
    selector: 'dp-filter-set-panel',
    templateUrl: './filter-set-panel.component.html',
    styleUrls: ['./filter-set-panel.component.scss'],
})
export class FilterSetPanelComponent implements OnInit, OnDestroy {
    @Input() edit = false;
    @Input() origin: HTMLElement;

    @Output() collapse = new EventEmitter<void>();
    @Output() apply = new EventEmitter<void>();
    @Output() save = new EventEmitter<void>();
    @Output() reset = new EventEmitter<void>();
    @Output() resetToLastSaved = new EventEmitter<void>();
    @Output() widgetFilterVisibility = new EventEmitter();

    @HostBinding('style.width.px')
    width = inject(FILTER_PANEL_WIDTH);
    private resetToSavedAction: ResetAction = {
        label: 'Reset to Saved Filters',
        disabled: false,
        action: () => {
            this.resetToLastSavedVal();
            this.resetToLastSaved.emit();
        },
    };
    private resetToDefaultAction: ResetAction = {
        label: 'Reset to Default',
        disabled: false,
        action: this.resetToDefault.bind(this),
    };
    resetActions: ResetAction[] = [this.resetToDefaultAction, this.resetToSavedAction];
    workflow = inject(WidgetWorkflow) as GlobalFiltersWorkflow;
    filtersDirtyState = inject(FiltersDirtyStateToken);

    private zone = inject(NgZone);
    private filtersPanel: ElementRef<HTMLElement> = inject(ElementRef);
    private cdr = inject(ChangeDetectorRef);
    private clickOutsideSubscription = Subscription.EMPTY;
    private destroy$ = new Subject<void>();

    ngOnInit() {
        this.listenClickOutside();
        this.listenResetButtonState();
    }

    ngOnDestroy() {
        this.clickOutsideSubscription.unsubscribe();
        this.destroy$.next();
    }

    visibilityChanged(value: string) {
        this.filtersDirtyState.saveEnabled$ = new ReplaySubject<boolean>(1);
        this.filtersDirtyState.applyEnabled$ = new ReplaySubject<boolean>(1);
        this.widgetFilterVisibility.emit(value);
        this.listenResetButtonState();
    }

    private resetToLastSavedVal() {
        this.widgetFilterVisibility.emit(this.workflow.placeholders.widgetFilterVisibility);
    }

    private resetToDefault() {
        this.widgetFilterVisibility.emit(WIDGET_FILTER_VISIBILITY_DEFAULT);
        this.reset.emit();
    }

    private listenClickOutside() {
        this.clickOutsideSubscription.unsubscribe();
        const preventedElements: Array<(event: PointerEvent) => boolean> = [isClickOnDialog, isClickOnContextHelp, isClickOnPopup];

        this.zone.runOutsideAngular(() =>
            setTimeout(() => {
                this.clickOutsideSubscription = fromEvent(window, 'click').subscribe((event: PointerEvent) => {
                    const path = event.composedPath();

                    if (!path.some(pathEl => pathEl === this.filtersPanel.nativeElement) && !preventedElements.some(fn => fn(event))) {
                        this.zone.run(() => {
                            this.collapse.emit();
                        });
                    }
                });
            })
        );
    }

    private listenResetButtonState() {
        combineLatest([this.filtersDirtyState.resetToLastSavedEnabled$, this.filtersDirtyState.resetToDefaultEnabled$])
            .pipe(takeUntil(this.destroy$))
            .subscribe(([lastSavedState, defaultState]) => {
                this.resetToSavedAction.disabled = !lastSavedState;
                this.resetToDefaultAction.disabled = !defaultState;

                this.cdr.markForCheck();
            });
    }
}

function isClickOnContextHelp({ target }: PointerEvent) {
    return target instanceof HTMLElement && Boolean(target.closest('.context-help-wrap'));
}

function isClickOnDialog(event: PointerEvent) {
    const { target } = event;

    return target instanceof HTMLElement && event.composedPath().some(el => el instanceof HTMLElement && el.matches('ngb-modal-window'));
}

function isClickOnPopup({ target }: PointerEvent) {
    return target instanceof HTMLElement && Boolean(target.closest('lib-toastr'));
}
