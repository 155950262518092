<div class="font-11px font-weight-600 pl-2 py-1">
    {{ title }}
</div>
<perfect-scrollbar class="pl-2 scrollbar">
    <lib-side-menu *ngIf="options.menu.length" [menuItems]="options.menu" class="side-menu" (sideSubMenuClicked)="sideMenuClicked($event)">
        <ng-template sideMenuItemTemplate let-menuItem>
            <span class="text-truncate" [ngbTooltip]="menuItem.title" [container]="'body'">{{ menuItem.title }}</span>
        </ng-template>

        <ng-template sideMenuChildItemTemplate let-menuItem>
            <span class="ml-15px text-truncate" [ngbTooltip]="menuItem.title" [container]="'body'">{{ menuItem.title }}</span>
            <span
                *ngIf="menuItem.additionalInfo"
                class="text-white min-width-fit-content issue-badge d-inline-block ml-auto mr-3 font-11px"
                >{{ menuItem.additionalInfo }}</span
            >
        </ng-template>
    </lib-side-menu>
</perfect-scrollbar>
