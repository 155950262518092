import { Directive, ElementRef, Host, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { TourStepOption, InteractiveTourService } from './interactive-tour.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({selector: '[inttouranchor]'})
export class InteractiveTourAnchorPopoverDirective extends NgbPopover {
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[inttouranchor]'
})
export class InteractiveTourAnchorDirective implements OnInit, OnDestroy {
  @Input() inttouranchor: string;

  @Input('tourAnchorContext') context: any;

  private helperLayer: HTMLDivElement;

  private overlay: HTMLDivElement;

  el: HTMLElement;

  hostEl: HTMLElement;

  constructor(
    private tourService: InteractiveTourService,
    private elRef: ElementRef<HTMLElement>,
    @Host() private popoverDirective: InteractiveTourAnchorPopoverDirective
  ) {
    this.popoverDirective.triggers = 'manual';
    this.popoverDirective.autoClose = false;
    this.popoverDirective.popoverClass = 'tour-popover';
    this.el = this.hostEl = this.elRef.nativeElement;
  }

  ngOnInit(): void {
    this.tourService.register(this.inttouranchor, this);
  }

  showTourStep(step: TourStepOption, template: TemplateRef<any>): void {
    this.popoverDirective.ngbPopover = template;

    (this.popoverDirective as any)._elementRef = new ElementRef(this.el);
    this.popoverDirective.container = 'body';

    this.popoverDirective.placement = step.placement || 'auto';
    step.prevBtnTitle = step.prevBtnTitle || 'Previous';
    step.nextBtnTitle = step.nextBtnTitle || 'Next';
    step.endBtnTitle = step.endBtnTitle || 'End Tour';
    step.pauseBtnTitle = step.pauseBtnTitle || 'Pause';
    this.popoverDirective.popoverClass = 'element-wrapper';

    const context = {step, progress: .3};
    this.popoverDirective.open(context);

    this.addHelperLayer();
  }

  hideTourStep(): void {
    this.popoverDirective.close();
    this.removeHelperLayer();
  }

  private addHelperLayer(): void {
    this.el.classList.add('tour-element--show');

    const {top, left, width, height} = this.el.getBoundingClientRect();
    this.helperLayer = document.createElement('div');
    this.helperLayer.style.cssText = `position: absolute; top: ${top + window.pageYOffset}px; left:${left +
    window.pageXOffset}px; width: ${width}px; height: ${height}px; z-index: 1058; box-shadow: rgb(33 33 33 / 80%) 0px 0px 1px 2px, rgb(33 33 33 / 50%) 0px 0px 0px 5000px; opacity: 1;`;
    const dialogWrapper = this.hostEl.closest('ngb-modal-window');
    if (dialogWrapper) {
      dialogWrapper.appendChild(this.helperLayer);
    } else {
      document.body.appendChild(this.helperLayer);
    }


    this.overlay = document.createElement('div');
    this.overlay.classList.add('tour-overlay');
    this.overlay.style.cssText = 'inset: 0; position: fixed; cursor: not-allowed; z-index: 997; opacity: 0;';
    document.body.appendChild(this.overlay);
  }

  private removeHelperLayer(): void {
    if (this.helperLayer) {
      this.el.classList.remove('tour-element--show');
      this.helperLayer.parentNode.removeChild(this.helperLayer);
      this.helperLayer = null;

      this.overlay.parentNode.removeChild(this.overlay);
      this.overlay = null;
    }
  }

  ngOnDestroy(): void {
    this.tourService.unregister(this.inttouranchor);
    this.removeHelperLayer();
  }
}
