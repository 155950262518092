import { Deserializable } from '../../../models/job-definition';

export class GroovyAction extends Deserializable {
    _arrays = {};
    _class = {};
    _enums = {};
    _maps = {};
    meta = {
        _arrays: this._arrays,
        _class: this._class,
        _enums: this._enums,
        _maps: this._maps,
    };

    constructor(instance?: any) {
        super();
        this.fillByInstance(GroovyAction, instance);
    }
}
