export interface Command {
    next: string; //sourceId
    targetType: TargetType; //
    targetId: string;
    relationType: RelationType;
    action: ActionType;
    previous?: string; //oldOwnerId

    description?: string;
}

export enum PortfolioRelationType {
    PORTFOLIO = 'Portfolio',
    APPLICATION = 'Application',
}

export enum RelationType {
    CONTRIBUTOR = 'CONTRIBUTOR',
    FOLLOWER = 'FOLLOWER',
    SUBORDINATE = 'SUBORDINATE',
    OWNER = 'OWNER',
    PENDING = 'PENDING',
}

export enum ActionType {
    INSERT = 'INSERT',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
}

export enum TargetType {
    USER = 'USER',
    APPLICATION = 'APPLICATION',
}

export class AddSubordinateUserCommand implements Command {
    targetType = TargetType.USER;
    relationType = RelationType.SUBORDINATE;
    action = ActionType.INSERT;

    constructor(public next: string, public targetId: string) {}

    static of(nextUser: any, targetUser: any, approvalMode: boolean) {
        const command: Command = new AddSubordinateUserCommand(nextUser.id, targetUser.id);
        command.description = `${nextUser.name} requested a subordination to ${targetUser.name}`;

        return command;
    }
}

export class RemoveSubordinateUserCommand implements Command {
    targetType = TargetType.USER;
    relationType = RelationType.SUBORDINATE;
    action = ActionType.DELETE;

    constructor(public next: string, public targetId: string) {}

    static of(nextUser: any, targetUser: any, approvalMode: boolean = true) {
        const command: Command = new RemoveSubordinateUserCommand(nextUser.id, targetUser.id);
        command.description = `${targetUser.name} requested a remove subordination from ${nextUser.name}`;

        return command;
    }
}

export class AddFollowedUserCommand implements Command {
    targetType = TargetType.USER;
    relationType = RelationType.FOLLOWER;
    action = ActionType.INSERT;

    constructor(public next: string, public targetId: string) {}

    static of(nextUser: any, targetUser: any, approvalMode: boolean = true) {
        const command: Command = new AddFollowedUserCommand(nextUser.id, targetUser.id);
        command.description = `${targetUser.name} requested to follow ${nextUser.name}`;

        return command;
    }
}

export class RemoveFollowedUserCommand implements Command {
    targetType = TargetType.USER;
    relationType = RelationType.FOLLOWER;
    action = ActionType.DELETE;

    constructor(public next: string, public targetId: string) {}

    static of(nextUser: any, targetUser: any, approvalMode: boolean = true) {
        const command: Command = new RemoveFollowedUserCommand(nextUser.id, targetUser.id);
        command.description = `${targetUser.name} requested to unfollow ${nextUser.name}`;

        return command;
    }
}

export class AddFollowedAppCommand implements Command {
    targetType = TargetType.APPLICATION;
    relationType = RelationType.FOLLOWER;
    action = ActionType.INSERT;

    constructor(public next: string, public targetId: string) {}
}

export class RemoveFollowedAppCommand implements Command {
    targetType = TargetType.APPLICATION;
    relationType = RelationType.FOLLOWER;
    action = ActionType.DELETE;

    constructor(public next: string, public targetId: string) {}
}

export class AddContributedAppCommand implements Command {
    public targetType = TargetType.APPLICATION;
    public relationType = RelationType.CONTRIBUTOR;
    public action = ActionType.INSERT;

    constructor(public next: string, public targetId: string) {}
}

export class RemoveContributedAppCommand implements Command {
    targetType = TargetType.APPLICATION;
    relationType = RelationType.CONTRIBUTOR;
    action = ActionType.DELETE;

    constructor(public next: string, public targetId: string) {}
}

export class AddRelatedAppCommand implements Command {
    targetType = TargetType.APPLICATION;
    relationType = RelationType.CONTRIBUTOR;
    action = ActionType.INSERT;

    constructor(public next: string, public targetId: string) {}

    static of(nextUser: any, targetApp: any, relationType: RelationType, approvalMode: boolean = true) {
        const command: Command = new AddRelatedAppCommand(nextUser.id, targetApp.id);
        command.relationType = relationType;
        command.description = `${nextUser.name} requested ${relationType === RelationType.FOLLOWER ? 'to follow' : 'a contribution to'} ${
            targetApp.name
        }`;

        return command;
    }
}

export class RemoveRelatedAppCommand implements Command {
    targetType = TargetType.APPLICATION;
    relationType = RelationType.CONTRIBUTOR;
    action = ActionType.DELETE;

    constructor(public next: string, public targetId: string) {}

    static of(user: any, app: any, relationType: RelationType, approvalMode: boolean = true) {
        const command: Command = new RemoveRelatedAppCommand(user.id, app.id);
        command.relationType = relationType;
        command.description =
            relationType === RelationType.FOLLOWER
                ? `${user.name} requested to unfollow ${app.name}`
                : `${user.name} requested to remove a contribution from ${app.name}`;

        return command;
    }
}

export class UpdateUserCommand implements Command {
    targetType = TargetType.USER;
    relationType = RelationType.SUBORDINATE;
    action = ActionType.UPDATE;

    constructor(public next: string, public targetId: string, public previous: string) {}

    static of(newManager: any, targetUser: any, previousManager: any, approvalMode: boolean = true) {
        const command: Command = new UpdateUserCommand(newManager.id, targetUser.id, previousManager.id);
        command.description = `${targetUser.name} requested to change a manager from ${previousManager.name} to ${newManager.name}`;

        return command;
    }
}
