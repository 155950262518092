import { Component, Input } from '@angular/core';
import { SreGanttService } from '../../../services/sre-gantt.service';

@Component({
    selector: 'dp-sre-gantt-column',
    templateUrl: './sre-gantt-column.component.html',
    styleUrls: ['./sre-gantt-column.component.scss'],
})
export class SreGanttColumnComponent {
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _value: number;
    private _firstValue: boolean;

    @Input() set value(value: number) {
        this._value = value;
    }

    @Input() set firstValue(value: boolean) {
        this._firstValue = value;
    }

    get value() {
        return this._value;
    }

    get firstValue() {
        return this._firstValue;
    }

    constructor(public service: SreGanttService) {}
}
