<button
    class="btn btn-secondary"
    [ngSwitch]="ui.topErrorLevel"
    [class.notice-button]="!ui.topErrorLevel || ui.topErrorLevel === 'NOTICE'"
    [class.warning-button]="ui.topErrorLevel === 'WARNING'"
    [class.error-button]="ui.topErrorLevel === 'ERROR'"
    (click)="opened = !opened"
    [disabled]="!issues?.length"
>
    <fa-icon icon="facInfoCircle" class="fa mr-7px" style="font-size: 18px" *ngSwitchCase="'NOTICE'"></fa-icon>
    <fa-icon icon="exclamationTriangle" class="fa mr-7px" style="font-size: 18px" *ngSwitchCase="'WARNING'"></fa-icon>
    <fa-icon icon="facExclamationCircle" class="fa mr-7px" style="font-size: 18px" *ngSwitchCase="'ERROR'"></fa-icon>
    {{ ui.buttonText }}
    <span class="counter-badge counter">{{ ui.topErrorCount ? ui.topErrorCount : 0 }}</span>
</button>
<div class="validation-window" *ngIf="opened">
    <nav
        ngbNav
        #validationNav="ngbNav"
        [(activeId)]="activeError"
        class="nav nav-tabs justify-content-start main-content-header main-content-header-shadow pb-0 header"
    >
        <div class="w-100 d-flex justify-content-between align-items-center" style="height: 30px">
            <h2>{{ ui.windowTitle }}</h2>
            <span style="cursor: pointer; font-size: 12px;" (click)="opened = false">✕</span>
        </div>
        <ng-container ngbNavItem="Notice">
            <a ngbNavLink class="notice-nav">
                {{ ui.notice.title }}
                <span class="counter-badge counter">{{ ui.notice.count }}</span>
            </a>
            <ng-template ngbNavContent>
                <div class="item" *ngFor="let notice of ui.notice.items">
                    <div class="icon">
                        <fa-icon icon="facInfoCircle" class="fa item-icon notice"></fa-icon>
                    </div>
                    <div class="content">
                        <div class="context">
                            {{ notice.context }}
                        </div>
                        <div class="text">
                            {{ notice.text }}
                        </div>
                    </div>
                </div>
                <div *ngIf="!ui?.notice.count" class="no-data">
                    <h1>No data</h1>
                </div>
            </ng-template>
        </ng-container>
        <ng-container ngbNavItem="Warning">
            <a ngbNavLink class="warning-nav">
                {{ ui.warning.title }}
                <span class="counter-badge counter">{{ ui.warning.count }}</span>
            </a>
            <ng-template ngbNavContent>
                <div class="item" *ngFor="let warning of ui.warning.items">
                    <div class="icon">
                        <fa-icon icon="exclamationTriangle" class="fa warning"></fa-icon>
                    </div>
                    <div class="content">
                        <div class="context">
                            {{ warning.context }}
                        </div>
                        <div class="text">
                            {{ warning.text }}
                        </div>
                    </div>
                </div>
                <div *ngIf="!ui?.warning.count" class="no-data">
                    <h1>No data</h1>
                </div>
            </ng-template>
        </ng-container>
        <ng-container ngbNavItem="Error">
            <a ngbNavLink class="error-nav">
                {{ ui.error.title }}
                <span class="counter-badge counter">{{ ui.error.count }}</span>
            </a>
            <ng-template ngbNavContent>
                <div class="item" *ngFor="let error of ui.error.items">
                    <div class="icon">
                        <fa-icon icon="facExclamationCircle" class="fa error"></fa-icon>
                    </div>
                    <div class="content">
                        <div class="context">
                            {{ error.context }}
                        </div>
                        <div class="text">
                            {{ error.text }}
                        </div>
                    </div>
                </div>
                <div *ngIf="!ui?.error.count" class="no-data">
                    <h1>No data</h1>
                </div>
            </ng-template>
        </ng-container>
    </nav>
    <div class="pt-0 main-content-header-shadow" style="height: 100%" [ngbNavOutlet]="validationNav"
         perfectScrollbar></div>
</div>
