import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
    selector: 'app-publish-modal',
    templateUrl: './publish-modal.component.html',
    styleUrls: ['./publish-modal.component.scss']
})
export class PublishModalComponent implements OnInit {
    @Input() title = 'Publish To Marketplace';
    @Input() subTitle = 'Template will be published in Marketplace after review ';

    form: FormGroup;

    isSent: EventEmitter<PublishInfo> = new EventEmitter(); //

    constructor(public modal: NgbActiveModal) {
    }

    ngOnInit() {
        this.initForm();
    }

    handleSend() {
        const changelog = this.form.get('changelog').value ? this.form.get('changelog').value : '';
        const comment = this.form.get('comment').value ? this.form.get('comment').value : '';
        this.isSent.emit({
            changelog,
            comment
        });
        this.modal.dismiss('Send button clicked');
    }

    handleCancel() {
        this.modal.dismiss();
    }

    private initForm(): void {
        this.form = new FormGroup({
            changelog: new FormControl(null),
            comment: new FormControl(null)
        });
    }
}

export interface PublishInfo {
    comment: string;
    changelog: string;
}
