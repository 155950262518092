<dp-edit-job-definition-breadcrumb class="mb-25px d-block" [path]="state.path$ | async"></dp-edit-job-definition-breadcrumb>
<div class="pb-4 overflow-y-auto d-flex flex-column flex-1">
    <perfect-scrollbar>
        <lib-codemirror-editor
                *ngFor="let script of scriptFields"
                class="pb-4 mh-0 pr-3"
                mode="text/x-groovy"
                [label]="script.label"
                [(ngModel)]="scripts[script.field]"
        ></lib-codemirror-editor>
    </perfect-scrollbar>
</div>
