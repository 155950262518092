import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { AuthService } from '@app/auth';

import { filter, map } from 'rxjs/operators';
import { HealthService } from '@app/shared/services/admin/health-service';
import { RbacStaticType } from '@dagility-ui/kit';

@Injectable({
    providedIn: 'root',
})
export class EnvironmentsGuard implements CanActivate {
    constructor(private authorizationService: AuthService, private healthService: HealthService) {}

    canActivate(): Observable<boolean> {
        // filter need if we don't receive response from isServiceAlive
        return combineLatest([
            this.authorizationService.hasPermission(RbacStaticType.OBJECT, 'Habitat', 'read'),
            this.healthService.getIsServiceAlive('habitat'),
        ]).pipe(
            filter((res: boolean[]) => res[1] !== null),
            map(([hasRights, isAlive]) => hasRights && isAlive)
        );
    }
}
