import { Deserializable } from '../../../models/job-definition';
import { Operand } from './operand';

export class CalculationAction extends Deserializable {
    _arrays = {};
    _enums = {};
    _class = {
        operand: Operand,
    };
    _maps = {};
    meta = {
        _arrays: this._arrays,
        _enums: this._enums,
        _class: this._class,
        _maps: this._maps,
    };

    operand: Operand = new Operand();
    variable = '';

    constructor(instance?: any) {
        super();
        this.fillByInstance(CalculationAction, instance);
    }
}
