<lib-chart class="col-7" type="linechart" [options]="options.lineOptions" [checkHostResize]="true"></lib-chart>
<div class="col-5 d-flex flex-column justify-content-end align-items-end">
    <div>
        <div *ngIf="options.delta !== 0" class="difference-block" [ngClass]="options.delta > 0 ? 'metric-up-color' : 'metric-down-color'">
            <fa-icon [icon]="options.delta > 0 ? 'triangleUp' : 'triangleDown'"></fa-icon>
            {{ options.delta > 0 ? '+' : '' }}
            {{ options.delta | number: '1.0-1' }}
        </div>
        <div *ngIf="options.delta === 0" class="difference-block">
            {{ options.score === 0 ? 'No Data' : 'No Change' }}
        </div>
        <div class="month-block">This month</div>
        <div class="score-block mb-3">
            <span class="current-score" [style.color]="options.scoreColor">{{ options.score | number: '1.1-1' }}</span>
            /5 score
        </div>
    </div>
</div>

<ng-template #tooltipTemplate let-currentScore="currentScore" let-date="date" let-title="title" let-currentScoreColor="currentScoreColor">
    <div style="width: 150px">
        <div class="d-flex align-items-center mb-1">
            <svg class="mr-2" width="12" height="12" viewPort="0 0 12 12">
                <circle cx="6" cy="6" r="5" [attr.fill]="options.scoreColor"></circle>
            </svg>
            <span class="tooltip-header flex-1 text-truncate">
                {{ title }}
            </span>
        </div>
        <div class="month-block mb-1">{{ date }}</div>
        <div class="d-flex align-items-center">
            <div>Score</div>
            <div class="ml-auto d-flex align-items-center">
                <svg class="mr-2" width="12" height="12" viewPort="0 0 12 12">
                    <circle cx="6" cy="6" r="5" [attr.fill]="currentScoreColor"></circle>
                </svg>
                {{ currentScore | number: '1.1-1' }}
            </div>
        </div>
    </div>
</ng-template>
