export function prettifyObject<T extends Record<string, unknown>>(obj: T | unknown[] | unknown, removeEmptyFields = true): any {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map(item => prettifyObject(item as any, removeEmptyFields));
    }

    return Object.keys(obj)
        .sort()
        .reduce((acc, key) => {
            const value = (obj as Record<string, unknown>)[key];

            if (removeEmptyFields && (value === '' || value === null || value === undefined)) {
                return acc;
            }

            acc[key] = prettifyObject(value as any, removeEmptyFields);

            return acc;
        }, {} as Record<string, unknown>);
}
