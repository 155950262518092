import { Page } from '@dagility-ui/kit';
import { ModelGraph } from '@app/shared/components/query-builder/models/model-graph.model';

export class QbDataConverter {
    static convertDateInPage(modelGraph: ModelGraph, sourcePage: Page<Map<string, object>>) {
        const content = sourcePage.content;
        const datasetFields = modelGraph.dataSetFields.fields;
        const datasetFieldsDateType = datasetFields.filter(field => field.type?.toLocaleLowerCase() === 'date');
        datasetFieldsDateType.forEach(field => {
            const dsFieldName = field.dsFieldName;
            content.forEach(data => {
                const date = (data as any)
                if(typeof(date[dsFieldName]) as string === 'number')
                    date[dsFieldName] = new Date(date[dsFieldName] as number).toLocaleString();
            });
        });
        return sourcePage;
    }
}
