import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { GridColumn, SortDirection } from '@dagility-ui/kit';
import { QueryBuilderStore } from '@app/shared/components/query-builder/store/query-builder.store';
import { ModelGraphActionsService } from '@app/shared/components/query-builder/services/model-graph-actions.service';
import { FieldOrder } from '@app/shared/components/query-builder/models/model-graph-actions.model';

@Component({
    selector: 'app-qb-model-order-by-table',
    templateUrl: './order-by-table.component.html',
    styleUrls: ['./order-by-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderByTableComponent implements OnInit {
    gridData: OrderByField[] = [];

    gridDataSelected: OrderByField[] = [];

    gridColumns: GridColumn[] = [{ title: 'field' }];
    modelNames: { [key: string]: string } = {};

    fields: FieldOrder[] = [];

    constructor(private queryBuilderStore: QueryBuilderStore, private modelGraphActionsService: ModelGraphActionsService) {}

    ngOnInit() {
        this.gridDataFromFields();
    }

    @HostListener('document:queryBuilderChanged', ['$event'])
    gridDataFromFields = (): any => {
        const modelGraph = this.queryBuilderStore.modelGraph;

        this.modelNames = modelGraph?.models.reduce((prev: { [key: string]: string }, current) => {
            prev[current.uid] = current.name;
            return prev;
        }, {});

        this.gridData = modelGraph?.dataSetFields?.fields
            .filter(field => field.selected)
            .filter(field => !field.orderBy)
            .map(field => ({
                displayedName: this.modelNames[field.srcModelUid]
                    ? this.modelNames[field.srcModelUid] + '.' + field.dsFieldName
                    : field.dsFieldName,
                modelUid: field.srcModelUid,
                dsFieldName: field.dsFieldName,
                dsFieldUid: field.uid,
                selected: false,
            }));
        this.gridDataSelected = modelGraph?.dataSetFields?.fields
            .filter(field => field.selected)
            .filter(field => field.orderBy && field.orderBy.dir)
            .map(field => ({
                displayedName: this.modelNames[field.srcModelUid]
                    ? this.modelNames[field.srcModelUid] + '.' + field.dsFieldName
                    : field.dsFieldName,
                modelUid: field.srcModelUid,
                dsFieldName: field.dsFieldName,
                dsFieldUid: field.uid,
                selected: false,
                ord: field.orderBy.ord,
                dir: field.orderBy.dir,
            }))
            .sort((field1, field2) => (field1.ord > field2.ord ? 1 : -1));
        this.fields = this.gridDataSelected.map(field => ({
            dsFieldUid: field.dsFieldUid,
            dir: field.dir,
        }));
    };

    handleRemove(item: OrderByField) {
        this.fields = this.fields.filter(field => field.dsFieldUid != item.dsFieldUid);
        this.updateOrderByFields();
    }

    handleAdd(field: OrderByField) {
        this.fields.push({
            dsFieldUid: field.dsFieldUid,
            dir: 'ASC',
        });
        this.updateOrderByFields();
    }

    onSelectRow(field: OrderByField) {
        field.selected = !field.selected;
    }

    handlePushSelected(gridFrom: OrderByField[]) {
        const fields: FieldOrder[] = gridFrom
            .filter(field => field.selected)
            .map(field => ({
                dsFieldUid: field.dsFieldUid,
                dir: 'ASC',
            }));
        if (gridFrom === this.gridData) {
            fields.forEach(field => this.fields.push(field));
        } else {
            this.fields = this.fields.filter(field => !fields.map(item => item.dsFieldUid).includes(field.dsFieldUid));
        }
        this.updateOrderByFields();
    }

    updateSort(field: OrderByField, dir: SortDirection) {
        if (dir === undefined) {
            this.fields = this.fields.filter(f => f.dsFieldUid !== field.dsFieldUid);
        } else {
            this.fields.find(f => f.dsFieldUid === field.dsFieldUid).dir = dir;
        }
        this.updateOrderByFields();
    }

    handleDropListDropped(event: CdkDragDrop<OrderByField[]>) {
        moveItemInArray(this.gridDataSelected, event.previousIndex, event.currentIndex);
        this.fields = this.gridDataSelected.map( field => (
            {
                dsFieldUid: field.dsFieldUid,
                dir: field.dir,
            } as FieldOrder)
        );
        this.updateOrderByFields();
    }

    hasSelectedFields(gridData: OrderByField[]) {
        return gridData.some(field => field.selected);
    }


    handleCancelSelection(gridData: OrderByField[]) {
        gridData.forEach(field => field.selected = false);
    }

    private updateOrderByFields() {
        const fields = this.fields;
        this.modelGraphActionsService
            .updateOrderByFields({ fields, sourceGraph: this.queryBuilderStore.globalModelGraph })
            .subscribe(result => this.queryBuilderStore.updateState(result));
    }
}

interface OrderByField {
    displayedName: string;
    modelUid: string;
    dsFieldName: string;
    dsFieldUid: string;
    selected: boolean;
    ord?: number;
    dir?: SortDirection;
}
