import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Inject,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { DropdownItem, ModalService } from '@dagility-ui/kit';
import { ToolService } from '@dagility-ui/shared-components';

import { GridsterOptionsService } from '../dashboard/services/gridster/gridster-options.service';
import { WidgetBuilderFacade } from './state/widget-builder.facade';
import { AnyWidget } from './services/widget-builder.service';
import { AnyWidgetModel, WidgetEventHandler, WidgetQueryType } from './models/any-widget.model';
import { convertGraphToAnyWidget, normalizeWidgetModel, WidgetAction } from './models/widget.graph';
import { WidgetBuilderState, WidgetBuilderStore } from './services/widget-builder.store';
import { WidgetDebuggerState } from './services/widget.debugger';
import { WIDGET_DATA_TOKEN, WidgetBuilderData } from './providers/widget-data.provider';
import { getDrilldownConverter } from './services/widget.drilldown';
import { WidgetWorkflowContainer } from './services/widget.flow';
import { AnyWidgetWrapperComponent } from 'data-processor/lib/widget-library/widget-builder/components/widget/any-widget-wrapper/any-widget-wrapper.component';
import {
    DEFAULT_PLACEHOLDERS,
    DefaultPlaceholders,
} from 'data-processor/lib/widget-library/widget-builder/providers/default-placeholders.provider';
import { AsyncQueryBlock } from 'data-processor/lib/widget-library/widget-builder/models/query.block';
import { GLOBAL_FILTERS_EDITOR } from './providers/global-filters-editor.token';
import { moveLineFieldsToOldFields } from './services/widget.lines';
import { WIDGET_BUILDER_TAB, WidgetBuilderTab } from './providers/widget-builder-tab.token';
import { WidgetDebuggerContainer } from './services/widget-debugger.manager';

interface ExtrasState {
    fromLink?: string;
    fromClone?: string;
}

@Component({
    selector: 'dp-widget-builder',
    templateUrl: './widget-builder.component.html',
    styleUrls: ['./widget-builder.component.scss'],
    host: {
        class: 'widget-builder',
    },
    providers: [
        WidgetBuilderFacade,
        WidgetBuilderStore,
        WidgetDebuggerState,
        GridsterOptionsService,
        {
            provide: WIDGET_BUILDER_TAB,
            useExisting: forwardRef(() => WidgetBuilderComponent),
        },
        WidgetDebuggerContainer,
    ],
})
export class WidgetBuilderComponent implements OnInit, OnDestroy, WidgetBuilderTab {
    @Input() actionsTemplate: TemplateRef<any>;
    @Input() showBackLink = true;
    @Input() set externalOptions(options: Record<string, any>) {
        this.facade.externalOptions = options;
    }
    @Input() set externalWidgetId(id: number) {
        this.facade.externalWidgetId = id;
    }
    @Input() set extensions(ext: any[]) {
        this.facade.extensions = ext;
    }
    @Input() extensionsEnabled = false;

    @Output() saveFilters = new EventEmitter<any>();
    @Output() saveAndClose = new EventEmitter<any>();

    @ViewChild(WidgetWorkflowContainer, { static: false }) set widget(value: WidgetWorkflowContainer) {
        if (value) {
            this.facade.widgetWorkflow = value.workflow;

            setTimeout(() => {
                this.cdr.markForCheck();
            });
        } else {
            this.facade.widgetWorkflow = null;
        }
    }

    get rootWidget() {
        const widget = this.store.value;

        return widget.layers[widget.root];
    }

    get logs$() {
        return this.rootWidget.server ? this.facade.widgetWorkflow?.logs$ : this.debuggerContainer.debugger.logs$;
    }

    get placeholders$() {
        return this.rootWidget.server ? this.debuggerState.placeholders$ : this.debuggerContainer.debugger.currentPlaceholders$;
    }

    get sidenavContent() {
        return document.querySelector('lib-sidenav-content');
    }

    groups: DropdownItem<number>[] = [];
    previousUrl: string = null;

    data$: Observable<any>;
    model$: Observable<WidgetBuilderState> = this.store;

    activeBlock: any;

    isDirty() {
        return this.store.dirty;
    }

    canDeactivate(): boolean {
        return !this.facade.formState?.dirty;
    }

    constructor(
        public activeModal: NgbActiveModal,
        public store: WidgetBuilderStore,
        public facade: WidgetBuilderFacade,
        private toolService: ToolService,
        private debuggerState: WidgetDebuggerState,
        private debuggerContainer: WidgetDebuggerContainer,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private modalService: ModalService,
        @Inject(WIDGET_DATA_TOKEN) private widget$: WidgetBuilderData,
        @Inject(DEFAULT_PLACEHOLDERS) private defaultPlaceholders: DefaultPlaceholders,
        @Inject(GLOBAL_FILTERS_EDITOR) public globalFiltersMode: boolean
    ) {
        if (this.router.getCurrentNavigation()?.extras?.state) {
            const { fromLink, fromClone } = this.router.getCurrentNavigation().extras.state as ExtrasState;

            this.previousUrl = fromLink ?? null;
            this.facade.fromClone = !!fromClone;
        }
    }

    ngOnInit(): void {
        this.data$ = this.widget$.pipe(
            tap(({ id }) => {
                if (!id) {
                    return;
                }
                this.facade.anyWidgetId = +id;
            }),
            switchMap(model => {
                if (model.id) {
                    removeItemsField(model.data);
                    moveLineFieldsToOldFields(model.data);

                    return of(model);
                }

                let data: AnyWidgetModel & { common: Record<string, any> } = this.facade.buildForm({} as any, {
                    newWidget: true,
                    isRoot: true,
                }).value;
                data.query = [];
                data.filters = [];
                data = {
                    ...data,
                    ...data.common,
                    server: true,
                };
                data.chartOptions.title = 'Layer 0';

                return of({
                    id: null,
                    dashboardId: null,
                    data: data as AnyWidgetModel,
                } as AnyWidget);
            }),
            tap(({ data }) => {
                this.store.init(normalizeWidgetModel(data));
            })
        );

        this.initDataSources();
        this.updateStyles();
    }

    updateStyles() {
        this.sidenavContent?.classList.add('overflow-hidden');
    }

    initDataSources(): void {
        if (this.facade.dataSources$) {
            return;
        }

        this.facade.dataSources$ = this.toolService.getEnabledTools().pipe(
            map((toolIds: string[]) => [
                {
                    label: 'Postgresql',
                    value: WidgetQueryType.POSTGRESQL,
                },
                {
                    label: 'Clickhouse',
                    value: WidgetQueryType.CLICKHOUSE,
                },
                ...toolIds.map(toolId => ({ label: toolId, value: toolId } as DropdownItem)),
            ]),
            shareReplay(1)
        );
    }

    handleRemoveItem(): void {
        this.store.deleteGraphItems(this.activeBlock);
        this.activeBlock = null;
    }

    handleStartDebug(): void {
        this.activeBlock = null;
    }

    handleStopDebug(): void {
        this.debuggerContainer.debugger?.destroy();
        this.debuggerContainer.debugger = null;
        this.facade.currentWidget = null;
    }

    handleEditBlock(block: any): void {
        if (block && block.viewType === 'filter-dynamic' && block.data.query) {
            this.activeBlock = {
                ...block,
                data: {
                    ...block.data,
                    query: this.store.value.queries[block.data.query],
                },
            };
            return;
        }

        this.activeBlock = block;
    }

    handleEditDrilldown(drilldown: WidgetAction): void {
        this.activeBlock = drilldown;
    }

    handleEditEventHandler(event: WidgetEventHandler) {
        this.activeBlock = event;
    }

    handleAddFilter(layerId: string): void {
        this.store.addFilterToLayer(layerId);
    }

    handleAddQuery(layerId: string): void {
        this.store.addQueryToLayer(layerId);
    }

    handleAddDrilldown(layerId: string): void {
        this.store.addDrilldown(layerId);
    }

    handleAddEventHandler(layerId: string) {
        this.store.addEventHandler(layerId);
    }

    handleAddAsyncQuery(layerId: string) {
        this.store.addAsyncQuery(layerId);
    }

    handleCloseEdit(): void {
        this.activeBlock = null;
    }

    handleSaveBlock(data: any): void {
        if (
            this.activeBlock.collection === 'layers' &&
            !getDrilldownConverter(data.type) &&
            getDrilldownConverter(this.activeBlock.data.type)
        ) {
            this.store.deleteLayers(this.activeBlock.id, false);
        }

        if (this.activeBlock.viewType === 'handler') {
            this.store.updateEventHandler(this.activeBlock.layerId, this.activeBlock.index, data);
        } else if (this.activeBlock instanceof AsyncQueryBlock) {
            this.store.updateAsyncQuery(this.activeBlock.layerId, this.activeBlock.placeholder, data);
        } else if (this.activeBlock.viewType === 'filter-dynamic') {
            this.store.updateDynamicFilter(this.activeBlock.id, data);
        } else if (this.activeBlock.collection) {
            this.store.updateBlock(this.activeBlock.id, this.activeBlock.collection, data);
        } else {
            this.store.updateDrilldown((this.activeBlock as WidgetAction).id, data);
        }

        this.activeBlock = null;
    }

    back(): void {
        const state: Record<string, any> = this.facade.fromClone
            ? {
                  clonedId: this.facade.anyWidgetId,
              }
            : {};

        if (this.previousUrl) {
            this.router.navigate([this.previousUrl], { state });
        } else {
            this.router.navigate(['../'], { relativeTo: this.route, state });
        }
    }

    handleSaveFilters(filters: any) {
        this.saveFilters.emit(filters);
    }

    handleSaveAndClose(event: any) {
        this.saveAndClose.emit(event);
    }

    handlePreview() {
        const widget = convertGraphToAnyWidget(this.store.value.root, this.store.value);

        this.modalService.open(
            AnyWidgetWrapperComponent,
            {
                centered: true,
                size: 'xl',
                windowClass: 'any-widget-modal any-widget-help',
                injector: Injector.create({
                    providers: [
                        {
                            provide: DEFAULT_PLACEHOLDERS,
                            useValue: this.defaultPlaceholders,
                        },
                    ],
                }),
            },
            {
                options: widget,
                externalOptions: this.facade.externalOptions,
                showHelp: true,
                separatedView: true,
            }
        );
    }

    ngOnDestroy() {
        this.sidenavContent?.classList.remove('overflow-hidden');
    }
}

function removeItemsField(widget: AnyWidgetModel) {
    let queue: AnyWidgetModel[] = [widget];

    while (queue.length) {
        let currentWidget = queue.shift();

        for (const filter of currentWidget.filters ?? []) {
            delete (filter as any).query?.items;
        }

        for (const query of currentWidget.query ?? []) {
            delete (query as any).items;
        }

        queue = queue.concat((currentWidget.drilldownList ?? []).map(({ widget }) => widget));
        queue = queue.concat(currentWidget.widgets ?? []);
    }
}
