<div class="d-flex justify-content-center p-4">
    <div class="h2 m-0">Upload a Configuration File</div>
</div>
<div class="modal-body pt-0 px-4 pb-4 body1 d-flex">
    <div class="pl-2 pr-4 d-flex align-items-center">
        <fa-icon icon="facInfoCircle"></fa-icon>
    </div>
    <div class="flex-1">
        Upload a YAML file containing the required variables. The file must include the variable name and its corresponding type. Note that
        the file size cannot exceed 500 MB. The file can also include:
        <ol class="mt-3 ml-3 mb-0 p-0">
            <li class="pb-1">
                <strong>Default Value:</strong> This can be used as a substitution for the variable's input when triggering a pipeline or
                workflow.
            </li>
            <li class="pb-1"><strong>Options:</strong> These can be set as Required, Is Array, or Runtime.</li>
            <li class="pb-1">
                <strong>Picklist Items:</strong> These can also be used as a substitution for the variable's input when triggering a
                pipeline or workflow.
            </li>
        </ol>
    </div>
</div>
<div class="modal-footer border-top p-3 justify-content-center">
    <div class="d-flex align-items-center justify-content-center">
        <button class="btn btn-secondary font-11px ml-20px" (click)="activeModal.dismiss()">Cancel</button>
        <button class="btn btn-primary ml-3" [disabled]="uploading" [btnWithLoader]="uploading" (click)="doUpload()">
            Upload
        </button>
    </div>
</div>
