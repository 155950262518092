import { Component, ElementRef, Input, NgZone, OnInit } from '@angular/core';

@Component({
    selector: 'codemirror-merge-editor',
    template: '',
    styleUrls: ['./codemirror-merge-editor.component.scss'],
})
export class CodemirrorMergeEditorComponent implements OnInit {
    @Input() original: string;

    @Input() modified: string;

    @Input() mode = 'text/html';

    @Input() readonly = false;

    constructor(private zone: NgZone, private elRef: ElementRef) {}

    ngOnInit() {
        this.zone.runOutsideAngular(async () => {
            const { CodeMirror } = await import('projects/shared-components/modules/codemirror/codemirror.dependencies');
            await import('./codemirror-merge');
            CodeMirror.MergeView(this.elRef.nativeElement, {
                value: this.original,
                origLeft: null,
                orig: this.modified,
                mode: this.mode,
                readOnly: this.readonly,
                scrollbarStyle: 'simple',
                lineNumbers: true,
                highlightDifferences: true,
                connect: null,
                collapseIdentical: false,
            });
        });
    }
}
