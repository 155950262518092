<perfect-scrollbar
    class="two-dimensional-grid-wrapper"
    [config]="{ useBothWheelAxes: false, suppressScrollX: false, suppressScrollY: false }"
>
    <div>
        <table #table>
            <thead>
                <tr>
                    <th class="sticky-header" style="left: 0; z-index: 122"></th>
                    <th
                        [id]="c"
                        *ngFor="let c of tableData.verticalColumns"
                        class="sticky-header"
                        style="z-index: 120"
                        [ngbTooltip]="c"
                        container="body"
                        triggers="manual"
                        libTooltipWhenOverflow
                    >
                        {{ c }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let horizontalHeader of tableData.horizontalColumns; let i = index">
                    <td
                        class="sticky-column"
                        style="z-index: 120"
                        [ngbTooltip]="horizontalHeader"
                        container="body"
                        triggers="manual"
                        libTooltipWhenOverflow
                    >
                        {{ horizontalHeader }}
                    </td>
                    <ng-container *ngFor="let verticalHeader of tableData.verticalColumns">
                        <td
                            style="position: relative"
                            *ngIf="(tableData.data[horizontalHeader] || {})[verticalHeader] || {} as vm"
                            [ngbTooltip]="vm.value"
                            container="body"
                            triggers="manual"
                            libTooltipWhenOverflow
                            (click)="handleClick(vm.data, $event)"
                            [innerHTML]="(vm.value ? cellRenderer(vm.value) : '') | safeHtml: 'html'"
                        ></td>
                    </ng-container>
                </tr>
            </tbody>
        </table>
    </div>
</perfect-scrollbar>
