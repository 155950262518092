import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import {
    EOMessage,
    ExtendJob,
    Job,
    JobHistoryDetail,
    JobLog,
    JobNode,
    JobResponse,
    JobStageDetail,
    ProgressiveLogResponse,
} from '../model/jobs-management.classes';
import { map } from 'rxjs/operators';
import { toHttpParams } from '@dagility-ui/kit';
import { ENV_TOKEN } from '@app/tokens';

@Injectable({
    providedIn: 'root',
})
export class JobsManagementService {
    pipelineJobs = new BehaviorSubject<any>('');
    appSourcesBaseUrl = `${this.env.buildApiURL}/jobs-management`;

    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {}

    getJobsByProject(projectIds: number[]): Observable<JobResponse[]> {
        if (projectIds && projectIds.length === 1 && projectIds[0]) {
            return this.http.get<JobResponse[]>(`${this.appSourcesBaseUrl}/get-jobs-by-project/${projectIds[0].toString()}`);
        } else {
            return this.http.post<JobResponse[]>(`${this.appSourcesBaseUrl}/get-jobs-by-projects`, { projectIds: projectIds });
        }
    }

    getAllJobs(): Observable<JobResponse[]> {
        return this.http.get<JobResponse[]>(`${this.appSourcesBaseUrl}/get-jobs-full-list`);
    }

    executePipeline(job: Job, params: any): Observable<void> {
        const request = {
            job: job,
            params: params,
        };
        return this.http.post<void>(`${this.appSourcesBaseUrl}/run-job`, request);
    }

    terminatePipeline(job: ExtendJob) {
        return this.http.get<void>(`${this.appSourcesBaseUrl}/stop-job`, {
            params: toHttpParams(job),
        });
    }

    subscribeToPipeline(jobs: Job[]): Observable<string> {
        jobs.forEach(j => (j.jobMessageProcessorType = 'PIPELINE'));
        return this.http
            .post<string>(`${this.appSourcesBaseUrl}/monitor-subscriptions`, { subscribeToPipelines: jobs })
            .pipe(map(arr => arr[0]));
    }

    subscribeToWorkflow(jobs: Job[], workflowId: string): Observable<string> {
        jobs.forEach(j => (j.jobMessageProcessorType = 'WORKFLOW'));
        return this.http
            .post<string>(`${this.appSourcesBaseUrl}/monitor-subscriptions`, {
                subscribeToPipelines: jobs,
                workflowId: workflowId,
            })
            .pipe(map(arr => arr[0]));
    }

    updateSubscribeToPipeline(subscriptionId: string, jobs: Job[]): Observable<void> {
        return this.http.put<void>(`${this.appSourcesBaseUrl}/monitor-subscriptions/${subscriptionId}`, { subscribeToPipelines: jobs });
    }

    unsubscribeToPipeline(subscriptionId: string): Observable<void> {
        return this.http.get<void>(`${this.appSourcesBaseUrl}/monitor-subscriptions/${subscriptionId}/unsubscribe`);
    }

    pingMonitor(subscriptionId: string): Observable<void> {
        return this.http.get<void>(`${this.appSourcesBaseUrl}/monitor-subscriptions/${subscriptionId}/ping`);
    }

    getDetailedPipeline(job: Job): Observable<JobResponse[]> {
        return this.http.get<JobResponse[]>(`${this.appSourcesBaseUrl}/job-details`, {
            params: toHttpParams(job),
        });
    }

    getLastMessage(job: ExtendJob): Observable<EOMessage> {
        return this.http.get<EOMessage>(`${this.appSourcesBaseUrl}/last-message`, { params: toHttpParams(job) });
    }

    getStages(projectId: string, pipelineId: string): Observable<JobStageDetail[]> {
        return this.http.get<JobStageDetail[]>(`${this.appSourcesBaseUrl}/job-stages`, {
            params: {
                projectId,
                pipelineId,
            },
        });
    }

    getFullLog(request: ExtendJob): Observable<JobLog> {
        return this.http.get<JobLog>(`${this.appSourcesBaseUrl}/job-logs`, { params: toHttpParams(request) });
    }

    getGroupedLog(request: ExtendJob): Observable<JobNode> {
        return this.http.get<JobNode>(`${this.appSourcesBaseUrl}/job-grouped-logs`, { params: toHttpParams(request) });
    }

    getFullLogStream(request: ExtendJob): Observable<any> {
        return this.http.get(`${this.appSourcesBaseUrl}/job-full-logs`, {
            params: toHttpParams(request),
            responseType: 'blob',
        });
    }

    getPipelineHistory(request: ExtendJob & { page?: number; size?: number }): Observable<JobHistoryDetail[]> {
        return this.http.get<JobHistoryDetail[]>(`${this.appSourcesBaseUrl}/job-history`, { params: toHttpParams(request) });
    }

    getProgressiveLog(job: ExtendJob & { bufferOffset?: number }): Observable<ProgressiveLogResponse> {
        const params = toHttpParams(job);
        params['bufferOffset'] = job.bufferOffset.toString();
        return this.http.get<ProgressiveLogResponse>(`${this.appSourcesBaseUrl}/progressive-logs`, { params });
    }
}
