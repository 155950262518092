import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { ResizeObserverService } from '@dagility-ui/kit';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'dp-score-doughnut',
    templateUrl: './score-doughnut.component.html',
    styleUrls: ['./score-doughnut.component.scss'],
    providers: [ResizeObserverService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScoreDoughnutComponent {
    @Input() model: any;

    isSmallView$ = this.resizeObserver.observe$(this.elementRef).pipe(
        startWith(true),
        map(() => {
            return this.elementRef.nativeElement.clientHeight < 280;
        })
    );

    constructor(private resizeObserver: ResizeObserverService, private elementRef: ElementRef<HTMLElement>) {}
}
