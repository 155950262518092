import { Component, HostListener, Input } from '@angular/core';
import { QueryBuilderStore } from '@app/shared/components/query-builder/store/query-builder.store';
import { DataSetField, DataSetFieldRole, FieldRoleType } from '@app/shared/components/query-builder/models/model-graph.model';
import { QB_ICON_URLS } from '@app/shared/components/query-builder/consts/qb-icon-urls.const';
import {
    ModelGraphAddDataSetFieldRoleCommand,
    ModelGraphRemoveDataSetFieldRoleCommand,
    ModelGraphReorderDataSetFieldRoleCommand,
} from '@app/shared/components/query-builder/models/model-graph-actions.model';
import { ModelGraphActionsService } from '@app/shared/components/query-builder/services/model-graph-actions.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AllowedIcons } from '@app/shared/components/query-builder/consts/icon-remoter/icon-remoter.component';

@Component({
    selector: 'app-qb-visualization-field-item',
    templateUrl: './field-item.component.html',
    styleUrls: ['./field-item.component.scss'],
})
export class FieldItemComponent {
    @Input() datasetFieldRole: DataSetFieldRole;
    @Input() name: string;
    @Input() maxDroppedItems: number = null;
    @Input() iconName: AllowedIcons;
    @Input() dragPlaceTitle: string = 'Drag&Drop';
    @Input() ordered: boolean = false;

    icons = QB_ICON_URLS;

    dragStatus: boolean = false;

    loading = false;

    constructor(private queryBuilderStore: QueryBuilderStore, private modelGraphActionsService: ModelGraphActionsService) {}

    private _datasetFields: DataSetField[] = [];

    get datasetFields(): DataSetField[] {
        if (!this.queryBuilderStore.modelGraph.dataSetFields) {
            return [];
        }
        const datasetFields = this.queryBuilderStore.modelGraph.dataSetFields.fields;
        if (this._datasetFields.length !== datasetFields.length) {
            this._datasetFields = datasetFields
                .filter(x => x.roles.map(role => role.name).includes(this.datasetFieldRole))
                .sort(
                    (a, b) =>
                        a.roles.find(role => role.name === this.datasetFieldRole).ord -
                        b.roles.find(role => role.name === this.datasetFieldRole).ord
                );
        }
        return this._datasetFields;
    }

    fieldHasFieldRoleType(datasetField: DataSetField, fieldRoleType: FieldRoleType) {
        return !datasetField.calculated
            ? this.queryBuilderStore.modelGraph.models
                  .filter(x => x.uid === datasetField.srcModelUid)[0]
                  .data.fields.filter(x => x.name === datasetField.dsFieldName)[0]
                  .roles.map(x => x.type)
                  .includes(fieldRoleType)
            : false;
    }

    onDropHandler(event: DragEvent) {
        if (this.loading) {
            return;
        }
        this.loading = true;
        const data = JSON.parse(event.dataTransfer.getData('text')) as DataSetField;
        const command: ModelGraphAddDataSetFieldRoleCommand = {
            srcModelUid: data.srcModelUid,
            srcDataField: data.dsDataField,
            dsFieldUid: data.uid,
            roles: [this.datasetFieldRole],
            sourceGraph: this.queryBuilderStore.globalModelGraph,
        };
        this.modelGraphActionsService.addDsField(command).subscribe(
            result => this.queryBuilderStore.updateState(result),
            () => {
                this.loading = false;
                this.dragStatus = false;
            },
            () => {
                this.loading = false;
                this.dragStatus = false;
            }
        );
    }

    deleteField(field: HTMLDivElement, datasetField: DataSetField) {
        field.classList.add('loading');
        const command: ModelGraphRemoveDataSetFieldRoleCommand = {
            srcModelUid: datasetField.srcModelUid,
            srcDataField: datasetField.srcDataField,
            dsFieldUid: datasetField.uid,
            sourceGraph: this.queryBuilderStore.globalModelGraph,
        };
        this.modelGraphActionsService.removeDsField(command).subscribe(
            result => this.queryBuilderStore.updateState(result),
            () => {
                field.classList.remove('loading');
            },
            () => {
                field.classList.remove('loading');
            }
        );
    }

    moveMappingElement(datasetField: DataSetField, event: CdkDragDrop<DataSetField[], any>) {
        if (event.currentIndex === event.previousIndex) return;
        moveItemInArray(this._datasetFields, event.previousIndex, event.currentIndex);
        const command: ModelGraphReorderDataSetFieldRoleCommand = {
            srcModelUid: datasetField.srcModelUid,
            srcDataField: datasetField.srcDataField,
            dsFieldUid: datasetField.uid,
            role: {
                name: this.datasetFieldRole,
                ord: event.currentIndex,
            },
            sourceGraph: this.queryBuilderStore.globalModelGraph,
        };
        this.modelGraphActionsService.reorderDsField(command).subscribe(result => {
            this.queryBuilderStore.updateState(result);
        });
    }

    @HostListener('document:dragstart', ['$event'])
    private onDragStart(event: DragEvent) {
        try {
            const data = JSON.parse(event.dataTransfer.getData('text')) as DataSetField;
            if (data && ((data.uid && data.srcDataField) || (data.uid && data.calculated))) {
                this.dragStatus = true;
            }
        } catch (e) {
            this.dragStatus = false;
        }
    }

    @HostListener('document:dragend')
    private onDragEnd() {
        this.dragStatus = false;
    }
}
