import { GridColumn } from '@dagility-ui/kit';

export const TEMPLATE_LIST_MAX_IMPORT_FILE_SIZE = 50_000_000;

export const MARKETPLACE_TAB = 'marketplace';

export const gridColumnsMyTemplates: GridColumn[] = [
    { title: 'Name', field: 'name', sortingField: 'name', width: '220px' },
    { title: 'Last Update', field: 'modifiedAt', sortingField: 'modifiedAt', width: '125px' },
    { title: 'Description', field: 'description', sortingField: 'description', width: '120px' },
    { title: 'Type', field: 'type', sortingField: 'type', width: '76px' },
    { title: 'Marketplace rating', field: 'rate', sortingField: 'rate', width: '135px' },
    { title: 'Marketplace stat', field: 'status', width: '120px' },
    { title: 'Actions', width: '230px' },
    { title: 'Compare', width: '200px' },
];

export const gridColumnsMarketplaceTemplates: GridColumn[] = [
    { title: 'Name', field: 'name', sortingField: 'name', width: '20%' },
    { title: '', width: '90px' },
    { title: 'Last Update', field: 'modifiedAt', sortingField: 'modifiedAt', width: '120px' },
    { title: 'Description', field: 'description', sortingField: 'description', width: '25%' },
    { title: 'Type', field: 'type', sortingField: 'type', width: '75px' },
    { title: 'Actions', width: '140px' },
];

export const subGridColumnsMarketplace: GridColumn[] = [
    { title: '', width: '30px' },
    { title: 'Version', field: 'version', sortingField: 'version', width: '90px' },
    { title: '', width: '90px' },
    { title: 'Date', field: 'modifiedAt', sortingField: 'modifiedAt', width: '120px' },
    { title: 'Rating', field: 'rate', sortingField: 'rate', width: '135px' },
    { title: 'Downloads', field: 'downloads', sortingField: 'downloads', width: '110px' },
    { title: 'Actions', width: '170px' },
];
