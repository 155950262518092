import { ElementRef, Injectable, NgZone, OnDestroy } from '@angular/core';
import { BehaviorSubject, fromEvent, merge, Subject } from 'rxjs';
import { ZoneFreeOperator } from '@dagility-ui/kit';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { AnyWidgetStore } from 'data-processor/lib/widget-library/widget-builder/components/widget/any-widget/any-widget.store';
import { AnyWidgetModel, WidgetDrilldown } from '../../models/any-widget.model';
import { Placeholders } from '../widget-builder.service';
import { WIDGET_CLICKED_EVENT_ID, WidgetEvent, WidgetEventManager } from './widget-event.manager';

export enum WidgetDOMEventAttribute {
    EVENT_ID = 'wc-event-id',
    EVENT_DATA = 'wc-event-data',
    EVENT_SKIP = 'wc-event-skip',
    DRILLDOWN_EVENT = 'wc-event-drilldown',
}

export class WidgetDOMEvent extends WidgetEvent {
    value: string;
    drilldown = false;

    static fromHost() {
        const event = new WidgetDOMEvent();
        event.id = WIDGET_CLICKED_EVENT_ID;

        return event;
    }

    constructor(element?: HTMLElement) {
        super();

        if (element) {
            this.value = element.getAttribute(WidgetDOMEventAttribute.EVENT_DATA);
            this.id = element.getAttribute(WidgetDOMEventAttribute.EVENT_ID);
            this.drilldown = element.hasAttribute(WidgetDOMEventAttribute.DRILLDOWN_EVENT);
        }
    }
}

const ENTERABLE_TOOLTIP_CLASS = 'enterable-tooltip';
const CURSOR_POINTER_CLASS = 'widget-clickable';

@Injectable()
export class WidgetDOMEventsService implements OnDestroy {
    private readonly destroyed$ = new Subject<void>();

    constructor(
        private readonly elementRef: ElementRef<HTMLElement>,
        private readonly zone: NgZone,
        private readonly store: AnyWidgetStore,
        private readonly eventManager: WidgetEventManager
    ) {}

    observe() {
        const workflow = this.store.workflow;
        const element = this.elementRef.nativeElement;
        let hostDrilldown: WidgetDrilldown | null;
        const drilldown$ = new Subject();

        if (workflow) {
            merge(
                getWidgetDOMEvents({
                    zone: this.zone,
                    element: this.elementRef.nativeElement,
                    getPlaceholdersFn: target => {
                        const widgetElement: HTMLElement = target.closest('any-widget');
                        let placeholders: Record<string, any>;

                        if (!widgetElement) {
                            placeholders = workflow.placeholders;
                        } else {
                            const widgetId = widgetElement.dataset['widget_id'];

                            placeholders = (workflow.widgetDebugger.dataSources[widgetId] as BehaviorSubject<any>).value;
                        }

                        return placeholders;
                    },
                }),
                workflow.widget$.pipe(
                    filter(Boolean),
                    tap((widget: AnyWidgetModel) => {
                        if (widget.chartOptions.enterableTooltip) {
                            element.classList.add(ENTERABLE_TOOLTIP_CLASS);
                        } else {
                            element.classList.remove(ENTERABLE_TOOLTIP_CLASS);
                        }
                    }),
                    tap(() => element.classList.remove(CURSOR_POINTER_CLASS)),
                    map(widget => {
                        hostDrilldown = (widget.drilldownList || []).find(({ target }) => target === WIDGET_CLICKED_EVENT_ID);

                        return (
                            (widget?.handlers || []).some(
                                handler => handler.type === 'OUT' && handler.eventId === WIDGET_CLICKED_EVENT_ID
                            ) || Boolean(hostDrilldown)
                        );
                    }),
                    tap(isHandlerExists => {
                        if (isHandlerExists) {
                            element.classList.add(CURSOR_POINTER_CLASS);
                        } else {
                            element.classList.remove(CURSOR_POINTER_CLASS);
                        }
                    }),
                    filter(Boolean),
                    switchMap(() =>
                        getWidgetDOMEvents({
                            zone: this.zone,
                            element,
                            filterFn: () => !this.store.extensionOpened,
                            getPlaceholdersFn: () => {
                                if (hostDrilldown) {
                                    return (workflow.widgetDebugger.dataSources[workflow.widget$.value.id] as BehaviorSubject<any>).value;
                                }

                                return workflow.placeholders;
                            },
                            generateEventFn: () => WidgetDOMEvent.fromHost(),
                        })
                    )
                )
            )
                .pipe(takeUntil(this.destroyed$))
                .subscribe(({ placeholders, event }) => {
                    const { widget } = this.store.value;
                    this.eventManager.process(event, placeholders, widget);

                    if (hostDrilldown) {
                        drilldown$.next({
                            drilldown: hostDrilldown,
                            placeholders,
                            id: widget.id,
                            event: {},
                            templates: {
                                separatedView: true,
                            },
                        });
                    }
                });
        }

        return { drilldown$ };
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }
}

function findClosestTarget(element: HTMLElement) {
    return element.closest(`[${WidgetDOMEventAttribute.EVENT_ID}]`);
}

export function getWidgetDOMEvents(config: {
    zone: NgZone;
    element: HTMLElement;
    getPlaceholdersFn: (target: HTMLElement) => Placeholders;
    filterFn?: (target: HTMLElement) => boolean;
    generateEventFn?: (target: HTMLElement) => WidgetDOMEvent;
}) {
    const filterEventFn =
        config.filterFn ||
        ((target: HTMLElement) => {
            return target && !!target.getAttribute(WidgetDOMEventAttribute.EVENT_ID);
        });
    const generateEventFn = config.generateEventFn || (target => new WidgetDOMEvent(target));

    return fromEvent(config.element, 'click')
        .pipe(
            map((event: MouseEvent) => event.target as HTMLElement),
            filter((target: HTMLElement) => {
                if (!target) {
                    return false;
                }

                const scrollBarRailSelector = `[class*="ps__rail"]`;

                if (target.closest(scrollBarRailSelector) || target.matches(scrollBarRailSelector)) {
                    return false;
                }

                return !target.closest(`[${WidgetDOMEventAttribute.EVENT_SKIP}]`);
            }),
            map(findClosestTarget),
            filter(filterEventFn),
            map(target => {
                const placeholders = config.getPlaceholdersFn(target);

                return { placeholders, event: generateEventFn(target) };
            })
        )
        .lift(new ZoneFreeOperator(config.zone));
}
