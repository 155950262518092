import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { FaCustomIconDirective } from '@dagility-ui/kit';
import { ComponentOutletComponent } from './component';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IlluminateDashboardWidgetExtension } from 'data-processor/lib/widget-library/dashboard/services/dashboard-widget-settings.manager';
import { AnyWidgetModel } from 'data-processor/lib/widget-library/widget-builder/models/any-widget.model';

export class WidgetExtensionInnerViewComponent {}

export abstract class WidgetExtensionInnerFormComponent {
    abstract save(): Observable<Record<string, any>>;
}

export class WidgetExtensionInner {
    extensionId: string;
    form?: ComponentOutletComponent<WidgetExtensionInnerFormComponent>;
    formName?: string;
    view: ComponentOutletComponent<WidgetExtensionInnerViewComponent>;
    viewIcon: FaCustomIconDirective['icon'] | IconProp| string;
    viewIconIsPlainSVG: boolean
}

export const WIDGET_EXTENSIONS_INNER = new InjectionToken<WidgetExtensionInner[]>('widget inner extensions', {
    factory: () => [],
});

export interface WidgetExtensionInnerFormContext {
    widgetId: number;
    extensionId: string;
    dashboardWidgetId: number;
    state: Record<string, any>;
    onSave: (state: Record<string, any>) => void;
    onCancel: () => void;
}

export interface WidgetExtensionInnerViewContext {
    widgetId: number;
    dashboardWidgetId: number;
    extension: IlluminateDashboardWidgetExtension;
    onClickOutside: () => void;
    options?: AnyWidgetModel;
}

export type LoadExtensionsFn = (widgetId: number) => Observable<IlluminateDashboardWidgetExtension[]>;

export const LOAD_EXTENSIONS_FN = new InjectionToken<LoadExtensionsFn>('Widget extensions loader', {
    factory: () => null,
});
