import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { JobResponse } from '@app/shared/components/pipeline-list/model/jobs-management.classes';
import { Subject } from 'rxjs';
import { WorkflowsPipelinesService } from './services/workflows-pipelines.service';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-workflows-pipelines',
    templateUrl: './workflows-pipelines.component.html',
    styleUrls: ['../../../pages/ci-cd/workflows/workflows.component.scss'],
})
export class WorkflowsPipelinesComponent implements OnInit, OnDestroy {
    @Input() projectId: any;

    @Input() workflowId: any;

    @Input() workflowDashboard: boolean = false;

    jobsList: JobResponse[];

    isLoading = true;

    private destroyed$ = new Subject();

    constructor(private router: Router, private workflowsPipelinesService: WorkflowsPipelinesService) {}

    ngOnInit() {
        this.workflowsPipelinesService
            .getPipelines(this.workflowId)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(value => {
                this.jobsList = value;
                this.isLoading = false;
            });
        this.router.events.pipe(takeUntil(this.destroyed$)).subscribe(value => {
            if (value instanceof NavigationStart) {
                this.isLoading = true;
                const workflowUrl = 'workflow/';
                const url = value.url;
                const startIndex = url.indexOf(workflowUrl);
                this.workflowId = url.substring(startIndex + workflowUrl.length, url.length);
                if (+this.workflowId) {
                    this.workflowsPipelinesService
                        .getPipelines(this.workflowId)
                        .pipe(takeUntil(this.destroyed$))
                        .subscribe(pipelines => {
                            this.jobsList = pipelines;
                            this.isLoading = false;
                        });
                } else {
                    this.isLoading = false;
                }
            }
        });
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }
}
