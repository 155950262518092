import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isBoolean, isNumber, isString } from 'lodash';
import { FormControl } from '@angular/forms';
import { noop } from 'rxjs';

import { BaseFormControl } from '@dagility-ui/kit';

@Component({
    selector: 'dp-configure-job-definition-select-type',
    templateUrl: './configure-job-definition-select-type.component.html',
    styleUrls: ['./configure-job-definition-select-type.component.scss', '../templates.scss'],
})
export class ConfigureJobDefinitionSelectTypeComponent extends BaseFormControl implements OnInit {
    @Input() value: any;
    @Input() label: string;

    @Output() fieldChange = new EventEmitter();
    @Output() change = new EventEmitter();

    type = 'string';
    types = [
        { label: 'String', id: 'string' },
        { label: 'Number', id: 'number' },
        { label: 'Boolean', id: 'boolean' },
        { label: 'Array', id: 'array' },
    ];

    _value: any;

    onChange: any = noop;
    onTouched: any = noop;

    ngOnInit() {
        super.ngOnInit();
    }

    getType(v: any) {
        // typeof?
        let type = null;
        if (isBoolean(v)) {
            type = 'boolean';
        } else if (isNumber(v)) {
            type = 'number';
        } else if (isString(v)) {
            type = 'string';
        } else if (Array.isArray(v)) {
            type = 'array';
        }
        this.cdr.markForCheck();
        return type;
    }

    handleFieldChange(e: any) {
        if (this.type != 'boolean') {
            e.stopImmediatePropagation();
        }
        const newValue = this.type == 'boolean' ? !this._value : this.type == 'number' ? +e.target.value : e.target.value;
        this._value = newValue;
        this.onChange(this._value);
        this.change.emit(newValue);
        this.fieldChange.emit({ newValue });
    }

    handleTypeChange({ id }: any) {
        this.type = id;
        let newValue;
        switch (this.type) {
            case 'number':
                newValue = 0;
                break;
            case 'boolean':
                newValue = false;
                break;
            case 'string':
                newValue = '';
                break;
            case 'array':
                newValue = [''];
                break;
        }
        this.value = newValue;
        this._value = newValue;
        this.onChange(this._value);
        this.change.emit(newValue);
        this.fieldChange.emit({ newValue });
    }

    writeValue(value: any) {
        if (value != null) {
            this._value = value;
            this.type = this.getType(value);
        }
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    validate(_: FormControl): any {
        return null;
    }

    setArrayElement(index: number, e: any) {
        e.stopImmediatePropagation();
        this._value = this._value.map((el: any, i: any) => (i == index ? e.target.value : el));
        this.onChange(this._value);
        this.change.emit(this._value);
    }

    addArrayItem() {
        this._value = [...this._value, ''];
        this.onChange(this._value);
        this.change.emit(this._value);
    }

    removeArrayItem(index: number) {
        this._value = this._value.filter((el: any, i: any) => i != index);
        this.onChange(this._value);
        this.change.emit(this._value);
    }

    trackFunction(index: number, element: any) {
        return index;
    }
}
