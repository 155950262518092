import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ExpressionMode } from '@app/shared/components/query-builder/models/model-graph-actions.model';
import { Subscription } from 'rxjs';
import { ExpressionHelperEvent, ExpressionBuilderService } from './expression-builder.service';
import { ExpressionHelperModalComponent } from './expression-helper-modal/expression-helper-modal.component';
import { ExpressionHelperComponent } from './expression-helper/expression-helper.component';

@Component({
    selector: 'app-qb-model-expression-builder',
    templateUrl: './expression-builder.component.html',
    styleUrls: ['./expression-builder.component.scss'],
})
export class ExpressionBuilderComponent implements OnInit, OnDestroy {
    @ViewChild(ExpressionHelperModalComponent) expressionBuilderModalRef: ExpressionHelperModalComponent;
    @ViewChild(ExpressionHelperComponent) expressionBuilderRef: ExpressionHelperComponent;

    @Input() expressionCursor: number = 0;
    @Input() active: boolean = false;
    @Input() expressionString: string = '';
    @Input() isViewEditor: boolean = false;
    @Input() isExpressionLocked: boolean = false;
    @Input() isGroovyMode: boolean = false;
    @Input() isSwitchFunctionEditor: boolean = false;
    @Output() expressionChanged = new EventEmitter();

    expressionMode: ExpressionMode | null;
    selection: { start: number; end: number } = { start: 0, end: 0 };
    expressionInputElement?: HTMLInputElement;
    expressionControl: FormControl = new FormControl();
    expressionPartsSub: Subscription;
    @Input() set cursor(value: number) {
        this.expressionCursor = value;
    }
    @Input() set expression(value: string) {
        if (value && value[0] === '{') {
            const node = this.expressionBuilderService.parseData(value);
            this.expressionControl.setValue(this.expressionBuilderService.convertNodeToFunctionView(node), { emitEvent: false });
        } else {
            this.expressionControl.setValue(value, { emitEvent: false });
        }
        this.expressionString = value;
    }

    constructor(private elRef: ElementRef, private expressionBuilderService: ExpressionBuilderService) {
        this.expressionPartsSub = this.expressionBuilderService.expressionParts$.subscribe(data => {
            this.expressionMode = data.mode;
            this.isGroovyMode = this.expressionMode === ExpressionMode.GROOVY;
            if (this.isGroovyMode) {
                this.isSwitchFunctionEditor = false;
            }
        });
    }

    ngOnDestroy(): void {
        if (this.expressionPartsSub) {
            this.expressionPartsSub.unsubscribe();
        }
    }

    focus(cursor: number) {
        setTimeout(() => {
            this.expressionInputElement.focus();
            this.cursor = cursor;
            this.selection = { start: cursor, end: cursor };
            this.expressionInputElement.setSelectionRange(cursor, cursor);
        }, 50);
    }

    ngOnInit(): void {
        this.expressionInputElement = this.elRef.nativeElement.querySelector('#expressionInputRef')?.getElementsByTagName('input')[0];
        this.expressionControl.valueChanges.subscribe(value => {
            if (this.expressionInputElement) {
                this.expressionCursor = this.expressionInputElement.selectionStart;
            }
            this.expression = value;
        });
    }

    onKeyPressed(event: any) {
        switch (event.key) {
            case 'Enter':
                this.expressionInputElement.setSelectionRange(this.expressionString.length, this.expressionString.length);
                break;
            case 'Tab':
                event.preventDefault();
                this.moveNextArgument();
                break;
            case 'ArrowLeft':
            case 'ArrowRight':
                this.expressionCursor = this.expressionInputElement.selectionStart;
                break;
            case 'ArrowDown':
            case 'ArrowUp':
                event.preventDefault();
                break;
        }
    }

    moveNextArgument() {
        if (this.expressionInputElement) {
            let position = Math.max(this.expressionInputElement.selectionStart, this.expressionInputElement.selectionEnd);
            let str = this.expressionString.substr(position, this.expressionString.length - position);
            let argIndex = str.indexOf('$');
            if (argIndex < 0) {
                str = this.expressionString;
                argIndex = str.indexOf('$');
                position = 0;
            }
            if (argIndex >= 0) {
                const start = position + argIndex;
                const endStr = str.substr(argIndex + 1, str.length - argIndex - 1).split(' ')[0];
                const endSymbol = Math.max(endStr.indexOf(','), 0) || Math.max(endStr.indexOf(')'), 0);
                const end = start + endSymbol + 1;
                this.expressionInputElement.setSelectionRange(start, end);
                this.updateSelection();
            }
        }
    }

    setExpressionData(data: string) {
        this.expressionBuilderModalRef.setData(data);
    }

    updateSelection() {
        if (this.expressionInputElement) {
            this.selection = { start: this.expressionInputElement.selectionStart, end: this.expressionInputElement.selectionEnd };
        }
    }

    onExpressionChanged(event: ExpressionHelperEvent) {
        if (event.expressionView) {
            this.expressionControl.setValue(event.expressionView, { emitEvent: false });
        }
        this.expressionChanged.emit(event);

        if (['select-function'].includes(event.type)) {
            if (this.expressionInputElement) {
                this.expressionInputElement.focus();

                this.expressionInputElement.value = event.expression;
                if (event.selectionStart) {
                    this.expressionInputElement.setSelectionRange(event.selectionStart, event.selectionEnd);
                    this.selection = { start: event.selectionStart, end: event.selectionEnd };
                }
            }
        }
    }

    onClickInput() {
        this.expressionCursor = this.expressionInputElement.selectionStart;
    }

    onCancel() {
        this.expressionChanged.emit({ type: 'expression-canceled' });
    }

    onApply() {
        this.expressionChanged.emit({ type: 'expression-complete' });
    }

    onInputMouseUp() {
        this.updateSelection();
    }

    onInput() {
        this.expressionChanged.emit({
            type: 'expression-changed',
            expression: this.expressionString,
            expressionView: this.expressionString,
        });
        if (this.expressionBuilderRef.suggestion) {
            const suggestion = this.expressionBuilderRef.suggestion;
            const lastWord = this.expressionBuilderRef.getNextString(this.expressionCursor, true);
            const index = suggestion.indexOf(lastWord);
            if (index >= 0 && lastWord) {
                const fillPart = suggestion.substr(index + lastWord.length, suggestion.length - index - lastWord.length);
                const lastWordStart = this.expressionString.lastIndexOf(lastWord);
                const endStr = this.expressionString.substr(this.expressionCursor, this.expressionString.length - this.expressionCursor);
                const expression = `${this.expressionString.substr(0, this.expressionCursor)}${fillPart}${endStr}`;
                this.expressionControl.setValue(expression);
                this.expressionInputElement.setSelectionRange(
                    lastWordStart + lastWord.length,
                    lastWordStart + lastWord.length + fillPart.length
                );
            }
        }
        this.updateSelection();
    }
}
