import { JobSet } from '../models/processor-monitoring/job-set.model';
import { ToolModel } from '@dagility-ui/shared-components';

export function normalizeJobDefinitionsState(jobDefinitions: any[]) {
    return jobDefinitions.reduce<Record<string, any>>((dict, jd) => {
        dict[`${jd.toolId}|${jd.instanceName}`] = jd;

        return dict;
    }, {});
}

export function normalizeJobSets(jobSets: JobSet[]) {
    return jobSets.reduce<Record<string, JobSet>>((dict, jobSet) => {
        dict[jobSet.id] = jobSet;

        return dict;
    }, {});
}

export function getToolJobSets(tools: ToolModel[]) {
    return tools.reduce<Record<string, number>>((dict, { toolId, jobSetId }) => {
        dict[toolId] = jobSetId;

        return dict;
    }, {});
}
