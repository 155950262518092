import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {BehaviorSubject, Observable} from 'rxjs';
import { ENV_TOKEN } from '@app/tokens';

@Injectable({
    providedIn: 'root'
})
export class MarketplaceService {
    private readonly marketplaceApiUrl = `${this.env.buildApiURL}/ci-cd/marketplace`;

    isAlive: boolean = null;

    isAlive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {
       this.isMarketplaceAvailable();
    }

    getMarketplaceSettings(): Observable<MarketplaceSettings> {
        return this.http.get<MarketplaceSettings>(`${this.marketplaceApiUrl}/get-marketplace-settings`);
    }

    setMarketplaceClientId(clientId: string): Observable<void> {
        return this.http.post<void>(`${this.marketplaceApiUrl}/set-marketplace-client-id`, null, {
            params: {
                clientId: clientId
            }
        });
    }

    setMarketplaceClientSecretId(clientSecretId: string, enabled: boolean) {
        return this.http.post(`${this.marketplaceApiUrl}/set-marketplace-client-secret-id`, null, {
            params: {
                clientSecretId: clientSecretId,
                enabled: `${enabled}`,
            }
        });
    }

    getIsMarketplaceAvailable(): Observable<boolean> {
        return this.isAlive$.asObservable();
    }

    isMarketplaceAvailable() {
        this.getMarketplaceSettings()
            .subscribe((response) => {
                this.isAlive = response.enabled;
                this.isAlive$.next(this.isAlive);
            }, () => {
                this.isAlive = null;
                this.isAlive$.next(null);
            });
    }
}

export interface MarketplaceSettings {
    clientId: string;
    clientSecretId: string;
    enabled: boolean;
}
