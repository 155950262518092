import { InjectionToken } from '@angular/core';

export class DataMorphAuthService {
    isAdmin(): boolean {
        return false;
    }
}

export const DATA_MORPH_AUTH = new InjectionToken('Data Morph Auth', {
    factory: () => new DataMorphAuthService(),
});
