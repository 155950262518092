<div class="editor-container" [class.not-active]="!active">
    <div class="selection-block">
        <lib-data-grid [dataSource]="dataGrid" [sticky]="true" [bodyHeight]="'232px'" id="dataFunctionsRef">
            <ng-template rowTemplate let-item="item">
                <td (click)="onSelectItem(item)" [class.selected]="item.selected">
                    <ng-container *ngIf="item.isTemplate">
                        <span class="template-icon">
                            <img class="color-brand" [src]="typeIcons[types.template]" alt="" />
                        </span>
                        <span [class.template-item]="item.isTemplate"> {{ item.label }} </span>
                    </ng-container>
                    <container-element [ngSwitch]="item.type">
                        <span *ngSwitchCase="types.field">
                            <span>{{ fieldName(item.data) }}</span>
                        </span>
                        <span *ngSwitchCase="types.func">
                            {{ item.data.name }}
                            <span class="args">({{ item.data.argTypes }})</span>
                            <span class="color-font-4"> - {{ item.data.description }}</span>
                        </span>
                        <span *ngSwitchCase="types.operator">
                            {{ item.data.name }}
                        </span>
                        <span *ngSwitchCase="types.param">
                            {{ item.data }}
                        </span>
                    </container-element>
                </td>
            </ng-template>
        </lib-data-grid>
    </div>
</div>

<div class="groovy-container" *ngIf="isGroovyMode"></div>
