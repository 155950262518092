import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ComponentCanDeactivate, DropdownItem, Warning } from '@dagility-ui/kit';
import { WidgetBuilderStore } from 'data-processor/lib/widget-library/widget-builder/services/widget-builder.store';
import { WidgetBuilderFacade } from 'data-processor/lib/widget-library/widget-builder/state/widget-builder.facade';
import { QueryBuilderStore } from '@app/shared/components/query-builder/store/query-builder.store';
import { QueryBuilderState } from '@app/shared/components/query-builder/store/state/query-builder.state';
import { QB_ICON_URLS } from '@app/shared/components/query-builder/consts/qb-icon-urls.const';
import { Observable } from 'rxjs';
import { LayerBlock } from 'data-processor/lib/widget-library/widget-builder/models/query.block';
import { getLayerBlock } from '@app/shared/components/query-builder/consts/widget-settings.const';
import { GetAllowedWidgetTypes } from '@app/shared/components/query-builder/qb-visualization/components/mapping-container/field-role-registry/field-role.registry';
import { EXTERNAL_PARAMETERS_PROVIDER } from '@app/shared/components/query-builder/providers/external-parameters.provider';
import { LockerActionsService } from '@app/shared/components/query-builder/store/locker-actions/locker-actions.service';
import { WidgetType } from 'data-processor';

@Component({
    selector: 'app-query-builder-visualization',
    templateUrl: './qb-visualization.component.html',
    styleUrls: ['./qb-visualization.component.scss'],
    providers: [WidgetBuilderStore, WidgetBuilderFacade, EXTERNAL_PARAMETERS_PROVIDER],
})
export class QBVisualizationComponent implements OnInit, ComponentCanDeactivate {
    icons = QB_ICON_URLS;
    widgetSettingsOpened = false;
    showChart: boolean = false;
    layerBlock: LayerBlock;
    @Output() backButton: EventEmitter<any> = new EventEmitter<any>();
    private widgetTypes: DropdownItem<WidgetType>[] = [
        {
            value: WidgetType.BAR_CHART,
            label: 'Bar Chart',
        },
        {
            value: WidgetType.LINE_CHART,
            label: 'Line Chart',
        },
        {
            value: WidgetType.PIE_CHART,
            label: 'Pie Chart',
        },
        {
            value: WidgetType.DOUGHNUT_CHART,
            label: 'Doughnut Chart',
        },
        {
            value: WidgetType.SUNBURST_CHART,
            label: 'Sunburst Chart',
        },
        {
            value: WidgetType.NESTED_PIE_CHART,
            label: 'Nested Pie Chart',
        },
        {
            value: WidgetType.TABLE,
            label: 'Grid',
        },
        {
            value: WidgetType.TWO_DIMENSIONAL_TABLE,
            label: 'Two Dimensional Grid',
        },
        {
            value: WidgetType.STACKED_BAR_CHART,
            label: 'Stacked Bar Chart',
        },
        {
            value: WidgetType.COMPLEX,
            label: 'Complex Widget',
        },
        {
            value: WidgetType.TREEMAP,
            label: 'Tree map',
        },
        {
            value: WidgetType.PROGRESS,
            label: 'Progress Widget',
        },
        {
            value: WidgetType.BOXPLOT,
            label: 'Boxplot',
        },
        {
            value: WidgetType.TILE_CHART,
            label: 'Tile Chart',
        },
        {
            value: WidgetType.LOG,
            label: 'Log Chart',
        },
        {
            value: WidgetType.MULTIPLE_Y_AXIS,
            label: 'Multiple Y Axis Chart',
        },
        {
            value: WidgetType.ACCORDION,
            label: 'Accordion',
        },
        {
            value: WidgetType.GANTT,
            label: 'Gantt',
        },
        {
            value: WidgetType.RADIAL,
            label: 'Radial',
        },
        {
            value: WidgetType.PI_WORK_DISTRIBUTION,
            label: 'PI Work Distribution',
        },
        {
            value: WidgetType.PI_WORK_CHART,
            label: 'PI Work Chart',
        },
        {
            value: WidgetType.PI_MULTIPLE_RADIALS,
            label: 'PI Multiple Radials',
        },
        {
            value: WidgetType.PI_PROGRESS_MENU,
            label: 'PI Progress Menu',
        },
        {
            value: WidgetType.PI_ISSUE_LIFE_CYCLE,
            label: 'PI Issue Life Cycle',
        },
        {
            value: WidgetType.TABLE_WITH_TABS,
            label: 'Grid With Tabs',
        },
        {
            value: WidgetType.HEALTH_SCORE,
            label: 'Health Score',
        },
        {
            value: WidgetType.ACCORDION_WITH_TABS,
            label: 'Accordion With Tabs',
        },
        {
            value: WidgetType.SCATTER_CHART,
            label: 'Scatter Chart',
        },
        {
            value: WidgetType.PI_GANTT,
            label: 'PI Gantt',
        },
        {
            value: WidgetType.PI_FEATURE_COMPLETION_STATUS,
            label: 'PI Feature Completion Status',
        },
        {
            value: WidgetType.SRE_SQUARES,
            label: 'SRE Squares',
        },
        {
            value: WidgetType.SRE_HEXAGONS,
            label: 'SRE Hexagons',
        },
        {
            value: WidgetType.CODE_ISSUE_DETAILS,
            label: 'Code Issue Details',
        },
        {
            value: WidgetType.SECURITY_POSTURE_DETAILS,
            label: 'Security Posture Details',
        },
        {
            value: WidgetType.SRE_GANTT,
            label: 'SRE Gantt',
        },
        {
            value: WidgetType.FACTOR_SCORES,
            label: 'Factor Scores',
        },
        {
            value: WidgetType.CODE_QUALITY_SUMMARY,
            label: 'Code Quality',
        },
        {
            value: WidgetType.SCORE_DOUGHNUT,
            label: 'Score Doughnut',
        },
        {
            value: WidgetType.METRIC_LINE,
            label: 'Metric Line',
        },
        {
            value: WidgetType.TOP_PRIORITY_RISKS,
            label: 'Top Priority Risks',
        },
        {
            value: WidgetType.PORTFOLIO_HEALTH_SCORES,
            label: 'Portfolio Health Scores',
        },
        {
            value: WidgetType.PORTFOLIO_SCORE_SUBCATEGORIES,
            label: 'Portfolio Score Subcategories',
        },
        {
            value: WidgetType.PORTFOLIO_STATISTICS,
            label: 'Portfolio Statistics',
        },
        {
            value: WidgetType.PORTFOLIO_RISK_SCORES,
            label: 'Portfolio Risk Scores',
        },
        {
            value: WidgetType.COMPARE_METRICS,
            label: 'Compare Metrics',
        },
    ].sort((a, b) => a.label.localeCompare(b.label));
    allowedWidgetTypes: DropdownItem<WidgetType>[] = this.widgetTypes.filter(type => {
        const registeredTypes = GetAllowedWidgetTypes();
        return registeredTypes.includes(type.value);
    });

    constructor(public queryBuilderStore: QueryBuilderStore) {}

    get actionsLocked() {
        return LockerActionsService.lockedStatus();
    }

    get hasErrors(): boolean {
        return this.queryBuilderStore.validationErrors.filter(error => error.level === 'ERROR').length > 0;
    }

    get currentState(): QueryBuilderState {
        return this.queryBuilderStore.getValue();
    }

    get widgetConfig(): any {
        return this.queryBuilderStore.drilldownGraph.widgetConfig;
    }

    ngOnInit() {
        if (this.currentState.modelGraph) {
            const anyWidgetModel = this.queryBuilderStore.widgetSettings;
            this.layerBlock = getLayerBlock(anyWidgetModel);
            this.layerBlock.displaySettingsConfig.showSizeChanger = !this.queryBuilderStore.drillDownId;
        }
    }

    handleWidgetSelect({ value }: any) {
        const widgetSettings = this.queryBuilderStore.drilldownGraph.widgetConfig;
        widgetSettings.type = value;
        this.queryBuilderStore.setState({
            /*widgetSettings: { data: widgetSettings } */
        });
    }

    openWidgetSettings() {
        const anyWidgetModel = this.queryBuilderStore.widgetSettings;
        this.layerBlock = getLayerBlock(anyWidgetModel);
        this.layerBlock.displaySettingsConfig.showSizeChanger = !this.queryBuilderStore.drillDownId;
        this.widgetSettingsOpened = true;
    }

    closeWidgetSettings() {
        this.widgetSettingsOpened = false;
    }

    saveWidgetSettings(event: any) {
        const res = Object.assign(this.queryBuilderStore.widgetSettings, event);
        this.queryBuilderStore.setState({ widgetSettings: { data: res } });
        this.closeWidgetSettings();
    }

    canDeactivate(): boolean | Observable<boolean> {
        return true;
    }

    getWarning(): Warning {
        return undefined;
    }
}
