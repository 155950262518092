<div class="d-inline-flex w-100 align-items-center pb-2">
    <button type="button" class="close ml-auto" aria-label="Close button" aria-describedby="modal-title" (click)="modalService.close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<ng-container
    *ngIf="{
        activeTab: activeTab$ | async,
        tabs: tabs$ | async
    } as vm"
>
    <div class="d-flex">
        <ul class="menu-tabs">
            <li class="menu-tab" (click)="changeActiveTab(tab.id)" *ngFor="let tab of vm.tabs; let i = index">
                <a class="menu-tab-link cursor-pointer" [class.menu-tab-link--active]="tab.id === vm.activeTab">
                    {{ tab.name$ | async }}
                    <fa-icon class="ml-1" *ngIf="vm.tabs.length > 1" icon="facDelete" (click)="deleteTab(tab.id, i)"></fa-icon
                ></a>
            </li>
        </ul>
        <a class="ml-auto cursor-pointer align-self-start mt-2 add-tab" (click)="addTab()">
            <fa-icon class="mr-1" icon="plus"></fa-icon> Add tab</a
        >
    </div>

    <dp-sql-executor-tab
        class="flex-1"
        *ngFor="let tab of vm.tabs; trackBy: trackByTabFn"
        [hidden]="tab.id !== vm.activeTab"
        [sources$]="sources$"
        [form]="tab.form"
    ></dp-sql-executor-tab>
</ng-container>
