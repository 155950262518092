<div class="approvers-container">
    <ng-container *ngFor="let approver of approvers | slice: 0:(showAll ? approvers.length : defaultAppoversCount)">
        <div class="d-flex">
            <lib-user-pic class="mr-2 align-self-center" [user]="approver"></lib-user-pic>
            <div class="d-flex flex-column justify-content-center">
                <div class="body2">{{ approver.name }}</div>
                <div *ngIf="!!approver.position" class="body3 disable">
                    <fa-icon class="mr-1" icon="facGlobe"></fa-icon>{{ approver.position }}
                </div>
            </div>
        </div>

        <div class="d-flex align-items-center body3">
            <div class="custom-badge text-truncate">
                {{ approver.status }}
            </div>
        </div>
    </ng-container>
</div>

<button *ngIf="showMoreButtonAvailable" class="btn btn-link body1 mt-3 ml-1 py-2 px-3 d-flex" (click)="showAllToggle()">
    <fa-icon class="mr-2" [class.collapse-icon]="showAll" icon="doubleArrowDown"></fa-icon
    >{{ showAll ? 'Hide approvers' : 'Show more approvers' }}
</button>
