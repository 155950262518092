<div class="d-flex align-items-center px-4 py-20px">
    <h2>Dashboard Filters <span context-help-id="dashboard-filters"></span></h2>
    <button type="button" class="close ml-auto" (click)="collapse.emit()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<dp-filter-set-accordion (widgetFilterVisibility)="visibilityChanged($event)"></dp-filter-set-accordion>
<div class="d-flex footer px-4 py-3">
    <lib-split-button
        class="reset-button"
        dropdownMode="true"
        buttonIcon="rotationLeftWithDot"
        placement="top-left"
        [items]="resetActions"
        [isPrimary]="false"
        (itemClick)="$event.action()"
    >
        <ng-template let-item="item">
            <span class="body2">{{ item.label }}</span>
        </ng-template>
    </lib-split-button>
    <button
        data-test-id="save-button"
        [disabled]="(filtersDirtyState.saveEnabled$ | async) === false"
        ngbTooltip="Click on Save to apply and store filter selections for current and future user sessions."
        placement="top"
        class="btn btn-secondary flex-1"
        (click)="save.emit()"
    >
        Save
    </button>
    <button class="btn btn-primary flex-1" [disabled]="(filtersDirtyState.applyEnabled$ | async) === false" (click)="apply.emit()">
        Apply
    </button>
</div>
