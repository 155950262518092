import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { delay, map, shareReplay, switchMap } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { Store } from '@dagility-ui/kit';

import { AnyWidgetModel, WidgetDrilldown } from '../../../models/any-widget.model';
import { WidgetDebuggerState } from '../../../services/widget.debugger';
import { DrilldownEvent, WidgetDrilldownHistory } from '../../../services/widget.drilldown';
import { WidgetTemplateInterpolationManager } from '../../../services/widget-template.interpolation';
import { WidgetLogger, WidgetScriptExecutor } from '../../../services/widget-script.executor';
import { WidgetWorkflow } from '../../../services/widget.flow';
import { checkWidgetIsValid } from '../../../services/widget-builder.util';

export interface AnyWidgetState {
    widget: AnyWidgetModel;
    placeholders: Record<string, any>;
}

@Injectable()
export class AnyWidgetStore extends Store<AnyWidgetState> {
    drilldown$ = new Subject<void>();
    workflow: WidgetWorkflow;
    debuggerState: WidgetDebuggerState = null;
    inited$ = new BehaviorSubject(null);
    extensionOpened: boolean = false;

    placeholders$ = this.inited$.pipe(
        switchMap(() => {
            const { id } = this.value.widget;

            if (this.workflow) {
                return this.workflow.widgetDebugger.placeholdersMap[id];
            }

            if (this.debuggerState) {
                return this.debuggerState.placeholders$;
            }

            return this.select(state => state.placeholders);
        }),
        shareReplay(1)
    );

    title$ = this.placeholders$.pipe(
        map(placeholders => new WidgetTemplateInterpolationManager(placeholders).interpolate(this.value.widget.chartOptions.title)),
        delay(0)
    );

    description$ = this.placeholders$.pipe(
        map(placeholders => new WidgetTemplateInterpolationManager(placeholders).interpolate(this.value.widget.chartOptions.description)),
        delay(0)
    );

    get filters() {
        return this.value.widget.filters;
    }

    private drilldownHistory = new WidgetDrilldownHistory();

    filterValuesMap: Record<string, any> = {};
    rootWidget: AnyWidgetModel;
    mock = false;
    externalOptions: Record<string, any>;
    phf: Record<string, any> = {};
    gridColumnDefsBeforePagination: Record<string, any> = {};
    widgetIsValid = true;

    isRoot(): boolean {
        return this.state.widget === this.rootWidget;
    }

    get title(): string {
        return new WidgetTemplateInterpolationManager(this.placeholders).interpolate(this.state.widget.chartOptions.title);
    }

    get description() {
        return new WidgetTemplateInterpolationManager(this.placeholders).interpolate(this.state.widget.chartOptions.description);
    }

    get placeholders(): Record<string, any> {
        return this.debuggerState ? this.debuggerState.placeholders$.value : this.state.placeholders;
    }

    get currentLevel(): number | null {
        return this.workflow ? this.workflow.position.range.drilldowns.length : null;
    }

    constructor(private scriptExecutor: WidgetScriptExecutor, private logger: WidgetLogger) {
        super({
            widget: null,
            placeholders: {},
        });
    }

    initRoot(widget: AnyWidgetModel, placeholders: Record<string, any> = {}): void {
        this.rootWidget = widget;
        this.widgetIsValid = checkWidgetIsValid(widget);

        this.phf = placeholders;
        this.setState({
            widget,
            placeholders,
        });
        this.inited$.next(null);
    }

    reset() {
        this.logger.clear();
        this.drilldownHistory.reset();
        this.filterValuesMap = {};
        this.initRoot(cloneDeep(this.rootWidget), {});
    }

    drilldown(
        placeholders: Record<string, any>,
        event: DrilldownEvent,
        drilldown: WidgetDrilldown,
        filtersState: Record<string, any>,
        openModal = false
    ): any {
        const oldPlaceholders = cloneDeep(placeholders);
        const context = {
            placeholders: {
                ...placeholders,
                ...filtersState,
            },
            event: event.payload,
        };

        if (!!drilldown.drilldownScript) {
            this.scriptExecutor.evaluateScript({
                context,
                expression: drilldown.drilldownScript,
                type: 'drilldown',
                placeholder: this.state.widget.chartOptions.title,
            });
        }

        if (drilldown.displayType === 'modal' && openModal) {
            return {
                placeholders: context.placeholders,
                options: drilldown.widget,
                anyWidgetId: drilldown.widget.id,
            };
        }

        this.drilldownHistory.push({
            widget: this.state.widget,
            placeholders: oldPlaceholders,
        });
        for (const id of [this.state.widget.id, ...(this.state.widget.widgets || []).map(w => w.id)]) {
            this.filterValuesMap[id] = filtersState[id];
        }

        this.phf = context.placeholders;

        this.widgetIsValid = checkWidgetIsValid(drilldown.widget);
        this.setState({
            widget: cloneDeep(drilldown.widget),
            placeholders: {},
        });

        this.drilldown$.next();
    }

    back(): void {
        if (this.drilldownHistory.empty()) {
            return;
        }

        this.logger.clear();

        const { widget, placeholders } = this.drilldownHistory.pop();

        this.widgetIsValid = checkWidgetIsValid(widget);
        this.setState({ widget, placeholders });
    }

    updatePlaceholders(): void {
        this.setState({
            placeholders: {
                ...this.placeholders,
            },
        });
    }

    updateAndReload(event: { placeholder: string; value: any }[]) {
        this.workflow.updatePlaceholdersAndReload(event);
    }
}
