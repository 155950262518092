import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { GridListItem, GridsterOptions, IGridsterOptions } from 'angular2gridster';

import { DataMorph } from '../../models/dp-dashboard.model';
import { AnyWidgetModel, WidgetType } from '../../../widget-builder/models/any-widget.model';

import {
    buildResponsiveOption,
    GRIDSTER_BREAKPOINTS,
    GRIDSTER_OPTIONS,
    GridsterBreakpoint,
    GridsterMinSizes,
    GridsterProperty,
    GridsterResponsiveOptions,
    LANES_BY_BREAKPOINTS,
    MIN_WIDGET_SIZE,
    WidgetWithMinSizes,
} from './angular2gridster.const';

export const WIDGET_SIZE_DELIMITER = 'x';

@Injectable()
export class GridsterOptionsService implements OnDestroy {
    currentOptions: IGridsterOptions;
    maxLanes$: Observable<number>;
    breakpoint$: Observable<GridsterBreakpoint>;
    private currentBreakpointChange$ = new ReplaySubject<GridsterBreakpoint>(1);
    private maxLanesChange$ = new ReplaySubject<number>(1);
    private destroy$ = new Subject<void>();

    constructor(zone: NgZone) {
        zone.runOutsideAngular(() => {
            const gridsterOptions = new GridsterOptions(GRIDSTER_OPTIONS, null);
            this.maxLanes$ = this.maxLanesChange$.asObservable().pipe(distinctUntilChanged());
            this.breakpoint$ = this.currentBreakpointChange$.asObservable().pipe(distinctUntilChanged());

            gridsterOptions.change.pipe(takeUntil(this.destroy$)).subscribe(options => {
                this.currentOptions = options;
                this.currentBreakpointChange$.next((this.currentOptions.breakpoint as GridsterBreakpoint) || 'sm');
                this.maxLanesChange$.next(this.getMaxLanes());
            });
        });
    }

    getMaxLanes(): number {
        return this.currentOptions.lanes;
    }

    convertProportionToUnit(proportion: string | number, size: number): number {
        return Math.floor(parseFloat(proportion as never) * size);
    }

    fillSizes(
        widget: DataMorph.IlluminateDashboardWidget & { breakpointMinSizes: GridsterMinSizes },
        size: { w: number; h: number } = this.getDefaultSizes(widget.data)
    ) {
        const gridsterOptions: GridsterResponsiveOptions = {};
        widget.breakpointMinSizes = this.fillMinSizes(widget.data);

        this.fillWidgetSizes({
            changes: ['w', 'h'],
            size,
            options: gridsterOptions,
            minSizes: widget.breakpointMinSizes,
        });

        return gridsterOptions;
    }

    getDefaultSizes(widget: AnyWidgetModel) {
        const { lanes } = this.currentOptions;
        const defaultSize = MIN_WIDGET_SIZE / lanes;

        return {
            w: this.convertProportionToUnit(widget.size?.defaultW || defaultSize, lanes),
            h: this.convertProportionToUnit(widget.size?.defaultH || defaultSize, lanes),
        };
    }

    parseSize(size: string) {
        const [w, h] = size.split(WIDGET_SIZE_DELIMITER);

        return { w: Number(w), h: Number(h) };
    }

    fillMinSizes(widget: AnyWidgetModel) {
        const hasProportions = !!widget.size;

        return GRIDSTER_BREAKPOINTS.reduce((acc, breakpoint) => {
            const lanes = LANES_BY_BREAKPOINTS[breakpoint];
            acc[breakpoint] = {
                minWidth: hasProportions ? this.coerceMinSize(this.convertProportionToUnit(widget.size.minW, lanes)) : MIN_WIDGET_SIZE,
                minHeight: hasProportions ? this.coerceMinSize(this.convertProportionToUnit(widget.size.minH, lanes)) : MIN_WIDGET_SIZE,
                maxHeight: lanes,
            };

            if (widget.type === WidgetType.METRIC_TILE) {
                acc[breakpoint].maxWidth = acc[breakpoint].maxHeight = acc[breakpoint].minWidth = acc[breakpoint].minHeight = 2;
            }

            return acc;
        }, {} as WidgetWithMinSizes['breakpointMinSizes']);
    }

    fillWidgetSizes(params: {
        changes: Array<GridsterProperty>;
        size: Pick<GridListItem, 'w' | 'h'>;
        options: GridsterResponsiveOptions;
        minSizes: GridsterMinSizes;
    }) {
        const { changes, size, options, minSizes } = params;
        const { breakpoint: currentBreakpoint } = this.currentOptions;

        const setProperty = (propertyKey: GridsterProperty, value: number, breakpoint: GridsterBreakpoint) => {
            if (!changes.includes(propertyKey)) {
                return;
            }

            options[buildResponsiveOption(breakpoint, propertyKey)] = value;

            if (breakpoint === 'sm') {
                options[propertyKey] = value;
            }
        };

        const currentLanes = LANES_BY_BREAKPOINTS[(currentBreakpoint as GridsterBreakpoint) || 'sm'];

        for (const breakpoint of GRIDSTER_BREAKPOINTS) {
            if (breakpoint === currentBreakpoint) {
                const { maxWidth, maxHeight } = minSizes?.[breakpoint] ?? {};

                if (maxWidth && maxHeight) {
                    size.w = Math.min(size.w, maxWidth);
                    size.h = Math.min(size.h, maxHeight);
                }

                setProperty('w', size.w, breakpoint);
                setProperty('h', size.h, breakpoint);

                continue;
            }
            const { [breakpoint]: breakpointLanes } = LANES_BY_BREAKPOINTS;
            const k = breakpointLanes / currentLanes;
            const { minWidth, minHeight, maxWidth, maxHeight } = minSizes?.[breakpoint];
            let scaledWidth = Math.max(Math.min(this.convertProportionToUnit(k, size.w), breakpointLanes), minWidth, MIN_WIDGET_SIZE);
            let scaledHeight = Math.max(Math.min(this.convertProportionToUnit(k, size.h), breakpointLanes), minHeight, MIN_WIDGET_SIZE);
            if (maxHeight && maxWidth) {
                scaledWidth = Math.min(scaledWidth, maxWidth);
                scaledHeight = Math.min(scaledHeight, maxHeight);
            }
            setProperty('w', scaledWidth, breakpoint);
            setProperty('h', scaledHeight, breakpoint);
        }
    }

    isChangeForCurrentBreakpoint(breakpoint: string) {
        return !breakpoint || breakpoint === this.currentOptions.breakpoint;
    }

    coerceMinSize(size: number): number {
        return Math.max(size, MIN_WIDGET_SIZE);
    }

    coerceSize(size: number, minSize: number): number {
        return Math.max(size, minSize);
    }

    ngOnDestroy() {
        this.destroy$.next();
    }
}
