import { isEmpty } from 'lodash';

import { JobDefinitionDataType } from './enums';
import { Deserializable } from './abstract-job-definition';
import { IsBoolean, IsString, Type, IsEnum } from '../type.decorator';
import { Validate, NotEmpty, MapRequired, Required } from '../validate.decorator';

export class TargetReference extends Deserializable {
    _maps = {
        fields: 'string',
    };
    _enums = {
        dataType: JobDefinitionDataType,
    };
    _arrays = {};
    _class = {};
    _nameMapping = {
        fields: {
            key: 'Target Column',
            value: 'Variable Field',
        },
    };
    meta = {
        _arrays: this._arrays,
        _enums: this._enums,
        _class: this._class,
        _maps: this._maps,
        _nameMapping: this._nameMapping,
    };

    @Required()
    @IsString()
    from: string = '';

    @Required()
    @IsString()
    targetField: string = '';

    @Required()
    @IsEnum(JobDefinitionDataType)
    dataType: JobDefinitionDataType = null;

    @MapRequired()
    @Validate([NotEmpty(isEmpty)])
    @Type(String)
    fields: Map<string, string> = {} as any;

    @IsString()
    sourceField: string = '';

    @IsBoolean()
    required = false;

    getType() {
        return 'TargetReference';
    }

    getInfo() {
        return `Target Reference ${this.dataType ? `data type = "${this.dataType}"` : ''} ${this.from ? `from = "${this.from}"` : ''}`;
    }

    constructor(instanceData?: TargetReference) {
        super();
        this.fillByInstance(TargetReference, instanceData);
    }
}
