import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { JobDefinition } from '../models/job-definition';
import { EditJobDefinitionService } from '../edit-job-definition.service';

@Component({
    selector: 'dp-edit-job-definition-groovy',
    templateUrl: './edit-job-definition-groovy.component.html',
    host: {
        class: 'd-flex flex-column'
    },
    styleUrls: ['../templates.scss'],
})
export class EditJobDefinitionGroovyComponent {
    @Input() formSlice: FormGroup;
    @Input() scripts: any;

    scriptsNames = {
        beforeScript: 'Before Script',
        beforeIterationScript: 'Before Iteration Script',
        afterIterationScript: 'After Iteration Script',
        afterScript: 'After Script',
    };

    scriptFields = JobDefinition.GROOVY_FIELDS.map(field => ({ field, label: (this.scriptsNames as Record<string, any>)[field] }));

    constructor(public state: EditJobDefinitionService) {}
}
