import { ChangeDetectionStrategy, Component, ElementRef, Input, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { noop } from 'rxjs';

import { EditJobDefinitionService } from '../../edit-job-definition.service';
import { DebugTabs } from '../../state/job-definition.state.model';
import { DebugContext } from '../../state/job-definition-debug.state';

@Component({
    selector: 'dp-job-definition-builder-debug',
    templateUrl: './job-definition-builder-debug.component.html',
    styleUrls: ['./job-definition-builder-debug.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobDefinitionBuilderDebugComponent implements OnInit, OnDestroy {
    @Input() debugContext: DebugContext;

    tabs: { field: DebugTabs; name: string }[] = [
        { field: 'variables', name: 'Variables' },
        { field: 'watches', name: 'Watches' },
        { field: 'evaluate', name: 'Evaluate' },
    ];
    unListenFn: () => void = noop;

    constructor(
        public store: EditJobDefinitionService,
        private elementRef: ElementRef<HTMLElement>,
        private zone: NgZone,
        private renderer2: Renderer2
    ) {}

    ngOnInit() {
        this.zone.runOutsideAngular(() => {
            this.unListenFn = this.renderer2.listen(this.elementRef.nativeElement, 'keyup', e => {
                if (e && e.code === 'Delete') {
                    e.stopImmediatePropagation();
                    e.preventDefault();
                }
            });
        });
    }

    addWatch() {
        this.store.addNewWatch();
    }

    removeWatch(i: number) {
        this.store.removeWatch(i);
    }

    evaluateWatch({ index, expression }: any) {
        this.store.evaluate(index, expression);
    }

    tabChange(tab: DebugTabs) {
        this.store.changeDebugTab(tab === this.debugContext.activeTab ? null : tab);
    }

    collapse() {
        this.store.changeDebugTab(null);
    }

    ngOnDestroy() {
        this.unListenFn();
    }
}
