import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, publishReplay, refCount, tap } from 'rxjs/operators';

import { AuthService } from '@app/auth';
import { RbacAction, RbacStaticType } from '@dagility-ui/kit';
import { IModuleParamSubstitution } from '@app/core/services/module-param-substitution';
import { KeepNiEnvSubstitution } from '@app/core/keep-ni/keep-ni-env-substitution';
import { ENV_TOKEN } from '@app/tokens';
import { HealthService } from '@app/shared/services/admin/health-service';

export interface ModuleConfig {
    name: string;
    displayName: string;
    contextPath: string;
    appUrl: string;
    healthUrl: string;
    image: string;
    position: number;
    permissionObject: string;
    permissionAction: string;
    isActive$?: Observable<boolean>;
    appUrl$?: Observable<string>;
}

@Injectable({
    providedIn: 'root',
})
export class ModulesApiService {
    protected readonly baseApiUrl = `${this.env.adminApiURL}/module`;

    private moduleParamProcessors: IModuleParamSubstitution[];

    constructor(
        @Inject(ENV_TOKEN) private env: Env,
        private http: HttpClient,
        private authorizationService: AuthService,
        private keepNiEnvSubstitution: KeepNiEnvSubstitution,
        private healthService: HealthService
    ) {
        this.moduleParamProcessors = [keepNiEnvSubstitution];
    }

    hasRightForObject(action: RbacAction, objectLocator: string): Observable<boolean> {
        return this.authorizationService.hasPermission(RbacStaticType.OBJECT, objectLocator, action);
    }

    getAllModules(): Observable<ModuleConfig[]> {
        return this.http.get<ModuleConfig[]>(`${this.baseApiUrl}/modules`).pipe(
            tap((modules: ModuleConfig[]) => {
                modules.forEach(mod => {
                    if (mod.image.includes('data:image')) {
                        mod.image = null;
                    }
                    mod.isActive$ = this.isModuleActive(mod);
                    this.processSubstitutions(mod);
                });
            }),
            catchError(() => {
                return of([]);
            })
        );
    }

    getModuleUrl(moduleApiUrl: string) {
        return this.http
            .get(moduleApiUrl, {
                headers: { 'skip-global-error': 'true' },
                responseType: 'text',
            })
            .pipe(catchError(() => of(null)));
    }

    private processSubstitutions(mod: ModuleConfig) {
        this.moduleParamProcessors.forEach(p => p.process(mod));
    }

    isModuleActive(moduleConfig: ModuleConfig): Observable<boolean> {
        const result =
            moduleConfig.permissionAction && moduleConfig.permissionObject
                ? this.hasRightForObject(moduleConfig.permissionAction, moduleConfig.permissionObject).pipe(
                      mergeMap(hasRights => (hasRights ? this.urlStatus(moduleConfig) : of(false)))
                  )
                : this.urlStatus(moduleConfig);
        return result.pipe(publishReplay(1), refCount());
    }

    private urlStatus(moduleConfig: ModuleConfig): Observable<boolean> {
        const globalUrl = moduleConfig.healthUrl && moduleConfig.healthUrl.startsWith('http');
        const healthUrl = moduleConfig.healthUrl && moduleConfig.healthUrl.includes('actuator/health');
        const localRootUrl = healthUrl ? this.env.serviceUrl : '';

        if (healthUrl) {
            const isAlive$ = this.healthService.getIsHealthUrlAlive(moduleConfig.healthUrl);
            if (isAlive$) {
                return isAlive$;
            }
        }

        return this.http
            .get<any>(globalUrl ? moduleConfig.healthUrl : `${localRootUrl}${moduleConfig.healthUrl}`, {
                headers: { 'skip-global-error': 'true' },
            })
            .pipe(
                map(isAlive => (healthUrl ? isAlive.status === 'UP' : !!isAlive)),
                catchError(() => of(false))
            );
    }
}
