import {
    DataSetFieldRole,
    JoinCondition,
    JoinType,
    ModelGraph,
    ModelReference,
    DataSetField,
    ParameterDefValueType,
    ParameterDefType,
    DataSetFieldRoleOrd,
} from '@app/shared/components/query-builder/models/model-graph.model';
import { Page, SortDirection } from '@dagility-ui/kit';
import { ValidationError } from '@app/shared/components/query-builder/services/query-builder-error-parser.service';
import { AnyWidget, WidgetFilter } from 'data-processor';

export interface ModelGraphPreviewDataRequest {
    body: {
        graph: ModelGraph;
        placeholders: Map<string, any>;
    };
    params: {
        page: number;
        size: number;
        sort: string[];
    };
}

export interface ModelGraphSaveAsModelRequest {
    modelGraph: ModelGraph;
    modelName: string;
    modelDescription: string;
}

export interface ModelRelationCandidate {
    modelName: string;
    fieldName: string;
    parentModelUid: string;
    referencedModelUid: string;
    dataVar: string;
    joinType: JoinType;
    joinDescription: string;
    joinCondition: JoinCondition;
}

export interface ModelFilterCandidate {
    parentModelUid: string;
    name: string;
    description: string;
    filterModelUid: string;
}

export interface ModelGraphFieldsRequest extends ModelGraphMutationCommand {
    local?: boolean;
}

export interface Parameter {
    uid?: string;
    name: string;
    valueType: ParameterDefValueType;
    type?: ParameterDefType;
}

export interface ExternalParameter extends Parameter {
    value: any;
}

export interface DBFunctionArgs {
    argCount: number;
    returnType: string;
    argTypes: string[];
}

export enum ExpressionMode {
    SQL = 'SQL',
    GROOVY = 'GROOVY',
}

export interface ExpressionParts {
    fields: DataSetField[];
    parameters: string[];
    functions: DBFunction[];
    operators: DBFunction[];
    mode: ExpressionMode | null;
    templates: ExpressionTemplate[];
}
export interface DBFunction {
    uid: string;
    name: string;
    description: string;
    schema: string;
    dbToolId: string;
    args: DBFunctionArgs;
}

export interface ExpressionTemplate {
    uid?: string;
    used?: number;
    global: boolean;
    userId?: string;
    text: string;
    content: string;
}

export interface GraphMutationResult {
    readonly graph: ModelGraph;
    readonly timeTaken: number;
    readonly validationResults: ValidationError[];
}

export interface ModelGraphNewDrillDownResponse {
    defaultName: string;
    defaultParameter: string;
}

export interface DataPreviewResponse {
    readonly result: Page<Map<string, object>>;
    readonly errors?: string[];
}

export interface ModelGraphMutationCommand {
    sourceGraph: ModelGraph;
    subGraphId?: string;
    drillDownId?: string;
}

export interface ModelGraphReorderDataSetFieldRoleCommand extends ModelGraphMutationCommand {
    dsFieldUid: string;
    srcModelUid: string;
    srcDataField: string;
    role: DataSetFieldRoleOrd;
}

export interface ModelGraphAddWCFilterCommand extends ModelGraphMutationCommand {
    filter: WidgetFilter;
    modelUid: string;
    labelDataField: string;
    valueDataField: string;
}

export interface ModelGraphUpdateWidgetSettingsCommand extends ModelGraphMutationCommand {
    widgetSettings: AnyWidget;
}

export interface ModelGraphUpdateWCFilterCommand extends ModelGraphMutationCommand {
    filterUid: string;
    filter: WidgetFilter;
    modelUid: string;
    labelDataField: string;
    valueDataField: string;
}

export interface ModelGraphDeleteWCFilterCommand extends ModelGraphMutationCommand {
    filterUid: string;
}

export interface ModelGraphReorderWCFilterCommand extends ModelGraphMutationCommand {
    filterUid: string;
    ord: number;
}

export interface ModelGraphAddDataSetFieldRoleCommand extends ModelGraphMutationCommand {
    dsFieldUid: string;
    srcModelUid: string;
    srcDataField: string;
    roles: DataSetFieldRole[];
}

export interface ModelGraphUpdateReferenceCommand extends ModelGraphMutationCommand {
    reference: ModelReference;
}

export interface ModelGraphAddReferencedModelCommand extends ModelGraphMutationCommand {
    relation: ModelRelationCandidate;
}

export interface ModelGraphAttachModelFilterCommand extends ModelGraphMutationCommand {
    filter: ModelFilterCandidate;
}

export interface ModelGraphCreateCommand extends ModelGraphMutationCommand {
    rootModelUid: string;
}

export interface ModelGraphUpdateOrderByFieldsCommand extends ModelGraphMutationCommand {
    fields: FieldOrder[];
}

export interface ModelGraphUpdateCalculatedDataSetFieldCommand extends ModelGraphMutationCommand {
    dsFieldUid: string;
    fieldName: string;
    dataField: string;
    expression: string;
}

export interface ModelGraphAddCalculatedDataSetFieldCommand extends ModelGraphMutationCommand {
    fieldName: string;
    dataField: string;
    expression: string;
}

export interface ModelGraphDetachModelFilterCommand extends ModelGraphMutationCommand {
    parentModelUid: string;
    filterModelUid: string;
}

export interface ModelGraphRemoveDataSetFieldRoleCommand extends ModelGraphMutationCommand {
    srcModelUid: string;
    srcDataField: string;
    dsFieldUid: string;
}

export interface ModelGraphRemoveNotAllowedDatasetRolesCommand extends ModelGraphMutationCommand {
    roles: DataSetFieldRole[];
}

export interface ModelGraphRemoveFilterCommand extends ModelGraphMutationCommand {
    filterModelUid: string;
}

export interface ModelGraphRemoveModelCommand extends ModelGraphMutationCommand {
    referencedModelUid: string;
}

export interface FieldOrder {
    dsFieldUid: string;
    dir: SortDirection;
}

export interface FieldRef {
    dsFieldUid?: string;
    srcModelUid?: string;
    fieldName?: string;
}

export interface ModelGraphAddGroupByCommand extends ModelGraphMutationCommand {
    fieldRefs: FieldRef[];
}

export interface ModelGraphRemoveGroupByCommand extends ModelGraphMutationCommand {
    fieldRefs: FieldRef[];
}

export interface ModelGraphSelectFieldCommand extends ModelGraphMutationCommand {
    fields: FieldRef[];
}

export interface ModelGraphUnselectFieldCommand extends ModelGraphMutationCommand {
    fields: FieldRef[];
}

export interface QBConditionGroup {
    id?: number;
    parentId?: number;
    groupOp?: 'AND' | 'OR';
    conditions?: QBConditionGroup[];
    expression?: string[];
    expressions?: any;
}

export interface ModelGraphUpdateFilterConditionCommand extends ModelGraphMutationCommand {
    filter: QBConditionGroup | QBConditionGroup[];
}

export interface ModelGraphUpdateUniqueSelectionCommand extends ModelGraphMutationCommand {
    unique: boolean;
}

export interface ModelGraphRenameModelCommand extends ModelGraphMutationCommand {
    modelUid: string;
    name: string;
}

export interface WidgetDrilldown {
    uid: string;
    graph: ModelGraph;
    drilldown: IDrilldown;
    drillDownName?: string;
    drillDownParamName?: string;
}

export interface IDrilldown {
    drilldownScript?: string;
    displayType?: string;
    default?: boolean;
    drillDownName?: string;
    drillDownParamName?: string;
    uid?: string;
}

export interface ModelGraphAddDrilldownCommand extends ModelGraphMutationCommand {
    drilldown: IDrilldown;
    drillDownName: string;
    drillDownParamName: string;
    subGraphId: string;
    drillDownId: string; // здесь id DrillDown, в котором создаем новый
}
export interface ModelGraphUpdateDrilldownCommand extends ModelGraphMutationCommand {
    drilldown: IDrilldown;
    drillDownName: string;
    drillDownParamName: string;
    subGraphId: string;
    drillDownId: string; // здесь id DrillDown, в котором обновляем DD (id вложенного указывается ниже)
    drillDownUpdateId: string; // здесь id вложенного DrillDown, который мы обновляем
}
export interface ModelGraphDeleteDrilldownCommand extends ModelGraphMutationCommand {
    drillDownId: string; // десь id DrillDown, в котором в котором удаляем DD (id вложенного указывается ниже)
    subGraphId: string;
    drillDownDeleteId: string;
}
export interface ModelGraphDefaultDrilldownCommand extends ModelGraphMutationCommand {
    drillDownId: string;
}

export interface ModelGraphCreateParameterCommand extends ModelGraphMutationCommand {
    parameter: Parameter;
}

export interface ModelGraphUpdateParameterCommand extends ModelGraphMutationCommand {
    parameter: Parameter;
}

export interface ModelGraphDeleteParameterCommand extends ModelGraphMutationCommand {
    parameterUid: string;
}
