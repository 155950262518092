import { Component, Input } from '@angular/core';

import { CodeCoverage, CoverageStatisticsType } from '../model/code-quality-summary.model';

@Component({
    selector: 'dp-code-coverage',
    templateUrl: './code-coverage.component.html',
    styleUrls: ['./code-coverage.component.scss'],
})
export class CodeCoverageComponent {
    @Input() item: CodeCoverage;

    getCoverageStatisticsList(statisticsMap: Record<CoverageStatisticsType, { label: string, value: any }>): { label: string, value: any }[] {
        return statisticsMap ? Object.entries(statisticsMap).map(x => x[1]) : [];
    }
}
