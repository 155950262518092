import { Injectable, OnDestroy } from '@angular/core';
import { GridsterComponent } from 'angular2gridster';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class GridsterReflowService implements OnDestroy {
    private gridsterComp: GridsterComponent;
    private destroyed$ = new Subject<void>();
    gridsterReady$ = new ReplaySubject<void>(1);

    init(gridsterComp: GridsterComponent) {
        if (gridsterComp) {
            this.gridsterComp = gridsterComp;
            gridsterComp.ready.pipe(takeUntil(this.destroyed$)).subscribe(() => this.gridsterReady$.next());
        }
    }

    reflow(gridsterComp?: GridsterComponent) {
        if (gridsterComp) {
            this.gridsterComp = gridsterComp;
        } else {
            gridsterComp = this.gridsterComp;
        }
        if (!gridsterComp?.$element.clientWidth || !gridsterComp?.$element.clientHeight) {
            return;
        }

        let topOffset = 0;
        const grid = gridsterComp.gridster.gridList.grid;
        for (let rowIndex = 0; rowIndex < grid.length; ) {
            const row = grid[rowIndex];
            const widgetsInRow = [...new Set(row.filter(Boolean))];
            if (topOffset > 0) {
                // recalculate y position for any widgets if there was at least one widget that changed its height from fixed to auto
                for (const item of widgetsInRow) {
                    item.$element.style.transform = `translate(${item.positionX}px, ${topOffset}px)`;
                }
            }

            // make auto height for all widgets that are the only one in a row
            if (
                widgetsInRow.length === 1 &&
                ['portfolioHealthScores', 'portfolioScoreSubcategories', 'portfolioStatistics', 'portfolioRiskScores'].includes(
                    widgetsInRow[0].$element.firstElementChild.firstElementChild.dataset.type
                )
            ) {
                const item = widgetsInRow[0];
                const scrollEl = item.$element.firstElementChild;
                const contentEl = scrollEl.firstElementChild;
                const scrollElRect = scrollEl.getBoundingClientRect();
                contentEl.classList.add('h-auto');
                const contentRect = contentEl.getBoundingClientRect();
                contentEl.classList.remove('h-auto');
                const contentHeight = contentRect.bottom - scrollElRect.top + scrollEl.offsetTop * 2;

                item.$element.style.height = `${contentHeight}px`;
                topOffset += contentHeight;
            } else {
                topOffset += widgetsInRow[0].$element.offsetHeight;
            }
            rowIndex += widgetsInRow.length ? widgetsInRow[0].h : 1;
        }

        const wrapper = gridsterComp.$element.firstElementChild as HTMLElement;
        wrapper.style.height = topOffset + 'px';
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }
}
