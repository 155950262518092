<div class="card">
    <button type="button" class="close position-absolute" aria-label="Close button" aria-describedby="modal-title" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
    <div [formGroup]="formGroup" class="card-body common-popup-form p-0 h-100">
        <div class="col-md col-md-12 h-100 d-flex flex-column">
            <div class="row pt-25px pb-0 px-15px">
                <h6>New Folder</h6>
            </div>
            <div class="row py-15px">
                <lib-input
                    class="col"
                    id="name"
                    formControlName="folderName"
                    label="Name"
                    [customErrors]="{ pattern: 'Only numbers, letters a-z, A-Z and symbols \'-\', \'_\' are allowed' }"
                    (keypress)="enterKeyHandler($event)"
                ></lib-input>
            </div>
            <div class="row py-15px px-15px">
                <div class="d-flex justify-content-end w-100">
                    <button class="btn btn-link mr-25px" (click)="cancel()" type="button">
                        Cancel
                    </button>
                    <button class="btn btn-primary" type="submit" (click)="submit()">
                        Create
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
