import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkTreeModule } from '@angular/cdk/tree';

import { EditJobDefinitionModule } from './edit-job-definition-form/edit-job-definition.module';
import { ProcessorMonitoringRoutingModule } from './processor-monitoring-routing.module';
import { ProcessorMonitoringComponent } from './processor-monitoring.component';
import { ViewJobDefinitionLogFormComponent } from './view-job-definition-log-form/view-job-definition-log-form.component';
import { ProcessorsComponent } from './processors/processors.component';
import { ToolTreeNodeComponent } from './processors/tool-tree-node/tool-tree-node.component';
import { MonitoringStateComponent } from './processors/monitoring-state/monitoring-state.component';
import { ProcessorMonitoringService } from './processor-monitoring.service';
import { JobDefinitionStartFormComponent } from './processors/job-definition-start-form/job-definition-start-form.component';

import { CreateNewJobDefinitionFormComponent } from './processors/create-new-job-definition-form/create-new-job-definition-form.component';
import { JobSetConfirmFormComponent } from './processors/job-set-confirm-form/job-set-confirm-form.component';
import { JobSetFormComponent } from './processors/job-set-form/job-set-form.component';
import { ToolGraphViewComponent } from './tool-graph-view/tool-graph-view.component';
import { JobDefinitionBlockComponent } from './tool-graph-view/job-definition-block/job-definition-block.component';
import { JobDefinitionChannelLabelComponent } from './tool-graph-view/job-definition-channel-label/job-definition-channel-label.component';
import { ToolGraphViewLayoutComponent } from './tool-graph-view/tool-graph-view-layout/tool-graph-view-layout.component';
import { SqlExecutorComponent } from './sql-executor/sql-executor.component';
import { SqlExecutorTabComponent } from './sql-executor/sql-executor-tab/sql-executor-tab.component';
import { PropertiesEditorModule, SharedComponentsModule, WrapToPerfectScrollbarDirectiveModule } from '@dagility-ui/shared-components';

@NgModule({
    declarations: [
        ProcessorMonitoringComponent,
        ProcessorsComponent,
        ToolTreeNodeComponent,
        MonitoringStateComponent,
        ViewJobDefinitionLogFormComponent,
        JobDefinitionStartFormComponent,
        CreateNewJobDefinitionFormComponent,
        JobSetConfirmFormComponent,
        JobSetFormComponent,
        ToolGraphViewComponent,
        JobDefinitionBlockComponent,
        JobDefinitionChannelLabelComponent,
        ToolGraphViewLayoutComponent,
        SqlExecutorComponent,
        SqlExecutorTabComponent,
    ],
    imports: [
        CommonModule,
        SharedComponentsModule,
        EditJobDefinitionModule,
        ProcessorMonitoringRoutingModule,
        CdkTreeModule,
        PropertiesEditorModule,
        WrapToPerfectScrollbarDirectiveModule,
    ],
    providers: [ProcessorMonitoringService],
    exports: [ProcessorMonitoringComponent]
})
export class ProcessorMonitoringModule {
    static forRoot(): ModuleWithProviders<ProcessorMonitoringModule> {
        return {
            ngModule: ProcessorMonitoringModule,
            providers: [ProcessorMonitoringService],
        };
    }
}
