<div
    class="wrapper d-flex wrapper-{{ square.status }}"
    [class.cursor-pointer]="square.drilldownTarget"
    [class.with-status-bar]="square.statusBarValue >= 0"
    [class.text-white]="square.status !== 'none' && square.status !== 'warning'"
    [class.justify-content-between]="square.list"
    [ngbTooltip]="square.tooltip || ''"
    container="body"
    (click)="squareClickedHandler(square)"
>
    <div class="value-col d-flex flex-column justify-content-center p-0"
         [ngClass]="square.list && square.listSizeIncreased ? 'col-4' : square.list || square.chart ? 'col-6' : 'col-8'">
        <div class="body1 font-weight-600" *ngIf="square.title">{{ square.title }}</div>

        <ng-container
            [ngTemplateOutlet]="square.sreSquareValue.additionalLabel ? valueWithAdditionalLabel : valueWithPostfix"
            [ngTemplateOutletContext]="{ $implicit: square }"
        ></ng-container>

        <span *ngIf="square.description" class="info2">
            <span *ngIf="square.description.icon" class="mr-2"
                  [innerHTML]="square.description.icon | safeHtml: 'html'"></span>
            <span [ngClass]="square.status === 'none' ? 'value-description' : ''">{{ square.description.value }}</span>
        </span>
    </div>

    <ng-container
        [ngTemplateOutlet]="square.list ? list : (square.icon ? icon : chart)"
        [ngTemplateOutletContext]="{ $implicit: square }"
    ></ng-container>
</div>

<div *ngIf="square.statusBarValue >= 0" class="status-bar">
    <div
        *ngFor="let i of statusBars"
        class="status-bar-value"
        [class.status-bar-failed]="i <= 9 && i <= square.statusBarValue"
        [class.status-bar-warning]="i > 9 && i <= 18 && i <= square.statusBarValue"
        [class.status-bar-success]="i > 18 && i <= 27 && i <= square.statusBarValue"
        [class.status-bar-none]="i >= square.statusBarValue && i <= 27"
    ></div>
</div>

<ng-template #icon let-item>
    <ng-container *ngIf="getSquare(item) as square">
        <div class="icon-col p-0" [innerHTML]="square.icon | safeHtml: 'html'"></div>
    </ng-container>
</ng-template>

<ng-template #list let-item>
    <ng-container *ngIf="getSquare(item) as square">
        <div [ngClass]="square.listSizeIncreased ? 'col-8' : 'col-6'"
            class="row py-20px">
            <div *ngFor="let listItem of square.list; let j = index"
                 [ngClass]="square.listSizeIncreased ? 'col-3' : 'col-6'"
                 class="d-flex align-items-center justify-content-center">
                <div class="flex-column d-flex align-items-center justify-content-center">
                    <div [ngClass]="square.listSizeIncreased ? 'w-100 ml-1' : ''"
                        class="row no-gutters align-items-center list-item-{{listItem.color}}">
                        <div *ngIf="listItem.color" class="list-item-dot list-item-dot-{{listItem.color}}"></div>
                        <div class="info2">{{listItem.label}}</div>
                    </div>

                    <div [ngClass]="square.listSizeIncreased ? 'w-100' : ''"
                        class="row no-gutters align-items-center mt-1">
                        <h2 class="mt-0"
                            [class.text-white]="square.status !== 'none' && square.status !== 'warning'"
                        >{{listItem.value}}
                        </h2>
                        <div *ngIf="listItem.valueBadge" class="list-item-badge">
                            {{listItem.valueBadge}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #chart let-item>
    <ng-container *ngIf="getSquare(item) as square">
        <div *ngIf="square.chart" class="col-6 no-gutters my-auto p-0">
            <lib-chart [disableMarginsAdjustment]="true"
                       [type]="square.chart.type"
                       [options]="square.chart.options"
                       [height]="'auto'"
            ></lib-chart>
        </div>
    </ng-container>
</ng-template>

<ng-template #valueWithAdditionalLabel let-item>
    <ng-container *ngIf="getSquare(item) as square">
        <h1 class="body5 m-0 additional-label additional-label-{{ square.status }}">{{ square.sreSquareValue.additionalLabel }}</h1>
        <ng-container
            [ngTemplateOutlet]="valueWithPostfix"
            [ngTemplateOutletContext]="{ $implicit: square }"
        ></ng-container>
    </ng-container>
</ng-template>

<ng-template #valueWithPostfix let-item>
    <ng-container *ngIf="getSquare(item) as square">
        <div class="row no-gutters"
            [class.align-items-center]="square.sreSquareValue.postfixTitle"
            [class.align-items-baseline]="!square.sreSquareValue.postfixTitle"
        >
            <h1 class="title1 my-2">{{ square.sreSquareValue.value }}</h1>
            <div class="col ml-2">
                <div class="info1" *ngIf="square.sreSquareValue.postfixTitle">
                    {{square.sreSquareValue.postfixTitle}}
                </div>
                <div class="value-description info2" *ngIf="square.sreSquareValue.postfix">
                    {{square.sreSquareValue.postfix}}
                </div>
            </div>

        </div>
    </ng-container>
</ng-template>
