import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DpDashboardService } from 'data-processor/lib/widget-library/dashboard/services/dp-dashboard.service';
import { finalize } from 'rxjs/operators';
import { DashboardGroupState } from 'data-processor/lib/widget-library/dashboard/state/dp-dashboard.state.model';

@Component({
    selector: 'dp-dashboard-group-reorder-dialog',
    styleUrls: ['./dp-dashboard-group-reorder-dialog.component.scss'],
    templateUrl: './dp-dashboard-group-reorder-dialog.component.html',
})
export class DpDashboardGroupReorderDialogComponent {
    groups: Array<DashboardGroupState> = [];

    dashboardId: string;

    tabId: number;

    saving = false;

    isDirty = false;

    initialGroupIds: string[];

    constructor(public activeModal: NgbActiveModal, private dpDashboardService: DpDashboardService) {}

    ngOnInit() {
        this.initialGroupIds = this.groups.map(group => group.id.toString());
    }

    dropGroup(event: CdkDragDrop<any[]>) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.isDirty = this.initialGroupIds.toString() !== this.groups.map(group => group.id.toString()).toString();
    }

    apply() {
        this.saving = true;
        const groupIds = this.groups.map(group => group.id.toString());
        this.dpDashboardService
            .updateGroupsOrder(this.dashboardId, this.tabId.toString(), groupIds)
            .pipe(finalize(() => (this.saving = false)))
            .subscribe(() => {
                this.activeModal.close(groupIds);
            });
    }
}
