import { Component, Inject, Injector, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { faCompass } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { facAbout, facHelp, facKey, facLegal, facLogout, facPortfolio, facProfile } from '@dagility-ui/shared-components/icons';
import { boldBell, ContextMenuItem, ModalConfirmComponent } from '@dagility-ui/kit';
import { ENV_TOKEN } from '@app/tokens';
import { DefaultUser } from '@app/models';
import { FeatureToggleService, UserService } from '@app/services';
import { AuthService } from '@app/auth';
import { StompService } from '@app/shared/services/admin/stomp.service';
import { HeaderMenuService } from '@app/shared/services/admin/header-menu.service';
import { OrganizationService } from 'src/app/pages/admin/organization/api/organization';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    @ViewChild('approvalsInfoPanelRef', { read: ViewContainerRef, static: true }) approvalsInfoPanelRef: ViewContainerRef;
    icons = {
        facLogout: facLogout,
    };

    user: DefaultUser;
    isAdmin: boolean;
    subscriptionAdmin: boolean;
    organizationLogo: { imagePath: string } = { imagePath: '' };

    isCalibrate: boolean = this.env.calibrate?.enabled;
    menuCollapsed = false;

    guidesDrupalId = 114;

    helpMenuItems: ContextMenuItem[] = [
        {
            name: 'Help',
            routerLink: `/help-support/${this.guidesDrupalId}`,
            icon: facHelp,
        },
        {
            name: 'Guided Tours',
            routerLink: `/product-tour`,
            icon: faCompass,
        },
        {
            name: 'Legal',
            routerLink: `/home/legal-notices`,
            icon: facLegal,
        },
        {
            name: 'About',
            routerLink: `/home/about`,
            icon: facAbout,
        },
    ];

    userSettingMenuItems: ContextMenuItem[] = [
        {
            name: 'My Profile',
            routerLink: `/user/profile`,
            icon: facProfile,
        },
    ];

    tourAnhorList: any = {
        project: 'my-projects',
        illuminate: 'illuminate',
    };

    isDarkMode: boolean;

    private subscriptions: Subscription[] = [];

    constructor(
        @Inject(ENV_TOKEN) private env: Env,
        private authorizationService: AuthService,
        private userService: UserService,
        private stompService: StompService,
        private toaster: ToastrService,
        public headerMenuService: HeaderMenuService,
        private injector: Injector,
        private featureToggleService: FeatureToggleService
    ) {}

    ngOnInit() {
        this.isDarkMode = document.documentElement.dataset.theme && document.documentElement.dataset.theme.includes('dark');
        this.userService.defaultTheme$
            .pipe(filter(value => value && typeof value === 'string'))
            .subscribe(value => (this.isDarkMode = value.includes('dark')));

        this.user = this.authorizationService.getUser();
        this.isAdmin = this.authorizationService.isAdmin();
        this.subscriptionAdmin = this.authorizationService.isSubscriptionAdmin();

        const organizationService = this.injector.get(OrganizationService);
        this.subscriptions.push(
            organizationService.getAvatar().subscribe(result => {
                if (result !== undefined) {
                    const timeStamp = new Date().getTime();
                    this.organizationLogo.imagePath = `${result}?${timeStamp}`;
                }
            }),
            organizationService.organizationLogo.subscribe(logo => {
                this.organizationLogo = logo;
            })
        );

        this.generateSettingsMenuItems();

        this.subscriptions.push(
            this.userService.userChanged.subscribe(user => {
                this.user = user;
            })
        );

        this.listenToStompMessages();
        this.initializeApprovalsInfoPanel();
    }

    getTourAnchorId(value: string) {
        return this.tourAnhorList[value];
    }

    logout() {
        const modalService = this.injector.get(NgbModal);
        const modalRef = modalService.open(ModalConfirmComponent, {
            centered: true,
            backdrop: 'static',
        });
        modalRef.componentInstance.message = {
            title: 'Log Out',
            content: 'Are you sure you want to log out?',
        };
        modalRef.componentInstance.confirmOk.subscribe(() => {
            this.authorizationService.logout();
        });
    }

    openHelp() {
        window.open(`#/help-support/${this.guidesDrupalId}`);
    }

    ngOnDestroy() {
        this.userService.defaultTheme$.unsubscribe();
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    private generateSettingsMenuItems() {
        if (!this.isCalibrate) {
            if (this.authorizationService.isAdmin() && this.featureToggleService.isActiveSync('eo_52856')) {
                this.userSettingMenuItems.push({
                    name: 'My Organizations',
                    routerLink: '/user/orgs',
                    icon: 'grid2',
                });
            }

            this.userSettingMenuItems.push(
                {
                    name: 'My Preferences',
                    routerLink: `/user/preferences`,
                    icon: 'cogs',
                },
                {
                    name: 'My Scope',
                    routerLink: `/user/scope`,
                    icon: facLegal,
                },
                {
                    name: 'My Portfolio',
                    routerLink: `/user/portfolio`,
                    icon: facPortfolio,
                },
                {
                    name: 'My Notification',
                    routerLink: `/user/notifications`,
                    icon: boldBell,
                }
            );

            this.headerMenuService.checkConveyorAvailability().subscribe(value => {
                if (value) {
                    this.userSettingMenuItems.splice(4, 0, {
                        name: 'My Keys',
                        routerLink: `/user/keys`,
                        icon: facKey,
                    });
                }
            });
        }
    }

    private listenToStompMessages() {
        this.subscriptions.push(
            this.stompService.onMessage.subscribe(msg => {
                if (msg.topicName === 'notification') {
                    let text = 'You have just received a notification!';
                    if (msg.body && msg.body.json) {
                        text = JSON.parse(msg.body.json).bodyContent;
                    }
                    this.toaster.info(text, 'Notification', { enableHtml: true });
                }
                if (msg.topicName === 'UserRolesUpdateEvent') {
                    this.toaster.info('Your roles have been changed!', 'User changed');
                }
                if (msg.topicName === 'UserStatusUpdateEvent') {
                    this.userService.getUser(msg.body.data.userId).subscribe(user => {
                        let cancel = false;
                        if (user.status !== 'ACTIVE') {
                            this.toaster
                                .warning('You will be logged off.', 'Your account has been deactivated!', { timeOut: 15000 })
                                .onHidden.subscribe(() => {
                                    if (!cancel) {
                                        this.authorizationService.logout();
                                    }
                                });
                        } else {
                            cancel = true;
                            this.toaster.info('Your status has been changed!', 'User changed');
                        }
                    });
                }
            })
        );
    }

    private initializeApprovalsInfoPanel() {
        if (!this.isCalibrate) {
            import('../../../shared/modules/approvals-info-panel/approvals-info-panel.module').then(m => {
                const infoPanelComponent = m.ApprovalsInfoPanelModule.entryComponent;
                const componentRef = this.approvalsInfoPanelRef.createComponent(infoPanelComponent);
                componentRef.location.nativeElement.classList.add('mr-4');
            });
        }
    }
}
