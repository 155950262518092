import { Deserializable } from './abstract-job-definition';
import { VariableType } from './enums/variable-type';
import { Required } from '../validate.decorator';
import { IsEnum, Type } from '../type.decorator';

export class Variable extends Deserializable {
    _class = {};
    _enums = {
        type: VariableType,
    };
    _maps = {};
    _arrays = {};

    meta = {
        _arrays: this._arrays,
        _enums: this._enums,
        _class: this._class,
        _maps: this._maps,
    };

    @Required()
    @Type(String)
    name = '';

    @Required()
    @Type(String)
    label = '';

    @Required()
    @IsEnum(VariableType)
    type: VariableType = null;

    mandatory: boolean = false;

    getType(): string {
        return 'Variable';
    }

    getInfo(): string {
        return `Variable ${this.name ? `label = "${this.label}"` : ''} ${
            this.type ? `type = "${this.type}"` : ''
        }`;
    }

    constructor(instanceData?: Variable) {
        super();
        this.fillByInstance(Variable, instanceData);
    }
}
