import { Directive, ElementRef } from '@angular/core';
import { switchMap, take } from 'rxjs/operators';

import { IfInViewportService } from '@dagility-ui/kit';
import { LOAD_EXTENSIONS_FN } from 'data-processor/lib/extensions';

import { DashboardWidgetSettingsManager } from '../../services/dashboard-widget-settings.manager';
import { GridsterReflowService } from './gridster-reflow.service';
import { of } from 'rxjs';

@Directive({
    selector: '[dpIlluminateExtensions]',
    providers: [
        {
            provide: LOAD_EXTENSIONS_FN,
            useFactory: (
                gridster: GridsterReflowService,
                ifInViewport: IfInViewportService,
                { nativeElement }: ElementRef,
                api: DashboardWidgetSettingsManager
            ) => {
                return (id: number) =>
                    id
                        ? gridster.gridsterReady$.pipe(
                              take(1),
                              switchMap(() => ifInViewport.isInView(nativeElement)),
                              take(1),
                              switchMap(() => api.getExtensions(id))
                          )
                        : of([]);
            },
            deps: [GridsterReflowService, IfInViewportService, ElementRef, DashboardWidgetSettingsManager],
        },
    ],
})
export class IlluminateExtensionsDirective {}
