<div class="modal-header p-25px">
    <h6 class="modal-title">{{ title }}</h6>
    <div class="pull-right">
        <fa-icon class="fa zoom" icon="{{ isExpanded ? 'facCompress' : 'facExpand' }}" (click)="expand()"></fa-icon>
        <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>
<div class="modal-body p-0" [style.width.px]="bodyWidth" [style.maxHeight.px]="bodyHeight">
    <app-generated-script [jenkinsFile$]="jenkinsFile$"
                          [height]="jenkinsFileHeight"
                          [codeMirror]="codeMirror"
                          [refresh$]="refresh$"
                          [downloadFileName]="jenkinsFilename + '-script'">
    </app-generated-script>
</div>
