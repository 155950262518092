<div *ngIf="form" [formGroup]="form" class="d-flex flex-column">
    <div class="filter-controls row mb-3 align-items-start">
        <lib-dropdown
            class="col"
            label="Filter type"
            formControlName="type"
            [items]="types"
            (change)="handleFilterTypeChange($event)"
        ></lib-dropdown>
        <lib-input class="col" label="Placeholder" formControlName="placeholder"></lib-input>
        <lib-input
            *ngIf="!!form.value.type && ![type.RANGE, type.HIDDEN].includes(form.value.type)"
            class="col"
            label="Label"
            formControlName="label"
        ></lib-input>

        <checkbox *ngIf="form.value.type !== type.HIDDEN && globalFiltersMode" class="col main-filter-checkbox" formControlName="mainFilter"
            >Filter is required as main</checkbox
        >

        <checkbox *ngIf="form.value.type !== type.HIDDEN" class="col main-filter-checkbox" formControlName="isUiFilter">UI Filter</checkbox>
    </div>

    <lib-input *ngIf="form.value.type === type.CHECKBOX" label="Description" formControlName="description"></lib-input>

    <lib-textarea
        *ngIf="isUiFilterControl.value"
        class="filter-description"
        label="Tooltip"
        formControlName="tooltip"
        resizeMode="none"
        [resize]="false"
    ></lib-textarea>

    <checkbox *ngIf="form.value.type === type.NEW_RANGE" formControlName="showDaysCount" class="mb-3">Show Selected Days Count</checkbox>

    <lib-input
        type="number"
        *ngIf="form.value.type | mapper: isDynamicWidthFilter"
        class="mb-3"
        label="Width"
        formControlName="width"
    ></lib-input>

    <lib-input *ngIf="globalFiltersMode" type="number" class="mb-3" label="Min Width" formControlName="minWidth"></lib-input>

    <lib-dropdown
        *ngIf="[type.DROPDOWN, type.HIDDEN].includes(form.value.type)"
        class="mb-3"
        label="Dependent on"
        formControlName="dependencies"
        [multiple]="true"
        [items]="placeholders"
        (change)="handleFilterDependentChange(form, $event)"
    ></lib-dropdown>
    <div *ngIf="form.value.type === type.HIDDEN">
        <dp-widget-builder-query
            *ngIf="!filtersFormConfig?.queryReplacement && form.value.type === type.HIDDEN; else filtersFormConfig?.queryReplacement"
            [filterQuery]="true"
            class="mb-3 query-options"
        ></dp-widget-builder-query>
    </div>

    <ng-container *ngIf="form.value.type === type.DROPDOWN || form.value.type === type.RADIO_GROUP">
        <div class="d-flex">
            <checkbox class="mb-3" formControlName="dynamic" (change)="handleDynamicChange($event, form)">Dynamic</checkbox>
            <checkbox *ngIf="form.value.type === type.DROPDOWN" class="ml-3 mb-3" formControlName="multiple" (change)="changeForm()"
                >Multiple</checkbox
            >
            <checkbox *ngIf="form.value.type === type.DROPDOWN" class="ml-3 mb-3" formControlName="clearable">Clearable</checkbox>
        </div>
        <ng-container *ngIf="!!form.value.dynamic">
            <dp-widget-builder-query
                *ngIf="!filtersFormConfig?.queryReplacement; else filtersFormConfig?.queryReplacement"
                [filterQuery]="true"
                class="mb-3 query-options"
            ></dp-widget-builder-query>
        </ng-container>

        <div *ngIf="!form.value.dynamic">
            <div class="btn-link cursor-pointer add-item-button" (click)="addFilterItem()">+ Add item</div>
            <div class="p-2 d-flex flex-column" *ngFor="let item of dropdownItems; let j = index" formArrayName="items">
                <div class="item-controls row" [formGroupName]="j">
                    <lib-input class="col" formControlName="label" [label]="j === 0 ? 'Label' : null" (change)="changeForm()"></lib-input>
                    <lib-input class="col" formControlName="value" [label]="j === 0 ? 'Value' : null" (change)="changeForm()"></lib-input>

                    <fa-icon
                        icon="facTrash"
                        class="delete-item-icon"
                        [style.paddingTop]="j === 0 ? '20px' : '0px'"
                        aria-hidden="true"
                        (click)="removeOption(j)"
                        [ngbTooltip]="'Delete item'"
                        tooltipClass="project-tooltip"
                        placement="top"
                        [openDelay]="800"
                        container="body"
                    ></fa-icon>
                </div>
            </div>
        </div>
    </ng-container>

    <div *ngIf="form.value.type === type.INPUT" class="row my-3">
        <lib-input class="col" label="Button Text" formControlName="buttonText"></lib-input>
    </div>

    <div *ngIf="![type.CHECKBOX, type.HIDDEN, type.COMPLEX_NAMED_DROPDOWN, type.RADIO_GROUP].includes(form.value.type)" class="row my-3">
        <lib-input class="col" label="Filter Placeholder Text" formControlName="placeholderText"></lib-input>
        <lib-dropdown class="col" label="Position" formControlName="position" [clearable]="false" [items]="positionItems"></lib-dropdown>
        <checkbox *ngIf="form.value.type !== type.RANGE" class="col mt-auto" formControlName="hideLabel">Hide Label</checkbox>
    </div>

    <div class="row my-3" [hidden]="form.value.type === type.HIDDEN">
        <lib-dropdown class="col" label="Value dependent on" formControlName="valueDependency" [items]="placeholders"></lib-dropdown>
        <lib-dropdown
            class="col"
            label="Values"
            formControlName="dependentValue"
            [addTag]="true"
            [labelRemovable]="true"
            [items]="dependentValueItems"
            [addTagFunction]="addTagFn"
            [multiple]="true"
            (valueChange)="handleFilterValueDependentChange($event)"
        ></lib-dropdown>
        <checkbox *ngIf="form.value.type === type.DROPDOWN" class="col mt-auto" formControlName="onlyHideDependentOn">Only hide</checkbox>
    </div>

    <div
        *ngIf="
            !!form.value.dynamic || !filtersFormConfig?.defaultValueReplacementMap?.get(form.value.type);
            else filtersFormConfig?.defaultValueReplacementMap?.get(form.value.type)
        "
    >
        <lib-codemirror-editor
            *ngIf="form.value.type !== type.HIDDEN"
            class="mb-3"
            label="Default value"
            [needRefresh]="true"
            mode="text/javascript"
            formControlName="defaultValue"
        ></lib-codemirror-editor>
    </div>
</div>
