<div class="p-4 w-100">
    <div class="parameter-container">
        <span class="parameter-label">Conditions</span>
        <div class="parameter-value parameter-conditions">
            <ng-container *ngIf="!alert.eventConditions">
                <div *ngFor="let part of alert.conditionParts">
                    <div class="parameter-condition-box" [ngClass]="part.op ? 'op' : ''" *ngIf="part.display">
                        {{ part.value }}
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="alert.eventConditions">
                <ng-container *ngFor="let part of alert.eventConditionsPreview">
                    <div class="parameter-condition-box" [ngClass]="part === 'AND' || part === 'OR' ? 'op' : ''">
                        {{ part }}
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <div class="parameter-container ">
        <span class="parameter-label">Recipients</span>
        <div class="d-flex flex-column">
            <div class="parameter-value">
                <span class="recipientTitle">Users: </span>
                <span>{{ getUsers(alert.recipients.users) }}</span>
                <span
                    *ngIf="alert.recipients.users && alert.recipients.users.ids.length > 6"
                    class="color-brand font-10px cursor-pointer"
                    (click)="showAll = !showAll"
                >
                    {{ showAll ? 'Show less' : 'Show more' }}</span
                >
            </div>
            <div class="parameter-value">
                <span class="recipientTitle">Teams: </span>
                <span>{{ getTeams(alert.recipients.teams) }}</span>
            </div>
        </div>
    </div>
    <div class="parameter-container">
        <span class="parameter-label">Importance</span>
        <div class="parameter-value d-inline-flex align-items-center">
            <fa-icon class="mr-5px font-16px high-importance-icon" *ngIf="importance === 'High'"
                     icon="facfilledExclamatory"></fa-icon>
            {{ importance }}
        </div>
    </div>
    <div *ngIf="frequency" class="parameter-container">
        <span class="parameter-label">Frequency</span>
        <div class="parameter-value">{{ frequency }}</div>
    </div>
    <div class="parameter-container">
        <span class="parameter-label">Alert text</span>
        <div class="parameter-value" [innerHTML]="alert.text"></div>
    </div>
</div>
