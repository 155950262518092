import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'textHighlight',
})
export class TextHighlightPipe implements PipeTransform {
    transform(wholeText: string, searchQuery: string): string {
        if (!searchQuery) {
            return wholeText;
        }
        const re = new RegExp(searchQuery, 'gi');
        return wholeText.replace(re, '<mark class="text-highlight">$&</mark>');
    }
}
