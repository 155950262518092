import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

export interface PathStackItem {
    name: string;
    path: string;
}

@Component({
    selector: 'app-files-grid-path',
    templateUrl: './files-grid-path.component.html'
})
export class FilesGridPathComponent implements OnChanges {
    @Input() currentPath: string;
    @Output() selectPath = new EventEmitter();

    pathStack: PathStackItem[];

    ngOnChanges(changes: SimpleChanges): void {
        const pathChanges = changes['currentPath'];
        if (pathChanges) {
            this.init();
        }
    }

    init() {
        this.pathStack = [];
        const splitPath = this.currentPath.split('/');

        let acc = '';
        splitPath.forEach((currentValue, index) => {
            acc = acc.concat(currentValue);
            const pathItem = {
                name: index === 0 ? 'root' : currentValue,
                path: acc,
            };
            this.pathStack.push(pathItem);
            acc = acc.concat('/');
        });
    }

    handleBackClick() {
        const pathLength = this.pathStack.length;
        if (this.pathStack.length > 1) {
            this.navigateToPath(this.pathStack[pathLength - 2].path, false);
        }
    }

    navigateToPath(path: string, lastItem: boolean) {
        if (!lastItem) {
            this.selectPath.emit(path);
        }
    }
}
