<dp-edit-job-definition-breadcrumb [path]="store.path$ | async"></dp-edit-job-definition-breadcrumb>

<perfect-scrollbar class="flex-1 mt-3" [formGroup]="formSlice">
    <lib-dropdown class="item-container pb-3 d-block" label="Type" [items]="types" valueKey="id" labelKey="name" formControlName="type">
    </lib-dropdown>

    <ng-container [ngSwitch]="type">
        <div *ngSwitchCase="operandType.VALUE" class="items-container" [formGroup]="formSlice">
            <dp-edit-job-definition-iterative-template
                *ngSwitchCase="operandType.VALUE"
                [definition]="definition.value"
                [formSlice]="formSlice.get('value')"
                [meta]="definition.meta"
                key="value"
            ></dp-edit-job-definition-iterative-template>
        </div>

        <div *ngSwitchCase="operandType.CONDITION" class="no-gutters template-container p-4 w-100">
            <div class="col-2 d-inline-flex justify-content-start align-items-center pb-20px">
                <div class="header-text">Condition</div>
            </div>
            <div class="row no-gutters">
                <div class="col info-container text-truncate">
                    {{ definition.condition?.getInfo() }}
                </div>
                <a
                    (click)="goToStep('condition')"
                    class="d-flex align-items-center font-weight-bold cursor-pointer ml-auto mr-4 proceed-text"
                >
                    Proceed
                </a>
            </div>
        </div>
    </ng-container>
</perfect-scrollbar>
