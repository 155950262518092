import { Component, HostListener, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpEventType } from '@angular/common/http';
import { FilesAttacherService } from './files-attacher.service';

@Component({
    selector: 'app-files-attacher',
    templateUrl: './files-attacher.component.html',
    styleUrls: ['./files-attacher.component.scss'],
})
export class FilesAttacherComponent {
    @Input() path: string = '';

    constructor(private toastr: ToastrService, private fileAttacherService: FilesAttacherService) {}

    @Input() files: FileDataInternal[] = [];
    attachedMenuOpened: boolean = true;
    filesUploadingProgress: number = null;
    private fileUploadSettings: FileUploadSettings = {
        maxFileSize: 94371840,
        fileTypes: ['image/png', 'image/jpeg', 'application/pdf', 'image/svg+xml'],
        multipleUpload: true,
    };
    filesDragging: boolean = false;

    private static byteToMegabyte(bytesCount: number, digitAfterPointCount: number = 2) {
        return (bytesCount / 1024 / 1024).toFixed(digitAfterPointCount);
    }

    @HostListener('dragover', ['$event']) onDragOverFiles(event: any) {
        event.preventDefault();
        event.stopPropagation();
        this.filesDragging = true;
    }

    @HostListener('dragleave', ['$event']) onDragLeaveFiles(event: any) {
        event.preventDefault();
        event.stopPropagation();
        this.filesDragging = false;
    }

    @HostListener('drop', ['$event']) onDropFiles(event: any) {
        event.preventDefault();
        event.stopPropagation();
        const droppedFiles = event.dataTransfer.files;
        this.filesDragging = false;
        if (this.analyzeAndApplyToUploadFiles(droppedFiles)) {
            this.uploadFiles();
        }
    }

    selectFiles(): void {
        const input = document.createElement('input');

        const mimeAllowedTypes = this.fileUploadSettings.fileTypes.join(', ');
        if (mimeAllowedTypes) {
            input.accept = mimeAllowedTypes;
        }
        input.type = 'file';
        input.multiple = this.fileUploadSettings.multipleUpload;
        input.onchange = () => {
            if (this.analyzeAndApplyToUploadFiles(input.files)) {
                this.uploadFiles();
            }
        };
        input.click();
    }

    getUnloadedFiles() {
        return this.files.filter(file => file.id === null).length;
    }

    getMaxFileSize() {
        return FilesAttacherComponent.byteToMegabyte(this.fileUploadSettings.maxFileSize);
    }

    getSumFileSize() {
        if (!this.files || this.files.length === 0) {
            return;
        } else {
            let size = 0;
            this.files.forEach(file => {
                size += file.file ? file.file.size : file.size;
            });
            return FilesAttacherComponent.byteToMegabyte(size);
        }
    }

    getFileSize(file: FileDataInternal) {
        return FilesAttacherComponent.byteToMegabyte(file.file ? file.file.size : file.size);
    }

    getAllowedFileTypes() {
        let allowedTypes: string;
        if (!this.fileUploadSettings.fileTypes || this.fileUploadSettings.fileTypes.length === 0) {
            allowedTypes = 'any';
            return allowedTypes;
        }
        allowedTypes = this.fileUploadSettings.fileTypes.map(fileType => fileType.toUpperCase().split('/')[1]).join(', ');
        allowedTypes = allowedTypes.replace(/(\+[A-Za-z]+)/, '');
        return allowedTypes;
    }

    deleteFile(file: FileDataInternal) {
        const index = this.files.indexOf(file);
        this.files.splice(index, 1);
    }

    private analyzeAndApplyToUploadFiles(files: FileList) {
        let changeDetected = false;
        for (let i = 0; i < files.length; i++) {
            if (files.item(i).size && files.item(i).size > this.fileUploadSettings.maxFileSize) {
                this.toastr.error(`File ${files.item(i).name} was skipped, because it size is larger than allowed`);
            }
            if (this.fileUploadSettings.fileTypes.indexOf(files.item(i).type) === -1) {
                this.toastr.error(
                    `File ${files.item(i).name} has an incorrect extension. Allowed extensions for uploading: ` + this.getAllowedFileTypes()
                );
            } else {
                this.files.push({
                    id: null,
                    file: files.item(i),
                });
                changeDetected = true;
            }
        }
        return changeDetected;
    }

    private uploadFiles() {
        const filesUpload: File[] = [];
        const filteredFiles = this.files.filter(file => file.id === null);
        filteredFiles.forEach(filesInternalData => filesUpload.push(filesInternalData.file));
        this.fileAttacherService.uploadAttachmentFiles(filesUpload, this.path).subscribe(response => {
            switch (response.type) {
                case HttpEventType.Sent:
                    {
                        this.filesUploadingProgress = 0;
                    }
                    break;
                case HttpEventType.UploadProgress:
                    {
                        this.filesUploadingProgress = Math.round((response.loaded / response.total) * 100);
                    }
                    break;
                case HttpEventType.Response:
                    {
                        this.filesUploadingProgress = null;
                        const resp = response.body;
                        for (let i = 0; i < filteredFiles.length; i++) {
                            filteredFiles[i].id = resp[i].id;
                            filteredFiles[i].name = resp[i].name;
                            filteredFiles[i].size = resp[i].size;
                            filteredFiles[i].contentType = resp[i].contentType;
                        }
                    }
                    break;
            }
        });
    }
}

export interface FileUploadSettings {
    maxFileSize: number;
    fileTypes?: string[];
    multipleUpload?: boolean;
}

export interface FileDataInternal {
    id: string;
    contentType?: string;
    name?: string;
    size?: number;
    file?: File;
}
