import { DropDirection, SVGBlock, SVGEdge } from '@dagility-ui/shared-components';
import { WidgetEventHandler, WidgetQuery, WidgetType } from './any-widget.model';
import { WidgetAction, WidgetFilterNode, WidgetLayer } from './widget.graph';
import {DisplaySettingsConfig} from "data-processor/lib/widget-library/widget-builder/models/display-settings.config";

export class LayerBlock extends SVGBlock {
    id = '';
    end = {
        x: 0,
        y: 0,
    };
    width = 169;
    viewType = 'layer';
    collection = 'layers';
    draggable = true;
    type = 'layer';
    children: LayerBlock[];
    relX = 0;
    prelim = 0;
    shift = 0;
    change = 0;
    lExt = this;
    lExtRelX = 0;
    lThr: any = null;
    rExt = this;
    rExtRelX = 0;
    rThr: any = null;
    parent: LayerBlock;
    innerElements: SVGBlock[] = [];
    minX: number = 0;
    maxX: number = 0;
    actions: WidgetAction[] = [];
    handlers: Array<WidgetEventHandler & { viewType: string }> = [];
    messageBus = false;
    asyncQueries: QueryBlock[] = [];
    displaySettingsConfig: DisplaySettingsConfig = new DisplaySettingsConfig();

    get treeWidth() {
        return this.maxX - this.minX;
    }

    get bottom() {
        return this.y + this.height;
    }
    get numChildren() {
        return this.hasChildren ? this.children.length : 0;
    }
    get hasChildren() {
        return !this.noChildren;
    }
    get noChildren() {
        return this.children === null || !this.children.length;
    }
    get firstChild(): LayerBlock | null {
        return this.hasChildren ? this.children[0] : null;
    }
    get lastChild(): LayerBlock | null {
        return this.hasChildren ? this.children[this.numChildren - 1] : null;
    }

    get isComplex() {
        return this.data.type === WidgetType.COMPLEX;
    }

    get name() {
        return this.data.chartOptions.title || '';
    }

    constructor(public data: WidgetLayer) {
        super();

        this.id = data.id;
    }
}

export class QueriesBlock extends SVGBlock {
    id = 1;
    viewType = 'queries' as any;
    layerId: string;

    constructor(public layer: WidgetLayer) {
        super();
    }
}

export class AsyncQueries extends QueriesBlock {
    viewType = 'async-queries' as any;

    constructor(layer: WidgetLayer) {
        super(layer);
    }
}

export class ComplexCellBlock extends SVGBlock {
    id = null as any;
    viewType = 'cell';
    directions = ['inside'] as DropDirection[];
    zone = 'drop' as any;
    layerId: string;
    index = 0;
    root: LayerBlock;
}

export class FiltersBlock extends SVGBlock {
    id = 1;
    viewType = 'filters';
    layerId: string;

    constructor(public layer: WidgetLayer) {
        super();
    }
}

export class FilterBlock extends SVGBlock {
    id = '';
    viewType = 'filter';
    name = '';
    type = 'filter';
    draggable = true;
    collection = 'filters';
    directions = ['top', 'bottom'] as DropDirection[];
    layerId: string;

    constructor(public data: WidgetFilterNode) {
        super();
        this.id = this.data.id;
        this.name = this.data.placeholder;
        this.type = this.data.type;
    }
}

export class QueryBlock extends SVGBlock {
    get name() {
        return this.data.placeholder;
    }

    id = 'result';
    draggable = true;
    debuggable = true;
    type = 'query';
    viewType = 'query' as any;
    directions = ['top', 'bottom'] as DropDirection[];
    collection = 'queries';
    nameField = 'placeholder';
    layerId: string;
    debuggingScripts: number[] = [];
    placeholder: string;
    flow: number;

    constructor(public data: WidgetQuery, public filterQuery = false) {
        super();
        this.id = data.id;
        if (filterQuery) {
            this.directions = [];
        }
    }
}

export class AsyncQueryBlock extends QueryBlock {
    draggable = false;
    debuggable = false;

    constructor(public data: WidgetQuery, public filterQuery = false) {
        super(data, filterQuery);
        this.id = data.placeholder;
    }
}

export class DrilldownAction implements SVGEdge {
    path = '';
    needArrow = true;
    id: string;

    constructor(public from: LayerBlock, public to: LayerBlock) {}

    draw() {
        const startX = this.from.x + this.from.width / 2;
        const endX = this.to.x + this.to.width / 2;
        if (endX === startX) {
            this.path = `M ${startX} ${this.from.y + this.from.height} V ${this.to.y}`;
            return;
        }

        const deltaY = this.to.y - (this.from.y + this.from.height);

        this.path = `M ${startX} ${this.from.y + this.from.height} L ${startX} ${this.to.y - deltaY / 2} L ${endX} ${this.to.y -
            deltaY / 2} L ${endX} ${this.to.y}`;
    }
}
