<div class="wrapper h-100">
    <div *ngIf="!isChildComponent" class="d-flex align-items-baseline">
        <lib-search *ngIf="showWithParent" class="d-block size-s" [width]="200" (searchText)="searchItem($event)"></lib-search>
        <checkbox
            *ngIf="withParentNavs"
            class="d-block width-fit-content ml-auto pr-2"
            [(ngModel)]="showWithParent"
            [disabled]="isLoading"
            (change)="searchItem('')"
            >{{ checkboxLabel || 'Show Parent Accordion' }}</checkbox
        >
    </div>
    <ng-container *ngIf="!showWithParent; else withParentTmpl">
        <ng-container [ngTemplateOutlet]="navContent" [ngTemplateOutletContext]="{ $implicit: withoutParentNavs }"></ng-container>
    </ng-container>

    <ng-template #withParentTmpl>
        <perfect-scrollbar stopScrollPropagation class="pb-4 pr-2 parent-scrollbar" (psYReachEnd)="handleParentScrollEnd()">
            <ngb-accordion firstLevel class="custom-accordion mt-2" [closeOthers]="true">
                <ngb-panel *ngFor="let parentAccordion of withParentNavs">
                    <ng-template ngbPanelHeader>
                        <div class="d-inline-flex align-items-center w-100">
                            <button [ngbPanelToggle] class="header-button btn btn-link col col-first"></button>
                            <div class="parent-accordion-container pl-2 font-11px">
                                <ng-container *ngIf="parentAccordion.parentAccordionValue.link !== ''; else noParentAccordionLink">
                                    <a href="{{ parentAccordion.parentAccordionValue.link }}"
                                        ><span class="link-normal">{{ parentAccordion.parentAccordionValue.id }}</span></a
                                    >
                                </ng-container>
                                <ng-template #noParentAccordionLink>
                                    <span class="link-normal">{{ parentAccordion.parentAccordionValue.id }}</span>
                                </ng-template>

                                <span class="primary-text font-weight-600">{{ parentAccordion.parentAccordionValue.title }}</span>
                                <span class="secondary-text-and-icons font-10px d-flex"
                                    >{{ parentAccordion.parentAccordionValue.date | date: 'dateCustom' }}
                                    <span class="ml-auto">|</span></span
                                >
                                <span class="font-10px"
                                    ><span class="secondary-text-and-icons">Assigned : </span
                                    >{{ parentAccordion.parentAccordionValue.author }}</span
                                >
                            </div>
                            <div
                                *ngIf="parentAccordion.parentAccordionValue.status as status"
                                class="ml-auto mr-5 status"
                                [style.backgroundColor]="statusMapper[status]"
                            >
                                {{ status }}
                            </div>
                        </div>
                    </ng-template>

                    <ng-template ngbPanelContent>
                        <ng-container
                            [ngTemplateOutlet]="navContent"
                            [ngTemplateOutletContext]="{ $implicit: parentAccordion.navItems }"
                        ></ng-container>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </perfect-scrollbar>
    </ng-template>

    <ng-template #navContent let-navItems>
        <nav ngbNav #nav="ngbNav" class="nav nav-tabs justify-content-start" [class.nav-disable]="isLoading"
             [class.d-none]="navItems.length === 1"
             (navChange)="setTabSearch()">
            <ng-container *ngFor="let nav of navItems; index as navIndex" [ngbNavItem]="nav.tabName" [disabled]="isLoading">
                <a ngbNavLink>{{ nav.tabName }}</a>
                <ng-template ngbNavContent>
                    <ng-container
                        *ngIf="{
                            showSearch: !showWithParent && (!isChildComponent || nav.withSearch),
                            showDropdown: nav.sortField && nav.sortItems
                        } as vm"
                    >
                        <div *ngIf="vm.showSearch || vm.showDropdown" class="d-flex justify-content-between mb-2">
                            <lib-search
                                *ngIf="vm.showSearch"
                                class="d-block size-s mb-2"
                                [width]="200"
                                [value]="cachedSearchStr"
                                [searchTermLength]="-1"
                                (searchText)="nav.clientSearch && !hasInfiniteScroll ? clientSideSearchItem($event) : searchItem($event)"
                            ></lib-search>

                            <lib-dropdown
                                *ngIf="vm.showDropdown"
                                class="size-s mr-3"
                                labelPosition="left"
                                [label]="nav.sortLabel || 'Sorting'"
                                [items]="nav.sortItems"
                                [value]="'ASC'"
                                [clearable]="false"
                                (valueChange)="
                                    !hasInfiniteScroll
                                        ? handleClientSortDropdown(navIndex, $event, nav.sortField)
                                        : sort($event, nav.sortField)
                                "
                            >
                            </lib-dropdown>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="nav.items?.length; else noDataTmpl">
                        <div *ngIf="!nav.hideHeaders" class="headers pr-3 mb-2">
                            <span *ngFor="let accordionColumn of nav.accordionColumns; let first = first; let i = index" class="col"
                                  style="flex-grow: unset; flex-basis: unset"
                                  [style.width.%]="widths[i]">
                                 <span *ngIf="first" class="d-inline-block col col-first"></span>
                                {{ accordionColumn.title }}
                            </span>
                        </div>
                        <perfect-scrollbar
                            [class]="!showWithParent && (!isChildComponent || nav.withSearch) ? 'scrollbar-with-parent' : 'h-100 pr-3'"
                            (psYReachEnd)="handleNavScrollEnd(nav.tabValue)"
                        >
                            <ngb-accordion
                                firstLevel
                                class="custom-accordion"
                                [closeOthers]="true"
                                [activeIds]="
                                    openFirstRow && nav.items[0]?.table.tableData?.rowData.length && nav.items.length === 1
                                        ? 'accordion-panel-0'
                                        : ''
                                "
                                (panelChange)="onPanelChange($event)"
                            >
                                <ngb-panel
                                    *ngFor="let accordionRow of nav.items; index as index"
                                    id="accordion-panel-{{ index }}"
                                    cardClass="{{ accordionRow.value['class-disabled'] ? 'bg-blackout' : '' }} {{
                                        !accordionRow.table.tableData?.rowData.length ? 'card-without-content' : ''
                                    }}"
                                >
                                    <ng-container *ngIf="accordionRow.value">
                                        <ng-template ngbPanelHeader>
                                            <div *ngIf="!customTemplate; else customTmpl" class="d-inline-flex align-items-center w-100">
                                                <span *ngFor="let accordionColumn of nav.accordionColumns; let first = first; let i = index" class="col text-truncate"

                                                      style="flex-grow: unset; flex-basis: unset" [style.width.%]="widths[i]">
                                                     <button
                                                         *ngIf="first"
                                                         [ngbPanelToggle]
                                                         class="header-button d-inline-flex btn btn-link col col-first"
                                                     ></button>
                                                    <ng-container *ngIf="accordionColumn.clickEventFunction; else noClickEventTmpl">
                                                        <span
                                                            class="link-normal cursor-pointer"
                                                            [ngbTooltip]="
                                                                accordionRow.value[accordionColumn.field]?.tooltip
                                                                    ? accordionRow.value[accordionColumn.field].tooltip
                                                                    : accordionRow.value[accordionColumn.field]
                                                            "
                                                            container="body"
                                                            (click)="
                                                                onAccordionCellClick(accordionColumn.clickEventFunction, accordionRow.value)
                                                            "
                                                            >{{ getAccordionRowValue(accordionRow.value[accordionColumn.field]) }}</span
                                                        >
                                                    </ng-container>

                                                    <ng-template #noClickEventTmpl>
                                                        <span
                                                            [ngbTooltip]="
                                                                accordionRow.value[accordionColumn.field]?.tooltip
                                                                    ? accordionRow.value[accordionColumn.field].tooltip
                                                                    : accordionRow.value[accordionColumn.field]
                                                            "
                                                            container="body"
                                                            >{{ getAccordionRowValue(accordionRow.value[accordionColumn.field]) }}</span
                                                        >
                                                    </ng-template>

                                                    <span
                                                        *ngIf="accordionColumn.field === 'issueId' && !accordionRow.value.storyPoint"
                                                        class="ml-2"
                                                        [innerHTML]="icons.warning | safeHtml: 'html'"
                                                    >
                                                    </span>
                                                </span>
                                            </div>

                                            <ng-template #customTmpl>
                                                <div style="min-height: inherit" class="d-flex">
                                                    <button [ngbPanelToggle] class="header-button btn btn-link col col-first"></button>
                                                    <div
                                                        style="min-height: inherit"
                                                        class="w-100"
                                                        [innerHTML]="
                                                            customTemplate
                                                                | dpWidgetTemplate
                                                                    : placeholders
                                                                    : {
                                                                          navIndex: navIndex,
                                                                          index: updatedIndicesForCustomTemplate.get(navIndex + '-' + index)
                                                                      }
                                                        "
                                                    ></div>
                                                </div>
                                            </ng-template>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <lib-ag-grid
                                                *ngIf="accordionRow.table.tableData?.rowData.length"
                                                style="flex: unset"
                                                class="d-block w-100"
                                                gridHeight="100%"
                                                (libResizeObserver)="updateColWidths()"
                                                [headerHeight]="0"
                                                [skipAutoSize]="true"
                                                [rowBuffer]="1000"
                                                [tableData]="accordionRow.table.tableData"
                                                [pinnedBottomRowData]="accordionRow.table.tableData.pinnedBottomRowData"
                                                [rowClassRules]="rowClassRules"
                                                (cellClicked)="cellClicked($event)"
                                            ></lib-ag-grid>
                                        </ng-template>
                                    </ng-container>
                                </ngb-panel>
                            </ngb-accordion>
                        </perfect-scrollbar>
                    </ng-container>

                    <ng-template #noDataTmpl>
                        <div class="d-flex h-100 justify-content-center align-items-center">
                            <span class="title3 disable">
                                {{ nav.noDataMessage ?? 'No Data Available' }}
                            </span>
                        </div>
                    </ng-template>
                </ng-template>
            </ng-container>
        </nav>
        <div class="w-100" [ngbNavOutlet]="nav"></div>
    </ng-template>

    <div *ngIf="isLoading" class="list-gradient-fade d-flex align-items-center justify-content-center">
        <div class="loader"></div>
    </div>
</div>
