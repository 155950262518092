export function createSREHexagonElement(
    id: string,
    diameter: number,
    value: number,
    label: string,
    bounds: { average: number; bad: number },
    invertColors = false
): HTMLElement {
    const element = createElement(id, diameter);
    const hexWrapper = createHexagonWrapperElement();
    const hexEl = createHexagonElement(value, bounds, invertColors);
    const valueEl = createValueElement(value, label);

    hexEl.appendChild(valueEl);
    hexWrapper.appendChild(hexEl);
    element.appendChild(hexWrapper);

    return element;
}


export function createSREHexagonElementWExtScoring(
    id: string,
    diameter: number,
    value: number,
    label: string,
    bounds: any[],
    index: number
) {
    const element = createElement(id, diameter);
    const hexWrapper = createHexagonWrapperElement();
    const valueEl = createValueElement(value, label);
    const hexEl = createHexagonElementWExtScoring(index, bounds);

    hexEl.appendChild(valueEl);
    hexWrapper.appendChild(hexEl);
    element.appendChild(hexWrapper);

    return element;
}

function createElement(id: string, diameter: number): HTMLElement {
    const element = document.createElement('div');

    element.id = id;
    element.style.width = diameter + 'px';
    element.style.height = diameter + 'px';
    element.classList.add('element');

    return element;
}

function createHexagonWrapperElement(): HTMLElement {
    const hexagonWrapper = document.createElement('div');
    hexagonWrapper.classList.add('hexagon-wrapper');
    return hexagonWrapper;
}

function createHexagonElementWExtScoring(value: number, bounds: any[]) {
    const bound = getBoundForValue(value, bounds);
    const hexagon = document.createElement('div');
    hexagon.classList.add('hexagon');
    hexagon.style.background = bound.color || '#fb4e4e'
    return hexagon;
}

function getBoundForValue(value: number, bounds: any[]) {
    return bounds.find((it) => value === it.id) || {};
}

function createHexagonElement(value: number, bounds: { average: number; bad: number }, invertColors = false): HTMLElement {
    const hexagon = document.createElement('div');
    if (invertColors) {
        hexagon.classList.add(
            'hexagon',
            value >= bounds.bad ? 'hexagon-good' : value >= bounds.average ? 'hexagon-average' : 'hexagon-bad'
        );
    } else {
        hexagon.classList.add(
            'hexagon',
            value >= bounds.bad ? 'hexagon-bad' : value >= bounds.average ? 'hexagon-average' : 'hexagon-good'
        );
    }
    return hexagon;
}

function createValueElement(value: number, label: string): HTMLElement {
    const valueEl = document.createElement('span');
    valueEl.classList.add('hexagon-value');
    valueEl.innerHTML = label;
    return valueEl;
}
