import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'lib-radial-chart',
    templateUrl: './radial-chart.component.html',
    styleUrls: ['./radial-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadialChartComponent {
    @Input() value: number;
    @Input() startAngle: number;
    @Input() endAngle: number;
    @Input() maximum = 100;

    readonly strokeWidth = 24;
    readonly size = 187;
    readonly circleGap = 7;
    readonly innerCircleStrokeWidth = 4;

    get center() {
        return this.size / 2;
    }

    get radius() {
        return this.center - this.strokeWidth / 2;
    }

    get circleLength() {
        return 2 * Math.PI * this.radius;
    }

    get arcLength() {
        return (Math.PI * this.radius * (this.endAngle - this.startAngle)) / 180;
    }

    get offset() {
        return this.arcLength * Math.min(this.value / this.maximum, 1);
    }

    get rotateAngle() {
        return (360 - (this.endAngle - this.startAngle)) / 2 + 90;
    }

    get innerCircleRadius() {
        return this.radius - this.strokeWidth / 2 - this.circleGap - this.innerCircleStrokeWidth / 2;
    }

    get progressPathArray() {
        const { offset, circleLength } = this;

        return `${offset} ${circleLength - offset}`;
    }
}
