<div
    *ngIf="!server && form"
    class="d-flex flex-wrap align-items-end justify-content-end filter-wrapper"
    style="row-gap: 1.5rem"
    [dpFilterSet]="filters"
    [defaultPlaceholders$]="defaultPlaceholders$"
    [formGroup]="form"
    [appliedFilters]="store.defaultFiltersValues"
    [applyDynamicFilters]="manager.STORAGE_FILTERS"
    [reset]="true"
    (loaded)="handleLoaded()"
    (filterValueChanged)="filterValueChanged.emit($event)"
>
    <dp-complex-named-dropdown-filter
        *ngIf="complexNamedFilter && complexNamedFilter.isInline"
        [dashboardId]="dashboardId"
        [filter]="complexNamedFilter"
        [filtersGroup]="form"
        (change)="handleComplexNamedDropdownChange()"
    ></dp-complex-named-dropdown-filter>
    <ng-container *ngFor="let filter of filters" [dpChartFilter]="filter" [widgetDebugger]="workflow.widgetDebugger"> </ng-container>
    <ng-template [ngTemplateOutlet]="buttonsTemplate"></ng-template>
</div>

<ng-container *ngIf="server && workflow?.widgetDebugger.filters$.value as filtersGroup" [formGroup]="filtersGroup">
    <dp-complex-named-dropdown-filter
        *ngIf="complexNamedFilter && complexNamedFilter.isInline"
        [dashboardId]="dashboardId"
        [filter]="complexNamedFilter"
        [filtersGroup]="filtersGroup"
        [placeholders$]="workflow.widgetDebugger.placeholders$"
        (change)="handleComplexNamedDropdownChange()"
    ></dp-complex-named-dropdown-filter>

    <ng-container *ngTemplateOutlet="filtersLoopTmpl; context: { $implicit: inlineFilters, popup: false, dummy: false }"></ng-container>

    <button
        *ngIf="popoverFilters?.length"
        style="height: 24px !important; font-size: 12px !important;"
        class="btn btn-primary py-0 px-2 popover-button"
        [autoClose]="false"
        popoverClass="filters-popover"
        placement="bottom-right auto"
        [ngbPopover]="popupFiltersTmpl"
        autoClosePopup
    >
        <fa-icon icon="facFilters"></fa-icon>
    </button>

    <ng-template #popupFiltersTmpl>
        <div class="px-3 py-4">
            <h3 class="font-weight-normal mb-4">Dashboard Filters</h3>
            <perfect-scrollbar class="filters-popover-container pr-2">
                <ng-container *ngTemplateOutlet="filtersLoopTmpl; context: { $implicit: popoverFilters, popup: true, dummy: false }">
                </ng-container>
                <dp-complex-named-dropdown-filter
                    *ngIf="complexNamedFilter && !complexNamedFilter.isInline"
                    [dashboardId]="dashboardId"
                    [filter]="complexNamedFilter"
                    [filtersGroup]="filtersGroup"
                    [placeholders$]="workflow.widgetDebugger.placeholders$"
                    (change)="handleComplexNamedDropdownChange()"
                ></dp-complex-named-dropdown-filter>
            </perfect-scrollbar>
        </div>

        <div class="modal-footer py-10px">
            <button class="btn btn-sm btn-secondary mr-3" (click)="resetFilters()">Reset</button>
            <button class="btn btn-primary" (click)="popover.close()">Close</button>
        </div>
    </ng-template>

    <ng-template *ngIf="!popoverFilters?.length" [ngTemplateOutlet]="buttonsTemplate"></ng-template>

    <div class="fake-filters-container" #fakeFiltersContainer>
        <dp-complex-named-dropdown-filter
            *ngIf="complexNamedFilter"
            [dashboardId]="dashboardId"
            [dummyFilter]="true"
            [filter]="complexNamedFilter"
            [filtersGroup]="filtersGroup"
            [placeholders$]="workflow.widgetDebugger.placeholders$"
            (change)="handleComplexNamedDropdownChange()"
        ></dp-complex-named-dropdown-filter>

        <ng-container *ngTemplateOutlet="filtersLoopTmpl; context: { $implicit: filters, popup: false, dummy: true }"></ng-container>
        <ng-container *ngTemplateOutlet="buttonsTemplate"></ng-container>
    </div>

    <ng-template #filtersLoopTmpl let-iterFilters let-popup="popup" let-dummy="dummy">
        <ng-container
            *ngFor="let filter of iterFilters"
            [dpChartFilter]="filter"
            [widgetDebugger]="workflow.widgetDebugger"
            [popupFilter]="popup"
            [dummyFilter]="dummy"
            [filtersReset]="filtersReset"
            (ngModelChange)="
                !dummy &&
                    filterValueChanged.emit({
                        key: filter.placeholder,
                        value: $event
                    })
            "
        >
        </ng-container>
    </ng-template>
</ng-container>

<ng-template #buttonsTemplate>
    <button
        *ngIf="showResetButton"
        style="height: 24px !important; font-size: 10px !important;"
        ngbTooltip="Reset Filters"
        type="button"
        class="btn btn-primary reset-button"
        (click)="resetFilters()"
    >
        <fa-icon icon="rotationLeft"></fa-icon>
    </button>
    <span *ngIf="contextHelpId" [context-help-id]="contextHelpId"></span>
</ng-template>
