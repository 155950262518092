import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { PortfolioScoreHealth } from '../models/portfolio-scores.model';

@Component({
    selector: 'dp-portfolio-score-square',
    templateUrl: './portfolio-score-square.component.html',
    styleUrls: ['./portfolio-score-square.component.scss'],
})
export class PortfolioScoreSquareComponent implements OnInit {
    @Input() data: PortfolioScoreHealth;

    get isHealth(): boolean {
        return !!this.data.isHealth;
    }

    get statusBarValue(): number {
        return this.data && typeof this.data.score === 'number' ? ((this.data.score / 5) * 12) : 0;
    }

    statusBars = [...new Array(12).keys()];

    isLoading = true;

    @HostBinding('class.health') get health() {
        return this.data && this.data.isHealth;
    }

    ngOnInit() {
        this.isLoading = false;
    }
}
