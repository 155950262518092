import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    EventEmitter,
    inject,
    Inject,
    Injector,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    TrackByFunction,
    ViewChildren,
} from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ModalService, toJSONBlob, writeContents } from '@dagility-ui/kit';
import { ToasterService } from '@dagility-ui/shared-components';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, concat, defer, forkJoin, from, noop, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, filter, finalize, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { cloneDeep, flatten } from 'lodash';

import {
    DATA_MORPH_AUTH,
    DATA_MORPH_FEATURE_TOGGLE,
    DataMorphAuthService,
    DataMorphFeatureToggleService,
    DP_DASHBOARD_TOKEN,
} from 'data-processor/tokens';

import { DashboardExternalFilterDirective } from 'data-processor/lib/widget-library/dashboard/directives/dashboard-external-filter.directive';
import { TreeLikeMenuGroup, TreelikeMenuItem } from '@dagility-ui/kit/modules/forms/controls/treelike-menu/treelike-menu.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DpDashboardFacade } from '../../state/dp-dashboard.facade';
import { DataMorph } from '../../models/dp-dashboard.model';
import { DashboardWidgetSettingsManager } from '../../services/dashboard-widget-settings.manager';
import { DpDashboardService } from '../../services/dp-dashboard.service';
import { DpDashboardStore } from '../../state/dp-dashboard.store';
import { DpEditGroupComponent } from '../dp-edit-group/dp-edit-group.component';
import { DpGlobalFiltersService } from '../../services/global-filters.service';
import { GridsterOptionsService } from '../../services/gridster/gridster-options.service';
import { DashboardMessageBus } from '../../../widget-builder/services/message-bus/message-bus';
import { DpDashboardTabComponent } from '../dp-dashboard-tab/dp-dashboard-tab.component';
import { ExportLoaderComponent } from './export-loader';
import { DP_DASHBOARD_DEFAULT_PROVIDER } from './dp-dashboard-default.provider';
import { DashboardTabState } from 'data-processor/lib/widget-library/dashboard/state/dp-dashboard.state.model';

type jsPDF = import('../../../widget-builder/services/exporter/jspdf.imports').jsPDF;

export function getExternalPlaceholders() {
    return history.state.placeholders;
}

function html2canvasImport() {
    return from(import('html2canvas').then(module => module.default));
}

const IGNORED_ELEMENTS = 'btn';

@Component({
    selector: 'dp-dashboard',
    templateUrl: './dp-dashboard.component.html',
    styleUrls: ['./dp-dashboard.component.scss'],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'dp-dashboard' },
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        DpDashboardStore,
        DpDashboardFacade,
        DpGlobalFiltersService,
        DashboardMessageBus,
        GridsterOptionsService,
        DP_DASHBOARD_DEFAULT_PROVIDER,
    ],
})
export class DpDashboardComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    @Input() readonly = false;
    @Input() importAllowed = true;
    @Input() widgetsPlaceholders = {};
    @Input() extensionsEnabled = false;
    @Input() editDashboard: boolean;
    @Input() exportToPdfAvailable = false;
    @Input() filterContextHelpId: string;
    @Input() portfolioName: string;
    @Input() startTab: string;
    @Input() previousMlfState: any;
    @Input() withBreadcrumb = true;

    @Output() save = new EventEmitter<DataMorph.Dashboard>();
    @Output('editMode') editMode$ = this.store.select(state => state?.edit && !!state?.tabs?.length).pipe(shareReplay(1));
    @Output() event = new EventEmitter<any>();

    @ContentChild(DashboardExternalFilterDirective) externalFilter: DashboardExternalFilterDirective;

    @ViewChildren(DpDashboardTabComponent) dpDashboardTabs: QueryList<DpDashboardTabComponent>;

    vm$ = this.store.asObservable();
    dynamicDashboard: boolean = false;
    editTabId: number;

    breadcrumbHeader$ = of(true);
    data$ = this.dpDashboard$.pipe(
        tap(() => this.messageBus.reset()),
        switchMap(dashboard =>
            concat(
                of({ type: 'start' }),
                defer(() => {
                    this.dynamicDashboard = !!dashboard.uuid;

                    return forkJoin([
                        this.dashboardWidgetManager.getLastAppliedFilters(dashboard.id ?? dashboard.dashboardId).pipe(
                            catchError(() => of({})),
                            map(filters => Object.assign(filters ?? {}, getExternalPlaceholders() ?? {})),
                            tap(filters => (this.filterService.defaultFiltersValues = filters))
                        ),
                        this.dashboardWidgetManager.getWidgets(dashboard.dashboardId?.toString() || dashboard.uuid),
                    ]).pipe(
                        map(([, widgets]) => ({
                            dashboard,
                            widgets,
                        })),
                        tap(dashboardWithWidgets => this.store.init(dashboardWithWidgets, this.editDashboard, this.startTab)),
                        catchError(e => {
                            console.error(e);

                            return throwError(e);
                        })
                    );
                })
            )
        )
    );

    editActions$: Observable<{ label: string; icon: string; action: string }[]> = this.store
        .select(state => state.activeTab)
        .pipe(
            map(activeTab =>
                (this.featureToggle.isActiveSync('eo_48475')
                    ? []
                    : [
                          {
                              label: 'Edit Dashboard',
                              icon: 'facEdit',
                              action: 'editDashboard',
                          },
                      ]
                ).concat([
                    {
                        label: 'Add Tab',
                        icon: 'plus',
                        action: 'add',
                    },
                    ...(activeTab
                        ? [
                              {
                                  label: 'Edit Tab',
                                  icon: 'facEdit',
                                  action: 'edit',
                              },
                              {
                                  label: 'Delete Tab',
                                  icon: 'facTrash',
                                  action: 'delete',
                              },
                          ]
                        : []),
                ])
            )
        );

    dashboardActions$: Observable<TreeLikeMenuGroup[]> = getDashboardActions(this);
    tabActions$: Observable<TreeLikeMenuGroup[]> = getTabActions(this);
    dashboardActions = [{ label: 'Export to JSON', action: 'json' }];

    tabVisibility = DataMorph.DashboardTabVisibility;
    public pdf: jsPDF;
    private destroyed$ = new Subject<void>();

    constructor(
        public store: DpDashboardStore,
        private route: ActivatedRoute,
        private api: DpDashboardService,
        private modal: ModalService,
        private toaster: ToasterService,
        private router: Router,
        private filterService: DpGlobalFiltersService,
        private messageBus: DashboardMessageBus,
        public dashboardWidgetManager: DashboardWidgetSettingsManager,
        public zone: NgZone,
        @Inject(DATA_MORPH_AUTH) private authService: DataMorphAuthService,
        @Inject(DATA_MORPH_FEATURE_TOGGLE) private featureToggle: DataMorphFeatureToggleService,
        @Inject(DP_DASHBOARD_TOKEN) private dpDashboard$: Observable<DataMorph.Dashboard>,
        private facade: DpDashboardFacade,
        private injector: Injector,
        private cdref: ChangeDetectorRef
    ) {}

    get isAdmin() {
        return this.authService.isAdmin();
    }

    changeDashboard(commands: unknown[], state: NavigationExtras['state']) {
        this.modal.dismissAll();

        this.router.navigate(commands, {
            relativeTo: this.route,
            state,
        });
    }

    ngOnInit() {
        this.store.optionsChanged$
            .pipe(
                filter(() => !this.store.value.template || this.authService.isAdmin()),
                debounceTime(1000),
                filter(() => !this.store.value?.dynamic),
                switchMap(() =>
                    this.dashboardWidgetManager.saveDashboard({
                        options: this.store.value.options,
                        id: this.store.value.id,
                        dashboardId: this.store.value.dashboardId,
                    })
                ),
                takeUntil(this.destroyed$)
            )
            .subscribe();

        this.featureToggle.isActive('new_export').subscribe(isActive => {
            this.dashboardActions.push({
                label: 'Export to PDF with Data',
                action: 'pdf_new',
            });
        });
    }

    ngOnChanges() {
        if (this.exportToPdfAvailable && this.dashboardActions.findIndex(item => item.action === 'pptx' || item.action === 'pdf') === -1) {
            this.dashboardActions.push(
                { label: 'Export to PPTX', action: 'pptx' },
                {
                    label: 'Export to PDF',
                    action: 'pdf',
                }
            );
        }
    }

    getTab(tab: number) {
        this.editTabId = tab;
    }

    ngAfterViewInit() {
        this.store
            .select(state => state && state.edit)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                window.dispatchEvent(new Event('resize'));
            });
    }

    trackByTab: TrackByFunction<number> = (_: number, tab: number) => tab;

    handleTabChange({ preventDefault, nextId }: NgbNavChangeEvent): void {
        preventDefault();

        this.store.changeTab(nextId);
    }

    handleEditDashboard(edit: boolean) {
        this.store.setEdit(edit);
    }

    async handleDashboardAction(event: any, storeValue: any) {
        this.dpDashboardTabs.forEach(tab => {
            tab.cdr.detectChanges();
        });
        switch (event.action) {
            case 'json': {
                this.handleExportDashboard(storeValue);
                break;
            }
            case 'pptx': {
                this.handleExportDashboardToPptx();
                break;
            }
            case 'pdf': {
                this.handleExportDashboardToPdf(storeValue);
                break;
            }
            case 'pdf_new': {
                this.handleExportDashboardToPdfNew();
                break;
            }
        }
        this.messageBus.reset();
    }

    handleExportDashboard(dashboard: any) {
        this.api.exportDashboard(dashboard.id || dashboard.dashboardId || dashboard.uuid).subscribe((data: any) => {
            writeContents(toJSONBlob(data), `${data.name} dashboard.json`);

            this.toaster.successToast({
                title: 'Success',
                content: 'Dashboard was successfully exported',
            });
        });
    }

    handleExportDashboardToPptx() {
        const loading = this.modal.open(ExportLoaderComponent, {}, { fileType: 'PPTX' });
        this.dpDashboardTabs
            .find(tab => tab.active)
            .handleExportTab('PPTX')
            .pipe(
                map((data: any[]) => ({
                    name: this.store.value.name,
                    seriesList: flatten(flatten(data)).filter(Boolean),
                })),
                switchMap(dashboardToExport =>
                    html2canvasImport().pipe(
                        map(html2canvas => ({
                            html2canvas,
                            dashboardToExport,
                        }))
                    )
                ),
                switchMap(({ dashboardToExport, html2canvas }) => {
                    const globalFilters = document.getElementsByTagName('dp-dashboard-filter-set').item(0) as HTMLElement;
                    const filters = globalFilters.querySelector('.widget-filter');
                    return (filters
                        ? from(
                              html2canvas(globalFilters, {
                                  ignoreElements: element => element.classList.contains(IGNORED_ELEMENTS),
                              })
                          )
                        : of(null)
                    ).pipe(
                        map(globalFiltersCanvas => {
                            if (globalFiltersCanvas) {
                                const imgWidth = Math.min(210, globalFiltersCanvas.width * 0.26);
                                const imgHeight = (globalFiltersCanvas.height * imgWidth) / globalFiltersCanvas.width;
                                dashboardToExport.seriesList.unshift({
                                    name: 'Dashboard Filters',
                                    additionalInfo: globalFiltersCanvas.toDataURL('image/png'),
                                    x: 10,
                                    y: 20,
                                    width: imgWidth,
                                    height: imgHeight,
                                    title: 'Dashboard Filters',
                                });
                            }
                            return dashboardToExport;
                        })
                    );
                }),
                switchMap(dashboardToExport => this.api.exportDashboardToPPTX(dashboardToExport)),
                finalize(() => loading.close())
            )
            .subscribe(blob => {
                writeContents((blob as unknown) as Blob, `${this.store.value.name}.pptx`);
            });
    }

    async handleExportDashboardToPdfNew() {
        const loading = this.modal.open(ExportLoaderComponent, {}, { fileType: 'PDF' });
        let pdf: any;

        try {
            const { jsPDF } = await import('../../../widget-builder/services/exporter/jspdf.imports');
            const pdf1 = new jsPDF('p', 'mm', 'a4', true);
            pdf1.deletePage(1);
            pdf = pdf1;
        } catch {
            loading.close();

            return;
        }

        this.dpDashboardTabs
            .find(tab => tab.active)
            .handleExportTab('PDF', pdf, true)
            .pipe(finalize(() => loading.close()))
            .subscribe({
                complete: () => {
                    pdf.save(`${this.store.value.name}.pdf`);
                },
            });
    }

    handleExportDashboardToPdf(dashboard: any) {
        const loading = this.modal.open(ExportLoaderComponent, {}, { fileType: 'PDF' });
        this.dpDashboardTabs
            .find(tab => tab.active)
            .handleExportTab('PDF')
            .pipe(
                switchMap(dataA =>
                    html2canvasImport().pipe(
                        map(html2canvas => ({
                            html2canvas,
                            dataA,
                        }))
                    )
                )
            )
            .subscribe(async ({ html2canvas, dataA }) => {
                const data = flatten(flatten(dataA)) as any;

                // eslint-disable-next-line @typescript-eslint/no-shadow
                const { jsPDF } = await import('../../../widget-builder/services/exporter/jspdf.imports');
                this.pdf = new jsPDF('p', 'mm', 'a4', true);
                this.pdf.setFontSize(14);

                const globalFilters = document.getElementsByTagName('dp-dashboard-filter-set').item(0) as HTMLElement;
                if (globalFilters && globalFilters.offsetHeight) {
                    this.pdf.text('Dashboard Filters', 10, 10);
                    const globalFiltersCanvas = await html2canvas(globalFilters, {
                        ignoreElements: element => element.classList.contains(IGNORED_ELEMENTS),
                    });
                    const imgWidth = Math.min(210, globalFiltersCanvas.width * 0.26);
                    const imgHeight = (globalFiltersCanvas.height * imgWidth) / globalFiltersCanvas.width;
                    this.pdf.addImage(globalFiltersCanvas.toDataURL('image/png'), 0, 20, imgWidth, imgHeight);
                    this.pdf.addPage();
                }

                for (let i = 0; i < data.length; i++) {
                    if (data[i]) {
                        if (data[i].title) {
                            this.pdf.text(this.pdf.splitTextToSize(data[i].title, 200), 10, 10);
                        }
                        this.pdf.addImage(data[i].additionalInfo, 'PNG', data[i].x, data[i].y, data[i].width, data[i].height);
                    }
                    if (i !== data.length - 1 && data[i]) {
                        this.pdf.addPage();
                    }
                }

                loading.close();
                this.pdf.save(`${dashboard.name}.pdf`);
                this.pdf.close();
            });
    }

    async handleAddGroup() {
        const { activeTab, dashboardId } = this.store.value;

        try {
            const editedGroup = await this.modal.open(DpEditGroupComponent, { injector: this.injector }, { modal: true }).result;
            const group = await this.api
                .saveGroup({
                    ...editedGroup,
                    id: null,
                    dashboardId,
                    tabId: activeTab,
                })
                .toPromise();

            this.toaster.successToast({
                title: 'Success',
                content: 'Group created successfully',
            });
            this.store.addGroup(group);
        } catch {
            // noop
        }
    }

    async handleAddTab() {
        this.facade.addTab().subscribe();
        this.cdref.detectChanges();
    }

    async handleEditTab() {
        await this.facade.editTab(this.editTabId);
    }

    async handleDeleteTab() {
        await this.facade.deleteTab(this.editTabId);
    }

    async handleEditDashboardSettings() {
        this.facade.editDashboard().subscribe(updatedDashboard => {
            this.save.emit(updatedDashboard);
        });
    }

    handleEditGlobalFilters() {
        this.router.navigate(['../global-filters', this.store.value.id ?? this.store.value.dashboardId], {
            relativeTo: this.route,
            state: {
                fromLink: this.router.url,
            },
        });
    }

    handleSubmitChangedFilters(event: any) {
        this.dashboardWidgetManager.saveAppliedFilters(null, event.key, event.value).subscribe();
    }

    async handleSubmitAllChangedFilters(event: any) {
        await this.dashboardWidgetManager.saveAllAppliedFilters(this.store.value.id ?? this.store.value.dashboardId, event).toPromise();
    }

    handleResetFilters() {
        this.dashboardWidgetManager.resetFilters().subscribe();
    }

    handleBreadCrumbLink() {
        const firstTabId = +Object.keys(this.store.value.tabsMap).find(key => +this.store.value.tabsMap[+key].options.tabOrder === 0);
        this.store.changeTab(firstTabId);
        this.messageBus.tabChanged$.next();
        this.resetEventDependencies();
    }

    handleBackToPreviousTab() {
        if (this.store.value.tabsMap[this.store.value.activeTab].options.resetEventDependencies) {
            this.resetEventDependencies();
        }
        this.store.backToPreviousTab();
        this.messageBus.tabChanged$.next();
    }

    handleBackToPreviousState() {
        document.querySelector('dp-dashboard [ngbnavpane].active perfect-scrollbar').firstElementChild.scrollTop = 0;
        const prevState = this.previousMlfState.pop();
        const state = prevState.state;

        if (state.previousDashboardname) {
            this.dashboardWidgetManager.getDashboardIdByName(state.previousDashboardname).subscribe(({ id }) => {
                const placeholders = {
                    redirectTo: state.previousTab,
                    portfolioId: state.portfolioId,
                    states: this.previousMlfState.length ? cloneDeep(this.previousMlfState) : [],
                };
                this.changeDashboard([`../${id}`], { placeholders });
            });
        } else if (state.previousPortfolioId) {
            const event: any = { id: 'CHANGE_MLF', value: { portfolioId: state.previousPortfolioId } };
            if (state.previousTab) {
                const tabs: any[] = Object.values(this.store.value.tabsMap);
                const nextTab = tabs.find(tab => tab.name === state.previousTab);
                this.handleTabChange({ preventDefault: noop, nextId: nextTab.id, activeId: null });
            }
            this.event.emit(event);
        }
    }

    setPreviousState(state: any) {
        if (!this.previousMlfState) {
            this.previousMlfState = [];
        }

        this.previousMlfState.push(state);
    }

    resetEventDependencies() {
        this.messageBus.eventReload();
        this.messageBus.reset();
    }

    reOrderTabs(
        event: CdkDragDrop<any[]>,
        dashboardId: DataMorph.Dashboard['dashboardId'],
        tabsMap: Record<DataMorph.DashboardTab['id'], DashboardTabState>
    ) {
        if (event.previousIndex === event.currentIndex) {
            return;
        }
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        event.container.data.forEach((id, index) => {
            tabsMap[id].options.tabOrder = index;
        });
        this.api.updateTabsOrder(dashboardId, event.container.data).subscribe();
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }
}

export function getTabActions(dashboard: DpDashboardComponent): Observable<TreeLikeMenuGroup[]> {
    const store = inject(DpDashboardStore);
    const ft = inject(DATA_MORPH_FEATURE_TOGGLE);

    return combineLatest<TreelikeMenuItem[]>([
        store
            .select(state => state?.edit)
            .pipe(
                map(edit => {
                    if (!edit) {
                        return null;
                    }
                    return {
                        label: 'Edit Tab',
                        icon: 'facEdit',
                        actionHandler: (event: any) => dashboard.handleEditTab(),
                    };
                })
            ),
        store
            .select(state => state?.edit)
            .pipe(
                map(deleteItem => {
                    if (!deleteItem) {
                        return null;
                    }
                    return {
                        label: 'Delete Tab',
                        icon: 'facTrash',
                        actionHandler: () => dashboard.handleDeleteTab(),
                    };
                })
            ),
    ]).pipe(
        map(([editItem, deleteItem]) => {
            const manageTabsItems = [];
            if (editItem !== null) {
                manageTabsItems.push(editItem);
            }
            if (deleteItem !== null) {
                manageTabsItems.push(deleteItem);
            }
            return [
                {
                    label: 'Manage Tab',
                    items: manageTabsItems,
                },
            ].filter(group => group.items.length);
        })
    );
}

export function getDashboardActions(dashboard: DpDashboardComponent): Observable<TreeLikeMenuGroup[]> {
    const store = inject(DpDashboardStore);
    const ft = inject(DATA_MORPH_FEATURE_TOGGLE);

    return combineLatest<TreelikeMenuItem[]>([
        store
            .select(state => state?.edit)
            .pipe(
                map(edit => {
                    if (edit) {
                        return null;
                    }
                    return {
                        label: 'Edit',
                        actionHandler: () => dashboard.handleEditDashboard(true),
                    };
                })
            ),
        store.pipe(
            map(value => {
                if (value?.edit) {
                    return null;
                }
                return {
                    label: 'Export',
                    children: [
                        {
                            label: 'Export',
                            items: [
                                {
                                    label: 'JSON',
                                    actionHandler: () => dashboard.handleDashboardAction({ action: 'json' }, value),
                                },
                                {
                                    label: 'PDF',
                                    actionHandler: () => dashboard.handleDashboardAction({ action: 'pdf' }, value),
                                },
                                {
                                    label: 'PPTX',
                                    actionHandler: () => dashboard.handleDashboardAction({ action: 'pptx' }, value),
                                },
                                {
                                    label: 'PDF with Data',
                                    actionHandler: () => dashboard.handleDashboardAction({ action: 'pdf_new' }, value),
                                },
                            ],
                        },
                    ],
                };
            })
        ),
        ft.isActiveSync('eo_48475')
            ? store
                  .select(state => state?.dynamic)
                  .pipe(
                      map(dynamic => {
                          if (dynamic) {
                              return null;
                          }

                          return {
                              label: 'Settings',
                              actionHandler: () => dashboard.handleEditDashboardSettings(),
                          };
                      })
                  )
            : of(null),
        store
            .select(state => state?.edit)
            .pipe(
                map(edit => {
                    if (!edit) {
                        return null;
                    }
                    return {
                        label: 'Edit',
                        actionHandler: () => dashboard.handleEditGlobalFilters(),
                    };
                })
            ),
    ]).pipe(
        map(([editItem, exportItem, settingItem, editFilter]) => {
            const manageDashboardItems = [];
            const manageDashboardfilterItems = [];
            if (editItem !== null) {
                manageDashboardItems.push(editItem);
            }
            if (exportItem !== null) {
                manageDashboardItems.push(exportItem);
            }
            if (settingItem !== null) {
                manageDashboardItems.push(settingItem);
            }
            if (editFilter !== null) {
                manageDashboardfilterItems.push(editFilter);
            }
            return [
                {
                    label: 'Manage Dashboard',
                    items: manageDashboardItems,
                },
                {
                    label: 'Manage Dashboard Filter',
                    items: manageDashboardfilterItems,
                },
            ].filter(group => group.items.length);
        })
    );
}
