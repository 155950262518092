import { Component, HostListener, OnInit } from '@angular/core';
import { QueryBuilderStore } from '@app/shared/components/query-builder/store/query-builder.store';
import { QB_ICON_URLS } from '@app/shared/components/query-builder/consts/qb-icon-urls.const';
import { ModelGraphActionsService } from '@app/shared/components/query-builder/services/model-graph-actions.service';
import { ModelGraphRemoveNotAllowedDatasetRolesCommand } from '@app/shared/components/query-builder/models/model-graph-actions.model';
import {
    GetRolesByWidgetType,
    GetWidgetTypeRolesConventionByWidgetType,
} from '@app/shared/components/query-builder/qb-visualization/components/mapping-container/field-role-registry/field-role.registry';
import { WidgetType } from 'data-processor';

@Component({
    selector: 'app-qb-visualization-mapping-container',
    templateUrl: './mapping-container.component.html',
})
export class MappingContainerComponent implements OnInit {
    icons = QB_ICON_URLS;

    currentWidgetType: WidgetType;

    constructor(private queryBuilderStore: QueryBuilderStore, private modelGraphActionsService: ModelGraphActionsService) {}

    get widgetType(): WidgetType {
        return this.queryBuilderStore.drilldownGraph.widgetConfig.type;
    }

    get widgetTypeRolesConventions() {
        return GetWidgetTypeRolesConventionByWidgetType(this.currentWidgetType).sort(function(a, b) {
            return a.name === b.name ? 0 : a.name > b.name ? 1 : -1;
        });
    }

    ngOnInit(): void {
        this.preventUpdateWidgetType();
    }

    @HostListener('document:queryBuilderChanged', ['$event'])
    private updateWidgetType(event: CustomEvent): void {
        this.preventUpdateWidgetType();
        if (event.detail.changed === 'BOTH' || event.detail.changed === 'WIDGET_SETTINGS' || event.detail.changed === 'NO_CHANGES') {
            this.getCountOfDatasetFieldsWithRoles() > 0 && this.returnFieldsToDataset();
        }
    }

    private getCountOfDatasetFieldsWithRoles() {
        return this.queryBuilderStore.modelGraph.dataSetFields.fields.filter(field => field.roles.map(role => role.name).length > 0).length;
    }

    private preventUpdateWidgetType() {
        this.currentWidgetType = this.widgetType;
    }

    private returnFieldsToDataset() {
        const command: ModelGraphRemoveNotAllowedDatasetRolesCommand = {
            sourceGraph: this.queryBuilderStore.globalModelGraph,
            subGraphId: null,
            roles: [],
        };
        command.roles = GetRolesByWidgetType(this.currentWidgetType);
        this.modelGraphActionsService.removeNotAllowedDsFieldsRoles(command).subscribe(modelGraph => {
            this.queryBuilderStore.updateState(modelGraph);
        });
    }
}
