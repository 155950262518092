import { Optional, Provider } from '@angular/core';
import { refCount, shareReplay } from 'rxjs/operators';
import { defer } from 'rxjs';

import { ModalService } from '@dagility-ui/kit';
import { DateAdapter } from '@dagility-ui/shared-components';

import { NewPlaceholdersComponent } from '../components/new-placeholders/new-placeholders.component';
import { addTimeZonePlaceholderFactory } from '../providers/timezone-placeholders.provider';
import { DefaultPlaceholders, DEFAULT_PLACEHOLDERS } from './default-placeholders.provider';

export function modalPlaceholdersFactory(modalService: ModalService, dateAdapter: DateAdapter): DefaultPlaceholders {
    return addTimeZonePlaceholderFactory(
        () => defer(() => modalService.open(NewPlaceholdersComponent).result).pipe(shareReplay(1)),
        dateAdapter
    );
}

export const MODAL_PLACEHOLDERS_PROVIDER: Provider = {
    provide: DEFAULT_PLACEHOLDERS,
    deps: [ModalService, [new Optional(), DateAdapter]],
    useFactory: modalPlaceholdersFactory,
};
