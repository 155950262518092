import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { isDefined, ResizeObserverService } from '@dagility-ui/kit';
import { merge, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

const observeOnMutation = (target: HTMLElement): Observable<MutationRecord[]> => {
    return new Observable(observer => {
        const mutation = new MutationObserver((mutations, instance) => {
            observer.next(mutations);
        });
        mutation.observe(target, { attributes: false, childList: true, characterData: false });
        return () => {
            mutation.disconnect();
        };
    });
};

@Component({
    selector: 'dp-any-widget-filter-item-value',
    templateUrl: './any-widget-filter-item-value.component.html',
    styleUrls: ['./any-widget-filter-item-value.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ResizeObserverService],
})
export class AnyWidgetFilterItemValueComponent {
    @Input() value: unknown;

    items: string[];

    lastVisibleIndex$ = merge(this.resizeObserver.observe$(this.elRef, 0), observeOnMutation(this.elRef.nativeElement)).pipe(
        map(() => this.getOverflowIndex()),
        distinctUntilChanged()
    );

    constructor(private elRef: ElementRef, private resizeObserver: ResizeObserverService) {}

    ngOnChanges() {
        this.items = Array.isArray(this.value) ? this.value : isDefined(this.value) ? [this.value] : [];
    }

    private getOverflowIndex(): number {
        const { clientWidth, children } = this.elRef.nativeElement;
        const items = Array.from(children, ({ clientWidth }) => clientWidth);
        const first = 0;
        const last = items.length - 1;
        const more = children[last]?.tagName === `SPAN` ? items[last] : 0;

        items.unshift(...items.splice(first, 1));
        let total = items.reduce((sum, width) => sum + width, 0) - more;

        if (total <= clientWidth) {
            return this.items.length - 1;
        }

        for (let i = last - 1; i > 0; i--) {
            total -= items[i];

            if (total + more <= clientWidth) {
                return clamp(i - 1, -1, this.items.length - 1);
            }
        }

        return -1;
    }
}

const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);
