import { Router } from '@angular/router';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { StompService } from '@app/shared/services/admin/stomp.service';
import { Job, JobResponse } from '../pipeline-list//model/jobs-management.classes';
import { JobsManagementService } from '../pipeline-list//api/jobs-management.service';
import { finalize } from 'rxjs/operators';
import { combineLatest, Observable, Subscription } from 'rxjs';

import { facEditorExecution, facPipelineEditor } from '@dagility-ui/shared-components/icons';

import { AbstractPipelineListComponent } from '../pipeline-list/abstract-pipeline-list.component';
import { AuthService } from '@app/auth';
import { GridColumn, ModalService, Pageable, RbacAction, RbacStaticType, sortingDown, sortingUp } from '@dagility-ui/kit';
import { ProjectPipelineExecutionLogModalComponent } from 'src/app/pages/project/project-edit/project-pipelines/project-pipeline/project-pipeline-execution-log-modal/project-pipeline-execution-log-modal.component';

@Component({
    selector: 'app-workflow-dashboard-pipeline-list',
    templateUrl: './workflow-pipeline-list.component.html',
    styleUrls: ['./workflow-pipeline-list.component.scss'],
})
export class WorkflowDashboardPipelineListComponent extends AbstractPipelineListComponent implements OnInit, OnDestroy {
    @Input() jobs: JobResponse[];
    @Input() showParentData = true;
    @Input() projectId: number;
    @Input() workflowDashboard: boolean = false;
    pluginInstall = false;
    errorMessage = '';
    jobsList: JobResponse[];
    subscribeId: string;
    pingInterval: number;
    subscription: Subscription;
    gridColumns: GridColumn[];
    toolInFromJobList: number[];

    canExecute: boolean;
    canModify: boolean;

    icons = {
        facEditor: facPipelineEditor,
        facEditorExecution: facEditorExecution,
    };
    sortIcons = { sortingUp, sortingDown };

    constructor(
        private jobsManagementService: JobsManagementService,
        private readonly stompService: StompService,
        private modalService: ModalService,
        private authService: AuthService,
        private router: Router
    ) {
        super();
        combineLatest([this.hasRight('execute'), this.hasRight('modify')]).subscribe(([canExecute, canModify]) => {
            this.canModify = canModify;
            this.canExecute = canExecute;
        });
        this.toolInFromJobList = [];
        this.jobsList = [];
    }

    get columns(): GridColumn[] {
        return this.gridColumns.filter(col => !col.hidden);
    }

    private static makeParam(affectedJob: JobResponse): Job {
        return {
            toolId: affectedJob.toolId,
            name: affectedJob.jobName,
            project: affectedJob.project,
        };
    }

    hasRight(action: RbacAction): Observable<boolean> {
        return this.authService.hasPermission(RbacStaticType.OBJECT, 'Pipeline', action);
    }

    openPipelineExecution(projectId: number, pipelineId: number) {
        this.router.navigate([`/project/${projectId}/pipelines/${pipelineId}/graph/execution-viewer`]).then();
    }

    private stompSubscribing() {
        this.subscription = this.stompService.listenTopic(StompService.CI_CD_PIPELINE_USER_TOPIC + this.subscribeId).subscribe(msg => {
            const msgHeader = JSON.parse(msg.body);
            if (this.subscribeId !== msgHeader.type) {
                return;
            }
            const msgObj = JSON.parse(msgHeader.json);

            const toolIndex = this.toolInFromJobList.findIndex((item: any) => item === msgObj.job.toolId);

            if (toolIndex === -1) {
                return;
            }

            if (this.jobsList) {
                const affectedJobs = this.jobsList.filter(
                    job =>
                        job.toolId === msgObj.job.toolId &&
                        job.jobName === msgObj.job.name &&
                        job.project === msgObj.job.project &&
                        job.buildId === msgObj.buildNumber
                );

                affectedJobs.forEach(job => {
                    job.progressPercent = msgObj.progress;
                    job.lastDuration = msgObj.durationMillis;
                    job.lastStatus = msgObj.status;
                });

                // When stages have already done, no one have status in_progress, and we need refresh state of a job
                if (affectedJobs.length) {
                    if (
                        msgObj.status !== null &&
                        msgObj.status !== 'BUILDING' &&
                        msgObj.stages.find((stage: any) => stage.status === 'IN_PROGRESS') === undefined
                    ) {
                        const job = WorkflowDashboardPipelineListComponent.makeParam(affectedJobs[0]);
                        this.jobsManagementService.getDetailedPipeline(job).subscribe(jobResponseArray => {
                            this.addNullValues(jobResponseArray);
                            this.addNewElements(jobResponseArray);
                        });
                    }
                } else {
                    // If we don't find index in table, than mean this is a new job (build), so if condition below is true, we add new element in table
                    // This happened when job have several builds in one time.
                    const affectedJob = this.jobsList.find(
                        job => job.jobName === msgObj.job.name && job.project === msgObj.job.project && job.toolId === msgObj.job.toolId
                    );
                    if (affectedJob) {
                        const job = WorkflowDashboardPipelineListComponent.makeParam(affectedJob);
                        this.jobsManagementService.getDetailedPipeline(job).subscribe(jobResponseArray => {
                            this.addNullValues(jobResponseArray);
                            this.addNewElements(jobResponseArray);
                        });
                    }
                }
            }
        });
    }

    private addNewElements(jobResponseArray: JobResponse[]) {
        if (!jobResponseArray.length) {
            return;
        }
        const joblist = [...this.jobsList];
        jobResponseArray.forEach(job => {
            const pipelineChangeIndex = joblist.findIndex(
                obj => job.jobName === obj.jobName && job.projectName === obj.projectName && job.toolId === obj.toolId
            );
            if (pipelineChangeIndex !== -1) {
                this.jobsList[pipelineChangeIndex] = job;
                this.jobsManagementService.pipelineJobs.next(this.jobsList);
            }
        });
    }

    private addNullValues(jobResponseArray: JobResponse[]) {
        for (let i = 0; i < jobResponseArray.length; i++) {
            if (jobResponseArray[i].last5Statuses && jobResponseArray[i].last5Statuses.length < 5) {
                const nullCount = 5 - jobResponseArray[i].last5Statuses.length;
                for (let j = 0; j < nullCount; j++) {
                    jobResponseArray[i]?.last5Statuses.push(null);
                }
            }
        }
        return jobResponseArray;
    }

    ngOnInit() {
        if (this.jobs) {
            this.addNullValues(this.jobs);
        }

        this.gridColumns = this.workflowDashboard
            ? [
                  { title: 'Pipeline', width: '20%', sortingField: this.showParentData ? 'pipelineName' : '' },
                  { title: 'Build ID', width: '14%', sortingField: this.showParentData ? 'buildId' : '' },
                  { title: 'Last Executed', width: '10%', sortingField: this.showParentData ? 'lastExecutedTime' : '' },
                  { title: 'Duration', width: '13%', sortingField: this.showParentData ? 'lastDuration' : '' },
                  { title: 'History', width: '10%' },
                  { title: 'Status', width: '10%', sortingField: this.showParentData ? 'lastStatus' : '' },
                  { title: '', width: '11%' },
                  { title: 'Actions', width: '12%' },
              ]
            : [
                  {
                      title: 'Pipeline',
                      width: '20%',
                      sortingField: this.showParentData ? 'jobName' : '',
                  },
                  {
                      title: 'Build ID',
                      width: '15%',
                      sortingField: this.showParentData ? 'buildId' : '',
                  },
                  {
                      title: 'Last Launch',
                      width: '15%',
                      sortingField: this.showParentData ? 'lastExecutedTime' : '',
                  },
                  {
                      title: 'Duration',
                      width: '15%',
                      sortingField: this.showParentData ? 'lastDuration' : '',
                  },
                  {
                      title: 'History',
                      width: '13%',
                  },
                  {
                      title: 'Status',
                      width: '15%',
                      sortingField: this.showParentData ? 'lastStatus' : '',
                  },
                  { title: '', width: '5%' },
                  {
                      title: 'Actions',
                      width: '13%',
                  },
              ];
        this.getJobs();
        this.fillToolIds();
        this.subscribeAllJobs();
    }

    changeSort(fieldName: string): void {
        if (!fieldName) {
            return;
        }
        this.sort(fieldName);
    }

    sort(fieldName: string) {
        const sorted = this.pageable.sorted(fieldName);
        if (sorted === null || sorted === 'ASC') {
            this.pageable.sortOneField(fieldName);
        } else {
            this.pageable.orders = [];
        }

        this.getJobs(this.pageable);
    }

    getJobs(pageable: Pageable = this.pageable) {
        this.setLoadingStateBefore();
        this.setLoadingStateAfter(this.jobs);
        if (!!this.search) {
            this.searchJobList(this.search);
        }
        if (pageable && pageable.orders && pageable.orders.length > 0) {
            this.sortJobList(pageable.orders[0], this.jobsList);
        }
    }

    private subscribeAllJobs() {
        const subscribes: Job[] = this.jobsList.map(job => ({
            toolId: job.toolId,
            name: job.jobName,
            project: job.project,
        }));
        if (this.subscribeId) {
            // if we have subscription, just update it
            this.jobsManagementService.updateSubscribeToPipeline(this.subscribeId, subscribes).subscribe();
        } else {
            this.jobsManagementService.subscribeToPipeline(subscribes).subscribe(subscribeId => {
                this.subscribeId = subscribeId;
                this.setPing();
                this.stompSubscribing();
            });
        }
    }

    searchJobList(searchText: string) {
        const localData = [];
        if (this.jobs.length > 0) {
            localData.push(
                this.jobs.filter(val => {
                    return (
                        (val.jobName || '').match(searchText) !== null ||
                        (val.projectName || '').match(searchText) !== null ||
                        (val.componentName || '').match(searchText) !== null
                    );
                })
            );
            this.setLoadingStateAfter(localData[0]);
        }
    }

    sortJobList(orderData: any, data: any) {
        this.setLoadingStateAfter(sortArrByFieldAndDirection(data, orderData.property, orderData.direction));
    }

    private setPing() {
        if (this.pingInterval) {
            window.clearInterval(this.pingInterval);
            this.pingInterval = null;
        }
        if (this.subscribeId) {
            this.pingInterval = window.setInterval(() => {
                this.jobsManagementService.pingMonitor(this.subscribeId).subscribe();
            }, 30000);
        }
    }

    setLoadingStateBefore() {
        this.pluginInstall = false;
    }

    setLoadingStateAfter(jobResponse: any) {
        this.jobsList = jobResponse;
        this.pluginInstall = true;
        this.errorMessage = '';
    }

    startPipeline(job: JobResponse) {
        this.jobsManagementService
            .executePipeline(
                {
                    name: job.jobName,
                    toolId: job.toolId,
                    project: job.project,
                },
                {}
            )
            .subscribe(() => {
                this.subscribeAllJobs();
            });
    }

    stopPipeline(jobResponse: JobResponse) {
        const job = {
            toolId: jobResponse.toolId,
            name: jobResponse.jobName,
            project: jobResponse.project,
            buildNumber: jobResponse.buildId,
        };
        this.jobsManagementService.terminatePipeline(job).subscribe(() => {
            // noop
        });
    }

    handlePipelineExecution(pipeline: any): void {
        if (pipeline.lastStatus !== 'BUILDING' && pipeline.lastStatus !== 'RUNNING') {
            this.startPipeline(pipeline);
        } else {
            this.stopPipeline(pipeline);
        }
    }

    ngOnDestroy(): void {
        if (this.subscribeId) {
            this.jobsManagementService
                .unsubscribeToPipeline(this.subscribeId)
                .pipe(
                    finalize(() => {
                        this.subscribeId = null;
                        this.setPing();
                    })
                )
                .subscribe();
        }
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    private fillToolIds() {
        this.toolInFromJobList = [];
        this.jobsList.forEach(job => {
            this.toolInFromJobList.push(job.toolId);
        });
    }

    openExecutionLog(rowData: JobResponse) {
        if (rowData.buildId) {
            this.modalService.open(
                ProjectPipelineExecutionLogModalComponent,
                {
                    centered: true,
                    backdrop: 'static',
                    size: 'lg',
                    windowClass: 'pipeline-log-modal',
                },
                {
                    pipelineName: `${rowData.pipelineName}`,
                    logs$: this.jobsManagementService.getFullLog({
                        toolId: rowData.toolId,
                        name: rowData.jobName,
                        project: rowData.project,
                        buildNumber: rowData.buildId,
                    }),
                    time: rowData.lastExecutedTime,
                    buildNumber: rowData.buildId,
                }
            );
        }
    }

    handleActionEditPipeline(projectId: number, pipelineId: number) {
        this.router.navigate([`project/${projectId}/pipelines/${pipelineId}`]).then();
    }
}

export function sortArrByFieldAndDirection(array: any[], field: string, dir: string) {
    return [...array].sort((a, b) => {
        if (a[field] === null) {
            return 1;
        }
        if (b[field] === null) {
            return -1;
        }
        if (typeof a[field] === 'string' && typeof b[field] === 'string') {
            return dir === 'ASC'
                ? a[field].toLowerCase().localeCompare(b[field].toLowerCase())
                : b[field].toLowerCase().localeCompare(a[field].toLowerCase());
        }

        if (a[field] > b[field]) {
            return dir === 'ASC' ? 1 : -1;
        }
        if (a[field] < b[field]) {
            return dir === 'ASC' ? -1 : 1;
        }

        return 0;
    });
}
