<div class="chart-customization">
    <div class="modal-header">
        <div class="d-flex justify-content-between w-100">
            <div class="d-flex justify-content-lg-start">
                <div (click)="closeTraceability()" class="m-1 back-icon cursor-pointer"></div>
                <h4 class="modal-title m-0">
                    <span class="font-wt-600">Issue Life Cycle Traceability: {{ selectedIssueId }}</span>
                </h4>
            </div>

            <div class="d-flex flex-row justify-content-lg-between">
                <span class="p-1 font-12px">Issue ID</span>
                <input
                    type="text"
                    id="name"
                    (keyup.enter)="getData()"
                    [(ngModel)]="issueId"
                    maxlength="30"
                    class="form-control font-11px pr-2 max-width-80px size-s"
                    name="name"
                />
                <button type="button" class="btn btn-primary size-s mx-3" (click)="getData()">
                    <span class="font-10px">OK</span>
                </button>
            </div>
        </div>

        <button
            type="button"
            class="close pl-2"
            aria-label="Close button"
            aria-describedby="modal-title"
            (click)="activeModal.dismiss('Cross click')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <ng-container *ngIf="!loading">
        <ng-container *ngIf="traceabilityData.statusHeader; else noDataTempl">
            <app-chart-node [traceabilityData]="traceabilityData"></app-chart-node>
        </ng-container>
    </ng-container>
    <div *ngIf="loading" class="h-100 d-flex justify-content-center align-items-center">
        <lib-spinner></lib-spinner>
    </div>
    <ng-template #noDataTempl>
        <div class="d-flex justify-content-center max-height-300px flex-column align-items-center">
            <div class="not-found-image"></div>
            <span class="not-found-text">
                <strong>No Data</strong>
            </span>
        </div>
    </ng-template>
</div>
