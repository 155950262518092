import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ProcessorMonitoringService } from '../processor-monitoring.service';
import { ToolGraphViewLayoutComponent } from './tool-graph-view-layout/tool-graph-view-layout.component';
import { ToolGraphChannel, ToolGraphLines } from './tool-graph-view-model';
import { cloneDeep } from 'lodash';
import { ToolService } from '@dagility-ui/shared-components';

@Component({
    selector: 'dp-tool-graph-view',
    templateUrl: './tool-graph-view.component.html',
    styleUrls: ['./tool-graph-view.component.scss'],
})
export class ToolGraphViewComponent implements OnInit {
    @ViewChild('view', { static: false }) jdView: ToolGraphViewLayoutComponent;

    toolId: string;
    jobSetId: number;
    toolName: string;

    blocks: TreeNode[] = [];
    lines: ToolGraphLines[] = [];
    channels: ToolGraphChannel[] = [];

    maxValue: number;
    treeArrays: any = [];
    trees: any = [];
    tempTreeArray: any = [];
    alreadyUsed: Record<string, boolean> = {};

    isRoot: boolean;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private pmService: ProcessorMonitoringService,
        private toolService: ToolService
    ) {}

    ngOnInit() {
        this.route.paramMap.subscribe(param => {
            this.toolId = param.get('toolId');
        });

        this.toolService.getToolById(this.toolId).subscribe((tool: any) => {
            this.jobSetId = tool.result.jobSetId;
            this.toolName = tool.result.name;

            if (this.jobSetId) {
                this.pmService.exportJobSet(this.jobSetId).subscribe((jobSet: any) => {
                    this.tempTreeArray = cloneDeep(jobSet.jobDefinitions);

                    for (const jobDefinition of jobSet.jobDefinitions) {
                        const outgoingChannel = jobDefinition.outgoingChannel;
                        jobDefinition.outgoingChannel = [];
                        if (outgoingChannel) {
                            jobDefinition.outgoingChannel.push(outgoingChannel);
                        }
                    }

                    for (const jobDefinition of jobSet.jobDefinitions) {
                        this.alreadyUsed[jobDefinition] = false;
                        if (jobDefinition.actions) {
                            this.findOutgoingChannel(jobDefinition.actions, jobDefinition);
                        }

                        this.isRoot = false;
                        if (this.isJobRoot(jobSet.jobDefinitions, jobDefinition.incomingChannel)) {
                            jobDefinition.incomingChannel = null;
                        }

                        if (!jobDefinition.incomingChannel) {
                            this.treeArrays.push({
                                name: jobDefinition.instanceName,
                                outgoingChannel: jobDefinition.outgoingChannel,
                                children: [],
                            });
                            this.tempTreeArray.splice(
                                this.tempTreeArray.findIndex((job: any) => job.instanceName === jobDefinition.instanceName),
                                1
                            );
                        }
                    }
                    while (this.tempTreeArray.length !== 0) {
                        for (const jobDefinition of jobSet.jobDefinitions) {
                            if (this.alreadyUsed[jobDefinition.instanceName]) {
                                continue;
                            }
                            if (jobDefinition.incomingChannel) {
                                const newNode = {
                                    name: jobDefinition.instanceName,
                                    incomingChannel: jobDefinition.incomingChannel,
                                    outgoingChannel: jobDefinition.outgoingChannel,
                                };

                                for (const tree of this.treeArrays) {
                                    if (tree.outgoingChannel === newNode.incomingChannel) {
                                        tree.children.push({
                                            name: newNode.name,
                                            incomingChannel: newNode.incomingChannel,
                                            outgoingChannel: newNode.outgoingChannel,
                                            children: [],
                                        });
                                        this.alreadyUsed[newNode.name] = true;
                                        const temp = this.tempTreeArray.findIndex((job: any) => job.instanceName === newNode.name);
                                        if (temp !== -1) {
                                            this.tempTreeArray.splice(temp, 1);
                                        }
                                    } else {
                                        this.findParent(tree, newNode);
                                    }
                                }
                            }
                        }
                    }
                    for (const tree of this.treeArrays) {
                        this.createTree(tree);
                    }

                    this.resolveTreesConflicts();

                    for (const tree of this.trees) {
                        this.generateBlocksArray(tree);
                        this.generateLines(tree);
                    }
                });
            }
        });
    }

    findOutgoingChannel(actions: any, jd: any) {
        for (const action of actions) {
            if (action.type === 'CHANNEL') {
                jd.outgoingChannel.push(action.channel);
            } else if (action.type === 'LOOP') {
                this.findOutgoingChannel(action.actions, jd);
            } else if (action.type === 'FORK') {
                for (const forkBranch of action.actions) {
                    this.findOutgoingChannel(forkBranch, jd);
                }
            }
        }
    }

    isJobRoot(jobDefinitions: any, incomingChannel: string): boolean {
        for (const element of jobDefinitions) {
            this.findIncomingChannel(element.actions, incomingChannel);
            if (this.isRoot) {
                return false;
            }
        }
        return true;
    }

    findIncomingChannel(actions: any, incomingChannel: string) {
        for (const action of actions) {
            if (action.type === 'CHANNEL' && action.channel === incomingChannel) {
                this.isRoot = true;
            } else if (action.type === 'LOOP') {
                this.findIncomingChannel(action.actions, incomingChannel);
            } else if (action.type === 'FORK') {
                for (const forkBranch of action.actions) {
                    this.findIncomingChannel(forkBranch, incomingChannel);
                }
            }
        }
    }

    createTree(treeArray: any) {
        let newTree = this.buildTree(treeArray, null, null, 0);

        this.initializeTree(newTree);
        this.findTreeModifiedValues(newTree, 0);
        this.updateTreeYAxisValues(newTree);
        this.fixNodeConflicts(newTree);
        this.assignSiblingCounts(newTree);

        if (newTree.children.length == 1) {
            newTree.finalY = newTree.children[0].finalY;
        } else if (newTree.children.length >= 2) {
            let minY = Infinity;
            let maxY = -minY;
            for (const node of newTree.children) {
                minY = Math.min(minY, node.finalY);
                maxY = Math.max(maxY, node.finalY);
            }
            newTree.finalY = newTree.children[0].finalY + (maxY - minY) / 2;
        }

        this.trees.push(newTree);
    }

    resolveTreesConflicts() {
        for (let i = 0; i < this.trees.length - 1; i++) {
            this.maxValue = this.trees[i].finalY;
            this.findMaxY(this.trees[i]);
            this.shiftTree(this.trees[i + 1]);
        }
    }

    findMaxY(node: any) {
        for (const child of node.children) {
            this.findMaxY(child);
        }

        if (this.maxValue < node.finalY) {
            this.maxValue = node.finalY;
        }
    }

    shiftTree(node: any) {
        for (const child of node.children) {
            this.shiftTree(child);
        }

        node.finalY = node.finalY + this.maxValue + 100;
    }

    findParent(root: any, newNode: any) {
        if (root.outgoingChannel.includes(newNode.incomingChannel)) {
            root.children.push({
                name: newNode.name,
                incomingChannel: newNode.incomingChannel,
                outgoingChannel: newNode.outgoingChannel,
                children: [],
            });
            this.alreadyUsed[newNode.name] = true;
            const temp = this.tempTreeArray.findIndex((job: any) => job.instanceName === newNode.name);
            if (temp !== -1) {
                this.tempTreeArray.splice(temp, 1);
            }
        } else if (root.children.length != 0) {
            for (const child of root.children) {
                this.findParent(child, newNode);
            }
        }
    }

    back() {
        history.back();
    }

    generateBlocksArray(node: any) {
        for (const child of node.children) {
            this.generateBlocksArray(child);
        }
        this.blocks.push(node);
    }

    initializeTree(node: any) {
        for (const child of node.children) {
            this.initializeTree(child);
        }

        if (node.prevSibling) {
            node.y = node.prevSibling.y + 70;
        } else {
            node.y = 0;
        }

        if (node.children.length == 1) {
            node.modifier = node.y;
        } else if (node.children.length >= 2) {
            let minY = Infinity;
            let maxY = -minY;
            for (const child of node.children) {
                minY = Math.min(minY, child.y);
                maxY = Math.max(maxY, child.y);
            }
            node.modifier = node.y - (maxY - minY) / 2;
        }
    }

    findTreeModifiedValues(node: any, modSum: any) {
        node.finalY = node.y + modSum;
        for (const child of node.children) {
            this.findTreeModifiedValues(child, node.modifier + modSum);
        }
    }

    updateTreeYAxisValues(root: any) {
        let minYVal = Infinity;
        let nodes = [root];
        while (nodes.length) {
            let node = nodes.shift();
            nodes = nodes.concat(node.children);
            if (node.finalY < minYVal) {
                minYVal = node.finalY;
            }
        }

        nodes = [root];
        while (nodes.length) {
            let node = nodes.shift();
            nodes = nodes.concat(node.children);
            node.finalY += Math.abs(minYVal);
        }
    }

    fixNodeConflicts(root: any) {
        for (const element of root.children) {
            this.fixNodeConflicts(element);
        }

        for (let i = 0; i < root.children.length - 1; i++) {
            let botContour = this.getContour(root.children[i], -Infinity, Math.max);
            let topContour = this.getContour(root.children[i + 1], Infinity, Math.min);
            if (botContour >= topContour) {
                this.shiftDown(root.children[i + 1], botContour - topContour + 70);
            }
        }
    }

    getContour(root: any, val: any, func: any) {
        let nodes = [root];
        while (nodes.length) {
            let node = nodes.shift();
            nodes = nodes.concat(node.children);
            val = func(val, node.finalY);
        }
        return val;
    }

    shiftDown(root: any, shiftValue: any) {
        let nodes = [root];
        while (nodes.length) {
            let node = nodes.shift();
            nodes = nodes.concat(node.children);
            node.finalY += shiftValue;
        }
    }

    assignSiblingCounts(root: any) {
        let nodes = [root, null];
        let level = [];

        let siblings = 0;
        while (nodes.length) {
            let node = nodes.shift();
            if (!node) {
                for (const item of level) {
                    item.siblings = siblings;
                }
                level = [];
                siblings = 0;
                if (nodes.length) {
                    nodes.push(null);
                }
            } else {
                nodes = nodes.concat(node.children);
                siblings++;
                level.push(node);
            }
        }
    }

    buildTree(dataNode: any, parent: any, prevSibling: any, level: any) {
        let root = new TreeNode(level, 500, parent, prevSibling, dataNode, dataNode.name);
        for (let i = 0; i < dataNode.children.length; i++) {
            root.children.push(this.buildTree(dataNode.children[i], root, i >= 1 ? root.children[i - 1] : null, level + 280));
        }
        return root;
    }

    generateLines(node: any) {
        for (const child of node.children) {
            this.generateLines(child);
        }

        if (node.children.length === 1) {
            this.lines.push({ x1: node.x + 180, y1: node.finalY + 12.5, x2: node.children[0].x, y2: node.children[0].finalY + 12.5 });
            this.channels.push({ x: node.x + 205, y: node.finalY - 20, channelName: node.children[0].dataNode.incomingChannel });
        } else if (node.children.length >= 2) {
            this.lines.push({ x1: node.x + 180, y1: node.finalY + 12.5, x2: node.x + 200, y2: node.finalY + 12.5 });
            this.lines.push({
                x1: node.x + 200,
                y1: node.children[0].finalY + 12.5,
                x2: node.x + 200,
                y2: node.children[node.children.length - 1].finalY + 12.5,
            });
            for (const element of node.children) {
                this.lines.push({
                    x1: node.x + 200,
                    y1: element.finalY + 12.5,
                    x2: node.x + 280,
                    y2: element.finalY + 12.5,
                });
                this.channels.push({
                    x: node.x + 205,
                    y: element.finalY - 20,
                    channelName: element.dataNode.incomingChannel,
                });
            }
        }
    }
}

export class TreeNode {
    x: any;
    y: any;
    finalY: any;
    modifier: any;
    parent: any;
    prevSibling: any;
    children: any;
    dataNode: any;
    width: number;
    height: number;
    name: string;

    constructor(x: any, y: any, parent: any, prevSibling: any, dataNode: any, name: string) {
        this.x = x;
        this.y = y;
        this.finalY = 0;
        this.modifier = 0;
        this.width = 180;
        this.height = 25;

        this.name = name;

        this.parent = parent;
        this.prevSibling = prevSibling;
        this.children = [];

        this.dataNode = dataNode;
    }
}
