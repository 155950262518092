<div class="d-flex flex-column" [formGroup]="form">
    <div class="d-flex flex-row">
        <lib-input
            [style.width.px]="194"
            label="From"
            labelPosition="left"
            formControlName="from"
            [showError]="false"
            type="number"
            [min]="'0'"
            [max]="'5'">
        </lib-input>
        <lib-input
            [style.width.px]="194"
            label="To"
            labelPosition="left"
            formControlName="to"
            type="number"
            [showError]="false"
            [min]="'0'"
            [max]="'5'"
            class="ml-4">
        </lib-input>
    </div>
    <div class="d-flex flex-row mt-2">
        <range-slider name="range"
                      [min]="0"
                      [max]="5"
                      [list]="[0, 1, 2, 3, 4, 5]"
                      [minValue]="from.value"
                      [maxValue]="to.value"
                      (maxValueChange)="onToValueChange($event)"
                      (minValueChange)="onFromValueChange($event)"
        ></range-slider>
    </div>
</div>
