import {
    Attribute,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EnvironmentInjector,
    EventEmitter,
    HostBinding,
    HostListener,
    Inject,
    Input,
    NgZone,
    OnDestroy,
    Optional,
    Output,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, defer, EMPTY, from, merge, Observable, of, Subject, throwError, zip } from 'rxjs';
import {
    catchError,
    delay,
    filter,
    map,
    mapTo,
    pluck,
    shareReplay,
    skip,
    switchMap,
    switchMapTo,
    take,
    takeUntil,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import {
    coerceBooleanProperty,
    generateUUID,
    IfInViewportService,
    liftToObsWithStatus,
    ModalService,
    writeContents,
} from '@dagility-ui/kit';

import { AnyWidgetModel, WidgetFilter, WidgetType } from '../../../models/any-widget.model';
import { ExecutionResult, WidgetBuilderService } from '../../../services/widget-builder.service';
import { AnyWidgetChartModel, mapToChartOptions } from '../../../services/query-to-options.mapper';
import { ExecutionContext, getQueryExecutor } from '../../../services/widget-query.executor';
import { AnyWidgetStore } from './any-widget.store';
import { getFiltersForm, isServerSideGridWithPagination, parseError, removeHTMLTags } from '../../../services/widget-builder.util';
import { findMatchedDrilldown, getDrilldownConverter } from '../../../services/widget.drilldown';
import { DEFAULT_PLACEHOLDERS, DefaultPlaceholders } from '../../../providers/default-placeholders.provider';
import { EXPORT_DATA_TYPE, WIDGET_EXPORTER, WidgetExporterFactory } from '../../../services/exporter/widget.exporter';
import { WidgetLogger, WidgetScriptExecutor } from '../../../services/widget-script.executor';
import { getWidgetDOMEvents } from '../../../services/message-bus/widget-dom-events.service';
import { AnyWidgetService } from './any-widget.service';
import {
    WidgetDomEvent,
    WidgetDrilldownEvent,
    WidgetEventManager,
    WidgetLinkEvent,
} from '../../../services/message-bus/widget-event.manager';
import { GridsterReflowService } from 'data-processor/lib/widget-library/dashboard/components/dp-dashboard-group/gridster-reflow.service';
import { pdfExporterFactory } from 'data-processor/lib/widget-library/widget-builder/services/exporter/widget-exporter.pdf';
import {
    AnyWidgetFilterWrapperComponent,
    hasVisibleFilter,
} from 'data-processor/lib/widget-library/widget-builder/components/widget/any-widget-filter-wrapper/any-widget-filter-wrapper.component';

@Component({
    selector: 'dp-any-widget',
    templateUrl: './any-widget.component.html',
    styleUrls: ['./any-widget.component.scss'],
})
export class AnyWidgetComponent implements OnDestroy {
    @Input() isSmallWidget: boolean;
    @Input() isSmallWidgetWithoutFilter: boolean;

    @Input() set options(opt: AnyWidgetModel) {
        if (!this.debuggerState) {
            this.defaultPlaceholders$ = this.defaultPlaceholders({
                ...opt,
                options: this.store.externalOptions,
            }).pipe(shareReplay(1));

            if (!this.isComplexPart) {
                this.defaultPlaceholders$.pipe(skip(1), take(1)).subscribe(() => {
                    this.store.reset();
                });
            }

            this.defaultPlaceholders$ = this.defaultPlaceholders$.pipe(take(1));
        }

        this._options = opt;
        this.intersect$ = merge(
            this.initLoading,
            this.isComplexPart ? of(null) : this.inViewPort.isInViewObs(this.triggerAll ? document.body : this.elementRef.nativeElement)
        ).pipe(take(1), shareReplay(1));
        this.initialize();
    }

    get options() {
        return this._options;
    }

    get isComplexPart() {
        return coerceBooleanProperty(this.hasParent);
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _options: AnyWidgetModel;

    @Input() parentFilters: FormGroup;
    @Input() parentTitle: string;
    @Input() parentFiltersLoaded = new BehaviorSubject(null);
    @Input() separatedView = false;
    @Input() triggerAll: boolean;
    @Input() parentWidget: any;
    @Input() subWidgetIndex: number = 0;
    @Input() previewMode = false;
    @Input() showingOnlyFiltersBlock: boolean;

    @Output() openModal = new EventEmitter();
    @Output() drilldown = new EventEmitter();
    @Output() storeAfterLoad = new EventEmitter();

    @Output() initPortal = new EventEmitter();

    @ViewChild('widget', { static: false, read: ElementRef }) template: ElementRef<HTMLElement>;
    @ViewChild('agGrid') agGrid: TemplateRef<any>;
    @ViewChild(AnyWidgetFilterWrapperComponent) filterWrapperComp: AnyWidgetFilterWrapperComponent;

    @ViewChild('filtersPortalContent', { read: ElementRef })
    set filtersPortalContent(content: ElementRef<HTMLElement>) {
        if (hasVisibleFilter(this.filters)) {
            this.initPortal.emit(content.nativeElement);
        }
    }

    @ViewChildren(AnyWidgetComponent) complexWidgets: QueryList<AnyWidgetComponent>;

    @HostBinding('id') id = generateUUID();

    @HostBinding('attr.data-widget_id')
    get widgetId() {
        return this.options.id;
    }

    @HostListener('legend-changed', ['$event'])
    handleLegendChanged(event: CustomEvent<{ legends: any[] }>) {
        if (this.store.workflow) {
            this.store.workflow.setLegendState(event.detail.legends, this.subWidgetIndex);
        }
    }

    filtersGroup: FormGroup;
    chartData$: Observable<any>;
    infiniteScrollChartData$ = new Subject();
    serverSearchChartData$ = new Subject();
    serverSortChartData$ = new Subject();
    type = WidgetType;
    editDashboard$ = this.route.params.pipe(
        pluck('action'),
        map((action) => action === 'edit'),
        take(1)
    );
    filtersLoaded$: Subject<void>;
    defaultPlaceholders$: Observable<Record<string, any>> = EMPTY;
    filtersData: any;
    chartModel: AnyWidgetChartModel;
    filters: WidgetFilter[] = [];
    placeholders: Record<string, any> = {};
    isLoading = false;
    initLoading: Subject<boolean> = new Subject<boolean>();

    private destroyed$ = new Subject<void>();

    get debuggerState() {
        return this.store.debuggerState;
    }

    get ph() {
        if (this.store.workflow) {
            return this.debuggerState.placeholdersMap[this.options.id].value;
        }

        return this.isComplexPart && !this.debuggerState ? this.placeholders : this.store.placeholders;
    }

    get modalInputs() {
        return {
            separatedView: true,
            externalOptions: this.store.externalOptions,
        };
    }

    intersect$: Observable<any>;

    constructor(
        public store: AnyWidgetStore,
        @Attribute('hasParent') public hasParent: string,
        private api: WidgetBuilderService,
        private modalService: ModalService,
        private inViewPort: IfInViewportService,
        public elementRef: ElementRef,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private zone: NgZone,
        private logger: WidgetLogger,
        private scriptExecutor: WidgetScriptExecutor,
        public widgetService: AnyWidgetService,
        private eventManager: WidgetEventManager,
        @Optional() private gridsterReflowService: GridsterReflowService,
        @Inject(DEFAULT_PLACEHOLDERS) private defaultPlaceholders: DefaultPlaceholders,
        @Inject(WIDGET_EXPORTER) private widgetExporterFactory: WidgetExporterFactory,
        private injector: EnvironmentInjector
    ) {
        this.listenClickEvents();
    }

    initialize(): void {
        this.initFiltersFormGroup();

        if (!this.debuggerState && this.options.type === WidgetType.COMPLEX) {
            this.zone.onMicrotaskEmpty.pipe(take(1)).subscribe(() => {
                this.loadComplexChartFilters();
            });
        }

        this.loadChartData();
    }

    initFiltersFormGroup(): void {
        this.filtersLoaded$ = new Subject<void>();

        if (this.debuggerState) {
            this.filtersGroup = this.debuggerState.filtersGroupMap[this.options.id];
        } else {
            const form = getFiltersForm({
                filters: this.options.filters,
                defaultValuesMap: this.store.filterValuesMap[this.options.id],
                scriptExecutor: this.scriptExecutor,
                gridWithPagination: isServerSideGridWithPagination(this.options),
                initDefaultValues: false,
            });

            if (!isEqual(this.filters, this.options.filters) || !this.filtersGroup) {
                this.filtersGroup = form;
            } else {
                this.filterWrapperComp.filterSet.loading = true;

                this.filtersGroup.patchValue(form.value, { emitEvent: false });

                this.filterWrapperComp.filterSet.loading = false;
            }
        }

        this.filters = this.options.filters;
    }

    loadComplexChartFilters() {
        if (!this.debuggerState && this.options.type === WidgetType.COMPLEX) {
            this.filterWrapperComp?.filterSet?.loadFilters(null);
        }
    }

    loadChartData(): void {
        this.isLoading = true;

        this.chartData$ =
            this.options.type === WidgetType.COMPLEX
                ? EMPTY
                : this.store.mock && this.options.chartOptions.mockData
                ? of(JSON.parse(this.options.chartOptions.mockData))
                : this.debuggerState
                ? this.debuggerState.dataSources[this.options.id].pipe(this.getChartOptions())
                : this.intersect$.pipe(
                      switchMapTo(this.defaultPlaceholders$),
                      switchMap((defaultPlaceholders) => this.parentFiltersLoaded.pipe(mapTo(defaultPlaceholders))),
                      map((defaultPlaceholders) => {
                          this.filterWrapperComp?.filterSet?.loadFilters(null);

                          return defaultPlaceholders;
                      }),
                      switchMap((defaultPlaceholders) => this.filtersLoaded$.pipe(mapTo(defaultPlaceholders))),
                      tap((defaultPlaceholders) => {
                          const placeholders = cloneDeep({
                              ...this.store.phf,
                              ...defaultPlaceholders,
                              ...this.filtersGroup.value,
                              ...(this.parentFilters?.value || {}),
                          });

                          if (this.isComplexPart) {
                              this.placeholders = placeholders;
                          } else {
                              this.store.setState({
                                  placeholders,
                              });
                          }
                      }),
                      switchMap((defaultPlaceholders) =>
                          liftToObsWithStatus(
                              new (getQueryExecutor(this.options))({
                                  api: this.api,
                                  scriptExecutor: this.scriptExecutor,
                                  logger: this.logger,
                                  queries: this.options.query ?? [],
                              })
                                  .execute$(
                                      new ExecutionContext({
                                          defaultPlaceholders,
                                          filters: this.getFilters(),
                                          placeholders: this.ph,
                                      })
                                  )
                                  .pipe(this.getChartOptions())
                          )
                      ),
                      tap(() => {
                          this.isLoading = false;
                          this.widgetService.widgetsLoaded$.next('Widget successfully loaded');
                      }),
                      catchError((err) => {
                          console.error(err);

                          return of([]).pipe(this.getChartOptions());
                      }),
                      shareReplay(1)
                  );
    }

    getChartOptions = () => (obs: Observable<any>) => {
        return obs.pipe(
            catchError(() => of([])),
            tap(() => {
                if (!this.isComplexPart) {
                    this.store.updatePlaceholders();
                }
            }),
            map((data) => {
                if (this.debuggerState && data.type === 'start') {
                    return data;
                }

                return mapToChartOptions(this.options, this.ph, data, this.store.currentLevel);
            }),
            catchError((e) => {
                console.error(e);

                return throwError(e);
            }),
            tap((data) => {
                if (data?.options && data?.options.type === 'healthscore') {
                    this.getColorForHealthScore(data.options.healthColor);
                }
                this.chartModel = data;
            }),
            tap((cd) => {
                if (cd.options) {
                    this.storeAfterLoad.emit(this.store);
                }
            }),
            tap(() => {
                setTimeout(() => {
                    this.cdr.detectChanges();
                    if (this.gridsterReflowService) {
                        this.gridsterReflowService.reflow();
                    }
                });
            })
        );
    };

    handleExportData(
        format: EXPORT_DATA_TYPE,
        dashboardExport?: boolean,
        widgetTitle?: ElementRef,
        exportedDataFilter?: (items: unknown[]) => unknown[]
    ): Promise<any> {
        const exporterFn = this.widgetExporterFactory[this.options.type][dashboardExport ? 'PDF' : format];
        const fileTitle = widgetTitle ? removeHTMLTags(widgetTitle.nativeElement.innerHTML) : this.options.chartOptions.title;

        const model = cloneDeep(this.chartModel);
        if (model?.options.items && exportedDataFilter) {
            model.options.items = exportedDataFilter(model.options.items);
        }
        return new exporterFn(model, fileTitle, this.elementRef, this.complexWidgets, this.api)
            .export(this.id, this.options.type, null, dashboardExport && format)
            .then((blob: any) => {
                if (dashboardExport) {
                    return blob;
                } else if (blob) {
                    writeContents(blob, `${fileTitle}.${format.toLocaleLowerCase()}`);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    exportToPdf(pdf: any) {
        const exporter = this.injector.runInContext(() => pdfExporterFactory(this.chartModel, pdf, this.elementRef));

        if (!exporter) {
            return null;
        }

        return exporter.export();
    }

    handleFiltersData($event: any) {
        this.filtersData = $event;
    }

    getFilters = () => ({
        ...this.filtersGroup.value,
        ...(this.parentFilters?.value || {}),
    });

    handleReload(changes: any[]) {
        this.store.updateAndReload(changes);
    }

    handleDrilldownEvent(event: any, alreadyConverted = false): void {
        if (this.previewMode) {
            return;
        }

        const converter = getDrilldownConverter(this.options.type);

        if (!converter && !alreadyConverted) {
            return;
        }

        if (!!event.payload && !!event.payload.legend && Array.isArray(event.payload.legend)) {
            this.store.workflow?.setLegendState(event.payload.legend);
        }

        const convertedEvent = alreadyConverted ? event : converter(event);
        this.eventManager.process(
            new WidgetDrilldownEvent(convertedEvent, this.isComplexPart ? this.subWidgetIndex.toString() : null),
            this.ph,
            this.isComplexPart ? this.parentWidget.options : this.options
        );

        if (
            (!this.options.drilldown && isEmpty(this.options.drilldownList)) ||
            !event ||
            (event && event.data && event.data.stopDrilldown)
        ) {
            return;
        }

        let placeholders = {};
        if (this.store.workflow) {
            placeholders = this.store.workflow.widgetDebugger.placeholdersMap[this.options.id].value;
        }

        const drilldown = findMatchedDrilldown(this.options, convertedEvent, placeholders);

        if (!drilldown) {
            return;
        }

        if (this.store.workflow) {
            this.drilldown.emit({
                drilldown,
                event: convertedEvent,
                templates: this.modalInputs,
                id: this.options.id,
            });

            return;
        }

        const inputs = this.store.drilldown(
            this.ph,
            convertedEvent,
            drilldown,
            this.isComplexPart
                ? [this.parentWidget, ...this.parentWidget.complexWidgets].reduce<Record<string, any>>((acc, w) => {
                      acc[w.options.id] = w.filtersGroup?.value;

                      return acc;
                  }, {})
                : {
                      [this.options.id]: this.filtersGroup.value,
                  },
            !this.modalService.hasOpenModals() && !this.debuggerState
        );

        if (this.debuggerState) {
            this.debuggerState.drilldown$.next({
                event: convertedEvent,
                id: this.options.id,
                type: 'drilldown',
                to: drilldown.widget.id,
            });

            return;
        }

        if (drilldown.displayType === 'modal' && !this.modalService.hasOpenModals()) {
            this.openModal.emit({
                ...inputs,
                ...this.modalInputs,
            });
        }
    }

    handleLinkClicked(linkValue: any) {
        this.ph.selectedValueForMessageBus = linkValue;
        this.eventManager.process(new WidgetLinkEvent(linkValue), this.ph, this.isComplexPart ? this.parentWidget.options : this.options);
    }

    handleCategoryItemClicked(eventValue: any) {
        this.ph.selectedValueForMessageBus = eventValue.pk;
        this.ph.category = eventValue.pk;
        this.eventManager.process(
            new WidgetDomEvent(eventValue.type, 'reload'),
            this.ph,
            this.isComplexPart ? this.parentWidget.options : this.options
        );
    }

    handleScrollEnded(event: {
        offset: number;
        limit: number;
        searchStr: string;
        additionalInfo?: any;
        sortField: string;
        sortOrder: string;
    }) {
        this.calculateNewChartData(event, this.infiniteScrollChartData$);
    }

    handleServerSearch(event: {
        offset: number;
        limit: number;
        searchStr: string;
        additionalInfo?: any;
        sortField: string;
        sortOrder: string;
    }) {
        this.calculateNewChartData(event, this.serverSearchChartData$);
    }

    handleServerSort(event: {
        offset: number;
        limit: number;
        searchStr: string;
        additionalInfo?: any;
        sortField: string;
        sortOrder: string;
    }) {
        this.calculateNewChartData(event, this.serverSortChartData$);
    }

    handleFiltersLoaded(isError = false) {
        if (isError) {
            this.filtersLoaded$.error('');
        } else {
            this.filtersLoaded$.next();
        }
    }

    handleInitLoading(format: EXPORT_DATA_TYPE, pdf: any = null, newExport: boolean = false): Observable<any> {
        if (this.store.workflow) {
            this.store.workflow.forceLoad();
        } else {
            this.initLoading.next(true);
        }

        return (
            this.options.type === WidgetType.COMPLEX
                ? zip(...this.complexWidgets.map((widget) => widget.chartData$.pipe(filter((data) => data.type !== 'start'))))
                : this.chartData$.pipe(filter((data) => data.type !== 'start'))
        ).pipe(
            take(1),
            switchMap(() => this.zone.onMicrotaskEmpty.pipe(take(1))),
            delay(500),
            withLatestFrom(this.logger.empty$),
            switchMap(([_, loaded]) =>
                loaded ? (newExport ? defer(() => this.exportToPdf(pdf)) : from(this.handleExportData(format, true))) : of(null)
            ),
            catchError((err) => {
                console.error(err);
                return of(null);
            })
        );
    }

    getColorForHealthScore(healthColor: string) {
        const dpAnyWidgetWrapper = (this.elementRef.nativeElement as HTMLElement).closest('dp-any-widget-wrapper') as HTMLElement;
        dpAnyWidgetWrapper.style.background = healthColor;
        dpAnyWidgetWrapper.classList.add('health-chart');
    }

    private calculateNewChartData(
        event: { offset: number; limit: number; searchStr: string; additionalInfo?: any; sortField: string; sortOrder: string },
        subject: Subject<any>
    ) {
        if (!this.store.workflow?.id) {
            return subject.next(mapToChartOptions(this.options, this.ph, {}));
        }

        const placeholders = cloneDeep(this.ph);
        placeholders.pageable = { offset: event.offset, limit: event.limit };
        placeholders.additionalInfo = event.additionalInfo;
        placeholders.searchStr = event.searchStr;
        placeholders.sort = { sortField: event.sortField, sortOrder: event.sortOrder };
        placeholders._options = this.options;
        const params: any = {
            placeholders,
            widget: null,
            results: [{}],
            range: { drilldowns: [], from: null, to: null },
            filters: this.filters,
        };

        this.api
            .calculateWidgetById(this.store.workflow.id, params)
            .pipe(
                switchMap((result: ExecutionResult) => {
                    if (result.errors.length) {
                        return throwError(result);
                    }

                    return of(result);
                }),
                catchError((errors) => {
                    this.logger.log(parseError(errors));
                    return of([]);
                })
            )
            .subscribe((data: ExecutionResult) => {
                if (data.results?.length) {
                    subject.next({
                        ...mapToChartOptions(this.options, this.ph, data.results[0].result),
                        placeholdersResult: data.results[0].result,
                    });
                }
            });
    }

    private listenClickEvents() {
        if (!this.store.workflow) {
            return;
        }

        getWidgetDOMEvents({
            zone: this.zone,
            element: this.elementRef.nativeElement,
            getPlaceholdersFn: () => null,
        })
            .pipe(
                filter(({ event }) => event?.drilldown),
                takeUntil(this.destroyed$)
            )
            .subscribe(({ event }) => {
                this.handleDrilldownEvent({ target: event.id, payload: event.value }, true);
            });
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }
}
