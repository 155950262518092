import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { MergeRequestCommit } from '../models/merge-request-commit';

@Component({
    selector: 'dp-merge-request-commit',
    templateUrl: './merge-request-commit.component.html',
    styleUrls: ['./merge-request-commit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'd-block body2',
    },
})
export class MergeRequestCommitComponent {
    @Input() commit: MergeRequestCommit;

    constructor(private toasterService: ToastrService) {}

    handleCopied() {
        this.toasterService.success('Copied to clipboard', 'Success');
    }
}
