import { Observable, of, throwError } from 'rxjs';

import { DataMorph } from '../models/dp-dashboard.model';

export abstract class DashboardWidgetSettingsManager {
    STORAGE_FILTERS = false;

    abstract getDashboard(id: string, dashboardMode?: boolean): Observable<DataMorph.Dashboard>;

    abstract saveWidget(widget: DataMorph.IlluminateDashboardWidget): Observable<DataMorph.IlluminateDashboardWidget>;

    abstract saveDashboard(dashboard: {
        options: Record<string, any>;
        id: number;
        dashboardId: number;
    }): Observable<DataMorph.IlluminateDashboardWidget>;

    abstract deleteWidget(widgetId: number): Observable<void>;

    abstract getWidgets(dashboardId: string): Observable<DataMorph.IlluminateDashboardWidget[]>;

    abstract getWidget(dashboardWidgetId: number): Observable<DataMorph.IlluminateDashboardWidget>;

    abstract updateWidget(widget: DataMorph.IlluminateDashboardWidget): Observable<DataMorph.IlluminateDashboardWidget>;

    abstract getLastAppliedFilters(dashboardId: number): Observable<Record<string, any>>;

    abstract saveAppliedFilters(dashboardId: number, placeholder: string, value: any): Observable<void>;

    abstract resetFilters(): Observable<void>;

    abstract getExtensions(dashboardWidgetId: number): Observable<IlluminateDashboardWidgetExtension[]>;

    abstract getComplexNamedDropdownItems(id: number): Observable<any>;

    abstract updateComplexNamedDropdownItems(id: number, items: any): Observable<any>;

    abstract getWidgetTags(): Observable<string[]>;

    getDashboardIdByName(name: string): Observable<{ id: string }> {
        return throwError(new Error('not implemented'));
    }

    saveAllAppliedFilters(dashboardId: number, filters: Record<string, any>): Observable<void> {
        return of(undefined);
    }
}

export interface IlluminateDashboardWidgetExtension {
    id?: number;
    illuminateDashboardWidgetId: number;
    extension: Record<string, any>;
}
