import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

const typeToPos = {
    year: 0,
    month: 1,
    day: 2,
    hour: 3,
    minute: 4,
    second: 5,
};

@Injectable({ providedIn: 'root' })
export class DateAdapter {
    timezone: string;
    timezoneChanges$ = new Subject<void>();
    private browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    private startOfDay: number;
    private dateTimeFormatMap: Map<string, Intl.DateTimeFormat> = new Map();

    constructor() {
        this.calculateStartDay();
    }

    getTimezone = () => {
        return this.timezone ?? this.browserTimeZone;
    };

    setTimezone = (timezone: string) => {
        if (!this.isValidTimezoneIANAString(timezone)) {
            console.warn('Please, provide valid IANA timezone');

            return;
        }

        if (timezone === this.getTimezone()) {
            return;
        }

        this.timezone = timezone || this.browserTimeZone;
        this.calculateStartDay();
        this.timezoneChanges$.next();
    };

    getPlaceholders() {
        return {
            timezone: this.getTimezone(),
            local_start_day: this.getStartOfDay(),
        };
    }

    private calculateStartDay() {
        const utcDate = this.zoneTimeToUtc(new Date(), this.browserTimeZone);
        const userZonedTime = this.utcToZonedTime(utcDate, this.getTimezone());
        userZonedTime.setHours(0, 0, 0, 0);

        this.startOfDay = userZonedTime.getTime();
    }

    private fixOffset(date: Date, offset: number, timezone: string) {
        const localTS = date.getTime();

        let utcGuess = localTS - offset;

        const o2 = this.calculateTimezoneOffset(timezone, new Date(utcGuess));

        if (offset === o2) {
            return offset;
        }

        utcGuess -= o2 - offset;

        const o3 = this.calculateTimezoneOffset(timezone, new Date(utcGuess));

        if (o2 === o3) {
            return o2;
        }

        return Math.max(o2, o3);
    }

    private parseTimezone(timezone: string, date: Date, isUTCDate?: boolean) {
        const offset = this.calculateTimezoneOffset(timezone, date);

        return -(isUTCDate ? offset : this.fixOffset(date, offset, timezone));
    }

    private calculateTimezoneOffset(timezone: string, date: Date) {
        const tokens = this.partsOffset(this.getDateTimeFormat(timezone), date);

        const asUTC = Date.UTC(tokens[0], tokens[1] - 1, tokens[2], tokens[3] % 24, tokens[4], tokens[5]);

        let asTS = date.getTime();
        const over = asTS % 1000;
        asTS -= over >= 0 ? over : 1000 + over;

        return asUTC - asTS;
    }

    private getDateTimeFormat(timezone: string) {
        if (!this.dateTimeFormatMap.has(timezone)) {
            this.dateTimeFormatMap.set(
                timezone,
                new Intl.DateTimeFormat('en-US', {
                    hour12: false,
                    timeZone: timezone,
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                })
            );
        }

        return this.dateTimeFormatMap.get(timezone);
    }

    private partsOffset(dtf: Intl.DateTimeFormat, date: Date) {
        const formatted = dtf.formatToParts(date);
        const filled = [];
        for (let i = 0; i < formatted.length; i++) {
            const pos = typeToPos[formatted[i].type as keyof typeof typeToPos];

            if (pos >= 0) {
                filled[pos] = parseInt(formatted[i].value, 10);
            }
        }

        return filled;
    }

    private zoneTimeToUtc(date: Date, timezone: string) {
        const utc = Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds(),
            date.getMilliseconds()
        );
        const offsetMilliseconds = this.parseTimezone(timezone, new Date(utc));

        return new Date(utc + offsetMilliseconds);
    }

    private utcToZonedTime(date: Date, timezone: string) {
        const offsetMilliseconds = this.parseTimezone(timezone, date, true);
        const d = new Date(date.getTime() - offsetMilliseconds);

        return new Date(
            d.getUTCFullYear(),
            d.getUTCMonth(),
            d.getUTCDate(),
            d.getUTCHours(),
            d.getUTCMinutes(),
            d.getUTCSeconds(),
            d.getUTCMilliseconds()
        );
    }

    private isValidTimezoneIANAString(timezone: string) {
        if (this.dateTimeFormatMap.has(timezone)) {
            return true;
        }

        try {
            Intl.DateTimeFormat(undefined, { timeZone: timezone });

            return true;
        } catch (error) {
            return false;
        }
    }

    getStartOfDay() {
        return this.startOfDay;
    }
}
