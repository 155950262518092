<div (click)="click.emit()" [ngSwitch]="iconName" style="background-size: 100%;" >

    <svg *ngSwitchCase="'LABEL'" class="{{classColor}}"  [style.transform]="transformRotateStr" [style.width]="widthStr" [style.height]="heightStr" viewBox="0 0 10 8" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1 2C1 1.44772 1.44772 1 2 1H6.7614C7.07715 1 7.37435 1.14912 7.56308 1.40225L9.5 4L7.56308 6.59775C7.37435 6.85088 7.07715 7 6.7614 7H2C1.44772 7 1 6.55228 1 6V2Z"
            stroke-linejoin="round"/>
    </svg>

    <svg *ngSwitchCase="'VALUE'" class="{{classColor}}" [style.transform]="transformRotateStr" [style.width]="widthStr" [style.height]="heightStr" viewBox="0 0 12 12" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10 1H2C1.44772 1 1 1.44772 1 2V4C1 4.55228 1.44772 5 2 5H10C10.5523 5 11 4.55228 11 4V2C11 1.44772 10.5523 1 10 1Z"
            stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M10 7H2C1.44772 7 1 7.44772 1 8V10C1 10.5523 1.44772 11 2 11H10C10.5523 11 11 10.5523 11 10V8C11 7.44772 10.5523 7 10 7Z"
            stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <svg *ngSwitchCase="'KEY'"  class="{{classColor}}" [style.transform]="transformRotateStr" [style.width]="widthStr" [style.height]="heightStr" viewBox="0 0 12 12" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.5011 10.5126L9.50111 9.51257M9.50111 9.51257L11.0011 8.01257L9.25111 6.26257L7.75111 7.76257M9.50111 9.51257L7.75111 7.76257M5.69611 5.70757C5.95428 5.45284 6.15952 5.14955 6.29999 4.81518C6.44047 4.4808 6.51341 4.12194 6.51463 3.75926C6.51584 3.39657 6.4453 3.03723 6.30707 2.70192C6.16884 2.36661 5.96564 2.06196 5.70919 1.8055C5.45273 1.54904 5.14807 1.34585 4.81277 1.20762C4.47746 1.06938 4.11811 0.998845 3.75543 1.00006C3.39275 1.00128 3.03389 1.07422 2.69951 1.2147C2.36513 1.35517 2.06185 1.5604 1.80711 1.81857C1.30618 2.33723 1.02899 3.03189 1.03526 3.75293C1.04153 4.47397 1.33074 5.1637 1.84061 5.67357C2.35048 6.18345 3.04022 6.47266 3.76126 6.47893C4.4823 6.48519 5.17696 6.20801 5.69561 5.70707L5.69611 5.70757ZM5.69611 5.70757L7.75111 7.76257"
            stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <svg *ngSwitchCase="'COLUMNS'" class="{{classColor}}" [style.transform]="transformRotateStr" [style.width]="widthStr" [style.height]="heightStr" viewBox="0 0 10 10" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5 0.5V9.5M5 0.5H8.5C8.76522 0.5 9.01957 0.605357 9.20711 0.792893C9.39464 0.98043 9.5 1.23478 9.5 1.5V8.5C9.5 8.76522 9.39464 9.01957 9.20711 9.20711C9.01957 9.39464 8.76522 9.5 8.5 9.5H5V0.5ZM5 0.5H1.5C1.23478 0.5 0.98043 0.605357 0.792893 0.792893C0.605357 0.98043 0.5 1.23478 0.5 1.5V8.5C0.5 8.76522 0.605357 9.01957 0.792893 9.20711C0.98043 9.39464 1.23478 9.5 1.5 9.5H5V0.5Z"
            stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <svg *ngSwitchCase="'CATEGORY'" class="{{classColor}}" [style.transform]="transformRotateStr" [style.width]="widthStr" [style.height]="heightStr" viewBox="0 0 10 8" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path d="M2.5 4H9.5M2.5 1H9.5M2.5 7H9.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M0 0.5H1V1.5H0V0.5Z"/>
        <path d="M0 3.5H1V4.5H0V3.5Z"/>
        <path d="M0 6.5H1V7.5H0V6.5Z"/>
    </svg>

    <svg *ngSwitchCase="'TIME'" class="{{classColor}}" [style.transform]="transformRotateStr" [style.width]="widthStr" [style.height]="heightStr" viewBox="0 0 12 12" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6 3V6L8 7M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
            stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <svg *ngSwitchCase="'END-DATE'" class="{{classColor}}" [style.transform]="transformRotateStr" [style.width]="widthStr" [style.height]="heightStr" viewBox="0 0 10 10" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9 3.5V2.5C9 1.94772 8.55228 1.5 8 1.5H2C1.44772 1.5 1 1.94772 1 2.5V8.5C1 9.05228 1.44772 9.5 2 9.5H3.5M6.5 0.5V2.5M3.5 0.5V2.5M1 4.5H3.5M9 6L5.5 9.5M5.5 6L9 9.5"
            stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <svg *ngSwitchCase="'START-DATE'" class="{{classColor}}" [style.transform]="transformRotateStr" [style.width]="widthStr" [style.height]="heightStr" viewBox="0 0 11 11" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9 3.5V2.5C9 1.94772 8.55228 1.5 8 1.5H2C1.44772 1.5 1 1.94772 1 2.5V8.5C1 9.05228 1.44772 9.5 2 9.5H3.5M6.5 0.5V2.5M3.5 0.5V2.5M1 4.5H3.5M5.5 8H9.75M9.75 8L7.75 6M9.75 8L8 9.75"
            stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <svg *ngSwitchCase="'NAME'" class="{{classColor}}" [style.transform]="transformRotateStr" [style.width]="widthStr" [style.height]="heightStr" viewBox="0 0 11 11" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5 1.49999H1.5C1.23478 1.49999 0.98043 1.60535 0.792893 1.79289C0.605357 1.98042 0.5 2.23478 0.5 2.49999V9.49999C0.5 9.76521 0.605357 10.0196 0.792893 10.2071C0.98043 10.3946 1.23478 10.5 1.5 10.5H8.5C8.76522 10.5 9.01957 10.3946 9.20711 10.2071C9.39464 10.0196 9.5 9.76521 9.5 9.49999V5.99999M7.75 1.74999C7.94891 1.55108 8.2187 1.43933 8.5 1.43933C8.7813 1.43933 9.05109 1.55108 9.25 1.74999C9.44891 1.9489 9.56066 2.21869 9.56066 2.49999C9.56066 2.7813 9.44891 3.05108 9.25 3.24999L4.5 7.99999L2.5 8.49999L3 6.49999L7.75 1.74999Z"
            stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <svg *ngSwitchCase="'ERROR'" class="{{classColor}}" [style.transform]="transformRotateStr" [style.width]="widthStr" [style.height]="heightStr" viewBox="0 0 16 16" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.00065 5.33337V8.00004M14.6673 8.00004C14.6673 11.6819 11.6826 14.6667 8.00065 14.6667C4.31875 14.6667 1.33398 11.6819 1.33398 8.00004C1.33398 4.31814 4.31875 1.33337 8.00065 1.33337C11.6826 1.33337 14.6673 4.31814 14.6673 8.00004Z"
            stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M7.99935 11.3333C8.18344 11.3333 8.33268 11.1841 8.33268 11C8.33268 10.8159 8.18344 10.6666 7.99935 10.6666C7.81525 10.6666 7.66602 10.8159 7.66602 11C7.66602 11.1841 7.81525 11.3333 7.99935 11.3333Z"
            stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <svg *ngSwitchCase="'TABLE'" class="{{classColor}}" [style.transform]="transformRotateStr" [style.width]="widthStr" [style.height]="heightStr" viewBox="0 0 10 10" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5 0.5V3.75M5 3.75V6.5M5 3.75H1M5 3.75H9M5 6.5V9.5M5 6.5H9M5 6.5H1M1.5 9.5H8.5C8.77614 9.5 9 9.27614 9 9V1C9 0.723858 8.77614 0.5 8.5 0.5H1.5C1.22386 0.5 1 0.723858 1 1V9C1 9.27614 1.22386 9.5 1.5 9.5Z"
            stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <svg *ngSwitchCase="'CHEVRON'" class="{{classColor}}" [style.transform]="transformRotateStr" [style.width]="widthStr" [style.height]="heightStr" viewBox="0 0 8 4" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path d="M1 0.5L4 3.5L7 0.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <svg *ngSwitchCase="'DRAGDROP'" class="{{classColor}}" [style.transform]="transformRotateStr" [style.width]="widthStr" [style.height]="heightStr" viewBox="0 0 35 28" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M34.1667 14.0003H24.1667L20.8334 19.0003H14.1667L10.8334 14.0003H0.833374M34.1667 14.0003V24.0003C34.1667 24.8844 33.8155 25.7322 33.1904 26.3573C32.5653 26.9825 31.7174 27.3337 30.8334 27.3337H4.16671C3.28265 27.3337 2.43481 26.9825 1.80968 26.3573C1.18456 25.7322 0.833374 24.8844 0.833374 24.0003V14.0003M34.1667 14.0003L28.4167 2.51699C28.1407 1.96164 27.7153 1.49428 27.1883 1.16746C26.6613 0.840639 26.0535 0.667321 25.4334 0.666992H9.56671C8.94657 0.667321 8.33881 0.840639 7.81178 1.16746C7.28475 1.49428 6.85934 1.96164 6.58337 2.51699L0.833374 14.0003"
            stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <svg *ngSwitchCase="'X-AXIS'" class="{{classColor}}" [style.transform]="transformRotateStr" [style.width]="widthStr" [style.height]="heightStr" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 4H7.5M7.5 4L4 0.5M7.5 4L4 7.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <svg *ngSwitchCase="'Y-AXIS'" class="{{classColor}}" [style.transform]="transformRotateStr" [style.width]="widthStr" [style.height]="heightStr" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 7.5V0.5M4 0.5L0.5 4M4 0.5L7.5 4" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <svg *ngSwitchCase="'RELATIONS'" class="{{classColor}}" [style.transform]="transformRotateStr" [style.width]="widthStr" [style.height]="heightStr" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.50039 1.25037V8.00037M1.40039 3.25037L3.50039 1.15039L5.6004 3.25037M10.6004 8.75039L8.5004 10.8504L6.40039 8.75039M8.50039 4.00037V10.7504" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <svg *ngSwitchCase="'FIELD'" class="{{classColor}}" [style.transform]="transformRotateStr" [style.width]="widthStr" [style.height]="heightStr" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.5 1.5H1.5V5H10.5V1.5Z" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.5 7H1.5V10.5H10.5V7Z" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <svg *ngSwitchCase="'EDIT'" class="{{classColor}}" [style.transform]="transformRotateStr" [style.width]="widthStr" [style.height]="heightStr" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.5 1.50015C8.63132 1.36883 8.78722 1.26466 8.9588 1.19359C9.13038 1.12252 9.31428 1.08594 9.5 1.08594C9.68572 1.08594 9.86962 1.12252 10.0412 1.19359C10.2128 1.26466 10.3687 1.36883 10.5 1.50015C10.6313 1.63147 10.7355 1.78737 10.8066 1.95895C10.8776 2.13054 10.9142 2.31443 10.9142 2.50015C10.9142 2.68587 10.8776 2.86977 10.8066 3.04135C10.7355 3.21293 10.6313 3.36883 10.5 3.50015L3.75 10.2501L1 11.0001L1.75 8.25015L8.5 1.50015Z" stroke="#868484" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <svg *ngSwitchCase="'CLOSE'" class="{{classColor}}" [style.transform]="transformRotateStr" [style.width]="widthStr" [style.height]="heightStr" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 1L1 7M1 1L7 7" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <svg *ngSwitchCase="'RESET'" class="{{classColor}}" [style.transform]="transformRotateStr" [style.width]="widthStr" [style.height]="heightStr" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.666992 1.66631V5.66631M0.666992 5.66631H4.66699M0.666992 5.66631L3.76033 2.75964C4.68093 1.84059 5.87529 1.24562 7.16344 1.06438C8.45159 0.883149 9.76375 1.12546 10.9022 1.75482C12.0407 2.38417 12.9438 3.36647 13.4754 4.55371C14.0071 5.74094 14.1385 7.0688 13.8498 8.33721C13.5612 9.60562 12.8681 10.7459 11.8751 11.5861C10.882 12.4264 9.64282 12.9212 8.34414 12.996C7.04545 13.0707 5.75764 12.7214 4.67476 12.0006C3.59188 11.2798 2.77259 10.2266 2.34033 8.99964" stroke="#0097AB" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <svg *ngSwitchCase="'DELETE'" class="{{classColor}}" [style.transform]="transformRotateStr" [style.width]="widthStr" [style.height]="heightStr" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.5 3H2.5H10.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4 3V2C4 1.73478 4.10536 1.48043 4.29289 1.29289C4.48043 1.10536 4.73478 1 5 1H7C7.26522 1 7.51957 1.10536 7.70711 1.29289C7.89464 1.48043 8 1.73478 8 2V3M9.5 3V10C9.5 10.2652 9.39464 10.5196 9.20711 10.7071C9.01957 10.8946 8.76522 11 8.5 11H3.5C3.23478 11 2.98043 10.8946 2.79289 10.7071C2.60536 10.5196 2.5 10.2652 2.5 10V3H9.5Z" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5 5.5V8.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7 5.5V8.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <svg *ngSwitchCase="'FILTER'" class="{{classColor}}" [style.transform]="transformRotateStr" [style.width]="widthStr" [style.height]="heightStr" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 0.5H1L5 5.23V8.5L7 9.5V5.23L11 0.5Z" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <svg *ngSwitchCase="'USER'" class="{{classColor}}" [style.transform]="transformRotateStr" [style.width]="widthStr" [style.height]="heightStr" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 9.5V8.5C9 7.96957 8.78929 7.46086 8.41421 7.08579C8.03914 6.71071 7.53043 6.5 7 6.5H3C2.46957 6.5 1.96086 6.71071 1.58579 7.08579C1.21071 7.46086 1 7.96957 1 8.5V9.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5 4.5C6.10457 4.5 7 3.60457 7 2.5C7 1.39543 6.10457 0.5 5 0.5C3.89543 0.5 3 1.39543 3 2.5C3 3.60457 3.89543 4.5 5 4.5Z" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <svg *ngSwitchCase="'GLOBAL-ERROR'" width="320" height="216" viewBox="0 0 320 216" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M203 61H117V136.689L124.167 129.481L131.333 136.689L138.5 129.481L145.667 136.689L152.833 129.481L160 136.689L167.167 129.481L174.333 136.689L181.5 129.481L188.667 136.689L195.833 129.481L203 136.689V61ZM117 191.954V151.106L124.167 143.898L131.333 151.106L138.5 143.898L145.667 151.106L152.833 143.898L160 151.106L167.167 143.898L174.333 151.106L181.5 143.898L188.667 151.106L195.833 143.898L203 151.106V191.954H117Z" fill="#327DE2" fill-opacity="0.12"/>
        <path d="M63.6481 185.429L77.8323 106.582C78.379 103.543 82.0171 102.243 84.3658 104.248L145.302 156.256C147.641 158.252 146.947 162.031 144.051 163.065L68.9307 189.904C66.0351 190.939 63.1037 188.456 63.6481 185.429Z" fill="#FFB74C"/>
        <path d="M87.4276 135.614C86.3664 133.171 87.5935 130.34 90.1021 129.443V129.443C92.6106 128.547 95.354 129.96 96.0814 132.522L100.577 148.359C101.081 150.135 100.121 151.998 98.3825 152.62V152.62C96.6436 153.241 94.7208 152.408 93.9852 150.714L87.4276 135.614Z" fill="white"/>
        <rect x="97.5002" y="159.904" width="6.56298" height="6.56298" rx="3.28149" transform="rotate(-19.6608 97.5002 159.904)" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M235.586 144.586C236.367 143.805 237.633 143.805 238.414 144.586L244.198 150.37L249.982 144.586C250.763 143.805 252.03 143.805 252.811 144.586C253.592 145.367 253.592 146.633 252.811 147.414L247.027 153.198L252.811 158.982C253.592 159.763 253.592 161.03 252.811 161.811C252.03 162.592 250.763 162.592 249.982 161.811L244.198 156.027L238.414 161.811C237.633 162.592 236.367 162.592 235.586 161.811C234.805 161.03 234.805 159.763 235.586 158.982L241.37 153.198L235.586 147.414C234.805 146.633 234.805 145.367 235.586 144.586Z" fill="#6FA4EB"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M161.586 19.5858C162.367 18.8047 163.633 18.8047 164.414 19.5858L170.198 25.3698L175.982 19.5858C176.763 18.8047 178.03 18.8047 178.811 19.5858C179.592 20.3668 179.592 21.6332 178.811 22.4142L173.027 28.1982L178.811 33.9822C179.592 34.7633 179.592 36.0296 178.811 36.8107C178.03 37.5917 176.763 37.5917 175.982 36.8107L170.198 31.0267L164.414 36.8107C163.633 37.5917 162.367 37.5917 161.586 36.8107C160.805 36.0296 160.805 34.7633 161.586 33.9822L167.37 28.1982L161.586 22.4142C160.805 21.6332 160.805 20.3668 161.586 19.5858Z" fill="#6FA4EB"/>
        <rect width="37.1909" height="4" rx="2" transform="matrix(1 0 -6.50597e-06 1 125 79)" fill="white"/>
        <rect width="37.1909" height="4" rx="2" transform="matrix(1 0 -6.50597e-06 1 125 88.5977)" fill="white"/>
        <rect width="27.8137" height="4" rx="2" transform="matrix(1 0 -6.50597e-06 1 125 98.1953)" fill="white"/>
        <rect width="27" height="4" rx="2" transform="matrix(1 0 -6.50597e-06 1 168 164)" fill="white"/>
        <rect width="27" height="4" rx="2" transform="matrix(1 0 -6.50597e-06 1 168 173.598)" fill="white"/>
        <path d="M173.735 96.1366L214.505 68.758C217.069 67.0364 220.533 68.7456 220.727 71.8274L223.812 120.84C224.005 123.909 220.808 126.039 218.05 124.679L174.196 103.045C171.438 101.684 171.183 97.8508 173.735 96.1366Z" fill="#FFB74C"/>
        <path d="M206.911 85.6305C207.592 83.9806 209.527 83.2514 211.128 84.0411V84.0411C212.729 84.8309 213.328 86.8102 212.433 88.3551L206.906 97.9021C206.285 98.973 204.941 99.3797 203.831 98.8322V98.8322C202.722 98.2848 202.226 96.9706 202.699 95.8268L206.911 85.6305Z" fill="white"/>
        <rect x="199.913" y="101.804" width="4.39813" height="4.39813" rx="2.19907" transform="rotate(26.2579 199.913 101.804)" fill="white"/>
    </svg>

    <svg *ngSwitchDefault class="{{classColor}}" [style.transform]="transformRotateStr" [style.width]="widthStr" [style.height]="heightStr" viewBox="0 0 16 16" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.00065 5.33337V8.00004M14.6673 8.00004C14.6673 11.6819 11.6826 14.6667 8.00065 14.6667C4.31875 14.6667 1.33398 11.6819 1.33398 8.00004C1.33398 4.31814 4.31875 1.33337 8.00065 1.33337C11.6826 1.33337 14.6673 4.31814 14.6673 8.00004Z"
            stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M7.99935 11.3333C8.18344 11.3333 8.33268 11.1841 8.33268 11C8.33268 10.8159 8.18344 10.6666 7.99935 10.6666C7.81525 10.6666 7.66602 10.8159 7.66602 11C7.66602 11.1841 7.81525 11.3333 7.99935 11.3333Z"
            stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</div>
