<ng-container *ngIf="jobsList">
    <ng-container
        *ngTemplateOutlet="!isLoading ? (!jobsList.length ? noData : dataGrid) : loading; context: { data: jobsList, projectId: projectId }"
    ></ng-container>
</ng-container>

<ng-template #dataGrid let-projectId="projectId">
    <app-workflow-dashboard-pipeline-list
        [search]=""
        [jobs]="jobsList"
        [showParentData]="true"
        [projectId]="projectId"
        [workflowDashboard]="workflowDashboard"
        class="card-header-border-reset"
    >
    </app-workflow-dashboard-pipeline-list>
</ng-template>

<ng-template #noData>
    <div class="d-inline-flex justify-content-center align-items-center w-100" style="height: 100px !important; border-top: 1px solid rgba(0, 0, 0, 0.125);">
        <h2 class="mt-0">There are no pipelines yet</h2>
    </div>
</ng-template>

<ng-template #loading>
    <div class="d-inline-flex justify-content-center align-items-center w-100 h-100">
        <lib-spinner></lib-spinner>
    </div>
</ng-template>
