import { Inject, Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { AnyWidget, DashboardWidgetOptions, WidgetToolCategoryType } from 'data-processor';
import { isNaN } from 'lodash';
import { ENV_TOKEN } from '@app/tokens';

export interface AppScoreWidgetData {
    data?: any;
    description?: string;
    name: string;
    hasChart?: boolean;
    release?: any;
    releaseDate?: string;
    startDate?: string;
    overall?: number;
}

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    private basePath = `${this.env.insightApiURL}/insights/dashboards`;
    private deletePath = `${this.env.insightApiURL}/insights/insightdashboard/`;
    private statusPath = `${this.env.insightApiURL}/insights/insightdashboard/status`;
    private userPath = `${this.env.insightApiURL}/insights/UserDashboards`;
    private scopePath = `${this.env.insightApiURL}/insights/scope`;
    private listingPath = `${this.env.insightApiURL}/insights/dashboardList`;
    private dashboardOrderPath = `${this.env.insightApiURL}/insights/updateDashboardOrder`;
    private projectSummaryDashboardPath = `${this.env.insightApiURL}/insights/dashboardByIdentifier/PROJECT_SUMMARY`;
    private groupDashboardPath = `${this.env.insightApiURL}/insights/illuminateDashboard`;
    private saveGroupPath = `${this.env.insightApiURL}/insights/saveIlluminateDashboard`;
    private updateGroupPath = `${this.env.insightApiURL}/insights/updateIlluminateDashboard`;
    private constructorPath = `${this.env.insightApiURL}/constructor`;

    public sideMenuRefresh = new Subject<boolean>();

    _loading = false;

    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {}

    public get loading(): boolean {
        return this._loading;
    }

    public set loading(loading: boolean) {
        this._loading = loading;
    }

    getAllDashboards(params: any): Observable<any> {
        return this.http.get(this.basePath, { params });
    }
    getAllUserDashboards(params: any): Observable<any> {
        return this.http.get(this.userPath, { params });
    }
    deleteDashboard(id: string): Observable<any> {
        return this.http.delete(this.deletePath + id);
    }
    getById(id: string): Observable<any> {
        return this.http.get<any>(`${this.groupDashboardPath}/${id}`);
    }

    updateDashboard(data: any) {
        return this.http.put<any>(this.updateGroupPath, data);
    }
    addDashboard(data: any, isWCDashboard = false) {
        return this.http.post<any>(this.saveGroupPath, data, {
            params: {
                isNew: String(isWCDashboard),
            },
        });
    }
    getGroupDashboard(id: string): Observable<any> {
        return this.http.get<any>(`${this.groupDashboardPath}/${id}`);
    }
    addIlluminateDashboard(data: any) {
        return this.http.post<any>(this.saveGroupPath, data);
    }
    updateIlluminateDashboard(data: any) {
        return this.http.put<any>(this.updateGroupPath, data);
    }
    markFavourite(id: string, status: any) {
        return this.http.put<any>(this.statusPath + `/${id}?status=${status}`, []);
    }
    getScope() {
        return this.http.get(this.scopePath);
    }
    getDashboardListing(type: any) {
        return this.http.get(this.listingPath + `?type=${type}`);
    }
    updateDashboardOrder(list: any) {
        return this.http.put<any>(this.dashboardOrderPath, list);
    }
    getProjectSummaryDashboard() {
        return this.http.get(this.projectSummaryDashboardPath);
    }
    getPIReleaseFilter(projectId: any, startDate: any, endDate: any) {
        return this.http.get<any>(
            `${this.env.insightApiURL}/reports/releasesForProject?projectId=${projectId}&startDate=${startDate}&endDate=${endDate}`
        );
    }
    getPIFeatureCompletion(data: any) {
        return this.http.post<any>(`${this.env.insightApiURL}/reports/piFeatureCompletionForProject`, data);
    }
    getWidgetDashboard(dashboardId: number, widgetId: number): Observable<AnyWidget> {
        return this.http.get<AnyWidget>(`${this.constructorPath}/getWidgetByDashboard/${dashboardId}/${widgetId}`);
    }

    updateDashboardWidget(data: DashboardAnyWidget): Observable<void> {
        return this.http.post<void>(`${this.constructorPath}/updateWidgetOnDashboard`, data);
    }

    saveWidgetOnDashboard(data: DashboardAnyWidget): Observable<DashboardAnyWidget> {
        return this.http.put<DashboardAnyWidget>(`${this.constructorPath}/saveWidgetOnDashboard`, data);
    }

    getAllWidgetsByDashboard(dashboardId: string): Observable<AnyWidget[]> {
        return isNaN(dashboardId) ? of([]) : this.http.get<AnyWidget[]>(`${this.constructorPath}/getWidgetsByDashboard/${dashboardId}`);
    }

    deleteWidget(dashboardId: number, widgetId: number): Observable<void> {
        return this.http.delete<void>(`${this.constructorPath}/deleteWidgetFromDashboard/${dashboardId}/${widgetId}`);
    }

    getProjectsByIds(toolCategory: WidgetToolCategoryType, projectIds: number[]): Observable<AppUnits[]> {
        const params: Record<string, any> = {};

        if (toolCategory) {
            params.toolCategory = toolCategory;
        }

        return this.http.post<AppUnits[]>(`${this.constructorPath}/getProjectsByIds`, projectIds, {
            params,
        });
    }

    getTeamsByIds(teamIds: number[]): Observable<string[]> {
        return this.http.post<string[]>(`${this.constructorPath}/getTeamsByIds`, teamIds);
    }
}

export interface AppUnits {
    project_id: number;
    projectId?: string;
    units: Unit[];
}

interface Unit {
    unit_id: number;
    unitId: string;
}

export interface DashboardAnyWidget {
    dashboardId: number;
    uuid: string;
    widgetId: number;
    groupId: number;
    options?: DashboardWidgetOptions;
}
