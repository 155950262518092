import { BehaviorSubject } from 'rxjs';

export interface Sort {
    field: string;
    direction: 'ASC' | 'DESC';
}

export function sortGridColumn(index: number, grid: any[], behavior$: BehaviorSubject<any>) {
    if (!grid[index].sortingField) {
        return;
    }
    for (let i = 0; i < grid.length; i++) {
        if (i === index) {
            grid[index].sort = grid[index].sort === 'ASC' ? 'DESC' : grid[index].sort === 'DESC' ? '' : 'ASC';
        } else {
            grid[i].sort = '';
        }
    }

    behavior$.next({
        field: grid[index].sortingField,
        direction: grid[index].sort as 'ASC' | 'DESC',
    });
}

export function sortArrayByFieldAndDirection(array: any[], field: string, dir: string) {
    return [...array].sort((a, b) => {
        if (dir !== '') {
            if (a[field] === null) {
                return 1;
            }

            if (b[field] === null) {
                return -1;
            }

            if (typeof a[field] === 'string' && typeof b[field] === 'string') {
                if (a[field].localeCompare(b[field], undefined, { sensitivity: 'base' }) > 0) {
                    return dir === 'ASC' ? 1 : -1;
                }
                if (a[field].localeCompare(b[field], undefined, { sensitivity: 'base' }) < 0) {
                    return dir === 'ASC' ? -1 : 1;
                }
            } else {
                if (a[field] > b[field]) {
                    return dir === 'ASC' ? 1 : -1;
                }
                if (a[field] < b[field]) {
                    return dir === 'ASC' ? -1 : 1;
                }
            }
        }

        return 0;
    });
}
