import { NgModule } from '@angular/core';
import { UiKitModule } from '@dagility-ui/kit';
import { CommonModule } from '@angular/common';
import { IframeWrapperComponent } from '@app/shared/components/iframe-wrapper/iframe-wrapper.component';

@NgModule({
    imports: [UiKitModule, CommonModule],
    declarations: [IframeWrapperComponent],
    exports: [IframeWrapperComponent]
})
export class IframeWrapperModule {}
