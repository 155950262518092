<ng-container *ngFor="let model of models">
    <div class="model">
        <div class="model-header">
            <app-icon-remoter [width]="11" [height]="11" [iconName]="'TABLE'"></app-icon-remoter>
            <span>{{ model.name }}</span>
        </div>
        <div class="container">
            <div class="item">
                <div class="header">
                    <app-icon-remoter [color]="'SECONDARY-TEXT-ICONS'" [width]="12" [height]="12" [iconName]="'FIELD'"></app-icon-remoter>
                    <span>Fields</span>
                </div>
                <div class="fields">
                    <ng-container *ngFor="let field of dataSetFields">
                        <div
                            [class.disabled]="field.roles.length > 0"
                            [draggable]="field.roles.length === 0"
                            *ngIf="model.uid === field.srcModelUid && datasetFieldSelected(model, field)"
                            class="field"
                            (dragstart)="dragStart($event, field)"
                        >
                            <app-icon-remoter *ngIf="fieldHasFieldRoleType(field, 'PK')" [color]="'WHITE'" [width]="15" [height]="15" [iconName]="'KEY'"></app-icon-remoter>
                            <span [ngStyle]="{'margin-left': fieldHasFieldRoleType(field, 'PK') ? '4px' : '19px'}">{{ field.dsFieldName }}</span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="calculatedFields.length > 0">
    <div class="model">
        <div class="model-header">
            <img src="{{ icons.field }}" alt=""/>
            <span>Calculated fields</span>
        </div>
        <div class="container">
            <div class="item">
                <div class="no-header"></div>
                <div class="fields">
                    <ng-container *ngFor="let field of calculatedFields">
                        <div class="field"
                             [class.disabled]="field.roles.length > 0"
                             [draggable]="field.roles.length === 0"
                             (dragstart)="dragStart($event, field)"
                        >
                            <span>{{ field.dsFieldName }}</span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-container>

