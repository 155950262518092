<div class="list-log-view" [style.width.vw]="95">
    <div class="modal-header">
        <div class="d-block">
            <h6 class="font-14px header-text mb-0">{{ title }}</h6>
            <span *ngIf="lastExecutedTime">
                Execution Date: <strong> {{ lastExecutedTime | date: 'lastDateTimeCustom' }}</strong>
            </span>
        </div>
        <div>
            <fa-icon class="fa zoom" icon="{{ isExpanded ? 'facCompress' : 'facExpand' }}" (click)="isExpanded = !isExpanded"> </fa-icon>
            <button type="button" class="close" (click)="modal.dismiss()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>

    <div class="modal-body">
        <ng-container *ngIf="this.loaded; else loadingTmpl">
            <ng-container *ngIf="this.currentLogs.length != 0; else notFoundTmpl">
                <nav ngbNav #nav="ngbNav" class="nav nav-tabs justify-content-start" (navChange)="tabChange($event)">
                    <ng-container ngbNavItem="logs">
                        <a ngbNavLink data-test-id="workflow-logs">Logs</a>
                        <ng-template ngbNavContent>
                            <perfect-scrollbar [config]="perfectScrollbarConfig">
                                <div class="row mb-7px no-gutters py-1" [formGroup]="formGroup" [style.minWidth.px]="width">
                                    <lib-search
                                        class="col mr-3 mr-3"
                                        (searchText)="searchTerm = $event"
                                        [placeholder]="'Search by log text'"
                                        [value]="searchTerm"
                                    >
                                    </lib-search>
                                    <lib-dropdown
                                        class="col mr-3"
                                        placeholder="Select Type"
                                        [items]="objectTypes"
                                        [searchable]="true"
                                        [multiple]="true"
                                        clearable="false"
                                        id="types"
                                        [(value)]="filterTypes"
                                    >
                                    </lib-dropdown>
                                    <lib-datepicker
                                        class="col mr-3"
                                        placeholder="Start date/time"
                                        formControlName="dateStart"
                                        name="dateStart"
                                        [dateString]="defaultDateString"
                                        [height]="'28px'"
                                        [dateWithTime]="true"
                                    >
                                    </lib-datepicker>
                                    <lib-datepicker
                                        class="col mr-3"
                                        placeholder="End date/time"
                                        formControlName="dateEnd"
                                        name="dateEnd"
                                        [dateString]="defaultDateString"
                                        [height]="'28px'"
                                        [dateWithTime]="true"
                                    >
                                    </lib-datepicker>
                                    <div class="col mr-2 d-flex justify-content-end">
                                        <button class="btn btn-primary px-4" (click)="applyFilters()">Apply</button>
                                        <button class="btn btn-secondary px-4 ml-2" (click)="resetFilters()">Reset</button>
                                    </div>
                                </div>

                                <div
                                    class="log-list pt-0 pb-0 flex-1 d-flex flex-column"
                                    [style.height.px]="height - 160"
                                    [style.minWidth.px]="width"
                                >
                                    <lib-data-grid
                                        class="d-block mr-20px"
                                        [wheelUsing]="true"
                                        [bodyHeight]="gridHeight"
                                        [dataSource]="this.filteredLogs"
                                        [columns]="logGridColumns"
                                        [sticky]="true"
                                    >
                                        <ng-template headerTemplate>
                                            <tr>
                                                <th
                                                    class="table-header-cell"
                                                    *ngFor="let c of logGridColumns"
                                                    [style.width]="c.width"
                                                    scope="col"
                                                >
                                                    {{ c.title }}
                                                </th>
                                            </tr>
                                        </ng-template>
                                        <ng-template rowTemplate let-log="item" let-i="index">
                                            <tr
                                                class="log-list-item"
                                                [ngClass]="{ selected: i === selectedItem, 'has-log-link': isPipelineLogLink(log.text) }"
                                                (click)="openExecutionLog(log.text, i)"
                                            >
                                                <td style="width: 22%" class="text-truncate">
                                                    {{ log['moment'] | date: 'dateTimeCustom' }}
                                                </td>
                                                <td style="width: 8%" [ngClass]="markErrorLog(log, 'level')">{{ log.level }}</td>
                                                <td style="width: 70%">
                                                    {{ parseLogText(log.text) }}
                                                    <fa-icon
                                                        *ngIf="isPipelineLogLink(log.text)"
                                                        class="mr-10px pull-right"
                                                        icon="facLogs"
                                                    ></fa-icon>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </lib-data-grid>
                                    <div
                                        *ngIf="executionLogs$"
                                        class="execution-log-container"
                                        [ngClass]="{ expanded: isExecutionLogsExpanded }"
                                        [style.height.px]="executionLogHeight + 100"
                                    >
                                        <fa-icon
                                            class="fa zoom pull-right mr-8px"
                                            icon="{{ isExecutionLogsExpanded ? 'facCompress' : 'facExpand' }}"
                                            (click)="isExecutionLogsExpanded = !isExecutionLogsExpanded"
                                        >
                                        </fa-icon>
                                        <lib-log [logs$]="executionLogs$" [height]="executionLogHeight"></lib-log>
                                    </div>
                                </div>
                            </perfect-scrollbar>
                        </ng-template>
                    </ng-container>

                    <ng-container *ngIf="runtimeVariables$ | async as variable" ngbNavItem="runtime-variables">
                        <a ngbNavLink data-test-id="workflow-runtime-variables">Runtime Variables</a>
                        <ng-template ngbNavContent>
                            <ngb-accordion
                                *ngIf="variable.length; else noVariables"
                                [closeOthers]="true"
                                class="custom-accordion"
                                #accordion="ngbAccordion"
                            >
                                <ngb-panel *ngFor="let pipeline of variable; let i = index" [id]="'panel_' + i">
                                    <ng-template ngbPanelHeader>
                                        <div class="d-flex align-items-center">
                                            <button [ngbPanelToggle] class="header-button btn btn-link"></button>
                                            <div class="col cursor-pointer" (click)="accordion.toggle('panel_' + i)">
                                                <div class="h3 mb-0" data-test-id="pipeline-name">{{ pipeline.pipelineName || '—' }}</div>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <lib-data-grid
                                            [dataSource]="pipeline.variables"
                                            [columns]="gridColumns"
                                            [sticky]="true"
                                            [usePerfectScrollbar]="true"
                                            [bodyHeight]="'250px'"
                                        >
                                            <ng-template headerTemplate>
                                                <tr>
                                                    <th
                                                        *ngFor="let c of gridColumns; let j = index"
                                                        [ngStyle]="{ width: c.width }"
                                                        (click)="sortClick(j)"
                                                        class="over-me"
                                                    >
                                                        <div
                                                            [class.blue]="c.sort"
                                                            [class.cursor-pointer]="c.sortingField"
                                                            [class.cursor-default]="!c.sortingField"
                                                            class="text-truncate body2 d-inline-flex align-items-center column-header"
                                                            [ngbTooltip]="c.title"
                                                            container="body"
                                                        >
                                                            {{ c.title }}
                                                            <span
                                                                *ngIf="c.sortingField"
                                                                [innerHTML]="
                                                                    (c.sort !== 'ASC' ? icons.sortingUp : icons.sortingDown)
                                                                        | safeHtml: 'html'
                                                                "
                                                                class="fa sort-icon ml-5px"
                                                            >
                                                            </span>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </ng-template>

                                            <ng-template rowTemplate let-sourceItem="item" let-i="index">
                                                <td [ngStyle]="{ width: gridColumns[0].width }" class="cursor-default over-me">
                                                    <div
                                                        class="d-inline-flex align-items-center"
                                                        attr.data-test-id="{{ 'variable-name-' + i }}"
                                                    >
                                                        {{ sourceItem.variableName || '—' }}
                                                    </div>
                                                </td>
                                                <td [ngStyle]="{ width: gridColumns[1].width }" class="cursor-default over-me">
                                                    <div
                                                        attr.data-test-id="{{ 'variable-value-' + i }}"
                                                        [innerText]="sourceItem.value || '—'"
                                                    ></div>
                                                </td>
                                            </ng-template>
                                        </lib-data-grid>
                                    </ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                        </ng-template>
                    </ng-container>
                </nav>
                <div [ngbNavOutlet]="nav"></div>
            </ng-container>
            <ng-template #notFoundTmpl>
                <div class="logs-not-found-wrapper d-flex justify-content-center flex-column align-items-center">
                    <div class="logs-not-found-image"></div>
                    <span class="not-found-text">
                        Looks like workflow doesn't have any logs yet
                    </span>
                </div>
            </ng-template>
            <ng-template #noVariables>
                <h3 class="text-center p-4">There are no Runtime Variables.</h3>
            </ng-template>
        </ng-container>
    </div>

    <ng-template #loadingTmpl>
        <div class="spinner-wrapper d-flex justify-content-center align-items-center">
            <lib-spinner></lib-spinner>
        </div>
    </ng-template>

    <div class="modal-footer">
        <button *ngIf="tabValue === 'logs'" type="button" class="btn btn-primary form-button ml-auto font-11px" (click)="exportToFile()">
            Export logs to file
        </button>
        <button type="button" class="btn btn-secondary ml-25px font-11px" (click)="modal.close()">
            Cancel
        </button>
    </div>
</div>
