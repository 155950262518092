import { Deserializable } from '../../../models/job-definition';
import { Operand } from './operand';

export class ChannelAction extends Deserializable {
    _arrays = {};
    _class = {
        operand: Operand,
    };
    _enums = {};
    _maps = {
        fields: Operand,
    };
    meta = {
        _arrays: this._arrays,
        _class: this._class,
        _enums: this._enums,
        _maps: this._maps,
    };

    channel = '';
    fields: Map<string, Operand> = {} as any;
    broadcast = false;

    constructor(instance?: any) {
        super();
        this.fillByInstance(ChannelAction, instance);
    }
}
