<div class="d-flex align-items-center mx-4 my-3">
    <h2 class="mt-0">Help</h2>
    <button type="button" class="close" (click)="close.emit()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<perfect-scrollbar>
    <div data-test-id="summary" class="help-text mx-4 mb-4" [innerHTML]="help.summary | safeHtml: 'html'" (click)="handleContentClick($event)"></div>
    <ngb-accordion
        class="custom-accordion context-help-accordion"
        [closeOthers]="true"
        [animation]="false"
        (panelChange)="handleAccordionPanelChange($event)"
    >
        <ng-container *ngFor="let panel of panels">
            <ngb-panel *ngIf="help[panel.field]">
                <ng-template ngbPanelHeader>
                    <button [ngbPanelToggle] class="header-button btn btn-link"></button>
                    <span [attr.data-test-id]="testIdMap[panel.field]" class="accordion-header">{{ panel.header }}</span>
                </ng-template>
                <ng-template ngbPanelContent>
                    <span class="help-text" [innerHTML]="$any(help[panel.field]) | safeHtml: 'html'" (click)="handleContentClick($event)"></span>
                </ng-template>
            </ngb-panel>
        </ng-container>

        <ngb-panel *ngIf="showInsights">
            <ng-template ngbPanelHeader>
                <button data-test-id="insight-toggle-button" [ngbPanelToggle] class="header-button btn btn-link"></button>

                <span class="accordion-header">Insights and Recommendations</span>
            </ng-template>
            <ng-template ngbPanelContent>
                <div data-test-id="insight" *ngFor="let insight of help.insights">
                    <span class="insight-header d-block">Insight</span>
                    <span data-test-id="insight-insight" class="help-text" [innerHTML]="insight.insight | safeHtml: 'html'" (click)="handleContentClick($event)"></span>
                    <ng-container *ngFor="let recommendation of insight.recommendations">
                        <span class="insight-header">Recommendation</span>
                        <span data-test-id="insight-rec" class="help-text" [innerHTML]="recommendation | safeHtml: 'html'" (click)="handleContentClick($event)"></span>
                    </ng-container>
                </div>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</perfect-scrollbar>
