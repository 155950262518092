export enum JobDefinitionDataType {
    HTTP = 'HTTP',
    CHANNEL = 'CHANNEL',
    ELASTICSEARCH = 'ELASTICSEARCH',
    POSTGRESQL = 'POSTGRESQL',
    CLICKHOUSE = 'CLICKHOUSE',
    VARIABLE = 'VARIABLE',
    MONGODB = 'MONGODB',
    JDBC = 'JDBC',
}
