//#region external modules
import { ModuleWithProviders, NgModule } from '@angular/core';
//#region shared app modules
import { EnvironmentModel, PagerService, UiKitModule } from '@dagility-ui/kit';
import { DragDirectiveModule } from './directives/drag.directive';
import { SvgZoomDirectiveModule } from './directives/svg-zoom.directive';
import { AgGridTemplateModule } from './modules/ag-grid/ag-grid-template.module';
import { CodemirrorEditorModule } from './modules/codemirror/codemirror-editor.module';
import { PropertiesEditorModule } from './modules/properties-editor/properties-editor.module';
import { SvgBuilderModule } from './modules/svg-builder/svg-builder.module';
import { ChartsModule } from './modules/charts/charts.module';
import { IconsModule } from '@dagility-ui/shared-components/icons';
import { PropertiesCompletionModule } from './directives/properties-completion.directive';
//#region misc
//#region directives
//#region pipes
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { ImagePathPipe, ImagePathPipeModule } from './pipes/image-path.pipe';
import { SearchPipe } from './pipes/search-data.pipe';
import { ThousandSuffixesPipe } from './pipes/thousand-format.pipe';
import { MapperPipeModule } from './pipes/mapper.pipe';
import { AutoClosePopupDirective } from './directives/auto-close-popup.directive';

//#region components
import { MandatoryComponent } from './components/mandatory/mandatory.component';
import { ZoomControlComponent } from './components/zoom-control/zoom-control.component';
import { ToolChoosingComponent } from './components/tool-choosing/tool-choosing.component';
import { LogComponent } from './components/log-modal/log/log.component';
import { LogModalComponent } from './components/log-modal/log-modal.component';
import { WidgetMessageComponent } from './components/widget-message/widget-message.component';

//#region global services
// if any of services below is not supposed to be used globally
// then please consider moving this service into dedicated module
import { ProjectService } from './services/project.service';
import { ToolService } from './services/tool.service';
import { ToolTemplateService } from './services/tool-template.service';
import { InsightLoaderModule } from './components/insight-loader/insight-loader.component';
import { DagilityToastrComponent } from './components/dagility-toastr/dagility-toastr.component';
import { ToasterService } from './services/Toaster/toaster.service';
import { PropertyService } from './modules/fields-grid/component/fields-grid/api/property.service';
import { ContextHelpModule } from './modules/context-help/context-help.module';
import { CircleProgressComponent, CircleProgressOptions } from './components/circle-progress/circle-progress.component';
import { TourModule } from './modules/tour/tour.module';
import { InteractiveTourModule } from './modules/interactive-tour/interactive-tour.module';
import { CustomPatternsValidatorDirectiveModule } from './directives/custom-patterns-validator.directive';
import { LazyLoadingScriptService } from './services/lazy-load-script.service';
import { DagilityToastrExpireComponent } from './components/dagility-toastr/dagility-toastr-expire.component';
import { UserPicModule } from './components/user-pic/user-pic.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { DagilityCustomToastrComponent } from './components/dagility-toastr/dagility-custom-toastr.component';
import { TextHighlightPipe } from './pipes/text-highlighter.pipe';

//#region models

const modules = [
    UiKitModule,
    InsightLoaderModule,
    DragDirectiveModule,
    SvgZoomDirectiveModule,
    IconsModule,
    UserPicModule,
    ImagePathPipeModule,
    PropertiesCompletionModule,
    ContextHelpModule,
    CustomPatternsValidatorDirectiveModule,
    MapperPipeModule,
];
const components = [
    MandatoryComponent,
    ZoomControlComponent,
    ToolChoosingComponent,
    LogComponent,
    LogModalComponent,
    WidgetMessageComponent,
    DagilityToastrComponent,
    DagilityToastrExpireComponent,
    DagilityCustomToastrComponent,
    CircleProgressComponent,
    AutoClosePopupDirective,
    StepperComponent,
];
const pipes = [CustomDatePipe, SearchPipe, ThousandSuffixesPipe, TextHighlightPipe];

const providers = [
    ImagePathPipe,
    PagerService,
    ProjectService,
    ToolService,
    ToolTemplateService,
    ToasterService,
    PropertyService,
    CircleProgressOptions,
    LazyLoadingScriptService,
];

@NgModule({
    declarations: [...components, ...pipes],
    imports: [...modules],
    exports: [
        ...modules,
        ...components,
        ...pipes,
        AgGridTemplateModule,
        CodemirrorEditorModule,
        PropertiesEditorModule,
        SvgBuilderModule,
        ChartsModule,
        TourModule,
        InteractiveTourModule,
    ],
    providers: [...providers]
})
export class SharedComponentsModule {
    static forRoot(environment: EnvironmentModel): ModuleWithProviders<SharedComponentsModule> {
        return {
            ngModule: SharedComponentsModule,
            providers: [
                ...providers,
                {
                    provide: 'environment',
                    useValue: environment,
                },
            ],
        };
    }
}

export { SearchPipe, ImagePathPipe };
