import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, SkipSelf, TemplateRef } from '@angular/core';
import { getFieldTypes } from '../utils';
import { EditJobDefinitionService } from '../edit-job-definition.service';
import { Subscription } from 'rxjs';
import { FIELD_NAMES, WARNING_NAMES } from '../field-names';
import { AbstractJobDefinitionTemplate } from '../abstract-job-definition-template';
import { Deserializable, HIDDEN_FIELDS, JobDefinitionRunningType } from '../models/job-definition';
import { Min } from '../models/validate.decorator';
import { FieldTypes, JobDefinitionSetup } from '../models/common';

@Component({
    selector: 'dp-edit-job-definition-setup',
    templateUrl: './edit-job-definition-setup.component.html',
    styleUrls: ['../templates.scss'],
    host: {
        class: 'd-flex flex-column p-0',
    },
    providers: [
        {
            provide: FIELD_NAMES,
            deps: [[new SkipSelf(), FIELD_NAMES]],
            useFactory: (fieldNames: Record<string, string>) => ({
                ...fieldNames,
                timeout: 'Restart Frequency (minutes)',
            }),
        },
    ],
})
export class EditJobDefinitionSetupComponent extends AbstractJobDefinitionTemplate implements OnInit, OnChanges, OnDestroy {
    @Input() definition: JobDefinitionSetup;
    @Input() newJob = false;

    fieldTypes: FieldTypes;

    private mergeObject$: Subscription;
    private runningTypeValidation$: Subscription;

    contextHelpAnchors: Record<string, string> = {
        'Instance Name': 'instance-name',
        [this.fieldNames.timeout]: 'restart-frequency',
    };

    constructor(
        @Inject(FIELD_NAMES) private fieldNames: any,
        @Inject(WARNING_NAMES) public warnings: any,
        public expandedControl: EditJobDefinitionService,
        @Inject('templateProvider') public templateProvider: Record<string, TemplateRef<any>>
    ) {
        super();
    }

    ngOnChanges() {
        this.fieldTypes = getFieldTypes(this.definition, this.meta, [...HIDDEN_FIELDS, ...Deserializable.METAFIELDS], this.fieldNames);

        if (this.mergeObject$) {
            this.mergeObject$.unsubscribe();
        }

        this.mergeObject$ = this.formChanges(this.fieldTypes).subscribe(({ key, value }) => {
            (this.definition as Record<string, any>)[key] = value;
            const typingErrors = this.expandedControl.jd.typingErrors;

            if (value && typingErrors && typingErrors[key]) {
                delete typingErrors[key];
            }
        });
    }

    ngOnInit() {
        const timeoutControl = this.formSlice.get('timeout');
        const runningTypeControl = this.formSlice.get('runningType');
        if (runningTypeControl.value == JobDefinitionRunningType.SCHEDULE) {
            timeoutControl.setValidators(Min(1));
        }
        this.runningTypeValidation$ = runningTypeControl.valueChanges.subscribe((runningType: JobDefinitionRunningType) => {
            if (runningType === JobDefinitionRunningType.SCHEDULE) {
                timeoutControl.setValidators(Min(1));
            } else {
                timeoutControl.setValidators(null);
            }
            timeoutControl.updateValueAndValidity();
        });
    }

    ngOnDestroy() {
        if (this.mergeObject$) {
            this.mergeObject$.unsubscribe();
        }
        if (this.runningTypeValidation$) {
            this.runningTypeValidation$.unsubscribe();
        }
    }
}
