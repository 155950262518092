import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
    ExecutionResult,
    WidgetConfigRequest,
    WidgetDataExecutionRequest,
} from '@app/shared/components/query-builder/models/query-builder-widget.model';
import { ENV_TOKEN } from '@app/tokens';
import {GraphMutationResult} from "@app/shared/components/query-builder/models/model-graph-actions.model";
import {AnyWidget} from "data-processor";

@Injectable({
    providedIn: 'root',
})
export class QueryBuilderWidgetService {
    private readonly baseUrl = `${this.env.querybuilderApiURL}/qb/graph`;

    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {}

    getWidgetDataConfigNew(request: WidgetConfigRequest): Observable<GraphMutationResult> {
        return this.http.post<GraphMutationResult>(`${this.baseUrl}/widget-data-config-new`, request);
    }

    saveWidgetDataConfig(request: WidgetConfigRequest): Observable<AnyWidget> {
        return this.http.post<AnyWidget>(`${this.baseUrl}/save-data-config`, request);
    }

    getWidgetDataByGraphId(request: WidgetDataExecutionRequest): Observable<ExecutionResult> {
        return this.http.post<ExecutionResult>(`${this.baseUrl}/widget-data`, request);
    }
}
