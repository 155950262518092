<perfect-scrollbar *libLet="tableHeight$ | async as tableHeight">
    <ngb-accordion class="custom-accordion">
        <ngb-panel *ngFor="let accordionRow of options.items; index as index">
            <ng-template ngbPanelHeader>
                <div class="d-inline-flex align-items-center">
                    <button [ngbPanelToggle] class="header-button btn btn-link col"></button>
                    <span *ngIf="accordionRow.header" class="col">{{ accordionRow.header }}</span>
                    <span
                        *ngIf="!accordionRow.header"
                        [innerHTML]="options.headerTemplate | dpWidgetTemplate: placeholders:{ index: index }"
                    ></span>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <lib-ag-grid
                    style="flex: unset"
                    class="d-block w-100"
                    [style.height]="tableHeight"
                    gridHeight="100%"
                    [rowBuffer]="1000"
                    [tableData]="accordionRow.table.tableData"
                    [pinnedBottomRowData]="accordionRow.table.tableData.pinnedBottomRowData"
                    (cellClicked)="handleDrilldownEvent($event, index)"
                ></lib-ag-grid>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</perfect-scrollbar>
