<div
    [style]="actionsMenuVisible ? 'grid-template-rows: 1fr 53px' : 'grid-template-rows: 1fr 365px'"
    [class.model-grid-tree-hidden]="!modelTreeVisible"
    [class.model-grid-tree-visible]="modelTreeVisible"
    [class.model-grid-actions-menu-visible]="actionsMenuVisible"
    [class.model-grid-actions-menu-hidden]="!actionsMenuVisible"
    class="model-grid">
    <div class="model-graph-container" [class.tree-visible]="modelTreeVisible" [class.tree-hidden]="!modelTreeVisible" style="grid-row: 1 / 3">
        <button class="btn btn-primary tree-button"
                [class.tree-button-visible]="modelTreeVisible"
                [class.tree-button-hidden]="!modelTreeVisible"
                (click)="modelTreeVisible = !modelTreeVisible">
            <svg [style]="modelTreeVisible ? 'transform: rotate(0deg);' : 'transform: rotate(180deg);'" width="8"
                 height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.5 5.5L1 3L3.5 0.5M7 5.5L4.5 3L7 0.5" stroke="white" stroke-linecap="round"
                      stroke-linejoin="round"/>
            </svg>
        </button>
        <ng-container *ngIf="modelTreeVisible; else modelTreeHidden">
            <lib-search style="width: 90%;" class="mb-10px" (searchText)="searchByTreeView($event)"></lib-search>
            <div class="d-flex justify-content-start" style="width: 90%;">
                <trigger class="size-s mb-10px" [(ngModel)]="compatibleOnlyMode">Compatible only</trigger>
            </div>
            <perfect-scrollbar>
                <app-qb-model-graph-tree (deleteCustomModel)="deleteCustomModel.emit($event)" [search]="searchTreeStr"
                                         [root]="rootTreeNode"></app-qb-model-graph-tree>
            </perfect-scrollbar>
        </ng-container>
        <ng-template #modelTreeHidden>
            <div (click)="modelTreeVisible = true" class="hidden-menu">
                <div class="hidden-menu-item">
                    Data Models
                </div>
            </div>
        </ng-template>
    </div>
    <div>
        <app-qb-model-editor
            [rootModels]="rootModels"
            [rootModelTree]="treeModels"
            [compatibleOnlyMode]="compatibleOnlyMode"
            (openRelationEditor)="openEditorForm($event)"
            (closeRelationEditor)="closeEditorForm()"
            (addRootModel)="addRootModel.emit($event)"
        >
        </app-qb-model-editor>
    </div>
    <div style="overflow: hidden; background-color: #FFF; z-index: 1000;">
        <app-qb-model-actions
            (panelTabClick)="actionsMenuVisible = false"
            [hidden]="actionsMenuVisible"
            [isEditorForm]="isEditorForm"
            (openRelationEditor)="openEditorForm($event)"
            (closeRelationEditor)="closeEditorForm()"
        >
        </app-qb-model-actions>
        <fa-icon
            class="hideButton"
            [ngClass]="{ buttonHidden: actionsMenuVisible, buttonOpened: !actionsMenuVisible }"
            [icon]="actionsMenuVisible ? 'chevron-up' : 'chevron-down'"
            (click)="hideTable()"
        ></fa-icon>
    </div>
</div>
<app-relation-editor
    *ngIf="isEditorForm"
    (close)="closeEditorForm()"
    [rootModels]="rootModels"
    [relation]="relation"
>
</app-relation-editor>
