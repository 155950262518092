<div class="d-inline-flex w-100 align-items-center" [ngClass]="showTabs ? 'px-4 pt-4' : 'mb-4 pl-1'">
    <h2 class="mt-0">{{ header }}</h2>

    <ng-container *ngIf="!!formComponent?.getHelpTemplate && (block | mapper: formComponent?.getHelpTemplate) as help">
        <div [ngbTooltip]="help" triggers="click" autoClose="outside" placement="bottom" tooltipClass="wc-context-tooltip">
            <fa-icon class="fa-icon ml-10px context-help-icon" icon="QuestionInCircle"></fa-icon>
        </div>
    </ng-container>
    <button type="button" class="close ml-auto" (click)="handleCancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<lib-nav
    *ngIf="showTabs; else widgetForm"
    [destroyOnHide]="false"
    (navChange)="handleTabChange($event)"
    class="widget-nav flex-1 overflow-hidden"
    [class.remove-paddings]="activeTab === 'help'"
>
    <ng-template navItem [navItemName]="widgetTabHeader">
        <ng-template [ngTemplateOutlet]="widgetForm"></ng-template>
    </ng-template>
    <ng-container *ngIf="extensionsEnabled">
        <ng-container *ngFor="let extension of extensions">
            <ng-template navItem [navItemName]="extension.formName" [navItemId]="extension.formName">
                <ng-container *ngIf="seenTabs[extension.formName]">
                    <ng-container *dpComponentOutlet="extension.form; context: getContext(extension)"></ng-container>
                </ng-container>
            </ng-template>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="showContextHelp">
        <ng-template navItem navItemName="Help" navItemId="help">
            <dp-widget-builder-help-form
                [layerId]="block.id"
                [data]="block.data"
                (save)="handleSaveHelp($event)"
                (preview)="handlePreview($event)"
            ></dp-widget-builder-help-form>
        </ng-template>
    </ng-container>
</lib-nav>

<ng-template #widgetForm>
    <div class="d-flex flex-column overflow-hidden flex-1">
        <perfect-scrollbar>
            <div class="pl-1">
                <ng-container [ngSwitch]="block.viewType">
                    <dp-widget-builder-form class="d-block pr-3" *ngSwitchCase="'layer'" [data]="block"></dp-widget-builder-form>
                    <dp-widget-builder-filters
                        class="d-block pr-3"
                        *ngSwitchCase="'filter'"
                        [data]="block.data"
                        [placeholders]="placeholders"
                        (recalculatePlaceholders)="recalculatePlaceholders()"
                        [filtersFormConfig]="filtersFormConfig"
                        (formOnChange)="changeFiltersForm($event)"
                    ></dp-widget-builder-filters>
                    <ng-container *ngSwitchCase="'query'">
                        <dp-widget-builder-async-query
                            *ngIf="isAsyncQuery(block); else queryTemplate"
                            class="d-block pr-3"
                            [data]="block.data"
                        ></dp-widget-builder-async-query>
                        <ng-template #queryTemplate>
                            <dp-widget-builder-query class="d-block pr-3" [data]="block.data"></dp-widget-builder-query>
                        </ng-template>
                    </ng-container>
                    <dp-widget-builder-filters
                        class="d-block pr-3"
                        *ngSwitchCase="'filter-dynamic'"
                        [data]="block.data"
                        [placeholders]="placeholders"
                        (recalculatePlaceholders)="recalculatePlaceholders()"
                    ></dp-widget-builder-filters>
                    <dp-widget-builder-event-handler
                        class="d-block pr-3"
                        *ngSwitchCase="'handler'"
                        [data]="block"
                    ></dp-widget-builder-event-handler>
                    <dp-widget-builder-drilldown class="d-block pr-3" *ngSwitchDefault [data]="block.data"></dp-widget-builder-drilldown>
                </ng-container>
            </div>
        </perfect-scrollbar>

        <div class="d-flex align-items-center pr-3 pl-1 py-3">
            <button *ngIf="canDelete" class="btn btn-secondary" (click)="handleDelete()">Delete</button>

            <button class="btn btn-secondary ml-auto mr-4" (click)="handleCancel()">Cancel</button>
            <button [disabled]="!activeTabForm?.valid" class="btn btn-primary" (click)="handleSave()">Save</button>
        </div>
    </div>
</ng-template>
