import { UiKitModule } from '@dagility-ui/kit';
import { NgModule } from '@angular/core';
import { SharedComponentsModule } from '@dagility-ui/shared-components';
import { CommonModule } from '@angular/common';
import { ExpressionBuilderService } from './expression-builder.service';
import { ExpressionHelperComponent } from './expression-helper/expression-helper.component';
import { ExpressionHelperModalComponent } from './expression-helper-modal/expression-helper-modal.component';
import { ExpressionHelperMenuComponent } from './expression-helper-menu/expression-helper-menu.component';
import { ExpressionBuilderComponent } from './expression-builder.component';

@NgModule({
    declarations: [ExpressionBuilderComponent, ExpressionHelperComponent, ExpressionHelperModalComponent, ExpressionHelperMenuComponent],
    imports: [UiKitModule, SharedComponentsModule, CommonModule],
    exports: [ExpressionBuilderComponent, ExpressionHelperComponent, ExpressionHelperModalComponent, ExpressionHelperMenuComponent],
    providers: [ExpressionBuilderService],
})
export class ExpressionBuilderModule {}
