import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'elapsedTime'
})
export class ElapsedTimePipe implements PipeTransform {
    readonly oneDayInMs = 86400000;
    readonly oneHourInMs = 3600000;
    readonly oneMinuteInMs = 60000;

  transform(time: number): string {
      const now = Date.now();
      const diff = now - time;

      if (diff > this.oneDayInMs) {
          const days = Math.floor(diff / this.oneDayInMs);
          if (days > 1) {
              return `${days} days ago`;
          }
          return `${days} day ago`;
      }
      if (diff > this.oneHourInMs) {
          const hours = Math.floor(diff / this.oneHourInMs);
          if (hours > 1) {
              return `${hours} hours ago`;
          }
          return `${hours} hour ago`;
      }
      if (diff > this.oneMinuteInMs) {
          return `${Math.floor(diff / this.oneMinuteInMs)} min ago`;
      }
      return 'just now';
  }
}
