import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Inject,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    ViewChild,
} from '@angular/core';
import { merge, noop, Observable, Subject, Subscription } from 'rxjs';
import { delay, distinctUntilChanged, map, take, takeUntil, tap } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { clone } from 'lodash';
import { ResizeObserverService } from '@dagility-ui/kit';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { DpDashboardComponent } from 'data-processor/lib/widget-library/dashboard/components/dp-dashboard/dp-dashboard.component';
import { ComplexNamedDropdownFilterComponent } from 'data-processor/lib/widget-library/widget-builder/components/widget/filters/complex-named-dropdown-filter/complex-named-dropdown-filter.component';

import { ComplexNamedDropdownFilter, WidgetEventHandler, WidgetFilter, WidgetFilterType } from '../../models/any-widget.model';
import { createFakeWidget, getFiltersForm } from '../../services/widget-builder.util';
import {
    ClientWidgetScriptExecutor,
    ConsoleLogger,
    WidgetErrorLogger,
    WidgetLogger,
    WidgetScriptExecutor,
} from '../../services/widget-script.executor';
import { FilterSetDirective, PlaceholdersProvider } from '../../directives/filter-set.directive';
import { DEFAULT_PLACEHOLDERS, DefaultPlaceholders } from '../../providers/default-placeholders.provider';
import { addTimeZonePlaceholderFactory } from '../../providers/timezone-placeholders.provider';
import { DpGlobalFiltersService } from '../../../dashboard/services/global-filters.service';
import { DashboardWidgetSettingsManager } from '../../../dashboard/services/dashboard-widget-settings.manager';
import { WidgetWorkflow, WidgetWorkflowContainer } from '../../services/widget.flow';
import { GlobalFiltersWorkflow } from '../../services/global-filters.flow';
import { WidgetDebuggerState } from '../../services/widget.debugger';
import { DataMorph } from '../../../dashboard/models/dp-dashboard.model';
import { GLOBAL_FILTERS_EDITOR } from '../../providers/global-filters-editor.token';

function getFullElementWidth(element: HTMLElement) {
    const style = getComputedStyle(element);

    return element.offsetWidth + parseFloat(style.marginLeft) + parseFloat(style.marginRight);
}

const SHRINK_CLASS = 'shrink-mode';

@Component({
    selector: 'dp-dashboard-filter-set:not([new])',
    templateUrl: './dashboard-filter-set.component.html',
    styleUrls: ['./dashboard-filter-set.component.scss'],
    providers: [
        {
            provide: WidgetWorkflow,
            useClass: GlobalFiltersWorkflow,
        },
        {
            provide: PlaceholdersProvider,
            useExisting: DpGlobalFiltersService,
        },
        {
            provide: WidgetLogger,
            useClass: ConsoleLogger,
        },
        { provide: WidgetScriptExecutor, useClass: ClientWidgetScriptExecutor },
        { provide: WidgetWorkflowContainer, useExisting: DashboardFilterSetComponent },
        { provide: DEFAULT_PLACEHOLDERS, useFactory: addTimeZonePlaceholderFactory },
        { provide: GLOBAL_FILTERS_EDITOR, useValue: true },
        ResizeObserverService,
    ],
})
export class DashboardFilterSetComponent extends WidgetWorkflowContainer implements OnInit, OnDestroy {
    @Input() filters: WidgetFilter[];

    @Input() contextHelpId: string;

    @Input() server = false;

    @Input() complexNamedDropdown = false;

    @Input() complexNamedDropdownLabel: string;

    @Input() complexNamedDropdownDependencies: string;

    @Input() complexNamedDropdownFilterDependency: string;

    @Input() dashboardId: number;

    @Input() dynamicDashboard: boolean;

    @Input() handlers: WidgetEventHandler[];

    @Input() @HostBinding('class.debug') debug = false;

    @HostBinding('class.visible-filters')
    get showResetButton() {
        return (this.filters || []).some(f => f.type !== WidgetFilterType.HIDDEN);
    }

    get overflow() {
        return this.fakeFiltersContainer.nativeElement.offsetWidth < this.fakeFiltersContainer.nativeElement.scrollWidth;
    }

    @Output() filterValueChanged = new EventEmitter();

    @Output() filtersRecalculated = new EventEmitter();

    @Output() filtersReset = new EventEmitter();

    @ViewChild(FilterSetDirective) filterSetDirective: FilterSetDirective;
    @ViewChild(NgbPopover) popover: NgbPopover;
    @ViewChild(ComplexNamedDropdownFilterComponent) complexDropdownFilter: ComplexNamedDropdownFilterComponent;

    _fakeFiltersContainer: ElementRef<HTMLElement>;
    get fakeFiltersContainer() {
        return this._fakeFiltersContainer;
    }

    @ViewChild('fakeFiltersContainer', { read: ElementRef }) set fakeFiltersContainer(value: ElementRef<HTMLElement>) {
        this._fakeFiltersContainer = value;
        this.subscription.unsubscribe();
        if (!value || this.debug) {
            return;
        }
        this.subscription = merge(
            this.workflow.loaded$.pipe(take(1), delay(300)),
            this.resizeObserver.observe$(this.elementRef).pipe(
                map(() => this.elementRef.nativeElement.offsetWidth),
                distinctUntilChanged()
            )
        )
            .pipe(
                tap(() => {
                    const fakeFiltersContainerElement = this.fakeFiltersContainer.nativeElement;
                    const hostElement = this.elementRef.nativeElement;
                    const setMainFiltersAsInline = () => {
                        newInlineFilters = this.mainFilters;
                        newPopoverFilters = this.secondFilters;

                        if (this.complexNamedFilter) {
                            this.complexNamedFilter.isInline = false;
                        }
                    };
                    let newInlineFilters = this.inlineFilters;
                    let newPopoverFilters = this.popoverFilters;

                    if (this.overflow) {
                        fakeFiltersContainerElement.classList.add(SHRINK_CLASS);
                        this.shrinkMode = true;

                        if (!this.overflow) {
                            newInlineFilters = this.filters;
                            newPopoverFilters = [];
                        } else {
                            const filterElements = Array.from<HTMLElement>(
                                fakeFiltersContainerElement.querySelectorAll('.widget-filter[data-main-filter="true"]')
                            );

                            const isMainFiltersOverflow = () => {
                                const mainFiltersWidth = filterElements.reduce((a, b) => a + getFullElementWidth(b), 0);
                                const resetButtonWidth = getFullElementWidth(fakeFiltersContainerElement.querySelector('button'));
                                const availableWidth = hostElement.offsetWidth - resetButtonWidth;

                                return mainFiltersWidth > availableWidth;
                            };

                            fakeFiltersContainerElement.classList.remove(SHRINK_CLASS);
                            if (isMainFiltersOverflow()) {
                                this.shrinkMode = true;
                                fakeFiltersContainerElement.classList.add(SHRINK_CLASS);

                                if (isMainFiltersOverflow()) {
                                    newInlineFilters = [];
                                    newPopoverFilters = this.filters;

                                    if (this.complexNamedFilter) {
                                        this.complexNamedFilter.isInline = false;
                                    }
                                } else {
                                    setMainFiltersAsInline();
                                }
                            } else {
                                this.shrinkMode = false;
                                setMainFiltersAsInline();
                            }
                        }
                    } else {
                        this.shrinkMode = false;
                        newInlineFilters = this.filters;
                        newPopoverFilters = [];

                        if (this.complexNamedFilter) {
                            this.complexNamedFilter.isInline = true;
                        }
                    }
                    fakeFiltersContainerElement.classList.remove(SHRINK_CLASS);

                    if (this.inlineFilters.length !== newInlineFilters.length || this.popoverFilters.length !== newPopoverFilters.length) {
                        this.inlineFilters = newInlineFilters;
                        this.popoverFilters = newPopoverFilters;

                        if (this.complexNamedFilter) {
                            this.complexNamedFilter.isInline = true;
                        }

                        this.cdr.detectChanges();
                    }
                    if (this.shrinkMode) {
                        hostElement.classList.add(SHRINK_CLASS);
                    } else {
                        hostElement.classList.remove(SHRINK_CLASS);
                    }
                })
            )
            .subscribe();
    }

    shrinkMode = false;

    form: FormGroup;
    defaultPlaceholders$: Observable<any> = this.defaultPlaceholders(null);
    complexNamedFilter: ComplexNamedDropdownFilter;
    popoverFilters: WidgetFilter[] = [];
    inlineFilters: WidgetFilter[] = [];
    mainFilters: WidgetFilter[] = [];
    secondFilters: WidgetFilter[] = [];
    subscription = Subscription.EMPTY;
    private destroyed$ = new Subject<void>();

    constructor(
        @Inject(DEFAULT_PLACEHOLDERS) private defaultPlaceholders: DefaultPlaceholders,
        @Optional() public store: DpGlobalFiltersService,
        private scriptExecutor: WidgetScriptExecutor,
        @Optional() public debuggerState: WidgetDebuggerState,
        public manager: DashboardWidgetSettingsManager,
        public workflow: WidgetWorkflow,
        private logger: WidgetLogger,
        private zone: NgZone,
        @Optional() private dashboard: DpDashboardComponent,
        private elementRef: ElementRef<HTMLElement>,
        private resizeObserver: ResizeObserverService,
        private cdr: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {
        this.filters = this.filters ?? [];
        this.inlineFilters = this.filters;
        this.filters.forEach(widgetFilter => {
            if (widgetFilter.mainFilter) {
                this.mainFilters.push(widgetFilter);
            } else {
                this.secondFilters.push(widgetFilter);
            }
        });

        if (!this.server) {
            this.form = getFiltersForm({
                filters: this.filters,
                defaultValuesMap: this.store?.defaultFiltersValues ?? {},
                scriptExecutor: this.scriptExecutor,
                complexNamedDropdown: true,
            });

            return;
        }

        if (this.complexNamedDropdown) {
            this.complexNamedFilter = {
                placeholder: 'complexNamedDropdown',
                type: WidgetFilterType.COMPLEX_NAMED_DROPDOWN,
                width: '150',
                label: this.complexNamedDropdownLabel,
                defaultValue: 'test',
                dependencies: this.complexNamedDropdownDependencies.split(',').map(str => str.trim()),
                filterDependency: this.complexNamedDropdownFilterDependency,
                isInline: true,
            };
        }

        if (!this.dashboardId) {
            this.logger = new WidgetErrorLogger();
        }
        this.workflow.id = this.dashboardId;
        this.workflow.dynamicDashboard = this.dynamicDashboard;
        this.workflow.widgetDebugger = this.debuggerState ?? new WidgetDebuggerState();
        this.workflow.breakpoints = new Set(this.breakpoints);
        this.workflow.logger = this.logger;

        if (this.dashboard) {
            this.workflow.setDashboard(createDashboardStub(this.dashboard, this.zone));
        }

        this.workflow
            .init(
                createFakeWidget({
                    filters: this.filters,
                    name: '',
                    handlers: this.handlers || [],
                    server: true,
                    complexNamedDropdown: this.complexNamedDropdown,
                    complexNamedDropdownLabel: this.complexNamedDropdownLabel,
                    complexNamedDropdownDependencies: this.complexNamedDropdownDependencies,
                    complexNamedDropdownFilterDependency: this.complexNamedDropdownFilterDependency,
                }),
                {},
                this.store?.defaultFiltersValues ?? {}
            )
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                const formValue = this.workflow.widgetDebugger.filters$.value.value;
                this.workflow.initialPlaceholders = formValue;

                if (this.store) {
                    this.store.defaultFiltersValues = formValue;
                    this.store.globalFilters$.next(this.workflow.placeholders);
                }

                this.filtersRecalculated.emit(formValue);
            });
    }

    resetFilters(): void {
        this.filtersReset.emit();

        if (this.workflow && this.server) {
            this.workflow.reset();
        } else {
            this.filterSetDirective.ngOnChanges({
                formGroup: this.form,
                filters: this.filters,
                resetGlobalFilters: true,
            } as any);
        }

        this.workflow.complexNamedDropdownChanged$.next();
    }

    handleLoaded() {
        this.store.defaultFiltersValues = this.form.value;

        Object.assign(this.store.phf, this.form.value);

        delete this.store.phf.result;

        this.store.globalFilters$.next(this.store.phf);

        this.filtersRecalculated.emit(this.form.value);
        this.filterSetDirective.resetGlobalFilters = false;
    }

    handleComplexNamedDropdownChange() {
        this.workflow.complexNamedDropdownChanged$.next();
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.subscription.unsubscribe();
    }
}

export function createDashboardStub(dashboard: DpDashboardComponent, zone: NgZone) {
    function selectTab(name: string) {
        const tabs = Object.values(dashboard.store.value.tabsMap);
        const nextTab = tabs.find(tab => tab.name === name);

        if (nextTab) {
            dashboard.handleTabChange({ preventDefault: noop, nextId: nextTab.id, activeId: null });
        }
    }

    function changeDashboard(name: string, placeholders: any) {
        zone.run(() => {
            dashboard.dashboardWidgetManager.getDashboardIdByName(name).subscribe(({ id }) => {
                if (placeholders.states?.length === 0) {
                    placeholders.states = getPreviousStates();
                }

                dashboard.changeDashboard([`../${id}`], { placeholders });
            });
        });
    }

    function changeGroupVisibility(groupName: string, state: DataMorph.DashboardGroupVisibility) {
        zone.run(() => {
            dashboard.store.changeGroupVisibility(groupName, state);
        });
    }

    function changeGroupVisibilityByKey(groupName: string, groupKey: number, state: DataMorph.DashboardGroupVisibility) {
        zone.run(() => {
            dashboard.store.changeGroupVisibilityByKey(groupName, groupKey, state);
        });
    }

    function emit(event: any) {
        zone.run(() => {
            setPreviousState({ state: event['state'] });

            dashboard.event.emit(event);
        });
    }

    function setPreviousState(state: any) {
        if (state && state.state) {
            dashboard.setPreviousState(state);
        }
    }

    function getPreviousStates(): any[] {
        return dashboard.previousMlfState ? clone(dashboard.previousMlfState) : [];
    }

    function getActiveTab() {
        const state = dashboard.store.value;

        return state.tabsMap[state.activeTab].name;
    }

    return {
        selectTab,
        changeDashboard,
        changeGroupVisibility,
        changeGroupVisibilityByKey,
        emit,
        getActiveTab,
    };
}
