import { Deserializable, DataTransformation } from '../../../models/job-definition';

export class TransformationAction extends Deserializable {
    _class = {
        dataTransformation: DataTransformation,
    };
    _enums = {};
    _arrays = {};
    _maps = {};
    meta = {
        _arrays: this._arrays,
        _class: this._class,
        _maps: this._maps,
        _enums: this._enums,
    };

    variable = '';
    dataTransformation: DataTransformation = new DataTransformation(null);

    constructor(instance?: any) {
        super();
        this.fillByInstance(TransformationAction, instance);
    }
}
