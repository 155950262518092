import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BasicHttpClient } from '@dagility-ui/shared-components/utils/help-support/basic-http-client';

@Injectable({
    providedIn: 'root',
})
export class TourDataService extends BasicHttpClient {
    helpUrl = `/jsonapi/node/product_tour?include=field_paragraphs`;
    calibrateHelpUrl = `/jsonapi/node/product_tour?include=field_paragraphs&filter[field_module][operator]=IN&filter[field_module][value]=calibrate`;
    routeAccessMap: any = {};

    formatTourListData(response: any, moduleList: any, isCalibrate: boolean) {
        const formattedListData: any[] = [];
        response.data.forEach((element: any) => {
            const listData: Record<string, any> = {};
            const stepContent: any[] = [];
            const module = element.attributes.hasOwnProperty('field_module') ? element.attributes.field_module : [];
            element.relationships.field_paragraphs.data.forEach((stepElement: any) => {
                response.included.forEach((stepData: any) => {
                    const elementData: Record<string, any> = {};
                    if (stepData.id === stepElement.id) {
                        elementData['stepId'] = stepData.attributes.drupal_internal__id;
                        if (stepContent.length && stepData.attributes.field_route === null) {
                            stepData.attributes.field_route = stepContent[stepContent.length - 1].route;
                        }
                        elementData['route'] = stepData.attributes.field_route;
                        this.checkRouteAccess(
                            elementData,
                            moduleList.filter((it: any) => stepData.attributes.field_route.match(it.routerLink))
                        );
                        elementData['title'] = stepData.attributes.field_title.value;
                        elementData['anchorId'] = stepData.attributes.field_anchor_id;
                        elementData['content'] = stepData.attributes.field_content.processed;
                        elementData['enableBackdrop'] = true;
                        stepContent.push(elementData);
                    }
                });
            });
            listData['tourTitle'] = element.attributes.title;
            if(isCalibrate) {
                listData['active$'] = of(true);
            } else {
                listData['active$'] =
                module === '' || module === 'any'
                    ? of(true)
                    : moduleList.filter((it: any) => module.includes(it.module)).length
                    ? moduleList.filter((it: any) => module.includes(it.module))[0]['active$']
                    : of(true);
            }
            listData['tourId'] = element.id;
            listData['updatedDate'] = element.attributes.changed;
            listData['data'] = stepContent;
            formattedListData.push(listData);
        });
        return formattedListData;
    }

    getTourData(isCalibrate: boolean): Observable<any[]> {
        const url = isCalibrate ? this.calibrateHelpUrl : this.helpUrl;
        return this.http.get<any[]>(`${this.API_URL}${url}`);
    }

    checkRouteAccess(elementData: any, moduleMapped: any) {
        if (this.routeAccessMap.hasOwnProperty(elementData['route'])) {
            elementData['hasAccess'] = this.routeAccessMap[elementData['route']];
        } else {
            if (moduleMapped.length) {
                elementData['hasAccess'] = this.routeAccessMap[elementData['route']] = moduleMapped[0].hasAccess;
            } else {
                elementData['hasAccess'] = this.routeAccessMap[elementData['route']] = true;
            }
        }
    }
}
