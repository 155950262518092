import { NgModule } from '@angular/core';
import { VocFormComponent } from './voc-form/voc-form.component';
import { FormElementComponent } from './voc-form/form-element/form-element.component';
import { UiKitModule } from '@dagility-ui/kit';
import { VocWrapperComponent } from './voc-wrapper/voc-wrapper.component';

@NgModule({
    declarations: [VocFormComponent, FormElementComponent, VocWrapperComponent],
    imports: [UiKitModule],
    exports: [VocFormComponent, VocWrapperComponent],
})
export class VocFormModule {}
