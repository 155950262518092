import { Component, OnInit } from '@angular/core';
import { SreGanttService } from '../../services/sre-gantt.service';

const COLUMNS_LENGTH = 10;

@Component({
    selector: 'dp-sre-gantt-columns',
    templateUrl: './sre-gantt-columns.component.html',
})
export class SreGanttColumnsComponent implements OnInit {
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _columns: number[] = [];

    get columnLength() {
        return COLUMNS_LENGTH;
    }

    get columns(): number[] {
        return this._columns;
    }

    constructor(private service: SreGanttService) {}

    ngOnInit() {
        this.initColumns();
    }

    initColumns() {
        const knownColumnsLength = 2;
        const unknownColumnsLength = this.columnLength - knownColumnsLength;
        const max = this.service.maximum - this.service.minimum;

        const step = max / (unknownColumnsLength + 1);

        this.columns.push(0);
        for (let i = 0; i < unknownColumnsLength; i++) {
            this.columns.push(step * (i + 1));
        }

        this.columns.push(max);
    }
}
