import { Component, Input } from '@angular/core';

import { BaseFormControl } from '@dagility-ui/kit';

@Component({
    selector: 'dp-widget-color-picker',
    templateUrl: './widget-color-picker.component.html',
    styleUrls: ['./widget-color-picker.component.scss'],
})
export class WidgetColorPickerComponent extends BaseFormControl<string, HTMLInputElement> {
    @Input() cpPosition: string = 'top-right';

    handleChange(color: string): void {
        this.value = color;
        this.onChange(color);
    }
}
