const completionDictionaryExample: CompletionSourceItem[] = [
    {
        name: 'project',
        description: 'description',
        children: [
            {
                name: 'name',
                description: 'description1',
                children: null, // or missing
            },
            {
                name: 'prefix',
                description: 'description2',
            },
            {
                name: 'properties',
                description: 'description3',
                children: [
                    {
                        name: 'prop1',
                        description: 'description4',
                    },
                ],
            },
        ],
    },
    {
        name: 'component',
        description: 'description1',
        children: [
            {
                name: 'prop1',
                description: 'description2',
            },
            {
                name: 'prop2',
                description: 'description3',
                children: [
                    {
                        name: 'subProp1',
                        description: 'description4',
                    },
                    {
                        name: 'subProp2',
                        description: 'description5',
                    },
                ],
            },
        ],
    },
    {
        name: 'component2',
        description: 'description',
        children: [
            {
                name: 'age',
                description: 'description',
                children: [
                    {
                        name: 'subProp1',
                        description: 'description',
                    },
                    {
                        name: 'subProp2',
                        description: 'description',
                    },
                ],
            },
        ],
    },
];

export interface CompletionSourceItem {
    name: string;
    description: string;
    children?: CompletionSourceItem[] | null;
}

export interface CompletionItem {
    text: string;
    description: string;
    render: (elem: HTMLElement) => void;
}

export interface CompletionResult {
    items: CompletionItem[];
    curWord: string;
}

function ifInsideScope(lineText: string, index: number, scope: { start: string; end: string }): boolean {
    if (index > lineText.length - 1) {
        return false;
    }

    if (scope.end === ']' && checkForArrayProperties(lineText, index)) {
        return true;
    }

    const isTouchedScopeBoundary = (boundary: string, i: number) =>
        lineText.substr(i - (boundary.length - 1), boundary.length) === boundary;
    let depth = 0;
    for (let i = 0; i <= index; i++) {
        if (i >= scope.start.length - 1 && isTouchedScopeBoundary(scope.start, i)) {
            depth++;
        } else if (depth > 0 && i >= scope.start.length - 1 + scope.end.length - 1 && isTouchedScopeBoundary(scope.end, i)) {
            depth--;
        }
    }

    return depth > 0;
}

function checkForArrayProperties(lineText: string, index: number): boolean {
    for (let i = index - 2; i >= 0; i--) {
        if (lineText[i] === '[' && lineText[i + 1] !== '=') {
            return true;
        } else if (lineText[i] === ']') {
            break;
        }
    }
    return false;
}

function normalizeCompletionItem(items: CompletionSourceItem[]) {
    return items
        .sort((a: CompletionSourceItem, b: CompletionSourceItem) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        })
        .map(item => ({
            text: item.name,
            description: item.description,
            render: (elem: HTMLElement) => {
                elem.innerHTML = `<div>${item.name}</div><div class="completion-details">${item.description}</div>`;
            },
        }));
}

export function getPropertiesCompletionItems(
    lineText: string,
    index: number,
    scope: { start: string; end: string },
    dictionary: CompletionSourceItem[] = completionDictionaryExample
): CompletionResult {
    const curChar = lineText[index - 1];
    if (!ifInsideScope(lineText, index - 1, scope)) {
        return;
    }

    let completionsList = dictionary;
    if (!curChar) {
        // top level variables
        return {
            items: normalizeCompletionItem(completionsList),
            curWord: '',
        };
    }

    const scopeStartIndex = lineText.lastIndexOf(scope.start, index - 1);
    if (scopeStartIndex < 0) {
        return;
    }
    const text = lineText.substring(scopeStartIndex + 2, index);

    const contextText = text.split(' ').pop();
    const levels = contextText.split('.');
    const varRegExp = new RegExp('^[a-zA-Z][a-zA-Z0-9]*', 'i');
    const arrayRegExp = new RegExp(/\[.*?]/g);

    let curWord: string = '';
    while (levels.length) {
        curWord = levels.shift();
        if (curWord && !varRegExp.test(curWord)) {
            return;
        }

        if (levels.length) {
            let suggestion;
            if (arrayRegExp.test(curWord)) {
                curWord = curWord.replace(arrayRegExp, '[]');
            }
            suggestion = completionsList.find(({ name }) => name.toLowerCase() === curWord.toLowerCase());
            if (!suggestion) {
                return;
            }
            completionsList = suggestion.children || [];
        }
    }

    const regex = new RegExp('^' + curWord, 'i');
    return {
        items: normalizeCompletionItem(completionsList.filter((item: CompletionSourceItem) => item.name.match(regex))),
        curWord,
    };
}

export function definePropertiesCompletion(
    codeMirror: any,
    dictionary: CompletionSourceItem[] = completionDictionaryExample,
    changeSyntax?: boolean
) {
    codeMirror.registerHelper('hint', 'custom', function customHint(editor: any) {
        const cursor = editor.getCursor();

        const curLine = editor.getLine(cursor.line);
        const completionResult = getPropertiesCompletionItems(
            curLine,
            cursor.ch,
            changeSyntax
                ? {
                      start: '${',
                      end: '}',
                  }
                : { start: '[=', end: ']' },
            dictionary
        );

        return {
            list: completionResult ? completionResult.items : [],
            from: codeMirror.Pos(cursor.line, cursor.ch - (completionResult ? completionResult.curWord.length : 0)),
            to: codeMirror.Pos(cursor.line, cursor.ch),
        };
    });
    codeMirror.commands.autocomplete = function(mirror: any) {
        mirror.showHint({ hint: codeMirror.hint.custom });
    };
}
