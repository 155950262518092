import { Observable, of } from 'rxjs';
import { InjectionToken } from '@angular/core';

export enum DATA_MORPH_FEATURE {
    MONITORING_TOOLS = 'monitoring_tools',
    ALERTS = 'alerts',
    LINK_CLICK = 'wc_link_click',
    HIDE_DESCRIPTION = 'wc_description',
    TAGS_VISIBILITY = 'wc_visibility_by_tags',
}

export class DataMorphFeatureToggleService {
    isActive(featureName: string, isFeatureOnOff: boolean = false) {
        return of(false);
    }

    isActiveSync(featureName: string): boolean {
        return false;
    }

    areFeaturesActive(features: string[]): Observable<boolean[]> {
        return of(Array.from<boolean>({ length: features.length }).fill(false));
    }
}

export function dataMorphFeatureToggleFactory() {
    return new DataMorphFeatureToggleService();
}

export const DATA_MORPH_FEATURE_TOGGLE = new InjectionToken('Data Morph Feature Toggle', {
    factory: dataMorphFeatureToggleFactory,
});
