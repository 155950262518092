import {Injectable, TemplateRef} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { delay } from 'rxjs/operators';
import { IProgressWidgetData } from './progress-background-widget.model';

@Injectable({
    providedIn: 'root',
})
export class ProgressBackgroundWidgetService {
    widgets$: BehaviorSubject<ProgressWidgetHandler[]> = new BehaviorSubject([]);
    widgetsObs$ = this.widgets$.pipe(delay(0));

    createWidget(data: object, widgetTemplateRef?: TemplateRef<any>): ProgressWidgetHandler {
        const newWidget = new ProgressWidgetHandler(data, this, widgetTemplateRef);
        this.widgets$.value.push(newWidget);
        this.widgets$.next(this.widgets$.value);
        return newWidget;
    }

    getWidgetByDataKeyValue(key: string, value: any): ProgressWidgetHandler  {
        return (this.widgets$.value || []).find((widget: any) => widget.dataValue[key] === value);
    }

    removeWidget(widget: ProgressWidgetHandler) {
        const removedIndex = this.widgets$.value.indexOf(widget);
        if (removedIndex >= 0) {
            this.widgets$.value.splice(removedIndex, 1);
            this.widgets$.next(this.widgets$.value);
        }
    }

    destroyItFromWidget(widget: ProgressWidgetHandler) {
        this.removeWidget(widget);
    }

    destroyAll() {
        this.widgets$.value.forEach(widget => {
            widget.destroy();
        });
    }
}

export class ProgressWidgetHandler {
    isWidgetShown: boolean;
    widgetTemplateRef: TemplateRef<any>;

    subject$: BehaviorSubject<object | IProgressWidgetData>;

    constructor(data: object | IProgressWidgetData, private parentService: ProgressBackgroundWidgetService, widgetTemplateRef: TemplateRef<any>) {
        this.subject$ = new BehaviorSubject(data);
        this.widgetTemplateRef = widgetTemplateRef;
    }

    set setData(value: object) {
        this.subject$.next(value);
    }

    get dataValue(): any {
        return this.subject$.value;
    }

    set setTemplate(value: TemplateRef<any>) {
        this.widgetTemplateRef = value;
    }

    setDataByKey(value: any, key: string) {
        const subjectValue: Record<string, any> = this.subject$.value;
        if (subjectValue.hasOwnProperty(key)) {
            subjectValue[key] = value;
            this.subject$.next(subjectValue);
        }
    }

    showWidget() {
        this.isWidgetShown = true;
    }

    hideWidget() {
        this.isWidgetShown = false;
    }

    destroy() {
        this.parentService.destroyItFromWidget(this);
    }
}
