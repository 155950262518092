import { AbstractControl } from '@angular/forms';
import { DataMorph } from 'data-processor';
import { InjectionToken, Type } from '@angular/core';

export interface DashboardFormExtension {
    controlName: string;

    form: AbstractControl;

    setDashboard(dashboard: DataMorph.Dashboard): void;

    toSave(): Record<string, unknown>;
}

export const DASHBOARD_FORM_EXTENSION = new InjectionToken<Type<DashboardFormExtension>>(
    'Provide additional settings for dashboard creation form'
);
