import { AfterViewInit, Component, Inject, NgZone, ViewChild } from '@angular/core';
import { BehaviorSubject, noop, Observable, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DropdownComponent, DropdownItem, liftToObsWithStatus, MultipleHeaderOperationType } from '@dagility-ui/kit';

import { FilterSetDirective } from '../../../../directives/filter-set.directive';
import { DropdownFilter, FILTER_DEFAULT_SIZES } from '../../../../models/any-widget.model';
import { WidgetDebuggerState } from '../../../../services/widget.debugger';
import { BaseWidgetFilter } from '../base-widget-filter.component';
import { getWidgetWidth } from '../../../../services/widget-builder.util';

export const BATCH_SIZE = 100;

@Component({
    selector: 'dp-dropdown-filter',
    template: `
        <ng-template #optionTemplate let-item="item" let-multiple="multiple" let-item$="item$">
            <checkbox class="float-left" *ngIf="multiple" [value]="item$.selected"></checkbox>
            <div class="text-truncate" [ngbTooltip]="item.label" container="body" triggers="manual" libTooltipWhenOverflow>
                {{ item.label }}
            </div>
        </ng-template>

        <ng-template #labelTemplate let-item="item">
            <div
                class="text-truncate position-relative"
                [ngbTooltip]="itemByIdMap[item.value]?.item?.label"
                container="body"
                triggers="manual"
                libTooltipWhenOverflow
            >
                {{ itemByIdMap[item.value]?.item?.label }}
            </div>
        </ng-template>

        <div hidden>{{ formControl.value }}</div>

        <lib-dropdown
            *ngIf="items$ | obsWithStatus | async as itemsObs"
            class="size-s"
            [class.dropdown-loading]="itemsObs.loading"
            [width]="filter.width | mapper: getWidth"
            [searchable]="true"
            [clearSearchOnAdd]="false"
            [onlyEmitHeaderOperationTypeChange]="true"
            [formControl]="formControl"
            [label]="filter.hideLabel ? '' : filter.label"
            [multiple]="filter.multiple"
            [loading]="itemsObs.loading"
            [items]="itemsObs.value"
            [totalItemsLength]="totalItemsLength"
            [disableFilterOnSelect]="true"
            appendTo="body"
            [optionTemplateRef]="optionTemplate"
            [labelTemplateRef]="labelTemplate"
            [clearable]="filter.clearable ?? true"
            [placeholder]="filter.placeholderText || 'Select Option'"
            (filter)="handleFilter($event)"
            (searchTerm)="handleFilter($event.term)"
            (close)="filter.multiple && handleClose()"
            (clear)="filter.multiple && handleClear()"
            (multipleHeaderOperation)="handleMultipleHeaderClick($event)"
            [virtualScroll]="true"
            (scroll)="handleScroll($event)"
            [attr.action-id]="'action-' + filter.placeholder"
        ></lib-dropdown>
    `,
    styleUrls: ['./dropdown-filter.component.scss'],
})
export class DropdownFilterComponent extends BaseWidgetFilter implements AfterViewInit {
    @ViewChild(DropdownComponent) dropdown: DropdownComponent;

    page$ = new BehaviorSubject(0);
    filteredData: DropdownItem[] = [];
    filterTerm = '';
    selectedItems$: BehaviorSubject<any>;
    itemByIdMap: Record<string, { item: DropdownItem; index: number }> = {};
    defaultValueForMultiple: DropdownItem[] = [];
    totalItemsLength: number = 0;

    getWidth = getWidgetWidth(FILTER_DEFAULT_SIZES.DROPDOWN);
    afterReset = false;

    getItemsObs = () => (obs: Observable<DropdownItem[]>) =>
        liftToObsWithStatus(
            obs.pipe(
                catchError(err => {
                    console.error(err);
                    return of([] as DropdownItem[]);
                }),
                tap((data: any) => {
                    if (data.type !== 'start' && this.filter.multiple) {
                        this.listenMultipleFilterValue();
                        this.defaultValueForMultiple = data || [];
                    }
                }),
                switchMap(data => {
                    if (data.type === 'start') {
                        return of(data);
                    }
                    this.totalItemsLength = (data || []).length;
                    this.itemByIdMap = (data || []).reduce((acc: Record<string, any>, item: DropdownItem, idx: number) => {
                        acc[item.value] = { item, index: idx };

                        return acc;
                    }, {});

                    return this.page$.pipe(
                        tap(page => {
                            if (page === 0) {
                                this.filteredData = !this.filterTerm
                                    ? data
                                    : data.filter((item: DropdownItem) =>
                                          item.label?.toLowerCase().includes(this.filterTerm.toLowerCase())
                                      );
                            }
                        }),
                        map(page => this.filteredData.slice(0, BATCH_SIZE * (page + 1)))
                    );
                })
            )
        );

    private previousItems: any[] = null;

    resetPaging = (items: any[]) => {
        this.filterTerm = '';
        if (
            this.previousItems &&
            Array.isArray(this.previousItems) &&
            Array.isArray(items) &&
            JSON.stringify(this.previousItems.map(item => item.value)) === JSON.stringify(items.map(item => item.value))
        ) {
            return;
        }
        this.previousItems = items;
        this.page$.next(0);
    };

    // eslint-disable-next-line @typescript-eslint/member-ordering
    items$ = this.debuggerState
        ? this.debuggerState.dataSources[this.filter.placeholder].pipe(tap(this.resetPaging), this.getItemsObs())
        : this.filterSet.filtersDataMap?.[this.filter.placeholder]?.pipe(this.getItemsObs());

    onChange: (_: any) => void = noop;

    constructor(
        @Inject(BaseWidgetFilter.FILTER_TOKEN) public filter: DropdownFilter,
        private debuggerState: WidgetDebuggerState,
        private zone: NgZone,
        private filterSet: FilterSetDirective
    ) {
        super(filter);
    }

    public getFilterText(): { title: string; value: any } {
        return { title: this.filter.label, value: this.dropdown.ngSelect.selectedValues };
    }

    ngAfterViewInit() {
        this.dropdown.ngSelect.classes = 'dropdown-filter';

        this.filtersReset?.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.afterReset = true;
        });

        this.zone.runOutsideAngular(() => {
            this.dropdown.ngSelect.openEvent.pipe(takeUntil(this.destroyed$)).subscribe(() => {
                setTimeout(() => {
                    const dropdownPanel = document.querySelector<HTMLElement>('ng-dropdown-panel');
                    const options = Array.from(dropdownPanel.querySelectorAll<HTMLElement>('.ng-option .text-truncate')).map(
                        option => option.scrollWidth
                    );
                    if (options.length) {
                        const distanceToRightWindowCorner = window.innerWidth - dropdownPanel.getBoundingClientRect().left;
                        const maxWidth = Math.min(Math.max(...options) + 21, distanceToRightWindowCorner);
                        dropdownPanel.style.width = `${maxWidth}px`;
                    }

                    if (this.afterReset) {
                        this.afterReset = false;
                        this.dropdown.ngSelect?.dropdownPanel?.contentElementRef.nativeElement.parentNode.scroll(0, 0);
                    }
                });
            });
        });
    }

    registerOnChange(fn: (_: any) => void): void {
        this.onChange = fn;

        if (this.filter.multiple) {
            return;
        }
        this.subscription.unsubscribe();
        this.subscription = this.formControl.valueChanges.subscribe(v => this.onChange(v));
    }

    handleMultipleHeaderClick(operationType: MultipleHeaderOperationType): void {
        this.setMultipleValues(operationType);
    }

    invertAll() {
        const selectedValues = new Map(
            (this.dropdown.value ?? []).map((item: Record<string, unknown>) => {
                return [typeof item === 'object' ? item[this.dropdown.valueKey] : item, true];
            })
        );

        this.dropdown.value = this.defaultValueForMultiple.reduce((acc, { value }) => {
            if (!selectedValues.has(value)) {
                acc.push(value);
            }

            return acc;
        }, [] as unknown[]);
    }

    setMultipleValues(operation: MultipleHeaderOperationType) {
        switch (operation) {
            case 'ALL':
                this.dropdown.value = this.defaultValueForMultiple.map(({ value }) => value);
                break;
            case 'INV':
                this.invertAll();
                break;
            case 'NONE':
                this.dropdown.value = [];
                break;
        }

        this.formControl.setValue(this.dropdown.value);
    }

    handleFilter(searchTerm: string): void {
        this.filterTerm = searchTerm;
        this.page$.next(0);
    }

    handleClose(): void {
        this.selectedItems$.next(
            ((this.formControl.value as DropdownItem[] | string[]) || [])
                .map(item => (typeof item === 'object' ? item.value : item))
                .sort((a, b) => (this.itemByIdMap[a]?.index ?? 0) - (this.itemByIdMap[b]?.index ?? 0))
        );
    }

    handleScroll(event: null | { start: number; end: number }): void {
        if (!event || !event.end || event.end < (this.page$.value + 1) * BATCH_SIZE) {
            return;
        }

        this.page$.next(this.page$.value + 1);
    }

    handleClear(): void {
        this.selectedItems$.next([]);
    }

    private listenMultipleFilterValue() {
        this.subscription.unsubscribe();
        this.selectedItems$ = new BehaviorSubject<any>(this.formControl.value);

        this.subscription = this.selectedItems$
            .pipe(
                BaseWidgetFilter.filterValueChanged(
                    () => this.selectedItems$.value,
                    () => this.formControl.value
                )
            )
            .subscribe(v => this.onChange(v));
    }
}
