<div class="flex-1 overflow-hidden d-flex tabs-container">
    <div *ngIf="debugContext.activeTab === 'variables'" class="flex-1 variables-container">
        <div class="variables d-flex flex-1 col-4 p-3">
            <perfect-scrollbar>
                <dp-debug-variables [variables]="debugContext.context"></dp-debug-variables>
            </perfect-scrollbar>
        </div>
        <dp-debug-log class="logs flex-1 px-0" [logs]="debugContext.logs"></dp-debug-log>
    </div>
    <dp-debug-watch
        *ngIf="debugContext.activeTab === 'watches'"
        class="w-100 h-100"
        [watches]="debugContext.watches"
        (addWatch)="addWatch()"
        (evaluateWatch)="evaluateWatch($event)"
        (removeWatch)="removeWatch($event)"
    ></dp-debug-watch>
    <dp-debug-evaluate
        *ngIf="debugContext.activeTab === 'evaluate'"
        class="w-100 h-100"
        [evaluateResult]="debugContext.evaluateResult"
    ></dp-debug-evaluate>
</div>
<ul class="menu-tabs px-4 mb-0 border-top align-items-center">
    <li *ngFor="let tab of tabs" class="menu-tab">
        <a
            class="menu-tab-link cursor-pointer"
            [class.menu-tab-link--active]="tab.field === debugContext.activeTab"
            (click)="tabChange(tab.field)"
        >
            {{ tab.name }}
        </a>
    </li>

    <fa-icon
        *ngIf="!!debugContext.activeTab"
        class="ml-auto cursor-pointer"
        icon="facClose"
        ngbTooltip="Hide"
        (click)="collapse()"
    ></fa-icon>
</ul>
