import { NgModule } from '@angular/core';

import {
    CodemirrorEditorModule, PropertiesCompletionModule,
} from '@dagility-ui/shared-components';
import { UiKitModule } from '@dagility-ui/kit';

import { ActionPropertyEditorComponent } from '@app/shared/components/action-property-editor/action-property-editor.component';

@NgModule({
    imports: [UiKitModule, CodemirrorEditorModule, PropertiesCompletionModule],
    declarations: [ActionPropertyEditorComponent],
    exports: [ActionPropertyEditorComponent]
})
export class ActionPropertyEditorModule { }
