import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ENV_TOKEN } from '@app/tokens';

@Injectable()
export class PortfolioScoresService {
    private healthDataUrl = `${this.env.insightApiURL}/reports/`;

    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {}

    getPortfolioHealthData(partUrl: string, params: any): Observable<any> {
        return this.http.post(`${this.healthDataUrl}${partUrl}`, params);
    }
}
