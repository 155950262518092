import { Component, EventEmitter, Output, Input } from '@angular/core';
import { ExpressionMode } from '@app/shared/components/query-builder/models/model-graph-actions.model';

@Component({
    selector: 'app-expression-helper-menu',
    templateUrl: './expression-helper-menu.component.html',
    styleUrls: ['./expression-helper-menu.component.scss'],
})
export class ExpressionHelperMenuComponent {
    @Input() mode: ExpressionMode | null;
    @Output() cancel = new EventEmitter<string>();
    @Output() apply = new EventEmitter<string>();

    onCancel() {
        this.cancel.emit('cancel');
    }

    onApply() {
        this.apply.emit('apply');
    }

    getExpressionMode() {
        return this.mode === ExpressionMode.GROOVY ? 'Groovy Script' : '';
    }
}
