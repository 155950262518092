<ng-container [formGroup]="filtersForm">
    <ng-container *ngIf="isStaticFilter; else dynamicFilter">
        <ng-container *ngTemplateOutlet="filterEl"></ng-container>
    </ng-container>
    <ng-template #dynamicFilter>
        <ng-container *ngIf="!isEmptySource; else emptySourceTemplate">
            <div *ngIf="editMode" @fadeInOut style="height: fit-content">
                <ng-container *ngTemplateOutlet="filterEl"></ng-container>
            </div>
            <div @fadeInOut *ngIf="!editMode">
                <dp-items-with-more [items]="selectedValues$ | async" [visibleItemsCount]="5">
                    <ng-template let-item>
                        <dp-filter-value-preview [item]="item" (click)="previewClick.emit()"></dp-filter-value-preview>
                    </ng-template>

                    <ng-template dpItemsWithMoreExpandButton let-collapsed="collapsed" let-diff="diff">
                        <ng-template [ngIf]="collapsed" [ngIfElse]="showLess"> + &nbsp;{{ diff }} more selected </ng-template>
                        <ng-template #showLess>
                            Show less
                        </ng-template>
                    </ng-template>
                </dp-items-with-more>
            </div>
        </ng-container>
        <ng-template #emptySourceTemplate>
            <div
                class="pr-4 body1 filter-item empty-source disabled"
                data-test-id="empty-source"
                [ngbTooltip]="tooltipTmpl"
                container="body"
                tooltipClass="empty-source-tooltip"
            >
                <fa-icon icon="facExclamationCircle" class="warning-text mr-2"></fa-icon> No values available
            </div>
        </ng-template>
        <ng-template #tooltipTmpl>
            <div class="body2 font-weight-normal">
                The filter you selected does not have any values available at the moment. This could be due to one of the following reasons:
                <ul class="mb-0 mt-1">
                    <li class="mb-1">
                        There is no tool configured in UST PACE.
                    </li>
                    <li class="mb-1">The values are unavailable or not configured in the source system.</li>
                    <li>
                        The values may be temporarily unavailable due to an error. If you need help resolving the issue, please contact your
                        admin.
                    </li>
                </ul>
            </div>
        </ng-template>
    </ng-template>
    <ng-template #filterEl>
        <ng-container
            [dpChartFilter]="filter"
            [widgetDebugger]="$any(sortedDebuggerState)"
            [filterComponentFactory]="globalFilterFactory"
        ></ng-container>
    </ng-template>
</ng-container>
