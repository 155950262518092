import { Injectable, TemplateRef } from '@angular/core';
import { NavigationStart, Router, UrlSegment } from '@angular/router';
import { Subject, BehaviorSubject, Observable, merge as mergeStatic } from 'rxjs';
import { first, map, filter } from 'rxjs/operators';
import { Placement, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { TourAnchorDirective } from './tour-anchor.directive';
import { ConfirmModal, ModalConfirmComponent } from '@dagility-ui/kit';
import { TourMenuComponent } from './tour-menu/tour-menu.component';

export enum TourState {
    OFF,
    ON,
    PAUSED,
}

@Injectable({
    providedIn: 'root',
})
export class TourService<T extends StepOption = StepOption> {
    stepShow$: Subject<T> = new Subject();
    stepHide$: Subject<T> = new Subject();
    errorMessage$ = new BehaviorSubject<String>('');
    initialize$: Subject<T[]> = new Subject();
    start$ = new Subject<void>();
    end$ = new Subject<void>();
    pause$ = new Subject<void>();
    resume$ = new Subject<void>();
    anchorRegister$ = new BehaviorSubject({} as any);
    anchorUnregister$: Subject<string> = new Subject();
    events$: Observable<{ name: string; value: any }> = mergeStatic(
        this.stepShow$.pipe(map(value => ({ name: 'stepShow', value }))),
        this.stepHide$.pipe(map(value => ({ name: 'stepHide', value }))),
        this.initialize$.pipe(map(value => ({ name: 'initialize', value }))),
        this.start$.pipe(map(value => ({ name: 'start', value }))),
        this.end$.pipe(map(value => ({ name: 'end', value }))),
        this.pause$.pipe(map(value => ({ name: 'pause', value }))),
        this.resume$.pipe(map(value => ({ name: 'resume', value }))),
        this.anchorRegister$.pipe(
            map(value => ({
                name: 'anchorRegister',
                value,
            }))
        ),
        this.anchorUnregister$.pipe(
            map(value => ({
                name: 'anchorUnregister',
                value,
            }))
        )
    );

    steps: T[] = [];
    currentStep: T;
    direction: string = '';
    tourTitle: string = '';
    tourId: string = '';

    anchors: { [anchorId: string]: TourAnchorDirective } = {};
    private status: TourState = TourState.OFF;
    private isHotKeysEnabled = true;

    private overlay: HTMLDivElement;

    constructor(private modalService: NgbModal, private router: Router) {}

    initialize(steps: T[], titleData: any, stepDefaults?: T): void {
        if (steps && steps.length > 0) {
            this.status = TourState.OFF;
            this.tourTitle = titleData.tourTitle;
            this.tourId = titleData.tourId;
            this.steps = steps.map(step => Object.assign({}, stepDefaults, step));
            this.initialize$.next(this.steps);
        }
    }

    disableHotkeys(): void {
        this.isHotKeysEnabled = false;
    }

    enableHotkeys(): void {
        this.isHotKeysEnabled = true;
    }

    start(): void {
        this.startAt(0);
    }

    startAt(stepId: number | string): void {
        this.status = TourState.ON;
        this.goToStep(this.loadStep(stepId));
        this.start$.next();
        this.hideOverlay();
        this.addOverlay();
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationStart),
                first()
            )
            .subscribe(() => this.tryToHideStep());
    }

    async end() {
        this.status = TourState.OFF;
        this.hideStep(this.currentStep);
        this.currentStep = undefined;
        this.end$.next();
        this.hideOverlay();
        const shouldReuseRoute = this.router.routeReuseStrategy.shouldReuseRoute;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.modalService.dismissAll();
        await this.router.navigate(['../product-tour']);
        this.router.onSameUrlNavigation = 'ignore';
        this.router.routeReuseStrategy.shouldReuseRoute = shouldReuseRoute;
    }

    pause(): void {
        this.status = TourState.PAUSED;
        this.hideStep(this.currentStep);
        this.pause$.next();
    }

    resume(): void {
        this.status = TourState.ON;
        this.showStep(this.currentStep);
        this.resume$.next();
    }

    toggle(pause?: boolean): void {
        if (pause) {
            if (this.currentStep) {
                this.pause();
            } else {
                this.resume();
            }
        } else {
            if (this.currentStep) {
                this.end();
            } else {
                this.start();
            }
        }
    }

    next(): void {
        if (this.hasNext(this.currentStep)) {
            this.goToStep(this.loadStep(this.currentStep.nextStep || this.steps.indexOf(this.currentStep) + 1), 'next');
        }
    }

    hasNext(step: T): boolean {
        if (!step) {
            console.warn("Can't get next step. No currentStep.");
            return false;
        }
        return step.nextStep !== undefined || this.steps.indexOf(step) < this.steps.length - 1;
    }

    prev(): void {
        if (this.hasPrev(this.currentStep)) {
            this.goToStep(this.loadStep(this.currentStep.prevStep || this.steps.indexOf(this.currentStep) - 1), 'prev');
        }
    }

    hasPrev(step: T): boolean {
        if (!step) {
            console.warn("Can't get previous step. No currentStep.");
            return false;
        }
        return step.prevStep !== undefined || this.steps.indexOf(step) > 0;
    }

    goto(stepId: number | string): void {
        this.goToStep(this.loadStep(stepId));
    }

    register(anchorId: string, anchor: TourAnchorDirective): void {
        if (!anchorId) {
            return;
        }
        if (this.anchors[anchorId]) {
            throw new Error('anchorId ' + anchorId + ' already registered!');
        }
        this.anchors[anchorId] = anchor;
        this.anchorRegister$.next(this.anchors);
    }

    unregister(anchorId: string): void {
        if (!anchorId) {
            return;
        }
        delete this.anchors[anchorId];
        this.anchorUnregister$.next(anchorId);
        this.anchorRegister$.next(this.anchors);
    }

    getStatus(): TourState {
        return this.status;
    }

    getTourId(): string {
        return this.tourId;
    }

    isHotkeysEnabled(): boolean {
        return this.isHotKeysEnabled;
    }

    private goToStep(step: T, direction?: 'prev' | 'next'): void {
        this.modalService.dismissAll();
        // Tour menu - start
        const modalRef = this.modalService.open(
            TourMenuComponent,
            {
                backdrop: 'static',
                windowClass: 'custom-modal-class',
                backdropClass: 'no-overlay'
            }
        );
        modalRef.componentInstance.stepData = step;
        modalRef.componentInstance.steps = this.steps;
        modalRef.componentInstance.tourTitle = this.tourTitle;
        modalRef.componentInstance.tourId = this.tourId;
        modalRef.componentInstance.that = this;
        // Tour menu - end
        this.direction = direction;
        if (!step) {
            console.warn(`Can't go to non-existent step`);
            this.end();
            return;
        } else {
            this.goToStepOf(step, direction);
        }
    }

    private goToStepOf(step: T, direction?: 'prev' | 'next'): void {
        const anchorPromise = this.anchorRegister$.pipe(first()).toPromise();
        let navigatePromise: Promise<boolean> = anchorPromise;
        if (step.route && typeof step.route === 'string') {
            // prevent navigate if previous/next step has same route
            if (direction) {
                const stepId = direction === 'next' ? +step.stepId - 1 : +step.stepId + 1;
                // @ts-ignore
                const nextStep = this.steps.find(item => item.stepId === stepId);
                if (nextStep && nextStep.route === step.route) {
                    setTimeout(() => this.setCurrentStep(step), 150);
                    return;
                }
            }
            navigatePromise = this.router.navigateByUrl(step.route).then(() => anchorPromise);
        }
        navigatePromise.then(navigated => {
            if (navigated !== false) {
                if (navigated.hasOwnProperty(step.anchorId)) {
                    setTimeout(() => this.setCurrentStep(step), 100);
                } else {
                    setTimeout(() => {
                        if (navigated.hasOwnProperty(step.anchorId)) {
                            this.setCurrentStep(step);
                        } else {
                            setTimeout(() => this.setCurrentStep(step), 2000);
                        }
                    }, 1500);
                }
            }
        });
    }

    private loadStep(stepId: number | string): T {
        if (typeof stepId === 'number') {
            return this.steps[stepId];
        } else {
            return this.steps.find(step => step.stepId == stepId);
        }
    }

    private setCurrentStep(step: T): void {
        if (this.currentStep) {
            this.hideStep(this.currentStep);
        }
        this.currentStep = step;
        this.showStep(this.currentStep);
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationStart),
                first()
            )
            .subscribe(() => this.tryToHideStep());
    }

    private tryToHideStep() {
        if (this.currentStep && this.currentStep.hasOwnProperty('route')) {
            this.hideStep(this.currentStep);
        }
    }

    private showStep(step: T): void {
        const anchor = this.anchors[step && step.anchorId];
        if (!anchor || !step.hasAccess) {
            if (this.direction === 'prev' && this.hasPrev(step)) {
                const message: ConfirmModal = {
                    title: 'Access Denied',
                    content: `You do not have access to page '${step.title}'. Click OK to go to previous step.`,
                };
                this.modalConfirm(message).subscribe((data: any) => {
                    if (data) {
                        this.prev();
                    }
                });
            } else if (this.direction === 'next' && this.hasNext(step)) {
                const message: ConfirmModal = {
                    title: 'Access Denied',
                    content: `You do not have access to page '${step.title}'. Click OK to go to next step.`,
                };
                this.modalConfirm(message).subscribe((data: any) => {
                    if (data) {
                        this.next();
                    }
                });
            } else {
                if (step.stepId === this.steps[this.steps.length - 1].stepId) {
                    const modalConfirmMessage: ConfirmModal = {
                        title: 'Access Denied',
                        content: `You do not have access to page '${step.title}'. Please contact administrator for more details.`,
                    };
                    this.modalConfirm(modalConfirmMessage).subscribe((data: any) => {
                        if (data) {
                            console.warn("Can't attach to unregistered anchor with id " + step.anchorId);
                            this.end();
                            return;
                        }
                    });
                }
            }
        } else {
            anchor.showTourStep(step);
            this.stepShow$.next(step);
            this.errorMessage$.next('');
        }
    }

    modalConfirm(message: ConfirmModal) {
        const modalRef = this.modalService.open(ModalConfirmComponent, {
            centered: true,
            backdrop: 'static',
        });
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.showCancelButton = false;
        modalRef.componentInstance.confirmButtonText = 'OK';
        return modalRef.componentInstance.confirmOk;
    }

    private hideStep(step: T): void {
        const anchor = this.anchors[step && step.anchorId];
        if (!anchor) {
            return;
        }
        anchor.hideTourStep();
        this.stepHide$.next(step);
    }

    private addOverlay() {
        const overlay = document.createElement('div');
        overlay.style.cssText =
            'position: fixed; top: 0; right: 0; bottom: 0; z-index: 1040; left: 0; background: rgba(0,0,0,.4); opacity: 0; animation: show .35s forwards;';
        document.body.appendChild(overlay);
        // overlay.onclick = this.end.bind(this);
        this.overlay = overlay;
    }

    private hideOverlay() {
        if (this.overlay) {
            this.overlay.parentNode.removeChild(this.overlay);
            this.overlay = null;
        }
    }
}

@Injectable({
    providedIn: 'root',
})
export class TourStepService {
    template: TemplateRef<{ content: string }>;
}

export interface StepOption {
    stepId?: string;
    anchorId?: string;
    title?: string;
    content?: string;
    hasAccess?: boolean;
    route?: string | UrlSegment[];
    nextStep?: number | string;
    prevStep?: number | string;
    preventScrolling?: boolean;
    prevBtnTitle?: string;
    nextBtnTitle?: string;
    endBtnTitle?: string;
    pauseBtnTitle?: string;
    placement?:
        | Placement
        | 'after'
        | 'after-top'
        | 'after-bottom'
        | 'top-after'
        | 'top-before'
        | 'bottom-after'
        | 'bottom-before'
        | 'before'
        | 'before-top'
        | 'before-bottom'
        | 'below'
        | 'above';
}
