import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SideMenuComponent } from '@dagility-ui/kit';

@Component({
    selector: 'dp-progress-menu',
    templateUrl: './progress-menu.component.html',
    styleUrls: ['./progress-menu.component.scss'],
})
export class ProgressMenuComponent implements OnInit, AfterViewInit {
    @Input() options: { title: string; menu: any };
    @Output() drillDownClicked = new EventEmitter();

    @ViewChild(SideMenuComponent) sideMenu: SideMenuComponent;

    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _title: string;

    get title() {
        return this._title;
    }

    set title(str: any) {
        this._title = str;
    }

    ngOnInit() {
        this.title = this.options.title;
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.sideMenu) {
                this.options.menu[0].opened = true;
                this.sideMenu.handleClick(this.options.menu[0].children[0], this.sideMenu.sideSubMenuClicked, new Event('click'));
            }
        });
    }

    sideMenuClicked(value: any) {
        this.title = value.title;
        this.drillDownClicked.emit(value);
    }
}
