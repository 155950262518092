import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { OnDemandPreloadStrategy } from '@app/core/services/on-demand-preload-strategy';
import { PermissionGuard } from './shared/services/permission-guard.service';
import { DummyComponent, FirstLoginGuard } from './shared/services/admin/first-login.guard';

import { ServerErrorComponent } from './pages/server-error/server-error.component';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { CALIBRATE_MODULE_SEGMENT, MCM_MODULE_SEGMENT, NOSKRIPT_MODULE_SEGMENT, XPRESSO_MODULE_SEGMENT } from './app-routing.constants';
import { CalibrateComponent } from './pages/calibrate/calibrate.component';
import { XpressoComponent } from './pages/xpresso/xpresso.component';
import { ModulesResolver } from '@app/core/services/modules.resolver';
import { NoskriptComponent } from './pages/noskript/noskript.component';
import { McmComponent } from './pages/mcm/mcm.component';

const routes: Routes = [
    {
        path: '',
        canActivateChild: [PermissionGuard],
        children: [
            { path: '', canActivate: [FirstLoginGuard], component: DummyComponent },
            { path: 'admin', loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule) },
            { path: 'help-support', loadChildren: () => import('./pages/help-support/help-support.module').then(m => m.HelpSupportModule) },
            { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
            { path: 'user', loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule) },
            { path: 'product-tour', loadChildren: () => import('./pages/product-tour/product-tour.module').then(m => m.ProductTourModule) },
            {
                path: CALIBRATE_MODULE_SEGMENT,
                children: [
                    {
                        path: '**',
                        component: CalibrateComponent,
                        data: {
                            title: 'Calibrate',
                        },
                    },
                ],
            },
            {
                path: XPRESSO_MODULE_SEGMENT,
                component: XpressoComponent,
            },
            {
                path: NOSKRIPT_MODULE_SEGMENT,
                component: NoskriptComponent,
            },
            {
                path: MCM_MODULE_SEGMENT,
                component: McmComponent,
            },
        ],
    },
    {
        path: 'access-denied',
        component: AccessDeniedComponent,
    },
    {
        path: 'error',
        component: ServerErrorComponent,
    },
    {
        path: 'not-found',
        component: NotFoundComponent,
    },
    {
        path: ':moduleId',
        canActivate: [ModulesResolver],
        children: [{ path: '**', component: NotFoundComponent }],
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: OnDemandPreloadStrategy, relativeLinkResolution: 'legacy' }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
