<div class="model">
    <div class="model-header">
        <app-icon-remoter [width]="14" [height]="14" [iconName]="iconName"></app-icon-remoter>
        <span>{{ name }}</span>
    </div>
    <div class="container">
        <div class="item">
            <ng-container *ngIf="!ordered; else orderedTemplate">
                <div class="fields">
                    <div *ngFor="let datasetField of datasetFields">
                        <div #field class="field">
                            <ng-container *ngIf="!field.classList.contains('loading'); else loader">
                                <div class="d-flex">
                                    <app-icon-remoter *ngIf="fieldHasFieldRoleType(datasetField, 'PK')"
                                                      [color]="'WHITE'" [width]="15" [height]="15"
                                                      [iconName]="'KEY'"
                                    ></app-icon-remoter>
                                    <span
                                        [ngStyle]="{'margin-left': fieldHasFieldRoleType(datasetField, 'PK') ? '6px' : '0'}"
                                    >
                                        {{ datasetField.dsFieldName }}
                                    </span>
                                </div>
                                <div class="actions">
                                    <app-icon-remoter class="cursor-pointer"
                                                      (click)="deleteField(field, datasetField)"
                                                      [color]="'WHITE'"
                                                      [width]="12" [height]="12"
                                                      [iconName]="'DELETE'"
                                    ></app-icon-remoter>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <ng-container
                        *ngIf="!maxDroppedItems || (maxDroppedItems && datasetFields.length < maxDroppedItems)"
                    >
                        <div
                            *ngIf="dragStatus || loading"
                            class="drop-place"
                            (dragover)="$event.preventDefault()"
                            (drop)="onDropHandler($event)"
                        >
                            <ng-container *ngIf="!loading; else loader">
                                {{ dragPlaceTitle }}
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
</div>
<ng-template #loader>
    <lib-loader></lib-loader>
</ng-template>

<ng-template #orderedTemplate>
    <div class="fields" cdkDropList>
        <div *ngFor="let datasetField of datasetFields">
            <div cdkDrag #field class="field"
                 (cdkDragDropped)="moveMappingElement(datasetField, $event)"
            >
                <ng-container *ngIf="!field.classList.contains('loading'); else loader">
                    <div class="d-flex">
                        <app-icon-remoter *ngIf="fieldHasFieldRoleType(datasetField, 'PK')"
                                          [color]="'WHITE'" [width]="15" [height]="15"
                                          [iconName]="'KEY'"
                        ></app-icon-remoter>
                        <span
                            [ngStyle]="{'margin-left': fieldHasFieldRoleType(datasetField, 'PK') ? '6px' : '0'}"
                        >
                            {{ datasetField.dsFieldName }}
                        </span>
                    </div>
                    <div class="actions">
                        <app-icon-remoter class="cursor-pointer"
                                          (click)="deleteField(field, datasetField)" [color]="'WHITE'"
                                          [width]="12" [height]="12"
                                          [iconName]="'DELETE'"
                        ></app-icon-remoter>
                    </div>
                </ng-container>
            </div>
        </div>
        <ng-container
            *ngIf="!maxDroppedItems || (maxDroppedItems && datasetFields.length < maxDroppedItems)"
        >
            <div
                *ngIf="dragStatus || loading"
                class="drop-place"
                (dragover)="$event.preventDefault()"
                (drop)="onDropHandler($event)"
            >
                <ng-container *ngIf="!loading; else loader">
                    {{ dragPlaceTitle }}
                </ng-container>
            </div>
        </ng-container>
    </div>
</ng-template>
