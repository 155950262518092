import { InjectionToken } from '@angular/core';
import { Observable, of } from 'rxjs';

import { AnyWidgetModel } from '../models/any-widget.model';

export type DefaultPlaceholders<T = Record<string, any>> = (
    widget: AnyWidgetModel & { options: T },
    debug?: boolean
) => Observable<Record<string, any>>;

export const DEFAULT_PLACEHOLDERS = new InjectionToken<DefaultPlaceholders>('DefaultPlaceholders', {
    factory: () => () => of({}),
});
