import { AnyWidgetModel } from 'data-processor';
import { EnvironmentInjector, inject, Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { catchError, concatMap, first, map } from 'rxjs/operators';

import { ModalService, ModalWarningComponent } from '@dagility-ui/kit';

import { DATA_MORPH_AUTH } from 'data-processor/tokens';

@Injectable()
export class CanAddWidgetToDashboardService {
    private readonly rules: readonly CanAddWidgetRule[] = [checkToolCategoryRule];

    constructor(private injector: EnvironmentInjector, private modal: ModalService) {}

    check(widget: AnyWidgetModel): Observable<boolean> {
        return from(this.rules).pipe(
            concatMap(rule => this.injector.runInContext(() => rule(widget))),
            first<boolean>(Boolean, false),
            catchError(err => {
                if (err instanceof CanAddWidgetError) {
                    this.showErrorModal(err);
                } else {
                    console.error(err);
                }

                return of(false);
            })
        );
    }

    private showErrorModal({ header, message }: CanAddWidgetError) {
        return this.modal.open(
            ModalWarningComponent,
            {
                centered: true,
                windowClass: 'restrict-drop-widget',
            },
            {
                message: {
                    title: header,
                    content: message,
                    icon: 'warning',
                    iconFontSize: '20px',
                    iconColor: 'var(--da-warning-base)',
                },
                confirmButtonText: 'OK',
                showCancelButton: false,
            }
        );
    }
}

export type CanAddWidgetRule = (widget: AnyWidgetModel) => Observable<boolean>;

export const checkToolCategoryRule: CanAddWidgetRule = (widget: AnyWidgetModel): Observable<boolean> => {
    const authService = inject(DATA_MORPH_AUTH);

    return of(true).pipe(
        map(isActive => {
            if (!isActive || widget.toolCategoryType) {
                return true;
            }

            throw new CanAddWidgetError(
                'Tool Category Not Configured',
                `This widget does not have a tool category configured and cannot be added to the dashboard. ${
                    authService.isAdmin()
                        ? 'Go to Widget Settings and add the tool category'
                        : 'Contact admin to configure the tool category'
                }.`
            );
        })
    );
};

class CanAddWidgetError extends Error {
    constructor(public header: string, message: string) {
        super(message);
    }
}
