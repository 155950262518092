import { Injectable, Component } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root',
})
export class FirstLoginGuard implements CanActivate {
    constructor(private userService: UserService, private router: Router) {}

    canActivate(): boolean {
        const defaultPage = this.userService.defaultPage;

        if (defaultPage) {
            this.router.navigate([defaultPage], { replaceUrl: true });
        }

        return false;
    }
}

@Component({
    selector: '[dummy]',
    template: '<div>Dummy</div>',
})
export class DummyComponent {}
