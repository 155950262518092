import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

import { WatchVariable } from '../../../state/job-definition.state.model';

@Component({
    selector: 'dp-debug-watch',
    templateUrl: './debug-watch.component.html',
    styleUrls: ['./debug-watch.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugWatchComponent {
    @Input() watches: WatchVariable[] = [];

    @Output() addWatch = new EventEmitter();

    @Output() removeWatch = new EventEmitter<number>();

    @Output() evaluateWatch = new EventEmitter<{ index: number; expression: string }>();

    onBlur(index: number, expression: string) {
        if (!expression.trim()) {
            this.removeWatch.emit(index);
        } else {
            this.evaluateWatch.emit({ index, expression });
        }
    }
}
