<div class="modal-header">
    <h4 class="modal-title mt-0" *ngIf="message.title" id="modal-title">{{ message.title }}</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" [formGroup]="form">
    <lib-textarea
        label="{{message.content}}"
        formControlName="commit"
        [customHeight]="true"
        class="custom-height"
        [resize]="false"
    >
    </lib-textarea>
</div>
<div class="modal-footer">
    <button *ngIf="showCancelButton" type="button" class="btn btn-secondary" (click)="cancel()">{{ closeButtonText }}</button>
    <button *ngIf="showConfirmButton" type="button" ngbAutofocus class="btn btn-primary" (click)="confirm()">{{ confirmButtonText }}</button>
</div>
