import { Component, ElementRef, HostBinding } from '@angular/core';
import { facCheck, facSkipped } from '@dagility-ui/shared-components/icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '@dagility-ui/kit';
import {
    PublishComponentChangesDetailsComponent,
    PublishComponentDetails,
} from '../publish-component-changes-details/publish-component-changes-details.component';

@Component({
    selector: 'app-publish-component-changes-progress',
    templateUrl: './publish-component-changes-progress.component.html',
    styleUrls: ['./publish-component-changes-progress.component.scss'],
})
export class PublishComponentChangesProgressComponent {
    retryFn: (details: PublishComponentDetails[]) => void;

    allComponents: PublishComponentDetails[];

    publishedComponents: number[] = [];

    failedComponents: number[] = [];

    icons = {
        facCheck,
        facSkipped,
    };

    finished = false;

    state: 'minimized' | 'expanded' = 'expanded';

    @HostBinding('style.--progress')
    get progress() {
        return this.finished ? 100 : ((this.publishedComponents.length + this.failedComponents.length) * 100) / this.allComponents.length;
    }

    get isSucceeded() {
        return this.allComponents.length / this.publishedComponents.length === 1;
    }

    constructor(public activeModal: NgbActiveModal, private elRef: ElementRef, private modal: ModalService) {}

    toggleState() {
        this.state = this.state === 'minimized' ? 'expanded' : 'minimized';
        const parentWindow = this.elRef.nativeElement.closest('.modal');
        if (!parentWindow) {
            return;
        }
        const backdropEl = parentWindow.previousElementSibling;
        if (this.state === 'minimized') {
            backdropEl.dataset.minimized = true;
        } else {
            delete backdropEl.dataset.minimized;
        }
    }

    openDetails() {
        this.activeModal.close();
        this.modal.open(
            PublishComponentChangesDetailsComponent,
            {
                windowClass: 'publish-component-changes-details',
            },
            {
                retryFn: this.retryFn,
                details: this.allComponents.map(component => ({
                    id: component.id,
                    name: component.name,
                    projectName: component.projectName,
                    status: this.publishedComponents.includes(component.id) ? 'Success' : 'Failed',
                })),
            }
        );
    }
}
