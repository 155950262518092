<ng-container *ngTemplateOutlet="headerTemplateRef || defaultHeaderTemplate; context: { title: title, modal: modal }"></ng-container>
<ng-template #defaultHeaderTemplate>
    <div class="modal-header p-25px">
        <ng-container *ngTemplateOutlet="titleTemplateRef || defaultTitleTemplate; context: { title: title, modal: modal }"></ng-container>

        <ng-template #defaultTitleTemplate>
            <h6 class="modal-title">{{ title }}</h6>
        </ng-template>

        <div class="pull-right">
            <fa-icon class="fa zoom" icon="{{ isExpanded ? 'facCompress' : 'facExpand' }}" (click)="isExpanded = !isExpanded"></fa-icon>
            <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</ng-template>
<div class="modal-body p-0" [style.width.px]="bodyWidth" [style.maxHeight.px]="bodyHeight">
    <div>
        <perfect-scrollbar class="log scrollbar pb-4 bottom-border-radius" [style.maxheight.px]="logHeight">
            <div class="log-content p-4 bottom-border-radius">
                <div *ngIf="logs; else noData">{{ logs }}</div>
            </div>
        </perfect-scrollbar>
        <button class="btn btn-secondary btn-copy-to-clipboard pull-right" (click)="copyToClipboard()">
            Copy To Clipboard
        </button>
        <ng-template #noData>
            <span class="ml-15px no-components-text">There are no logs</span>
        </ng-template>
    </div>
</div>
