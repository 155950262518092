import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbDropdown, NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {ConfigureAccessPopupComponent} from 'data-processor/lib/plugins/tool-library/configure-access-popup/configure-access-popup.component';

@Component({
    selector: 'dp-configure-integration',
    templateUrl: './configure-integration.component.html',
    styleUrls: ['./configure-integration.component.scss'],
})
export class ConfigureIntegrationComponent {
    @ViewChild('dd') ddRef: NgbDropdown;

    @Input() items: any[];
    @Input() buttonText = 'Configure Integration';
    @Input() disabled: boolean;
    @Input() selected$: any;

    @Output() update = new EventEmitter();
    @Output() runToolWizard: EventEmitter<string> = new EventEmitter();

    constructor(private modalService: NgbModal, private modal: NgbActiveModal) {}

    isOpen() {
        return this.ddRef ? this.ddRef.isOpen() : false;
    }

    handleConfigureIntegration(plugin: any) {
        this.ddRef.close();
        this.modal.dismiss();

        const configureModalRef = this.modalService.open(ConfigureAccessPopupComponent, {
            windowClass: 'widget-modal',
            centered: true,
            backdrop: 'static',
            keyboard: false,
        });
        configureModalRef.componentInstance.plugin = Object.assign({}, plugin);
        configureModalRef.componentInstance.runToolWizard.subscribe((toolId: string) => this.runToolWizard.emit(toolId));
        configureModalRef.result.then(result => {
            this.update.emit();
        });
    }
}
