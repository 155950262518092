import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ENV_TOKEN } from '@app/tokens';

@Injectable({
    providedIn: 'root',
})

export class VOCFormService {
    private basePath = `${this.env.adminApiURL}`;
    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {}

    getVOCToken(): Observable<any> {
        return this.http.get(`${this.basePath}/tokenizer/token`);
    }
}
