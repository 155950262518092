<div *ngIf="formIssues.length" class="dropdown issue-toggle" [ngClass]="issueType" ngbDropdown placement="bottom-right" autoClose="true">
    <div class="dropdown-button pl-2 pr-2 pt-1 pb-1 cursor-pointer add-text" ngbDropdownToggle>
        {{ formIssues.length }} Issue{{ formIssues.length != 1 ? 's' : '' }}
    </div>
    <div
            class="dropdown-menu dropdown-menu-right font-10px pt-0 pb-0"
            style="max-height: 420px; width: 300px;"
            aria-labelledby="navbarDropdown"
            ngbDropdownMenu
    >
        <dp-edit-job-definition-issue-list
                style="max-height:inherit"
                class="h-100 w-100"
                [issuesList]="formIssues"
                (issueSelected)="issueSelected.emit($event)"
        ></dp-edit-job-definition-issue-list>
    </div>
</div>
