import { Component, ElementRef, HostListener, Inject, Input, OnDestroy, ViewChild } from '@angular/core';
import { UserService } from '@app/services';
import { kni } from '@dagility-ui/keep-ni';
import { CustomForm } from '../voc-form-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ENV_TOKEN } from '@app/tokens';
import { VOCFormService } from '../../voc-form.service';

@Component({
    selector: 'app-form-element',
    templateUrl: './form-element.component.html',
    styleUrls: ['./form-element.component.scss'],
})
export class FormElementComponent implements OnDestroy {
    loadingTemplate = true;
    @Input() modalService: NgbModal;
    @ViewChild('iframe', { static: false }) iframe: ElementRef;
    onMessageEventListner: Function;
    keepNiData: any = [];
    url: string = 'https://dev-voc.dagility.com/';

    constructor(@Inject(ENV_TOKEN) private env: Env, private us: UserService, private formService: VOCFormService) {
        const subscribeVal = kni().api.subscribe();
        if (subscribeVal && subscribeVal.parameters.componentPath) {
            this.keepNiData = JSON.parse(subscribeVal.parameters.componentPath);
        }
    }

    @HostListener('window:message', ['$event'])
    onMessage(event: any) {
        if (event.data && event.data.type === 'originInfoRequest') {
            if (this.iframe && this.iframe.nativeElement) {
                this.loadingTemplate = false;
                const data: CustomForm = this.getData();
                this.iframe.nativeElement.contentWindow.postMessage(data, '*');
            }
        }
        if (event.data && event.data.type === 'originTokenRequest') {
            this.formService.getVOCToken().subscribe((res: any) => {
                this.iframe.nativeElement.contentWindow.postMessage({ token: res.token, type: 'originTokenResponse' }, '*');
            });
        }
        if (event.data && event.data.type === 'ActionComplete') {
            this.modalService.dismissAll();
        }
        if (event.data && event.data.type === 'cancelRequest') {
            this.modalService.dismissAll();
        }
    }

    getData() {
        return {
            adminURL: this.env.adminApiURL,
            envId: window.location.origin,
            type: 'originInfoResponse',
            keepNi: this.keepNiData.length ? this.keepNiData : [{type: 'MODULE', name: 'PACE'}],
            email: this.us.user.email,
            token: '',
        };
    }

    ngOnDestroy(): void {
        if (this.onMessageEventListner) {
            this.onMessageEventListner();
        }
    }
}
