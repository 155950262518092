<div class="flex-basis-50">
    <div class="header">
        <span>Fields</span>
        <button *ngIf="hasSelectedFields(gridData)" class="btn btn-link size-s" (click)="handleCancelSelection(gridData)">
            <fa-icon class="mr-5px" icon="facClose" style="font-size: 6px"></fa-icon>
            Cancel selection
        </button>
    </div>
    <ng-container *ngIf="gridData.length else listPlaceholderTmpl">
        <perfect-scrollbar [style.maxHeight.px]="220">
            <ul class="data-list">
                <li *ngFor="let data of gridData" [class.selected]="data.selected" (click)="onSelectRow(data)">
                    <span>{{ modelNames[data.modelUid] }}{{ data.modelUid ? '.' : '' }}{{ data.field }}</span>
                </li>
            </ul>
        </perfect-scrollbar>
    </ng-container>
</div>
<div class="buttons-container">
    <div class="arrow-button" [class.disabled]="!hasSelectedFields(gridData)" (click)="handlePushSelected(gridData)">
        <fa-icon icon="chevron-right"></fa-icon>
    </div>
    <div class="arrow-button" [class.disabled]="!hasSelectedFields(gridDataSelected)" (click)="handlePushSelected(gridDataSelected)">
        <fa-icon icon="chevron-left"></fa-icon>
    </div>
</div>
<div class="flex-basis-50">
    <div class="header">
        <span>Grouped by</span>
        <button *ngIf="hasSelectedFields(gridDataSelected)" class="btn btn-link size-s" (click)="handleCancelSelection(gridDataSelected)">
            <fa-icon class="mr-5px" icon="facClose" style="font-size: 6px"></fa-icon>
            Cancel selection
        </button>
    </div>
    <ng-container *ngIf="gridDataSelected.length else listGroupPlaceholderTmpl">
        <perfect-scrollbar [style.maxHeight.px]="220">
            <ul class="data-list">
                <li *ngFor="let data of gridDataSelected" [class.selected]="data.selected" (click)="onSelectRow(data)">
                    <span>{{ modelNames[data.modelUid] }}{{ data.modelUid ? '.' : '' }}{{ data.field }}</span>
                    <fa-icon icon="facTrash" class="cursor-pointer" (click)="$event.stopPropagation(); handleRemove(data)"></fa-icon>
                </li>
            </ul>
        </perfect-scrollbar>
    </ng-container>
</div>

<ng-template #listGroupPlaceholderTmpl>
    <div class="list-placeholder">
        <h3>No fields selected</h3>
        <span class="mt-8px disable">Add fields here to use for grouping</span>
    </div>
</ng-template>

<ng-template #listPlaceholderTmpl>
    <div class="list-placeholder">
        <h3>No fields</h3>
        <span class="mt-8px disable">All fields are used for grouping</span>
    </div>
</ng-template>
