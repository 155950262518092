<ng-container *ngIf="!errorState; else errorRef">
    <ng-container *ngIf="!loading; else loadingRef">
        <div *ngIf="formGroup" [formGroup]="formGroup" class="row p-1">
            <lib-dropdown
                *ngIf="!hideTool"
                [items]="toolsTree"
                [optionTemplateRef]="toolOptionTemplateRef"
                [labelTemplateRef]="toolLabelTemplateRef"
                [appendTo]="appendTo"
                [ngClass]="{ 'col-4' : !hideUnit, 'col-6' : hideUnit }"
                label="{{ label }}"
                labelKey="toolName"
                valueKey="toolId"
                formControlName="toolId"
                class="lib-dropdown min-width-250 mb-7px"
                id="tool"
                (change)="handleToolChange($event)"
            >
                <ng-template #toolOptionTemplateRef let-item="item" let-item$="item$" let-labelKey="labelKey">
                    <div [class.disabled]="markDisabledTools && !item.toolEnabled"
                         class="option-template py-12px px-15px">
                        <img
                            *ngIf="item.toolImageUrl | imagePath"
                            class="option-icon mr-15px"
                            [src]="item.toolImageUrl | imagePath"
                            alt=""
                        />
                        <span *ngIf="!item.toolImageUrl" class="option-icon dummy"></span>
                        <span class="option-label">{{ item[labelKey] }}</span>
                    </div>
                </ng-template>

                <ng-template #toolLabelTemplateRef let-item="item" let-labelKey="labelKey">
                    <div [class.disabled]="markDisabledTools && !item.toolEnabled" class="option-template">
                        <img
                            *ngIf="item.toolImageUrl | imagePath"
                            class="option-icon mr-15px"
                            [src]="item.toolImageUrl | imagePath"
                            alt=""
                        />
                        <span class="option-label">{{ item[labelKey] }}</span>
                    </div>
                </ng-template>
                <ng-container *ngIf="contextHelpTemplate">
                    <ng-template *ngTemplateOutlet="contextHelpTemplate"></ng-template>
                </ng-container>
            </lib-dropdown>
            <ng-container
                *ngIf="selectedTool && selectedTool.grouped"
            >
                <lib-dropdown
                    *ngIf="groups$ | obsWithStatus | async as groups"
                    [loading]="groups.loading"
                    [items]="groups.value"
                    [addTag]="selectedTool.canAddCustomGroup && addGroup"
                    [addTagFunction]="selectedTool.canAddCustomGroup && addGroup ? addUnitGroupFn : undefined"
                    [clearable]="true"
                    [libDropdownAddTagValidator]="Validators.pattern('[/\\w_\\-$~#@%]*')"
                    [libDropdownAddTagErrorMessage]="'You are allowed to use characters: A-Z, a-z, /, _, -, ~, #, @, %'"
                    [label]="selectedTool.groupLabel || 'Group'"
                    [editableSearchTerm]="true"
                    [optionTemplateRef]="unitOptionTemplateRef"
                    [labelTemplateRef]="unitLabelTemplateRef"
                    [class.muted-input]="selectedUnitGroup?.deleted"
                    [appendTo]="appendTo"
                    [searchTermOnly]="true"
                    [ngClass]="{ 'col-4' : !hideUnit, 'col-6' : hideUnit }"
                    (searchTerm)="handleFilter('groups', $event)"
                    (close)="onCloseResetFilter('groups')"
                    (change)="handleUnitGroupChange($event)"
                    formControlName="unitGroupId"
                    labelKey="name"
                    valueKey="id"
                    class="unit-dropdown min-width-250 mb-7px"
                    id="name"
                >
                </lib-dropdown>
            </ng-container>
            <ng-container *ngIf=" !hideUnit &&
                    ((selectedTool && selectedTool.grouped && selectedUnitGroup && selectedUnitGroup.id) ||
                        (selectedTool && !selectedTool.grouped))"
            >
                <lib-dropdown
                    *ngIf="units$ | obsWithStatus | async as units"
                    [loading]="units.loading"
                    [items]="units.value"
                    [addTag]="addUnit"
                    [addTagFunction]="addUnit ? addUnitFn : undefined"
                    [libDropdownAddTagValidator]="Validators.pattern('[\\w_\\-$~#@%]+')"
                    [libDropdownAddTagErrorMessage]="'You are allowed to use characters: A-Z, a-z, _, -, ~, #, @, %'"
                    [clearable]="true"
                    [label]="selectedTool.unitLabel || 'Unit'"
                    [editableSearchTerm]="true"
                    [optionTemplateRef]="unitOptionTemplateRef"
                    [labelTemplateRef]="unitLabelTemplateRef"
                    [class.muted-input]="selectedUnit?.deleted"
                    [appendTo]="appendTo"
                    [searchTermOnly]="true"
                    (searchTerm)="handleFilter('units', $event)"
                    (close)="onCloseResetFilter('units')"
                    (change)="handleUnitChange($event)"
                    formControlName="unitId"
                    labelKey="name"
                    valueKey="id"
                    class="unit-dropdown col-4 min-width-250"
                    id="name-2"
                >
                </lib-dropdown>
            </ng-container>
        </div>
    </ng-container>
</ng-container>
<div class="mt-30px pl-1" id="control-container">
    <ng-container #controlContainer></ng-container>
</div>

<ng-template #loadingRef>
    <div class="w-100 d-inline-flex justify-content-center">
        <div class="loader"></div>
    </div>
</ng-template>

<ng-template #errorRef>
    <div class="w-100 d-inline-flex justify-content-center">
        Error
    </div>
</ng-template>

<ng-template #unitOptionTemplateRef let-item="item" let-item$="item$" let-labelKey="labelKey">
    <div [class.disabled]="item.deleted" class="option-template">
        <span class="option-label">{{ item[labelKey] }}</span>
    </div>
</ng-template>

<ng-template #unitLabelTemplateRef let-item="item" let-labelKey="labelKey">
    <div [class.disabled]="item.deleted" class="option-template">
        <span class="option-label">{{ item[labelKey] }}</span>
    </div>
</ng-template>
