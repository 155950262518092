import { Component, Inject, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CiCdProperty, PropertiesEditorComponent } from '@dagility-ui/shared-components';
import { Variable } from '../../models/job-definition/variable';

@Component({
    selector: 'dp-start-debug-variables',
    templateUrl: './start-debug-variables.component.html',
    styleUrls: ['./start-debug-variables.component.scss']
})
export class StartDebugVariablesComponent implements OnInit {
    @Input() variables: Variable[] = [];

    @ViewChild(PropertiesEditorComponent) propEditor: PropertiesEditorComponent;

    formGroup: FormGroup = this.fb.group({
        variables: [[]],
    });

    constructor(
        private fb: FormBuilder,
        private modal: NgbActiveModal,
        @Inject('templateProvider') public templateProvider: Record<string, TemplateRef<any>>
    ) {}

    ngOnInit() {
        this.formGroup.patchValue({
            variables: this.variables,
        });
    }

    handleCloseClick() {
        this.modal.dismiss();
    }

    handleSave() {
        if (this.formGroup.valid) {
            this.modal.close(
                (this.formGroup.get('variables').value as CiCdProperty[]).reduce(
                    (acc, { name, value }) => ({
                        ...acc,
                        [name]: value,
                    }),
                    {}
                )
            );
        } else {
            this.propEditor.validateFormAndDisplayErrors();
        }
    }
}
