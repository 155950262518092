import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Command, PortfolioRelationType, RelationType, UserStatus } from '@app/models';
import { Page, Pageable } from '@dagility-ui/kit';
import { ENV_TOKEN } from '@app/tokens';
import { shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class MyScopeService {
    private static readonly API_TREE_ROOT = '/usertree/usertree';
    private static readonly API_USERS_ROOT = '/usertree/users';
    private static readonly API_APPS_ROOT = '/usertree/apps';
    private static readonly API_RELATION_DETAILS_ROOT = '/usertree/user';
    private static readonly API_APPLICATION_RAW_ROOT = '/usertree/application';
    private static readonly API_APPLY_CHANGES = '/usertree/changes';
    private static readonly API_APPLY_CHANGES_CONTRIBUTORS = '/usertree/contributors-changes';
    private static readonly API_VALIDATE_CYCLES = '/usertree/verify';
    private static readonly API_PROJECT_USERS = '/usertree/project_users';
    private static readonly API_PROJECT_USERS_KEYCLOAK = '/usertree/project_users_with_keycloak';
    private readonly API_ADMIN_URL = this.env.adminApiURL;
    private readonly featureOnOffApi = `${this.env.adminApiURL}/feature-on-off`;

    private approvalModeIsActive$: Observable<boolean>;

    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {}

    getUnitUsers(unitId: number) {
        return this.http.get<Person[]>(`${this.API_ADMIN_URL}/usertree/unit-users/${unitId}`);
    }

    getAppsStatuses() {
        return this.http.get<Record<string, number>>(`${this.API_ADMIN_URL}/usertree/app-statuses`);
    }

    getContributorsCount() {
        return this.http.get<Record<string, number>>(`${this.API_ADMIN_URL}/usertree/app-contributors`);
    }

    getRawTree(key: string) {
        return this.http.get<UserTree>(`${this.API_ADMIN_URL}${MyScopeService.API_TREE_ROOT}/${key}`);
    }

    getRawApplication(name: string) {
        return this.http.get<App>(`${this.API_ADMIN_URL}${MyScopeService.API_APPLICATION_RAW_ROOT}/${name}`);
    }

    validateCyclicRelationships(changes: Command[]) {
        return this.http.post<ModificationResult>(`${this.API_ADMIN_URL}${MyScopeService.API_VALIDATE_CYCLES}`, changes);
    }

    registerKeycloakUser(userId: string) {
        return this.http.get<any>(`${this.API_ADMIN_URL}/usertree/register-keycloak-user/${userId}`);
    }

    getUsersByOffset(offset: number, size: number, filter: string, inactiveUsers?: string[]) {
        let params = new HttpParams({
            fromObject: {
                offset: offset.toString(),
                size: size.toString(),
                filter,
            },
        });
        if (inactiveUsers && inactiveUsers.length > 0) {
            params = params.set('inactive', inactiveUsers.join(','));
        }

        return this.http.get<any>(`${this.API_ADMIN_URL}${MyScopeService.API_USERS_ROOT}`, {
            params,
        });
    }

    getUsersFromKeycloak(offset: number, size: number, filter: string) {
        const params = new HttpParams({
            fromObject: {
                offset: offset.toString(),
                size: size.toString(),
                filter,
            },
        });

        return this.http.get<{ users: Person[]; offset: number; last: boolean }>(`${this.API_ADMIN_URL}/usertree/keycloak-users`, {
            params,
        });
    }

    getProjectUsers(size: number, filter: string, users: any[], keycloak?: boolean) {
        const projectUsersApi = keycloak ? MyScopeService.API_PROJECT_USERS_KEYCLOAK : MyScopeService.API_PROJECT_USERS;
        const url = `${this.API_ADMIN_URL}${projectUsersApi}`;

        let params = new HttpParams().set('size', `${size}`);
        if (filter) {
            params = params.set('filter', filter);
        }

        if (users && users.length) {
            params = keycloak
                ? params.set('usersIdsAndKeycloakIds', JSON.stringify(users))
                : params.set('userIds', users.map(user => user.id).join(','));
        }

        return this.http.get<{ users: Person[]; last: boolean }>(url, { params });
    }

    getApps(page: Pageable, filter?: string) {
        let params = page.create();
        if (filter) {
            params = params.set('filter', filter);
        }

        return this.http.get<Page<App>>(`${this.API_ADMIN_URL}${MyScopeService.API_APPS_ROOT}`, { params });
    }

    getCurrentRelationsOfUser(login: string) {
        return this.http.get<Person>(`${this.API_ADMIN_URL}${MyScopeService.API_RELATION_DETAILS_ROOT}/${login}`);
    }

    saveHistoryChanges(changes: Command[], keycloak?: boolean) {
        const changesApi = keycloak ? MyScopeService.API_APPLY_CHANGES_CONTRIBUTORS : MyScopeService.API_APPLY_CHANGES;
        return this.http.post<ModificationResult>(`${this.API_ADMIN_URL}${changesApi}`, changes);
    }

    queueChanges(change: Command) {
        return this.saveHistoryChanges([change]);
    }

    checkApprovalMode(): Observable<boolean> {
        if (!this.approvalModeIsActive$) {
            this.approvalModeIsActive$ = this.http.get<boolean>(`${this.featureOnOffApi}/status/Approvals`).pipe(shareReplay(1));
        }

        return this.approvalModeIsActive$;
    }
}

export interface UserTree {
    users: Record<string, any>;
    followed: any[];
    relatedApps: any[];
    apps: Record<string, any>;
}

export interface ModificationResult {
    status: string;
    code: number;
    message: string;
}

export interface Person {
    id?: number;
    name: string;
    login?: string;
    position?: string;
    status?: UserStatus;
    newFromKeycloak?: boolean;
    keycloakId?: string;

    appsCount?: number;
    usersCount?: number;

    parent?: Person;
    imagePath?: string;

    applications?: App[];
    applicationFollowerRelationships?: App[];
    contributorRelationships?: App[];
    subordinates?: Person[];
    userFollowerRelationships?: Person[];

    followedUsers?: Person[];
    relatedApps?: UserApp[];
}

export interface UserApp {
    id: number;
    name: string;
    type: RelationType;
}

export interface AppsAndPortfolios {
    apps: App[];
    portfolios: ShortenPortfolio[];
}

export interface App {
    id: number;
    name: string;
    owner: Person;
    contributors?: Person[];
    followersCount?: number;
    contributorsCount?: number;
    active: boolean;
    teamsCount?: number;
    type?: PortfolioRelationType;
}

export interface ShortenPortfolio {
    id: number;
    name: string;
    description: string;
    owner: Person;
    type?: PortfolioRelationType;
    teamsCount?: number;
}
