import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DashboardTabState } from '../../state/dp-dashboard.state.model';
import { DpDashboardStore, removeExtraSpaces } from '../../state/dp-dashboard.store';
import { DataMorph } from '../../models/dp-dashboard.model';
import { ENTITY_NAME } from '../../directives/entity-name.control';
import { CreateTabStepComponent } from '../create-tab/create-tab.component';
import { FormValidation } from '../../services/form-validation';

export type EditedTabOptions = Pick<DashboardTabState, 'name' | 'options'>;

@Component({
    selector: 'dp-edit-tab',
    templateUrl: './dp-edit-tab.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: ENTITY_NAME, useValue: 'Tab' }],
})
export class DpEditTabComponent implements OnInit, CreateTabStepComponent {
    @Input() tab: EditedTabOptions;
    @Input() headerText = 'Edit';
    @Input() tabVisibility: DataMorph.DashboardTabVisibility;
    @Input() createTab = false;

    form = this.fb.group({
        name: ['', [Validators.required, Validators.pattern(`[- _A-Za-z0-9]*[A-Za-z0-9]$`)]],
        options: this.fb.group({
            tabOrder: 0,
        }),
    });

    private formValidation = inject(FormValidation);

    constructor(private fb: FormBuilder, private modal: NgbActiveModal, private cdr: ChangeDetectorRef, private store: DpDashboardStore) {}

    ngOnInit() {
        if (this.tab) {
            this.form.patchValue({
                name: this.tab.name,
                options: {
                    tabOrder: this.tab.options?.tabOrder ?? 0,
                },
            });
        }

        if (this.tabVisibility === 'SHOW_ACTIVE') {
            (this.form.get('options') as FormGroup).addControl(
                'resetEventDependencies',
                new FormControl(this.tab ? !!this.tab.options?.resetEventDependencies : false)
            );
        }
    }

    handleSave() {
        this.runSaveValidators();

        if (!this.form.valid) {
            return;
        }

        this.modal.close(this.form.value);
    }

    handleCancel() {
        this.modal.dismiss();
    }

    runSaveValidators() {
        if (this.tab?.name !== this.form.value.name && this.store.getTabNames().has(removeExtraSpaces(this.form.value.name))) {
            this.formValidation.appendError(this.form.get('name'), 'unique');
            this.form.get('name').markAsTouched();
            this.cdr.detectChanges();
        }
    }
}
