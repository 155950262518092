<ng-container *ngIf="items$ | async as items">
    <ng-container *libLet="selectionModel.size() === items.length as allSelected">
        <div
            *libLet="!allSelected && !!selectionModel.size() as undefinedState"
            class="filter-item d-flex select-toggle mb-2"
            (click)="allSelected || undefinedState ? selectionModel.clear() : selectAll(items)"
        >
            <checkbox
                [undefinedState]="undefinedState"
                [value]="undefinedState ? undefined : allSelected"
                [disabled]="disabled"
                [loading]="disabled"
            >
                <span class="body2 toggle-label" [class.disabled]="disabled">
                    <ng-container *ngIf="selectionModel.size()">
                        <ng-container *ngIf="allSelected">
                            All Selected
                        </ng-container>
                        <ng-container *ngIf="undefinedState">
                            Unselect All
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="selectionModel.isEmpty()">
                        Select All
                    </ng-container>
                </span>
            </checkbox>

            <button
                data-test-id="inverse-button"
                #inverseButton
                class="inverse-button btn btn-link btn-sm ml-auto default-size body2 py-1 px-3"
                (click)="invertSelection(items); $event.stopPropagation(); inverseButton.blur()"
            >
                Invert
                <fa-icon class="ml-2" icon="refreshRight"></fa-icon>
            </button>
        </div>
    </ng-container>

    <ng-container *ngFor="let item of items">
        <div
            *libLet="selectionModel.isSelected(item.value) as isSelected"
            data-test-id="filter-item"
            class="filter-item checkbox-container"
            [class.selected]="isSelected"
            (click)="!disabled && selectionModel.toggle(item.value)"
        >
            <checkbox [value]="isSelected" [disabled]="disabled" [loading]="disabled"></checkbox>
            <span
                data-test-id="filter-label"
                class="text-truncate body1"
                libTooltipWhenOverflow
                triggers="manual"
                container="body"
                [ngbTooltip]="item.label"
                >{{ item.label }}</span
            >
            <dp-preferred-item-icon [item]="item"></dp-preferred-item-icon>
        </div>
    </ng-container>
</ng-container>
