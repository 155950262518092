<h3>Thresholds</h3>
<div class="d-flex justify-content-between my-3">
    <span class="btn-link cursor-pointer" (click)="addThreshold()">+ Add one more Threshold</span>
</div>

<ng-container formArrayName="colorThreshold">
    <div class="mb-2 d-flex p-2" *ngFor="let thresholds of thresholdsArray.controls; let i = index" [formGroup]="thresholds">
        <div class="row w-100">
            <div class="ml-2 range-block">
                <lib-input class="mb-1" label="From" formControlName="from"></lib-input>
                <checkbox formControlName="includingFrom">Including Limit Value</checkbox>
            </div>
            <div class="ml-3 range-block">
                <lib-input class="mb-1" label="To" formControlName="to"></lib-input>
                <checkbox formControlName="includingTo">Including Limit Value</checkbox>
            </div>
            <lib-input class="range-block ml-3" label="Label" formControlName="label"></lib-input>
            <div class="ml-3 flex-column color-block">
                <dp-widget-color-picker
                    class="align-self-start d-block"
                    label="Color"
                    formControlName="color"
                    cpPosition="top-left"
                ></dp-widget-color-picker>
                <fa-icon
                    icon="facTrash"
                    class="delete-column-icon mt-1 trash-icon-block cursor-pointer"
                    aria-hidden="true"
                    (click)="removeThreshold(i)"
                    [ngbTooltip]="'Delete threshold'"
                    placement="top"
                    [openDelay]="800"
                    container="body"
                ></fa-icon>
            </div>
        </div>
    </div>
</ng-container>
