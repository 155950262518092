import { Component, Input, Output, EventEmitter, HostListener, ViewChild, OnChanges, SimpleChanges } from '@angular/core';

import { WorkflowActionLine } from '../workflow-actions.line';
import { WorkflowActionBlock } from '../workflow-action.block';
import { SvgZoomDirective } from '@dagility-ui/shared-components';
import { ModalService } from '@dagility-ui/kit';

@Component({
    selector: 'dp-job-definition-builder-view',
    templateUrl: './job-definition-builder-view.component.html',
    styleUrls: ['./job-definition-builder-view.component.scss'],
})
export class JobDefinitionBuilderViewComponent implements OnChanges {
    @Input() layout: {
        lines: WorkflowActionLine[];
        blocks: WorkflowActionBlock[];
    };
    @Input() debugMode: boolean;
    @Input() debuggedBlockName: string;

    @Output() dropped = new EventEmitter();
    @Output() delete = new EventEmitter();
    @Output() breakpointToggle = new EventEmitter();
    @Output() editAction = new EventEmitter<WorkflowActionBlock>();

    @ViewChild(SvgZoomDirective, { static: false }) zoom: SvgZoomDirective;

    constructor(private modalService: ModalService) {}

    activeBlock: WorkflowActionBlock;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.layout) {
            this.activeBlock = null;
        }
    }

    @HostListener('document:keyup', ['$event'])
    handleKeyPress({ key }: KeyboardEvent) {
        if (this.debugMode || this.modalService.hasOpenModals()) {
            return;
        }

        if (['Delete'].includes(key) && this.activeBlock) {
            this.delete.emit(this.activeBlock);
        }
    }

    onActiveBlockChange(block: WorkflowActionBlock) {
        if (this.debugMode) {
            return;
        }

        this.activeBlock = block;
    }

    onEditBlock(block: WorkflowActionBlock) {
        this.activeBlock = null;
        this.editAction.emit(block);
    }

    trackByActions(index: number, { name }: WorkflowActionBlock) {
        return name;
    }
}
