<div
    class="row flex-1"
    [ngClass]="
        height == 'max'
            ? 'chart-height-500'
            : height == 'min'
            ? 'chart-height-60'
            : height == 'moderate'
            ? 'chart-height-200'
            : height == 'auto'
            ? ''
            : filterCount >= 4
            ? 'chart-height-280'
            : 'chart-height-320'
    "
    [class.chart--adjust-margins]="!disableMarginsAdjustment"
>
    <div [ngClass]="showTable ? 'col-6' : 'col-12 d-flex flex-column flex-1'" class="h-100">
        <div
            *ngIf="chartOptions"
            class="chart"
            [eChart]="chartOptions"
            [disableMarginsAdjustment]="disableMarginsAdjustment"
            [checkHostResize]="checkHostResize"
            (initialized)="onEChartInitialized($event)"
        ></div>
        <div class="treemap-hint pt-1" *ngIf="needTreemapHint">
            *Use mouse scroll to navigate the widget
        </div>
    </div>
    <div *ngIf="showTable" class="col-6">
        <lib-ag-grid
            class="d-block chart-table"
            *ngIf="!isObjectEmpty(chartTableData)"
            [tableData]="chartTableData"
            [pinnedBottomRowData]="pinnedBottomRowData"
            gridHeight="100%"
        ></lib-ag-grid>
    </div>
</div>
