import { Inject, Injectable, Optional } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';
import { ArrayByFieldDuplication, DropdownItem, generateUUID, isDefined } from '@dagility-ui/kit';

import { WidgetExtensionInnerFormComponent, WidgetFilterType, WidgetQueryDto } from 'data-processor';
import {
    AnyWidgetModel,
    AsyncQuery,
    ChartOptions,
    FILTER_DEFAULT_SIZES,
    ProgressColor,
    TileWidgetRating,
    TileWidgetSettings,
    WidgetAccordionColumn,
    WidgetAdditionalTemplate,
    WidgetColorThreshold,
    WidgetDrilldown,
    WidgetEventDependency,
    WidgetEventHandler,
    WidgetFilter,
    widgetFilterMatcher,
    WidgetHelp,
    WidgetHelpInsight,
    WidgetQuery,
    WidgetSerie,
    WidgetStatus,
    WidgetTableColumn,
    WidgetType,
} from '../models/any-widget.model';
import {
    hasGridOptions,
    hasHeader,
    hasHideLegendControl,
    hasStatusOrderAndStatusColor,
    hasStatusValueAndIcon,
    hasThreshold,
    hasTooltipFormatter,
    isBarChartWidget,
    isDynamicFilter,
    isDynamicWidthFilter,
} from '../services/widget-builder.util';
import { GridsterOptionsService } from '../../dashboard/services/gridster/gridster-options.service';
import {
    DEFAULT_HEIGHT_PROPORTION,
    DEFAULT_WIDTH_PROPORTION,
    MIN_HEIGHT_PROPORTION,
    MIN_WIDTH_PROPORTION,
} from '../../dashboard/services/gridster/angular2gridster.const';
import { WidgetWorkflow } from '../services/widget.flow';
import { GLOBAL_FILTERS_EDITOR } from 'data-processor/lib/widget-library/widget-builder/providers/global-filters-editor.token';
import { DATA_MORPH_FEATURE_TOGGLE, DataMorphFeatureToggleService } from 'data-processor/tokens';

export const QUERY_RESULT_PLACEHOLDER = 'result';

function dependentValueEmpty(): ValidatorFn {
    return (control: AbstractControl) => {
        if (!control.parent) {
            return null;
        }

        const typeControl = control.parent.get('type') as FormControl;

        if (typeControl.value === WidgetFilterType.HIDDEN) {
            return null;
        }

        const valueDependencyControl = control.parent.get('valueDependency');

        if (!!valueDependencyControl.value) {
            return Validators.required(control);
        }
    };
}

@Injectable()
export class WidgetBuilderFacade {
    get filters(): FormArray {
        return this.form.get('filters') as FormArray;
    }

    get series(): FormArray {
        return this.form.get('series') as FormArray;
    }

    get colorThreshold(): FormArray {
        return this.form.get('colorThreshold') as FormArray;
    }

    get accordionColumns(): FormArray {
        return this.form.get('accordionColumns') as FormArray;
    }

    get columns(): FormArray {
        return this.form.get('columns') as FormArray;
    }

    get eventDependencies(): FormArray {
        return this.form.get('eventDependencies') as FormArray;
    }

    get query(): FormArray {
        return this.form.get('query') as FormArray;
    }

    get formState(): FormGroup {
        return this.form;
    }

    constructor(
        private fb: FormBuilder,
        @Optional() private gridsterOptionsService: GridsterOptionsService,
        @Inject(GLOBAL_FILTERS_EDITOR) private globalFiltersEditor: boolean,
        @Inject(DATA_MORPH_FEATURE_TOGGLE) private ft: DataMorphFeatureToggleService
    ) {}

    form: FormGroup;
    accordion: NgbAccordion;
    widget: AnyWidgetModel;
    dataSources$: Observable<DropdownItem[]>;
    anyWidgetId: number;
    externalOptions: Record<string, any>;
    widgetWorkflow: WidgetWorkflow;
    currentWidget: AnyWidgetModel;
    extensionsComponent: WidgetExtensionInnerFormComponent[] = [];
    externalWidgetId: number;
    extensions: any[] = [];
    dirty$ = new BehaviorSubject(false);
    fromClone = false;
    firstRender = true;
    queries$: Observable<WidgetQueryDto[]>;
    queriesMap$: Observable<Record<string, WidgetQueryDto[]>>;

    toFormArray = <T>(mapFn: (el: T) => AbstractControl) => (arr: T[]) => this.fb.array(arr.map(mapFn));

    // eslint-disable-next-line @typescript-eslint/member-ordering
    private getFilterControlsByType = widgetFilterMatcher({
        DROPDOWN: ({ label, dynamic, multiple, clearable, dependencies }) =>
            this.fb.group({
                label: [label || '', Validators.required],
                dynamic: !!dynamic,
                multiple: !!multiple,
                dependencies: [dependencies || []],
                clearable: clearable ?? true,
            }) as FormGroup,
        RANGE: () => this.fb.group({}),
        INPUT: ({ label, buttonText }) =>
            this.fb.group({
                label: [label || '', Validators.required],
                buttonText: [buttonText || ''],
            }) as FormGroup,
        CHECKBOX: ({ label, description }) =>
            this.fb.group({
                label: [label || '', Validators.required],
                description: [description || ''],
            }) as FormGroup,
        DATE: ({ label }) =>
            this.fb.group({
                label: [label || '', Validators.required],
            }) as FormGroup,
        HIDDEN: ({ query, dependencies }) =>
            this.fb.group({
                query: this.buildQueryForm(query || ({} as any), { fromFilter: true, hiddenFilter: true }),
                dependencies: [dependencies || []],
            }) as FormGroup,
        NEW_RANGE: ({ label, showDaysCount }) =>
            this.fb.group({
                showDaysCount,
                label: [label || '', Validators.required],
            }) as FormGroup,
        COMPLEX_NAMED_DROPDOWN: ({ label }) => {
            return this.fb.group({
                label: [label || '', Validators.required],
            }) as FormGroup;
        },
        RADIO_GROUP: ({ label, dynamic }) =>
            this.fb.group({
                label: [label || '', Validators.required],
                dynamic: !!dynamic,
            }) as FormGroup,
        _: () => this.fb.group({}),
    });
    // eslint-disable-next-line @typescript-eslint/member-ordering
    private buildFiltersForm = this.toFormArray<WidgetFilter>(this.buildFilterGroup.bind(this));
    // eslint-disable-next-line @typescript-eslint/member-ordering
    private buildAccordionColumnsForm = this.toFormArray<WidgetAccordionColumn>(this.buildAccordionColumnGroup.bind(this));
    // eslint-disable-next-line @typescript-eslint/member-ordering
    private buildColumnsForm = this.toFormArray<WidgetTableColumn>(this.buildColumnGroup.bind(this));
    // eslint-disable-next-line @typescript-eslint/member-ordering
    private buildEventDependenciesForm = this.toFormArray<WidgetEventDependency>(this.buildEventDependencyGroup.bind(this));

    buildForm(
        widget: AnyWidgetModel,
        {
            basicChartOptionsControlsAvailable,
            layerLevel,
            newWidget,
            isRoot,
        }: { basicChartOptionsControlsAvailable?: boolean; layerLevel?: boolean; newWidget?: boolean; isRoot?: boolean }
    ): FormGroup {
        const widgetForm = this.fb.group(
            {
                id: widget.id || generateUUID(),
                common: this.fb.group({
                    type: [widget.type || null, Validators.required],
                }),
                chartOptions: this.buildChartOptionsForm(widget.chartOptions || ({} as any), widget.type),
                series: this.buildWidgetSeriesGroup(widget.series || [], widget.type),
                colorThreshold: this.buildWidgetColorThresholdGroup(widget.colorThreshold || []),
                accordionColumns: this.buildAccordionColumnsForm(widget.accordionColumns || []),
                columns: this.buildColumnsForm(widget.columns || []),
                eventDependencies: this.buildEventDependenciesForm(widget.eventDependencies || []),
                colors: this.buildColorsForm(widget.colors || []),
                tiles: this.buildTilesForm(widget.tiles || []),
                additionalTemplates: this.fb.array(
                    (widget.additionalTemplates || []).map(template => this.buildAdditionalTemplateForm(template))
                ),
                statuses: this.buildStatusForm(widget.statuses || [], widget.type),
                tags: this.buildControl(widget.tags || []),
                ftTags: this.buildControl(widget.ftTags ?? []),
                inputPlaceholders: this.buildControl(widget.inputPlaceholders ?? []),
            },
            {
                validators: WidgetBuilderValidators.PlaceholdersUnique,
            }
        ) as FormGroup;

        if (!this.globalFiltersEditor && isRoot) {
            widgetForm.addControl('size', this.buildWidgetSizeForm(widget, newWidget));
            widgetForm.addControl('toolCategoryType', this.fb.control(widget.toolCategoryType || null, Validators.required));
        }

        if (layerLevel) {
            widgetForm.addControl('widgets', this.buildWidgetsForm(widget.widgets));
            widgetForm.addControl('query', this.buildQueriesForm(widget.query, widget.type !== WidgetType.COMPLEX));
            widgetForm.addControl('filters', this.buildFiltersForm(widget.filters || ([] as any)));
            widgetForm.removeControl('colors');
        } else {
            this.widget = widget;
            this.form = widgetForm;
            widgetForm.addControl('server', this.fb.control(!!widget.server));
            widgetForm.addControl('complexNamedDropdown', this.fb.control(!!widget.complexNamedDropdown));
            widgetForm.addControl('complexNamedDropdownLabel', this.fb.control(widget.complexNamedDropdownLabel || ''));
            widgetForm.addControl('complexNamedDropdownDependencies', this.fb.control(widget.complexNamedDropdownDependencies || ''));
            widgetForm.addControl(
                'complexNamedDropdownFilterDependency',
                this.fb.control(widget.complexNamedDropdownFilterDependency || '')
            );
        }

        if (basicChartOptionsControlsAvailable && !layerLevel) {
            const chartOptions = this.form.get('chartOptions') as FormGroup;
            setTimeout(() => {
                chartOptions.removeControl('title');
                chartOptions.removeControl('description');
            });
        }

        return layerLevel ? widgetForm : this.form;
    }

    buildFilterGroup(filter: WidgetFilter): FormGroup {
        const form = this.fb.group({
            type: [filter.type, Validators.required],
            placeholder: [filter.placeholder || '', Validators.required],
            defaultValue: filter.defaultValue || '',
            valueDependency: filter.valueDependency,
            dependentValue: [filter.dependentValue || [], dependentValueEmpty()],
            placeholderText: [filter.placeholderText || ''],
            hideLabel: filter.hideLabel || false,
            position: [filter.position || 'LEFT'],
            mainFilter: [!!filter.mainFilter],
            minWidth: filter.minWidth || '100',
            isUiFilter: !!filter.isUiFilter,
            tooltip: filter.tooltip || '',
            onlyHideDependentOn: filter.onlyHideDependentOn || false,
            ...this.getFilterControlsByType(filter).controls,
        }) as FormGroup;

        if (isDynamicWidthFilter(filter.type)) {
            form.addControl('width', this.fb.control(isDefined(filter.width) ? filter.width : FILTER_DEFAULT_SIZES[filter.type]));
        }

        if (isDynamicFilter(filter) && !form.get('query')) {
            if ((filter as any).dynamic) {
                form.addControl('query', this.buildQueryForm(filter.query || ({} as any), { fromFilter: true }));
            } else {
                form.addControl('items', this.fb.array(((filter as any).items || []).map(this.buildDropdownItemForm.bind(this))));
            }
        }

        return form;
    }

    buildWidgetSeriesGroup(series: WidgetSerie[], type: WidgetType): FormArray {
        return this.fb.array((series || []).map(s => this.buildSeriesGroup(s, type)));
    }

    buildWidgetColorThresholdGroup(thresholds: WidgetColorThreshold[]) {
        return this.fb.array((thresholds || []).map(t => this.buildThresholdGroup(t)));
    }

    buildStatusForm(statuses: WidgetStatus[], type: WidgetType): FormArray {
        return this.fb.array((statuses || []).map(s => this.buildStatusGroup(s, type)));
    }

    buildSeriesGroup(serie: WidgetSerie, type: WidgetType): FormGroup {
        const form = this.fb.group({
            column: [serie.column || '', Validators.required],
            label: [serie.label || '', Validators.required],
            color: serie.color || '#FFFFFF',
            markLineLabel: serie.markLineLabel || '',
            markLineValue: serie.markLineValue || '',
        }) as FormGroup;

        if (type === WidgetType.MULTIPLE_Y_AXIS || type === WidgetType.MULTIPLE_SERIES_TYPE) {
            form.addControl('type', this.fb.control(serie.type, [Validators.required]));
        }
        if (type === WidgetType.LINE_CHART) {
            form.addControl('seriesArea', this.fb.control(serie.seriesArea || false));
        }

        if (type === WidgetType.BAR_CHART) {
            form.addControl('type', this.fb.control(serie.type || 'bar', [Validators.required]));
            form.addControl('seriesBarStack', this.fb.control(serie.seriesBarStack || ''));
        }

        return form;
    }

    buildThresholdGroup(threshold: WidgetColorThreshold): FormGroup {
        return this.fb.group({
            from: [threshold.from ?? null, Validators.required],
            to: [threshold.to ?? null, Validators.required],
            includingFrom: !!threshold.includingFrom,
            includingTo: !!threshold.includingTo,
            color: [threshold.color || '', Validators.required],
            label: [threshold.label || ''],
        });
    }

    buildDrilldownForm({ drilldownScript, displayType, target, default: isDefault, matcherScript }: WidgetDrilldown): FormGroup {
        return this.fb.group({
            drilldownScript: [drilldownScript],
            displayType: [displayType, Validators.required],
            target: [target || ''],
            default: [!!isDefault],
            matcherScript: [matcherScript || ''],
        });
    }

    buildAsyncQueryForm(query: AsyncQuery) {
        const queryBlock = this.buildQueryForm(query, { fromFilter: false, hiddenFilter: false });
        queryBlock.removeControl('type');
        queryBlock.addControl('requestType', this.fb.control(query.requestType || 'POST', Validators.required));

        return queryBlock;
    }

    buildQueryForm(
        { query, type, placeholder, script, parent, beforeScript }: Partial<WidgetQuery>,
        params?: {
            fromFilter?: boolean;
            hiddenFilter?: boolean;
        }
    ): FormGroup {
        const form = this.fb.group({
            parent: parent || null,
            placeholder: [placeholder || '', params?.fromFilter ? [] : [Validators.required]],
            query: [query || '', params?.hiddenFilter ? [WidgetBuilderValidators.HiddenFilterValidator] : [Validators.required]],
            type: [type || null, params?.hiddenFilter ? [] : [Validators.required]],
            script: script || '',
            beforeScript: beforeScript || '',
        });
        this.checkQueryFormStatus(form);

        return form;
    }

    checkQueryFormStatus(queryForm: FormGroup) {
        if (!this.ft.isActiveSync('query_library')) {
            return;
        }

        const action = queryForm.get('parent').value ? 'disable' : 'enable';

        ['query', 'type', 'script', 'beforeScript'].forEach(key => {
            queryForm.get(key)[action]();
        });
    }

    buildColumnGroup({
        title,
        field,
        headerTooltipTemplate,
        cellTemplate,
        cellTooltipTemplate,
        filtertype,
        progressColumn,
        statisticColumn,
        percentWidth,
        progressTemplate,
        progressTooltip,
        columnSorting,
        filterComparator,
        colors,
        progressColors,
        columnVisible,
    }: WidgetTableColumn): FormGroup {
        return this.fb.group({
            title: [title, Validators.required],
            field: [field, Validators.required],
            progressColumn: !!progressColumn,
            statisticColumn: !!statisticColumn,
            percentWidth: !!percentWidth,
            headerTooltipTemplate: headerTooltipTemplate || '',
            cellTemplate: cellTemplate || '',
            cellTooltipTemplate: cellTooltipTemplate || '',
            filtertype: filtertype || null,
            progressTemplate: progressTemplate || '',
            progressTooltip: progressTooltip || '',
            columnSorting: columnSorting || '',
            filterComparator: filterComparator || '',
            colors: this.buildColorsForm(colors || []),
            progressColors: this.buildProgressColorsForm(progressColors || []),
            columnVisible: columnVisible ?? null,
        });
    }

    buildAccordionColumnGroup({ title, field, clickEventFunction }: WidgetAccordionColumn): FormGroup {
        return this.fb.group({
            title: [title, Validators.required],
            field: [field, Validators.required],
            clickEventFunction: clickEventFunction || '',
        });
    }

    buildEventDependencyGroup({ condition }: WidgetEventDependency): FormGroup {
        return this.fb.group({
            condition: [condition, Validators.required],
        });
    }

    buildStatusGroup({ label, value, statusType, icon, order, color }: WidgetStatus, type: WidgetType): FormGroup {
        const form = this.fb.group({
            label: [label, Validators.required],
            value: [value, Validators.required],
        }) as FormGroup;

        if (hasStatusValueAndIcon(type)) {
            form.addControl('statusType', this.fb.control(statusType || 0));
            form.addControl('icon', this.fb.control(icon || ''));
        }

        if (hasStatusOrderAndStatusColor(type)) {
            form.addControl('order', this.fb.control(order || 0));
            form.addControl('color', this.fb.control(color || ''));
        }

        return form;
    }

    buildChartOptionsForm(options: ChartOptions, type: WidgetType): FormGroup {
        const form = this.fb.group({
            title: [options.title || '', Validators.required],
            hideTitle: [options.hideTitle || false],
            description: options.description || '',
            mockData: options.mockData || '',
            nodatamessage: options.nodatamessage || '',
            ...this.getOptionsControlsByWidgetType(options, type).controls,
        }) as FormGroup;

        if (hasTooltipFormatter(type)) {
            form.addControl('tooltipFormatter', this.fb.control(options.tooltipFormatter || ''));
            form.addControl('tooltipTrigger', this.fb.control(options.tooltipTrigger || null));
            form.addControl('enterableTooltip', this.fb.control(!!options.enterableTooltip));
        }

        if (hasHeader(type)) {
            form.addControl('headertemplate', this.fb.control(options.headertemplate || ''));
        }

        if (hasThreshold(type)) {
            form.addControl('relatedThreshold', this.fb.control(!!options.relatedThreshold));
            form.addControl('higherIsBetter', this.fb.control(!!options.higherIsBetter));
            form.addControl('disableBottomLine', this.fb.control(!!options.disableBottomLine));
            form.addControl('mediumThreshold', this.fb.control(options.mediumThreshold || null));
            form.addControl('criticalThreshold', this.fb.control(options.criticalThreshold || null));
        }

        if (hasGridOptions(type)) {
            form.addControl('gridBottom', this.fb.control(options.gridBottom || ''));
        }

        if (hasHideLegendControl(type)) {
            form.addControl('hideLegend', this.fb.control(!!options.hideLegend));
        }

        if (isBarChartWidget(type)) {
            form.addControl('isBarSeriesClick', this.fb.control(!!options.isBarSeriesClick));
            form.addControl('withEmptyBars', this.fb.control(!!options.withEmptyBars));
        }

        return form;
    }

    getOptionsControlsByWidgetType(options: ChartOptions, type: WidgetType): FormGroup {
        switch (type) {
            case WidgetType.METRIC_LINE:
            case WidgetType.LINE_CHART: {
                return this.fb.group({
                    xlabel: options.xlabel || '',
                    ylabel: options.ylabel || '',
                    showArea: !!options.showArea,
                    showStacked: !!options.showStacked,
                    dataZoom: !!options.dataZoom,
                    xaxistype: options.xaxistype || null,
                    minYAxisValue: options.minYAxisValue || null,
                    maxYAxisValue: options.maxYAxisValue || null,
                });
            }
            case WidgetType.STACKED_BAR_CHART: {
                return this.fb.group({
                    orientation: [options.orientation || null, Validators.required],
                    barsPosition: options.barsPosition || null,
                    minYAxisValue: options.minYAxisValue || null,
                    maxYAxisValue: options.maxYAxisValue || null,
                    colorBy: options.colorBy || 'series',
                });
            }
            case WidgetType.BAR_CHART: {
                return this.fb.group({
                    orientation: [options.orientation || null, Validators.required],
                    barsPosition: options.barsPosition || null,
                    xlabel: options.xlabel || '',
                    ylabel: options.ylabel || '',
                    minYAxisValue: options.minYAxisValue || null,
                    maxYAxisValue: options.maxYAxisValue || null,
                    colorBy: options.colorBy || 'series',
                });
            }
            case WidgetType.SCATTER_CHART: {
                return this.fb.group({
                    xlabel: options.xlabel || '',
                    ylabel: options.ylabel || '',
                    showArea: !!options.showArea,
                    dataZoom: !!options.dataZoom,
                    xaxistype: options.xaxistype || null,
                    minYAxisValue: options.minYAxisValue || null,
                    maxYAxisValue: options.maxYAxisValue || null,
                    showStacked: !!options.showStacked,
                });
            }
            case WidgetType.COMPLEX: {
                return this.fb.group({
                    layout: [options.layout || null, Validators.required],
                });
            }
            case WidgetType.TABLE: {
                return this.fb.group({
                    gridPagination: !!options.gridPagination,
                    serverSidePagination: !!options.serverSidePagination,
                    hasResetFilter: !!options.hasResetFilter,
                    isDynamicTable: !!options.isDynamicTable,
                });
            }
            case WidgetType.TWO_DIMENSIONAL_TABLE: {
                return this.fb.group({
                    verticalHeader: options.verticalHeader || '',
                    horizontalHeader: options.horizontalHeader || '',
                    gridValue: options.gridValue || '',
                    cellTemplate: options.cellTemplate || null,
                });
            }
            case WidgetType.DOUGHNUT_CHART: {
                return this.fb.group({
                    showTotal: !!options.showTotal,
                    showCount: !!options.showCount,
                    piWorkChartLabel: options.piWorkChartLabel || '',
                    minYAxisValue: options.minYAxisValue || null,
                    maxYAxisValue: options.maxYAxisValue || null,
                });
            }
            case WidgetType.PROGRESS: {
                return this.fb.group({
                    measure: options.measure || '',
                    nodatamessage: options.nodatamessage || '',
                });
            }
            case WidgetType.MULTIPLE_Y_AXIS: {
                return this.fb.group({
                    xlabel: options.xlabel || '',
                });
            }
            case WidgetType.LOG: {
                return this.fb.group({
                    scrollToBottom: Boolean(options.scrollToBottom),
                });
            }
            case WidgetType.SCORE_DOUGHNUT:
            case WidgetType.RADIAL: {
                return this.fb.group({
                    maxValue: [options.maxValue || null, Validators.required],
                    measure: [options.measure || '', Validators.required],
                    minYAxisValue: options.minYAxisValue || null,
                    maxYAxisValue: options.maxYAxisValue || null,
                });
            }
            case WidgetType.PI_WORK_CHART:
            case WidgetType.PI_WORK_DISTRIBUTION: {
                return this.fb.group({
                    piWorkChartLabel: [options.piWorkChartLabel || ''],
                });
            }
            case WidgetType.ACCORDION: {
                return this.fb.group({
                    fixedLastRow: !!options.fixedLastRow,
                });
            }
            case WidgetType.TABLE_WITH_TABS: {
                return this.fb.group({
                    hasResetFilter: !!options.hasResetFilter,
                    isDynamicTable: !!options.isDynamicTable,
                });
            }
            case WidgetType.ACCORDION_WITH_TABS: {
                return this.fb.group({
                    piCheckboxLabel: [options.piCheckboxLabel || 'Show Parent Accordion'],
                    fixedLastRow: !!options.fixedLastRow,
                    openFirstRow: !!options.openFirstRow,
                });
            }
            case WidgetType.BOXPLOT: {
                return this.fb.group({
                    xlabel: options.xlabel || '',
                    ylabel: options.ylabel || '',
                });
            }
            case WidgetType.TREEMAP: {
                return this.fb.group({
                    labelFormatter: options.labelFormatter || '',
                    hideBreadcrumb: options.hideBreadcrumb || false,
                    hideUpperLabel: options.hideUpperLabel || false,
                });
            }
            case WidgetType.MULTIPLE_SERIES_TYPE: {
                return this.fb.group({
                    xlabel: options.xlabel || '',
                    ylabel: options.ylabel || '',
                    xAxisLabelFormatter: options.xAxisLabelFormatter || '',
                });
            }
            default: {
                return this.fb.group({});
            }
        }
    }

    private buildWidgetsForm(widgets: any): FormArray {
        return this.fb.array(
            (widgets || []).map((widget: any) =>
                this.buildForm(widget, {
                    basicChartOptionsControlsAvailable: false,
                    layerLevel: true,
                })
            )
        );
    }

    private buildColorsForm(colors: string[]): FormArray {
        return this.fb.array((colors || []).map((color: any) => this.buildColorsControl(color)));
    }

    private buildProgressColorsForm(progressColors: ProgressColor[]): FormArray {
        return this.fb.array((progressColors || []).map((progressColor: any) => this.buildProgressColorsGroup(progressColor)));
    }

    buildColorsControl(color: string): AbstractControl {
        return this.fb.control(color);
    }

    buildProgressColorsGroup(progressColor: any): FormGroup {
        return this.fb.group({
            label: progressColor.label,
            color: progressColor.color,
        });
    }

    buildQueriesForm(queries: WidgetQuery[], mainQuery = false): FormArray {
        return this.fb.array(
            (queries || []).map(query => this.buildQueryForm(query)),
            mainQuery ? [Validators.required, WidgetBuilderValidators.NeedResult] : []
        );
    }

    buildDropdownItemForm({ value = '', label = '' }: DropdownItem): FormGroup {
        return this.fb.group({
            value: [value, Validators.required],
            label: [label, Validators.required],
        });
    }

    buildTilesForm(tiles: TileWidgetSettings[]): FormArray {
        return this.fb.array(tiles.map(this.buildTileItemForm.bind(this)));
    }

    buildTileWidgetRatingForm({ color = 'white', rating = '', info = '' }: Partial<TileWidgetRating>) {
        return this.fb.group({
            color: [color, Validators.required],
            rating: [rating, Validators.required],
            info,
        });
    }

    buildTileItemForm({ column = '', header = '', ratings = [], progress = false }: TileWidgetSettings): FormGroup {
        return this.fb.group(
            {
                column: [column, Validators.required],
                header: [header, Validators.required],
                progress,
                ratings: this.fb.array(ratings.map(this.buildTileWidgetRatingForm.bind(this))),
            },
            { validators: WidgetTileValidators.RatingUniqueValidator }
        );
    }

    buildEventHandlerForm({ eventId = '', script = '', type = null }: WidgetEventHandler): FormGroup {
        return this.fb.group({
            eventId: [eventId, Validators.required],
            script: [script, Validators.required],
            type: [type, Validators.required],
        });
    }

    buildAdditionalTemplateForm({ template, position, targetDrilldown, drilldownInput, tilesTemplate }: WidgetAdditionalTemplate) {
        return this.fb.group({
            position: [position ?? null, Validators.required],
            targetDrilldown: targetDrilldown ?? null,
            drilldownInput: drilldownInput ?? null,
            tilesTemplate: !!tilesTemplate,
            template: [template ?? '', Validators.required],
        });
    }

    buildHelpForm(help: WidgetHelp) {
        return this.fb.group({
            summary: [help.summary, Validators.required],
            description: [help.description, Validators.required],
            howScoreCalculated: help.howScoreCalculated,
            howScoreCanImproved: help.howScoreCanImproved,
            insights: this.fb.array((help.insights || []).map(insight => this.buildHelpInsight(insight))),
        });
    }

    buildHelpInsight(insight: WidgetHelpInsight) {
        return this.fb.group({
            insight: insight.insight ?? '',
            recommendations: this.fb.array(
                (insight.recommendations || []).map(recommendation => this.buildHelpRecommendation(recommendation))
            ),
        });
    }

    buildHelpRecommendation(recommendation: string) {
        return this.fb.control(recommendation);
    }

    buildControl(placeholders: string[]) {
        return this.fb.control(placeholders ?? []);
    }

    buildWidgetSizeForm(widget: AnyWidgetModel, newWidget: boolean) {
        const sizeForm = this.fb.group({
            minW: [MIN_WIDTH_PROPORTION, Validators.required],
            minH: [MIN_HEIGHT_PROPORTION, Validators.required],
            defaultW: [DEFAULT_WIDTH_PROPORTION, Validators.required],
            defaultH: [DEFAULT_HEIGHT_PROPORTION, Validators.required],
        });

        if (!newWidget) {
            sizeForm.patchValue(
                widget.size ?? {
                    minW: null,
                    minH: null,
                    defaultW: null,
                    defaultH: null,
                }
            );
        }

        return sizeForm;
    }
}

export function countChars(htmlString: string) {
    return htmlString
        .replace(/<[^>]*|>?/gm, '')
        .replace(/&nbsp;/gm, '')
        .replace(/\n/gm, '').length;
}

class WidgetTileValidators {
    static RatingUniqueValidator(formGroup: FormGroup) {
        return ArrayByFieldDuplication(formGroup, 'ratings', 'rating', 'duplicateRating');
    }
}

class WidgetBuilderValidators {
    static HiddenFilterValidator(control: AbstractControl): ValidationErrors {
        if (!control.parent) {
            return null;
        }

        const queryControl = control.parent.get('type');

        if (control.value) {
            queryControl.setValidators(Validators.required);
        } else {
            queryControl.setValidators(null);
        }

        queryControl.updateValueAndValidity();

        return null;
    }

    static QueryFromLibraryValidator: (params: Record<string, ValidatorFn[]>) => ValidatorFn = params => (control: AbstractControl) => {
        if (control.parent) {
            Object.entries(params).forEach(([key, validators]) => {
                if (control.value) {
                    control.parent.get(key).addValidators(validators);
                } else {
                    control.parent.get(key).clearValidators();
                }
            });
        }

        return null;
    };

    static NeedResult(control: FormArray) {
        return !control.value.length || (control.value as WidgetQuery[]).some(({ placeholder }) => placeholder === QUERY_RESULT_PLACEHOLDER)
            ? null
            : {
                  needResult: true,
              };
    }

    static PlaceholdersUnique(control: FormGroup) {
        return ArrayByFieldDuplication(control, 'query', 'placeholder', 'uniquePlaceholder')
            ? null
            : {
                  uniquePlaceholder: true,
              };
    }
}
