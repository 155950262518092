<div>
    <ng-container *ngIf="!jdStore.isJD && path.length">
        <span class="cursor-pointer font-14px heading1 not-last" (click)="goToRoot()">Root</span>
        <span class="px-2 font-14px arrow">></span>
    </ng-container>
    <ng-container class="cursor-pointer" *ngFor="let path of pathWithName; let last = last; let i = index">
        <span class="cursor-pointer font-14px heading1" [class.not-last]="!last" *ngIf="!last" (click)="goToStep(i)"> {{ path.name }}</span>
        <span *ngIf="!last" class="px-2 font-14px arrow">></span>
        <span class="heading1 font-14px" *ngIf="last">{{ path.name }}</span>
    </ng-container>
</div>
