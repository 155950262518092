import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { WidgetAccordionColumn } from 'data-processor';
import { findStringInObject, SandBox } from 'data-processor/lib/widget-library/widget-builder/services/widget-builder.util';
import { cloneDeep, isEqual } from 'lodash';
import { warning } from '@dagility-ui/shared-components/icons';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DropdownItem, SearchComponent, SortDirection } from '@dagility-ui/kit';
import { NgbNav, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

type ComparatorCallback = (a: any, b: any) => number;

interface NavItem {
    tabName: string;
    tabValue: string;
    noDataMessage?: string;
    accordionColumns: WidgetAccordionColumn[];
    items: Array<{ value: any; table: any }>;
    clientSearch?: boolean;
    withSearch?: boolean;
    withInfiniteScrollbar?: boolean;
    sortField?: string;
    sortFunction?: ComparatorCallback;
    sortItems?: DropdownItem[];
    sortLabel?: string;
}

export interface ParentNavItem {
    parentAccordionValue: { id: string; title: string; date: any; author: string; status: string };
    navItems: Array<NavItem>;
}

interface AdditionalInfo {
    showParent: boolean;
    tabFilter?: string;
}

interface AccordionWithTabsOptions {
    checkboxLabel: string;
    withParent: ParentNavItem[];
    withoutParent: { navItems: NavItem[] };
    isChildComponent: boolean;
    hasInfiniteScroll: boolean;
    statusMapper: { [key in string]: string };
    openFirstRow: boolean;
    customTemplate: string;
}

@Component({
    selector: 'dp-accordion-with-tabs',
    templateUrl: './accordion-with-tabs.component.html',
    styleUrls: ['./accordion-with-tabs.component.scss'],
})
export class AccordionWithTabsComponent implements OnInit, OnDestroy {
    @Input() placeholders: Record<string, any> = {};
    @Input() withParentNavs: Array<ParentNavItem>;
    @Input() withoutParentNavs: Array<NavItem> = [];
    @Input() isChildComponent = false;
    @Input() options: AccordionWithTabsOptions;
    @Input() hasInfiniteScroll = true;

    @Input() infiniteScrollChartData$: Subject<any> = new Subject();
    @Input() serverSearchChartData$: Subject<any> = new Subject();
    @Input() serverSortChartData$: Subject<any> = new Subject();

    @Output() linkClicked = new EventEmitter();
    @Output() scrollEnded = new EventEmitter();
    @Output() serverSearch = new EventEmitter();
    @Output() serverSort = new EventEmitter();
    @Output() drilldown = new EventEmitter();

    @ViewChild(SearchComponent) search: SearchComponent;
    @ViewChild(NgbNav) nav: NgbNav;
    @ViewChild(PerfectScrollbarComponent) perfectScrollbar: PerfectScrollbarComponent;

    cachedSearchStr = '';
    checkboxLabel: string;
    customTemplate: string;
    openFirstRow = false;
    showWithParent = false;
    statusMapper: Record<string, string>;
    isLoading = false;
    updatedIndicesForCustomTemplate = new Map();


    widths: number[] = [];

    readonly icons = { warning };
    readonly rowClassRules = {
        'row-disabled': (params: Record<string, any>) => !!params.data['class-disabled'],
    };
    private pageableMap = new Map();
    private searchStringsMap = new Map();
    private isFinished = false;
    private tabChanged = false;
    private infiniteScrollAdditionalInfo: AdditionalInfo = { showParent: false };
    private destroy$ = new Subject<void>();
    private navsSortDirectionMap = new Map();

    private defaultWithoutParentNavs: Array<NavItem> = [];
    private defaultWithParentNavs: Array<ParentNavItem> = [];

    private static openLink(value: string) {
        if (!value || value.includes('null')) {
            return;
        }

        window.open(value, '_blank');
    }

    constructor(private cdr: ChangeDetectorRef, private elRef: ElementRef) {}

    ngOnInit() {
        if (this.options) {
            this.initOptions();
        }

        this.defaultWithoutParentNavs = cloneDeep(this.withoutParentNavs);
        this.defaultWithParentNavs = cloneDeep(this.withParentNavs);

        (this.withoutParentNavs || []).forEach((nav, i) => {
            if (nav.sortField) {
                this.setSortDirection(i, 'ASC');
                this.sortClientSide(i, nav.sortField);
            }
        });

        if (this.customTemplate) {
            this.updateIndicesForCustomTemplate();
        }

        this.initPageableMap();
        this.initSubscription();
    }

    initOptions() {
        this.checkboxLabel = this.options.checkboxLabel;
        this.withParentNavs = this.options.withParent;
        this.withoutParentNavs = this.options.withoutParent.navItems;
        this.isChildComponent = !!this.options.isChildComponent;
        this.hasInfiniteScroll = !!this.options.hasInfiniteScroll;
        this.statusMapper = this.options.statusMapper;
        this.openFirstRow = !!this.options.openFirstRow;
        this.customTemplate = this.options.customTemplate;
    }

    initPageableMap() {
        this.withoutParentNavs.forEach(nav => {
            this.pageableMap.set(nav.tabValue, {
                offset: 1,
                limit: 10,
                finished: false,
                sortField: nav.sortField,
                sortOrder: 'ASC',
            });
            this.searchStringsMap.set(nav.tabValue, '');
        });

        this.pageableMap.set('parent', { offset: 0, limit: 10, finished: false });
        this.searchStringsMap.set('parent', '');
    }

    initSubscription() {
        this.infiniteScrollChartData$.pipe(takeUntil(this.destroy$)).subscribe(({ options, placeholdersResult }: any) => {
            const i = this.withoutParentNavs.findIndex(nav => nav.tabValue === this.infiniteScrollAdditionalInfo.tabFilter);
            this.infiniteScrollAdditionalInfo.showParent ? this.addToParent(options) : this.addToNavs(options, i, placeholdersResult);

            if (this.withoutParentNavs[i]?.sortField) {
                this.sortClientSide(i, this.withoutParentNavs[i].sortField);
            }

            if (this.customTemplate) {
                this.updateIndicesForCustomTemplate();
            }
        });

        const serverRequest = ({ options, placeholdersResult }: any) => {
            if (this.infiniteScrollAdditionalInfo.showParent) {
                this.withParentNavs = [];
                this.addToParent(options);
            } else {
                const i = this.withoutParentNavs.findIndex(nav => nav.tabValue === this.infiniteScrollAdditionalInfo.tabFilter);
                this.withoutParentNavs[i].items = [];
                this.defaultWithoutParentNavs[i].items = []; //need for client sort

                if (this.customTemplate) {
                    this.placeholders.result.withoutParent[i].accordionItems = [];
                }

                this.addToNavs(options, i, placeholdersResult);

                if (this.withoutParentNavs[i].sortField) {
                    this.sortClientSide(i, this.withoutParentNavs[i].sortField);
                }
            }

            if (this.customTemplate) {
                this.updateIndicesForCustomTemplate();
            }
        };

        this.serverSearchChartData$.pipe(takeUntil(this.destroy$)).subscribe(serverRequest);
        this.serverSortChartData$.pipe(takeUntil(this.destroy$)).subscribe(serverRequest);
    }

    setTabSearch() {
        setTimeout(() => {
            if (!this.search) {
                return;
            }

            this.search.value = this.searchStringsMap.get(
                this.showWithParent ? 'parent' : this.withoutParentNavs.find(nav => nav.tabName === this.nav?.activeId)?.tabValue
            );
            this.tabChanged = true;
            this.cdr.detectChanges();
        });
    }

    addToParent({ withParent }: any) {
        if (!withParent.length) {
            this.pageableMap.set('parent', { ...this.pageableMap.get('parent'), finished: true });
            this.isLoading = false;
            this.cdr.markForCheck();
            return;
        }

        this.withParentNavs = cloneDeep([...this.withParentNavs, ...withParent]);
        this.updateTemplate();
    }

    addToNavs({ withoutParent }: any, i: number, placeholdersItems: any) {
        const nav = this.withoutParentNavs.find(nav => nav.tabName === this.nav?.activeId);
        const tabValue = nav?.tabValue;

        const finish = () => {
            this.pageableMap.set(tabValue, { ...this.pageableMap.get(tabValue), finished: true });
            this.isLoading = false;
        };

        if (!nav.withInfiniteScrollbar) {
            finish();
            return;
        }

        if (withoutParent.navItems?.length) {
            this.isFinished = false;
        }

        const itemIds = this.defaultWithoutParentNavs[i].items.map(it => it.value.id);
        const newItems =
            withoutParent.navItems
                ?.find((nav: any) => nav.tabValue === tabValue)
                ?.items.filter((it: any) => !itemIds.includes(it.value.id)) || [];

        if (!newItems.length) {
            finish();
            return;
        }

        if (!this.withoutParentNavs[i]) {
            return;
        }

        if (this.customTemplate) {
            const placeholdersAccordions =
                placeholdersItems.withoutParent.find((nav: any) => nav.tabValue === tabValue)?.accordionItems || [];

            this.placeholders.result.withoutParent[i].accordionItems = [
                ...this.placeholders.result.withoutParent[i].accordionItems,
                ...placeholdersAccordions,
            ];
        }

        this.withoutParentNavs[i].items = cloneDeep([...this.defaultWithoutParentNavs[i].items, ...newItems]);
        this.updateTemplate();
    }

    updateTemplate() {
        this.defaultWithoutParentNavs = cloneDeep(this.withoutParentNavs);
        this.defaultWithParentNavs = cloneDeep(this.withParentNavs);

        this.isLoading = false;
        this.cdr.markForCheck();

        if (!this.search) {
            return;
        }

        const searchStr = `${this.search.value.trim()}`;

        if (
            searchStr !==
            this.searchStringsMap.get(
                this.showWithParent ? 'parent' : this.withoutParentNavs.find(nav => nav.tabName === this.nav?.activeId)?.tabValue
            )
        ) {
            this.searchItem(searchStr);
        }
    }

    handleParentScrollEnd() {
        if (
            !this.hasInfiniteScroll ||
            this.isChildComponent ||
            !this.showWithParent ||
            this.isLoading ||
            this.pageableMap.get('parent').finished
        ) {
            return;
        }

        this.infiniteScrollAdditionalInfo = { showParent: true };
        this.makeAdditionalRequest('parent', this.scrollEnded);
    }

    handleNavScrollEnd(tabValue: string) {
        if (!this.hasInfiniteScroll || this.showWithParent || this.isLoading || this.pageableMap.get(tabValue).finished) {
            return;
        }

        const hasInfiniteScroll = this.withoutParentNavs.find(i => i.tabValue === tabValue).withInfiniteScrollbar;

        if (!hasInfiniteScroll) {
            return;
        }

        this.infiniteScrollAdditionalInfo = { showParent: false, tabFilter: tabValue };
        this.makeAdditionalRequest(tabValue, this.scrollEnded);
    }

    searchItem(searchStr: string) {
        if (this.isLoading || !this.hasInfiniteScroll) {
            return;
        }

        if (searchStr === '' && this.search.value !== '') {
            this.search.value = '';
            this.tabChanged = false;
        }

        searchStr = searchStr.trim();

        let mapKey;
        if (!this.showWithParent) {
            const tabFilter = this.withoutParentNavs.find(nav => nav.tabName === this.nav?.activeId)?.tabValue;

            if (this.tabChanged && this.searchStringsMap.get(tabFilter).value === searchStr) {
                this.tabChanged = false;
                return;
            }

            this.tabChanged = false;
            this.infiniteScrollAdditionalInfo = {
                showParent: false,
                tabFilter,
            };

            if (!this.infiniteScrollAdditionalInfo.tabFilter) {
                this.infiniteScrollAdditionalInfo.tabFilter = this.withoutParentNavs[0].tabValue;
            }
            mapKey = this.infiniteScrollAdditionalInfo.tabFilter;
        } else {
            mapKey = 'parent';
        }

        this.searchStringsMap.set(mapKey, `${searchStr}`);

        this.infiniteScrollAdditionalInfo.showParent = this.showWithParent;
        const { sortField, sortOrder } = this.pageableMap.get(mapKey);

        this.pageableMap.set(mapKey, {
            offset: 0,
            limit: 10,
            finished: false,
            sortField: sortField,
            sortOrder: sortOrder,
        });
        this.makeAdditionalRequest(mapKey, this.serverSearch);
    }

    sort(sortOrder: SortDirection, sortField: string) {
        if (this.isLoading || !this.hasInfiniteScroll) {
            return;
        }

        if (!this.showWithParent) {
            const i = this.withoutParentNavs.findIndex(nav => nav.tabName === this.nav?.activeId);
            this.setSortDirection(i, sortOrder);
            this.infiniteScrollAdditionalInfo = {
                showParent: false,
                tabFilter: this.withoutParentNavs[i]?.tabValue,
            };
        }

        let mapKey;
        if (this.showWithParent) {
            mapKey = 'parent';
        } else {
            if (!this.infiniteScrollAdditionalInfo.tabFilter) {
                this.infiniteScrollAdditionalInfo.tabFilter = this.withoutParentNavs[0].tabValue;
            }

            mapKey = this.infiniteScrollAdditionalInfo.tabFilter;
        }

        this.infiniteScrollAdditionalInfo.showParent = this.showWithParent;

        this.pageableMap.set(mapKey, {
            offset: 0,
            limit: 10,
            finished: false,
            sortField: sortField,
            sortOrder: sortOrder,
        });
        this.makeAdditionalRequest(mapKey, this.serverSort);
    }

    handleClientSortDropdown(navIndex: number, direction: SortDirection, sortField: string) {
        this.setSortDirection(navIndex, direction);
        this.sortClientSide(navIndex, sortField);

        if (this.customTemplate) {
            this.updateIndicesForCustomTemplate();
        }
    }

    private setSortDirection(navIndex: number, direction: SortDirection) {
        this.navsSortDirectionMap.set(navIndex, direction);
    }

    private sortClientSide(navIndex: number, sortField: string) {
        if (!this.showWithParent) {
            const val = this.navsSortDirectionMap.get(navIndex);

            const comparator = this.withoutParentNavs[navIndex].sortFunction
                ? this.withoutParentNavs[navIndex].sortFunction
                : (a: any, b: any) => {
                      if (typeof a === 'string' && typeof b === 'string') {
                          return a.localeCompare(b);
                      }

                      return a - b;
                  };

            this.withoutParentNavs[navIndex].items = this.withoutParentNavs[navIndex].items.sort((a: any, b: any) => {
                a = a.value[sortField];
                b = b.value[sortField];

                return val === 'ASC' ? comparator(a, b) : comparator(b, a);
            });
        }
    }

    makeAdditionalRequest(mapKey: string, eventEmitter: EventEmitter<any>) {
        const { offset, limit, finished, sortField, sortOrder } = this.pageableMap.get(mapKey);

        if (finished) {
            return;
        }

        this.isLoading = true;

        eventEmitter.next({
            offset,
            limit,
            searchStr: `%${this.searchStringsMap.get(mapKey)}%`,
            additionalInfo: this.infiniteScrollAdditionalInfo,
            sortField,
            sortOrder,
        });

        this.pageableMap.set(mapKey, { offset: offset + 1, limit, finished, sortField, sortOrder });
        this.cdr.detectChanges();
    }

    clientSideSearchItem(searchStr: string) {
        const str = searchStr.toLowerCase();

        if (this.showWithParent) {
            const withParentNavs = cloneDeep(this.defaultWithParentNavs);
            const deletedParentItems: any = [];

            withParentNavs.forEach(parentItem => {
                const deletedNavs: any = [];
                parentItem.navItems.forEach(nav => {
                    const accordionColumns = nav.accordionColumns.map(column => column.field);
                    nav.items = nav.items.filter((item: any) => {
                        Object.keys(item.value).forEach(key => {
                            if (!accordionColumns.includes(key)) {
                                delete item.value[key];
                            }
                        });

                        return findStringInObject(item.value, str);
                    });
                    if (!nav.items.length) {
                        deletedNavs.push(nav);
                    }
                });

                parentItem.navItems = parentItem.navItems.filter(nav => !deletedNavs.includes(nav));

                if (!parentItem.navItems.length) {
                    deletedParentItems.push(parentItem);
                }
            });

            this.withParentNavs = withParentNavs.filter(nav => !deletedParentItems.includes(nav));
            return;
        }

        const navs = cloneDeep(this.defaultWithoutParentNavs);

        navs.forEach(nav => {
            const accordionColumns = nav.accordionColumns.map(column => column.field);
            nav.items = nav.items.filter(item => {
                Object.keys(item.value).forEach(key => {
                    if (!accordionColumns.includes(key)) {
                        delete item.value[key];
                    }
                });

                return findStringInObject(item.value, str);
            });
        });

        this.cachedSearchStr = searchStr;
        this.withoutParentNavs = navs;

        if (this.customTemplate) {
            this.updateIndicesForCustomTemplate();
        }

        this.setSearchFocus(searchStr);
    }

    setSearchFocus(searchStr: string) {
        setTimeout(() => {
            const searchEl = this.elRef.nativeElement
                .getElementsByClassName('lib-search')
                .item(0)
                .getElementsByTagName('input')
                .item(0);

            if (!searchEl || !searchEl.offsetWidth || !searchEl.offsetHeight) {
                this.setSearchFocus(searchStr);
                return;
            }

            this.search.value = searchStr;
            searchEl.focus();
        }, 10);
    }

    updateIndicesForCustomTemplate() {
        this.defaultWithoutParentNavs.forEach((nav, navIndex) => {
            const accordionColumns = nav.accordionColumns.map(column => column.field);
            this.withoutParentNavs[navIndex].items.forEach((item, itemIndex) => {
                const filteredVal = cloneDeep(item.value);

                Object.keys(filteredVal).forEach(k => {
                    if (!accordionColumns.includes(k)) {
                        delete filteredVal[k];
                    }
                });

                this.updatedIndicesForCustomTemplate.set(
                    `${navIndex}-${itemIndex}`,
                    this.defaultWithoutParentNavs[navIndex].items.findIndex(val => {
                        const filteredDefaultVal = cloneDeep(val.value);

                        Object.keys(filteredDefaultVal).forEach(k => {
                            if (!accordionColumns.includes(k)) {
                                delete filteredDefaultVal[k];
                            }
                        });

                        return isEqual(filteredDefaultVal, filteredVal);
                    })
                );
            });
        });
    }

    cellClicked({ value, data }: any) {
        if (!value) {
            return;
        }

        let obj;

        try {
            obj = JSON.parse(value);
        } catch {
            return;
        }

        const { linkValue, messageBus } = obj;
        if (linkValue) {
            AccordionWithTabsComponent.openLink(linkValue);
        }

        if (messageBus) {
            this.linkClicked.emit(data);
        }
    }

    getAccordionRowValue(rowValue: any) {
        if (typeof rowValue === 'string' || typeof rowValue === 'number') {
            return rowValue;
        }
        return rowValue?.value;
    }

    onAccordionCellClick(fn: string, value: WidgetAccordionColumn) {
        const sandBox = new SandBox();
        sandBox.buildFn(`return (function (value, linkClicked, openLink, drilldown) {${fn}})`)(
            value,
            this.linkClicked,
            AccordionWithTabsComponent.openLink,
            (target: string) => {
                this.drilldown.emit({ payload: value, target });
            }
        );
    }

    onPanelChange(event: NgbPanelChangeEvent) {
        if (!this.perfectScrollbar || !event.nextState) {
            return;
        }

        if (!this.showWithParent && this.withoutParentNavs.length) {
           this.updateColWidths();
        }

        setTimeout(() => {
            this.perfectScrollbar.directiveRef.scrollToElement(`#${event.panelId}-header`, -4);
            this.perfectScrollbar.directiveRef.update();
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    private updateColWidths() {
        const columnsLen = this.withoutParentNavs[0].accordionColumns.length;
        this.widths = Array.from({ length: columnsLen }, () => 100/columnsLen);
        const width = this.elRef.nativeElement.querySelector('perfect-scrollbar > div').offsetWidth - 4;
        this.withoutParentNavs.forEach(nav => {
            nav.items.forEach(item => {
                item.table.tableData.columnDefs.forEach((col: any) => {
                    if (col.colId === 'selectionColumn') {
                        col.hide = true;
                    }
                    col.width = width * this.widths[0]/100;
                });
                item.table.tableData.columnDefs = [...item.table.tableData.columnDefs];
                item.table.tableData = { ...item.table.tableData };
            })
        });
    }
}
