import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    TemplateRef,
    TrackByFunction,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import { cloneDeep, pick } from 'lodash';

import { DateAdapter, DropDirection, DropEvent, SVGBlock, ToasterService } from '@dagility-ui/shared-components';
import { loadFile, readFileToObject, ResizeObserverService, SplitButtonItem } from '@dagility-ui/kit';

import { DATA_MORPH_FEATURE_TOGGLE, DataMorphFeatureToggleService } from 'data-processor/tokens';

import { QueryBlock } from '../../../models/query.block';
import { convertGraphToAnyWidget, normalizeWidgetModel } from '../../../models/widget.graph';
import { QueryLayoutBuilder, WidgetBuilderLayout } from '../../../services/query-flow-layout.builder';
import { WidgetBuilderService, WidgetScript } from '../../../services/widget-builder.service';
import { WidgetBuilderState, WidgetBuilderStore } from '../../../services/widget-builder.store';
import { WidgetBuilderFacade } from '../../../state/widget-builder.facade';
import { WidgetDebuggerState } from '../../../services/widget.debugger';
import { GLOBAL_FILTERS_EDITOR } from '../../../providers/global-filters-editor.token';
import { exportAnyWidget } from '../../../widget-builder.utils';
import { ClientWidgetScriptExecutor, WidgetErrorLogger } from '../../../services/widget-script.executor';
import { AnyWidgetModel, WidgetEventHandler } from '../../../models/any-widget.model';
import { createFakeWidget } from '../../../../widget-builder/services/widget-builder.util';
import { moveLineFieldsToOldFields, moveOldFieldsToLinesFields, removeOldFields } from '../../../services/widget.lines';
import { GLOBAL_FILTER_FIELDS } from '../../dashboard-filter-set-settings/fake-widget-provider';
import { DEFAULT_PLACEHOLDERS, DefaultPlaceholders } from '../../../providers/default-placeholders.provider';
import { WidgetDebuggerContainer, WidgetDebugger } from '../../../services/widget-debugger.manager';

export interface SaveButtonSplitItem extends SplitButtonItem {
    handler: () => void;
}

@Component({
    selector: 'dp-widget-builder-flow',
    templateUrl: './widget-builder-flow.component.html',
    styleUrls: ['./widget-builder-flow.component.scss'],
    providers: [ResizeObserverService],
})
export class WidgetBuilderFlowComponent<T extends SVGBlock> implements OnChanges {
    @Input() model: WidgetBuilderState;

    @Input() activeBlock: T;

    @Input() actionsTemplate: TemplateRef<any>;

    @Output() editBlock = new EventEmitter<T>();

    @Output() addFilter = new EventEmitter<string>();

    @Output() addQuery = new EventEmitter<string>();

    @Output() addAsyncQuery = new EventEmitter<string>();

    @Output() addDrilldown = new EventEmitter<string>();

    @Output() addEventHandler = new EventEmitter<string>();

    @Output() debug = new EventEmitter();

    @Output() drop = new EventEmitter<{
        toId: string;
        fromLayerId: string;
        movedId: string;
        collectionName: 'queries' | 'filters';
        direction: DropDirection;
    }>();

    @Output() editDrilldown = new EventEmitter();

    @Output() save = new EventEmitter<any>();

    @Output() saveAndClose = new EventEmitter<any>();

    @Output() stopDebug = new EventEmitter();

    @Output() editEventHandler = new EventEmitter<WidgetEventHandler>();

    layout: WidgetBuilderLayout;

    viewportSize$ = this.resizeObserver.observe$(this.elementRef).pipe(
        startWith(true),
        map(() => ({
            isSmall: this.elementRef.nativeElement.clientWidth < 690,
            isXSmall: this.elementRef.nativeElement.clientWidth < 560,
        }))
    );

    constructor(
        public facade: WidgetBuilderFacade,
        private api: WidgetBuilderService,
        private store: WidgetBuilderStore,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private route: ActivatedRoute,
        private toaster: ToasterService,
        private debuggerState: WidgetDebuggerState,
        private debuggerContainer: WidgetDebuggerContainer,
        private resizeObserver: ResizeObserverService,
        private elementRef: ElementRef<HTMLElement>,
        @Inject(DATA_MORPH_FEATURE_TOGGLE) private featureToggleService: DataMorphFeatureToggleService,
        @Inject(DEFAULT_PLACEHOLDERS) private defaultPlaceholders: DefaultPlaceholders,
        @Inject(GLOBAL_FILTERS_EDITOR) public globalFiltersMode: boolean,
        private dateAdapter: DateAdapter
    ) {}

    get modelGraphId(): string {
        return convertGraphToAnyWidget(this.store.value.root, this.store.value).modelGraphId;
    }

    get anyWidgetId() {
        return this.facade.anyWidgetId;
    }

    get noDebug() {
        return !this.debuggerContainer.debugger && !this.facade.currentWidget;
    }

    get showResume() {
        return this.debuggerContainer.debugger?.context?.position || this.facade.widgetWorkflow?.position.range.from;
    }

    get isServer() {
        return this.store.value.layers[this.store.value.root].server;
    }

    get currentPosition$() {
        return this.isServer ? this.facade.widgetWorkflow?.currentPosition$ : this.debuggerContainer.debugger?.context?.currentPosition$;
    }

    handleSaveWidget = (close?: boolean): Observable<any> => {
        const widget = cloneDeep(convertGraphToAnyWidget(this.store.value.root, this.store.value));
        moveOldFieldsToLinesFields(widget);
        removeOldFields(widget);
        if (this.globalFiltersMode) {
            if (close) {
                return of(widget);
            } else {
                this.save.emit(widget);

                return;
            }
        }

        const createOrSave = () =>
            this.facade.anyWidgetId
                ? forkJoin([
                      this.api.updateWidget(widget, this.facade.anyWidgetId).pipe(map(() => ({ id: this.facade.anyWidgetId }))),
                      ...(this.facade.extensionsComponent || []).map(extensionCmp =>
                          extensionCmp.save().pipe(
                              map(extension => {
                                  this.store.extensionsState[extension.extension.id] = extension;
                              }),
                              catchError(() => of(null))
                          )
                      ),
                  ]).pipe(map(([id]) => id))
                : this.api.createWidget(widget);

        const saved$ = new Subject<void>();

        createOrSave().subscribe(
            ({ id }) => {
                this.toaster.successToast({
                    title: 'Success',
                    content: 'Saved successfully',
                });
                this.store.markAsPristine();
                if (this.facade.form) {
                    this.facade.form.markAsPristine();
                }

                if (!this.facade.anyWidgetId) {
                    this.store.firstLoad = true;
                    this.router.navigate(['../', id], { relativeTo: this.route });
                }
                saved$.next();
            },
            err => {
                saved$.error(err);
            }
        );

        return saved$.asObservable();
    };

    handleSaveAndCloseWidget = () => {
        this.handleSaveWidget(true).subscribe((widget?: AnyWidgetModel) => {
            if (this.globalFiltersMode) {
                this.saveAndClose.emit(widget);
            } else {
                this.saveAndClose.emit(true);
            }
        });
    };

    actions: SaveButtonSplitItem[] = [
        {
            disabled: false,
            label: 'Save',
            handler: this.handleSaveWidget,
        },
        {
            disabled: false,
            label: 'Save and Close',
            handler: this.handleSaveAndCloseWidget,
        },
    ];

    handleExport = () => {
        const widget = convertGraphToAnyWidget(this.store.value.root, this.store.value);
        moveOldFieldsToLinesFields(widget);

        if (this.globalFiltersMode) {
            exportAnyWidget(pick(widget, GLOBAL_FILTER_FIELDS) as any, null, widget.chartOptions.title);

            this.toaster.successToast({
                title: 'Success',
                content: 'Dashboard Filters was successfully exported',
            });

            return;
        }

        exportAnyWidget(widget, this.toaster);
    };

    handleImport = async () => {
        const fileList = await loadFile('application/JSON');
        const data = await readFileToObject(fileList.item(0));
        data.id = this.store.originalId;
        const rootLayer = this.store.value.layers[this.store.value.root];
        const widget: AnyWidgetModel = this.globalFiltersMode
            ? createFakeWidget({
                  name: rootLayer.chartOptions.title,
                  ...(pick(data, GLOBAL_FILTER_FIELDS) as any),
              })
            : data;
        moveLineFieldsToOldFields(widget);
        this.editBlock.emit(null);

        this.store.init(normalizeWidgetModel(widget));
        this.store.dirty = true;
    };

    importExportActions: SaveButtonSplitItem[] = [
        {
            disabled: false,
            label: 'Import',
            handler: this.handleImport,
        },
        {
            disabled: false,
            label: 'Export',
            handler: this.handleExport,
        },
    ];

    ngOnChanges(changes: SimpleChanges) {
        if (changes.model || changes.globalFiltersMode) {
            this.buildLayout(this.model);
        }
    }

    buildLayout(state: WidgetBuilderState): void {
        const builder = new QueryLayoutBuilder(state);
        builder.isFilters = this.globalFiltersMode;

        let layout = builder.build();

        if (this.globalFiltersMode) {
            layout = {
                edges: layout.edges,
                blocks: layout.blocks.filter(block => ['filters', 'query', 'filter', 'filter-dynamic', 'layer'].includes(block.viewType)),
            };
        }

        this.layout = layout;

        if (this.facade.firstRender && this.facade.fromClone) {
            this.facade.firstRender = false;

            const rootBlock = this.layout.blocks.find(block => block.id === this.store.value.root);

            setTimeout(() => {
                this.editBlock.emit(rootBlock as T);
            });
        }
    }

    async handleDebug(): Promise<void> {
        this.debug.emit();

        if (this.isServer) {
            this.debuggerState.filters$.next(null);
            this.debuggerState.placeholdersMap = {};
            this.facade.currentWidget = cloneDeep(convertGraphToAnyWidget(this.store.value.root, this.store.value, false));
            moveOldFieldsToLinesFields(this.facade.currentWidget);
        } else {
            const logger = new WidgetErrorLogger();

            this.debuggerContainer.debugger = new WidgetDebugger(
                this.defaultPlaceholders,
                this.debuggerState,
                this.store,
                this.facade.externalOptions || {},
                this.api,
                new ClientWidgetScriptExecutor(logger),
                logger,
                this.globalFiltersMode,
                this.cdr,
                this.dateAdapter
            );

            await this.debuggerContainer.debugger.debug();
        }

        this.cdr.markForCheck();
    }

    async handleResume(): Promise<void> {
        if (this.isServer) {
            this.facade.widgetWorkflow?.resume$.next(null);
        } else {
            this.debuggerContainer.debugger.breakpoints = new Set(this.store.value.breakpoints);
            await this.debuggerContainer.debugger.resume();

            this.cdr.markForCheck();
        }
    }

    handleStop(): void {
        this.stopDebug.emit();
    }

    handleDropped(event: DropEvent<QueryBlock> | null): void {
        if (!event || !event.to) {
            return;
        }

        const { to, meta } = event;
        this.store.move({
            toId: to.dataset.id,
            fromLayerId: meta.layerId,
            movedId: meta.id,
            direction: to.dataset.direction as any,
            collectionName: meta instanceof QueryBlock ? 'queries' : 'filters',
        });
    }

    handleSave({ handler }: SaveButtonSplitItem) {
        handler.call(this);
    }

    async handleImportExport({ handler }: SaveButtonSplitItem) {
        await handler.call(this);
    }

    handleToggleBreakpoint({ block, position }: { block: QueryBlock; position: WidgetScript }): void {
        this.store.toggleBreakpoint({
            widgetId: block.layerId,
            queryId: block.id,

            level: block.flow,
            placeholder: block.placeholder,
            script: position,
        });
    }

    handleExportToGroovy() {
        if (!this.isServer) {
            return;
        }

        const placeholders = (this.facade.widgetWorkflow.widgetDebugger.placeholders$ as BehaviorSubject<any>).value;
        const script = JSON.stringify(placeholders, null, 4);

        navigator.clipboard.writeText(script).then(
            () => {
                this.toaster.successToast({ title: 'Success', content: 'Copied to clipboard' });
            },
            () => {
                this.toaster.errorToast({ title: 'Failure', content: 'Something went wrong' });
            }
        );
    }

    trackByBlocks: TrackByFunction<SVGBlock> = (_: number, block: SVGBlock) => block.id;
}
