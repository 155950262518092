<div class="graph-card-front" *ngIf="!dataLoad">
    <div class="wrapper">
        <div class="wrapper-cell">
            <div class="image-placeholder"></div>
            <div class="text-placeholder">
                <div class="text-line"></div>
                <div class="text-line"></div>
                <div class="text-line"></div>
                <div class="text-line"></div>
            </div>
        </div>
    </div>
</div>
