import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ENV_TOKEN } from '@app/tokens';

@Injectable({
    providedIn: 'root',
})
export class ThresholdSettingsModalService {
    baseUrl = `${this.env.notificationsApiURL}`;

    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {}

    getImportance() {
        return this.http.get<any>(`${this.baseUrl}/alert-settings/importance`);
    }

    getFrequencies() {
        return this.http.get<any>(`${this.baseUrl}/alert-settings/frequencies`);
    }

    getAggregators() {
        return this.http.get<any>(`${this.baseUrl}/alert-settings/conditions/aggregators`);
    }

    getLogicOperators() {
        return this.http.get<any>(`${this.baseUrl}/alert-settings/conditions/logic-operators`);
    }

    getConditions() {
        return this.http.get<any>(`${this.baseUrl}/alert-settings/conditions/operators`);
    }

    getMetrics() {
        return this.http.get<any>(`${this.baseUrl}/alert-settings/conditions/metrics`);
    }

    getSettings(search: string) {
        return this.http.get<any>(`${this.baseUrl}/alert-settings`, {
            params: search ? { search } : null,
        });
    }

    getSettingsForCurrentUser() {
        return this.http.get<any>(`${this.baseUrl}/alert-settings/user`);
    }

    getSettingsByWidgetId(id: number) {
        return this.http.get<any>(`${this.baseUrl}/alert-settings/widget/${id}`);
    }

    saveSettings(settings: any) {
        return this.http.post<any>(`${this.baseUrl}/alert-settings`, settings);
    }

    saveRelation(body: any) {
        return this.http.post<any>(`${this.baseUrl}/alert-settings/widget`, body);
    }

    deleteRelation(thresholdId: number, widgetId: number) {
        return this.http.delete<any>(`${this.baseUrl}/alert-settings/widget?alertSettingsId=${thresholdId}&widgetId=${widgetId}`);
    }

    deleteAlert(thresholdId: number) {
        return this.http.delete<any>(`${this.baseUrl}/alert-settings/${thresholdId}`);
    }

    updateSettings(settings: any) {
        return this.http.put<any>(`${this.baseUrl}/alert-settings/${settings.id}`, settings);
    }

    getSettingsById(id: number) {
        return this.http.get<any>(`${this.baseUrl}/alert-settings/${id}`);
    }

    deleteSettings(id: number) {
        return this.http.delete<any>(`${this.baseUrl}/alert-settings/${id}`);
    }

    downloadAttachedFile(id: string) {
        return this.http.get<HttpResponse<Blob>>(`${this.baseUrl}/alert-attachments/files/${id}`, { responseType: 'blob' as 'json' });
    }

    getAlertByName(name: string) {
        return this.http.get<any>(`${this.baseUrl}/alert-settings/find/by-name/${name}`);
    }

    getPlaceholders(): Observable<{ key: string; value: any }[]> {
        return this.http.get<any>(`${this.baseUrl}/alert-settings/text-placeholders`);
    }

    enableAlert(id: number, enable: boolean) {
        return this.http.post<any>(`${this.baseUrl}/alert-settings/${id}`, { enable });
    }

    getAlertSettingsByPipelineId(pipelineId: number) {
        return this.http.get<any>(`${this.baseUrl}/alert-settings/pipeline/${pipelineId}`);
    }

    addAlertPipelineRelation(pipelineId: number, alertSettingsId: number[]) {
        return this.http.post<any>(`${this.baseUrl}/alert-settings/pipeline`, { pipelineId, alertSettingsId });
    }

    deleteAlertPipelineRelation(pipelineId: number, alertSettingsId: number) {
        return this.http.delete<any>(`${this.baseUrl}/alert-settings/pipeline`, { params: { pipelineId, alertSettingsId } });
    }
}
