<div class="modal-header settings-header">
    <ng-container>
        <h2 class="modal-title settings-title">{{ 'Alert Text' }}</h2>
    </ng-container>

    <div class="pull-right">
        <button type="button" class="close" aria-describedby="modal-title" (click)="onClose()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>
<div class="modal-body">
    <div class="editor">
        <ng-container *ngIf="ckEditorLoaded$ | async; else loaderTemplate">
            <ck-editor (change)="change($event)" #editor name="bodyEditor" [config]="editorOptions"></ck-editor>
        </ng-container>
        <ng-template #loaderTemplate>
            <div class="d-flex justify-content-center my-4">
                <lib-spinner></lib-spinner>
            </div>
        </ng-template>
    </div>
</div>
<div class="modal-footer">
    <div class="d-inline-flex justify-content-end align-items-center">
        <lib-loader *ngIf="false" [size]="'15px'"></lib-loader>
        <button class="btn btn-secondary font-11px ml-20px" (click)="cancel()">Cancel</button>
        <button class="btn btn-primary font-11px ml-20px" (click)="save()">
            OK
        </button>
    </div>
</div>
