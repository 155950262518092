import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CiCdPlugin } from '../../admin/plugins/plugin-management/ci-cd-plugin';
import { ENV_TOKEN } from '@app/tokens';

@Injectable({
    providedIn: 'root',
})
export class CiCdPluginService {
    baseUrl = `${this.env.buildApiURL}/plugins`;

    constructor(@Inject(ENV_TOKEN) private env: Env, private http: HttpClient) {}

    getInstalledPlugins(): Observable<CiCdPlugin[]> {
        return this.http.get<CiCdPlugin[]>(`${this.baseUrl}/installed`);
    }

    getPluginServices(type: string): Observable<string[]> {
        let params = new HttpParams().append('type', type);
        return this.http.get<string[]>(`${this.baseUrl}/plugin-services`, { params });
    }
}
