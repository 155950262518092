<div class="wrapper-max-width-with-collapsed-sidenav ps-container" *ngIf="!hasErrors; else errorTemplate">
    <ng-container *ngIf="!loading; else loadingTemplate">
        <ng-container *ngIf="filtersGrid.length || editor; else noDataTemplate">
            <div *ngIf="filtersGrid.length" class="m-4 p-4 content-wrapper">
                <button class="btn btn-link ml-auto mr-30px mb-3" (click)="handleAdd()">
                    <fa-icon class="font-16px mr-5px" icon="plus"></fa-icon>
                    Add Filter
                </button>
                <table class="table">
                    <thead>
                    <tr>
                        <th *ngFor="let column of filterColumns">
                            {{ column.title }}
                        </th>
                    </tr>
                    </thead>
                    <tbody cdkDropList (cdkDropListDropped)="handleTableDrop($event)">
                    <tr cdkDrag [cdkDragData]="filter" *ngFor="let filter of filtersGrid">
                        <td *ngFor="let col of filterColumns" [style.width]="col.width">
                            <ng-container *ngIf="col.field !== 'action'; else actionTemp">
                                {{
                                col.field !== 'type'
                                    ? filter.filter.filter[col.field]
                                    : getFilterType(filter.filter.filter[col.field])
                                }}
                            </ng-container>
                            <ng-template #actionTemp>
                                <div class="d-flex">
                                    <fa-icon icon="facEdit" class="action-icon mr-8px" ngbTooltip="Edit" openDelay="800"
                                             (click)="handleFilterEdit(filter.filter)"></fa-icon>
                                    <fa-icon icon="facTrash" class="action-icon" ngbTooltip="Delete" openDelay="800"
                                             (click)="handleFilterDelete(filter.filter)"></fa-icon>
                                </div>
                            </ng-template>
                        </td>
                        <td cdkDragHandle>
                            <svg
                                class="row-drag-handle"
                                width="16"
                                height="16"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M4.5 6.5C4.77614 6.5 5 6.27614 5 6C5 5.72386 4.77614 5.5 4.5 5.5C4.22386 5.5 4 5.72386 4 6C4 6.27614 4.22386 6.5 4.5 6.5Z"
                                    fill="#BDBCBC"
                                />
                                <path
                                    d="M4.5 3C4.77614 3 5 2.77614 5 2.5C5 2.22386 4.77614 2 4.5 2C4.22386 2 4 2.22386 4 2.5C4 2.77614 4.22386 3 4.5 3Z"
                                    fill="#BDBCBC"
                                />
                                <path
                                    d="M4.5 10C4.77614 10 5 9.77614 5 9.5C5 9.22386 4.77614 9 4.5 9C4.22386 9 4 9.22386 4 9.5C4 9.77614 4.22386 10 4.5 10Z"
                                    fill="#BDBCBC"
                                />
                                <path
                                    d="M7.5 6.5C7.77614 6.5 8 6.27614 8 6C8 5.72386 7.77614 5.5 7.5 5.5C7.22386 5.5 7 5.72386 7 6C7 6.27614 7.22386 6.5 7.5 6.5Z"
                                    fill="#BDBCBC"
                                />
                                <path
                                    d="M7.5 3C7.77614 3 8 2.77614 8 2.5C8 2.22386 7.77614 2 7.5 2C7.22386 2 7 2.22386 7 2.5C7 2.77614 7.22386 3 7.5 3Z"
                                    fill="#BDBCBC"
                                />
                                <path
                                    d="M7.5 10C7.77614 10 8 9.77614 8 9.5C8 9.22386 7.77614 9 7.5 9C7.22386 9 7 9.22386 7 9.5C7 9.77614 7.22386 10 7.5 10Z"
                                    fill="#BDBCBC"
                                />
                                <path
                                    d="M4.5 6.5C4.77614 6.5 5 6.27614 5 6C5 5.72386 4.77614 5.5 4.5 5.5C4.22386 5.5 4 5.72386 4 6C4 6.27614 4.22386 6.5 4.5 6.5Z"
                                    stroke="#BDBCBC"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M4.5 3C4.77614 3 5 2.77614 5 2.5C5 2.22386 4.77614 2 4.5 2C4.22386 2 4 2.22386 4 2.5C4 2.77614 4.22386 3 4.5 3Z"
                                    stroke="#BDBCBC"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M4.5 10C4.77614 10 5 9.77614 5 9.5C5 9.22386 4.77614 9 4.5 9C4.22386 9 4 9.22386 4 9.5C4 9.77614 4.22386 10 4.5 10Z"
                                    stroke="#BDBCBC"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M7.5 6.5C7.77614 6.5 8 6.27614 8 6C8 5.72386 7.77614 5.5 7.5 5.5C7.22386 5.5 7 5.72386 7 6C7 6.27614 7.22386 6.5 7.5 6.5Z"
                                    stroke="#BDBCBC"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M7.5 3C7.77614 3 8 2.77614 8 2.5C8 2.22386 7.77614 2 7.5 2C7.22386 2 7 2.22386 7 2.5C7 2.77614 7.22386 3 7.5 3Z"
                                    stroke="#BDBCBC"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M7.5 10C7.77614 10 8 9.77614 8 9.5C8 9.22386 7.77614 9 7.5 9C7.22386 9 7 9.22386 7 9.5C7 9.77614 7.22386 10 7.5 10Z"
                                    stroke="#BDBCBC"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>
    </ng-container>
</div>

<div *ngIf="editor" class="editor-container d-flex">
    <dp-widget-builder-block-form
        style="z-index: 1050 !important;"
        class="flex-1 overflow-hidden"
        [block]="block"
        (close)="closeEditor()"
        (save)="selectedFilter?.uid ? updateFilter($event) : addFilter($event)"
        (delete)="deleteFilter()"
        [filtersFormConfig]="filtersFormConfig"
        (filtersFormOnChange)="switchFormGroup($event)"
    >
    </dp-widget-builder-block-form>
</div>

<ng-template #loadingTemplate>
    <perfect-scrollbar *ngIf="!hasErrors; else errorTemplate" class="wrapper-max-width-with-collapsed-sidenav">
        <div class="row main-content-wrapper m-4 no-gutters">
            <div class="mb-15px w-100 d-flex justify-content-center align-items-center">
                <lib-loader></lib-loader>
            </div>
        </div>
    </perfect-scrollbar>
</ng-template>

<ng-template #noDataTemplate>
    <div class="d-flex justify-content-center flex-column align-items-center" style="padding-top: 184px;">
        <h1>No data</h1>
        <span class="font-14px disable mt-8px">Add new filter to show data</span>
        <button class="btn btn-primary mt-4" (click)="handleAdd()">
            <fa-icon icon="plus" class="font-16px mr-10px"></fa-icon>
            Add New
        </button>
    </div>
</ng-template>

<ng-template #errorTemplate>
    <div
        style="width: 100%; height: 80vh; display: flex; align-items: center; justify-content: center; flex-direction: column;"
    >
        <img src="{{ icons.error }}" alt=""/>
        <h2>Data Set configuration is invalid</h2>
        <br/>
        <div class="body3 color-font-4">
            Please switch to Model tab and check the list of Issues to fix the configuration
        </div>
        <br/>
        <button class="btn btn-primary" (click)="backButton.emit({ nextId: 'Model' })">Back to Model tab</button>
    </div>
</ng-template>

<ng-template #queryReplacement>
    <div>Filter data model</div>
    <form class="editor-model mb-10px" [formGroup]="modelForm">
        <div class="editor-block">
            <lib-dropdown
                class="mr-20px"
                [width]="200"
                [multiple]="true"
                [(ngModel)]="selectedTools"
                [items]="toolItems"
                placeholder="Select JDBC Tools filter"
                label="JDBC Tools filter"
                [clearable]="true"
                (change)="filterModelList('TOOL')"
                formControlName="tools"
            >
            </lib-dropdown>
            <lib-dropdown
                [width]="350"
                [multiple]="true"
                [(ngModel)]="selectedSchemas"
                [items]="schemaItems"
                [setDisable]="(selectedTools?.length === 1 && selectedTools[0].value === undefined) || selectedTools?.length === 0"
                placeholder="Select Schemas filter"
                label="Schemas filter"
                [clearable]="true"
                (change)="filterModelList('SCHEMA')"
                formControlName="schemas"
            >
            </lib-dropdown>
        </div>
        <div class="editor-block mt-15px">
            <lib-dropdown
                [width]="570"
                [(ngModel)]="selectedModel"
                [clearable]="false"
                [items]="modelItems"
                valueKey="uid"
                labelKey="name"
                label="Filter Model"
                placeholder="Select Filter Model"
                (change)="onChangeModel($event)"
                formControlName="model"
            >
            </lib-dropdown>
        </div>
        <div class="editor-block mt-15px">
            <lib-dropdown
                class="mr-20px"
                [width]="275"
                [(ngModel)]="selectedLabelField"
                [items]="valueFieldItems"
                valueKey="dataField"
                labelKey="name"
                [setDisable]="!selectedModel"
                placeholder="Select Label Field"
                label="Label Field"
                [clearable]="false"
                (change)="onChangeLabelField($event)"
                formControlName="label"
            >
            </lib-dropdown>
            <lib-dropdown
                [width]="275"
                [(ngModel)]="selectedValueField"
                [items]="valueFieldItems"
                valueKey="dataField"
                labelKey="name"
                [setDisable]="!selectedModel"
                placeholder="Select Value Field"
                label="Value Field"
                [clearable]="true"
                (change)="onChangeValueField($event)"
                formControlName="value"
            >
            </lib-dropdown>
        </div>
    </form>
</ng-template>

<ng-template #checkDefaultTemplate>
    <div>Default value</div>
    <div class="mt-10px">
        <trigger [(ngModel)]="checkDefaultValue"></trigger>
    </div>
</ng-template>

<ng-template #dateDefaultTemplate>
    <lib-datepicker
        style="width: 200px"
        label="Default value"
        placeholder="Select Default value"
        [(ngModel)]="dateDefaultValue"
        inputDatetimeFormat="yyyy-MM-dd"
    >
    </lib-datepicker>
</ng-template>

<ng-template #dateRangeDefaultTemplate>
    <div>Default value</div>
    <div class="mt-10px" style="display: flex; flex-direction: row;">
        <lib-datepicker
            style="width: 200px"
            label="Start Date"
            placeholder="Select Start Date"
            [(ngModel)]="dateRangeDefaultValue.minimum"
            inputDatetimeFormat="yyyy-MM-dd"
        >
        </lib-datepicker>
        <lib-datepicker
            class="ml-15px"
            style="width: 200px"
            label="End Date"
            placeholder="Select End Date"
            [(ngModel)]="dateRangeDefaultValue.maximum"
            inputDatetimeFormat="yyyy-MM-dd"
        >
        </lib-datepicker>
    </div>
</ng-template>

<ng-template #dateNewRangeDefaultTemplate>
    <lib-datepicker
        mode="range"
        style="width: 300px"
        label="Default value"
        placeholder="Select Default value"
        [(ngModel)]="dateNewRangeDefaultValue"
    >
    </lib-datepicker>
</ng-template>

<ng-template #inputDefaultTemplate>
    <lib-input style="width: 200px" label="Default value" placeholder="Select Default value"
               [(ngModel)]="inputDefaultValue"></lib-input>
</ng-template>

<ng-template #ddrgDefaultTemplate>
    <lib-dropdown
        style="width: 200px"
        label="Default value"
        placeholder="Select Default value"
        [(ngModel)]="ddrgDefaultValue"
        [items]="ddrgDefaultValueItems"
        [multiple]="ddrgIsMultiple"
        [clearable]="true"
        [labelRemovable]="true"
        appendTo="body"
        valueKey="value"
        labelKey="label"
    >
    </lib-dropdown>
</ng-template>
