import { Component, ContentChild, Output, TemplateRef, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ToasterService } from '@dagility-ui/shared-components';
import { pick } from 'lodash';

import { DpDashboardService } from '../../../dashboard/services/dp-dashboard.service';
import { DashboardWidgetSettingsManager } from '../../../dashboard/services/dashboard-widget-settings.manager';
import { DataMorph } from '../../../dashboard/models/dp-dashboard.model';
import { AnyWidgetModel } from '../../models/any-widget.model';
import { DashboardMessageBus } from '../../services/message-bus/message-bus';
import { GLOBAL_FILTERS_EDITOR } from '../../providers/global-filters-editor.token';
import { FAKE_WIDGET_DATA_PROVIDER, GLOBAL_FILTER_FIELDS } from './fake-widget-provider';

@Component({
    selector: 'dp-dashboard-filter-set-settings',
    templateUrl: './dashboard-filter-set-settings.component.html',
    styleUrls: ['./dashboard-filter-set-settings.component.scss'],
    providers: [
        FAKE_WIDGET_DATA_PROVIDER,
        DashboardMessageBus,
        {
            provide: GLOBAL_FILTERS_EDITOR,
            useValue: true,
        },
    ],
})
export class DashboardFilterSetSettingsComponent {
    @Output() back = new EventEmitter();

    @ContentChild(TemplateRef) filters: TemplateRef<any>;

    dashboardName: string;
    dashboard: DataMorph.Dashboard;
    activeBlock: any;

    data$: Observable<any> = this.route.params.pipe(
        switchMap(({ dashboardId }) => this.dashboardManager.getDashboard(dashboardId, true)),
        tap(dashboard => (this.dashboard = dashboard)),
        tap(({ name }) => {
            this.dashboardName = name;
        })
    );

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private dashboardManager: DashboardWidgetSettingsManager,
        private api: DpDashboardService,
        private toaster: ToasterService
    ) {}

    handleSave(widgetModel: AnyWidgetModel) {
        const saved$ = new Subject<void>();

        this.api
            .updateDashboardOptions({
                ...this.dashboard,
                dashboardOptions: {
                    ...(this.dashboard.dashboardOptions || {}),
                    ...pick(widgetModel, GLOBAL_FILTER_FIELDS),
                } as any,
            })
            .subscribe(() => {
                this.toaster.successToast({
                    content: 'Saved successfully',
                    title: 'Success',
                });

                saved$.next();
            });

        return saved$.asObservable();
    }

    handleSaveAndClose(widget: AnyWidgetModel) {
        this.handleSave(widget).subscribe(() => this.quit());
    }

    quit() {
        if (this.dashboard.id) {
            this.back.emit(this.dashboard);

            return;
        }

        this.router.navigate(['../../', this.dashboard.dashboardId], {
            relativeTo: this.route,
        });
    }
}
