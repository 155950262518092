import { Component, Input } from '@angular/core';

@Component({
    selector: 'dp-export-loader',
    template: `<div class="d-flex flex-column align-items-center p-4" style="height: 20vh">
        <div class="title3">Dashboard {{fileType}} export is in progress</div>
        <lib-spinner class="mt-20px"></lib-spinner>
    </div>`,
})
export class ExportLoaderComponent {
    @Input() fileType: string;
}
