import { inject, Injectable } from '@angular/core';
import { EMPTY, from, Observable, of } from 'rxjs';
import { catchError, defaultIfEmpty, finalize, map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { AnyWidget, DashboardWidgetSettingsManager, DataMorph, WidgetBuilderService } from 'data-processor';

import { DpDashboardService } from '../../services/dp-dashboard.service';
import { DpDashboardStore } from '../../state/dp-dashboard.store';
import { CanAddWidgetToDashboardService } from '../../services/can-add-widget-to-dashboard.service';
import { DpDashboardFacade } from '../../state/dp-dashboard.facade';
import { GridsterOptionsService } from '../../services/gridster/gridster-options.service';
import { buildResponsiveOption, GridsterBreakpoint } from '../../services/gridster/angular2gridster.const';
import { GridsterEvent } from '../../services/gridster/gridster-resize.util';
import { AddWidgetFlowManager } from './add-widget-flow.manager';

@Injectable()
export class DashboardWidgetListFacade {
    private api = inject(WidgetBuilderService);
    private store = inject(DpDashboardStore);
    private dashboardApi = inject(DpDashboardService);
    private dashboardWidgetManager = inject(DashboardWidgetSettingsManager);
    private canAddWidget = inject(CanAddWidgetToDashboardService);
    private facade = inject(DpDashboardFacade);
    private gridsterOptionsService = inject(GridsterOptionsService);
    private addWidgetFlow = inject(AddWidgetFlowManager);

    addWidget(widgetId: number, groupId: number, e: GridsterEvent) {
        const added$ = this.api.getWidgetFromLibrary(widgetId).pipe(
            switchMap(anyWidget =>
                this.canAddWidget.check(anyWidget.data).pipe(switchMap(isGranted => (isGranted ? of(anyWidget) : EMPTY)))
            ),
            switchMap(anyWidget =>
                this.facade.createWidget(this.store.value.dashboardId, groupId, anyWidget).pipe(
                    switchMap(options =>
                        options
                            ? of({
                                  options,
                                  anyWidget: {
                                      ...anyWidget,
                                      groupId,
                                  },
                              })
                            : EMPTY
                    )
                )
            ),
            switchMap(({ anyWidget, options }) => this.saveWidgetWithWrapper(anyWidget, options)),
            defaultIfEmpty(null),
            catchError(err => {
                console.error(err);

                return of(null);
            }),
            shareReplay()
        );

        added$
            .pipe(
                finalize(() => {
                    this.store.ignoreReorder = false;
                    this.store.resetBackupState();
                })
            )
            .subscribe(illuminateWidget => {
                if (!illuminateWidget) {
                    this.store.ignoreReorder = false;
                    this.store.restoreGridsterState();
                    e.gridster.gridsterComponent.reload();

                    return;
                }

                const sizes = this.gridsterOptionsService.parseSize(illuminateWidget.options.size as string);
                const gridsterOptions = this.gridsterOptionsService.fillSizes(illuminateWidget, sizes);
                const { gridster, item } = e;
                const breakpoint = gridster.options.breakpoint as GridsterBreakpoint;
                const { x, y } = this.store.isGroupEmptySync(groupId) ? { x: 0, y: 0 } : item;
                gridsterOptions[breakpoint ? buildResponsiveOption(breakpoint, 'x') : 'x'] = x;
                gridsterOptions[breakpoint ? buildResponsiveOption(breakpoint, 'y') : 'y'] = y;
                this.store.ignoreReorder = false;

                this.store.addWidget(illuminateWidget, gridsterOptions);
            });

        return added$;
    }

    importWidget() {
        const import$ = this.api.importWidget().pipe(
            switchMap(anyWidget =>
                from(this.addWidgetFlow.add(anyWidget)).pipe(
                    switchMap(options =>
                        options ? this.api.createWidget(anyWidget).pipe(map(widgetWithId => ({ anyWidget: widgetWithId, options }))) : EMPTY
                    )
                )
            ),
            switchMap(({ options, anyWidget }) =>
                this.saveWidgetWithWrapper(Object.assign(anyWidget, { groupId: options.options.groupId }), options)
            ),
            shareReplay()
        );

        import$.subscribe(widget => {
            const sizes = this.gridsterOptionsService.getDefaultSizes(widget.data);
            const gridsterOptions = this.gridsterOptionsService.fillSizes(widget as any, sizes);
            gridsterOptions.x = 0;
            gridsterOptions.y = 0;

            this.store.addWidget(widget, gridsterOptions);
        });

        return import$;
    }

    saveWidgetWithWrapper(
        widget: AnyWidget,
        widgetOptions: Pick<DataMorph.IlluminateDashboardWidget, 'options' | 'illuminateOptions'>
    ): Observable<DataMorph.IlluminateDashboardWidget> {
        const { dashboardId } = this.store.value;
        let newDashboardWidgetId: number = null;

        return this.dashboardApi
            .saveWidgetOnDashboard({
                dashboardId,
                uuid: dashboardId.toString(),
                groupId: widget.groupId,
                options: widgetOptions.options,
                widgetId: widget.id,
                data: widget.data,
                dashboardWidgetId: null,
            })
            .pipe(
                tap(({ dashboardWidgetId }) => {
                    newDashboardWidgetId = dashboardWidgetId;
                }),
                switchMap(() =>
                    this.dashboardWidgetManager.saveWidget({
                        dashboardId,
                        uuid: dashboardId.toString(),
                        widgetId: widget.id,
                        illuminateOptions: widgetOptions.illuminateOptions,
                        dashboardWidgetId: newDashboardWidgetId,
                        options: null,
                        data: null,
                        groupId: null,
                        id: null,
                    })
                ),
                map(
                    ({ id }) =>
                        ({
                            dashboardId,
                            widgetId: widget.id,
                            illuminateOptions: widgetOptions.illuminateOptions,
                            options: widgetOptions.options,
                            dashboardWidgetId: newDashboardWidgetId,
                            data: widget.data,
                            groupId: widget.groupId,
                            id,
                        } as DataMorph.IlluminateDashboardWidget)
                )
            );
    }
}
