<div class="modal-header align-items-center border-0">
    <h2 class="modal-title mt-0">{{ headerText }}</h2>
    <button data-test-id="cross-button" type="button" class="close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form [formGroup]="form" class="modal-body">
    <lib-input data-test-id="dashboard-name" class="mb-4" entityNameControl label="Name" formControlName="name"></lib-input>
    <lib-textarea
        data-test-id="dashboard-description"
        class="mb-4"
        label="Description"
        formControlName="description"
        resizeMode="none"
        [resize]="false"
        [minlength]="descriptionBounds.min"
        [maxlength]="descriptionBounds.max.toString()"
    >
    </lib-textarea>

    <ng-template #formExtension></ng-template>

    <ng-content select="[body-extension]"></ng-content>
</form>
<div class="modal-footer border-0">
    <button data-test-id="cancel-button" class="btn btn-secondary" (click)="modal.dismiss()">Cancel</button>
    <button
        data-test-id="action-button"
        class="ml-4 btn btn-primary"
        [checkDirty]="checkDirty && checkDirtyFt"
        [submittedControl]="form"
        (submitAfterAsyncValidation)="handleSave()"
    >
        {{ actionButtonText }}
    </button>
</div>
