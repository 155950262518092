import { Component, Input, OnDestroy } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { map, takeUntil } from 'rxjs/operators';

import { toDropDownItem } from '@dagility-ui/kit';
import { ToolService } from '@dagility-ui/shared-components';

import { EditJobDefinitionService } from 'data-processor/lib/processor-monitoring/edit-job-definition-form/edit-job-definition.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'dp-edit-query-action',
    templateUrl: './edit-query-action.component.html',
    styleUrls: ['../templates.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class EditQueryActionComponent implements OnDestroy {
    @Input() definition: any;

    tools$ = this.toolService.getEnabledTools().pipe(
        map(tools => tools.sort((a, b) => a.localeCompare(b))),
        map(tools => ['POSTGRESQL', 'CLICKHOUSE'].concat(tools).map(toDropDownItem))
    );

    destroyed$ = new Subject<void>();

    get control() {
        return this.formGroup.control;
    }

    constructor(public store: EditJobDefinitionService, private toolService: ToolService, public formGroup: FormGroupDirective) {
        this.control
            .get('query')
            .valueChanges.pipe(takeUntil(this.destroyed$))
            .subscribe(queryValue => {
                this.definition.query = queryValue;
            });
        this.control
            .get('variable')
            .valueChanges.pipe(takeUntil(this.destroyed$))
            .subscribe(variableValue => {
                this.definition.variable = variableValue;
            });
        this.control
            .get('from')
            .valueChanges.pipe(takeUntil(this.destroyed$))
            .subscribe(fromValue => {
                this.definition.from = fromValue;
            });
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }
}
