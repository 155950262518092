import { Component, Input } from '@angular/core';

import {
    CriteriaRiskItem,
    PortfolioRiskSummary,
} from 'data-processor/lib/widget-library/widget-builder/components/risks-header/risks-header.model';

@Component({
    selector: 'dp-risk-count-square',
    templateUrl: './risk-count-square.component.html',
    styleUrls: ['./risk-count-square.component.scss'],
})
export class RiskCountSquareComponent {
    @Input() set data(value: PortfolioRiskSummary | CriteriaRiskItem) {
        this.viewData = value;
        this.isPortfolioScore = 'score' in value;
        this.viewData.chart = {
            options: this.getDefaultChartOptions(),
        };
    }

    isPortfolioScore: boolean;
    viewData: PortfolioRiskSummary | CriteriaRiskItem;

    transformColor(hex: string) {
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
            ? 'rgba(' + parseInt(result[1], 16) + ', ' + parseInt(result[2], 16) + ', ' + parseInt(result[3], 16) + ', 0.14)'
            : 'white';
    }

    getDefaultChartOptions() {
        return {
            title: {},
            type: 'linechart',
            series: [
                {
                    name: this.isPortfolioScore ? 'Risk Score' : 'Risk Count',
                    type: 'line',
                    data: this.viewData.chart,
                    lineStyle: { color: this.isPortfolioScore ? 'white' : this.viewData.color },
                    color: this.isPortfolioScore ? 'white' : this.viewData.color,
                },
            ],
            grid : {
                bottom: 0
            },
            tooltipFormatter: (item: any) =>
                this.isPortfolioScore ? item.seriesName + ': ' + item.value : item.marker + item.seriesName + ': ' + item.value,
            inserted: true,
        };
    }
}
