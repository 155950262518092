import { Directive, ElementRef, Input, NgZone, SimpleChanges } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GridsterComponent } from 'angular2gridster';

@Directive({
    selector: '[dpGridsterDir]',
})
export class DpDashboardGridsterDirective {
    @Input() edit: boolean;

    private readonly destroyGridsterMouseDown$ = new Subject();

    constructor(private gridsterComponent: GridsterComponent, private elRef: ElementRef, private zone: NgZone) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.edit) {
            setTimeout(() => {
                if (this.gridsterComponent) {
                    this.gridsterComponent.setOption('resizable', changes.edit.currentValue);

                    this.destroyGridsterMouseDown$.next();

                    if (changes.edit.currentValue) {
                        this.zone.runOutsideAngular(() => {
                            fromEvent(this.elRef.nativeElement, 'mousedown', { capture: true })
                                .pipe(takeUntil(this.destroyGridsterMouseDown$))
                                .subscribe((e: any) => {
                                    if (
                                        e.target.classList.contains('gridster-item-resizable-handler') ||
                                        e.target.classList.contains('ng-arrow-wrapper')
                                    ) {
                                        return;
                                    }
                                    e.stopPropagation = null;
                                    e.preventDefault = null;
                                    Object.defineProperty(e, 'cancelBubble', {
                                        configurable: true,
                                        get() {
                                            return false;
                                        },
                                        set() {
                                            /* intentional empty */
                                        },
                                    });

                                    if (document.activeElement && document.activeElement.className.startsWith('ag-')) {
                                        (document.activeElement as HTMLElement).blur = () => {
                                            /* intentional empty */
                                        };
                                    }
                                });
                        });
                    }
                }
            });
        }
    }

    ngOnDestroy() {
        this.destroyGridsterMouseDown$.next();
    }
}
