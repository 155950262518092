import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    DataSetField,
    FieldRole,
    FilterRequirement,
    IRelation,
    Model,
    ModelDataField,
    ModelGraph,
} from '@app/shared/components/query-builder/models/model-graph.model';
import {
    FieldRef,
    GraphMutationResult,
    ModelGraphRemoveModelCommand,
    ModelRelationCandidate,
} from '@app/shared/components/query-builder/models/model-graph-actions.model';
import {
    facHorizontalGrid,
    facSubMenu,
    filter,
    ModalConfirmComponent,
    ModalService,
    SortDirection,
    sortingDown,
    sortingUp,
} from '@dagility-ui/kit';
import { QueryBuilderStore } from '@app/shared/components/query-builder/store/query-builder.store';
import { ModelGraphActionsService } from '@app/shared/components/query-builder/services/model-graph-actions.service';
import { QB_ICON_URLS } from '@app/shared/components/query-builder/consts/qb-icon-urls.const';
import { TreeNode } from '@app/shared/components/query-builder/qb-model/components/graph-tree/node/tree-node.model';
import { clone, cloneDeep } from 'lodash';
import { SaveGraphModalComponent } from '@app/shared/components/query-builder/qb-model/components/editor/save-graph-modal/save-graph-modal.component';
import { ModelGraphService } from '@app/shared/components/query-builder/services/model-graph.service';
import { LockerActionsService } from '@app/shared/components/query-builder/store/locker-actions/locker-actions.service';

@Component({
    selector: 'app-qb-model-editor',
    templateUrl: './editor.component.html',
    styleUrls: ['./editor.component.scss'],
})
export class EditorComponent implements OnInit, OnChanges {
    @ViewChild('svgCommonBlock') svgCommonBlock: ElementRef;
    @ViewChild('svgContainer') svgContainer: ElementRef;
    @ViewChild('svgFixedContainer') svgFixedContainer: ElementRef;

    @Input() rootModels: Model[];
    @Input() rootModelTree: TreeNode[];
    @Input() compatibleOnlyMode: boolean;
    @Output() openRelationEditor = new EventEmitter<IRelation>();
    @Output() closeRelationEditor = new EventEmitter<any>();
    @Output() addRootModel = new EventEmitter<Model>();

    currentSvgScale: number = 1;
    svgModels: SvgModel[] = [];
    svgRelations: SvgRelation[] = [];
    icons = QB_ICON_URLS;
    customIcons = {
        sortingUp,
        sortingDown,
        facSubMenu,
        facHorizontalGrid,
        filter,
    };
    updatingRelations: boolean;
    allRelCandidates: ModelRelationCandidate[] = [];
    relNotEmptyMap: Map<string, boolean> = new Map<string, boolean>();
    loading = false;
    svgBorderOffset: SvgBorderOffset;
    isHovered = false;
    hintData: string;
    hintBox = { top: 0, left: 0 };
    subGraphHeader: string;
    subGraphBreadcrumbItems: Model[] = [];
    isMenuOpened: boolean = false;
    private svgMinScaleCoefficient = 0.5;
    private svgMaxScaleCoefficient = 2;
    private dropped: boolean = false;

    constructor(
        private modelGraphActionsService: ModelGraphActionsService,
        private queryBuilderStore: QueryBuilderStore,
        private modelGraphService: ModelGraphService,
        private modalService: ModalService,
        private elRef: ElementRef,
        private cdr: ChangeDetectorRef
    ) {
        this.updateGraph = this.updateGraph.bind(this);
    }

    get actionsLocked() {
        return LockerActionsService.lockedStatus();
    }

    get modelGraph(): ModelGraph {
        return this.queryBuilderStore.modelGraph;
    }

    get globalModelGraph(): ModelGraph {
        return this.queryBuilderStore.globalModelGraph;
    }

    get subGraphSelected() {
        return this.queryBuilderStore.subGraphSelected;
    }

    get drilldownSelected() {
        return this.queryBuilderStore.drilldownSelected;
    }

    get currentModelGraphUid() {
        return this.queryBuilderStore.modelGraph.uid;
    }

    get datasetFields(): DataSetField[] {
        return this.queryBuilderStore.modelGraph?.dataSetFields.fields;
    }

    set droppedStatus(status: boolean) {
        this.dropped = status;
    }

    ngOnInit(): void {
        this.svgBorderOffset = {
            topOffset: 0,
            rightOffset: 0,
            bottomOffset: 0,
            leftOffset: 0,
        };
        this.queryBuilderStore.$forcedEditorViewUpdate.subscribe(() => {
            this.updateView();
        });
    }

    ngOnChanges() {
        this.updateTreeNode();
    }

    openEditorForm(relation: IRelation) {
        this.openRelationEditor.emit(relation);
    }

    closeEditorForm() {
        this.closeRelationEditor.emit();
    }

    filterAttached(requirement: FilterRequirement): boolean {
        const modelGraph = this.queryBuilderStore.modelGraph;
        return Boolean(modelGraph?.filtersAttached.find(fa => fa.filterRequirementUid === requirement.uid));
    }

    findModelGraphUidByRootModel(model: Model): string {
        const rootModels = this.queryBuilderStore.modelGraph?.models;
        const subGraphs = rootModels.map(m => m.subQueryGraph).filter(g => g !== null);
        for (const subGraph of subGraphs) {
            if (subGraph.rootModelUid === model.uid) {
                return subGraph.uid;
            }
            subGraphs.push(...subGraph.models.map(m => m.subQueryGraph).filter(g => g !== null));
        }
        return null;
    }

    openSaveGraphModal() {
        const saveModal = this.modalService.open(
            SaveGraphModalComponent,
            {
                centered: true,
            },
            {
                names: this.rootModels.map(m => m.name),
            }
        );
        saveModal.result.then(result => {
            if (result.valid) {
                this.modelGraphService
                    .saveAsModel({
                        modelGraph: this.queryBuilderStore.modelGraph,
                        modelName: result.name,
                        modelDescription: result.description,
                    })
                    .subscribe(model => this.addRootModel.emit(model));
            }
        });
    }

    goToSubGraph(subGraphUid: string) {
        this.queryBuilderStore.setSubGraphUid(subGraphUid);
        this.dropped = false;
        this.checkModelChanges();
        this.setRelations();
        this.updateView();
        this.recalculateBorders();
    }

    attachModel(event: DragEvent) {
        this.closeEditorForm();
        this.dropped = true;
        if (this.loading) {
            return;
        }
        const data = JSON.parse(event.dataTransfer.getData('text')) as Model;
        if (this.queryBuilderStore.modelGraph === null) {
            this.loading = true;
            this.modelGraphActionsService.mutationNew({ sourceGraph: null, rootModelUid: data.uid }).subscribe(
                result => {
                    this.queryBuilderStore.updateState(result);
                },
                () => {
                    this.loading = false;
                },
                () => {
                    this.loading = false;
                }
            );
        } else {
            if (!this.allRelCandidates.map(c => c.referencedModelUid).includes(data.uid)) {
                this.openEditorForm({
                    leftModelField: '',
                    rightModelField: '',
                    leftModel:
                        this.queryBuilderStore.modelGraph?.rootModelUid && this.queryBuilderStore.modelGraph?.models.length === 1
                            ? this.queryBuilderStore.modelGraph?.models[0]
                            : null,
                    rightModel: data,
                    joinType: 'INNER',
                });
                return;
            }
            this.loading = true;
            this.modelGraphActionsService
                .addReference({
                    sourceGraph: this.queryBuilderStore.globalModelGraph,
                    relation: this.allRelCandidates.filter(c => c.referencedModelUid === data.uid).pop(),
                })
                .subscribe(
                    mutationResult => {
                        this.queryBuilderStore.updateState(mutationResult);
                    },
                    () => (this.loading = false),
                    () => (this.loading = false)
                );
        }
    }

    calculateModelPosition(svgModel: SvgModel) {
        if (!this.svgModels || !this.svgModels.length) {
            return;
        }
        let rightmostPosition = 0;
        let topPosition = 0;
        this.svgModels.forEach(model => {
            const modelElement = document.getElementById(model.model.uid);
            const modelRight =
                modelElement.getBoundingClientRect().right - this.svgContainer.nativeElement.getBoundingClientRect().left + 40;
            if (modelRight > rightmostPosition) {
                rightmostPosition = modelRight;
            }
            const modelTop = modelElement.getBoundingClientRect().top - this.svgContainer.nativeElement.getBoundingClientRect().top;
            if (modelTop > topPosition) {
                topPosition = modelTop;
            }
        });
        svgModel.positionX = (rightmostPosition + 40 * this.currentSvgScale) / this.currentSvgScale;
        svgModel.positionY = (topPosition + this.currentSvgScale) / this.currentSvgScale;
    }

    scale(step: any) {
        this.currentSvgScale = this.currentSvgScale + step / 100;
        if (this.currentSvgScale < this.svgMinScaleCoefficient) {
            this.currentSvgScale = this.svgMinScaleCoefficient;
        } else if (this.currentSvgScale > this.svgMaxScaleCoefficient) {
            this.currentSvgScale = this.svgMaxScaleCoefficient;
        }
        this.svgCommonBlock.nativeElement.setAttribute('transform', `scale(${this.currentSvgScale})`);
    }

    handleCheckbox(modelUid: string, field: ModelDataField) {
        const modelGraph = this.queryBuilderStore.globalModelGraph;
        const fields: FieldRef[] = [{ srcModelUid: modelUid, fieldName: field.name }];

        if (this.isCheckboxSelected(modelUid, field)) {
            this.modelGraphActionsService
                .unselectDsField({ fields, sourceGraph: modelGraph })
                .subscribe(result => this.queryBuilderStore.updateState(result));
        } else {
            this.modelGraphActionsService
                .selectDsField({ fields, sourceGraph: modelGraph })
                .subscribe(result => this.queryBuilderStore.updateState(result));
        }
    }

    setIsHovered(bool: boolean, item: string, event: any) {
        this.isHovered = bool;
        this.hintData = item;
        const target = event.target.getBoundingClientRect();
        const { height } = this.elRef.nativeElement.querySelector('#hintBoxRef').getBoundingClientRect();
        this.hintBox = { top: target.y - height - 180, left: target.x - 175 };
    }

    parseRelationDefinition(relation: string) {
        const definitions: Record<string, string> = {
            INNER: 'The INNER JOIN selects records that have matching values in both data tables',
            LEFT:
                'The LEFT JOIN returns all records from the left data table (table1), and the matching records from the right table (table2).',
            RIGHT:
                'The RIGHT JOIN returns all records from the right data table (table2), and the matching records from the left data table (table1).',
            FULL: 'The FULL OUTER JOIN returns all records when there is a match in left (table1) or right (table2) data table records.',
            CROSS:
                'The CROSS JOIN produces a result set which is the number of rows in the first data table multiplied by the number of rows in the second data table',
        };
        return definitions[relation];
    }

    isCheckboxExists(modelUid: string, field: ModelDataField) {
        const dataFields = this.queryBuilderStore.modelGraph?.dataSetFields.fields;
        return !!dataFields?.filter(f => f.srcModelUid === modelUid).find(datasetField => datasetField.dsFieldName === field.name);
    }

    isCheckboxSelected(modelUid: string, field: ModelDataField) {
        const dataFields = this.queryBuilderStore.modelGraph?.dataSetFields.fields;
        return dataFields?.filter(f => f.srcModelUid === modelUid).find(datasetField => datasetField.dsFieldName === field.name)?.selected;
    }

    handleMainCheckbox(modelUid: string) {
        const sourceGraph = this.queryBuilderStore.globalModelGraph;
        const fields: FieldRef[] = this.queryBuilderStore.modelGraph?.dataSetFields.fields
            .filter(field => field.srcModelUid === modelUid)
            .map(field => ({ srcModelUid: modelUid, fieldName: field.dsFieldName }));

        if (this.isMainCheckboxSelected(modelUid)) {
            this.modelGraphActionsService
                .unselectDsField({ fields, sourceGraph })
                .subscribe(result => this.queryBuilderStore.updateState(result));
        } else {
            this.modelGraphActionsService
                .selectDsField({ fields, sourceGraph })
                .subscribe(result => this.queryBuilderStore.updateState(result));
        }
    }

    isMainCheckboxExists(modelUid: string) {
        return this.queryBuilderStore.modelGraph?.dataSetFields.fields.filter(field => field.srcModelUid === modelUid).length > 0;
    }

    isMainCheckboxSelected(modelUid: string) {
        const dataFields = this.queryBuilderStore.modelGraph?.dataSetFields.fields;
        return (
            dataFields.filter(field => field.srcModelUid === modelUid).filter(field => field.selected).length ===
            dataFields.filter(field => field.srcModelUid === modelUid).length
        );
    }

    detachModel(svgModel: SvgModel) {
        if (this.actionsLocked) {
            return;
        }
        const model = svgModel.model;
        switch (model.type) {
            case 'MODEL': {
                if (
                    !this.drilldownSelected &&
                    !this.subGraphSelected &&
                    model.uid === this.queryBuilderStore.globalModelGraph.rootModelUid
                ) {
                    this.reset();
                    return;
                }
                this.loading = true;
                const deleteData: ModelGraphRemoveModelCommand = {
                    referencedModelUid: model.uid,
                    sourceGraph: this.queryBuilderStore.globalModelGraph,
                };
                this.modelGraphActionsService.removeReference(deleteData).subscribe(
                    mutationResult => {
                        this.queryBuilderStore.updateState(mutationResult);
                    },
                    () => (this.loading = false),
                    () => {
                        this.loading = false;
                        this.updateView();
                        this.recalculateBorders();
                    }
                );
            }
        }
    }

    initBlockPosition(elem: Element) {
        const viewCfgModels = this.queryBuilderStore.modelGraph?.view?.models?.find(model => model.modelUid === elem.id);
        if (viewCfgModels.x === 0 && viewCfgModels.y === 0) {
            viewCfgModels.x = 30;
            viewCfgModels.y = 90;
        }
        elem.setAttribute('x', String(viewCfgModels.x));
        elem.setAttribute('y', String(viewCfgModels.y));
    }

    setMoveFixedContainerEvent() {
        this.svgContainer.nativeElement.addEventListener('mousedown', () => {
            document.body.style.cursor = 'move';
            document.addEventListener('mousemove', onMouseMoveHandler);
        });
        document.addEventListener('mouseup', () => {
            document.removeEventListener('mousemove', onMouseMoveHandler);
            document.body.style.cursor = 'default';
        });
        const onMouseMoveHandler = (e: MouseEvent) => {
            const offsetX = -e.movementX;
            const offsetY = -e.movementY;
            this.svgFixedContainer.nativeElement.scrollBy({ left: offsetX, top: offsetY, behavior: 'smooth' });
        };
    }

    getPath(relation: SvgRelation) {
        const x1 =
            relation.from.getBoundingClientRect().left < relation.to.getBoundingClientRect().left
                ? (relation.from.getBoundingClientRect().right - this.svgContainer.nativeElement.getBoundingClientRect().left) /
                  this.currentSvgScale
                : (relation.from.getBoundingClientRect().left - this.svgContainer.nativeElement.getBoundingClientRect().left) /
                  this.currentSvgScale;
        const y1 =
            (relation.fromField.getBoundingClientRect().top -
                this.svgContainer.nativeElement.getBoundingClientRect().top +
                (relation.fromField.offsetHeight / 2) * this.currentSvgScale) /
            this.currentSvgScale;
        const x2 =
            relation.from.getBoundingClientRect().left < relation.to.getBoundingClientRect().left
                ? (relation.to.getBoundingClientRect().left - this.svgContainer.nativeElement.getBoundingClientRect().left) /
                  this.currentSvgScale
                : (relation.to.getBoundingClientRect().right - this.svgContainer.nativeElement.getBoundingClientRect().left) /
                  this.currentSvgScale;
        const y2 =
            (relation.toField.getBoundingClientRect().top -
                this.svgContainer.nativeElement.getBoundingClientRect().top +
                (relation.toField.offsetHeight / 2) * this.currentSvgScale) /
            this.currentSvgScale;
        const xm = (x1 + x2) / 2;
        const xOffset = x2 - x1 >= 12 ? 6 : x2 - x1 <= -12 ? -6 : (x2 - x1) / 2;
        const yOffset = y2 - y1 >= 12 ? 6 : y2 - y1 <= -12 ? -6 : (y2 - y1) / 2;
        return `M ${x1} ${y1} L ${xm - xOffset} ${y1} Q ${xm} ${y1} ${xm} ${y1 + yOffset} L ${xm} ${y2 - yOffset} Q ${xm} ${y2} ${xm +
            xOffset} ${y2} L ${x2} ${y2}`;
    }

    getFieldRole(roles: FieldRole[]) {
        if (!roles || !roles.length) {
            return '';
        }
        const types = roles.map(role => role.type);
        if (types.includes('PK')) {
            return 'PK';
        }
        if (types.includes('FK')) {
            return 'FK';
        }
        return '';
    }

    onWrapToSubQueryClick() {
        this.dropped = false;
        const sourceGraph = this.queryBuilderStore.globalModelGraph;
        this.modelGraphActionsService.createSubquery({ sourceGraph }).subscribe(result => {
            this.queryBuilderStore.updateState(result);
            this.updateView();
        });
    }

    updateFieldSort(field: DataSetField, dir: SortDirection) {
        const sourceGraph = this.queryBuilderStore.modelGraph;
        const gridDataSelected = sourceGraph?.dataSetFields?.fields
            .filter(f => f.selected)
            .filter(f => f.orderBy && f.orderBy.dir)
            .map(f => ({
                modelUid: f.srcModelUid,
                dsFieldName: f.dsFieldName,
                dsFieldUid: f.uid,
                selected: false,
                ord: f.orderBy.ord,
                dir: f.orderBy.dir,
            }))
            .sort((f1, f2) => (f1.ord > f2.ord ? 1 : -1));
        let fields = gridDataSelected.map(f => ({
            dsFieldUid: f.dsFieldUid,
            dir: f.dir,
        }));
        if (!field.orderBy) {
            fields.push({
                dsFieldUid: field.uid,
                dir,
            });
        }
        if (dir === undefined) {
            fields = fields.filter(f => f.dsFieldUid !== field.uid);
        } else {
            fields.find(f => f.dsFieldUid === field.uid).dir = dir;
        }
        this.modelGraphActionsService
            .updateOrderByFields({ fields, sourceGraph: this.queryBuilderStore.globalModelGraph })
            .subscribe(result => this.queryBuilderStore.updateState(result));
    }

    onEditClicked(modelUid: string, readOnly: boolean) {
        const element: any = document.getElementById(`${modelUid}-name`);
        if (readOnly) {
            element.readOnly = false;
            element.focus();
        } else {
            this.changeModelName(modelUid);
        }
    }

    onModelNameChange(event: any, uid: string) {
        const element: any = document.getElementById(`${uid}-name`);
        element.value = event.target.value.replace(/\W/g, '');
    }

    onBlur(modelUid: string) {
        const sourceGraph = this.queryBuilderStore.modelGraph;
        const element: any = document.getElementById(`${modelUid}-name`);
        element.value = sourceGraph?.models.find(model => model.uid === modelUid).name;
        element.readOnly = true;
    }

    changeModelName(modelUid: string) {
        const element: any = document.getElementById(`${modelUid}-name`);
        element.readOnly = true;
        const sourceGraph = this.queryBuilderStore.modelGraph;
        const globalModelGraph = this.queryBuilderStore.globalModelGraph;
        const modelName = sourceGraph?.models.find(model => model.uid === modelUid).name;
        const newName = element.value;
        if (newName && newName !== modelName) {
            this.modelGraphActionsService
                .renameModel({ modelUid, name: newName, sourceGraph: globalModelGraph })
                .subscribe(result => this.queryBuilderStore.updateState(result));
        } else {
            element.value = modelName;
        }
    }

    onMenuOpened(event: boolean) {
        this.isMenuOpened = event;
        this.recalculateBorders();
    }

    @HostListener('window:resize')
    recalculateBorders() {
        const commonBlockRect = this.svgCommonBlock?.nativeElement.getBoundingClientRect();
        const svgFixedContainerRect = this.svgFixedContainer?.nativeElement.getBoundingClientRect();
        const svgContainerRect = this.svgContainer?.nativeElement.getBoundingClientRect();
        const svgFixedContainerSize: { width: number; height: number } = {
            width: svgFixedContainerRect.right - svgFixedContainerRect.left,
            height: svgFixedContainerRect.bottom - svgFixedContainerRect.top,
        };

        const droppedOffset = this.isMenuOpened ? 200 : 0;
        this.svgBorderOffset = {
            topOffset: svgContainerRect?.bottom - commonBlockRect.top - svgFixedContainerSize.height,
            rightOffset: commonBlockRect.right - svgContainerRect.left - svgFixedContainerSize.width,
            bottomOffset: commonBlockRect?.bottom - svgContainerRect.top - svgFixedContainerSize.height,
            leftOffset: svgContainerRect.right - commonBlockRect.left - svgFixedContainerSize.width,
        };
        this.svgContainer.nativeElement.style.width = `calc(100% + ${
            this.svgBorderOffset.rightOffset > 0 ? this.svgBorderOffset.rightOffset + 20 : this.svgBorderOffset.rightOffset
        }px)`;
        this.svgContainer.nativeElement.style.height = `calc(100% + ${(this.svgBorderOffset.bottomOffset > 0
            ? this.svgBorderOffset.bottomOffset + 90
            : this.svgBorderOffset.bottomOffset) + droppedOffset}px)`;
    }

    onNewSubQueryClick() {
        this.dropped = true;
        const sourceGraph = this.queryBuilderStore.modelGraph;
        this.modelGraphActionsService.createEmptySubquery({ sourceGraph }).subscribe(result => {
            this.queryBuilderStore.updateState(result);
            this.updateView();
        });
    }

    isWithGroupByIcon(uid: string) {
        return this.datasetFields?.some(dsField => dsField.srcModelUid === uid && dsField.groupBy);
    }

    isGroupByActive(uid: string, dataField: string) {
        return this.datasetFields?.find(dsField => dsField.srcModelUid === uid && dsField.dsFieldName === dataField && dsField.groupBy);
    }

    isWithOrderByIcon(uid: string) {
        return this.datasetFields?.some(dsField => dsField.srcModelUid === uid && dsField.orderBy);
    }

    getSelectedDirection(uid: string, dataField: string) {
        const dsField = this.datasetFields?.find(f => f.srcModelUid === uid && f.dsFieldName === dataField);
        if (dsField && dsField.orderBy) {
            return dsField.orderBy.dir;
        }
        return undefined;
    }

    isSortDisabled(uid: string, dataField: string) {
        const dsField = this.datasetFields?.find(f => f.srcModelUid === uid && f.dsFieldName === dataField);
        return this.datasetFields?.some(f => f.groupBy) && !dsField?.groupBy;
    }

    handleGroupByClick(uid: string, dataField: string) {
        const sourceGraph = this.queryBuilderStore.globalModelGraph;
        const dsField = this.datasetFields?.find(f => f.srcModelUid === uid && f.dsFieldName === dataField && f.groupBy);
        const fieldRef = {
            srcModelUid: uid,
            fieldName: dataField,
        };
        if (dsField) {
            this.modelGraphActionsService
                .removeGroupByField({ fieldRefs: [fieldRef], sourceGraph })
                .subscribe(result => this.queryBuilderStore.updateState(result));
        } else {
            this.modelGraphActionsService
                .addGroupByField({ fieldRefs: [fieldRef], sourceGraph })
                .subscribe(result => this.queryBuilderStore.updateState(result));
        }
    }

    handleOrderByChange(dir: SortDirection, uid: string, dataField: string) {
        const dsField = this.datasetFields?.find(dsField => dsField.srcModelUid === uid && dsField.dsFieldName === dataField);
        this.updateFieldSort(dsField, dir);
    }

    updateModelSize(uid: string) {
        const modelItem = document.getElementById(uid);
        if (modelItem) {
            modelItem.setAttribute('width', String(modelItem.children[0].clientWidth));
            modelItem.setAttribute('height', String(modelItem.children[0].clientHeight));
        }
    }

    updateAllModelSizes() {
        if (this.svgFixedContainer.nativeElement && !this.svgFixedContainer.nativeElement.scrollWidth) {
            return;
        }
        this.svgModels
            .map(m => m.model)
            .forEach(f => {
                const modelItem = document.getElementById(f.uid);
                if (modelItem) {
                    modelItem.setAttribute('width', String(modelItem.children[0].clientWidth));
                    modelItem.setAttribute('height', String(modelItem.children[0].clientHeight));
                }
            });
    }

    private updateGraph(result: GraphMutationResult) {
        return this.queryBuilderStore.setState({
            modelGraph: result.graph,
            validationErrors: result.validationResults,
        });
    }

    @HostListener('document:queryBuilderChanged', ['$event'])
    private updateModel(event: CustomEvent) {
        if (!this.queryBuilderStore.modelGraph) {
            this.loading = false;
            this.svgModels = [];
            this.svgRelations = [];
            this.queryBuilderStore.init();
            this.resetRelationCandidates();
            return;
        }
        if (event.detail.changed === 'BOTH' || event.detail.changed === 'MODEL_GRAPH') {
            this.loading = true;
            this.checkModelChanges();
            this.setRelations();
            if (QueryBuilderStore.relationCandidatesChecking) {
                this.updateRelationCandidates();
            }
            QueryBuilderStore.relationCandidatesChecking = false;
            this.updateAllModelSizes();
            this.loading = false;
        }
        if (event.detail.changed === 'SUBQUERY') {
            this.loading = true;
            this.dropped = false;
            this.checkModelChanges();
            this.setRelations();
            this.updateRelationCandidates();
            this.updateView();
            this.loading = false;
        }
    }

    private updateRelationCandidates() {
        const graph = this.queryBuilderStore.modelGraph;
        this.modelGraphActionsService.allRelationCandidates(graph).subscribe(candidates => {
            this.allRelCandidates = candidates;
            graph?.models
                .map(model => model.uid)
                .forEach(modelUid => {
                    this.relNotEmptyMap.set(modelUid, this.allRelCandidates.map(c => c.parentModelUid).includes(modelUid));
                });
            graph?.models
                .map(model => model.uid)
                .filter(modelUid => !this.relNotEmptyMap.has(modelUid))
                .forEach(modelUid => this.relNotEmptyMap.delete(modelUid));
            this.updateTreeNode();
            this.updatingRelations = !this.updatingRelations;
        });
    }

    private resetRelationCandidates() {
        this.allRelCandidates = [];
        this.relNotEmptyMap.clear();
        this.resetTreeNode();
    }

    private updateTreeNode() {
        if (!this.queryBuilderStore.modelGraph) {
            return;
        }
        if (this.compatibleOnlyMode) {
            this.rootModelTree.forEach(model => {
                model.settings.disabled = !this.allRelCandidates.map(c => c.referencedModelUid).includes(model.data.uid);
                model.settings.draggable = !model.settings.disabled;
            });
        } else {
            this.rootModelTree.forEach(model => {
                model.settings.disabled = this.queryBuilderStore.modelGraph?.models.map(m => m.uid).includes(model.data.uid);
                model.settings.draggable = this.allRelCandidates.map(c => c.referencedModelUid).includes(model.data.uid);
            });
        }
    }

    private resetTreeNode() {
        this.rootModelTree.forEach(model => (model.settings.disabled = false));
    }

    private appendToArea(data: Model) {
        const svgModel: SvgModel = {
            model: data,
            positionX: 30,
            positionY: 90,
        };
        if (this.dropped) {
            this.calculateModelPosition(svgModel);
        }
        this.svgModels.push(svgModel);
        setTimeout(() => {
            const modelItem = document.getElementById(svgModel.model.uid);
            this.updateModelSize(svgModel.model.uid);
            this.setMoveEvent(modelItem);
            if (!this.dropped) {
                this.initBlockPosition(modelItem);
            }
            this.viewCfgHandler(svgModel.model.uid, svgModel.positionX, svgModel.positionY);
            this.setRelations();
        }, 100);
        this.recalculateBorders();
    }

    private updateView() {
        this.updateModelsView();
        this.updateRelationsView();
        this.setMoveFixedContainerEvent();
        this.updateSubGraphHeader();
        this.updateSubGraphBreadcrumb();
        this.cdr.detectChanges();
    }

    private updateModelsView() {
        const graph = this.queryBuilderStore.modelGraph;
        this.svgModels = this.svgModels.filter(
            model => graph?.models.map(m => m.uid).includes(model.model.uid) || graph?.filters.map(f => f.uid).includes(model.model.uid)
        );
    }

    private updateRelationsView() {
        this.svgRelations = this.svgRelations.filter(
            x => this.svgModels.map(m => m.model.uid).includes(x.from.id) && this.svgModels.map(m => m.model.uid).includes(x.to.id)
        );
    }

    private setMoveEvent(elem: Element) {
        let fixX = 0;
        let fixY = 0;
        const selectedModelGraph: ModelGraph = this.queryBuilderStore.modelGraph;
        elem.addEventListener('mousedown', (e: MouseEvent) => {
            fixX = e.pageX - elem.getBoundingClientRect().left;
            fixY = e.pageY - elem.getBoundingClientRect().top;
            document.addEventListener('mousemove', onMouseMoveHandler);
        });
        document.addEventListener('mouseup', () => {
            document.removeEventListener('mousemove', onMouseMoveHandler);
            document.body.style.cursor = 'default';
            this.recalculateBorders();
        });
        const onMouseMoveHandler = (e: MouseEvent) => {
            e.stopImmediatePropagation();
            elem.parentNode.insertBefore(elem, elem.previousElementSibling);
            document.body.style.cursor = 'move';
            const bounds = this.svgContainer.nativeElement.getBoundingClientRect();
            elem.setAttribute('x', '0');
            elem.setAttribute('y', '0');
            const x = (e.pageX - bounds.left) / this.currentSvgScale - fixX;
            const y = (e.pageY - bounds.top) / this.currentSvgScale - fixY;
            const selectedModelViewCfg = selectedModelGraph?.view.models?.find(model => model.modelUid === elem.id);
            if (selectedModelViewCfg) {
                selectedModelViewCfg.notPositioned = false;
                selectedModelViewCfg.x = Math.floor(x);
                selectedModelViewCfg.y = Math.floor(y);
            }
            this.viewCfgHandler(elem.id, x, y);
            elem.setAttribute('transform', `translate(${x}, ${y})`);
        };
    }

    private viewCfgHandler(modelUid: string, posX: number, posY: number) {
        const viewModel = this.queryBuilderStore.modelGraph?.view?.models?.filter(model => model.modelUid === modelUid)[0];
        if (viewModel) {
            if (viewModel.x === 0 && viewModel.y === 0) {
                viewModel.x = posX;
                viewModel.y = posY;
            }
        }
    }

    private checkModelChanges(): void {
        const modelGraph = this.queryBuilderStore.modelGraph;
        if (!modelGraph) {
            return;
        }

        const modelsSvgUIds = this.svgModels.map(value => value.model).map(model => model.uid);
        const uncommittedModelUIds: string[] = [];

        const modelsAndFilters = [...modelGraph.models, ...modelGraph.filters];

        modelsAndFilters
            .map(model => model.uid)
            .forEach(modelUid => {
                if (!modelsSvgUIds.includes(modelUid)) {
                    uncommittedModelUIds.push(modelUid);
                }
            });
        if (uncommittedModelUIds.length > 0) {
            for (const uid of uncommittedModelUIds) {
                const model: Model = modelsAndFilters.find(m => m.uid === uid);
                if ((model.type === 'FILTER' && modelGraph.filtersAttached.find(x => x.filterModelUid === uid)) || model.type === 'MODEL') {
                    this.appendToArea(model);
                }
            }
        }
    }

    private setRelations() {
        const references = this.queryBuilderStore.modelGraph ? this.queryBuilderStore.modelGraph.references : null;
        if (!references) {
            return;
        }
        references.forEach(reference => {
            const fromElem = document.getElementById(reference.parentModelUid);
            const fromField = document.getElementById(`${reference.parentModelUid}-${reference.joinCondition.leftModelField}`);
            const toElem = document.getElementById(reference.referencedModelUid);
            const toField = document.getElementById(`${reference.referencedModelUid}-${reference.joinCondition.rightModelField}`);
            if (!fromElem || !fromField || !toElem || !toField) {
                return;
            }
            const svgRelation: SvgRelation = {
                from: fromElem,
                fromField,
                to: toElem,
                toField,
                lineInText: reference.joinType,
                data: {
                    leftModel: this.queryBuilderStore.modelGraph.models.find(m => m.uid === reference.parentModelUid),
                    rightModel: this.queryBuilderStore.modelGraph.models.find(m => m.uid === reference.referencedModelUid),
                    joinType: reference.joinType,
                    leftModelField: reference.joinCondition.leftModelField,
                    rightModelField: reference.joinCondition.rightModelField,
                },
            };
            if (!this.svgRelations.some(r => r.from.id === svgRelation.from.id && r.to.id === svgRelation.to.id)) {
                this.svgRelations.push(svgRelation);
            } else {
                this.svgRelations.find(r => r.from.id === svgRelation.from.id && r.to.id === svgRelation.to.id).lineInText =
                    svgRelation.lineInText;
            }
        });
        const filtersAttached = this.queryBuilderStore.modelGraph?.filtersAttached;
        filtersAttached.forEach(reference => {
            const fromElem = document.getElementById(reference.parentModelUid);
            const fromField = document.getElementById(reference.filterRequirementUid);
            const toElem = document.getElementById(reference.filterModelUid);
            const toField = document.getElementById(`${reference.filterModelUid}-header`);
            const svgRelation: SvgRelation = {
                from: fromElem,
                fromField,
                to: toElem,
                toField,
                data: null,
            };
            if (!fromElem || !fromField || !toElem || !toField) {
                return;
            }
            if (!this.svgRelations.some(r => r.from.id === svgRelation.from.id && r.to.id === svgRelation?.to.id)) {
                this.svgRelations.push(svgRelation);
            }
        });
    }

    private updateSubGraphHeader() {
        const drilldownGraph: ModelGraph = this.queryBuilderStore.drilldownGraph;
        const modelGraphUid = cloneDeep<string>(this.queryBuilderStore.subGraphUid);
        if (!modelGraphUid) {
            this.subGraphHeader = null;
            return;
        }
        const models: Model[] = clone(drilldownGraph.models);
        for (const model of models) {
            const subQueryGraph = model.subQueryGraph;
            if (subQueryGraph) {
                if (modelGraphUid === subQueryGraph.uid) {
                    this.subGraphHeader = model.name;
                } else {
                    models.push(...subQueryGraph.models);
                }
            }
        }
    }

    private updateSubGraphBreadcrumb() {
        const drilldownGraph: ModelGraph = this.queryBuilderStore.drilldownGraph;
        let currentModelGraphUid = cloneDeep<string>(this.queryBuilderStore.subGraphUid);
        if (!currentModelGraphUid) {
            return;
        }
        const models: Model[] = clone(drilldownGraph.models);
        for (const model of models) {
            const subQueryGraph = model.subQueryGraph;
            if (subQueryGraph) {
                models.push(...subQueryGraph.models);
            }
        }
        const breadcrumbModels: Model[] = [];
        const filteredModels = models.filter(model => model.subQueryGraph);
        for (let i = 0; i < filteredModels.length; i++) {
            if (filteredModels[i].subQueryGraph.uid === currentModelGraphUid) {
                breadcrumbModels.push(filteredModels[i]);
                currentModelGraphUid = this.getModelGraphByModel(filteredModels[i].uid);
                i = 0;
            }
        }
        models
            .filter(model => model.subQueryGraph)
            .forEach(model => {
                if (model.subQueryGraph.uid === currentModelGraphUid) {
                    breadcrumbModels.push(model);
                    currentModelGraphUid = this.getModelGraphByModel(model.uid);
                }
            });
        this.subGraphBreadcrumbItems = breadcrumbModels.reverse();
    }

    private getModelGraphByModel(modelUid: string): string {
        const drilldownGraph: ModelGraph = this.queryBuilderStore.drilldownGraph;
        const modelGraphs: ModelGraph[] = cloneDeep<ModelGraph[]>([drilldownGraph]);
        for (const modelGraph of modelGraphs) {
            const models = modelGraph.models;
            if (models.filter(model => model.uid === modelUid).length > 0) {
                return modelGraph.uid;
            } else {
                modelGraphs.push(...models.filter(model => model.subQueryGraph !== null).map(model => model.subQueryGraph));
            }
        }
        return null;
    }

    private reset(): void {
        const modalRef = this.modalService.open(ModalConfirmComponent, {
            centered: true,
            backdrop: 'static',
        });
        modalRef.componentInstance.message = {
            title: `Delete Root Model?`,
            content: `Current Model will be reset. Continue?`,
        };
        modalRef.componentInstance.confirmOk.subscribe(() => {
            this.loading = false;
            this.svgModels = [];
            this.svgRelations = [];
            this.queryBuilderStore.init();
            this.resetRelationCandidates();
        });
    }
}

export interface SvgModel {
    model: Model;
    positionX: number;
    positionY: number;
}

export interface SvgRelation {
    from: HTMLElement;
    fromField: HTMLElement;
    to: HTMLElement;
    toField: HTMLElement;
    lineInText?: string;
    data: IRelation;
}

export interface SvgBorderOffset {
    topOffset: number;
    rightOffset: number;
    bottomOffset: number;
    leftOffset: number;
}
