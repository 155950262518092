import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PerfectScrollbarComponent, PerfectScrollbarConfig } from 'ngx-perfect-scrollbar';

import { FieldsGridValidators } from '../validators/fields-grid-validators';
import { FIELD_TYPE_ITEMS, optionsGridColumns } from '../constants/fields-grid.constants';
import { CiCdFieldDescription, CiCdFieldType, CiCdOption } from '../../../model/fields.model';
import {
    DropdownItem,
    facPlusRegular,
    GridColumn,
    sortObjectArrayByKey,
    toDropDownItemCapitalizedLabel,
    validateFormAndDisplayErrors,
} from '@dagility-ui/kit';
import { normalizeField } from '../utils/fields-grid-utils';
import { CompletionSourceItem } from '../../../../../directives/properties-completion';

const simpleInputTypes = ['STRING', 'NUMBER', 'BOOLEAN'];
const textAreaInputTypes = ['GROOVY'];
const multiLineTextAreaType = ['STRING_MULTILINE'];

@Component({
    selector: 'lib-new-field-popup',
    templateUrl: './new-field-popup.component.html',
    styleUrls: ['./new-field-popup.component.scss'],
})
export class NewFieldPopupComponent implements OnInit {
    @Input() field: CiCdFieldDescription;
    @Input() editMode = false;
    @Input() fieldsArray: CiCdFieldDescription[];
    @Input() hideEmbedded = false;
    @Input() disableTypeDropdown = false;
    @Input() groovyTypeIsHidden = true;
    @Input() allowedTypes: CiCdFieldType[];
    @Input() readonly = false;
    @Input() propertiesCompletion: CompletionSourceItem[] = [];
    @Input() fieldOptionsContextHelpTemplate: TemplateRef<any>;
    @ViewChild('optionsScrollRef') optionsScrollRef: PerfectScrollbarComponent;

    perfectScrollbarConfig: PerfectScrollbarConfig = new PerfectScrollbarConfig({
        suppressScrollX: false,
        suppressScrollY: false,
        useBothWheelAxes: true,
    });

    fieldTypeItems: DropdownItem[];

    formGroup: FormGroup;

    optionsGridColumns: GridColumn[];
    currentSortingField = '';

    icons = {
        facPlusRegular: facPlusRegular,
    };
    isSimpleType = true;
    isTextAreaType = true;
    isMultiLineTextAreaType = true;

    private previousTypeValue: CiCdFieldType;

    constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private cdr: ChangeDetectorRef) {}

    get fieldConfiguration() {
        return (this.formGroup.get('fieldConfiguration') || {}) as FormGroup;
    }

    get optionsControls() {
        return (this.fieldConfiguration.get(`options`) || []) as FormArray;
    }

    get nameControl() {
        return this.fieldConfiguration.get('name') || null;
    }

    get labelControl() {
        return this.fieldConfiguration.get('label') || null;
    }

    get typeControl() {
        return this.fieldConfiguration.get('type') || null;
    }

    get defaultValueControl() {
        return this.fieldConfiguration.get('defaultValue') || null;
    }

    ngOnInit() {
        const optionsControlsArray = this.getOptionsControlsArray(this.field.options);
        this.optionsGridColumns = optionsGridColumns;

        this.formGroup = this.fb.group(
            {
                fieldConfiguration: this.fb.group({
                    name: [{ value: this.field.name, disabled: this.readonly }, [Validators.required, Validators.pattern(`^(?!\\.)(?!.*\\.$)(?!.*\\.\\.)[a-zA-Z0-9_.]+$`)]],
                    label: [{ value: this.field.label, disabled: this.readonly }, []],
                    type: [
                        {
                            value: this.field.type,
                            disabled: this.readonly || this.disableTypeDropdown,
                        },
                        [Validators.required],
                    ],
                    defaultValue: [{ value: this.field.defaultValue && this.field.defaultValue.toString(), disabled: this.readonly }],
                    description: [{ value: this.field.description || '', disabled: this.readonly }],
                    embedded: [{ value: this.field.embedded, disabled: this.readonly }],
                    mandatory: [{ value: this.field.mandatory, disabled: this.readonly }],
                    array: [{ value: this.field.array, disabled: this.readonly }],
                    runtime: [{ value: this.field.runtime, disabled: this.readonly }],
                    options: optionsControlsArray,
                }),
                _fieldsArray: [this.fieldsArray],
                _oldFieldName: [this.field.name],
            },
            {
                validators: [
                    FieldsGridValidators.FieldNameUniqueValidation,
                    FieldsGridValidators.FieldOptionsLabelDuplication,
                    FieldsGridValidators.FieldOptionsValueDuplication,
                    FieldsGridValidators.FieldDefaultValueTypeValidation,
                    FieldsGridValidators.FieldOptionsValueTypeValidation,
                    FieldsGridValidators.FieldDefaultInOptionsValues,
                ],
            }
        );

        this.changeOptionsSort(this.optionsGridColumns[0]);

        this.fieldTypeItems = this.allowedTypes
            ? this.allowedTypes.map(toDropDownItemCapitalizedLabel)
            : FIELD_TYPE_ITEMS.map(toDropDownItemCapitalizedLabel);
        this.setDefaultValueControlFlags(this.field.type);
        this.previousTypeValue = this.field.type;
    }

    getOptionsControlsArray(options: CiCdOption[]) {
        return this.fb.array(
            (options || []).map(option => {
                return this.fb.group({
                    label: [{ value: option.label, disabled: this.readonly }, [Validators.required]],
                    value: [{ value: option.value.toString(), disabled: this.readonly }, [Validators.required]],
                });
            })
        );
    }

    submit() {
        validateFormAndDisplayErrors(this.formGroup);
        if (this.formGroup.valid) {
            let formValue = this.fieldConfiguration.getRawValue();
            formValue = normalizeField(formValue);
            Object.keys(formValue).forEach(key => {
                (this.field as Record<string, any>)[key] = formValue[key];
            });
            this.formGroup.markAsPristine();
            this.activeModal.close(true);
        }
    }

    cancel() {
        this.activeModal.dismiss();
    }

    deleteAllOptions() {
        this.optionsControls.clear();
        this.formGroup.markAsDirty();
    }

    addOption() {
        const hasEmptyOptions = this.optionsControls.value.some((option: any) => {
            return !option.label || !option.value;
        });
        if (!hasEmptyOptions) {
            this.optionsControls.push(
                this.fb.group({
                    label: ['', [Validators.required]],
                    value: ['', [Validators.required]],
                })
            );
            this.cdr.detectChanges();
            this.scrollToBottom();
        }
    }

    scrollToBottom() {
        if (this.optionsScrollRef && this.optionsScrollRef.directiveRef) {
            this.optionsScrollRef.directiveRef.scrollToBottom();
        }
    }

    removeOption(index: number, options: FormArray) {
        options.removeAt(index);
        this.formGroup.markAsDirty();
    }

    changeOptionsSort(column: GridColumn) {
        if (column.sortingField) {
            if (column.sortingField !== this.currentSortingField) {
                this.currentSortingField = column.sortingField;
            } else {
                column.sortAsc = !column.sortAsc;
            }
            let valueArray = this.optionsControls.value;
            valueArray = sortObjectArrayByKey(valueArray, this.currentSortingField, column.sortAsc);
            this.optionsControls.patchValue(valueArray);
        }
    }

    showConfirm() {
        return confirm('Field configuration has been modified. Your changes will be lost if you leave the page.');
    }

    confirmSavingGroovy() {
        return confirm(
            'Change field type to "Groovy script"? Window view will be changed, "Is Array" option and "Options" values will be lost.'
        );
    }

    setDefaultValueControlFlags(value: any) {
        this.isSimpleType = simpleInputTypes.indexOf(value) >= 0;
        this.isTextAreaType = textAreaInputTypes.indexOf(value) >= 0;
        this.isMultiLineTextAreaType = multiLineTextAreaType.indexOf(value) >= 0;
    }

    onTypeChanged(value: any) {
        if (value === 'GROOVY') {
            const groovyIncompatibleFields =
                this.fieldConfiguration.value.array ||
                (this.fieldConfiguration.value.options && this.fieldConfiguration.value.options.length);

            if (!groovyIncompatibleFields || (groovyIncompatibleFields && this.confirmSavingGroovy())) {
                this.setDefaultValueControlFlags(value);
                this.fieldConfiguration.get('array').patchValue(false);
                this.fieldConfiguration.setControl('options', this.getOptionsControlsArray([]));
                this.previousTypeValue = this.fieldConfiguration.value.type;
            } else {
                this.fieldConfiguration.get('type').patchValue(this.previousTypeValue);
                this.setDefaultValueControlFlags(this.previousTypeValue);
            }
        } else {
            this.previousTypeValue = value;
            this.setDefaultValueControlFlags(value);
        }
    }
}
