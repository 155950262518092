<div class="bg-white start-job-modal">
    <div class="modal-header align-items-center">
        <h6 class="font-14px header-text mb-0">Do you really want to run job ?</h6>
        <button type="button" class="close ml-auto" aria-label="Close button" aria-describedby="modal-title" (click)="modalService.close()">
            <fa-icon [icon]="icons.facClose" class="close-modal-window-icon font-14px"></fa-icon>
        </button>
    </div>

    <div class="modal-body">
        <div *ngIf="params" class="header-info-text">The following parameters are required for the job start.</div>
        <div *ngIf="paramsLoaded; else errorTemplate">
            <form *ngIf="form" [formGroup]="form">
                <div class="h-100">
                    <div class="d-flex flex-column start-pipeline-scrollbar">
                        <lib-properties-editor
                            *ngIf="params"
                            class="mt-30px runtime-properties-editor"
                            formControlName="configuration"
                            [fieldsEditAllowed]="false"
                            [fieldConfigContextHelpTemplate]="templateProvider.contextHelpTemplate"
                            [fieldOptionsContextHelpTemplate]="templateProvider.contextHelpTemplate"
                        ></lib-properties-editor>
                        <div class="row mt-30px ml-auto mb-30px">
                            <button (click)="modalService.dismiss()" class="btn btn-secondary mr-25px font-11px">Cancel</button>
                            <button type="button" (click)="startJob()" class="btn btn-primary all mr-25px font-11px">
                                <fa-icon icon="facStart" class="mr-7px"></fa-icon> Start
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <ng-template #errorTemplate>
        <div class="mb-30px d-inline-flex justify-content-center align-items-center w-100 loader-wrapper">
            <lib-spinner></lib-spinner>
        </div>
    </ng-template>
</div>
