import { WorkflowAction, WorkflowActionType } from 'data-processor/lib/processor-monitoring/edit-job-definition-form/models/actions';
import { moveLineFieldToOldField, moveOldFieldToLinesField, removeOldField } from 'data-processor/lib/common';

const LINES_FIELDS: Partial<Record<WorkflowActionType, string>> = {
    GROOVY: 'script',
    QUERY: 'query',
};

export function moveToLinesJDFields(actions: WorkflowAction[]) {
    traverseWorkflowActions(actions, action => {
        const lineField = LINES_FIELDS[action.type];

        if (lineField) {
            moveOldFieldToLinesField(action, lineField as any, () => `lines`);
            delete (action as any)[lineField];
        }
    });
}

export function removeOldJDFields(actions: WorkflowAction[]) {
    traverseWorkflowActions(actions, action => {
        const lineField = LINES_FIELDS[action.type];

        if (lineField) {
            removeOldField(action, lineField as any);
        }
    });
}

export function moveToOldJDFields(actions: WorkflowAction[]) {
    traverseWorkflowActions(actions, action => {
        const lineField = LINES_FIELDS[action.type];

        if (lineField) {
            moveLineFieldToOldField(action, lineField as any, () => `lines`);
            delete (action as any).lines;
        }
    });
}

function traverseWorkflowActions(actions: WorkflowAction[], traverseFn: (action: WorkflowAction) => void) {
    for (const action of actions) {
        traverseFn(action);

        if (action.type === 'LOOP') {
            traverseWorkflowActions((action as any).actions || [], traverseFn);
        }

        if (action.type === 'FORK') {
            for (const branch of (action as any).actions || []) {
                traverseWorkflowActions(branch, traverseFn);
            }
        }
    }
}
