import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { filter as filterObs } from 'rxjs/operators';

import { BaseWidgetFilter } from '../base-widget-filter.component';

@Component({
    selector: 'dp-range-filter',
    templateUrl: './range-filter.component.html',
    styleUrls: ['./range-filter.component.scss'],
})
export class RangeFilterComponent extends BaseWidgetFilter {
    formGroup = new FormGroup({
        minimum: new FormControl(),
        maximum: new FormControl(),
    });

    getFilterText(): { title: string; value: any } {
        return {
            title: 'Range',
            value:
                BaseWidgetFilter.transformDate(this.formGroup.get('minimum').value) +
                ' - ' +
                BaseWidgetFilter.transformDate(this.formGroup.get('maximum').value),
        };
    }

    writeValue(value: any): void {
        this.formGroup.patchValue(value);
    }

    registerOnChange(fn: any): void {
        this.subscription.unsubscribe();
        this.subscription = this.formGroup.valueChanges
            .pipe(
                filterObs(({ minimum, maximum }) => {
                    if (!maximum || !minimum) {
                        return true;
                    }

                    return Date.parse(minimum) <= Date.parse(maximum);
                })
            )
            .subscribe(({ minimum, maximum }) =>
                fn({
                    minimum: BaseWidgetFilter.transformDate(minimum),
                    maximum: BaseWidgetFilter.transformDate(maximum),
                })
            );
    }
}
