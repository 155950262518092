import { AnyWidgetModel, WidgetFilter } from 'data-processor';
import { buildFilterDependenciesMap } from 'data-processor/lib/widget-library/widget-builder/services/widget-builder.util';

type Placeholder = WidgetFilter['placeholder'];

export class FilterDependencyTreeBuilder {
    private cache = new Map<Placeholder, FilterDependencyTree>();
    private filterDependenciesMap: Record<Placeholder, Placeholder[]>;
    private filterIndexMap: Map<Placeholder, number> = new Map<Placeholder, number>();

    init({ filters = [] }: AnyWidgetModel) {
        this.cache.clear();
        this.filterDependenciesMap = buildFilterDependenciesMap(filters);
        this.filterIndexMap = new Map(filters.map((filter, idx) => [filter.placeholder, idx]));
    }

    buildTree(placeholder: Placeholder) {
        if (!this.cache.has(placeholder)) {
            const rootLevel = new FilterDependencyTree(placeholder, []);
            this.fillTreeLevel(rootLevel);

            this.cache.set(placeholder, rootLevel);
        }

        return this.cache.get(placeholder);
    }

    private fillTreeLevel(tree: FilterDependencyTree) {
        const dependencies = this.filterDependenciesMap[tree.filter] || [];

        for (const slaveFilter of dependencies) {
            const child = new FilterDependencyTree(slaveFilter, []);
            tree.children.push(child);
            this.fillTreeLevel(child);
        }

        tree.children.sort((a, b) => this.filterIndexMap.get(a.filter) - this.filterIndexMap.get(b.filter));
    }

    reset() {
        this.cache.clear();
    }
}

class FilterDependencyTree {
    constructor(public filter: Placeholder, public children: FilterDependencyTree[]) {}

    walk(walkFn: (root: FilterDependencyTree) => void) {
        walkFn(this);

        this.children.forEach(child => child.walk(walkFn));
    }
}
