import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

import { IssueElement } from '../models/issue-list.model';

@Component({
    selector: 'dp-job-definition-issue-button',
    templateUrl: './job-definition-issue-button.component.html',
    styleUrls: ['./job-definition-issue-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobDefinitionIssueButtonComponent {
    @Input() formIssues: IssueElement[] = [];
    @Input() issueType = '';

    @Output() issueSelected = new EventEmitter();
}
