import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'dateFormat',
})
export class dateFormatPipe implements PipeTransform {
    readonly DATE_FMT = 'dd/MMM/yyyy';
    readonly DATE_TIME_FMT = `mm:ss,+0000`;
    readonly timezone = '+0000';

    transform(value: any, args?: any): any {
        if (value === null) {
            return '';
        }
        if (args === 'duration') {
            const duration = new DatePipe('en-US').transform(value, 'mm:ss', this.timezone);
            const min = duration.split(':')[0] !== '00' ? `${duration.split(':')[0]}min` : '';
            const sec = duration.split(':')[1] !== '00' ? `${duration.split(':')[1]}sec` : '';
            return `${min} ${sec}`;
        }

        if (args === 'lastExecution') {
            const date = new Date();
            const today = new DatePipe('en-US').transform(date, 'mediumDate');
            const yesterday = new DatePipe('en-US').transform(date.setDate(date.getDate() - 1), 'mediumDate');
            const lastExecution = new DatePipe('en-US').transform(value, 'mediumDate');
            const time = new DatePipe('en-US').transform(value, 'h:mm a');
            return today === lastExecution
                ? `Today ${time}`
                : yesterday === lastExecution
                ? `Yesterday ${time}`
                : `${lastExecution} ${time}`;
        }
        return '';
    }
}
