<ng-container *ngIf="form" [formGroup]="form">
    <lib-dropdown
        class="d-block mb-3"
        formControlName="type"
        label="Event Type"
        placeholder="Select Event Type"
        [items]="eventType"
    ></lib-dropdown>
    <lib-dropdown
        *ngIf="eventId === 'OUT'"
        class="mb-3 d-block"
        label="Event Source"
        formControlName="eventId"
        [addTag]="true"
        [items]="eventSources"
    ></lib-dropdown>
    <lib-input *ngIf="eventId === 'IN'" class="d-block mb-3" label="Event Source" formControlName="eventId"></lib-input>

    <lib-codemirror-editor label="Event Handler" needRefresh="true" mode="text/javascript" formControlName="script">
    </lib-codemirror-editor>
</ng-container>
