<ng-container *ngIf="{ lastIndex: lastVisibleIndex$ | async } as vm">
    <div *ngFor="let item of items; let index = index" class="value-item px-1" [class.value-item--hidden]="index > vm.lastIndex">
        {{ item }}{{ index < vm.lastIndex ? ',' : '' }}
    </div>
    <span
        class="value-item more-item px-2"
        [class.value-item--hidden]="vm.lastIndex >= items.length - 1"
        [ngbTooltip]="moreTooltip"
        container="body"
        data-test-id="more"
        >+{{ items.length - vm.lastIndex - 1 }} more</span
    >

    <ng-template #moreTooltip>
        <div *ngFor="let item of items.slice(vm.lastIndex + 1)" class="mb-1">
            {{ item }}
        </div>
    </ng-template>
</ng-container>
