import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { FormGroup, FormControl } from '@angular/forms';

import { ToolType } from '@dagility-ui/shared-components';
import {CustomIcon, facClose} from '@dagility-ui/shared-components/icons';
import { GridColumn } from '@dagility-ui/kit';
import { ProcessorMonitoringService } from '../../processor-monitoring.service';
import { JobSet } from '../../../models/processor-monitoring/job-set.model';

@Component({
    selector: 'dp-job-set-form',
    templateUrl: './job-set-form.component.html',
    styleUrls: ['./job-set-form.component.scss'],
})
export class JobSetFormComponent implements OnInit {
    toolType: ToolType;

    icons: Record<string, IconDefinition | CustomIcon> = {
        facClose: facClose,
    };

    selectedSetId: any;
    jobSetMap: Record<string, JobSet>;

    pluginGridColumns: GridColumn[] = [
        {
            title: 'Set Name',
            field: 'setName',
            width: '50%',
        },
    ];

    action: string;
    title: string;

    jobSets$: Observable<JobSet[]>;

    form: FormGroup;

    get jobSetIdControl() {
        return this.form.get('jobSetId');
    }

    constructor(public modalService: NgbActiveModal, private api: ProcessorMonitoringService) {}

    ngOnInit() {
        this.form = new FormGroup({
            jobSetId: new FormControl(this.selectedSetId),
        });

        this.jobSets$ = this.api.getJobSets(this.toolType).pipe(
            tap(jobSets => {
                this.jobSetMap = jobSets.reduce<Record<string, JobSet>>((acc, jobSet) => {
                    acc[jobSet.id] = jobSet;

                    return acc;
                }, {});
            }),
            map(jobSets => [...jobSets].sort((a, b) => a.name.localeCompare(b.name)))
        );
    }

    handleAction() {
        this.modalService.close(this.jobSetMap[this.jobSetIdControl.value]);
    }

    handleSetChange(event: Event, jobSet: JobSet) {
        event.preventDefault();
        event.stopPropagation();

        this.form.patchValue({ jobSetId: this.jobSetIdControl.value === jobSet.id ? null : jobSet.id });
    }
}
