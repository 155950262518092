import { Directive, TemplateRef, Input, TrackByFunction } from '@angular/core';

import { SVGEdge } from '../models';

interface SvgEdgeContext<T extends SVGEdge> {
    $implicit: T;
}

@Directive({
    selector: '[libSvgEdge]',
})
export class SvgEdgeDirective<T extends SVGEdge> {
    @Input('libSvgEdgeTrackBy') trackBy: TrackByFunction<T> | null;

    constructor(public ref: TemplateRef<SvgEdgeContext<T>>) {}
}
