import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    HostListener,
    Input,
    Output,
    TemplateRef,
    EventEmitter,
    HostBinding,
} from '@angular/core';

import { WidgetPreview } from '../widget-library.page';

@Component({
    selector: 'dp-widget-list-item',
    templateUrl: './widget-list-item.component.html',
    styleUrls: ['./widget-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetListItemComponent {
    @Input() widget: WidgetPreview;

    @Input() hideDescription: boolean;

    @Output() selected = new EventEmitter();

    @HostBinding('class.dashboard-mode') @Input() dashboardMode = false;

    @ContentChild(TemplateRef, { static: true })
    actionsTemplate: TemplateRef<any>;
    isButtonShown = false;

    @HostListener('click') handleClick() {
        this.selected.emit(this.widget);
    }

    @HostListener('mouseenter') onEnter() {
        this.isButtonShown = true;
    }

    @HostListener('mouseleave') onLeave() {
        this.isButtonShown = false;
    }
}
