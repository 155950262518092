import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { facSubMenu, sortingDown, sortingUp } from '@dagility-ui/kit';
import { SortDirection } from '@app/shared/components/query-builder/models/model-graph.model';

export interface SortTypeItem {
    label: string;
    icon: string;
    dir: SortDirection;
}

@Component({
    selector: 'app-sort-type-dropdown',
    templateUrl: './sort-type-dropdown.component.html',
    styleUrls: ['./sort-type-dropdown.component.scss'],
})
export class SortTypeDropdownComponent implements OnInit, OnChanges{

    @Input() selectedDirection: SortDirection;
    @Input() withSortOff: boolean;
    @Output() itemClicked = new EventEmitter<SortDirection>();

    selected: SortTypeItem;

    sortTypeItems: SortTypeItem[] = [
        {
            label: 'A-Z',
            icon: sortingDown,
            dir: 'ASC',
        },
        {
            label: 'Z-A',
            icon: sortingUp,
            dir: 'DESC',
        },
        {
            label: 'Off',
            icon: facSubMenu,
            dir: undefined,
        },
    ];

    ngOnInit() {
        if (!this.withSortOff) {
            this.sortTypeItems.pop();
        }
    }

    ngOnChanges() {
       this.selected = this.sortTypeItems.find(item => item.dir === this.selectedDirection);
    }

    onItemClicked(item: SortTypeItem) {
        this.selected = item;
        this.selectedDirection = item.dir;
        this.itemClicked.emit(item.dir);
    }
}
