<perfect-scrollbar stopScrollPropagation class="scrollbar" (psYReachEnd)="handleScrollEnd()">
    <div class="mt-3 pr-3">
        <lib-card-with-status
            *ngFor="let item of options.items; trackBy: trackByIssue; let last = last"
            [statusMapper]="options.statusMapper"
            [status]="getCardStatus(item.issue.status)"
            [class.mb-3]="!last"
        >
            <dp-feature-completion-status-issue
                [isSingleWidget]="options.isSingleWidget"
                [issue]="item.issue"
                [statusMapper]="options.statusMapper"
                [showLink]="options.showLink"
                (linkClicked)="linkClicked.emit(getLinkClickedValue($event, 'workDistribution'))"
                (handleDrilldown)="handleDrilldown.emit($event)"
            ></dp-feature-completion-status-issue>

            <ng-template
                cardExpandedTemplate
                [expandedLabel]="'Completion status'"
                [isShown]="item.withoutParent?.navItems?.length || item.tabbedGrid?.length"
            >
                <dp-accordion-with-tabs
                    [withoutParentNavs]="item.withoutParent.navItems"
                    [isChildComponent]="true"
                    [hasInfiniteScroll]="false"
                    (linkClicked)="linkClicked.emit(getLinkClickedValue($event, 'accordion'))"
                    (drilldown)="handleDrilldown.emit($event)"
                >
                </dp-accordion-with-tabs>
            </ng-template>
        </lib-card-with-status>
    </div>
</perfect-scrollbar>

<div *ngIf="isLoading" class="d-flex align-items-center justify-content-center">
    <div class="loader"></div>
</div>
