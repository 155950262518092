import { BehaviorSubject, defer, Observable } from 'rxjs';

import { AnyWidgetModel, WidgetQuery, WidgetQueryType } from '../models/any-widget.model';
import { WidgetBuilderService } from './widget-builder.service';
import { QUERY_RESULT_PLACEHOLDER } from '../state/widget-builder.facade';
import { parseError } from './widget-builder.util';
import { WidgetLogger, WidgetScriptExecutor } from './widget-script.executor';

const scripts = ['before', 'sql', 'after'];

export enum WidgetFlow {
    FILTERS = 0,
    QUERIES = 1,
}

export enum WidgetScript {
    BEFORE = 0,
    SQL = 1,
    AFTER = 2,
}

export interface WidgetExecutionPosition {
    widgetIndex: number;
    flow: WidgetFlow;
    filterIndex: number;
    index: number;
    scriptIndex: WidgetScript;
}

class ExecutionContextParams {
    defaultPlaceholders: Record<string, any>;
    filters: Record<string, any>;
    placeholders: Record<string, any>;
}

export class ExecutionContext extends ExecutionContextParams {
    position: WidgetExecutionPosition = {
        widgetIndex: 0,
        flow: WidgetFlow.FILTERS,
        filterIndex: 0,
        index: 0,
        scriptIndex: WidgetScript.BEFORE,
    };
    breakpoints: Set<string> = new Set<string>();
    exception: string | null;
    debug = false;
    stopped = false;
    currentPosition$ = new BehaviorSubject(null);
    lastStoppedScript: WidgetScript = null;

    constructor(params: ExecutionContextParams) {
        super();
        Object.assign(this, params);
    }

    setPosition(queryIndex: number, scriptIndex: number): void {
        this.position.index = queryIndex;
        this.position.scriptIndex = scriptIndex;
    }

    get scriptContext(): any {
        return {
            ...this.defaultPlaceholders,
            ...this.placeholders,
            ...this.filters,
            placeholders: this.placeholders,
        };
    }

    buildQueryContext(): any {
        return {
            ...this.placeholders,
            ...this.defaultPlaceholders,
            ...this.filters,
        };
    }
}

export interface WidgetQueryExecutor<Result = any> {
    queries: WidgetQuery[];

    filterPlaceholder?: string;

    execute$(context: ExecutionContext): Observable<Result>;
    execute(context: ExecutionContext): Promise<Result>;
}

class QueryExecutorParams {
    api: WidgetBuilderService;
    scriptExecutor: WidgetScriptExecutor;
    logger: WidgetLogger;
    queries: WidgetQuery[];
    filterPlaceholder?: string;
}

class ClientWidgetQueryExecutor extends QueryExecutorParams implements WidgetQueryExecutor {
    constructor(params: QueryExecutorParams) {
        super();

        Object.assign(this, params);
    }

    async execute(context: ExecutionContext): Promise<any> {
        const scriptContext: Record<string, any> = context.scriptContext;

        if (!context.position) {
            return undefined;
        }

        let positionIndex = context.position.scriptIndex;
        context.stopped = false;

        for (let queryIndex = context.position.index; queryIndex < this.queries.length; queryIndex++) {
            const query = this.queries[queryIndex];
            const placeholder = query.placeholder || this.filterPlaceholder;
            const isJdbcDataSource = ![WidgetQueryType.CLICKHOUSE, WidgetQueryType.POSTGRESQL].includes(query.type);

            for (let scriptIndex = positionIndex; scriptIndex < scripts.length; scriptIndex++) {
                if (
                    !!context.breakpoints &&
                    context.breakpoints.has(`${query.id}|${scriptIndex}`) &&
                    context.lastStoppedScript !== scriptIndex
                ) {
                    context.setPosition(queryIndex, scriptIndex);
                    context.stopped = true;
                    context.currentPosition$.next({
                        queryId: query.id,
                        scriptIndex: scriptIndex,
                    });
                    context.lastStoppedScript = scriptIndex;

                    return context.placeholders;
                }

                try {
                    switch (scriptIndex) {
                        case WidgetScript.BEFORE: {
                            this.scriptExecutor.evaluateScript({
                                context: scriptContext,
                                expression: query.beforeScript,
                                type: 'before',
                                placeholder,
                            });
                            break;
                        }
                        case WidgetScript.SQL: {
                            try {
                                const trimmedQuery = (query.query || '').trim();

                                if (!trimmedQuery) {
                                    break;
                                }

                                const sqlScript: string = trimmedQuery.toUpperCase().startsWith('SELECT')
                                    ? trimmedQuery
                                    : this.scriptExecutor.evaluateScript({
                                          context: scriptContext,
                                          expression: trimmedQuery,
                                          type: 'sql',
                                          placeholder,
                                      });

                                if (!!trimmedQuery.length && !!sqlScript.length) {
                                    const queryResult = await this.api
                                        .executeQuery(
                                            isJdbcDataSource ? WidgetQueryType.JDBC : query.type,
                                            isJdbcDataSource ? query.type : null,
                                            sqlScript,
                                            context.buildQueryContext(),
                                            !context.debug
                                        )
                                        .toPromise();
                                    context.placeholders[query.placeholder || QUERY_RESULT_PLACEHOLDER] = queryResult;
                                    scriptContext[query.placeholder || QUERY_RESULT_PLACEHOLDER] = queryResult;
                                }
                            } catch (e) {
                                const errorMessage = `Error in SQL Script for "${placeholder}" placeholder:\r\n ${parseError(e)}`;

                                throw new Error(errorMessage);
                            }
                            break;
                        }
                        case WidgetScript.AFTER: {
                            this.scriptExecutor.evaluateScript({
                                context: scriptContext,
                                expression: query.script,
                                type: 'after',
                                placeholder,
                            });
                            break;
                        }
                    }
                } catch (e) {
                    if (context.debug) {
                        context.position = null;
                        context.currentPosition$.next(null);
                        context.exception = e;
                        context.lastStoppedScript = null;
                    }
                    this.logger.log(e.message);

                    throw e;
                }
            }

            positionIndex = 0;
            context.lastStoppedScript = null;
        }

        context.stopped = false;
        context.position = null;
        context.lastStoppedScript = null;
        context.currentPosition$.next(null);

        return context.placeholders[QUERY_RESULT_PLACEHOLDER];
    }

    execute$(context: ExecutionContext): Observable<any> {
        return defer(() => this.execute(context));
    }
}

export function getQueryExecutor(widget: AnyWidgetModel): new (params: QueryExecutorParams) => WidgetQueryExecutor {
    return ClientWidgetQueryExecutor;
}
