import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Column, ICellRendererParams } from '@ag-grid-community/core';

@Component({
    selector: 'dp-column-with-context-menu',
    template: `
        <div *ngIf="form" #dropdown="ngbDropdown" ngbDropdown [container]="'body'" placement="bottom-right auto" class="d-inline-block">
            <em class="fa fa-ellipsis-v p-2" ngbDropdownToggle ngbTooltip="Select columns" container="body"></em>
            <div class="table-column-selection" ngbDropdownMenu [formGroup]="form">
                <div class="p-3">
                    <lib-search class="search-bar mb-2" [placeholder]="'Search by Column'" (searchText)="handleSearch($event)"></lib-search>
                </div>
                <div *ngIf="filteredColumns.length === displayedColumns.length" class="px-3 py-2">
                    <checkbox [value]="mainCheck" (change)="handleSelectAll($event)">
                        Select all
                    </checkbox>
                </div>
                <perfect-scrollbar style="height: 200px">
                    <div class="modal-body p-3">
                        <div class="d-block mb-3" *ngFor="let column of filteredColumns">
                            <checkbox (change)="handleCheckboxChange()" [formControlName]="column.id">
                                {{ column.name }}
                            </checkbox>
                        </div>
                    </div>
                </perfect-scrollbar>
                <div class="modal-footer">
                    <button class="btn btn-secondary" (click)="dropdown.close()">Cancel</button>
                    <button class="btn btn-primary" [disabled]="hideApply" (click)="handleApply()">Apply</button>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./column-with-context-menu.scss'],
})
export class ColumnWithContextMenuComponent implements ICellRendererAngularComp {
    displayedColumns: { id: string; name: string }[] = [];
    filteredColumns: { id: string; name: string }[] = [];
    form: FormGroup;

    private columns: Column[] = [];
    private params: ICellRendererParams;

    mainCheck = true;
    hideApply = false;

    agInit(params: ICellRendererParams): void {
        this.params = params;

        this.displayedColumns = params.columnApi
            .getAllColumns()
            .slice(0, -1)
            .map(column => ({
                id: column.getId(),
                name: column.getColDef().headerName,
            }));
        this.columns = params.columnApi.getAllColumns();

        this.form = new FormGroup(
            this.columns.reduce<Record<string, any>>((acc, column) => {
                acc[column.getId()] = new FormControl(column.isVisible());

                return acc;
            }, {})
        );
        this.mainCheck = !Object.values(this.form.getRawValue()).some((element: boolean) => !element);
        this.filteredColumns = this.displayedColumns;
    }

    handleApply() {
        this.params.api.setColumnDefs(
            this.columns.map(column => ({
                ...column.getColDef(),
                hide: !this.form.value[column.getId()],
            }))
        );
    }

    handleSearch(filter: any) {
        this.filteredColumns =
            filter.toLowerCase() === ''
                ? this.displayedColumns
                : this.displayedColumns.filter(column => column.name.toLowerCase().includes(filter.toLowerCase()));
    }

    handleCheckboxChange() {
        let arrayState = true;
        for (const checkBoxState of Object.values(this.form.getRawValue())) {
            if (!checkBoxState) {
                arrayState = false;
                break;
            }
        }
        this.checkSelectedColumns();
        this.mainCheck = arrayState;
    }

    handleSelectAll(value: any) {
        this.form = new FormGroup(
            this.columns.reduce<Record<string, any>>((acc, column) => {
                acc[column.getId()] = new FormControl(value);

                return acc;
            }, {})
        );
        this.form.get('selectionColumn').setValue(true);
        this.mainCheck = value;
        this.checkSelectedColumns();
    }

    checkSelectedColumns() {
        const formObject = this.form.getRawValue();
        delete formObject.selectionColumn;
        this.hideApply = Object.values(formObject).every(element => !element);
    }

    refresh(params: any): boolean {
        return false;
    }
}
