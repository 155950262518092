<svg xmlns="http://www.w3.org/2000/svg" *ngIf="svg"
     [attr.viewBox]="svg.viewBox" preserveAspectRatio="xMidYMid meet"
     [attr.height]="svg.height" [attr.width]="svg.width" [attr.class]="options.svgClass">
    <circle *ngIf="options.showBackground"
            [attr.cx]="svg.backgroundCircle.cx"
            [attr.cy]="svg.backgroundCircle.cy"
            [attr.r]="svg.backgroundCircle.r"
            [attr.fill]="svg.backgroundCircle.fill"
            [attr.fill-opacity]="svg.backgroundCircle.fillOpacity"
            [attr.stroke]="svg.backgroundCircle.stroke"
            [attr.stroke-width]="svg.backgroundCircle.strokeWidth"/>
    <circle *ngIf="options.showInnerStroke"
            [attr.cx]="svg.circle.cx"
            [attr.cy]="svg.circle.cy"
            [attr.r]="svg.circle.r"
            [attr.fill]="svg.circle.fill"
            [attr.stroke]="svg.circle.stroke"
            [attr.stroke-width]="svg.circle.strokeWidth"/>
    <path
            [attr.d]="svg.path.d"
            [attr.stroke]="svg.path.stroke"
            [attr.stroke-width]="svg.path.strokeWidth"
            [attr.stroke-linecap]="svg.path.strokeLinecap"
            [attr.fill]="svg.path.fill"/>
    <text *ngIf="options.showTitle || options.showSubtitle"
          alignment-baseline="baseline"
          [attr.x]="svg.circle.cx"
          [attr.y]="svg.circle.cy"
          [attr.text-anchor]="svg.title.textAnchor">
        <ng-container *ngIf="options.showTitle">
            <tspan *ngFor="let tspan of svg.title.tspans"
                   [attr.x]="svg.title.x"
                   [attr.y]="svg.title.y"
                   [attr.dy]="tspan.dy"
                   [attr.font-size]="svg.title.fontSize"
                   [attr.font-weight]="svg.title.fontWeight"
                   [attr.fill]="svg.title.color">{{tspan.span}}</tspan>
        </ng-container>
        <ng-container *ngIf="options.showSubtitle">
            <tspan *ngFor="let tspan of svg.subtitle.tspans"
                   [attr.x]="svg.subtitle.x"
                   [attr.y]="svg.subtitle.y"
                   [attr.dy]="tspan.dy"
                   [attr.font-size]="svg.subtitle.fontSize"
                   [attr.font-weight]="svg.subtitle.fontWeight"
                   [attr.fill]="svg.subtitle.color">{{tspan.span}}</tspan>
        </ng-container>
    </text>
    <svg:foreignObject [attr.x]="0"
                   [attr.y]="0"
                   [attr.height]="svg.height"
                   [attr.width]="svg.width"
                   alignment-baseline="baseline">
        <xhtml:div *ngIf="options.showIcon" class="d-flex justify-content-center align-items-center w-100 h-100">
            <span *ngIf="centerIconHtml" [innerHtml]="options.centerIcon | safeHtml: 'html'" [ngClass]="centerIconClass" class="center-icon"></span>
            <fa-icon *ngIf="!centerIconHtml" [icon]="options.centerIcon" [ngClass]="centerIconClass" class="font-38px center-icon"></fa-icon>
        </xhtml:div>
    </svg:foreignObject>
</svg>
