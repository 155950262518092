<ng-container *ngIf="items?.length">
    <div class="btn btn-secondary legend-btn mr-2" (click)="legendAllSelect()">All</div>
    <div class="btn btn-secondary legend-btn" (click)="legendInverseSelect()">Invert</div>
    <div #container class="d-flex align-items-center ml-2 overflow-hidden">
        <div class="arrow arrow--left">
            <fa-icon icon="chevron-left"></fa-icon>
        </div>

        <ul class="d-flex align-items-center">
            <li *ngFor="let item of items" class="d-inline-flex align-items-center cursor-pointer"
                [class.legend-item--active]="!!item.selected"
                (mouseenter)="dispatchAction('highlight', item.name)"
                (mouseleave)="dispatchAction('downplay', item.name)"
                (click)="handleClick(item)"
            >
                <span [style.background]="item.color" class="legend-circle"></span>
                {{ item.name }}
            </li>
        </ul>
        <div class="arrow arrow--right">
            <fa-icon icon="chevron-right"></fa-icon>
        </div>
    </div>
</ng-container>

