import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { randomColor } from 'data-processor/lib/widget-library/widget-builder/services/widget-builder.util';

interface Data {
    name?: string;
    count?: number;
    value?: number;
    issueList?: any;
    issues?: any;
    tooltipData?: any;
}

@Component({
    selector: 'dp-work-distribution-chart',
    templateUrl: './work-distribution-chart.component.html',
    styleUrls: ['./work-distribution-chart.component.scss'],
})
export class WorkDistributionChartComponent implements OnInit, OnChanges, OnDestroy {
    @Input() chartData: Data[];
    @Input() legendTitle = '';
    @Input() chartOptions: Record<string, any>;
    @Input() width: string = '85%';
    @Input() height: string = '80%';
    @Output() selected$ = new EventEmitter();
    totalCount: number = 0;
    showChart = false;
    selected: String = '';
    formattedTooltipHeader: any = {};
    formattedTooltipData: any = [];
    mouseOnTooltip = false;
    constructor(private el: ElementRef, private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.setChart();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.chartData?.currentValue?.length) {
            this.chartData = changes.chartData.currentValue;
            this.setChart();
        }
    }

    get isMediumScreen() {
        return window.innerWidth < 1720 && window.innerWidth >= 1440;
    }

    setChart() {
        if (!this.chartData) {
            return;
        }

        this.showChart = false;

        this.selected = 'all';
        this.chartOptions.series[0].data = [...this.chartData];
        this.totalCount = this.chartData.reduce((prev: any, current: any) => prev + Number(current.count), 0);
        this.chartOptions.colors = this.chartData.map(() => randomColor());

        setTimeout(() => {
            this.showChart = true;
            this.cdr.detectChanges();
        });
    }

    generateTooltipData(selectedName: string) {
        const selectedData = this.chartData.find((data: any) => data.name === selectedName);
        this.formattedTooltipHeader = {
            name: selectedName,
            count: selectedData.count || selectedData.value,
        };

        if (Array.isArray(selectedData.tooltipData)) {
            this.formattedTooltipData = selectedData.tooltipData;
        }

        setTimeout(() => {
            const workDistributionChartEl: any = document.getElementsByClassName('work-distribution-chart-info').item(0);

            if (!workDistributionChartEl) {
                return;
            }

            const { left, top } = workDistributionChartEl.getBoundingClientRect();

            workDistributionChartEl.parentNode.removeChild(workDistributionChartEl);
            workDistributionChartEl.style.left = left >= window.innerWidth - 200 - 10 ? `${window.innerWidth - 200 - 10}px` : `${left}px`;
            workDistributionChartEl.style.top = `${top}px`;

            document.body.append(workDistributionChartEl);
        });
    }

    onChartEvent(change: String) {
        this.selected = change;
        this.selected$.next(change);
    }

    mouseEnter(legend: any, chart: any) {
        chart.dispatchAction({
            type: 'highlight',
            name: legend.name,
        });
    }

    mouseLeave(legend: any, chart: any) {
        chart.dispatchAction({
            type: 'downplay',
            name: legend.name,
        });
    }

    cancelTooltip(mouseEvent: MouseEvent, checkElementClassName: string) {
        setTimeout(() => {
            if (this.mouseOnTooltip) {
                return;
            }

            const checkElement: any = document.getElementsByClassName(checkElementClassName).item(0);

            if (!checkElement) {
                return;
            }

            const { left, top } = checkElement.getBoundingClientRect();
            const { clientX, clientY } = mouseEvent;
            const conditionX = clientX < left - 10 || clientX > left + checkElement.offsetWidth;
            const conditionY = clientY < top - 10 || clientY > top + checkElement.offsetHeight;

            if (!conditionX && !conditionY) {
                return;
            }

            this.formattedTooltipHeader = {};
            this.formattedTooltipData = [];
            this.cdr.detectChanges();
        }, 1250);
    }

    ngOnDestroy() {
        this.formattedTooltipHeader = {};
        this.formattedTooltipData = [];
        const el = document.getElementsByClassName('work-distribution-chart-info').item(0);
        if (el) {
            el.parentNode.removeChild(el);
        }
    }
}
