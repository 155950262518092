import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, map, mapTo, startWith, switchMap } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { validateFormAndDisplayErrors } from '@dagility-ui/kit';
import { LazyLoadingScriptService } from '@dagility-ui/shared-components';
import { WidgetHelp, WidgetHelpInsight } from 'data-processor';

import { countChars, WidgetBuilderFacade } from '../../../../state/widget-builder.facade';

interface MaxCharsControl {
    currentCount$: Observable<number>;
    maxCount: number;
    valid$: Observable<boolean>;
}

type ExcludeArray<Type> = { [Key in keyof Type as Type[Key] extends Array<unknown>? never:Key]: number };

const maxCounts: ExcludeArray<WidgetHelp> & ExcludeArray<WidgetHelpInsight> & { recommendation: number } = {
    summary: 15000,
    howScoreCalculated: 5000,
    howScoreCanImproved: 5000,
    description: 5000,
    insight: 5000,
    recommendation: 5000,
};

declare var CKEDITOR: any;

function buildMaxCharsObservable(control: FormControl, maxCount: number): MaxCharsControl {
    const currentCount$ = control.valueChanges.pipe(
        startWith(control.value as string),
        map((html: string) => countChars(html))
    );

    return {
        currentCount$,
        maxCount,
        valid$: currentCount$.pipe(map(() => countChars(control.value) <= maxCount)),
    };
}

@Component({
    selector: 'dp-widget-builder-help-template.d-flex.flex-column.overflow-hidden',
    templateUrl: './widget-builder-help-template.component.html',
    styleUrls: ['./widget-builder-help-template.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetBuilderHelpTemplateComponent {
    @Input() header: string;

    @Input() set data(data: any) {
        if (!data.hasOwnProperty('insight')) {
            this.form = new FormControl(data.value, data.mandatory ? [Validators.required] : []);
            this.maxCharsMap.set(this.form, buildMaxCharsObservable(this.form, maxCounts[data.type as keyof typeof maxCounts]));
        } else {
            this.form = this.facade.buildHelpInsight(data as WidgetHelpInsight);
            this.maxCharsMap.set(
                this.form.get('insight'),
                buildMaxCharsObservable(this.form.get('insight') as FormControl, maxCounts.insight)
            );
            (this.form.get('recommendations') as FormArray).controls.forEach(control => {
                this.maxCharsMap.set(control, buildMaxCharsObservable(control as FormControl, maxCounts.recommendation));
            });

            this.insightMode = true;
        }
    }

    maxCharsMap: Map<AbstractControl, MaxCharsControl> = new Map<AbstractControl, MaxCharsControl>();

    refresh$ = new BehaviorSubject(null);

    saveEnabled$ = this.refresh$.pipe(
        switchMap(() => combineLatest(Array.from(this.maxCharsMap.values()).map(({ valid$ }) => valid$))),
        map(valids => valids.every(Boolean)),
        map(v => !v)
    );

    insightMode: boolean = false;

    form: FormGroup | FormControl;

    ckEditorLoaded$ = this.lazyLoadService.loadScript('/assets/js/ckeditor/ckeditor.js').pipe(
        mapTo(true),
        catchError(() => of(false))
    );

    get recommendationsArray() {
        return this.form.get('recommendations') as FormArray;
    }

    readonly editorOptions = {
        toolbarGroups: [
            { name: 'basicstyles', groups: ['basicstyles'] },
            { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'paragraph'] },
            { name: 'links', groups: ['links'] },
        ],
        removeButtons: 'Strike,Subscript,Superscript,Unlink,Anchor',
        removePlugins: 'elementspath',
        entities: false,
    };

    constructor(
        private lazyLoadService: LazyLoadingScriptService,
        private activeModal: NgbActiveModal,
        private facade: WidgetBuilderFacade
    ) {}

    handleAddRecommendation() {
        const control = this.facade.buildHelpRecommendation('');
        this.maxCharsMap.set(control, buildMaxCharsObservable(control, maxCounts.recommendation));
        this.recommendationsArray.push(control);
        this.refresh$.next(null);
    }

    handleRemoveRecommendation(index: number) {
        const control = this.recommendationsArray.at(index);
        this.maxCharsMap.delete(control);

        this.recommendationsArray.removeAt(index);
        this.refresh$.next(null);
    }

    handleCancel() {
        this.activeModal.dismiss();
    }

    handleSave() {
        if (!this.form.valid) {
            if (this.form instanceof FormControl) {
                validateFormAndDisplayErrors(new FormArray([this.form]));
            } else {
                validateFormAndDisplayErrors(this.form);
            }

            return;
        }

        this.activeModal.close(this.form.value);
    }

    onEditorReady() {
        const backgroundColor = getComputedStyle(document.getElementsByClassName('cke_top')[0]).backgroundColor;
        const color = getComputedStyle(document.getElementsByClassName('cke_top')[0]).color;
        CKEDITOR.addCss(`.cke_editable { cursor: text; background: ${backgroundColor}; color: ${color};}`);

        //update editable area of ckeditor
        for (let i in CKEDITOR.instances) {
            CKEDITOR.instances[i].setData(CKEDITOR.instances[i].getData());
        }
    }

    isMaxCharsControlGuard(charsControl: any): MaxCharsControl {
        return charsControl;
    }
}
