import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EChartDirective } from './echart.directive';
import { ChartComponent } from './chart/chart.component';
import { CHART_LIBRARY } from './chart-libraries/chart-library';
import { EchartsLibrary } from './chart-libraries/echarts/echarts.library';
import { AgGridTemplateModule } from '../ag-grid/ag-grid-template.module';
import { ProgressChartComponent } from './progress-chart/progress-chart.component';
import { UiKitModule } from '@dagility-ui/kit';
import { TileChartComponent } from './tile-chart/tile-chart.component';
import { ChartLegendComponent } from './chart-legend/chart-legend.component';
import { GaugeChartComponent } from './gauge-chart/gauge-chart.component';
import { RadialChartComponent } from './radial-chart/radial-chart.component';

@NgModule({
    imports: [CommonModule, AgGridTemplateModule, UiKitModule],
    declarations: [
        EChartDirective,
        ChartComponent,
        ProgressChartComponent,
        TileChartComponent,
        ChartLegendComponent,
        GaugeChartComponent,
        RadialChartComponent,
    ],
    exports: [ChartComponent, ProgressChartComponent, TileChartComponent, GaugeChartComponent, RadialChartComponent],
    providers: [
        {
            provide: CHART_LIBRARY,
            useValue: EchartsLibrary,
        },
    ],
})
export class ChartsModule {}
