<div class="mb-2">{{ label }}</div>
<input
    #input
    #controlContainer
    class="color-pick"
    [colorPicker]="value"
    (colorPickerChange)="handleChange($event)"
    [cpPosition]="cpPosition"
    cpPositionRelativeToArrow="true"
    [style.background]="value"
/>
