import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { uniqueField } from '@dagility-ui/kit';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ConditionWorkflowAction, WorkflowAction, WorkflowGroovyAction } from '../../models/actions';
import { EditJobDefinitionService } from '../../edit-job-definition.service';
import { actionsFactory } from '../utils';
import { IssueElement } from '../../models/issue-list.model';
import { JDValue, Operand } from '../../job-definition-builder/edit-workflow-action/models/operand';
import { QueryAction } from '../../job-definition-builder/edit-workflow-action/models/query.action';

@Component({
    selector: 'dp-edit-workflow-action',
    templateUrl: './edit-workflow-action.component.html',
    styleUrls: ['./edit-workflow-action.component.scss'],
    providers: [EditJobDefinitionService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditWorkflowActionComponent implements OnInit {
    @Input() action: WorkflowAction;
    @Input() blockNames: Record<string, any> = {};
    @Input() nextBlockNames: any[] = [];
    @Input() elseNextBlockNames: any[] = [];
    @Input() issue: IssueElement;

    isCondition = false;
    isGroovy = false;

    formGroup: FormGroup;

    hiddenFieldsByType = {
        FORK: ['actions'],
        LOOP: ['actions'],
    };

    constructor(private modal: NgbActiveModal, public store: EditJobDefinitionService) {}

    ngOnInit() {
        this.store.init(new actionsFactory[this.action.type](this.action), false);
        this.store.form.addControl(
            'name',
            new FormControl(this.action.name, [uniqueField(this.blockNames, this.action.name), Validators.required])
        );
        this.store.form.addControl('next', new FormControl(this.action.next));

        if (this.action.type === 'CONDITION') {
            this.isCondition = true;
            this.store.form.addControl('elseNext', new FormControl((this.action as ConditionWorkflowAction).elseNext));
        } else if (this.action.type === 'GROOVY') {
            this.isGroovy = true;
            this.store.form.addControl('script', new FormControl((this.action as WorkflowGroovyAction).script));
        }
        if (this.issue) {
            this.store.goToPathAndValidateControl({
                ...this.issue,
                errorPath: this.issue.errorPath.slice(2),
            });
        }
    }

    handleCloseClick() {
        this.modal.dismiss();
    }

    handleDelete() {
        this.modal.close({ deleted: true });
    }

    handleSave() {
        let dataToSave = this.store.toSave();
        if (dataToSave) {
            dataToSave = Object.assign({}, dataToSave, {
                name: this.store.form.value.name,
                next: this.store.form.value.next,
                elseNext: this.store.form.value.elseNext,
                script: this.store.form.value.script,
            });

            this.modal.close({ data: dataToSave });
        }
    }

    isJDValue(obj: any) {
        return obj instanceof JDValue;
    }

    isOperand(obj: any) {
        return obj instanceof Operand;
    }

    isQuery(obj: any) {
        return obj instanceof QueryAction;
    }
}
