import { Component, Input, NgZone, TemplateRef } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
    selector: '[dagility-custom-toast-component]',
    templateUrl: './dagility-custom-toastr.component.html',
})
export class DagilityCustomToastrComponent extends Toast {
    @Input() bodyTemplate: TemplateRef<any>;

    constructor(toastrService: ToastrService, toastPackage: ToastPackage, ngZone: NgZone) {
        super(toastrService, toastPackage, ngZone);
    }

    onClose = () => {
        this.remove();
    };
}
