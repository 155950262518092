import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureToggleService } from '../services/feature-toggle.service';

@Directive({
    selector: '[ifFeatureEnabled]',
})
export class IfFeatureEnabledDirective {
    private hasView = false;

    @Input() ifFeatureEnabledNotHide = false;

    @Input() set ifFeatureEnabled(argument: string) {
        if (!this.ifFeatureEnabledNotHide) {
            if (!argument) {
                this.setState(false);
                return;
            }

            this.featureToggleService.isActive(argument).subscribe(granted => this.setState(granted));
        } else {
            this.setState(true);
        }
    }

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private featureToggleService: FeatureToggleService
    ) {}

    private setState(cond: boolean): void {
        if (cond && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else if (!cond && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
        }
    }
}
