import { ErrorHandler, Injectable } from '@angular/core';

let sentry: any;

@Injectable()
export class SentryErrorHandler extends ErrorHandler {

    handleError(error: any) {
        if (sentry) {
            sentry.captureException(error.originalError || error);
        }
        return super.handleError(error);
    }
}

export function initSentry(env: Env) {
    if (!env.sentry || !env.sentry.dsn) {
        return;
    }

    const { dsn, environment, buildNumber} = env.sentry; // todo
    import(/* webpackChunkName: "sentry-browser" */'@sentry/browser').then( (sentryInstance: any) => {
        sentry = sentryInstance;
        sentry.init({
            dsn,
            environment: environment,
            release: buildNumber,
        });
    }).catch(e => {
        console.log('Failed to load sentry', e);
    });
}
