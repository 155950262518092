<div class="modal-header">
    <h2 class="modal-title mt-0">Reorder Groups</h2>
    <button type="button" class="close" data-test-id="cross-tick-btn" aria-describedby="modal-title" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body d-flex flex-column overflow-auto" perfectScrollbar cdkScrollable>
    <p class="body1">Drag and drop groups in your preferred order.</p>
    <div cdkDropList [cdkDropListData]="groups" (cdkDropListDropped)="dropGroup($event)">
        <div
            *ngFor="let group of groups"
            class="group pr-2 mb-2 d-flex align-items-center p-0"
            [cdkDragData]="group"
            cdkDrag
            data-test-id="group"
        >
            <lib-card class="flex-1 py-2">
                <div class="h3 m-0">{{ group.name }}</div>
                <div class="body2">{{ group.widgets.length }} Widget{{ group.widgets.length > 1 ? 's' : '' }}</div>
            </lib-card>
            <fa-icon class="ml-2 disable" icon="dragHandle"></fa-icon>
            <div *cdkDragPlaceholder class="drag-placeholder"></div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-test-id="cancel-btn" (click)="activeModal.dismiss()">Cancel</button>
    <button
        type="button"
        class="btn btn-primary"
        data-test-id="apply-btn"
        [disabled]="saving || !isDirty"
        [btnWithLoader]="saving"
        (click)="apply()"
    >
        Apply
    </button>
</div>
