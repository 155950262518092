<lib-slide-selector *ngIf="!service.hideSlideSelector" [items]="sliderItems" (selectedTab)="handleSelectedTab($event)">
</lib-slide-selector>
<button
    class="btn btn-primary w-100 size-s mt-2 text-truncate"
    [ngbTooltip]="'Open Logs for ' + service.selectedValue.name"
    [container]="'body'"
    (click)="service.openLogs()"
>
    Open Logs for {{ service.selectedValue.name }}
</button>
<dp-sre-gantt-table class="d-block"></dp-sre-gantt-table>
