import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { NewFieldPopupComponent } from './new-field-popup/new-field-popup.component';
import { DEFAULT_FIELD_DESCRIPTOR, fieldGridColumns } from './constants/fields-grid.constants';
import { CiCdFieldDescription, CiCdFieldType, HierarchyLevel, PublishProperties } from '../../model/fields.model';
import { normalizeFields } from './utils/fields-grid-utils';
import { moveArrayElement } from '../../utils/utilities';
import { GridColumn, ModalConfirmComponent, ModalService, sortingDown, sortingUp } from '@dagility-ui/kit';
import { CompletionSourceItem } from '../../../../directives/properties-completion';
import { AddSystemPropertyComponent, PropertyWSelected } from './add-system-property/add-system-property.component';
import { TemplatePublishChangesComponent } from './template-publish-modal/template-publish-changes.component';
import { UploadVariablesDialogComponent } from './upload-variables-dialog/upload-variables-dialog.component';

@Component({
    selector: 'lib-fields-grid',
    templateUrl: './fields-grid.component.html',
    styleUrls: ['./fields-grid.component.scss'],
})
export class FieldsGridComponent implements OnChanges {
    @Input() fields: CiCdFieldDescription[];
    @Input() title = 'Fields Configuration';
    @Input() gridBodyHeight = '';
    @Input() addSystemProperties = false;
    @Input() hierarchyLevel: HierarchyLevel;
    @Input() showToggle = false;
    @Input() lockEmbedded = false;
    @Input() cloneFields = false;
    @Input() propertyMode = false;
    @Input() allowedTypes: CiCdFieldType[] = ['STRING', 'STRING_MULTILINE', 'NUMBER', 'BOOLEAN'];
    @Input() readonly = false;
    @Input() notClickableCheckBox = false;
    @Input() fieldOptionsContextHelpTemplate: TemplateRef<any> = null;
    @Input() fieldConfigContextHelpTemplate: TemplateRef<any> = null;
    @Input() propertiesCompletion: CompletionSourceItem[] = [];

    @Input() uploadFn: (file: File) => Promise<any>;

    @Input() canPublishChanges = false;
    @Input() idToPublish: number;

    @Output() valueChange: EventEmitter<CiCdFieldDescription[]> = new EventEmitter();
    @Output() toggleChange: EventEmitter<boolean> = new EventEmitter();
    @Output() publishProperties: EventEmitter<PublishProperties> = new EventEmitter<PublishProperties>();
    @Output() modalWindowOpened: EventEmitter<boolean> = new EventEmitter<boolean>();

    gridColumns: GridColumn[] = fieldGridColumns;
    currentSortingField = '';

    editFields: CiCdFieldDescription[] = [];

    fieldsHidden = false;

    icons = {
        sortingUp,
        sortingDown,
    };

    isAddDropdownOpened = false;

    constructor(private modalService: ModalService) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.fields = normalizeFields(this.fields);
        if (this.cloneFields) {
            this.cloneFieldsToEditFields();
        } else {
            this.editFields = this.fields = this.fields || [];
        }
    }

    cloneFieldsToEditFields() {
        this.editFields = [];
        if (this.fields && this.fields.length) {
            this.fields.forEach(field => {
                const editField: any = { ...field };
                if (field.options) {
                    editField.options = [];
                    field.options.forEach(option => {
                        const editOption = { ...option };
                        editField.options.push(editOption);
                    });
                }
                this.editFields.push(editField);
            });
        }
    }

    openNewFieldModal(field: any, editMode: boolean) {
        this.modalWindowOpened.emit(true);
        const modalRef = this.modalService.open(
            NewFieldPopupComponent,
            {
                centered: true,
                backdrop: 'static',
                windowClass: 'configure-components-popup',
            },
            {
                field: field,
                editMode: editMode,
                fieldsArray: this.editFields,
                hideEmbedded: this.lockEmbedded,
                disableTypeDropdown: this.propertyMode && editMode,
                allowedTypes: this.allowedTypes,
                readonly: this.readonly,
                fieldOptionsContextHelpTemplate: this.fieldOptionsContextHelpTemplate,
                propertiesCompletion: this.propertiesCompletion,
            }
        );

        modalRef.result
            .then(
                (result: any) => {
                    if (result) {
                        if (!editMode) {
                            this.editFields.push(field);
                        }
                        this.emitValue();
                    }
                },
                () => {}
            )
            .finally(() => this.modalWindowOpened.next(false));
    }

    addField() {
        const newField: CiCdFieldDescription = { ...DEFAULT_FIELD_DESCRIPTOR };
        this.openNewFieldModal(newField, false);
    }

    editField(field: any) {
        this.openNewFieldModal(field, true);
    }

    removeField(field: any) {
        const modalRef = this.modalService.open(ModalConfirmComponent, {
            centered: true,
            backdrop: 'static',
        });
        modalRef.componentInstance.message = {
            title: 'Delete',
            content: `Are you sure you want to delete this variable?`,
            warningMessage: 'Referencing a deleted variable in a script can cause errors.',
        };
        modalRef.componentInstance.confirmOk.subscribe((result: any) => {
            if (result) {
                this.editFields = this.removeArrayItem(field, this.editFields);
                this.valueChange.emit(this.editFields);
            }
        });
    }

    emitValue() {
        this.valueChange.emit(this.editFields);
    }

    removeArrayItem(element: any, array: any[]) {
        const removedIndex = array.indexOf(array.find(el => el === element));
        if (removedIndex > -1) {
            array.splice(removedIndex, 1);
        }
        return array;
    }

    handleAddSystemProperty() {
        this.modalWindowOpened.emit(true);
        const modalRef = this.modalService.open(
            AddSystemPropertyComponent,
            {
                centered: true,
                backdrop: 'static',
                size: 'lg',
            },
            {
                editProperties: this.editFields,
                hierarchyLevel: this.hierarchyLevel,
            }
        );
        modalRef.result
            .then(
                (result: { addedProperties: PropertyWSelected[] }) => {
                    if (result && result.addedProperties) {
                        this.editFields.push(...result.addedProperties);
                        this.emitValue();
                    }
                },
                () => {}
            )
            .finally(() => this.modalWindowOpened.next(false));
    }

    handleToggle() {
        this.fieldsHidden = !this.fieldsHidden;
        this.toggleChange.emit(this.fieldsHidden);
    }

    handleReorder(event: CdkDragDrop<string[]>) {
        moveArrayElement(this.fields, event.previousIndex, event.currentIndex);
        this.emitValue();
    }

    publishChanges() {
        this.modalWindowOpened.emit(true);
        let modalRef = this.modalService.open(
            TemplatePublishChangesComponent,
            {
                centered: true,
                backdrop: 'static',
            },
            {
                radioButtons: [
                    {
                        value: true,
                        label: 'Update existing properties with the latest changes.',
                    },
                    {
                        value: false,
                        label: 'Update with newly added properties only.',
                        checked: true,
                    },
                ],
            }
        );

        modalRef.componentInstance.confirmOk.subscribe((overwrite: boolean) =>
            this.publishProperties.next({
                id: this.idToPublish,
                level: this.hierarchyLevel,
                overwrite: overwrite,
            })
        );

        modalRef.result.finally(() => this.modalWindowOpened.emit(false));
    }

    showUploadDialog() {
        this.modalService
            .open(
                UploadVariablesDialogComponent,
                {
                    centered: true,
                    backdrop: 'static',
                    windowClass: 'upload-variables-dialog',
                },
                {
                    uploadFn: this.uploadFn,
                }
            )
            .result.finally(() => {
                this.modalWindowOpened.emit(false);
            });
    }
}
