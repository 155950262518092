import {Component, EventEmitter, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModal, DropdownItem } from '@dagility-ui/kit';

@Component({
    selector: 'app-repository-existing-modal',
    templateUrl: './repository-existing-modal.component.html',
    styleUrls: ['./repository-existing-modal.component.scss']
})
export class RepositoryExistingModalComponent {

    spinnerItems: number[] = new Array(8);

    loading: boolean = true;
    listOfUrls: DropdownItem[];

    @Output() confirmOk: EventEmitter<any> = new EventEmitter();
    @Output() cancelBtnClicked: EventEmitter<any> = new EventEmitter();

    message: ConfirmModal;

    confirmButtonText = 'Yes';
    closeButtonText = 'No';

    showConfirmButton = true;
    showCancelButton = true;

    constructor(public modal: NgbActiveModal) {}

    confirm() {
        this.confirmOk.emit(true);
        this.modal.close();
    }

    cancel() {
        this.cancelBtnClicked.emit();
        this.modal.dismiss('cancel click');
    }

    openLink(url: string) {
        window.open(url);
    }
}

