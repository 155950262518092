import { DataSetFieldRole } from '@app/shared/components/query-builder/models/model-graph.model';
import { cloneDeep } from 'lodash';
import { WidgetType } from 'data-processor';
import { AllowedIcons } from '@app/shared/components/query-builder/consts/icon-remoter/icon-remoter.component';

export interface WidgetTypeRolesConvention {
    dataSetFieldRole: DataSetFieldRole;
    name: string;
    iconName: AllowedIcons;
    ordered?: boolean;
    maxDroppedItems?: number;
    dragPlaceTitle?: string;
}

const WIDGET_TYPE_ROLES: Map<WidgetTypeRolesConvention, WidgetType[]> = new Map<WidgetTypeRolesConvention, WidgetType[]>();
{
    WIDGET_TYPE_ROLES.set(
        {
            dataSetFieldRole: 'LABEL',
            name: 'Label',
            iconName: 'LABEL',
            maxDroppedItems: 1,
        },
        [
            WidgetType.NESTED_PIE_CHART,
            WidgetType.DOUGHNUT_CHART,
            WidgetType.PIE_CHART,
            WidgetType.BOXPLOT,
            WidgetType.RADIAL,
            WidgetType.SCATTER_CHART,
        ]
    );
    WIDGET_TYPE_ROLES.set(
        {
            dataSetFieldRole: 'VALUE',
            name: 'Value',
            iconName: 'VALUE',
            maxDroppedItems: 1,
        },
        [
            WidgetType.NESTED_PIE_CHART,
            WidgetType.DOUGHNUT_CHART,
            WidgetType.PIE_CHART,
            WidgetType.BOXPLOT,
            WidgetType.RADIAL,
            WidgetType.SCATTER_CHART,
        ]
    );
    WIDGET_TYPE_ROLES.set(
        {
            dataSetFieldRole: 'KEY',
            name: 'Key',
            iconName: 'KEY',
            maxDroppedItems: 1,
        },
        [WidgetType.SCATTER_CHART]
    );
    WIDGET_TYPE_ROLES.set(
        {
            dataSetFieldRole: 'CATEGORY_LABEL',
            name: 'Category Label',
            iconName: 'CATEGORY',
            maxDroppedItems: 1,
        },
        [WidgetType.NESTED_PIE_CHART]
    );
    WIDGET_TYPE_ROLES.set(
        {
            dataSetFieldRole: 'TABLE_COLUMN',
            name: 'Columns',
            iconName: 'TABLE',
            ordered: true,
        },
        [WidgetType.TABLE /*, WidgetType.TABLE_WITH_TABS*/]
    );
    // WIDGET_TYPE_ROLES.set(
    //     {
    //         dataSetFieldRole: 'TAB_NAME',
    //         name: 'Tab names',
    //         iconName: 'TABLE',
    //         maxDroppedItems: 1,
    //     },
    //     [WidgetType.TABLE_WITH_TABS]
    // );
    WIDGET_TYPE_ROLES.set(
        {
            dataSetFieldRole: 'AXIS_1_VALUE',
            name: 'Y Axis',
            iconName: 'Y-AXIS',
            ordered: true
        },
        [WidgetType.BAR_CHART, WidgetType.LINE_CHART, WidgetType.MULTIPLE_Y_AXIS]
    );
    WIDGET_TYPE_ROLES.set(
        {
            dataSetFieldRole: 'AXIS_2_VALUE',
            name: 'X Axis',
            iconName: 'X-AXIS',
            maxDroppedItems: 1,
        },
        [WidgetType.BAR_CHART, WidgetType.LINE_CHART, WidgetType.MULTIPLE_Y_AXIS]
    );
    WIDGET_TYPE_ROLES.set(
        {
            dataSetFieldRole: 'MULTI_LABEL',
            name: 'Label',
            iconName: 'LABEL',
        },
        [WidgetType.SUNBURST_CHART]
    );
    WIDGET_TYPE_ROLES.set(
        {
            dataSetFieldRole: 'VALUE',
            name: 'Value',
            iconName: 'VALUE',
            maxDroppedItems: 1,
        },
        [WidgetType.SUNBURST_CHART]
    );

    WIDGET_TYPE_ROLES.set(
        {
            dataSetFieldRole: 'GANTT_CAT',
            name: 'Category',
            iconName: 'CATEGORY',
            maxDroppedItems: 1,
        },
        [WidgetType.GANTT]
    );
    WIDGET_TYPE_ROLES.set(
        {
            dataSetFieldRole: 'GANTT_NAME',
            name: 'Name',
            iconName: 'NAME',
            maxDroppedItems: 1,
        },
        [WidgetType.GANTT]
    );
    WIDGET_TYPE_ROLES.set(
        {
            dataSetFieldRole: 'GANTT_START_DATE',
            name: 'Start Date',
            iconName: 'START-DATE',
            maxDroppedItems: 1,
        },
        [WidgetType.GANTT]
    );
    WIDGET_TYPE_ROLES.set(
        {
            dataSetFieldRole: 'GANTT_END_DATE',
            name: 'End Date',
            iconName: 'END-DATE',
            maxDroppedItems: 1,
        },
        [WidgetType.GANTT]
    );
    WIDGET_TYPE_ROLES.set(
        {
            dataSetFieldRole: 'GANTT_DURATION',
            name: 'Duration',
            iconName: 'TIME',
            maxDroppedItems: 1,
        },
        [WidgetType.GANTT]
    );
}

export function GetRolesByWidgetType(currentWidgetType: WidgetType) {
    const widgetTypeRoles = cloneDeep<Map<WidgetTypeRolesConvention, WidgetType[]>>(WIDGET_TYPE_ROLES);
    const roles: DataSetFieldRole[] = [];
    widgetTypeRoles.forEach((widgetTypes, widgetTypeRolesConv) => {
        if (widgetTypes.find(widgetType => widgetType === currentWidgetType)) roles.push(widgetTypeRolesConv.dataSetFieldRole);
    });
    return roles;
}

export function GetWidgetTypeRolesConventionByWidgetType(currentWidgetType: WidgetType): WidgetTypeRolesConvention[] {
    const widgetTypeRoles = cloneDeep<Map<WidgetTypeRolesConvention, WidgetType[]>>(WIDGET_TYPE_ROLES);
    const roles: WidgetTypeRolesConvention[] = [];
    widgetTypeRoles.forEach((widgetTypes, widgetTypeRolesConv) => {
        if (widgetTypes.find(widgetType => widgetType === currentWidgetType)) roles.push(widgetTypeRolesConv);
    });
    return roles;
}

export function GetAllowedWidgetTypes(): WidgetType[] {
    const widgetTypeRoles = cloneDeep<Map<WidgetTypeRolesConvention, WidgetType[]>>(WIDGET_TYPE_ROLES);
    const widgetTypes: WidgetType[] = [];
    widgetTypeRoles.forEach(value => {
        widgetTypes.push(...value.filter(v => !widgetTypes.includes(v)));
    });
    return widgetTypes;
}
