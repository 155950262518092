<div *libLet="smallView$ | async as smallView" class="tiles-group custom-chart-size">
    <div class="tiles-row py-4 d-flex justify-content-md-start" *ngFor="let headerRow of headerData; let i = index">
        <div
            class="tile-box m-1 d-flex"
            *ngFor="let item of headerRow; let j = index; let first = first"
            data-html="true"
            [class.small]="smallView"
            [style.margin-left]="first ? '0 !important' : '0.25rem !important'"
            [disableTooltip]="!measuresToolTipData.length || isEmpty(measuresToolTipData[i][j])"
            [ngbTooltip]="htmlContent"
            (click)="tileClick.emit({ tileName: item, rating: ratingData[i][j] })"
            placement="top"
            container="body"
            tooltipClass="org-chart-tooltip"
            [ngStyle]="{
                cursor: ratingData[i][j] === undefined ? 'pointer' : ''
            }"
        >
            <ng-template #htmlContent>
                <table>
                    <tr *ngFor="let toolTipData of measuresToolTipData[i][j] | keyvalue">
                        <td class="text-left" style="border-bottom-width: 1px;">
                            <label class="rank-circle" [ngStyle]="{ background: data.colorData[item][toolTipData.key] }"></label>&nbsp;
                            <span class="rank-text" [ngStyle]="{ opacity: toolTipData.key == ratingData[i][j] ? '1' : '0.7' }">
                                <span *ngIf="!(i == 1 && j == 1)">{{ toolTipData.key }}&nbsp;:&nbsp;</span>
                                {{ toolTipData.value }}
                            </span>
                        </td>
                    </tr>
                </table>
            </ng-template>
            <div
                *ngIf="!(data.progress || {})[item]"
                [ngClass]="{ 'rating-null': ratingData[i][j] === undefined }"
                class="tile-rating-box d-flex justify-content-center float-left"
                [ngStyle]="{ background: colorData[i][j], color: colorData[i][j] ? '#ffffff' : '#4a4a4a' }"
            >
                <span
                    class="tile-rating"
                    [ngStyle]="{
                        'font-size': isAlphabet(ratingData[i][j])
                            ? '18px'
                            : ratingData[i][j] && ratingData[i][j].toString().length > 2
                            ? ratingData[i][j] && ratingData[i][j].toString().length > 4
                                ? '10px'
                                : '12px'
                            : '14px'
                    }"
                    >{{ ratingData[i][j] }}</span
                >
            </div>
            <div
                class="tile-content d-flex flex-column"
                [ngbTooltip]="htmlContentData"
                placement="top"
                [disableTooltip]="!(data.progress || {})[item]"
                container="body"
                tooltipClass="org-chart-tooltip"
            >
                <ng-template #htmlContentData>
                    <table>
                        <tr>
                            <td class="text-left">
                                <label class="rank-circle bg-success"></label>&nbsp;
                                <span class="rank-text"> Covered: {{ ratingData[i][j] | number: '1.0-2' }}% </span>
                            </td>
                        </tr>
                        <tr>
                            <td class="text-left">
                                <label class="rank-circle bg-danger"></label>&nbsp;
                                <span class="rank-text"> Not covered: {{ 100 - ratingData[i][j] | number: '1.0-2' }}% </span>
                            </td>
                        </tr>
                    </table>
                </ng-template>
                <div
                    class="tile-header p-0"
                    [ngStyle]="{ padding: isAlphabet(ratingData[i][j]) || (data.progress || {})[item] ? '0.4rem' : '1rem' }"
                >
                    {{ item }}
                </div>
                <div *ngIf="(data.progress || {})[item]" class="progress ml-0 mt-auto">
                    <div class="progress-bar bg-success" role="progressbar" [style.width.%]="ratingData[i][j]"></div>
                    <div class="progress-bar bg-danger" role="progressbar" [style.width.%]="100 - ratingData[i][j]"></div>
                </div>
                <span
                    [ngStyle]="{
                        'font-size': ratingData[i][j] === undefined ? '15px' : '12px'
                    }"
                    class="tile-value p-0 mt-auto align-self-start"
                    >{{ valueData[i][j] }}</span
                >
            </div>
        </div>
    </div>
</div>
