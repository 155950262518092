<div class="d-inline d-inline-flex align-items-center font-16px">
    <fa-icon class="mr-10px cursor-pointer" icon="facArrowLeftRegular" (click)="handleBackClick()"></fa-icon>
    <nav *ngIf="pathStack.length > 1">
        <ol class="breadcrumb">
            <li *ngFor="let stackItem of pathStack; let lastItem = last"
                [ngClass]="{'active': lastItem, 'cursor-pointer': !lastItem}"
                class="breadcrumb-item">
                <a (click)="navigateToPath(stackItem.path, lastItem)">
                    {{ stackItem.name }}
                </a>
            </li>
        </ol>
    </nav>
</div>
