import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { CustomIcon, facClose } from '@dagility-ui/shared-components/icons';
import { validateFormAndDisplayErrors } from '@dagility-ui/kit';

@Component({
    selector: 'dp-job-set-confirm-form',
    templateUrl: './job-set-confirm-form.component.html',
    styleUrls: ['./job-set-confirm-form.component.scss'],
})
export class JobSetConfirmFormComponent implements OnInit {
    @Input() isJD: boolean;
    @Input() setName: string;

    icons: Record<string, IconDefinition | CustomIcon> = {
        facClose: facClose,
    };

    form: FormGroup;
    title: string;
    message: string;
    action: string;

    constructor(public modalService: NgbActiveModal, private fb: FormBuilder) {}

    ngOnInit() {
        this.form = this.fb.group({
            setName: [this.setName || '', [Validators.required]],
        });
    }

    handleSave() {
        if (this.form.valid) {
            this.modalService.close(this.form.get('setName').value);
        } else {
            validateFormAndDisplayErrors(this.form);
        }
    }
}
