import { isEmpty } from 'lodash';
import { of } from 'rxjs';
import { DropdownItem, isDefined } from '@dagility-ui/kit';
import { ColDef } from '@ag-grid-community/core';

import { ChartType, ProgressChartData, TileChartDataModel } from '@dagility-ui/shared-components';

import { AnyWidgetModel, WidgetSerie, WidgetTableColumn, WidgetType } from '../models/any-widget.model';
import {
    BasePlaceholders,
    getColorForThresholdRelatedChart,
    hasHeader,
    hasTooltipFormatter,
    isBarChartWidget,
    randomLightColor,
    SandBox,
    ThresholdRelatedColors,
} from './widget-builder.util';
import { WidgetTemplateInterpolationManager } from './widget-template.interpolation';
import { ColumnWithContextMenuComponent } from '../components/widget/any-widget/column-context-menu/column-with-context-menu';
import { CustomTooltipComponent } from '../components/widget/any-widget/custom-tooltip.component';
import { ProgressColumnComponent } from '../components/custom-components/progress-column.component';
import { StatisticColumnComponent } from '../components/custom-components/statistic-column/statistic-column.component';
import { CellCustomComponent } from 'data-processor/lib/widget-library/widget-builder/components/custom-components/cell-custom/cell-custom.component';

const buildSeriesChartOptions = (
    typeOptionsFn: (series: WidgetSerie, interpolationManager: WidgetTemplateInterpolationManager) => Record<string, any>,
    getDataFn: (series: WidgetSerie, item: any, interpolationManager: WidgetTemplateInterpolationManager) => any
) => (
    queryResult: any,
    widgetModel: AnyWidgetModel,
    interpolationManager: WidgetTemplateInterpolationManager,
    placeholders?: any,
    type?: string
) =>
    getDefaultSeriesIfEmpty(widgetModel, interpolationManager).reduce(
        (acc, series) => {
            const zones = queryResult.find((el: any) => el.zones);
            const markArea: any = {};
            markArea.data = [];
            if (zones) {
                const zonesIndex = queryResult.findIndex((el: any) => el.zones);
                queryResult.splice(zonesIndex, 1);
                for (const zone of zones.zones) {
                    const elementsArray = [];
                    elementsArray.push({ itemStyle: { color: randomLightColor() }, name: zone.name, xAxis: zone.from });
                    elementsArray.push({ xAxis: zone.to });
                    markArea.data.push(elementsArray);
                }
            }

            let newSeries: Record<string, any>;
            if (type === 'scatter') {
                const data = [];
                if (queryResult && queryResult.length) {
                    for (const obj of queryResult) {
                        data.push([obj.label, obj.value, obj.key]);
                    }
                }
                newSeries = {
                    type: 'scatterchart',
                    data: data,
                };
            } else {
                newSeries = {
                    data: (queryResult || []).map((item: any) => getDataFn(series, item, interpolationManager)),
                    ...typeOptionsFn(series, interpolationManager),
                };
            }

            if (widgetModel.chartOptions.relatedThreshold && placeholders.scoreResult && type === 'bar') {
                const data = [];
                for (const seriesElement of newSeries.data) {
                    data.push({
                        value: seriesElement,
                        itemStyle: {
                            color: getColorForThresholdRelatedChart(
                                seriesElement,
                                widgetModel.chartOptions.higherIsBetter,
                                widgetModel.chartOptions.mediumThreshold,
                                widgetModel.chartOptions.criticalThreshold,
                                widgetModel.colorThreshold
                            ),
                        },
                    });
                }
                newSeries.data = data;
            }

            if (widgetModel.type === 'stackedbarchart') {
                newSeries['colorBy'] = widgetModel.chartOptions.colorBy;
            }
            if (widgetModel.chartOptions.showArea) {
                newSeries.areaStyle = {};
            }
            if (widgetModel.chartOptions.showStacked) {
                newSeries.stack = 'Total';
                newSeries.smooth = false;
            }
            if (markArea.data.length) {
                newSeries.markArea = markArea;
            }
            if (widgetModel.chartOptions.relatedThreshold && placeholders.scoreResult !== undefined && type === 'line') {
                newSeries.color = getColorForThresholdRelatedChart(
                    placeholders.scoreResult,
                    widgetModel.chartOptions.higherIsBetter,
                    widgetModel.chartOptions.mediumThreshold,
                    widgetModel.chartOptions.criticalThreshold,
                    widgetModel.colorThreshold
                );
            }
            if (newSeries.hasOwnProperty('colorBy') && newSeries.colorBy === 'data') {
                acc.colors.push(...widgetModel.colors);
            } else {
                acc.colors.push(series.color);
            }
            acc.series.push(newSeries);

            return acc;
        },
        {
            series: [],
            colors: [],
            categories: (queryResult || []).filter(({ label }: any) => label).map(({ label }: any) => label),
            sortedCategories: (queryResult || []).filter(({ sortingDate }: any) => sortingDate).map(({ sortingDate }: any) => sortingDate),
            minYAxisValue: +widgetModel.chartOptions.minYAxisValue,
            maxYAxisValue: +widgetModel.chartOptions.maxYAxisValue,
            legend: { ...getLegendSettings(widgetModel.chartOptions.hideLegend) },
            grid: {
                bottom: widgetModel.chartOptions.gridBottom,
            },
            relatedThreshold: widgetModel.chartOptions.relatedThreshold,
            disableBottomLine: widgetModel.chartOptions.disableBottomLine,
            scoreColor: placeholders
                ? getColorForThresholdRelatedChart(
                      placeholders.scoreResult,
                      widgetModel.chartOptions.higherIsBetter,
                      widgetModel.chartOptions.mediumThreshold,
                      widgetModel.chartOptions.criticalThreshold,
                      widgetModel.colorThreshold
                  )
                : undefined,
            tooltipClosePosition: placeholders?.tooltipClosePosition,
        }
    );

function buildMultilineOptions(label: string, queryResult: any[], widgetModel: AnyWidgetModel, placeholders: any, type = 'line') {
    const isTimeWidget = isTimeSeriesWidget(widgetModel);

    const getValueFn = (elementLabel: string, value: any) => {
        if (isTimeWidget) {
            return [elementLabel, value];
        } else {
            return value;
        }
    };

    const getColorFn = (value: any) => {
        if (value.color) {
            return value.color;
        }
    };

    const getMarkLineFn = (value: any) => {
        // get markline values from series and assign to respective attributes
        if (value.markLine) {
            const dataArray = [];
            for (let eachMarkLine of value.markLine) {
                dataArray.push({
                    xAxis: eachMarkLine.xAxis,
                    label: {
                        formatter: eachMarkLine.formatter,
                        position: 'end',
                        color: '#fff',
                        fontSize: 10,
                        backgroundColor: eachMarkLine.backgroundColor,
                        padding: 3,
                        borderRadius: 5,
                    },
                    lineStyle: {
                        color: eachMarkLine.backgroundColor,
                        type: eachMarkLine.lineType,
                    },
                });
            }
            const markLineObj = { data: dataArray, symbol: ['none', 'none'] };
            return markLineObj;
        } else {
            return;
        }
    };

    const zones = queryResult.find(el => el.zones);
    const markArea: any = {};
    markArea.data = [];
    if (zones) {
        const zonesIndex = queryResult.findIndex(el => el.zones);
        queryResult.splice(zonesIndex, 1);
        for (const zone of zones.zones) {
            const elementsArray = [];
            elementsArray.push({ itemStyle: { color: randomLightColor() }, name: zone.name, xAxis: zone.from });
            elementsArray.push({ xAxis: zone.to });
            markArea.data.push(elementsArray);
        }
    }
    const seriesByFieldMap: Record<string, any> = {};

    return queryResult.reduce(
        (acc, { [label]: labelValue, ...common }, index) => {
            acc.categories.push(labelValue);

            if (index === 0) {
                const seriesCustomOptions = common.seriesCustomOptions ?? {};

                acc.series = Object.entries(common).reduce((series, [key, value]) => {
                    if (key === 'seriesCustomOptions') {
                        return series;
                    }

                    acc.legend.data.push(key);
                    const seriesDataObject: Record<string, any> = {
                        name: key,
                        data: [getValueFn(labelValue, value)],
                        type,
                        stack: key,
                        smooth: true,
                        symbol: 'rect',
                        symbolSize: 8,
                        showAllSymbol: 'auto',
                        animation: true,
                        markLine: getMarkLineFn(value),
                        seriesCustomOptions: seriesCustomOptions[key],
                    };

                    if (typeof value === 'object') {
                        seriesDataObject.color = getColorFn(value);
                    }
                    if (widgetModel.chartOptions.showArea) {
                        seriesDataObject.areaStyle = {};
                    }
                    if (widgetModel.chartOptions.showStacked) {
                        seriesDataObject.stack = 'Total';
                        seriesDataObject.smooth = false;
                    }
                    if (markArea.data.length) {
                        seriesDataObject.markArea = markArea;
                    }
                    series.push(seriesDataObject);
                    seriesByFieldMap[key] = seriesDataObject;

                    return series;
                }, []);
            } else {
                Object.entries(common).forEach(([field, value], seriesIndex) => {
                    if (field === 'seriesCustomOptions') {
                        return;
                    }

                    seriesByFieldMap[field]?.data.push(getValueFn(labelValue, value));
                });
            }

            return acc;
        },
        {
            series: [],
            categories: [],
            legend: {
                ...getLegendSettings(widgetModel.chartOptions.hideLegend),
                data: [],
                type: 'scroll',
            },
            minYAxisValue: +widgetModel.chartOptions.minYAxisValue,
            maxYAxisValue: +widgetModel.chartOptions.maxYAxisValue,
            colors: widgetModel.colors,
            grid: {
                bottom: widgetModel.chartOptions.gridBottom,
            },
        }
    );
}

function buildDynamicBar(queryResult: any[], widgetModel: AnyWidgetModel, m: WidgetTemplateInterpolationManager) {
    const labelPattern = /label\d+/;

    const options: Record<string, any> = {
        series: [],
        categories: [],
        legend: {
            ...getLegendSettings(widgetModel.chartOptions.hideLegend),
            data: [],
            type: 'scroll',
        },
        minYAxisValue: +widgetModel.chartOptions.minYAxisValue,
        maxYAxisValue: +widgetModel.chartOptions.maxYAxisValue,
        colors: widgetModel.colors,
    };

    if (!queryResult.length) {
        return options;
    }

    const seriesMap: Record<string, any> = {};
    const uniqueLabels = queryResult.reduce<Set<string>>((unique, row) => {
        Object.keys(row).forEach(key => {
            if (!labelPattern.test(key)) {
                return;
            }

            const label = row[key];
            if (!unique.has(label)) {
                unique.add(label);
                seriesMap[label] = { name: label, data: [], type: 'bar' };
            }
        });

        return unique;
    }, new Set<string>());

    for (const row of queryResult) {
        const { label: category } = row;
        const labelValueMap: Record<string, number> = {};
        const labelTypeMap: Record<string, string> = {};
        const labelColorMap: Record<string, string> = {};
        const labelMarkLineLabelMap: Record<string, string> = {};
        const labelMarkLineValueMap: Record<string, string> = {};

        Object.keys(row).forEach(key => {
            if (!labelPattern.test(key)) {
                return;
            }

            const n = key.match(/\d+/)[0];
            const {
                [`label${n}`]: label,
                [`value${n}`]: value,
                [`type${n}`]: type,
                [`color${n}`]: color,
                [`markLineLabel${n}`]: markLineLabel,
                [`markLineValue${n}`]: markLineValue,
            } = row;

            labelValueMap[label] = value;
            labelTypeMap[label] = type;
            labelColorMap[label] = color;
            labelMarkLineLabelMap[label] = markLineLabel;
            labelMarkLineValueMap[label] = markLineValue;
        });

        for (const label of uniqueLabels) {
            const value = labelValueMap[label];
            const type = labelTypeMap[label] === undefined ? 'bar' : labelTypeMap[label];
            const color = labelColorMap[label] || '';
            const markLineLabel = labelMarkLineLabelMap[label] || '';
            const markLineValue = labelMarkLineValueMap[label] || '';

            seriesMap[label].data.push(value ?? null);
            seriesMap[label].type = type;
            if (color !== '') {
                seriesMap[label].color = color;
            }
            seriesMap[label].stack =
                widgetModel.chartOptions.withEmptyBars && type !== 'bar'
                    ? false
                    : widgetModel.chartOptions.barsPosition === 'Combined' && widgetModel.series[0] && widgetModel.series[0].seriesBarStack
                    ? widgetModel.series[0] && widgetModel.series[0].seriesBarStack
                    : widgetModel.chartOptions.barsPosition === 'Ad' && widgetModel.series[0] && widgetModel.series[0].type === 'bar'
                    ? widgetModel.chartOptions.barsPosition
                    : false;

            seriesMap[label].emphasis =
                widgetModel.series[0].type === 'bar' && widgetModel.chartOptions.barsPosition === 'Ad'
                    ? {
                          focus: 'series',
                      }
                    : false;
            seriesMap[label].markLine = markLineValue
                ? {
                      data: [
                          {
                              name: markLineLabel ? m.interpolate(markLineLabel) : '',
                              yAxis: m.interpolate(markLineValue),
                              label: {
                                  show: true,
                                  distance: 10,
                                  formatter: markLineLabel ? '{b}: {c}' : '{c}',
                              },
                              lineStyle: {
                                  normal: {
                                      type: 'solid',
                                      show: true,
                                      position: 'end',
                                  },
                              },
                          },
                      ],
                  }
                : {};
        }

        options.categories.push(category);
    }

    options.legend.data = [...uniqueLabels];
    options.series = options.legend.data.map((label: string) => seriesMap[label]);
    return options;
}

const pieDoughnutChartBuilder = (doughnut: boolean) => (
    queryResult: any,
    widgetModel: AnyWidgetModel,
    interpolationManager: WidgetTemplateInterpolationManager
) => ({
    ...buildSeriesChartOptions(
        () => ({
            type: 'pie',
            radius: doughnut ? ['50%', '70%'] : '70%',
        }),
        (series, item, m) => ({ name: m.interpolate(item.label), value: item[series.column] })
    )(queryResult, widgetModel, interpolationManager),
    colors: widgetModel.colors || [],
    showTotal: !!widgetModel.chartOptions.showTotal,
    showCount: !!widgetModel.chartOptions.showCount,
    piWorkChartLabel: widgetModel.chartOptions.piWorkChartLabel,
});

function buildProgressChart(
    queryResult: { progress: Record<string, number>; labels: DropdownItem[] },
    { series, chartOptions, drilldownList, drilldown }: AnyWidgetModel,
    interpolationManager: WidgetTemplateInterpolationManager
): Omit<ProgressChartData, 'header'> {
    if (Array.isArray(queryResult)) {
        return {
            labels: [],
            progress: [],
            noDataMessage: '',
            clickable: false,
            measure: '',
        };
    }

    const progress = isDynamicSeriesChart(series)
        ? Object.keys(queryResult.progress).map(label => ({
              label,
              value: queryResult.progress[label],
              color: '#000',
              column: label,
          }))
        : series.map(({ column, label, color }) => ({
              label: interpolationManager.interpolate(label),
              color,
              value: queryResult.progress[column],
              column,
          }));

    const { nodatamessage, measure } = chartOptions;

    return {
        progress,
        labels: queryResult.labels,
        noDataMessage: interpolationManager.interpolate(nodatamessage),
        clickable: !!drilldown || !!(drilldownList || []).length,
        measure: measure || '',
    };
}

function buildBoxPlotChart(queryResult: { label: string; value: number[]; [key: string]: any }[]) {
    const series = queryResult.map(q => (Array.isArray(q.value) ? q.value : [q.value]));

    return { series, categories: queryResult.map(q => q.label), rawData: series };
}

function buildTileChart(
    queryResult: Record<string, { value: any; rating: any }>,
    widget: AnyWidgetModel,
    interpolationManager: WidgetTemplateInterpolationManager
): { result: TileChartDataModel } {
    return {
        result: widget.tiles.reduce<TileChartDataModel>(
            (acc, tile) => {
                if (!queryResult[tile.column]) {
                    return acc;
                }

                const { rating, value } = queryResult[tile.column];
                const progress = !!tile.progress;

                acc.tileData.headers.push(interpolationManager.interpolate(tile.header));
                acc.tileData.rating.push(rating);
                acc.tileData.value.push(progress ? `${rating}%` : value);
                acc.progress[tile.header] = progress;

                const ratingData: Record<string, any> = {};
                const allRatings = tile.ratings.find(item => item.rating === '*');

                acc.colorData[tile.header] = allRatings
                    ? { 0: allRatings.color }
                    : (acc.colorData[tile.header] = tile.ratings.reduce<Record<string, string>>((colors, item) => {
                          colors[item.rating] = item.color;

                          if (!!item.info) {
                              ratingData[item.rating] = item.info;
                          }

                          return colors;
                      }, {}));

                acc.ratingData.push(ratingData);

                return acc;
            },
            {
                tileData: {
                    headers: [],
                    rating: [],
                    value: [],
                },
                ratingData: [],
                colorData: {},
                progress: {},
            }
        ),
    };
}

function buildLogChart(queryResult: any, widgetModel: AnyWidgetModel) {
    return {
        result: of({
            fullLog: queryResult,
        }),
        scrollToBottom: widgetModel.chartOptions.scrollToBottom,
    };
}

function buildMultipleYAxisChart(queryResult: any, widgetModel: AnyWidgetModel, im: WidgetTemplateInterpolationManager) {
    const options = buildSeriesChartOptions(
        series => ({
            name: im.interpolate(series.label),
            type: series.type,
        }),
        getValueBySeriesColumn
    )(queryResult, widgetModel, im);
    const yaxis: Array<Record<string, any>> = [];

    for (let seriesIdx = 0; seriesIdx < widgetModel.series.length; seriesIdx++) {
        const axisData: Record<string, any> = {
            type: 'value',
            name: im.interpolate(widgetModel.series[seriesIdx].label),
        };

        options.series[seriesIdx].yAxisIndex = seriesIdx;
        yaxis.push(axisData);
    }

    return {
        ...options,
        options: {
            legend: options.legend,
            xaxis: [
                {
                    name: widgetModel.chartOptions.xlabel,
                },
            ],
            yaxis,
        },
    };
}

function buildAccordion(
    queryResult: Array<{ header: string; items: any[] }>,
    widgetModel: AnyWidgetModel,
    interpolationManager: WidgetTemplateInterpolationManager,
    placeholders: Record<string, any>
) {
    return {
        headerTemplate: widgetModel.chartOptions.headertemplate,
        items: queryResult.map(item => ({
            header: item.header,
            table: buildTable(item.items, widgetModel, interpolationManager, placeholders),
        })),
    };
}

function buildGantt(queryResult: any): any {
    const categories: any = [];
    const data: any = [];
    const category = { category: queryResult[0].category, categoryIndex: 0 };
    categories.push(category.category);
    let minXValue = queryResult[0].startDate;
    for (const result of queryResult) {
        if (!categories.find((element: any) => element === result.category)) {
            categories.push(result.category);
            category.category = result.category;
            category.categoryIndex++;
        }
        data.push({
            name: result.name,
            itemStyle: { normal: result.itemStyle || { color: '#7b9ce1', opacity: 0.5 } },
            value: [category.categoryIndex, result.startDate, result.endDate, result.duration],
            subChart: result.subChart
                ? {
                      ...buildWorkChart({ series: result.subChart.result }, { chartOptions: { piWorkChartLabel: 'Items' } }),
                      totalIssues: result.subChart.totalIssues,
                      issuesCompleted: result.subChart.issuesCompleted,
                  }
                : undefined,
        });
        if (minXValue > result.startDate) {
            minXValue = result.startDate;
        }
    }
    return {
        categories,
        data,
        startDate: minXValue,
    };
}

function buildRadial(queryResult: any, widgetModel: AnyWidgetModel): any {
    const angleAxisLabelMargin = 5;
    const maxValue = +widgetModel.chartOptions.maxValue;
    const maxRadialValue = maxValue + maxValue * 0.2;
    const radius = ['75%', '85%'];
    const series = [];
    let outerCircleValue: number;
    let innerCircleValue: number;
    const measure = widgetModel.chartOptions.measure;
    const color = getColorForThresholdRelatedChart(
        queryResult[0]?.value,
        widgetModel.chartOptions.higherIsBetter,
        +widgetModel.chartOptions.mediumThreshold,
        +widgetModel.chartOptions.criticalThreshold
    );

    const iterations = queryResult[0]?.value < maxValue ? 1 : 2;
    for (let i = 1; i <= iterations; i++) {
        let value = 0;
        let stack = 0;
        if (iterations === 1) {
            value = queryResult[0]?.value;
            outerCircleValue = value;
            stack = 1;
        } else {
            if (i === 1) {
                value = maxValue;
                outerCircleValue = value;
                stack = 2;
            } else if (i === 2) {
                value = queryResult[0]?.value - maxValue;
                innerCircleValue = Math.floor(value * 100) / 100;
                stack = 1;
            }
        }
        series.unshift({
            barGap: '150%',
            barWidth: i === 1 ? 20 : 10,
            color,
            coordinateSystem: 'polar',
            cursor: 'default',
            data: [value],
            roundCap: true,
            stack,
            type: 'bar',
            z: 3,
        });
        series.unshift({
            barGap: '150%',
            barWidth: i === 1 ? 20 : 10,
            color: '#F8F9FA',
            coordinateSystem: 'polar',
            cursor: 'default',
            data: [maxValue * 0.2],
            roundCap: true,
            stack,
            type: 'bar',
            z: 1,
        });
        series.unshift({
            barGap: '150%',
            barWidth: i === 1 ? 20 : 10,
            color: '#ECECEC',
            coordinateSystem: 'polar',
            cursor: 'default',
            data: [maxValue],
            roundCap: true,
            stack,
            type: 'bar',
            z: 2,
        });
    }
    const innerCircleText = innerCircleValue ? '\n{c| +' + innerCircleValue + measure + '}' : '';
    const title = {
        text: '{a|' + outerCircleValue + measure + '}' + innerCircleText ?? '',
        textStyle: {
            align: 'center',
            rich: {
                a: {
                    fontSize: 23,
                    fontWeight: 700,
                },
                c: {
                    fontSize: 19,
                    color,
                    fontWeight: 500,
                    padding: [5, 0],
                },
            },
        },
        x: 'center',
        y: 'center',
    };
    return {
        angleAxisLabelMargin,
        maxRadialValue,
        innerCircleValue,
        outerCircleValue,
        measure,
        series,
        title,
        radius,
        hideLegend: !!widgetModel.chartOptions.hideLegend,
    };
}

function buildHealthScore(queryResult: any, widgetModel: AnyWidgetModel, placeholders: Record<string, any>): any {
    const points = queryResult.value;
    const healthColor = getColorForThresholdRelatedChart(
        points,
        widgetModel.chartOptions.higherIsBetter,
        widgetModel.chartOptions.mediumThreshold,
        widgetModel.chartOptions.criticalThreshold
    );
    return {
        points,
        healthColor,
    };
}

function buildWorkDistribution({ workDistributionType, remainingIssues, remainingStoryPoints, series }: any, widgetModel: any): any {
    return {
        workDistributionType,
        remainingIssues,
        remainingStoryPoints,
        ...buildWorkChart({ series }, widgetModel),
    };
}

function buildWorkChart({ series }: any, { chartOptions }: any) {
    return {
        plotOptions: {
            pie: {
                cursor: 'pointer',
                allowPointSelect: true,
            },
        },
        tooltip: {
            shared: false,
            pointFormat: '<strong>{point.y}</strong>',
        },
        legend: {
            show: false,
        },
        series: [
            {
                data: series,
            },
        ],
        title: {
            text: '',
            subtext: chartOptions?.piWorkChartLabel,
            textStyle: {
                fontSize: 20,
                fontWeight: 'normal',
                lineHeight: '5',
            },
            subtextStyle: {
                color: '#000000',
                fontSize: 14,
                lineHeight: '5',
            },
        },
        customTextValue: true,
        showZeroValue: true,
    };
}

function buildMultipleRadials(queryResult: any) {
    return {
        radials: queryResult,
    };
}

function buildProgressMenu({ title, menu }: any) {
    return {
        title,
        menu,
    };
}

function buildTableWithNavs(
    queryResult: Array<{ tabName: string; items: any[]; tabCount?: number }>,
    widgetModel: AnyWidgetModel,
    interpolationManager: WidgetTemplateInterpolationManager,
    placeholders: Record<string, any>
) {
    if (isEmpty(queryResult)) {
        return { items: [] };
    }

    return {
        items: queryResult.map(item => ({
            tabName: item.tabName,
            tabId: `${widgetModel.id}-${item.tabName}`,
            tabCount: item.tabCount,
            table: buildTable(
                item.items,
                widgetModel,
                interpolationManager,
                placeholders,
                placeholders => placeholders[`columns_${item.tabName}`]
            ),
        })),
    };
}

function buildAccordionWithNavs(
    queryResult: any,
    widgetModel: AnyWidgetModel,
    interpolationManager: WidgetTemplateInterpolationManager,
    placeholders: Record<string, any>
) {
    const sandbox = new SandBox();
    const navItemsMapper = (obj: any) =>
        Array.isArray(obj)
            ? obj?.map((item: any) => ({
                  tabName: item.tabName,
                  tabValue: item.tabValue,
                  hideHeaders: item.hideHeaders,
                  noDataMessage: item.noDataMessage,
                  accordionColumns: widgetModel.accordionColumns,
                  withSearch: !!item.withSearch,
                  withInfiniteScrollbar: item.withInfiniteScrollbar ?? true,
                  clientSearch: !!item.clientSearch,
                  sortField: item.sortField,
                  sortFunction: item.sortFunction ? executeTemplateScript(item.sortFunction, sandbox)() : null,
                  sortItems: item.sortItems,
                  sortLabel: item.sortLabel,
                  items: item.accordionItems?.map((accordion: any) => ({
                      value: accordion.accordionValue,
                      table: buildTable(accordion.tableItems ?? [], widgetModel, interpolationManager, placeholders),
                  })),
              }))
            : [];

    return {
        statusMapper: widgetModel.series?.reduce((res: any, serie) => ({ ...res, [serie.column]: serie.color }), {}),
        checkboxLabel: widgetModel.chartOptions.piCheckboxLabel,
        isChildComponent: queryResult.isChildComponent,
        hasInfiniteScroll: queryResult.hasInfiniteScroll ?? true,
        customTemplate: queryResult.customTemplate,
        withoutParent: {
            navItems: navItemsMapper(queryResult.withoutParent),
        },
        withParent: queryResult.withParent?.map((parentAccordion: any) => ({
            parentAccordionValue: parentAccordion.parentAccordionValue,
            navItems: navItemsMapper(parentAccordion.navItems),
        })),
        openFirstRow: widgetModel.chartOptions.openFirstRow,
    };
}

function buildPIGantt(queryResult: any, widgetModel: AnyWidgetModel) {
    const legends = widgetModel.series?.map((serie: any) => ({ color: serie.color, value: serie.column }));

    if (!queryResult.series) {
        return {};
    }

    queryResult.series.forEach(
        (serie: any) =>
            (serie.itemStyle = {
                status: serie.itemStyle.status,
                color: legends?.find(item => item.value === serie.itemStyle.status)?.color || '#c3c3c3',
            })
    );

    return {
        legends,
        hasMarkLine: true,
        ...buildGantt(queryResult.series),
    };
}

function buildPIFeatureCompletionStatus(
    queryResult: any,
    widgetModel: AnyWidgetModel,
    interpolationManager: WidgetTemplateInterpolationManager,
    placeholders: Record<string, any>
) {
    const statusMapper = widgetModel.statuses.reduce(
        (res: any, status) => ({ ...res, [status.value.toLowerCase()]: status.statusType }),
        {}
    );

    const statusValueMapper = widgetModel.statuses.reduce(
        (res: any, status) => ({
            ...res,
            [status.value.toLowerCase()]: {
                value: status.value.toLowerCase(),
                label: status.label,
                icon: status.icon,
            },
        }),
        {}
    );

    const getStatus = (status: string, statusCategory: string) => ({
        ...statusValueMapper[statusCategory.toLowerCase()],
        label: status,
    });

    const isSingleWidget = Boolean(queryResult.singleWidget);
    const showLink = queryResult.linkActive ?? true;

    return {
        statusMapper,
        isSingleWidget,
        showLink,
        items: queryResult.items?.map((item: any) => ({
            issue: {
                ...item.issue,
                status: getStatus(item.issue.status, item.issue.statusCategory) ?? {
                    value: 'default',
                    label: item.issue.status,
                },
                workDistributionTooltip: buildWorkDistribution(item.issue.workDistributionTooltip, widgetModel),
            },
            ...buildAccordionWithNavs(item.issueItems, widgetModel, interpolationManager, placeholders),
        })),
    };
}

function buildPIIssueLifeCycle(queryResult: any, { statuses }: AnyWidgetModel) {
    return { ...queryResult, statuses };
}

function buildFactorScore(queryResult: any): any {
    return {
        values: queryResult,
    };
}

function buildSRESquares(queryResult: any): any {
    return {
        squares: queryResult.squares,
    };
}

function buildSREHexagons(queryResult: any): any {
    return {
        values: queryResult.values,
        bounds: queryResult.bounds,
        invertColors: !!queryResult.invertColors,
    };
}

function buildTreemap(data: any, widgetModel: AnyWidgetModel, { placeholders }: WidgetTemplateInterpolationManager) {
    const treeData = toSunburstTree(data);
    const THRESHOLD_ERROR_VALUE = -1;

    if (widgetModel.chartOptions.relatedThreshold) {
        function setThresholds(nodes: SunburstTree[]) {
            nodes.forEach(node => {
                const thresholdValue = (node as any).thresholdValue;

                (node as any).itemStyle = {
                    color:
                        thresholdValue === THRESHOLD_ERROR_VALUE
                            ? ThresholdRelatedColors.WITH_ISSUES
                            : getColorForThresholdRelatedChart(
                                  thresholdValue,
                                  widgetModel.chartOptions.higherIsBetter,
                                  widgetModel.chartOptions.mediumThreshold,
                                  widgetModel.chartOptions.criticalThreshold,
                                  widgetModel.colorThreshold
                              ),
                };

                setThresholds(node.children || []);
            });
        }

        setThresholds(treeData);
    }

    return {
        labelFormatter:
            widgetModel.chartOptions.labelFormatter && widgetModel.chartOptions.labelFormatter.trim()
                ? getTooltipFormatterFn(widgetModel.chartOptions.labelFormatter, placeholders, widgetModel)
                : undefined,
        series: {
            type: WidgetType.TREEMAP,
            data: treeData,
        },
        breadcrumb: {
            show: !widgetModel.chartOptions.hideBreadcrumb ?? true,
        },
        upperLabel: {
            show: !widgetModel.chartOptions.hideUpperLabel ?? true,
        },
    };
}

function buildCodeIssueDetails(queryResult: any): any {
    return {
        data: queryResult,
    };
}

function buildSecurityPostureDetails(queryResult: any): any {
    return {
        data: queryResult,
    };
}

function buildSREGantt(queryResult: any, {}: AnyWidgetModel, {}: WidgetTemplateInterpolationManager, placeholders: any): any {
    const timestamps: any = [];

    queryResult = queryResult.filter((span: any) => span.duration);

    queryResult.forEach((span: any) => {
        timestamps.push(span.timestamp);
        timestamps.push(span.timestamp + span.duration);
    });

    const data: any = [];
    const parents: any = queryResult.filter((span: any) => !span.parentId) || [];

    const itemMapper = (item: any) => ({
        title: item.localEndpoint?.serviceName || item.title,
        value: {
            id: item.id,
            parentId: item.parentId,
            name: item.name,
            color: item.color,
            from: item.timestamp,
            to: item.timestamp + item.duration,
            tableData: Object.entries(item.tags || [])?.map(([key, value]) => ({ tag: key, text: value })),
        },
    });

    const ganttMapper = (parent: any) => {
        const children: any = queryResult.filter((span: any) => span.parentId === parent.id);

        const childrenData: any = [];
        children.forEach((child: any) => childrenData.push(ganttMapper(child)));

        return {
            ...itemMapper(parent),
            children: childrenData || [],
        };
    };

    parents.forEach((parent: any) => data.push(ganttMapper(parent)));

    const ganttOptions = placeholders.ganttOptions || {};

    return {
        minimum: Math.min(...timestamps),
        maximum: Math.max(...timestamps),
        data,
        hideSlideSelector: !!ganttOptions.hideSlideSelector,
        customColumnDefs: ganttOptions.customColumnDefs,
    };
}

export function getColumns(
    { chartOptions, columns }: AnyWidgetModel,
    placeholders: Record<string, any>,
    getColumnsFn: (placeholders: Record<string, any>) => WidgetTableColumn[]
): WidgetTableColumn[] {
    if (chartOptions.isDynamicTable && placeholders && isDefined(getColumnsFn(placeholders))) {
        return getColumnsFn(placeholders) ?? [];
    }

    return columns ?? [];
}

export function buildTable(
    queryResult: unknown[],
    widgetModel: AnyWidgetModel,
    interpolationManager: WidgetTemplateInterpolationManager,
    placeholders: Record<string, any>,
    getColumnsFromPlaceholders = (placeholders: Record<string, any>) => placeholders.columns
) {
    const sandbox = new SandBox();
    const comparator = (a: any, b: any) => {
        if (typeof a === 'string' && typeof b === 'string') {
            return a.localeCompare(b);
        }

        return a - b;
    };

    const getFilterParams = (column: WidgetTableColumn) => {
        const res: any = {
            suppressAndOrCondition: true,
        };

        if (column.filterComparator) {
            res.comparator = executeTemplateScript(column.filterComparator, sandbox)();
        }

        return res;
    };

    const columns = getColumns(widgetModel, placeholders, getColumnsFromPlaceholders);
    const oldColumnVisibility = columns.every((element: WidgetTableColumn) => !element.columnVisible);
    const tableProperties = {
        hasResetFilter: widgetModel.chartOptions.hasResetFilter,
        tableId: widgetModel.id,
        tableData: {
            columnDefs: [
                ...columns.map(column => ({
                    headerName: interpolationManager.interpolate(column.title),
                    field: column.field,
                    resizable: true,
                    suppressMovable: true,
                    headerTooltip: column.headerTooltipTemplate,
                    tooltipComponentFramework: CustomTooltipComponent,
                    filter: column.filtertype,
                    filterParams: getFilterParams(column),
                    cellRendererFramework: column.progressColumn
                        ? ProgressColumnComponent
                        : column.statisticColumn
                        ? StatisticColumnComponent
                        : CellCustomComponent,
                    comparator: column.columnSorting ? executeTemplateScript(column.columnSorting, sandbox)() : comparator,
                    cellRendererParams: column.progressColumn
                        ? {
                              progressTemplate: executeTemplateScript(column.progressTemplate, sandbox),
                              progressTooltip: executeTemplateScript(column.progressTooltip, sandbox),
                              progressColors: column.progressColors || [],
                              randomizedProgressColors: [],
                              placeholders,
                              percentWidth: column.percentWidth,
                          }
                        : column.statisticColumn
                        ? { placeholders }
                        : {
                              cellRender: cellRenderer(column.cellTemplate),
                              cellTooltipTemplate: column.cellTooltipTemplate,
                              placeholders,
                          },
                    hide: oldColumnVisibility ? undefined : !column.columnVisible,
                })),
                {
                    headerComponentFramework: ColumnWithContextMenuComponent,
                    colId: 'selectionColumn',
                    resizable: false,
                    suppressMovable: true,
                    maxWidth: 48,
                },
            ] as ColDef[],
            rowData: (widgetModel.chartOptions.fixedLastRow ? (queryResult as any[]).slice(0, -1) : queryResult).map((row: any) =>
                Object.entries(row).reduce<Record<string, any>>((acc, [key, prop]) => {
                    acc[key] = typeof prop === 'object' ? JSON.stringify(prop) : prop;

                    return acc;
                }, {})
            ),
            pinnedBottomRowData: widgetModel.chartOptions.fixedLastRow ? (queryResult as any[]).slice(-1) : [],
        },
    } as any;
    const columnDefs = tableProperties.tableData.columnDefs;

    if (placeholders.selectedRowId !== undefined) {
        tableProperties.tableData.rowSelection = 'single';
        tableProperties.tableData.suppressRowDeselection = true;
        tableProperties.tableData.suppressCellSelection = true;
    }

    if (oldColumnVisibility && columnDefs.length > 9) {
        for (let i = 8; i < columnDefs.length; i++) {
            if (!columnDefs[i].colId) {
                columnDefs[i].hide = true;
            }
        }
    }

    return tableProperties;
}

function executeTemplateScript(script: string, sandbox: SandBox) {
    return sandbox.buildFn(`return ( function(params, placeholders) {${script} })`);
}

function getValueBySeriesColumn(series: WidgetSerie, item: any) {
    return item[series.column] ?? 0;
}

const scatterChartBuilder = () => (
    queryResult: any,
    widgetModel: AnyWidgetModel,
    interpolationManager: WidgetTemplateInterpolationManager,
    placeholders: any
) => ({
    ...buildSeriesChartOptions(
        (series, p) => ({
            name: interpolationManager.interpolate(series.label),
            type: 'scatter',
        }),
        getValueBySeriesColumn
    )(queryResult, widgetModel, interpolationManager, placeholders, 'scatter'),
    colors: widgetModel.colors || [],
});

const mappers: {
    [key in WidgetType]: (
        queryResult: any,
        widgetModel: AnyWidgetModel,
        interpolationManager: WidgetTemplateInterpolationManager,
        placeholders: Record<string, any>
    ) => Record<string, any>;
} = {
    [WidgetType.BAR_CHART]: (queryResult: any, widgetModel, im, placeholders) =>
        isDynamicSeriesChart(widgetModel.series)
            ? buildDynamicBar(queryResult, widgetModel, im)
            : buildSeriesChartOptions(
                  (series, m) => ({
                      name: m.interpolate(series.label),
                      type: series.type ? series.type : 'bar',
                      colorBy: widgetModel.chartOptions.colorBy,
                      markLine: series.markLineValue
                          ? {
                                data: [
                                    {
                                        name: series.markLineLabel ? m.interpolate(series.markLineLabel) : '',
                                        yAxis: m.interpolate(series.markLineValue),
                                        label: {
                                            show: true,
                                            distance: 10,
                                            formatter: series.markLineLabel ? '{b}: {c}' : '{c}',
                                        },
                                        lineStyle: {
                                            normal: {
                                                type: 'solid',
                                                show: true,
                                                position: 'end',
                                            },
                                        },
                                    },
                                ],
                            }
                          : {},
                      seriesCustomOptions: series.options,
                      stack:
                          widgetModel.chartOptions.barsPosition === 'Combined' && series.seriesBarStack
                              ? series.seriesBarStack
                              : widgetModel.chartOptions.barsPosition === 'Ad' && series.type === 'bar'
                              ? widgetModel.chartOptions.barsPosition
                              : false,
                      emphasis:
                          series.type === 'bar' && widgetModel.chartOptions.barsPosition === 'Ad'
                              ? {
                                    focus: 'series',
                                }
                              : false,
                  }),
                  (series: WidgetSerie, item: any) =>
                      widgetModel.chartOptions.withEmptyBars ? item[series.column] : item[series.column] === 0 ? null : item[series.column]
              )(queryResult, widgetModel, im, placeholders),
    [WidgetType.LINE_CHART]: (queryResult: any, widgetModel: AnyWidgetModel, interpolationManager, placeholders: any) =>
        isDynamicSeriesChart(widgetModel.series)
            ? buildMultilineOptions(widgetModel.series[0].label, queryResult, widgetModel, placeholders)
            : buildSeriesChartOptions(
                  (series, p) => ({
                      name: interpolationManager.interpolate(series.label),
                      type: 'line',
                      markLine: series.markLineValue
                          ? {
                                data: [
                                    {
                                        name: series.markLineLabel ? p.interpolate(series.markLineLabel) : '',
                                        yAxis: p.interpolate(series.markLineValue),
                                        label: {
                                            show: true,
                                            distance: 10,
                                            formatter: series.markLineLabel ? '{b}: {c}' : '{c}',
                                        },
                                        lineStyle: {
                                            normal: {
                                                type: 'solid',
                                                show: true,
                                                position: 'end',
                                            },
                                        },
                                    },
                                ],
                            }
                          : {},
                      areaStyle: series.seriesArea && !widgetModel.chartOptions.showArea ? {} : null,
                  }),
                  isTimeSeriesWidget(widgetModel)
                      ? (s, i) => {
                            const value = i[s.column];

                            if (!isDefined(value)) {
                                return undefined;
                            }

                            return [i.label, value];
                        }
                      : getValueBySeriesColumn
              )(queryResult, widgetModel, interpolationManager, placeholders, 'line'),
    [WidgetType.DOUGHNUT_CHART]: pieDoughnutChartBuilder(true),
    [WidgetType.PIE_CHART]: pieDoughnutChartBuilder(false),
    [WidgetType.SCATTER_CHART]: scatterChartBuilder(),
    [WidgetType.SUNBURST_CHART]: queryResult => ({
        tooltip: {
            trigger: 'item',
        },
        series: {
            type: 'sunburst',
            highlightPolicy: 'ancestor',
            data: toSunburstTree(queryResult),
            radius: [0, '100%'],
            label: {
                rotate: 'radial',
            },
        },
    }),
    [WidgetType.NESTED_PIE_CHART]: queryResult => ({
        series: getSeriesForNestedPieChart(queryResult),
        colors: [],
        categories: (queryResult || []).map(({ label }: any) => label),
        legend: {
            ...getLegendSettings(),
            selectedMode: false,
        },
    }),
    [WidgetType.TABLE]: buildTable,
    [WidgetType.TWO_DIMENSIONAL_TABLE]: (queryResult, { chartOptions }) =>
        mapQueryResultToTwoDimensionalGrid(
            queryResult,
            chartOptions.verticalHeader,
            chartOptions.horizontalHeader,
            chartOptions.gridValue,
            chartOptions.cellTemplate
        ),
    [WidgetType.STACKED_BAR_CHART]: (queryResult, { chartOptions }) => ({
        series: getSeriesForBarChart(queryResult),
        categories: getCategoriesForBarChart(queryResult),
        minYAxisValue: +chartOptions.minYAxisValue,
        maxYAxisValue: +chartOptions.maxYAxisValue,
        legend: { ...getLegendSettings(chartOptions.hideLegend) },
    }),
    [WidgetType.TREEMAP]: buildTreemap,
    [WidgetType.COMPLEX]: () => ({}),
    [WidgetType.PROGRESS]: buildProgressChart,
    [WidgetType.BOXPLOT]: buildBoxPlotChart,
    [WidgetType.TILE_CHART]: buildTileChart,
    [WidgetType.LOG]: buildLogChart,
    [WidgetType.MULTIPLE_Y_AXIS]: buildMultipleYAxisChart,
    [WidgetType.ACCORDION]: buildAccordion,
    [WidgetType.GANTT]: buildGantt,
    [WidgetType.RADIAL]: buildRadial,
    [WidgetType.PI_WORK_DISTRIBUTION]: buildWorkDistribution,
    [WidgetType.PI_WORK_CHART]: buildWorkChart,
    [WidgetType.PI_MULTIPLE_RADIALS]: buildMultipleRadials,
    [WidgetType.PI_PROGRESS_MENU]: buildProgressMenu,
    [WidgetType.TABLE_WITH_TABS]: buildTableWithNavs,
    [WidgetType.HEALTH_SCORE]: buildHealthScore,
    [WidgetType.ACCORDION_WITH_TABS]: buildAccordionWithNavs,
    [WidgetType.PI_GANTT]: buildPIGantt,
    [WidgetType.PI_FEATURE_COMPLETION_STATUS]: buildPIFeatureCompletionStatus,
    [WidgetType.PI_ISSUE_LIFE_CYCLE]: buildPIIssueLifeCycle,
    [WidgetType.SRE_SQUARES]: buildSRESquares,
    [WidgetType.SRE_HEXAGONS]: buildSREHexagons,
    [WidgetType.CODE_ISSUE_DETAILS]: buildCodeIssueDetails,
    [WidgetType.SECURITY_POSTURE_DETAILS]: buildSecurityPostureDetails,
    [WidgetType.SRE_GANTT]: buildSREGantt,
    [WidgetType.FACTOR_SCORES]: buildFactorScore,
    [WidgetType.CODE_QUALITY_SUMMARY]: buildCodeQualitySummary,
    [WidgetType.SCORE_DOUGHNUT]: buildScoreDoughnut,
    [WidgetType.METRIC_LINE]: buildMetricLineTemplate,
    [WidgetType.TOP_PRIORITY_RISKS]: buildTopPriorityRisksTemplate,
    [WidgetType.PORTFOLIO_HEALTH_SCORES]: buildPortfolioHealthScores,
    [WidgetType.PORTFOLIO_SCORE_SUBCATEGORIES]: buildPortfolioScoreSubcategories,
    [WidgetType.PORTFOLIO_STATISTICS]: buildPortfolioStatistics,
    [WidgetType.PORTFOLIO_RISK_SCORES]: buildPortfolioRiskScores,
    [WidgetType.COMPARE_METRICS]: buildCompareMetrics,
    [WidgetType.MERGE_REQUEST_INFO]: queryResult => ({ data: queryResult }),
    [WidgetType.MULTIPLE_SERIES_TYPE]: buildMultipleSeriesTypeChart,
    [WidgetType.METRIC_TILE]: queryResult => queryResult,
};

function buildCodeQualitySummary(queryResult: any) {
    return queryResult;
}

function buildPortfolioHealthScores(queryResult: any) {
    return queryResult;
}

function buildPortfolioRiskScores(queryResult: any) {
    return queryResult;
}

function buildCompareMetrics(queryResult: any) {
    return queryResult;
}

function buildPortfolioScoreSubcategories(queryResult: any) {
    return { subCategories: queryResult };
}

function buildPortfolioStatistics(queryResult: any) {
    return queryResult;
}

function buildScoreDoughnut(queryResult: any, widgetModel: AnyWidgetModel) {
    if (isEmpty(queryResult)) {
        return {};
    }
    const { maxValue, measure, higherIsBetter, mediumThreshold, criticalThreshold } = widgetModel.chartOptions;
    const thresholdColor = getColorForThresholdRelatedChart(
        queryResult.value,
        higherIsBetter,
        mediumThreshold,
        criticalThreshold,
        widgetModel.colorThreshold
    );
    let risk = 'Low';

    if (widgetModel.colorThreshold) {
        const relatedThreshold = widgetModel.colorThreshold.find(threshold => threshold.color === thresholdColor);
        if (relatedThreshold) {
            risk = relatedThreshold.label;
        } else {
            console.warn(`Not found label for ${thresholdColor} threshold`);
        }
    }

    return {
        value: queryResult.value,
        maxValue,
        measure,
        thresholdColor,
        risk,
        delta: queryResult.delta,
        note: queryResult.note,
    };
}

function buildTopPriorityRisksTemplate(queryResult: any) {
    return queryResult;
}

function buildMetricLineTemplate(queryResult: any, widgetModel: AnyWidgetModel, interpolationManager: any, placeholders: any) {
    const lineOptions = mappers[WidgetType.LINE_CHART](queryResult, widgetModel, interpolationManager, placeholders);
    lineOptions.anyWidget = true;

    return {
        lineOptions,
        score: placeholders.lastValue,
        delta: placeholders.delta,
        scoreColor: lineOptions.scoreColor,
        widget: widgetModel,
    };
}

function buildMultipleSeriesTypeChart(queryResult: any, widgetModel: AnyWidgetModel, im: WidgetTemplateInterpolationManager) {
    const options = buildSeriesChartOptions(
        series => ({
            name: im.interpolate(series.label),
            type: series.type,
            symbolSizeColumnName: series.type === 'scatter' ? `${series.column}SymbolSize` : null,
        }),
        (series: WidgetSerie, item: any) => (item[series.column] === 0 ? null : item[series.column])
    )(queryResult, widgetModel, im);

    const xAxisMinValue = queryResult.find((item: any) => item.xAxisMinValue);

    options.series.forEach(serie => {
        if (serie.type !== 'scatter') {
            return;
        }

        serie.data = serie.data.map((item: any, i: number) => ({
            value: serie.data[i] || null,
            symbolSize: queryResult[i][serie.symbolSizeColumnName],
        }));
    });

    let xAxisLabelFormatter;

    if (widgetModel.chartOptions.xAxisLabelFormatter) {
        const sandbox = new SandBox();
        xAxisLabelFormatter = executeTemplateScript(widgetModel.chartOptions.xAxisLabelFormatter, sandbox)();
    }

    return {
        ...options,
        options: {
            legend: options.legend,
            xaxis: [
                {
                    name: widgetModel.chartOptions.xlabel,
                    formatter: xAxisLabelFormatter,
                    min: xAxisMinValue,
                },
            ],
            yaxis: [
                {
                    name: widgetModel.chartOptions.ylabel,
                },
            ],
        },
    };
}

function cellRenderer(cellTemplate: string) {
    const sandbox = new SandBox();
    return cellTemplate && cellTemplate.length ? sandbox.buildFn(`return ( function(params) {${cellTemplate} })`) : null;
}

export function mapToChartOptions(widgetModel: AnyWidgetModel, placeholders: Record<string, any>, queryResult: any, currentLevel?: number) {
    const interpolationManager = new WidgetTemplateInterpolationManager(placeholders);
    const commonOptions = mapWidgetOptionsToChartOptions(widgetModel, interpolationManager);
    const empty = (isEmpty(queryResult) && typeof queryResult === 'object') ||
        (WidgetType.METRIC_TILE == widgetModel.type && isDefined(queryResult) && isEmpty(queryResult.tile));
    const widgetOptions = mappers[widgetModel.type](queryResult, widgetModel, interpolationManager, placeholders);

    if (widgetOptions.hasOwnProperty('legend') && isDefined(currentLevel)) {
        addLegendState(widgetOptions, placeholders, currentLevel);
    }

    if (widgetModel.type === WidgetType.METRIC_LINE) {
        widgetOptions.lineOptions.tooltipFormatter = commonOptions.tooltipFormatter;
        widgetOptions.lineOptions.tooltipTrigger = commonOptions.tooltipTrigger;
        widgetOptions.lineOptions.enterableTooltip = commonOptions.enterableTooltip;
    }

    if ([WidgetType.BAR_CHART, WidgetType.STACKED_BAR_CHART].includes(widgetModel.type)) {
        widgetOptions.showLabel = Boolean(placeholders.showLabel);
    }

    return {
        empty,
        noDataMessage: widgetModel.chartOptions.nodatamessage || getDefaultMessage(widgetModel.type),
        options: {
            ...commonOptions,
            ...widgetOptions,
        },
    };
}

function getDefaultMessage(template: WidgetType) {
    const templateMessage: Partial<Record<WidgetType, string>> = {
        [WidgetType.METRIC_TILE]: 'Try adjusting filter selections to load data in this widget.',
    };

    return (
        templateMessage[template] ??
        "Try adjusting filter selections to load data in this widget. If you still don't see data, notify your UST PACE admin."
    );
}

function addLegendState(widgetOptions: Record<string, any>, placeholders: Record<string, any>, currentLevel: number) {
    const legend = widgetOptions.legend;
    const legendItemsFromOptions = getLegendItems(widgetOptions);

    const legendUpdated = assignLegendState(placeholders, legend, currentLevel, legendItemsFromOptions);

    if (!legendUpdated && currentLevel !== 0) {
        const updatedFromPreviousLevel = assignLegendState(placeholders, legend, currentLevel - 1, legendItemsFromOptions);

        if (updatedFromPreviousLevel) {
            placeholders[`${BasePlaceholders.LEGEND_STATE}_${currentLevel}`] =
                placeholders[`${BasePlaceholders.LEGEND_STATE}_${currentLevel - 1}`];
        }
    }
}

function assignLegendState(placeholders: Record<string, any>, legend: Record<string, unknown>, level: number, legendFromOptions: string[]) {
    const legendState = placeholders[`${BasePlaceholders.LEGEND_STATE}_${level}`];

    if (isDefined(legendState) && Array.isArray(legendState)) {
        const legendItems = legendState.map(item => item.label);

        if (checkArrayEquality(legendItems, legendFromOptions ?? [])) {
            const selectedState: Record<string, boolean> = {};

            for (const item of legendState) {
                selectedState[item.label] = item.selected;
            }

            legend.selected = selectedState;

            return true;
        }
    }

    return false;
}

function getLegendItems(widgetOptions: Record<string, any>): string[] {
    if (!!widgetOptions.legend && !!widgetOptions.legend.data) {
        return widgetOptions.legend.data;
    }

    if (widgetOptions.series && Array.isArray(widgetOptions.series)) {
        return widgetOptions.series.map(item => item.name);
    }

    return [];
}

function checkArrayEquality(array1: string[], array2: string[]) {
    if (array1.length !== array2.length) {
        return false;
    }

    const seen: Map<unknown, number> = new Map();

    for (const item of array1) {
        seen.set(item, (seen.get(item) ?? 0) + 1);
    }

    return array2.every(item => {
        if (seen.get(item)) {
            seen.set(item, seen.get(item) - 1);

            return true;
        }

        return false;
    });
}

function getCategoriesForBarChart(queryResult: any) {
    if (queryResult && queryResult.length) {
        const chartTree = toSunburstTree(queryResult);

        const categories = [];
        for (const element of chartTree) {
            categories.push(element.name);
        }
        return categories;
    } else {
        return [];
    }
}

function getSeriesForBarChart(queryResult: any): { name: string; data: number[] }[] {
    if (queryResult && queryResult.length) {
        const chartTree = toSunburstTree(queryResult);
        const seriesMap = new Map<string, number[]>();
        const series = [];

        const childrenMap: Record<string, any> = {};
        for (const element of chartTree) {
            for (const { name, value } of element.children) {
                childrenMap[element.name] = childrenMap[element.name] || {};

                childrenMap[element.name][name] = value;
                if (!seriesMap.has(name)) {
                    seriesMap.set(name, []);
                }
            }
        }

        for (const element of chartTree) {
            for (const [label, value] of seriesMap.entries()) {
                value.push(childrenMap[element.name][label] || 0);
            }
        }

        for (const [key, value] of seriesMap.entries()) {
            series.push({
                name: key,
                data: value,
            });
        }

        return series;
    } else {
        return [];
    }
}

function getSeriesForNestedPieChart(queryResult: any) {
    if (queryResult && queryResult.length) {
        const chartTree = toSunburstTree(queryResult);
        const series: any = [];

        const area = 100 - (Object.keys(queryResult[0]).length - 2) * 5;
        const areaSector = Math.round(area / Object.keys(queryResult[0]).length - 1);

        return calculateSeriesForNestedChart(chartTree, series, Object.keys(queryResult[0]).length - 1, 0, areaSector);
    } else {
        return [];
    }
}

function calculateSeriesForNestedChart(list: SunburstTree[], series: any, count: number, level: number, areaSector: number): any {
    if (count > 0) {
        let children: SunburstTree[] = [];

        const seriesData = [];
        for (const element of list) {
            seriesData.push({ name: element.name, value: element.value });
            children = [...children, ...element.children];
        }

        const radiusFrom = `${level}%`;
        const radiusTo = `${level + areaSector}%`;

        series.push({
            type: 'pie',
            selectedMode: 'single',
            radius: [radiusFrom, radiusTo],

            label: {
                position: 'inner',
            },
            labelLine: {
                show: false,
            },
            data: seriesData,
        });

        count--;
        return calculateSeriesForNestedChart(children, series, count, level + areaSector + 5, areaSector);
    } else {
        return series;
    }
}

function toSunburstTree(data: any[]): SunburstTree[] {
    function _toSunburstTree(list: any[], maxLevel: number, level: number): SunburstTree[] {
        const map: Record<string, SunburstTree> = {};
        if (level > maxLevel) {
            return [];
        }

        for (const item of list) {
            const key = item[`label${level}`];

            if (key === undefined) {
                continue;
            }

            if (!map[key]) {
                map[key] = {
                    ...item,
                    name: key,
                    value: 0,
                    children: [],
                };
            }

            map[key].value += item.value;
            map[key].children.push(item);
        }

        for (const key of Object.keys(map)) {
            map[key].children = _toSunburstTree(map[key].children, maxLevel, level + 1);
        }

        return Object.values(map);
    }

    return data && data.length ? _toSunburstTree(data, Object.keys(data[0]).length - 1, 1) : [];
}

function isDynamicSeriesChart(series: WidgetSerie[]): boolean {
    return series && series.length === 1 && series[0].column === '*';
}

function getDefaultSeriesIfEmpty(widgetModel: AnyWidgetModel, interpolationManager: WidgetTemplateInterpolationManager): WidgetSerie[] {
    return widgetModel.series && widgetModel.series.length
        ? widgetModel.series
        : [
              {
                  label: interpolationManager.interpolate(widgetModel.chartOptions.ylabel),
                  column: 'value',
              },
          ];
}

function mapWidgetOptionsToChartOptions(widget: AnyWidgetModel, interpolationManager: WidgetTemplateInterpolationManager) {
    const { chartOptions, type } = widget;
    const tooltipFormatterExist = hasTooltipFormatter(type);

    const chartOptionsObject: EChartsAnyWidgetModel = {
        anyWidget: true,
        header: hasHeader(type) && chartOptions.headertemplate ? interpolationManager.interpolate(chartOptions.headertemplate) : undefined,
        title: {
            text: interpolationManager.interpolate(chartOptions.title),
            subtext: interpolationManager.interpolate(chartOptions.description),
        },
        xAxis: {
            name: interpolationManager.interpolate(chartOptions.xlabel),
            minInterval: chartOptions.xAxisMinInterval,
        },
        yAxis: {
            name: interpolationManager.interpolate(chartOptions.ylabel),
            minInterval: chartOptions.yAxisMinInterval,
        },
        type: getChartType(widget) as WidgetType,
        stacked:
            (WidgetType.STACKED_BAR_CHART === type && !isDefined(chartOptions.barsPosition)) ||
            (isBarChartWidget(type) && chartOptions.barsPosition === ('Stacked' || 'Combined')),
        tooltipFormatter:
            tooltipFormatterExist && chartOptions.tooltipFormatter
                ? getTooltipFormatterFn(widget.chartOptions.tooltipFormatter, interpolationManager.placeholders, widget)
                : undefined,
        tooltipTrigger: tooltipFormatterExist && chartOptions.tooltipTrigger ? chartOptions.tooltipTrigger : undefined,
        enterableTooltip: tooltipFormatterExist && chartOptions.enterableTooltip,
        dataZoom: widget.chartOptions.dataZoom ?? null,
        isBarSeriesClickEventSource: !!widget.chartOptions.isBarSeriesClick,
    };

    if (chartOptions.xaxistype) {
        chartOptionsObject.xAxis.type = chartOptions.xaxistype;
    }

    return chartOptionsObject;
}

function isTimeSeriesWidget(widget: AnyWidgetModel): boolean {
    return widget.chartOptions.xaxistype === 'time';
}

export function isTimeSeriesChartModel(chartModel: AnyWidgetChartModel) {
    return (chartModel.options.type as string) === 'timeserieschart';
}

function getChartType(widget: AnyWidgetModel): ChartType | string {
    const { type, chartOptions } = widget;

    switch (true) {
        case isBarChartWidget(type):
            return chartOptions.orientation === 'Horizontal' ? 'horizontalstackedbarchart' : 'stackedbarchart';
        case type === WidgetType.NESTED_PIE_CHART:
            return 'piechart';
        case isTimeSeriesWidget(widget):
            return 'timeserieschart';
        default:
            return type;
    }
}

function getLegendSettings(hide?: boolean): any {
    return {
        show: !hide,
        icon: 'circle',
        textStyle: {
            fontSize: 11,
        },
        selector: ['all', 'inverse'],
        selectorPosition: 'start',
        bottom: '0',
        width: '85%',
        type: 'scroll',
        orient: 'horizontal',
    };
}

function getTooltipFormatterFn(
    fn: string,
    placeholders: Record<string, any>,
    widget: AnyWidgetModel
): (params: any) => HTMLElement | HTMLElement[] | string {
    const sandbox = new SandBox();

    return sandbox.buildFn(`return (function(placeholders, getColorForThresholdRelatedChart) {
        return function(params) {
            ${fn}
        }
    })`)(placeholders, (value: number) => getColorForThresholdRelatedChart(value, false, 0, 0, widget.colorThreshold));
}

function mapQueryResultToTwoDimensionalGrid(
    data: any[],
    verticalHeaderColumn: string,
    horizontalHeaderColumn: string,
    valueColumn: string,
    cellTemplate: string
): TwoDimensionalGridModel {
    const horizontalColumns = new Set<string>([]);
    const verticalColumns = new Set<string>([]);
    const groupedData: TwoDimensionalGridData = {};

    for (const row of data) {
        const horizontalColumn: string = row[horizontalHeaderColumn];
        const verticalColumn: string = row[verticalHeaderColumn];

        horizontalColumns.add(horizontalColumn);
        verticalColumns.add(verticalColumn);

        if (!groupedData[horizontalColumn]) {
            groupedData[horizontalColumn] = {};
        }

        groupedData[horizontalColumn][verticalColumn] = {
            value: row[valueColumn],
            data: row,
        };
    }

    return {
        horizontalColumns: Array.from(horizontalColumns),
        verticalColumns: Array.from(verticalColumns),
        data: groupedData,
        cellTemplate,
    };
}

export interface AnyWidgetChartModel {
    empty: boolean;
    noDataMessage?: string;
    options: EChartsAnyWidgetModel;
}

interface EChartsAnyWidgetModel {
    anyWidget: true;
    title: {
        text: string;
        subtext: string;
    };
    xAxis: {
        name: string;
        type?: string;
        minInterval: number;
    };
    yAxis: {
        name: string;
        minInterval: number;
    };
    header?: string;
    type: WidgetType;
    stacked: boolean;
    tooltipFormatter?: ReturnType<typeof getTooltipFormatterFn>;
    isBarSeriesClickEventSource?: boolean;

    [key: string]: any;
}

interface SunburstTree {
    name: string;
    value: number;
    children: SunburstTree[];
}

type TwoDimensionalGridData = Record<string, Record<string, { value: any; data: any[] }>>;

export interface TwoDimensionalGridModel {
    horizontalColumns: string[];
    verticalColumns: string[];
    data: TwoDimensionalGridData;
    cellTemplate: string;
}
