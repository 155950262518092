<div class="coverage-wrapper">
    <div class="coverage-chart">
        <div class="row no-gutters">
            <h2 class="mt-0 chart-header__title">
                {{ item.title }}
            </h2>
        </div>

        <ng-container
            [ngTemplateOutlet]="item.chart ? chart : emptyTemplate"
            [ngTemplateOutletContext]="{ $implicit: item }"
        ></ng-container>

        <div class="row no-gutters align-items-center justify-content-center"
             [style.margin-top.px]="-80"
        >
            <fa-icon
                class="mr-3"
                [ngClass]="item.series.diff > 0 ? 'color-success' : 'color-failed'"
                [icon]="item.series.diff > 0 ? 'facArrowUpRegular' : 'facArrowDownRegular'"
            >
            </fa-icon>
            <span class="font-28px">{{ item.series.diff }}</span>
            <span class="font-12px" [style.margin-top.px]="12">%</span>
        </div>
        <div class="row no-gutters mt-1 justify-content-center">
            <span class="text-center text-gray">Compared to last cycle</span>
        </div>
    </div>

    <div *ngFor="let stat of getCoverageStatisticsList(item.statistics); first as isFirst; last as isLast"
         class="row no-gutters align-items-center"
         [ngClass]="{ 'mt': isFirst, 'mb': isLast }"
    >
        <div class="col font-12px">
            {{ stat.label }}
        </div>
        <div class="col statistics-value">
            {{ stat.value }}
        </div>
    </div>
</div>

<ng-template #chart let-item>
    <ng-container *ngIf="item as data">
        <lib-chart
            [type]="data.chart.type"
            [options]="data.chart.options"
            [height]="'moderate'"
        >
        </lib-chart>
    </ng-container>
</ng-template>

<ng-template #emptyTemplate></ng-template>
