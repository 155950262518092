import { Component, Input, Optional } from '@angular/core';
import { ControlContainer, FormGroup, FormGroupDirective } from '@angular/forms';

import { WidgetBuilderFacade } from '../../../state/widget-builder.facade';
import { AsyncQuery } from '../../../models/any-widget.model';
import { BlockForm } from '../widget-builder-block-form/widget-builder-block-form.component';
import { WidgetBuilderStore } from 'data-processor/lib/widget-library/widget-builder/services/widget-builder.store';
import { DropdownItem } from '@dagility-ui/kit';

@Component({
    selector: 'dp-widget-builder-async-query',
    templateUrl: './widget-builder-async-query.component.html',
    styleUrls: ['./widget-builder-async-query.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
    providers: [{ provide: BlockForm, useExisting: WidgetBuilderAsyncQueryComponent }],
})
export class WidgetBuilderAsyncQueryComponent extends BlockForm {
    @Input() set data(query: AsyncQuery) {
        this.form = this.facade.buildAsyncQueryForm(query);
    }

    form: FormGroup;
    types: DropdownItem[] = [{ label: 'POST', value: 'POST' }, { label: 'GET', value: 'GET' }];

    constructor(public facade: WidgetBuilderFacade, @Optional() public parent: FormGroupDirective, private store: WidgetBuilderStore) {
        super();
    }
}
