import {
    ChangeDetectorRef,
    Directive,
    ElementRef,
    Inject,
    Injectable,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    Optional,
    SimpleChanges,
    Type,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, combineLatest, merge, NEVER, Observable, of, ReplaySubject, Subject, Subscription, throwError } from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    filter,
    filter as filterObs,
    finalize,
    map,
    mapTo,
    mergeMap,
    shareReplay,
    skip,
    startWith,
    switchMap,
    switchMapTo,
    take,
    tap,
} from 'rxjs/operators';
import { cloneDeep, isEmpty, isEqual, partition, pick, pickBy } from 'lodash';

import { DropdownItem, IfInViewportService, isDefined, ModalService } from '@dagility-ui/kit';

import {
    AnyWidgetModel,
    WidgetDrilldown,
    WidgetEventDependency,
    WidgetFilter,
    WidgetFilterType,
    WidgetType,
} from '../models/any-widget.model';
import { DEFAULT_PLACEHOLDERS, DefaultPlaceholders } from '../providers/default-placeholders.provider';
import { AnyWidgetStore } from '../components/widget/any-widget/any-widget.store';
import { WIDGET_PREVIEW_MODE } from '../providers/widget-preview.token';
import { DrilldownEvent, WidgetDrilldownHistory } from './widget.drilldown';
import {
    BasePlaceholders,
    checkWidgetIsValid,
    getFiltersForm,
    hasInfiniteScroll,
    isDropdownFilter,
    isDynamicFilter,
    isServerSideGridWithPagination,
    parseError,
    SilenceWidgetError,
} from './widget-builder.util';
import { WidgetLogger, WidgetScriptExecutor } from './widget-script.executor';
import { WidgetDebuggerState } from './widget.debugger';
import {
    Breakpoint,
    ExecutionParameters,
    ExecutionParametersApi,
    ExecutionResult,
    Placeholders,
    WidgetBuilderService,
    WidgetScript,
} from './widget-builder.service';
import { WidgetEvent, WidgetEventManager, WidgetFormEvent, WidgetLoadedEvent } from './message-bus/widget-event.manager';
import { DashboardMessageBus, findEventHandler } from './message-bus/message-bus';
import { AsyncQueriesFlow } from './async-queries.flow';
import { FilterDependencyTreeBuilder } from './filter-dependency-tree';
import { PreferredFiltersContainer } from './preferred-filters.container';
import { DATA_MORPH_FEATURE_TOGGLE } from 'data-processor/tokens';

export const START_LOADING = { type: 'start' };

@Injectable()
export class WidgetWorkflow implements OnDestroy {
    get placeholders() {
        return this.position.placeholders;
    }

    set placeholders(value: Placeholders) {
        this.position.placeholders = value;
    }

    get logs$() {
        return this.logger.getLogs();
    }

    get debug() {
        return !this.id;
    }

    get results() {
        return WidgetWorkflow.flatWidgets(this.widget$.value).reduce((acc, { id }) => {
            const placeholders$ = this.widgetDebugger.placeholdersMap[id] as BehaviorSubject<any>;

            if (placeholders$) {
                acc.push(placeholders$.value);
            }

            return acc;
        }, []);
    }

    isSmallWidget = false;

    get isFunnelIconEnabled() {
        return this.placeholders.widgetFilterVisibility === 'hideAll' || this.isSmallWidget;
    }

    position: ExecutionParameters = {
        widget: null,
        placeholders: {},
        results: [],
        filters: [],
        range: {
            drilldowns: [],
            from: null,
            to: null,
        },
    };

    breakpoints = new Set<Breakpoint>();
    widget$ = new BehaviorSubject<AnyWidgetModel>(null);
    startLoading$ = new ReplaySubject<void>(1);
    loaded$ = new Subject<void>();
    filters: FormGroup;
    reloadedFilters: Set<string> = new Set();
    updateFromPlaceholders = false;
    resetEventDependencies$ = new BehaviorSubject(null);
    resume$ = new BehaviorSubject(null);
    currentPosition$ = new BehaviorSubject(null);
    complexNamedDropdownChanged$ = new Subject<void>();
    id: number;
    dynamicDashboard: boolean;
    initialPlaceholders: Placeholders = {};
    widget: any;
    lastReceivedTime = 0;
    externalState: ExecutionResult;
    filterDependencyTree = new FilterDependencyTreeBuilder();
    preferredFilters: PreferredFiltersContainer = null;

    private readonly applyWidgetDataSources$ = new BehaviorSubject(true);
    readonly placeholdersUpdated$ = new BehaviorSubject({});
    readonly filterValueChanged$ = new Subject();

    defaultValue$ = this.startLoading$.pipe(
        switchMap(() => this.loaded$.pipe(take(1))),
        map(() => this.filters.value)
    );

    get asyncQueriesLoader$() {
        return this.asyncQueriesFlow.asyncQueriesLoader$;
    }

    get widgetWithQueriesOffset() {
        return this.widget$.value.type === WidgetType.COMPLEX ? 1 : 0;
    }

    private placeholdersStack = new WidgetDrilldownHistory();
    private fromDrilldown = false;
    private defaultPlaceholders$: ReturnType<DefaultPlaceholders>;
    private options: Record<string, any>;
    protected firstLoad = true;
    private resetEventDependencies = false;
    private loaded = false;
    private widgetEventDependencies: WidgetEventDependency[];
    private forceLoad$ = new Subject<void>();
    private asyncQueriesSubscription = Subscription.EMPTY;
    private uiFiltersSubscription = Subscription.EMPTY;
    private currentPosition: { queryId: string; scriptIndex: number } = null;

    protected isCascadingFiltersEnabled = true;

    private static flatWidgets(widget: AnyWidgetModel): AnyWidgetModel[] {
        return [widget].concat(widget.type === WidgetType.COMPLEX ? widget.widgets : []);
    }

    constructor(
        protected scriptExecutor: WidgetScriptExecutor,
        protected api: WidgetBuilderService,
        @Optional() public widgetDebugger: WidgetDebuggerState,
        @Inject(DEFAULT_PLACEHOLDERS) protected defaultPlaceholdersFn$: DefaultPlaceholders,
        @Optional() private store: AnyWidgetStore,
        @Optional() private eventManager: WidgetEventManager,
        @Optional() protected messageBus: DashboardMessageBus,
        private modal: ModalService,
        public logger: WidgetLogger,
        private inViewPort: IfInViewportService,
        private elementRef: ElementRef,
        @Optional() private asyncQueriesFlow: AsyncQueriesFlow,
        protected cdr: ChangeDetectorRef,
        @Inject(WIDGET_PREVIEW_MODE) private widgetPreviewMode: boolean
    ) {
        this.applyWidgetDataSources$.pipe(skip(1), distinctUntilChanged()).subscribe(res => {
            if (res) {
                this.initLoading();
            }
        });
    }

    init(
        widget: AnyWidgetModel,
        options: Record<string, any>,
        initialPlaceholders: Placeholders = {},
        eventDependencies?: Record<string, Subject<any>>
    ) {
        this.options = options;
        this.initialPlaceholders = initialPlaceholders;
        this.setServerFlag(widget);
        this.widgetEventDependencies = widget?.eventDependencies;
        this.setEventDependencies(eventDependencies);
        this.widget$.next(widget);
        this.position.widget = widget;
        this.widgetDebugger.dataSources = {};
        this.widgetDebugger.placeholders$ = new BehaviorSubject<any>({});
        this.initDataSources(widget);
        this.defaultPlaceholders$ = this.getDefaultPlaceholders();
        this.subscribeToMessageBus();

        if (!this.checkWidgetIsValid(widget)) {
            return NEVER;
        }

        return this.defaultPlaceholders$.pipe(
            catchError(err => {
                this.handleErrors(err);

                console.log(err);

                if (err instanceof SilenceWidgetError) {
                    return NEVER;
                }

                return throwError(err);
            }),
            tap(() => this.startLoading$.next()),
            tap(this.initializePlaceholders.bind(this)),
            tap(() => {
                this.resetPosition();
                this.resetWidget();
            }),
            switchMapTo(this.widget$),
            filter(() => {
                if (this.store && !this.store?.widgetIsValid) {
                    setTimeout(() => {
                        this.store.inited$.next(null);
                        this.cdr.detectChanges();
                    });
                }

                return this.store?.widgetIsValid ?? true;
            }),
            tap(this.initFilters.bind(this)),
            switchMap(this.listenFilters.bind(this)),
            tap(() => this.filterValueChanged$.next()),
            tap(this.updateFilterValue.bind(this)),
            tap(this.clearDependentFilters.bind(this)),
            switchMap(() => this.resetEventDependencies$),
            tap(this.initLoading.bind(this)),
            switchMap(this.createEventDependencies.bind(this)),
            tap(this.setPlaceholdersAfterEvents.bind(this)),
            switchMap(() => this.resume$),
            tap(() => this.logger.clear()),
            tap(this.findNearestBreakpoint.bind(this)),
            tap(this.fillStaticFilters.bind(this)),
            switchMap(() => {
                if (!this.widget.complexNamedDropdown || !this.placeholders.fromComplexNamedDropdown) {
                    return of(true);
                }

                return this.complexNamedDropdownChanged$;
            }),
            switchMap(() =>
                this.calculate().pipe(
                    switchMap((result: ExecutionResult) => {
                        if (result.errors.length) {
                            return throwError(result);
                        }

                        return of(result);
                    }),
                    tap(result => {
                        this.eventManager?.process(new WidgetLoadedEvent(result), result.placeholders, this.widget$.value);
                    }),
                    tap(this.swapFromToPositions.bind(this)),
                    tap(this.updatePlaceholders.bind(this)),
                    tap(this.updateFiltersFromPlaceholders.bind(this)),
                    tap(this.updateFilterDataSources.bind(this)),
                    tap(this.hideDependentFilters.bind(this)),
                    switchMap(result => this.applyWidgetDataSources$.pipe(filter(Boolean), mapTo(result))),
                    tap(this.updateWidgetDataSources.bind(this)),
                    catchError(errors => {
                        if (!this.widgetPreviewMode) {
                            console.error(errors);
                        }

                        this.handleErrors(errors);

                        return of([]);
                    }),
                    tap(this.executeAsyncScripts.bind(this)),
                    tap(() => this.loaded$.next()),
                    tap(() => {
                        this.afterCalculateCall();
                    })
                )
            ),
            tap(() => {
                this.fromDrilldown = false;
                this.firstLoad = false;
                this.loaded = true;
            })
        );
    }

    setDrilldownLevel(level: number[][]) {
        this.position.range.drilldowns = [...this.position.range.drilldowns, ...level];
    }

    forceLoad() {
        if (!this.loaded) {
            this.forceLoad$.next();
        }
    }

    reload(widget: AnyWidgetModel) {
        if (this.store && isEqual(widget, this.store.value.widget)) {
            return;
        }

        this.initDataSources(widget);
        this.store.rootWidget = widget;
        this.position.widget = widget;
        this.setWidget(widget);
    }

    setDashboard(dashboardStub: any) {
        // empty implementation for widget
    }

    protected getDefaultPlaceholders() {
        const defaultPlaceholders$ = this.defaultPlaceholdersFn$({
            ...this.widget$.value,
            options: this.options,
        });
        const inViewPort$ = this.inViewPort.isInViewObs(this.elementRef.nativeElement).pipe(
            filter(() => !this.loaded),
            take(1)
        );
        let fromForceLoad = false;

        return merge(
            inViewPort$,
            this.forceLoad$.pipe(
                tap(() => {
                    fromForceLoad = true;
                })
            )
        )
            .pipe(
                switchMapTo(defaultPlaceholders$),
                tap(() => (this.loaded = false)),
                switchMap(placeholders =>
                    (fromForceLoad || this.widget$.value.type === WidgetType.PORTFOLIO_HEALTH_SCORES
                        ? of(true as any)
                        : this.inViewPort.isInViewObs(this.elementRef.nativeElement)
                    ).pipe(mapTo(placeholders), take(1))
                )
            )
            .pipe(
                tap(() => {
                    fromForceLoad = false;
                }),
                map(
                    placeholders =>
                        ({
                            ...placeholders,
                            ...this.initialPlaceholders,
                        } as any)
                ),
                map(this.removeRedundantPlaceholders.bind(this)),
                shareReplay(1)
            );
    }

    private removeRedundantPlaceholders(placeholders: Record<string, any>) {
        const inputPlaceholders = this.widget$.value.inputPlaceholders;

        if (!inputPlaceholders || !inputPlaceholders.length || this.modal.hasOpenModals()) {
            return placeholders;
        }

        return pick(placeholders, [...inputPlaceholders, 'widgetFilterVisibility']);
    }

    private setWidget(widget: AnyWidgetModel) {
        if (this.store && isEqual(widget, this.store.value.widget)) {
            return;
        }

        if (this.store) {
            this.store.widgetIsValid = this.checkWidgetIsValid(widget);
        }

        this.widget$.next(widget);
        this.store?.setState({
            widget,
        });
    }

    private setServerFlag(widget: AnyWidgetModel) {
        widget.server = true;

        (widget.widgets || []).forEach(subWidget => this.setServerFlag(subWidget));
        (widget.drilldownList || []).forEach(drilldown => this.setServerFlag(drilldown.widget));
    }

    private setEventDependencies(eventDependencies: Record<string, Subject<any>>) {
        if (this.messageBus && !this.messageBus.eventDependencies) {
            this.messageBus.eventDependencies = eventDependencies;
        }
    }

    private resetPosition() {
        if (this.firstLoad) {
            return;
        }

        this.position.range = {
            drilldowns: [],
            from: null,
            to: null,
        };
    }

    protected calculate() {
        if (this.firstLoad && this.externalState) {
            return of(this.externalState);
        }

        const params = this.buildCalculateWidgetParams();

        return this.debug ? this.api.calculateWidget(params) : this.api.calculateWidgetById(this.id, params);
    }

    private handleErrors(result: ExecutionResult | Error) {
        if (result instanceof Error || result instanceof HttpErrorResponse || result instanceof SilenceWidgetError) {
            if (!(result instanceof SilenceWidgetError)) {
                this.logger.log(parseError(result));
            }

            this.updateDataSources(
                {
                    results: [],
                    placeholders: {
                        _options: {},
                    },
                    errors: [],
                },
                true
            );
        } else {
            this.swapFromToPositions();
            this.updateFiltersFromPlaceholders(result);
            this.updateDataSources(result, true);
            this.updatePlaceholders(result);

            result.errors.forEach(err => this.logger.log(err));
        }
    }

    private getResults(useWidgetPlaceholders: boolean) {
        return WidgetWorkflow.flatWidgets(this.widget$.value)
            .slice(this.widgetWithQueriesOffset)
            .map(widget => (useWidgetPlaceholders ? this.widgetDebugger.placeholdersMap[widget.id].value : {}));
    }

    private subWidgetIsDebugging() {
        const from = this.position.range.from;

        if (!this.debug || !from || !from.placeholder || from.level === -1) {
            return false;
        }

        const currentWidget = WidgetWorkflow.flatWidgets(this.widget$.value)[this.widgetWithQueriesOffset];
        const queryIndex = currentWidget.query.findIndex(({ placeholder }) => placeholder === from.placeholder);

        return !(queryIndex === 0 && from.script === WidgetScript.BEFORE);
    }

    protected buildCalculateWidgetParams(): ExecutionParametersApi {
        return {
            ...this.position,
            results: this.getResults(this.subWidgetIsDebugging()),
            widget: this.debug
                ? {
                      options: this.options as any,
                      id: null,
                      uuid: null,
                      dashboardId: null,
                      groupId: null,
                      data: {
                          ...this.position.widget,
                          id: null,
                      },
                  }
                : null,
        };
    }

    private initializePlaceholders(externalPlaceholders: Placeholders): void {
        this.placeholders = cloneDeep(externalPlaceholders);
    }

    private hideDependentFilters() {
        for (const widgetFilter of this.widget$.value.filters || []) {
            if (widgetFilter.valueDependency) {
                if ((widgetFilter.dependentValue || []).some(element => element == this.placeholders[widgetFilter.valueDependency])) {
                    this.widgetDebugger.setFilterVisibleState(widgetFilter.placeholder, true);
                } else {
                    this.widgetDebugger.setFilterVisibleState(widgetFilter.placeholder, false);

                    if (!widgetFilter.onlyHideDependentOn) {
                        this.resetFilterState(widgetFilter.placeholder);
                    }
                }
            } else {
                this.widgetDebugger.setFilterVisibleState(widgetFilter.placeholder, true);
            }
        }
    }

    protected updateFilterValue(event: FilterValueChange | null): Placeholders {
        if (isServerSideGridWithPagination(this.widget) && event && event.placeholder !== 'pageable') {
            this.position.placeholders.pageable = { offset: 0, limit: 10 };
        }

        if (event) {
            this.placeholders[event.placeholder] = event.value;
            this.placeholders.updateFilter = true;

            this.eventManager?.process(new WidgetFormEvent(event.value, event.placeholder), this.placeholders, this.widget$.value);
        }
        this.hideDependentFilters();

        return this.placeholders;
    }

    updatePlaceholdersAndReload(changes: { placeholder: string; value: unknown }[]) {
        const placeholders = cloneDeep(this.position.placeholders);
        if (changes && changes.length) {
            changes.forEach(it => {
                placeholders[it.placeholder] = it.value;
            });
            this.position.placeholders = placeholders;
        }

        this.initLoading();
        this.resume$.next(null);
    }

    protected initFilters(widget: AnyWidgetModel): void {
        this.widget = widget;
        this.filterDependencyTree.init(widget);
        this.filters = getFiltersForm({
            filters: widget.filters || [],
            defaultValuesMap: {},
            scriptExecutor: this.scriptExecutor,
            gridWithPagination: isServerSideGridWithPagination(widget),
            initDefaultValues: false,
            complexNamedDropdown: true,
        });

        if (this.firstLoad && this.widget.complexNamedDropdown) {
            this.placeholders.fromComplexNamedDropdown = true;
        }

        if (hasInfiniteScroll(widget) || isServerSideGridWithPagination(widget)) {
            this.position.placeholders.pageable = { offset: 0, limit: 10 };
        }

        this.widgetDebugger.filters$.next(this.filters);
    }

    protected clearDependentFilters(event: FilterValueChange): Placeholders {
        if (this.fromDrilldown || this.firstLoad) {
            return this.placeholders;
        }

        if (!event) {
            this.reloadAllFilters();
        } else {
            const dependencyTree = this.filterDependencyTree.buildTree(event.placeholder);

            // if filter is independent, we start flow from first widget query
            if (!dependencyTree.children.length) {
                this.setFromToFirstWidgetQuery();
            } else {
                const { filter: startFilter } = dependencyTree.children[0];

                this.position.range.from = {
                    level: -1,
                    placeholder: startFilter,
                    script: WidgetScript.BEFORE,
                };

                dependencyTree.walk(item => {
                    if (item.filter !== dependencyTree.filter) {
                        this.resetFilterStateAndAddToReload(item.filter);
                    }
                });
            }
        }

        return this.placeholders;
    }

    protected resetFilterStateAndAddToReload = (placeholder: string) => {
        this.resetFilterState(placeholder);
        this.reloadedFilters.add(placeholder);
    };

    protected reloadAllFilters() {
        for (const { placeholder } of this.widget$.value.filters ?? []) {
            this.resetFilterStateAndAddToReload(placeholder);
        }
    }

    protected createEventDependencies() {
        const fromDebug = !this.id;
        if (!this.messageBus || !this.widgetEventDependencies || !this.widgetEventDependencies.length || fromDebug) {
            return of(null);
        }

        return merge(
            this.widgetEventDependencies
                .map(dependency => dependency.condition.split(',').map(str => str.trim()))
                .map(dependency => {
                    dependency.forEach((eventId: string) => {
                        if (!this.messageBus.eventDependencies[eventId]) {
                            this.messageBus.eventDependencies[eventId] = new BehaviorSubject(null);
                        }
                    });

                    return combineLatest(dependency.map(eventId => this.messageBus.eventDependencies[eventId])).pipe(
                        filter(arrayFromEventManager => arrayFromEventManager.some(value => value))
                    );
                })
        ).pipe(mergeMap(obs => obs));
    }

    protected setPlaceholdersAfterEvents(arrayFromEvents: any[]) {
        if (this.placeholders.updateFilter) {
            delete this.placeholders.updateFilter;
            return;
        }

        if (arrayFromEvents) {
            const { placeholders } = arrayFromEvents.find(value => value.placeholders);
            this.position.placeholders = this.removeRedundantPlaceholders(placeholders);

            if (hasInfiniteScroll(this.widget)) {
                this.position.placeholders.pageable = { offset: 0, limit: 10 };
            }
        }
    }

    protected initLoading() {
        if (!this.applyWidgetDataSources$.value) {
            return;
        }
        WidgetWorkflow.flatWidgets(this.widget$.value)
            .map(widget => widget.id)
            .concat(Array.from(this.reloadedFilters))
            .forEach(placeholder => {
                (this.widgetDebugger.dataSources[placeholder] as BehaviorSubject<any>)?.next(START_LOADING);
            });
    }

    protected subscribeToMessageBus() {
        if (!this.messageBus) {
            return;
        }

        this.loaded$
            .pipe(
                take(1),
                switchMapTo(this.messageBus.on()),
                map(events => {
                    if (events) {
                        return this.filterEvents(events);
                    }
                    return events;
                })
            )
            .subscribe(events => {
                if (!events) {
                    if (this.widgetEventDependencies?.length) {
                        this.messageBus.reset();
                        this.resetEventDependencies = true;
                        Object.keys(this.messageBus.eventDependencies).forEach(key => delete this.messageBus.eventDependencies[key]);
                        this.resetEventDependencies$.next(null);
                    }

                    return;
                }

                if (!events.length) {
                    return;
                }

                this.processEvents(events);
            });
    }

    private filterEvents(events: WidgetEvent[]) {
        const processedEventIds: Record<string, boolean> = {};
        const filteredEvents: WidgetEvent[] = [];

        for (let i = events.length - 1; i >= 0; i--) {
            const event = events[i];

            if (event.timestamp <= this.lastReceivedTime) {
                break;
            }

            if (processedEventIds[event.id]) {
                continue;
            }

            processedEventIds[event.id] = true;
            filteredEvents.push(event);
        }

        return filteredEvents;
    }

    private rerender(executionResult: ExecutionResult) {
        this.updatePlaceholders(executionResult);
        this.updateFiltersFromPlaceholders(executionResult);
        this.updateDataSources(executionResult);
    }

    protected processEvents(events: WidgetEvent[]) {
        events.forEach(event => {
            const handler = findEventHandler(event, this.widget$.value);
            this.updateLastReceivedTime(event);

            if (!handler) {
                return;
            }

            const results = this.results;
            const placeholders = cloneDeep(this.placeholders);

            let needRerender;
            let needReload = false;

            const refresh = () => {
                needRerender = true;
            };

            const reload = () => {
                needReload = true;
            };

            const bindings = Object.entries({
                event,
                placeholders,
                results,
                refresh,
                reload,
            }).reduce(
                ([names, params], [paramName, param]) => {
                    names.push(paramName);
                    params.push(param);

                    return [names, params];
                },
                [[], []]
            );

            try {
                this.scriptExecutor.buildFn(`return (function(${bindings[0].join(',')}){${handler.script}})`)(...bindings[1]);

                if (this.firstLoad) {
                    return;
                }

                if (this.resetEventDependencies) {
                    if (this.widgetEventDependencies?.length) {
                        this.resetEventDependencies$.next(null);
                    }

                    this.firstLoad = true;
                    this.resetEventDependencies = false;
                    return;
                }

                if (needRerender) {
                    this.rerender({
                        placeholders,
                        results,
                        errors: [],
                    });
                } else if (needReload) {
                    this.position.placeholders = placeholders;
                    this.initLoading();
                    this.resume$.next(null);
                }
            } catch (e) {
                this.logger.log(parseError(e));
            }
        });
    }

    findNearestBreakpoint(): void {
        if (!this.breakpoints.size) {
            this.position.range.to = null;

            return;
        }

        const widget = this.widget$.value;
        const flatWidgets = WidgetWorkflow.flatWidgets(widget);
        const flowLength = flatWidgets.length;
        let currentWidget: AnyWidgetModel;
        const scripts = [WidgetScript.BEFORE, WidgetScript.SQL, WidgetScript.AFTER];
        const breakpoints = Array.from(this.breakpoints).reduce<Record<string, any>>((acc, position) => {
            (((acc[position.widgetId] ??= {})[position.level] ??= {})[position.placeholder] ??= {})[position.script] = true;

            return acc;
        }, {});

        const findToPosition = (): Breakpoint => {
            for (
                let flow = this.position.range.from ? this.position.range.from.level + 1 : 0;
                flow < flowLength + (widget.type === WidgetType.COMPLEX ? 0 : 1);
                flow++
            ) {
                currentWidget = widget.type === WidgetType.COMPLEX ? flatWidgets[flow] : widget;

                if (!breakpoints[currentWidget.id]?.[flow]) {
                    continue;
                }

                if (flow === 0) {
                    for (
                        let filterIndex = this.getPlaceholderIndex(
                            (currentWidget.filters || []).map(filter => filter.placeholder),
                            flow - 1
                        );
                        filterIndex < (currentWidget.filters || []).length;
                        filterIndex++
                    ) {
                        const filter = currentWidget.filters[filterIndex];

                        if (!breakpoints?.[currentWidget.id]?.[flow]?.[filter.placeholder]) {
                            continue;
                        }

                        if (isDynamicFilter(filter)) {
                            for (let script = this.getScriptIndex(flow - 1, filter.placeholder); script < scripts.length; script++) {
                                if (breakpoints?.[currentWidget.id]?.[flow]?.[filter.placeholder]?.[script]) {
                                    const position = {
                                        level: -1,
                                        placeholder: filter.placeholder,
                                        script,
                                    };
                                    this.currentPosition = {
                                        queryId: filter.query.id,
                                        scriptIndex: script,
                                    };

                                    if (isEqual(position, this.position.range.from)) {
                                        continue;
                                    }

                                    return position;
                                }
                            }
                        }
                    }
                } else {
                    for (
                        let queryIndex = this.getPlaceholderIndex(
                            currentWidget.query.map(query => query.placeholder),
                            flow - 1
                        );
                        queryIndex < currentWidget.query.length;
                        queryIndex++
                    ) {
                        const query = currentWidget.query[queryIndex];

                        if (!breakpoints?.[currentWidget.id]?.[flow]?.[query.placeholder]) {
                            continue;
                        }

                        for (let script = this.getScriptIndex(flow - 1, query.placeholder); script < scripts.length; script++) {
                            if (breakpoints?.[currentWidget.id]?.[flow]?.[query.placeholder]?.[script]) {
                                const position = {
                                    level: flow - 1,
                                    placeholder: query.placeholder,
                                    script,
                                };
                                this.currentPosition = {
                                    queryId: query.id,
                                    scriptIndex: script,
                                };

                                if (isEqual(position, this.position.range.from)) {
                                    continue;
                                }

                                return position;
                            }
                        }
                    }
                }
            }

            return null;
        };

        this.position.range.to = findToPosition();

        if (!this.position.range.to) {
            this.currentPosition = null;
        }
    }

    private getPlaceholderIndex(placeholders: string[], currentFlow: number) {
        if (!!this.position.range.from && currentFlow === this.position.range.from.level) {
            return placeholders.indexOf(this.position.range.from.placeholder);
        }

        return 0;
    }

    getScriptIndex(currentFlow: number, currentPlaceholder: string) {
        if (
            this.position.range.from &&
            this.position.range.from.level === currentFlow &&
            this.position.range.from.placeholder === currentPlaceholder
        ) {
            return this.position.range.from.script;
        }

        return WidgetScript.BEFORE;
    }

    private swapFromToPositions() {
        this.position.range.from = this.position.range.to;

        this.currentPosition$.next(this.currentPosition);
    }

    getPlaceholdersFromCurrentLevel(state: ExecutionResult) {
        if (this.position.range.to) {
            if (this.position.range.to.level === -1) {
                return state.placeholders;
            }

            return state.results[this.position.range.to.level];
        }

        return state.results[state.results.length - 1];
    }

    protected updatePlaceholders(state: ExecutionResult) {
        this.widgetDebugger.placeholders$.next(this.getPlaceholdersFromCurrentLevel(state));

        this.placeholders = state.placeholders;
    }

    private initDataSources(widget: AnyWidgetModel) {
        const createOrSetDataSource = (placeholder: string) => {
            if (this.widgetDebugger.dataSources[placeholder]) {
                (this.widgetDebugger.dataSources[placeholder] as BehaviorSubject<any>).next(START_LOADING);
            } else {
                this.widgetDebugger.dataSources[placeholder] = new BehaviorSubject(START_LOADING);
            }
        };

        this.widgetDebugger.dataSources['complexNamedDropdown'] = new BehaviorSubject(START_LOADING);

        WidgetWorkflow.flatWidgets(widget).forEach(subWidget => {
            createOrSetDataSource(subWidget.id);
            this.widgetDebugger.placeholdersMap[subWidget.id] = new BehaviorSubject({ ...this.placeholders });

            (subWidget.filters || []).forEach(widgetFilter => {
                if (isDynamicFilter(widgetFilter)) {
                    createOrSetDataSource(widgetFilter.placeholder);
                }

                this.widgetDebugger.setFilterVisibleState(widgetFilter.placeholder, !!widgetFilter.valueDependency);
            });
        });
    }

    updateFiltersFromPlaceholders({ placeholders }: ExecutionResult) {
        this.updateFromPlaceholders = true;

        Object.entries(this.filters.controls).forEach(([placeholder, control]) => {
            const filterValue = placeholders[placeholder] ?? null;

            control.patchValue(filterValue, { emitEvent: false });
        });

        this.updateFromPlaceholders = false;
        this.placeholdersUpdated$.next(this.filters.value);
    }

    fillStaticFilters() {
        // eslint-disable-next-line no-underscore-dangle
        if (!this.placeholders._options) {
            // eslint-disable-next-line no-underscore-dangle
            this.placeholders._options = {};
        }

        (this.widget$.value.filters || []).forEach(widgetFilter => {
            if (!isDropdownFilter(widgetFilter)) {
                return;
            }

            const items: DropdownItem[] = widgetFilter.items;

            if (!items || widgetFilter.dynamic) {
                return;
            }

            // eslint-disable-next-line no-underscore-dangle
            this.placeholders._options[widgetFilter.placeholder] = items;
        });
    }

    private updateFilterDataSources({ results, placeholders }: ExecutionResult, addEmpty = false) {
        this.position.results = results;

        const valueOnEmpty: any[] = addEmpty ? [] : undefined;
        const flatWidgets = WidgetWorkflow.flatWidgets(this.widget$.value);

        for (const filter of flatWidgets[0].filters || []) {
            if (!isDynamicFilter(filter) || !this.needReloadFilterDataSource(filter)) {
                continue;
            }

            // eslint-disable-next-line no-underscore-dangle
            const valueFromPlaceholders = placeholders._options?.[filter.placeholder];
            const result: any[] = valueFromPlaceholders ?? valueOnEmpty;

            if (result) {
                this.preferredFilters?.process(filter.placeholder, result);
                (this.widgetDebugger.dataSources[filter.placeholder] as BehaviorSubject<any>).next(result);
            }

            if (!result && filter.type === WidgetFilterType.DROPDOWN) {
                (this.widgetDebugger.dataSources[filter.placeholder] as BehaviorSubject<any>).next([]);
            }
        }
    }

    private updateWidgetDataSources({ results, placeholders }: ExecutionResult, addEmpty = false) {
        const valueOnEmpty: any[] = addEmpty ? [] : undefined;
        const flatWidgets = WidgetWorkflow.flatWidgets(this.widget$.value);
        const offset = this.widgetWithQueriesOffset;

        if (this.widget$.value.type === WidgetType.COMPLEX) {
            this.widgetDebugger.placeholdersMap[flatWidgets[0].id].next(placeholders);
        }

        for (let i = 0; i < results.length; i++) {
            const widgetId = flatWidgets[i + offset].id;

            this.widgetDebugger.placeholdersMap[widgetId].next(results[i]);
        }

        for (let i = 0; i < results.length; i++) {
            const result: any[] = results?.[i]?.result ?? valueOnEmpty;
            const widgetId = flatWidgets[i + offset].id;

            if (!result || this.position.range.to) {
                continue;
            }

            (this.widgetDebugger.dataSources[widgetId] as BehaviorSubject<any>).next(result);
        }

        if (addEmpty && !results.length) {
            for (const { id } of flatWidgets) {
                (this.widgetDebugger.dataSources[id] as BehaviorSubject<any>)?.next([]);
            }
        }

        this.reloadedFilters.clear();
    }

    private updateDataSources(result: ExecutionResult, addEmpty = false) {
        this.updateFilterDataSources(result, addEmpty);
        this.updateWidgetDataSources(result, addEmpty);
    }

    protected updateLastReceivedTime(event: WidgetEvent) {
        this.lastReceivedTime = Math.max(this.lastReceivedTime, event.timestamp);
    }

    protected needReloadFilterDataSource(filter: WidgetFilter) {
        return true;
    }

    drilldown(
        anyWidgetClazz: Type<any>,
        {
            drilldown,
            event,
            templates,
            id,
        }: {
            drilldown: WidgetDrilldown;
            event: DrilldownEvent;
            templates: Record<string, any>;
            id: string;
        }
    ) {
        const openInModal = drilldown.displayType === 'modal' && !this.modal.hasOpenModals() && !this.debug;
        const widgets = WidgetWorkflow.flatWidgets(this.widget$.value);
        const widgetIndex = widgets.findIndex(widget => widget.id === id);
        const to = (widgets[widgetIndex].drilldownList || []).indexOf(drilldown);

        if (to === -1 || widgetIndex === -1) {
            return;
        }

        this.asyncQueriesLoader$.next(false);

        const drilldownLevel = [widgetIndex - this.widgetWithQueriesOffset, to];

        if (!openInModal) {
            this.position.range.drilldowns.push(drilldownLevel);
        }
        const oldPlaceholders = cloneDeep(this.placeholders);
        const placeholders = this.widgetDebugger.placeholdersMap[id].value;

        this.addLegendPlaceholders(placeholders, oldPlaceholders);

        if (drilldown.drilldownScript) {
            this.scriptExecutor.evaluateScript({
                expression: drilldown.drilldownScript,
                type: 'drilldown',
                context: {
                    placeholders,
                    event: event.payload,
                    payload: event.event,
                },
                placeholder: this.widget$.value.chartOptions.title,
            });
        }

        // this condition need for example to choose between drilldown or message bus events.
        // example: widget can be on 2 different dashboards. On first, he emits message bus on another he emits drilldown.
        if (!!placeholders.passCurrentDrilldown) {
            this.position.range.drilldowns.pop();
            return;
        }

        if (openInModal) {
            this.modal.open(
                anyWidgetClazz,
                {
                    centered: true,
                    size: 'xl',
                    windowClass: 'any-widget-modal',
                    backdrop: 'static',
                    injector: Injector.create({
                        providers: [
                            {
                                provide: DEFAULT_PLACEHOLDERS,
                                useValue: () => of(placeholders),
                            },
                            {
                                provide: DashboardMessageBus,
                                useValue: this.messageBus,
                            },
                        ],
                    }),
                },
                {
                    breakpoints: this.breakpoints,
                    externalOptions: this.options,
                    options: drilldown.widget,
                    widgetId: this.id,
                    drilldownLevel: [...this.position.range.drilldowns, drilldownLevel],
                    ...templates,
                }
            );
        } else {
            this.position.results = [];
            this.position.placeholders = placeholders;

            this.preferredFilters?.clear();
            this.placeholdersStack.push({
                widget: this.widget$.value,
                placeholders: oldPlaceholders,
            });

            this.initDataSources(drilldown.widget);

            this.setWidget(drilldown.widget);
            this.store.inited$.next(null);
        }
    }

    preview(anyWidgetClazz: Type<any>) {
        return this.modal.open(
            anyWidgetClazz,
            {
                centered: true,
                size: 'xl',
                windowClass: 'any-widget-modal any-widget-help',
                injector: Injector.create({
                    providers: [
                        {
                            provide: DEFAULT_PLACEHOLDERS,
                            useValue: () => of({}),
                        },
                    ],
                }),
            },
            {
                widgetId: this.id,
                externalOptions: this.options,
                options: this.widget$.value,
                externalState: {
                    placeholders: this.placeholders,
                    errors: [],
                    results: this.getResults(true),
                },
                separatedView: true,
                showHelp: true,
                previewMode: true,
            }
        );
    }

    back() {
        this.fromDrilldown = true;

        this.position.range.drilldowns.pop();

        let drilldownCache;
        if (isDefined(this.placeholders.drilldownCache)) {
            drilldownCache = cloneDeep(this.placeholders.drilldownCache);
        }

        const { widget, placeholders } = this.placeholdersStack.pop();

        this.initDataSources(widget);

        this.placeholders = placeholders;
        this.placeholders.drilldownCache = drilldownCache;
        this.clearColumnsState();
        this.setWidget(widget);
        this.store.inited$.next(null);
    }

    reset() {
        this.position.range.from = null;
        this.clearDependentFilters(null);
        this.initLoading();
        this.startLoading$.next();

        this.resume$.next(null);
        if (this.isFunnelIconEnabled) {
            this.apply();
        }
    }

    protected executeAsyncScripts() {
        this.asyncQueriesSubscription.unsubscribe();

        const asyncQueries = this.widget$.value.asyncQueries ?? [];

        if (isEmpty(asyncQueries)) {
            return;
        }

        const executionResult: ExecutionResult = {
            placeholders: this.placeholders,
            results: this.position.results,
            errors: [],
        };

        this.asyncQueriesFlow?.call(asyncQueries, executionResult, this.widget$.value).subscribe(() => {
            this.rerender(cloneDeep(executionResult));
        });
    }

    protected listenFilters(widget: AnyWidgetModel): Observable<FilterValueChange> {
        const [_, uiFilters] = partition(
            (widget.filters || []).filter(widgetFilter => widgetFilter.type !== WidgetFilterType.HIDDEN),
            widgetFilter => !widgetFilter.isUiFilter
        );
        const { id: widgetId } = widget;

        this.listenUiFilters(uiFilters);

        return merge(
            ...Object.entries(this.filters.controls)
                .filter(([placeholder]) => !uiFilters.some(it => it.placeholder === placeholder))
                .map(([placeholder, control]) =>
                    control.valueChanges.pipe(
                        filterObs(() => !this.updateFromPlaceholders),
                        tap(() => {
                            if (this.isFunnelIconEnabled) {
                                this.applyWidgetDataSources$.next(false);
                            }
                        }),
                        map(value => {
                            const fromComplexNamedDropdown = value && value.fromComplexNamedDropdown;
                            this.placeholders.fromComplexNamedDropdown = fromComplexNamedDropdown;

                            return fromComplexNamedDropdown ? value.controlValue : value;
                        }),
                        map(value => ({
                            value,
                            placeholder,
                            widgetId,
                        }))
                    )
                )
        ).pipe(startWith(null as FilterValueChange));
    }

    protected listenUiFilters(filters: WidgetFilter[]) {
        this.uiFiltersSubscription.unsubscribe();

        this.uiFiltersSubscription = merge(
            ...filters.map(({ placeholder }) =>
                this.filters.get(placeholder).valueChanges.pipe(
                    filterObs(() => !this.updateFromPlaceholders),
                    map(value => ({ value, placeholder }))
                )
            )
        ).subscribe(({ value, placeholder }) => {
            const { results } = this;
            const placeholders = cloneDeep(this.placeholders);

            placeholders[placeholder] = value;

            for (const result of results) {
                result[placeholder] = value;
            }

            this.rerender({
                results,
                placeholders,
                errors: [],
            });
            if (this.isFunnelIconEnabled) {
                this.loaded$.next();
            }
        });
    }

    protected checkWidgetIsValid(widget: AnyWidgetModel) {
        return checkWidgetIsValid(widget);
    }

    /*
        if external placeholders has been changed, reset filter to init state
    */
    protected resetWidget() {
        this.setWidget(this.position.widget);

        // is used for title/description refresh
        if (!this.placeholdersStack.empty()) {
            this.placeholdersStack.reset();
            this.store?.inited$.next(null);
        }
    }

    protected clearColumnsState() {
        WidgetWorkflow.flatWidgets(this.widget$.value).forEach(({ id }) => {
            delete this.store.gridColumnDefsBeforePagination[id];
        });
    }

    protected setFromToFirstWidgetQuery() {
        this.position.range.from = {
            level: 0,
            placeholder: WidgetWorkflow.flatWidgets(this.widget$.value)[this.widgetWithQueriesOffset].query[0].placeholder,
            script: WidgetScript.BEFORE,
        };
    }

    protected afterCalculateCall() {
        // empty by default
    }

    resetFilterState(placeholder: string) {
        delete this.placeholders[placeholder];
        delete this.placeholders._options?.[placeholder];
    }

    addLegendPlaceholders(newPlaceholders: Placeholders, oldPlaceholders: Placeholders) {
        const legendStateKey = new RegExp(`^${BasePlaceholders.LEGEND_STATE}_\\d+$`);

        Object.assign(
            newPlaceholders,
            pickBy(oldPlaceholders, (_, key) => legendStateKey.test(key))
        );
    }

    setLegendState(state: Array<{ selected: false; name: string }>, widgetIndex?: number) {
        this.placeholders[`${BasePlaceholders.LEGEND_STATE}_${this.position.range.drilldowns.length}`] = state.map(item => ({
            label: item.name,
            selected: !item.selected,
        }));
    }

    apply() {
        this.applyWidgetDataSources$.next(true);
    }

    ngOnDestroy() {
        this.asyncQueriesSubscription?.unsubscribe();
        this.uiFiltersSubscription?.unsubscribe();
    }
}

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class WidgetWorkflowContainer implements OnChanges {
    @Input() breakpoints: Breakpoint[];

    workflow: WidgetWorkflow;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.breakpoints && this.workflow) {
            this.workflow.breakpoints = new Set(this.breakpoints);
        }
    }
}

export interface FilterValueChange {
    widgetId: string;
    placeholder: string;
    value: any;
}
