import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { appendError } from '@dagility-ui/kit';

interface Variable {
    label: string;
    name: string;
    value?: any;
}

@Component({
    selector: 'dp-new-placeholders',
    template: `
        <div class="modal-header">
            <h2>
                Input Placeholders
            </h2>
        </div>
        <div class="modal-body">
            <ng-container *ngIf="!placeholders">
                <lib-codemirror-editor
                    [formControl]="form"
                    mode="application/json"
                    [customErrors]="{
                        invalidJSON: 'Invalid JSON format'
                    }"
                >
                </lib-codemirror-editor>
            </ng-container>
            <ng-container *ngIf="placeholders">
                <lib-properties-editor
                    class="properties-editor-form d-block"
                    [fieldsEditAllowed]="false"
                    [showError]="false"
                    [formControl]="form"
                    [customErrors]="{
                        invalidData: 'Invalid data format'
                    }"
                >
                </lib-properties-editor>
                <lib-validation-errors [control]="form">
                    <ng-template valError="invalidData">
                        Invalid data format
                    </ng-template>
                </lib-validation-errors>
            </ng-container>
        </div>
        <div class="modal-footer">
            <button class="btn btn-sm btn-primary" (click)="handleRun()">Run</button>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewPlaceholdersComponent implements OnInit {
    @Input() placeholders: Array<Variable>;

    form: FormControl = new FormControl('{}');

    constructor(public modal: NgbActiveModal) {}

    ngOnInit() {
        if (this.placeholders) {
            this.form.patchValue(this.placeholders);
        }
    }

    handleRun() {
        let json: string;
        if (this.placeholders) {
            const variablesJson = this.buildJSONFromVariables(this.form.value);

            if (!isValidJSON(variablesJson)) {
                appendError(this.form, 'invalidData', true, true);
                return;
            }
            json = variablesJson;
        } else {
            if (!isValidJSON(this.form.value)) {
                appendError(this.form, 'invalidJSON');
                return;
            }

            json = this.form.value;
        }

        this.modal.close(JSON.parse(json));
    }

    buildJSONFromVariables(variables: Variable[]) {
        return `{${variables.map(item => `"${item.name}": ${item.value}`).join(',\r\n')}}`;
    }
}

function isValidJSON(str: string): boolean {
    try {
        JSON.parse(str);

        return true;
    } catch {
        return false;
    }
}
